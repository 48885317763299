/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable ngrx/no-typed-global-store */
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { Config, FRUser } from '@forgerock/javascript-sdk';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';

import { CoreService } from '@amfam/opportunities';
import { AnalyticsActions, AnalyticsFacade, GTMDataLayerInit } from '@amfam/shared/analytics';
import { HeaderWrapperService } from '@amfam/shared/ui/ds-header';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import {
  ConfigService,
  EventDispatcherService,
  ScriptLoaderService,
  WindowRef
} from '@amfam/shared/utility/shared-services';
import { DockingBarService, DsModalService, LoadingSpinnerService } from '@amfam/ui-kit';

import { AuthService, ForgerockAuthService } from './core';
import { FeatureDetectionService } from './core/feature-detection/feature-detection.service';
import * as fromRoot from './core/store';
import { SessionActivityService } from './login/session-activity';

@Component({
  selector: 'ds-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public noDock$: Observable<boolean>;
  private stop$ = new Subject<void>();
  dataLayerInit: GTMDataLayerInit;

  title = 'My Account';

  mobileMenuOpen$: Observable<boolean>;
  mobileMenuActive$: Observable<boolean>;

  @ViewChild('overlay')
  overlay: ElementRef;

  @HostListener('click')
  logSessionActivity() {
    this.sessionSvc.registerUserActivity();
  }
  noDocumentModalId = 'noDocumentModal';
  noDocumentMessage: string;

  constructor(
    private spinner: LoadingSpinnerService,
    private sessionSvc: SessionActivityService,
    private dockingService: DockingBarService,
    private featureDetectionService: FeatureDetectionService,
    private router: Router,
    private win: WindowRef,
    private store: Store<fromRoot.RootState>,
    private headerWrapperService: HeaderWrapperService,
    private deviceService: DeviceDetectorService,
    private coreService: CoreService,
    private scriptloader: ScriptLoaderService,
    private configService: ConfigService,
    private analyticsFacade: AnalyticsFacade,
    private modal: DsModalService,
    private eventDispatcherService: EventDispatcherService,
    private featureService: FeatureFlagService,
    private forgerockAuthService: ForgerockAuthService,
    private authService: AuthService
  ) {
    // intentional console log intended to help devs/testers see in browser console to know which version is deployed
    console.log(`Platform Build: ${configService.get('version')}`);
  }

  ngOnInit() {
    // Attempt to load the script only if the script URL is available in the config.json
    try {
      const scriptURL = this.configService.get('links.salesForceBeconURL');
      if (scriptURL) {
        this.scriptloader.loadScript(scriptURL);
      } else {
        throw new Error('Script URL not found check app.config.ts');
      }
    } catch (error) {
      console.error('Error loading: scriptURL', error);
    }

    // Check the app status
    this.featureDetectionService.detect();
    this.analyticsFacade.loadGoogleMaps();
    this.store.dispatch(AnalyticsActions.loadDataDog());
    this.store.dispatch(AnalyticsActions.loadDynatrace());
    // this.store.dispatch(AnalyticsActions.loadAdobeAnalytics()); // This is how we load analytics with the ngrx implementation.
    this.store.dispatch(AnalyticsActions.loadGoogleAnalytics()); // This is how we load analytics with the ngrx implementation.

    this.mobileMenuOpen$ = this.headerWrapperService.mobileMenuOpen$;
    this.mobileMenuActive$ = this.headerWrapperService.mobileMenuActive$;

    // TODO find a better place for this subscription / refactor analytic user type
    this.store
      .select(fromRoot.getAnalyticUserType)
      .pipe(takeUntil(this.stop$))
      .subscribe(userType => {
        this.analyticsFacade.setProfileInfo({ userType });
      });

    const trackId = sessionStorage.getItem('trackID');
    if (trackId) datadogRum.setUser({ id: trackId });

    // TODO fix pipe(delay(0))
    this.noDock$ = this.dockingService.dockEmpty$.pipe(delay(0));

    // Scroll to the top of the page on route change
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.win.nativeWindow.scrollTo(0, 0);

      /*
      On navigation end, change focused element to body if needed.
      Without this the focused item will be the last item focused before the route change.
      This causes a 'focus trap' and keeps the 'Skip to content' link at the top of the page from being the first tabbed to items.
      That means that the screen reader starts at a weird place in the screen, sometimes on an element that no longer exists.
      */

      // a11y: this checks if the currently focused dom element is the body
      if (document.activeElement !== document.body) {
        // if it's not then it sets the tabindex so it can be focused
        document.body.tabIndex = -1;
        // then sets the focus
        document.body.focus();
        // then removes the tabIndex attribute again
        document.body.removeAttribute('tabIndex');
      }
    });

    this.coreService.listenForMessages();
    Config.set({
      clientId: this.configService.get('forgerock.webOauthClient'), // e.g. 'ForgeRockSDKClient'
      redirectUri: this.configService.get('forgerock.redirectUri'), // Redirect back to your app, e.g. 'https://sdkapp.example.com:8443'
      scope: this.configService.get('forgerock.scope'), // e.g. 'openid profile email address phone me.read'
      serverConfig: {
        baseUrl: this.configService.get('forgerock.afiUrl'), // e.g. 'https://myorg.forgeblocks.com/am' or 'https://openam.example.com:8443/openam'
        timeout: this.configService.get('forgerock.timeout') // 3000 to 5000 is good, this impacts the redirect time to login
      },
      realmPath: this.configService.get('forgerock.realmPath') // e.g. 'alpha' or 'root'
    });

    this.eventDispatcherService.noDocumentModal$
      .pipe(
        takeUntil(this.stop$),
        tap(action => {
          this.noDocumentMessage = this.getNoDocumentMessage(action);
          this.modal.open(this.noDocumentModalId);
          console.log(action);
        })
      )
      .subscribe();

    this.eventDispatcherService.logout$
      .pipe(
        takeUntil(this.stop$),
        tap(async () => {
          await this.backToLogin();
        })
      )
      .subscribe();
  }

  async backToLogin() {
    const experienceId = await firstValueFrom(this.store.select(BrandSelectors.selectExperienceId));
    if (this.featureService.isEnabled('forgerock')) {
      await FRUser.logout();
      this.forgerockAuthService
        .logout()
        .pipe(takeUntil(this.stop$))
        .subscribe(() => {
          window.location.href = window.location.origin + '/' + experienceId;
        });
    } else {
      this.authService
        .logout()
        .pipe(takeUntil(this.stop$))
        .subscribe(() => {
          window.location.href = window.location.origin + '/' + experienceId;
        });
    }
  }

  closeNoDocumentModal() {
    this.modal.close(this.noDocumentModalId);
  }

  getNoDocumentMessage(action = 'download') {
    return `We're unable to ${action} your policy document at this time. Please try again later`;
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
    this.spinner.start();
  }
}
