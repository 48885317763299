<div class="row">
  <div class="content">
    <h1 data-cy="resetPasswordHeaderText" content [contentPath]="'auth.resetPasswordHeader'"></h1>
    <p content [contentPath]="'auth.resetPasswordCreate'"></p>
    @if (userIdentifier) {
      <accept-password-input
        (onComplete)="onComplete($event)"
        [componentUsernameInput]="userIdentifier"
        [errorText]="resetPasswordErrorText"
      ></accept-password-input>
    }
    @if (correlationId) {
      <accept-password-input
        (onComplete)="onComplete($event)"
        [errorText]="resetPasswordErrorText"
      ></accept-password-input>
    }
  </div>
</div>
