export class PartyEmail {
  readonly emailId?: string;
  emailAddress: string;
  primaryIndicator?: boolean | string;
  setPrimaryEmail?: boolean;
  contactMethodUsages: UsageCode[];
  specialInstructions?: string;

  constructor(email: string, type: PartyEmailUsageCode, typeDesc?: string, makePrimary?: boolean) {
    this.emailAddress = email;
    this.contactMethodUsages = [new UsageCode(type, typeDesc)];
    this.setPrimaryEmail = makePrimary;
  }
}

export class UsageCode {
  typeOfUsageCode: PartyEmailUsageCode;
  descriptionForOtherType?: string;

  constructor(type: PartyEmailUsageCode, typeDesc?: string) {
    this.typeOfUsageCode = type;
    if (typeDesc) {
      this.descriptionForOtherType = typeDesc;
    }
  }
}

type PartyEmailUsageCode =
  | 'MYACCOUNT'
  | 'HOME'
  | 'WORK'
  | 'SCHOOL'
  | 'OTHER'
  | 'BUSINESS'
  | 'EDELIVERY'
  | 'EMERGENCY';
