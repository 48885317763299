/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ReviewItemConfig } from '@amfam/billing/auto-pay/ui';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { PaymentMethodSelectors } from '@amfam/billing/payment-method/data-access';
import {
  PaymentMethodAccountType,
  PaymentMethodUtilService
} from '@amfam/billing/payment-method/util';
import {
  PaymentTermsAndConditionsPartnerContent,
  PaymentTermsAndConditionsType
} from '@amfam/billing/shared/ui';
import { MaeAnalytics, MaeFeatureActions, MaeFeatureSelectors } from '@amfam/mae/feature';
import {
  AnalyticsFacade,
  AutomaticPaymentsAnalytics,
  AutomaticPaymentsAnalyticsAdmin,
  EventAnalyticParams
} from '@amfam/shared/analytics';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import {
  Applications,
  ApplicationService,
  CopyService
} from '@amfam/shared/utility/shared-services';
import { DockingBarService, DsModalService } from '@amfam/ui-kit';

import * as AutoPaySetupActions from '../../+state/auto-pay-setup.actions';
import { AutoPaySetup, AutoPaySteps } from '../../+state/auto-pay-setup.models';
import * as AutoPaySetupSelectors from '../../+state/auto-pay-setup.selectors';
import { AutoPayUtilService } from '../../util/auto-pay-util.service';

@Component({
  selector: 'ds-auto-pay-review-wrapper',
  templateUrl: './auto-pay-review-wrapper.component.html',
  styleUrls: ['./auto-pay-review-wrapper.component.scss']
})
export class AutoPayReviewWrapperComponent implements OnInit {
  autoPayReviewItems$: Observable<ReviewItemConfig[]>;
  reviewSettingsText: string;
  termsAndConditionsType$: Observable<PaymentTermsAndConditionsType>;
  isPartner$: Observable<boolean>;
  termsAndConditionsPartnerContent$: Observable<PaymentTermsAndConditionsPartnerContent>;
  authorizedToSubmit$: Observable<boolean>;
  adminEnrollmentFlow = false;
  isMyAccountAdmin: boolean;
  showTermsAndConditionsModal = false;
  tertiaryButtonName: string;

  constructor(
    private dockingBar: DockingBarService,
    private store: Store,
    private autoPayUtil: AutoPayUtilService,
    private modalService: DsModalService,
    private copyService: CopyService,
    private paymentMethodUtil: PaymentMethodUtilService,
    private roleService: ImpersonateRolesService,
    private analyticsFacade: AnalyticsFacade,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.isMyAccountAdmin = this.applicationService.isApp(Applications.MYACCOUNT_ADMIN);

    this.reviewSettingsText = this.copyService.getCopy('billing.autoPayRefactor.reviewSettings');

    this.autoPayReviewItems$ = this.store
      .select(AutoPaySetupSelectors.selectAllAutoPaySetups)
      .pipe(
        map((setups: AutoPaySetup[]) =>
          setups.map((setup: AutoPaySetup) => this.autoPayUtil.getReviewItem(setup, true))
        )
      );
    this.authorizedToSubmit$ = this.roleService.isAuthorized('makeautopayrule_submit_disable');

    this.termsAndConditionsType$ = this.getPaymentTermsAndConditionsType();
    this.isPartner$ = this.store.select(BrandSelectors.selectIsPartner);
    this.termsAndConditionsPartnerContent$ = this.getPaymentTermsAndConditionsPartnerContent();
    if (this.isMyAccountAdmin) {
      this.store
        .select(MaeFeatureSelectors.isEnrollmentWorkFlow)
        .pipe(take(1))
        .subscribe(enrollmentFlow => {
          this.adminEnrollmentFlow = enrollmentFlow;
          if (!this.adminEnrollmentFlow) {
            this.dockingBar.registerHeading('Set Up AutoPay');
          }
        });
    } else {
      this.dockingBar.registerHeading('Set Up AutoPay');
    }
    if (this.isMyAccountAdmin) {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsConfirm
      );
    } else {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalytics.pageMultipleAutomaticPaymentsConfirm
      );
    }

    this.store
      .select(AutoPaySetupSelectors.selectIsUserComingFromEnrollment)
      .pipe(take(1))
      .subscribe(isUserComingFromEnrollment => {
        this.tertiaryButtonName = isUserComingFromEnrollment ? 'Skip AutoPay Setup' : 'Cancel';
      });
  }

  getPaymentTermsAndConditionsType(): Observable<PaymentTermsAndConditionsType> {
    return combineLatest([
      this.store.select(AutoPaySetupSelectors.selectAllAutoPaySetups),
      this.store.select(PaymentMethodSelectors.selectPaymentMethods)
    ]).pipe(
      map(([autoPaySetups, paymentMethods]) => {
        const paymentMethodTypes: PaymentMethodAccountType[] = autoPaySetups.map(setup =>
          this.paymentMethodUtil.getPaymentMethodAccountType(
            paymentMethods.find(
              paymentMethod =>
                paymentMethod.nickName === setup.autoPaySettings.paymentAccountNickName.value
            )
          )
        );
        return (paymentMethodTypes.includes(PaymentMethodAccountType.CHECKING) ||
          paymentMethodTypes.includes(PaymentMethodAccountType.SAVINGS)) &&
          paymentMethodTypes.includes(PaymentMethodAccountType.CREDIT_DEBIT)
          ? PaymentTermsAndConditionsType.AUTOPAY_BOTH
          : paymentMethodTypes.includes(PaymentMethodAccountType.CHECKING) ||
              paymentMethodTypes.includes(PaymentMethodAccountType.SAVINGS)
            ? PaymentTermsAndConditionsType.AUTOPAY_BANK
            : paymentMethodTypes.includes(PaymentMethodAccountType.CREDIT_DEBIT)
              ? PaymentTermsAndConditionsType.AUTOPAY_CARD
              : PaymentTermsAndConditionsType.AUTOPAY_BOTH;
      })
    );
  }

  getPaymentTermsAndConditionsPartnerContent() {
    return this.store.select(BrandSelectors.selectPaymentTermsAndConditionsPartnerContent);
  }

  saveAndFinish(): void {
    if (this.isMyAccountAdmin) {
      this.showTermsAndConditionsModal = true;
      this.analyticsFacade.trackPage(
        MaeAnalytics.pageAgentProgramEnrollmentMultipleAutomaticPaymentsAgreement
      );
    } else {
      this.save();
    }
  }

  edit(billAccountNumber: string) {
    this.store.dispatch(AutoPaySetupActions.editAutoPaySetup({ billAccountNumber }));
  }

  cancel() {
    this.modalService.open('autoPayCancelModal');
    if (this.isMyAccountAdmin) {
      this.analyticsFacade.trackButtonClick(
        AutomaticPaymentsAnalyticsAdmin.buttonConfirmSkipAutoPaySetup
      );
    } else {
      this.analyticsFacade.trackButtonClick(
        AutomaticPaymentsAnalytics.buttonConfirmSkipAutoPaySetup
      );
    }
  }

  back() {
    this.store.dispatch(AutoPaySetupActions.previousStep());
  }

  skipAutopay() {
    this.store.dispatch(AutoPaySetupActions.setStep({ step: AutoPaySteps.SKIP }));
    this.store.dispatch(MaeFeatureActions.skipAutoPay());
  }

  agreeTermsAndConditionsEvent(agree: boolean): void {
    if (agree) {
      this.save();
    }
  }

  trackGA(setups, allBillAccounts, paymentMethods) {
    setups.forEach(setup => {
      // Using the billAccountNumber find the product type
      const productType = allBillAccounts.find(
        billAccount => billAccount.billAccountNumber === setup.billAccount.billAccountNumber
      ).policyTypeDisplayName;
      // Find the payment_term
      const paymentTerm = setup.autoPaySettings.paymentAmount.value;
      // Find the payment type
      const paymentMethod = paymentMethods[setup.autoPaySettings.paymentMethodId];

      let paymentType;
      if (Object.keys(paymentMethod).includes('creditCard')) {
        paymentType = 'debit-credit';
      } else if (Object.keys(paymentMethod).includes('achWithdrawal')) {
        paymentType = paymentMethod.achWithdrawal.accountType;
      }

      const autoPaySignUpAttemptEvent: EventAnalyticParams = {
        event: 'autopay_signup_attempt',
        product_line: productType, // auto, home, atv, boat.
        payment_term: paymentTerm, // min due, full balance // This is available
        payment_type: paymentType // checking/savings, debit, credit
      };

      this.analyticsFacade.trackEvent(autoPaySignUpAttemptEvent);
    });
  }

  save(): void {
    combineLatest([
      this.store.select(AutoPaySetupSelectors.selectAllAutoPaySetups),
      this.store.select(AutoPaySetupSelectors.selectContext),
      this.store.select(BillAccountsSelectors.selectAllBillAccounts),
      this.store.select(PaymentMethodSelectors.selectPaymentMethodEntities)
    ])
      .pipe(take(1))
      .subscribe(([setups, context, allBillAccounts, paymentMethods]) => {
        this.trackGA(setups, allBillAccounts, paymentMethods);
        this.autoPayUtil.saveAutoPay(context, setups);
      });
  }
}
