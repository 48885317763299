import { AnalyticsFacade } from '@amfam/shared/analytics';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

import { ANSRequest } from '@amfam/opportunities';
import {
  ANSRequestModel,
  AutoCoveragesModel,
  EligibilityEnum,
  MessageDetail,
  MessageModel,
  NotificationRequestInternalEmail,
  OldReplaceVehicleQuoteApiRequest,
  QuoteModel,
  ReplaceVehicleAnalyticsModel,
  ReplaceVehicleNotifyApiRequest,
  ReplaceVehicleNotifyApiResponse,
  ReplaceVehicleQuoteApiRequest,
  ReplaceVehicleQuoteApiResponse,
  ReplaceVehicleRequestCustomerEmail,
  RiskModel,
  SubmitOnlineBindRequestModel
} from '@amfam/policy/models';
import { PrettyPolicyNum } from '@amfam/policy/pipes';
import { ApiStatus } from '@amfam/shared/models';
import {
  Addressee,
  CommunicationService,
  ConfigService,
  CopyService,
  EventAnalytic,
  InternalEmail,
  PageAnalytic,
  TemplatedEmail
} from '@amfam/shared/utility/shared-services';
import { get as _get, omit as _omit } from 'lodash';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
// Store
import { PolicyUtilService } from '@amfam/policy/e-signature/data-access';
import {
  CoverageChangeCustomerTemplateDataModel,
  CoverageChangeModel,
  CoverageChangeQuoteResponseModel
} from '@amfam/policy/models/src/lib/models/auto-coverages.model';
import { userQuery } from '@amfam/shared/user';
import { UtilService } from '@amfam/shared/utility/shared-services';
import { Store } from '@ngrx/store';
import addDays from 'date-fns/add_days';
import * as fromRoot from '../../../core/store';
import { ChangeCoverageAnalyticsModel } from '../../../policy/models/coverage-change.model';
import { ContentService } from '../../content';
import { Content } from '../../content/content.model';

@Injectable()
export class ReplaceVehicleService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private communicationService: CommunicationService,
    private copyService: CopyService,
    private store: Store<fromRoot.RootState>,
    private utilService: UtilService,
    private analyticsFacade: AnalyticsFacade,
    private policyUtilService: PolicyUtilService,
    private contentService: ContentService
  ) {}

  public getPremiumEstimate(
    model: ReplaceVehicleQuoteApiRequest | OldReplaceVehicleQuoteApiRequest
  ): Observable<ReplaceVehicleQuoteApiResponse> {
    const uri = this.getAutoWhatIfUrl() + 'autopolicies/' + model.policyNumber + '/quotes';
    return this.http.post<ReplaceVehicleQuoteApiResponse>(uri, _omit(model, ['policyNumber']));
  }

  public submitOnlineBind(model: SubmitOnlineBindRequestModel) {
    const uri = this.getAutoWhatIfUrl() + 'autoquotes/' + model.workOrderNumber + '/submit';
    return this.http.post<ApiStatus>(uri, _omit(model, ['workOrderNumber', 'vehicleId']));
  }

  public notifyAgent(
    model: ReplaceVehicleNotifyApiRequest
  ): Observable<ReplaceVehicleNotifyApiResponse> {
    const uri = this.getAutoWhatIfUrl() + 'autopolicies/' + model.policyNumber + '/quotes';
    return this.http.post<ReplaceVehicleNotifyApiResponse>(uri, model.payload);
  }

  public ansNotification(model: ANSRequestModel): Observable<ReplaceVehicleNotifyApiResponse> {
    const uri = this.getAutoWhatIfUrl() + 'autopolicies/' + model.orderNumber + '/submit';
    return this.http.post<ReplaceVehicleNotifyApiResponse>(
      uri,
      _omit(model, ['orderNumber', 'vehicleId'])
    );
  }

  /**
   * @author Abhishek Singh
   * @param ANSRequest
   * @returns Json response as part of the post call on the producer API
   * @description This function takes the model and sends it to the producer API which in turn posts the ANS notification
   */
  public ansNotificationForBind(model: ANSRequest): Observable<any> {
    const uri = this.config.get('producerApi') + 'producers/' + model.producerId + '/notifications';
    return this.http.post(uri, _omit(model, ['producerId']));
  }

  public buildANSNotificationPayload(
    producerId: string,
    policyNumber: string,
    notificationText: string,
    orderNumber: string,
    notificationType = 'ANSMYARPVQ'
  ) {
    return combineLatest(
      this.store.select(userQuery.getUserState),
      this.store.select(fromRoot.getAgentInformationByProducerId(producerId)),
      (user, agent) => {
        return {
          customer: user,
          agent: agent
        };
      }
    ).pipe(
      take(1),
      mergeMap(response => {
        const ansNotificationPayload: ANSRequest = {
          notificationsRequest: {
            notificationText: notificationText,
            notificationType: notificationType,
            customerName: response.customer.displayName,
            referenceNumber: orderNumber,
            formattedReferenceNumber: new PrettyPolicyNum().transform(policyNumber),
            policyNumber: policyNumber,
            from: 'DMSI',
            dueDate: addDays(new Date(), 2).toISOString(),
            createdBy: 'DMSI',
            uniqueId: this.utilService.generateId(),
            sourceSystem: 'MYACCOUNT'
          },
          producerId: response.agent.id
        };
        return of(ansNotificationPayload);
      })
    );
  }

  public generateInternalEmails(model: NotificationRequestInternalEmail): Observable<any> {
    return this.sendInternalEmail(this.buildInternalEmail(model));
  }

  public buildInternalEmail(model: NotificationRequestInternalEmail) {
    const email: InternalEmail = new InternalEmail();
    const agentEmail = _get(model, 'producer.agencyEmails[0]', _get(model, 'producer.emails[0]'));

    const internalAgentEmail = _get(model, 'producer.agencyEmails[0]')
      ? this.config.get('policyGracePeriodNotificatonInternalAgencyMailbox')
      : this.config.get('policyGracePeriodNotificatonInternal');

    const emailAddress = this.config.get('production') ? agentEmail : internalAgentEmail;

    const firstName = _get(model, 'producer.firstName', '');
    const lastName = _get(model, 'producer.lastName', '');

    email.messageFrom = new Addressee('policy_change_request@amfam.com', 'Policy Change Request');
    email.messageToList.push(new Addressee(emailAddress, firstName + lastName));

    email.messageSubject = this.getEmailSubject(model.type);

    email.messageContent = this.generateInternalEmailContent(model);
    return email;
  }

  private getEmailSubject(typeOfEmail: NotificationRequestInternalEmail['type']): string {
    if (typeOfEmail === 'No Quote' || typeOfEmail === 'Quote to agent') {
      return `Action Required: Your Customer Wants to Replace a Vehicle`;
    }
    return `Action Required: Replace a Vehicle Quote from My Account`;
  }

  private sendInternalEmail(email: InternalEmail) {
    return this.communicationService.sendInternalEmail(email);
  }

  public generateInternalEmailContent(model: NotificationRequestInternalEmail): string {
    const firstName = _get(model, 'producer.firstName', '');
    const agentInformation = this.getAgentEmailBody(model.type, model);
    let content = `Dear ${firstName},<br></br>`;
    content += agentInformation;
    content += `<br></br>Thank you,<br></br>The My Account Team`;
    content += `<br></br><hr/>`;
    return content;
  }

  private getAgentEmailBody(
    typeOfEmail: NotificationRequestInternalEmail['type'],
    model: NotificationRequestInternalEmail
  ): string {
    const email = _get(model, 'email', '');
    const customerName = _get(model, 'customerName', '');
    const policyNumber = _get(model, 'policyNumber', '');
    const newVehicle = _get(model, 'newVehicle', '');
    const oldVehicle = _get(model, 'oldVehicle', '');
    const orderNumber = _get(model, 'orderNumber', '');
    const isUsedForBusiness = _get(model, 'isUsedForBusiness', '');
    const isOwner = _get(model, 'isOwner', '');
    const hasTPI = _get(model, 'hasTPI', '');
    const copyTPIIndicator = _get(model, 'copyTPIIndicator', '');
    const existingTPI =
      copyTPIIndicator === 'Yes'
        ? _get(model, 'existingTPI', '')
        : this.copyService.getCopy('policy.policyChangeAgentFollowUpText');

    if (typeOfEmail === 'No Quote') {
      return this.copyService.getCopy('policy.agentEmailContentNoQuote', {
        customerName,
        policyNumber,
        newVehicle,
        oldVehicle,
        isUsedForBusiness,
        isOwner,
        hasTPI,
        existingTPI: hasTPI === 'Yes' ? existingTPI : 'Not Applicable'
      });
    }
    if (typeOfEmail === 'Quote to agent') {
      return this.copyService.getCopy('policy.agentEmailContentQuoteToAgent', {
        customerName,
        policyNumber,
        newVehicle,
        oldVehicle,
        isUsedForBusiness,
        isOwner,
        hasTPI,
        existingTPI: hasTPI === 'Yes' ? existingTPI : 'Not Applicable',
        orderNumber
      });
    }
    if (typeOfEmail === 'Quote' && hasTPI === 'Yes' && copyTPIIndicator === 'No') {
      return this.copyService.getCopy('policy.agentEmailContentQuoteTPIChangeNeeded', {
        customerName,
        policyNumber,
        newVehicle,
        oldVehicle,
        isUsedForBusiness,
        isOwner,
        hasTPI,
        existingTPI,
        orderNumber
      });
    }
    if (
      typeOfEmail === 'Quote' &&
      ((hasTPI === 'Yes' && copyTPIIndicator === 'Yes') || hasTPI === 'No')
    ) {
      return this.copyService.getCopy('policy.agentEmailContentQuoteNoTPIChangeNeeded', {
        customerName,
        policyNumber,
        newVehicle,
        oldVehicle,
        isUsedForBusiness,
        isOwner,
        hasTPI,
        existingTPI: hasTPI === 'No' ? 'Not Applicable' : existingTPI,
        orderNumber
      });
    }
    return this.copyService.getCopy('policy.agentInformation', {
      customerName: customerName,
      policyNumber: policyNumber,
      newVehicle: newVehicle,
      oldVehicle: oldVehicle,
      orderNumber: orderNumber,
      email: email
    });
  }

  public buildMessageDetails(messageModel: MessageModel) {
    const messageDetails: MessageDetail[] = new Array<MessageDetail>();
    messageDetails.push(new MessageDetail('FirstName', _get(messageModel, 'firstName', '')));
    messageDetails.push(new MessageDetail('PolicyNumber', _get(messageModel, 'policyNumber', '')));

    messageDetails.push(
      new MessageDetail('RiskInformation', _get(messageModel, 'riskInformation', ''))
    );
    messageDetails.push(
      new MessageDetail('TotalPremium', _get(messageModel, 'totalPremium', null))
    );
    messageDetails.push(
      new MessageDetail('MonthlyPremium', _get(messageModel, 'monthlyPremium', null))
    );
    messageDetails.push(
      new MessageDetail('TermDescription', _get(messageModel, 'termDescription', ''))
    );

    messageDetails.push(
      new MessageDetail('NewRiskInformation', _get(messageModel, 'newRiskInformation', ''))
    );
    messageDetails.push(
      new MessageDetail('NewTotalPremium', _get(messageModel, 'newTotalPremium', null))
    );
    messageDetails.push(
      new MessageDetail('NewMonthlyPremium', _get(messageModel, 'newMonthlyPremium', null))
    );
    messageDetails.push(
      new MessageDetail('NewTermDescription', _get(messageModel, 'newTermDescription', ''))
    );
    messageDetails.push(
      new MessageDetail('QuoteExpirationDate', _get(messageModel, 'quoteExpirationDate', ''))
    );

    messageDetails.push(new MessageDetail('Drivers', _get(messageModel, 'drivers', '')));
    messageDetails.push(
      new MessageDetail('GaragedAddress', _get(messageModel, 'garagedAddress', ''))
    );
    messageDetails.push(new MessageDetail('Coverages', _get(messageModel, 'coverages', '')));
    messageDetails.push(new MessageDetail('Discounts', _get(messageModel, 'discounts', '')));
    messageDetails.push(new MessageDetail('Fees', _get(messageModel, 'fees', '')));
    messageDetails.push(
      new MessageDetail('WorkOrderNumber', _get(messageModel, 'orderNumber', ''))
    );
    messageDetails.push(new MessageDetail('ProdID', _get(messageModel, 'producerId', '')));
    return messageDetails;
  }

  public buildMessageDetailsForCoverageChangeEmail(
    messageModel: CoverageChangeCustomerTemplateDataModel
  ) {
    const messageDetails: MessageDetail[] = new Array<MessageDetail>();
    messageDetails.push(new MessageDetail('FirstName', _get(messageModel, 'firstName', '')));
    messageDetails.push(new MessageDetail('PolicyNumber', _get(messageModel, 'policyNumber', '')));
    messageDetails.push(new MessageDetail('ProdID', _get(messageModel, 'ProdID', '')));
    messageDetails.push(
      new MessageDetail('CurrentCoverage', _get(messageModel, 'CurrentCoverage', ''))
    );
    messageDetails.push(
      new MessageDetail('CoverageChanges', _get(messageModel, 'CoverageChanges', ''))
    );
    messageDetails.push(new MessageDetail('Vehicle', _get(messageModel, 'Vehicle', '')));
    return messageDetails;
  }

  generateCustomerEmail(
    model: ReplaceVehicleRequestCustomerEmail,
    messageDetails: MessageDetail[],
    templateID: string,
    accountId?: string
  ): Observable<any> {
    const email = new TemplatedEmail(
      templateID,
      model.partnerId,
      'MyAccount',
      'EN',
      model.customerId,
      model.customerEmailId,
      messageDetails,
      accountId
    );
    return this.communicationService.sendTemplatedEmail(email);
  }

  /**
   * Eligibility Rules
   * 1. The vehicle that's being replaced must be of the vehicle type: Car/ Light Truck
   * 2. The vehicle that's being replaced must have a primary use other than Antique/Classic/Replica
   * 3. Replace a Vehicle Quote is accessible for customers in all Advance auto policy contract states, except Arizona
   */
  public getAdvancePolicyEligibility(risk: RiskModel): boolean {
    if (
      risk &&
      _get(risk, 'vehicleTypeName') === EligibilityEnum.vehicleTypeName &&
      _get(risk, 'vehicleUseDescription') !== EligibilityEnum.vehicleUseDescription
    ) {
      return true;
    }
    return false;
  }

  private getAutoWhatIfUrl(): string {
    return this.config.get('autoWhatIfApi');
  }

  /**
   * @author Abhishek Singh
   * @param
   * @returns
   * @description Send the analytics for the replace a vehicle actions.
   */
  public sendAnalytics(analyticPayload: ReplaceVehicleAnalyticsModel): void {
    const pageObj: PageAnalytic = {
      pageName: 'MyAccount:Policies:ReplaceVehicle:' + analyticPayload.pageAnalyticName,
      experience: '',
      primaryCategory: 'MyAccount',
      subCategory1: 'Replace Vehicle',
      subCategory2: '',
      subCategory3: ''
    };
    const eventObj: EventAnalytic = {
      eventName: 'Replace Vehicle',
      eventStep: analyticPayload.eventName,
      replaceVehicle: {
        referenceNumber: analyticPayload.referenceNumber,
        agentName: analyticPayload.agentName
      }
    };
    if (analyticPayload.buttonAnalyticName) {
      return this.analyticsFacade.trackButtonClick({ link: analyticPayload.buttonAnalyticName });
    } else {
      if (analyticPayload.eventName) {
        return this.analyticsFacade.trackPageAndEvent(pageObj, eventObj);
      } else {
        return this.analyticsFacade.trackPage(pageObj);
      }
    }
  }

  /**
   * @author Abhishek Singh
   * @param None
   * @returns An observable of payload contaning the coverages limits for the auto policies.
   * @description This function call the site core to get the coverages limits for the auto policies.
   */
  public getCoveragesLimits(): Observable<AutoCoveragesModel> {
    const failsafeDeductibleLimits: AutoCoveragesModel =
      this.copyService.getCopy('policy.deductibleLimits');

    return this.contentService.getContent('AutoCoverages').pipe(
      map((response: Content) => this.formatCoveragesResponse(response.richContent)),
      catchError(() => of(failsafeDeductibleLimits))
    );
  }

  /**
   *
   * @author Abhishek Singh
   * @param coveragesResponse
   * @description Sitecore returns the response in string with undesired html tags embedded , we remove them and parse before converting to JSON values
   */
  private formatCoveragesResponse(coveragesResponse: string): AutoCoveragesModel {
    return JSON.parse(coveragesResponse.replace(/&nbsp;|<span>|<\/span>/g, ''));
  }

  /**
   * @author Abhishek Singh
   * @param model
   * @description This function would make a call to the api and get the premium estimate based on the coverage selected by the user.
   * We then adapt the response to return the quotemodel
   */
  public getCoveragePremiumEstimate(model: CoverageChangeModel): Observable<QuoteModel> {
    const uri =
      this.getAutoWhatIfUrl() + 'autopolicies/' + model.policyNumber + '/quotecoveragechange';
    return this.http
      .post<CoverageChangeQuoteResponseModel>(
        uri,
        _omit(model, ['policyNumber', 'vehicleIdentifier'])
      )
      .pipe(map(response => this.policyUtilService.reformQuoteResponse(response)));
  }

  public sendAgenEmailForCoverageChange(model: CoverageChangeCustomerTemplateDataModel) {
    const email: InternalEmail = new InternalEmail();
    const agentEmail = _get(model, 'producer.agencyEmails[0]', _get(model, 'producer.emails[0]'));

    const internalAgentEmail = _get(model, 'producer.agencyEmails[0]')
      ? this.config.get('policyGracePeriodNotificatonInternalAgencyMailbox')
      : this.config.get('policyGracePeriodNotificatonInternal');

    const emailAddress = this.config.get('production') ? agentEmail : internalAgentEmail;

    const firstName = _get(model, 'producer.firstName', '');
    const lastName = _get(model, 'producer.lastName', '');

    email.messageFrom = new Addressee('policy_change_request@amfam.com', 'Policy Change Request');
    email.messageToList.push(new Addressee(emailAddress, firstName + lastName));
    email.messageSubject =
      'Action Required: Your Customer Is Requesting Changes to Their Auto Policy';
    const agentInformation = this.copyService.getCopy('policy.agentEmailForCoverageChange', {
      customerName: firstName,
      policyNumber: model.policyNumber,
      vehicleInfo: model.Vehicle,
      coverageChanges: model.CoverageChanges,
      orderNumber: model.orderNumber
    });

    email.messageContent = this.getAgentEmailContentForCoverageChange(
      model,
      'policy.agentEmailForCoverageChange',
      firstName
    );
    return this.sendInternalEmail(email);
  }

  public getAgentEmailContentForCoverageChange(
    model: CoverageChangeCustomerTemplateDataModel,
    path: string,
    firstName: string
  ): string {
    const agentInformation = this.copyService.getCopy(path, {
      customerName: model.firstName,
      policyNumber: model.policyNumber,
      vehicleInfo: model.Vehicle,
      coverageChanges: model.CoverageChanges,
      orderNumber: model.orderNumber
    });

    let content = `Dear ${firstName},<br></br>`;
    content += agentInformation;
    content += `<br></br>Thank you,<br></br>The My Account Team`;
    content += `<br></br><hr/>`;
    return content;
  }

  /**
   * @author Abhishek Singh
   * @param analyticPayload: Payload for sending the analytics.
   * @returns
   * @description Send the analytics for the coverage changeactions.
   */
  public sendCoverageChangeAnalytics(analyticPayload: ChangeCoverageAnalyticsModel): void {
    const pageObj: PageAnalytic = {
      pageName: 'MyAccount:Policies:ChangeCoverage:' + analyticPayload.pageAnalyticName,
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Policies',
      subCategory2: '',
      subCategory3: ''
    };
    const eventObj: EventAnalytic = {
      eventName: 'Coverage Change',
      eventStep: analyticPayload.eventName,
      changeCoverageInfo: {
        selection: analyticPayload.selection,
        RRAmount: analyticPayload.rraAmount
      }
    };
    if (analyticPayload.buttonAnalyticName) {
      return this.analyticsFacade.trackButtonClick({ link: analyticPayload.buttonAnalyticName });
    } else {
      if (analyticPayload.eventName) {
        return this.analyticsFacade.trackPageAndEvent(pageObj, eventObj);
      } else {
        return this.analyticsFacade.trackPage(pageObj);
      }
    }
  }
}
