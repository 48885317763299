import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounce } from 'lodash';

import { DriverDefaults } from '@amfam/claim/dynamic-fnol/data-access';
import { FnolRiskModel, PolicyTypeIconConstants, RiskModel } from '@amfam/policy/models';

@Component({
  selector: 'ds-risk-list',
  templateUrl: './risk-list.component.html',
  styleUrls: ['./risk-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskListComponent implements OnInit {
  @Input() risks: Array<RiskModel | FnolRiskModel> = new Array<RiskModel | FnolRiskModel>();
  @Input() preSelectedRisk: RiskModel | FnolRiskModel;
  @Input() otherLabel = '';
  @Input() otherContent = '';
  @Input() showPolicyNumber = true;
  @Input() vehicleLabel;
  @Input() showIcon = true;
  @Output() riskChange = new EventEmitter();

  currentRisk: RiskModel | FnolRiskModel;
  currentRiskId = '';
  riskSelectionForm: UntypedFormGroup;
  riskSelectionCtrl: AbstractControl;
  driverDefaults = DriverDefaults;
  riskIcons = PolicyTypeIconConstants;

  // debounce since we have click/change event handlers that both fire on click.
  toggleRisk = debounce((riskId: string) => {
    this.riskSelectionCtrl.setValue(riskId);
    this.currentRisk = this.risks.find(risk => risk.id === riskId);
    this.currentRiskId = this.currentRisk?.id ?? riskId;
    this.riskChange.emit(riskId);
  }, 10);

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.riskSelectionForm = this.formBuilder.group({
      riskSelection: ['', Validators.compose([Validators.required])]
    });
    this.riskSelectionCtrl = this.riskSelectionForm.controls['riskSelection'];
    /**
     * If there is single risk, preselect it.
     */
    if (this.risks.length === 1) {
      this.toggleRisk(this.risks[0].id);
    }

    /**
     * If a risk needs to be preselected.
     */
    if (this.preSelectedRisk) {
      this.toggleRisk(this.preSelectedRisk.id);
    }
  }
}
