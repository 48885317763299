import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { AccountTypeEnum, userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { PageAnalytic } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService, ToasterService } from '@amfam/ui-kit';

import { ENROLLMENT_TYPE } from '../../../../../app/enrollment/enrollment-type.model';
import * as fromRoot from '../../../../core/store/';
import * as enrollUserActions from '../../../../core/store/enrollment/enroll-user.actions';
import * as enrollStepsActions from '../../../../core/store/enrollment/steps/enroll-steps.actions';
import * as workflowActions from '../../../../core/store/workflow';
import { stepNames } from '../../../../core/store/workflow/workflow.model';
import { VerifyTokenModel } from '../../../shared/verify-token.model';
import { VerifyUserModel } from '../../../shared/verify-user.model';
import { VerificationService } from '../verification.service';

@Component({
  selector: 'ds-enroll-verify-email',
  templateUrl: './enroll-verify-email.component.html',
  styleUrls: ['../../../enrollment.component.scss']
})
export class EnrollVerifyEmailComponent implements OnInit, OnDestroy {
  isButtonActive = false;
  programPathContext: string;
  gotError = false;
  emailList: any = [];
  email: string;
  partyId: string;
  showEmailOptions = true;
  showEmailSent = false;
  showOTP = false;
  codeNotFound = false;
  private typeOfAccountCode: string;
  private experienceId: string;
  private partnerId: string;
  private enrollType: string;
  private programEnrollment = false;
  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic;
  private trackAnalytic: PageAnalytic;
  private conversionPageAnalytic: PageAnalytic;

  private stop$: Subject<void> = new Subject<void>();
  forgerockEnabled = this.featureFlagService.isEnabled('forgerock');

  constructor(
    private store: Store<fromRoot.RootState>,
    private verifyBy: VerificationService,
    private toasterService: ToasterService,
    private spinner: LoadingSpinnerService,
    private activeRoute: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    this.enrollType = this.activeRoute.parent.parent.routeConfig.data.enrollType;
    this.pageAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:Email',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'Confirm Identity:Email'
    };
    this.trackAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:EmailSent',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'Confirm Identity:Email'
    };
    this.conversionPageAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:EmailSent',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Add Personal Line',
      subCategory2: '',
      subCategory3: ''
    };
    // **** End of Analytics data for this component
    if (
      this.featureFlagService.isEnabled('token_based_enrollment') &&
      this.enrollType === ENROLLMENT_TYPE.TOKEN
    ) {
      this.programEnrollment = true;
      const token = _get(this.activeRoute, 'parent.parent.snapshot.params.tokenId', '');

      // JP: Safe check on token. If token is missing, this will break email link that's sent, so route user to error page
      if (token === '') {
        this.store.dispatch(
          fromRouterActions.Go({
            path: ['/error']
          })
        );
      }
      // JP: Context is mapped to token/:tokenId for reference to link back into token enrollment
      this.programPathContext =
        'token/' + _get(this.activeRoute, 'parent.parent.snapshot.params.tokenId', '');
    }

    combineLatest(
      this.store.select(fromRoot.getEnrollUserState),
      this.store.select(userQuery.getTypeOfAccountCode),
      this.store.select(BrandSelectors.selectExperienceId),
      this.store.select(BrandSelectors.selectPartnerId),
      (enrollUserState, typeOfAccountCode, experienceId, partnerId) => {
        return {
          enrollUserState: enrollUserState,
          typeOfAccountCode: typeOfAccountCode,
          experienceId: experienceId,
          partnerId: partnerId
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        this.experienceId = state.experienceId;
        this.partnerId = state.partnerId;
        this.emailList = state.enrollUserState.maskedEmails;
        this.partyId = state.enrollUserState.partyId;
        this.typeOfAccountCode = state.typeOfAccountCode;
        // Analytics for conversion
        if (this.typeOfAccountCode && this.typeOfAccountCode !== AccountTypeEnum.Customer) {
          this.analyticsFacade.trackPage(this.conversionPageAnalytic);
        } else {
          this.analyticsFacade.trackPage(this.pageAnalytic);
        }
      });
  }

  public sendEmail(email: string) {
    this.spinner.start({ blockActions: true });
    this.email = email;
    this.isButtonActive = false;

    const requestObj: VerifyUserModel = new VerifyUserModel(
      this.partyId,
      this.forgerockEnabled ? 'EMAIL OTP' : 'EMAIL',
      email,
      this.programPathContext,
      this.experienceId,
      this.partnerId,
      this.programEnrollment
    );

    this.verifyBy
      .email(requestObj)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        res => {
          // TODO - pull this from a message service
          this.spinner.stop();
          if (!res) {
            return;
          }
          const emailMsg = 'We will be sending you an email shortly';
          if (res) {
            this.toasterService.pop('success', emailMsg);
            this.showEmailOptions = false;
            this.showOTP = this.forgerockEnabled;
            this.showEmailSent = !this.forgerockEnabled;
            this.gotError = false;
          } else {
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/enroll/contact-us']
              })
            );
          }
          this.isButtonActive = true;
          this.analyticsFacade.trackPage(this.trackAnalytic);
        },
        err => {
          this.spinner.stop();
          if (!err) {
            return;
          }
          this.gotError = true;
          const emailErr = 'We had a trouble sending you an email';
          this.toasterService.pop('error', emailErr);
          this.showEmailOptions = true;
          this.showEmailSent = false;
          this.showOTP = false;
          this.isButtonActive = true;
        }
      );
  }

  verifyCode(pin: string) {
    this.spinner.start({ blockActions: true });
    const requestObj: VerifyTokenModel = new VerifyTokenModel(
      pin,
      this.forgerockEnabled ? 'EMAIL OTP' : 'EMAIL',
      this.partyId
    );

    this.verifyBy
      .pin(requestObj)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        (res: any) => {
          this.spinner.stop();
          if (!res) {
            return;
          }
          const statusCode = res.status && res.status.code ? res.status.code : 500;
          if (statusCode === 200) {
            this.verifyBy.resetVerifyAttempts();
            this.toasterService.pop('success', 'Your code has been verified');
            this.store.dispatch(
              new enrollUserActions.SubmitVerificationAction({
                verificationType: 'email',
                partyId: this.partyId
              })
            );
            this.store.dispatch(
              new enrollStepsActions.VerifyStepAction({
                verificationStep: true
              })
            );

            // complete the step
            this.store.dispatch(
              new workflowActions.WorkflowStepCompleteAction({ step: stepNames.verification })
            );
          } else {
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/enroll/contact-us']
              })
            );
          }
        },
        err => {
          this.spinner.stop();
          if (!err) {
            return;
          }
          const errCode = err.status && err.status.code ? err.status.code : 500;
          if (errCode === 404) {
            this.verifyBy.incrementAttempts();
            this.codeNotFound = true;
          } else {
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/enroll/contact-us']
              })
            );
          }
        }
      );
  }

  goBackOneStep() {
    this.store.dispatch(new workflowActions.WorkflowStepBackAction());
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
