/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { OAuth2Tokens, TokenStorage } from '@forgerock/javascript-sdk';
import { Store } from '@ngrx/store';
import { firstValueFrom, of } from 'rxjs';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ConfigService, CopyService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { forgerockLogin } from '../store/session/forgerock-session.actions';
import { ForgerockAuthService } from './forgerock-auth.service';

@Injectable()
export class ForgerockAuthGuard {
  private emailValidationPath: string;

  constructor(
    private store: Store,
    private config: ConfigService,
    private router: Router,
    private forgerockAuthService: ForgerockAuthService,
    private featureFlagService: FeatureFlagService,
    private analyticsFacade: AnalyticsFacade,
    private spinner: LoadingSpinnerService,
    private copyService: CopyService
  ) {
    this.emailValidationPath = this.config.get('profileValidateEmailPath');
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.featureFlagService.isEnabled('forgerock')) {
      return of(true);
    }
    const url = state.url;
    return this.checkAuth(url);
  }

  canLoad(route: Route) {
    const url = route.path;
    return this.checkAuth(url);
  }

  private async checkAuth(url: string) {
    // redirect to intended URL after logging in
    this.forgerockAuthService.redirectUrl = url;
    if (this.forgerockAuthService.isLoggedIn()) {
      return Promise.resolve(true);
    }
    // If altAuth verified, return true to allow email validation
    if (this.forgerockAuthService.isAltAuthVerified) {
      if (url === '/profile') {
        return of(this.sendToLogin(url));
      }
      this.forgerockAuthService.redirectUrl = '/';
      return of(true);
    }

    try {
      const authenticated = await firstValueFrom(this.forgerockAuthService.refresh());
      if (authenticated) {
        switch (localStorage.getItem('frFlowType')) {
          case 'change-password':
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/profile'],
                query: { openAccordion: 'security' }
              })
            );
            break;

          case 'enrollment':
            this.store.dispatch(fromRouterActions.Go({ path: ['/paperless/sign-up'] }));
            break;

          default:
        }

        return Promise.resolve(true);
      } else {
        if (url.includes(this.emailValidationPath)) {
          return this.sendToEmailValidation(url);
        }
      }
    } catch (error) {
      if (url.includes(this.emailValidationPath)) {
        return this.sendToEmailValidation(url);
      }
    }

    let tokens;
    try {
      tokens = await this.forgerockAuthService.getTokens();

      if (tokens) {
        this.analyticsFacade.trackEvent({
          event: 'authenticate_sign_in_success'
        });
        return this.dispatchToAuthenticate(tokens as OAuth2Tokens);
      } else {
        tokens = await TokenStorage.get();
        const tokenExpiryDate = new Date((tokens as OAuth2Tokens)?.tokenExpiry);
        if (new Date() > tokenExpiryDate) {
          TokenStorage.remove();
        }

        if (tokens) {
          this.analyticsFacade.trackEvent({
            event: 'authenticate_sign_in_success'
          });
          return this.dispatchToAuthenticate(tokens as OAuth2Tokens);
        } else {
          // User likely not authenticated
          this.analyticsFacade.trackEvent({
            event: 'authenticate_sign_in_failure'
          });
          if (url.includes('/overview')) {
            return this.sendToForgerockLogin(url);
          } else {
            return this.sendToLogin(url);
          }
        }
      }
    } catch (err) {
      this.analyticsFacade.trackEvent({
        event: 'secure_sign_in_failure',
        error_code: 'NOAUTH'
      });
      return this.sendToLogin(url);
    }
  }

  private sendToLogin(url: string): boolean {
    this.setPendingRedirect(url);
    this.forgerockAuthService.redirectUrl = url;
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/login']
      })
    );
    return false;
  }
  private sendToEmailValidation(url: string): boolean {
    this.forgerockAuthService.redirectUrl = url;

    localStorage.setItem('emailTokenUrl', url);

    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/email-validation']
      })
    );
    return false;
  }
  private dispatchToAuthenticate(tokens: OAuth2Tokens): boolean {
    this.analyticsFacade.trackEvent({
      event: 'authenticate_sign_in_start'
    });
    this.spinner.start();
    this.store.dispatch(
      forgerockLogin({ accessToken: tokens.accessToken, idToken: tokens.idToken })
    );
    return true;
  }

  private setPendingRedirect(url: string) {
    const deepLinks: string[] = this.copyService.getCopy('auth.deepLinks');
    const key = this.copyService.getCopy('auth.pendingRedirectKey');
    const isDeepLink = deepLinks.some(deepLink => url.includes(deepLink));
    if (isDeepLink) {
      sessionStorage.setItem(key, url);
    }
  }
  private async sendToForgerockLogin(url) {
    this.spinner.start();
    this.forgerockAuthService.redirectUrl = url;
    const acrValues = this.config.get('forgerock.login');
    await this.forgerockAuthService.getTokens(acrValues, false);
  }
}
