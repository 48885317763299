import { createAction } from '@ngrx/store';

export enum SummariesActionTypes {
  LoadSummariesType = '[Summaries] Load Summaries',
  SummariesLoadedType = '[Summaries] Summaries Loaded',
  SummariesLoadSuccessType = '[Summaries] Summaries Load Success',
  SummariesLoadErrorType = '[Summaries] Summaries Load Error',
  SummariesLoadStatusNotFoundType = '[Summaries] Load Status Not Found',
  UpdatePoiLinkType = '[Summaries Load CompleteEffect] Update Poi Link in Opertor Policy'
}

export const LoadSummaries = createAction(
  SummariesActionTypes.LoadSummariesType,
  (payload?: unknown) => ({ payload })
);

export const SummariesLoadSuccess = createAction(
  SummariesActionTypes.SummariesLoadSuccessType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload: any) => ({ payload })
);

export const SummariesLoadError = createAction(
  SummariesActionTypes.SummariesLoadErrorType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload: any) => ({ payload })
);

export const SummariesLoaded = createAction(
  SummariesActionTypes.SummariesLoadedType,
  // TODO: AS: Type this later
  (payload: unknown) => ({ payload })
);

export const SummariesStatusNotFoundAction = createAction(
  SummariesActionTypes.SummariesLoadStatusNotFoundType,
  (payload?: { status: unknown }) => ({ payload })
);

export const UpdatePoiLink = createAction(
  SummariesActionTypes.UpdatePoiLinkType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload: any) => ({ payload })
);

export type SummariesAction =
  | ReturnType<typeof LoadSummaries>
  | ReturnType<typeof SummariesLoaded>
  | ReturnType<typeof SummariesLoadSuccess>
  | ReturnType<typeof SummariesLoadError>
  | ReturnType<typeof SummariesStatusNotFoundAction>
  | ReturnType<typeof UpdatePoiLink>;

export const fromSummariesActions = {
  LoadSummaries,
  SummariesLoaded,
  SummariesLoadSuccess,
  SummariesLoadError,
  SummariesStatusNotFoundAction,
  UpdatePoiLink
};
