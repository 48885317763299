import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AddVehicleRateState } from '../../models/add-vehicle-rate.model';
import * as AddVehicleQuoteActions from './add-vehicle-quote.actions';

export type State = EntityState<AddVehicleRateState>;

export const adapter: EntityAdapter<AddVehicleRateState> = createEntityAdapter<AddVehicleRateState>(
  {
    selectId: state => state.policyNumber
  }
);

export const initialState: State = adapter.getInitialState({});

const userReducer = createReducer(
  initialState,

  on(AddVehicleQuoteActions.addVehicleRate, (state, request) =>
    adapter.upsertOne({ ...request.payload, status: null, loading: true }, state)
  ),

  on(AddVehicleQuoteActions.addVehicleRateSuccess, (state, rateResponse) => {
    const responseResult = Object.assign({}, rateResponse.payload.result, {
      policyNumber: rateResponse.payload.result.policyNumber.replace(/-/g, '')
    });

    return adapter.upsertOne(
      {
        ...responseResult,
        status: rateResponse.payload.status,
        error: null,
        loading: false
      },
      state
    );
  }),

  on(AddVehicleQuoteActions.addVehicleRateError, (state, rateFailureResponse) =>
    adapter.upsertOne(
      {
        ...rateFailureResponse.payload.request,
        status: rateFailureResponse.payload.status,
        error: rateFailureResponse.payload.status,
        loading: false
      },
      state
    )
  ),

  on(AddVehicleQuoteActions.addVehicleRateReset, () => adapter.getInitialState()),

  on(AddVehicleQuoteActions.addVehicleRateErrorReset, state =>
    state.ids && state.ids.length > 0
      ? adapter.updateOne(
          {
            id: state.ids[0].toString(),
            changes: {
              status: null,
              error: null
            }
          },
          state
        )
      : initialState
  ),
  on(AddVehicleQuoteActions.loadFuturePaymentsSuccess, (state, action) =>
    adapter.updateOne(
      {
        id: action.payload.policyNumber,
        changes: { paymentSchedule: action.payload.paymentSchedule, loading: false }
      },
      state
    )
  )
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectNewVehiclePolicyNumbers = selectIds;

export const selectNewVehicleQuoteEntities = selectEntities;

export const selectAllNewVehicleQuoteList = selectAll;

export const selectNewVehicleQuoteListCount = selectTotal;
