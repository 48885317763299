/* eslint-disable ngrx/avoid-mapping-selectors */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { format, isToday, isValid } from 'date-fns';
import { flatMap as _flatmap, get as _get } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';

import {
  AutomaticPayment,
  AutoPayActions,
  AutoPayAmounts,
  AutoPayRule,
  AutoPaySelectors,
  GetAutoPayPredictionPayload
} from '@amfam/billing/auto-pay/data-access';
import { AutoPayBillAccountTile, AutoPayPaymentPreview } from '@amfam/billing/auto-pay/ui';
import { AutoPayUtilSelectors } from '@amfam/billing/auto-pay/util';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import {
  PaymentMethodActions,
  PaymentMethodSelectors
} from '@amfam/billing/payment-method/data-access';
import {
  PaymentMethod,
  PaymentMethodAccountType,
  PaymentMethodAddEditConfig,
  PaymentMethodAddEditOperationType,
  PaymentMethodUtilService
} from '@amfam/billing/payment-method/util';
import { MaeFeatureActions, MaeFeatureSelectors } from '@amfam/mae/feature';
import {
  AnalyticsFacade,
  AutomaticPaymentsAnalytics,
  AutomaticPaymentsAnalyticsAdmin
} from '@amfam/shared/analytics';
import { BillAccount } from '@amfam/shared/models';
import { ArrayToList, CurrencyUSDPipe, PrettyBillingAcctNum } from '@amfam/shared/ui/pipes';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import {
  Applications,
  ApplicationService,
  CopyService,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { DockingBarService, DsModalService, LoadingSpinnerService, Option } from '@amfam/ui-kit';

import * as AutoPaySetupActions from '../../+state/auto-pay-setup.actions';
import { AutoPayContext, AutoPaySettings, AutoPaySteps } from '../../+state/auto-pay-setup.models';
import * as AutoPaySetupSelectors from '../../+state/auto-pay-setup.selectors';
import { AutoPayUtilService } from '../../util/auto-pay-util.service';

@Component({
  selector: 'ds-auto-pay-setup-wrapper',
  templateUrl: './auto-pay-setup-wrapper.component.html',
  styleUrls: ['./auto-pay-setup-wrapper.component.scss']
})
export class AutoPaySetupWrapperComponent implements OnInit, OnDestroy {
  autoPayForm: UntypedFormGroup;
  selectedBillAccountNumber: string;
  selectedBillAccount$: Observable<BillAccount>;
  billAccountTile$: Observable<AutoPayBillAccountTile>;

  paymentMethodAddEditConfig$: BehaviorSubject<PaymentMethodAddEditConfig> = new BehaviorSubject(
    null
  );

  paymentMethodOptions: Option[];
  paymentFrequencyOptions: Option[];
  paymentDateOptions: Option[];

  paymentPreview$: Observable<AutoPayPaymentPreview>;
  paymentPreviewLoading$: Observable<boolean>;

  paymentAccountTypes = PaymentMethodAccountType;
  paymentAccountAddEditTypes = PaymentMethodAddEditOperationType;

  displayDiscountWarning$: Observable<boolean>;
  hasAutoPayDiscount$: Observable<boolean>;
  isFullPay$: Observable<boolean>;
  displayPaymentExpiredError$: Observable<boolean>;
  title$: Observable<string>;
  primaryButtonText$: Observable<string>;
  hasClassicPolicy$: Observable<boolean>;

  displayBackButton$: Observable<boolean>;
  isAddMultiple$: Observable<boolean>;
  isEditingSetup$: Observable<boolean>;

  hasRegistrationError$: Observable<boolean>;
  registrationErrorHeadingText: string;
  registrationErrorBodyText: string;

  displayFullPayDiscountMessage$: Observable<boolean>;

  authorizedToAddPaymentMethod$: Observable<boolean>;
  authorizedToEditPaymentMethod$: Observable<boolean>;

  isEditingAndNotChanged$: Observable<boolean>;

  defaultPaymentMethod: Option;
  defaultPaymentFrequency: Option;
  defaultPaymentDate: Option;
  tertiaryButtonName: string;
  adminEnrollmentFlow = false;
  isMyAccountAdmin: boolean;

  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private dockingBar: DockingBarService,
    private modalService: DsModalService,
    private paymentMethodUtil: PaymentMethodUtilService,
    private spinner: LoadingSpinnerService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade,
    private roleService: ImpersonateRolesService,
    private autoPayUtil: AutoPayUtilService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.isMyAccountAdmin = this.applicationService.isApp(Applications.MYACCOUNT_ADMIN);

    this.checkForRegistrationError();

    // reinitialize component after query param change (next bill account)
    this.route.queryParams.pipe(takeUntil(this.stop$)).subscribe(queryParams => {
      this.selectedBillAccountNumber = queryParams['billAccount'];
      this.selectedBillAccount$ = this.store.select(
        BillAccountsSelectors.selectBillAccount(this.selectedBillAccountNumber)
      );
      this.hasAutoPayDiscount$ = this.store.select(
        AutoPayUtilSelectors.selectBillAccountHasAutoPayDiscount(this.selectedBillAccountNumber)
      );
      this.displayFullPayDiscountMessage$ = this.store.select(
        AutoPayUtilSelectors.selectIsEligibleForFullPayDiscount(this.selectedBillAccountNumber)
      );
      this.hasClassicPolicy$ = this.store.select(
        AutoPayUtilSelectors.selectBillAccountHasClassicPolicy(this.selectedBillAccountNumber)
      );
      if (this.isMyAccountAdmin) {
        this.store
          .select(MaeFeatureSelectors.isEnrollmentWorkFlow)
          .pipe(take(1))
          .subscribe(enrollmentFlow => {
            this.adminEnrollmentFlow = enrollmentFlow;
            if (!this.adminEnrollmentFlow) {
              this.dockingBar.registerHeading('Set Up AutoPay');
            }
          });
      } else {
        this.dockingBar.registerHeading('Set Up AutoPay');
      }
      this.billAccountTile$ = this.getBillAccountTile();
      this.getPaymentMethodOptions();
      this.getPaymentFrequencyOptions();
      this.getPaymentDateOptions();
      this.getForm();

      this.displayPaymentExpiredError$ = this.getExpiredPayment();
      this.paymentPreviewLoading$ = this.store.select(AutoPaySelectors.getPredictionLoading);
      this.displayDiscountWarning$ = this.getDisplayDiscountWarning();
      this.isFullPay$ = this.getIsFullPay();
      this.title$ = this.getTitle();
      this.isEditingSetup$ = this.store.select(AutoPaySetupSelectors.selectIsEditingSetup);

      this.isAddMultiple$ = this.store.select(AutoPaySetupSelectors.selectIsAddMultiple);

      this.primaryButtonText$ = this.getPrimaryButtonText();
      this.isEditingAndNotChanged$ = this.getIsEditingAndNotChanged();
    });
    this.dispatchAnalytics();

    this.authorizedToAddPaymentMethod$ = this.roleService.isAuthorized(
      'makepaymentacct_walkthrough'
    );
    this.authorizedToEditPaymentMethod$ = this.roleService.isAuthorized(
      'editpaymentacct_walkthrough'
    );

    this.store
      .select(AutoPaySetupSelectors.selectIsUserComingFromEnrollment)
      .pipe(take(1))
      .subscribe(isUserComingFromEnrollment => {
        this.tertiaryButtonName = isUserComingFromEnrollment ? 'Skip AutoPay Setup' : 'Cancel';
      });
  }

  private getForm(): void {
    this.store
      .select(AutoPaySetupSelectors.selectAllAutoPaySetups)
      .pipe(take(1))
      .subscribe(setups => {
        // find correct default settings for this bill account
        const defaultSettings: AutoPaySettings = setups.find(
          setup => setup.billAccount.billAccountNumber === this.selectedBillAccountNumber
        ).autoPaySettings;

        // find corresponding Option for each setting
        this.defaultPaymentMethod = this.paymentMethodOptions.find(
          (option: Option) => option.text === defaultSettings.paymentAccountNickName.value
        );
        this.defaultPaymentFrequency = this.paymentFrequencyOptions.find(
          (option: Option) => option.id === defaultSettings.paymentAmount.value
        );
        this.defaultPaymentDate = this.paymentDateOptions.find(
          (option: Option) => option.id === defaultSettings.daysBeforeDueDate.value
        );

        // create form with defaults
        this.autoPayForm = this.formBuilder.group({
          paymentMethod: [this.defaultPaymentMethod, Validators.compose([Validators.required])],
          paymentFrequency: [
            this.defaultPaymentFrequency,
            Validators.compose([Validators.required])
          ],
          paymentDate: [this.defaultPaymentDate, Validators.compose([Validators.required])]
        });

        // get payment preview using these options
        this.paymentPreview$ = this.getPaymentPreview();
      });
  }

  private getBillAccountTile(): Observable<AutoPayBillAccountTile> {
    return this.selectedBillAccount$.pipe(
      map((billAccount: BillAccount) => {
        const accountName = _get(billAccount, 'billingPreferences.accountNickName', null)
          ? billAccount.billingPreferences.accountNickName
          : `Acct ${new PrettyBillingAcctNum().transform(billAccount.billAccountNumber)}`;
        const riskDescriptions = new ArrayToList().transform(
          _flatmap(billAccount.policyList, policy => policy.riskDescriptionList)
        );
        const policyTypeIcon = billAccount.icon;
        return {
          accountName,
          riskDescriptions,
          policyTypeIcon
        };
      })
    );
  }

  getExpiredPayment(): Observable<boolean> {
    return combineLatest([
      this.autoPayForm.get('paymentMethod').valueChanges,
      this.store.select(PaymentMethodSelectors.selectPaymentMethods)
    ]).pipe(
      map(([changes, paymentMethods]) => {
        const paymentMethod: PaymentMethod = paymentMethods.find(
          payment => payment.paymentAccountId === (changes as Option)?.id
        );

        return this.paymentMethodUtil.isPaymentMethodExpired(paymentMethod);
      })
    );
  }

  private getPaymentMethodOptions(): void {
    combineLatest([
      this.store.select(PaymentMethodSelectors.selectPaymentMethods),
      this.hasClassicPolicy$
    ])
      .pipe(takeUntil(this.stop$))
      .subscribe(([paymentMethods, hasClassicPolicy]) => {
        this.paymentMethodOptions = this.autoPayUtil.getPaymentMethodOptions(
          paymentMethods,
          hasClassicPolicy
        );
      });
  }

  private getPaymentFrequencyOptions(): void {
    combineLatest([this.hasClassicPolicy$, this.displayFullPayDiscountMessage$])
      .pipe(take(1))
      .subscribe(([hasClassicPolicy, displayFullPayDiscountMessage]) => {
        this.paymentFrequencyOptions = this.autoPayUtil.getPaymentFrequencyOptions(
          hasClassicPolicy,
          displayFullPayDiscountMessage
        );
      });
  }

  private getPaymentDateOptions(): void {
    this.paymentDateOptions = this.autoPayUtil.getPaymentDateOptions();
  }

  private getPaymentPreview(): Observable<AutoPayPaymentPreview> {
    let context;
    this.store
      .select(AutoPaySetupSelectors.selectContext)
      .pipe(take(1))
      .subscribe(
        autoPayContext => (context = autoPayContext === AutoPayContext.EDIT ? 'edit' : 'add')
      );
    // Get Auto Pay Prediction on form changes
    this.autoPayForm.valueChanges
      .pipe(
        startWith(this.autoPayForm.value),
        filter(() => this.autoPayForm.valid),
        takeUntil(this.stop$)
      )
      .subscribe(() => {
        const correlationId = this.utilService.generateId();
        const payload: GetAutoPayPredictionPayload = {
          billAccountNumber: this.selectedBillAccountNumber,
          autopayRule: this.getAutoPayRule(),
          predictionOnly: true,
          transactionId: this.utilService.generateId()
        };

        this.store.dispatch(
          AutoPayActions.getAutoPayPrediction({ payload, correlationId, context })
        );
      });

    // return value as observable
    return this.store.select(AutoPaySelectors.getAutoPayPrediction).pipe(
      map((prediction: AutomaticPayment) => {
        const date: string = _get(prediction, 'nextPaymentDate', '');
        const amount: string = _get(prediction, 'predictedDollarAmount', 'UNKNOWN');
        const valid: boolean = isValid(new Date(date)) && amount !== 'UNKNOWN';
        const matchingAccount =
          _get(prediction, 'billAccountNumber', '') === this.selectedBillAccountNumber;
        const formattedDate: string = isToday(date) ? 'Today' : format(date, 'MMM DD');
        return valid && matchingAccount
          ? {
              date: formattedDate,
              amount: new CurrencyUSDPipe().transform(amount)
            }
          : null;
      })
    );
  }

  private getAutoPayRule(): AutoPayRule {
    return {
      paymentAccountNickName: this.autoPayForm.get('paymentMethod').value.text,
      daysBeforeDueDate: this.autoPayForm.get('paymentDate').value.id,
      paymentAmount: this.autoPayForm.get('paymentFrequency').value.id
    };
  }

  private getDisplayDiscountWarning(): Observable<boolean> {
    return combineLatest([
      this.autoPayForm
        .get('paymentMethod')
        .valueChanges.pipe(startWith(this.autoPayForm.get('paymentMethod').value)),
      this.store.select(PaymentMethodSelectors.selectPaymentMethods),
      this.hasAutoPayDiscount$
    ]).pipe(
      filter(([changes]) => !!changes),
      map(([changes, paymentMethods, hasAutoPayDiscount]) => {
        const paymentMethod: PaymentMethod = paymentMethods.find(
          payment => payment.paymentAccountId === (changes as Option)?.id
        );
        const paymentMethodType: PaymentMethodAccountType =
          this.paymentMethodUtil.getPaymentMethodAccountType(paymentMethod);
        return paymentMethodType === PaymentMethodAccountType.CREDIT_DEBIT
          ? hasAutoPayDiscount
          : false;
      })
    );
  }

  private getIsFullPay(): Observable<boolean> {
    return this.autoPayForm.get('paymentFrequency').valueChanges.pipe(
      startWith(this.autoPayForm.get('paymentFrequency').value),
      map(
        () =>
          _get(this.autoPayForm.get('paymentFrequency').value as Option, 'id') ===
          AutoPayAmounts.FULLPAY
      )
    );
  }

  private getTitle(): Observable<string> {
    return this.store.select(AutoPaySetupSelectors.selectAutoPayTitle);
  }

  private dispatchAnalytics(): void {
    this.store
      .select(AutoPaySetupSelectors.selectContext)
      .pipe(take(1))
      .subscribe(context => {
        if (this.isMyAccountAdmin) {
          if (context === AutoPayContext.EDIT) {
            this.analyticsFacade.trackPage(
              AutomaticPaymentsAnalyticsAdmin.MyAccountBillingPaymentsMultipleAutomaticPaymentsEditStart
            );
          } else if (context === AutoPayContext.ADD_MULTIPLE) {
            this.analyticsFacade.trackPageAndEvent(
              AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsStart,
              AutomaticPaymentsAnalyticsAdmin.eventMultipleAutomaticPaymentsStart
            );
          } else if (context === AutoPayContext.ADD) {
            this.analyticsFacade.trackPageAndEvent(
              AutomaticPaymentsAnalyticsAdmin.pageSingleAutomaticPayments
            );
          }
        } else {
          if (context === AutoPayContext.EDIT) {
            this.analyticsFacade.trackPage(
              AutomaticPaymentsAnalytics.MyAccountBillingPaymentsMultipleAutomaticPaymentsEditStart
            );
          } else if (context === AutoPayContext.ADD_MULTIPLE) {
            this.analyticsFacade.trackEvent(
              AutomaticPaymentsAnalytics.eventMultipleAutomaticPaymentsStart
            );
          }
        }
      });
  }

  private getPrimaryButtonText(): Observable<string> {
    return combineLatest([
      this.store.select(AutoPaySetupSelectors.selectNextSetup),
      this.isEditingSetup$
    ]).pipe(
      map(([nextSetup, editingSetup]) =>
        nextSetup && !editingSetup ? 'Continue to Next Account' : 'Finish AutoPay Setup and Review'
      )
    );
  }

  private checkForRegistrationError(): void {
    this.hasRegistrationError$ = this.store.select(
      AutoPaySetupSelectors.selectHasRegistrationError
    );
    this.registrationErrorHeadingText = this.copyService.getCopy(
      'billing.autoPayRefactor.registrationErrorHeadingText'
    );
    this.registrationErrorBodyText = this.copyService.getCopy(
      'billing.autoPayRefactor.registrationErrorBodyText'
    );
  }

  editPaymentMethod(): void {
    if (this.isMyAccountAdmin) {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsEditPaymentMethod
      );
    } else {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalytics.pageMultipleAutomaticPaymentsEditPaymentMethod
      );
    }

    const paymentAccountId: string = this.autoPayForm.get('paymentMethod').value.id;
    this.store
      .select(PaymentMethodSelectors.selectPaymentMethod(paymentAccountId))
      .pipe(take(1))
      .subscribe((paymentMethod: PaymentMethod) => {
        this.openPaymentMethodModal({
          accountType: this.paymentMethodUtil.getPaymentMethodAccountType(paymentMethod),
          operation: PaymentMethodAddEditOperationType.EDIT,
          paymentMethod
        });
      });
  }

  addCreditDebit(): void {
    if (this.isMyAccountAdmin) {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsNewPaymentMethodDebitCredit
      );
    } else {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalytics.pageMultipleAutomaticPaymentsNewPaymentMethodDebitCredit
      );
    }

    this.openPaymentMethodModal({
      accountType: PaymentMethodAccountType.CREDIT_DEBIT,
      operation: PaymentMethodAddEditOperationType.ADD,
      paymentMethod: null
    });
  }

  addCheckingAccount(): void {
    if (this.isMyAccountAdmin) {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsNewPaymentMethodChecking
      );
    } else {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalytics.pageMultipleAutomaticPaymentsNewPaymentMethodChecking
      );
    }

    this.openPaymentMethodModal({
      accountType: PaymentMethodAccountType.CHECKING,
      operation: PaymentMethodAddEditOperationType.ADD,
      paymentMethod: null
    });
  }

  addSavingsAccount(): void {
    if (this.isMyAccountAdmin) {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsNewPaymentMethodSaving
      );
    } else {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalytics.pageMultipleAutomaticPaymentsNewPaymentMethodSaving
      );
    }

    this.openPaymentMethodModal({
      accountType: PaymentMethodAccountType.SAVINGS,
      operation: PaymentMethodAddEditOperationType.ADD,
      paymentMethod: null
    });
  }

  private openPaymentMethodModal(modalConfig: PaymentMethodAddEditConfig) {
    this.paymentMethodAddEditConfig$.next(modalConfig);
    this.modalService.open('paymentMethodModal', true);
  }

  paymentMethodAddEditComplete(newPaymentAccountId: string): void {
    this.modalService.close('paymentMethodModal');

    const correlationId: string = this.utilService.generateId();
    this.spinner.start({
      blockActions: true
    });
    this.store.dispatch(PaymentMethodActions.getPaymentMethods({ correlationId }));
    this.store
      .select(PaymentMethodSelectors.selectPaymentMethodState)
      .pipe(
        filter(state => state.correlationId === correlationId),
        take(1)
      )
      .subscribe(state => {
        this.spinner.stop();
        if (!state.hasError) {
          const newPaymentMethodOption: Option = this.paymentMethodOptions.find(
            (option: Option) => option.id === newPaymentAccountId
          );
          this.autoPayForm.get('paymentMethod').setValue(newPaymentMethodOption);
        }
      });
  }

  getIsEditingAndNotChanged(): Observable<boolean> {
    return combineLatest([
      this.autoPayForm.valueChanges.pipe(startWith(this.autoPayForm.value)),
      this.store.select(AutoPaySetupSelectors.selectContext)
    ]).pipe(
      map(
        ([, context]) =>
          context === AutoPayContext.EDIT &&
          this.autoPayForm.get('paymentMethod').value.id === this.defaultPaymentMethod.id &&
          this.autoPayForm.get('paymentFrequency').value.id === this.defaultPaymentFrequency.id &&
          this.autoPayForm.get('paymentDate').value.id === this.defaultPaymentDate.id
      )
    );
  }

  saveAutoPaySettings(): void {
    const autoPaySettings: AutoPaySettings = this.autoPayUtil.getAutoPaySettings(
      this.autoPayForm.get('paymentMethod').value,
      this.autoPayForm.get('paymentFrequency').value,
      this.autoPayForm.get('paymentDate').value
    );
    this.store.dispatch(
      AutoPaySetupActions.updateAutoPaySetup({
        billAccountNumber: this.selectedBillAccountNumber,
        autoPaySettings
      })
    );
  }

  cancel(): void {
    this.modalService.open('autoPayCancelModal');
    if (this.isMyAccountAdmin) {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsCancelConfirmation
      );
    } else {
      this.analyticsFacade.trackPage(
        AutomaticPaymentsAnalytics.pageMultipleAutomaticPaymentsCancelConfirmation
      );
    }
  }

  back() {
    this.store.dispatch(AutoPaySetupActions.previousStep());
  }
  skipAutopay() {
    this.store.dispatch(AutoPaySetupActions.setStep({ step: AutoPaySteps.SKIP }));
    this.store.dispatch(MaeFeatureActions.skipAutoPay());
  }
  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
