<div
  class="row flex autopay-detail-card margin-bottom-10"
  [ngClass]="{ clickable: config.showEditButton }"
  (click)="edit()"
>
  <div class="column four flex justify-center autopay-detail-card-left">
    <div class="row flex items-center">
      @if (config.icon) {
        <div class="column">
          <span class="pseudo-icon bill-account-icon" [ngClass]="config.icon"></span>
        </div>
      }
      <div class="column">
        <p>{{ config.leftHeader }}</p>
        @for (text of config.leftSubtext; track text) {
          <p class="caption">{{ text }}</p>
        }
      </div>
    </div>
  </div>
  <div class="column eight flex justify-center autopay-detail-card-right">
    <div class="row flex items-center">
      <div>
        <p>{{ config.rightHeader }}</p>
        @for (text of config.rightSubtext; track text) {
          <p class="caption">{{ text }}</p>
        }
      </div>
      <span class="grow"></span>
      @if (config.showEditButton) {
        <div class="flex items-center link-button">
          <a class="caption no-decoration" (click)="edit()" data-cy="editButton">Edit</a>
          <span class="edit-icon icon-chevron-right"></span>
        </div>
      }
    </div>
  </div>
</div>
