import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { get as _get } from 'lodash';

import { NewVehicleFeaturesState } from '../../models/vehicle-features.model';
import * as AddVehicleFeaturesActions from './add-vehicle-feature.actions';

export type State = EntityState<NewVehicleFeaturesState>;

export const adapter: EntityAdapter<NewVehicleFeaturesState> =
  createEntityAdapter<NewVehicleFeaturesState>({
    selectId: state => state.vin
  });

export const initialState: State = adapter.getInitialState({});

const userReducer = createReducer(
  initialState,

  on(AddVehicleFeaturesActions.getNewVehicleFeatures, (_state, request) =>
    adapter.upsertOne({ ...request.payload, loading: true }, initialState)
  ),

  on(AddVehicleFeaturesActions.getNewVehicleFeaturesSuccess, (state, payload) =>
    adapter.upsertOne(
      {
        vin: payload.payload.request.vin,
        features: payload.payload.response.features,
        error: null,
        loading: false
      },
      state
    )
  ),
  on(AddVehicleFeaturesActions.getNewVehicleFeaturesError, (state, payload) =>
    adapter.upsertOne(
      {
        vin: payload.payload.request.vin,
        ...payload,
        error: _get(payload, 'payload.response.status'),
        loading: false
      },
      state
    )
  )
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectNewVehicleVinIds = selectIds;

export const selectNewVehicleFeatureEntities = selectEntities;

export const selectAllNewVehicleFeatures = selectAll;

export const selectNewVehicleFeaturesListCount = selectTotal;
