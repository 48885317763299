import { Vehicle } from './vehicle';

export class Boat extends Vehicle {
  public type: string;
  public lengthFeet: string;
  public lengthInches: string;
  public horsepower: string;
  public power: string;
  public sourceSystem: string;

  static fromJson(json: any, sourceSystem: string): Boat {
    const boat = new Boat();
    boat.sourceSystem = sourceSystem;
    boat.assignProperties(json);

    return boat;
  }

  protected assignProperties(json: any) {
    super.assignProperties(json, this.sourceSystem);

    this.type = json.type;
    this.lengthFeet = json.lengthFeet;
    this.lengthInches = json.lengthInches;
    this.horsepower = json.horsepower;
    this.power = json.power;
  }
}
