import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AutoPayEffects } from './+state/auto-pay.effects';
import { autoPayFeatureKey, reducers } from './+state/auto-pay.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(autoPayFeatureKey, reducers),
    EffectsModule.forFeature([AutoPayEffects])
  ]
})
export class BillingAutoPayDataAccessModule {}
