<div class="confirmation-cta">
  <h2 class="confirmation-cta-title margin-bottom-0">{{ ctaArea?.heading }}</h2>
  @if (ctaArea?.text) {
    <p class="margin-bottom-10">{{ ctaArea?.text }}</p>
  }
  @for (benefit of ctaArea?.benefits; track benefit) {
    <div><span class="icon-complete margin-right-5"></span> {{ benefit }}</div>
  }
  <br />
  <button ds-button (click)="runFunction()">{{ ctaArea?.button.title }}</button>
</div>
