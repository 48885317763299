import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PaymentMethodAddEditOperationType } from '@amfam/billing/payment-method/util';

import {
  GetFinancialInstitutionResponse,
  GetPCIAuthorizationTokenResponse,
  SavePaymentMethodResponse
} from '../../models/financial-account.model';
import { AddEditPaymentMethodPayload } from '../../models/payment-method.model';
import { FinancialAccountService } from '../../services/financial-account.service';
import * as FinancialAccountActions from '../financial-account/financial-account.actions';
import * as PaymentMethodActions from '../payment-method/payment-method.actions';
import * as FinancialAccountSelectors from './financial-account.selectors';

@Injectable()
export class FinancialAccountEffects {
  getPCIAuthorizationToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FinancialAccountActions.getPCIAuthorizationToken),
      switchMap(action =>
        this.financialAccountService.getPCIAuthorizationToken().pipe(
          map((response: GetPCIAuthorizationTokenResponse) =>
            FinancialAccountActions.getPCIAuthorizationTokenSuccess({
              response,
              correlationId: action.correlationId
            })
          ),
          catchError(error =>
            of(
              FinancialAccountActions.getPCIAuthorizationTokenFailure({
                error,
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    );
  });

  getFinancialInstitution$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FinancialAccountActions.getFinancialInstitution),
      concatLatestFrom(() =>
        this.store.select(FinancialAccountSelectors.selectPCIAuthorizationToken)
      ),
      switchMap(([action, pciToken]) =>
        this.financialAccountService
          .getFinancialInstitution(action.payload.routingNumber, pciToken)
          .pipe(
            map((response: GetFinancialInstitutionResponse) =>
              FinancialAccountActions.getFinancialInstitutionSuccess({
                response,
                correlationId: action.correlationId
              })
            ),
            catchError(error =>
              of(
                FinancialAccountActions.getFinancialInstitutionFailure({
                  error,
                  correlationId: action.correlationId
                })
              )
            )
          )
      )
    );
  });

  savePaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FinancialAccountActions.savePaymentMethod),
      concatLatestFrom(() =>
        this.store.select(FinancialAccountSelectors.selectPCIAuthorizationToken)
      ),
      switchMap(([action, pciToken]) =>
        this.financialAccountService.savePaymentMethod(action.savePayload, pciToken).pipe(
          switchMap((response: SavePaymentMethodResponse) => {
            // append token in response to next call
            const addEditPayload: AddEditPaymentMethodPayload = action.addEditPayload;
            addEditPayload.paymentAccount.token = response.finAcctServiceResponse.tokenId;
            const addEditAction =
              action.operation === PaymentMethodAddEditOperationType.ADD
                ? PaymentMethodActions.addPaymentMethod({
                    correlationId: action.correlationId,
                    payload: addEditPayload
                  })
                : PaymentMethodActions.editPaymentMethod({
                    correlationId: action.correlationId,
                    payload: addEditPayload,
                    paymentAccountId: action.paymentAccountId
                  });
            return [
              FinancialAccountActions.savePaymentMethodSuccess({
                response,
                correlationId: action.correlationId
              }),
              addEditAction
            ];
          }),
          catchError(error =>
            of(
              FinancialAccountActions.savePaymentMethodFailure({
                error,
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private financialAccountService: FinancialAccountService,
    private store: Store
  ) {}
}
