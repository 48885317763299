<div>
  <!-- Saved Payment Methods -->
  @if (paymentMethodOptions?.length > 0) {
    <div class="column twelve" [formGroup]="formGroup">
      @for (option of paymentMethodOptions; track option) {
        <div>
          <div
            class="payment-method-option"
            [ngClass]="{
              disabled: option.expireStatus === 'Expired',
              selected: option.id === formGroup.controls[control].value?.id
            }"
            (click)="radioButtonClick(option)"
            data-cy="paymentMethodRadioOption"
          >
            <div class="flex row items-center">
              <input
                [formControlName]="control"
                [value]="option"
                id="{{ option.id }}"
                class="margin-right-6"
                type="radio"
                [checked]="option.id === formGroup.controls[control].value?.id"
                data-cy="optionId"
              />
              <label for="{{ option.id }}" class="flex row" data-cy="nickNameEndInLastFourOrThree">
                <div>
                  @if (!option.bankIcon) {
                    <ds-credit-card-icon
                      class="credit-card"
                      [ccType]="option.icon"
                      [ccIconHeight]="24"
                      data-cy="creditCardIcon"
                    ></ds-credit-card-icon>
                  }
                  @if (option.bankIcon) {
                    <span class="icon-bank bank-icon" role="img" data-cy="bankIcon"></span>
                  }
                </div>
                {{ option.nickName + ' ending in ' + option.lastFourOrThree }}
              </label>
              <div class="flex">
                <button ds-button class="-outline-white" (click)="edit(option.id)">
                  <span class="icon icon-pen"> </span>
                </button>
              </div>
            </div>
            @if (option.expireStatus) {
              <div class="flex warning" data-cy="paymentMethodExpireStatus">
                {{ option.expireStatus }}
              </div>
            }
          </div>
        </div>
      }
    </div>
  }

  <!-- Add Payment Method -->
  <div class="flex add-payment-method">
    <button
      ds-button
      class="-outline-white"
      [ngClass]="{ disabled: !authorizedToAddPaymentMethod }"
      (click)="addCheckingAccount()"
      data-cy="addCheckingAccountButton"
    >
      Add Checking
    </button>
    <button
      ds-button
      class="-outline-white"
      [ngClass]="{ disabled: !authorizedToAddPaymentMethod }"
      (click)="addSavingsAccount()"
      data-cy="addSavingsAccountButton"
    >
      Add Savings
    </button>
    <button
      ds-button
      class="-outline-white"
      [ngClass]="{ disabled: !authorizedToAddPaymentMethod }"
      (click)="addCreditDebit()"
      data-cy="addCreditDebitCardButton"
    >
      Add Card
    </button>
  </div>

  @if (paymentMethodError) {
    <div class="payment-method-error">
      <ds-error-box [messageText]="choosePaymentMethod"> </ds-error-box>
    </div>
  }
</div>
