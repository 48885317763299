// Angular

// rxjs
import { CurrencyPipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
// Misc
import { get as _get, omit as _omit } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';

import { userQuery } from '@amfam/shared/user';
// Models
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
// Services
import {
  Addressee,
  CommunicationService,
  ConfigService,
  CopyService,
  InternalEmail,
  TemplatedEmail
} from '@amfam/shared/utility/shared-services';

import { ANSRequest } from '../models/add-vehicle-bind.model';
import {
  AddVehicleInitiateRequest,
  AddVehicleInitiateResponse,
  DetermineTPMAvailabilityResponse
} from '../models/add-vehicle-initiate.model';
import { AddVehicleRateRequest, AddVehicleRateResponse } from '../models/add-vehicle-rate.model';
import {
  AddVehicleNotificationRequestAgentEmail,
  AddVehicleNotificationRequestPartnerEmail,
  EnrollmentsResponse
} from '../models/add-vehicle.model';
import {
  AdaptedVehicleFeatures,
  AddVehicleFeaturesResponseModel,
  GetNewVehicleFeaturesRequestModel
} from '../models/vehicle-features.model';
import { VehicleFeaturesAdapterService } from './vehicle-features-adapter.service';
import { VehicleInitiateAdapterService } from './vehicle-initiate-adapter.service';

@Injectable({ providedIn: 'root' })
export class AddVehicleService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private communicationService: CommunicationService,
    private copyService: CopyService,
    private featureAdapter: VehicleFeaturesAdapterService,
    private initiateAdapter: VehicleInitiateAdapterService,
    private store: Store,
    private featureFlagService: FeatureFlagService,
    private currencyPipe: CurrencyPipe
  ) {}

  /**
   * @author Abhishek Singh
   * @param ANSRequest
   * @returns Json response as part of the post call on the producer API
   * @description This function takes the model and sends it to the producer API which in turn posts the ANS notification
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public ansNotification(model: ANSRequest): Observable<any> {
    const uri = this.config.get('producerApi') + 'producers/' + model.producerId + '/notifications';
    return this.http.post(uri, _omit(model, ['producerId']));
  }

  public sendAddVehicleAgentEmail(model: AddVehicleNotificationRequestAgentEmail) {
    const email: InternalEmail = new InternalEmail();
    const internalAgentEmail = _get(model, 'hasAgencyEmail')
      ? this.config.get('policyGracePeriodNotificatonInternalAgencyMailbox')
      : this.config.get('policyGracePeriodNotificatonInternal');

    const emailAddress = this.config.get('production')
      ? model.agentEmailAddress
      : internalAgentEmail;

    email.messageFrom = new Addressee('policy_change_request@amfam.com', 'Policy Change Request');
    email.messageToList.push(new Addressee(emailAddress, model.agentName));
    email.messageSubject = this.copyService.getCopy(`policy.${model.typeofEmail}EmailSubject`);
    email.messageContent = this.getAgentEmailContentForCoverageChange(
      model,
      model.classicOnlyAutoPolicyOrCompOnlyAdv
        ? `policy.${model.typeofEmail}ClassicOnlyAutoPolicyOrCompOnlyAdvAgentEmail`
        : `policy.${model.typeofEmail}AgentEmail`
    );

    return this.sendInternalEmail(email);
  }

  public sendAddVehiclePartnerEmailTPI(model: AddVehicleNotificationRequestPartnerEmail) {
    const email: InternalEmail = new InternalEmail();
    const emailAddress = this.config.get('partnerLicensedAdvisorEmail');

    email.messageFrom = new Addressee('policy_change_request@amfam.com', 'Policy Change Request');
    email.messageToList.push(new Addressee(emailAddress, 'Advisor'));
    email.messageSubject = this.copyService.getCopy(
      `policy.${model.typeofEmail}PartnerEmailSubjectTPI`
    );
    email.messageContent = this.getPartnerEmailContentForCoverageChange(
      model,
      `policy.${model.typeofEmail}PartnerEmailTPI`
    );
    return this.sendInternalEmail(email);
  }

  public sendAddVehiclePartnerEmailNoTPI(model: AddVehicleNotificationRequestPartnerEmail) {
    const email: InternalEmail = new InternalEmail();
    const emailAddress = this.config.get('partnerLicensedAdvisorEmail');

    email.messageFrom = new Addressee('policy_change_request@amfam.com', 'Policy Change Request');
    email.messageToList.push(new Addressee(emailAddress, 'Advisor'));
    // typeofemail vehicleCancelledTransaction
    email.messageSubject = this.copyService.getCopy(
      `policy.${model.typeofEmail}PartnerEmailSubjectNoTPI`
    );
    email.messageContent = this.getPartnerEmailContentForCoverageChange(
      model,
      `policy.${model.typeofEmail}PartnerEmail`
    );
    return this.sendInternalEmail(email);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sendAddVehicleCustomerEmail(email: TemplatedEmail): Observable<any> {
    return this.communicationService.sendTemplatedEmail(email);
  }

  public getAgentEmailContentForCoverageChange(
    model: AddVehicleNotificationRequestAgentEmail,
    path: string
  ): string {
    const agentInformation = this.copyService.getCopy(path, {
      agentName: model.agentName,
      odoReading: this.numberWithCommas(model.odometerReading),
      customerName: model.customerName,
      policyNumber: model.policyNumber,
      newVehicleDetails: model.newVehicleDetails,
      newVehicleVin: model.newVehicleVin,
      newVehicleMsrp: this.formatMSRP(model.newVehicleMsrp || ''),
      estimatedVehiclePrice: this.formatMSRP(model.estimatedVehiclePrice || ''),
      existingVehicle: model.existingVehicle,
      odometerReading: this.numberWithCommas(model.odometerReading),
      newVehicleDiscountActive: model.newVehicleDiscountActive,
      newVehicleDiscountDays: model.newVehicleDiscountDays,
      vehicleMainUse: model.vehicleMainUse,
      annualMileage: this.numberWithCommas(model.annualMileage),
      vehicleAddress: model.vehicleAddress,
      vehicleSecurityEquipmentFound: model.vehicleSecurityEquipmentFound,
      vehicleSecurityEquipmentEntered: model.vehicleSecurityEquipmentEntered,
      vehiclePrimaryDriver: model.vehiclePrimaryDriver,
      vehicleHasOwnerCoOwner: model.vehicleHasOwnerCoOwner,
      vehicleUsedForBusiness: model.vehicleUsedForBusiness,
      vehicleKitCarReplia: model.vehicleKitCarReplia,
      vehicleHasTPI: model.vehicleHasTPI,
      vehicleUMPDSelected: model.vehicleUMPDSelected,
      vehicleUIMPDSelected: model.vehicleUIMPDSelected,
      vehicleFullGlassCoverageSelected: model.vehicleFullGlassCoverageSelected,
      vehicleWorkOrderNumber: model.vehicleWorkOrderNumber
    });

    let content = agentInformation;
    content += `<br></br>Thank you,<br></br>The My Account Team`;
    content += `<br></br><a href="mailto:ACCOUNTSUPPORT@amfam.com">ACCOUNTSUPPORT@amfam.com</a><br></br>`;
    return content;
  }

  public getPartnerEmailContentForCoverageChange(
    model: AddVehicleNotificationRequestPartnerEmail,
    path: string
  ): string {
    const partnerInformation = this.copyService.getCopy(path, {
      odoReading: this.numberWithCommas(model.odometerReading),
      customerName: model.customerName,
      policyNumber: model.policyNumber,
      newVehicleDetails: model.newVehicleDetails,
      odometerReading: this.numberWithCommas(model.odometerReading),
      newVehicleDiscountActive: model.newVehicleDiscountActive,
      newVehicleDiscountDays: model.newVehicleDiscountDays,
      vehicleMainUse: model.vehicleMainUse,
      annualMileage: this.numberWithCommas(model.annualMileage),
      vehicleAddress: model.vehicleAddress,
      vehicleSecurityEquipmentFound: model.vehicleSecurityEquipmentFound,
      vehicleSecurityEquipmentEntered: model.vehicleSecurityEquipmentEntered,
      vehiclePrimaryDriver: model.vehiclePrimaryDriver,
      vehicleHasOwnerCoOwner: model.vehicleHasOwnerCoOwner,
      vehicleUsedForBusiness: model.vehicleUsedForBusiness,
      vehicleKitCarReplia: model.vehicleKitCarReplia,
      vehicleHasTPI: model.vehicleHasTPI,
      vehicleUnrepairedDamage: model.vehicleUnrepairedDamage,
      vehicleLeaseStartDate: model.vehicleLeaseStartDate ? model.vehicleLeaseStartDate : 'NA',
      vehicleUMPDSelected: model.vehicleUMPDSelected,
      vehicleUIMPDSelected: model.vehicleUIMPDSelected,
      vehicleFullGlassCoverageSelected: model.vehicleFullGlassCoverageSelected,
      vehicleWorkOrderNumber: model.vehicleWorkOrderNumber,
      newVehicleUnderMileageLimit: model.newVehicleUnderMileageLimit
    });

    let content = partnerInformation;
    content += `<br></br>Thank you,<br></br>The My Account Team`;
    content += `<br></br><a href="mailto:ACCOUNTSUPPORT@amfam.com">ACCOUNTSUPPORT@amfam.com</a><br></br>`;
    return content;
  }

  public getNewVehicleFeatures(
    model: GetNewVehicleFeaturesRequestModel
  ): Observable<AdaptedVehicleFeatures> {
    let params = new HttpParams();
    params = params.append('typeOfVehicleCode', model.typeOfVehicleCode);
    params = params.append('year', model.year);
    return this.http
      .get<AddVehicleFeaturesResponseModel>(
        this.config.get('vehicleApi') + 'vehicles/' + model.vin,
        {
          params: params
        }
      )
      .pipe(map(res => this.featureAdapter.adapt(res)));
  }

  public initiateAddVehicleFeature(
    payload: AddVehicleInitiateRequest,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _isPartner?: boolean
  ): Observable<AddVehicleInitiateResponse> {
    let headers = new HttpHeaders();
    if (this.config.get('applicationTitle') === 'MyAccount - INT - 01') {
      headers = headers.set('afi-leg', '01');
    }
    if (this.config.get('applicationTitle') === 'MyAccount - INT - 03') {
      headers = headers.set('afi-leg', '03');
    }

    const url = `${this.config.get('plpcContextAPI')}save`;
    return this.http.post<AddVehicleInitiateResponse>(url, payload, { headers: headers }).pipe(
      map(res => this.initiateAdapter.adapt(res)),
      catchError(err => throwError(err))
    );
  }

  public rateAddVehicleFeature(payload: AddVehicleRateRequest): Observable<AddVehicleRateResponse> {
    let headers = new HttpHeaders();
    if (this.config.get('applicationTitle') === 'MyAccount - INT - 01') {
      headers = headers.set('afi-leg', '01');
    }
    return this.http.post<AddVehicleRateResponse>(
      this.config.get('plpcContextAPI') + 'save-and-rate',
      payload,
      { headers: headers }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public bindAddVehicleFeature(policyNumber: string, vin?: string): Observable<any> {
    const plpcContextAddVehicleAFI = this.featureFlagService.isEnabled(
      'plpc_context_add_vehicle_afi'
    );
    let headers = new HttpHeaders();
    if (this.config.get('applicationTitle') === 'MyAccount - INT - 01') {
      headers = headers.set('afi-leg', '01');
    }
    const bindAPI = plpcContextAddVehicleAFI
      ? `${this.config.get('plpcContextAPI')}bind/${policyNumber}/${vin}`
      : `${this.config.get('addVehicleContextApi')}bind/${policyNumber}`;
    return this.http.post(bindAPI, {}, { headers: headers });
  }

  /**
   * @author: You have git lens!
   * @description: Fetch the information regarding the policy TPM package availibility.
   * @param policyNumber
   * @param jobNumber
   */
  public getTPMAvailibility(policyNumber: string, jobNumber: string): Observable<boolean> {
    const enrollmentSummaryUrl = `${this.config.get('ubiApi')}enrollments`;
    const kydTPMPackageUrl = `${this.config.get('ubiApi')}determineFreeTPMAvailability`;
    const params: HttpParams = new HttpParams().set('policyNumber', policyNumber);
    const options = { params: params };
    return this.http.get(enrollmentSummaryUrl, options).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switchMap((data: any) =>
        this.http
          .post<DetermineTPMAvailabilityResponse>(`${kydTPMPackageUrl}/policyNumber`, {
            JobNumber: jobNumber,
            UBIProgramCategory:
              data.ubiProgramCategoryCode === 'UBI_DEVICE' ? 'VEHICLEDEVICE' : 'DRIVERDEVICE'
          })
          .pipe(
            map(
              (tpmResponse: DetermineTPMAvailabilityResponse) =>
                <boolean>tpmResponse.IsPolicyEligible
            )
          )
      )
    );
  }

  public getEnrollments(policyNumber?: string, vin?: string): Observable<EnrollmentsResponse> {
    return this.store.select(userQuery.getCustomerId).pipe(
      first(),
      mergeMap(customerId => {
        let url = `${this.config.get('ubiApi')}enrollments?customerIdentifier=${customerId}`;
        if (policyNumber) {
          url += `&policyNumber=${policyNumber}`;
        }
        if (vin) {
          url += `&vin=${vin}`;
        }

        return this.http.get(url).pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((data: any) => {
            let enrollment: EnrollmentsResponse = null;
            if (data.namedInsured !== null && typeof data.namedInsured !== 'undefined') {
              enrollment = <EnrollmentsResponse>data.namedInsured;
            }
            return enrollment;
          })
        );
      })
    );
  }

  public getFuturePayments(payload: { policyNumber: number; submissionId: string }) {
    const plpcContextAddVehicleAFI = this.featureFlagService.isEnabled(
      'plpc_context_add_vehicle_afi'
    );
    let headers = new HttpHeaders();
    if (this.config.get('applicationTitle') === 'MyAccount - INT - 01') {
      headers = headers.set('afi-leg', '01');
    }
    return this.http.post(
      this.config.get(plpcContextAddVehicleAFI ? 'plpcContextAPI' : 'addVehicleContextApi') +
        'previewbilling',
      payload,
      {
        headers: headers
      }
    );
  }

  private sendInternalEmail(email: InternalEmail) {
    return this.communicationService.sendInternalEmail(email);
  }

  private formatMSRP(msrp: string) {
    const value = msrp.replace(/[^0-9.-]+/g, '');
    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-2');
  }
  private numberWithCommas(odometerReading: string) {
    const parts = odometerReading.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
}
