import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild
} from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { HeaderWrapperService } from '@amfam/shared/ui/ds-header';

@Component({
  selector: 'ds-roofline',
  templateUrl: './ds-roofline.component.html',
  styleUrls: ['./ds-roofline.component.scss']
})
export class DsRooflineComponent implements AfterViewInit, OnDestroy {
  @Input() loggedIn$: Observable<boolean>;
  @ViewChild('roofpeak', { static: true }) roofpeak: ElementRef<HTMLInputElement>;
  @ViewChild('mover') mover: ElementRef;

  leftOffsetPosition: number;
  innerWidth: number;
  linkActive: ElementRef | null;

  // Shared subject for completing observables
  protected stop$ = new Subject<void>();

  constructor(
    private headerWrapperService: HeaderWrapperService,
    private renderer: Renderer2
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = document.documentElement.clientWidth;
  }

  ngAfterViewInit() {
    this.innerWidth = document.documentElement.clientWidth;

    this.headerWrapperService.loaded$.pipe(takeUntil(this.stop$)).subscribe(() => {
      // Recheck active link position after loaded
      this.moveRoofline();
    });

    combineLatest([this.headerWrapperService.headerNavRect$, this.loggedIn$])
      .pipe(
        takeUntil(this.stop$),
        filter(([headerNavRect]) => !!headerNavRect)
      )
      .subscribe(([headerNavRect, loggedIn]) => {
        if (loggedIn) {
          this.renderer.addClass(this.roofpeak.nativeElement, 'roofpeak');
        } else {
          this.renderer.removeClass(this.roofpeak.nativeElement, 'roofpeak');
        }

        const rooflineImgWidth = this.roofpeak.nativeElement.getBoundingClientRect().width;
        const headerNavRectWidth = headerNavRect.width ?? this.innerWidth;
        this.leftOffsetPosition =
          rooflineImgWidth / 2 + Math.max((this.innerWidth - headerNavRectWidth) / 2, 0);

        this.moveRoofline();
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  private setMoverWidth(link: ElementRef | null) {
    if (!link?.nativeElement) return;
    const rect = link?.nativeElement.getBoundingClientRect();
    this.mover.nativeElement.style.width = `${rect.left - this.leftOffsetPosition + rect.width / 2}px`;
  }

  private moveRoofline() {
    this.headerWrapperService.linkActive$
      .pipe(
        takeUntil(this.stop$),
        filter(linkActive => linkActive?.nativeElement)
      )
      .subscribe(linkActive => {
        this.linkActive = linkActive;
        this.setMoverWidth(linkActive);
      });

    this.headerWrapperService.linkFocus$.pipe(takeUntil(this.stop$)).subscribe(linkFocus => {
      this.setMoverWidth(linkFocus || this.linkActive);
    });
  }
}
