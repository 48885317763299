import { createAction } from '@ngrx/store';

// eslint-disable-next-line
import {
  AddEditPaymentAccountDTO,
  AddEditPaymentAccountFailureModel,
  AddEditPaymentAccountSuccessModel,
  DeletePaymentAccountFailureModel,
  DeletePaymentAccountRequestModel,
  DeletePaymentAccountSuccessModel,
  PaymentAccount,
  UpdateModeOfAuthFailureModel,
  UpdateModeOfAuthModel,
  UpdateModeOfAuthSuccessModel
} from '@amfam/shared/models';

import { PaymentRecordRequest } from '../models/payment-capture.model';

export enum PaymentAccountActionTypes {
  PaymentAccountsLoadType = '[PaymentAccounts] Load',
  PaymentAccountsLoadSuccessType = '[PaymentAccounts] Load Success',
  PaymentAccountsLoadFailType = '[PaymentAccounts] Load Fail',
  PaymentAccountsLoadCompleteType = '[PaymentAccounts] Load Complete',

  PaymentAccountsLoadDetailType = '[PaymentAccounts] Load Detail',
  PaymentAccountsLoadDetailSuccessType = '[PaymentAccounts] Load Detail Success',

  PaymentAccountsRefreshType = '[PaymentAccounts] Refresh',

  PaymentAccountsSaveType = '[PaymentAccounts] Save',
  PaymentAccountsSaveFailType = '[PaymentAccounts] Save Fail',

  PaymentAccountsAddType = '[PaymentAccounts] Add',
  PaymentAccountsAddSuccessType = '[PaymentAccounts] Add Success',
  PaymentAccountsAddFailType = '[PaymentAccounts] Add Fail',

  PaymentAccountsEditType = '[PaymentAccounts] Edit',
  PaymentAccountsEditSuccessType = '[PaymentAccounts] Edit Success',
  PaymentAccountsEditFailType = '[PaymentAccounts] Edit Fail',

  PaymentAccountsUpdateModeAuthType = '[PaymentAccounts] Update Mode of Auth',
  PaymentAccountsUpdateModeAuthSuccessType = '[PaymentAccounts] Update Mode of Auth Success',
  PaymentAccountsUpdateModeAuthFailType = '[PaymentAccounts] Update Mode of Auth Fail',

  PaymentAccountsDeleteType = '[PaymentAccounts] Delete',
  PaymentAccountsDeleteSuccessType = '[PaymentAccounts] Delete Success',
  PaymentAccountsDeleteFailType = '[PaymentAccounts] Delete Fail',

  PaymentAccountScreenRecordingUpdateType = '[PaymentAccounts] Screen Recording Update',

  PaymentAccountsCleanupType = '[PaymentAccounts] Cleanup',
  PaymentAccountsTruncateType = '[PaymentAccounts] Truncate'
}

export const PaymentAccountsLoad = createAction(
  PaymentAccountActionTypes.PaymentAccountsLoadType,
  (payload?: unknown) => ({ payload })
);

export const PaymentAccountsLoadSuccess = createAction(
  PaymentAccountActionTypes.PaymentAccountsLoadSuccessType,
  (payload: PaymentAccount) => ({ payload })
);

export const PaymentAccountsLoadFail = createAction(
  PaymentAccountActionTypes.PaymentAccountsLoadFailType,
  (payload: unknown) => ({ payload })
);

export const PaymentAccountsLoadComplete = createAction(
  PaymentAccountActionTypes.PaymentAccountsLoadCompleteType,
  (payload?: unknown) => ({ payload })
);

export const PaymentAccountsLoadDetail = createAction(
  PaymentAccountActionTypes.PaymentAccountsLoadDetailType,
  (payload: unknown) => ({ payload })
);

export const PaymentAccountsLoadDetailSuccess = createAction(
  PaymentAccountActionTypes.PaymentAccountsLoadDetailSuccessType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload: any) => ({ payload })
);

export const PaymentAccountsSave = createAction(
  PaymentAccountActionTypes.PaymentAccountsSaveType,
  (payload: AddEditPaymentAccountDTO) => ({ payload })
);

export const PaymentAccountsSaveFail = createAction(
  PaymentAccountActionTypes.PaymentAccountsSaveFailType,
  (payload: AddEditPaymentAccountFailureModel) => ({ payload })
);

export const PaymentAccountsAdd = createAction(
  PaymentAccountActionTypes.PaymentAccountsAddType,
  (payload: AddEditPaymentAccountDTO) => ({ payload })
);

export const PaymentAccountsAddSuccess = createAction(
  PaymentAccountActionTypes.PaymentAccountsAddSuccessType,
  (payload: AddEditPaymentAccountSuccessModel) => ({ payload })
);

export const PaymentAccountsAddFail = createAction(
  PaymentAccountActionTypes.PaymentAccountsAddFailType,
  (payload: AddEditPaymentAccountFailureModel) => ({ payload })
);

export const PaymentAccountsEdit = createAction(
  PaymentAccountActionTypes.PaymentAccountsEditType,
  (payload: AddEditPaymentAccountDTO) => ({ payload })
);

export const PaymentAccountsEditSuccess = createAction(
  PaymentAccountActionTypes.PaymentAccountsEditSuccessType,
  (payload: AddEditPaymentAccountSuccessModel) => ({ payload })
);

export const PaymentAccountsEditFail = createAction(
  PaymentAccountActionTypes.PaymentAccountsEditFailType,
  (payload: AddEditPaymentAccountFailureModel) => ({ payload })
);

export const PaymentAccountsUpdateModeAuth = createAction(
  PaymentAccountActionTypes.PaymentAccountsUpdateModeAuthType,
  (payload: UpdateModeOfAuthModel) => ({ payload })
);

export const PaymentAccountsUpdateModeAuthSuccess = createAction(
  PaymentAccountActionTypes.PaymentAccountsUpdateModeAuthSuccessType,
  (payload: UpdateModeOfAuthSuccessModel) => ({ payload })
);

export const PaymentAccountsUpdateModeAuthFail = createAction(
  PaymentAccountActionTypes.PaymentAccountsUpdateModeAuthFailType,
  (payload: UpdateModeOfAuthFailureModel) => ({ payload })
);

export const PaymentAccountsDelete = createAction(
  PaymentAccountActionTypes.PaymentAccountsDeleteType,
  (payload: DeletePaymentAccountRequestModel) => ({ payload })
);

export const PaymentAccountsDeleteSuccess = createAction(
  PaymentAccountActionTypes.PaymentAccountsDeleteSuccessType,
  (payload: DeletePaymentAccountSuccessModel) => ({ payload })
);

export const PaymentAccountsDeleteFail = createAction(
  PaymentAccountActionTypes.PaymentAccountsDeleteFailType,
  (payload: DeletePaymentAccountFailureModel) => ({ payload })
);

export const PaymentAccountScreenRecordingUpdate = createAction(
  PaymentAccountActionTypes.PaymentAccountScreenRecordingUpdateType,
  (payload: PaymentRecordRequest) => ({ payload })
);

export const PaymentAccountsCleanup = createAction(
  PaymentAccountActionTypes.PaymentAccountsCleanupType,
  (payload: string) => ({ payload }) // paymentAccountNickName
);

export const PaymentAccountsTruncate = createAction(
  PaymentAccountActionTypes.PaymentAccountsTruncateType,
  (payload: string) => ({ payload }) // paymentAccountNickName
);

export type PaymentAccountActions =
  | ReturnType<typeof PaymentAccountsLoad>
  | ReturnType<typeof PaymentAccountsLoadSuccess>
  | ReturnType<typeof PaymentAccountsLoadFail>
  | ReturnType<typeof PaymentAccountsLoadComplete>
  | ReturnType<typeof PaymentAccountsLoadDetail>
  | ReturnType<typeof PaymentAccountsLoadDetailSuccess>
  | ReturnType<typeof PaymentAccountsSave>
  | ReturnType<typeof PaymentAccountsSaveFail>
  | ReturnType<typeof PaymentAccountsAdd>
  | ReturnType<typeof PaymentAccountsAddSuccess>
  | ReturnType<typeof PaymentAccountsAddFail>
  | ReturnType<typeof PaymentAccountsEdit>
  | ReturnType<typeof PaymentAccountsEditSuccess>
  | ReturnType<typeof PaymentAccountsEditFail>
  | ReturnType<typeof PaymentAccountsUpdateModeAuth>
  | ReturnType<typeof PaymentAccountsUpdateModeAuthSuccess>
  | ReturnType<typeof PaymentAccountsUpdateModeAuthFail>
  | ReturnType<typeof PaymentAccountsDelete>
  | ReturnType<typeof PaymentAccountsDeleteSuccess>
  | ReturnType<typeof PaymentAccountsDeleteFail>
  | ReturnType<typeof PaymentAccountScreenRecordingUpdate>
  | ReturnType<typeof PaymentAccountsCleanup>
  | ReturnType<typeof PaymentAccountsTruncate>;

export const fromPaymentAccountActions = {
  PaymentAccountsLoad,
  PaymentAccountsLoadSuccess,
  PaymentAccountsLoadFail,
  PaymentAccountsLoadComplete,
  PaymentAccountsLoadDetail,
  PaymentAccountsLoadDetailSuccess,
  PaymentAccountsSave,
  PaymentAccountsSaveFail,
  PaymentAccountsAdd,
  PaymentAccountsAddSuccess,
  PaymentAccountsAddFail,
  PaymentAccountsEdit,
  PaymentAccountsEditSuccess,
  PaymentAccountsEditFail,
  PaymentAccountsUpdateModeAuth,
  PaymentAccountsUpdateModeAuthSuccess,
  PaymentAccountsUpdateModeAuthFail,
  PaymentAccountsDelete,
  PaymentAccountsDeleteSuccess,
  PaymentAccountsDeleteFail,
  PaymentAccountScreenRecordingUpdate,
  PaymentAccountsCleanup,
  PaymentAccountsTruncate
};
