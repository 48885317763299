import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CustomerFeedbackEnum } from '../../models/feedback';
import { OpportunitiesCardInterface } from '../../models/slide';

@Component({
  selector: 'ds-opportunity-card',
  templateUrl: './opportunity-card.component.html',
  styleUrls: ['./opportunity-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityCardComponent {
  @Input() opportunityCardInformation: OpportunitiesCardInterface;
  @Input() recommendationId: string;
  @Input() successMessage: string;
  @Input() nothanksSuccessMessage: string;
  @Input() failureMessage: string;
  @Input() isAuthorized: boolean;

  @Output() learnMoreEvent = new EventEmitter<string>();
  customerFeedbackEnum = CustomerFeedbackEnum;
}
