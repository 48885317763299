/* eslint-disable ngrx/avoid-mapping-selectors */
/* eslint-disable ngrx/avoid-combining-selectors */
/* eslint-disable ngrx/avoid-dispatching-multiple-actions-sequentially */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable ngrx/prefer-action-creator-in-dispatch */
/* eslint-disable no-case-declarations */
import { AutoPayUtilService } from '@amfam/billing/auto-pay/feature';
import { BillAccountActions, BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import {
  pendingPaymentActions,
  pendingPaymentQuery
} from '@amfam/billing/pending-payment/data-access';
import { PendingRegistrationsActions } from '@amfam/billing/registration/data-access';
import { BillAccountTransmuteService } from '@amfam/billing/shared/util';
import { PolicySummarySelectors } from '@amfam/policy/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import {
  Alert,
  AlertCopy,
  AlertLevels,
  AlertTypes,
  BillAccount,
  BillingPaymentPaths,
  ONLINE_BILLING,
  OverviewAnalytics,
  TryAgainRegistrationModel
} from '@amfam/shared/models';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService, UtilService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable, Subject, combineLatest, of as observableOf } from 'rxjs';
import { filter, map, mergeMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from '../../../core/store/';

interface AlertModel {
  message: string;
  action: string;
}

interface AlertClasses {
  'high-alert': boolean;
  'medium-alert': boolean;
  'low-alert': boolean;
  'message-alert': boolean;
  homesite: boolean;
}

interface ButtonClasses {
  '-outline-white': boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'alert-item',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() alert: Alert;
  @Input() fromOverview: boolean;
  @Input() actionFromErrorModal: Observable<string>;
  content: AlertCopy;

  buttonClasses: ButtonClasses;
  alertClasses: AlertClasses;
  alertIcon: string;
  alertTypes = AlertTypes;
  classicPastDueEnabled: boolean;
  showPastDueAlert: boolean;
  private bannerContent: AlertModel;
  private externalPolicyUrl: string;
  private stop$ = new Subject<void>();
  private correlationId = '';

  private readonly billingAlertTypes = [
    AlertTypes.BILL_OVERDUE,
    AlertTypes.MULTIPLE_BILLS_OVERDUE,
    AlertTypes.UNSUCCESSFUL_AFT_PAYMENT,
    AlertTypes.UNSUCCESSFUL_PAYMENT,
    AlertTypes.PAPERLESS_BILLING_DISCOUNT,
    AlertTypes.AUTOPAY_DISCOUNT,
    AlertTypes.EMAILL_DEEP_LINK_FAILURE
  ];

  private tryAgainRegistration: TryAgainRegistrationModel;

  constructor(
    private copyService: CopyService,
    private store: Store,
    private analyticsFacade: AnalyticsFacade,
    private spinner: LoadingSpinnerService,
    private utilService: UtilService,
    private transmuteService: BillAccountTransmuteService,
    private feature: FeatureFlagService,
    private autoPayUtil: AutoPayUtilService
  ) {}

  ngOnInit() {
    this.classicPastDueEnabled = this.feature.isEnabled('classic_past_due');
    this.showPastDueAlert =
      (this.alert.type === this.alertTypes.BILL_OVERDUE ||
        this.alert.type === this.alertTypes.MULTIPLE_BILLS_OVERDUE) &&
      this.classicPastDueEnabled;

    this.store
      .select(fromRoot.getBannerContent)
      .pipe(takeUntil(this.stop$))
      .subscribe(content => {
        this.bannerContent = {
          message: content,
          action: ''
        };
      });
    combineLatest(
      this.store.select(PolicySummarySelectors.getHomesitePolicies),
      this.store.select(BrandSelectors.getPropertyServiceUrl),
      (policies, url) => ({
        policies: policies,
        url: url
      })
    )
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        this.externalPolicyUrl = state.url;
        if (
          state.url.indexOf('?') > 0 &&
          state.policies &&
          state.policies.length &&
          state.policies.length > 0
        ) {
          this.externalPolicyUrl =
            this.externalPolicyUrl + '&policyNumber=' + state.policies[0].policyNumber;
        }
      });

    this.getObservableContent()
      .pipe(takeUntil(this.stop$))
      .subscribe(content => (this.content = content));
    this.alertClasses = {
      'high-alert': this.alert.level === AlertLevels.HIGH,
      'medium-alert': this.alert.level === AlertLevels.MEDIUM,
      'low-alert': this.alert.level === AlertLevels.LOW,
      'message-alert': this.alert.level === AlertLevels.MESSAGE,
      homesite: this.alert.level === AlertLevels.HOMESITE
    };
    this.buttonClasses = {
      '-outline-white': this.alert.level === AlertLevels.HOMESITE
    };

    switch (this.alert.level) {
      case AlertLevels.MESSAGE:
        this.alertIcon = 'icon-info';
        break;
      case AlertLevels.HOMESITE:
        this.alertIcon = 'icon-home';
        break;
      default:
        this.alertIcon = 'icon-alert';
        break;
    }
    //if (this.actionFromErrorModal) {
    this.actionFromErrorModal.pipe(takeUntil(this.stop$)).subscribe(action => {
      if (action === 'tryAgainClicked') {
        this.initiateSilentRegistration(
          this.tryAgainRegistration.billAccountNumber,
          this.tryAgainRegistration.route
        );
      }
    });
    // }
  }

  getObservableContent(): Observable<AlertCopy> {
    if (this.alert.type === AlertTypes.SYSTEM_OUTAGE) {
      return this.store.select(fromRoot.getBannerContent).pipe(
        map(content => ({
          message: content,
          action: ''
        }))
      );
    }
    let copyPath = 'overview.alerts.' + AlertTypes[this.alert.type] + '.message';
    const ctaPath = 'overview.alerts.' + AlertTypes[this.alert.type] + '.action';
    let options;

    // Add dynamic data for the unsuccessful payment alert
    if (
      this.alert.type === AlertTypes.UNSUCCESSFUL_PAYMENT ||
      this.alert.type === AlertTypes.UNSUCCESSFUL_AFT_PAYMENT
    ) {
      if (
        _get(this.alert, 'data.paymentAmount') &&
        _get(this.alert, 'data.nameReference') &&
        _get(this.alert, 'data.paymentDate')
      ) {
        options = {
          paymentAmount: this.alert.data.paymentAmount,
          nameReference: this.alert.data.nameReference,
          paymentDate: this.alert.data.paymentDate
        };
      } else {
        // Get the fail-over text if we don't have the data needed
        copyPath = 'overview.alerts.' + AlertTypes[this.alert.type] + '.noDataMessage';
      }
    } else if (this.alert.type === AlertTypes.BILL_OVERDUE) {
      copyPath = 'overview.alerts.' + AlertTypes[this.alert.type] + '.message';
    } else if (this.alert.type === AlertTypes.MULTIPLE_BILLS_OVERDUE) {
      copyPath = 'overview.alerts.' + AlertTypes[this.alert.type] + '.message';
    } else if (this.alert.type === AlertTypes.EMAILL_DEEP_LINK_FAILURE) {
      copyPath = 'overview.alerts.' + AlertTypes[this.alert.type] + '.message';
    }

    const message = this.copyService.getCopy(copyPath, options);
    const action = this.copyService.getCopy(ctaPath);

    return observableOf({
      message: message,
      action: action
    });
  }

  takeAction(): void {
    switch (this.alert.type) {
      case AlertTypes.BILL_OVERDUE:
        this.showPastDueAlert
          ? this.analyticsFacade.trackButtonClick(OverviewAnalytics.overviewPastDueBanner)
          : this.analyticsFacade.trackButtonClick(OverviewAnalytics.clickFailedPaymentPastDue);
        this.handleBillOverdue(_get(this.alert, 'data.billAccounts[0]'), false);
        break;
      case AlertTypes.MULTIPLE_BILLS_OVERDUE:
        this.showPastDueAlert
          ? this.analyticsFacade.trackButtonClick(OverviewAnalytics.overviewPastDueBanner)
          : this.analyticsFacade.trackButtonClick(OverviewAnalytics.clickFailedPaymentPastDue);
        if (
          this.classicPastDueEnabled &&
          !this.alert.data.billAccounts.includes(ba => ba.isAdvance)
        ) {
          _get(this.alert, 'data.billAccounts', []).forEach(billAccount => {
            this.handleBillOverdue(billAccount, true);
          });
          // ONE TIME PAYMENT ENTRYPOINT
          if (this.feature.isEnabled('simplified_payment')) {
            this.store.dispatch(
              new fromRouterActions.Go({
                path: [BillingPaymentPaths.ADD_ONE_TIME_PAYMENT]
              })
            );
          } else if (this.feature.isEnabled('one-time-pay')) {
            this.store
              .select(pendingPaymentQuery.getBillAccountsEligibleForOneTimePay)
              .pipe(take(1))
              .subscribe(billAccount => {
                if (billAccount.length === 1) {
                  this.store.dispatch(
                    new fromRouterActions.Go({
                      path: [BillingPaymentPaths.REVIEW_ONE_TIME_PAYMENT]
                    })
                  );
                } else {
                  this.store.dispatch(
                    new fromRouterActions.Go({
                      path: [BillingPaymentPaths.ADD_ONE_TIME_PAYMENT]
                    })
                  );
                }
              });
          } else {
            this.store.dispatch(
              new fromRouterActions.Go({
                path: [BillingPaymentPaths.ADD_SCHEDULED_PAYMENT]
              })
            );
          }
        } else {
          this.store.dispatch(
            new fromRouterActions.Go({
              path: [BillingPaymentPaths.BILLING_SECTION_PAGE]
            })
          );
        }
        break;

      /*
        Unsuccessful Payments - We need to determine which bill accounts to register and which to include in a bundled payment
          * All registered elsewhere, AFT and bill accounts with zero balance are already filtered out in the alert selector
          * Set pendingRegistration for any unregistered bill accounts
          * Set pendingPayment for all bill accounts

        Routing:
        * We route to registration if there are any bill accounts to register
        * We route to add scheduled payment if there are any bill accounts to pay
        * We default to the section page
      */
      case AlertTypes.UNSUCCESSFUL_PAYMENT:
      case AlertTypes.MULTIPLE_UNSUCCESSFUL_PAYMENTS:
        // Analytics - move to routing area if we need to be more specific
        this.analyticsFacade.trackButtonClick(OverviewAnalytics.clickFailedPayment);

        // Reset state
        this.store.dispatch(new pendingPaymentActions.ClearPendingPaymentsAction());
        this.store.dispatch(new PendingRegistrationsActions.PendingRegistrationsRemoveAll());

        let goToRegistration = false;
        let goToPayment = false;

        const billAccounts: BillAccount[] = _get(this.alert, 'data.billAccounts', []);
        billAccounts.forEach(ba => {
          if (_get(ba, 'billingMethod') !== ONLINE_BILLING && !ba.registeredElsewhere) {
            // set pending registration
            this.store.dispatch(
              new PendingRegistrationsActions.PendingRegistrationsAddOne({
                associated: ba.associated,
                policyNumber: _get(ba, 'policyList[0].policyNumber', ''),
                billAccountNumber: ba.billAccountNumber
              })
            );
            goToRegistration = true;
          }

          // Set pending payment
          this.store.dispatch(
            new pendingPaymentActions.AddPendingPaymentAction({
              billAccountNumber: ba.billAccountNumber
            })
          );
          goToPayment = true;

          if (goToRegistration) {
            this.store.dispatch(
              new fromRouterActions.Go({
                path: [BillingPaymentPaths.BILLING_REGISTRATION]
              })
            );
          } else if (goToPayment) {
            // ONE TIME PAYMENT ENTRYPOINT
            if (this.feature.isEnabled('simplified_payment')) {
              this.store.dispatch(
                new fromRouterActions.Go({
                  path: [BillingPaymentPaths.ADD_ONE_TIME_PAYMENT]
                })
              );
            } else if (this.feature.isEnabled('one-time-pay')) {
              this.store
                .select(pendingPaymentQuery.getBillAccountsEligibleForOneTimePay)
                .pipe(take(1))
                .subscribe(billAccount => {
                  if (billAccount.length === 1) {
                    this.store.dispatch(
                      new fromRouterActions.Go({
                        path: [BillingPaymentPaths.REVIEW_ONE_TIME_PAYMENT]
                      })
                    );
                  } else {
                    this.store.dispatch(
                      new fromRouterActions.Go({
                        path: [BillingPaymentPaths.ADD_ONE_TIME_PAYMENT]
                      })
                    );
                  }
                });
            } else {
              this.store.dispatch(
                new fromRouterActions.Go({
                  path: [BillingPaymentPaths.ADD_SCHEDULED_PAYMENT]
                })
              );
            }
          } else {
            this.store.dispatch(
              new fromRouterActions.Go({
                path: [BillingPaymentPaths.BILLING_SECTION_PAGE]
              })
            );
          }
        });
        break;
      case AlertTypes.PREREQUISITE_NEEDED_AUTOPAY_DISCOUNT:
        const billAccountNumber = this.alert.data.billAccountNumber;
        this.autoPayUtil.addAutoPay(billAccountNumber, 'overview');

        break;
      case AlertTypes.PAPERLESS_BILLING_DISCOUNT:
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/profile'],
            query: { openAccordion: 'communication' }
          })
        );
        break;
      case AlertTypes.AUTOPAY_DISCOUNT:
        // Route to autopay section
        this.autoPayUtil.addAutoPay(_get(this.alert, 'data.accountNumber'), 'overview');

        break;
      case AlertTypes.PAPERLESS_POLICY_DISCOUNT:
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/profile'],
            query: { openAccordion: 'communication' }
          })
        );
        break;
      case AlertTypes.AUTOPAY_DOES_NOT_EXIST_ON_BILLACCOUNT:
        this.store.dispatch(new BillAccountActions.DismissBanner(true));
        break;
      case AlertTypes.AUTOPAY_EDIT:
        // AUTOPAY ENTRY POINT
        this.autoPayUtil.editAutoPay(_get(this.alert, 'data.accountNumber'), 'overview');

        break;
      case AlertTypes.MULTIPLE_PAPERLESS_BILLING_DISCOUNT:
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/profile'],
            query: { openAccordion: 'communication' }
          })
        );
        break;
      case AlertTypes.MULTIPLE_AUTOPAY_EDIT:
      case AlertTypes.MULTIPLE_BILLS_AUTOPAY_DISCOUNT:
        this.store.dispatch(
          new fromRouterActions.Go({
            path: [BillingPaymentPaths.BILLING_SECTION_PAGE]
          })
        );
        break;
      case AlertTypes.KYD_DISCOUNT:
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/policies/knowyourdrive']
          })
        );
        break;
      case AlertTypes.KYD_SMARTPHONE_DISCOUNT:
      case AlertTypes.KYD_SMARTPHONE_OPERATOR_DISCOUNT:
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['/policies/knowyourdrivesmartphone']
          })
        );
        break;
      case AlertTypes.POLICIES_MANAGED_EXTERNALLY:
        window.location.href = this.externalPolicyUrl;
        break;
      case AlertTypes.GOPAPERLESS_FAILURE:
        this.store.dispatch(
          new fromRouterActions.Go({
            path: ['profile'],
            query: { openAccordion: 'communication' }
          })
        );
        break;
    }
  }

  routeToBillAccount(billAccountNumber: string): void {
    this.store.dispatch(
      new fromRouterActions.Go({
        path: [BillingPaymentPaths.BILLING_DETAIL_PAGE, billAccountNumber]
      })
    );
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  public tryAgainBtnClicked() {
    this.initiateSilentRegistration(
      this.tryAgainRegistration.billAccountNumber,
      this.tryAgainRegistration.route
    );
  }

  private handleBillOverdue(billingAccount: BillAccount, multiplePastDue: boolean) {
    const isRegistered =
      _get(billingAccount, 'billingMethod') === ONLINE_BILLING &&
      !billingAccount.registeredElsewhere;
    if (!isRegistered) {
      let addRoute: string;
      // ONE TIME PAYMENT ENTRYPOINT
      if (this.feature.isEnabled('simplified_payment')) {
        this.store.dispatch(
          new fromRouterActions.Go({
            path: [BillingPaymentPaths.ADD_ONE_TIME_PAYMENT]
          })
        );
      } else if (this.feature.isEnabled('one-time-pay')) {
        this.store
          .select(pendingPaymentQuery.getBillAccountsEligibleForOneTimePay)
          .pipe(take(1))
          .subscribe(billAccounts => {
            if (billAccounts.length === 1) {
              addRoute = BillingPaymentPaths.REVIEW_ONE_TIME_PAYMENT;
            } else {
              addRoute = BillingPaymentPaths.ADD_ONE_TIME_PAYMENT;
            }
          });
      } else {
        addRoute = BillingPaymentPaths.ADD_SCHEDULED_PAYMENT;
      }
      this.initiateSilentRegistration(billingAccount.billAccountNumber, addRoute, multiplePastDue);
    } else {
      // Route to make a payment section
      this.store.dispatch(
        new pendingPaymentActions.AddPendingPaymentAction({
          billAccountNumber: billingAccount.billAccountNumber
        })
      );
      // ONE TIME PAYMENT ENTRYPOINT
      if (this.feature.isEnabled('simplified_payment')) {
        this.store.dispatch(
          new fromRouterActions.Go({
            path: [BillingPaymentPaths.ADD_ONE_TIME_PAYMENT]
          })
        );
      } else if (this.feature.isEnabled('one-time-pay')) {
        this.store
          .select(pendingPaymentQuery.getBillAccountsEligibleForOneTimePay)
          .pipe(take(1))
          .subscribe(billAccount => {
            if (billAccount.length === 1) {
              this.store.dispatch(
                new fromRouterActions.Go({
                  path: [BillingPaymentPaths.REVIEW_ONE_TIME_PAYMENT]
                })
              );
            } else {
              this.store.dispatch(
                new fromRouterActions.Go({
                  path: [BillingPaymentPaths.ADD_ONE_TIME_PAYMENT]
                })
              );
            }
          });
      } else {
        this.store.dispatch(
          new fromRouterActions.Go({
            path: [BillingPaymentPaths.ADD_SCHEDULED_PAYMENT]
          })
        );
      }
    }
  }

  private initiateSilentRegistration(
    billAccountNumber: string,
    route: string,
    waitToRoute?: boolean
  ): void {
    this.store.dispatch(new PendingRegistrationsActions.PendingRegistrationsRemoveAll());
    this.spinner.start();
    const correlationId = this.utilService.generateId();
    const silentRegistrationPayload = this.transmuteService.buildSilentRegistrationPayload(
      [billAccountNumber],
      correlationId
    );
    this.store.dispatch(
      new BillAccountActions.BillAccountSilentRegistration(silentRegistrationPayload)
    );
    this.store
      .select(BillAccountsSelectors.getBillAccounts)
      .pipe(
        mergeMap(billAccounts => billAccounts),
        withLatestFrom(
          this.store.select(BillAccountsSelectors.getBillAccountNotificationsEntities)
        ),
        filter(
          ([billingAccount, matchedBillAccountNotificationState]) =>
            matchedBillAccountNotificationState[billingAccount.billAccountNumber].correlationId ===
              correlationId &&
            (!!billingAccount.registrationDate ||
              !!matchedBillAccountNotificationState[billingAccount.billAccountNumber]
                .updateRegistrationError)
        ),
        take(1)
      )
      .subscribe(([ba, matchedBillAccountNotificationState]) => {
        this.spinner.stop();
        // Route to make a payment or add autopay section if bill account is successfully registered.
        if (_get(ba, 'registrationDate')) {
          if (
            route === BillingPaymentPaths.ADD_SCHEDULED_PAYMENT ||
            route === BillingPaymentPaths.ADD_ONE_TIME_PAYMENT ||
            route === BillingPaymentPaths.REVIEW_ONE_TIME_PAYMENT
          ) {
            this.store.dispatch(
              new pendingPaymentActions.AddPendingPaymentAction({
                billAccountNumber: ba.billAccountNumber
              })
            );
          }
          if (!waitToRoute) {
            this.store.dispatch(
              new fromRouterActions.Go({
                path: [route]
              })
            );
          }
        } else {
          if (
            route === BillingPaymentPaths.ADD_SCHEDULED_PAYMENT ||
            route === BillingPaymentPaths.ADD_ONE_TIME_PAYMENT ||
            route === BillingPaymentPaths.REVIEW_ONE_TIME_PAYMENT
          ) {
            this.store.dispatch(new pendingPaymentActions.ClearPendingPaymentsAction());
          }
          // Let Try Again CTA know which bill account to register,
          this.tryAgainRegistration = {
            billAccountNumber: billAccountNumber,
            route: route
          };
        }
      });
  }

  /**
   * @description: Try again button was clicked from the sub-component/modal screen to re-submit
   * the silent registration.
   */
}
