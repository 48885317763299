import { Action } from '@ngrx/store';

import {
  ApiStatus,
  ResetPasswordSubmitModel,
  ResetPasswordSuccessModel,
  SecurityQuestionsAnswerModel
} from '@amfam/shared/models';

export const LOAD_USER_DATA = '[ResetPassword] Load User Data';
export const LOAD_USER_DATA_SUCCESS = '[ResetPassword] Load User Data Success';
export const LOAD_USER_DATA_ERROR = '[ResetPassword] Load User Data Error';

export const LOAD_SECURITY_QUESTIONS = '[ResetPassword] Load Security Questions';
export const LOAD_SECURITY_QUESTIONS_SUCCESS = '[ResetPassword] Load Security Questions Success';
export const LOAD_SECURITY_QUESTIONS_ERROR = '[ResetPassword] Load Security Questions Error';

export const USER_VERIFIED_PHONE = '[ResetPassword] User Verified Phone';
export const USER_VERIFIED_EMAIL = '[ResetPassword] User Verified Email';
export const USER_VERIFIED_SECURITY_QUESTIONS = '[ResetPassword] User Verified Security Questions';
export const VERIFY_METHOD_LOCKED = '[ResetPassword] Verification Method Locked';

export const RESET_PASSWORD = '[ResetPassword] Reset Password';
export const RESET_PASSWORD_SUCCESS = '[ResetPassword] Reset Password Success';
export const RESET_PASSWORD_ERROR = '[ResetPassword] Reset Password Error';

export const RESET_PASSWORD_LOGIN_SUCCESS = '[ResetPassword] Login Success';
export const RESET_PASSWORD_LOGIN_ERROR = '[ResetPassword] Login Error';

export const USER_LOGIN_LOCKED = '[ResetPassword] User Login Locked';
export const SHELL_ACCOUNT_FOUND = '[ResetPassword] Shell Account Found';

export class LoadUserDataAction implements Action {
  readonly type = LOAD_USER_DATA;
  constructor(public payload: string) {}
}

export class LoadUserDataSuccessAction implements Action {
  readonly type = LOAD_USER_DATA_SUCCESS;
  constructor(public payload?: any) {}
}

export class LoadUserDataErrorAction implements Action {
  readonly type = LOAD_USER_DATA_ERROR;
  constructor(public payload?: any) {}
}

export class LoadSecurityQuestionsAction implements Action {
  readonly type = LOAD_SECURITY_QUESTIONS;
  constructor(public payload?: any) {}
}

export class LoadSecurityQuestionsSuccessAction implements Action {
  readonly type = LOAD_SECURITY_QUESTIONS_SUCCESS;
  constructor(public payload?: any) {}
}

export class LoadSecurityQuestionsErrorAction implements Action {
  readonly type = LOAD_SECURITY_QUESTIONS_ERROR;
  constructor(public payload?: any) {}
}

export class UserVerifiedPhoneAction implements Action {
  readonly type = USER_VERIFIED_PHONE;
  constructor(public payload: string) {}
}

export class UserVerifiedEmailAction implements Action {
  readonly type = USER_VERIFIED_EMAIL;
  constructor(public payload: string) {}
}

export class UserVerifiedSecurityQuestionsAction implements Action {
  readonly type = USER_VERIFIED_SECURITY_QUESTIONS;
  constructor(public payload: SecurityQuestionsAnswerModel[]) {}
}

export class VerifyMethodLockedAction implements Action {
  readonly type = VERIFY_METHOD_LOCKED;
  constructor(public payload?: any) {}
}

export class ResetPasswordAction implements Action {
  readonly type = RESET_PASSWORD;
  constructor(public payload: ResetPasswordSubmitModel) {}
}

export class ResetPasswordSuccessAction implements Action {
  readonly type = RESET_PASSWORD_SUCCESS;
  constructor(public payload: ResetPasswordSuccessModel) {}
}

export class ResetPasswordErrorAction implements Action {
  readonly type = RESET_PASSWORD_ERROR;
  constructor(public payload: ApiStatus) {}
}

export class ResetPasswordLoginSuccessAction implements Action {
  readonly type = RESET_PASSWORD_LOGIN_SUCCESS;
  constructor(public payload: ApiStatus) {}
}

export class ResetPasswordLoginErrorAction implements Action {
  readonly type = RESET_PASSWORD_LOGIN_ERROR;
  constructor(public payload: ApiStatus) {}
}

export class UserLoginLockedAction implements Action {
  readonly type = USER_LOGIN_LOCKED;
  constructor(public payload: string) {}
}

export class ShellAccountFoundAction implements Action {
  readonly type = SHELL_ACCOUNT_FOUND;
  constructor() {}
}

export type Actions =
  | LoadUserDataAction
  | LoadUserDataSuccessAction
  | LoadUserDataErrorAction
  | LoadSecurityQuestionsAction
  | LoadSecurityQuestionsSuccessAction
  | LoadSecurityQuestionsErrorAction
  | UserVerifiedPhoneAction
  | UserVerifiedEmailAction
  | UserVerifiedSecurityQuestionsAction
  | VerifyMethodLockedAction
  | UserLoginLockedAction
  | ResetPasswordAction
  | ResetPasswordSuccessAction
  | ResetPasswordErrorAction
  | ResetPasswordLoginSuccessAction
  | ResetPasswordLoginErrorAction
  | ShellAccountFoundAction;
