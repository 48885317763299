import { UserState } from '@amfam/shared/user';

import { Enrollment } from './enrollment.model';

export class EnrollConfirmationModel {
  public anyErrors: boolean;

  constructor(
    public enrollments: Array<Enrollment>,
    public user: UserState
  ) {
    this.anyErrors =
      enrollments.length > 0 ? enrollments.some(enrollment => !enrollment.success) : true;
  }
}
