import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '@amfam/shared/utility/shared-services';

import {
  SetBillAccountDeliveryPreferencePayload,
  SetBillAccountDeliveryPreferenceResponse
} from '../models/communication-preferences.models';

@Injectable({
  providedIn: 'root'
})
export class CommunicationPreferencesService {
  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) {}

  setBillAccountDeliveryPreference(
    billAccountNumber: string,
    payload: SetBillAccountDeliveryPreferencePayload
  ): Observable<SetBillAccountDeliveryPreferenceResponse> {
    const endpoint: string =
      this.config.get('billingApi') + 'billaccounts/' + billAccountNumber + '/preferences';
    return this.http.put<SetBillAccountDeliveryPreferenceResponse>(endpoint, payload);
  }
}
