/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  ANSRequestModel,
  NotificationRequestCustomerEmail,
  NotificationRequestInternalEmail,
  OldReplaceVehicleQuoteApiRequest,
  ReplaceVehicle,
  ReplaceVehicleQuoteApiRequest,
  SubmitOnlineBindRequestModel
} from '@amfam/policy/models';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CommunicationService } from '@amfam/shared/utility/shared-services';

import * as replaceVehicleActions from './replace-vehicle.actions';
import { ReplaceVehicleService } from './replace-vehicle.service';

@Injectable()
export class ReplaceVehicleEffects {
  constructor(
    private store: Store<ReplaceVehicle>,
    private replaceVehicleService: ReplaceVehicleService,
    private action$: Actions,
    private communicationService: CommunicationService
  ) {}

  getPremiumEstimate$ = createEffect(() =>
    this.action$.pipe(
      ofType(replaceVehicleActions.REPLACE_VEHICLE_GET_PREMIUM),
      map((action: replaceVehicleActions.ReplaceVehicleGetPremiumAction) => action.payload),
      switchMap((payload: ReplaceVehicleQuoteApiRequest | OldReplaceVehicleQuoteApiRequest) =>
        this.replaceVehicleService.getPremiumEstimate(payload).pipe(
          map(response => {
            if (_get(response, 'status.code').toString() === '203000') {
              return new replaceVehicleActions.ReplaceVehicleGetPremiumFailAction(response);
            } else {
              return new replaceVehicleActions.ReplaceVehicleGetPremiumSuccessAction(response);
            }
          }),
          catchError(error =>
            of(new replaceVehicleActions.ReplaceVehicleGetPremiumFailAction(error))
          )
        )
      )
    )
  );

  submitQuoteForBind$ = createEffect(() =>
    this.action$.pipe(
      ofType(replaceVehicleActions.REPLACE_VEHICLE_SUBMIT_ONLINE_BIND),
      map((action: replaceVehicleActions.ReplaceVehicleSubmitOnlineBindAction) => action.payload),
      switchMap((payload: SubmitOnlineBindRequestModel) =>
        this.replaceVehicleService.submitOnlineBind(payload).pipe(
          mergeMap(response => [
            fromRouterActions.Go({
              path: ['/policies/replace-vehicle/' + payload.vehicleId + '/confirmation']
            }),
            new replaceVehicleActions.ReplaceVehicleSubmitOnlineBindSuccessAction(response)
          ]),
          catchError(error =>
            of(new replaceVehicleActions.ReplaceVehicleSubmitOnlineBindFailAction(error))
          )
        )
      )
    )
  );

  emailAgent$ = createEffect(() =>
    this.action$.pipe(
      ofType(replaceVehicleActions.REPLACE_VEHICLE_NOTIFY_AGENT),
      map((action: replaceVehicleActions.ReplaceVehicleNotifyAgentAction) => action.payload),
      switchMap((payload: NotificationRequestInternalEmail) =>
        this.replaceVehicleService.generateInternalEmails(payload).pipe(
          mergeMap(() => {
            const updateReplaceVehicleNextActions = [];
            /**
             * AS: If we do not have a quote we are not triggering an ANS notification and need to route to
             * confirmation from this effect.
             */
            if (payload.type === 'No Quote') {
              updateReplaceVehicleNextActions.push(
                fromRouterActions.Go({
                  path: ['/policies/replace-vehicle/' + payload.vehicleId + '/confirmation']
                })
              );
            }
            updateReplaceVehicleNextActions.push(
              new replaceVehicleActions.ReplaceVehicleNotifyAgentSuccessAction(payload)
            );
            return updateReplaceVehicleNextActions;
          }),
          catchError(error =>
            of(new replaceVehicleActions.ReplaceVehicleNotifyAgentFailAction(error))
          )
        )
      )
    )
  );

  emailCustomer$ = createEffect(() =>
    this.action$.pipe(
      ofType(replaceVehicleActions.REPLACE_VEHICLE_NOTIFY_CUSTOMER),
      map((action: replaceVehicleActions.ReplaceVehicleNotifyCustomerAction) => action.payload),
      switchMap((payload: NotificationRequestCustomerEmail) =>
        this.replaceVehicleService
          .generateCustomerEmail(
            payload.email,
            this.replaceVehicleService.buildMessageDetails(payload.message),
            payload.templateId
          )
          .pipe(
            map(
              response =>
                new replaceVehicleActions.ReplaceVehicleNotifyCustomerSuccessAction(response)
            ),
            catchError(error =>
              of(new replaceVehicleActions.ReplaceVehicleNotifyCustomerFailAction(error))
            )
          )
      )
    )
  );

  ansNotification$ = createEffect(() =>
    this.action$.pipe(
      ofType(replaceVehicleActions.REPLACE_VEHICLE_ANS_NOTIFICATION),
      map((action: replaceVehicleActions.ReplaceVehicleANSAction) => action.payload),
      switchMap((payload: ANSRequestModel) =>
        this.replaceVehicleService.ansNotification(payload).pipe(
          map(response => {
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/policies/replace-vehicle/' + payload.vehicleId + '/confirmation']
              })
            );
            return new replaceVehicleActions.ReplaceVehicleANSSuccessAction();
          }),
          catchError(error => of(new replaceVehicleActions.ReplaceVehicleANSFailAction(error)))
        )
      )
    )
  );

  ansNotificationForBind$ = createEffect(() =>
    this.action$.pipe(
      ofType(replaceVehicleActions.REPLACE_VEHICLE_ANS_NOTIFY_AGENT),
      map((action: replaceVehicleActions.ReplaceVehicleANSNotifyAgentAction) => action.payload),
      switchMap((payload: ANSRequestModel) =>
        this.replaceVehicleService
          .buildANSNotificationPayload(
            payload.producerId,
            payload.policyNumber,
            payload.notificationText,
            payload.orderNumber
          )
          .pipe(
            switchMap(ansPayload =>
              // FIXME: This route is executed after replace vehicle...
              this.replaceVehicleService.ansNotificationForBind(ansPayload).pipe(
                mergeMap(() => [
                  payload.vehicleId
                    ? fromRouterActions.Go({
                        path: ['/policies/replace-vehicle/' + payload.vehicleId + '/confirmation']
                      })
                    : fromRouterActions.Go({
                        path: ['/policies/risk-selection/confirmation']
                      }),
                  new replaceVehicleActions.ReplaceVehicleANSNotifyAgentSuccessAction()
                ]),
                catchError(error =>
                  of(new replaceVehicleActions.ReplaceVehicleANSNotifyAgentFailAction(error))
                )
              )
            )
          )
      )
    )
  );

  /**
   * @author: Abhishek Singh
   * @returns:
   * @description: This effect triggers a analytics notification.
   */

  analyticsNotification$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<replaceVehicleActions.SendAnalyticsAction>(replaceVehicleActions.SEND_ANALYTICS),
        map(action => action.payload),
        map(payload => this.replaceVehicleService.sendAnalytics(payload))
      ),
    { dispatch: false }
  );

  sendCustomerEmailNotification$ = createEffect(() =>
    this.action$.pipe(
      ofType<replaceVehicleActions.SendGracePeriodCustomerEmail>(
        replaceVehicleActions.REPLACE_VEHICLE_GRACE_PERIOD_NOTIFY_CUSTOMER
      ),
      map(action => action.payload),
      switchMap(templatedEmail =>
        this.communicationService.sendTemplatedEmail(templatedEmail).pipe(
          map(() => new replaceVehicleActions.SendGracePeriodCustomerEmailSuccess()),
          catchError(() => of(new replaceVehicleActions.SendGracePeriodCustomerEmailFailed()))
        )
      )
    )
  );
}
