import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { userQuery } from '@amfam/shared/user';

@Component({
  selector: 'ds-manage-business-email-address',
  templateUrl: './manage-business-email-address.component.html',
  styleUrls: ['./manage-business-email-address.component.scss']
})
export class ManageBusinessEmailAddressComponent {
  public businessEmailAddress$ = this.store.select(userQuery.getEmailAddress);
  public businessName$ = this.store.select(userQuery.getBusinessName);
  public firstName$ = this.store.select(userQuery.getFirstName);
  public lastName$ = this.store.select(userQuery.getLastName);

  constructor(private store: Store) {}
}
