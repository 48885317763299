<div class="row">
  <div class="column twelve lg-push-two md-eight md-push-two">
    <amfam-enrollment-header
      [firstLine]="skipAutoPayFirstLineText"
      [secondLine]="skipAutoPaySecondLineText"
    ></amfam-enrollment-header>
    <call-to-action
      [primaryButtonName]="'GO TO OVERVIEW'"
      (primaryEvent)="continue()"
    ></call-to-action>
  </div>
</div>
