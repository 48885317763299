<div class="overview-card__wrapper">
  <div class="overview-card__content">
    <div class="overview-card__heading">
      <ng-content select="ds-overview-card-heading"></ng-content>
    </div>
    <div class="overview-card__subheading">
      <ng-content select="ds-overview-card-subheading"></ng-content>
    </div>
    <ng-content select="ds-overview-card-content"></ng-content>
  </div>
  <div class="overview-card__cta"><ng-content select="ds-overview-card-cta"></ng-content></div>
</div>
