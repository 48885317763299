import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ExitWarningService {
  private _showWarning$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private adviceIgnored = false;

  constructor() {}

  get showWarning$() {
    return this._showWarning$.asObservable();
  }

  public showWarning() {
    // Allow canDeactivate by returning true if advice has been ignored
    if (this.adviceIgnored) {
      return true;
    }

    // Show the warning and return false which will mean canDeactivate is false;
    this._showWarning$.next(true);
    return false;
  }

  public hideWarning() {
    this._showWarning$.next(false);
  }

  public ignoreAdvice() {
    this.adviceIgnored = true;
    this.hideWarning();
  }

  public setAdviceIgnored(flag: boolean): void {
    this.adviceIgnored = flag;
  }
}
