import { get as _get } from 'lodash';
import { DocumentLink } from './document-link';

// date-fns
import { isAfter, isBefore, isSameDay } from 'date-fns';
// My Account Imports
import { SourceSystemType } from './source-system-type';

export class PolicyDocument {
  id: string;
  policyNumber: string;
  categoryType: string;
  documentDate: string;
  effectiveDate: string;
  links: DocumentLink[] = new Array<DocumentLink>();
  linksToSelf: DocumentLink[] = new Array<DocumentLink>();
  metadata: any = {};
  documentSubHeading: string;
  isNew?: boolean;
  sourceSystemName: string;
  title?: string;
  subTitle?: string;

  static fromJson(json: any, policyId: string, policySourceSystem: number, sourceSystemName: string): PolicyDocument {
    const document = new PolicyDocument();
    document.id = json.documentID;
    if (json.metaData) {
      for (let i = 0; i < json.metaData.length; i++) {
        document.metadata[json.metaData[i].name] = json.metaData[i].value;
      }
      if (!document.id) {
        document.id = document.metadata['Id'];
      }
      const policyNumbers: string[] = PolicyDocument.convertToArray(
        String(document.metadata['Policy Number'])
      );
      const policyNumber = Array.isArray(policyNumbers)
        ? policyNumbers.find(pn => policyId)
        : policyNumbers;
      document.metadata['Policy Number'] = String(policyNumber);
      document.policyNumber = String(policyNumber);
      document.categoryType = document.metadata['Category Type'];
      document.documentDate = document.metadata['Date On Document']
        ? document.metadata['Date On Document']
        : document.metadata['Date Created'];
      document.effectiveDate = document.metadata['Effective Date'];
      if (
        policySourceSystem === SourceSystemType.Legacy &&
        policyNumber &&
        String(policyNumber) === policyId
      ) {
        document.documentSubHeading = document.metadata['Content Description'];
      } else {
        document.documentSubHeading = document.metadata['Category Type'];
      }
      document.sourceSystemName = sourceSystemName
    }

    if (json.links) {
      const links = Array.isArray(json.links) ? json.links : [json.links];
      document.links = links.map(link => DocumentLink.fromJson(link));
      document.linksToSelf = document.links.filter(link => link.rel === 'self');
    }

    return document;
  }

  static convertToArray(str:string) {
    const innerString = str.replace(/[\[\]]/g, '').trim();
    if (innerString.includes(',')) {
      return innerString.split(',').map(item => item.trim());
    } else {
      return [innerString];
    }
  }

  static compareDate(a: PolicyDocument, b: PolicyDocument, field: string): number {
    if (!a || !b) {
      return -1;
    }
    if (isBefore(a[field], b[field])) {
      return 1;
    }
    if (isAfter(a[field], b[field])) {
      return -1;
    }
    return 0;
  }

  static hasProofofInsuranceWithEffective(x: PolicyDocument, field: string): boolean {
    const isProofOfInsuranceWithEffective =
      x &&
      x[field] &&
      x.metadata &&
      x.metadata['Content Description'] === 'PROOF OF INSURANCE CARD';
    if (isProofOfInsuranceWithEffective) {
      return true;
    }

    return false;
  }

  static hasCurrentEffectiveDate(x: PolicyDocument, field: string): boolean {
    const isProofOfInsuranceWithEffective = PolicyDocument.hasProofofInsuranceWithEffective(
      x,
      field
    );

    if (
      isProofOfInsuranceWithEffective &&
      (isBefore(x[field], new Date()) || isSameDay(x[field], new Date()))
    ) {
      return true;
    }
    return false;
  }

  static compareEffectiveDate(a: PolicyDocument, b: PolicyDocument): number {
    if (!a || !b) {
      return -1;
    }
    if (_get(a, 'effectiveDate', '').length && _get(b, 'effectiveDate', '').length) {
      const isAProofOfInsuranceWithEffective =
        PolicyDocument.hasProofofInsuranceWithEffective(a, 'effectiveDate') &&
        this.hasCurrentEffectiveDate(a, 'effectiveDate');

      const isBProofOfInsuranceWithEffective =
        PolicyDocument.hasProofofInsuranceWithEffective(b, 'effectiveDate') &&
        this.hasCurrentEffectiveDate(b, 'effectiveDate');

      if (!isAProofOfInsuranceWithEffective && !isBProofOfInsuranceWithEffective) {
        return 0;
      }
      if (!isAProofOfInsuranceWithEffective && isBProofOfInsuranceWithEffective) {
        return 1;
      }

      if (isAProofOfInsuranceWithEffective && !isBProofOfInsuranceWithEffective) {
        return -1;
      }

      if (isAProofOfInsuranceWithEffective && isBProofOfInsuranceWithEffective) {
        return this.compareDate(a, b, 'effectiveDate');
      }
    }

    return 0;
  }

  static isProofOfInsurance(a: PolicyDocument, b: PolicyDocument): number {
    const isAProofOfInsurance =
      a && a.metadata && a.metadata['Content Description'] === 'PROOF OF INSURANCE CARD';
    const isBProofOfInsurance =
      b && b.metadata && b.metadata['Content Description'] === 'PROOF OF INSURANCE CARD';

    if (isAProofOfInsurance && !isBProofOfInsurance) {
      return -1;
    }
    if (!isAProofOfInsurance && isBProofOfInsurance) {
      return 1;
    }

    return 0;
  }

  /**
   * @description This function sorts 2 documents based on effective date (checks if the date is current or not), date on  document and Proof of insurance,
   * For the testing purpose, play with this example: https://stackblitz.com/edit/sort-by-effective-current
   */

  static comparePOI(a: PolicyDocument, b: PolicyDocument): number {
    const compareEffectiveDate = PolicyDocument.compareEffectiveDate(a, b);
    if (compareEffectiveDate !== 0) {
      return compareEffectiveDate;
    }
    const compareDate = PolicyDocument.compareDate(a, b, 'documentDate');
    if (compareDate !== 0) {
      return compareDate;
    }

    return PolicyDocument.isProofOfInsurance(a, b);
  }
}
