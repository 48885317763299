<p class="subheading">Payment Method</p>
@if (paymentMethodOptions?.length > 0) {
  <div class="pad-top-20">
    <p>Saved payment methods</p>
    <ds-radio-group
      data-cy="paymentMethodOptionsRadioButtons"
      class="radio-group-container"
      [control]="'paymentMethod'"
      [parentFormGroup]="formGroup"
      [options]="paymentMethodOptions"
    >
    </ds-radio-group>
  </div>
}

@if (displayPaymentExpiredError) {
  <div class="row">
    <ds-error-box [messageText]="paymentExpiredErrorMessage"> </ds-error-box>
  </div>
}
@if (!!formGroup.get('paymentMethod').value) {
  <div class="row">
    <a (click)="editPaymentMethod()" [ngClass]="{ disabled: !authorizedToEditPaymentMethod }"
      >Edit payment method</a
    >
  </div>
}

@if (displayDiscountWarning) {
  <ds-warning-box [headingText]="autoPayWarningHeading" [messageText]="autoPayWarningBody">
  </ds-warning-box>
}
<ds-add-payment-method-quicklinks
  [authorizedToEditPaymentMethod]="authorizedToEditPaymentMethod"
  [authorizedToAddPaymentMethod]="authorizedToAddPaymentMethod"
  [displayAutoPayDiscountMessage]="true"
  [displayDiscountWarning]="displayDiscountWarning"
  [displayPaymentExpiredError]="displayPaymentExpiredError"
  [displayAutoPayDiscountMessage]="displayAutoPayDiscountMessage"
  [formGroup]="formGroup"
  [paymentMethodError]="paymentMethodError"
  [paymentMethodOptions]="paymentMethodOptions"
  (addCheckingAccountEvent)="addCheckingAccount()"
  (addCreditDebitEvent)="addCreditDebit()"
  (addSavingsAccountEvent)="addSavingsAccount()"
>
</ds-add-payment-method-quicklinks>
@if (paymentMethodError) {
  <div class="row">
    <ds-error-box [messageText]="choosePaymentMethod"> </ds-error-box>
  </div>
}
@if (paymentError) {
  <div class="row">
    <ds-error-box data-cy="paymentErrorMessage" [messageText]="paymentError"> </ds-error-box>
  </div>
}
