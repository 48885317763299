// safe-event-handler.directive.ts
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[safeEventHandler]'
})
export class SafeEventHandlerDirective implements OnInit {
  @Input() eventType: string;
  @Input() action: (event: any, id?: string) => void;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    const buttons = this.el.nativeElement.querySelectorAll('[clickable]');
    buttons.forEach(button => {
      this.renderer.listen(button, this.eventType, event => {
        this.action(event, button.getAttribute('data-id'));
      });
    });
  }
}
