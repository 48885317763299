<!-- Email -->
@if (showEmailOptions) {
  <div>
    <div class="row margin-bottom-12">
      <div class="column twelve text-left">
        <h3 content [contentPath]="'enrollment.verifyEmailConfirm'"></h3>
        <p><span content [contentPath]="'enrollment.verifyEmailContinueText'"></span></p>
      </div>
    </div>
    <ds-verify-email [maskedEmails]="emailList" (submittedEmailEvent)="sendEmail($event)">
    </ds-verify-email>
    @if (gotError && showEmailOptions) {
      <small
        class="block caption error margin-top-5"
        content
        [contentPath]="'enrollment.verifyEmailNotFound'"
      ></small>
    }
  </div>
}

<div class="row">
  <div class="column twelve">
    @if (showEmailSent) {
      <div>
        <p>
          <span content [contentPath]="'enrollment.verifyEmailSentPart1'"></span>{{ email
          }}<span content [contentPath]="'enrollment.verifyEmailSentPart2'"></span>
        </p>
        <button
          ds-button
          class="link"
          [disabled]="!isButtonActive"
          (click)="sendEmail(email)"
          content
          [contentPath]="'enrollment.verifyEmailResend'"
        ></button>
        @if (gotError) {
          <small
            class="block caption error margin-top-5"
            content
            [contentPath]="'enrollment.verifyEmailNotFound'"
          ></small>
        }
      </div>
    }

    @if (showOTP) {
      <div>
        <h3 content contentPath="enrollment.emailCode.confirmHeader"></h3>
        <p class="pad-bottom-10">
          <span content contentPath="enrollment.emailCode.confirmBody" [options]="{ email }"></span>
        </p>
        <p content contentPath="enrollment.emailCode.confirmCodeHeader"></p>
        <ds-verify-code-entry
          [confirmMethod]="'EMAIL OTP'"
          (submittedCodeEvent)="verifyCode($event)"
        >
        </ds-verify-code-entry>
        @if (codeNotFound) {
          <small
            data-cy="confirmationCodeNotFoundText"
            class="error-600-text margin-bottom-5"
            content
            contentPath="enrollment.emailCode.confirmCodeHeader"
          >
          </small>
        }
        <p class="caption">
          <button
            ds-button
            class="link"
            [disabled]="!isButtonActive"
            (click)="sendEmail(email)"
            content
            [contentPath]="'enrollment.verifyEmailResend'"
          ></button>
        </p>
        @if (gotError) {
          <small
            class="block caption error margin-top-5"
            content
            [contentPath]="'enrollment.verifyEmailNotFound'"
          ></small>
        }
      </div>
    }
    <a
      href="javascript:void(0)"
      (click)="goBackOneStep()"
      class="inline-block margin-top-20 caption"
      ><span class="icon-chevron-left"></span> Back</a
    >
  </div>
</div>
