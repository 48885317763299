import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, NativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// Third party
import { PolicyPipesModule } from '@amfam/policy/pipes';
import { PipesModule } from '@amfam/shared/ui/pipes';
import { BrandDirectivesModule } from '@amfam/shared/utility/brand';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';

import { TabEnabledLinkDirective } from './accessibility/tab-enabled-link.directive';
import { TabFocusDirective } from './accessibility/tab-focus.directive';
import { AgentComponent } from './agent/agent.component';
import { AmfamDsnAlertComponent } from './amfam-dsn-alert/amfam-dsn-alert.component';
import { AmfamDsnCardComponent } from './amfam-dsn-card/amfam-dsn-card.component';
import { BadgeComponent } from './badge/badge.component';
import { BannerComponent } from './banner/banner.component';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { CardComponent } from './card/card.component';
import { ColumnLayoutComponent } from './column-layout/column-layout.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { CriteriaListComponent } from './criteria-list/criteria-list.component';
import { CurrencyComponent } from './currency/currency.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DockingBarComponent } from './docking-bar/components/docking-bar/docking-bar.component';
import { AccordionComponent } from './ds-accordion/components/accordion.component';
import { AccordionCategoryComponent } from './ds-accordion/container/accordion-category.component';
import { DsAlertModalComponent } from './ds-alert-modal/alert-modal.component';
import { DsButtonComponent } from './ds-button/components/ds-button/ds-button.component';
import { DsCheckboxToggleComponent } from './ds-checkbox-toggle/ds-checkbox-toggle.component';
import { DsClicktipIconComponent } from './ds-clicktip/components/ds-clicktip-icon/ds-clicktip-icon.component';
import { DsClicktipComponent } from './ds-clicktip/components/ds-clicktip/ds-clicktip.component';
import { DsConfirmationCardAreaComponent } from './ds-confirmation/components/ds-confirmation-card-area/ds-confirmation-card-area.component';
import { DsConfirmationCtaAreaComponent } from './ds-confirmation/components/ds-confirmation-cta-area/ds-confirmation-cta-area.component';
import { DsConfirmationDetailsComponent } from './ds-confirmation/components/ds-confirmation-details/ds-confirmation-details.component';
import { DsConfirmationDisclaimerComponent } from './ds-confirmation/components/ds-confirmation-disclaimer/ds-confirmation-disclaimer.component';
import { DsConfirmationLinkAreaComponent } from './ds-confirmation/components/ds-confirmation-link-area/ds-confirmation-link-area.component';
import { DsConfirmationNextStepsComponent } from './ds-confirmation/components/ds-confirmation-next-steps/ds-confirmation-next-steps.component';
import { DsConfirmationComponent } from './ds-confirmation/ds-confirmation.component';
import { DsCardIconComponent } from './ds-credit-card-icon/ds-credit-card-icon.component';
import { DsDateCircleComponent } from './ds-date-circle/ds-date-circle.component';
import { DsErrorBoxComponent } from './ds-error-box/ds-error-box.component';
import { ErrorItemComponent } from './ds-error-message/components/error-item/error-item.component';
import { ErrorComponent } from './ds-error-message/containers/error.component';
import { DsErrorboxComponent } from './ds-errorbox/ds-errorbox.component';
import { DsLocalizedSpinnerComponent } from './ds-localized-spinner/ds-localized-spinner.component';
import { DsModalComponent } from './ds-modal/components/ds-modal/ds-modal.component';
import { DsProgressIndicatorCircleComponent } from './ds-progress-indicator-circle/components/ds-progress-indicator-circle/ds-progress-indicator-circle.component';
import { DsProgressIndicatorComponent } from './ds-progress-indicator/components/ds-progress-indicator/ds-progress-indicator.component';
import { DsRadioToggleButtonComponent } from './ds-radio-toggle-button/ds-radio-toggle-button.component';
import { DsStepCounterComponent } from './ds-step-counter/ds-step-counter.component';
import { DsSwiperComponent, DsSwiperSlideComponent } from './ds-swiper';
import { DsTableBodyCellComponent } from './ds-table/components/table-body/table-body-cell/table-body-cell.component';
import { DsTableBodyRowWrapperComponent } from './ds-table/components/table-body/table-body-row-wrapper/table-body-row-wrapper.component';
import { DsTableBodyRowComponent } from './ds-table/components/table-body/table-body-row/table-body-row.component';
import { DsTableBodyComponent } from './ds-table/components/table-body/table-body.component';
import { DsTableHeaderCellComponent } from './ds-table/components/table-header/table-header-cell/table-header-cell.component';
import { DsTableHeaderComponent } from './ds-table/components/table-header/table-header.component';
import { DsTableComponent } from './ds-table/containers/ds-table/ds-table.component';
import { DsTableResizeWatcherDirective } from './ds-table/ds-table-resize-watcher.directive';
import { DsTileComponent } from './ds-tile/ds-tile.component';
import { DsWarningBoxComponent } from './ds-warning-box/ds-warning-box.component';
import { FindVinModalComponent } from './find-vin-modal/find-vin-modal.component';
import { FlipCardComponent } from './flip-card/flip-card.component';
import { DsCheckboxGroupComponent } from './form-controls/components/ds-checkbox-group/ds-checkbox-group.component';
import { DsFormCharacterCounterComponent } from './form-controls/components/ds-form-character-counter/ds-form-character-counter.component';
import { DsFormControlContainerComponent } from './form-controls/components/ds-form-control-container/ds-form-control-container.component';
import { DsFormControlMessageComponent } from './form-controls/components/ds-form-control-message/ds-form-control-message.component';
import { DsRadioGroupComponent } from './form-controls/components/ds-radio-group/ds-radio-group.component';
import { DsTextAreaComponent } from './form-controls/components/ds-text-area/ds-text-area.component';
import { FreeFormQuestionComponent } from './forms/free-form-question/free-form-question.component';
import { MultipleSelectQuestionComponent } from './forms/multiple-select-question/multiple-select-question.component';
import { SingleSelectQuestionComponent } from './forms/single-select-question/single-select-question.component';
import { InfoBannerComponent } from './info-banner/info-banner.component';
import { JumpLinkComponent } from './jump-link/jump-link.component';
import { JumpLinksComponent } from './jump-links/jump-links.component';
import { KeyValueCardComponent } from './key-value-card/key-value-card.component';
import { LoadingSpinnerComponent } from './loading-spinner/components/loading-spinner.component';
import { PaymentMethodBlockComponent } from './payment-method-block/payment-method-block.component';
import { PaymentMethodCardComponent } from './payment-method-card/payment-method-card.component';
import { PolicyCardComponent } from './policy-card/policy-card.component';
import { PremiumCardComponent } from './premium-card/premium-card.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { QuoteCardComponent } from './quote-card/quote-card.component';
import { IsRenderedDirective } from './renderer/isrendered.directive';
import { SelectCardComponent } from './select-card/select-card.component';
import { SidebarComponent } from './side-bar/sidebar.component';
import { SidebarService } from './side-bar/sidebar.service';
import { SimpleCardComponent } from './simple-card/simple-card.component';
import { TooltipModule } from './tooltip/tooltip.module';
import { UnderlyingPolicyButtonComponent } from './underlyingPolicyButton/underlying-policy-button.component';
import { VehicleCardComponent } from './vehicle-card/vehicle-card.component';
import { EarlyExitModalComponent } from './vehicle-early-exit-modal/early-exit-modal.component';

@NgModule({
  declarations: [
    DsFormControlContainerComponent,
    DsFormControlMessageComponent,
    DsFormCharacterCounterComponent,
    DsConfirmationComponent,
    DsConfirmationCtaAreaComponent,
    DsConfirmationDetailsComponent,
    DsConfirmationLinkAreaComponent,
    DsConfirmationNextStepsComponent,
    DsConfirmationDisclaimerComponent,
    DsConfirmationCardAreaComponent,
    DsTableComponent,
    DsTableBodyComponent,
    DsTableBodyRowComponent,
    DsTableBodyCellComponent,
    DsTableHeaderComponent,
    DsTableHeaderCellComponent,
    DsTableBodyRowWrapperComponent,
    DsTableResizeWatcherDirective,
    DsButtonComponent,
    DsProgressIndicatorCircleComponent,
    DockingBarComponent,
    DsLocalizedSpinnerComponent,
    DsModalComponent,
    DsCheckboxGroupComponent,
    DsRadioGroupComponent,
    DsTextAreaComponent,
    LoadingSpinnerComponent,
    SidebarComponent,
    ErrorComponent,
    ErrorItemComponent,
    AccordionCategoryComponent,
    AccordionComponent,
    DsDateCircleComponent,
    DsProgressIndicatorComponent,
    InfoBannerComponent,
    DsTileComponent,
    BannerComponent,
    CardComponent,
    DsClicktipComponent,
    DsClicktipIconComponent,
    DsCheckboxToggleComponent,
    BadgeComponent,
    CallToActionComponent,
    FreeFormQuestionComponent,
    MultipleSelectQuestionComponent,
    SingleSelectQuestionComponent,
    DsRadioToggleButtonComponent,
    TabFocusDirective,
    TabEnabledLinkDirective,
    DsCardIconComponent,
    PaymentMethodBlockComponent,
    ColumnLayoutComponent,
    CriteriaListComponent,
    DsWarningBoxComponent,
    ConfirmationComponent,
    DsStepCounterComponent,
    DsAlertModalComponent,
    DsErrorBoxComponent,
    EarlyExitModalComponent,
    FindVinModalComponent,
    PremiumCardComponent,
    QuoteCardComponent,
    CurrencyComponent,
    DsErrorboxComponent,
    PaymentMethodCardComponent,
    FlipCardComponent,
    SelectCardComponent,
    DsSwiperComponent,
    DsSwiperSlideComponent,
    AmfamDsnAlertComponent,
    AmfamDsnCardComponent,
    ProfileCardComponent,
    AgentComponent,
    PolicyCardComponent,
    VehicleCardComponent,
    DatePickerComponent,
    SimpleCardComponent,
    KeyValueCardComponent,
    JumpLinksComponent,
    JumpLinkComponent,
    UnderlyingPolicyButtonComponent,
    IsRenderedDirective
  ],
  exports: [
    DsFormControlContainerComponent,
    DsFormControlMessageComponent,
    DsFormCharacterCounterComponent,
    DsButtonComponent,
    DsProgressIndicatorCircleComponent,
    DsProgressIndicatorComponent,
    DockingBarComponent,
    DsConfirmationComponent,
    DsConfirmationCtaAreaComponent,
    DsConfirmationDetailsComponent,
    DsConfirmationLinkAreaComponent,
    DsConfirmationNextStepsComponent,
    DsConfirmationDisclaimerComponent,
    DsConfirmationCardAreaComponent,
    DsTableComponent,
    DsTableResizeWatcherDirective,
    DsLocalizedSpinnerComponent,
    DsModalComponent,
    DsCheckboxGroupComponent,
    DsRadioGroupComponent,
    DsTextAreaComponent,
    LoadingSpinnerComponent,
    SidebarComponent,
    ErrorComponent,
    ErrorItemComponent,
    AccordionCategoryComponent,
    AccordionComponent,
    DsDateCircleComponent,
    InfoBannerComponent,
    DsTileComponent,
    BannerComponent,
    CardComponent,
    DsClicktipComponent,
    DsClicktipIconComponent,
    DsCheckboxToggleComponent,
    BadgeComponent,
    CallToActionComponent,
    FreeFormQuestionComponent,
    MultipleSelectQuestionComponent,
    SingleSelectQuestionComponent,
    DsRadioToggleButtonComponent,
    TabFocusDirective,
    TabEnabledLinkDirective,
    DsCardIconComponent,
    PaymentMethodBlockComponent,
    ColumnLayoutComponent,
    CriteriaListComponent,
    DsWarningBoxComponent,
    ConfirmationComponent,
    DsStepCounterComponent,
    DsAlertModalComponent,
    DsErrorBoxComponent,
    EarlyExitModalComponent,
    FindVinModalComponent,
    PremiumCardComponent,
    QuoteCardComponent,
    CurrencyComponent,
    DsErrorboxComponent,
    PaymentMethodCardComponent,
    FlipCardComponent,
    SelectCardComponent,
    DsSwiperComponent,
    DsSwiperSlideComponent,
    AmfamDsnAlertComponent,
    AmfamDsnCardComponent,
    ProfileCardComponent,
    TooltipModule,
    AgentComponent,
    PolicyCardComponent,
    VehicleCardComponent,
    DatePickerComponent,
    SimpleCardComponent,
    KeyValueCardComponent,
    JumpLinksComponent,
    JumpLinkComponent,
    UnderlyingPolicyButtonComponent,
    IsRenderedDirective
  ],
  providers: [SidebarService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SharedServicesModule,
    PipesModule,
    PolicyPipesModule,
    TooltipModule,
    BrandDirectivesModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NativeDateModule
  ]
})
export class UiKitModule {}
