import { Party } from '@amfam/shared/models';
import { Action, createAction } from '@ngrx/store';
import { UserLogin, UserUpdate } from './user.model';

export enum UserActionTypes {
  LoginSuccess = '[User] Login Success',
  UpdatePartyType = '[User] Update Party',
  UpdateUserType = '[User] Update User',
  EditUserId = '[User] Edit User Id',
  UserInfoSuccess = '[User] UserInfo Success',
  UpdateUserEmail = '[User] Update User Email',
  EditSmImpersonatrUserId = '[User] Edit SM Impersonator User ID',
  EditCustomerId = '[User] Edit Customer ID',
  EditWAID = '[User] Edit WAID',
  EditExperienceId = '[User] Edit Experience ID'
}

export class LoginSuccess implements Action {
  readonly type = UserActionTypes.LoginSuccess;
  constructor(public payload: UserLogin) {}
}

export const UpdateParty = createAction(UserActionTypes.UpdatePartyType, (payload: Party) => ({
  payload
}));

export const UpdateUser = createAction(UserActionTypes.UpdateUserType, (payload: UserUpdate) => ({
  payload
}));

export class EditUserId implements Action {
  readonly type = UserActionTypes.EditUserId;
  constructor(public payload: string) {}
}

export class UserInfoSuccess implements Action {
  readonly type = UserActionTypes.UserInfoSuccess;
  constructor(public payload: UserLogin) {}
}

export class UpdateUserEmail implements Action {
  readonly type = UserActionTypes.UpdateUserEmail;
  constructor(public payload: string) {}
}

export class EditSmImpersonatrUserId implements Action {
  readonly type = UserActionTypes.EditSmImpersonatrUserId;
  constructor(public payload: string) {}
}

export class EditCustomerId implements Action {
  readonly type = UserActionTypes.EditCustomerId;
  constructor(public payload: string) {}
}

export class EditWAID implements Action {
  readonly type = UserActionTypes.EditWAID;
  constructor(public payload: string) {}
}

export class EditExperienceId implements Action {
  readonly type = UserActionTypes.EditExperienceId;
  constructor(public payload: string) {}
}

export type UserAction =
  | LoginSuccess
  | ReturnType<typeof UpdateParty>
  | ReturnType<typeof UpdateUser>
  | EditUserId
  | UserInfoSuccess
  | UpdateUserEmail
  | EditSmImpersonatrUserId
  | EditCustomerId
  | EditWAID
  | EditExperienceId;

export const fromUserActions = {
  LoginSuccess,
  UpdateParty,
  UpdateUser,
  EditUserId,
  UserInfoSuccess,
  UpdateUserEmail,
  EditSmImpersonatrUserId,
  EditCustomerId,
  EditWAID,
  EditExperienceId
};
