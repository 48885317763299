<form [formGroup]="policiesPreferencesForm" class="preferences-row fill">
  <div class="row">
    <div class="column twelve flex flex-direction-column md-flex-direction-row">
      <div class="row flex grow">
        <div class="flex wrap"><div class="fill" data-cy="policyDocumentsHeader">Policy Documents</div></div>
      </div>
      <div class="row policy preference-options">
        <div class="column preference-options-item">
          <fieldset
            [disabled]="
              !(roleService.isAuthorized('edit_communication_prefrences') | async) ||
              adminPartnerDisabled
            "
          >
            <input type="radio" value="false" id="paper" formControlName="policiesPreferenceCtrl" data-cy="policiesPreferenceCtrlPaper" />
          </fieldset>
          <label class="block md-none" for="paper" data-cy="policyPaper">Paper</label>
        </div>
        <div class="column preference-options-item">
          <fieldset
            [disabled]="
              !(roleService.isAuthorized('edit_communication_prefrences') | async) ||
              adminPartnerDisabled
            "
          >
            <input type="radio" value="true" id="email" formControlName="policiesPreferenceCtrl" data-cy="policiesPreferenceCtrlEmail"/>
          </fieldset>
          <label class="block md-none" for="email" data-cy="userEmail">Email</label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="enableWarningComponent" class="row">
    <div class="column twelve">
      <ds-preferences-warning
        [deliveryPreference]="policiesPreferencesForm.controls['policiesPreferenceCtrl'].value"
      ></ds-preferences-warning>
    </div>
  </div>
</form>
