<div class="main row container">
  <div class="column twelve lg-eight lg-push-two md-eight md-push-two">
    <confirmation
      [config]="confirmationData$ | async"
      (primaryEvent)="submit()"
      (tertiaryEvent)="done()"
      ><div class="bottom-border">
        <div content>
          <p class="margin-bottom-20">{{ messageBodyText }}</p>
        </div>
        @if (subHeading) {
          <div class="margin-bottom-20">
            <h2 class="subHeading">{{ subHeading }}</h2>
            <div class="payment-card">
              <ds-payment-method-card
                class="cell-component"
                [paymentAccount]="paymentMethod"
              ></ds-payment-method-card>
            </div>
          </div>
        }
        <!-- affected payments -->
        @if (
          deletePaymentMethodInfo && (scheduledPayments.length > 0 || automaticPayments.length > 0)
        ) {
          <div>
            <!-- affected scheduled payments -->
            @if (scheduledPayments.length > 0) {
              <div class="payments">
                <h2 class="pad-bottom-10 subHeading">One-time payments</h2>
                @for (payment of scheduledPayments; track payment) {
                  <ds-scheduled-payments-detail
                    [payment]="payment"
                    [paymentAccount]="paymentMethod"
                  ></ds-scheduled-payments-detail>
                }
              </div>
            }
            <!-- affected automatic payments -->
            @if (automaticPayments.length > 0) {
              <div class="payments">
                <h2 class="pad-bottom-10 subHeading">AutoPay</h2>
                @for (payment of automaticPayments; track payment) {
                  <ds-scheduled-payments-detail
                    [payment]="payment"
                    [paymentAccount]="paymentMethod"
                  ></ds-scheduled-payments-detail>
                }
              </div>
            }
          </div>
        }
      </div>
    </confirmation>
  </div>
</div>
