import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ClaimGlassOnly } from '../models/claim-glass-only.model';
import { CLAIMGLASSONLY_FEATURE_KEY } from './claim-glass-only.reducer';

// Lookup the 'ClaimGlassOnly' feature state managed by NgRx
const getClaimGlassOnlyState = createFeatureSelector<ClaimGlassOnly>(CLAIMGLASSONLY_FEATURE_KEY);

export const claimGlassOnlyQuery = {
  getClaimGlassOnlyState
};
