import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { get as _get } from 'lodash';

import { PolicyDocument } from '@amfam/policy/models';

import { DocumentsAction, DocumentsActionTypes } from './documents.actions';

export const DOCUMENTS_FEATURE_KEY = 'documents';

/**
 * Interface for the 'Documents' data used in
 *  - DocumentsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* eslint-disable @typescript-eslint/no-empty-interface */

export interface PolicyDocumentsPartialState {
  readonly [DOCUMENTS_FEATURE_KEY]: PolicyDocumentsEntityState;
}

export interface PolicyDocumentsEntityState extends EntityState<PolicyDocument> {
  selectedID: string;
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  error: unknown;
}

export const adapter: EntityAdapter<PolicyDocument> = createEntityAdapter<PolicyDocument>({
  selectId: (policyObjModel: PolicyDocument) => policyObjModel.id,
  sortComparer: false
});

export const initialState: PolicyDocumentsEntityState = adapter.getInitialState({
  selectedID: '',
  loading: false,
  loaded: false,
  hasError: false,
  error: null
});

export function reducer(
  state: PolicyDocumentsEntityState = initialState,
  action: DocumentsAction
): PolicyDocumentsEntityState {
  switch (action.type) {
    case DocumentsActionTypes.LoadDocumentsSuccessType: {
      return adapter.upsertMany(_get(action, 'payload', []), state);
    }

    case DocumentsActionTypes.DocumentViewType:
    case DocumentsActionTypes.DocumentDismissNotificationType: {
      return adapter.upsertOne(
        {
          ...state.entities[action.payload],
          isNew: false
        },
        state
      );
    }
    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
