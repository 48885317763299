import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ds-error-box',
  templateUrl: './ds-error-box.component.html',
  styleUrls: ['./ds-error-box.component.scss']
})
export class DsErrorBoxComponent implements OnInit {
  @Input() messageText: string;

  constructor() {}

  ngOnInit(): void {}
}
