<li
  [@showNavItem]="showNavItem"
  (@showNavItem.done)="focusPreviousElem()"
  class="mobile-nav-item"
  data-cy="mobileNavigationMenuItem"
>
  @if (!link?.linkExternalUrl) {
    <a
      href="javascript:void(0)"
      [ngClass]="link?.linkClasses"
      [attr.aria-label]="link.linkText"
      [attr.data-cy]="link?.mobileLinkTestId"
      (click)="linkClick($event)"
      (blur)="onLinkBlur()"
    >
      <div class="mobile-nav-item-link">
        <div class="mobile-link-content">
          @if (!!link?.linkIcon) {
            <span
              class="link-content-icon"
              role="img"
              alt=""
              [attr.title]="link.linkText"
              [ngClass]="link?.linkIcon"
            ></span>
          }
          <span [class.overflow-ellipsis]="link?.overflowEllipsis" class="link-text">{{
            link.linkText
          }}</span>
          @if (!!link?.subLinkArray?.length) {
            <span role="presentation" class="link-content-children-icon icon-chevron-right"></span>
          }
        </div>
      </div>
    </a>
  }
  @if (!!link?.linkExternalUrl) {
    <a
      [href]="link?.linkExternalUrl"
      [ngClass]="link?.linkClasses"
      [href]="link?.linkExternalUrl"
      [attr.aria-label]="link.linkText"
      [attr.target]="link?.linkTarget"
      [attr.data-cy]="link?.mobileLinkTestId"
      (click)="linkClick($event)"
      (blur)="onLinkBlur()"
    >
      <div class="mobile-link-content">
        @if (!!link?.linkIcon) {
          <span
            class="link-content-icon"
            role="img"
            alt=""
            [attr.title]="link.linkText"
            [ngClass]="link?.linkIcon"
          ></span>
        }
        <span [class.overflow-ellipsis]="link?.overflowEllipsis" class="link-text">{{
          link.linkText
        }}</span>
      </div>
    </a>
  }
</li>
@if (!!link?.subLinkArray?.length) {
  <ul
    class="mobile-subnav"
    [@showSubmenu]="showSub"
    (@showSubmenu.done)="focusSublinkTitle()"
    [attr.data-cy]="link?.mobileSubmenuTestId"
  >
    <li class="mobile-subnav-title">
      <a #sublinkTitle href="javascript:void(0)" (click)="sublinkTitleClick()">
        <span class="icon-chevron-left pad-right-10"></span>
        <span>{{ link.mobileAltSubMenuTitle || link.linkText }}</span>
      </a>
    </li>
    @for (sublink of link?.subLinkArray; track trackByFn($index, sublink)) {
      <li
        #subLinkElemItem
        authCheck
        class="mobile-subnav-item"
        [ngClass]="sublink?.listClasses"
        [style.maxWidth.px]="sublink?.maxWidth"
        [permissionName]="sublink?.permissionName"
        [checkAction]="sublink?.checkAction"
        (blur)="resetOnBlur($event.currentTarget.parent)"
      >
        @if (!sublink?.linkExternalUrl) {
          <a
            href="javascript:void(0)"
            [ngClass]="sublink?.linkClasses"
            [dsRouterLink]="sublink?.linkRoute"
            [queryParams]="sublink?.queryParams"
            [attr.aria-label]="sublink?.linkText"
            [attr.data-cy]="sublink?.mobileLinkTestId"
            (click)="sublinkClick(sublink, $event)"
            (blur)="resetOnBlur($event.currentTarget.parentNode)"
          >
            <div class="mobile-link-content">
              @if (!!sublink?.linkIcon) {
                <span
                  class="link-content-icon"
                  role="img"
                  alt="Link Icon"
                  [attr.title]="sublink?.linkText"
                  [ngClass]="sublink?.linkIcon"
                ></span>
              }
              <span [class.overflow-ellipsis]="sublink?.overflowEllipsis" class="link-text">{{
                sublink.linkText
              }}</span>
            </div>
          </a>
        }
        @if (!!sublink?.linkExternalUrl) {
          <a
            [ngClass]="sublink?.linkClasses"
            [href]="sublink?.linkExternalUrl"
            [attr.aria-label]="sublink?.linkText"
            [attr.target]="sublink?.linkTarget"
            [attr.data-cy]="sublink?.mobileLinkTestId"
            (click)="sublinkClick(sublink, $event)"
            (blur)="resetOnBlur($event.currentTarget.parentNode)"
          >
            <div class="mobile-link-content">
              @if (!!sublink?.linkIcon) {
                <span
                  class="link-content-icon"
                  role="img"
                  alt="Link Icon"
                  [attr.title]="sublink?.linkText"
                  [ngClass]="sublink?.linkIcon"
                ></span>
              }
              <span [class.overflow-ellipsis]="sublink?.overflowEllipsis" class="link-text">{{
                sublink.linkText
              }}</span>
            </div>
          </a>
        }
      </li>
    }
  </ul>
}
