import { Component, Input, OnInit } from '@angular/core';
import { find as _find, get as _get } from 'lodash';

import { ProgressIndicatorList } from '../../models/progress-indicator-list';

@Component({
  selector: 'ds-progress-indicator',
  templateUrl: './ds-progress-indicator.component.html',
  styleUrls: ['./ds-progress-indicator.component.scss']
})
export class DsProgressIndicatorComponent implements OnInit {
  @Input() steps: ProgressIndicatorList[];
  @Input() mini: Boolean = false;
  @Input() progressTitle = '';
  public activeStep: any;

  constructor() {}

  ngOnInit() {
    this.steps = this.filterSteps(this.steps);
    this.activeStep = this.getActiveStep(this.steps);
  }

  filterSteps(steps) {
    // If the steps are coming from the workflow, they will not contain the show property
    if (_find(steps, 'show')) {
      return steps.filter(step => {
        return _get(step, 'show') === true;
      });
    } else {
      return steps;
    }
  }

  getActiveStep(steps) {
    // Isolate the active step title for the mini view
    const activeStep = steps.find(step => {
      return _get(step, 'active') === true;
    });
    return activeStep;
  }

  activeStepStatus(step) {
    if (step.skipped) {
      return step.title + ' Skipped';
    } else if (step.complete && !step.skipped) {
      return step.title + ' Complete';
    } else if (step.active && !step.complete) {
      return step.title + ' Active';
    } else {
      return step.title + ' Not Complete';
    }
  }
}
