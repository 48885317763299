import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ds-column-layout',
  templateUrl: './column-layout.component.html',
  styleUrls: ['./column-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnLayoutComponent implements OnInit {
  @Input() numberOfColumns: 'two' | 'three';

  constructor() {}

  ngOnInit(): void {}
}
