import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[dsPhoneMaskWithoutForm]'
})
export class PhoneMaskWithoutFormDirective implements AfterViewInit {
  @Input() identifier: string;
  @Output() actualvalue: EventEmitter<string> = new EventEmitter<string>();
  protected separator = '-';
  private model: ElementRef;
  private preValue;

  constructor(
    el: ElementRef,
    private renderer: Renderer2
  ) {
    this.model = el;
  }

  @HostListener('ngModelChange', ['$event'])
  onNgModelChange(currentValue: string) {
    this.updateValue(currentValue === undefined ? '' : currentValue);
  }
  @HostListener('input')
  onInput() {
    const currentValue = this.model.nativeElement.value ? this.model.nativeElement.value : '';

    this.updateValue(currentValue);
  }

  ngAfterViewInit() {
    this.preValue = this.model.nativeElement.value ? this.model.nativeElement.value : '';
  }

  // updates the actual and displayed values
  updateValue(data?: string) {
    const preInputValue = this.preValue || '';
    const lastChar = preInputValue.substr(preInputValue.length - 1);

    // remove all mask characters (keep only numeric)
    let newVal = data.replace(/\D/g, '');

    // store start & end index
    let start = this.renderer.selectRootElement('#' + this.identifier).selectionStart;
    let end = this.renderer.selectRootElement('#' + this.identifier).selectionEnd;

    if (data.length < preInputValue.length) {
      //removing value from input
      if (preInputValue.length < start) {
        // while removing if we encounter '-' character then remove the last digit too
        if (lastChar === '-') {
          newVal = newVal.substr(0, newVal.length - 1);
        }
      }
      //if no number then keep ''
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length > 3 && newVal.length <= 6) {
        //  when removing, we change pattern match.
        //  otherwise deleting of non-numeric characters is not recognized
        newVal = newVal.replace(/^(\d{0,3})/, '$1-');
      } else if (newVal.length > 6 && newVal.length <= 9) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2-');
      } else if (newVal.length > 9) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
      }
      this.actualvalue.emit(this.getActualValue(newVal));

      this.model.nativeElement.value = newVal;

      // keep cursor the normal position after setting the input above.
      this.renderer.selectRootElement('#' + this.identifier).setSelectionRange(start, end);
    } else {
      // when typed value in input
      const removedD = data.charAt(start);

      // don't show '-' for empty value
      if (newVal.length === 0) {
        newVal = '';
      }
      // show '-' after 3 digits
      else if (newVal.length > 3 && newVal.length <= 6) {
        newVal = newVal.replace(/^(\d{0,3})/, '$1-');
      } else if (newVal.length > 6 && newVal.length <= 9) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2-');
      } else if (newVal.length > 9) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
      }

      // check typing whether in middle or not
      // in the following case, you are typing in the middle
      if (preInputValue.length >= start) {
        // change cursor according to special chars.
        if (removedD === '-') {
          start = start + 1;
          end = end + 1;
        }
        this.actualvalue.emit(this.getActualValue(newVal));
        this.model.nativeElement.value = newVal;
        this.renderer.selectRootElement('#' + this.identifier).setSelectionRange(start, end);
      } else {
        this.actualvalue.emit(this.getActualValue(newVal));
        this.model.nativeElement.value = newVal;
        this.renderer
          .selectRootElement('#' + this.identifier)
          .setSelectionRange(start + 2, end + 2); // +2 because of wanting standard typing
      }
    }
    this.preValue = this.model.nativeElement.value;
  }

  // Returns the masked value
  getMaskedValue(actualValue: string): string {
    let maskedValue = '';

    const unmaskedValue = actualValue.replace(/\D+/g, '');
    for (let i = 0; i < unmaskedValue.length && i < 12; i++) {
      if (i === 3 || i === 6) {
        maskedValue += this.separator;
      }

      maskedValue += unmaskedValue[i];
    }

    if (actualValue.endsWith(this.separator)) {
      if (maskedValue.length === 3 || maskedValue.length === 7) {
        maskedValue += this.separator;
      }
    }

    return maskedValue;
  }

  // Returns the actual (unmasked) value
  getActualValue(currentValue: string): string {
    let unmaskedValue = '';
    if (currentValue) {
      unmaskedValue = currentValue.replace(/\D+/g, '');
    }

    return unmaskedValue;
  }
}
