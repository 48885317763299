import { Action } from '@ngrx/store';

import { ImpersonationState } from './impersonation.model';

export const SET_ROLES = '[Impersonation] Set Roles';
export const SET_ROLES_SUCCESS = '[Impersonation] Set Roles Success';
export const SET_ROLES_FAIL = '[Impersonation] Set Roles Fail';

export class SetRolesSuccessAction implements Action {
  readonly type = SET_ROLES_SUCCESS;
  constructor(public payload: ImpersonationState) {}
}

export class SetRolesAction implements Action {
  readonly type = SET_ROLES;
  constructor(public payload?: any) {}
}

export class SetRolesFailAction implements Action {
  readonly type = SET_ROLES_FAIL;
  constructor(public payload: Error) {}
}

export const fromImpersonationActions = {
  SetRolesSuccessAction,
  SetRolesAction,
  SetRolesFailAction
};

export type ImpersonationActions = SetRolesAction | SetRolesSuccessAction | SetRolesFailAction;
