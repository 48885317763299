@if (!initialLoading) {
  <div class="row">
    <div class="column twelve">
      <div class="row margin-bottom-20">
        <h1 content [contentPath]="'auth.resetOptionsEmailHeader'"></h1>
        <p>
          <span content [contentPath]="'auth.resetOptionsEmailText'"></span
          ><strong>{{ maskedEmail }}</strong
          >.
        </p>
      </div>
      <div class="row margin-bottom-10 margin-top-20">
        <small content [contentPath]="'auth.resetOptionsCheckSpam'"></small>
      </div>
      <button
        ds-button
        class="link caption"
        (click)="sendEmail()"
        content
        [contentPath]="'enrollment.verifyEmailResend'"
      ></button>
      @if (gotError) {
        <small
          class="block caption error margin-top-10"
          content
          [contentPath]="'enrollment.verifyEmailNotFound'"
        ></small>
      }
      <div class="row margin-top-40">
        <a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block caption"
          ><em class="icon-chevron-left"></em> Back</a
        >
      </div>
    </div>
  </div>
}
