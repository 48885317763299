import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import { AnalyticsActions, AnalyticsFacade } from '@amfam/shared/analytics';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import {
  featureFlagQuery,
  FeatureFlagService
} from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ConfigService, CookiesService, CopyService } from '@amfam/shared/utility/shared-services';
import { BannerConfig, LoadingSpinnerService } from '@amfam/ui-kit';

import { ForgerockAuthService } from '../../core/auth/forgerock-auth.service';
import * as fromStore from '../../core/store';
import * as sessionActions from '../../core/store/session/session.actions';
import { LoginAnalytics } from '../shared/login-constants';

@Component({
  selector: 'ds-login-base',
  templateUrl: './login-base.component.html',
  styleUrls: ['../login-form/login-form.component.scss', './login-base.component.scss']
})
export class LoginBaseComponent implements OnInit {
  cookiesEnabled = true;
  cookieCheckFeatureEnabled$: Observable<boolean>;
  responseCode = 0;
  emailRedirect = false;
  duplicateEmailBannerConfig: BannerConfig;
  featureFlagLoaded$ = this.store.select(featureFlagQuery.selectHasFeatureFlag);
  isForgeRockEnabled$ = this.featureFlagLoaded$.pipe(
    filter(loaded => !!loaded),
    map(() => this.featureFlagService.isEnabled('forgerock'))
  );

  isHideMyAccountSignup$ = this.featureFlagLoaded$.pipe(
    filter(loaded => !!loaded),
    map(() => this.featureFlagService.isEnabled('hide_myaccount_signup'))
  );
  primaryButtonName: string;
  secondaryButtonName: string;
  private validateEmailPath: string;

  constructor(
    private store: Store<fromStore.RootState>,
    private cookiesService: CookiesService,
    private config: ConfigService,
    private route: ActivatedRoute,
    private analyticsFacade: AnalyticsFacade,
    private featureFlagService: FeatureFlagService,
    private forgerockService: ForgerockAuthService,
    private copyService: CopyService,
    public spinner: LoadingSpinnerService,
    private titleService: Title
  ) {
    // did user try to login from amfam.com
    route.data.subscribe(data => {
      if (data['invalid']) {
        this.store.dispatch(
          new sessionActions.LoginUserFailAction({
            status: { code: 401, reason: 'invalid credentials' }
          })
        );
      }
    });

    this.validateEmailPath = this.config.get('profileValidateEmailPath');
  }

  ngOnInit() {
    this.setTitle();
    this.cookiesEnabled = this.cookiesService.cookiesEnabled();
    if (!this.cookiesEnabled) {
      this.store.dispatch(
        AnalyticsActions.sendDynatraceAction({
          payload: {
            actionName: 'exception',
            actionType: 'Cookies Disabled' + '|' + 'login form'
          }
        })
      );
    }
    this.cookieCheckFeatureEnabled$ = this.store.pipe(
      select(featureFlagQuery.selectFeatureFlag('cookie_check')),
      map(cookieCheck => !!cookieCheck && cookieCheck.enabled)
    );
    this.analyticsFacade.trackPage(LoginAnalytics.loginAnalytic);
    this.primaryButtonName = this.copyService.getCopy('auth.forgerock.signIn');
    this.secondaryButtonName = this.copyService.getCopy('auth.forgerock.createAccount');
  }

  responseReceived(responseCode: number) {
    if (responseCode === 409) {
      this.analyticsFacade.trackPage(LoginAnalytics.loginMultipleEmailErrorAnalytic);
      this.duplicateEmailBannerConfig = {
        headingTextPath: 'overview.duplicateEmailError'
      };
    }
    this.responseCode = responseCode;
  }
  async login() {
    this.spinner.start();
    this.analyticsFacade.trackEvent({
      event: 'secure_sign_in_start'
    });

    const acrValues = this.config.get('forgerock.login');
    await this.getForgerockToken(acrValues);
  }
  async registration() {
    this.spinner.start();
    const acrValues = this.config.get('forgerock.registration');
    localStorage.setItem('frFlowType', 'enrollment');
    await this.getForgerockToken(acrValues);
  }

  private async getForgerockToken(acrValues: string) {
    try {
      const tokens = await this.forgerockService.getTokens(acrValues, false);

      if (tokens) {
        this.store.dispatch(fromRouterActions.Go({ path: ['overview'] }));
      }
      return true;
    } catch (err) {
      // User likely not authenticated
      datadogRum.addError('Error at login/registration:' + err);
    }
  }

  private setTitle() {
    combineLatest([
      this.store.select(BrandSelectors.selectIsPartner),
      this.store.select(BrandSelectors.selectCompany)
    ])
      .pipe(
        filter(([isPartner, partner]) => isPartner && !!partner),
        first(),
        tap(([, partner]) => {
          this.titleService.setTitle(this.copyService.getCopy('auth.loginTitle', { partner }));
        })
      )
      .subscribe();
  }
}
