<h4 *ngIf="paymentMethodType" class="pad-top-20">Payment Authorization</h4>
<blockquote
  *ngIf="paymentMethodType"
  class="border -{{ borderColor }} caption"
  [ngSwitch]="paymentMethodType"
>
  <span *ngSwitchCase="'Card'" [innerHtml]="(cardAuthText | async)?.richContent" data-cy="cardAuthText"> </span>
  <span *ngSwitchCase="'Bank'" [innerHtml]="(achAuthText | async)?.richContent" data-cy="achAuthText"> </span>
  <span *ngSwitchCase="'autopayCard'" [innerHtml]="(autopayCardAuthText | async)?.richContent" data-cy="autopayCardAuthText">
  </span>
  <span *ngSwitchCase="'autopayBank'" [innerHtml]="(autopayACHAuthText | async)?.richContent" data-cy="autopayACHAuthText">
  </span>
  <span *ngSwitchDefault>
    <span data-cy="authTextOne">
      By clicking “Submit” I am providing my written authorization to allow American Family Mutual
      Insurance Company, S.I. or any of its
      <a href="https://www.amfam.com/companyid" target="_blank">subsidiary companies</a> to directly
      deduct payment as early as today from my checking or savings account or charge this payment as
      early as today from my debit/credit card. I agree to not receive notifications if my draw
      amount is less than my statement amount due to a: (1) policy cancellation; (2) premium change;
      or (3) change to my draw date.
    </span>
    <br />
    <br />
    <span data-cy="authTextTwo">
      A return bank fee of $25.00 may be charged if the payment is returned. To stop a scheduled
      payment, automatic payments or stop paying electronically, log into My Account at
      <span *dsPartnerAFI>amfam.com</span><span *dsPartnerConnect>midvaleinsurance.com</span> or
      contact <span *dsPartnerConnect>CONNECT, powered by </span> American Family Insurance at
      <span dsPartnerContent [brandContent]="'getPrettyCusCareNumber'"></span>.
    </span>
  </span>
  <!-- The data has not come back yet -->
  <ds-localized-spinner
    class="block pad-bottom-20"
    [dataLoading]="loading"
    [loadingMessage]="'Looking for payment authorization...'"
  >
  </ds-localized-spinner>
</blockquote>
