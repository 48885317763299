import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IQuote } from '@amfam/shared/models';

@Component({
  selector: 'ds-quote-card',
  templateUrl: './quote-card.component.html',
  styleUrls: ['./quote-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteCardComponent {
  @Input() quote: IQuote;
}
