<div class="row">
  <div class="column twelve lg-eight lg-push-two md-eight md-push-two">
    <div class="column twelve md-push-two md-eight">
      <h1
        class="margin-bottom-10"
        content
        [contentPath]="'profile.gopaperlessContent.header'"
        data-cy="enrolmentPaperlessHeader"
      ></h1>

      <p
        class="pad-bottom-10"
        content
        [contentPath]="'profile.gopaperlessContent.listHeader'"
        data-cy="enrolmentPaperlessBody"
      ></p>
      <ul class="bullet pad-bottom-0">
        <li
          class="margin-bottom-0"
          content
          [contentPath]="'profile.gopaperlessContent.bulletOne'"
        ></li>
        <li
          class="margin-bottom-0"
          content
          [contentPath]="'profile.gopaperlessContent.bulletTwo'"
        ></li>
        <li
          class="margin-bottom-10"
          content
          [contentPath]="'profile.gopaperlessContent.bulletThree'"
        ></li>
      </ul>

      <p
        class="margin-bottom-30"
        content
        [contentPath]="'profile.gopaperlessContent.body'"
        [options]="{
          email: email$ | async
        }"
      ></p>

      <p>
        Tap Go Paperless to agree to the
        <a
          href="javascript:void(0)"
          (click)="openTermsAndConditionModal()"
          data-cy="termsAndConditionsLink"
          >Terms &amp; Conditions</a
        >
      </p>

      @if (isAdminApp) {
        <div>
          <ds-policy-terms-and-conditions
            [(visible)]="showTermsAndConditionsModal"
            [showPaperless]="false"
            [showPolicyTerms]="true"
            [fromProfile]="true"
            (agreeTermsAndConditionsEvent)="agreeTermsAndConditions($event)"
          >
          </ds-policy-terms-and-conditions>
        </div>
      }

      <call-to-action
        data-cy="callToActionSubmitButton"
        [primaryButtonName]="'Go Paperless'"
        (primaryEvent)="submit()"
        [tertiaryButtonName]="'No Thanks'"
        (tertiaryEvent)="skipPaperless()"
      ></call-to-action>
    </div>
  </div>
</div>
<ds-paperless-modal-wrapper> </ds-paperless-modal-wrapper>
