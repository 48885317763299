import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { PartnerTermsData } from '../terms.model';

@Component({
  selector: 'ds-billing-terms',
  templateUrl: './billing-terms.component.html',
  styleUrls: ['./billing-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingTermsComponent implements OnInit {
  @Input() partnerData: PartnerTermsData;

  constructor() {}

  ngOnInit(): void {}
}
