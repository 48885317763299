<p class="margin-top-20 margin-bottom-5">{{ title }}<sup>*</sup></p>
<div class="row flex margin-bottom-5" data-cy="autoPaySetupPaymentPreview">
  <div
    class="payment-preview-container column"
    [ngClass]="{ 'six margin-right-5': isFullPay, twelve: !isFullPay }"
  >
    <div class="row flex justify-center payment-preview-date margin-bottom-5">
      {{ paymentPreview.date }}
    </div>
    <div class="row flex justify-center payment-preview-amount">
      {{ paymentPreview.amount }}
    </div>
  </div>
  @if (isFullPay) {
    <div class="payment-preview-container column six margin-left-5">
      <div class="row flex justify-center payment-preview-date margin-bottom-5">
        On next due date
      </div>
      <div class="row flex justify-center payment-preview-amount">Remaining balance</div>
    </div>
  }
</div>

<p class="disclaimer"><sup>*</sup>{{ paymentPreviewFeesDisclaimer }}</p>
