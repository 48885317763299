import { Component, OnInit } from '@angular/core';

import { PageAnalytic } from '../analytics/analytics.model';
import { AnalyticsService } from '../analytics/analytics.service';
import { ConfigService } from './config.service';

@Component({
  selector: 'ds-config',
  templateUrl: './config.component.html'
})
export class ConfigComponent implements OnInit {
  configJson: any;
  private pageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Config',
    experience: '',
    primaryCategory: '',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };

  constructor(
    private config: ConfigService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.configJson = this.config.getConfigJson();
    this.analytics.sendPageView(this.pageAnalytic);
  }
}
