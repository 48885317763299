// Angular Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Lodash Imports
import { get as _get, includes as _includes } from 'lodash';

// My Account Imports
import { LifeDetail, LifePolicy, PolicySummary } from '@amfam/policy/models';
import { ConfigService } from '@amfam/shared/utility/shared-services/src/lib/config';
import { TypedWorker } from './typed-worker';

@Injectable({
  providedIn: 'root'
})
export class LifeService implements TypedWorker {
  private policyStatusCodes = ['A', 'E', 'G', 'J', 'K', 'P', 'Q', 'U', 'W'];
  private productTypes = ['TERM', 'WHOLE LIFE', 'SPT', 'UL-MYLIFE', 'UNKNOWN'];

  constructor(private config: ConfigService, private http: HttpClient) {}

  /**
   * @description: Returns the productApi endpoint for lifepolicies.
   */
  public getEndpoint() {
    return this.config.get('productApi') + 'lifepolicies/';
  }

  public getPolicy(url: string, policySummary: PolicySummary): Observable<LifePolicy> {
    return this.http.get(url).pipe(map((data: any) => this.loadModel(data, policySummary)));
  }

  public loadModel(json: LifeDetail, policySummary: PolicySummary): LifePolicy {
    let policy: any = json;
    if (json.policy) {
      policy = json.policy;
    }

    // Rules to determine active life policy.
    if (
      _includes(this.policyStatusCodes, _get(policy, 'policyStatus', '').toUpperCase()) &&
      _includes(this.productTypes, _get(policy, 'productType', '').toUpperCase())
    ) {
      policy = Object.assign({}, policy, policySummary, { term: policy.productType });

      return LifePolicy.fromJson({ ...policy, sourceSystem: policySummary.sourcePath });
    }
    return new LifePolicy();
  }
}
