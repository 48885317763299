import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PaymentAccountActions } from '@amfam/billing/paymentaccount/data-access';
import { FinancialAccountService } from '@amfam/billing/shared/util';

import {
  FetchPciTokenModel,
  FinancialAccountResponseModel,
  PciAuthResponseModel
} from '../models/financial-institution.models';
import {
  FetchPciTokenAction,
  GetFinancialInstitution,
  GetFinancialInstitutionFail,
  GetFinancialInstitutionSuccess
} from './financial-institution.actions';

@Injectable()
export class FinancialInstitutionEffects {
  getFinancialInstitution$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetFinancialInstitution),
      map(action => action.payload),
      switchMap((routingNumber: string) =>
        this.financialInstitutionService.getFinancialInstitution(routingNumber).pipe(
          map((res: FinancialAccountResponseModel) => GetFinancialInstitutionSuccess(res)),
          catchError(error => of(GetFinancialInstitutionFail(error)))
        )
      )
    );
  });

  getAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FetchPciTokenAction),
      map(action => action.payload),
      switchMap((payload: FetchPciTokenModel) =>
        this.financialInstitutionService.getAuthorizationToken().pipe(
          map((res: PciAuthResponseModel) => {
            this.financialInstitutionService.setAuthorizationToken(res.pciToken);
            if (payload.routingNumber) {
              return GetFinancialInstitution(payload.routingNumber);
            } else {
              return PaymentAccountActions.PaymentAccountsSave(payload.paymentAccountDTO);
            }
          }),
          catchError(error => of(GetFinancialInstitutionFail(error)))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private financialInstitutionService: FinancialAccountService
  ) {}
}
