import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PaymentMethodUtilService } from '@amfam/billing/payment-method/util';

import {
  AddEditPaymentMethodResponse,
  GetPaymentMethodsResponse,
  UpdateModeOfAuthorizationResponse
} from '../../models/payment-method.model';
import { PaymentMethodService } from '../../services/payment-method.service';
import * as PaymentMethodActions from './payment-method.actions';

@Injectable()
export class PaymentMethodEffects {
  getPaymentMethods$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentMethodActions.getPaymentMethods),
      switchMap(action =>
        this.paymentMethodService.getPaymentMethods().pipe(
          map((response: GetPaymentMethodsResponse) =>
            PaymentMethodActions.getPaymentMethodsSuccess({
              paymentMethods: response.paymentAccounts,
              correlationId: action.correlationId
            })
          ),
          catchError(error =>
            of(
              PaymentMethodActions.getPaymentMethodsFailure({
                error,
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    );
  });

  addPaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentMethodActions.addPaymentMethod),
      switchMap(action =>
        this.paymentMethodService.addPaymentMethod(action.payload).pipe(
          map((response: AddEditPaymentMethodResponse) =>
            PaymentMethodActions.addPaymentMethodSuccess({
              response,
              correlationId: action.correlationId
            })
          ),
          catchError(error =>
            of(
              PaymentMethodActions.addPaymentMethodFailure({
                error,
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    );
  });

  editPaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentMethodActions.editPaymentMethod),
      switchMap(action =>
        this.paymentMethodService.editPaymentMethod(action.payload, action.paymentAccountId).pipe(
          map((response: AddEditPaymentMethodResponse) =>
            PaymentMethodActions.editPaymentMethodSuccess({
              response,
              correlationId: action.correlationId,
              oldPaymentAccountId: action.paymentAccountId,
              newNickName: action.payload.paymentAccount.nickName,
              oldNickName: action.payload.paymentAccount.oldNickName
            })
          ),
          catchError(error =>
            of(
              PaymentMethodActions.editPaymentMethodFailure({
                error,
                correlationId: action.correlationId
              })
            )
          )
        )
      )
    );
  });

  updateModeOfAuthorization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentMethodActions.updateModeOfAuthorization),
      switchMap(action => {
        const responses: Observable<UpdateModeOfAuthorizationResponse>[] = action.payload.map(
          request => this.paymentMethodService.updateModeOfAuthorization(request)
        );
        return forkJoin(responses).pipe(
          map((res: UpdateModeOfAuthorizationResponse[]) => {
            return PaymentMethodActions.updateModeOfAuthorizationSuccess({
              response: res,
              correlationId: action.correlationId
            });
          }),
          catchError(error =>
            of(
              PaymentMethodActions.updateModeOfAuthorizationFailure({
                error,
                correlationId: action.correlationId
              })
            )
          )
        );
      })
    );
  });

  deletePaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentMethodActions.deletePaymentMethod),
      switchMap(action =>
        this.paymentMethodService.deletePaymentMethod(action.paymentAccountId).pipe(
          map((response: AddEditPaymentMethodResponse) =>
            PaymentMethodActions.deletePaymentMethodSuccess({
              response,
              correlationId: action.correlationId,
              paymentAccountId: action.paymentAccountId
            })
          ),
          catchError(error =>
            of(
              PaymentMethodActions.deletePaymentMethodFailure({
                error,
                correlationId: action.correlationId,
                paymentAccountId: action.paymentAccountId
              })
            )
          )
        )
      )
    );
  });

  analyticsNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PaymentMethodActions.sendPaymentMethodAnalytics),
        map(action => this.paymentMethodUtil.sendPaymentMethodAnalytics(action.payload))
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private paymentMethodService: PaymentMethodService,
    private paymentMethodUtil: PaymentMethodUtilService
  ) {}
}
