import { createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

import { CreditCardExpirationStatusType, PaymentMethod } from '@amfam/billing/payment-method/util';

import { selectState, State } from '..';
import * as PaymentAccountSelectors from '../../../../../../paymentaccount/data-access/src/lib/+state/paymentaccount.selectors'; // TODO temp fix for circular dependency

import * as FinancialAccountSelectors from '../financial-account/financial-account.selectors';
import * as fromPaymentMethod from './payment-method.reducer';

export const selectPaymentMethodState = createSelector(
  selectState,
  (state: State) => state.paymentMethodState
);

export const selectPaymentMethodEntities = createSelector(
  selectPaymentMethodState,
  (state: fromPaymentMethod.State) => state.entities
);

export const selectLoading = createSelector(
  selectPaymentMethodState,
  (state: fromPaymentMethod.State) => state.loading
);

export const selectHasError = createSelector(
  selectPaymentMethodState,
  (state: fromPaymentMethod.State) => state.hasError
);

export const selectCorrelationId = createSelector(
  selectPaymentMethodState,
  (state: fromPaymentMethod.State) => state.correlationId
);

export const selectPaymentMethods = createSelector(
  selectPaymentMethodState,
  (state: fromPaymentMethod.State) => {
    const paymentMethods = fromPaymentMethod.selectAllPaymentMethods(state);
    paymentMethods.sort((a, b) => new Intl.Collator().compare(a.nickName, b.nickName));
    return paymentMethods;
  }
);

export const selectPaymentMethod = (paymentAccountId: string) =>
  createSelector(selectPaymentMethodEntities, entities => entities[paymentAccountId]);

export const selectMatchedPaymentAccount = createSelector(
  selectPaymentMethodEntities,
  (paymentAccounts, paymentId: string) => paymentAccounts[paymentId]
);

export const selectPaymentMethodsSortedByExpirationStatus = createSelector(
  selectPaymentMethods,
  paymentMethods =>
    paymentMethods.sort((a: PaymentMethod, b: PaymentMethod) => {
      // Assign a weight of 0 for expired payment accounts, 1 for expiring payment accounts, 2 for not expired/expiring payment accounts
      const aExpired: boolean =
        _get(a, 'creditCard.expirationStatus', null) === CreditCardExpirationStatusType.EXPIRED;
      const aExpiringSoon: boolean =
        _get(a, 'creditCard.expirationStatus', null) ===
        CreditCardExpirationStatusType.EXPIRING_SOON;
      const bExpired: boolean =
        _get(b, 'creditCard.expirationStatus', null) === CreditCardExpirationStatusType.EXPIRED;
      const bExpiringSoon: boolean =
        _get(b, 'creditCard.expirationStatus', null) ===
        CreditCardExpirationStatusType.EXPIRING_SOON;
      const aValue = aExpired ? 0 : aExpiringSoon ? 1 : 2;
      const bValue = bExpired ? 0 : bExpiringSoon ? 1 : 2;

      if (aValue > bValue) {
        return 1;
      } else if (aValue < bValue) {
        return -1;
      }
      return 0;
    })
);

export const selectHasAnyError = createSelector(
  FinancialAccountSelectors.selectHasPCITokenError,
  FinancialAccountSelectors.selectHasFinancialInstitutionError,
  selectHasError,
  (pciError, financialInstitutionError, paymentMethodError) =>
    pciError || financialInstitutionError || paymentMethodError
);

export const selectNewPaymentAccountId = createSelector(
  selectPaymentMethodState,
  (state: fromPaymentMethod.State) => state.newPaymentAccountId
);

export const selectIsDataLoading = createSelector(
  PaymentAccountSelectors.selectPaymentAccountsAnyLoading,
  selectLoading,
  (loading, newLoading) => loading || newLoading
);

export const selectCombinedPaymentData = createSelector(
  PaymentAccountSelectors.selectPaymentAccountsExpiredFirst,
  selectPaymentMethods,
  selectIsDataLoading,
  selectNewPaymentAccountId,
  (paymentAccounts, newPaymentAccounts, loading, newPaymentAccountId) => ({
    paymentAccounts: newPaymentAccounts,
    loading,
    newPaymentAccountId
  })
);
