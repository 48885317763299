import { AnalyticsFacade } from '@amfam/shared/analytics';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { every as _every, get as _get, some as _some } from 'lodash';
import { Subject, combineLatest } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { SourceSystemType } from '@amfam/policy/models';
import {
  BillAccount,
  BillAccountData,
  DeliveryPreferences,
  NewBillingPreference,
  PcmAnalytics,
  PreferenceItem
} from '@amfam/shared/models';
import {
  ApplicationService,
  Applications,
  CopyService,
  PageAnalytic,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { DsModalService, LoadingSpinnerService, ToasterService } from '@amfam/ui-kit';

// Store
import {
  BillAccountActions,
  BillAccountsSelectors,
  BillingUtilService
} from '@amfam/billing/billaccount/data-access';
import { PolicySelectors } from '@amfam/policy/data-access';
import { EsignatureActions, EsignatureSelectors } from '@amfam/policy/e-signature/data-access';
import {
  GenerateEmailConstants,
  GoPaperLessActions,
  GoPaperLessSelectors,
  GoPaperlessService
} from '@amfam/policy/go-paperless/data-access';
import { AnalyticsActions } from '@amfam/shared/analytics';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';

@Component({
  selector: 'ds-communication-preferences',
  templateUrl: './communication-preferences.component.html',
  styleUrls: ['./communication-preferences.component.scss']
})
export class CommunicationPreferencesComponent implements OnInit, OnDestroy {
  @Input() preferenceData: PreferenceItem[];
  @Input() eSignatureStatus = '';
  @Input() isPartner: boolean | undefined;

  canSetAll = true;
  showTermsAndConditionsModal = false;
  message = '';
  cantChangemodalId = 'cantChangeSettings-modal';
  discountModalId = 'discount-modal';
  warnLosingDiscountModalId = 'warnLosingDiscount-modal';
  paperlessPolicy: boolean;
  emailAddress: string;
  disableSaveButton = true;
  showPolicyTerms = false;
  showBillingTerms = false;
  showPolicyComponent: boolean;
  clearWarning = 0;
  private isESignatureRequired: boolean;
  private billingPreferences: string[];
  private newBillingPreferences: NewBillingPreference[];
  private policyPreference: string;
  private newPolicyPreference: string;
  private correlationId: string;
  private preferenceActionDone = false;
  private eSignatureErrorQueryParams: string[] = [];
  private stop$: Subject<void> = new Subject<void>();
  private hasAdvancePolicies = false;
  public isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
  public switchToPaperlessButtonText = this.copyService.getCopy('profile.switchToPaperlessButton');
  public switchToPaperButtonText = this.copyService.getCopy('profile.switchToPaperButton');
  public switchSelectedAccountsButtonText = this.copyService.getCopy(
    'profile.switchSelectedAccountsButton'
  );
  public cancelButtonText = this.copyService.getCopy('profile.cancelButton');

  // **** Start of Analytics data for this component
  protected errorAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Enroll:Paperless:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Policies',
    subCategory2: 'Go Paperless',
    subCategory3: ''
  };
  // **** End of Analytics data for this component

  constructor(
    private copyService: CopyService,
    private utilService: UtilService,
    private modalService: DsModalService,
    private store: Store<any>,
    private toasterService: ToasterService,
    private billingUtilService: BillingUtilService,
    private goPaperlessService: GoPaperlessService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade,
    private appService: ApplicationService
  ) {}

  ngOnInit() {
    if (_some(this.preferenceData, { canSetPreferences: false })) {
      this.canSetAll = false;
    }

    // List of error query params on docusign failure.
    this.eSignatureErrorQueryParams = this.copyService.getCopy('policy.eSignatureErrorQueryParams');

    if (this.isAdminApp) {
      //listening of update preference action for consolidated emails & consolidated error messages
      //also applicable for only admin app
      combineLatest(
        this.store.select(GoPaperLessSelectors.getGoPaperlessState),
        this.store.select(BillAccountsSelectors.getBillAccountsState),
        (paperlessState, billAccounts) => {
          return {
            paperlessState: paperlessState,
            billAccounts: billAccounts
          };
        }
      )
        .pipe(takeUntil(this.stop$))
        .subscribe(state => {
          if (this.correlationId && !this.preferenceActionDone) {
            // Is policy involved in update preference action
            const isPolicyPaperlessInvolved = !!this.newPolicyPreference;
            // Is BillAccounts involved in update preference action
            const isBillAccountsPaperlessInvolved = !!this.newBillingPreferences;
            const isPolicySucceed =
              state.paperlessState.goPaperLessState.loading === false &&
              state.paperlessState.goPaperLessState.correlationId === this.correlationId;

            if (isBillAccountsPaperlessInvolved) {
              const ids = this.newBillingPreferences.map(p => p.billAccountNumber);
              // To know if all the bill accounts update preferences are completed (weather it is successful / failure)
              const allBillAccountActionDone =
                Object.values(state.billAccounts.billingState.entities).filter(
                  e =>
                    ids.includes(e.billAccountNumber) &&
                    !e.updatingPreferenceAction &&
                    (e.correlationId === null || e.correlationId)
                ).length === ids.length;
              if (
                allBillAccountActionDone &&
                (!isPolicyPaperlessInvolved || !state.paperlessState.goPaperLessState.loading)
              ) {
                const basSucceeded = Object.values(state.billAccounts.billingState.entities).filter(
                  e => ids.includes(e.billAccountNumber) && e.correlationId === this.correlationId
                );

                const basFailed = Object.values(state.billAccounts.billingState.entities).filter(
                  e => ids.includes(e.billAccountNumber) && e.correlationId === null
                );

                this.generateEmailsForAllPreferences(
                  isPolicyPaperlessInvolved,
                  isBillAccountsPaperlessInvolved,
                  basSucceeded,
                  basFailed,
                  isPolicyPaperlessInvolved ? isPolicySucceed : undefined
                );
              }
            } else if (
              isPolicyPaperlessInvolved &&
              !state.paperlessState.goPaperLessState.loading
            ) {
              this.generateEmailsForAllPreferences(
                isPolicyPaperlessInvolved,
                isBillAccountsPaperlessInvolved,
                [],
                [],
                isPolicySucceed
              );
            }
          }
        });
    }

    // Combined observable to feed template and component.
    combineLatest(
      this.store.select(GoPaperLessSelectors.isEnrolledGoPaperless),
      this.store.select(userQuery.getUserState),
      this.store.select(EsignatureSelectors.getESignatureRequired),
      this.store.select(BillAccountsSelectors.getBillAccountsArePaperless),
      this.store.select(PolicySelectors.getPolicyRisks),
      this.store.select(PolicySelectors.getAdvancePolicies),
      (
        goPaperLessEnrollment,
        currentUser,
        isESignatureRequired,
        getBillAccountsArePaperless,
        policyRisks,
        advancePolicies
      ) => {
        return {
          goPaperLessEnrollment: goPaperLessEnrollment,
          currentUser: currentUser,
          isESignatureRequired: isESignatureRequired,
          paperlessBilling: getBillAccountsArePaperless,
          policyRisks: policyRisks,
          advancePolicies: advancePolicies
        };
      }
    )
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        this.paperlessPolicy = state.goPaperLessEnrollment;
        this.hasAdvancePolicies = !!_get(state, 'advancePolicies.length');

        if (this.paperlessPolicy) {
          this.policyPreference = DeliveryPreferences.EMAIL;
        } else {
          this.policyPreference = DeliveryPreferences.PAPER;
        }
        this.emailAddress = state.currentUser.emailAddress;
        // Its a temporary fix for GA customers, need to be revisited whenever possible
        if (this.isAdminApp) {
          this.isESignatureRequired = false;
        } else {
          this.isESignatureRequired = state.isESignatureRequired;
        }
        this.showPolicyComponent = _get(state, 'policyRisks.length', 0) > 0;

        // Determine message content for preferences-message component.
        if (state.paperlessBilling) {
          if (this.paperlessPolicy) {
            this.message = this.copyService.getCopy(
              'profile.communicationPreferencesAllPaperlessMessage'
            );
          } else {
            this.message = this.copyService.getCopy(
              'profile.communicationPreferencesBillingPaperlessMessage'
            );
          }
        } else {
          if (
            _some(this.preferenceData, { billAccountType: SourceSystemType.Legacy }) &&
            _some(this.preferenceData, { billAccountType: SourceSystemType.Advance })
          ) {
            this.message = this.copyService.getCopy(
              'profile.communicationPreferencesClassicAndAdvanceMessage'
            );
          } else if (_every(this.preferenceData, { billAccountType: SourceSystemType.Legacy })) {
            this.message = this.copyService.getCopy(
              'profile.communicationPreferencesClassicMessage'
            );
          } else if (_every(this.preferenceData, { billAccountType: SourceSystemType.Advance })) {
            this.message = this.copyService.getCopy(
              'profile.communicationPreferencesAdvanceMessage'
            );
          }
        }
      });

    // E-signature success
    if (this.eSignatureStatus === 'OnSigningComplete') {
      this.spinner.start({
        blockActions: true
      });
      this.store.dispatch(new GoPaperLessActions.EnrollGoPaperless());
      combineLatest(
        this.store.select(GoPaperLessSelectors.getGoPaperlessLoading),
        this.store.select(GoPaperLessSelectors.getGoPaperlessError),
        (loading, hasError) => {
          return {
            loading: loading,
            hasError: hasError
          };
        }
      )
        .pipe(
          filter(state => !state.loading),
          takeUntil(this.stop$)
        )
        .subscribe(state => {
          if (state.hasError) {
            this.analyticsFacade.trackPage(this.errorAnalytic);
            this.toasterService.pop(
              'error',
              this.copyService.getCopy('billing.setPreferencesError')
            );
            this.disableSaveButton = true;
          } else {
            this.toasterService.pop('success', 'Your changes have been saved.');
          }
        });
    } else if (this.eSignatureErrorQueryParams.some(param => param === this.eSignatureStatus)) {
      // E-signature failure
      this.toasterService.pop('error', this.copyService.getCopy('billing.setPreferencesError'));
    }
  }

  generateEmailsForAllPreferences(
    policyInvolved,
    baInvolved,
    baSucceed: BillAccount[],
    baFailed: BillAccount[],
    policySucceed?: boolean
  ) {
    this.preferenceActionDone = true;
    let optinIds = [];
    let optoutIds = [];
    let bothIds = [];

    if (baSucceed.length > 0) {
      optinIds = this.getBASuccesfullIdsPayload(baSucceed, DeliveryPreferences.EMAIL);
      optoutIds = this.getBASuccesfullIdsPayload(baSucceed, DeliveryPreferences.PAPER);
      bothIds = this.getBASuccesfullIdsPayload(baSucceed, DeliveryPreferences.BOTH);
    }

    if (policySucceed) {
      if (this.newPolicyPreference === DeliveryPreferences.PAPER) {
        optoutIds = [
          '<li>Policy documents and communications about all of your policies</li>',
          ...optoutIds
        ];
      } else if (this.newPolicyPreference === DeliveryPreferences.EMAIL) {
        optinIds = [
          '<li>Policy documents and communications about all of your policies</li>',
          ...optinIds
        ];
      }
    }

    if (optinIds.length > 0) {
      this.generateEmail(GenerateEmailConstants.MAA_CommPrefChange_OptIn, optinIds);
    }
    if (optoutIds.length > 0) {
      this.generateEmail(GenerateEmailConstants.MAA_CommPrefChange_OptOut, optoutIds);
    }
    if (bothIds.length > 0) {
      this.generateEmail(GenerateEmailConstants.MAA_StatementPrefChange_OptBoth, bothIds);
    }
    if ((policyInvolved && policySucceed === false) || baFailed.length > 0) {
      this.handleErrors(policyInvolved, baInvolved, policySucceed, baSucceed, baFailed);
    }
  }

  handleErrors(policyInvolved, baInvolved, policySucceed, baSucceed, baFailed) {
    if (policyInvolved && baInvolved) {
      if (policySucceed === false && baFailed.length > 0) {
        if (baSucceed.length === 0 && baFailed.length > 0) {
          this.toasterService.pop('error', this.copyService.getCopy('profile.preferencesError'));
        } else if (baFailed.length > 0) {
          this.toasterService.pop(
            'error',
            this.copyService.getCopy('profile.partialBillAccountsAndPolicyFailureMessage')
          );
        }
      } else if (policySucceed === false) {
        this.toasterService.pop(
          'error',
          this.copyService.getCopy('profile.billingSuccessButPolicyFailureMessage')
        );
      } else if (baFailed.length > 0) {
        if (baSucceed.length === 0) {
          this.toasterService.pop(
            'error',
            this.copyService.getCopy('profile.policySuccessButBillingFailureMessage')
          );
        } else {
          this.toasterService.pop(
            'error',
            this.copyService.getCopy('profile.policySuccessButPartialBillingFailureMessage')
          );
        }
      }
    } else if (policyInvolved) {
      if (policySucceed === false) {
        this.toasterService.pop(
          'error',
          this.copyService.getCopy('profile.policyOrAllBillingFailureMessage')
        );
      }
    } else if (baInvolved) {
      if (baSucceed.length === 0 && baFailed.length > 0) {
        this.toasterService.pop(
          'error',
          this.copyService.getCopy('profile.policyOrAllBillingFailureMessage')
        );
      } else if (baFailed.length > 0) {
        this.toasterService.pop(
          'error',
          this.copyService.getCopy('profile.fewBillingFailureMessage')
        );
      }
    }
  }

  getBASuccesfullIdsPayload(succeedBillAcs, preference) {
    const namesMap = new Map();
    succeedBillAcs.forEach(ba => {
      namesMap.set(
        ba.billAccountNumber,
        ba.billingPreferences ? ba.billingPreferences.accountNickName : undefined
      );
    });
    return this.newBillingPreferences
      .filter(
        bp =>
          Array.from(namesMap.keys()).includes(bp.billAccountNumber) && bp.preference === preference
      )
      .map(ba => {
        if (namesMap.get(ba.billAccountNumber)) {
          return `<li>Statements for ${namesMap.get(ba.billAccountNumber)}</li>`;
        } else {
          return `<li>Statements for bill account ending in ${ba.billAccountNumber.substring(
            ba.billAccountNumber.length - 4,
            ba.billAccountNumber.length
          )}</li>`;
        }
      });
  }

  generateEmail(paperlessOperation, ids) {
    this.store.dispatch(
      new GoPaperLessActions.GenerateGoPaperlessEmail({
        paperlessOperation: paperlessOperation,
        affectedItemsList: ids
      })
    );
  }

  // Output event from billing preferences component
  billingPreferencesChangeEvent(prefs: string[]) {
    this.billingPreferences = prefs;
    // To know modified preferences
    this.newBillingPreferences = this.billingPreferences
      .map((pref, i) => {
        if (
          pref !== this.preferenceData[i].deliveryPreference &&
          this.preferenceData[i].canSetPreferences
        ) {
          return {
            billAccountNumber: this.preferenceData[i].billAccountNumber,
            preference: pref
          };
        }
      })
      .filter(value => !!value);
    this.enableOrDisableSaveButton();
  }

  // output event from policies preferences component
  policyPreferencesChangeEvent(newPolicyPref: string) {
    this.newPolicyPreference = newPolicyPref;
    this.enableOrDisableSaveButton();
  }

  /**
   * Track if billing or policy preferences are changed.
   */
  private enableOrDisableSaveButton(): void {
    const billingChanged = this.newBillingPreferences && this.newBillingPreferences.length > 0;
    const billingIncludesEmail =
      _some(this.newBillingPreferences, ['preference', DeliveryPreferences.EMAIL]) ||
      _some(this.newBillingPreferences, ['preference', DeliveryPreferences.BOTH]);
    const policyChanged = !!this.newPolicyPreference;
    this.showPolicyTerms = policyChanged && this.newPolicyPreference === DeliveryPreferences.EMAIL;
    this.showBillingTerms =
      billingChanged &&
      billingIncludesEmail &&
      (this.changedFromPaper() ||
        (!this.changedFromBothToEmail() && !this.changedFromEmailToBoth()));
    if (billingChanged || policyChanged) {
      this.disableSaveButton = false;
    } else {
      this.disableSaveButton = true;
    }
  }

  openCantChangeSettingsModal(): void {
    this.analyticsFacade.trackPage(PcmAnalytics.modalCannotChangeSettingsAnalytic);
    this.modalService.open(this.cantChangemodalId);
  }
  //Checks if it is changed from Email to Both
  changedFromEmailToBoth() {
    return this.changeView(DeliveryPreferences.BOTH, DeliveryPreferences.EMAIL);
  }
  //Checks if it is changed from Paper
  changedFromPaper() {
    return (
      this.changeView(DeliveryPreferences.PAPER, DeliveryPreferences.EMAIL) ||
      this.changeView(DeliveryPreferences.PAPER, DeliveryPreferences.BOTH)
    );
  }
  //Checks it is changed from Both to Email
  changedFromBothToEmail() {
    return this.changeView(DeliveryPreferences.EMAIL, DeliveryPreferences.BOTH);
  }
  //Method to check the logic of DeliveryPreferences to & from values
  changeView(from: DeliveryPreferences, to: DeliveryPreferences) {
    if (this.billingPreferences) {
      return (
        this.billingPreferences.filter((pref, i) => {
          const originalPref = this.preferenceData[i].deliveryPreference;
          return pref !== originalPref && pref === to && originalPref === from;
        }).length > 0
      );
    }
  }
  savePreferences(): void {
    this.analyticsFacade.trackButtonClick(PcmAnalytics.communicationPreferencesSave);

    // Initialize to original billing preferences if there are no new billing preferences.
    if (!_get(this.billingPreferences, 'length')) {
      this.billingPreferences = this.preferenceData.map(pref => {
        return pref.deliveryPreference;
      });
    }

    // Check eligible for discount or warn lossing discount.
    const isEligibleForDiscount = this.showPromoteModal();

    const warnLosingDiscount = this.showWarnModal();

    // When loose discount scenario and promote go paperless scenario overlap -> loose discount scenario takes priority.
    if (isEligibleForDiscount && warnLosingDiscount) {
      if (this.isAdminApp) {
        this.store.dispatch(
          AnalyticsActions.sendAnalytic({
            options: {
              page: PcmAnalytics.emailToPaperAdminAnalytic
            }
          })
        );
      }

      this.modalService.open(this.warnLosingDiscountModalId);
    } else if (isEligibleForDiscount) {
      if (this.isAdminApp) {
        this.store.dispatch(
          AnalyticsActions.sendAnalytic({
            options: {
              page: PcmAnalytics.paperToEmailAdminAnalytic
            }
          })
        );
      }
      this.modalService.open(this.discountModalId);
    } else if (warnLosingDiscount) {
      if (this.isAdminApp) {
        this.store.dispatch(
          AnalyticsActions.sendAnalytic({
            options: {
              page: PcmAnalytics.emailToPaperAdminAnalytic
            }
          })
        );
      }
      this.modalService.open(this.warnLosingDiscountModalId);
    } else if (
      !isEligibleForDiscount &&
      !warnLosingDiscount &&
      (this.showPolicyTerms || this.showBillingTerms)
    ) {
      this.showTermsAndConditionsModal = true;
      if (this.isAdminApp) {
        this.store.dispatch(
          AnalyticsActions.sendAnalytic({
            options: {
              page: PcmAnalytics.paperToEmailModalAdminAnalytic
            }
          })
        );
      }
    } else {
      this.setPreferences();
    }
  }

  private showPromoteModal(): boolean {
    let billingPreferenceNotEmail = false;
    let billingChangedToEmail = false;
    const policyChangedToEmail = this.newPolicyPreference === DeliveryPreferences.EMAIL;

    if (_get(this.billingPreferences, 'length')) {
      billingPreferenceNotEmail = this.billingPreferences
        .map((pref, i) => {
          return this.preferenceData[i].canSetPreferences && pref !== DeliveryPreferences.EMAIL
            ? pref
            : DeliveryPreferences.EMAIL;
        })
        .some(preference => preference !== DeliveryPreferences.EMAIL);

      billingChangedToEmail =
        this.billingPreferences.filter((pref, i) => {
          const originalPref = this.preferenceData[i].deliveryPreference;
          return pref === DeliveryPreferences.EMAIL && pref !== originalPref;
        }).length > 0;
    }

    // Is paper if changed to paper or was unchanged from paper
    const policyPreferenceNotEmail =
      this.newPolicyPreference === DeliveryPreferences.PAPER ||
      (!this.newPolicyPreference && this.policyPreference === DeliveryPreferences.PAPER);
    const preferenceChangedToEmail = billingChangedToEmail || policyChangedToEmail;
    const anyPreferenceNotEmail = billingPreferenceNotEmail || policyPreferenceNotEmail;

    // Display modal if a preference changed to email and any other preference can change to email
    if (preferenceChangedToEmail && anyPreferenceNotEmail) {
      return true;
    }
    return false;
  }

  private showWarnModal(): boolean {
    let isBillingPreferenceChangedFromEmail = false;
    // update isBillingPreferenceChangedFromEmail if this.billingPreferences are available.
    if (_get(this.billingPreferences, 'length')) {
      isBillingPreferenceChangedFromEmail =
        this.billingPreferences.filter((pref, i) => {
          const originalPref = this.preferenceData[i].deliveryPreference;
          return originalPref === DeliveryPreferences.EMAIL && pref !== originalPref && pref;
        }).length > 0;
    }

    const isPolicyPreferenceChangedFromEmail =
      this.newPolicyPreference === DeliveryPreferences.PAPER && this.hasAdvancePolicies;

    // Display modal if billing or policies preferences are changed from email
    if (isBillingPreferenceChangedFromEmail || isPolicyPreferenceChangedFromEmail) {
      return true;
    }
    return false;
  }

  // set preferences when agree to terms and conditions.
  agreeTermsAndConditionsEvent(agree: boolean): void {
    if (agree) {
      this.setPreferences();
      if (this.isAdminApp) {
        this.store.dispatch(
          AnalyticsActions.sendAnalytic({
            options: {
              button: PcmAnalytics.discountModalSave
            }
          })
        );
      }
    } else {
      if (this.isAdminApp) {
        this.store.dispatch(
          AnalyticsActions.sendAnalytic({
            options: {
              button: PcmAnalytics.closeDiscountModal
            }
          })
        );
      }
    }
  }

  /**
   * Build payload for preferences
   * Dispatch actions
   * Handle success and failure scenarios
   */
  private setPreferences(): void {
    this.spinner.start({
      blockActions: true
    });
    this.postAnalytics();

    this.correlationId = this.utilService.generateId();
    this.preferenceActionDone = false;

    // Set policy preferences for everyone except georgia customers who choose 'email'.
    if (
      (!this.isESignatureRequired || this.newPolicyPreference === DeliveryPreferences.PAPER) &&
      !!this.newPolicyPreference
    ) {
      if (this.newPolicyPreference === DeliveryPreferences.EMAIL) {
        this.store.dispatch(
          new GoPaperLessActions.EnrollGoPaperless({ correlationId: this.correlationId })
        );
      } else if (this.newPolicyPreference === DeliveryPreferences.PAPER) {
        this.store.dispatch(
          new GoPaperLessActions.UnEnrollGoPaperless({ correlationId: this.correlationId })
        );
      }
    }

    // Set bill account preferences.
    if (this.newBillingPreferences && this.newBillingPreferences.length > 0) {
      this.newBillingPreferences.forEach(newPreference => {
        this.store
          .select(
            BillAccountsSelectors.getBillAccountDataToUpdatePreferences(
              newPreference.billAccountNumber
            )
          )
          .pipe(take(1))
          .subscribe((billAccountData: BillAccountData) => {
            const preferencesObj = this.billingUtilService.buildPreferencePayload(
              newPreference,
              billAccountData,
              this.correlationId
            );
            this.store.dispatch(
              new BillAccountActions.BillAccountUpdateDeliveryPreference(preferencesObj)
            );
          });
      });
    }

    combineLatest(
      this.store.select(BillAccountsSelectors.getBillAccountsNotifications),
      this.store.select(BillAccountsSelectors.getBillAccountsAnyLoading),
      this.store.select(GoPaperLessSelectors.getGoPaperlessLoading),
      this.store.select(GoPaperLessSelectors.getGoPaperlessError),
      (billAccountNotifications, billAccountsAnyLoading, goPaperlessLoading, goPaperlessError) => {
        return {
          billAccountNotifications,
          loading: billAccountsAnyLoading || goPaperlessLoading,
          goPaperlessError: goPaperlessError
        };
      }
    )
      .pipe(
        filter(state => !state.loading),
        map(state => {
          const filteredBillAccountsNotifications = state.billAccountNotifications.filter(ba => {
            return ba.updatePreferenceCorrelationId === this.correlationId;
          });
          return Object.assign({}, state, {
            billAccountNotifications: filteredBillAccountsNotifications
          });
        }),
        filter(
          state =>
            state.billAccountNotifications.length === _get(this.newBillingPreferences, 'length', 0)
        ),
        take(1)
      )
      .subscribe(state => {
        const policyPreferenceError: boolean = state.goPaperlessError;
        const billingPreferencesError: boolean = _some(state.billAccountNotifications, {
          updateDeliveryPreferencesError: true
        });

        if (policyPreferenceError || billingPreferencesError) {
          this.analyticsFacade.trackPage(PcmAnalytics.saveErrorAnalytic);
        } else {
          // Route to docusign if e-signature is required and selected preference is email
          if (this.isESignatureRequired && this.newPolicyPreference === DeliveryPreferences.EMAIL) {
            this.store
              .pipe(select(BrandSelectors.getExperienceId), take(1))
              .subscribe(experienceId => {
                const callbackUrl = this.utilService.getAbsoluteUrl('profile', experienceId);
                const signatureRequest =
                  this.goPaperlessService.createSignatureRequest(callbackUrl);
                this.store.dispatch(new EsignatureActions.SignESignature(signatureRequest));
              });

            this.spinner.start({
              blockActions: true
            });
          } else {
            this.analyticsFacade.trackPage(PcmAnalytics.saveSuccessAnalytic);
            this.clearWarning++;
            this.disableSaveButton = true;
            if (this.isAdminApp) {
              this.toasterService.pop('success', 'Your customer’s preferences have been saved.');
            } else {
              this.toasterService.pop('success', 'Your changes have been saved.');
            }
          }
        }
        // Reset vars
        this.newBillingPreferences = [];
        this.newPolicyPreference = null;
      });
  }

  /**
   * convert billing and policy preferences to email
   */
  setPaperlessPreferences(modalId: string): void {
    if (this.isAdminApp) {
      this.store.dispatch(
        AnalyticsActions.sendAnalytic({
          options: {
            button: PcmAnalytics.discountModalSave
          }
        })
      );
    } else {
      this.analyticsFacade.trackPage(PcmAnalytics.modalPromoteDiscountAnalytic);
    }
    /**
     * Create payload for eligible bill accounts to email.
     */
    this.newBillingPreferences = this.preferenceData
      .map((pref, i) => {
        if (pref.deliveryPreference !== DeliveryPreferences.EMAIL && pref.canSetPreferences) {
          return {
            billAccountNumber: pref.billAccountNumber,
            preference: DeliveryPreferences.EMAIL
          };
        }
      })
      .filter(value => !!value);
    this.newPolicyPreference = DeliveryPreferences.EMAIL;
    this.enableOrDisableSaveButton();
    this.closeModal(modalId);
    if (this.showWarnModal() && !this.changedToBoth()) {
      this.showTermsAndConditionsModal = false;
      this.setPreferences();
    } else {
      this.showTermsAndConditionsModal = true;
      if (this.isAdminApp) {
        this.store.dispatch(
          AnalyticsActions.sendAnalytic({
            options: {
              page: PcmAnalytics.paperToEmailModalAdminAnalytic
            }
          })
        );
      }
    }
  }

  //checking if any accounts changed from others to both
  changedToBoth() {
    const billingChangedToBoth =
      this.billingPreferences.filter((pref, i) => {
        const originalPref = this.preferenceData[i].deliveryPreference;
        return pref === DeliveryPreferences.BOTH && pref !== originalPref;
      }).length > 0;
    return billingChangedToBoth;
  }

  openTermsAndConditionModal(modalId: string): void {
    this.closeModal(modalId, true);
    if (this.showPolicyTerms || this.showBillingTerms) {
      this.showTermsAndConditionsModal = true;
      if (this.isAdminApp) {
        this.store.dispatch(
          AnalyticsActions.sendAnalytic({
            options: {
              page: PcmAnalytics.paperToEmailModalAdminAnalytic
            }
          })
        );
      }
    } else {
      this.setPreferences();
    }
  }

  loseDiscount(modalId: string): void {
    if (this.isAdminApp) {
      this.store.dispatch(
        AnalyticsActions.sendAnalytic({
          options: {
            button: PcmAnalytics.loseDiscountModalSave
          }
        })
      );
    } else {
      this.analyticsFacade.trackPage(PcmAnalytics.modalWarningAnalytic);
    }
    if (this.showPolicyTerms || this.showBillingTerms) {
      this.openTermsAndConditionModal(modalId);
    } else if (this.showPromoteModal()) {
      this.closeModal(modalId);
      this.modalService.open(this.discountModalId);
    } else {
      this.closeModal(modalId);
      this.setPreferences();
    }
  }

  closeModal(modalId: string, isNO?: boolean): void {
    if (isNO && this.isAdminApp) {
      if (modalId === this.warnLosingDiscountModalId) {
        this.onCloseWarnLosingDiscountModalId(true);
      } else if (modalId === this.discountModalId) {
        this.onCloseDiscountModalId(true);
      }
    }
    this.modalService.close(modalId);
  }
  onCloseWarnLosingDiscountModalId(event) {
    if (event && this.isAdminApp) {
      this.store.dispatch(
        AnalyticsActions.sendAnalytic({
          options: {
            button: PcmAnalytics.closeWarningModal
          }
        })
      );
    }
  }

  onCloseDiscountModalId(event) {
    if (event && this.isAdminApp) {
      this.store.dispatch(
        AnalyticsActions.sendAnalytic({
          options: {
            button: PcmAnalytics.closeDiscountModal
          }
        })
      );
    }
  }

  // Send Ananlytics
  private postAnalytics() {
    // Determine Billing terms and conditions
    const billingTC = _get(this.newBillingPreferences, 'length', 0) > 0;
    // Determine Policies terms and conditions
    const policiesTC = !!this.newPolicyPreference;

    window.setTimeout(() => {
      if (billingTC && policiesTC) {
        this.analyticsFacade.trackPage(PcmAnalytics.combinedTCAnalytic);
      } else if (billingTC) {
        this.analyticsFacade.trackPage(PcmAnalytics.billingTCAnalytic);
      } else if (policiesTC) {
        this.analyticsFacade.trackPage(PcmAnalytics.policiesTCAnalytic);
      }
    }, 500);
  }

  ngOnDestroy() {
    // Close this so the modal isn't trying to open the next time this component renders
    this.modalService.close(this.cantChangemodalId);
    this.modalService.close(this.discountModalId);
    this.modalService.close(this.warnLosingDiscountModalId);
    this.stop$.next();
    this.stop$.complete();
  }
}
