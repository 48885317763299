import { Component, EventEmitter, Output } from '@angular/core';

import { CopyService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-duplicate-payment-confirmation-modal',
  templateUrl: './duplicate-payment-confirmation-modal.component.html',
  styleUrls: ['./duplicate-payment-confirmation-modal.component.scss']
})
export class DuplicatePaymentConfirmationModalComponent {
  @Output()
  handleYes = new EventEmitter<void>();
  @Output()
  handleNo = new EventEmitter<void>();

  heading = this.copyService.getCopy('billing.duplicateBill.heading');
  alreadyPaid = this.copyService.getCopy('billing.duplicateBill.alreadyPaid');
  question = this.copyService.getCopy('billing.duplicateBill.question');
  yes = this.copyService.getCopy('billing.duplicateBill.yes');
  no = this.copyService.getCopy('billing.duplicateBill.no');

  constructor(private copyService: CopyService) {}
}
