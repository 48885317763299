<div role="navigation" [attr.data-cy]="sitemapTestId" [attr.aria-label]="sitemapAriaLabel">
  @for (link of linkListObj; track link) {
    <ul role="group">
      <li
        role="sitemap-item"
        authCheck
        [permissionName]="link?.permissionName"
        [checkAction]="link?.checkAction"
        class="site-map-link-wrapper"
        [ngClass]="{ 'has-children': !!link.subLinkArray }"
      >
        @if (!link?.linkExternalUrl) {
          <a
            [dsRouterLink]="link?.linkRoute"
            [queryParams]="link?.queryParams"
            [attr.data-cy]="link?.linkTestId"
            (click)="sitemapClicked.emit(link)"
            class="heading-sub-page"
          >
            @if (!!link?.linkIcon) {
              <span role="img" alt="" [ngClass]="link?.linkIcon"></span>
            }
            <span>{{ link.linkText }}</span>
          </a>
        }
        @if (!!link?.linkExternalUrl) {
          <a
            [href]="link?.linkExternalUrl"
            [attr.target]="link?.linkTarget"
            [attr.data-cy]="link?.linkTestId"
            class="heading-sub-page"
          >
            {{ link.linkText }}</a
          >
        }
      </li>
      @for (sublink of link?.subLinkArray; track sublink) {
        <li
          role="sitemap-item"
          authCheck
          [permissionName]="sublink?.permissionName"
          [checkAction]="sublink?.checkAction"
          [attr.data-cy]="sublink?.linkTestId"
        >
          @if (!!sublink?.linkIcon) {
            <span role="img" alt="" [ngClass]="sublink?.linkIcon"></span>
          }
          @if (!sublink?.linkExternalUrl) {
            <a
              [dsRouterLink]="sublink?.linkRoute"
              [queryParams]="sublink?.queryParams"
              [attr.data-cy]="sublink?.linkTestId"
            >
              {{ sublink.linkText }}</a
            >
          }
          @if (!!sublink?.linkExternalUrl) {
            <a
              [href]="sublink?.linkExternalUrl"
              [attr.target]="sublink?.linkTarget"
              [attr.data-cy]="sublink?.linkTestId"
            >
              {{ sublink.linkText }}</a
            >
          }
        </li>
        @if (!!sublink?.dividerAfter) {
          <hr aria-hidden="true" />
        }
      }
      @if (!!link?.dividerAfter) {
        <hr aria-hidden="true" />
      }
    </ul>
  }
</div>
