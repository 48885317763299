export interface PaymentMethod {
  achWithdrawal?: AchWithdrawal;
  lastUpdateTimestamp: string;
  nickName: string;
  paymentAccountId: string;
  modeOfAuthorization?: PaymentMethodModeOfAuthorizationType;
  creditCard?: CreditCard;
  expired?: boolean;
  expiring?: boolean;
}

export interface CreditCard {
  cardNumber: string;
  cardType: string;
  expirationStatus: CreditCardExpirationStatusType;
}

export interface AchWithdrawal {
  routingNumber: string;
  accountNumber: string;
  accountType: PaymentMethodAccountType;
  accountUse: PaymentMethodAccountUseType;
  financialInstitution: string;
}

export interface PaymentMethodAddEditConfig {
  accountType: PaymentMethodAccountType;
  operation: PaymentMethodAddEditOperationType;
  paymentMethod: PaymentMethod;
}

export interface PaymentMethodAnalyticsModel {
  pageAnalyticName?: string;
  buttonAnalyticName?: string;
}

export enum PaymentMethodAccountType {
  CREDIT_DEBIT = 'Credit',
  SAVINGS = 'Savings',
  CHECKING = 'Checking'
}

export enum PaymentMethodFinancialType {
  CARD = 'CARD',
  BANK = 'BANK'
}

export enum CreditCardType {
  AMERICAN_EXPRESS = 'AX',
  DISCOVER = 'DS',
  VISA = 'VI',
  MASTERCARD = 'MC'
}

export class CreditCardRegex {
  public static readonly AMERICAN_EXPRESS = /^3[47]/g;
  public static readonly DISCOVER =
    /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/;
  public static readonly VISA = /^4/;
  public static readonly MASTERCARD = /^5[1-5]/;
}

export enum CreditCardExpirationStatusType {
  EXPIRED = 'EXPIRED',
  EXPIRING_SOON = 'EXPIRING_SOON',
  NOT_EXPIRED = 'NOT_EXPIRED'
}

export enum PaymentMethodModeOfAuthorizationType {
  ESIGNATURE = 'ESIGNATURE',
  ORAL = 'ORAL',
  EMPTY = ''
}

export enum PaymentMethodAccountUseType {
  PERSONAL = 'Personal',
  BUSINESS = 'Business'
}

export enum PaymentMethodAddEditOperationType {
  ADD = 'add',
  EDIT = 'edit'
}

export interface PaymentMethodRow {
  accType: string;
  paymentMethod: PaymentMethod;
  paymentMethodDetails: CreditCard | AchWithdrawal;
}
