import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { get as _get, has as _has } from 'lodash';

import { BillAccount, ONLINE_BILLING, UNSPECIFIED } from '@amfam/shared/models';

import { BillingActions, BillingActionTypes } from './billaccount.actions';

export const BILLING_FEATURE_KEY = 'billing';

export interface BillingPartialState {
  readonly [BILLING_FEATURE_KEY]: BillingEntityState;
}

export interface BillingEntityState extends EntityState<BillAccount> {
  selectedBillAccountNumber: string;
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  error: Error;
  deepLinkBillAccountFound?: boolean;
  billAccountHasAutopay?: boolean;
}

export const adapter: EntityAdapter<BillAccount> = createEntityAdapter<BillAccount>({
  selectId: (billingObjModel: BillAccount) => billingObjModel.billAccountNumber,
  sortComparer: false
});

export const initialState: BillingEntityState = adapter.getInitialState({
  selectedBillAccountNumber: '',
  loading: false,
  loaded: false,
  hasError: false,
  error: null
});

export function reducer(
  state: BillingEntityState = initialState,
  action: BillingActions
): BillingEntityState {
  switch (action.type) {
    case BillingActionTypes.BillAccountsLoadSuccessType: {
      return adapter.upsertMany(_get(action, 'payload', []), state);
    }

    case BillingActionTypes.BillAccountUpdateDeliveryPreferenceType: {
      const id = _get(action, 'payload.billAccountNumber');
      return adapter.upsertOne(
        Object.assign({}, state.entities[id], {
          ...state.entities[id],
          updatingPreferenceAction: true,
          correlationId: null
        }),
        state
      );
    }
    case BillingActionTypes.BillAccountUpdateDeliveryPreferenceSuccessType: {
      const id = _get(action, 'payload.billAccountNumber');
      return adapter.upsertOne(
        Object.assign({}, state.entities[id], {
          ...state.entities[id],
          updatingPreferenceAction: false,
          correlationId: _get(action, 'payload.updatePreferenceCorrelationId')
        }),
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateDeliveryPreferenceFailType: {
      const id = _get(action, 'payload.billAccountNumber');
      const errorCode = _get(action, 'payload.error.status.code');
      return adapter.upsertOne(
        Object.assign({}, state.entities[id], {
          ...state.entities[id],
          updatingPreferenceAction: false,
          hasError: true,
          errorCode: errorCode
        }),
        state
      );
    }

    case BillingActionTypes.BillAccountLoadDetailSuccessType:
    case BillingActionTypes.BillAccountLoadDocumentsSuccessType:
    case BillingActionTypes.BillAccountUpdatePreferenceSuccessType:
    case BillingActionTypes.BillAccountUpdatePreferenceFailType:
    case BillingActionTypes.BillAccountDeletePreferenceSuccessType:
    case BillingActionTypes.BillAccountDeletePreferenceFailType:
    case BillingActionTypes.BillAccountUpdateRegistrationSuccessType:
    case BillingActionTypes.BillAccountUpdateRegistrationPartialFailType:
    case BillingActionTypes.BillAccountUpdateRegistrationFailType:
    case BillingActionTypes.BillAccountLoadUnAssociatedRiskDetailSuccessType:
    case BillingActionTypes.BillAccountReloadMinimumDueSuccessType:
    case BillingActionTypes.BillAccountLoadPreferenceSuccessType:
    case BillingActionTypes.BillAccountUpdateBillingPreferenceSuccessType: {
      return adapter.upsertOne(_get(action as unknown, 'payload'), state);
    }

    case BillingActionTypes.BillAccountTransformPolicyListSuccessType: {
      return adapter.upsertOne(_get(action, 'payload.billAccount'), state);
    }

    case BillingActionTypes.BillAccountLoadFuturePaymentsSuccessType: {
      const futurePayments = _get(action.payload, 'paymentSchedule.paymentScheduleItems', []);
      const id = action.payload.paymentSchedule.billAccountNumber.replace(/-/g, '');
      return adapter.upsertOne(
        Object.assign({}, state.entities[id], {
          billAccountNumber: id,
          futurePayments: futurePayments
        }),
        state
      );
    }

    case BillingActionTypes.BillAccountUnregisterAssociatedType: {
      const updatedBillAccount = Object.assign(
        {},
        state.entities[action.payload.billAccountNumber],
        { billingMethod: 'PAPER' }
      );
      delete updatedBillAccount.billingPreferences;
      delete updatedBillAccount.registrationDate;
      return adapter.upsertOne(updatedBillAccount, state);
    }

    case BillingActionTypes.BillAccountUnregisterUnAssociatedType: {
      return adapter.removeOne(action.payload.bilLAccountNumber, state);
    }

    case BillingActionTypes.BillAccountLoadPreferenceFailType: {
      return adapter.upsertOne(
        {
          ...state.entities[action.payload.billAccountNumber],
          loadPrefsError: true
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateReadOnlyStatusType: {
      const ba: BillAccount = state.entities[action.payload.billAccountNumber];

      const isUnspecifiedBillingMethod = _get(ba, 'billingMethod') === UNSPECIFIED;

      /*
        registeredElsewhere can give a false positive when the GET /billaccounts?WAID call fails.
        Checking the inverse of isRegisteredHere in the isExternalBam calculation avoids this
      */
      const isRegisteredHere =
        !ba.registeredElsewhere && _get(ba, 'billingMethod') === ONLINE_BILLING;

      /*
        Determine if the bill account is managed by another user:
        Externam Bam if:
        1. there are preferences (AND)
        2. the first preference is NOT modifiable (AND) // TODO: From John: If one is not mofifyable all will be not modifyable
        3. It is registered but not registered here (AND)
        4. It is not on AFT (AND)
        5. It is not an unspecified billingMethod (usually an unregistered commercial policy)
      */
      const isExternalBam =
        _has(action, 'payload.billingPreferences') &&
        !_get(ba, 'billingPreferences.preferences[0].isModifiable') && // TODO: Should this be all of them are not modifyable?
        !isRegisteredHere &&
        !ba.enabledAFT &&
        !isUnspecifiedBillingMethod;

      /*
        Determine if payments should be disabled:
        notEligibleToMakePayments if:
        1. there is a preferences load error (AND)
        2. The bill account is not registered
      */
      const notEligibleToMakePayments =
        _get(ba, 'loadPrefsError', false) && _get(ba, 'billingMethod', '') !== ONLINE_BILLING;

      /*
        Determine if the bill account should be read-only:
        ReadOnly if:
        1. It is registered elsewhere (or)
        2. It is on AFT (or)
        3. There is an external Bam (or)
        4. Payments are disabled
      */
      const readOnly: boolean =
        _get(ba, 'registeredElsewhere', false) ||
        _get(ba, 'enabledAFT', false) ||
        isExternalBam ||
        notEligibleToMakePayments;

      return adapter.upsertOne(
        Object.assign({}, ba, {
          readOnly: readOnly,
          isExternalBam: isExternalBam
        }),
        state
      );
    }

    case BillingActionTypes.BillAccountPopulatePreferenceType: {
      return adapter.upsertOne(
        Object.assign({}, state.entities[action.payload.billAccountNumber], action.payload),
        state
      );
    }

    case BillingActionTypes.BillAccountFoundType: {
      return { ...state, deepLinkBillAccountFound: _get(action, 'payload') };
    }

    case BillingActionTypes.DoesBillAccountHaveAutopayType: {
      return { ...state, billAccountHasAutopay: _get(action, 'payload') };
    }

    case BillingActionTypes.DismissBannerType: {
      return { ...state, billAccountHasAutopay: _get(action, 'payload') };
    }

    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
