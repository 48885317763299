<div class="container flex">
  <div class="column">
    <h5
      data-cy="deleteMyAccountHeaderOneB"
      content
      contentPath="enrollment.deleteMyAccountHeader"
    ></h5>
    <ul>
      <li data-cy="automaticPayment" content contentPath="enrollment.automaticPayment"></li>
      <li
        data-cy="discountPaperlessAndAutoPay"
        content
        contentPath="enrollment.discountPaperlessAndAutoPay"
      ></li>
      <li data-cy="claimFilling" content contentPath="enrollment.claimFilling"></li>
      <li data-cy="onlineCommunication" content contentPath="enrollment.onlineCommunication"></li>
    </ul>
    <span data-cy="deleteMyAccountFooter" content contentPath="enrollment.deleteMyAccountFooter">
    </span>
  </div>
  <div class="column flex-1 delete-account">
    <button
      ds-button
      class="delete-confirm"
      (click)="userConfirmationDeleteAccount()"
      [attr.aria-label]="'Delete Account'"
      data-cy="deleteMyAccountBtn"
      content
      contentPath="enrollment.deleteMyAccountBtn"
    ></button>
  </div>
  <ds-modal [id]="deleteMyAccountModalId" [closeOthers]="true">
    <div class="error-modal-container">
      <h3>Error</h3>
      <div
        data-cy="errorWhileDeletingMyAccount"
        content
        contentPath="enrollment.errorWhileDeletingMyAccount"
        class="pad-vertical-20 font-size-0-85"
      ></div>
      <button
        ds-button
        (click)="closeDeleteMyAccountModal()"
        data-cy="deleteMyAccountModal"
        content
        contentPath="enrollment.okBtn"
        class="error-ok flex-1"
      ></button>
    </div>
  </ds-modal>
  <ds-modal [id]="deleteMyAccountConfirmModalId" [closeOthers]="true" class="pad-bottom-20">
    <div class="modal-container">
      <h5 class="font-weight-bold" content contentPath="enrollment.deleteAccountModalHeader"></h5>
      <div
        data-cy="deleteMyAccountConfirmMessage"
        class="pad-vertical-20 font-size-0-85"
        content
        contentPath="enrollment.deleteMyAccountConfirmMessage"
      ></div>
      <div class="flex">
        <button
          ds-button
          (click)="closeDeleteMyAccountConfirmModal()"
          data-cy="deleteMyAccountCancel"
          class="delete-cancel flex-1"
          content
          contentPath="enrollment.deleteMyAccountCancel"
        ></button>
        <button
          ds-button
          (click)="deleteAccount()"
          data-cy="DeleteMyAccountConfirmModal"
          class="delete-confirm flex-1"
          content
          contentPath="enrollment.yesDelete"
        ></button>
      </div>
    </div>
  </ds-modal>
</div>
