<div>
  @if (noEnrolledUserStateInfo) {
    <h3 data-cy="enrollmentMyAccountExistsHeader">
      We're sorry. We found a My Account that already exists.
    </h3>
  } @else {
    <h3 data-cy="enrollmentMyAccountExistsHeader">
      We're sorry. We found a My Account that already exists with the following information.
    </h3>
  }
  @if (lastName) {
    <hr aria-hidden="true" class="dash" />
  }
  <div class="margin-top-20">
    @if (lastName) {
      <p>
        Last Name: <b>{{ lastName }}</b>
      </p>
    }
    @if (zipcode) {
      <p>
        ZIP Code: <b>{{ zipcode }}</b>
      </p>
    }
    @if (dob) {
      <p>
        Date of Birth: <b>{{ dob }}</b>
      </p>
    }
    @if (policyNum) {
      <p>
        Policy Number: <b>{{ policyNum }}</b>
      </p>
    }
  </div>
  @if (lastName) {
    <hr aria-hidden="true" class="dash" />
  }
  <div class="row">
    @if (!isMaeWorkflow) {
      <a
        href="javascript:void(0)"
        (click)="goBackOneStep()"
        class="inline-block margin-top-20 caption"
        ><i class="icon-chevron-left"></i> Back</a
      >
    }
    <button ds-button type="button" (click)="goToLogin()" class="float-right -offset-top">
      Go to the Login Page
    </button>
  </div>
  <hr aria-hidden="true" />
</div>
