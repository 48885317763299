/* eslint-disable @typescript-eslint/naming-convention */
import { userQuery } from '@amfam/shared/user';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Observable} from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as fromRoot from '../store';
import { Credentials } from '../store/session/session.actions';
import { userDetails } from '../store/delete-myaccount/delete.actions';

@Injectable()
export class DeleteService {

  private digitalAccountApiUrl: string;
  private authenticateUrl: string;
  private deleteAccountApiUrl: string;
  private unenrollUrl: string;
  private deleteUrl: string;
  private communicationApiUrl: string;
  private templatedEmailUrl: string;

  constructor(
    private store: Store<fromRoot.RootState>,
    private config: ConfigService,
    private http: HttpClient  ) {
    this.deleteAccountApiUrl = this.config.get('deleteAccountApi');
    this.digitalAccountApiUrl = this.config.get('digitalAccountApi');
    this.communicationApiUrl = this.config.get('communicationApi');

    this.authenticateUrl = this.digitalAccountApiUrl + '/digitalaccounts/authenticate';
    this.unenrollUrl = this.deleteAccountApiUrl + '/unenrollprograms/WAID-CTDNC';
    this.deleteUrl = this.deleteAccountApiUrl + '/delete/WAID-CTDNC';
    this.templatedEmailUrl = this.communicationApiUrl + 'templatedemails';
  }

  authenticate(payload: Credentials): Observable<any> {
    const body = {};
    let headers = new HttpHeaders();
    headers = headers.set(
      'Authorization',
      'Basic ' + btoa(payload.username + ':' + payload.password)
    );

    return this.http
      .post(this.authenticateUrl, body, { headers: headers })
      .pipe(map((data: any) => {
        return data
      }));
  }

  unenrollPrograms(): Observable<any> {

    let CustkeyToken = '';
    this.store
      .select(userQuery.selectCUSTKEY)
      .pipe(take(1))
      .subscribe(token => (CustkeyToken = token));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + CustkeyToken,
      })
    };

    const body = {};

    return this.http
      .post(this.unenrollUrl, body, { headers: httpOptions.headers })
      .pipe(map((data: any) => {
        return data
      }));
  }

  deleteMyaccount(): Observable<any> {
    let CustkeyToken = '';
    this.store
      .select(userQuery.selectCUSTKEY)
      .pipe(take(1))
      .subscribe(token => (CustkeyToken = token));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + CustkeyToken,
      })
    };

    const body = {
      reason: "Delete MyAccount - User initiated via Web app"    
      };

    return this.http
      .post(this.deleteUrl, body, { headers: httpOptions.headers })
      .pipe(map((data: any) => {
        return data
      }));
  }

  sendTemplatedEmails(payload: userDetails): Observable<any> {
    let CustkeyToken = '';
    this.store
      .select(userQuery.selectCUSTKEY)
      .pipe(take(1))
      .subscribe(token => (CustkeyToken = token));
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + CustkeyToken,
      })
    };
    
    const body = {
      brandId: "MyAccount",
      language: "EN",
      messageDetails: [
        {
          key: "EmailAddress",
          value: ""
        },
        {
          key: "SubscriberKey",
          value: ""
        },
        {
          key: "FirstName",
          value: payload.firstName
        }
      ],
      partnerId: "AFI",
      recipientAddress: payload.email,
      templateCode: "MYAC_DEL"
    };


    return this.http
      .post(this.templatedEmailUrl, body, { headers: httpOptions.headers })
      .pipe(map((data: any) => {
        return data
      }));
  }

}
