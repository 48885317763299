import { ConfigService } from '@amfam/shared/utility/shared-services';
import { merge as _merge } from 'lodash';
import { environment } from '../environments/environment';

const { version: appVersion } = require('../../../../package.json');

export interface Config {
  applicationTitle: string;
  version: string;
  features: {
    addVehicle: string;
    maintenanceRedirect: string;
    chat: string;
    partner: string;
    partnerSso: string;
    fnol: string;
    saveUserID: string;
    typeZeroSecurityQuestions: string;
    opportunities: string;
    photoUpload: string;
    upsellOpportunities: string;
    postFnolFlow: string;
    mobileAppBanner: string;
    accidentChecklist: string;
    tokenBasedEnrollment: string;
    propertyFnol: string;
    reliefCredit: string;
    autoFnol: string;
    reliefCreditPhase2: string;
    classicPastDue: string;
    reliefCreditPhase4: string;
    partnerDynamicFnol: string;
    kydModalCoverage: string;
    paymentAccordionRefactor: string;
    autoPayRefactor: string;
    reliefCreditPhase5: string;
    opportunitiesV2: string;
    partnerAddVehicle: string;

    // environment values
    production?: false;
    devMode?: boolean;
    suppressErrorPage?: boolean;
    billingCareCentreEmail?: string;
    billingPersonalLinesEmail?: string;
    billingCommercialLinesEmail?: string;
    billingInternalFromEmail?: string;
    policyChangeRequestInternal?: string;
    policyGracePeriodNotificatonInternal?: string;
    policyGracePeriodNotificatonInternalAgencyMailbox?: string;
    claimContantAdjusterInternal?: string;
    claimReportClaimInternal?: string;
    partnerBillingEmail?: string;
    partnerClaimEmail?: string;
    partnerAdjusterEmail?: string;
    gaProperty?: string;
    afiApiKey?: string;
  };
  // only in config.json, used for interpolation
  amfamDotCom?: string;
  appDomainTier?: string;
  apps?: string;
  myDotAmfam?: string;
  gateway?: string;
  contentApiGateway?: string;
  sitecoreLambda?: string;
  partnerLandingPageTier?: string;
  finAccount?: string;
  payNow?: string;
  // API endpoints
  addVehicleContextApi: string;
  addVehicleSvc: string;
  addressLookupApi: string;
  alternateAuthApi: string;
  autoWhatIfApi: string;
  billingApi: string;
  brandApi: string;
  claimApiv1: string;
  claimApi: string;
  claimServicesUtilApi: string;
  claimExposureApi: string;
  communicationApi: string;
  contentApi: string;
  contextRouterApi: string;
  customerUploadApi: string;
  digitalAccountApi: string;
  digitalServiceProgramApi: string;
  discountApi: string;
  documentApi: string;
  claimVendorLocations: string;
  eSignatureApi: string;
  financialAccountService: string;
  gatewayTimeApi: string;
  geoApi: string;
  maeApi: string;
  partyApi: string;
  paymentApi: string;
  payoroboApi: string;
  pciAuthorizationService: string;
  producerApi: string;
  productApi: string;
  reportGlassClaimApi: string;
  recommenderApi: string;
  ubiApi: string;
  vehicleApi: string;
  claimFnolApi?: string;
  creativeVirtualApi?: string;
  converseApi?: string;
  recommenderApiV2?: string;

  // API endpoint versions
  addVehicleContextApiVersion?: string;
  addressLookupApiVersion?: string;
  alternateAuthApiVersion?: string;
  autoWhatIfApiVersion?: string;
  billingApiVersion?: string;
  brandApiVersion?: string;
  claimApiv1Version?: string;
  claimEnterpriseApiVersion?: string;
  claimEnterpriseApiPropertyVersion?: string;
  claimEnterpriseGateway?: string;
  reportClaimApiVersion?: string;
  claimApiVersion?: string;
  claimServicesUtilApiVersion?: string;
  claimExposureApiVersion?: string;
  communicationApiVersion?: string;
  contentApiVersion?: string;
  customerUploadApiVersion?: string;
  deleteAccountApiVersion?: string;
  digitalAccountApiVersion?: string;
  digitalServiceProgramApiVersion?: string;
  discountApiVersion?: string;
  documentApiVersion?: string;
  claimVendorLocationsVersion?: string;
  eSignatureApiVersion?: string;
  finAccountVersion?: string;
  gatewayTimeApiVersion?: string;
  geoApiVersion?: string;
  i3ApiVersion?: string;
  maeApiVersion?: string;
  partyApiVersion?: string;
  paymentApiVersion?: string;
  pciAuthorizationApiVersion?: string;
  producerApiVersion?: string;
  productApiVersion?: string;
  reportGlassClaimApiVersion?: string;
  ssoApiVersion?: string;
  ubiApiVersion?: string;
  vehicleApiVersion?: string;
  claimFnolApiVersion?: string;
  creativeVirtualApiVersion?: string;
  converseApiVersion?: string;
  reliefCreditApi: string;
  reliefCreditApiVersion?: string;
  // links
  links: {
    amfamAppStoreLink: string;
    amfamChatUrl: string;
    amfamDotComUrl: string;
    amfamDotComClaimsFaqUrl: string;
    amfamDRPShopSearchUrl: string;
    amfamGooglePlayLink: string;
    fxm: string;
    htkPortalUrl: string;
    launch: string;
    nortonSecurity: string;
    propertyServiceSsoUrl: string;
    propertyServiceUrl: string;
    reportGlassClaimVendorUrl: string;
    scheduleACallbackUrl: string;
    payNowURL: string;
    reliefPaymentURL: string;
    myAccountURL: string;
    companyInfoPage: string;
    salesForceBeconURL: string;
  };
  // impersonation
  impersonationConfig: {
    apiEndpoint: string;
    applicationName: string;
  };
  // static items
  processId: string;
  afiExperienceId: string;
  numberLink: string;
  lifeNumberLink: string;
  lifeBillingLink: string;
  billingEmail: string;
  fnolContactNumber: string;
  profileEmailTypeList: Array<string>;
  profileValidateEmailPath: string;
  userIdRetrieval_AllowedAttempts: number;
  afiAppName: string;
  finAcctConsumerKey?: string;
  amfamGlassKey?: string;
  osiGlassKey?: string;
  midvaleGlassKey?: string;
  modelCoverageDisabledStates: string[];
  gmKey: string;
  afiApiKey: string;
  stampsApi: string;
}

export function setConfigDefaults(data): Config {
  /* Note:
    1.8.0 will be read as 1.08.0, not 1.80.0
  */

  const configData = {
    applicationTitle: 'MyAccount',
    version: appVersion,
    featureOverride: {},
    features: {
      addVehicle: '>=1.82.0',
      maintenanceRedirect: '>=3.0.0',
      partner: '>=1.10.0',
      partnerSso: '>=1.11.0',
      fnol: '>=1.92.0', // Toggle ON FNOL for hotfix release 1.92.4
      saveUserID: '>=1.27.0',
      typeZeroSecurityQuestions: '>=1.27.0',
      opportunities: '>=1.31.0',
      chat: '>=1.34.0',
      upsellOpportunities: '>=1.36.0',
      photoUpload: '>=1.92.0', // Toggle ON FNOL for hotfix release 1.92.4
      postFnolFlow: '>=1.92.0', // Toggle ON FNOL for hotfix release 1.92.4
      mobileAppBanner: '>=1.43.0',
      accidentChecklist: '>=1.49.0',
      tokenBasedEnrollment: '>=1.61.0',
      propertyFnol: '>=1.92.0', // Toggle ON FNOL for hotfix release 1.92.4
      reliefCredit: '>=1.70.0',
      autoFnol: '>=1.92.0', // Toggle ON FNOL for hotfix release 1.92.4
      reliefCreditPhase2: '>=1.73.0',
      reliefCreditPhase4: '>=1.83.0',
      partnerDynamicFnol: '>=1.92.0', // Toggle ON FNOL for hotfix release 1.92.4
      classicPastDue: '>=1.84.0',
      kydModalCoverage: '>=1.83.0',
      paymentAccordionRefactor: '>2.0.0',
      autoPayRefactor: '>=1.94.0',
      autoPayPhaseTwo: '>=1.98.0',
      reliefCreditPhase5: '>=1.88.0',
      ie11Disclaimer: '>=1.89.0',
      replaceVehicleQuickQuote: '>=1.92.0',
      cookieCheck: '>=1.91.0',
      opportunitiesV2: '>=1.92.0',
      partnerAddVehicle: '>=1.100.0'
    },
    financialAccountService: `https://${
      data.finAccount
    }.amfam.com/finacctservice/services/external/${data.finAccountVersion || 'v2'}/finacct/`,
    pciAuthorizationService: `https://${
      data.finAccount
    }.amfam.com/pciAuthorizationService/services/external/${
      data.pciAuthorizationApiVersion || 'v1'
    }/`,
    addressLookupApi: `https://${data.gateway}.amfam.com/geo/${
      data.addressLookupApiVersion || 'v2'
    }/matchedlocations`,
    addVehicleContextApi: `https://${data.gateway}.amfam.com/addvehicle/${
      data.addVehicleContextApiVersion || 'v1'
    }/`,
    addVehicleSvc: `https://${data.gateway}.amfam.com/addvehiclesvc/'v1'/`,
    plpcContextAPI: `https://${data.gateway}.amfam.com/plpcvehicle/v1/`,
    alternateAuthApi: `https://${data.gateway}.amfam.com/altauthn/${
      data.alternateAuthApiVersion || 'v1'
    }/`,
    autoWhatIfApi: `https://${data.gateway}.amfam.com/autowhatif/${
      data.autoWhatIfApiVersion || 'v2'
    }/`,
    billingApi: `https://${data.gateway}.amfam.com/billaccount/${data.billingApiVersion || 'v1'}/`,
    brandApi: `https://${data.gateway}.amfam.com/brands/${data.brandApiVersion || 'v1'}`,
    claimApiv1: `https://${data.gateway}.amfam.com/claims/${data.claimApiv1Version || 'v1'}/`,
    claimEnterpriseApi: `https://${data.gateway}.amfam.com/launchclaims/${
      data.claimEnterpriseApiVersion || 'v1'
    }/`,
    claimEnterpriseApiv2: `https://${data.gateway}.amfam.com/launchclaims/${
      data.claimEnterpriseApiPropertyVersion || 'v1'
    }/`,
    reportClaimApi: `https://${data.gateway}.amfam.com/reportclaim/${
      data.reportClaimApiVersion || 'v1'
    }/`,
    claimApi: `https://${data.gateway}.amfam.com/claims/${data.claimApiVersion || 'v2'}/`,
    claimServicesUtilApi: `https://${data.gateway}.amfam.com/claimservicesutil/${
      data.claimServicesUtilApiVersion || 'v1'
    }/`,
    claimExposureApi: `https://${data.gateway}.amfam.com/exposure/${
      data.claimExposureApiVersion || 'v1'
    }/`,
    communicationApi: `https://${data.gateway}.amfam.com/communication/${
      data.communicationApiVersion || 'v1'
    }/`,
    contentApi: `https://${data.contentApiGateway || data.gateway}.amfam.com/content/${
      data.contentApiVersion || 'v1'
    }/`,
    sitecoreApi: `https://${data.sitecoreDomain}/ContentApi/`,
    sitecoreLambdaApi: `https://${data.sitecoreLambda}/sitecore/`,
    contextRouterApi: `https://afi${data.contextRouterTier}.amfam.com/`,
    customerUploadApi: `https://${data.gateway}.amfam.com/customerupload/${
      data.customerUploadApiVersion || 'v1'
    }/`,
    deleteAccountApi: `https://${data.gateway}.amfam.com/myaccountdelete/${
      data.deleteAccountApiVersion || 'v1'
    }`,
    digitalAccountApi: `https://${data.gateway}.amfam.com/digitalaccount/${
      data.digitalAccountApiVersion || 'v1'
    }`,
    digitalServiceProgramApi: `https://${data.gateway}.amfam.com/digitalserviceprogram/${
      data.digitalServiceProgramApiVersion || 'v1'
    }`,
    discountApi: `https://${data.gateway}.amfam.com/discount/${data.discountApiVersion || 'v1'}/`,
    documentApi: `https://${data.gateway}.amfam.com/document/${data.documentApiVersion || 'v2'}/`,
    claimVendorLocations: `https://${data.gateway}.amfam.com/claimvendorlocations/${
      data.claimVendorLocationsVersion || 'v1'
    }/`,
    eSignatureApi: `https://${data.gateway}.amfam.com/esignature/${
      data.eSignatureApiVersion || 'v1'
    }/`,
    gatewayTimeApi: `https://${data.gateway}.amfam.com/util/${
      data.gatewayTimeApiVersion || 'v1'
    }/time`,
    geoApi: `https://${data.gateway}.amfam.com/geo/${data.geoApiVersion || 'v2'}/`,
    maeApi: `https://${data.gateway}.amfam.com/myaccountenrollment/${data.maeApiVersion || 'v2'}/`,
    payoroboApi: `https://${data.gateway}.amfam.com/payorobo/${data.billingApiVersion || 'v1'}/`,
    partyApi: `https://${data.gateway}.amfam.com/party/${data.partyApiVersion || 'v3'}/`,
    paymentApi: `https://${data.gateway}.amfam.com/customerpayment/${
      data.paymentApiVersion || 'v1'
    }/`,
    producerApi: `https://${data.gateway}.amfam.com/producer/${data.producerApiVersion || 'v1'}/`,
    productApi: `https://${data.gateway}.amfam.com/product/${data.productApiVersion || 'v1'}/`,
    recommenderApi: `https://${data.gateway}.amfam.com/recommenderservice/${
      data.productApiVersion || 'v1'
    }/`,
    reportGlassClaimApi: `https://${data.gateway}.amfam.com/safelite/${
      data.reportGlassClaimApiVersion || 'v1'
    }/safelitetoken`,
    ubiApi: `https://${data.gateway}.amfam.com/ubi/${data.ubiApiVersion || 'v1'}/`,
    vehicleApi: `https://${data.gateway}.amfam.com/vehicle/${data.vehicleApiVersion || 'v1'}/`,
    claimFnolApi: `https://${data.gateway}.amfam.com/claimfnol/${
      data.claimFnolApiVersion || 'v1'
    }/`,
    creativeVirtualApi: `https://${data.gateway}.amfam.com/creativevirtual/${
      data.creativeVirtualApiVersion || 'v1'
    }/`,
    converseApi: `https://${data.gateway}.amfam.com/converse/${
      data.converseApiVersion || 'v1'
    }/question/`,
    reliefCreditApi: `https://${data.gateway}.amfam.com/covid19/${
      data.reliefCreditApiVersion || 'v1'
    }/credits/`,
    recommenderApiV2: `https://${data.gateway}.amfam.com/recommenderservice/v2/`,
    links: {
      amfamAppStoreLink: 'https://itunes.apple.com/us/app/my-amfam/id329763835?mt=8',
      amfamChatUrl: 'https://chat-ui.amfam.com?tid=ChatbotClick',
      amfamDotComUrl: `https://${data.amfamDotCom}.amfam.com/`,
      amfamDotComClaimsFaqUrl: `https://${data.amfamDotCom}.amfam.com/resources/faqs/claims-insurance`,
      amfamDRPShopSearchUrl: `https://${data.apps}.amfam.com/crpsearch/searchPage.do`,
      amfamGooglePlayLink:
        'https://play.google.com/store/apps/details?id=com.amfam.myamfam&amp;hl=en',
      fxm: `https://${data.amfamDotCom}.amfam.com/bundle/beacon`,
      htkPortalUrl: `https://${data.gateway}.amfam.com/loyalty/${
        data.ssoApiVersion || 'v2'
      }/saml/memberportal`,
      launch: '//assets.adobedtm.com/launch-ENea6e6794b2a741ffbe997ddb4527bce9-development.min.js',
      nortonSecurity: `https://www.digicert.com/what-is-ssl-tls-https`,
      propertyServiceSsoUrl: `https://${data.gateway}.amfam.com/saml/${
        data.ssoApiVersion || 'v2'
      }/homesite?relayState=Billing`,
      propertyServiceUrl: 'https://qa-patmypolicy.midvaleinsurance.com/OnlineServicing',
      reportGlassClaimVendorUrl: `https://submittest.glassclaim.com/SelfService/Default.aspx`,
      scheduleACallbackUrl: `https://${data.myDotAmfam}.amfam.com/click4call/callScheduler.do?tid=ap52`,
      payNowURL: `https://${data.payNow}.amfam.com/pmcwb/processPayment.do?paymentMethod=CWB&method=setUpPage`,
      reliefPaymentURL: `https://www.amfam.com/relief-payment`,
      myAccountURL: `https://myaccount.amfam.com/`,
      companyInfoPage: `https://www.amfam.com/companyid`,
      salesForceBeconURL: `//cdn.evgnet.com/beacon/americanfamilymutualins/development/scripts/evergage.min.js`
    },
    impersonationConfig: {
      apiEndpoint: `https://${data.gateway}.amfam.com/applicationrole/v1/applications/`,
      applicationName: 'impmyaccount2016'
    },
    processId: 'macsrvt',
    afiExperienceId: '10001',
    numberLink: 'tel:1-800-692-6326',
    fnolContactNumberHref: 'tel:1-800-692-6326',
    fnolContactNumber: '1-800-692-6326',
    ersContactNumber: 'tel:1-866-987-0206',
    lifeNumberLink: 'tel:1-844-514-6226',
    lifeBillingLink: 'tel:1-866-424-8002',
    billingEmail: '',
    profileEmailTypeList: ['HOME', 'WORK', 'SCHOOL'],
    profileValidateEmailPath: '/validate-email',
    userIdRetrieval_AllowedAttempts: 5,
    afiAppName: 'MyAccountWebApplication',
    finAcctConsumerKey: 'MYACCT',
    amfamGlassKey: 'D2315E43-A4CC-447C-AD77-233BCE008E97',
    osiGlassKey: '53F5863B-F663-4708-B55B-45F3451B4F4E',
    midvaleGlassKey: '64F367F4-0391-4C5C-92A3-A9DD567D530F',
    modelCoverageDisabledStates: ['WA'],
    optimizelyKey: `${data.optimizelyKey}`,
    gmKey: 'AIzaSyA-rMud5FMwnI0YwIm0Gim5nE195c0ye9A',
    afiApiKey: 'f5ebecb5-4c56-4cdc-9c23-d5e6677e2434',
    stampsApi: `https://${data.gateway}.amfam.com/product/v1/products/product-api/products`
  };
  return _merge(configData, environment);
}

export function loadConfig(configService: ConfigService) {
  return () => configService.load(setConfigDefaults);
}
