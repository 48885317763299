import { cloneDeep as _cloneDeep, keyBy as _keyBy } from 'lodash';

import { Step } from '../models/step';
import {
  ProgressIndicatorAction,
  ProgressIndicatorActionTypes
} from './progress-indicator.actions';

export const PROGRESSINDICATOR_FEATURE_KEY = 'progressIndicator';

export interface ProgressIndicatorState {
  loaded: boolean;
  workflowType: string;
  ids: string[];
  steps: { [id: string]: Step };
  activeStep: string;
}

export const initialState: ProgressIndicatorState = {
  loaded: false,
  workflowType: '',
  ids: [],
  steps: {},
  activeStep: ''
};

export const initialStep: Step = {
  name: '',
  title: '',
  complete: false,
  active: false,
  show: false,
  skipped: false,
  nextStep: '',
  backStep: ''
};

export interface StepCompleteModel {
  step: string;
  nextStep?: string;
}

export interface ActiveStepModel {
  activeStep?: string;
}

export interface LoadProgressIndicatorModel {
  workflowType: string;
  steps: Step[];
  activeStep?: string;
}

export function progressIndicatorReducer(
  state: ProgressIndicatorState = initialState,
  action: ProgressIndicatorAction
): ProgressIndicatorState {
  let steps = {};
  let ids = [];
  let activeStep: Step;
  let nextStep: Step;
  switch (action.type) {
    case ProgressIndicatorActionTypes.ProgressIndicatorLoad:
      steps = _keyBy(action.payload.steps, step => step['name']);
      ids = Object.keys(steps);
      return Object.assign({}, state, {
        loaded: true,
        workflowType: action.payload.workflowType,
        ids: ids,
        steps: steps,
        activeStep: action.payload.activeStep || steps[ids[0]].name
      });

    case ProgressIndicatorActionTypes.ProgressIndicatorStepComplete:
      let nextStepName = '';
      steps = _cloneDeep(state.steps);
      // find current step
      activeStep = steps[state.activeStep];
      // only complete if payload step is the active step
      if (activeStep.name !== action.payload.step) {
        return state;
      }
      // set current step to complete
      steps[activeStep.name].complete = true;
      if (activeStep.backStep) {
        // set backstep to complete
        steps[activeStep.backStep].complete = true;
      }
      // manage nextStep if changing
      if (activeStep.nextStep) {
        // find the name of the next step
        nextStepName = activeStep.nextStep;
        // set nextStep if provided
        if (action.payload && action.payload.nextStep && action.payload.nextStep !== '') {
          nextStepName = action.payload.nextStep;
          // change the step shown in the progress bar
          steps[activeStep.nextStep].show = false;
          steps[nextStepName].show = true;
          // set the new next step name
          steps[activeStep.name].nextStep = nextStepName;
        }
        // set the backStep for the nextStep
        steps[nextStepName].backStep = activeStep.name;
      }

      return Object.assign({}, state, {
        steps: steps,
        activeStep: nextStepName
      });

    case ProgressIndicatorActionTypes.ProgressIndicatorStepBack:
      steps = _cloneDeep(state.steps);
      // find the previous step
      nextStep = steps[steps[state.activeStep].backStep];
      // set route for nextStep if provided
      return Object.assign({}, state, {
        steps: steps,
        activeStep: nextStep.name
      });

    case ProgressIndicatorActionTypes.ProgressIndicatorStepNext:
      steps = _cloneDeep(state.steps);
      // find the previous step
      nextStep = steps[steps[state.activeStep].nextStep];
      // set route for nextStep if provided
      return Object.assign({}, state, {
        steps: steps,
        activeStep: nextStep.name
      });

    case ProgressIndicatorActionTypes.ProgressIndicatorStepSetActive:
      steps = _cloneDeep(state.steps);
      if (action.payload && action.payload.activeStep && action.payload.activeStep !== '') {
        activeStep = steps[action.payload.activeStep];
        if (activeStep) {
          return Object.assign({}, state, {
            steps: steps,
            activeStep: activeStep.name
          });
        }
      }
      return state;

    case ProgressIndicatorActionTypes.ProgressIndicatorReset:
      steps = _cloneDeep(state.steps);
      for (const id in steps) {
        if (steps[id]) {
          steps[id] = Object.assign(steps[id], { complete: false });
        }
      }
      ids = Object.keys(steps);
      return Object.assign({}, state, {
        steps: steps,
        activeStep: steps[ids[0]].name
      });

    case ProgressIndicatorActionTypes.ProgressIndicatorDelete:
      return initialState;

    case ProgressIndicatorActionTypes.ProgressIndicatorSkip:
      steps = _cloneDeep(state.steps);
      // mark current step as skipped
      steps[state.activeStep].skipped = true;
      // find the previous step
      nextStep = steps[steps[state.activeStep].nextStep];
      // set route for nextStep if provided
      return Object.assign({}, state, {
        steps: steps,
        activeStep: nextStep.name
      });

    default:
      return state;
  }
}
