<div
  #modal
  [attr.id]="id"
  [ngClass]="{
    'full-screen': fullScreen,
    normal: !fullScreen,
    'default-close': defaultCloseButton
  }"
  class="modal ds-modal overlay-black"
>
  <!-- The service depends on the #content child being here, don't remove it. -->
  <div #content class="content ds-modal-wrapper" [ngStyle]="{ 'max-width.px': maxWidth }">
    @if (showCloseButton) {
      <button
        ds-button
        class="close icon-exit"
        aria-label="Close"
        (click)="close()"
        [ngClass]="warningBackground ? 'ds-modal-warningClose' : ''"
      ></button>
    }
    <div class="ds-modal-content" [ngClass]="warningBackground ? 'ds-modal-warningBackground' : ''">
      <ng-content></ng-content>
    </div>
  </div>
</div>
