export interface AutoPaySetup {
  active: boolean;
  editing: boolean;
  billAccount: AutoPayBillAccount;
  autoPaySettings: AutoPaySettings;
}

export interface AutoPaySettings {
  paymentMethodId: string | number;
  paymentAccountNickName: {
    value: string;
    text: string;
  };
  daysBeforeDueDate: {
    value: string;
    text: string;
  };
  paymentAmount: {
    value: string;
    text: string;
  };
}

export interface AutoPayBillAccount {
  billAccountNumber: string;
  riskDescription: string;
  policyTypeIcon: string;
}

export enum AutoPaySteps {
  SELECT_ACCOUNTS = 'SELECT_ACCOUNTS',
  SETUP = 'SETUP',
  REVIEW = 'REVIEW',
  CONFIRMATION = 'CONFIRMATION',
  SKIP = 'SKIP'
}

export enum AutoPayContext {
  ADD_MULTIPLE = 'ADD_MULTIPLE',
  ADD = 'ADD',
  EDIT = 'EDIT'
}

export enum AutoPaySubmitStatus {
  SUCCESS = 'SUCCESS',
  PARTIAL_FAILURE = 'PARTIAL_FAILURE',
  FAILURE = 'FAILURE'
}
