import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { Applications, ApplicationService } from '@amfam/shared/utility/shared-services';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private hasExpid = false;
  private expidPath = '';
  private isMyAccountAdmin = false;

  constructor(
    private router: Router,
    private applicationService: ApplicationService
  ) {}

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    commands = this.getCommands(commands, extras);
    return this.router.navigate(commands, extras);
  }

  getExperienceId() {
    return this.expidPath;
  }

  setExperienceId(expid) {
    this.hasExpid = true;
    this.expidPath = expid;
  }

  clearExperienceId() {
    this.hasExpid = false;
    this.expidPath = '';
  }

  getCommands(currentCommands: any[] | string, extras?: NavigationExtras): any[] {
    let commands = [];
    if (currentCommands != null) {
      commands = Array.isArray(currentCommands) ? [...currentCommands] : [currentCommands];
    }
    this.isMyAccountAdmin = this.applicationService.isApp(Applications.MYACCOUNT_ADMIN);

    if (!this.isMyAccountAdmin) {
      if (this.hasExpid) {
        // add the prefix once
        if (commands && commands.length > 0) {
          if (!commands[0].startsWith('/' + this.expidPath)) {
            if (commands[0] === '/') {
              commands[0] = '/' + this.expidPath;
            } else if (commands[0].startsWith('/')) {
              commands[0] = '/' + this.expidPath + commands[0];
            } else if (extras && extras.relativeTo) {
              return commands; // no need to update relative routes that don't start with /
            } else {
              commands[0] = '/' + this.expidPath + '/' + commands[0];
            }
          }
        }
      }
    }

    return commands;
  }
}
