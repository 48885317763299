import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { DsModalService } from './ds-modal.service';

@Component({
  selector: 'ds-modal',
  styleUrls: ['./ds-modal.component.scss'],
  templateUrl: './ds-modal.component.html'
})
export class DsModalComponent implements OnInit, OnDestroy, AfterViewInit {
  private clickListenerFunc: Function;
  private touchendListenerFunc: Function;
  private keyupListenerFunc: Function;

  @Input() fullScreen = false;
  @Input() closeOthers = false;
  @Input() maxWidth: number;
  @Input() id: string;
  @Input() warningBackground: boolean;

  @Output() initialized = new EventEmitter<boolean>();
  @Output() onCloseModalEvent = new EventEmitter();

  // The service depends on the #content child being here, don't remove it.
  @ViewChild('content', { static: true })
  content: ElementRef;

  @ViewChild('modal', { static: true })
  modal: ElementRef;

  constructor(private modalService: DsModalService, private renderer: Renderer2) {}

  ngOnInit() {
    this.modalService.add(this);
    this.content.nativeElement.classList.add('none');
    this.initialized.emit(true);
  }

  ngAfterViewInit() {
    if (!this.modal.nativeElement.hasAttribute('id')) {
      console.error('modal must have an id');
      return;
    }
    if (document && document.body && this.modal) {
      document.body.appendChild(this.modal.nativeElement);
    }

    this.clickListenerFunc = this.renderer.listen(this.modal.nativeElement, 'click', event => {
      this.closeEventHandler(event);
    });
    this.touchendListenerFunc = this.renderer.listen(
      this.modal.nativeElement,
      'touchend',
      event => {
        this.closeEventHandler(event);
      }
    );
    this.keyupListenerFunc = this.renderer.listen(document.body, 'keyup', event => {
      this.keyupEventHandler(event);
    });
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    const modal = this.modal.nativeElement;
    if (modal.parentNode) modal.parentNode.removeChild(modal);

    if (this.clickListenerFunc) {
      this.clickListenerFunc();
    }

    if (this.touchendListenerFunc) {
      this.touchendListenerFunc();
    }

    if (this.keyupListenerFunc) {
      this.keyupListenerFunc();
    }
  }

  closeEventHandler(event: any) {
    if (this.modalService.hasOpenModals()) {
      const targetElement = event.target;
      if (this.content.nativeElement) {
        if (!this.content.nativeElement.contains(targetElement)) {
          this.close();
        }
      }
    }
  }

  keyupEventHandler(event: any) {
    if (this.modalService.hasOpenModals()) {
      const keyCode = event.keyCode || event.charCode;

      if (keyCode === 27) {
        this.close();
      }
    }
  }

  public close(): void {
    this.modalService.close(this.id);
    this.onCloseModalEvent.emit(true);
  }

  public closeAll(): void {
    this.modalService.closeAll();
    this.onCloseModalEvent.emit(true);
  }

  public open(): void {
    this.modalService.open(this.id, this.closeOthers);
  }
}
