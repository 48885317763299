import {
  ClaimServicesStatus,
  EligibleServicesResponse
} from '../+state/eligible-services/eligible-services.models';
import { EligibleServicesState } from '../+state/eligible-services/eligible-services.reducer';

export class MockEligibleServicesData {
  public static getMockEligibleServicesResponse = function () {
    const eligibleServicesResponse: EligibleServicesResponse = {
      status: {
        reason: 'Ok',
        code: 200,
        transactionId: '0000016a4711535a-b595',
        messages: [],
        maxMessageLevel: 'INFO'
      },
      claimNumber: '01-023-016778',
      vin: '1G3WH54TXLD406000',
      eligibleServices: eligibleServices,
      numberOfCustomerPhotos: 0
    };
    return eligibleServicesResponse;
  };

  public static getMockEligibleServicesState = function () {
    const eligibleServicesState: EligibleServicesState = {
      claimNumber: '01-023-016778',
      eligibleServices: eligibleServices,
      numberOfCustomerPhotos: 0,
      loaded: true,
      loading: false,
      hasError: false
    };
    return eligibleServicesState;
  };

  public static getMockClaimServicesStatus = function () {
    const status: ClaimServicesStatus = {
      repairEligible: true,
      repairSelected: false,
      rentalCoverage: true,
      rentalEligible: true,
      rentalSelected: false,
      rentalDependencyUnmet: true,
      photosUploaded: false
    };
    return status;
  };
}

const eligibleServices = [
  {
    serviceCode: 'drp',
    serviceDescription: 'DRP Eligible Service',
    coverageCode: '',
    coverageDescription: '',
    serviceDependenciesSatisfied: false,
    serviceDependentOnSRCode: '',
    serviceCustomerCallInMsgCode: '',
    serviceRequestDisclaimer: [
      {
        serviceDisclaimerCode: 'genericdisclaimer',
        serviceDisclaimerDescription:
          'American Family partners with the following shops who have proven to provide fast and top-quality service. The choice to repair your vehicle and where you choose to get repairs is completely yours.',
        serviceDisclaimerStateCode: 'CO'
      }
    ],
    serviceOpen: false
  },
  {
    serviceCode: 'RENTAL',
    serviceDescription: 'RENTAL Eligible Service',
    coverageCode: 'RentalCov_afi',
    coverageDescription: '$25.00 Daily Limit/$750.00 Max Limit',
    serviceDependenciesSatisfied: false,
    serviceDependentOnSRCode: 'drp',
    serviceCustomerCallInMsgCode: '',
    serviceRequestDisclaimer: [],
    serviceOpen: false
  }
];
