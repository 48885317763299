import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Option } from '@amfam/ui-kit';

@Component({
  selector: 'ds-add-payment-method-quicklinks',
  templateUrl: './add-payment-method-quicklinks.component.html',
  styleUrls: ['./add-payment-method-quicklinks.component.css']
})
export class AddPaymentMethodQuicklinksComponent {
  @Input() displayAutoPayDiscountMessage: boolean;
  @Input() paymentMethodError: boolean;
  @Input() paymentMethodOptions: Option[];
  @Input() formGroup: UntypedFormGroup;
  @Input() authorizedToAddPaymentMethod: boolean;
  @Input() authorizedToEditPaymentMethod: boolean;
  @Input() displayDiscountWarning: boolean;
  @Input() displayPaymentExpiredError: boolean;
  @Output() addCreditDebitEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() addCheckingAccountEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() addSavingsAccountEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  addCreditDebit(): void {
    if (this.authorizedToAddPaymentMethod) {
      this.addCreditDebitEvent.emit();
    }
  }

  addCheckingAccount(): void {
    if (this.authorizedToAddPaymentMethod) {
      this.addCheckingAccountEvent.emit();
    }
  }

  addSavingsAccount(): void {
    if (this.authorizedToAddPaymentMethod) {
      this.addSavingsAccountEvent.emit();
    }
  }
}
