<div class="row flex warning-box" data-cy="warningBox">
  <div class="column icon-column margin-right-10">
    <span class="icon-alert" data-cy="warningBoxIconAlert"></span>
  </div>
  <div class="column">
    <p tabindex="0" [attr.aria-label]="headingText" class="subheading" data-cy="warningBoxHeading">
      {{ headingText }}
    </p>

    <div>
      <p class="caption" data-cy="warningBoxBody">{{ messageText }}</p>
      @if (linkText) {
        <a class="caption" (click)="linkEvent.emit(linkText)" data-cy="warningLinkText">{{
          linkText
        }}</a>
      }
      @if (postLinkText) {
        <p class="caption" data-cy="warningPostLinkText">{{ postLinkText }}</p>
      }
    </div>
  </div>
</div>
