@if (isValid() && !!addressList.length) {
  <div class="suggest-address">
    <h4>USPS Suggested Address:</h4>
    @if (!!addressList.length) {
      <ul>
        @for (address of addressList; track address; let i = $index) {
          <li>
            <div class="input-group">
              <fieldset class="radio">
                <input
                  type="radio"
                  id="address{{ i }}"
                  name="address"
                  (click)="onSelect(address)"
                  [attr.checked]="address === selectedAddress"
                />
                <label
                  class="pad-left-10"
                  for="address{{ i }}"
                  [innerHTML]="getDisplayAddress(address)"
                ></label>
              </fieldset>
            </div>
          </li>
        }
      </ul>
    }
  </div>
}
