import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { defer, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { ImpersonateRolesService } from '../services/impersonate-roles.service';
import * as impersonationActions from './impersonation.actions';

@Injectable()
export class ImpersonationEffects {
  constructor(
    private actions$: Actions,
    private impersonateRoleService: ImpersonateRolesService
  ) {}

  setRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(impersonationActions.SET_ROLES),
      map((action: impersonationActions.SetRolesAction) => action.payload),
      filter(payload => {
        // Make sure there is an smImpersonatorUserID before calling populatePermissionSet
        return payload && payload.smImpersonatorUserID && payload.smImpersonatorUserID !== '';
      }),
      switchMap(payload =>
        this.impersonateRoleService.populatePermissionSet(payload).pipe(
          map((response: any) => {
            return new impersonationActions.SetRolesSuccessAction(response);
          }),
          catchError(err => of(new impersonationActions.SetRolesFailAction(err)))
        )
      )
    )
  );
}
