<section>
  <h2 data-cy="answerSecurityQuestionsText" class="heading-main">Answer Security Questions</h2>
  <p content [contentPath]="'auth.securityQtnResetHelpText'"></p>
  <form [formGroup]="securityForm" (ngSubmit)="submitAnswers()" class="pad-top-20">
    <div class="column twelve text-left">
      <p data-cy="answerSecurityQuestions1" class="margin-bottom-5">
        <strong>Question 1:</strong> {{ firstQuestion }}?
      </p>
    </div>
    <div class="margin-bottom-20">
      <show-hide>
        <ds-form-control-container
          [labelText]="'Answer'"
          [control]="securityForm.controls['inputFirstAnswer']"
          class="margin-bottom-20"
        >
          <input
            data-cy="answerSecurityQuestionsInput1"
            show-hide-input
            id="inputFirstAnswer"
            [focus]="true"
            class="fill"
            formControlName="inputFirstAnswer"
            maxlength="50"
          />
          <ds-form-control-message
            [control]="securityForm.controls['inputFirstAnswer']"
          ></ds-form-control-message>
        </ds-form-control-container>
      </show-hide>
    </div>
    <div class="column twelve text-left">
      <p data-cy="answerSecurityQuestions2" class="margin-bottom-5">
        <strong>Question 2:</strong> {{ secondQuestion }}?
      </p>
    </div>
    <div class="margin-bottom-20">
      <show-hide>
        <ds-form-control-container
          [labelText]="'Answer'"
          [control]="securityForm.controls['inputSecondAnswer']"
        >
          <input
            data-cy="answerSecurityQuestionsInput2"
            show-hide-input
            id="inputSecondAnswer"
            class="fill"
            formControlName="inputSecondAnswer"
            maxlength="50"
          />
          <ds-form-control-message
            [control]="securityForm.controls['inputSecondAnswer']"
          ></ds-form-control-message>
          @if (securityForm.hasError('answersMatch')) {
            <ds-form-control-message [control]="securityForm"></ds-form-control-message>
          }
          @if (securityVerificationError) {
            <span
              data-cy="answerSecurityQuestionsMismatchErrorText"
              class="block caption error"
              content
              [contentPath]="'auth.securityQtnAnswerMismatch'"
            ></span>
          }
          @if (securityQuestionsGenericError) {
            <span
              class="block caption error"
              content
              [contentPath]="'auth.resetOptionsGenericError'"
            ></span>
          }
        </ds-form-control-container>
      </show-hide>
    </div>
    <button
      ds-button
      data-cy="answerSecurityQuestionsConfirmButton"
      id="confirmBtn"
      class="float-right"
      type="submit"
      [disabled]="!securityForm.valid"
    >
      Next
    </button>
  </form>
  <a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block margin-top-20 caption"
    ><em class="icon-chevron-left"></em> Back</a
  >

  @if (submitAttempts >= 3) {
    <div class="row pad-top-20">
      <h3 content [contentPath]="'auth.securityQtnTrouble'"></h3>
      <small>
        <span content [contentPath]="'auth.securityQtnOtherOptions1'"></span>
        <span *dsPartnerConnect dsPartnerContent [brandContent]="'selectPrettyCusCareNumber'"></span>
        <span *dsPartnerAFI content [contentPath]="'auth.securityQtnNumberAFI'"></span>
        <span content [contentPath]="'auth.securityQtnOtherOptions2'"></span>
      </small>
    </div>
  }
</section>
