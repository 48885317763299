import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AgentEffects } from './+state/agent.effects';
import { AGENT_FEATURE_KEY, agentReducer } from './+state/agent.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AGENT_FEATURE_KEY, agentReducer),
    EffectsModule.forFeature([AgentEffects])
  ]
})
export class AgentModule {}
