import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TypedWorker } from './typed-worker';
import { ConfigService } from '@amfam/shared/utility/shared-services/src/lib/config';
import { PolicySummary, UmbrellaPolicy } from '@amfam/policy/models';

@Injectable({
  providedIn: 'root'
})
export class PlumbrellaService implements TypedWorker {
  constructor(private config: ConfigService, private http: HttpClient) {}

  public getEndpoint() {
    return this.config.get('productApi') + 'plumbrellapolicies/';
  }

  public getPolicy(url: string, policySummary: PolicySummary): Observable<UmbrellaPolicy> {
    return this.http.get(url).pipe(map((data: any) => this.loadModel(data, policySummary)));
  }

  public loadModel(json: any, policySummary: PolicySummary): UmbrellaPolicy {
    let policy: any = json;
    if (json.policy) {
      policy = json.policy;
    }
    return UmbrellaPolicy.fromJson({...policy, sourceSystem: policySummary.sourcePath});
  }

  private getContactName(contactReferenceKey: any, policy: any) {
    let name = '';

    for (let i = 0; i < policy.Contacts.length; i++) {
      if (policy.Contacts[i].ContactReferenceKey === contactReferenceKey) {
        name = policy.Contacts[i].name;
        break;
      }
    }

    return name;
  }
}
