import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { filter, take } from 'rxjs/operators';

import { AnalyticsActions } from '@amfam/shared/analytics';
import { digitalAccountQuery } from '@amfam/shared/digital-account/data-access';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { initiateEnrollment, validateEmail } from '../+state/mae-feature.actions';
import { maeFeatureQuery } from '../+state/mae-feature.selectors';
import { MaeAnalytics } from '../mae-constants';

@Component({
  selector: 'ds-mae-error',
  templateUrl: './mae-error.component.html',
  styleUrls: ['./mae-error.component.scss']
})
export class MaeErrorComponent implements OnInit {
  somethingWentWrongText;
  enrollmentErrorMessage;
  retryCount = 3;
  duplicateEmail = false;

  constructor(
    private copyService: CopyService,
    private store: Store,
    private spinnerService: LoadingSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinnerService.stop();
    this.somethingWentWrongText = this.copyService.getCopy('enrollment.somethingWentWrongText');
    this.enrollmentErrorMessage = this.copyService.getCopy('enrollment.enrollmentErrorMessage');
    this.store.dispatch(
      AnalyticsActions.sendAnalytic({
        options: {
          page: MaeAnalytics.pageAgentProgramEnrollmentError
        }
      })
    );
  }
  exitSetup() {
    window.close();
  }
  tryAgain() {
    let email = '';
    let correlationId = '';
    this.store
      .select(maeFeatureQuery.selectMaeFeatureState)
      .pipe(
        filter(state => !!state),
        take(1)
      )
      .subscribe(data => {
        email = data.email;
        correlationId = data.correlationId;

        this.store.dispatch(validateEmail({ email, correlationId }));
      });

    this.store
      .select(digitalAccountQuery.getDigitalAccountState)
      .pipe(
        filter(state => state.correlationId === correlationId),
        take(1)
      )
      .subscribe(state => {
        this.duplicateEmail = _get(state, 'emailAssingedToOthers', false);
        if (!this.duplicateEmail) {
          this.store.dispatch(initiateEnrollment({ email: email }));
        }
      });
    this.retryCount--;
    if (this.retryCount <= 0) {
      this.enrollmentErrorMessage = this.copyService.getCopy(
        'enrollment.enrollmentErrorMessageRetry'
      );
    }
  }
}
