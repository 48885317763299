/* eslint-disable @typescript-eslint/member-ordering */
import { ButtonAnalytic, EventAnalytic, PageAnalytic } from '@amfam/shared/utility/shared-services';

/**
 * AS: Constant class for routing paths being used in billing and payments.
 */
export class BillingPaymentPaths {
  public static readonly VIEW_PAYMENT_ACCOUNTS = '/billing/payment-methods';
  public static readonly ADD_PAYMENT_ACCOUNT = '/billing/payment-methods/add';
  public static readonly EDIT_PAYMENT_ACCOUNT = '/billing/payment-methods/edit';
  public static readonly DELETE_PAYMENT_ACCOUNT = '/billing/payment-methods/delete';

  public static readonly VIEW_SCHEDULED_PAYMENTS = '/billing/payment';
  public static readonly ADD_SCHEDULED_PAYMENT = '/billing/payment/add';
  public static readonly EDIT_SCHEDULED_PAYMENT = '/billing/payment/edit';
  public static readonly DELETE_SCHEDULED_PAYMENT = '/billing/payment/delete';

  public static readonly BILLING_SECTION_PAGE = '/billing';
  public static readonly BILLING_REGISTRATION = '/billing/register';
  public static readonly BILLING_DETAIL_PAGE = '/billing';
  public static readonly BILLING_CONTACT = '/billing/contact';
  public static readonly BILLING_CONTACT_CONFIRMATION = '/billing/contactConfirmation';
  public static readonly CONFIRMATION_PATH = '/billing/confirmation';

  public static readonly ADD_ONE_TIME_PAYMENT = '/billing/one-time-pay/choose-accounts';
  public static readonly REVIEW_ONE_TIME_PAYMENT = '/billing/one-time-pay/review-payment';
  public static readonly CONFIRMATION_ONE_TIME_PAYMENT = '/billing/one-time-pay/confirmation';
  public static readonly SIMPLIFIED_PAYMENT_REVIEW = '/billing/one-time-pay/payment-review';
  public static readonly SIMPLIFIED_PAYMENT_CONFIRMATION =
    '/billing/one-time-pay/payment-confirmation';

  public static readonly DELETE_PAYMENT_METHOD = '/billing/payment-method/delete';
  public static readonly CONFIRMATION_PAYMENT_METHOD = '/billing/payment-method/confirmation';
}

/*
  Constant class for element Ids used by the util service scroll to function.
*/
export class BillingElementIds {
  public static readonly PAYMENT_HISTORY_ACCORDION = 'paymentHistoryAccordionConst';
}

export class BillingAnalytics {
  // page analytic for duplicate payment
  public static readonly duplicatePaymentConfirmationModalOpen: PageAnalytic = {
    pageName: 'MyAccount:Payment:Simplified:ReviewPayment:PayNow:PayNowDuplicateAlert',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing',
    subCategory2: 'Payment',
    subCategory3: ''
  };

  // page analytic for schedule payment
  public static readonly pageSchedulePayment: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:Payment:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Payment',
    subCategory3: ''
  };

  // **** Start of Analytics data for this component
  public static readonly AutoPayEditPageAdminAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:AutomaticPayments:Edit',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Automatic Payments'
  };

  // page analytic for autopay
  public static readonly pageAutoPayAnalyticAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:AutomaticPayments:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Automatic Payments'
  };

  // page analytic for billing section MyAccount
  public static readonly pageBillingSection: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:Dashboard',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: '',
    subCategory3: ''
  };

  // page analytic for Register account
  public static readonly registerAccount: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:RegisterAccount:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Register Billing Account',
    subCategory3: ''
  };

  // page analytic for Register account for Admin
  public static readonly registerAccountAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:RegisterAccount:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Register Billing Account'
  };

  // page analytic for Register billaccount for MyAccount
  public static readonly pageBaRegisteredAnalytic: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:RegisteredDetails',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Account Details',
    subCategory3: ''
  };

  // page analytic for Register account details for Admin
  public static readonly pageBaRegisteredAdminAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:RegisteredDetails',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Account Details'
  };

  // page analytic for Un Register account details for Admin
  public static readonly pageBaUnRegisteredAdminAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:UnregisteredDetails',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Account Details'
  };

  // page analytic for schedule payment for Admin
  public static readonly pageSchedulePaymentAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:Payment:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment'
  };

  // page analytic for schedule payment for Admin
  public static readonly viewAllPaymentsAdminAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:ScheduledPayments',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Scheduled Payments'
  };

  // page analytic for schedule payment for future Admin
  public static readonly pageScheduleEditPaymentAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:ScheduledPayments:Edit',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment'
  };

  // page analytic for edit schedule payment admin
  public static readonly pageScheduleEditPaymentSameDayAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:ScheduledPayments:Edit:SameDay',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment'
  };

  // event analytic for schedule payment
  public static readonly eventSchedulePayment: EventAnalytic = {
    eventName: 'BillingPayments:Payment',
    eventStep: 'start'
  };

  // event analytic for schedule payment for Admin
  public static readonly eventSchedulePaymentAdmin: EventAnalytic = {
    eventName: 'BillingPayments:Payment',
    eventStep: 'start'
  };

  // page analytic for schedule payment complete.
  public static readonly pageSchedulePaymentComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:Payment:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Payment',
    subCategory3: ''
  };

  // page analytic for schedule payment complete for Admin.
  public static readonly pageSchedulePaymentCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:Payment:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment'
  };

  // page analytic for edit scheduled payment complete.
  public static readonly pageEditSchedulePaymentComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:ScheduledPayments:Edit:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Scheduled Payments',
    subCategory3: 'n/a'
  };
  // page analytic for edit scheduled payment complete for Admin.
  public static readonly pageEditSchedulePaymentCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:ScheduledPayments:Edit:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Scheduled Payments'
  };

  // page analytic for edit scheduled payment delete for Admin.
  public static readonly pageDeleteSchedulePaymentStart: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:ScheduledPayments:Delete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Scheduled Payments'
  };

  // page analytic for delete scheduled payment complete.
  public static readonly pageDeleteSchedulePaymentComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:ScheduledPayments:Delete:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Scheduled Payments',
    subCategory3: 'n/a'
  };

  // page analytic for delete scheduled payment complete for Admin.
  public static readonly pageDeleteSchedulePaymentCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:ScheduledPayments:Delete:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Scheduled Payments'
  };

  // page analytic for Add payment method start
  public static readonly pageAddPaymentAccStart: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:AddPaymentMethod:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: '',
    subCategory3: ''
  };

  // page analytic for Add payment method start for Admin
  public static readonly pageAddPaymentAccStartMyAccountAdmin: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:AddPaymentMethod:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment Method'
  };

  // page analytic for Add payment method complete.
  public static readonly pageAddPaymentAccComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:AddPaymentMethod:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: '',
    subCategory3: ''
  };

  // page analytic for Add payment method complete for Admin.
  public static readonly pageAddPaymentAccCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:AddPaymentMethod:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment Method'
  };

  // page analytic for edit payment method start.
  public static readonly pageEditPaymentAccStart: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:EditPaymentMethod:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: '',
    subCategory3: ''
  };

  // page analytic for edit payment method start for Admin.
  public static readonly pageEditPaymentAccStartAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:EditPaymentMethod:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment Method'
  };

  // page analytic for edit payment method complete.
  public static readonly pageEditPaymentAccComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:EditPaymentMethod:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: '',
    subCategory3: ''
  };

  // page analytic for edit payment method complete for Admin.
  public static readonly pageEditPaymentAccCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:EditPaymentMethod:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment Method'
  };

  // page analytic for delete payment method complete.
  public static readonly pageDeletePaymentAccComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:DeletePaymentMethod:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: '',
    subCategory3: ''
  };

  // page analytic for delete payment method complete for Admin.
  public static readonly pageDeletePaymentAccCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:DeletePaymentMethod:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment Method'
  };

  // page analytic for auto pay.
  public static readonly pageAutoPay: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Automatic Payments',
    subCategory3: ''
  };

  // page analytic for auto pay.
  public static readonly pageAutoPayAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:AutomaticPayments:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Automatic Payments'
  };

  // page analytic for manage payment delete.
  public static readonly managePaymentDeleteStartAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:DeletePaymentMethod:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Payment Method'
  };

  // page analytic for auto pay awareness.
  public static readonly pageAutoPayAuthAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:AutomaticPayments:Authorization',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Automatic Payments'
  };

  // click analytic for duplicate payment payNowClicked click
  public static readonly payNowClicked: ButtonAnalytic = {
    link: 'MyAccount:Payment:Simplified:ReviewPayment:PayNow:PayNowDoubleTapped'
  };

  // click analytic for duplicate payment yes click
  public static readonly yesClicked: ButtonAnalytic = {
    link: 'MyAccount:Payment:Simplified:ReviewPayment:PayNow:PayNowDuplicate:Continue'
  };

  // click analytic for duplicate payment no click
  public static readonly noClicked: ButtonAnalytic = {
    link: 'MyAccount:Payment:Simplified:ReviewPayment:PayNow:PayNowDuplicate:Cancel'
  };

  // click analytic for Autopay Awareness
  public static readonly clickAutoPaySignup: ButtonAnalytic = {
    link: 'PaymentCompleteAutopaySignUp'
  };

  // click analytic for Autopay Awareness Customize
  public static readonly clickAutoPayCustomize: ButtonAnalytic = {
    link: 'PaymentCompleteAutopayCustomize'
  };

  public static readonly clickFuturePaymentsLink: ButtonAnalytic = {
    link: 'ViewFuturePaymentSchedule'
  };

  public static readonly clickAutoPayAccordion: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:ManageAutoPay'
  };

  public static readonly clickScheduledPaymentsAccordion: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:ManageScheduledPayments'
  };

  public static readonly schedulePaymentClick: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:ManageOneTimePay:SchedulePayment'
  };

  public static readonly clickAccordionExpandAll: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:ExpandAll'
  };

  // Registered Account Accordions
  public static readonly clickRegisteredDetailsAutoPayAccordion: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:RegisteredDetails:ManageAutoPay'
  };

  public static readonly clickRegisteredDetailsScheduledPaymentsAccordion: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:RegisteredDetails:ManageScheduledPayments'
  };

  public static readonly clickRegisteredDetailsAccordionExpandAll: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:RegisteredDetails:ExpandAll'
  };

  // event analytic for auto pay.
  public static readonly eventAutoPay: EventAnalytic = {
    eventName: 'Automatic Payments',
    eventStep: 'start'
  };

  // page analytic for auto pay unchecked.
  public static readonly pageAutoPayUnChecked: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:UnChecked',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Automatic Payments',
    subCategory3: ''
  };

  // page analytic for add automatic payment complete.
  public static readonly pageAddAutoPayComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Automatic Payments',
    subCategory3: ''
  };

  // page analytic for add automatic payment complete for Admin.
  public static readonly pageAddAutoPayCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:AutomaticPayments:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Automatic Payments'
  };

  // page analytic for edit automatic payment complete.
  public static readonly pageEditAutoPayComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:Edit:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Automatic Payments',
    subCategory3: 'n/a'
  };

  // page analytic for edit automatic payment complete for Admin.
  public static readonly pageEditAutoPayCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:AutomaticPayments:Edit:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Automatic Payments'
  };

  // page analytic for delete automatic payment complete.
  public static readonly pageDeleteAutoPayComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:AutomaticPayments:DeleteStop:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Automatic Payments',
    subCategory3: 'n/a'
  };
  // page analytic for delete automatic payment complete for Admin.
  public static readonly pageDeleteAutoPayStopAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:AutomaticPayments:DeleteStop',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Automatic Payments'
  };

  // page analytic for delete automatic payment complete for Admin.
  public static readonly pageDeleteAutoPayCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:AutomaticPayments:DeleteStop:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Automatic Payments'
  };

  // page analytic for registration complete.
  public static readonly pageRegisteredBaComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:RegisterAccount:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Register Billing Account',
    subCategory3: ''
  };

  // page analytic for registration complete for Admin.
  public static readonly pageRegisteredCompleteMyAccountAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:RegisterAccount:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Register Billing Account'
  };

  // page analytic for unregistration complete.
  public static readonly pageUnRegisterBaComplete: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:UnregisterAccount:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Unregister Billing Account',
    subCategory3: ''
  };

  // page analytic for unregistration complete.
  public static readonly pageUnRegisterBaCompleteAdmin: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:UnregisterAccount:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Billing Payments',
    subCategory3: 'Unregister Billing Account'
  };

  // page analytic for terms and conditions(Reg flow).
  public static readonly pageTermsAndConditions: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:TermsConditions',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Terms and Conditions',
    subCategory3: 'n/a'
  };

  // Analytics for classic past due
  public static readonly pageOverviewPastDue: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:Dashboard',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: '',
    subCategory3: 'Past Due'
  };

  public static readonly pageAdminOverviewPastDue: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: '',
    subCategory3: 'Past Due'
  };

  public static readonly pageBillingDetailPastDue: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:RegisteredDetails',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Account Details',
    subCategory3: 'Past Due'
  };

  public static readonly pageAdminBillingDetailPastDue: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:RegisteredDetails',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Account Details',
    subCategory3: 'Past Due'
  };

  public static readonly pageScheduledPaymentConfirmationPastDue: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:Payment:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Account Details',
    subCategory3: 'Past Due'
  };

  public static readonly pageAdminScheduledPaymentConfirmationPastDue: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:Payment:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Account Details',
    subCategory3: 'Past Due'
  };

  public static readonly pagePaidForFuturePastDue: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:Payment:PaidForFuture',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Account Details',
    subCategory3: 'Past Due'
  };

  public static readonly pageAdminPaidForFuturePastDue: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:Payment:PaidForFuture',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Account Details',
    subCategory3: 'Past Due'
  };

  public static readonly pageSchedulePaymentPastDue: PageAnalytic = {
    pageName: 'MyAccount:BillingPayments:Payment:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Payment',
    subCategory3: 'Past Due'
  };

  public static readonly pageAdminSchedulePaymentPastDue: PageAnalytic = {
    pageName: 'MyAccount:Admin:BillingPayments:Payment:Start',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Billing Payments',
    subCategory2: 'Payment',
    subCategory3: 'Past Due'
  };

  public static readonly clickPastDueModalConfirmLink: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:Payment:PaidForFuture:Continue'
  };

  public static readonly clickPastDueModalCancelLink: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:Payment:PaidForFuture:Cancel'
  };

  // page analytic for payment history date range months selection.
  public static paymentHistoryDateRange(timeframe: string): PageAnalytic {
    const pageAnalytic = {
      pageName: '',
      experience: '',
      primaryCategory: '',
      subCategory1: '',
      subCategory2: '',
      subCategory3: '',
      timeframe: timeframe
    };
    return pageAnalytic;
  }

  public static readonly buttonBillingPaymentsEditLenderInfo: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:EditLenderInfo'
  };

  public static readonly buttonViewCurrentBill: ButtonAnalytic = {
    link: 'MyAccount:BillingPayments:ViewCurrentBill'
  };
}

export class BillAccountRegistration {
  public static readonly ONLINE_BILLING = 'OLB';
  public static readonly NICKNAME_TEXT = 'Billing Account End ';
}

export class BillingContact {
  public static readonly HAVE_COLLECTION_QUESTION = 'I have a collections question';
  public static readonly POLICY_CHANGE_CANCELLATION = 'Policy change or cancellation';
  public static readonly AGENT_EMAIL_ADDRESS = 'Agent Email Address';
}

export class BillAccountType {
  public static readonly PERSONAL = 'PERSONAL';
  public static readonly COMMERCIAL = 'COMMERCIAL';
}

export const INVALID_IMPERSONATION_STATUS_CODE = '202304';

export const ONLINE_BILLING = 'ONLINE_BILLING';
export const UNSPECIFIED = 'UNSPECIFIED';

export const PAPER = 'PAPER';
export class PaymentStatus {
  public static readonly AUTHORIZED = 'AUTHORIZED';
  public static readonly SCHEDULED = 'SCHEDULED';
  public static readonly SUCCESSFUL = 'SUCCESSFUL';
  public static readonly UNSUCCESSFUL = 'UNSUCCESSFUL';
  public static readonly DELETED = 'DELETED';
}

export class AutopayRadioOptions {
  public static readonly MINIMUM_DUE = 'Minimum Due';
  public static readonly FULLPAY_BALANCE = 'Full Pay';
  public static readonly ACCOUNT_BALANCE = 'Account Balance';
}
export class EntityReferences {
  public static readonly PREFERENCES = 'billingPreference.preferences';
  public static readonly PAPER = 'billingPreference.preferences.DOCUMENTS.PAPER';
  public static readonly EMAIL = 'billingPreference.preferences.DOCUMENTS.EMAIL';
}

export class MultiAutopaySetupAnalytics {
  // click analytic for multiple autopay setup
  public static readonly clickMultiAutopaySetup: ButtonAnalytic = {
    link: 'BillingAndPaymentsSetupAutopay'
  };

  // click analytic for multiple autopay setup
  public static readonly addMultiAutopaySetup: ButtonAnalytic = {
    link: 'MyAccount:BillingAndPaymentsAddAutomaticPayment'
  };
}
