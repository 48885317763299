import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { flatMap as _flatmap } from 'lodash';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { AutoPayUtilService } from '@amfam/billing/auto-pay/feature';
import { AutoPayUtilSelectors } from '@amfam/billing/auto-pay/util';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { MaeFeatureActions } from '@amfam/mae/feature';
import { GoPaperLessSelectors } from '@amfam/policy/go-paperless/data-access';
import { PolicyTypeIconConstants } from '@amfam/policy/models';
import {
  CommunicationPreferencesSelectors,
  PaperlessSetup,
  PaperlessSubmitStatus
} from '@amfam/profile/communication-preferences/data-access';
import {
  AnalyticsFacade,
  PaperlessAnalytics,
  PaperlessAnalyticsAdmin
} from '@amfam/shared/analytics';
import { BillAccount } from '@amfam/shared/models';
import { ArrayToList } from '@amfam/shared/ui/pipes';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import {
  Applications,
  ApplicationService,
  CopyService
} from '@amfam/shared/utility/shared-services';
import {
  ConfirmationConfig,
  ConfirmationStatus,
  DockingBarService,
  LoadingSpinnerService
} from '@amfam/ui-kit';

import { CommunicationPreferencesUtilService } from '../../util/communication-preferences-util.service';

@Component({
  selector: 'ds-paperless-confirmation-wrapper',
  templateUrl: './paperless-confirmation-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaperlessConfirmationWrapperComponent implements OnInit, OnDestroy {
  billAccounts$: Observable<BillAccount[]>;
  confirmationConfig$: Observable<ConfirmationConfig>;
  successfulSetups$: Observable<BillAccount[]>;
  failedSetups$: Observable<BillAccount[]>;
  successfulReviewItems$: Observable<PaperlessSetup[]>;
  failedReviewItems$: Observable<PaperlessSetup[]>;
  isPolicyPaperlessSuccess$: Observable<boolean>;
  overallSubmissionStatus$: Observable<PaperlessSubmitStatus>;
  submitStatuses: typeof PaperlessSubmitStatus = PaperlessSubmitStatus;
  isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);

  successPaperlessHeading: string = this.copyService.getCopy(
    'goPaperless.paperlessEnrolmentFlow.successPaperlessHeading'
  );
  successPaperlessSubheading: string = this.copyService.getCopy(
    'goPaperless.paperlessEnrolmentFlow.successPaperlessSubheading'
  );
  failurePaperlessHeading: string = this.copyService.getCopy(
    'goPaperless.paperlessEnrolmentFlow.failurePaperlessHeading'
  );
  failurePaperlessSubheading: string = this.copyService.getCopy(
    'goPaperless.paperlessEnrolmentFlow.failurePaperlessSubheading'
  );

  private stop$ = new Subject<void>();

  private tryAgainLimit = 3;
  private disableTryAgain = false;

  constructor(
    private dockingBar: DockingBarService,
    private store: Store,
    private copyService: CopyService,
    private autoPayUtil: AutoPayUtilService,
    private analyticsFacade: AnalyticsFacade,
    private communicationPreferencesUtilService: CommunicationPreferencesUtilService,
    private spinner: LoadingSpinnerService,
    private appService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.spinner.stop();

    this.successfulSetups$ = this.getSuccessfulSetups();
    this.failedSetups$ = this.getFailedSetups();
    this.isPolicyPaperlessSuccess$ = this.getPaperlessPolicyStatus();
    this.failedReviewItems$ = this.getFailedReviewItems();
    this.successfulReviewItems$ = this.getSuccessfulReviewItems();
    //don't change this order
    this.overallSubmissionStatus$ = this.getOverallSubmissionStatus();
    this.confirmationConfig$ = this.getConfirmationConfig();
    this.sendAnalytics();
    if (!this.isAdminApp) {
      this.dockingBar.registerHeading('Paperless Signup');
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  primaryButtonClick(): void {
    this.overallSubmissionStatus$
      .pipe(take(1))
      .subscribe((overallSubmissionStatus: PaperlessSubmitStatus) => {
        if (overallSubmissionStatus === PaperlessSubmitStatus.SUCCESS) {
          this.goToSetUpAutoPay();
        } else {
          this.tryAgain();
        }
      });
  }

  tertiaryButtonClick(): void {
    this.overallSubmissionStatus$
      .pipe(take(1))
      .subscribe((overallSubmissionStatus: PaperlessSubmitStatus) => {
        if (overallSubmissionStatus === PaperlessSubmitStatus.SUCCESS) {
          this.skipAutoPay();
        } else {
          this.goToSetUpAutoPay();
        }
      });
    if (this.isAdminApp) {
      this.analyticsFacade.trackButtonClick(
        PaperlessAnalyticsAdmin.buttonGoPaperlessEnrollBothCompleteSkipAutoPaySetup
      );
    } else {
      this.analyticsFacade.trackButtonClick(
        PaperlessAnalytics.buttonGoPaperlessEnrollBothCompleteSkipAutoPaySetup
      );
    }
  }

  private getPaperlessPolicyStatus(): Observable<boolean> {
    return combineLatest([
      this.store.select(GoPaperLessSelectors.selectGoPaperlessLoading),
      this.store.select(GoPaperLessSelectors.selectIsEnrolledGoPaperless)
    ]).pipe(
      filter(([loading]) => !loading),
      takeUntil(this.stop$),
      map(([, isEnrolled]) => isEnrolled)
    );
  }

  private getSuccessfulReviewItems(): Observable<PaperlessSetup[]> {
    let paperlessSetups: PaperlessSetup[];

    return combineLatest([this.successfulSetups$, this.isPolicyPaperlessSuccess$]).pipe(
      map(([setups, paperlessSuccess]) => {
        paperlessSetups = setups.map(setup => ({
          policyTypeIcon: setup.icon,
          riskDescriptions: [
            `${this.copyService.getCopy('profile.communicationPreferencesBillsFor')}${new ArrayToList().transform(
              _flatmap(setup.policyList, policy => policy.riskDescriptionList)
            )}`
          ],
          accountName: `Acct ${setup.billAccountNumber}`
        }));

        if (paperlessSuccess)
          paperlessSetups.push({
            policyTypeIcon: PolicyTypeIconConstants.DOCUMENTS,
            riskDescriptions: [
              this.copyService.getCopy('profile.communicationPreferencesPolicyDocuments')
            ],
            accountName: this.copyService.getCopy(
              'profile.communicationPreferencesInsuranceCardsDocsDecs'
            )
          });
        return paperlessSetups;
      })
    );
  }

  private getFailedReviewItems(): Observable<PaperlessSetup[]> {
    let paperlessSetups: PaperlessSetup[];
    return combineLatest([this.failedSetups$, this.isPolicyPaperlessSuccess$]).pipe(
      map(([setups, paperlessSuccess]) => {
        paperlessSetups = setups.map(setup => ({
          policyTypeIcon: setup.icon,
          riskDescriptions: [
            `${this.copyService.getCopy('profile.communicationPreferencesBillsFor')}${new ArrayToList().transform(
              _flatmap(setup.policyList, policy => policy.riskDescriptionList)
            )}`
          ],
          accountName: `Acct ${setup.billAccountNumber}`
        }));

        if (!paperlessSuccess)
          paperlessSetups.push({
            policyTypeIcon: PolicyTypeIconConstants.DOCUMENTS,
            riskDescriptions: [
              this.copyService.getCopy('profile.communicationPreferencesPolicyDocuments')
            ],
            accountName: this.copyService.getCopy(
              'profile.communicationPreferencesInsuranceCardsDocsDecs'
            )
          });
        return paperlessSetups;
      })
    );
  }

  //isModifiable value is looking at preferences[0] always, based on the understanding, for single or multiple preferences ,
  //the value would be same, either modifiable true or false, 0 postion or 1 position the value would always be same.

  private getSuccessfulSetups(): Observable<BillAccount[]> {
    return combineLatest([
      this.store.select(BillAccountsSelectors.selectBillAccounts),
      this.store.select(
        CommunicationPreferencesSelectors.selectCommunicationPreferencesNotifications
      )
    ]).pipe(
      map(([billAccounts, notifications]) =>
        billAccounts.filter(
          billAccount =>
            billAccount.billingPreferences.preferences[0].isModifiable === true &&
            !notifications.find(notification => notification.id === billAccount.billAccountNumber)
              .hasError
        )
      ),
      take(1)
    );
  }

  private getFailedSetups(): Observable<BillAccount[]> {
    return combineLatest([
      this.store.select(BillAccountsSelectors.selectBillAccounts),
      this.store.select(
        CommunicationPreferencesSelectors.selectCommunicationPreferencesNotifications
      )
    ]).pipe(
      map(([billAccounts, notifications]) =>
        billAccounts.filter(
          billAccount =>
            billAccount.billingPreferences?.preferences[0].isModifiable === true &&
            notifications.find(notification => notification.id === billAccount.billAccountNumber)
              .hasError
        )
      ),
      take(1)
    );
  }

  private getOverallSubmissionStatus(): Observable<PaperlessSubmitStatus> {
    return combineLatest([
      this.failedSetups$,
      this.successfulSetups$,
      this.isPolicyPaperlessSuccess$
    ]).pipe(
      map(([failedSetups, successfulSetup, policyPaperlessSetup]) => {
        if (successfulSetup.length === 0 && !policyPaperlessSetup) {
          return PaperlessSubmitStatus.FAILURE;
        } else if (failedSetups.length === 0 && policyPaperlessSetup) {
          return PaperlessSubmitStatus.SUCCESS;
        } else {
          return PaperlessSubmitStatus.PARTIAL_FAILURE;
        }
      })
    );
  }

  private sendAnalytics() {
    this.overallSubmissionStatus$
      .pipe(take(1))
      .subscribe((overallSubmissionStatus: PaperlessSubmitStatus) => {
        if (overallSubmissionStatus === PaperlessSubmitStatus.SUCCESS) {
          if (this.isAdminApp) {
            this.analyticsFacade.trackPage(
              PaperlessAnalyticsAdmin.pageGoPaperlessEnrollBothComplete
            );
          } else {
            this.analyticsFacade.trackPage(PaperlessAnalytics.pageGoPaperlessEnrollBothComplete);
          }
        } else {
          if (this.isAdminApp) {
            this.analyticsFacade.trackPage(PaperlessAnalyticsAdmin.pageGoPaperlessEnrollBothError);
          } else {
            this.analyticsFacade.trackPage(PaperlessAnalytics.pageGoPaperlessEnrollBothError);
          }
        }
      });
  }

  private skipAutoPay() {
    if (!this.isAdminApp) {
      this.store.dispatch(
        fromRouterActions.Go({
          path: ['/overview']
        })
      );
    } else {
      this.store.dispatch(
        fromRouterActions.Go({
          path: ['/enroll/skip-autopay']
        })
      );
    }
  }

  private tryAgain() {
    this.spinner.start({
      blockActions: true
    });
    this.failedSetups$.pipe(take(1)).subscribe(failedSetups => {
      failedSetups.forEach(failedSetup => {
        this.communicationPreferencesUtilService.setBillAccountDeliveryPreference(failedSetup);
      });
    });

    this.isPolicyPaperlessSuccess$.pipe(take(1)).subscribe(paperlessSuccess => {
      if (!paperlessSuccess) {
        this.communicationPreferencesUtilService.setPolicyDocumentPreference();
      }
    });

    combineLatest([
      this.store.select(GoPaperLessSelectors.selectGoPaperlessLoading),
      this.store.select(CommunicationPreferencesSelectors.selectAnyLoading)
    ])
      .pipe(takeUntil(this.stop$))
      .subscribe(([policyLoading, billingLoading]) => {
        if (!billingLoading && !policyLoading) {
          this.spinner.stop();
        }
      });

    this.tryAgainLimit--;
    this.confirmationConfig$ = this.getConfirmationConfig();
  }

  private goToSetUpAutoPay(): void {
    this.store
      .select(AutoPayUtilSelectors.selectBillAccountsEligibleForAutoPay)
      .pipe(take(1))
      .subscribe(billAccounts => {
        // take the user to autopay setup if they have eligible bill accounts
        if (billAccounts.length === 0) {
          if (this.isAdminApp) {
            this.store.dispatch(MaeFeatureActions.launchOverview());
          } else {
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/overview']
              })
            );
          }
        } else {
          this.autoPayUtil.routeToMultipleAutoPaySelection('overview');
        }
      });
  }

  private getConfirmationConfig(): Observable<ConfirmationConfig> {
    if (this.tryAgainLimit <= 0) {
      this.disableTryAgain = true;
    }

    return combineLatest([
      this.overallSubmissionStatus$,
      this.store.select(AutoPayUtilSelectors.selectBillAccountsEligibleForAutoPay)
    ]).pipe(
      map(([overallSubmissionStatus, billAccountsEligibleForAutoPay]) => {
        if (
          overallSubmissionStatus === PaperlessSubmitStatus.FAILURE ||
          overallSubmissionStatus === PaperlessSubmitStatus.PARTIAL_FAILURE
        ) {
          return {
            heading: this.failurePaperlessHeading,
            status: ConfirmationStatus.ERROR,
            ctaConfig: {
              primaryButtonName: this.copyService.getCopy('shared.retry'),
              disablePrimaryButton: this.disableTryAgain,
              tertiaryButtonName: this.copyService.getCopy(
                'profile.communicationPreferencesContinueSetup'
              )
            }
          };
        } else if (billAccountsEligibleForAutoPay.length === 0) {
          return {
            heading: this.successPaperlessHeading,
            status: ConfirmationStatus.SUCCESS,
            ctaConfig: {
              primaryButtonName: this.copyService.getCopy(
                'profile.communicationPreferencesGotoOverview'
              )
            }
          };
        } else {
          return {
            heading: this.successPaperlessHeading,
            status: ConfirmationStatus.SUCCESS,
            ctaConfig: {
              primaryButtonName: this.copyService.getCopy('goPaperless.setupAutopay'),
              tertiaryButtonName: this.copyService.getCopy('goPaperless.skipAutopaySetup')
            }
          };
        }
      })
    );
  }
}
