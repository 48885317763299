import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { get as _get } from 'lodash';

import {
  initialPendingRegistration,
  PendingRegistration
} from '../models/pending-registration.model';
import {
  PendingRegistrationsActions,
  PendingRegistrationsActionTypes
} from './registration.actions';

export const PENDING_REGISTRATION_FEATURE_KEY = 'prending registration';

export interface PendingRegistrationPartialState {
  readonly [PENDING_REGISTRATION_FEATURE_KEY]: PendingRegistrationEntityState;
}

export interface PendingRegistrationEntityState extends EntityState<PendingRegistration> {
  selectedBillAccountNumber: string;
}

export const adapter: EntityAdapter<PendingRegistration> = createEntityAdapter<PendingRegistration>(
  {
    selectId: (billingObjModel: PendingRegistration) => billingObjModel.billAccountNumber,
    sortComparer: false
  }
);

export const initialState: PendingRegistrationEntityState = adapter.getInitialState({
  selectedBillAccountNumber: ''
});

export function reducer(
  state: PendingRegistrationEntityState = initialState,
  action: PendingRegistrationsActions
): PendingRegistrationEntityState {
  switch (action.type) {
    case PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_ADD_ONE: {
      return adapter.upsertOne(_get(action, 'payload', initialPendingRegistration), state);
    }

    case PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_ADD_MANY: {
      return adapter.upsertMany(_get(action, 'payload', []), state);
    }

    case PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_REMOVE_ALL: {
      return adapter.removeAll(state);
    }

    case PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_REMOVE_ONE: {
      return adapter.removeOne(action.payload.billAccountNumber, state);
    }

    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
