import { Pipe, PipeTransform } from '@angular/core';
// TODO update paths
import { isString } from 'lodash';

import {
  Address,
  CoverageTerm,
  GenericProductType,
  Option,
  PolicyBase,
  PolicySummary,
  SourceSystemType,
  Vehicle,
  VehicleTypeConstants
} from '@amfam/policy/models';

@Pipe({ name: 'removeRejected' })
export class RemoveRejected implements PipeTransform {
  transform(options: Option[], args?: any): any {
    return options.filter(o => o?.name?.toLowerCase() !== 'rejected');
  }
}

@Pipe({ name: 'removeRejectedIfSelected' })
export class RemoveRejectedIfSelected implements PipeTransform {
  transform(options: Option[], selected: boolean | string): Option[] {
    selected = isString(selected) && selected.toLowerCase() !== 'rejected';
    return selected ? options.filter(o => o?.name?.toLowerCase() !== 'rejected') : options;
  }
}

@Pipe({ name: 'removeNoCoverage' })
export class RemoveNoCoverage implements PipeTransform {
  transform(
    options: Option[],
    stateCode: string,
    noCoverageStates: { states: string[]; coverages: string[] },
    publicID: string
  ): Option[] {
    if (
      noCoverageStates.states.includes(stateCode.toUpperCase()) &&
      noCoverageStates.coverages.includes(publicID)
    )
      return options.filter(o => o?.name?.replace(' ', '').toLowerCase() !== 'nocoverage');
    return options;
  }
}
@Pipe({ name: 'coverageArray' })
export class CoverageArray implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value !== undefined && value.length > 0) {
      const strArray: Array<string> = value.split('/');
      const returnVal = strArray[args];
      return returnVal;
    }
    return value;
  }
}

@Pipe({ name: 'prettyPolicyType' })
export class PrettyPolicyType implements PipeTransform {
  transform(value: any): any {
    if (value && typeof value === 'string' && value.length) {
      const prettyTokens = [];
      const stringSplit = value.split('_');
      for (let i = 0; i < stringSplit.length; i++) {
        const lowercaseToken = stringSplit[i].toLowerCase();
        const prettyToken = lowercaseToken.charAt(0).toUpperCase() + lowercaseToken.slice(1);
        prettyTokens.push(prettyToken);
      }
      return prettyTokens.join(' ');
    }
    return value;
  }
}

@Pipe({ name: 'prettyPolicyNum' })
export class PrettyPolicyNum implements PipeTransform {
  transform(value: any): any {
    const policy = value as PolicyBase;
    if (policy && policy.policyNumber) {
      const policyNumber = policy.policyNumber;
      if (policy.sourceSystem === SourceSystemType.Advance) {
        if (policyNumber.length > 11) {
          return (
            policyNumber.slice(0, 5) +
            '-' +
            policyNumber.slice(5, 10) +
            '-' +
            policyNumber.slice(10, 12)
          );
        }
      } else {
        if (policyNumber.length > 9) {
          return (
            policyNumber.slice(0, 4) +
            '-' +
            policyNumber.slice(4, 8) +
            '-' +
            policyNumber.slice(8, 10)
          );
        }
      }

      return policyNumber;
    } else if (value && typeof value.policyNumber === 'undefined') {
      if (value.length > 11) {
        return value.slice(0, 5) + '-' + value.slice(5, 10) + '-' + value.slice(10, 12);
      }
      if (value.length > 9) {
        return value.slice(0, 4) + '-' + value.slice(4, 8) + '-' + value.slice(8, 10);
      }
      if (value.length === 8) {
        return value.slice(0, 6) + '-' + value.slice(6, 7);
      }
    }

    return '';
  }
}

@Pipe({ name: 'prettyRiskDescription' })
export class PrettyRiskDescription implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      value = value.replace(/;;/g, ';').replace(/;/g, ' ').replace(/,/g, ' ');
    }
    return value;
  }
}

@Pipe({ name: 'formatRentalDeductibles' })
export class FormatRentalDeductibles implements PipeTransform {
  transform(value: string): any {
    if (!value || typeof value !== 'string') {
      return '';
    }
    const deductibleArr = value.split('/');
    return `- $${deductibleArr[0]} Daily Limit, $${deductibleArr[1]} maximum`;
  }
}

@Pipe({ name: 'prettyHomesiteRiskDescription' })
export class PrettyHomesiteRiskDescription implements PipeTransform {
  transform(value: string): any {
    if (typeof value === 'string') {
      value = value.substring(0, value.indexOf(';'));
    }
    return value;
  }
}

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  static concatenate(existing: string, value: string, newLine = false, separator = ', '): string {
    if (!value) {
      return '';
    }

    let result = '';
    if (existing) {
      if (newLine) {
        result += '\r\n';
      } else {
        result += separator;
      }
    }
    result += value;

    return result;
  }

  transform(value: any, format = 'long'): any {
    let result = '';
    const formatTokens = format ? format.split('-') : [];
    const longFormat = formatTokens.some(token => token === 'long');
    const singleLineFormat = formatTokens.some(token => token === 'singleline');

    if (value) {
      const line1 = value.addressLine1 || value.addr1 || value.line1;
      result += AddressPipe.concatenate(result, line1, !singleLineFormat);

      if (longFormat) {
        const line2 = value.addressLine2 || value.addr2 || value.line2;
        const zip5 = value.zipCode || value.zip5;
        result += AddressPipe.concatenate(result, line2, !singleLineFormat);
        result += AddressPipe.concatenate(result, value.city, !singleLineFormat);
        result += AddressPipe.concatenate(result, value.state);
        result += AddressPipe.concatenate(result, zip5, false, ' ');
      }
    }
    return result;
  }
}

@Pipe({ name: 'vehicle' })
export class VehiclePipe implements PipeTransform {
  transform(value: any): any {
    let result = '';
    if (value) {
      result = `${value.year} ${value.make} ${value.model}`;
    }
    return result;
  }
}

@Pipe({ name: 'risk' })
export class RiskPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const isArray = value instanceof Array;
      const array = isArray ? value : [value];
      const results = array.map(x => {
        if (x instanceof Address) {
          return new AddressPipe().transform(x, 'short');
        }
        if (typeof x === 'string') {
          return x;
        }
        if (x instanceof Vehicle) {
          return new VehiclePipe().transform(x);
        }

        return '';
      });

      if (!isArray) {
        return results[0];
      } else {
        return results;
      }
    }

    return '';
  }
}

/*
 *		Creates a term string with the value and description formatted to match the type (currency vs percent)
 *		and inspects the description for percent to apply the proper fomatting based on the existance of "%"
 */
@Pipe({ name: 'term' })
export class TermPipe implements PipeTransform {
  transform(value: CoverageTerm, split = false, format: boolean = true): any {
    if (!value) {
      return '';
    } else {
      const displayValue = value.value;
      let description = value.description;

      if (!format) {
        return split
          ? { value: '', description: value.valueDescription || description }
          : value.valueDescription || description;
      }

      if (description.indexOf('%') >= 0) {
        description = description.slice(description.indexOf('%') + 1, description.length);
        description = description.trim();
      }

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      });

      switch (value.valueType) {
        case 'currency':
          // Return the trimmed and formatted currency string with description
          if (split)
            return {
              value: formatter.format(parseFloat(displayValue)),
              description
            };
          else return `${formatter.format(parseFloat(displayValue))} ${description}`;
        case 'percent':
          // Return the trimmed and formatted percent string with description
          if (split)
            return {
              value: `${parseFloat(displayValue)}${'%'}`,
              description
            };
          else return `${parseFloat(displayValue)}${'%'} ${description}`;

        case 'string':
          return split ? { value: '', description: displayValue } : displayValue;

        default:
          return split ? { value: displayValue, description } : displayValue;
      }
    }
  }
}

@Pipe({ name: 'filterpolicy' })
export class FilterPolicy implements PipeTransform {
  transform(policies: PolicySummary[], code: string | number): PolicySummary[] {
    switch (code) {
      case VehicleTypeConstants.RV:
      case VehicleTypeConstants.CYCLE:
      case VehicleTypeConstants.WATERCRAFT:
      case VehicleTypeConstants.AUTO:
        return policies.filter(p => p.productDescription.toLowerCase() === code);

      case GenericProductType.Home:
      case GenericProductType.Life:
      case GenericProductType.Umbrella:
        return policies.filter(policy => policy.generalizedProductType === code);

      default:
        return policies;
    }
  }
}
