// date-fns
import { format } from 'date-fns';

import { Address } from './address';
import { AssignedProducer } from './assignedProducer';
import { Coverage } from './coverage';
import { Discount } from './discount';
import { Fee } from './fee';
import { GenericProductType } from './generic-product-type';
import { NamedInsured } from './named-insured';
import { PolicyBase } from './policy-base';
import { PolicyStatus } from './policy-status';
import { Risk } from './risk';
import { SourceSystem, SourceSystemType } from './source-system-type';
import { Surcharge } from './surcharge';
import { Vehicle } from './vehicle';

export class Policy extends PolicyBase {
  public policyNumber: string;
  public policyDescription: string;
  public status: string;
  public policyStatus: PolicyStatus;
  public productType: string;
  public dwellingRoles: NamedInsured[] = new Array<NamedInsured>();
  public iconType: string;
  public policyType: string;
  public sourceSystem: SourceSystemType;
  public periodStartDate: Date;
  public periodEndDate: Date;
  public cancelDate: Date;
  public billingAccountNumber: string;
  public billingAccountNumberDisplay: string;
  public totalPremium: number;
  public totalDiscount: number;
  public termDescription: string;
  public policyAddress: Address;
  public assignedProducer: AssignedProducer;
  public policyRoles: NamedInsured[] = new Array<NamedInsured>();
  public policyRisks: Risk[] = new Array<Risk>();
  public policyCoverages: Coverage[] = new Array<Coverage>();
  public policyDiscounts: Discount[] = new Array<Discount>();
  public policyFees: Fee[] = new Array<Fee>();
  public policySurcharges: Surcharge[] = new Array<Surcharge>();
  public riskDescriptionList?: string[];
  vehicles: Array<Vehicle>;
  public accountNumber?: string;
  public amfamCompanyCode?: string;
  public amfamCompanyDescription?: string;
  public cancelReasonCode: string;
  public isPolicyCancelled: boolean = false;

  static fromJson(json: any): Policy {
    const policy = new Policy();
    policy.assignProperties(json);

    return policy;
  }

  public static getPolicyStatusType(status: string): PolicyStatus {
    let policyStatusType = PolicyStatus.Other;
    switch (status.toUpperCase()) {
      case 'CANCELLED':
        policyStatusType = PolicyStatus.Cancelled;
        break;
      case 'INFORCE':
        policyStatusType = PolicyStatus.Inforce;
        break;
      case 'INACTIVE':
        policyStatusType = PolicyStatus.Inactive;
        break;
      case 'SCHEDULED':
        policyStatusType = PolicyStatus.Scheduled;
        break;
      default:
        policyStatusType = PolicyStatus.Other;
        break;
    }

    return policyStatusType;
  }

  public static compare(a: Policy, b: Policy): number {
    if (a == null || b == null) {
      return -1;
    }

    const aProductType = Policy.getGenericProductTypeOrder(a.generalizedProductType);
    const bProductType = Policy.getGenericProductTypeOrder(b.generalizedProductType);
    if (aProductType < bProductType) {
      return -1;
    }
    if (aProductType > bProductType) {
      return 1;
    }

    if (a.periodStartDate < b.periodStartDate) {
      return -1;
    }
    if (a.periodStartDate > b.periodStartDate) {
      return 1;
    }
    return 0;
  }

  private static getGenericProductTypeOrder(productType: GenericProductType) {
    let sort: number;
    switch (productType) {
      case GenericProductType.Auto:
        sort = 0;
        break;
      case GenericProductType.Home:
        sort = 1;
        break;
      case GenericProductType.Umbrella:
        sort = 2;
        break;
      default:
        sort = 100;
        break;
    }

    return sort;
  }

  protected assignProperties(json: any) {
    super.assignProperties(json);

    // make these next two date objects (formatting to deal with timezones)
    this.periodStartDate = new Date(format(json.periodStartDate, 'MM/DD/YYYY h:mm a'));
    this.periodEndDate = new Date(format(json.periodEndDate, 'MM/DD/YYYY h:mm a'));
    this.cancelDate = json.cancelDate
      ? new Date(format(json.cancelDate, 'MM/DD/YYYY h:mm a'))
      : null;
    this.billingAccountNumber = json.billingAccountNumber;
    this.billingAccountNumberDisplay = json.billingAccountNumber;
    this.totalPremium = json.totalPremium;
    this.totalDiscount = json.totalDiscount;
    this.termDescription = json.termDescription;
    this.policyAddress = Address.fromJson(json.policyAddress);
    this.assignedProducer = json.assignedProducer;
    this.policyDescription = json.policyDescription;
    this.status = json.termStatus;
    this.accountNumber = json.accountNumber;
    this.amfamCompanyCode = json.amfamCompanyCode;
    this.amfamCompanyDescription = json.amfamCompanyDescription;
    this.cancelDate = json.cancelDate;
    this.cancelReasonCode = json.cancelReasonCode;

    if (json.policyRoles) {
      for (const insured of json.policyRoles) {
        this.policyRoles.push(NamedInsured.fromJson(insured));
      }
    }
    if (json.policyCoverages) {
      for (const coverage of json.policyCoverages) {
        const coverageEntity = Coverage.fromJson(coverage, this.sourcePath !== SourceSystem.ROME);
        if (Coverage.isValid(coverageEntity)) {
          this.policyCoverages.push(coverageEntity);
        }
      }
    }

    if (json.policyDiscounts) {
      for (const discount of json.policyDiscounts) {
        this.policyDiscounts.push(Discount.fromJson(discount));
      }
    }

    if (json.policySurcharges) {
      for (const surcharge of json.policySurcharges) {
        this.policySurcharges.push(Surcharge.fromJson(surcharge));
      }
    }

    if (json.policyFees) {
      for (const fee of json.policyFees) {
        this.policyFees.push(Fee.fromJson(fee));
      }
    }

    if (this.cancelDate) {
      this.isPolicyCancelled = true;
    }

    if (this.cancelReasonCode && !this.cancelDate) {
      this.cancelDate = json.outOfForceDate;
      this.isPolicyCancelled = true;
    }
  }
}
