import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { assign as _assign } from 'lodash';
import { Observable } from 'rxjs';

import { ConfigService, UserAnalytic, WindowRef } from '@amfam/shared/utility/shared-services';

import {
  AccountEnrollment,
  AnalyticOptions,
  ButtonAnalyticParams,
  EventAnalyticParams,
  PageAnalyticParams,
  ReplaceVehicleAnalyticParams,
  UserAnalyticParams
} from '../models/analytics.model';
import { DynatraceService } from '../services/dynatrace.service';
import * as AnalyticsActions from './analytics.actions';
import { getAdobeAnalyticsLoaded } from './analytics.selectors';

@Injectable({ providedIn: 'root' })
export class AnalyticsFacade {
  private profileInfo: UserAnalytic = new UserAnalytic();
  private gaEnabled = false;
  private dtmEnabled = false;

  constructor(
    private store: Store,
    private config: ConfigService,
    private win: WindowRef,
    private dynatraceService: DynatraceService
  ) {}

  private sendAnalytics(options: AnalyticOptions) {
    this.store.dispatch(AnalyticsActions.sendAnalytic({ options }));
  }

  /**
   * sets the profile info from the passed in user
   *
   * @param {UserAnalytic} userAnalytic
   * @memberof AnalyticsService
   */
  setProfileInfo(userAnalytic: UserAnalytic): void {
    this.profileInfo = Object.assign(this.profileInfo, userAnalytic);
  }

  /**
   * send button clicked link to Adobe
   *
   * @param {ButtonAnalytic} buttonAnalytic
   * @memberof AnalyticsFacade
   */
  trackButtonClick(link: ButtonAnalyticParams): void {
    const options: AnalyticOptions = {
      button: link
    };
    this.sendAnalytics(options);
  }

  /**
   * @param  {PageAnalyticParams} page
   * @memberof AnalyticsFacade
   */
  trackPage(page: PageAnalyticParams): void {
    const options: AnalyticOptions = {
      page,
      user: this.getUser()
    };
    this.sendAnalytics(options);
  }

  /**
   * @param  {EventAnalyticParams} event
   * @memberof AnalyticsFacade
   */
  trackEvent(event: EventAnalyticParams): void {
    const options: AnalyticOptions = {
      event,
      isGA: true
    };
    this.sendAnalytics(options);
  }
  /**
   * Send page and events to Adobe
   *
   * @param {PageAnalytic} pageAnalytic
   * @param {EventAnalytic} eventAnalytic
   * @memberof AnalyticsFacade
   */

  trackPageAndEvent(
    page: PageAnalyticParams,
    event?: EventAnalyticParams,
    button?: ButtonAnalyticParams,
    replaceVehicle?: ReplaceVehicleAnalyticParams
  ): void {
    const options: AnalyticOptions = {
      page,
      event,
      button,
      replaceVehicle,
      user: this.getUser()
    };
    this.sendAnalytics(options);
  }

  /**
   * track page and error in Adobe and Google
   *
   * @param {PageAnalytic} pageAnalytic
   * @param {string} error
   * @memberof AnalyticsFacade
   */
  trackPageAndError(pageAnalytic: PageAnalyticParams, error: string) {
    const obj = <PageAnalyticParams>_assign(pageAnalytic);
    obj.pageName = `${pageAnalytic.pageName}:${error}`;
    this.trackPage(obj);
  }

  trackAutoPayEnrollmentCountAndPageAndEvent(
    page: PageAnalyticParams,
    event: EventAnalyticParams,
    accountEnrollment: AccountEnrollment
  ): void {
    const options: AnalyticOptions = {
      page,
      event,
      accountEnrollment
    };
    this.sendAnalytics(options);
  }

  loadGoogleMaps(): Promise<any> {
    return new Promise<void>(resolve => {
      const url =
        '//maps.googleapis.com/maps/api/js?client=gme-americanfamilyinsurance1&channel=amfamdrp&key=' +
        this.config.get('gmKey');
      const node = document.createElement('script');
      node.src = url;
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  /**
   * loads Adobe and SiteCore analytics, validates analytics providers after page load
   *
   * @memberof AnalyticsFacade
   */
  loadAnalytics() {
    this.loadGoogleMaps();
    this.loadScript('launch', 'utf-8');
    this.loadScript('fxm', 'utf-8');
    this.win.nativeWindow.addEventListener('load', this.verifyAnalytics.bind(this), false);
  }

  /**
   * load script
   *
   * @private
   * @returns
   * @memberof AnalyticsFacade
   */
  private loadScript(tag, charset) {
    return new Promise<void>(resolve => {
      const url = this.config.get('links.' + tag);
      const node = document.createElement('script');
      node.src = url;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = charset;
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  /**
   * This method will verify that anayltics tags are loaded correctly and send the avaliability to DynaTrace
   *
   * @private
   * @memberof AnalyticsFacade
   */
  private verifyAnalytics() {
    this.dynatraceService.sendDynatraceAppVersion();
    if (this.win.nativeWindow._satellite && this.win.nativeWindow._satellite.track) {
      this.dtmEnabled = true;
    } else {
      this.dtmEnabled = false;
    }
    if (this.win.nativeWindow.ga && this.win.nativeWindow.ga.loaded) {
      this.gaEnabled = true;
    } else {
      this.gaEnabled = false;
    }
    this.dynatraceService.sendDynatraceAction('dtm', String(this.dtmEnabled));
    this.dynatraceService.sendDynatraceAction('ga', String(this.gaEnabled));
  }
  isAdobeAnalyticsLoaded(): Observable<boolean> {
    return this.store.select(getAdobeAnalyticsLoaded);
  }

  private getUser(): UserAnalyticParams {
    return {
      userType: this.profileInfo.userType,
      trackID: this.profileInfo.trackId,
      experienceID: this.profileInfo.experienceId,
      partnerID: this.profileInfo.partnerId
    };
  }
}
