import { ApiStatus } from '@amfam/shared/models';

export interface AddVehicleInitiateRequest {
  policyNumber: string;
  vehicle: {
    vin: string;
    year: string;
    make?: string;
    model?: string;
    series?: string;
    currentMileage: number;
    annualMileage: number;
    ownershipDate: string;
    msrp: string;
    primaryUsage?: string;
    partnerPrimaryUse?: string;
    garageAddress: {
      addressLine1: string;
      city: string;
      state: string;
      postalCode: string;
      country: 'USA';
    };
    airBagsIndicator: string;
    antiLockBrakes: boolean;
    antiTheftDeviceIndicator: string;
    antiTheftCategoryOne?: boolean;
    antiTheftCategoryTwo?: boolean;
    antiTheftCategoryThree?: boolean;
    antiTheftCategoryFour?: boolean;
    dayTimeRunningLights: boolean;
    electronicStability: boolean;
    hasOtherOrCoowner: boolean;
    tpis: [];
    coverages: [];
  };
  drivers: AddVehicleDriver[];
}

export interface AddVehicleInitiateResponse {
  status: ApiStatus;
  result: {
    policyNumber: string;
    submissionId: string;
    drivers: AddVehicleDriver[];
    transactionType: string;
    coveragesModelledAfterId: number;
    rate: AddVehicleRate;
    vehicleCoverages: AddVehicleCoverage[];
    tpis: TPIModel[];
    vehiclesInPolicy: AddVehicleInitiateVehicleModel[];
    driverAssignments: AddVehicleInitiateDriverAssignmentModel[];
  };
}

export interface AddVehicleInitiateErrorResponse {
  status: ApiStatus;
  request: AddVehicleInitiateRequest;
}

export interface AddVehicleFees {
  name: string;
  amount: number;
  feeCode: string;
  currency: string;
}

export interface AddVehicleRate {
  totalCost: number;
  priorCost: number;
  transactionCost: number;
  coveragePremiums: CoveragePremium[];
  appliedDiscounts: AppliedDiscount[];
  totalDiscountAmount: number;
  fees: AddVehicleFees[];
}

export interface AddVehicleCoverage {
  coverageName: string;
  coverageCode: string;
  termValue: string;
  selected: boolean;
  required: boolean;
  terms: AddVehicleTerm[];
  hasTerms: boolean;
  publicID?: string;
}

export interface AddVehicleTermOption {
  name: string;
  optionValue: string;
}

export interface AddVehicleTerm {
  name: string;
  selectedOption: string;
  chosenTermValue?: string;
  options: AddVehicleTermOption[];
}

export interface AddVehicleDriver {
  publicId: string;
  name?: string;
  primary: boolean;
  vin?: string;
  affiliations?: Affiliation[];
}

export interface Affiliation {
  membershipType: string;
  affiliationGroup: string;
  affiliationEligibility: boolean;
  memberNumber: string;
  memberTier: string;
  publicID: string;
  affiliationName: string;
}

export interface AddVehicleInfoState {
  policyNumber: string;
  submissionId?: string;
  drivers?: AddVehicleDriver[];
  transactionType?: string;
  rate?: AddVehicleRate;
  vehicleCoverages?: AddVehicleCoverage[];
  tpis?: TPIModel[];
  vehiclesInPolicy?: AddVehicleInitiateVehicleModel[];
  driverAssignments?: AddVehicleInitiateDriverAssignmentModel[];
  hasTPMPackage?: boolean;
  loading: boolean;
  status: ApiStatus;
  error?: ApiStatus;
  success?: ApiStatus;
}

interface TPIModel {
  name: string;
  publicId: string;
}

export interface AddVehicleInitiateVehicleModel {
  vin: string;
  description: string;
  coveragesModelledAfter?: string;
}

export interface AddVehicleInitiateDriverAssignmentModel {
  vin: string;
  driverId: number;
  primary: boolean;
  vehicleDisplayName: string;
  driverName: string;
  vehicleId: number;
  publicId?: string;
}

interface AppliedDiscount {
  discountName: string;
  discountCode: string;
}

interface CoveragePremium {
  coverageName: string;
  coverageCode: string;
  premium: number;
  effectiveDate: Date;
  endDate: Date;
}

export interface DetermineTPMAvailabilityRequest {
  JobNumber: string;
  UBIProgramCategory: 'VEHICLEDEVICE' | 'DRIVERDEVICE';
}

export interface DetermineTPMAvailabilityResponse {
  status: ApiStatus;
  IsPolicyEligible: boolean;
  InEligibleReasons: string;
}
