import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VehicleCoveragesEffects } from './+state/vehicle-coverages.effects';
import * as fromVehicleCoverages from './+state/vehicle-coverages.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromVehicleCoverages.FEATURE_KEY, fromVehicleCoverages.reducer),
    EffectsModule.forFeature([VehicleCoveragesEffects])
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class PolicyVehicleCoveragesDataAccessModule {}
