import { createAction } from '@ngrx/store';

// Registration check actions
export const CAN_REGISTER = '[BillAccountRegistration] Can Register';
export const CAN_REGISTER_SUCCESS = '[BillAccountRegistration] Can Register Success';
export const CAN_REGISTER_FAIL = '[BillAccountRegistration] Can Register Fail';
export const CAN_REGISTER_CLEAR = '[BillAccountRegistration] Can Register Clear';

export const CanRegisterAction = createAction(CAN_REGISTER, (payload: unknown) => ({ payload }));

export const CanRegisterSuccessAction = createAction(CAN_REGISTER_SUCCESS, (payload: unknown) => ({
  payload
}));

export const CanRegisterFailAction = createAction(CAN_REGISTER_FAIL, (payload: unknown) => ({
  payload
}));

export const CanRegisterClearAction = createAction(CAN_REGISTER_CLEAR);

export type BillAccountCanRegisterAction =
  | ReturnType<typeof CanRegisterAction>
  | ReturnType<typeof CanRegisterSuccessAction>
  | ReturnType<typeof CanRegisterFailAction>
  | ReturnType<typeof CanRegisterClearAction>;

export const fromBillAccountCanRegisterActions = {
  CanRegisterAction,
  CanRegisterSuccessAction,
  CanRegisterFailAction,
  CanRegisterClearAction
};
