/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FRUser, OAuth2Tokens, TokenManager } from '@forgerock/javascript-sdk';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  of as observableOf,
  throwError as observableThrowError,
  Subject,
  Subscription
} from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { CoreService } from '@amfam/opportunities';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { AccountTypeEnum, userQuery, UserState } from '@amfam/shared/user';
import { BrandSelectors, Partners } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ConfigService, CookiesService, PageAnalytic } from '@amfam/shared/utility/shared-services';

import * as fromRoot from '../store';
import * as resetPasswordActions from '../store/reset-password/reset-password.actions';
import * as sessionActions from '../store/session/session.actions';

@Injectable({ providedIn: 'root' })
export class ForgerockAuthService {
  // track unsuccessful attempts here
  emailEntryAttemptsStore$: Subject<any> = new BehaviorSubject(null);

  // redirect for after logging in
  redirectUrl = '/';

  altAuthVerified = false;
  loggedIn = false;

  private digitalAccountApiUrl: string;
  private authenticateUrl: string;
  private logoutUrl: string;
  private refreshUrl: string;
  private retrieveUserIdUrl: string;
  private user$: Observable<UserState>;
  private loggedIn$: Observable<boolean>;
  private loggedInSub: Subscription;
  private partnerIdSub: Subscription;
  private partnerId: string;
  private rememberUser = false;
  private maskedUsername: string;
  private validateEmailPath: string;
  private accountType: string;
  private partnerUrls = {
    [Partners.AMERIPRISE_DIRECT]: 'forgerock.ameripriseUrl',
    [Partners.COSTCO_CHOICE]: 'forgerock.costcoChoiceUrl',
    [Partners.COSTCO_ECP]: 'forgerock.costcoEcpUrl',
    [Partners.AFBA]: 'forgerock.afbaUrl',
    [Partners.WELLS_FARGO]: 'forgerock.wellsFargoUrl',
    [Partners.UW_MADISON]: 'forgerock.uwaaUrl',
    [Partners.ELECTRIC_BOOKROLL]: 'forgerock.electricUrl',
    [Partners.COVER_MY_STUFF]: 'forgerock.coverMyStuffUrl',
    [Partners.DIRECT_32]: 'forgerock.direct32Url',
    [Partners.COMPARE]: 'forgerock.compareUrl',
    [Partners.CONNECT_COSTCO]: 'forgerock.connectUrl',
    [Partners.CONNECT_NON_PARTNER]: 'forgerock.connectNonPartnerUrl',
    [Partners.INSURIFY]: 'forgerock.insurifyUrl',
    [Partners.METLIFE_BOOKROLL]: 'forgerock.metlifeUrl',
    [Partners.INSURITAS]: 'forgerock.insuritasUrl',
    [Partners.HOMEGAUGE]: 'forgerock.homeGaugeUrl',
    [Partners.MATIC]: 'forgerock.maticUrl',
    [Partners.ONSTAR]: 'forgerock.onStarInsuranceUrl',
    default: 'forgerock.afiUrl'
  };

  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Logout',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Logout',
    subCategory2: '',
    subCategory3: ''
  };
  // **** End of Analytics data for this component

  constructor(
    private store: Store,
    private config: ConfigService,
    private cookies: CookiesService,
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
    private analyticsFacade: AnalyticsFacade,
    private coreService: CoreService
  ) {
    this.digitalAccountApiUrl = this.config.get('digitalAccountApi');
    this.authenticateUrl = this.digitalAccountApiUrl + '/digitalaccounts/authenticate';
    this.logoutUrl = this.digitalAccountApiUrl + '/digitalaccounts/logout';
    this.refreshUrl = this.digitalAccountApiUrl + '/digitalaccounts/refresh';
    this.retrieveUserIdUrl = this.digitalAccountApiUrl + '/digitalaccounts/forgotuserid';
    this.user$ = this.store.select(userQuery.getUserState);
    this.validateEmailPath = this.config.get('profileValidateEmailPath');
    this.loggedInSub = this.store.select(fromRoot.loggedIn).subscribe(loggdIn => {
      this.loggedIn = loggdIn;
    });
    this.partnerIdSub = this.store
      .select(BrandSelectors.selectPartnerId)
      .subscribe(partnerId => (this.partnerId = partnerId));

    this.store
      .select(userQuery.getUserState)
      .pipe(take(1))
      .subscribe(user => {
        this.accountType =
          user.typeOfAccountCode === AccountTypeEnum.CFR ? 'commercial' : 'personal';
      });

    // Check if rememberMe cookie exists and does not have value 'ForgetMe'
    const rmuid = this.cookies.getItem('RMUID');
    this.rememberUser = rmuid && rmuid !== 'ForgetMe' ? true : false;
    if (this.rememberUser) {
      this.maskedUsername = this.cookies.getItem('MKUID');
    } else {
      // Remove MKUID and RMUID cookies if rememberUser = false
      this.cookies.removeItem('RMUID', '/', '.amfam.com');
      this.cookies.removeItem('MKUID', '/', '.amfam.com');
    }
  }

  get isAltAuthVerified() {
    return this.altAuthVerified;
  }
  get currentUser$() {
    return this.user$;
  }

  get userIdRetrievalAttempts$() {
    return this.emailEntryAttemptsStore$.asObservable();
  }

  get rememberMe() {
    return this.rememberUser;
  }

  get maskedUserId() {
    return this.maskedUsername;
  }

  setAltAuth(value: boolean) {
    this.altAuthVerified = value;
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  forgerockLogin(accessToken: string, idToken: string): Observable<any> {
    if (this.isLoggedIn()) {
      this.logout();
    }

    const body = {
      partnerId: this.partnerId,
      idToken: idToken
    };

    let headers = new HttpHeaders();
    headers = headers.set('frAccessToken', accessToken);
    headers = headers.set('amfamfrt', 'true');

    return this.http.post(this.authenticateUrl, body, { headers: headers }).pipe(
      map((data: any) => {
        if (data && data.trackId) {
          if (data.duplicateEmail === true) {
            this.cookies.setItem('isDuplicateEmail', '1');
          } else {
            this.cookies.removeItem('isDuplicateEmail');
          }
          this.trackLogIn(data);
        }
        this.getEmailTokenUrl();
        return data;
      }),
      catchError(err => {
        this.store.dispatch(fromRouterActions.Go({ path: ['/error'] }));
        return this._handleError(err);
      })
    );
  }

  refresh(): Observable<boolean> {
    return this.http.post(this.refreshUrl, '').pipe(
      map((data: any) => {
        if (data && data.trackId) {
          this.store.dispatch(new sessionActions.LoginUserSuccessAction(data));
          return true;
        }
      }),
      catchError(err => this._handleRefreshError(err))
    );
  }

  logout(): Observable<boolean> {
    return this.http.post(this.logoutUrl, '').pipe(
      map((res: any) => this._logout()),
      catchError((error: any) => observableOf(this._logout()))
    );
  }

  /*
    Get the current time from the gateway
  */
  getGatewayTime(): Observable<any> {
    const gatewayTimeUrl = this.config.get('gatewayTimeApi');
    return this.http.get(gatewayTimeUrl);
  }

  public loginRedirect(): boolean {
    this.store.dispatch(
      fromRouterActions.Go({
        path: [this.redirectUrl]
      })
    );
    return true;
  }

  public checkUsernameAvailability(username: string): Observable<any> {
    const checkUsernameAvailabilityUrl =
      this.digitalAccountApiUrl + '/digitalaccounts?userId=' + username;

    return this.http.get(checkUsernameAvailabilityUrl).pipe(
      switchMap((data: any) => observableOf(data)),
      distinctUntilChanged(),
      catchError((error: any) => this._handleError(error))
    );
  }

  public checkEmailAddressAvailability(email: string): Observable<any> {
    const checkEmailAddressAvailabilityUrl =
      this.digitalAccountApiUrl +
      '/digitalaccounts?emailAddress=' +
      encodeURIComponent(email) +
      (this.partnerId ? '&partnerId=' + encodeURIComponent(this.partnerId) : '');

    return this.http.get(checkEmailAddressAvailabilityUrl).pipe(
      switchMap((data: any) => {
        if (data && data.status && data.status.code === 200) {
          return observableOf(data);
        } else {
          return this._handleError(data);
        }
      }),
      distinctUntilChanged(),
      catchError((error: any) => this._handleError(error))
    );
  }

  incrementEmailEntryAttempts() {
    let attempts = sessionStorage.getItem('email-entry-attempts');
    if (attempts) {
      const attemptsNum = parseInt(attempts, 10) + 1;
      sessionStorage.removeItem('email-entry-attempts');
      sessionStorage.setItem('email-entry-attempts', attemptsNum.toString());
      attempts = sessionStorage.getItem('email-entry-attempts');
    } else if (!attempts) {
      sessionStorage.setItem('email-entry-attempts', '1');
      attempts = sessionStorage.getItem('email-entry-attempts');
    }
    this.emailEntryAttemptsStore$.next(attempts);
  }

  retrieveUserId(requestObj: any): Observable<any> {
    return this.http.post(this.retrieveUserIdUrl, JSON.stringify(requestObj)).pipe(
      mergeMap((data: any) => {
        if (data.status.code === 200) {
          data.emailForResend = requestObj.emailAddress;
          return observableOf(data);
        }
        return this._handleError(data);
      }),
      catchError((error: any) => this._handleError(error))
    );
  }

  refreshSessionStorageAttempts() {
    const attempts = sessionStorage.getItem('attempts');
    if (attempts) {
      this.emailEntryAttemptsStore$.next(attempts);
    } else {
      return;
    }
  }

  setCurrentlyLockedUserId(userId: string) {
    this.store.dispatch(new resetPasswordActions.UserLoginLockedAction(userId));
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public _handleRefreshError(res) {
    if (this.isLoggedIn()) {
      this.expire().then(r =>
        this.store.dispatch(
          fromRouterActions.Go({
            path: ['/session-expired']
          })
        )
      );
    } else {
      if (this.redirectUrl.includes(this.validateEmailPath)) {
        this.store.dispatch(
          fromRouterActions.Go({
            path: ['/email-validation']
          })
        );
      }
    }
    return this._handleError(res);
  }

  public async getTokens(acr_values?: string, forceRenew?: boolean): Promise<OAuth2Tokens | void> {
    // Retrieve the base URL using the partnerId
    const baseUrl = this.config.get(this.partnerUrls[this.partnerId] || 'forgerock.afiUrl');
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');
    if (acr_values) {
      return await TokenManager.getTokens({
        forceRenew: forceRenew,
        login: 'redirect',
        query: { acr_values },
        serverConfig: {
          timeout: this.config.get('forgerock.timeout'),
          baseUrl: baseUrl
        }
      });
    } else if (code && state) {
      return await TokenManager.getTokens({
        serverConfig: {
          timeout: this.config.get('forgerock.timeout'),
          baseUrl: baseUrl
        },
        query: { code, state }
      });
    }
  }
  private getEmailTokenUrl() {
    const emailTokenUrl = localStorage.getItem('emailTokenUrl');
    if (emailTokenUrl) {
      localStorage.removeItem('emailTokenUrl');
      this.store.dispatch(fromRouterActions.Go({ path: [emailTokenUrl] }));
    }
  }
  private trackLogIn(userData) {
    const accountType =
      userData.typeOfAccountCode === AccountTypeEnum.CFR ? 'commercial' : 'personal';

    this.analyticsFacade.trackEvent({
      event: 'login',
      method: '',
      account_type: accountType,
      login_complete_count: '1',
      party_key: userData.trackId
    });
    this.analyticsFacade.trackEvent({
      event: 'secure_sign_in_success'
    });
  }

  private trackLogout() {
    this.analyticsFacade.trackPage(this.pageAnalytic);
    this.analyticsFacade.trackEvent({
      event: 'logout',
      account_type: this.accountType,
      logout_count: '1'
    });
  }

  private async expire(): Promise<boolean> {
    await FRUser.logout();
    return this._logout();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _handleError(res) {
    return observableThrowError(res);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _logout(): boolean {
    this.cleanupLocalStorage();
    this.coreService.stopListening();
    this.store.dispatch(new sessionActions.LogoutUserAction());
    this.trackLogout();
    return true;
  }

  private cleanupLocalStorage() {
    localStorage.removeItem('frFlowType');
    localStorage.removeItem('FR-SDK-AFI-MyAccount-Web-Preprod');
    localStorage.removeItem('FR-SDK-AFI-MyAccount-Web');
    localStorage.removeItem('expid');
  }
}
