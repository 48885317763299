import { Component, Input } from '@angular/core';

import { CopyService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-overview-section',
  templateUrl: './overview-section.component.html',
  styleUrls: ['./overview-section.component.scss']
})
export class OverviewSectionComponent {
  @Input() loading = false;
  @Input() newDesign = false;
  @Input() imgSrc = '';
  @Input() disableDefaultClass = false;
  @Input() private sectionHeading = '';
  @Input() private sectionHeadingPath = '';
  @Input() private spinnerText = '';
  @Input() private spinnerTextPath = '';

  constructor(private copyService: CopyService) {}

  get sectionHeadingString() {
    return this.getCopy(this.sectionHeading, this.sectionHeadingPath);
  }

  get spinnerTextString() {
    return this.getCopy(this.spinnerText, this.spinnerTextPath);
  }

  private getCopy(str: string, key: string) {
    return str || (key ? this.copyService.getCopy(key) : '');
  }
}

// the below are all child components specific to `OverviewSectionComponent`

// header-link
@Component({
  selector: 'ds-overview-section-header-link',
  template: ` <ng-content></ng-content> `
})
export class OverviewSectionHeaderLinkComponent {}

// content
@Component({
  selector: 'ds-overview-section-content',
  templateUrl: './overview-section-content.component.html',
  styleUrls: ['./overview-section-content.component.scss']
})
export class OverviewSectionContentComponent {
  @Input() justifyCenter: boolean;
}
