import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccordionCategoryComponent } from '../container/accordion-category.component';

@Component({
  selector: 'ds-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit, AfterContentInit, OnDestroy, OnChanges {
  @ContentChildren(AccordionCategoryComponent)
  categories: QueryList<AccordionCategoryComponent>;
  showExpandButton = true;
  showCollapseButton = false;
  private totalCategories = 0;
  totalCategoriesOpen = 0;
  @Input() allowToggleAccordion = false;
  @Input() allowMultipleOpenCategories = false;
  private stop$ = new Subject<void>();
  @Input() index: number;
  @Output() expandAllEvent = new EventEmitter();

  constructor() {}

  ngOnChanges() {
    window.setTimeout(() => {
      this.totalCategories = this.categories.length;

      // For each categories start a subscription and increment the open count if need be
      this.categories.toArray().forEach(categoryInstance => {
        this.addCategory(categoryInstance);
        if (categoryInstance.isOpen) {
          this.totalCategoriesOpen++;
        }
      });

      // Run link logic
      this.setToggleButton();
    }, 0);
  }
  ngOnInit() {}

  ngAfterContentInit() {
    // JC: This causes a recheck of the bindings and avoids a changed after checked exception. Trust Nick.
    window.setTimeout(() => {
      // Get a count of all of the AccordionCategoryComponents
      this.totalCategories = this.categories.length;

      // For each categories start a subscription and increment the open count if need be
      this.categories.toArray().forEach(categoryInstance => {
        this.addCategory(categoryInstance);
        if (categoryInstance.isOpen) {
          this.totalCategoriesOpen++;
        }
      });

      // Run link logic
      this.setToggleButton();
    }, 0);
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  public addCategory(category: AccordionCategoryComponent) {
    category.opening.pipe(takeUntil(this.stop$)).subscribe(openingCategory => {
      this.opening(openingCategory);
    });
    category.closing.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.closing();
    });
  }

  private opening(openingCategory: AccordionCategoryComponent) {
    // IF allowMultipleOpenCategories..
    if (this.allowMultipleOpenCategories) {
      // IF totalCategories is greater than totalCategoriesOpen
      // OR if totalCategories and totalCategoriesOpen is 0
      // THEN increment totalCategories
      if (
        this.totalCategories > this.totalCategoriesOpen ||
        (this.totalCategories === 0 && this.totalCategoriesOpen === 0)
      ) {
        this.totalCategoriesOpen++;
      }
      // ELSE close all other categories
    } else {
      this.categories.forEach(category => {
        if (category.isOpen && category !== openingCategory) {
          category.close();
        }
      });
    }
    // Run link logic
    this.setToggleButton();
  }

  private closing() {
    // IF allowMultipleOpenCategories AND totalCategoriesOpen is greater than 0
    // THEN decrease totalOpenCategories
    if (this.allowMultipleOpenCategories && this.totalCategoriesOpen > 0) {
      this.totalCategoriesOpen--;
    }
    // Run link logic
    this.setToggleButton();
  }

  private setToggleButton() {
    // IF totalCategories is 0 THEN no active links
    if (this.totalCategories && this.totalCategories === 0) {
      this.showExpandButton = false;
      this.showCollapseButton = false;
      // ELSE IF totalCategoriesOpen is 0 THEN expand link is active
    } else if (this.totalCategoriesOpen === 0) {
      this.showExpandButton = true;
      this.showCollapseButton = false;
      // ELSE IF totalCategories is equal to totalCategoriesOpen THEN collapse link is active
    } else if (this.totalCategories && this.totalCategories === this.totalCategoriesOpen) {
      this.showExpandButton = false;
      this.showCollapseButton = true;
      // ELSE both links are active
    } else {
      this.showExpandButton = true;
      this.showCollapseButton = true;
    }
  }

  expandAll() {
    if (this.categories && this.categories.length) {
      this.categories.forEach(category => {
        category.open();
      });
      this.expandAllEvent.emit();
    }
  }

  collapseAll() {
    if (this.categories && this.categories.length) {
      this.categories.forEach(category => {
        category.close();
      });
    }
  }
}
