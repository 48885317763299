import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

import { PolicySelectors } from '@amfam/policy/data-access';
import { GenericProductType, RiskModel } from '@amfam/policy/models';
import { Option } from '@amfam/shared/models';
import { ErrorLevels, ErrorObj } from '@amfam/ui-kit';

import { DriverDefaults } from '../models/driver-types';
import { FnolSummary } from '../models/initiate-dynamic-question-adapter.model';
import { Driver, FnolModel } from './fnol.model';
import { FNOL_FEATURE_KEY, FnolState, selectFnols } from './fnol.reducer';

// Lookup the 'Fnol' feature state managed by NgRx
export const getFnolState = createFeatureSelector<FnolState>(FNOL_FEATURE_KEY);

export const getFnolEntities = createSelector(getFnolState, (state: FnolState) => state.entities);

export const getFnols = createSelector(getFnolState, selectFnols);

export const getSelectedEntityId = createSelector(
  getFnolState,
  (state: FnolState) => state.selectedEntityId
);

export const getSelectedEntity = createSelector(
  getFnolEntities,
  getSelectedEntityId,
  (entities, id) => {
    return entities[id];
  }
);

export const getFnolLoadingStatus = createSelector(getSelectedEntity, fnol =>
  _get(fnol, 'loading')
);

export const getCurrentFnol = createSelector(getSelectedEntity, fnol => fnol);

export const getSelectedFnolWithClaimNumber = createSelector(getSelectedEntity, fnol => {
  if (!!_get(fnol, 'claimNumber')) {
    return fnol;
  }
});

export const getFnolErrorStatus = createSelector(getSelectedEntity, fnol =>
  _get(fnol, 'hasError', false)
);

export const getLossLocation = createSelector(getSelectedEntity, draftClaim =>
  _get(draftClaim, 'lossLocationAddress[0]')
);

export const getFnolErrorMessage = createSelector(getSelectedEntity, fnol => {
  const defaultMessage: Array<ErrorObj> = [
    {
      errorMessage: 'This service is temporarily unavailable. Please try again later.',
      errorLevel: ErrorLevels.HIGH
    }
  ];
  return _get(fnol, 'claimErrorMessage', defaultMessage);
});

export const getPhoneNumberAndEmailAddress = createSelector(getSelectedEntity, fnol => {
  return {
    emailAddress: fnol.emailAddress,
    phoneNumber: fnol.phoneNumber
  };
});

export const getVehicleDamageSummary = createSelector(getSelectedEntity, fnol =>
  _get(fnol, 'vehicleDamage')
);

export const getThirdPartyContactsSummary = createSelector(getSelectedEntity, fnol =>
  _get(fnol, 'thirdPartyContacts')
);

export const getClaimantContactSummary = createSelector(getSelectedEntity, fnol =>
  _get(fnol, 'claimantContact')
);

export const getDynamicView = createSelector(getSelectedEntity, fnol => {
  return {
    questionType: _get(fnol, 'questionType'),
    question: _get(fnol, 'question'),
    instructionText: _get(fnol, 'instructionText'),
    options: _get(fnol, 'options'),
    selectedAnswer: _get(fnol, 'selectedAnswer'),
    placeHolder: _get(fnol, 'placeHolder'),
    maxLength: _get(fnol, 'maxLength')
  };
});

export const submitQuestionStoreData = createSelector(getSelectedEntity, fnol => {
  return {
    questionType: _get(fnol, 'questionType'),
    questionId: _get(fnol, 'questionId'),
    draftClaimNumber: _get(fnol, 'claimNumber')
  };
});

export const getRiskDescription = createSelector(getSelectedEntity, fnol => {
  if (_get(fnol, 'risk.generalizedProductType', '') === GenericProductType.Auto) {
    if (!_get(fnol, 'vehicleDetails[0].vehTypeVin')) {
      return 'Vehicle Not on Policy';
    }
    return (
      _get(fnol, 'risk.year', '') +
      ' ' +
      _get(fnol, 'risk.make', '') +
      ' ' +
      _get(fnol, 'risk.model', '')
    );
  } else {
    return (
      _get(fnol, 'risk.address.addressLine1', '') +
      ', ' +
      _get(fnol, 'risk.address.city', '') +
      ', ' +
      _get(fnol, 'risk.address.state', '')
    );
  }
});

export const getFnolSummary = createSelector(getSelectedEntity, fnol => _get(fnol, 'summary'));

export const getFnolIncidentSummary = createSelector(getFnolSummary, (fnolSummary: FnolSummary) => {
  if (!!_get(fnolSummary, 'incidentSummary.length')) {
    return _get(fnolSummary, 'incidentSummary').map(summary => {
      return {
        reviewQuestion: _get(summary, 'reviewQuestionDisplay'),
        reviewAnswer: _get(summary, 'reviewAnswerDisplay'),
        questionId: _get(summary, 'questionId'),
        questionType: _get(summary, 'questionType')
      };
    });
  }
});

export const goBackToGetQuestion = createSelector(getSelectedEntity, fnol =>
  _get(fnol, 'enableBackButton')
);

export const getFnolRiskLineOfBusiness = createSelector(getCurrentFnol, (fnol: FnolModel) => {
  return _get(fnol, 'risk.generalizedProductType', '');
});

// Returns the contact info for driver
export const getAutoFnolDriver = createSelector(getSelectedEntity, fnol => {
  const driverContact = _get(fnol, 'vehicleDetails[0].vehLossDriverContact.contactId');
  return _get(fnol, 'claimContacts', []).find(contact => {
    return _get(contact, 'contact.contactId') === driverContact;
  });
});

// Returns the contact info for reporter. This is set to 1 by default
export const getFnolReporter = createSelector(getSelectedEntity, fnol => {
  return _get(fnol, 'claimContacts', []).find(contact => {
    return _get(contact, 'contact.contactId') === 1;
  });
});

export const getNamesOnPolicy = createSelector(
  getSelectedEntity,
  PolicySelectors.selectPolicyRisks,
  (draftClaim, allRisks) => {
    const options: Option[] = [];
    let i = 0;
    const matchedRisk = allRisks.find(risk => risk.id === draftClaim.risk.id);

    // If we've selected Vehicle is not on Policy, we need to show all operators for all auto policies
    if (
      _get(draftClaim, 'risk.vehicleTypeName') === DriverDefaults.VEHICLE_NOT_ON_POLICY ||
      !matchedRisk
    ) {
      allRisks.forEach(risk => {
        // Loop through names listed on policy roles
        _get(risk, 'policy.policyRoles', []).forEach(role => {
          const newOperator = {
            id: i.toString(),
            text: _get(role, 'firstName', '').trim() + ' ' + _get(role, 'lastName', '').trim()
          };
          // Only add newOperator if we haven't already added it to the list
          if (
            newOperator.text.trim() &&
            !options.find(option => option.text === newOperator.text)
          ) {
            options.push(newOperator);
            i++;
          }
        });

        // Loop through names listed on vehicle roles
        _get(risk, 'vehicleRoles', []).forEach(role => {
          const newOperator = {
            id: i.toString(),
            text: _get(role, 'firstName', '').trim() + ' ' + _get(role, 'lastName', '').trim()
          };
          // Only add newOperator if we haven't already added it to the list
          if (
            newOperator.text.trim() &&
            !options.find(option => option.text === newOperator.text)
          ) {
            options.push(newOperator);
            i++;
          }
        });
      });
    } else {
      // Otherwise, just show operators for given policy
      // Primary Named Insured needs to be added as the first option
      const primaryNamedInsured = _get(matchedRisk, 'policy.policyRoles', []).find(
        role => _get(role, 'roleType', '').toUpperCase() === 'PRIMARYNAMEDINSURED'
      );
      if (
        primaryNamedInsured &&
        _get(primaryNamedInsured, 'firstName') &&
        _get(primaryNamedInsured, 'lastName')
      ) {
        options.push({
          id: i.toString(),
          text:
            _get(primaryNamedInsured, 'firstName', '').trim() +
            ' ' +
            _get(primaryNamedInsured, 'lastName', '').trim()
        });
        i++;
      }

      // Loop through names listed on policy roles
      _get(matchedRisk, 'policy.policyRoles', []).forEach(role => {
        const newOperator = {
          id: i.toString(),
          text: _get(role, 'firstName', '').trim() + ' ' + _get(role, 'lastName', '').trim()
        };
        // Only add newOperator if we haven't already added it to the list
        if (newOperator.text.trim() && !options.find(option => option.text === newOperator.text)) {
          options.push(newOperator);
          i++;
        }
      });

      // Loop through names listed on vehicle roles
      _get(matchedRisk, 'vehicleRoles', []).forEach(role => {
        const newOperator = {
          id: i.toString(),
          text: _get(role, 'firstName', '').trim() + ' ' + _get(role, 'lastName', '').trim()
        };
        // Only add newOperator if we haven't already added it to the list
        if (newOperator.text.trim() && !options.find(option => option.text === newOperator.text)) {
          options.push(newOperator);
          i++;
        }
      });
    }

    // No driver in vehicle
    options.push({ id: i.toString(), text: DriverDefaults.NO_DRIVER_IN_VEHICLE });
    i++;

    // Driver not on policy
    options.push({ id: i.toString(), text: DriverDefaults.DRIVER_NOT_ON_POLICY });

    return options;
  }
);

export const getDriverPayloadFromName = createSelector(
  PolicySelectors.selectPolicyRisks,
  (allRisks: RiskModel[], driverOption: Option) => {
    let driver: Driver;
    allRisks.some(risk => {
      // Search all policy roles for a match
      _get(risk, 'policy.policyRoles', []).some(role => {
        if (
          _get(role, 'firstName', '').trim() + ' ' + _get(role, 'lastName', '').trim() ===
          driverOption.text
        ) {
          driver = {
            id: <string>driverOption.id,
            firstName: role.firstName,
            lastName: role.lastName
          };
          return true;
        }
      });
      // If we have a match, we can break, otherwise check vehicle roles
      if (driver) {
        return true;
      }

      // Search all vehicle roles for a match
      _get(risk, 'vehicleRoles', []).some(role => {
        if (
          _get(role, 'firstName', '').trim() + ' ' + _get(role, 'lastName', '').trim() ===
          driverOption.text
        ) {
          driver = {
            id: <string>driverOption.id,
            firstName: role.firstName,
            lastName: role.lastName
          };
          return true;
        }
      });
      return driver;
    });
    return driver;
  }
);

export const getPreSelectedDriver = createSelector(getSelectedEntity, draftClaim => {
  return draftClaim.driver;
});

export const fnolQuery = {
  getDynamicView,
  submitQuestionStoreData,
  getSelectedEntity,
  getFnolLoadingStatus,
  getCurrentFnol,
  getPhoneNumberAndEmailAddress,
  getVehicleDamageSummary,
  getRiskDescription,
  getFnolSummary,
  getFnolIncidentSummary,
  getFnols,
  getFnolErrorStatus,
  getFnolErrorMessage,
  getSelectedFnolWithClaimNumber,
  goBackToGetQuestion,
  getFnolRiskLineOfBusiness,
  getAutoFnolDriver,
  getFnolReporter,
  getThirdPartyContactsSummary,
  getClaimantContactSummary,
  getNamesOnPolicy,
  getPreSelectedDriver,
  getLossLocation,
  getDriverPayloadFromName
};
