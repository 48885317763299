// Angular
import { Injectable } from '@angular/core';
// Store
import { Actions, createEffect, ofType } from '@ngrx/effects';
// rxjs
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

// Models
// import { CreateDraftClaimResponse, DraftClaim } from '../models';
// Services
import { ClaimEnterpriseService } from '../services/claim-enterprise.service';
import { CreateEnterpriseClaim, fromClaimEnterpriseActions } from './claim-enterprise.actions';

@Injectable()
export class ClaimEnterpriseEffects {
  claimCreate$ = createEffect(() =>
    // eslint-disable-next-line ngrx/prefer-effect-callback-in-block-statement
    this.action$.pipe(
      ofType(CreateEnterpriseClaim),
      map(action => action),
      switchMap(action =>
        this.claimEnterpriseService.createClaim(action.payload, action.isProperty).pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((res: any) => fromClaimEnterpriseActions.CreateEnterpriseClaimSuccess(res.data)),
          catchError(error => of(fromClaimEnterpriseActions.CreateEnterpriseClaimFail(error)))
        )
      )
    )
  );
  constructor(
    private action$: Actions,
    private claimEnterpriseService: ClaimEnterpriseService
  ) {}
}
