import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@amfam/shared/utility/shared-services';

import {
  GetAllSecurityQuestionsResponse,
  GetUserSecurityQuestionsPayload
} from '../+state/security-questions/security-questions.models';

@Injectable({ providedIn: 'root' })
export class SecurityQuestionsService {
  constructor(
    public config: ConfigService,
    public http: HttpClient
  ) {}

  /**
   * DM: Gets list of all potential security questions
]  */
  public getAllSecurityQuestions(): Observable<GetAllSecurityQuestionsResponse> {
    const endpoint =
      this.config.get('digitalAccountApi') + '/referencedata?filter=SECURITY%20QUESTIONS';
    return this.http.get<GetAllSecurityQuestionsResponse>(endpoint);
  }

  /**
   * MR: Gets the security questions associated with a user
   * @param userData - Contains userIdentifier and partnerId to be used for finding security questions
   */
  public getUserSecurityQuestions(userData: GetUserSecurityQuestionsPayload): Observable<any> {
    const endpoint = this.config.get('digitalAccountApi') + '/securityquestions';
    let params: HttpParams = new HttpParams();
    if (userData.userIdentifier.includes('@')) {
      params = params.set('email', encodeURIComponent(userData.userIdentifier));
      params = params.set('partnerId', userData.partnerId);
    } else {
      params = params.set('userId', userData.partnerId);
    }

    return this.http
      .get(endpoint)
      .pipe(map((data: any) => data.securityQuestions.map(item => item.question)));
  }
}
