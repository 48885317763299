
import { Policy, SignatureRequest } from '@amfam/policy/models';
import { createAction } from '@ngrx/store';

export enum ESignatureActionTypes {
  GetESignatureType = '[ESignature] Get ESignature',
  GetESignatureSuccessType = '[ESignature] Get ESignature Success',
  GetESignatureCompleteType = '[ESignature] Get ESignature Complete',
  GetESignatureFailType = '[ESignature] Get ESignature Fail',

  SignESignatureType = '[ESignature] Sign ESignature',
  SignESignatureSuccessType = '[ESignature] Sign ESignature Success',
  SignESignatureCompleteType = '[ESignature] Sign ESignature Complete'
}

export const GetESignature = createAction(
  ESignatureActionTypes.GetESignatureType,
  (payload: Policy[]) => ({
    payload
  })
);

export const GetESignatureSuccess = createAction(
  ESignatureActionTypes.GetESignatureSuccessType,
  (payload?: Policy[]) => ({
    payload
  })
);

export const GetESignatureFail = createAction(
  ESignatureActionTypes.GetESignatureFailType,
  (payload?: undefined) => ({
    payload
  })
);

export const GetESignatureComplete = createAction(
  ESignatureActionTypes.GetESignatureCompleteType,
  (payload?: undefined) => ({
    payload
  })
);

export const SignESignature = createAction(
  ESignatureActionTypes.SignESignatureType,
  (payload: SignatureRequest) => ({
    payload
  })
);

export const SignESignatureSuccess = createAction(
  ESignatureActionTypes.SignESignatureSuccessType,
  (payload: string) => ({
    payload
  })
);

export const SignESignatureComplete = createAction(
  ESignatureActionTypes.SignESignatureCompleteType,
  (payload?: string) => ({
    payload
  })
);

export type ESignatureActions =
  | ReturnType<typeof GetESignature>
  | ReturnType<typeof GetESignatureSuccess>
  | ReturnType<typeof GetESignatureFail>
  | ReturnType<typeof GetESignatureComplete>
  | ReturnType<typeof SignESignature>
  | ReturnType<typeof SignESignatureSuccess>
  | ReturnType<typeof SignESignatureComplete>;

export const fromESignatureActions = {
  GetESignature,
  GetESignatureSuccess,
  GetESignatureFail,
  GetESignatureComplete,
  SignESignature,
  SignESignatureSuccess,
  SignESignatureComplete
};
