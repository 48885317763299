@if (!forgerockEnabled) {
  <h1 content [contentPath]="'auth.emailValidationHeader'"></h1>
  <p class="pad-top-10" content [contentPath]="'auth.emailValidationSubHeader'"></p>
  <div class="row pad-top-30">
    <div class="column twelve">
      <div class="button-width">
        <div class="block button-group pad-bottom-20">
          <button
            ds-button
            class="-stacked"
            tabindex="-1"
            [disabled]="accountLocked"
            (click)="loginSelected()"
            [class.active]="showLogin"
            content
            [contentPath]="'auth.emailValidationLoginButton'"
          ></button>
          <button
            ds-button
            class="-stacked"
            tabindex="-1"
            (click)="searchSelected()"
            [class.active]="showSearch"
            content
            [contentPath]="'auth.emailValidationSearchButton'"
          ></button>
        </div>
      </div>
    </div>

    <div class="column twelve">
      @if (showLogin && !accountLocked) {
        <div>
          <p class="pad-top-10" content [contentPath]="'auth.emailValidationLoginText'"></p>
          <ds-login-form
            (responseCodeEvent)="loginResponseReceived($event)"
            [isEmailValidation]="true"
          >
          </ds-login-form>
          <div class="column twelve caption pad-top-10">
            <span content contentPath="auth.duplicateEmailloginTrouble"></span>
            <a dsPartnerContent content contentPath="shared.amfamPhoneNumber"></a>
          </div>
        </div>
      }
      @if (showSearch || accountLocked) {
        <div>
          <p
            class="pad-bottom-10 pad-top-10"
            content
            [contentPath]="'auth.emailValidationSearchText'"
          ></p>
          <ds-magic3-search
            (submittedFormEvent)="onMagic3Submit($event)"
            [showBackButton]="false"
            [searchErrorMessage]="searchErrorMessageInput"
          >
          </ds-magic3-search>
          @if (accountLocked) {
            <p class="error-600-text">
              [Your account is currently locked. Please verify with personal info.]
            </p>
          }
        </div>
      }
    </div>
  </div>
} @else {
  <div class="login">
    <div class="login__form">
      <div class="login__form-header" content [contentPath]="'auth.forgerock.welcome'"></div>

      <p
        class="pad-top-10"
        content
        [contentPath]="'auth.forgerock.emailVerificationHeaderText'"
      ></p>
      <br />
      <button
        ds-button
        class="-stacked"
        tabindex="-1"
        (click)="signIn()"
        [class.active]="showLogin"
        content
        [contentPath]="'auth.forgerock.signIn'"
      ></button>
      <div class="column twelve caption pad-top-10">
        <span content contentPath="auth.duplicateEmailloginTrouble"></span>
        <a dsPartnerContent content contentPath="shared.amfamPhoneNumber"></a>
      </div>
    </div>
  </div>
}
