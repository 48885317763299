import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AutoPayContext, AutoPaySetup, AutoPaySteps } from './auto-pay-setup.models';
import * as fromAutoPaySetup from './auto-pay-setup.reducer';

export const selectAutoPaySetupState = createFeatureSelector<fromAutoPaySetup.State>(
  fromAutoPaySetup.autoPaySetupFeatureKey
);

export const selectActiveStep = createSelector(
  selectAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.activeStep
);

export const selectEntryPoint = createSelector(
  selectAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.entryPoint
);

export const selectContext = createSelector(
  selectAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.context
);

export const selectPreselectedAccounts = createSelector(
  selectAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.preselectedAccounts
);

export const selectAllAutoPaySetups = createSelector(
  selectAutoPaySetupState,
  fromAutoPaySetup.selectAllAutoPaySetups
);

export const selectIsUserComingFromEnrollment = createSelector(
  selectAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.isUserComingFromEnrollment
);

export const selectHasRegistrationError = createSelector(
  selectAutoPaySetupState,
  (state: fromAutoPaySetup.State) => state.registrationError
);

export const selectActiveSetup = createSelector(
  selectAllAutoPaySetups,
  (setups: AutoPaySetup[]) => {
    // find bill account that is not set up yet
    const activeSetup: AutoPaySetup = setups.find(setup => setup.active);
    return activeSetup;
  }
);

export const selectPreviousSetup = createSelector(
  selectActiveStep,
  selectAllAutoPaySetups,
  (activeStep: AutoPaySteps, setups: AutoPaySetup[]) => {
    if (activeStep === AutoPaySteps.REVIEW) {
      return setups[setups.length - 1];
    } else {
      const activeSetupIndex: number = setups.findIndex(setup => setup.active);
      return activeSetupIndex > 0 ? setups[activeSetupIndex - 1] : null;
    }
  }
);

export const selectNextSetup = createSelector(
  selectActiveStep,
  selectAllAutoPaySetups,
  (activeStep: AutoPaySteps, setups: AutoPaySetup[]) => {
    if (activeStep === AutoPaySteps.SELECT_ACCOUNTS) {
      return setups[0];
    } else {
      const activeSetupIndex: number = setups.findIndex(setup => setup.active);
      return setups.length > 0 && activeSetupIndex < setups.length - 1
        ? setups[activeSetupIndex + 1]
        : null;
    }
  }
);
export const selectAutoPayTitle = createSelector(selectContext, context =>
  context === AutoPayContext.EDIT ? 'Edit AutoPay' : 'Set Up AutoPay'
);
export const selectIsEditingSetup = createSelector(
  selectActiveSetup,
  setup => setup?.editing ?? false
);
export const selectIsAddMultiple = createSelector(
  selectContext,
  context => context === AutoPayContext.ADD_MULTIPLE
);
