import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { fromRouterActions, NavigationService } from '@amfam/shared/utility/navigation';
import { EventDispatcherService, PageAnalytic } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import * as fromRoot from '../../../../core/store/';
import * as workflowActions from '../../../../core/store/workflow/workflow.actions';
import { PersonalService } from '../../shared/personal.service';

@Component({
  selector: 'verify-account-exists',
  templateUrl: './verify-account-exists.component.html',
  styleUrls: ['../../../enrollment.component.scss']
})
export class VerifyAccountExistsComponent implements OnInit {
  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Enroll:ExistingAccount',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Enroll',
    subCategory2: 'Personal',
    subCategory3: 'Error:Existing Account'
  };
  // **** End of Analytics data for this component

  lastName: string;
  zipcode: string;
  dob: string;
  policyNum: string;
  urlToNavigate: Array<string>;
  noEnrolledUserStateInfo = false;
  isMaeWorkflow = false;

  constructor(
    private store: Store<fromRoot.RootState>,
    private analyticsFacade: AnalyticsFacade,
    private dispatcher: EventDispatcherService,
    private spinner: LoadingSpinnerService
  ) {}

  goBackOneStep() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/enroll']
      })
    );
  }

  ngOnInit() {
    this.urlToNavigate = ['/login'];

    combineLatest(
      this.store.select(fromRoot.getWorkflowType),
      this.store.select(fromRoot.getEnrollUserState),
      (workflowType, enrollUserState) => {
        return {
          workflowType: workflowType,
          enrollUserState: enrollUserState
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        this.isMaeWorkflow = state.workflowType === 'mae';
        this.lastName = _get(state, 'enrollUserState.lastName');
        this.dob = _get(state, 'enrollUserState.dob');
        this.zipcode = _get(state, 'enrollUserState.zipcode');
        this.policyNum = _get(state, 'enrollUserState.policyNumber');
        this.noEnrolledUserStateInfo =
          !this.lastName && !this.dob && !this.zipcode && !this.policyNum;
      });
    this.analyticsFacade.trackPage(this.pageAnalytic);
  }

  goToLogin() {
    // Delete existing workflow
    this.spinner.start();
    this.store.dispatch(new workflowActions.WorkflowDeleteAction());
    this.dispatcher.logout();
  }
}
