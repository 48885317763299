import { get as _get, has as _has, head as _head } from 'lodash';

import { Address, Policy, PolicyTypeIconConstants, Risk } from '@amfam/policy/models';
import { CapitalToPascal } from '@amfam/shared/ui/pipes';

import { Life } from './life.model';

export class LifePolicy extends Policy {
  public coverageAmount: number;
  public riskLocationAddress: Address;
  public namedInsured: Risk;

  static fromJson(json: Life): LifePolicy {
    const policy = new LifePolicy();
    policy.assignProperties(json);

    return policy;
  }

  protected assignProperties(json: Life) {
    json.periodStartDate = json.effectiveDate;
    super.assignProperties(json);
    this.iconType = PolicyTypeIconConstants.LIFE;
    this.policyType = 'life';
    this.coverageAmount = json.coverageAmount;

    if (_has(json, 'insured')) {
      json.insured.forEach(insured => {
        const name =
          _get(insured, 'firstName', '') +
          ' ' +
          _get(insured, 'middleInitial', '') +
          ' ' +
          _get(insured, 'lastName', '');
        this.policyRisks.push(name);
        insured.addressInfo.forEach(address => {
          const zipCode = _get(address, 'zipCode', '').replace(/-/g, '');
          address.zipCode = zipCode ? zipCode.substring(0, 5) + '-' + zipCode.substring(5) : '';
          this.riskLocationAddress = Address.fromJson(address);
        });
      });
    }

    if (this.policyRisks.length > 0) {
      this.namedInsured = _head(this.policyRisks);
    }

    this.riskDescriptionList = [new CapitalToPascal().transform(this.policyType)];
  }
}
