import { AddressLookupModel } from '../../address-lookup/address-model';

export interface EnrollUserModel {
  // Identification
  lastName?: string;
  dob?: string;
  zipcode?: string;
  enrollmentType?: string;
  // Business
  businessName?: string;
  // Party
  partyId?: string;
  partnerId?: string;
  typeOfPartyCode?: string;
  firstName?: string;
  middleName?: string;
  greetingName?: string;
  socialSecurityNumberOnRecordIndicator?: boolean;
  maskedPhones?: string[];
  maskedEmails?: string[];
  match?: number;
  // Verification
  verificationType?: string;
  policyNumber?: string;
  // Sign Up
  emailAddressInput?: string;
  emailAddressSelect?: string;
  userName?: string;
  password?: string;
  // Security Questions
  questionOne?: string;
  answerOne?: string;
  questionTwo?: string;
  answerTwo?: string;
  // Cruise
  shellAccountFoundForgotUserId?: boolean;
  shellAccountFound?: boolean;
}

export const initialState: EnrollUserModel = {
  // Identification
  lastName: '',
  dob: '',
  zipcode: '',
  // Business
  businessName: '',
  // Party
  partyId: '',
  partnerId: '',
  typeOfPartyCode: '',
  firstName: '',
  middleName: '',
  greetingName: '',
  socialSecurityNumberOnRecordIndicator: false,
  maskedPhones: null,
  maskedEmails: null,
  match: null,
  // Verification
  verificationType: '',
  policyNumber: '',
  // Sign Up
  emailAddressInput: '',
  emailAddressSelect: '',
  userName: '',
  password: '',
  enrollmentType: '',
  // Security Questions
  questionOne: '',
  answerOne: '',
  questionTwo: '',
  answerTwo: '',
  // Cruise
  shellAccountFoundForgotUserId: false,
  shellAccountFound: false
};
