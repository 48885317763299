<div>
  <h4
    content
    [contentPath]="
      !isAdminApp
        ? 'profile.communicationPreferencesHeading'
        : 'profile.communicationPreferencesAdminHeading'
    "
    data-cy="comunicationPreferencesHeading"
  ></h4>
  <p
    class="accordion-text"
    content
    [contentPath]="
      !isAdminApp
        ? 'profile.communicationPreferencesSubheading'
        : 'profile.communicationPreferencesAdminSubheading'
    "
    data-cy="comunicationPreferencesSubHeading"
  ></p>
  @if (!isAdminApp) {
    <ds-preferences-message [message]="message"></ds-preferences-message>
  }
  <div class="flex wrap">
    <div class="flex fill">
      <div class="preference-options-header">
        <span class="preference-options-header-item" data-cy="paperHeading">Paper</span>
        <span class="preference-options-header-item" data-cy="emailHeading">Email</span>
        <span class="preference-options-header-item" data-cy="bothHeading">Both</span>
      </div>
    </div>
    <ds-billing-communication-preferences
      class="flex wrap fill"
      (billingPreferencesChangeEvent)="billingPreferencesChangeEvent($event)"
      [clearWarning]="clearWarning"
      [preferenceData]="preferenceData"
      data-cy="billingPreferencesChangeEvent"
    >
    </ds-billing-communication-preferences>
    @if (showPolicyComponent) {
      <ds-policies-communication-preferences
        class="flex wrap fill"
        (policyPreferencesChangeEvent)="policyPreferencesChangeEvent($event)"
        [clearWarning]="clearWarning"
        [paperlessPolicy]="paperlessPolicy"
        data-cy="policyPreferencesChangeEvent"
      >
      </ds-policies-communication-preferences>
    }
  </div>
  <div class="flex justify-space-between margin-top-30">
    <div>
      @if (!canSetAll) {
        <a
          (click)="openCantChangeSettingsModal()"
          content
          [contentPath]="'profile.communicationPreferencesNoChangeExplanation'"
          data-cy="communicationPreferencesNoChangeExplanation"
        ></a>
      }
    </div>
    <button
      ds-button
      [disabled]="disableSaveButton"
      (click)="savePreferences()"
      data-cy="savePreferences"
    >
      <span content [contentPath]="'shared.saveBtn'"></span>
    </button>
  </div>
</div>

<!-- Can not change settings modal -->
<ds-modal [id]="cantChangemodalId" [closeOthers]="true" [maxWidth]="780">
  <div class="pad-20">
    <h3
      class="text-center pad-bottom-20 font-weight-semibold"
      content
      [contentPath]="'profile.cantChangeSettingsModal.title'"
    ></h3>
    <p
      class="pad-bottom-10"
      content
      [contentPath]="'profile.cantChangeSettingsModal.listHeader'"
    ></p>
    <ul class="bullet pad-bottom-0">
      <li
        class="margin-bottom-0"
        content
        [contentPath]="'profile.cantChangeSettingsModal.bulletOne'"
      ></li>
      <li
        class="margin-bottom-0"
        content
        [contentPath]="'profile.cantChangeSettingsModal.bulletTwo'"
      ></li>
    </ul>
    <p content [contentPath]="'profile.cantChangeSettingsModal.callUs'"></p>
  </div>
</ds-modal>

<!-- Terms and Conditions modal -->
<ds-policy-terms-and-conditions
  [(visible)]="showTermsAndConditionsModal"
  [fromProfile]="true"
  [showPolicyTerms]="showPolicyTerms"
  [showPaperless]="false"
  [showBillingTerms]="showBillingTerms"
  (agreeTermsAndConditionsEvent)="agreeTermsAndConditionsEvent($event)"
>
</ds-policy-terms-and-conditions>

<!-- Promote discount modal -->
<ds-modal
  [id]="discountModalId"
  [closeOthers]="true"
  [maxWidth]="496"
  (onCloseModalEvent)="onCloseDiscountModalId($event)"
>
  <div class="row pad-horizontal-20">
    <div class="column twelve">
      <h3
        class="text-center margin-bottom-20"
        content
        contentPath="profile.paperlessTitle"
        data-cy="paperlessTitle"
      ></h3>
      <p
        content
        contentPath="'profile.promoteDiscountModalText'"
        data-cy="promoteDiscountModalText"
      ></p>
    </div>
    <div class="column twelve margin-top-20">
      <div class="row flex items-center">
        <div class="column seven">
          <button
            ds-button
            class="-outline fill"
            (click)="setPaperlessPreferences('discount-modal')"
            [attr.aria-label]="switchToPaperlessButtonText"
            data-cy="switchToPaperlessButton"
          >
            {{ switchToPaperlessButtonText }}
          </button>
        </div>
        <div class="column five pad-left-20">
          <p class="caption">
            <a
              (click)="openTermsAndConditionModal('discount-modal')"
              href="javascript:void(0)"
              [attr.aria-label]="switchSelectedAccountsButtonText"
              data-cy="switchSelectedAccountsButtonText"
              >{{ switchSelectedAccountsButtonText }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</ds-modal>

<!-- Loose discount modal -->
<ds-modal
  [id]="warnLosingDiscountModalId"
  [closeOthers]="true"
  [maxWidth]="496"
  (onCloseModalEvent)="onCloseWarnLosingDiscountModalId($event)"
>
  <div class="row pad-horizontal-20">
    @if (!isAdminApp) {
      <div class="column twelve">
        <h3
          class="text-center margin-bottom-20 error-600-text"
          content
          contentPath="profile.loseDiscountModalTitle"
          data-cy="loseDiscountModalTitle"
        ></h3>
        <p content contentPath="profile.loseDiscountModalText" data-cy="loseDiscountModalText"></p>
      </div>
    }
    @if (isAdminApp) {
      <div class="column twelve">
        <h3
          class="text-center margin-bottom-20 error-600-text"
          content
          contentPath="profile.loseDiscountModalTitleAdmin"
          data-cy="loseDiscountModalTitleAdmin"
        ></h3>
        <p
          content
          contentPath="profile.loseDiscountModalTextAdmin"
          data-cy="loseDiscountModalTextAdmin"
        ></p>
      </div>
    }
    <div class="column twelve margin-top-20">
      <div class="row flex items-center">
        <div class="column eight">
          <button
            ds-button
            class="-error fill"
            (click)="loseDiscount('warnLosingDiscount-modal')"
            [attr.aria-label]="switchToPaperButtonText"
            data-cy="switchToPaperButtonText"
          >
            {{ switchToPaperButtonText }}
          </button>
        </div>
        <div class="column four pad-left-20">
          <p class="caption">
            <a
              (click)="closeModal('warnLosingDiscount-modal', true)"
              href="javascript:void(0)"
              [attr.aria-label]="cancelButtonText"
              data-cy="cancelButtonText"
              >{{ cancelButtonText }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</ds-modal>
