import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import {
  ROUTER_CANCEL,
  ROUTER_ERROR,
  ROUTER_NAVIGATION,
  RouterAction,
  RouterStateSerializer
} from '@ngrx/router-store';
import { get as _get } from 'lodash';

import { RouterActions } from './router-actions';
import { initialRouterState, RouterState, RouterStateUrl } from './router-model';

export const NAVIGATION_FEATURE_KEY = 'navigation';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const url = routerState.url;
    const queryParams = routerState.root.queryParams;
    const params = route.params;
    const data = route.data;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams, data };
  }
}

export function routerReducer(
  state = initialRouterState,
  action: RouterAction<any, any> | RouterActions
): RouterState {
  switch (action.type) {
    case ROUTER_NAVIGATION:
      return {
        state: action.payload.routerState,
        previousState: state && typeof state.state !== 'undefined' ? state.state : null,
        navigationId: action.payload.event.id
      };
    case ROUTER_ERROR:
    case ROUTER_CANCEL:
    default: {
      return state;
    }
  }
}

export const getCurrentRouterState = (routerState: RouterState) => routerState.state;
export const getPreviousState = (routerState: RouterState) => _get(routerState, 'previousState');
export const getNavigationId = (routerState: RouterState) => _get(routerState, 'navigationId');
