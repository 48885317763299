<h3 [ngClass]="{ 'null-phone': !phones.length }" class="pad-top-30 margin-bottom-10">
  <span *dsPartnerAFI content [contentPath]="'profile.managePhoneNumbers.phoneNumbersTitle'"></span>
  <span
    *dsPartnerConnect
    content
    [contentPath]="'profile.managePhoneNumbers.phoneNumbersTitlePartners'"
  ></span>
</h3>
@if (!phones.length) {
  <p
    content
    [contentPath]="'profile.managePhoneNumbers.emptyPhoneNumberState'"
    class="pad-bottom-10"
    data-cy="noContactPhonesText"
  ></p>
}
@for (phone of phones; track phone) {
  <ds-phone-list-item
    data-cy="contactPhoneListItem"
    [phone]="phone"
    (makePrimaryEvent)="onMakePrimary($event)"
    (deleteEvent)="onDelete($event)"
  >
  </ds-phone-list-item>
}
@if (phones.length && allPrimaryCheck) {
  <p
    class="pad-top-20"
    content
    [contentPath]="'profile.managePhoneNumbers.primaryPhoneNumberChange'"
  ></p>
}
@if (isAddingPhone) {
  <div class="edit-form pad-bottom-10">
    <form [formGroup]="phoneListForm" (ngSubmit)="addPhoneNumber()">
      <div class="row flex wrap">
        <div class="column twelve sm-five sm-pad-right-10 pad-bottom-10 sm-pad-bottom-0 float-left">
          <ds-form-control-container
            [labelTextContentPath]="'shared.inputFieldLabelText.phoneNumber'"
            [control]="phoneListForm.get('phoneNumber')"
          >
            <input
              [identifier]="'phoneNumber'"
              id="phoneNumber"
              data-cy="phoneNumberInput"
              [preValue]="phoneListForm.value.phoneNumber"
              [phoneControl]="phoneListForm.controls['phoneNumber']"
              formControlName="phoneNumber"
              maxlength="12"
              dsPhoneMask
            />
            <ds-form-control-message
              [control]="phoneListForm.get('phoneNumber')"
            ></ds-form-control-message>
          </ds-form-control-container>
        </div>
        <div class="column twelve sm-three sm-pad-right-10 pad-bottom-10 sm-pad-bottom-0">
          <ds-form-control-container
            [labelTextContentPath]="'profile.managePhoneNumbers.inputLabels.phoneType'"
            [control]="phoneListForm.get('typeOfUsageCode')"
          >
            <select
              id="phoneType"
              class="icon fill"
              data-cy="phoneNumberType"
              formControlName="typeOfUsageCode"
            >
              @for (type of usageCodes; track type) {
                <option value="{{ type }}">
                  @if (type !== 'TDD_TTY') {
                    <span>{{ type | titlecase }}</span>
                  }
                  @if (type === 'TDD_TTY') {
                    <span>TDD/TTY</span>
                  }
                </option>
              }</select
            ><span class="icon-chevron-down"></span>
            <ds-form-control-message
              [control]="phoneListForm.get('typeOfUsageCode')"
            ></ds-form-control-message>
          </ds-form-control-container>
        </div>
        @if (phoneListForm.get('typeOfUsageCode').value === 'WORK') {
          <div class="column twelve sm-four pad-bottom-10 sm-pad-bottom-0">
            <ds-form-control-container
              [labelTextContentPath]="'profile.managePhoneNumbers.inputLabels.extension'"
              [control]="phoneListForm.get('extension')"
            >
              <input
                type="tel"
                data-cy="workPhoneExtensionInput"
                id="extension"
                formControlName="extension"
                class="fill"
                maxlength="10"
              />
              <ds-form-control-message
                [control]="phoneListForm.get('extension')"
              ></ds-form-control-message>
            </ds-form-control-container>
          </div>
        }
        @if (phoneListForm.get('typeOfUsageCode').value === 'OTHER') {
          <div class="column twelve sm-four pad-bottom-10 sm-pad-bottom-0">
            <ds-form-control-container
              [labelTextContentPath]="'shared.inputFieldLabelText.otherTypeDescription'"
              [control]="phoneListForm.get('descriptionForOtherType')"
            >
              <input
                type="text"
                data-cy="otherPhoneDescriptionInput"
                id="descriptionForOtherType"
                formControlName="descriptionForOtherType"
                class="fill"
                maxlength="35"
              />
              @if (phoneListForm.hasError('required')) {
                <ds-form-control-message [control]="phoneListForm"></ds-form-control-message>
              }
            </ds-form-control-container>
          </div>
        }
      </div>
      <div class="row flex wrap">
        <div class="column twelve pad-top-10">
          <input
            type="checkbox"
            class="checkbox"
            data-cy="makePrimaryCheckbox"
            [disabled]="(roleService.isAuthorized('make_primary_phone') | async) === false"
            id="makePrimaryPhoneOnSave"
            [checked]="this.makePrimaryOnSave"
            formControlName="primaryChecked"
          />
          <label
            for="makePrimaryPhoneOnSave"
            class="checkbox-label"
            content
            [contentPath]="'shared.makePrimaryPhoneText'"
          ></label>
          <p
            class="subText"
            content
            [contentPath]="'profile.managePhoneNumbers.primaryPhoneNumberUsageDescription'"
          ></p>
        </div>
      </div>
      <div class="row flex wrap">
        <div class="column twelve pad-top-10 null-phone">
          <button
            ds-button
            data-cy="submitPhoneNumberButton"
            type="submit"
            [disabled]="
              !phoneListForm.valid ||
              (roleService.isAuthorized('addphonenumber_submit') | async) === false
            "
            class="-outline float-left"
            content
            [contentPath]="'shared.saveBtn'"
          ></button>
          <button
            ds-button
            type="button"
            (click)="cancel()"
            content
            [contentPath]="'shared.cancel'"
            class="-white float-left bg-transparent cancel a margin-left-10"
          ></button>
        </div>
      </div>
    </form>
  </div>
}

<div
  [ngClass]="{
    'margin-top-20': phones.length,
    'pad-top-10': !phones.length,
    'pad-bottom-10': !phones.length
  }"
>
  <button
    ds-button
    id="addPhoneNumBtn"
    [disabled]="
      isAddingPhone || (roleService.isAuthorized('addphonenumber_view') | async) === false
    "
    class="-outline float-left"
    (click)="open()"
    data-cy="addPhoneNumberButton"
    content
    [contentPath]="'shared.addBtn'"
  ></button>
</div>
