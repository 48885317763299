import { ATV } from './atv';
import { AutoCategoryType } from './auto-category-type';
import { Boat } from './boat';
import { Motorcycle } from './motorcycle';
import { Vehicle } from './vehicle';
import { VehicleType } from './vehicle-type';
import { Policy } from './policy';
import { PolicyTypeIconConstants } from './policy-type-icon-constants';
import { RiskTypeIconConstants } from './risk-type-icon-constants';

// lodash
import { flatMap as _flatMap } from 'lodash';

export class AutoPolicy extends Policy {
  public vehicles: Vehicle[] = new Array<Vehicle>();
  public category: AutoCategoryType;
  public poiLink: string;
  public iconType: string;

  public static getStatus(type): string {
    switch (type) {
      case VehicleType.ATV:
      case VehicleType.UTV:
      case VehicleType.GolfCart:
      case VehicleType.MobilityDevice:
        return RiskTypeIconConstants.ATV;
      case VehicleType.Boat:
        return RiskTypeIconConstants.WATERCRAFT;
      case VehicleType.Motorcycle:
      case VehicleType.Moped:
      case VehicleType.DirtBike:
      case VehicleType.Scooter:
        return RiskTypeIconConstants.MOTORCYCLE;
      case VehicleType.Motorhome:
      case VehicleType.Camper:
      case VehicleType.Trailer:
        return RiskTypeIconConstants.RV;
      case VehicleType.Snowmobile:
        return RiskTypeIconConstants.SNOWMOBILE;
      case VehicleType.FarmMachinery:
        return RiskTypeIconConstants.FARMMACHINERY;
      default:
        return PolicyTypeIconConstants.AUTO;
    }
  }
  static fromJson(json: any): AutoPolicy {
    const policy = new AutoPolicy();
    policy.assignProperties(json);
    return policy;
  }

  protected assignProperties(json: any) {
    super.assignProperties(json);
    this.policyType = 'auto';
    this.iconType = PolicyTypeIconConstants.AUTO;

    this.category = this.getCategoryType(json.policyDescription);

    this.vehicles = json.vehicles
      .map(vehicle => ({ vehicle: vehicle, type: Vehicle.getVehicleType(vehicle.vehicleTypeCode) }))
      .map(data => {
        data.vehicle.iconType = AutoPolicy.getStatus(data.type);
        switch (data.vehicle.iconType) {
          case RiskTypeIconConstants.ATV:
            return ATV.fromJson(data.vehicle, this.sourcePath);
          case RiskTypeIconConstants.WATERCRAFT:
            return Boat.fromJson(data.vehicle, this.sourcePath);
          case RiskTypeIconConstants.MOTORCYCLE:
            return Motorcycle.fromJson(data.vehicle, this.sourcePath);
          default:
            return Vehicle.fromJson(data.vehicle, this.sourcePath);
        }
      })
      .sort(this.compare);

    this.policyRisks = this.vehicles;
    this.riskDescriptionList = _flatMap(
      this.policyRisks,
      (risk: Vehicle) => `${risk.year} ${risk.make} ${risk.model}`
    );
  }

  private getCategoryType(policyDescription: string): AutoCategoryType {
    switch (policyDescription.toUpperCase()) {
      case 'BOATOWNERS':
        return AutoCategoryType.Boat;
    }

    return AutoCategoryType.Auto;
  }

  private compare(a: Vehicle, b: Vehicle): number {
    if (a == null || b == null) {
      return -1;
    }

    const aModelYear = parseInt(a.year, 10);
    const bModelYear = parseInt(b.year, 10);

    if (aModelYear < bModelYear) {
      return 1;
    }

    if (aModelYear > bModelYear) {
      return -1;
    }

    if (aModelYear === bModelYear) {
      if (a.make < b.make) {
        return -1;
      }

      if (a.make > b.make) {
        return 1;
      }

      return 0;
    }

    return 0;
  }
}
