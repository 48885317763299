import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: 'a[click]:not([href]):not([role]):not([tabindex]):not([x-no-tabbing])'
})
export class TabEnabledLinkDirective {
  @HostBinding('attr.role') role = 'button';
  @HostBinding('attr.tabindex') tabindex = '0';

  @HostListener('keydown.Enter', ['$event'])
  @HostListener('keydown.Space', ['$event'])
  onKeyDown(e: any) {
    e.preventDefault();
    e.target.click();
  }
}
