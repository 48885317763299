import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import * as fromFeatureFlag from './+state/feature-flag.reducer';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('featureFlag', fromFeatureFlag.reducer)]
})
export class SharedUtilityFeatureFlagDataAccessModule {}
