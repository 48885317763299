import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { TPIIndicator } from '@amfam/policy/vehicle/data-access';

import { VechiclePolicyChangeEntryPoint, VehicleSelectCriteria } from '../models/vehicle.models';
import * as vehicleActions from './vehicle.actions';
import { vehicleQuery } from './vehicle.selectors';

@Injectable({ providedIn: 'root' })
export class VehicleFacade {
  makes$ = this.store.select(vehicleQuery.selectMakes);
  models$ = this.store.select(vehicleQuery.selectModels);
  series$ = this.store.select(vehicleQuery.selectStyles);
  years$ = this.store.select(vehicleQuery.selectYears);
  isLoading$ = this.store.select(vehicleQuery.selectLoading);
  criteria$ = this.store.select(vehicleQuery.selectCriteria);
  newVehicle$ = this.store.select(vehicleQuery.selectNewVehicle);
  newRiskDescription$ = this.store.select(vehicleQuery.selectNewRiskDescription);
  error$ = this.store.select(vehicleQuery.selectHasError);
  status$ = this.store.select(vehicleQuery.selectStatus);
  vehiclePolicyChangeEntryPoint$ = this.store.select(
    vehicleQuery.selectVehiclePolicyChangeEntryPoint
  );
  vehicleFeatures$ = this.store.select(vehicleQuery.selectVehicleFeatures);

  constructor(private store: Store) {}

  loadMakes(year: string) {
    this.store.dispatch(vehicleActions.GetMakes({ year }));
  }

  loadModels(year: string, make: string) {
    this.store.dispatch(vehicleActions.GetModels({ year, make }));
  }

  loadSeries(year: string, make: string, model: string) {
    this.store.dispatch(vehicleActions.GetStyles({ year, make, model }));
  }

  lookupVinYear(year: string, vin: string) {
    this.store.dispatch(vehicleActions.LookupVinYear({ year, vin }));
  }

  reset() {
    this.store.dispatch(vehicleActions.Reset());
  }

  setCriteria(criteriaOptions: VehicleSelectCriteria) {
    this.store.dispatch(vehicleActions.SetCriteria(criteriaOptions));
  }

  setTpi(tpiName: string, copyTpiIndicator: TPIIndicator) {
    this.store.dispatch(vehicleActions.SetTpi({ tpiName, copyTpiIndicator }));
  }

  setVehiclePolicyChangeEntryPoint(entryPoint: VechiclePolicyChangeEntryPoint) {
    this.store.dispatch(vehicleActions.SetVehiclePolicyChangeEntryPoint({ entryPoint }));
  }

  addNewVehicle(year: string, make?: string, model?: string, series?: string, vin?: string) {
    let newVehicle: VehicleSelectCriteria = {
      year
    };
    if (make) newVehicle = { ...newVehicle, make };
    if (model) newVehicle = { ...newVehicle, model };
    if (series) newVehicle = { ...newVehicle, series };

    if (vin) newVehicle = { ...newVehicle, vin };

    this.store.dispatch(vehicleActions.SetCriteria(newVehicle));
  }
}
