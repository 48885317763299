import * as deleteActions from './delete.actions';
import { DeleteMyAccount, initialState } from './delete.model';

export function reducer(state = initialState, action: deleteActions.Actions): DeleteMyAccount {
  switch (action.type) {
    case deleteActions.RESET:
      return Object.assign({}, state, {
        authenticateStatus: null,
        unenrollProgramsStatus: null,
        deleteMyAccountStatus: null,
        sendTemplatedEmailStatus: null
      });

    case deleteActions.AUTHENTICATE_USER_FAIL:
      return Object.assign({}, state, {
        authenticateStatus: action.payload.status.code || action.payload.status
      });

    case deleteActions.AUTHENTICATE_USER_SUCCESS:
      return Object.assign({}, state, {
        authenticateStatus: action.payload.status.code
      });

    case deleteActions.UNENROLL_USER_FAIL:
    case deleteActions.UNENROLL_USER_SUCCESS:
      return Object.assign({}, state, {
        unenrollProgramsStatus: action.payload.status.code || action.payload.status
      });

    case deleteActions.DELETE_MYACCOUNT_ACTION_SUCCESS:
    case deleteActions.DELETE_MYACCOUNT_ACTION_FAIL:
      return Object.assign({}, state, {
        deleteMyAccountStatus: action.payload.status.code
      });

    case deleteActions.SEND_TEMPLATED_EMAIL_ACTION_SUCCESS:
    case deleteActions.SEND_TEMPLATED_EMAIL_ACTION_FAIL:
      return Object.assign({}, state, {
        sendTemplatedEmailStatus: action.payload.status.code
      });

    default:
      return state;
  }
}

export const authenticateStatus = (state: DeleteMyAccount) => state.authenticateStatus;
export const unenrollProgramsStatus = (state: DeleteMyAccount) => state.unenrollProgramsStatus;
export const deleteMyAccountStatus = (state: DeleteMyAccount) => state.deleteMyAccountStatus;
export const sendTemplatedEmailStatus = (state: DeleteMyAccount) => state.sendTemplatedEmailStatus;
