<h1 class="heading-main">
  <span *dsPartnerAFI content [contentPath]="'goPaperless.completeYourSignUp'"></span>
  <span *dsPartnerConnect content [contentPath]="'goPaperless.vehicleProgramEnrollment'"></span>
</h1>
<form [formGroup]="termsForm" (ngSubmit)="submitForm()">
  <div class="row">
    <div class="column twelve">
      <span *dsPartnerAFI content [contentPath]="'goPaperless.termsAndConditions'"> </span>
      <span *dsPartnerConnect>
        <span content [contentPath]="'overview.enrollTermsVehicle'"></span>
        <a
          [href]="propertyServiceUrl"
          target="_blank"
          content
          [contentPath]="'overview.visitTheirWebsite'"
        ></a>
        <span content [contentPath]="'overview.forMoreInfo'"></span
      ></span>
    </div>
  </div>
  <div class="row pad-top-20">
    @for (program of model.programs; track program) {
      <div class="column twelve program">
        <div class="pad-bottom-20">
          <div class="program-name">{{ program.title }}</div>
          @if (program.isPaymentTerms) {
            <ds-payment-terms-and-conditions
              paymentMethodType="Unknown"
            ></ds-payment-terms-and-conditions>
          }
          @if (program.termsUrl) {
            <a
              href="{{ propertyServiceUrl }}"
              target="amfam"
              class="accent"
              content
              [contentPath]="'overview.viewTermsConditionsFor'"
              [options]="{
                propertyServiceUrl: propertyServiceUrl
              }"
            ></a>
          }
        </div>
      </div>
    }
  </div>
  @if (model.hasTerms) {
    <div class="row pad-top-20">
      <div class="column twelve">
        <div class="terms pad" tabindex="0">
          @for (program of model.programs; track program) {
            @if (program.terms) {
              <div class="program-terms" [innerHtml]="program.terms"></div>
            }
          }
        </div>
      </div>
    </div>
  }
  <div class="row pad-top-20">
    <div class="column twelve">
      <fieldset>
        <input
          authCheck
          class="margin-right-5"
          permissionName="maetnc_submit"
          checkAction="disable"
          type="radio"
          id="agreeToTermsRadio"
          formControlName="agreeToTerms"
          [value]="true"
          [(ngModel)]="agreeToTerms"
        />
        @if (model.showPaymentTerms) {
          <label
            for="agreeToTermsRadio"
            data-cy="enrollTermsAgreeToTermsRadio"
            content
            [contentPath]="'goPaperless.agreePaymentAuth'"
          ></label>
        }
        @if (!model.showPaymentTerms) {
          <label
            for="agreeToTermsRadio"
            data-cy="enrollTermsAgreeToTermsRadio"
            content
            [contentPath]="'goPaperless.agreeTermsAndConditions'"
          ></label>
        }
      </fieldset>
    </div>
    <div class="column twelve">
      <fieldset>
        <input
          authCheck
          class="margin-right-5"
          permissionName="maetnc_submit"
          checkAction="disable"
          type="radio"
          id="declineTermsRadio"
          formControlName="agreeToTerms"
          [value]="false"
          [(ngModel)]="agreeToTerms"
        />
        @if (model.showPaymentTerms) {
          <label
            for="declineTermsRadio"
            data-cy="enrollTermsDeclineTermsRadio"
            content
            [contentPath]="'goPaperless.declinePaymentAuth'"
          ></label>
        }
        @if (!model.showPaymentTerms) {
          <label
            for="declineTermsRadio"
            data-cy="enrollTermsDeclineTermsRadio"
            content
            [contentPath]="'goPaperless.declineTermsAndConditions'"
          ></label>
        }
      </fieldset>
      @if (!agreeToTerms && hasSelectedAgreeToTermsOption) {
        <div class="warning pad margin-top-10">
          <span content [contentPath]="'goPaperless.noteDeclining'"></span>
        </div>
      }
    </div>
  </div>
  <div class="row callToAction">
    <div class="column twelve">
      <span class="md-float-right">
        <button
          ds-button
          type="submit"
          [disabled]="!hasSelectedAgreeToTermsOption"
          content
          [contentPath]="'shared.submitBtn'"
          data-cy="enrollTermsButton"
        ></button>
      </span>
    </div>
  </div>
</form>
