export interface Alert {
  level: AlertLevels;
  type: AlertTypes;
  data?: {
    [propName: string]: any;
  };
  consolidated: boolean;
}

export enum AlertLevels {
  'UNKNOWN',
  'HIGH',
  'MEDIUM',
  'LOW',
  'MESSAGE',
  'HOMESITE',
  'WARNING'
}

export enum AlertTypes {
  'UNKNOWN',
  'BILL_OVERDUE',
  'MULTIPLE_BILLS_OVERDUE',
  'PAPERLESS_POLICY_DISCOUNT',
  'PAPERLESS_BILLING_DISCOUNT',
  'MULTIPLE_PAPERLESS_BILLING_DISCOUNT',
  'UNSUCCESSFUL_PAYMENT',
  'UNSUCCESSFUL_AFT_PAYMENT',
  'MULTIPLE_UNSUCCESSFUL_PAYMENTS',
  'PREREQUISITE_NEEDED_AUTOPAY_DISCOUNT',
  'AUTOPAY_DISCOUNT',
  'MULTIPLE_AUTOPAY_EDIT',
  'AUTOPAY_EDIT',
  'MULTIPLE_BILLS_AUTOPAY_DISCOUNT',
  'SYSTEM_OUTAGE',
  'KYD_DISCOUNT',
  'KYD_SMARTPHONE_DISCOUNT',
  'KYD_SMARTPHONE_OPERATOR_DISCOUNT',
  'POLICIES_MANAGED_EXTERNALLY',
  'GOPAPERLESS_FAILURE',
  'EMAILL_DEEP_LINK_FAILURE',
  'AUTOPAY_DOES_NOT_EXIST_ON_BILLACCOUNT',
  'CA_MILEAGE'
}

export interface AlertCopy {
  message?: string;
  action?: string;
  actionType?: string;
}
