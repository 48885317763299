import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ResetOptionModel, VerifyPinRequest } from '@amfam/shared/models';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { LoadingSpinnerService, ToasterService } from '@amfam/ui-kit';

import * as fromRoot from '../../../../core/store';
import { ForgotService } from '../../../shared/forgot.service';
import { ForgotPasswordAnalytics } from '../../shared/forgot-password-analytic-constants';

@Component({
  selector: 'ds-reset-password-text',
  templateUrl: './reset-password-text.component.html',
  styleUrls: ['../../forgot-password.component.scss']
})
export class ResetPasswordTextComponent implements OnInit, OnDestroy {
  pinConfirmData: VerifyPinRequest;
  phone: string;
  userIdentifier: string;
  partnerId: string;
  phoneList: string[];
  showCodeEntry = false;
  codeNotFound = false;
  phoneLocked = false;
  gotError = false;

  constructor(
    private store: Store,
    private toaster: ToasterService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade,
    private forgotService: ForgotService
  ) {}

  ngOnInit() {
    combineLatest(
      this.store.select(fromRoot.getResetPasswordState),
      this.store.select(BrandSelectors.selectPartnerId),
      (resetPasswordState, partnerId) => {
        return {
          resetPasswordState: resetPasswordState,
          partnerId: partnerId
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        this.userIdentifier = _get(state, 'resetPasswordState.userIdentifier', '');
        this.phoneList = _get(state, 'resetPasswordState.userDetails.maskedPhoneNumbers');
        this.partnerId = state.partnerId;
        if (this.phoneList.length === 1) {
          this.sendCode(this.phoneList[0]);
        }
        this.pinConfirmData = {
          userId: this.userIdentifier,
          partnerId: this.partnerId
        };
      });

    this.analyticsFacade.trackPage(ForgotPasswordAnalytics.pageForgotPasswordText);
  }

  sendCode(phone: string) {
    this.spinner.start();
    this.phoneLocked = false;
    this.gotError = false;
    this.phone = phone;

    const requestObj: ResetOptionModel = {
      userIdentifier: this.userIdentifier,
      typeOfVerificationMethodCode: 'TEXT MESSAGE',
      maskedPhoneNumber: phone
    };

    this.forgotService
      .sendVerificationRequest(requestObj)
      .pipe(take(1))
      .subscribe(
        res => {
          this.spinner.stop();
          const textSuccess = 'We will be texting you shortly with your code';
          this.toaster.pop('success', textSuccess);
          this.showCodeEntry = true;
        },
        err => {
          this.spinner.stop();
          this.checkIfPhoneLocked(err);
          const textError = 'We had trouble sending you a text';
          this.gotError = true;
          this.toaster.pop('error', textError);
          this.showCodeEntry = false;
        }
      );
  }

  private checkIfPhoneLocked(err: any) {
    const apiCode = _get(err, 'status.messages[0].code');
    if (apiCode === 400010) {
      this.phoneLocked = true;
    }
  }

  goBackOneStep() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/forgot-password/reset-options']
      })
    );
  }

  ngOnDestroy() {
    this.spinner.stop();
  }
}
