<div class="flex row margin-top-5">
  <div><i class="icon" [ngClass]="policyRiskIcons[0]"></i></div>
  @if (policyRiskIcons.length > 1) {
    <div>
      <i class="icon" [ngClass]="policyRiskIcons[1]"></i>
    </div>
  }
  @if (policyRiskIcons.length > 2) {
    <div class="plusSign">+{{ policyRiskIcons.length - 2 }}</div>
  }
</div>
