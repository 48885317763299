import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let nextId = 0;

@Component({
  selector: 'ds-accordion-category',
  templateUrl: './accordion-category.component.html',
  styleUrls: ['./accordion-category.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false, void', style({ height: '0px' })),
      transition('* => *', animate(250))
    ])
  ]
})
export class AccordionCategoryComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() isOpen = false;
  @Input() icon = '';
  @Input() title = '';
  @Input() htmlTitle: boolean = false;
  @Input() startOpen: boolean;
  @Input() showNewBadge: boolean;
  @Input() showIcon: boolean = true;

  // TODO - Change these eventemitters out to self managed observables
  @Output() opening = new EventEmitter<AccordionCategoryComponent>();
  @Output() closing = new EventEmitter<AccordionCategoryComponent>();
  @Output() opened = new EventEmitter<AccordionCategoryComponent>();
  @ViewChild('scrollTarget') scrollTarget: ElementRef;

  private stop$ = new Subject<void>();

  private uniqueId = nextId++;
  showOutline: boolean;
  uniqueContentId = '';
  uniqueLabelId = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    // Create unique names from the title
    // Change title to lowercase, replace spaces with dashes, add type and unique number
    this.uniqueContentId = this.formatUniqueId(this.title, 'content', this.uniqueId);
    this.uniqueLabelId = this.formatUniqueId(this.title, 'label', this.uniqueId);
  }

  ngAfterViewInit() {
    this.route.params.pipe(takeUntil(this.stop$)).subscribe(() => {
      if (this.startOpen) {
        this.openAndScroll();
      }
    });
  }

  ngOnChanges(changes) {
    if (changes.startOpen) {
      if (this.startOpen) {
        this.open();
      }
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  public handleToggleClick() {
    if (this.showIcon) {
      this.toggleOpen();
    }
  }
  public open() {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.opening.emit(this);

    if (this.scrollTarget && this.scrollTarget.nativeElement) {
      const scrollElement = this.scrollTarget.nativeElement;
      if (document.body.clientWidth < 768) {
        window.setTimeout(() => {
          scrollElement.scrollIntoView();
        }, 50);
      }
    }
  }

  openCloseDone(event: AnimationEvent) {
    if (event.toState) {
      this.opened.emit();
    }
  }

  public openAndScroll() {
    if (!this.isOpen) {
      this.isOpen = true;
      this.opening.emit(this);
    }

    if (this.scrollTarget && this.scrollTarget.nativeElement) {
      const scrollElement = this.scrollTarget.nativeElement;
      // Wait for accordion to open and give frame of reference
      window.setTimeout(() => {
        const headerHeight = 138;
        let offset = 0;
        // Use existing solution for mobile
        if (document.body.clientWidth < 768) {
          scrollElement.scrollIntoView();
        } else {
          // Find element distance from top
          let element = scrollElement;
          while (element) {
            offset += element.offsetTop - element.scrollTop + element.clientTop;
            element = element.offsetParent;
          }
          // Account for header covering part of page
          offset -= headerHeight;
          window.scrollTo(0, offset);
        }
      }, 500);
    }
  }

  public close() {
    if (!this.isOpen) {
      return;
    }
    this.isOpen = false;
    this.closing.emit(this);
  }

  public toggleOpen() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  public enableOutline() {
    this.showOutline = true;
  }

  public disableOutline() {
    this.showOutline = false;
  }

  private formatUniqueId(title, contentType, uniqueId) {
    return title.replace(/\s+/g, '-').toLowerCase() + '-' + contentType + '-' + uniqueId;
  }
}
