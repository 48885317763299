@if (!!policyTermsConditionModal) {
  <ds-modal
    [id]="policyTermsConditionModal"
    [closeOthers]="true"
    [maxWidth]="modalWidth"
    (onCloseModalEvent)="closeModal($event)"
  >
    <div data-cy="policyTermsConditionModal">
      @if (showPaperless) {
        <div class="row" data-cy="policyTermsConditionModalPaperlessContent">
          <div class="column twelve items-center">
            <h1
              class="pad-bottom-20"
              content
              [contentPath]="'goPaperless.paperlessBenefitTitle'"
              data-cy="policyTermsConditionModalPaperlessContentTitle"
            ></h1>
            <p
              *dsPartnerAFI
              class="pad-bottom-20"
              content
              [contentPath]="'goPaperless.paperlessBenefitTextAFI'"
              data-cy="paperlessBenefitTextAFI"
            ></p>
            <p
              *dsPartnerConnect
              class="pad-bottom-20"
              content
              [contentPath]="'goPaperless.paperlessBenefitTextPARTNER'"
              data-cy="paperlessBenefitTextPARTNER"
            ></p>
            <h2
              class="font-weight-semibold"
              content
              [contentPath]="'goPaperless.paperlessBenefitTitle2'"
              data-cy="paperlessBenefitTitle2"
            ></h2>
            <p
              *dsPartnerAFI
              class="pad-bottom-40"
              content
              [contentPath]="'goPaperless.paperlessBenefitsAFI'"
              data-cy="paperlessBenefitsAFI"
            ></p>
            <p
              *dsPartnerConnect
              class="pad-bottom-40"
              content
              [contentPath]="'goPaperless.paperlessBenefitsPARTNER'"
              data-cy="paperlessBenefitsPARTNER"
            ></p>
          </div>
        </div>
      }
      @if (!showPaperless) {
        <div class="row pad-20" data-cy="policyTermsConditionModalTermsContent">
          <div class="column twelve">
            @if (!isAdminApp && showPolicyTerms) {
              <ds-policy-terms
                [partnerData]="termsPartnerData$ | async"
                data-cy="policyTermsConditionModalPolicyTermsContent"
              ></ds-policy-terms>
            }
            @if (!isAdminApp && showBillingTerms) {
              <ds-billing-terms
                [partnerData]="termsPartnerData$ | async"
                data-cy="billingTermsConditionModalPolicyTermsContent"
              ></ds-billing-terms>
            }
            @if (isAdminApp && (showPolicyTerms || showBillingTerms)) {
              <ds-admin-terms
                [partnerData]="termsPartnerData$ | async"
                data-cy="policyTermsConditionModalPolicyTermsContent"
              >
              </ds-admin-terms>
            }
          </div>
        </div>
      }
      @if (fromProfile) {
        <div class="column twelve margin-top-20 pad-bottom-20">
          <div class="row flex items-center">
            <button
              ds-button
              (click)="agreeTermsAndConditions(true)"
              class="-outline-white margin-right-10 pad-horizontal-20"
              data-cy="policyTermsConditionModalTermsAgreeButton"
              content
              [contentPath]="'profile.policyTermsAndConditions.agree'"
            ></button>
            <p class="caption">
              <a
                (click)="agreeTermsAndConditions(false)"
                data-cy="policyTermsConditionModalTermsDisagreeLink"
                content
                [contentPath]="'shared.cancel'"
              ></a>
            </p>
          </div>
        </div>
      }
      @if (!fromProfile) {
        <div class="column twelve pad-bottom-20">
          <div class="row flex justify-close-text">
            <p>
              <a (click)="closeModal()" data-cy="policyTermsConditionModalCloseLink">Close</a>
            </p>
          </div>
        </div>
      }
    </div>
  </ds-modal>
}
