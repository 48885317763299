import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducers } from './+state';
import { FinancialAccountEffects } from './+state/financial-account/financial-account.effects';
import { PaymentMethodEffects } from './+state/payment-method/payment-method.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('paymentMethod', reducers),
    EffectsModule.forFeature([PaymentMethodEffects, FinancialAccountEffects])
  ]
})
export class BillingPaymentMethodDataAccessModule {}
