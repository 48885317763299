<div class="row" [ngClass] = "{'ca-mileage-confirmation': hostFromCaMileage}">
  <div class="column twelve lg-push-two lg-eight">
    <!-- AS: As part of multiple autopay setup , we are now going to display success and failure
    headers in the same confirmation. Seperating it out based on the type here -->

    <!--AS: If there is an error to be displayed in the confirmation page-->
    @if (
      confirmationStatus === 'error' ||
      (errorDetailsList?.length > 0 && hasErrorConfirmationTobeShown && !!errorConfirmationHeading)
    ) {
      <div>
        <div class="confirmation-title" [class.column]="confirmationStatus === 'verify'">
          <span class="icon pseudo-icon error"></span>
          <div>
            <h2
              class="heading-main confirmation-title-text"
              [innerHtml]="errorDetailsList ? errorConfirmationHeading : confirmationHeading"
            ></h2>
            @if (confirmationSubHeading) {
              <span class="confirmation-title-subtext" [innerHTML]="confirmationSubHeading"></span>
            }
          </div>
        </div>
        <!--AS: Use the details object to build the rows-->
        <ng-content></ng-content>
        @if (errorDetailsList) {
          <ds-confirmation-details [details]="errorDetailsList"></ds-confirmation-details>
        }
        @if (errorDetailsList) {
          <div class="confirmation-cta">
            <button ds-button class="margin-right-20" (click)="retryClickedEvent.emit()">
              TRY AGAIN
            </button>
            <a class="caption pad-horizontal-20" href="{{ amfamTelNumber }}" target="_blank">{{
              amfamPhone
            }}</a>
          </div>
        }
      </div>
    }

    <!--AS: If there is no error to be displayed in the confirmation page-->
    @if (confirmationStatus !== 'error') {
      <div>
        <div class="confirmation-title" [class.column]="confirmationStatus === 'verify'">
          <span
            class="icon pseudo-icon"
            [ngClass]="{
              warning: confirmationStatus === 'warning',
              alert: confirmationStatus === 'alert',
              claims: confirmationStatus === 'claims',
              verify: confirmationStatus === 'verify'
            }"
          ></span>
          <div>
            <h2
              data-cy="confirmationScreenHeader"
              class="heading-main confirmation-title-text"
              [class.darkTitle]="confirmationStatus === 'verify'"
              [innerHTML]="confirmationHeading"
            ></h2>
            @if (confirmationSubHeading) {
              <span
                data-cy="confirmationScreenSubHeader"
                class="confirmation-title-subtext"
                [innerHTML]="confirmationSubHeading"
              ></span>
            }
          </div>
        </div>
        <!--AS: New subheading to be displayed incase of multiautopay success-->
        @if (!!headerInfoText) {
          <div
            [ngClass]="
              confirmationStatus === 'verify' ? 'info-text-message-new' : 'info-text-message'
            "
          >
            <p [innerHTML]="headerInfoText"></p>
          </div>
        }
        <!--AS: Use the details object to build the rows-->
        <ng-content></ng-content>
        @if (successDetailsList) {
          <ds-confirmation-details [details]="successDetailsList"></ds-confirmation-details>
        }
      </div>
    }

    @if (
      confirmationData?.nextSteps &&
      (confirmationData?.nextSteps?.heading || confirmationData?.nextSteps?.text)
    ) {
      <ds-confirmation-next-steps
        [nextSteps]="confirmationData.nextSteps"
      ></ds-confirmation-next-steps>
    }
    @if (vehicleName) {
      <div>
        <div class="display-policy">
          <div class="mrg-100 mrg-rgt">{{ vehicleAddedText }}</div>
          <div class="print-data">{{ vehicleName }}</div>
        </div>
        <div class="display-policy mrg-bottom42">
          <div class="mrg-100">{{ policyNumberText }}</div>
          <div class="print-data">{{ policyNumber | prettyPolicyNum }}</div>
        </div>
        <ds-amfam-dsn-alert
          [icon]="true"
          [type]="'info'"
          [message]="alertMessage"
          [title]="title"
        ></ds-amfam-dsn-alert>
      </div>
    }
    <ng-content select="ds-confirmation-card-area"></ng-content>

    @if (confirmationData?.disclaimer) {
      <ds-confirmation-disclaimer
        [disclaimer]="confirmationData.disclaimer"
      ></ds-confirmation-disclaimer>
    }

    @if (confirmationData?.ctaArea) {
      <ds-confirmation-cta-area [ctaArea]="confirmationData.ctaArea"></ds-confirmation-cta-area>
    }
    <ng-content select="ds-confirmation-link-area" class="font-weight-semibold"></ng-content>
  </div>
</div>
