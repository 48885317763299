// Angular
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get as _get } from 'lodash';
// rxjs
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

// Misc
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
// Services
import { ConfigService } from '@amfam/shared/utility/shared-services';

import { AddVehicleFeaturesResponseModel, Vehicle } from '../models/vehicle-features.model';
// Models
import {
  GetMakesPayload,
  GetModelsPayload,
  GetStylesPayload,
  GetVehicleMakesApiResponse,
  LookupVinYearPayload
} from '../models/vehicle.models';
import { VehicleFeaturesAdapterService } from './vehicle-features-adapter.service';

@Injectable({ providedIn: 'root' })
export class VehicleService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private vehicleFeaturesAdapterService: VehicleFeaturesAdapterService,
    private featureFlag: FeatureFlagService
  ) {}

  getVehicleByVinAndYear(payload: LookupVinYearPayload) {
    const uri = this.config.get('vehicleApi') + 'vehicles/' + payload.vin;
    const params: HttpParams = new HttpParams()
      .set('year', payload.year)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get(uri, { params }).pipe(
      map((json: AddVehicleFeaturesResponseModel) => {
        const vehicle: Vehicle = _get(json, 'matchedVehicles.automobiles[0].vehicle');
        const features = this.vehicleFeaturesAdapterService.adapt(json)?.features;

        if (vehicle && !!vehicle.manufacturersSuggestedRetailPrice) {
          return {
            newVehicle: {
              year: vehicle.year,
              vin: payload.vin,
              make: vehicle.make,
              model: vehicle.model,
              series: vehicle.series,
              msrp: vehicle.manufacturersSuggestedRetailPrice,
              estimatedValue: vehicle.manufacturersSuggestedRetailPrice
            },
            features: features
          };
        } else {
          if (this.featureFlag.isEnabled('msrp')) return null;
          else
            throw new Error(
              'We’re not currently able to give a quote for this vehicle. To get a quote, please contact your agent.'
            );
        }
      })
    );
  }

  getVehicleMakes(payload: GetMakesPayload): Observable<GetVehicleMakesApiResponse> {
    const uri = this.config.get('vehicleApi') + 'makes';
    const params: HttpParams = new HttpParams()
      .set('year', payload.year)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get<GetVehicleMakesApiResponse>(uri, { params });
  }

  getVehicleModels(payload: GetModelsPayload) {
    const uri = this.config.get('vehicleApi') + 'models';
    const params: HttpParams = new HttpParams()
      .set('year', payload.year)
      .set('make', payload.make)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get(uri, { params });
  }

  getVehicleStyles(payload: GetStylesPayload) {
    const uri = this.config.get('vehicleApi') + 'series';
    const params: HttpParams = new HttpParams()
      .set('year', payload.year)
      .set('make', payload.make)
      .set('model', payload.model)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get(uri, { params }).pipe(
      catchError(error => {
        if (_get(error, 'status.code') === 404) {
          return of([]);
        }
        return throwError(error);
      })
    );
  }

  getVehicles(year: string, make: string, model: string) {
    const uri = this.config.get('vehicleApi') + 'vehicles';
    const params: HttpParams = new HttpParams()
      .set('year', year)
      .set('make', make)
      .set('model', model)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get(uri, { params }).pipe(
      catchError(error => {
        if (_get(error, 'status.code') === 404) {
          return of(null);
        }
        return throwError(error);
      })
    );
  }

  getVehicleById(year: string, make: string, model: string, id: string) {
    const uri = `${this.config.get('vehicleApi')}vehicles/${id}`;
    const params: HttpParams = new HttpParams()
      .set('year', year)
      .set('make', make)
      .set('model', model)
      .set('typeOfVehicleCode', 'automobile');
    return this.http.get(uri, { params }).pipe(
      catchError(error => {
        if (_get(error, 'status.code') === 404) {
          return of(null);
        }
        return throwError(error);
      })
    );
  }

  getVehicleByAll(year: string, make: string, model: string, series?: string) {
    if (series && String(series).toLowerCase() === 'other') series = null;
    return this.getVehicles(year, make, model).pipe(
      map(vehicles => {
        let filteredVehicles = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        vehicles = (vehicles as any)?.automobiles.automobile;
        if (vehicles) {
          if (Array.isArray(vehicles)) {
            if (series)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              filteredVehicles = (vehicles as any[]).filter(
                vehicle => String(vehicle.series).toUpperCase() === String(series).toUpperCase()
              );
            else filteredVehicles = vehicles;
            const selectedVehicle = this.getHighestPatternPercentVehicle(filteredVehicles);
            return selectedVehicle?.id;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } else return (vehicles as any).id;
        }
        return null;
      }),
      switchMap(id => (!id ? null : this.getVehicleById(year, make, model, id))),
      catchError(() => of(null))
    );
  }

  getHighestPatternPercentVehicle(vehicles) {
    return vehicles.reduce((max, vehicle) =>
      Number(max.patternPercentage) > Number(vehicle.patternPercentage) ? max : vehicle
    );
  }
}
