@for (column of columns; track column) {
  <ds-table-body-cell
    [ngClass]="{ 'hide-on-mobile': column.hideOnMobile, 'cell-header': column.isHeaderOnMobile }"
    class="{{ tableClasses }}"
    [column]="column"
    [row]="row"
  >
  </ds-table-body-cell>
}
<div class="datatable-row-detail">
  @if (rowDetail && row.accountReference.displayName === 'Bundled Payment') {
    <ng-template [ngTemplateOutlet]="rowDetail" [ngTemplateOutletContext]="{ row: row }">
    </ng-template>
  }
</div>
