import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PolicyTypeIconConstants } from '@amfam/policy/models';

import { AutoPayBillAccountTile } from './auto-pay-bill-account-tile.model';

@Component({
  selector: 'ds-auto-pay-setup-bill-account-tile',
  templateUrl: './auto-pay-setup-bill-account-tile.component.html',
  styleUrls: ['./auto-pay-setup-bill-account-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoPaySetupBillAccountTileComponent {
  @Input() billAccountTile: AutoPayBillAccountTile;
  @Input() policyTypeIcon: PolicyTypeIconConstants;

  constructor() {}
}
