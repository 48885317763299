import { createAction } from '@ngrx/store';

import { PolicyDocument, PolicySummary } from '@amfam/policy/models';

export enum DocumentsActionTypes {
  LoadDocumentsType = '[Documents] Load Documents',
  LoadDocumentsSuccessType = '[Documents] Load Success',
  LoadDocumentsCompleteType = '[Documents] Documents Load Complete',
  LoadDocumentsErrorType = '[Documents] Documents Load Error',
  DocumentViewType = '[Documents] View',
  DocumentDismissNotificationType = '[Documents] Dismiss Notification'
}

export const LoadDocuments = createAction(
  DocumentsActionTypes.LoadDocumentsType,
  (payload: PolicySummary[]) => ({ payload })
);

export const LoadDocumentsSuccess = createAction(
  DocumentsActionTypes.LoadDocumentsSuccessType,
  (payload: PolicyDocument[]) => ({ payload })
);

export const LoadDocumentsError = createAction(
  DocumentsActionTypes.LoadDocumentsErrorType,
  (payload?: unknown) => ({ payload })
);

export const LoadDocumentsComplete = createAction(
  DocumentsActionTypes.LoadDocumentsCompleteType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload?: any) => ({ payload })
);

export const ViewAction = createAction(
  DocumentsActionTypes.DocumentViewType,
  (payload: string) => ({ payload })
);

export const DismissNotificationAction = createAction(
  DocumentsActionTypes.DocumentDismissNotificationType,
  (payload: string) => ({ payload })
);

export type DocumentsAction =
  | ReturnType<typeof LoadDocuments>
  | ReturnType<typeof LoadDocumentsComplete>
  | ReturnType<typeof LoadDocumentsError>
  | ReturnType<typeof LoadDocumentsSuccess>
  | ReturnType<typeof ViewAction>
  | ReturnType<typeof DismissNotificationAction>;

export const fromDocumentsActions = {
  LoadDocuments,
  LoadDocumentsComplete,
  LoadDocumentsError,
  LoadDocumentsSuccess,
  ViewAction,
  DismissNotificationAction
};
