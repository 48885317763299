import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { PartyPhone } from '@amfam/shared/models';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { ButtonAnalytic } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-phone-list-item',
  templateUrl: './phone-list-item.component.html',
  styleUrls: ['./phone-list-item.component.scss']
})
export class PhoneListItemComponent {
  static readonly makePrimaryPhoneButton: ButtonAnalytic = {
    link: 'MyAccount:Profile:MakePrimaryPhone'
  };
  @Input() phone: PartyPhone;

  // passes up id of phone entry to delete
  @Output() deleteEvent = new EventEmitter<PartyPhone>();
  // Make the phone number primary
  @Output() makePrimaryEvent = new EventEmitter<PartyPhone>();

  constructor(
    public roleService: ImpersonateRolesService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  makePrimary() {
    // MakePrimary Analytics
    this.analyticsFacade.trackButtonClick(PhoneListItemComponent.makePrimaryPhoneButton);

    this.makePrimaryEvent.emit(this.phone);
  }

  deletePhone() {
    this.deleteEvent.emit(this.phone);
  }
}
