<div class="input-group column twelve">
  <div class="form-group" [ngClass]="{ 'has-error': errorMessage }">
    <div #inputWrapper [class]="inputWrapperClass">
      <ng-content></ng-content>
      @if (labelText || labelTextContentPath) {
        <label
          #labelCtrl
          [attr.for]="inputIdAttr"
          [class]="labelClass"
          [ngClass]="{ floating: isFloating }"
          class="control-label"
          >{{ labelText ?? copyService.getCopy(labelTextContentPath) }}</label
        >
      }
    </div>
  </div>
</div>
