import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

// Store
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';

import * as fromRoot from '../../core/store';

@Injectable()
export class MaintenanceGuard {
  constructor(
    private featureFlag: FeatureFlagService,
    private store: Store<fromRoot.RootState>
  ) {}

  canActivate() {
    if (!!this.featureFlag.isEnabled('global_outage')) {
      this.store.dispatch(
        fromRouterActions.Go({
          path: ['/outage']
        })
      );
      return false;
    }
    if (this.featureFlag.isEnabled('maintenance_redirect')) {
      this.sendToMaintenancePage();
    }
    return true;
  }

  private sendToMaintenancePage() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/maintenance']
      })
    );
    return false;
  }
}
