import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CopyService } from '@amfam/shared/utility/shared-services';
import { ValidationService } from '@amfam/ui-kit';

import { Magic3Model } from './magic3.model';

@Component({
  selector: 'ds-magic3-search',
  templateUrl: './magic3-search.component.html',
  styleUrls: ['./magic3-search.component.scss']
})
export class Magic3SearchComponent implements OnInit {
  @Output() submittedFormEvent = new EventEmitter();
  @Output() goBackEvent = new EventEmitter();
  @Input() showBackButton: boolean;
  @Input() searchErrorMessage: string;

  form: UntypedFormGroup;
  formLabeltext: Magic3Model;

  private lastNameInput: AbstractControl;
  private dobInput: AbstractControl;
  private zipcodeInput: AbstractControl;

  constructor(
    private copyService: CopyService,
    private formBuilder: UntypedFormBuilder
  ) {}

  onSubmit() {
    const magic3: Magic3Model = {
      lastName: this.lastNameInput.value,
      dob: this.dobInput.value,
      zipcode: this.zipcodeInput.value
    };
    this.submittedFormEvent.emit(magic3);
  }

  goBackOneStep() {
    this.goBackEvent.emit();
  }

  ngOnInit() {
    this.buildForm();
    const { inputFieldLabelText: shared } = this.copyService.getComponentData('shared');
    this.formLabeltext = {
      lastName: shared.lastName,
      dob: shared.dateOfBirth,
      zipcode: shared.zipCode
    };
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      lastName: [
        '',
        [
          ValidationService.lastNameEntryValidator,
          ValidationService.lastNameAdditionalValidator,
          ValidationService.specialCharValidator,
          ValidationService.nolastNameValidatior
        ]
      ],
      zipcode: [
        '',
        [Validators.required, Validators.maxLength(5), ValidationService.zipCodeNumbersAndLength]
      ],
      dob: [
        '',
        [
          Validators.required,
          ValidationService.mmDdYyyyValidDateValidator,
          ValidationService.mmDdYyyyDateValidator,
          ValidationService.mmDdYyyyPastThruTodayDateRangeValidator
        ]
      ]
    });
    this.lastNameInput = this.form.controls['lastName'];
    this.dobInput = this.form.controls['dob'];
    this.zipcodeInput = this.form.controls['zipcode'];
  }
}
