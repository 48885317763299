import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { EligibleServicesState } from './eligible-services/eligible-services.reducer';
/** REDUCERS */
import * as fromEligibleService from './eligible-services/eligible-services.reducer';
import { RentalServiceState } from './rental-service/rental-service.reducer';
import * as fromRentalService from './rental-service/rental-service.reducer';
import { RepairServiceState } from './repair-service/repair-service.reducer';
import * as fromRepairService from './repair-service/repair-service.reducer';

export const CLAIMSERVICES_FEATURE_KEY = 'claimServices';

export interface ClaimServicesState {
  eligibleServices: EligibleServicesState;
  repairService: RepairServiceState;
  rentalService: RentalServiceState;
}

export const reducers: ActionReducerMap<ClaimServicesState> = {
  eligibleServices: fromEligibleService.eligibleServicesReducer,
  repairService: fromRepairService.repairServiceReducer,
  rentalService: fromRentalService.rentalServiceReducer
};

// Lookup the 'EligibleServices' feature state managed by NgRx
export const getClaimServicesState =
  createFeatureSelector<ClaimServicesState>(CLAIMSERVICES_FEATURE_KEY);
