@for (tableDetailObj of details; track tableDetailObj) {
  <div class="confirmation-details">
    @if (tableDetailObj.tableRows?.length > 0) {
      <h2
        class="confirmation-details-title margin-bottom-0"
        [innerHTML]="tableDetailObj.heading"
      ></h2>
    }
    @if (tableDetailObj.tableRows?.length > 0) {
      <ds-table
        class="block md-none"
        [rows]="tableDetailObj.tableRows"
        [columns]="tableDetailObj.tableColumns"
        [headerHeight]="60"
        [tableClasses]="'action-row'"
      >
      </ds-table>
    }
    @if (tableDetailObj.tableRows?.length > 0) {
      <ngx-datatable
        class="none md-block"
        [ngClass]="'material expandable noshadow'"
        [cssClasses]="{
          pagerLeftArrow: 'icon-chevron-left',
          pagerRightArrow: 'icon-chevron-right',
          pagerPrevious: 'icon-arrow-left-double',
          pagerNext: 'icon-arrow-right-double'
        }"
        [rows]="tableDetailObj.tableRows"
        [columns]="tableDetailObj.tableColumns"
        [columnMode]="'force'"
        [headerHeight]="60"
        [rowHeight]="'auto'"
        [selectionType]="''"
        [limit]="6"
        [footerHeight]="''"
      >
      </ngx-datatable>
    }
  </div>
}
