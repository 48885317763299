// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get as _get, has as _has } from 'lodash';
// rxjs
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Services
import { ConfigService } from '@amfam/shared/utility/shared-services';

// TODO update import
import {
  CreateDraftClaimResponse,
  DraftClaim,
  FnolConfirmation,
  SubmitDraftClaimResponse
} from '../models';
// TODO update import
import { SubmitDraftClaimAdapter } from './claim-fnol.adapter';

@Injectable({ providedIn: 'root' })
export class ClaimFnolService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private submitDraftClaimAdapter: SubmitDraftClaimAdapter
  ) {}

  public getDraftClaimDetail(draftClaimNumber: string): Observable<any> {
    return this.http.get(this.config.get('claimApi') + 'fnol/' + draftClaimNumber).pipe(
      map((json: any) => {
        // Replace dashes in the claim number
        if (_has(json, 'fnol.claimNumber')) {
          json.fnol.claimNumber = json.fnol.claimNumber.replace(/[-]/g, '');
        }
        return json;
      })
    );
  }

  public createDraftClaim(requestObj: DraftClaim): Observable<CreateDraftClaimResponse> {
    const reqObj = {
      fnol: {
        lossDate: requestObj.lossDate,
        policyNumber: requestObj.policyNumber,
        insured: requestObj.insured,
        fnolDetail: _get(requestObj, 'fnolDetail.typeOfLossCauseCode', null)
          ? _get(requestObj, 'fnolDetail', null)
          : {},
        lossLocationAddress: _get(requestObj, 'lossLocationAddress.state', null)
          ? _get(requestObj, 'lossLocationAddress', {})
          : {},
        autoClaimDetail: requestObj.autoClaimDetail
      }
    };
    return this.http.post<CreateDraftClaimResponse>(this.config.get('claimApi') + 'fnol', reqObj);
  }

  /**
   * AS:
   * @param requestObj : Claim object values which needs to be updated for the specific draft number
   * @function updateDraftClaim: Function to update the details of the draft claim.
   */
  public updateDraftClaim(requestObj: DraftClaim): Observable<any> {
    return this.http.put(
      this.config.get('claimApi') + 'fnol' + '/' + requestObj.fnol.claimNumber,
      requestObj
    );
  }

  public submitDraftClaim(requestObj: DraftClaim): Observable<FnolConfirmation> {
    return this.http
      .post<SubmitDraftClaimResponse>(
        this.config.get('claimApi') + 'fnol/' + requestObj.fnol.claimNumber + '/submitclaim',
        requestObj
      )
      .pipe(
        map(draftClaimResponse => {
          const res = this.submitDraftClaimAdapter.adapt(requestObj, draftClaimResponse);
          return res;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public deleteDraftClaim(claimNumber: string): Observable<any> {
    return this.http.delete(this.config.get('claimApi') + 'fnol/' + claimNumber);
  }
}
