import {
  CreditCardType,
  PaymentMethodAccountType,
  PaymentMethodAccountUseType,
  PaymentMethodFinancialType
} from '@amfam/billing/payment-method/util';
import { ApiStatus } from '@amfam/shared/models';

export interface SavePaymentMethodPayload {
  financialType: PaymentMethodFinancialType;
  nickName: string;
  // For Bank Accounts
  bankAccount?: {
    routingNumber: string;
    accountNumber: string;
    accountType: PaymentMethodAccountType;
    accountUse: PaymentMethodAccountUseType;
  };
  // For Credit Cards
  creditCard?: {
    cardNumber: string;
    cardType: CreditCardType;
    expirationDate: string;
  };
  consumerKey: string;
}

export interface SavePaymentMethodResponse {
  finAcctServiceResponse: {
    apiStatus: ApiStatus;
    consumerKey: string;
    tokenId: string;
  };
}

export interface GetPCITokenPayload {
  consumerKey: string;
  destinationName: string;
}

export interface GetPCIAuthorizationTokenResponse {
  pciToken: string;
  status: ApiStatus;
}

export interface GetFinancialInstitutionPayload {
  routingNumber: string;
}

export interface GetFinancialInstitutionResponse {
  finAcctServiceResponse: {
    apiStatus: ApiStatus;
    consumerKey: string;
    tokenId?: string;
    financialInstitute?: FinancialInstitution;
  };
}

export interface FinancialInstitution {
  FinanacialInstitutionName: string;
  routingNumber: string;
  addressLines: string[];
  isActive: string;
}
