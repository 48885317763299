import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';

import { userQuery } from '@amfam/shared/user';
import {
  Applications,
  ApplicationService,
  ConfigService
} from '@amfam/shared/utility/shared-services';

@Injectable({ providedIn: 'root' })
export class FinancialAccountService {
  private authToken: string;

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private appService: ApplicationService,
    private store: Store
  ) {}

  public getAuthorizationToken(): Observable<object> {
    let CustkeyToken = '';
    this.store
      .select(userQuery.selectCUSTKEY)
      .pipe(take(1))
      .subscribe(token => (CustkeyToken = token));
    const uri: string = this.config.get('pciAuthorizationService') + 'create';
    const payload = {
      destinationName: 'FinancialAccountService',
      consumerKey: this.config.get('finAcctConsumerKey')
    };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + CustkeyToken
      })
    };
    return this.http.post(uri, payload, httpOptions);
  }

  public setAuthorizationToken(pciToken: string) {
    this.authToken = pciToken;
  }

  public saveFinancialAccount(payload: unknown): Observable<object> {
    /**
     * AS: Do not add the headers if we do not have an auth token
     */
    if (this.appService.isApp(Applications.MYACCOUNT_ADMIN)) {
      const uri: string = this.config.get('financialAccountServiceInternal') + 'financialaccounts';
      return this.http.post(uri, payload);
    } else {
      const uri: string = this.config.get('financialAccountService') + 'financialaccounts';
      return this.http.post(uri, payload, this.getFinAcctHeaders());
    }
  }

  public getFinancialInstitution(routingNumber: string): Observable<object> {
    /**
     * AS: Do not add the headers if we do not have an auth token
     */
    if (this.appService.isApp(Applications.MYACCOUNT_ADMIN)) {
      const uri =
        this.config.get('financialAccountServiceInternal') +
        'financialinstitutions/' +
        routingNumber +
        '?consumerKey=' +
        this.config.get('finAcctConsumerKey');
      return this.http.get(uri);
    } else {
      const uri =
        this.config.get('financialAccountService') +
        'financialinstitutions/' +
        routingNumber +
        '?consumerKey=' +
        this.config.get('finAcctConsumerKey');
      return this.http.get(uri, this.getFinAcctHeaders());
    }
  }

  private getFinAcctHeaders() {
    return {
      headers: new HttpHeaders({
        Authorization: this.authToken
      })
    };
  }
}
