import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DeliveryPreferences } from '@amfam/shared/models';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';

@Component({
  selector: 'ds-policies-communication-preferences',
  templateUrl: './policies-communication-preferences.component.html',
  styleUrls: ['../communication-preferences/communication-preferences.component.scss']
})
export class PoliciesCommunicationPreferencesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() paperlessPolicy: boolean;
  @Input() clearWarning: number;
  @Input() adminPartnerDisabled: boolean | undefined;
  @Output() policyPreferencesChangeEvent = new EventEmitter<string>();
  policiesPreferencesForm: UntypedFormGroup;
  preferenceCtrl: AbstractControl;
  enableWarningComponent = false;

  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public roleService: ImpersonateRolesService
  ) {}

  ngOnInit() {
    this.policiesPreferencesForm = this.formBuilder.group({
      policiesPreferenceCtrl: [this.paperlessPolicy.toString() || '']
    });
    this.policiesPreferencesForm.valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      if (value) {
        this.enableWarningComponent = false;

        /**
         * We track changed preference and emit to parent component
         * @variable newPolicyPreference return empty string when preference is changed to original
         * @example - email to paper to email -> newPolicyPreference return empty string. We know there is no change to original preference.
         */
        let newPolicyPreference = '';
        const selectedPreference = value.policiesPreferenceCtrl === 'true';
        if (selectedPreference !== this.paperlessPolicy) {
          if (selectedPreference) {
            newPolicyPreference = DeliveryPreferences.EMAIL;
          } else {
            // show warning message when preference is changed to paper.
            this.enableWarningComponent = true;
            newPolicyPreference = DeliveryPreferences.PAPER;
          }
        }
        this.policyPreferencesChangeEvent.emit(newPolicyPreference);
      }
    });
  }

  ngOnChanges() {
    /**
     * When changing to email in GA it loads docusign and
     * when entering back into the app from docusign, this component
     * loads before the value of paperlessPolicy is updated in the store (goPaperlessState.isEnrolled).
     * Adding this line here will update that value after it changes to reflect the change in the component.
     */

    if (this.policiesPreferencesForm) {
      this.policiesPreferencesForm.controls['policiesPreferenceCtrl'].setValue(
        this.paperlessPolicy.toString()
      );
    }

    if (this.clearWarning) {
      this.enableWarningComponent = false;
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
