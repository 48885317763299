import { CopyService } from '@amfam/shared/utility/shared-services';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-rome-bill-payment-faq-modal',
  templateUrl: './rome-bill-payment-faq-modal.component.html',
  styleUrls: ['./rome-bill-payment-faq-modal.component.scss']
})
export class RomeBillPaymentFAQModalComponent {
  @Input()
  id: string;

  heading = this.copyService.getCopy('billing.simplifiedPayment.romeBillingModal.heading');
  questionsAndAnswers = this.copyService.getCopy(
    'billing.simplifiedPayment.romeBillingModal.questionsAndAnswers'
  );
  alerts = this.copyService.getCopy('billing.simplifiedPayment.romeBillingModal.alerts');

  constructor(private copyService: CopyService) {}
}
