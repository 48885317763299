import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GoPaperlessEffects } from './+state/go-paperless.effects';
import { GO_PAPERLESS_FEATURE_KEY, goPaperLessReducers } from './+state/go-paperless.selectors';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([GoPaperlessEffects]),
    StoreModule.forFeature(GO_PAPERLESS_FEATURE_KEY, goPaperLessReducers)
  ],
  exports: []
})
export class PolicyGoPaperlessDataAccessModule {}
