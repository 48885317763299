import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'free-form-question',
  templateUrl: './free-form-question.component.html',
  styleUrls: ['./free-form-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeFormQuestionComponent implements OnInit {
  @Input() selectedAnswer: string;
  @Input() placeHolder: string;
  @Input() maxLength: number;
  @Input() displayBackButton: boolean = false;
  @Output() submitQuestion = new EventEmitter<string>();
  @Output() cancelEvent = new EventEmitter();
  @Output() goBackEvent = new EventEmitter();

  freeFormTextGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.freeFormTextGroup = this.formBuilder.group({
      description: [{ value: '', disabled: false }]
    });

    this.freeFormTextGroup.controls['description'].setValidators([
      Validators.required,
      Validators.maxLength(this.maxLength)
    ]);

    if (this.selectedAnswer) {
      this.freeFormTextGroup.controls['description'].setValue(this.selectedAnswer);
    }
  }

  onFreeTextSubmit(selectedOption: string) {
    this.submitQuestion.emit(selectedOption);
    this.freeFormTextGroup.reset();
  }

  cancel() {
    this.cancelEvent.emit();
  }

  goBack() {
    this.goBackEvent.emit();
    this.freeFormTextGroup.reset();
  }
}
