import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { fromRouterActions } from '@amfam/shared/utility/navigation';

import { AuthService } from '../../../core';
import * as fromRoot from '../../../core/store';

@Injectable()
export class ForgotPasswordGuard {
  constructor(
    private authService: AuthService,
    private store: Store<fromRoot.RootState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      this.store.dispatch(
        fromRouterActions.Go({
          path: ['/overview']
        })
      );
      return false;
    }

    return true;
  }
}
