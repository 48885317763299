import { Component, Input, OnInit } from '@angular/core';

import { ErrorObj } from '../models/error-model';

@Component({
  selector: 'ds-error-message',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  @Input() errorList: Array<ErrorObj>;

  constructor() {}

  ngOnInit() {}
}
