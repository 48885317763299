import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

export interface RouterState extends RouterReducerState<RouterStateUrl> {
  previousState: RouterStateUrl;
}

export const initialRouterState: RouterState = {
  state: null,
  previousState: null,
  navigationId: null
};
