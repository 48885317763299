import { Dictionary } from '@ngrx/entity';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get, isEmpty as _isEmpty, map as _map } from 'lodash';

import { Policy, PolicyDocument } from '@amfam/policy/models';

import { POLICIES_FEATURE_KEY } from '../policies/policies.reducer';
import { PoliciesState, selectPolicies } from '../policies/policies.selectors';
import * as fromDocumentsState from './documents.reducer';
import * as fromDocumentsNotifications from './notifications.reducer';

export interface DocumentsState {
  state: fromDocumentsState.PolicyDocumentsEntityState;
  notifications: fromDocumentsNotifications.NotificationState;
}

export const documentsReducers: ActionReducerMap<DocumentsState> = {
  state: fromDocumentsState.reducer,
  notifications: fromDocumentsNotifications.reducer
};

// Lookup the 'Documents' feature state managed by NgRx
const selectDocumentsState = createFeatureSelector<PoliciesState>(POLICIES_FEATURE_KEY);

const selectLoaded = createSelector(
  selectDocumentsState,
  (state: PoliciesState) => !_get(state, 'documentsNotifications.loading')
);

const selectError = createSelector(selectDocumentsState, (state: PoliciesState) =>
  _get(state, 'documentsNotifications.error')
);

const selectAllDocuments = createSelector(
  selectDocumentsState,
  selectLoaded,
  (state: PoliciesState, isLoaded) =>
    isLoaded ? fromDocumentsState.selectAll(state.documentsState) : []
);

export const selectPolicyDocumentEntities = createSelector(
  selectDocumentsState,
  selectLoaded,
  (state: PoliciesState, isLoaded): Dictionary<PolicyDocument> => {
    if (isLoaded) {
      return fromDocumentsState.selectEntities(state.documentsState);
    }
  }
);

export const selectPolicyDocumentIds = createSelector(
  selectDocumentsState,
  selectLoaded,
  (state: PoliciesState, isLoaded): number[] => {
    if (isLoaded) {
      return <number[]>fromDocumentsState.selectIds(state.documentsState);
    }
    return [];
  }
);

export const selectPolicyDocumentNotifications = createSelector(
  selectPolicyDocumentEntities,
  selectPolicies,
  (entities, policies: Policy[]) => {
    if (_isEmpty(entities)) {
      return false;
    }
    const notificationsObj = {};
    let notifications: object[] | boolean;
    // Build list of notifications
    for (const doc of Object.values(entities)) {
      const docPolicyNumber = (doc.policyNumber || '').replace(/[^a-zA-Z0-9]/g, '');
      if (doc.isNew && policies.length) {
        if (notificationsObj[docPolicyNumber]) {
          notificationsObj[docPolicyNumber].number += 1;
        } else {
          const policyType = policies.filter(policy => policy.policyNumber === docPolicyNumber)[0]
            .policyType;
          notificationsObj[docPolicyNumber] = { number: 1, policyType: policyType, ids: [] };
        }
        notificationsObj[docPolicyNumber].ids.push(doc.id);
      }
    }
    // Return false if no notifications, else create array from object
    if (!Object.keys(notificationsObj).length) {
      notifications = false;
    } else {
      notifications = _map(notificationsObj, (notification, policyNum) =>
        Object.assign({}, notification, { policyNumber: policyNum })
      );
    }
    return notifications;
  }
);

export const selectPolicyDocumentsLoading = createSelector(
  selectDocumentsState,
  (state: PoliciesState) => _get(state, 'documentsNotifications.loading')
);

export const selectPolicyDocumentsLoaded = createSelector(
  selectDocumentsState,
  (state: PoliciesState) => !_get(state, 'documentsNotifications.loading')
);

export const selectPolicyDocuments = createSelector(
  selectPolicyDocumentEntities,
  selectPolicyDocumentIds,
  (entities, ids) => ids.map(id => entities[id])
);

export const selectPolicyDocumentsWithState = createSelector(
  selectPolicyDocuments,
  selectPolicyDocumentsLoading,
  (policyDocuments, policyDocumentsLoading) => ({
    policyDocuments: policyDocuments,
    policyDocumentsLoading: policyDocumentsLoading
  })
);

export const documentsQuery = {
  selectLoaded,
  selectError,
  selectAllDocuments,
  selectPolicyDocumentsWithState,
  selectPolicyDocuments,
  selectPolicyDocumentsLoaded,
  selectPolicyDocumentsLoading,
  selectPolicyDocumentIds,
  selectPolicyDocumentEntities,
  selectPolicyDocumentNotifications
};
