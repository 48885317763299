import { Action } from '@ngrx/store';

import { UserLogin } from '@amfam/shared/user';

import { DeviceInformation } from './session.model';

export const SESSION_LOAD = '[Session] Load Session';
export const SESSION_LOAD_SUCCESS = '[Session] Load Session Success';
export const LOGIN_USER = '[Session] Login User';
export const LOGIN_USER_SUCCESS = '[Session] Login User Success';
export const LOGIN_USER_FAIL = '[Session] Login User Fail';
export const REFRESH_USER = '[Session] Refresh User';
export const REFRESH_USER_SUCCESS = '[Session] Refresh User Success';
export const REFRESH_USER_FAIL = '[Session] Refresh User Fail';
export const REFRESH_USER_CANCELLED = '[Session] Refresh User Cancelled';
export const LOGOUT_USER = '[Session] Logout User';
export const EDIT_USERID = '[Session] Edit User Id';
export const SET_TIME_OFFSET = '[Session] Set Time Offset';

// Before Load Account
export const BEFORE_LOAD_ACCOUNT = '[Session] Before Load Account';
export const BEFORE_LOAD_ACCOUNT_COMPLETE = '[Session] Before Load Account Complete';

// Load Account
export const LOAD_ACCOUNT = '[Session] Load Account';

export interface Credentials {
  username: string;
  password: string;
}

export class SessionLoadAction implements Action {
  readonly type = SESSION_LOAD;

  constructor() {}
}

export class SessionLoadSuccessAction implements Action {
  readonly type = SESSION_LOAD_SUCCESS;

  constructor(public payload: DeviceInformation) {}
}

export class LoginUserAction implements Action {
  readonly type = LOGIN_USER;

  constructor(public payload: Credentials) {}
}

// TODO do we need this?
export class LoginUserSuccessAction implements Action {
  readonly type = LOGIN_USER_SUCCESS;

  constructor(public payload: UserLogin) {} // payload: {token, profile}
}

export class LoginUserFailAction implements Action {
  readonly type = LOGIN_USER_FAIL;

  constructor(public payload: any) {} // payload: {error}
}

export class RefreshUserAction implements Action {
  readonly type = REFRESH_USER;

  constructor(public payload?: any) {}
}

export class RefreshUserSuccessAction implements Action {
  readonly type = REFRESH_USER_SUCCESS;

  constructor(public payload: any) {} // payload: {error}
}

export class RefreshUserFailAction implements Action {
  readonly type = REFRESH_USER_FAIL;

  constructor(public payload: any) {} // payload: {error}
}

export class RefreshUserCancelledAction implements Action {
  readonly type = REFRESH_USER_CANCELLED;

  constructor(public payload: any) {} // payload: {error}
}

export class LogoutUserAction implements Action {
  readonly type = LOGOUT_USER;

  constructor(public payload?: any) {}
}

export class SetTimeOffsetAction implements Action {
  readonly type = SET_TIME_OFFSET;

  constructor(public payload?: any) {}
}

export class EditUserIdAction implements Action {
  readonly type = EDIT_USERID;

  constructor(public payload: string) {}
}

export class BeforeLoadAccountAction implements Action {
  readonly type = BEFORE_LOAD_ACCOUNT;
  constructor(public payload?: any) {} // payload: {error}
}

export class BeforeLoadAccountCompleteAction implements Action {
  readonly type = BEFORE_LOAD_ACCOUNT_COMPLETE;
  constructor(public payload?: any) {} // payload: {error}
}

export class LoadAccountAction implements Action {
  readonly type = LOAD_ACCOUNT;
  constructor(public payload: UserLogin) {} // payload: {error}
}

export type Actions =
  | SessionLoadAction
  | SessionLoadSuccessAction
  | LoginUserAction
  | LoginUserSuccessAction
  | LoginUserFailAction
  | LogoutUserAction
  | RefreshUserAction
  | RefreshUserFailAction
  | SetTimeOffsetAction
  | EditUserIdAction
  | BeforeLoadAccountAction
  | BeforeLoadAccountCompleteAction
  | LoadAccountAction;
