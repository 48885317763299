import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BillingPaymentMethodDataAccessModule } from '@amfam/billing/payment-method/data-access';
import { BillingPaymentMethodUiModule } from '@amfam/billing/payment-method/ui';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';

import { AddPaymentMethodQuickLinksWrapperComponent } from './add-payment-method-quicklinks-wrapper/add-payment-method-quicklinks-wrapper.component';
import { BankAccountAddEditWrapperComponent } from './bank-account-add-edit-wrapper/bank-account-add-edit-wrapper.component';
import { CreditDebitAddEditWrapperComponent } from './credit-debit-add-edit-wrapper/credit-debit-add-edit-wrapper.component';
import { PaymentMethodConfirmationComponent } from './payment-method-confirmation/payment-method-confirmation.component';
import { PaymentMethodDeleteContainerComponent } from './payment-method-delete-container/payment-method-delete-container.component';
import { PaymentMethodModalComponent } from './payment-method-modal/payment-method-modal.component';

@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
    SharedServicesModule,
    FormsModule,
    ReactiveFormsModule,
    BillingPaymentMethodDataAccessModule,
    BillingPaymentMethodUiModule
  ],
  declarations: [
    CreditDebitAddEditWrapperComponent,
    BankAccountAddEditWrapperComponent,
    PaymentMethodModalComponent,
    AddPaymentMethodQuickLinksWrapperComponent,
    PaymentMethodDeleteContainerComponent,
    PaymentMethodConfirmationComponent
  ],
  exports: [
    CreditDebitAddEditWrapperComponent,
    BankAccountAddEditWrapperComponent,
    PaymentMethodModalComponent,
    AddPaymentMethodQuickLinksWrapperComponent,
    PaymentMethodDeleteContainerComponent,
    PaymentMethodConfirmationComponent
  ]
})
export class BillingPaymentMethodFeatureModule {}
