import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AutoPayConfirmationWrapperComponent } from '../components/auto-pay-confirmation-wrapper/auto-pay-confirmation-wrapper.component';
import { AutoPayReviewWrapperComponent } from '../components/auto-pay-review-wrapper/auto-pay-review-wrapper.component';
import { AutoPaySetupWrapperComponent } from '../components/auto-pay-setup-wrapper/auto-pay-setup-wrapper.component';
import { BillAccountSelectionWrapperComponent } from '../components/bill-account-selection-wrapper/bill-account-selection-wrapper.component';
import { AutoPayWrapperComponent } from '../containers/auto-pay-wrapper/auto-pay-wrapper.component';
import { AutoPayGuard } from './auto-pay.guard';

const routes: Routes = [
  {
    path: '',
    component: AutoPayWrapperComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'select-accounts'
      },
      {
        path: 'select-accounts',
        data: { breadcrumb: 'Autopay Setup' },
        component: BillAccountSelectionWrapperComponent,
        canDeactivate: [AutoPayGuard]
      },
      {
        path: 'setup',
        data: { breadcrumb: 'Autopay Setup' },
        component: AutoPaySetupWrapperComponent,
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        canActivate: [AutoPayGuard],
        canDeactivate: [AutoPayGuard]
      },
      {
        path: 'review',
        data: { breadcrumb: 'Autopay Review' },
        component: AutoPayReviewWrapperComponent,
        canActivate: [AutoPayGuard],
        canDeactivate: [AutoPayGuard]
      },
      {
        path: 'confirmation',
        data: { breadcrumb: 'Confirmation' },
        component: AutoPayConfirmationWrapperComponent,
        canActivate: [AutoPayGuard]
      },
      {
        path: '**',
        redirectTo: 'select-accounts'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [AutoPayGuard],
  exports: [RouterModule]
})
export class AutoPayRoutingModule {}
