import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as BrandSelectors from '../+state/brand.selectors';

@Directive({
  selector: '[dsPartnerGenericHide]'
})
export class PartnerGenericHideDirective implements OnDestroy, OnInit {
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private store: Store
  ) {}

  /**
   *  PartnerGenericHideDirective hides the component if isGenericPartner in Store is true.
   */

  ngOnInit() {
    this.store
      .select(BrandSelectors.selectIsGenericPartner)
      .pipe(takeUntil(this.stop$))
      .subscribe(isGenericPartner => {
        if (isGenericPartner) {
          // remove template from DOM
          this.viewContainer.clear();
        } else {
          // add template to DOM
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
