/* eslint-disable @typescript-eslint/naming-convention */
export const ANALYTICS_ENROLLMENT_TYPE: any = {
  CONVERSION: 'AddPersonalLine',
  MAE: 'mae',
  PERSONAL: 'personal'
};

export class AnalyticsUserType {
  public static cfr = 'Business';
  public static conversion = 'Business Converting to Personal';
  public static customerAfi = 'Personal';
  public static customerPartner = 'Connect';
  public static mae = 'MyAccount Agent Enrollment';
  public static maePartner = 'MyAccount Agent Enrollment – Connect';
  public static cruise = 'Agent POS';
  public static cruisePartner = 'Connect POS';
  public static nonCustomer = 'Non-Customer';
}

// PARAMS
export interface AnalyticOptions {
  page?: PageAnalyticParams;
  event?: EventAnalyticParams;
  button?: ButtonAnalyticParams;
  user?: UserAnalyticParams;
  replaceVehicle?: ReplaceVehicleAnalyticParams;
  coverageChange?: CoverageChangeAnalyticParams;
  claims?: ClaimsAnalyticsParams;
  accountEnrollment?: AccountEnrollment;
  isGA?: boolean;
}

export interface AccountEnrollment {
  accountsenrolled: string;
}

export interface UserAnalyticParams {
  userType: string;
  trackID: string;
  experienceID: string;
  partnerID: string;
}

export interface PageAnalyticParams {
  pageName: string;
  experience: string;
  primaryCategory: string;
  subCategory1: string;
  subCategory2: string;
  subCategory3: string;
  // custom params
  timeframe?: string;
  Number?: string;
  error?: string;
  paymentAmount?: string;
  paymentDate?: string;
}

export interface EventAnalyticParams {
  eventName?: string;
  eventStep?: string;
  eventAmount?: string | Array<string>;
  eventTerm?: string | Array<string>;
  eventCategory?: string;
  replaceVehicle?: {
    agentName?: string;
    referenceNumber?: string;
  };
  changeCoverageInfo?: {
    selection?: string[];
    RRAmount?: string;
  };
  claimConfirmationInfo?: {
    claimNumber?: string;
  };
  event?: string;
  method?: string;
  account_type?: string;
  login_attempt_count?: string;
  login_complete_count?: string;
  signup_attempt_count?: string;
  signup_complete_count?: string;
  signup_failure_count?: string;
  party_key?: string;
  logout_count?: string;
  product_line?: string;
  payment_term?: string;
  payment_type?: string;
  past_due?: boolean;
  form_name?: string;
  quote_id?: string;
  vehicle_make?: string;
  vehicle_model?: string;
  vehicle_year?: string;
}

export interface ButtonAnalyticParams {
  link: string;
}

export interface ReplaceVehicleAnalyticParams {
  agentName: string;
  referenceNumber: string;
}

export interface CoverageChangeAnalyticParams {
  selection: string[];
  RRAmount: string;
}

export interface ClaimsAnalyticsParams {
  claimNumber: string;
}

// DATA LAYER
export interface DataLayer {
  user?: UserDataLayer;
  page?: PageDataLayer;
  event?: EventDataLayer[];
  linkClicked?: LinkDataLayer;
  replaceVehicle?: ReplaceVehicleDataLayer;
  policyCoverageChange?: CoverageChangeDataLayer;
  claims?: ClaimsDataLayer;
  accountEnrollment?: AccountEnrollment;
  payNow?: {
    paymentAmount?: string;
    paymentDate?: string;
  };
}

export interface UserDataLayer {
  profileInfo: {
    userType: string;
    trackID: string;
    experienceID: string;
    partnerID: string;
  };
}

export interface PageDataLayer {
  pageInfo: {
    pageName: string;
    experience: string;
  };
  category: {
    primaryCategory: string;
    subCategory1: string;
    subCategory2: string;
    subCategory3: string;
  };
  attributes: {
    language: string;
  };
  paymnthistory?: {
    timeframe: string;
  };
  payNow?: {
    paymentAmount?: string;
    paymentDate?: string;
  };
}

export interface EventDataLayer {
  eventInfo: {
    eventName: string;
    eventStep: string;
    eventAmount?: string | Array<string>;
    eventTerm?: string | Array<string>;
  };
}

export interface LinkDataLayer {
  link: string;
}

export interface ReplaceVehicleDataLayer {
  agentName: string;
  referenceNumber: string;
}

export interface CoverageChangeDataLayer {
  selection: string[];
  RRAmount: string;
}

export class ClaimsDataLayer {
  claimNumber: string;
}

export interface DynatraceActionPayload {
  actionName: string;
  actionType: string;
}

export type GTMDataLayerInit = {
  event: string;
  content_language: string | undefined;
  content_group: string | undefined;
  user_id: string | undefined;
  page_name: string | undefined;
  previous_page_name: string | undefined;
  logged_in: string | undefined;
  customer_type: string | undefined;
  zip_code: string | undefined;
  city: string | undefined;
  state: string | undefined;
  dam_number: string | undefined;
};

export enum ACTIONTYPE {
  ACCOUNT_LOCKED = 'AccountLocked',
  INVALID_CREDENTIALS = 'InvalidCredentials',
  FORGOTUSERID = 'ForgotUserId',
  FORGOTPASSWORD = 'ForgotPassword',
  SHELLACCOUNT = 'ShellAccount'
}
