import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '@amfam/shared/utility/shared-services';

@Injectable()
export class BusinessConversionService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) {}

  public convertAccount(waid: string, partyId: string, forgerockId?: string): Observable<any> {
    const digitalAPI = this.configService.get('digitalAccountApi');
    const postURL = this.buildURL(digitalAPI);
    const data = {
      waid: waid,
      customerId: partyId
    };
    let headers: HttpHeaders = new HttpHeaders();
    if (forgerockId) {
      data['forgeRockId'] = forgerockId;
      headers = headers.set('afe-source-id', 'AFI_MyAccount');
    }
    return this.http.post(postURL, JSON.stringify(data), { headers });
  }

  private extractData(body: any) {
    return body.data || {};
  }

  private buildURL(api: string): string {
    return api + '/digitalaccounts/convertuser';
  }
}
