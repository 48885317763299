// Angular
import { Injectable } from '@angular/core';
// Store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { get as _get, has as _has } from 'lodash';
// rxjs
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ClaimActionTypes, fromClaimActions, LoadClaimsSuccess } from '@amfam/claim/data-access';
import { fromEligibleServicesActions } from '@amfam/claim/post-fnol/services-data-access';
// Misc
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';

// Models
import { CreateDraftClaimResponse, DraftClaim } from '../models';
// Services
import { ClaimFnolService } from '../services/claim-fnol.service';
import { ReportClaimFnolService } from '../services/report-claim-fnol.service';
import {
  ClaimFnolActionTypes,
  CreateDraftClaim,
  DeleteDraftClaim,
  fromClaimFnolActions,
  LoadDraftClaimDetail,
  ResumeDraftClaim,
  SubmitDraftClaim,
  SubmitDraftClaimSuccess,
  UpdateDraftClaim
} from './claim-fnol.actions';

@Injectable()
export class ClaimFnolEffects {
  loadDraftClaims$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimActionTypes.LoadClaimsSuccess),
      map((action: LoadClaimsSuccess) => action.payload.fnols),
      map(draftClaimsList => {
        let fnols = [];
        if (
          this.featureFlagService.isEnabled('auto_fnol') ||
          this.featureFlagService.isEnabled('property_fnol')
        ) {
          fnols = draftClaimsList.filter(fnol => !_get(fnol, 'isNewDigitalClaim', true));
        } else {
          fnols = draftClaimsList;
        }
        return new fromClaimFnolActions.LoadDraftClaimsSuccess(fnols);
      })
    )
  );

  loadDraftClaimDetail$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimFnolActionTypes.LoadDraftClaimDetail),
      map((action: LoadDraftClaimDetail) => action.payload),
      switchMap(payload =>
        this.claimFnolService.getDraftClaimDetail(payload).pipe(
          map((response: any) => {
            if (_has(response, 'fnol') && _get(response, 'fnol.claimNumber')) {
              return new fromClaimFnolActions.LoadDraftClaimDetailSuccess(response.fnol);
            } else {
              return new fromClaimFnolActions.LoadDraftClaimDetailFail({
                claimNumber: payload,
                status: {}
              });
            }
          }),
          catchError(error =>
            of(
              new fromClaimFnolActions.LoadDraftClaimDetailFail({
                claimNumber: payload,
                status: error
              })
            )
          )
        )
      )
    )
  );

  draftClaimCreate$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimFnolActionTypes.CreateDraftClaim),
      map((action: CreateDraftClaim) => action.payload),
      switchMap(payload => {
        if (this.reportClaimFnolService.isImpersonating()) {
          return of(new fromClaimFnolActions.CreateDraftClaimFail());
        }
        return this.claimFnolService.createDraftClaim(payload).pipe(
          map((res: CreateDraftClaimResponse) => {
            // Flag to dispatch next action.
            if (payload.submitDraftClaim) {
              return new fromClaimFnolActions.SubmitDraftClaim(payload);
            }
            return new fromClaimFnolActions.CreateDraftClaimSuccess(res);
          }),
          catchError(error => {
            return of(new fromClaimFnolActions.CreateDraftClaimFail(error));
          })
        );
      })
    )
  );

  /** AS: TODO: This logic to be refactored if the PUT operation with an 'unavailable' draft claim number
   * would result in creation of a new draft claim.
   * @param: Payload object which contains the the draft claim object details. Must contain
   * the claimNumber(number/'unavailable).
   * @func: Check if we have a claim number if not then try to create one
   *  1) Success: Use the updated claim number to create a new payload. Which would be sent to the
   *              updateDraftClaim funtion.
   *  2) Failure: In case the create fails for the second/third/fourth/fifth time Ugggh.. just rage quit and go home.
   *             Seriously just stop making it happen and leave. Fine throw an error and let the reducer handle it.
   * If we already have a claim number (Woo hoo!) make an update call with the details.
   */

  draftClaimUpdate$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimFnolActionTypes.UpdateDraftClaim),
      map((action: UpdateDraftClaim) => action.payload),
      switchMap(payload => {
        return this.claimFnolService.updateDraftClaim(payload).pipe(
          map((res: any) => {
            return new fromClaimFnolActions.UpdateDraftClaimSuccess(res);
          }),
          catchError(error => {
            return of(
              new fromClaimFnolActions.UpdateDraftClaimFail({
                claimNumber: payload.fnol.claimNumber,
                status: error
              })
            );
          })
        );
      })
    )
  );

  draftClaimDelete$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimFnolActionTypes.DeleteDraftClaim),
      map((action: DeleteDraftClaim) => action.payload),
      switchMap(payload =>
        this.claimFnolService.deleteDraftClaim(payload.claimNumber).pipe(
          map(res => {
            return new fromClaimFnolActions.DeleteDraftClaimSuccess(
              Object.assign({}, res, { claimNumber: payload.claimNumber })
            );
          }),
          catchError(error => {
            return of(
              new fromClaimFnolActions.DeleteDraftClaimFail({
                claimNumber: payload.claimNumber,
                status: error
              })
            );
          })
        )
      )
    )
  );

  /**
    NT: After submitting an FNOL, the GET /claims?partyIdentifier might not find the new claim right away
    Since that operation is Async in the backend. GET /claims/{ID} is synchronous to the system that
    The claim lives in.
  */

  submitDraftClaim$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimFnolActionTypes.SubmitDraftClaim),
      map((action: SubmitDraftClaim) => action.payload),
      switchMap(payload => {
        let submitDraftClaimPayload: DraftClaim = payload;
        // Create payload to submit draft claim.
        if (payload.submitDraftClaim) {
          submitDraftClaimPayload = this.reportClaimFnolService.createSubmitDraftClaimFactory(
            Object.assign({}, payload)
          );
        }
        return this.claimFnolService.submitDraftClaim(submitDraftClaimPayload).pipe(
          mergeMap((res: any) => {
            // collect actions to dispatch in array
            const submitDraftClaimActions = [
              new fromClaimFnolActions.SubmitDraftClaimSuccess({
                claimNumber: _get(res, 'submittedClaim.claimNumber'),
                fnolClaimNumber: _get(payload, 'fnol.claimNumber'),
                autoClaimDetail: _get(payload, 'fnol.autoClaimDetail')
              })
            ];
            return submitDraftClaimActions;
          }),
          catchError(error => {
            return of(
              new fromClaimFnolActions.SubmitDraftClaimFail({
                claimNumber: _get(payload, 'fnol.claimNumber'),
                status: error
              })
            );
          })
        );
      })
    )
  );

  resumeDraftClaim$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimFnolActionTypes.ResumeDraftClaim),
      map((action: ResumeDraftClaim) => action.payload),
      map(payload => new fromClaimFnolActions.LoadDraftClaimDetail(payload))
    )
  );

  getClaimDataAfterSubmit$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimFnolActionTypes.SubmitDraftClaimSuccess),
      map((action: SubmitDraftClaimSuccess) => action.payload),
      switchMap(payload => {
        const claimNumber = _get(payload, 'claimNumber', '').toString().replace(/-/g, '');
        const autoClaimDetail = _get(payload, 'autoClaimDetail.vehicles[0]', {});
        const vin = _get(payload, 'autoClaimDetail.vehicles[0].vin', '');
        return [
          new fromClaimActions.LoadClaimDetail({ claimNumber }),
          // "creates" the claim in our store so we can access this data elsewhere
          new fromClaimActions.UpdateClaimField({
            claimNumber: claimNumber,
            claimFields: { claimNumber: claimNumber, autoClaimDetails: [autoClaimDetail] }
          }),
          new fromClaimActions.GetExposureId({ vin: vin, claimNumber: claimNumber }),
          new fromEligibleServicesActions.GetEligibleServices({
            claimNumber: claimNumber,
            vin: vin
          }),
          new fromClaimActions.SetSelectedClaim(claimNumber)
        ];
      })
    )
  );

  constructor(
    private action$: Actions,
    private claimFnolService: ClaimFnolService,
    private reportClaimFnolService: ReportClaimFnolService,
    private featureFlagService: FeatureFlagService
  ) {}
}
