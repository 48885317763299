import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

// Store
import { DigitalAccount } from '@amfam/shared/digital-account/data-access';
import { fromUserActions, UserUpdate } from '@amfam/shared/user';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { LoadingSpinnerService, ValidationService } from '@amfam/ui-kit';

import { EditBusinessService } from '../../services/edit-business.service';

@Component({
  selector: 'ds-active-business-email-manager',
  templateUrl: './active-business-email-manager.component.html',
  styleUrls: ['./active-business-email-manager.component.scss']
})
export class ActiveBusinessEmailManagerComponent implements OnInit, OnDestroy {
  @Input() emailAddress: string;
  @Input() headerCopy: string;
  @Input() mainCaptionCopy: string;
  @Output() edit: EventEmitter<boolean> = new EventEmitter();

  public isEditing = false;
  changeEmailForm: UntypedFormGroup;
  emailInUse: boolean;

  private editSub: Subscription;

  constructor(
    private editBusiness: EditBusinessService,
    private spinner: LoadingSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    public roleService: ImpersonateRolesService
  ) {}

  ngOnInit() {
    this.changeEmailForm = this.formBuilder.group({
      emailChange: [
        this.emailAddress,
        [
          Validators.required,
          Validators.maxLength(50),
          ValidationService.emailValidator,
          ValidationService.allowedEmailCharactersValidator
        ]
      ]
    });
  }

  editing(): void {
    this.toggleEdit();
  }

  toggleEdit(): void {
    this.isEditing = !this.isEditing;
    this.edit.emit(this.isEditing);
  }

  save(): void {
    this.emailInUse = false;
    this.spinner.start();
    const newEmail = this.changeEmailForm.get('emailChange').value;
    const digitalAccount: DigitalAccount = {
      emailAddress: newEmail
    };
    this.editSub = this.editBusiness.updateDigitalAccount(digitalAccount).subscribe(
      () => {
        const userUpdate: UserUpdate = {
          emailAddress: newEmail
        };
        this.store.dispatch(fromUserActions.UpdateUser(userUpdate));
        this.toggleEdit();
        this.spinner.stop();
      },
      err => {
        this.spinner.stop();
        this.handleError(err);
      }
    );
  }

  cancel(): void {
    this.resetForm();
    this.toggleEdit();
  }

  removeErrorMessage(): void {
    if (this.emailInUse) {
      this.emailInUse = false;
    }
  }

  ngOnDestroy() {
    if (this.editSub) {
      this.editSub.unsubscribe();
    }
  }

  private resetForm(): void {
    const emailChange = this.changeEmailForm.controls['emailChange'];
    // reset the form control with the initial value and stop editing
    emailChange.reset(this.emailAddress);
  }

  private handleError(err): void {
    const apiMessage = err.status.messages ? err.status.messages[0] : null;
    if (apiMessage && apiMessage.code) {
      const apiCode = apiMessage.code;
      switch (apiCode) {
        case 400001: // Request Missing Info
          break;
        case 400002: // Validation Error
          break;
        case 400003: // Inconsitent Info
          this.emailInUse = true;
          break;
        case 500000:
          break;
      }
    }
  }
}
