import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ValidationService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-verify-policy-num',
  templateUrl: './verify-policy-num.component.html'
})
export class VerifyPolicyNumComponent implements OnInit {
  @Output() policyNumSubmittedEvent = new EventEmitter();

  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.loadPolicyNumForm();
  }

  // Emits input policy number to parent component
  submitPolicyNum(policyNum: string) {
    this.policyNumSubmittedEvent.emit(policyNum);
  }

  private loadPolicyNumForm() {
    this.form = this.formBuilder.group({
      policyNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          ValidationService.policyNumberValidator
        ])
      ]
    });
  }
}
