import { Action } from '@ngrx/store';

import {
  ExposureData,
  ExposureIdReqData,
  LoadClaimDetailPayload,
  LoadClaimDetailSuccessPayload,
  LoadClaimsSuccessPayload,
  UpdateClaimFieldPayload
} from '../models';

export enum ClaimActionTypes {
  LoadClaims = '[ClaimDataAccess] Load Claims',
  LoadClaimsSuccess = '[ClaimDataAccess] Load Claims Success',
  LoadClaimsFail = '[ClaimDataAccess] Load Claims Fail',

  LoadClaimDetail = '[ClaimDataAccess] Load Claim Detail',
  LoadClaimDetailSuccess = '[ClaimDataAccess] Load Claim Detail Success',
  LoadClaimDetailFail = '[ClaimDataAccess] Load Claim Detail Fail',
  DetailUnavailibleIgnoreErrors = '[ClaimDataAccess] Detail Unavailible Ignore Errors',

  SetSelectedClaim = '[ClaimDataAccess] Set Selected Claim',

  GetExposureId = '[ClaimDataAccess] Get ExposureId',
  GetExposureIdSuccess = '[ClaimDataAccess] Get ExposureId Success',
  GetExposureIdFail = '[ClaimDataAccess] Get ExposureId Fail',

  ClearSelectedClaim = '[ClaimDataAccess] Clear Selected Claim',
  UpdateClaimField = '[ClaimDataAccess] Update Claim Field',
  RequireClaimsRefresh = '[ClaimDataAccess] Require Claims Refresh'
}

export class LoadClaims implements Action {
  readonly type = ClaimActionTypes.LoadClaims;
  constructor(public payload?: any) {}
}

export class LoadClaimsSuccess implements Action {
  readonly type = ClaimActionTypes.LoadClaimsSuccess;
  constructor(public payload: LoadClaimsSuccessPayload) {}
}

export class LoadClaimsFail implements Action {
  readonly type = ClaimActionTypes.LoadClaimsFail;
  constructor(public payload?: any) {}
}

export class LoadClaimDetail implements Action {
  readonly type = ClaimActionTypes.LoadClaimDetail;
  constructor(public payload: LoadClaimDetailPayload) {}
}

export class LoadClaimDetailSuccess implements Action {
  readonly type = ClaimActionTypes.LoadClaimDetailSuccess;
  constructor(public payload: LoadClaimDetailSuccessPayload) {}
}

export class LoadClaimDetailFail implements Action {
  readonly type = ClaimActionTypes.LoadClaimDetailFail;
  constructor(public payload?: any) {}
}

export class DetailUnavailibleIgnoreErrors implements Action {
  readonly type = ClaimActionTypes.DetailUnavailibleIgnoreErrors;
  constructor(public payload: string) {} // Claim Number
}

export class SetSelectedClaim implements Action {
  readonly type = ClaimActionTypes.SetSelectedClaim;
  constructor(public payload: string) {}
}
// DM: used for setting a field on the claim object
export class UpdateClaimField implements Action {
  readonly type = ClaimActionTypes.UpdateClaimField;
  constructor(public payload: UpdateClaimFieldPayload) {}
}

export class GetExposureId implements Action {
  readonly type = ClaimActionTypes.GetExposureId;
  constructor(public payload: ExposureIdReqData) {}
}

export class GetExposureIdSuccess implements Action {
  readonly type = ClaimActionTypes.GetExposureIdSuccess;
  constructor(public payload: ExposureData) {}
}

export class GetExposureIdFail implements Action {
  readonly type = ClaimActionTypes.GetExposureIdFail;
  constructor(public payload: ExposureData) {}
}

export class ClearSelectedClaim implements Action {
  readonly type = ClaimActionTypes.ClearSelectedClaim;
  constructor() {}
}

export class RequireClaimsRefresh implements Action {
  readonly type = ClaimActionTypes.RequireClaimsRefresh;
  constructor() {}
}

export type ClaimAction =
  | LoadClaims
  | LoadClaimsSuccess
  | LoadClaimsFail
  | LoadClaimDetail
  | LoadClaimDetailSuccess
  | LoadClaimDetailFail
  | DetailUnavailibleIgnoreErrors
  | SetSelectedClaim
  | UpdateClaimField
  | GetExposureId
  | GetExposureIdSuccess
  | GetExposureIdFail
  | ClearSelectedClaim
  | RequireClaimsRefresh;

export const fromClaimActions = {
  LoadClaims,
  LoadClaimsSuccess,
  LoadClaimsFail,
  LoadClaimDetail,
  LoadClaimDetailSuccess,
  LoadClaimDetailFail,
  DetailUnavailibleIgnoreErrors,
  SetSelectedClaim,
  UpdateClaimField,
  GetExposureId,
  GetExposureIdSuccess,
  GetExposureIdFail,
  ClearSelectedClaim,
  RequireClaimsRefresh
};
