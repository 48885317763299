import { Component, Input, OnInit } from '@angular/core';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';

@Component({
  selector: 'ds-tile',
  templateUrl: './ds-tile.component.html',
  styleUrls: ['./ds-tile.component.scss']
})
export class DsTileComponent implements OnInit {
  @Input() tileClasses: string;
  @Input() showBanner: boolean;
  @Input() showPill: boolean;
  @Input() variant: string;
  @Input() noCTAWrapper;
  isRomePillEnabled: boolean;

  constructor(private featureFlagService: FeatureFlagService) {}

  ngOnInit() {
    this.isRomePillEnabled = this.featureFlagService.isEnabled('rome_card_pill');
  }
}
