import { createAction } from '@ngrx/store';

import { PaymentConfirmationModel } from '@amfam/shared/models';

export enum PaymentConfirmationActionTypes {
  PAYMENT_CONFIRMATION_LOAD = '[PaymentConfirmation] Load',
  PAYMENT_CONFIRMATION_LOAD_SUCCESS = '[PaymentConfirmation] Load Success',
  PAYMENT_CONFIRMATION_LOAD_FAIL = '[PaymentConfirmation] Load Fail'
}

export const PaymentConfirmationLoad = createAction(
  PaymentConfirmationActionTypes.PAYMENT_CONFIRMATION_LOAD,
  (payload: PaymentConfirmationModel) => ({
    payload
  })
);

export const PaymentConfirmationLoadSuccess = createAction(
  PaymentConfirmationActionTypes.PAYMENT_CONFIRMATION_LOAD_SUCCESS,
  (payload: PaymentConfirmationModel) => ({
    payload
  })
);

export const PaymentConfirmationLoadFail = createAction(
  PaymentConfirmationActionTypes.PAYMENT_CONFIRMATION_LOAD_FAIL,
  (payload?: unknown) => ({
    payload
  })
);

export type PaymentConfirmationActions =
  | ReturnType<typeof PaymentConfirmationLoad>
  | ReturnType<typeof PaymentConfirmationLoadSuccess>
  | ReturnType<typeof PaymentConfirmationLoadFail>;

export const fromPaymentConfirmationActions = {
  PaymentConfirmationLoad,
  PaymentConfirmationLoadSuccess,
  PaymentConfirmationLoadFail
};
