<div class="flex justify-center">
  <div class="content" tabindex="-1">
    <span class="icon-help block"></span>
    <h1>Contact Us</h1>
    <ul class="caption">
      <li *dsPartnerAFI>
        <span class="icon icon-chevron-left"></span>
        <a (click)="back()" target="amfam" content [contentPath]="'enrollment.contactUsBack'"></a>
      </li>
      <li>
        <span class="icon icon-call"></span>
        <a
          dsPartnerContent
          [brandContent]="'selectPrettyCusCareNumber'"
          [brandHref]="'selectCusCareNumberFullHref'"
        ></a>
      </li>
      <li>
        <a class="no-underline">
          <span
            dsPartnerContent
            [brandContent]="'selectCusCareHoursWeekday'"
            class="color-copy"
          ></span>
          <span dsPartnerContent [brandContent]="'selectCusCareHoursFriday'" class="color-copy"></span>
          <span
            dsPartnerContent
            [brandContent]="'selectCusCareHoursWeekend'"
            class="color-copy"
          ></span>
        </a>
      </li>

      <li>
        <a dsPartnerContent [brandHref]="'selectCusCareEmailHref'" class="flex row nowrap">
          <span class="icon icon-email"></span>
          <span dsPartnerContent [brandContent]="'selectCusCareEmail'" class="color-copy"></span>
        </a>
      </li>
    </ul>
  </div>
</div>
