// Angular
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';

// Misc
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
// Services
import { ConfigService, Content, ContentService } from '@amfam/shared/utility/shared-services';

// Models
import { ClaimsFaqResponse } from '../models/claim-faq.model';

@Injectable({ providedIn: 'root' })
export class ClaimFaqService {
  private httpBackend: HttpClient;
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
    private backend: HttpBackend,
    private contentService: ContentService
  ) {
    this.httpBackend = new HttpClient(backend);
  }

  public getClaimsFaq(): Observable<Content> {
    return this.contentService.getContent('faq', 'claims');
  }
}
