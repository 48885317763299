import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DocumentsEffects } from './+state/documents/documents.effects';
import { DocumentsFacade } from './+state/documents/documents.facade';
import { PoliciesEffects } from './+state/policies/policies.effects';
import { PoliciesFacade } from './+state/policies/policies.facade';
import * as fromPolicies from './+state/policies/policies.reducer';
import { policiesReducers } from './+state/policies/policies.selectors';
import { SummariesEffects } from './+state/summaries/summaries.effects';
import { SummariesFacade } from './+state/summaries/summaries.facade';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([PoliciesEffects, SummariesEffects, DocumentsEffects]),
    StoreModule.forFeature(fromPolicies.POLICIES_FEATURE_KEY, policiesReducers)
  ],
  providers: [PoliciesFacade, DocumentsFacade, SummariesFacade]
})
export class PolicyDataAccessPolicyModule {}
