import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { AddVehicleService } from '../../services/add-vehicle.service';
import {
  getNewVehicleFeatures,
  getNewVehicleFeaturesError,
  getNewVehicleFeaturesSuccess
} from './add-vehicle-feature.actions';

@Injectable()
export class AddNewVehicleFeaturesEffects {
  getNewVehicleFeatures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getNewVehicleFeatures),
      map(action => action.payload),
      mergeMap(requestPayload =>
        this.addVehicleService.getNewVehicleFeatures(requestPayload).pipe(
          map(response =>
            getNewVehicleFeaturesSuccess({
              payload: {
                request: requestPayload,
                response: response
              }
            })
          ),
          catchError(error =>
            of(
              getNewVehicleFeaturesError({
                payload: { response: error, request: requestPayload }
              })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private addVehicleService: AddVehicleService
  ) {}
}
