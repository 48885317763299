export interface ConfirmationPage {
  heading: string;
  description: string;
  icon: Icon;
}

export interface Icon {
  url?: string;
  class?: string;
}
