import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { find as _find, flatMap as _flatMap, get as _get } from 'lodash';

import { PolicySelectors } from '@amfam/policy/data-access';
import { agentQuery } from '@amfam/shared/utility/agent';

import { CustomerFeedbackEnum } from '../models/feedback';
import * as fromNotification from './notification.reducer';
import * as fromOpportunity from './recommendation.reducer';

export interface OpportunityState {
  status: fromOpportunity.State;
  notification: fromNotification.NotificationState;
}

export const reducers: ActionReducerMap<OpportunityState> = {
  status: fromOpportunity.reducer,
  notification: fromNotification.reducer
};

export const selectOpportunityState = createFeatureSelector<OpportunityState>('opportunity');

export const selectOpportunityStateStatus = createSelector(
  selectOpportunityState,
  (state: OpportunityState) => state.status
);

export const selectOpportunityCardState = createSelector(
  selectOpportunityState,
  (state: OpportunityState) => state.notification
);

export const getOpportunityCardError = createSelector(
  selectOpportunityCardState,
  (state: fromNotification.NotificationState, recommendationId: string) =>
    state.entities[recommendationId] ? state.entities[recommendationId].error : null
);

export const getOpportunityFeedback = createSelector(
  selectOpportunityCardState,
  (state: fromNotification.NotificationState, recommendationId: string) =>
    state.entities[recommendationId] || null
);

export const getOpportunityCardSuccess = createSelector(
  selectOpportunityCardState,
  (state: fromNotification.NotificationState, recommendationId: string) =>
    state.entities[recommendationId] ? state.entities[recommendationId].success : null
);

export const isOpportunityNotified = (recommendationId: string) =>
  createSelector(
    selectOpportunityCardState,
    (state: fromNotification.NotificationState) =>
      !!state.entities[recommendationId].error ||
      !!state.entities[recommendationId].success ||
      false
  );

export const getOpportunityCardLoading = createSelector(
  selectOpportunityCardState,
  (state: fromNotification.NotificationState, recommendationId: string): boolean =>
    state.entities[recommendationId] ? state.entities[recommendationId].loading : null
);

export const getOpportunityByRecommendationId = createSelector(
  selectOpportunityStateStatus,
  (state: fromOpportunity.State, recommendationId: string | number) =>
    state.entities[<number>recommendationId]
);

export const getOpportunityByOpportunityType = createSelector(
  selectOpportunityStateStatus,
  (state: fromOpportunity.State, opportunityType: string) =>
    _find(
      state.entities,
      entity => _get(entity, 'content.opportunityProductType', '') === opportunityType
    )
);

export const getAllOpportunitiesContent = createSelector(
  selectOpportunityStateStatus,
  (state: fromOpportunity.State) =>
    _flatMap(state.entities, entity => {
      if (!!entity.content) {
        return { ...entity.content, recommendationId: entity.recommendationId };
      }
      return null;
    }).filter(content => !!content)
);

export const getNotifiedOpportunities = createSelector(selectOpportunityCardState, state =>
  Object.values(state.entities)
);

export const getDismissedOpportunities = createSelector(getNotifiedOpportunities, opps =>
  opps.filter(opp => opp?.success?.customerFeedbackCode === CustomerFeedbackEnum.CustomerDismissed)
);

export const getQuotedOpportunities = createSelector(getNotifiedOpportunities, opps =>
  opps.filter(
    opp =>
      opp?.success?.customerFeedbackCode === CustomerFeedbackEnum.CustomerRequestedQuote ||
      opp?.success?.customerFeedbackCode === CustomerFeedbackEnum.CustomerDismissed
  )
);

export const getOpportunitiesNoDismissed = createSelector(
  getAllOpportunitiesContent,
  getQuotedOpportunities,
  (opps, dismissed) =>
    opps.filter(opp => !dismissed.map(d => d.recommendationId).includes(opp.recommendationId))
);

export const getAllActiveAgents = createSelector(
  agentQuery.getRealAgents,
  PolicySelectors.selectPolicies,
  (agents, policies) =>
    agents.filter(
      agent => agent && _find(policies, p => p.assignedProducer.producerIdNum === agent.id)
    )
);

export const getAgentInformationByAgentId = agentId =>
  createSelector(getAllActiveAgents, agents => agents.find(agent => agentId === agent.id));

export const getOfferKeys = createSelector(selectOpportunityStateStatus, state => state.offerKeys);
