@for (alert of consolidatedAlerts | async; track alert) {
  <alert-item
    [fromOverview]="fromOverview"
    [alert]="alert"
    [actionFromErrorModal]="buttonClickedFromErrorModal"
  ></alert-item>
}
<ds-billing-registration-failure-modal
  [visible]="hasRegistrationError$ | async"
  (tryAgainClicked)="buttonClickedFromModal('tryAgainClicked')"
></ds-billing-registration-failure-modal>
