@if (showWarning) {
  <div class="modal modal-animate overlay-black lg-six">
    <div class="content" tabindex="-1">
      <span class="icon-alert block"></span>
      <h1 content [contentPath]="'enrollment.exitWarningFirst'"></h1>
      <hr aria-hidden="true" />
      <p class="pad-top-10 center" content [contentPath]="'enrollment.exitWarningMessage'"></p>
      <h3 class="pad-top-20 center" content [contentPath]="'enrollment.exitWarningChoose'"></h3>
      <hr aria-hidden="true" />
      <ul class="options">
        <li>
          <span class="icon icon-chevron-left"></span>
          <a (click)="cancel()"
            ><span content [contentPath]="'enrollment.exitWarningFinish'"></span
          ></a>
        </li>
        <li>
          <span class="icon icon-exit"></span>
          <a (click)="confirm()"
            ><span content [contentPath]="'enrollment.exitWarningExit'"></span
          ></a>
        </li>
      </ul>
    </div>
  </div>
}
