import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ConfigService, CopyService, PageAnalytic } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import * as fromRoot from '../../core/store';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  error: string;
  sub: Subscription;
  amfamPhone: string = this.copyService.get('shared', 'amfamPhoneNumber');

  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:SystemError',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'System Error',
    subCategory2: '',
    subCategory3: ''
  };
  // **** End of Analytics data for this component

  constructor(
    private location: Location,
    private spinner: LoadingSpinnerService,
    private store: Store<fromRoot.RootState>,
    private analyticsFacade: AnalyticsFacade,
    private copyService: CopyService,
    private config: ConfigService
  ) {}

  ngOnInit() {
    try {
      this.spinner.stop();
      this.sub = this.store.select(fromRoot.getError).subscribe(error => {
        if (error && !this.config.get('production')) {
          this.error = error.message + '\n' + error.stack;
        }
      });
    } catch (ex) {
      // We need to catch any errors on the page so we don't get into a recursive loop.
      console.error(ex);
    }
    this.analyticsFacade.trackPage(this.pageAnalytic);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
