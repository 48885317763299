// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// rxjs
import { Observable } from 'rxjs';

// Services
import { ConfigService } from '@amfam/shared/utility/shared-services';

import { EligibleServicesResponse } from '../+state/eligible-services/eligible-services.models';
import { CreateRentalRequestResponse } from '../+state/rental-service/rental-service.models';
// Models
import { CreateRepairRequestResponse } from '../+state/repair-service/repair-service.models';
import { ClaimServicesConstants } from '../claim-services.constants';

@Injectable({ providedIn: 'root' })
export class ClaimServicesService {
  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {}

  public getEligibleServices(
    claimNumber: string,
    vin: string,
    cdhid: string
  ): Observable<EligibleServicesResponse> {
    const claimServicesUrl =
      this.config.get('claimServicesUtilApi') +
      claimNumber +
      '/autoeligibleservices?vin=' +
      vin +
      '&cdhid=' +
      cdhid;
    return this.http.get<EligibleServicesResponse>(claimServicesUrl);
  }

  public createRepairRequest(
    claimNumber: string,
    vin: string,
    shopId: string
  ): Observable<CreateRepairRequestResponse> {
    const reqObj = {
      claim: {
        claimNumber: claimNumber,
        vehicle: {
          vin: vin,
          serviceRequestInfo: {
            serviceRequestProviderId: shopId,
            serviceRequestCode: ClaimServicesConstants.DRP,
            serviceRequestDescription: ClaimServicesConstants.DRP
          }
        }
      }
    };
    const claimServicesUrl = this.config.get('claimServicesUtilApi') + claimNumber + '/drps';
    return this.http.post<CreateRepairRequestResponse>(claimServicesUrl, reqObj);
  }

  public createRentalRequest(
    claimNumber: string,
    vin: string,
    shopId: string,
    cdhid: string
  ): Observable<CreateRentalRequestResponse> {
    const reqObj = {
      claimSR: {
        cdhid: cdhid,
        vin: vin,
        rentalLocPicked: shopId
      }
    };

    const url = this.config.get('claimServicesUtilApi') + claimNumber + '/rentals';
    return this.http.post<CreateRentalRequestResponse>(url, reqObj);
  }
}
