import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { EntityNotification } from '@amfam/shared/models';

import * as fromCommunicationPreferencesNotifications from './communication-preferences-notifications.reducer';
import * as fromCommunicationPreferences from './communication-preferences.reducer';

export const communicationPreferencesFeatureKey = 'communicationPreferences';

// Reducer Map
export interface State {
  communicationPreferencesState: fromCommunicationPreferences.State;
  communicationPreferencesNotificationsState: fromCommunicationPreferencesNotifications.State;
}

export const reducers: ActionReducerMap<State> = {
  communicationPreferencesState: fromCommunicationPreferences.reducer,
  communicationPreferencesNotificationsState: fromCommunicationPreferencesNotifications.reducer
};

// State Selectors
export const selectState = createFeatureSelector<State>(communicationPreferencesFeatureKey);

export const selectCommunicationPreferencesState = createSelector(
  selectState,
  (state: State) => state.communicationPreferencesState
);

export const selectCommunicationPreferencesNotificationsState = createSelector(
  selectState,
  (state: State) => state.communicationPreferencesNotificationsState
);

export const selectCommunicationPreferencesNotifications = createSelector(
  selectCommunicationPreferencesNotificationsState,
  fromCommunicationPreferencesNotifications.selectAllCommunicationPreferencesNotifications
);

export const selectAnyLoading = createSelector(
  selectCommunicationPreferencesNotifications,
  (notifications: EntityNotification[]) =>
    notifications.map(notification => notification.loading).includes(true)
);
