<div class="row pad-16">
  <card cardClasses="profile-card gray" data-cy="profileCard">
    <div card-header class="flex">
      <div class="flex items-center justify-space-between flex-1">
        <div>
          @if (isPartyLoaded) {
            <h3 data-cy="accountUserName">
              {{ profileCard.firstName }} {{ profileCard.lastName }}
            </h3>
          }
          @if (canShowStatus) {
            <div>
              <span content [contentPath]="'profile.profileCard.status'"></span>:
              @if (!isShellAccount) {
                <span
                  class="statusActive"
                  content
                  [contentPath]="'profile.profileCard.active'"
                ></span>
              }
              @if (isShellAccount) {
                <span
                  class="statusPending"
                  content
                  [contentPath]="'profile.profileCard.shellAccountPending'"
                ></span>
              }
            </div>
          }
        </div>
        @if (!forgerockEnabled && !isShellAccount) {
          <div>
            <h4 data-cy="accountUserID">
              <span content [contentPath]="'profile.profileCard.userId'"></span>:
              <span>{{ profileCard.userId }}</span>
            </h4>
          </div>
        }
      </div>
    </div>
    <div card-content>
      <div class="flex flex-direction-column">
        <div>
          <h4
            content
            [contentPath]="
              isShellAccount ? 'shared.enrolledEmail' : 'profile.manageEmails.primaryEmail'
            "
            data-cy="accountEmailAddress"
          ></h4>
          <p class="primary-email">
            {{
              isShellAccount
                ? shellEmail
                : profileCard.primaryEmail
                  ? profileCard.primaryEmail.emailAddress
                  : ''
            }}
          </p>

          @if (profileCard.primaryPhone) {
            <h4 content [contentPath]="'profile.profileCard.primaryPhoneNumber'"></h4>
            <p>
              {{
                profileCard.primaryPhone.areaCode + profileCard.primaryPhone.phoneNumber
                  | phoneNumberSingleStringFormatter: profileCard.primaryPhone.extension
              }}
            </p>
          } @else {
            @if (!isMyAccountAdmin) {
              <div>
                <h4 content [contentPath]="'profile.profileCard.primaryPhoneNumber'"></h4>
                <p>
                  <a
                    (click)="moveToPhoneNumberClicked.emit()"
                    content
                    [contentPath]="'profile.profileCard.addPhoneNumber'"
                  ></a>
                </p>
              </div>
            }
          }
        </div>
        <div>
          @if (isCustomer) {
            <div>
              <h4
                data-cy="accountAddress"
                content
                [contentPath]="'profile.profileCard.mailingAddress'"
              ></h4>
              @if (profileCard.address) {
                <address>
                  <span>{{ profileCard.address.line1 }}</span
                  ><br />
                  @if (profileCard.address.line2) {
                    <span>{{ profileCard.address.line2 }}<br /></span>
                  }
                  <span class="pad-right-6">{{ profileCard.address.city }},</span>
                  <span class="pad-right-6">{{ profileCard.address.state }}</span>
                  <span>{{ profileCard.address.zip5 }}</span>
                </address>
              }
              @if (!profileCard.address) {
                <p content [contentPath]="'profile.profileCard.notAvailable'"></p>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </card>
</div>
