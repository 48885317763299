import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { find as _find, get as _get } from 'lodash';

import { OpportunityContent } from '@amfam/shared/utility/shared-services';

import { Opportunity } from '../models';
import { OpportunityActionsUnion, OpportunityActionTypes } from './opportunity.action';

// TODO: Rename this to recommendation
export interface State extends EntityState<Opportunity> {
  offerKeys: string[];
}

export const adapter: EntityAdapter<Opportunity> = createEntityAdapter<Opportunity>({
  selectId: (opportunity: Opportunity) => opportunity.recommendationId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({ offerKeys: [] });

export function reducer(state = initialState, action: OpportunityActionsUnion): State {
  switch (action.type) {
    case OpportunityActionTypes.OPPORTUNITIES_LOAD_SUCCESS: {
      return adapter.addMany(action.payload?.policyOpportunities || [], {
        ...state,
        offerKeys: action.payload.offerKeys || []
      });
    }

    case OpportunityActionTypes.OPPORTUNITIES_CONTENT_LOAD_SUCCESS: {
      return adapter.updateMany(updateOpportunityContent(state, _get(action, 'payload', [])), {
        ...state
      });
    }

    default: {
      return state;
    }
  }
}

/**
 * @returns Updated Opportunities
 * @param state
 * @param content
 * @description Use the content from the store to upload in the opportunities
 */
function updateOpportunityContent(
  state: State,
  content: OpportunityContent[]
): Update<Opportunity>[] {
  let updationArray: Update<Opportunity>[] = [];
  for (const opportunityItem in state.entities) {
    if (opportunityItem) {
      updationArray = [
        ...updationArray,
        buildUpdatePayload(
          state.entities[opportunityItem],
          _find(content, {
            opportunityProductType: state.entities[opportunityItem].revisedProductType
          })
        )
      ];
    }
  }
  return updationArray;
}

/**
 *
 * @param entity : The current opportunity in the store which needs to be updated
 * @param content : The Content coming from the sitecore for the particular product type.
 * @description : Build the payload which would be used to edit the Opportunity via the EntityAdapter.
 */
function buildUpdatePayload(entity: Opportunity, content: OpportunityContent): Update<Opportunity> {
  return {
    id: entity.recommendationId,
    changes: Object.assign({}, entity, { content: content })
  };
}
