import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import {
  EntityNotification,
  FailureNotification,
  LoadNotification,
  SuccessNotification
} from '@amfam/shared/models';

import * as CommunicationPreferencesActions from './communication-preferences.actions';

export type State = EntityState<EntityNotification>;

export const adapter: EntityAdapter<EntityNotification> = createEntityAdapter<EntityNotification>({
  selectId: (notification: EntityNotification) => notification.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState();

export const reducer = createReducer(
  initialState,

  on(CommunicationPreferencesActions.setBillAccountDeliveryPreferenceSuccess, (state, action) =>
    adapter.upsertOne(
      new SuccessNotification(action.billAccountNumber, action.correlationId),
      state
    )
  ),

  on(CommunicationPreferencesActions.setBillAccountDeliveryPreferenceFailure, (state, action) =>
    adapter.upsertOne(
      new FailureNotification(action.billAccountNumber, action.correlationId, action.error),
      state
    )
  ),

  on(CommunicationPreferencesActions.setBillAccountDeliveryPreference, (state, action) =>
    adapter.upsertOne(new LoadNotification(action.billAccountNumber), state)
  )
);

const { selectAll } = adapter.getSelectors();

export const selectAllCommunicationPreferencesNotifications = selectAll;
