import { Dictionary } from '@ngrx/entity';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap as _flatMap, get as _get, some as _some } from 'lodash';

import { PaymentStatus, ScheduledPayment, UNAVAILABLE_PAYMENT_ID } from '@amfam/shared/models';

import * as fromSchedulePaymentNotifications from './notifications.reducer';
import * as fromSchedulePaymentState from './schedulepayment.reducer';
import { SCHEDULE_PAYMENT_FEATURE_KEY } from './schedulepayment.reducer';

export interface SchedulePaymentState {
  schedulePaymentState: fromSchedulePaymentState.SchedulePaymentEntityState;
  schedulePaymentNotifications: fromSchedulePaymentNotifications.NotificationState;
}

export const schedulePaymentReducers: ActionReducerMap<SchedulePaymentState> = {
  schedulePaymentState: fromSchedulePaymentState.reducer,
  schedulePaymentNotifications: fromSchedulePaymentNotifications.reducer
};

export const selectScheduledPaymentsState = createFeatureSelector<SchedulePaymentState>(
  SCHEDULE_PAYMENT_FEATURE_KEY
);

export const selectScheduledPaymentsLoading = createSelector(
  selectScheduledPaymentsState,
  (state: SchedulePaymentState) => _get(state, 'schedulePaymentNotifications.loading', true)
);

export const selectScheduledPaymentsLoaded = createSelector(
  selectScheduledPaymentsState,
  (state: SchedulePaymentState) => !_get(state, 'schedulePaymentNotifications.loading', true)
);

export const selectScheduledPaymentEntities = createSelector(
  selectScheduledPaymentsState,
  selectScheduledPaymentsLoaded,
  (state: SchedulePaymentState, isLoaded) =>
    isLoaded
      ? <Dictionary<ScheduledPayment>>(
          fromSchedulePaymentState.selectEntities(state.schedulePaymentState)
        )
      : {}
);

export const selectScheduledPaymentIds = createSelector(
  selectScheduledPaymentsState,
  selectScheduledPaymentsLoaded,
  (state: SchedulePaymentState, isLoaded) =>
    isLoaded ? <string[]>fromSchedulePaymentState.selectIds(state.schedulePaymentState) : []
);

export const selectScheduledPaymentsError = createSelector(
  selectScheduledPaymentsState,
  (state: SchedulePaymentState) => !!_get(state, 'schedulePaymentNotifications.error')
);

// this returns scheduled and authorized Online Billing payments only. The http call returns non-Online Billing payments that are either:
// SUCCESSUL, UNSUCCESSFUL, DELETED, SCHEDULED or AUTHORIZED
// For the most part, the treatment of pending payments in the app are only built-out to mean Online Billing payments, with the current
// exception of the unsuccessful payment alert. Payment History uses a different mechanism in the store.
export const selectScheduledPayments = createSelector(
  selectScheduledPaymentEntities,
  selectScheduledPaymentIds,
  (entities, ids): ScheduledPayment[] =>
    ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(
        payment =>
          (_get(payment, 'paymentStatus') === PaymentStatus.SCHEDULED ||
            _get(payment, 'paymentStatus') === PaymentStatus.AUTHORIZED) &&
          _get(payment, 'paymentMethod') === 'Online Billing'
      )
);

// This selector will return all scheduled payments except with DELETED status and will be used for bill account status pill hierarchy
export const selectScheduledPaymentsWithMostPaymentStatus = createSelector(
  selectScheduledPaymentEntities,
  selectScheduledPaymentIds,
  (entities, ids): ScheduledPayment[] =>
    ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(
        payment =>
          payment.paymentStatus !== PaymentStatus.DELETED &&
          payment.paymentMethod === 'Online Billing'
      )
);

export const selectInflightScheduledPayment = createSelector(
  selectScheduledPaymentEntities,
  selectScheduledPaymentIds,
  (entities, ids) => ids.filter(id => id === UNAVAILABLE_PAYMENT_ID).map(id => entities[id])
);

// getAllScheduledPayments returns all payments along with inflight payment with an ID value of UNAVAILABLE_PAYMENT_ID
// and should only be used within add / edit payment flows
export const selectAllScheduledPayments = createSelector(
  selectScheduledPaymentEntities,
  selectScheduledPaymentIds,
  selectInflightScheduledPayment,
  (entities, ids, inflightPayment) => [
    ...ids
      .map(id => entities[id])
      .filter(
        payment =>
          (_get(payment, 'paymentStatus') === PaymentStatus.SCHEDULED ||
            _get(payment, 'paymentStatus') === PaymentStatus.AUTHORIZED) &&
          _get(payment, 'paymentMethod') === 'Online Billing'
      ),
    ...inflightPayment
  ]
);

export const selectFailedPaymentsAllMethods = createSelector(
  selectScheduledPaymentEntities,
  selectScheduledPaymentIds,
  (entities, ids) =>
    ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(payment => _get(payment, 'paymentStatus') === 'UNSUCCESSFUL')
);

export const selectSuccessfulPaymentsAllMethods = createSelector(
  selectScheduledPaymentEntities,
  selectScheduledPaymentIds,
  (entities, ids) =>
    ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(payment => _get(payment, 'paymentStatus') === 'SUCCESSFUL')
);

export const selectHasScheduledPaymentForBillaccount = (id: string) =>
  createSelector(selectScheduledPayments, scheduledPayments => {
    for (const scheduledPayment of scheduledPayments) {
      const billAccountsArr =
        _flatMap(scheduledPayment.billAccounts, billaccount => billaccount) || [];
      if (billAccountsArr.find(billAccounts => billAccounts.billAccountNumber === id)) {
        return true;
      }
    }
  });

export const selectSuccessfullScheduledPaymentForBillingAccount = createSelector(
  selectAllScheduledPayments,
  (payments: ScheduledPayment[], billAccountNumber: string) => {
    const defaultDate = '9999-01-01T00:00:00.000-0600';
    const initialDate = '0001-01-01';
    // Order pending payments by most recent first
    payments
      .sort((a, b) => {
        // if the payment is SUCCESSFUL, we need to sort on the receivedDate field. For pending payments we sort on lastUpdateTimestamp
        // If a lastUpdateTimestamp starts with 0001-01-01 treat it as the most recent payment, so cast it to the year 9999
        const dateA =
          a.paymentStatus === 'SUCCESSFUL'
            ? _get(a, 'receivedDate', '')
            : _get(a, 'lastUpdateTimestamp', '').startsWith(initialDate)
              ? defaultDate
              : a.lastUpdateTimestamp;

        const dateB =
          b.paymentStatus === 'SUCCESSFUL'
            ? _get(b, 'receivedDate', '')
            : _get(b, 'lastUpdateTimestamp', '').startsWith(initialDate)
              ? defaultDate
              : b.lastUpdateTimestamp;
        return String(dateA).localeCompare(String(dateB));
      })
      .reverse();

    return payments.find(payment => {
      if (payment.billAccounts.length === 1) {
        return payment.billAccounts[0].billAccountNumber === billAccountNumber;
      }
      return false;
    });
  }
);

export const selectScheduledPaymentsNotificationsEntities = createSelector(
  selectScheduledPaymentsState,
  selectScheduledPaymentsLoaded,
  (state: SchedulePaymentState, isLoaded) =>
    isLoaded
      ? fromSchedulePaymentNotifications.selectEntities(state.schedulePaymentNotifications)
      : []
);

export const selectScheduledPaymentsNotificationsIds = createSelector(
  selectScheduledPaymentsState,
  selectScheduledPaymentsLoaded,
  (state: SchedulePaymentState, isLoaded) =>
    isLoaded
      ? <string[]>fromSchedulePaymentNotifications.selectIds(state.schedulePaymentNotifications)
      : []
);

export const selectInflightScheduledPaymentNotifications = createSelector(
  selectScheduledPaymentsNotificationsEntities,
  selectScheduledPaymentsNotificationsIds,
  (entities, ids) => ids.filter(id => id === UNAVAILABLE_PAYMENT_ID).map(id => entities[id])
);

export const selectScheduledPaymentsNotifications = createSelector(
  selectScheduledPaymentEntities,
  selectScheduledPaymentsNotificationsEntities,
  selectScheduledPaymentIds,
  (entities: Dictionary<ScheduledPayment>, notificationsEntities, ids) =>
    <fromSchedulePaymentNotifications.ScheduledPaymentNotificationState[]>ids
      .filter(id => id !== UNAVAILABLE_PAYMENT_ID)
      .map(id => entities[id])
      .filter(
        payment =>
          (_get(payment, 'paymentStatus') === PaymentStatus.SCHEDULED ||
            _get(payment, 'paymentStatus') === PaymentStatus.AUTHORIZED) &&
          _get(payment, 'paymentMethod') === 'Online Billing'
      )
      .map(payment => notificationsEntities[payment.paymentId])
);

export const selectScheduledPaymentsAnyLoading = createSelector(
  selectScheduledPaymentsNotifications,
  selectScheduledPaymentsLoading,
  (schedulePayments, schedulePaymentsLoading) =>
    schedulePaymentsLoading || _some(schedulePayments, { loading: true })
);

export const selectScheduledPaymentsFilteredNotifications = (correlationId: string) =>
  createSelector(selectScheduledPaymentsNotifications, scheduledPayments => {
    const filtered = scheduledPayments.filter(sp => _get(sp, 'correlationId') === correlationId);
    return filtered;
  });

export const schedulePaymentQuery = {
  selectScheduledPaymentsAnyLoading,
  selectScheduledPaymentsNotifications,
  selectScheduledPaymentsNotificationsIds,
  selectScheduledPaymentsNotificationsEntities,
  selectSuccessfullScheduledPaymentForBillingAccount,
  selectSuccessfulPaymentsAllMethods,
  selectFailedPaymentsAllMethods,
  selectAllScheduledPayments,
  selectInflightScheduledPayment,
  selectScheduledPayments,
  selectScheduledPaymentsError,
  selectScheduledPaymentIds,
  selectScheduledPaymentEntities,
  selectScheduledPaymentsLoaded,
  selectScheduledPaymentsLoading,
  selectScheduledPaymentsState,
  selectHasScheduledPaymentForBillaccount
};
