import { createAction } from '@ngrx/store';

import { Policy, PolicySummary } from '@amfam/policy/models';

export enum PoliciesActionTypes {
  LoadPoliciesType = '[Policies] Policies Load',
  PoliciesLoadSuccessType = '[Policies] Policies Load Success',
  PoliciesRawLoadSuccessType = '[Policies] Policies Raw Load Success',
  PoliciesLoadCompleteType = '[Policies] Policies Load Complete',
  PoliciesLoadErrorType = '[Policies] Policies Load Error',

  LoadOnePolicyType = '[Policies] Load One Policy',
  PolicyLoadSuccessType = '[Policies] Policy Load Success',
  PolicyLoadErrorType = '[Policies] Policy Load Error',

  PoliciesSetSelectedType = '[Policies] Policies Set Selected',
  PoliciesSetSelectedSuccessType = '[Policies] Policies Set Selected Success',
  PoliciesSetSelectedErrorType = '[Policies] Policies Set Selected Error'
}

export const LoadPolicies = createAction(
  PoliciesActionTypes.LoadPoliciesType,
  (payload: PolicySummary[]) => ({ payload })
);

export const PoliciesLoadError = createAction(
  PoliciesActionTypes.PoliciesLoadErrorType,
  (payload?: Policy[]) => ({ payload })
);

export const PoliciesLoadSuccess = createAction(
  PoliciesActionTypes.PoliciesLoadSuccessType,
  (payload: Policy[]) => ({ payload })
);

export const PoliciesRawLoadSuccess = createAction(
  PoliciesActionTypes.PoliciesRawLoadSuccessType,
  (payload?: Policy[]) => ({ payload })
);

export const PoliciesLoadComplete = createAction(
  PoliciesActionTypes.PoliciesLoadCompleteType,
  (payload?: unknown) => ({ payload })
);

export const LoadOnePolicy = createAction(
  PoliciesActionTypes.LoadOnePolicyType,
  (payload: unknown) => ({ payload })
);

export const PolicyLoadError = createAction(
  PoliciesActionTypes.PolicyLoadErrorType,
  (payload?: unknown) => ({ payload })
);

export const PolicyLoadSuccess = createAction(
  PoliciesActionTypes.PolicyLoadSuccessType,
  (payload: unknown) => ({ payload })
);

export const PoliciesSetSelected = createAction(
  PoliciesActionTypes.PoliciesSetSelectedType,
  (payload: string) => ({ payload })
);

export const PoliciesSetSelectedSuccess = createAction(
  PoliciesActionTypes.PoliciesSetSelectedSuccessType,
  (payload?: unknown) => ({ payload })
);

export const PoliciesSetSelectedError = createAction(
  PoliciesActionTypes.PoliciesSetSelectedErrorType,
  (payload: unknown) => ({ payload })
);

export type PoliciesAction =
  | ReturnType<typeof LoadPolicies>
  | ReturnType<typeof PoliciesLoadSuccess>
  | ReturnType<typeof PoliciesRawLoadSuccess>
  | ReturnType<typeof PoliciesLoadError>
  | ReturnType<typeof PoliciesLoadComplete>
  | ReturnType<typeof LoadOnePolicy>
  | ReturnType<typeof PolicyLoadSuccess>
  | ReturnType<typeof PolicyLoadError>
  | ReturnType<typeof PoliciesSetSelected>
  | ReturnType<typeof PoliciesSetSelectedSuccess>
  | ReturnType<typeof PoliciesSetSelectedError>;

export const fromPoliciesActions = {
  LoadPolicies,
  PoliciesLoadSuccess,
  PoliciesRawLoadSuccess,
  PoliciesLoadError,
  PoliciesLoadComplete,
  PoliciesSetSelected,
  PoliciesSetSelectedSuccess,
  PoliciesSetSelectedError,
  LoadOnePolicy,
  PolicyLoadSuccess,
  PolicyLoadError
};
