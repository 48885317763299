<div class="card-content" *ngIf="risk$ | async as risk" data-cy="insurance-card">
  <div class="main-header" data-cy="insuranceCardHeader">Insurance Card</div>

  <!-- <div class="buttons">
    <div class="button">
      <a
        [routerLink]="'/policies/auto/' + risk.policyNumber"
        [queryParams]="{ vehicleId: risk.vehicleId }"
        data-cy="view-policy-link"
        ><img src="/assets/images/poi-auto.svg" /><span>View Policy</span>
      </a>
    </div>
    <div class="button">
      <a (click)="openStatement(risk.poiLink)" data-cy="download-pdf-link"
        ><img src="/assets/images/download.svg" /><span>Download as PDF</span>
      </a>
    </div>
  </div>
  <div>
    <ds-jump-links [jumpLinks]="links"></ds-jump-links>
  </div> -->

  <div class="margin-bottom-40">
    <!-- <h3 data-cy="yourPolicyHeader">Your Policy</h3> -->
    <amfam-policy-card
      [coverages]="coverages$ | async"
      [risk]="risk"
      [insureds]="insureds$ | async"
      data-cy="policyCard"
      [links]="links"
    ></amfam-policy-card>
  </div>
  <div class="margin-bottom-40">
    <div class="sub-header margin-bottom-20" data-cy="yourVehicleHeader">Coverage Details</div>
    <amfam-vehicle-card
      [risk]="risk"
      data-cy="vehicleCard"
      (vehicleCardClicked)="onVehicleCardClicked($event)"
    ></amfam-vehicle-card>
  </div>
@if (agent$ | async) {
  <div class="agent-section margin-bottom-40" [class.stretch-column]="isHandsetPortrait$ | async">
    <div class="sub-header margin-bottom-20" data-cy="yourAgentHeader">Agent Info</div>
    <agent-item
      class="agent-item"
      [agent]="agent$ | async"
      [defaultContactNumber]="defaultContactNumber"
      data-cy="agentItem"
      [newTemplate]="true"
    ></agent-item>
  </div>
}
  <div class="disclaimer">
    <div class="agent-id">
      <p data-cy="producerId">Producer ID: {{ risk.producerId }}</p>
      <p *ngIf="risk.address.state === 'AZ'" data-cy="azDotNumber">
        ADOT: {{ azDOTNumber$ | async }}
      </p>
    </div>
    <b>{{ POIName$ | async }}</b>
    <p [innerHTML]="stateDisclaimer$ | async" data-cy="stateDisclaimer"></p>
    <p [innerHTML]="footer" data-cy="footer"></p>
    <div class="company-and-naic">
      <p data-cy="amfamCompanyDescription">{{ risk.amfamCompanyDescription }}</p>
      <p data-cy="naicCode">NAIC Number: {{ naicCode$ | async }}</p>
    </div>
    <i data-cy="disclaimer">{{ disclaimer }}</i>
  </div>
  <div class="doodle-container">
    <img src="/assets/images/car_waving.svg" />
  </div>
</div>
<div class="action sticky" *ngIf="isHandsetPortrait$ | async">
  <a href="tel:1-866-987-0206" class="flex-column icon-button" data-cy="roadside-assistance-link">
    <img src="/assets/images/TowTruck2x.png" height="50" width="50" alt="File a Claim" />
    <span data-cy="roadsideAssistance">Roadside Assistance</span>
  </a>

  <a
    class="flex-column icon-button"
    [routerLink]="['/claims/report-claim-fnol']"
    data-cy="fileClaimLink"
  >
    <img src="/assets/images/Document2x.png" height="50" width="50" alt="File a Claim" />
    <span>File a Claim</span>
  </a>
</div>
