import { GenericProductType, RiskModel } from '@amfam/policy/models';
import { Option } from '@amfam/shared/models';
import { ErrorObj } from '@amfam/ui-kit';

import { CreateDraftClaimModel } from '../models/create-draft-claim.model';
import { InitiateDynamicQuestionAdapterModel } from '../models/initiate-dynamic-question-adapter.model';

export interface FnolModel extends CreateDraftClaimModel {
  cdhId?: string;
  firstName?: string;
  lastName?: string;
  driver?: Driver;
  riskDescription?: string;
  risk?: RiskModel;
  emailAddress?: string;
  phoneNumber?: string;
  question?: string;
  questionId?: string;
  questionCategory?: string;
  options?: Option[];
  selectedAnswer?: string;
  questionType?: string;
  instructionText?: string;
  loading?: boolean;
  hasError?: boolean;
  claimErrorMessage?: Array<ErrorObj>;
  submitting?: boolean;
  enableBackButton?: boolean;
}

export interface FnolAnalyticsModel {
  claimNumber: string;
  isError: boolean;
  lineOfBusiness: GenericProductType;
  apiResponse?: InitiateDynamicQuestionAdapterModel;
}

export interface LossLocationAddress {
  addressLine1?: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip?: string;
}

export interface Driver {
  id?: string;
  firstName: string;
  lastName: string;
}

export const initialFnolModel: FnolModel = {
  insuredContactInfo: {
    contactId: null
  },
  sourceSystem: '',
  lossDate: '',
  openDate: '',
  lobCode: '',
  claimContacts: [],
  reportedBy: {
    contactId: null
  },
  reportedByType: '',
  reportedOn: '',
  reportedTime: '',
  lossLocationAddress: [
    {
      city: '',
      state: ''
    }
  ],
  vehicleDetails: [],
  question: '',
  questionId: '',
  options: [],
  selectedAnswer: '',
  questionType: '',
  instructionText: '',
  policyNumber: '',
  cdhId: '',
  firstName: '',
  lastName: '',
  loading: null,
  hasError: null
};
