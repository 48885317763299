import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { bindPartnerAddVehicleChangeCoverageQuoteError } from '@amfam/policy/vehicle-coverages/data-access';
import { ApiStatus } from '@amfam/shared/models';

import { AddVehicleService } from '../../services/add-vehicle.service';
import {
  bindAddVehicleQuote,
  bindAddVehicleQuoteError,
  bindAddVehicleQuoteSuccess,
  sendAgentANSNotification,
  sendAgentANSNotificationError,
  sendAgentANSNotificationSuccess,
  sendAgentEmailNotification,
  sendAgentEmailNotificationError,
  sendAgentEmailNotificationSuccess,
  sendCustomerEmailNotification,
  sendCustomerEmailNotificationError,
  sendCustomerEmailNotificationSuccess,
  sendPartnerCustomerEmailNotification,
  sendPartnerCustomerEmailNotificationError,
  sendPartnerCustomerEmailNotificationSuccess,
  sendPartnerEmailNotification,
  sendPartnerEmailNotificationError,
  sendPartnerEmailNotificationSuccess
} from './add-vehicle-bind.actions';

@Injectable()
export class AddVehicleBindEffects {
  sendAgentEmailNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendAgentEmailNotification),
      map(action => action.payload),
      switchMap(requestPayload =>
        this.addVehicleService.sendAddVehicleAgentEmail(requestPayload).pipe(
          map(response => sendAgentEmailNotificationSuccess({ payload: response })),
          catchError(error => of(sendAgentEmailNotificationError({ payload: error })))
        )
      )
    );
  });

  sendPartnerEmailNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendPartnerEmailNotification),
      map(action => action.payload),
      switchMap(requestPayload =>
        // check tpi
        requestPayload.vehicleHasTPI.toString().toLowerCase() === 'yes'
          ? this.addVehicleService.sendAddVehiclePartnerEmailTPI(requestPayload).pipe(
              map(response => sendPartnerEmailNotificationSuccess({ payload: response })),
              catchError(error => of(sendPartnerEmailNotificationError({ payload: error })))
            )
          : this.addVehicleService.sendAddVehiclePartnerEmailNoTPI(requestPayload).pipe(
              map(response => sendPartnerEmailNotificationSuccess({ payload: response })),
              catchError(error => of(sendPartnerEmailNotificationError({ payload: error })))
            )
      )
    );
  });

  sendAgentANSNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendAgentANSNotification),
      map(action => action.payload),
      switchMap(request =>
        this.addVehicleService.ansNotification(request).pipe(
          map(response =>
            sendAgentANSNotificationSuccess({
              payload: response
            })
          ),
          catchError(error => of(sendAgentANSNotificationError({ payload: error })))
        )
      )
    );
  });

  sendCustomerEmailNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendCustomerEmailNotification),
      map(action => action.payload),
      switchMap(templatedEmail =>
        this.addVehicleService.sendAddVehicleCustomerEmail(templatedEmail).pipe(
          map(response =>
            sendCustomerEmailNotificationSuccess({
              payload: response
            })
          ),
          catchError(error => of(sendCustomerEmailNotificationError({ payload: error })))
        )
      )
    );
  });

  sendPartnerCustomerEmailNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendPartnerCustomerEmailNotification),
      map(action => action.payload),
      switchMap(templatedEmail =>
        this.addVehicleService.sendAddVehicleCustomerEmail(templatedEmail).pipe(
          map(response =>
            sendPartnerCustomerEmailNotificationSuccess({
              payload: response
            })
          ),
          catchError(error => of(sendPartnerCustomerEmailNotificationError({ payload: error })))
        )
      )
    );
  });

  bindAddVehicleQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(bindAddVehicleQuote),
      map(action => action.payload),
      //withLatestFrom(this.store.select(BrandSelectors.selectIsPartner)),
      switchMap(payload =>
        this.addVehicleService.bindAddVehicleFeature(payload.policyNumber, payload.vin).pipe(
          map(response =>
            bindAddVehicleQuoteSuccess({
              payload: response
            })
          ),
          catchError(error => of(bindAddVehicleQuoteError({ payload: error })))
        )
      )
    );
  });

  onAddVehiclePartnerError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(bindPartnerAddVehicleChangeCoverageQuoteError),
      map(action => action.vehicleId),
      //withLatestFrom(this.store.select(BrandSelectors.selectIsPartner)),
      map(() => bindAddVehicleQuoteError({ payload: {} as ApiStatus }))
    );
  });

  constructor(
    private actions$: Actions,
    private addVehicleService: AddVehicleService
  ) {}
}
