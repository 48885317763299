import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { get as _get, has as _has } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavLinkTreeObj } from '../../../models';
import { HeaderWrapperService } from '../../../services/header-wrapper.service';

@Component({
  selector: 'ds-header-mobile-navigation-item',
  templateUrl: './ds-header-mobile-navigation-item.component.html',
  styleUrls: [
    '../shared/ds-header-mobile-navigation.scss',
    './ds-header-mobile-navigation-item.component.scss'
  ],
  animations: [
    trigger('showNavItem', [
      state('show', style({ transform: 'translateX(0)', visibility: 'visible' })),
      state('hide', style({ transform: 'translateX(-100%)', visibility: 'hidden' })),
      transition('* => *', animate('250ms'))
    ]),
    trigger('showSubmenu', [
      state('show', style({ transform: 'translateX(0)', visibility: 'visible' })),
      state('hide', style({ transform: 'translateX(100%)', visibility: 'hidden' })),
      transition('* => *', animate('250ms'))
    ])
  ]
})
export class DsHeaderMobileNavigationItemComponent implements OnInit, OnDestroy {
  @Input() showSub = 'hide';
  @Input() showNavItem = 'show';
  @Input() link: NavLinkTreeObj;

  @Output() linkClickEvent = new EventEmitter();
  @Output() linkBlurEvent = new EventEmitter();
  @Output() sublinkClickEvent = new EventEmitter();
  @Output() sublinkTitleClickEvent = new EventEmitter();

  @ViewChildren('subLinkElemItem') subLinkElemItems: QueryList<ElementRef>;
  @ViewChild('sublinkTitle') sublinkTitle: ElementRef;

  linkArray: NavLinkTreeObj[] = [];
  previousFocusElem: HTMLElement | null;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private headerWrapperService: HeaderWrapperService,
    private router: Router
  ) {}

  ngOnInit() {
    // Set the item to focus here, but wait to focus it
    // until the animation is complete, therwise it won't work.
    this.headerWrapperService.mobileMenuOpenItem$
      .pipe(takeUntil(this.stop$))
      .subscribe(prevElem => {
        this.previousFocusElem = prevElem;
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  linkClick(event) {
    const currentElem = _get(event, 'currentTarget', '');
    this.linkClickEvent.emit({ link: this, event });
    if (!_get(this.link, 'textOnly')) {
      this.headerWrapperService.setMobileSublinkActive(currentElem);
    }

    // If the link item has children, open the sub-menu and don't route
    if (_has(this.link, 'subLinkArray.length')) {
      this.showSub = 'show';
      this.headerWrapperService.setMobileMenuOpenItem(currentElem);
      // Else route as there's no sub-menu to open
    } else {
      if (_has(this.link, 'linkRoute')) {
        return _has(this.link, 'queryParams')
          ? this.router.navigate([this.link?.linkRoute, this.link?.queryParams])
          : this.router.navigate([this.link?.linkRoute]);
      }
    }
  }

  onLinkBlur() {
    this.linkBlurEvent.emit(this);
  }

  sublinkClick(sublink, event) {
    // Bubble up event
    this.sublinkClickEvent.emit({ sublink, event });
    // Close mobile menu unless specified otherwise with the textOnly property
    if (!_get(sublink, 'textOnly')) {
      this.headerWrapperService.setMobileMenuOpen(false);
      this.showSub = 'hide';
    }
  }

  sublinkTitleClick() {
    // Bubble up event
    this.sublinkTitleClickEvent.emit();
    this.showSub = 'hide';
  }

  focusSublinkTitle() {
    if (_has(this.sublinkTitle, 'nativeElement')) {
      this.sublinkTitle.nativeElement.focus();
    }
  }

  // Fired from the template on animation.done
  focusPreviousElem() {
    if (this.previousFocusElem !== null) {
      this.previousFocusElem.focus();
    }
  }

  resetOnBlur(target) {
    if (
      _has(this.subLinkElemItems, 'last.nativeElement') &&
      target === this.subLinkElemItems.last.nativeElement
    ) {
      this.focusSublinkTitle();
    }
  }

  // Tell Angular to track these items by ID so that they are not recreated in the DOM
  trackByFn(index) {
    return index;
  }
}
