import { Directive, HostListener } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { forEach as _forEach } from 'lodash';

@Directive({
  selector: '[dsDigitMask]'
})
export class DigitMaskDirective {
  private model: FormControlName;
  private digitPattern = /\d+/;

  constructor(model: FormControlName) {
    this.model = model;
  }

  @HostListener('keyup', ['$event'])
  onInputChange(event: KeyboardEvent) {
    // Allow navigation and deleting of characters
    if (
      event.keyCode === 8 ||
      event.keyCode === 37 ||
      event.keyCode === 39 ||
      event.keyCode === 46
    ) {
      return;
    }
    // Allow any integers
    if (event.keyCode >= 48 && event.keyCode <= 57) {
      return;
    }

    const currentValue = this.model.value;

    if (!currentValue || currentValue.length <= 0) {
      return;
    }

    const actualValue = this.getActualValue(currentValue);
    const maskedValue = actualValue;

    // This is the actual binding (unmasked) value
    this.model.viewToModelUpdate(actualValue);

    // This is the displaying (masked) value
    this.model.valueAccessor.writeValue(maskedValue);
  }

  // Returns the actual (unmasked) value
  getActualValue(currentValue: string): string {
    let result = '';

    // Check if something is available to mask
    if (currentValue && currentValue.length > 0) {
      const digits = currentValue.match(this.digitPattern);

      _forEach(digits, function (value) {
        result += value;
      });
    }

    return result;
  }
}
