import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  combineLatest,
  filter,
  finalize,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';

import { PolicySelectors } from '@amfam/policy/data-access';
import { InsuredTypes, RiskModel, SourceSystemType } from '@amfam/policy/models';
import { AnalyticsFacade, EventAnalyticParams } from '@amfam/shared/analytics';
import { ButtonProps, DocumentActions } from '@amfam/shared/models';
import { DocumentPipeService, StateAbbreviationPipe } from '@amfam/shared/ui/pipes';
import { Agent, agentQuery } from '@amfam/shared/utility/agent';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import {
  CopyService,
  EventDispatcherService,
  openWindowBlob,
  WindowRef
} from '@amfam/shared/utility/shared-services';
import { DockingBarService, DsModalService, LoadingSpinnerService } from '@amfam/ui-kit';

import {
  arizonaDOTNumber,
  coverageCodeConst,
  POIName,
  setNAIC,
  sourceMapping,
  stateMapping
} from '../../coverage-code-consts';
import * as poiAnalytics from './insurance-card.analytics';

@Component({
  selector: 'ds-insurance-card',
  templateUrl: './insurance-card.component.html',
  styleUrls: ['./insurance-card.component.scss']
})
export class InsuranceCardComponent implements OnInit, OnDestroy {
  isHandsetPortrait$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.HandsetPortrait])
    .pipe(map(obs => obs.matches));

  coverages$: Observable<string>;
  risk$: Observable<RiskModel>;
  vehicleId: string;
  stateDisclaimer$: Observable<string>;
  agents$ = this.store.select(agentQuery.getRealAgents);
  agent$: Observable<Agent>;
  defaultContactNumber: string;
  footer: string;
  dataLoading$: Observable<boolean>;
  disclaimer = this.copyService.getCopy('policy.insuranceCardDislaimer');
  insureds$: Observable<string[]>;
  naicCode$: Observable<string>;
  POIName$: Observable<string>;
  azDOTNumber$: Observable<string>;
  links: ButtonProps[];
  blob: File;
  noDocumentModalId = 'noDocumentModal';
  isPartner: boolean;
  experienceId: string;
  riskHasCancelDate: boolean;
  hasEffectiveDatesChangedModalId = 'hasEffectiveDatesChangedModalId';

  private stop$ = new Subject<void>();

  constructor(
    private dockingBarService: DockingBarService,
    private breakpointObserver: BreakpointObserver,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private copyService: CopyService,
    private sanitizer: DomSanitizer,
    private documentPipeService: DocumentPipeService,
    private win: WindowRef,
    private deviceService: DeviceDetectorService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade,
    private router: Router,
    private eventDispatcherService: EventDispatcherService,
    private modalService: DsModalService
  ) {
    this.analyticsFacade.trackPage(poiAnalytics.insuranceCardAnalytic);

    this.dockingBarService.registerHeading('Insurance Card');

    this.vehicleId = this.activatedRoute.snapshot.paramMap.get('id');
    this.store
      .select(BrandSelectors.selectIsPartner)
      .pipe(takeUntil(this.stop$))
      .subscribe(isPartner => {
        this.isPartner = isPartner;
      });
    this.store
      .select(BrandSelectors.selectBrandState)
      .pipe(takeUntil(this.stop$))
      .subscribe(brandState => {
        this.experienceId = brandState.experienceId;
      });
    this.risk$ = this.store
      .select(PolicySelectors.selectVehicleRisks)
      .pipe(map(risk => risk.find(val => val.id === this.vehicleId)));

    this.insureds$ = this.risk$.pipe(
      map(risk => {
        const roles = risk.policy.policyRoles;
        const pni = roles.find(
          role => role.roleType.toLowerCase() === InsuredTypes.PRIMARYNAMEDINSURED
        );
        const sni = roles.find(
          role => role.roleType.toLowerCase() === InsuredTypes.SECONDARYNAMEDINSURED
        );
        const names = [];
        if (pni?.name) names.push(pni.name);
        if (sni?.name) names.push(sni.name);
        return names;
      })
    );

    this.agent$ = combineLatest([this.agents$, this.risk$]).pipe(
      filter(([agents, risk]) => agents?.length > 0 && !!risk),
      map(([agents, risk]) => agents.find(agent => agent.id === risk.producerId))
    );

    this.dataLoading$ = combineLatest([
      this.store.select(PolicySelectors.selectPoliciesLoading),
      this.risk$,
      this.store.select(agentQuery.getAgentsLoading)
    ]).pipe(map(([detailLoading, risk, agentLoading]) => detailLoading || !risk || agentLoading));

    this.coverages$ = this.risk$.pipe(
      map(risk => {
        const policyCoverages = risk.policy.policyCoverages
          .filter(
            coverage =>
              coverageCodeConst[coverage.code] !== undefined && coverage.limitTerms.length > 0
          )
          .map(coverage => coverageCodeConst[coverage.code])
          .join(' ');

        const vehicleCoverages = risk.vehicleCoverages
          .filter(coverage => coverageCodeConst[coverage.code] !== undefined)
          .map(coverage => coverageCodeConst[coverage.code])
          .join(' ');

        return policyCoverages + ' ' + vehicleCoverages;
      })
    );
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  ngOnInit(): void {
    this.spinner.start({ blockActions: true });
    this.dataLoading$.subscribe(loading => {
      if (loading === false) {
        this.spinner.stop();
      }
    });

    this.defaultContactNumber = this.copyService.getCopy('shared.contactNumber');
    this.footer = this.copyService.getCopy('policy.footerInfo');
    this.naicCode$ = this.risk$.pipe(map(risk => this.getNAIC(risk.amfamCompanyCode)));
    this.POIName$ = this.risk$.pipe(map(risk => this.getPOIName(risk.address.state)));

    this.stateDisclaimer$ = this.risk$.pipe(
      map(risk => this.getDisclaimer(risk.policy.policyAddress.state, risk.policy.sourceSystem))
    );

    this.azDOTNumber$ = this.risk$.pipe(
      filter(risk => risk.address.state === 'AZ'),
      switchMap(() => this.getAZDOTNumber(this.naicCode$))
    );
    this.risk$.pipe(filter(risk => !!risk)).subscribe(risk => {
      this.riskHasCancelDate = !!risk.cancelDate;
    });
    this.downloadPdf();
    this.buildLinks();
  }

  downloadPdf() {
    this.risk$
      .pipe(
        takeUntil(this.stop$),
        filter(risk => !!risk && !!risk.poiLink),
        first(),
        switchMap(risk => {
          this.spinner.start({ blockActions: true });
          return this.documentPipeService.retrieveBlobDocument(risk.poiLink);
        }),
        tap(blob => {
          if (blob)
            this.blob = new File([blob as Blob], 'Proof Of Insurance .pdf', {
              type: 'application/pdf'
            });
        }),
        finalize(() => {
          this.spinner.stop();
        })
      )
      .subscribe();
  }

  buildLinks() {
    this.breakpointObserver
      .observe('(max-width: 768px)')
      .pipe(
        tap(result => {
          this.links = [
            {
              name: 'Download',
              iconClass: 'icon-download',
              clickEvent: () => {
                this.analyticsFacade.trackButtonClick(poiAnalytics.downloadPoiButton);
                // If there is a cancel date, open a modal otherwise open statement
                if (this.riskHasCancelDate) {
                  this.modalService.open(this.hasEffectiveDatesChangedModalId);
                } else {
                  this.openStatement();
                }
              }
            },
            {
              name: 'Print',
              iconClass: 'icon-print',
              clickEvent: () => {
                this.analyticsFacade.trackButtonClick(poiAnalytics.printPoiButton);
                this.openStatement(true);
              }
            },
            {
              name: 'Share',
              iconClass: 'icon-share',
              clickEvent: () => {
                this.analyticsFacade.trackButtonClick(poiAnalytics.sharePoiButton);
                this.share();
              }
            },
            {
              name: 'File a Claim',
              iconClass: 'icon-claims2',
              clickEvent: () => {
                this.router.navigate(['/claims/report-claim-fnol']);
              }
            }
          ];
          if (result.matches) {
            if (this.links) this.links.length = 3;
          }
        })
      )
      .subscribe();
  }

  onVehicleCardClicked(risk: RiskModel) {
    this.analyticsFacade.trackButtonClick(poiAnalytics.viewCoveragePoiButton);
    const route = this.isPartner ? this.experienceId + '/policies/auto/' : '/policies/auto/';
    this.router.navigate([route, risk.policyNumber], {
      queryParams: { vehicleId: risk.vehicleId }
    });
  }

  getPOIName(state) {
    const poiName = POIName[state] || POIName['default'];
    const fullStateName = new StateAbbreviationPipe().transform(state);
    return `${fullStateName} ${poiName}`.toUpperCase();
  }

  getNAIC(companyCode: string) {
    return setNAIC[companyCode];
  }
  getAZDOTNumber(naicCode$: Observable<string>): Observable<string> {
    return naicCode$.pipe(map(code => arizonaDOTNumber[code]));
  }

  getDisclaimer(stateAbbrv: string, sourceSystem: SourceSystemType): string {
    const stateKey = stateMapping[stateAbbrv] || stateMapping['default'];
    const sourceKey = sourceMapping[sourceSystem] || sourceMapping[SourceSystemType.Legacy];
    return this.copyService.getCopy(`${stateKey}.${sourceKey}`);
  }

  public openStatementOnAndroid() {
    let windowRef;
    if (!window.navigator) {
      windowRef = this.win.nativeWindow.open();
    }
    try {
      const blobUrl = this.documentPipeService.getBlobUrl(this.blob);
      if (!blobUrl) {
        if (windowRef) {
          windowRef.close();
        }
      }
      const url = this.sanitizer.sanitize(SecurityContext.URL, blobUrl);
      this.spinner.stop();
      openWindowBlob(this.win, url, blobUrl, windowRef, 'document.pdf');
      if (print) this.print(windowRef);
    } catch (error) {
      if (windowRef) {
        windowRef.close();
      }
    }
  }

  public openStatementForEffectiveDateChange() {
    this.openStatement();
    this.modalService.close(this.hasEffectiveDatesChangedModalId);
  }

  public openStatement(print = false) {
    if (!this.blob) {
      this.eventDispatcherService.openNoDocumentModal(
        print ? DocumentActions.PRINT : DocumentActions.DOWNLOAD
      );
      // GA event for download failure
      if (!print) {
        const downloadFailureEvent: EventAnalyticParams = {
          event: 'proof_of_insurance_download_failure'
        };
        this.analyticsFacade.trackEvent(downloadFailureEvent);
      }
      return;
    }
    if (this.deviceService.device.toLowerCase() === 'android') this.openStatementOnAndroid();
    else {
      const windowRef = window.open('', '_blank');
      try {
        const blobUrl = this.documentPipeService.getBlobUrl(this.blob);
        if (!blobUrl) {
          // GA event for download failure
          if (!print) {
            const downloadFailureEvent: EventAnalyticParams = {
              event: 'proof_of_insurance_download_failure'
            };
            this.analyticsFacade.trackEvent(downloadFailureEvent);
          }
          if (windowRef) {
            windowRef.close();
          }
        }
        const url = this.sanitizer.sanitize(SecurityContext.URL, blobUrl);
        this.spinner.stop();

        // GA event for download success
        if (!print) {
          const downloadSuccessEvent: EventAnalyticParams = {
            event: 'proof_of_insurance_download_success'
          };
          this.analyticsFacade.trackEvent(downloadSuccessEvent);
        }
        windowRef.location.href = url;
        if (print) this.print(windowRef);
      } catch (error) {
        if (!print) {
          const downloadFailureEvent: EventAnalyticParams = {
            event: 'proof_of_insurance_download_failure'
          };
          this.analyticsFacade.trackEvent(downloadFailureEvent);
        }

        if (windowRef) {
          windowRef.close();
        }
      }
    }
  }

  private print(windowRef) {
    const handle = setInterval(() => {
      if (windowRef && windowRef.document.readyState === 'complete') {
        windowRef.focus();
        windowRef.print();
        clearInterval(handle);
      }
    }, 100);
  }

  private share() {
    if (navigator.share && this.blob) {
      navigator
        .share({
          title: 'Proof of Insurance',
          text: 'Proof of Insurance',
          files: [this.blob]
        })
        .then(() => console.log('PDF shared successfully'))
        .catch(error => console.error('Error sharing PDF:', error));
    } else this.eventDispatcherService.openNoDocumentModal(DocumentActions.SHARE);
  }
}
