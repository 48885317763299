import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import {
  DriverAssignment,
  ICoverage,
  Option,
  PolicyCoverage,
  Term,
  Vehicle,
  VehicleCoverage,
  VehiclesInPolicy
} from '@amfam/policy/models';

import * as Actions from '../+state/vehicle-coverages.actions';
import {
  addVehicleCoverage,
  deleteVehicleCoverages,
  FEATURES
} from '../+state/vehicle-coverages.actions';
import * as Selectors from '../+state/vehicle-coverages.selectors';

export interface UpdateDriverAssignmentsInput {
  vehicle: VehiclesInPolicy;
  driver: { fixedId: number; displayName: string };
  selectedId: string;
  driverAssignments: DriverAssignment[];
  action: ACTIONS;
}

/**
 * @description
 * @author @jshaw-af
 * @date 15/03/2022
 * @export
 * @enum {string}
 */
export enum ACTIONS {
  ASSIGN_DRIVER = 'ASSIGN_DRIVER',
  ASSIGN_VEHICLE = 'ASSIGN_VEHICLE'
}

/**
 * @description
 * @author @jshaw-af
 * @date 15/03/2022
 * @export
 * @class VehicleCoveragesFacade
 */
@Injectable({ providedIn: 'root' })
export class VehicleCoveragesFacade {
  ids$: Observable<string[] | number[]> = this.store.select(Selectors.selectIds);
  vehicleName$ = this.store.select(Selectors.selectVehicleName);
  rate$ = this.store.select(Selectors.selectRate);
  canRecalculate$: Observable<boolean> = this.store.select(Selectors.selectCanRecalculate);
  vehicleCoverages$: Observable<VehicleCoverage[]> = this.store.select(
    Selectors.selectVehicleCoverages
  );
  policyCoverages$: Observable<PolicyCoverage[]> = this.store.select(
    Selectors.selectPolicyCoverages
  );
  coverages$: Observable<ICoverage[]> = this.store.select(Selectors.selectCoverages);
  aficoverages$: Observable<ICoverage[]> = this.store.select(Selectors.selectAfiCoverages);
  appliedDiscounts$ = this.store.select(Selectors.selectAppliedDiscounts);
  driverAssignments$ = this.store.select(Selectors.selectDriverAssignments);
  vehicles$ = this.store.select(Selectors.selectVehicles);
  filteredVehicles$ = this.store.select(Selectors.selectFilteredVehicles);
  drivers$ = this.store.select(Selectors.selectDriversFixedIdDisplayName);
  filteredDrivers$ = this.store.select(Selectors.selectFilteredDrivers);
  selectedId$ = this.store.select(Selectors.selectSelectedId);
  workOrderNumberAndPolicyNumber$ = this.store.select(
    Selectors.selectWorkOrderNumberAndPolicyNumber
  );
  primaryDrivers$: Observable<unknown> = this.store.select(Selectors.selectPrimaryDrivers);
  policyProducingAgents$ = this.store.select(Selectors.selectAdvanceAutoPolicyProducingAgents);
  vehicleFees$ = this.store.select(Selectors.selectVehicleFees);
  pni$ = this.store.select(Selectors.selectPNI);
  vehicleId$ = this.store.select(Selectors.selectVehicleId);
  baseId$ = this.store.select(Selectors.selectBaseId);
  baseEntity$ = this.store.select(Selectors.selectBaseEntity);
  idsExceptBaseId$ = this.store.select(Selectors.selectIdsExceptBaseId);
  error$ = this.store.select(Selectors.selectError);
  constructor(private store: Store) {}

  resetQuote() {
    this.store.dispatch(Actions.resetQuote());
  }

  saveAndRate(feature: Actions.FEATURES, vehicleId: string = undefined) {
    this.store.dispatch(Actions.saveAndRate({ feature, vehicleId: vehicleId }));
  }

  loadInitialRate(feature: Actions.FEATURES, vehicleId: string = undefined) {
    this.store.dispatch(Actions.LoadInitialRate({ feature, vehicleId: vehicleId, initial: true }));
  }

  changeCoverage(vehicle: Vehicle) {
    this.store.dispatch(Actions.changeCoverageAPI({ vehicle }));
  }

  cancelChangeCoverage() {
    this.store.dispatch(Actions.cancelChangeCoverage());
  }

  bindChangeCoverage(vehicleId: string) {
    this.workOrderNumberAndPolicyNumber$
      .pipe(
        first(),
        tap(policy =>
          this.store.dispatch(
            Actions.bindChangeCoverageQuote({
              payload: {
                workOrderNumber: policy.workOrderNumber,
                policyNumber: policy.policyNumber.replace(/-/gi, ''),
                vehicleId,
                vin: policy.vin
              }
            })
          )
        )
      )
      .subscribe();
  }

  getBindCoverageAction() {
    return this.workOrderNumberAndPolicyNumber$.pipe(
      withLatestFrom(this.vehicleId$),
      first(),
      map(([policy, vehicleId]) =>
        Actions.bindChangeCoverageQuote({
          payload: {
            workOrderNumber: policy.workOrderNumber,
            policyNumber: policy.policyNumber.replace(/-/gi, ''),
            vehicleId,
            vin: policy.vin
          }
        })
      )
    );
  }

  getPartnerAddVehicleCoverageAction() {
    return this.workOrderNumberAndPolicyNumber$.pipe(
      withLatestFrom(this.vehicleId$),
      first(),
      map(([policy, vehicleId]) =>
        Actions.bindPartnerAddVehicleChangeCoverageQuote({
          payload: {
            workOrderNumber: policy.workOrderNumber,
            policyNumber: policy.policyNumber.replace(/-/gi, ''),
            vehicleId,
            vin: policy.vin
          }
        })
      )
    );
  }

  /**
   * @description
   * @author @jshaw-af
   * @date 15/03/2022
   * @memberof VehicleCoveragesFacade
   */
  resetDriverAssignments() {
    this.selectedId$.subscribe(id => {
      this.store.dispatch(
        Actions.updateVehicleCoverage({
          update: {
            id: id.toString(),
            changes: {
              driverAssignments: []
            }
          }
        })
      );
    });
  }

  /**
   * @description
   * @author @jshaw-af
   * @date 15/03/2022
   * @param {UpdateDriverAssignmentsInput} payload
   * @memberof VehicleCoveragesFacade
   */
  updateDriverAssignments(payload: UpdateDriverAssignmentsInput) {
    switch (payload.action) {
      case ACTIONS.ASSIGN_VEHICLE:
        this.store.dispatch(
          Actions.updateVehicleCoverage({
            update: {
              id: payload.selectedId,
              changes: {
                driverAssignments: [
                  ...payload.driverAssignments.filter(
                    d => payload.driver.fixedId.toString() !== d.driverId.toString()
                  ),
                  {
                    driverId: payload.driver.fixedId,
                    driverName: payload.driver.displayName,
                    primary: true,
                    vehicleDisplayName: payload.vehicle.description,
                    vehicleId: payload.vehicle.fixedId,
                    vin: payload.vehicle.vin
                  }
                ]
              }
            }
          })
        );
        break;
      case ACTIONS.ASSIGN_DRIVER:
        this.store.dispatch(
          Actions.updateVehicleCoverage({
            update: {
              id: payload.selectedId,
              changes: {
                driverAssignments: [
                  ...payload.driverAssignments.filter(d => payload.vehicle.vin !== d.vin),
                  {
                    driverId: payload.driver.fixedId,
                    driverName: payload.driver.displayName,
                    primary: true,
                    vehicleDisplayName: payload.vehicle.description,
                    vehicleId: payload.vehicle.fixedId,
                    vin: payload.vehicle.vin
                  }
                ]
              }
            }
          })
        );
        break;

      default:
        break;
    }
  }

  updateCoverage(payload: {
    selectedId: string;
    coverage: ICoverage;
    term: Term;
    option?: Option;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
  }) {
    this.vehicleCoverages$.pipe(take(1)).subscribe(vcs => {
      this.store.dispatch(
        Actions.updateCoverage({
          update: {
            id: payload.selectedId.toString(),
            changes: {
              vehicleCoverages: vcs.map(vc => ({
                ...vc,
                coverages: vc.coverages.map(c => {
                  if (c.publicID === payload.coverage.publicID) {
                    if (
                      c.hasTerms &&
                      c.terms &&
                      c.terms.length > 0 &&
                      c.terms[0].options.length > 1
                    ) {
                      const terms = c.terms.map(t => {
                        if (t.name === payload.term.name) {
                          return {
                            ...t,
                            updated: true,
                            chosenTerm: t.options.filter(
                              o => o.name === (payload.option as unknown as string)
                            )[0].code,
                            chosenTermValue: payload.option as unknown as string
                          };
                        }
                        return t;
                      });

                      return {
                        ...c,
                        selected: !!payload.option,
                        updated: c.terms.some(
                          t =>
                            t.name === payload.term.name &&
                            t.chosenTermValue !== (payload.option as unknown as string)
                        ),
                        terms: [...terms]
                      };
                    } else {
                      return {
                        ...c,
                        updated: !!payload.value,
                        selected: !!payload.value,
                        isRejected: !payload.value
                      };
                    }
                  }
                  return { ...c };
                })
              }))
            }
          }
        })
      );
    });
  }

  resetToBaseEntity(feature: FEATURES) {
    this.baseEntity$
      .pipe(
        withLatestFrom(this.idsExceptBaseId$, this.vehicleId$),
        filter(([baseEntity, ids, vehicleId]) => !!baseEntity && !!ids && !!vehicleId),
        first(),
        tap(([baseEntity, ids, vehicleId]) => {
          this.store.dispatch(deleteVehicleCoverages({ ids }));
          this.store.dispatch(
            addVehicleCoverage({
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              payload: { ...baseEntity, id: uuid() } as any,
              feature: feature,
              vehicleId: vehicleId.toString()
            })
          );
        })
      )
      .subscribe();
  }

  clearError() {
    this.store.dispatch(Actions.clearError());
  }

  resetAll() {
    this.store.dispatch(Actions.resetAll());
  }
}
