// eslint-disable-next-line
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { get as _get } from 'lodash';

import { ApiStatus, UNAVAILABLE_PAYMENTACCOUNT_ID } from '@amfam/shared/models';

import { PaymentAccountActions, PaymentAccountActionTypes } from './paymentaccount.actions';

export interface PaymentAccountNotificationState {
  nickName: string;
  error?: ApiStatus | null;
  success?: ApiStatus | null;
  loading: boolean;
  correlationId?: string | null;
}

export interface NotificationState extends EntityState<PaymentAccountNotificationState> {
  loading: boolean;
  error: ApiStatus | null;
}

export const adapter: EntityAdapter<PaymentAccountNotificationState> =
  createEntityAdapter<PaymentAccountNotificationState>({
    selectId: (paymentAccountNotificationState: PaymentAccountNotificationState) =>
      paymentAccountNotificationState?.nickName,
    sortComparer: false
  });

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: PaymentAccountActions
): NotificationState {
  switch (action.type) {
    case PaymentAccountActionTypes.PaymentAccountsLoadType: {
      return { ...initialState, loading: true };
    }
    case PaymentAccountActionTypes.PaymentAccountsLoadDetailType: {
      return { ...state, loading: true };
    }

    case PaymentAccountActionTypes.PaymentAccountsLoadCompleteType: {
      return { ...state, loading: false };
    }

    case PaymentAccountActionTypes.PaymentAccountsSaveType:
    case PaymentAccountActionTypes.PaymentAccountsAddType: {
      const savePaymentId = _get(
        action,
        'payload.currentPaymentAccountNickName',
        UNAVAILABLE_PAYMENTACCOUNT_ID
      );

      return adapter.upsertOne(
        {
          nickName: savePaymentId,
          error: null,
          correlationId: null,
          loading: true
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsSaveFailType:
    case PaymentAccountActionTypes.PaymentAccountsAddFailType: {
      return adapter.upsertOne(
        {
          nickName: _get(
            action,
            'payload.request.currentPaymentAccountNickName',
            UNAVAILABLE_PAYMENTACCOUNT_ID
          ),
          error: action.payload.status,
          correlationId: _get(action, 'payload.request.correlationId'),
          loading: false
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsAddSuccessType: {
      return adapter.upsertOne(
        {
          nickName: _get(action.payload, 'request.finAcctPayload.nickName'),
          correlationId: _get(action, 'payload.request.correlationId'),
          error: null,
          loading: false
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsEditType: {
      return adapter.upsertOne(
        {
          nickName: _get(action, 'payload.currentPaymentAccountNickName'),
          loading: true
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsEditFailType: {
      return adapter.upsertOne(
        {
          nickName: _get(action, 'payload.request.finAcctPayload.nickName'),
          loading: false,
          error: action.payload.status,
          correlationId: _get(action, 'payload.request.correlationId')
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsEditSuccessType: {
      const previousNickName = String(
        _get(action, 'payload.request.currentPaymentAccountNickName')
      );
      const newNickName = String(_get(action, 'payload.request.finAcctPayload.nickName'));

      state = adapter.removeOne(previousNickName, state);
      return adapter.upsertOne(
        {
          nickName: newNickName,
          loading: false,
          correlationId: _get(action, 'payload.request.correlationId')
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsUpdateModeAuthType: {
      return adapter.upsertOne(
        {
          nickName: _get(action, 'payload.request.paymentAccount.nickName', ''),
          loading: true
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsUpdateModeAuthFailType: {
      return adapter.upsertOne(
        {
          nickName: _get(action, 'payload.request.paymentAccount.nickName', ''),
          loading: false,
          error: action.payload.status,
          correlationId: _get(action, 'payload.request.correlationId')
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsUpdateModeAuthSuccessType: {
      return adapter.upsertOne(
        {
          nickName: _get(action, 'payload.request.paymentAccount.nickName', ''),
          loading: false,
          error: null
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsDeleteType: {
      return adapter.upsertOne(
        {
          nickName: _get(action, 'payload.nickName', ''),
          loading: true,
          correlationId: _get(action, 'payload.request.correlationId')
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsDeleteFailType: {
      return adapter.upsertOne(
        {
          nickName: _get(action, 'payload.request.nickName', ''),
          loading: false,
          error: action.payload.status,
          correlationId: _get(action, 'payload.request.correlationId')
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsDeleteSuccessType: {
      return adapter.upsertOne(
        {
          nickName: _get(action, 'payload.request.nickName', ''),
          loading: false,
          error: null,
          correlationId: _get(action, 'payload.request.correlationId')
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsCleanupType: {
      return adapter.upsertOne(
        {
          nickName: action?.payload || '',
          loading: false,
          error: null,
          correlationId: null
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsTruncateType: {
      return adapter.removeOne(action.payload, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
