import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';

import { PipesModule } from '../../../../shared/ui/pipes/src/lib/pipes.module';
import { InsuranceCardComponent } from './insurance-card/insurance-card.component';
import {
  OverviewCardComponent,
  OverviewCardContentComponent,
  OverviewCardCtaComponent,
  OverviewCardHeadingComponent,
  OverviewCardSubheadingComponent
} from './overview-card';
import {
  OverviewSectionComponent,
  OverviewSectionContentComponent,
  OverviewSectionHeaderLinkComponent
} from './overview-section/overview-section.component';

@NgModule({
  declarations: [
    OverviewCardComponent,
    OverviewCardContentComponent,
    OverviewCardCtaComponent,
    OverviewCardHeadingComponent,
    OverviewCardSubheadingComponent,
    OverviewSectionComponent,
    OverviewSectionContentComponent,
    OverviewSectionHeaderLinkComponent,
    InsuranceCardComponent
  ],
  exports: [
    OverviewCardComponent,
    OverviewCardContentComponent,
    OverviewCardCtaComponent,
    OverviewCardHeadingComponent,
    OverviewCardSubheadingComponent,
    OverviewSectionComponent,
    OverviewSectionContentComponent,
    OverviewSectionHeaderLinkComponent,
    InsuranceCardComponent
  ],
  imports: [CommonModule, UiKitModule, RouterModule, PipesModule, SharedServicesModule]
})
export class OverviewUiModule {}
