<div>
  <div class="content" tabindex="-1">
    <span class="icon-alert block"></span>
    <h1 content [contentPath]="'enrollment.verifyEmailTokenExpiredText'"></h1>
    <div class="row">
      <p
        class="margin-bottom-20 margin-top-40 column six text-center fill"
        content
        [contentPath]="'enrollment.verifyEmailTokenExpiredSorry'"
      ></p>
    </div>
    <div class="row">
      <p class="margin-bottom-20 margin-top-40 column six text-center fill">
        You can restart the password reset process to request a new link or choose one of the
        options below.
      </p>
    </div>
    <div class="row margin-bottom-40">
      <div class="column twelve text-center">
        <button
          ds-button
          class="-offset-bottom"
          type="button"
          [dsRouterLink]="['/forgot-password']"
        >
          Try Again
        </button>
      </div>
    </div>
    <div class="row pad-top-20 text-center"><p class="helpText">Need help?</p></div>
    <div class="row margin-top-20">
      <div class="column four text-center">
        <span class="icon icon-calendar"></span>
        <a [href]="scheduleACallbackUrl" target="amfam">Schedule a callback</a>
      </div>
      <div class="column four text-center">
        <span class="icon icon-feedback"></span>
        <a [href]="amfamChatUrl" target="amfam">Live chat</a>
      </div>
      <div class="column four text-center">
        <span class="icon icon-call"></span>
        <a
          dsPartnerContent
          [brandContent]="'selectPrettyCusCareNumber'"
          [brandHref]="'selectCusCareNumberFullHref'"
          target="_blank"
        ></a>
      </div>
    </div>
  </div>
</div>
