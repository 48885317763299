import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ProfileAnalytics } from '@amfam/shared/models';
import {
  Applications,
  ApplicationService,
  EventDispatcherService
} from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-delete-my-account-confirmation',
  templateUrl: './delete-my-account-confirmation.component.html',
  styleUrls: ['./delete-my-account-confirmation.component.scss']
})
export class DeleteMyAccountConfirmationComponent implements OnInit, OnDestroy {
  isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
  protected stop$: Subject<void> = new Subject<void>();
  constructor(
    private analyticsFacade: AnalyticsFacade,
    private appService: ApplicationService,
    private eventDispatcher: EventDispatcherService
  ) {}

  ngOnInit() {
    this.analyticsFacade.trackPage(ProfileAnalytics.deleteMyAccountConfirmationPageAnalytics);
    this.analyticsFacade.trackEvent(ProfileAnalytics.deleteMyAccountConfirmationEvent);
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  backToLogin() {
    this.eventDispatcher.logout();
  }
}
