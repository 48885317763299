import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { fromRouterActions } from '@amfam/shared/utility/navigation';

import * as fromRoot from '../../../core/store';

@Injectable()
export class ResetOptionsGuard {
  constructor(private store: Store<fromRoot.RootState>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(fromRoot.isResetOptionsUnlocked).pipe(
      switchMap(unlocked => {
        // Returns true if userID has been accepted and user is not already verified
        if (unlocked) {
          return of(true);
        }
        // Returns false and routes to first step of Forgot Password flow
        this.store.dispatch(
          fromRouterActions.Go({
            path: ['/forgot-password']
          })
        );
        return of(false);
      })
    );
  }
}
