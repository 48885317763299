export interface AddVehicleNotificationRequestAgentEmail {
  typeofEmail: typeOfAgentEmail;
  agentEmailAddress: string;
  agentName: string;
  customerName: string;
  policyNumber: string;
  newVehicleDetails?: string;
  newVehicleVin?: string;
  newVehicleMsrp?: string;
  estimatedVehiclePrice?: string;
  existingVehicle?: string;
  odometerReading?: string;
  newVehicleDiscountActive?: boolean | string;
  newVehicleDiscountDays?: string;
  vehicleMainUse?: string;
  annualMileage?: string;
  vehicleAddress?: string;
  vehicleSecurityEquipmentFound?: string;
  vehicleSecurityEquipmentEntered?: string;
  vehiclePrimaryDriver?: string;
  vehicleHasOwnerCoOwner?: boolean | string;
  vehicleUsedForBusiness?: boolean | string;
  vehicleKitCarReplia?: boolean | string;
  vehicleHasTPI?: boolean | string;
  vehicleUMPDSelected?: vehicleCoverageSelectionOptions;
  vehicleUIMPDSelected?: vehicleCoverageSelectionOptions;
  vehicleFullGlassCoverageSelected?: vehicleCoverageSelectionOptions;
  vehicleWorkOrderNumber?: string;
  hasAgencyEmail?: boolean;
  classicOnlyAutoPolicyOrCompOnlyAdv?: boolean;
}

export interface AddVehicleNotificationRequestPartnerEmail {
  typeofEmail: typeOfAgentEmail;
  partnerEmailAddress: string;
  customerName: string;
  policyNumber: string;
  newVehicleDetails?: string;
  newVehicleVin?: string;
  newVehilceMsrp?: string;
  estimatedVehiclePrice?: string;
  odometerReading?: string;
  newVehicleDiscountActive: boolean | string;
  newVehicleDiscountDays: string;
  vehicleMainUse?: string;
  annualMileage?: string;
  vehicleAddress?: string;
  vehicleSecurityEquipmentFound?: string;
  vehicleSecurityEquipmentEntered?: string;
  vehiclePrimaryDriver?: string;
  vehicleHasOwnerCoOwner?: boolean | string;
  vehicleUsedForBusiness?: boolean | string;
  vehicleKitCarReplia?: boolean | string;
  vehicleHasTPI?: boolean | string;
  vehicleUnrepairedDamage?: boolean | string;
  vehicleUMPDSelected?: vehicleCoverageSelectionOptions;
  vehicleUIMPDSelected?: vehicleCoverageSelectionOptions;
  vehicleFullGlassCoverageSelected?: vehicleCoverageSelectionOptions;
  vehicleWorkOrderNumber?: string;
  vehicleLeaseStartDate?: string;
  newVehicleUnderMileageLimit?: string;
}

export interface AddVehicleNotificationRequestCustomerEmail {
  customerEmailAddress: string;
  customerFirstName: string;
  producerID: string;
  policyNumber: string;
  newRiskInformation: string;
  newTotalPremium: number;
  newMonthlyPremium: number;
  newTermDescription: string;
  drivers: string[];
  garageAddress: string;
  coverages: string[];
  fees: string[];
  discounts: [];
}

export enum typeOfAgentEmail {
  vehicleBoundNoTPI = 'vehicleBoundNoTPI',
  vehicleBoundNeedsTPI = 'vehicleBoundNeedsTPI',
  vehicleMileageUnder500 = 'vehicleMileageUnder500',
  vehicleGarageAddressUnknown = 'vehicleGarageAddressUnknown',
  vehicleHasKitBusinessCoOwnerFlag = 'vehicleHasKitBusinessCoOwnerFlag',
  vehicleClassicPolicy = 'vehicleClassicPolicy',
  vehicleCancelledTransaction = 'vehicleCancelledTransaction',
  expectationSettingAgent = 'expectationSetting'
}

export enum vehicleCoverageSelectionOptions {
  Yes = 'Yes',
  Rejected = 'Rejected',
  NotProvided = 'Not Provided',
  NotApplicable = 'N/A'
}

export enum vehiclePrimaryUse {
  workschool = 'Work or school',
  pleasure = 'Leisure',
  farming = 'Farming year-round (trucks only)',
  business = 'Business',
  antique = 'It’s a classic car'
}

export enum vehicleOwnershipDate {
  Today = 'Today!',
  ThisWeek = 'This week',
  ThisMonth = 'This month',
  OneToThreeMonths = '1 - 3 months',
  MoreThanThreeMonths = '3+ months'
}

export enum vehiclePartnerUse {
  WorkLessThan10 = 'Work or school less than 10 miles away',
  WorkMoreThan10 = 'Work or school more than 10 miles away',
  pleasure = 'Leisure',
  farming = 'Farming year-round (trucks only)',
  business = 'Business'
}
export enum vehicleCAPartnerUse {
  Commute3ToLessThan10 = 'Commute 3 - 9.9',
  CommuteEqualOrGreaterThan10 = 'Commute 10+',
  PleasureCommute = 'Pleasure/Commute 0 - 2.9',
  BusinessOccupation = 'Business/Occupation other than Farm'
}

export interface EnrollmentsResponse {
  enrolledPolicies: PolicyEnrollment[];
}
interface VehicleEnrollment {
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vin: string;
  enrollmentSummary?: EnrollmentSummary;
}
interface PolicyEnrollment {
  policyNumber: string;
  enrolledVehicles: VehicleEnrollment[];
}

interface EnrollmentSummary {
  enrollmentAckDate: string;
  enrollmentId: number;
  enrollmentStatus: string;
  recentOptOutDate: string;
  waitingPeriodEndDate?: string;
}
