import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClaimFnolEffects } from './+state/claim-fnol.effects';
import {
  CLAIMFNOL_FEATURE_KEY,
  initialState as claimFnolInitialState,
  claimFnolReducer
} from './+state/claim-fnol.reducer';
import { SubmitDraftClaimAdapter } from './services/claim-fnol.adapter';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CLAIMFNOL_FEATURE_KEY, claimFnolReducer),
    EffectsModule.forFeature([ClaimFnolEffects])
  ],
  providers: [SubmitDraftClaimAdapter]
})
export class ClaimFnolDataAccessModule {}
