import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Store
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ConfigService } from '@amfam/shared/utility/shared-services';

import * as fromRoot from '../../core/store';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {
  private emailValidationPath: string;

  constructor(
    private authService: AuthService,
    private config: ConfigService,
    private store: Store<fromRoot.RootState>,
    private featureFlagService: FeatureFlagService
  ) {
    this.emailValidationPath = this.config.get('profileValidateEmailPath');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.featureFlagService.isEnabled('forgerock')) {
      return of(true);
    }

    const url = state.url;
    return this.checkAuth(url);
  }

  canLoad(route: Route) {
    const url = route.path;
    return this.checkAuth(url);
  }

  private checkAuth(url: string): Observable<boolean> {
    // redirect to intended URL after logging in
    this.authService.redirectUrl = url;
    if (this.authService.isLoggedIn()) {
      return of(true);
    }

    // If altAuth verified, return true to allow email validation
    if (this.authService.isAltAuthVerified) {
      if (url === '/profile') {
        return of(this.sendToLogin(url));
      }
      this.authService.redirectUrl = '/';
      return of(true);
    }

    return this.authService.refresh().pipe(
      map(authenticated => {
        if (!authenticated) {
          // Send to email validation login page if coming from email
          if (url.includes(this.emailValidationPath)) {
            return this.sendToEmailValidation(url);
          }
          return this.sendToLogin(url);
        }
        return true;
      }),
      catchError(() => {
        // Send to email validation login page if coming from email
        if (url.includes(this.emailValidationPath)) {
          return of(this.sendToEmailValidation(url));
        }
        return of(this.sendToLogin(url));
      })
    );
  }

  private sendToLogin(url: string): boolean {
    this.authService.redirectUrl = url;
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/login']
      })
    );
    return false;
  }

  private sendToEmailValidation(url: string): boolean {
    this.authService.redirectUrl = url;
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/email-validation']
      })
    );
    return false;
  }
}
