import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import DOMPurify from 'dompurify';
import { filter, first, Observable, tap } from 'rxjs';

import { DSPMessages } from '@amfam/shared/models';
import { SessionEventsService, WindowRef } from '@amfam/shared/utility/shared-services';

import { isOpportunityNotified } from '../+state';
import { OpportunitiesFeedbackAction } from '../+state/opportunity.action';
import { CustomerFeedbackEnum } from '../models/feedback';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  private renderer: Renderer2;
  private stopListener;
  recommendationId;
  constructor(
    private rendererFactory: RendererFactory2,
    private win: WindowRef,
    private sessionEventsService: SessionEventsService,
    private router: Router,
    private store: Store
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private _dspUrl: string;
  public get dspUrl(): string {
    return this._dspUrl || localStorage.getItem('dspUrl');
  }

  public set dspUrl(value: string) {
    this._dspUrl = value;
    localStorage.setItem('dspUrl', value);
  }

  sendFeedback(recommendationId: string): void {
    this.store.dispatch(
      new OpportunitiesFeedbackAction(
        {
          feedbackActionCode: CustomerFeedbackEnum.CustomerRequestedQuote,
          producerId: null,
          recommendationId: recommendationId,
          isMultiAgent: false
        },
        true
      )
    );
  }

  private isNotified(recommendationId: string): Observable<boolean> {
    return this.store.select(isOpportunityNotified(recommendationId)).pipe(
      filter(notified => !!notified),
      first()
    );
  }

  private goBack() {
    this.router
      .navigate(['/overview'])
      .then(() => {
        this.win.nativeWindow.location.reload();
      })
      .catch(() => {
        this.win.nativeWindow.location.reload();
      });
  }

  listenForMessages() {
    // Start listening for messages
    console.log('MyAccount => DSP: Listening for messages !');
    this.stopListener = this.renderer.listen(this.win.nativeWindow, 'message', event => {
      if (
        !this.dspUrl ||
        event.origin !== new URL(this.dspUrl).origin ||
        typeof event.data !== 'string'
      )
        return;

      const cleanMessage = DOMPurify.sanitize(String(event.data || ''));

      // Register user activity !
      console.log('MyAccount => DSP:Incoming Message from DSP ', event);
      if (cleanMessage === DSPMessages.MY_ACCOUNT_SESSION_ACTIVE)
        this.sessionEventsService.triggerUserActivity();
      else if (cleanMessage === DSPMessages.MY_ACCOUNT_BIND_CONFIRMATION) {
        event && event.source && event.source.close();
        if (this.recommendationId) {
          this.sendFeedback(this.recommendationId);
          this.isNotified(this.recommendationId)
            .pipe(tap(() => this.goBack()))
            .subscribe();
        } else this.goBack();
      }
    });
  }

  stopListening() {
    // Stop Listener
    this.stopListener();
    localStorage.removeItem('dspUrl');
  }
}
