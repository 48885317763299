import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { filter, take, takeUntil } from 'rxjs/operators';

import { EmailPayloadToGetValidationStatus } from '@amfam/profile/data-access';
import { Party, PartyEmail, PartyPhone, PartyPhoneUsageCode } from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { HttpClient } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { UpdateMembershipPayload } from '../+state/profile.actions';

@Injectable({
  providedIn: 'root'
})
export class ProfileUtilService {
  private endpoint: string;
  private stop$ = new Subject<void>();
  constructor(
    private config: ConfigService,
    private store: Store,
    private http: HttpClient
  ) {
    this.store
      .select(userQuery.getCustomerId)
      .pipe(takeUntil(this.stop$))
      .subscribe(customerId => {
        if (customerId) {
          this.endpoint = this.config.get('profile') + '/' + customerId; //Not sure what the endpoint will be
        }
      });
  }

  public flattenPhones(phones: PartyPhone[]) {
    const flatPhones: PartyPhone[] = [];
    for (const phone of phones) {
      for (const contactMethodUsage of phone.contactMethodUsages) {
        const contactMethodUsages = new Array<{
          typeOfUsageCode: PartyPhoneUsageCode;
          descriptionForOtherType?: string;
        }>();
        contactMethodUsages.push(contactMethodUsage);
        const flatPhone = Object.assign({}, phone, {
          tenDigitPhoneNumber: phone.tenDigitPhoneNumber,
          contactMethodUsages: contactMethodUsages
        });
        flatPhones.push(flatPhone);
      }
    }
    return flatPhones;
  }

  public getOldAndNewPrimaryEmail(
    selectedEmail: PartyEmail,
    payload: Party
  ): EmailPayloadToGetValidationStatus {
    let data: EmailPayloadToGetValidationStatus;
    this.store
      .select(userQuery.getMyAccountEmail)
      .pipe(
        filter(email => !!email),
        take(1)
      )
      .subscribe(currentMyAccountEmail => {
        let futureMyAccountEmail: PartyEmail;
        // This is to know the emailId as selectedEmail don't have.
        if (_get(payload, 'emails')) {
          futureMyAccountEmail = payload.emails.find(
            email => selectedEmail.emailAddress === email.emailAddress
          );
        }
        data = this.getOldAndNewPrimaries(currentMyAccountEmail, futureMyAccountEmail);
      });
    return data;
  }

  public getOldAndNewPrimaries(
    currentMyAccountEmail: PartyEmail,
    futureMyAccountEmail: PartyEmail
  ): EmailPayloadToGetValidationStatus {
    const oldPrimary: PartyEmail = !currentMyAccountEmail
      ? undefined
      : Object.assign(
          {},
          {
            emailAddress: currentMyAccountEmail.emailAddress,
            emailId: currentMyAccountEmail.emailId,
            contactMethodUsages: currentMyAccountEmail.contactMethodUsages
          }
        );

    const newPrimary: PartyEmail = Object.assign(
      {},
      {
        emailAddress: futureMyAccountEmail.emailAddress,
        emailId: futureMyAccountEmail.emailId,
        contactMethodUsages: futureMyAccountEmail.contactMethodUsages
      }
    );
    return {
      newEntry: newPrimary,
      oldEntry: oldPrimary,
      type: 'MYACCOUNT'
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editMembership(payload: UpdateMembershipPayload): Observable<any> {
    /* const url = this.endpoint + '/membership';
    const body = {
      authId: this.config.get('processId'),
      ...payload
    };
    return this.http.post(url, body); */
    // When API is available this piece should be removed.
    if (payload.membershipNumber.endsWith('400'))
      return of({
        status: {
          code: 400,
          messages: [
            {
              messageCode: 400003,
              reason: 'Invalid Request'
            }
          ]
        }
      });
    else if (payload.membershipNumber.endsWith('400018'))
      return of({
        status: {
          code: 400,
          messages: [
            {
              messageCode: 400018,
              reason: 'Invalid Policy'
            }
          ]
        }
      });
    else if (payload.membershipNumber.endsWith('403017')) {
      return of({
        status: {
          code: 400,
          messages: [
            {
              messageCode: 403017,
              reason: 'Invalid membership number'
            }
          ]
        }
      });
    } else {
      return of({ status: { code: 200, reason: 'OK', response: true } });
    }
    // When API is available this piece should be removed.
  }
}
