import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentPipeService {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  retrieveDocument(url: string) {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
      catchError(() => of(''))
    );
  }

  retrieveBlobDocument(url: string) {
    return this.http.get(url, { responseType: 'blob' }).pipe(catchError(() => of('')));
  }

  getBlobUrl(blob: Blob | MediaSource) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
  }
}
