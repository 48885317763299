<div [class]="showForm ? 'container' : ''">
  @if (!showForm) {
    <div class="solidBorderBottom">
      @if (showPending) {
        <div class="row1 pending">
          <div class="column margin-right-5">
            <span class="icon-clock-repeat icon"></span>
          </div>
          <span
            content
            [contentPath]="'profile.membership.editMembershipPending'"
            class="pendingText"
          ></span>
        </div>
      }
      <div [class]="showPending ? 'row1' : ''">
        <p>
          <span
            content
            [contentPath]="'profile.membership.membershipType'"
            class="field-heading"
          ></span>
        </p>
        <p class="field-value">{{ membershipTypeVal$ | async }}</p>
      </div>

      <div class="row1 readOnlyContainer">
        <div>
          <p>
            <span
              content
              [contentPath]="'profile.membership.membershipNumber'"
              class="field-heading"
            ></span>
          </p>
          <p class="field-value">{{ membershipNumberVal$ | async }}</p>
        </div>
        @if (!showPending) {
          <a (click)="editMembership()" data-cy="edit-membership-link"
            >Edit<span class="margin-top-5 float-right icon-chevron-right-thick"></span
          ></a>
        }
      </div>
    </div>
  }
  @if (showForm) {
    <p>
      <span
        content
        [contentPath]="'profile.membership.editMembershipTitle'"
        class="form-heading"
      ></span>
    </p>
    <p>
      <span
        content
        [contentPath]="'profile.membership.editMembershipSubtitle'"
        class="field-subHeading"
      ></span>
    </p>
    <form [formGroup]="membershipForm" (ngSubmit)="submit()">
      <div class="row">
        <p class="form-input">
          <span
            content
            [contentPath]="'profile.membership.editMembershipType'"
            class="form-input-heading"
          ></span>
        </p>
        <ds-radio-group
          data-cy="costcoMembershipOptionsRadioButtons"
          class="radio-group-container"
          [control]="'membershipType'"
          [parentFormGroup]="membershipForm"
          [options]="membershipOptions"
        >
        </ds-radio-group>
      </div>
      <div class="row">
        <label
          for="membershipNumber"
          class="form-input-heading"
          content
          [contentPath]="'profile.membership.editMembershipNumber'"
        ></label>

        <ds-form-control-container [control]="membershipForm.get('membershipNumber')">
          <input
            type="text"
            id="membershipNumber"
            formControlName="membershipNumber"
            autocomplete="off"
            data-cy="membershipNum"
            tabindex="0"
            class="numberField"
            (change)="changeHandler()"
          />

          <ds-form-control-message
            [control]="membershipForm.controls.membershipNumber"
            [validateOnSubmit]="submitted"
          ></ds-form-control-message>
        </ds-form-control-container>
      </div>
      @if (hasError) {
        <div class="row" style="margin-bottom: 0px">
          <ds-amfam-dsn-alert
            [action]="false"
            [icon]="true"
            [dismiss]="false"
            [type]="'error'"
            [message]="this.alertErrorMessage"
            [applyMargin]="false"
          >
          </ds-amfam-dsn-alert>
        </div>
      }
      @if (!hasError) {
        <div class="row" style="margin-bottom: 0px">
          <ds-amfam-dsn-alert
            [action]="false"
            [icon]="true"
            [dismiss]="false"
            [type]="'info'"
            [message]="this.alertMessage"
            [applyMargin]="false"
          >
          </ds-amfam-dsn-alert>
        </div>
      }
      <div class="row"><hr /></div>
      <call-to-action
        [primaryButtonName]="primaryButtonName"
        [displayBackButton]="false"
        [tertiaryButtonName]="tertiaryButtonName"
        (tertiaryEvent)="editMembershipCancel()"
      >
      </call-to-action>
    </form>
  }
</div>
