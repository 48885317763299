<div class="jump-link" [ngClass]="size || ''">
  <button
    type="button"
    class="jump-link-button"
    [@scrollAnimation]
    [ngClass]="jumpLink.iconClass || ''"
    (click)="jumpLink.clickEvent()"
  >
    @if (jumpLink.iconUrl) {
      <img [src]="'/assets/images/' + jumpLink.iconUrl" />
    }
  </button>
  <div class="jump-link-name">{{ jumpLink.name }}</div>
</div>
