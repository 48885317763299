import { ApplicationRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Action, DockingBarService } from '@amfam/ui-kit';

@Directive()
export abstract class FooterLinksComponentDirective implements OnInit, OnDestroy {
  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    protected dockingService: DockingBarService,
    private route: ActivatedRoute,
    private application: ApplicationRef
  ) {}

  ngOnInit() {
    const action = new Action('Print', () => {
      window.print();
    });
    this.dockingService.registerAction(action);
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  protected onContentLoad() {
    this.route.queryParams.pipe(takeUntil(this.stop$)).subscribe(queryParams => {
      if (typeof queryParams['print'] !== 'undefined') {
        this.application.tick();
        window.print();
      }
    });
  }
}
