import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AnalyticsFacade, EventAnalyticParams, PaperlessAnalytics } from '@amfam/shared/analytics';
import { PcmAnalytics } from '@amfam/shared/models';
import { ButtonAnalytic } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'amfam-preferences-init',
  templateUrl: './preferences-init.component.html',
  styleUrls: ['./preferences-init.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreferencesInitComponent implements OnInit, OnChanges {
  @Input() billingEligibleToGoPaperless: boolean;
  @Input() isPolicyGoPaperless: boolean;
  @Input() routeToConfirmation: boolean;
  @Output() goPaperlessClickEvent = new EventEmitter();

  showPaperless: boolean;
  isBilling: boolean;

  termsAndConditionsForm: UntypedFormGroup;
  showModal = false;
  public clickGoPaperlessAnalytics: ButtonAnalytic;
  public asterisk: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    /**
     * Paperless Card Condition
       # isChangeValid = Checks undefined, null, and the difference between past and current values.
       # billingEligibleToGoPaperless = Eligible for both (Billing & Policy) and only (Billing).
       # isPolicyGoPaperless = Eligible only for Policy.
     */
    if (this.isChangeValid(changes))
      this.isBilling =
        this.billingEligibleToGoPaperless ||
        (!this.isPolicyGoPaperless && this.billingEligibleToGoPaperless);
  }

  ngOnInit() {
    if (this.routeToConfirmation) {
      if (!this.isPolicyGoPaperless && this.billingEligibleToGoPaperless) {
        this.clickGoPaperlessAnalytics = PaperlessAnalytics.buttonGoPaperlessEnrollBoth;
      } else if (!this.isPolicyGoPaperless) {
        this.clickGoPaperlessAnalytics = PaperlessAnalytics.buttonGoPaperlessEnrollPolicyOnly;
      } else if (this.billingEligibleToGoPaperless) {
        this.clickGoPaperlessAnalytics = PaperlessAnalytics.buttonGoPaperlessEnrollBililngOnly;
      }
    } else {
      this.clickGoPaperlessAnalytics = PcmAnalytics.clickGoPaperless;
    }
    this.termsAndConditionsForm = this.formBuilder.group({
      termAgree: ['', Validators.compose([Validators.required])]
    });
    this.asterisk = '*';
  }

  openModal() {
    this.showModal = true;
    this.showPaperless = false;
  }

  goPaperLess() {
    // GA event for paperless enrollment start
    const paperlessStartEvent: EventAnalyticParams = {
      event: 'paperless_enroll_start',
      method: 'dashboard'
    };
    this.analyticsFacade.trackEvent(paperlessStartEvent);

    this.goPaperlessClickEvent.emit(true);
  }

  private isChangeValid(changes: SimpleChanges): boolean {
    return (
      changes &&
      changes.billingEligibleToGoPaperless.currentValue !== undefined &&
      changes.billingEligibleToGoPaperless.currentValue !== null &&
      changes.isPolicyGoPaperless.currentValue !== undefined &&
      changes.isPolicyGoPaperless.currentValue !== null &&
      (changes.billingEligibleToGoPaperless.currentValue !==
        changes.billingEligibleToGoPaperless.previousValue ||
        changes.isPolicyGoPaperless.currentValue !== changes.isPolicyGoPaperless.previousValue)
    );
  }
}
