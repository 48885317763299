import { PolicySelectors, PolicySummarySelectors } from '@amfam/policy/data-access';
import { PrettyPolicyNum } from '@amfam/policy/pipes';
import { PrettyPhoneNumberPipe } from '@amfam/shared/ui/pipes';
import { userQuery } from '@amfam/shared/user';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import {
  Addressee,
  ButtonAnalytic,
  CommunicationService,
  ConfigService,
  CopyService,
  InternalEmail,
  MessageDetail,
  OpportunityContent,
  OpportunityContentWithRecommendationId,
  PageAnalytic,
  TemplatedEmail,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Store
import { select, Store } from '@ngrx/store';
import addDays from 'date-fns/add_days';
// date-fns
import { AnalyticsFacade } from '@amfam/shared/analytics';
import dateFormat from 'date-fns/format';
import { get as _get, omit as _omit, template as _template } from 'lodash';
import { combineLatest, Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as fromOpportunity from '../+state';
import { OpportunitiesResponse, Opportunity, Recommendation, StampsApiResponse } from '../models';
import { AgentNotificationRequest } from '../models/agentNotification';
import { ANSRequest } from '../models/ansNotification';
import { CustomerFeedbackEnum, FeedbackRequest, OpportunitiesFeedback } from '../models/feedback';
import { OpportunityAnalytics } from '../models/opportunity-analytic-contants';
import { OpportunitiesStateAveragesInterface } from '../models/stateAverages';
import { OpportunityAdapter } from './adapter.service';

@Injectable({
  providedIn: 'root'
})
export class OpportunityService {
  private endpoint: string;
  private recommenderV2EndPoint: string;
  constructor(
    private communicationService: CommunicationService,
    private copyService: CopyService,
    private config: ConfigService,
    private http: HttpClient,
    private store: Store,
    private utilService: UtilService,
    private adapter: OpportunityAdapter,
    private analyticsFacade: AnalyticsFacade,
    private featureFlagService: FeatureFlagService
  ) {
    this.endpoint = this.config.get('producerApi');
    this.recommenderV2EndPoint = this.config.get('recommenderApiV2');
  }

  mapContent(opportunitiesContent: OpportunityContent[]): OpportunityContent[] {
    // TODO: Remove once the specialty lines are enabled
    // opportunitiesContent = this.removeByTags(opportunitiesContent, ['specialty_lines']);
    return opportunitiesContent;
  }

  private removeByTags(
    opportunitiesContent: OpportunityContent[],
    tags: string[]
  ): OpportunityContent[] {
    return opportunitiesContent.filter(opp =>
      opp.tags && opp.tags.length > 0 ? !opp.tags.some(tag => tags.includes(tag)) : opp
    );
  }

  mapSelectionTypeObjects(result: OpportunityContent, type: string) {
    if (type) {
      const selectedType = result.types?.find(t => t.code.toLowerCase() === type.toLowerCase());
      if (selectedType) {
        result = {
          ...result,
          heroCard: selectedType.heroCard || result.heroCard,
          detailComponentFAQ: selectedType.faq,
          analyticsInfo: selectedType.analytics || result.analyticsInfo,
          detailComponentHeader: selectedType.detailComponentHeader || result.detailComponentHeader,
          detailComponentIntro: selectedType.detailComponentIntro || result.detailComponentIntro,
          quote: selectedType.quote || result.quote
        };
      }
    }
    return result;
  }

  /**
   * @author Abhishek Singh
   * @param None
   * @returns Observable of the JSON response coming back as part of the parties/opportunities call
   * @description This function builds the queryparmeters and call a get on parties/opportunities to get the list of recommendations.
   */
  public loadOpportunity(): Observable<Recommendation> {
    let cdhid: string;
    let policiesList: string;
    return combineLatest(
      this.store.select(userQuery.getUserState),
      this.store.select(PolicySummarySelectors.getNonOperatorPolicyList),
      (user, policies) => ({
        user: user,
        policies: policies
      })
    ).pipe(
      filter(payload => (payload?.policies?.length || 0) > 0),
      take(1),
      mergeMap(state => {
        if (state.user !== null && state.user.waid) {
          cdhid = state.user.customerId;
        }
        policiesList = state.policies.join(',');

        let uri: string;
        let params: HttpParams = new HttpParams();
        uri = `${this.recommenderV2EndPoint}recommendations`;
        params = params
          .set('policies', policiesList)
          .set('cdhid', 'CDHID-CTDNC')
          .set('specialtylines', true);

        return this.http.get<OpportunitiesResponse>(uri, { params }).pipe(
          map((res: OpportunitiesResponse) => ({
            policyOpportunities: res.policyOpportunities.map(opportunity =>
              this.adapter.adapt(opportunity)
            ),
            offerKeys: res.offerKeys
          })),
          catchError(error => observableThrowError(error))
        );
      }),
      catchError(error => observableThrowError(error))
    );
  }

  /**
   * @author Abhishek Singh
   * @param None
   * @returns Observable of the JSON response coming back from the sitecore call
   * @description Make a call to the sitecore to fetch the content of the opportunities
   */
  public loadOpportunitiesContent(): Observable<OpportunityContent[]> {
    // TODO : Once we move the content to sitecore we need to fetch the details from there.
    return of(this.copyService.getComponentData('opportunity'));
  }

  /**
   * @author Abhishek Singh
   * @param AgentNotificationRequest
   * @returns Observable of the JSON response coming back as part of the communication api post call
   * @description This function takes the model , buids the content which needs to be sent to the agent and posts that to a service
   * which then calls the communication API to send the email.
   */
  public generateInternalEmails(model: AgentNotificationRequest): Observable<any> {
    const email: InternalEmail = new InternalEmail();
    const internalAgentEmail = _get(model, 'hasAgencyEmail')
      ? this.config.get('policyGracePeriodNotificatonInternalAgencyMailbox')
      : this.config.get('policyGracePeriodNotificatonInternal');

    const emailAddress = this.config.get('production')
      ? _get(model, 'producerEmail', '')
      : internalAgentEmail;

    const agentName = _get(model, 'producerName', '');
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByRecommendationId, model.recommendationId),
      take(1),
      map(response => ({
        messageContent: this.generateInternalEmailContent(model, response.content.agentEmail),
        opportunity: response
      })),
      switchMap(state => {
        email.messageFrom = new Addressee(
          state.opportunity.content.agentEmailFromEmailAddress,
          state.opportunity.content.agentEmailFromTxt
        );
        email.messageToList.push(new Addressee(emailAddress, agentName));
        email.messageSubject = state.opportunity.content.agentEmailSubject;
        email.messageContent = state.messageContent;
        return this.communicationService.sendInternalEmail(email);
      }),
      catchError(error => observableThrowError(error))
    );
  }

  /**
   * @author Abhishek Singh
   * @param AgentNotificationRequest
   * @returns Email content in the string format.
   * @description This function takes the model , buids the content by fetching the information from the copy service and replacing the
   * variables with the values coming in as part of the model.
   */
  private generateInternalEmailContent(
    model: AgentNotificationRequest,
    agentEmailText: string
  ): string {
    const agentName = _get(model, 'producerName', '');
    const customerName = _get(model, 'customerName', '');
    const autoPolicyNumber = _get(model, 'customerAutoPolicies', '');
    const hoPolicyNumber = _get(model, 'customerHomePolicies', '');
    const customerEmail = _get(model, 'customerEmail', '');
    const customerPhone = _get(model, 'customerPhone', '');
    const activePolicies = _get(model, 'activePolicies', '');
    const temp = _template(agentEmailText)({
      agentName: agentName,
      customerName: customerName,
      autoPolicyNumber: autoPolicyNumber,
      homeownerPolicyNumber: hoPolicyNumber,
      customerPhone: customerPhone ? customerPhone : 'N/A',
      email: customerEmail ? customerEmail : 'N/A',
      activePolicies: activePolicies
    });
    return temp;
  }

  /**
   * @author Abhishek Singh
   * @param TemplatedEmail
   * @returns Json response as part of the post call on the communication API
   * @description This function takes the model and sends it to the communication API which in turn sends the email to the customer.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public generateCustomerEmail(model: TemplatedEmail): Observable<unknown> {
    return this.communicationService.sendTemplatedEmail(model);
  }

  /**
   * @author Abhishek Singh
   * @param ANSRequest
   * @returns Json response as part of the post call on the producer API
   * @description This function takes the model and sends it to the producer API which in turn posts the ANS notification
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public ansNotification(model: ANSRequest): Observable<any> {
    const uri = this.endpoint + 'producers/' + model.producerId + '/notifications';
    return this.http.post(uri, _omit(model, ['producerId']));
  }

  /**
   * @author Abhishek Singh
   * @param Feedback
   * @returns Json response as part of the post call on the recommender API
   * @description This function takes the model and sends it to the recommender API as a feedback. This is not being utilised for
   * Umbrella R1
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public sendFeedback(model: FeedbackRequest): Observable<any> {
    const uri = `${this.recommenderV2EndPoint}feedback`;
    return this.http.post(uri, model);
  }

  /**
   * @author Jakub 'SUPERMAN' Tordoff
   * @param stateAverages: [OpportunitiesStateAveragesInterface]
   * @param stateKey : stateKey
   * @description get the user address state and map it to state averages, the averages are consumed by opportuinity-copy.json via OpportunitiesDetailComponent
   */
  public filterStateAverages(): Observable<OpportunitiesStateAveragesInterface> {
    return combineLatest([
      this.store.select(userQuery.getUserStateCode),
      this.getStateAveragesData()
    ]).pipe(
      take(1),
      map(([userStateCode, stateAverages]) =>
        stateAverages.find(average => average.stateCode === userStateCode)
      )
    );
  }

  /**
   * @param producerId: Id of the currently selected agent
   * @returns AgentNotificationRequest
   * @description: Build the model which need to be sent as parmeter to the generateInternalEmail function.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  buildInternalEmailData(
    agentId: string,
    recommendationId: string
  ): Observable<AgentNotificationRequest> {
    return combineLatest(
      this.store.select(userQuery.getUserState),
      this.store.select(fromOpportunity.getAgentInformationByAgentId(agentId)),
      this.store.select(PolicySelectors.getVehiclePolicies),
      this.store.select(PolicySelectors.getPropertyPolicies),
      this.store.select(PolicySelectors.getActivePolicies),
      (user, agent, autoPolicies, homePolicies, activePolicies) => ({
        customer: user,
        agent: agent,
        autoPolicies: autoPolicies,
        homePolicies: homePolicies,
        activePolicies: activePolicies
      })
    ).pipe(
      take(1),
      mergeMap(response => {
        let autoPoliciesList: string;
        let propertyPoliciesList: string;
        let activePoliciesList: string;
        // Get the list of all the auto policies concatenated in a string as the API doesnt accept more
        // that one policy as a parameter
        response.autoPolicies.forEach(autoPolicy => {
          autoPoliciesList = autoPoliciesList
            ? autoPoliciesList + ', ' + autoPolicy.policyNumber
            : autoPolicy.policyNumber;
        });
        // Get the list of all the home owner policies concatenated in a string as the API doesnt accept more
        // that one policy as a parameter
        response.homePolicies.forEach(homePolicy => {
          propertyPoliciesList = propertyPoliciesList
            ? propertyPoliciesList + ', ' + homePolicy.policyNumber
            : homePolicy.policyNumber;
        });
        response.activePolicies.forEach(policy => {
          activePoliciesList = activePoliciesList
            ? activePoliciesList + ', ' + policy.policyNumber
            : policy.policyNumber;
        });
        const customerPhone = _get(response, 'customer.party.phones', []).find(
          (phone: { primaryIndicator: any }) => phone.primaryIndicator
        );
        return of({
          producerName: response?.agent?.firstName || response?.agent?.fullName,
          producerEmail: _get(response, 'agent.agencyEmails[0]', _get(response, 'agent.emails[0]')),
          customerName: response.customer.displayName,
          customerAutoPolicies: autoPoliciesList,
          customerHomePolicies: propertyPoliciesList,
          recommendationId: recommendationId,
          customerPhone: customerPhone
            ? new PrettyPhoneNumberPipe().transform(
                customerPhone.areaCode + customerPhone.phoneNumber
              )
            : 'N/A',
          customerEmail: response.customer.emailAddress,
          activePolicies: activePoliciesList,
          hasAgencyEmail: !!_get(response, 'agent.agencyEmails[0]')
        });
      }),
      catchError(error => observableThrowError(error))
    );
  }

  /**
   * @param producerId: Id of the currently selected agent
   * @returns AgentNotificationRequest
   * @description: Build the model which need to be sent as parmeter to the generateCustomerEmail function.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  buildCustomerEmailData(payload: OpportunitiesFeedback): Observable<TemplatedEmail> {
    return combineLatest(
      this.store.select(userQuery.getUserState),
      this.store.select(fromOpportunity.getAgentInformationByAgentId(payload.agentId)),
      this.store.select(fromOpportunity.getOpportunityByRecommendationId, payload.recommendationId),
      (user, agent, opportunity) => ({
        customer: user,
        agent: agent,
        opportunity: opportunity
      })
    ).pipe(
      take(1),
      mergeMap(response => {
        const messageDetails: MessageDetail[] = new Array<MessageDetail>();
        messageDetails.push(new MessageDetail('FirstName', _get(response, 'customer.firstName')));
        messageDetails.push(
          new MessageDetail('EmailAddress', _get(response, 'customer.emailAddress'))
        );
        messageDetails.push(new MessageDetail('ProdID', _get(response, 'agent.id', '').toString()));
        if (response.opportunity.content.coverageType)
          messageDetails.push(
            new MessageDetail('CoverageType', response.opportunity.content.coverageType)
          );
        const customerNotificationPayload: TemplatedEmail = new TemplatedEmail(
          this.config.get('env') === 'prod'
            ? response.opportunity.content.templateID
            : response.opportunity.content.testTemplateID ||
              response.opportunity.content.templateID,
          response.customer.partnerId,
          'MyAccount',
          'EN',
          response.customer.customerId,
          response.customer.emailAddress,
          messageDetails
        );
        return of(customerNotificationPayload);
      }),
      catchError(error => observableThrowError(error))
    );
  }

  /**
   * @param producerId: Id of the currently selected agent
   * @returns AgentNotificationRequest
   * @description: Build the model which need to be sent as parmeter to the generateCustomerEmail function.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  buildNotificationData(agentId: string, recommendationId: string): Observable<ANSRequest> {
    return combineLatest(
      this.store.select(userQuery.getUserState),
      this.store.select(fromOpportunity.getAgentInformationByAgentId(agentId)),
      this.store.select(PolicySelectors.getActivePolicies),
      this.store.select(PolicySelectors.getVehiclePolicies),
      this.store.select(PolicySelectors.getPropertyPolicies),
      this.store.select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
      (user, agent, policies, autoPolicies, homePolicies, selectedOpportunity) => ({
        customer: user,
        agent: agent,
        policies: policies,
        autoPolicies: autoPolicies,
        homePolicies: homePolicies,
        selectedOpportunity: selectedOpportunity
      })
    ).pipe(
      take(1),
      mergeMap(response => {
        let policiesList: string;
        let autoPoliciesList: string;
        let propertyPoliciesList: string;

        // Get the list of all the auto policies concatenated in a string as the API doesnt accept more
        // that one policy as a parameter
        response.autoPolicies.forEach(autoPolicy => {
          autoPoliciesList = autoPoliciesList
            ? autoPoliciesList + ', ' + autoPolicy.policyNumber
            : autoPolicy.policyNumber;
        });
        // Get the list of all the home owner policies concatenated in a string as the API doesnt accept more
        // that one policy as a parameter
        response.homePolicies.forEach(homePolicy => {
          propertyPoliciesList = propertyPoliciesList
            ? propertyPoliciesList + ', ' + homePolicy.policyNumber
            : homePolicy.policyNumber;
        });

        response.policies.forEach(policy => {
          policiesList = policiesList
            ? policiesList + ', ' + policy.policyNumber
            : policy.policyNumber;
        });
        const customerPhone = _get(response, 'customer.party.phones', []).find(
          (phone: { primaryIndicator: any }) => phone.primaryIndicator
        );
        const ansNotificationBody = _template(response.selectedOpportunity.content.ansNotification)(
          {
            date: dateFormat(new Date(), 'MM/DD/YYYY'),
            // eslint-disable-next-line max-len
            customerName: response.customer.displayName,
            autoPolicyNumber: autoPoliciesList,
            homeownerPolicyNumber: propertyPoliciesList,
            customerPhone: customerPhone
              ? new PrettyPhoneNumberPipe().transform(
                  customerPhone.areaCode + customerPhone.phoneNumber
                )
              : 'N/A',
            email: response.customer.emailAddress ? response.customer.emailAddress : 'N/A',
            activePolicies: policiesList
          }
        );

        const ansNotificationPayload: ANSRequest = {
          notificationsRequest: {
            notificationText: ansNotificationBody,
            notificationType: response.selectedOpportunity.content.ansNotificationType,
            customerName: response.customer.displayName,
            referenceNumber: response.policies[0].policyNumber,
            formattedReferenceNumber:
              new PrettyPolicyNum().transform(response.policies[0].policyNumber) ||
              response.policies[0].policyNumber,
            policyNumber: policiesList,
            from: 'DMSI Sales',
            dueDate: addDays(new Date(), 90).toISOString(),
            createdBy: 'DMSI',
            uniqueId: this.utilService.generateId(),
            sourceSystem: 'MYACCOUNT'
          },
          producerId: response.agent.id
        };
        return of(ansNotificationPayload);
      }),
      catchError(error => observableThrowError(error))
    );
  }

  /**
   * @param producerId: Id of the currently selected agent
   * @returns AgentNotificationRequest
   * @description: Build the model which need to be sent as parmeter to the generateCustomerEmail function.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  buildFeedbackData(actionData: OpportunitiesFeedback): Observable<FeedbackRequest> {
    let cdhid: string;
    return combineLatest(
      this.store.select(userQuery.getUserState),
      this.store.select(fromOpportunity.getAllActiveAgents),
      this.store.select(fromOpportunity.getOpportunityCardSuccess, actionData.recommendationId),
      this.store.select(fromOpportunity.getOfferKeys),
      (user, agents, successfullFeedback, offerKeys) => ({
        user: user,
        agents: agents,
        successfullFeedback: successfullFeedback,
        offerKeys: offerKeys
      })
    ).pipe(
      take(1),
      mergeMap(state => {
        /**
         * If we have already submitted the successfull notification to the agent we need to cancel the subsequent
         * feedback calls. Unless they are clicking on learn more.
         */
        if (
          state.successfullFeedback &&
          actionData.feedbackActionCode !== CustomerFeedbackEnum.CustomerLearnMore
        ) {
          return observableThrowError({
            status: {
              code: '',
              message: 'User already has a successful feedback submitted.'
            }
          });
        }
        if (state.user !== null && state.user.waid) {
          cdhid = state.user.customerId;
        }
        const feedbackData: FeedbackRequest = {
          feedback: {
            recommendationId: <string>_get(actionData, 'recommendationId', ''),
            feedbackActionCode: actionData.feedbackActionCode,
            feedbackReason: 'Successful Feedback',
            servicingProducerId:
              Number(actionData.producerId) || Number(_get(state, 'agents[0].id')),
            feedbackDate: new Date().toISOString(),
            createdBy: cdhid,
            createdBySystemName: 'MYACCOUNT'
          },
          offerKeys: state.offerKeys
        };
        return of(feedbackData);
      }),
      catchError(error => observableThrowError(error))
    );
  }

  /**
   * @param none
   * @returns void
   * @description: send the analytics information when there is success/failure
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  sendAnalytics(recommendationId: string, isMultiAgent = false, type?: string): Observable<any> {
    return combineLatest(
      this.store.pipe(select(fromOpportunity.getOpportunityCardError, recommendationId)),
      this.store.pipe(select(fromOpportunity.getOpportunityCardLoading, recommendationId)),
      this.store.pipe(select(fromOpportunity.getOpportunityByRecommendationId, recommendationId)),
      (error, opportunityLoading, selectedOpportunity) => ({
        loading: opportunityLoading,
        error: error,
        opportunity: selectedOpportunity
      })
    ).pipe(
      filter(state => !state.loading),
      take(1),
      mergeMap(state => {
        let pageInformation: PageAnalytic;
        if (state.error !== null && state.error !== undefined) {
          pageInformation = isMultiAgent
            ? type
              ? OpportunityAnalytics.whichAgentErrorPageOpporutunityWithType
              : OpportunityAnalytics.whichAgentErrorPageOpporutunity
            : type
            ? OpportunityAnalytics.pageOpportunityContactAgentErrorWithType
            : OpportunityAnalytics.pageOpportunityContactAgentError;
        } else {
          pageInformation = isMultiAgent
            ? type
              ? OpportunityAnalytics.whichAgentSuccessPageOpporutunityWithType
              : OpportunityAnalytics.whichAgentSuccessPageOpporutunity
            : type
            ? OpportunityAnalytics.pageOpportunityContactAgentSuccessWithType
            : OpportunityAnalytics.pageOpportunityContactAgentSuccess;
        }
        return this.buildPageAnalyticData(pageInformation, recommendationId, type).pipe(
          mergeMap(response => of(this.analyticsFacade.trackPage(response))),
          catchError(error => observableThrowError(error))
        );
      }),
      catchError(error => observableThrowError(error))
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  sendOptimizelyAnalytics(data: { eventName: string; testingKey: string; variationName: string }) {
    this.featureFlagService.trackOptimizelyEvent(
      data.eventName,
      data.testingKey,
      data.variationName
    );
  }

  /**
   *
   * @param analyticData Incoming analytic model containing page analytic mode based on the specific criterion
   * @param productType Product type of the selected opportunity
   * @param subProductType Sub Producttype of the selected opportunity/
   * @returns PageAanalytic : returns a PageAanalytic model containing updated page name anc categories.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  buildPageAnalyticData(
    analyticData: PageAnalytic,
    recommendationId: string,
    type?: string
  ): Observable<PageAnalytic> {
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
      filter(opportunity => !!opportunity),
      take(1),
      map(opportunity => this.mapSelectionTypeObjects(opportunity?.content, type)),
      mergeMap(content =>
        of(
          Object.assign({}, analyticData, {
            pageName: _template(analyticData.pageName)({
              endorsement: content?.analyticsInfo?.pageInfo,
              type: content?.analyticsInfo?.type
            }),
            subCategory1: _template(analyticData.subCategory1)({
              productType: content?.analyticsInfo?.subCategory1
            }),
            subCategory2: _template(analyticData.subCategory2)({
              subProductType: content?.analyticsInfo?.subCategory2
            }),
            subCategory3: content?.analyticsInfo?.subCategory3
          })
        )
      ),
      catchError(error => observableThrowError(error))
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  buildIconClickAnalyticData(name: string, recommendationId: string): Observable<ButtonAnalytic> {
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
      filter(opportunity => !!opportunity),
      take(1),
      mergeMap(opportunity => {
        const endorsement: string =
          opportunity.productType +
          (opportunity.subProductType ? ':' + opportunity.subProductType : '');
        return of({
          link: endorsement + ':Opportunity:' + name
        });
      }),
      catchError(error => observableThrowError(error))
    );
  }

  private getStateAveragesData(): Observable<OpportunitiesStateAveragesInterface[]> {
    return of([
      {
        stateCode: 'AZ',
        annually: 402.47029,
        monthly: 33.53919083,
        daily: 1.11
      },
      {
        stateCode: 'CO',
        annually: 326.9716704,
        monthly: 27.2476392,
        daily: 0.9
      },
      {
        stateCode: 'GA',
        annually: 137.5775169,
        monthly: 11.46479308,
        daily: 0.38
      },
      {
        stateCode: 'ID',
        annually: 294.8607932,
        monthly: 24.57173277,
        daily: 0.81
      },
      {
        stateCode: 'IL',
        annually: 315.507579,
        monthly: 26.29229825,
        daily: 0.86
      },
      {
        stateCode: 'IN',
        annually: 272.757748,
        monthly: 22.72981233,
        daily: 0.75
      },
      {
        stateCode: 'IA',
        annually: 240.5098601,
        monthly: 20.04248834,
        daily: 0.66
      },
      {
        stateCode: 'KS',
        annually: 198.7725175,
        monthly: 16.56437646,
        daily: 0.55
      },
      {
        stateCode: 'MN',
        annually: 269.080706,
        monthly: 22.42339217,
        daily: 0.74
      },
      {
        stateCode: 'MO',
        annually: 249.0211404,
        monthly: 20.7517617,
        daily: 0.68
      },
      {
        stateCode: 'NE',
        annually: 232.6911238,
        monthly: 19.39092698,
        daily: 0.64
      },
      {
        stateCode: 'NV',
        annually: 372.996998,
        monthly: 31.08308316,
        daily: 1.02
      },
      {
        stateCode: 'ND',
        annually: 225.6792177,
        monthly: 18.80660148,
        daily: 0.62
      },
      {
        stateCode: 'OH',
        annually: 304.1937447,
        monthly: 25.34947872,
        daily: 0.83
      },
      {
        stateCode: 'OR',
        annually: 268.3611462,
        monthly: 22.36342885,
        daily: 0.74
      },
      {
        stateCode: 'SD',
        annually: 243.3554411,
        monthly: 20.2796201,
        daily: 0.67
      },
      {
        stateCode: 'UT',
        annually: 337.2571363,
        monthly: 28.10476135,
        daily: 0.92
      },
      {
        stateCode: 'WA',
        annually: 210.0503199,
        monthly: 17.50419333,
        daily: 0.58
      },
      {
        stateCode: 'WI',
        annually: 255.540197,
        monthly: 21.29501642,
        daily: 0.7
      }
    ]);
  }

  /**
   * @param  recommendationId : number
   * @returns OpportunityType : string
   * @description Take the recommendation id and find the corresponding opportunityType text from the store.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getOpportunityTypeByRecommendationId(recommendationId: string): Observable<string> {
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
      map(opportunity => opportunity.content.opportunityProductType)
    );
  }

  getOpportunityByRecommendationId(recommendationId: string): Observable<Opportunity> {
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByRecommendationId, recommendationId)
    );
  }

  /**
   * @param  OpportunityType : string
   * @returns recommendationId : number
   * @description Take the opportunityType text  and find the corresponding recommendation id from the store.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getRecommendationIdByOpportunityType(opportunityType: string): Observable<string> {
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByOpportunityType, opportunityType),
      map(opportunity => _get(opportunity, 'recommendationId'))
    );
  }

  public stamps(
    zipCode: string,
    serviceLine: string = 'auto',
    lineCategory: string = 'car'
  ): Observable<StampsApiResponse> {
    const url = `${this.config.get(
      'stampsApi'
    )}?zip=${zipCode}&serviceLine=${serviceLine}&lineCategory=${lineCategory}`;
    return this.http.get<StampsApiResponse>(url);
  }

  /**
   *
   * @param analyticData Incoming analytic model containing a link string based on the specific criterion
   * @param productType Product type of the selected opportunity
   * @param subProductType Sub Producttype of the selected opportunity/
   * @returns ButtonAnalytic : returns a ButtonAnalytic model containing a link string with the concatenated product type
   * and sub product type.
   */
  buildButtonAnalyticData(
    analyticData: ButtonAnalytic,
    recommendationId: string,
    type?: string
  ): Observable<ButtonAnalytic> {
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
      take(1),
      mergeMap(opportunity =>
        of({
          link:
            'MyAccount:' +
            opportunity.content.analyticsInfo.pageInfo +
            ':' +
            _template(analyticData.link)({ type })
        })
      )
    );
  }
}

export const sortOpportunitiesOperator = (priorityOrder: Observable<string[]>) =>
  switchMap((recommendationList: (OpportunityContent & OpportunityContentWithRecommendationId)[]) =>
    priorityOrder.pipe(
      mergeMap(prioritylist => {
        let returningArray: (OpportunityContent & OpportunityContentWithRecommendationId)[] = [];
        for (const priorityItem of prioritylist) {
          const recommendationItem = recommendationList.find(
            recommendation => recommendation.opportunityProductCode === priorityItem
          );
          if (recommendationItem) {
            returningArray = [...returningArray, recommendationItem];
          }
        }
        return of(returningArray);
      })
    )
  );
