export class DocumentLink {
  public href: string;
  public method: string;
  public rel: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(json: any): DocumentLink {
    const documentLink = new DocumentLink();
    documentLink.href = json.href;
    documentLink.method = json.method;
    documentLink.rel = json.rel;

    return documentLink;
  }
}
