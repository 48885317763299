@if (newDesign) {
  <div class="row overview-section__header_new">
    <div class="column twelve">
      <p class="overview-section__heading font-weight-semibold" data-cy="sectionHeading">
        {{ sectionHeadingString }}
      </p>
      @if (imgSrc) {
        <img class="heading-image" [src]="imgSrc" alt="" />
      }
    </div>
  </div>
} @else {
  <div class="row" [ngClass]="{ 'overview-section__header': !disableDefaultClass }">
    <div class="column twelve md-four flex overview-heading-wrapper">
      <div class="overview-heading-container">
        @if (disableDefaultClass) {
          <span class="overview-heading font-weight-semibold">{{ sectionHeadingString }}</span>
        } @else {
          <h2 class="overview-section__heading font-weight-bold" data-cy="sectionHeading">
            {{ sectionHeadingString }}
          </h2>
        }
      </div>
      <div class="overview-section-container">
        <div class="overview-section__link">
          <ng-content select="ds-overview-section-header-link"></ng-content>
        </div>
      </div>
    </div>
  </div>
}

<ds-localized-spinner class="block" [dataLoading]="loading" [loadingMessage]="spinnerTextString">
</ds-localized-spinner>

<ng-content select="ds-overview-section-content"></ng-content>
