export interface DeleteMyAccount {
  authenticateStatus: number;
  unenrollProgramsStatus: number;
  deleteMyAccountStatus: number;
  sendTemplatedEmailStatus: number;
}

export const initialState: DeleteMyAccount = {
  authenticateStatus: null,
  unenrollProgramsStatus: null,
  deleteMyAccountStatus: null,
  sendTemplatedEmailStatus: null
};
