import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClaimEnterpriseEffects } from './+state/claim-enterprise.effects';
import {
  CLAIM_ENTERPRISE_FEATURE_KEY,
  initialState as claimEnterpriseInitialState,
  claimEnterpriseReducer
} from './+state/claim-enterprise.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CLAIM_ENTERPRISE_FEATURE_KEY, claimEnterpriseReducer, {
      initialState: claimEnterpriseInitialState
    }),
    EffectsModule.forFeature([ClaimEnterpriseEffects])
  ]
})
export class ClaimEnterpriseDataAccessModule {}
