import { createAction } from '@ngrx/store';

import { GetPaymentHistoryPayload, Payment } from '@amfam/shared/models';

export enum PaymentHistoryActionTypes {
  GetPaymentsType = '[Payment History] Get Payments',
  GetPaymentsSuccessType = '[PaymentHistory] Get Payments Success',
  GetPaymentsFailType = '[PaymentHistory] Get Payments Fail'
}

export const GetPayments = createAction(
  PaymentHistoryActionTypes.GetPaymentsType,
  (payload: GetPaymentHistoryPayload) => ({ payload })
);

export const GetPaymentsSuccess = createAction(
  PaymentHistoryActionTypes.GetPaymentsSuccessType,
  (payload: Payment[]) => ({ payload })
);

export const GetPaymentsFail = createAction(PaymentHistoryActionTypes.GetPaymentsFailType);

export type PaymentHistoryActions =
  | ReturnType<typeof GetPayments>
  | ReturnType<typeof GetPaymentsSuccess>
  | ReturnType<typeof GetPaymentsFail>;

export const fromPaymentHistoryActions = {
  GetPayments,
  GetPaymentsSuccess,
  GetPaymentsFail
};
