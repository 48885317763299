<div class="main">
  <div class="row margin-top-20"><h3>Something Went Wrong</h3></div>
  <div class="row margin-top-20">
    Unfortunately, an error occurred and we can’t complete your request. We’re working on a
    solution, and we apologize for the inconvenience.<br />
    In the meantime, please call <span *dsPartnerAFI>{{ amfamPhone }} and select option 4</span
    ><span *dsPartnerConnect dsPartnerContent [brandContent]="'selectPrettyCusCareNumber'"></span>
    &nbsp; for help or try again later.
  </div>
  <br />
  @if (error) {
    <div>{{ error }}</div>
  }
  <div class="row margin-top-20"><a ds-button [dsRouterLink]="['/overview']">Home</a></div>
</div>
