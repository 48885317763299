import { Routes } from '@angular/router';

import { DeleteMyAccountComponent } from './delete-my-account/delete-my-account.component';
import { ProfileComponent } from './profile.component';

export const ProfileRoutes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      breadcrumb: 'Profile'
    }
  },
  {
    path: 'profile/delete-my-account',
    component: DeleteMyAccountComponent,
    data: {
      breadcrumb: 'Delete My Account'
    }
  }
];
