import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ClaimServicesService } from '../../services/claim-services.service';
import {
  CreateRepairRequest,
  CreateRepairRequestFail,
  CreateRepairRequestSuccess,
  RepairServiceActionTypes
} from './repair-service.actions';
import { CreateRepairRequestPayload } from './repair-service.models';

@Injectable()
export class RepairServiceEffects {
  createRepairRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateRepairRequest>(RepairServiceActionTypes.CreateRepairRequest),
      map(action => action.payload),
      switchMap((payload: CreateRepairRequestPayload) =>
        this.claimServicesService
          .createRepairRequest(payload.claimNumber, payload.vin, payload.selectedShop.id)
          .pipe(
            map(() => new CreateRepairRequestSuccess()),
            catchError(() => of(new CreateRepairRequestFail()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private claimServicesService: ClaimServicesService
  ) {}
}
