@if (adjuster && !claimsStatusEnabled) {
  <div class="agents-list">
    <h4>Claim Contact</h4>
    <ul>
      <li class="agents-list-item">
        <div class="agent-headings">
          <h5>{{ adjuster.name }}</h5>
        </div>
        <ul>
          <li
            class="agent-photo"
            [style.background-image]="adjuster.photo ? 'url(' + adjuster.photo + ')' : ''"
          >
            <span class="initials">{{ adjuster.initials }}</span>
          </li>
          <li>
            <span class="pseudo-icon icon-email"></span>
            <a [dsRouterLink]="['/claims/contact-adjuster', claim.claimNumber]">Contact</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
}
@if (adjuster && claimsStatusEnabled) {
  <div class="agents-list">
    <h4>Claim Contact</h4>
    <ul>
      <li class="agents-list-item">
        <div class="agent-headings">
          <h5>{{ adjuster.name }}</h5>
        </div>
        <ul>
          <li
            class="agent-photo"
            [style.background-image]="adjuster.photo ? 'url(' + adjuster.photo + ')' : ''"
          >
            <span class="initials">{{ adjuster.initials }}</span>
          </li>
          <li>
            <span class="pseudo-icon icon-email"></span>
            <a [dsRouterLink]="['/claims/contact-adjuster', claim.claimNumber]">Email</a>
          </li>
          <li>
            <span tabindex="0" aria-label="Phone Number" class="pseudo-icon icon-call"></span>
            @if (!!adjuster.phone) {
              <a [href]="adjuster.phone | phoneNumberTelFormatter">{{
                adjuster.phone | adjusterPhoneNumberFormatter
              }}</a>
            }
            @if (!adjuster.phone) {
              <a
                dsPartnerContent
                [brandContent]="'selectPrettyClaimPhoneNum'"
                [brandHref]="'selectClaimPhoneNumFullHref'"
              ></a>
            }
          </li>
        </ul>
      </li>
    </ul>
  </div>
}

<!-- If there is no adjuster yet we need to show the AMFAM contact number -->
@if (!adjuster) {
  <div class="agents-list">
    <h4>Claims Contact</h4>
    <a href="{{ amfamTel }}" target="_blank">{{ amfamPhone }}</a>
  </div>
}
