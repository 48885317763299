import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DsRouterLinkModule } from '@amfam/shared/ui/ds-router-link';

import { QuickLinksListComponent } from './components/quick-links-list/quick-links-list.component';

@NgModule({
  imports: [CommonModule, DsRouterLinkModule],
  declarations: [QuickLinksListComponent],
  exports: [QuickLinksListComponent]
})
export class QuickLinksModule {}
