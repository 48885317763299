<section id="edit-security-questions-component" class="item">
  <div class="row">
    <div class="column twelve">
      @if (!showForm) {
        <div
          class="pad-left-40 pad-top-10 float-left"
          content
          [contentPath]="'profile.securityQuestionsTitle'"
        ></div>
      }
      @if (!showForm) {
        <button
          ds-button
          class="-outline-white float-right margin-left-10"
          id="openSecurityQuestionsForm"
          (click)="openSecurityQuestionsForm()"
          data-cy="openSecurityQuestionsButton"
          content
          [contentPath]="'shared.changeBtn'"
          authCheck
          [permissionName]="'securityquestions_view'"
          [checkAction]="'disable'"
        ></button>
      }
      @if (showForm) {
        <div class="row pad-left-40">
          @if (errorLoadingQuestions) {
            <p
              class="color-red"
              content
              [contentPath]="'profile.editSecurityQuestions.loadingError'"
            ></p>
          }
          <h3 content [contentPath]="'profile.securityQuestionsTitle'"></h3>
          <p class="margin-bottom-20" content [contentPath]="'profile.securityQuestionsText'"></p>
          @if (!errorLoadingQuestions) {
            <ds-manage-security-questions
              [securityQuestions]="securityQuestions"
              (finish)="onComplete($event)"
              (getAllQuestionsServiceError)="onGetAllQuestionsServiceError()"
            >
            </ds-manage-security-questions>
          }
          <button
            ds-button
            class="-white -offset-top bg-transparent cancel a float-right margin-right-5"
            (click)="openSecurityQuestionsForm()"
            data-cy="cancelSecurityQuestionsButton"
            content
            [contentPath]="'shared.cancel'"
          ></button>
        </div>
      }
    </div>
  </div>
</section>
<hr aria-hidden="true" class="dashed" />
