<div [style.height.px]="headerHeight" class="header-wrapper-container">
  <div
    class="fixed-wrapper"
    [style.top.px]="(loggedIn$ | async) ? headerOffset : '0'"
    #fixedwrapper
  >
    @if ((loggedIn$ | async) && !(mobileMenuActive$ | async)) {
      <a
        [style.height.px]="headerOffset"
        (focus)="focusHandler()"
        (blur)="blurHandler()"
        class="sr-only skip-content"
        (click)="focusMainContent()"
        (keyup.enter)="focusMainContent()"
        >Skip to Content</a
      >
    }
    <ds-header
      [loaded$]="dataLoaded$"
      [chatEnabled$]="chatEnabled$"
      [loggedIn$]="loggedIn$"
      [isPartner$]="isPartner$"
      [homeUrl]="homeUrl"
      [notifications$]="notifications$"
      [linkArray$]="linkArray$"
      [brandInfo$]="brandInfo$"
      [headerLogo]="headerLogo"
      [skipContentFocus]="skipContentFocus"
      (linkClickEvent)="linkClicked($event)"
      (sublinkClickEvent)="sublinkClicked($event)"
      (notificationDismissClickEvent)="notificationDismissClicked($event)"
      class="app-header block"
    ></ds-header>
    <ds-roofline [loggedIn$]="loggedIn$" class="roofline-container none lg-block"></ds-roofline>
    @if (showHeader) {
      <docking-bar></docking-bar>
    }
  </div>
</div>
<session-timeout></session-timeout>
