/* eslint-disable max-len */

/*
  01 - Base Validations (e.g., required, not null)
  02 - Date and time validations
  03 - Character validations, typically a regex check (e.g., is numeric)
  04 - Phone number validations
  05 - Email validations
  06 - Address validations (e.g., zip code)
  07 - Password validations
  08 - Format validations, similar to character validations, but more specific (e.g., looks like a policy number)
  09 - Financial validations (e.g., credit card, routing number)
*/

import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
// date-fns
import {
  addHours,
  endOfMonth,
  format,
  getHours,
  isAfter,
  isBefore,
  setHours,
  setMinutes,
  subHours
} from 'date-fns';
import { get as _get, has as _has } from 'lodash';

import { PaymentAmountModel } from './models/payment-amount.model';
import { CCValidator } from './validators/creditCard/CCValidator';

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  /* ================================================================================
      01 -- Base validations (e.g., required, length)
  */

  static isChecked(control: UntypedFormControl) {
    if (control.value) {
      return null;
    }

    return { required: true };
  }

  static userNamePasswordLengthValidator(control: UntypedFormControl) {
    if (control.value.length < 7 || control.value.length > 15) {
      return { invalidLength: true };
    }

    return null;
  }

  static unsetStringValidator(control: UntypedFormControl) {
    if (control.value.match(/unset/i)) {
      return { required: true };
    }

    return null;
  }

  static requiresOneOrMore(group: UntypedFormGroup) {
    for (const name in group.controls) {
      if (name) {
        // fixing typescript if filter error
        const control = group.controls[name];
        if (control.value) {
          return null;
        }
      }
    }

    return { selectOneOrMore: true };
  }

  static requiredWhen(conditions: any) {
    return (control: UntypedFormControl): { [s: string]: boolean } => {
      let required = false;
      if (conditions()) {
        required = true;
      }

      if (required && !control.value && control.value !== false) {
        return { required: true };
      }

      return null;
    };
  }

  // ng2 default required validator doesn't trim strings before checking
  // by trimming we enforce that a required field cannot contain only spaces
  static requiredWithTrim(control: UntypedFormControl) {
    return typeof control.value === 'string' && !control.value.trim()
      ? { requiredWithTrim: true }
      : null;
  }

  // usage validateWhen(this === true, Validators.compose([...]))
  static validateWhen(preConditions: any, validator: ValidatorFn) {
    return (control: UntypedFormControl): { [s: string]: boolean } => {
      const required = false;
      if (preConditions()) {
        return validator(control);
      }

      return null;
    };
  }

  /* ================================================================================
      02 -- Date and time validations
  */

  // Helper function, since the date-fns function isValid does not validate for
  // dates such as 4/31 or leap years, etc.
  // This function is equivalent to: moment(value, 'MM/DD/YYYY', true).isValid()
  static isValidDate(date: string) {
    if (date) {
      const dateRegex = /^(0[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/; // MM/DD/YYYY
      const jsDate = new Date(date);
      const month = +date.split('/')[0];
      const day = +date.split('/')[1];
      const year = +date.split('/')[2];
      const validFormat = date.match(dateRegex) ? true : false;
      const validDate =
        jsDate.getFullYear() === year &&
        jsDate.getMonth() + 1 === month &&
        jsDate.getDate() === day;
      return validFormat && validDate;
    }
    return false;
  }

  static datePickerDateNotInRange(disableSince: any) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const control = group.parent.controls['paymentDateControl'];
      const selectedDate = control.value;
      if (selectedDate && disableSince) {
        const dueDate = format(
          new Date(disableSince.year, disableSince.month - 1, disableSince.day - 1),
          'MM/DD/YYYY'
        );
        const after = isAfter(format(selectedDate.formatted, 'MM/DD/YYYY'), dueDate);
        const before = isBefore(
          format(selectedDate.formatted, 'MM/DD/YYYY'),
          format(new Date(), 'MM/DD/YYYY')
        );
        if ((after || before) && /^[0-9/]*$/.test(selectedDate.formatted)) {
          return { datePickerDateNotInRangeError: true };
        }

        return null;
      }

      return null;
    };
  }

  static expirationDateValidator(cardDateMonthCtrl, cardDateYearCtrl) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const month = parseInt(group.controls[cardDateMonthCtrl].value, 10);
      const year = ValidationService.parseTwoDigitYear(
        parseInt(group.controls[cardDateYearCtrl].value, 10)
      );
      if (month && year) {
        const startDate = new Date().setFullYear(year, month - 1, 1);
        const endDate = endOfMonth(startDate);
        if (isBefore(format(endDate, 'MM/DD/YYYY'), format(new Date(), 'MM/DD/YYYY'))) {
          return { expDateInvalid: true };
        }

        return null;
      }

      return null;
    };
  }

  static expirationDateInputValidator(control: UntypedFormControl): { [s: string]: boolean } {
    const month = parseInt(control.value.split('/')[0], 10);
    const year = ValidationService.parseTwoDigitYear(parseInt(control.value.split('/')[1], 10));
    const mmyyRegex: RegExp = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    const validExpirationDate: boolean = mmyyRegex.test(control.value);
    if (month && year) {
      const startDate = new Date().setFullYear(year, month - 1, 1);
      const endDate = endOfMonth(startDate);
      if (
        !validExpirationDate ||
        isBefore(format(endDate, 'MM/DD/YYYY'), format(new Date(), 'MM/DD/YYYY'))
      ) {
        return { expDateInvalid: true };
      }
    }
    return null;
  }

  // implementation of moment.js function parseTwoDigitYear
  static parseTwoDigitYear(year: number) {
    return year <= 68 ? year + 2000 : year + 1900;
  }

  // checks if year is between 2017 and 2039
  // TODO - make this more dynamic like getYearsObject
  static expirationYearValidator(control: UntypedFormControl) {
    if (control.value && !control.value.match(/(1[7-9]|2[0-9]|3[0-9])$/)) {
      control.markAsTouched();
      control.markAsDirty();
      return { invalidExpirationYear: true };
    }

    return null;
  }

  static futureDayValidator(control: UntypedFormControl) {
    const value = (control.value && control.value.formatted) || control.value;
    const after = isAfter(value, format(new Date(), 'MM/DD/YYYY'));
    if (ValidationService.isValidDate(value) && after) {
      return { invalidFutureDay: true };
    }

    return null;
  }

  /**
   * Validation against future date and time using input of the date, time and meridiem.
   * Returns standard form control validation of 'invalidFutureTime' if the date / time is in the future
   * This is used to validate a form group within a form group and must be applied to the outer form group to work
   * In the example we are validating on the inner 'details' form group
   * @param formGroupName - The name of the inner form group containing the controls we need to validate on
   * @param timeInput - The name of the time input form control
   * @param meridiemInput - The name of the meridiem input form control
   * @param dateInput - The name of the date input form control
   * @example
   * this.reportClaimForm = this.formBuilder.group({
   *             riskSelection: [''],
   *             details: this.formBuilder.group({
   *                         dateOfIncident: [''],
   *                         timeOfIncident: [''],
   *                         amPmSelection: [''],
   *                         locationOfIncident: [''],
   *                         descriptionOfIncident: ['']
   *              }),
   *              contactInfo: this.formBuilder.group({
   *                          emailAddress: [''],
   *                          phoneNumber: ['']
   *              })
   * }, { validator: ValidationService.futureTimeValidator('details', 'timeOfIncident', 'amPmSelection', 'dateOfIncident') });
   */

  static futureTimeValidator(
    formGroupName: string,
    timeInput: string,
    meridiemInput: string,
    dateInput: string
  ) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const innerForm = <UntypedFormGroup>group.controls[formGroupName]; // Get the form control
      if (
        innerForm && // Have the form group
        innerForm.controls[dateInput] && // Form group has control for date
        innerForm.controls[dateInput].value && // Date control has value
        innerForm.controls[dateInput].value.formatted && // Date control value has formatted property
        innerForm.controls[timeInput] && // Form group has control for time
        innerForm.controls[timeInput].value && // Time control has value
        innerForm.controls[meridiemInput] && // Form group has control for meridiem
        innerForm.controls[meridiemInput].value // Meridiem control has value
      ) {
        // Format the entered date and tack on hours and minutes
        let enteredDateTime = innerForm.controls[dateInput].value.formatted;
        enteredDateTime = setHours(
          enteredDateTime,
          innerForm.controls[timeInput].value.split(':')[0]
        );
        enteredDateTime = setMinutes(
          enteredDateTime,
          innerForm.controls[timeInput].value.split(':')[1]
        );

        // If it's PM and not 12 add 12 to the hour input to get to 24 hour format
        if (innerForm.controls[meridiemInput].value === 'PM' && getHours(enteredDateTime) !== 12) {
          enteredDateTime = addHours(enteredDateTime, 12);
          // else if it's AM and 12 subtract 12 to get to 24 hour format of 00
        } else if (
          innerForm.controls[meridiemInput].value === 'AM' &&
          getHours(enteredDateTime) === 12
        ) {
          enteredDateTime = subHours(enteredDateTime, 12);
        }

        // If the date / time is in the future, the validation fails / is true
        if (isAfter(enteredDateTime, new Date())) {
          return { invalidFutureTime: true };
        }

        return null;
      }

      return null;
    };
  }

  // Basic mm/dd/yyyy format test, doesn't test validity of actual dates.
  static mmDdYyyyDateValidator(control: UntypedFormControl) {
    const value = (control.value && control.value.formatted) || control.value;
    const dateRegex = /^(0[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/; // MM/DD/YYYY
    const valid = value.match(dateRegex) ? true : false;
    if (value && !valid) {
      return { invalidDateFormat: true };
    }
  }

  // The date needs to be 1900 - current year
  static mmDdYyyyPastThruTodayDateRangeValidator(control: UntypedFormControl) {
    const value = (control.value && control.value.formatted) || control.value;
    if (ValidationService.isValidDate(value)) {
      const after = isAfter(format(value, 'MM/DD/YYYY'), format(new Date(), 'MM/DD/YYYY'));
      const before = isBefore(format(value, 'MM/DD/YYYY'), '01/01/1900');

      if (value && (before || after)) {
        return { invalidDateRange: true };
      }
      return null;
    }

    return null;
  }

  // The date needs to be an actual date (leap year && ex: 12/32/2016)
  static mmDdYyyyValidDateValidator(control: UntypedFormControl) {
    const value = (control.value && control.value.formatted) || control.value;
    if (!ValidationService.isValidDate(value)) {
      return { invalidDate: true };
    }

    return null;
  }

  static timeValidator(control: UntypedFormControl) {
    const timeRegex = /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9]$/;
    const valid = control.value.match(timeRegex) ? true : false;
    if (control.value && !valid) {
      return { invalidTimeFormat: true };
    }

    return null;
  }

  /* ================================================================================
      03 -- Character validations, typically a regex check (e.g., is numeric)
  */

  //// Alpha numeric

  static alphanumericCheck(control: UntypedFormControl) {
    if (!control.value.match(/^[a-zA-Z0-9]+$/)) {
      return { notAlphanumeric: true };
    }

    return null;
  }

  static alphanumericPlusOuterSpaces(control: UntypedFormControl) {
    if (!control.value.match(/^\s*[a-zA-Z0-9]+\s*$/)) {
      return { notAlphanumericPlusOuterSpaces: true };
    }

    return null;
  }

  static usernameValidator(control: UntypedFormControl) {
    if (!control.value.match(/^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$/)) {
      return { invalidUsername: true };
    }

    return null;
  }

  static costcoMembershipValidator(control: UntypedFormControl) {
    if (control.value !== null && !control.value?.match(/^[1,3,8][0-9]{11}$/)) {
      return { invalidMembershipNumber: true };
    }

    return null;
  }

  static costcoMembershipLengthValidator(control: UntypedFormControl) {
    if (control.value !== null && control.value.length > 0 && control.value.length !== 12) {
      return { invalidLength: true };
    }

    return null;
  }

  //// Alpha

  static atLeastOneLetter(control: UntypedFormControl) {
    if (!control.value.match(/^(?=.*[a-zA-Z])/)) {
      return { atLeastOneLetterError: true };
    }

    return null;
  }

  static cannotStartWithNumber(control: UntypedFormControl) {
    if (!control.value.match(/^[^0-9]+/)) {
      return { startsWithNumber: true };
    }

    return null;
  }

  static emptyOrLettersOnly(control: UntypedFormControl) {
    if (!control.value.match(/^[a-zA-Z]*$/)) {
      return { emptyOrLettersOnly: true };
    }

    return null;
  }

  static emptyOrLettersOrDashesOnly(control: UntypedFormControl) {
    if (!control.value.match(/^[a-zA-Z-]*$/)) {
      return { emptyOrLettersOnly: true };
    }

    return null;
  }

  //// Numeric

  static atLeastOneNumber(control: UntypedFormControl) {
    if (!control.value.match(/^(?=.*[0-9])/)) {
      return { atLeastOneNumberError: true };
    }

    return null;
  }

  static numbersOnly(control: UntypedFormControl) {
    if (!control.value.match(/^[0-9]*$/)) {
      return { noErrorMessage: true };
    }

    return null;
  }

  static oneToSevenNumbers(control: UntypedFormControl) {
    if (!control.value.match(/^[0-9]{1,7}$/)) {
      return { oneToSevenNumbers: true };
    }

    return null;
  }

  static validateOdometer(control: UntypedFormControl) {
    let odometerValue = control.value;
    if (typeof odometerValue === 'string') {
      if (odometerValue.indexOf(',') > -1)
        odometerValue = Number(odometerValue.split(',').join(''));
      else odometerValue = Number(odometerValue);
    }
    if (odometerValue > 99999 || odometerValue <= 0) {
      return { invalidOdometer: true };
    }
    return null;
  }

  static odometerRequiredValidator(control: UntypedFormControl) {
    if (control.value.length === 0) {
      return { missingOdometer: true };
    }
    return null;
  }

  static annualMileageRequiredValidator(control: UntypedFormControl) {
    if (control.value.length === 0) {
      return { missingAnnualMileage: true };
    }
    return null;
  }
  //// Special characters

  static invalidChar(control: UntypedFormControl) {
    const invalidChars = [63];
    if (control.value) {
      for (const sub of control.value) {
        if (invalidChars.includes(sub.charCodeAt(0))) {
          return { invalidCharacter: true };
        }
      }
    }
    return null;
  }

  static nonAsciiCharacters(control: UntypedFormControl) {
    if (control.value && control.value.match(/[^\x00-\x7F]/g)) {
      // for char hex codes see: http://defindit.com/ascii.html
      // this is the same as allowing charCode 0-127 or only keyboard input
      return { nonAsciiCharacters: true };
    }

    return null;
  }

  // Regex to throw error in any case: '0:)?' or '12te' for last four digits of ssn.
  static noAlphabetsAndSpecialChar(control: UntypedFormControl) {
    if (control.value.match(/^(?=.*[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/)) {
      return { invalidSSN: true };
    }

    return null;
  }

  static specialCharValidator(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    if (!control.value.match(/^[a-zA-Z\s-']+$/)) {
      return { specialCharError: true };
    }

    return null;
  }

  static specialCharValidatorWithLetterAndNum(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    if (control.value !== null && !control.value.match(/^[a-zA-Z0-9\s-./#&]+$/)) {
      return { specialCharValidError: true };
    }
    return null;
  }

  /**
   * @description A general purpose text input validator to whitelist the special charecters allowed in the text
   * This stems from the requirement to blackbox the emoji's from the text area in the application.
   * @param control takes the form control as an input
   */
  static textInputValidator(control: UntypedFormControl): null | { invalidCharacters: boolean } {
    if (control.value.match(/[^a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\n\t\s]+/g)) {
      return { invalidCharacters: true };
    }
    return null;
  }

  /* ================================================================================
      04 -- Phone number validations
  */

  static phoneNumberAreaCodeValidator(control: UntypedFormControl) {
    if (!control.value.match(/[\d]{3}/)) {
      return { invalidAreaCode: true };
    }

    const areaCode = control.value.substring(0, 3);
    if (areaCode === '000' || areaCode === '999') {
      return { invalidAreaCode: true };
    }

    return null;
  }

  // phone number extension validator for work phone numbers
  static phoneNumberExtensionValidator(control: UntypedFormControl) {
    if (!control.value.match(/^[0-9]*$/)) {
      return { invalidExtension: true };
    }

    return null;
  }

  static phoneNumberPrefixLineValidator(control: UntypedFormControl) {
    if (!control.value.match(/[\d]{3}-{0,1}[\d]{4}/)) {
      return { invalidPhoneNumber: true };
    }

    // remove the optional dashes for consistent data
    const phoneNumberPrefixLine = control.value.replace(/-/g, '');

    const prefixLine = phoneNumberPrefixLine.substring(0, 7);
    if (prefixLine === '9999999' || prefixLine === '0000000') {
      return { invalidPhoneNumber: true };
    }

    return null;
  }

  static phoneNumberValidator(control: UntypedFormControl) {
    if (control && _get(control, 'value')) {
      // does it look like a phone number?
      if (!control.value.match(/^([\d]{6}|((\([\d]{3}\)|[\d]{3})( [\d]{3} |-[\d]{3}-)))[\d]{4}$/)) {
        return { invalidPhoneNumber: true };
      }

      // remove the optional dashes for consistent data
      const phoneNumber = control.value.replace(/-/g, '');

      // is the area code 000/999?
      const areaCode = phoneNumber.substring(0, 3);
      if (areaCode === '000' || areaCode === '999') {
        return { invalidPhoneNumber: true };
      }

      const body = phoneNumber.substring(3, 11);
      if (body === '9999999' || body === '0000000') {
        return { invalidPhoneNumber: true };
      }
    }
    return null;
  }

  /* ================================================================================
      05 -- Email validations
  */

  static allowedEmailCharactersValidator(control: UntypedFormControl) {
    if (!control.value || control.value.match(/^[a-zA-Z 0-9\-\&\'\/\.\+\_\@]*$/)) {
      return null;
    }

    return { invalidEmailSpecialCharacters: true };
  }

  static emailValidator(control: UntypedFormControl) {
    if (!control.value) {
      return;
    }

    // RFC 2822 compliant regex
    const regexp =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,4})$/i;
    if (!control.value.match(regexp)) {
      return { invalidEmail: true };
    }

    return null;
  }

  // for managing email addresses & phone number
  // ensures the description field is required if the type field is 'OTHER'
  static descIsRequired(type: string, desc: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const typeOfFormElement = _get(group.get(type), 'value');
      const descOfFormElement = _get(group.get(desc), 'value');
      return typeOfFormElement === 'OTHER' &&
        !(descOfFormElement ? descOfFormElement.toString().trim() : null)
        ? { required: true }
        : null;
    };
  }

  // checks if an email using `emailProp` and `typeProp` already exists in array `emailSet`
  static isDupEmail(emailProp, typeProp, emailSet) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const emailAddr = group.controls[emailProp].value;
      const emailType = group.controls[typeProp].value;
      // get any emails with same address
      let matches: any[] = emailSet.filter(email => email.emailAddress === emailAddr);
      // get any emails with same address that also have same type
      matches = matches.filter(
        email => !!email.contactMethodUsages.find(code => code.typeOfUsageCode === emailType)
      );
      return matches.length ? { isDupEmail: true } : null;
    };
  }

  /* ================================================================================
      06 -- Address validations
  */

  static fnolCityValidator(control: UntypedFormControl) {
    if (control && control.value && !control.value.match(/^[a-z0-9\s-'/]+$/i)) {
      return { invalidCity: true };
    }

    return null;
  }

  /*
   *Specific validator for the zip code for
   *enrollment.  Needs to be numbers and 5 digits
   */
  static zipCodeNumbersAndLength(control: UntypedFormControl) {
    if (!control.value.match(/^[0-9]+$/)) {
      return { onlyNumbers: true };
    } else if (control.value.length !== 5) {
      return { zipCodeNotFive: true };
    }

    return null;
  }

  static zipCodeValidator(control: UntypedFormControl) {
    if (control.value != null && control.value.match(/^\d{5}(-\d{4})?$/)) {
      return null;
    }

    return { invalidZipcode: true };
  }

  /* ================================================================================
      07 -- Password validations
  */

  // KP: this method is updated to also account for a partial match scenario (of length 7)
  // if the user id is 'abcdefghi' and the password contains 'abcdefg', 'bcdefgh' or 'cdefghi', it
  // is an erroneous one
  static passwordContainsUserID(usernameInput: string, passwordInput: string) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const username = group.controls[usernameInput];
      const password = group.controls[passwordInput];
      if (_get(password, 'value.length') === 0) {
        return { passwordContainsUserID: true };
      }
      for (let i = 0; i <= password.value.length - 7; i++) {
        // calculate all possible substrings of length 7 for the userId and check if any of the
        // calculated substrings matches the entered password. If it does, we've got ourselves a validation error.
        const substr = password.value.substring(i, i + 7);
        if (username.value.indexOf(substr) !== -1) {
          return { passwordContainsUserID: true };
        }
      }
      return null;
    };
  }

  static passwordValidator(control: UntypedFormControl) {
    if (!control.value.match(/[A-Za-z0-9_~\-!@#\$%\^&\*\(\)]+$/)) {
      return { invalidPassword: true };
    }

    return null;
  }

  static invalidPasswordChars(control: UntypedFormControl) {
    if (!control.value.match(/^[^|*#';\s]+$/)) {
      return { invalidPasswordChars: true };
    }

    return null;
  }

  /* ================================================================================
      08 -- Format validations, similar to character validations, but more specific (e.g., looks like a policy number)
  */

  static uuidValidator(control: UntypedFormControl) {
    if (
      control.value &&
      control.value.match(
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
      )
    ) {
      return null;
    }

    return { invalidUuid: true };
  }

  static vinFormatValidator(control: UntypedFormControl) {
    // JC: VIN is alphanumeric and exactly 17 chars
    if (control && control.value) {
      const alphaNum = control.value.match(/^[a-z0-9]+$/i);
      if (!alphaNum) {
        return { specialCharVIN: true };
      } else if (control.value.length < 17) {
        return { shortVIN: true };
      } else if (control.value.length > 17) {
        return { longVIN: true };
      }
      return null;
    }

    return null;
  }

  static vinRequiredValidator(control: UntypedFormControl) {
    if (control.value.length === 0) {
      return { missingVIN: true };
    }

    return null;
  }

  // custom validator for third party risk lookup
  // requires either vin, two of year/make/model, or unknown checkbox
  static vehicleInfoFormValidator(control: UntypedFormControl) {
    if (
      !control.get('vin').value &&
      !control.get('make').value &&
      !control.get('unknownRisk').value
    ) {
      return { missingValues: true };
    }

    return null;
  }

  // DM: custom validator that requires all or none of Y/M/M
  static vehicleYMMAllOrNoneValidator(control: UntypedFormControl) {
    if (
      (control.get('vehicleYear').value &&
        (!control.get('vehicleMake').value || !control.get('vehicleModel').value)) ||
      (control.get('vehicleMake').value && !control.get('vehicleModel').value)
    ) {
      return { missingValues: true };
    }

    return null;
  }

  static policyNumberValidator(control: UntypedFormControl) {
    if (control.value && typeof control.value === 'string') {
      const policyNumberWithoutHyphen = control.value.replace(/-/g, '');

      if (!policyNumberWithoutHyphen.match(/^[a-zA-Z0-9-]+$/)) {
        return { invalidPolicyNumber: true };
      }
      // If the policy number starts with 4100 but does not have 12 digits we tell the user to enter 12 digits.
      if (
        policyNumberWithoutHyphen.indexOf('4100') === 0 &&
        policyNumberWithoutHyphen.length < 12
      ) {
        return { invalidPolicyNumberMaxLength: true };
      }

      // If the policy number does not have 10 digits we tell the user to enter 10 digits.
      if (policyNumberWithoutHyphen.indexOf('4100') !== 0 && policyNumberWithoutHyphen.length < 8) {
        return { invalidPolicyNumberMinLength: true };
      }
    }

    return null;
  }

  static accountNumberValidator(control: UntypedFormControl) {
    // TODO: Have to figure it out exact error message !!
    // Checks if alphanumeric and hyphens only + 25 character limit
    if (!control.value.match(/^[a-zA-Z0-9-]+$/)) {
      return { invalidAccountNumber: true };
    }

    return null;
  }

  static bankAccountNumberValidator(control: UntypedFormControl) {
    // AS: alphanumeric characters
    if (!control.value.match(/^[a-z0-9]+$/i)) {
      return { inValidBankAccountNumber: true };
    }

    return null;
  }

  static ssnValidator(control: UntypedFormControl) {
    if (
      control.value &&
      control.value
        .replace(/-/g, '')
        .match(
          /^(?!\b(\d)\1+\b)(?!123456789|123123123|219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/
        )
    ) {
      return null;
    }

    return { invalidSSN: true };
  }

  static ssnLastFourDigitsValidator(control: UntypedFormControl) {
    if (control.value.match(/0000/)) {
      return { invalidSSN: true };
    }

    return null;
  }

  static firstNameValidator(control: UntypedFormControl): any {
    if (!control.value) {
      return;
    }

    const value = control.value.toLowerCase();

    if (!value.match(/[a-zA-Z\'\-\ ]/g)) {
      return { specialCharError: true };
    }

    if (value.match(/[a-zA-Z\'\-\ ]/g).length !== value.length) {
      return { specialCharError: true };
    }

    if (!!value.match(/( and )/g) || !!value.match(/( or )/)) {
      return { invalidAndOr: true };
    }

    if (control.value === null || control.value === '') {
      return { atLeastOneLetterErrorMsg: true };
    }

    if (!control.value.match(/^(?=.*[a-zA-Z])/)) {
      return { atLeastOneLetterErrorMsg: true };
    }

    if (!!value.match(/( estate|estate | estates|estates | trust|trust )/g)) {
      return { invalidFirstNameEstate: true };
    }
  }

  static lastNameEntryValidator(control: UntypedFormControl) {
    if (control.value === null || control.value === '') {
      return { atLeastOneLetterErrorMsg: true };
    } else if (!control.value.match(/^(?=.*[a-zA-Z0-9\s-./#&])/)) {
      return { atLeastOneLetterErrorMsg: true };
    }

    return null;
  }

  static addressEntryValidator(control: UntypedFormControl) {
    if (control.value === null || control.value === '') {
      return;
    }
    if (!control.value.match(/^(?=.*[a-zA-Z0-9])/)) {
      return { atLeastOneLetterOrNumErrorMsg: true };
    }
    return null;
  }

  static firstNameEntryValidator(control: UntypedFormControl) {
    if (control.value === null || control.value === '') {
      return null;
    } else if (!control.value.match(/^(?=.*[a-zA-Z])/)) {
      return { atLeastOneLetterErrorMsg: true };
    }

    return null;
  }
  static cityEntryValidator(control: UntypedFormControl) {
    if (control.value === null || control.value === '') {
      return { atLeastOneLetterOrNumErrorMsg: true };
    } else if (!control.value.match(/^(?=.*[a-zA-Z0-9])/)) {
      return { atLeastOneLetterOrNumErrorMsg: true };
    }

    return null;
  }

  static lastNameAdditionalValidator(control: UntypedFormControl) {
    if (_get(control, 'value')) {
      if (control.value.match(/\sand\s+/)) {
        return { lastnameAdditionalValidation: true };
      } else if (control.value.match(/\sor\s+/)) {
        return { lastnameAdditionalValidation: true };
      } else if (!control.value.match(/^([^0-9]*)$/)) {
        return { lastnameAdditionalValidation: true };
      }
    }

    return null;
  }

  static nolastNameValidatior(control: UntypedFormControl) {
    if (_get(control, 'value')) {
      const lastNameInput = control.value.replace(/\s/g, '').toUpperCase();
      if (lastNameInput === 'NOLASTNAME' || control.value.match(/nln+/i)) {
        return { lastnameAdditionalValidation: true };
      }
    }

    return null;
  }

  static nickNameValidator(control: UntypedFormControl) {
    if (!control.value.match(/^([a-zA-Z0-9\s])*$/)) {
      return { invalidNickName: true };
    } else if (typeof control.value === 'string' && !control.value.trim()) {
      return { noSpaceNickName: true };
    }

    return null;
  }

  static nickNameNumberLengthValidator(control: UntypedFormControl) {
    const str = control.value.replace(/[a-zA-Z\s]/g, '');
    const num = str.match(/\d+/);
    if (num) {
      if (num[0].length > 4) {
        return { noMoreThanFourNumbers: true };
      }
    }

    return null;
  }

  static nickNameDuplicationValidator(currentNickName: string, nickNamelist: string[]) {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      for (const nickName of nickNamelist) {
        // AS : Start the comparison only when the form group is not pristine.
        if (
          nickName.toUpperCase() ===
            group.controls[currentNickName].value.toString().toUpperCase() &&
          !group.pristine
        ) {
          return { duplicateNickName: true };
        }
      }
      return null;
    };
  }

  // We are passing flag here to check if billingCodes flag is enabled.
  static paymentAmountValidation(flag: boolean) {
    return (control: AbstractControl): { [key: string]: any } => {
      const amount =
        typeof control.value === 'number'
          ? control?.value?.toFixed(2)
          : control?.value?.replace(/[$,]/g, '');
      if (control && control.dirty && amount) {
        // JJC: Don't trigger error unless control is dirty and has a value
        const otherAmtStr = String(amount); // SK: convert to string to match with regex.
        if (amount <= 0) {
          if (flag) {
            return { paymentGreaterThanZero: true };
          } else {
            return { invalidPaymentAmnt: true };
          }
        } else if (!otherAmtStr.match(/(^[0-9]+(\.[0-9]{1,2})?$)|(^(\.[0-9]{1,2})?$)/)) {
          return { invalidPaymentAmnt: true };
        }
        return null;
      }
      return null;
    };
  }

  static paymentAmountMaxValidation(paymentAmounts: PaymentAmountModel) {
    // SK: Error messages yet to come.
    return (control: AbstractControl): { [s: string]: boolean } => {
      const amount =
        typeof control.value === 'number'
          ? control?.value?.toFixed(2)
          : control?.value?.replace(/[$,]/g, '');
      if (
        paymentAmounts.balance.valueOf() >= 0 &&
        Number(amount) > paymentAmounts.balance.valueOf() + 10
      ) {
        return { noMoreThanTenOverBalance: true };
      } else if (paymentAmounts.balance.valueOf() < 0 && Number(amount) > 10) {
        return { noMoreThanTenOverBalance: true };
      } else if (_has(paymentAmounts, 'accountType')) {
        if (paymentAmounts.accountType === 'PERSONAL' && Number(amount) > 30000) {
          return { noMoreThanPersonalLimit: true };
        } else if (paymentAmounts.accountType === 'COMMERCIAL' && Number(amount) > 85000) {
          return { noMoreThanCommercialLimit: true };
        }

        return null;
      }

      return null;
    };
  }

  /* ================================================================================
  09 -- Financial validations (e.g., credit cards, routing numbers)
  */

  // returns a specific Object signature that is akin to a String Map (think typedef from C++) with a string key and a boolean value
  static creditCardValidator(control: UntypedFormControl): { [s: string]: boolean } {
    const card: string = control.value.toString();
    if (card.length > 0) {
      // length test
      if (card.length < CCValidator.MIN_LENGTH) {
        return { ccMinLength: true };
      }
      // general validation test
      if (!CCValidator.isValid(card)) {
        return { ccInvalid: true };
      }

      return null;
    }

    return null;
  }

  static routingNumberValidator(control: UntypedFormControl) {
    if (!control.value.match(/^[0-9]*$/)) {
      return { invalidRoutingNumber: true };
    }

    return null;
  }

  static paymentMethodNotAddOrEditValidator(control: UntypedFormControl) {
    if (control && control.dirty && control.value) {
      if (control.value === 'addPaymentMethod' || control.value === 'editPaymentMethod') {
        return { invalidPaymentMethod: true };
      }
    }

    return null;
  }

  static photoUploadTextAreaInput(control: UntypedFormControl) {
    if (control && control.dirty && control.value) {
      if (control.value.match(/[^A-Za-z0-9 ,.?!’']+/g)) {
        return { showSpecialCharWarningMsg: true };
      }
    }
    return null;
  }

  static minimumDuePaymentPastDueValidator(
    otherAmountCtrlName: string,
    minimumDue: string,
    cancellationLetterSent = false
  ) {
    return (formControl: AbstractControl): { [key: string]: any } => {
      const amount = formControl?.value?.replace(/\$|,/g, '');
      const minimumDueAmt = minimumDue?.replace(/\$|,/g, '');
      if (Number(amount) < Number(minimumDueAmt)) {
        return cancellationLetterSent
          ? { payingLessThanMinDuePastDueCancellationLetterSent: true }
          : { payingLessThanMinDuePastDue: true };
      }
      return null;
    };
  }

  // JP: Custom validator to ensure that at least one checkbox is checked else form is disabled
  static minimumCheckedCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: UntypedFormGroup) {
      let checked = 0;

      _get(formGroup, "controls['option']['controls']", []).forEach(option => {
        if (option.value) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireCheckboxToBeChecked: true
        };
      }

      return null;
    };
  }

  static maxNumberValidator(maxValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value?.replace(/[^0-9.-]+/g, '');
      return +value > maxValue ? { maxValue: { max: maxValue, actual: value } } : null;
    };
  }

  static minNumberValidator(minValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value?.replace(/[^0-9.-]+/g, '');
      return +value < minValue ? { minValue: { max: minValue, actual: value } } : null;
    };
  }

  static validMoneyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value?.replace(/[^0-9.-]+/g, '');
      return Number.isNaN(value) ? { invalidMoney: control.value } : null;
    };
  }

  static customerFullNameValidator(control: AbstractControl): ValidationErrors | null {
    // Regex to accept any char followed by space and another character after space
    const namePattern = /^\S+\s\S/;

    return control.value?.match(namePattern) ? null : { invalidFullName: true };
  }

  static odometerReadingValidator(currentOdometerReading: number | undefined): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const valNumber = Number(control.value);
      const newVal = isNaN(valNumber) ? 0 : valNumber;
      return newVal < currentOdometerReading ? { odometerLessThanCurrent: true } : null;
    };
  }

  // common validator wrapper that allows for a custom error message to be used with any validator
  static validateWithKey(validatorFn: ValidatorFn, key: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const result = validatorFn(control);
      return result ? { invalidCustom: { ...result, key } } : null;
    };
  }
}
