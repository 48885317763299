<div class="row pad-16">
  <card cardClasses="profile-card gray" data-cy="profileCard">
    <div card-header class="flex">
      <div class="flex items-center justify-space-between flex-1">
        <div>
          @if(isPartyLoaded){
          <h3 data-cy="accountUserName">{{ profileCard.firstName }} {{ profileCard.lastName }}</h3>
          }
          <div *ngIf="canShowStatus">
            Status:
            <span class="statusActive" *ngIf="!isShellAccount"> Active </span>
            <span class="statusPending" *ngIf="isShellAccount"> Shell Account Pending </span>
          </div>
        </div>
        @if(!forgerockEnabled && !isShellAccount) {
        <div>
          <h4 data-cy="accountUserID">
            User ID: <span>{{ profileCard.userId }}</span>
          </h4>
        </div>
        }
      </div>
    </div>
    <div card-content>
      <div class="flex flex-direction-column">
        <div>
          <h4
            content
            [contentPath]="
              isShellAccount ? 'shared.enrolledEmail' : 'profile.manageEmails.primaryEmail'
            "
            data-cy="accountEmailAddress"
          ></h4>
          <p class="primary-email">
            {{
              isShellAccount
                ? shellEmail
                : profileCard.primaryEmail
                ? profileCard.primaryEmail.emailAddress
                : ''
            }}
          </p>

          @if(profileCard.primaryPhone){
          <h4>Primary phone number</h4>
          <p>
            {{
              profileCard.primaryPhone.areaCode + profileCard.primaryPhone.phoneNumber
                | phoneNumberSingleStringFormatter: profileCard.primaryPhone.extension
            }}
          </p>
          } @else {
          <div *ngIf="!isMyAccountAdmin">
            <h4>Primary phone number</h4>
            <p><a (click)="moveToPhoneNumberClicked.emit()">Add phone number</a></p>
          </div>
          }
        </div>
        <div>
          <div *ngIf="isCustomer">
            <h4 data-cy="accountAddress">Mailing address</h4>
            <address *ngIf="profileCard.address">
              <span>{{ profileCard.address.line1 }}</span
              ><br />
              <span *ngIf="profileCard.address.line2">{{ profileCard.address.line2 }}<br /></span>
              <span class="pad-right-6">{{ profileCard.address.city }},</span>
              <span class="pad-right-6">{{ profileCard.address.state }}</span>
              <span>{{ profileCard.address.zip5 }}</span>
            </address>
            <p *ngIf="!profileCard.address">N/A</p>
          </div>
        </div>
      </div>
    </div>
  </card>
</div>
