import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromOnboardingPaperlessEnrollmentFeature from './onboarding-paperless-enrollment-feature-reducer';

export const selectPaperlessOnboardingEnrollmentFeatureState =
  createFeatureSelector<fromOnboardingPaperlessEnrollmentFeature.OnboardingPaperlessEnrollmentFeatureState>(
    fromOnboardingPaperlessEnrollmentFeature.onboardingPaperlessEnrollmentFeatureKey
  );

export const selectIsOnboardingFlow = createSelector(
  selectPaperlessOnboardingEnrollmentFeatureState,
  (state: fromOnboardingPaperlessEnrollmentFeature.OnboardingPaperlessEnrollmentFeatureState) =>
    state?.onboardingFlow
);

export const selectCorrelationId = createSelector(
  selectPaperlessOnboardingEnrollmentFeatureState,
  (state: fromOnboardingPaperlessEnrollmentFeature.OnboardingPaperlessEnrollmentFeatureState) =>
    state?.correlationId
);
export const paperlessOnboardingEnrollmentFeatureQuery = {
  selectPaperlessOnboardingEnrollmentFeatureState,
  selectIsOnboardingFlow,
  selectCorrelationId
};
