import { createAction } from '@ngrx/store';

import { TPIIndicator } from '@amfam/policy/vehicle/data-access';

import {
  GetMakesPayload,
  GetModelsPayload,
  GetStylesPayload,
  GetVehicleMakesApiResponse,
  GetVehicleModelsApiResponse,
  GetVehicleSeriesApiResponse,
  GetVehicleVinYearApiResponse,
  VechiclePolicyChangeEntryPoint,
  VehicleSelectCriteria
} from '../models/vehicle.models';

export enum VehicleActionTypes {
  ResetType = '[Vehicle] Reset',
  LookupVinYearType = '[Vehicle] Lookup Vehicle By Vin and Year',
  LookupVinYearSuccessType = '[Vehicle] Lookup Vehicle By Vin and Year Success',
  LookupVinYearFailType = '[Vehicle] Lookup Vehicle By Vin and Year Failure',
  GetMakesType = '[Vehicle] Get Vehicle Makes',
  GetMakesSuccessType = '[Vehicle] Get Vehicle Makes Success',
  GetMakesFailType = '[Vehicle] Get Vehicle Makes Failure',
  GetModelsType = '[Vehicle] Get Vehicle Models',
  GetModelsSuccessType = '[Vehicle] Get Vehicle Models Success',
  GetModelsFailType = '[Vehicle] Get Vehicle Models Failure',
  GetStylesType = '[Vehicle] Get Vehicle Styles',
  GetStylesSuccessType = '[Vehicle] Get Vehicle Styles Success',
  GetStylesFailType = '[Vehicle] Get Vehicle Styles Failure',
  SetCriteriaType = '[Vehicle] Set Vehicle Lookup Criterion',
  SetTpiType = '[Vehicle] Set Tpi',
  SetVehiclePolicyChangeEntryPointType = '[Vehicle] Set vehicle policy change entry point'
}

export const Reset = createAction(VehicleActionTypes.ResetType);

export const LookupVinYear = createAction(
  VehicleActionTypes.LookupVinYearType,
  (payload: VehicleSelectCriteria) => ({ payload })
);

// TODO - determine response payload dimensions and create model
export const LookupVinYearSuccess = createAction(
  VehicleActionTypes.LookupVinYearSuccessType,
  (payload: GetVehicleVinYearApiResponse) => ({ payload }) // has success status
);

// TODO - determine response payload dimensions and create model
export const LookupVinYearFail = createAction(
  VehicleActionTypes.LookupVinYearFailType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload: any) => ({ payload }) // has error status. This might be a string or an ApiStatus object. Any is the best type we can get
);

export const GetMakes = createAction(
  VehicleActionTypes.GetMakesType,
  (payload: GetMakesPayload) => ({ payload })
);

export const GetMakesSuccess = createAction(
  VehicleActionTypes.GetMakesSuccessType,
  (payload: GetVehicleMakesApiResponse) => ({ payload }) // has makes array
);

export const GetMakesFail = createAction(
  VehicleActionTypes.GetMakesFailType,
  (payload: GetVehicleMakesApiResponse) => ({ payload }) // has error status, empty makes array
);

export const GetModels = createAction(
  VehicleActionTypes.GetModelsType,
  (payload: GetModelsPayload) => ({ payload })
);

export const GetModelsSuccess = createAction(
  VehicleActionTypes.GetModelsSuccessType,
  (payload: GetVehicleModelsApiResponse) => ({ payload }) // has models array
);

export const GetModelsFail = createAction(
  VehicleActionTypes.GetModelsFailType,
  (payload: GetVehicleModelsApiResponse) => ({ payload }) // has error status & empty models array
);

export const GetStyles = createAction(
  VehicleActionTypes.GetStylesType,
  (payload: GetStylesPayload) => ({ payload })
);

export const GetStylesSuccess = createAction(
  VehicleActionTypes.GetStylesSuccessType,
  (payload: GetVehicleSeriesApiResponse) => ({ payload }) // has styles array, may be empty
);

export const GetStylesFail = createAction(
  VehicleActionTypes.GetStylesFailType,
  (payload: GetVehicleSeriesApiResponse) => ({ payload }) // has error status & empty styles array
);

export const SetCriteria = createAction(
  VehicleActionTypes.SetCriteriaType,
  (payload: VehicleSelectCriteria) => ({ payload })
);

export const SetTpi = createAction(
  VehicleActionTypes.SetTpiType,
  (payload: { tpiName: string; copyTpiIndicator: TPIIndicator }) => ({ payload })
);

export const SetVehiclePolicyChangeEntryPoint = createAction(
  VehicleActionTypes.SetVehiclePolicyChangeEntryPointType,
  (payload: { entryPoint: VechiclePolicyChangeEntryPoint }) => ({ payload })
);

export type VehicleAction =
  | ReturnType<typeof Reset>
  | ReturnType<typeof LookupVinYear>
  | ReturnType<typeof LookupVinYearSuccess>
  | ReturnType<typeof LookupVinYearFail>
  | ReturnType<typeof GetMakes>
  | ReturnType<typeof GetMakesSuccess>
  | ReturnType<typeof GetMakesFail>
  | ReturnType<typeof GetModels>
  | ReturnType<typeof GetModelsSuccess>
  | ReturnType<typeof GetModelsFail>
  | ReturnType<typeof GetStyles>
  | ReturnType<typeof GetStylesSuccess>
  | ReturnType<typeof GetStylesFail>
  | ReturnType<typeof SetCriteria>
  | ReturnType<typeof SetTpi>
  | ReturnType<typeof SetVehiclePolicyChangeEntryPoint>;

export const fromVehicleActions = {
  Reset,
  LookupVinYear,
  LookupVinYearSuccess,
  LookupVinYearFail,
  GetMakes,
  GetMakesSuccess,
  GetMakesFail,
  GetModels,
  GetModelsSuccess,
  GetModelsFail,
  GetStyles,
  GetStylesSuccess,
  GetStylesFail,
  SetCriteria,
  SetTpi,
  SetVehiclePolicyChangeEntryPoint
};
