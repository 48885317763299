import { Observable } from 'rxjs';

import * as enrollStepsActions from './enroll-steps.actions';
import { EnrollStepsModel, initialState } from './enroll-steps.model';

export function reducer(
  state = initialState,
  action: enrollStepsActions.Actions
): EnrollStepsModel {
  switch (action.type) {
    case enrollStepsActions.PERSONAL_ID_COMPLETE:
      return Object.assign({}, state, {
        identificationStep: action.payload.identificationStep
      });

    case enrollStepsActions.VERIFY_COMPLETE:
      return Object.assign({}, state, {
        verificationStep: action.payload.verificationStep
      });

    case enrollStepsActions.PERSONAL_SIGNUP_COMPLETE:
      return Object.assign({}, state, {
        personalSignUpStep: action.payload.personalSignUpStep
      });

    case enrollStepsActions.PERSONAL_SECURITYQUESTIONS_COMPLETE:
      return Object.assign({}, state, {
        personalSecurityQuestionsStep: action.payload.personalSecurityQuestionsStep
      });

    case enrollStepsActions.BUSINESS_ENROLL_COMPLETE:
      return Object.assign({}, state, {
        businessEnrollStep: action.payload.businessEnrollStep
      });

    case enrollStepsActions.BUSINESS_SIGNUP_COMPLETE:
      return Object.assign({}, state, {
        businessSignUpStep: action.payload.businessSignUpStep
      });

    case enrollStepsActions.BUSINESS_SECURITYQUESTIONS_COMPLETE:
      return Object.assign({}, state, {
        businessSecurityQuestionsStep: action.payload.businessSecurityQuestionsStep
      });

    case enrollStepsActions.RESET_STEPS:
      return initialState;

    default:
      return state;
  }
}

export const identificationStep = (state: EnrollStepsModel) => state.identificationStep;
export const verificationStep = (state: EnrollStepsModel) => state.verificationStep;
export const personalSignUpStep = (state: EnrollStepsModel) => state.personalSignUpStep;
export const personalSecurityQuestionsStep = (state: EnrollStepsModel) =>
  state.personalSecurityQuestionsStep;
export const businessEnrollStep = (state: EnrollStepsModel) => state.businessEnrollStep;
export const businessSignUpStep = (state: EnrollStepsModel) => state.businessSignUpStep;
export const businessSecurityQuestionsStep = (state: EnrollStepsModel) =>
  state.businessSecurityQuestionsStep;
