import { Pipe, PipeTransform } from '@angular/core';
import { get as _get } from 'lodash';

import { PhoneNumberSingleStringFormatterPipe } from '@amfam/shared/ui/pipes';

@Pipe({
  name: 'phoneDescription'
})
export class PhoneDescriptionPipe
  extends PhoneNumberSingleStringFormatterPipe
  implements PipeTransform
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): string {
    if (_get(value, 'tenDigitPhoneNumber')) {
      const phoneDescription = super.transform(value.tenDigitPhoneNumber, value.extension);
      return phoneDescription;
    }
  }
}
