import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as fromRoot from '../../';
import { KydSmartphoneService } from '../../../../policy/know-your-drive-partial-household/services/kyd-smartphone.service';
import * as kydSmartphoneEnrollPolicyActions from './kyd-smartphone-enroll-policy.actions';

@Injectable()
export class KydSmartphoneEnrollPolicyEffects {
  enrollPolicy = createEffect(() =>
    this.action$.pipe(
      ofType(kydSmartphoneEnrollPolicyActions.ENROLL_POLICY),
      map((action: kydSmartphoneEnrollPolicyActions.EnrollPolicyAction) => action.payload),
      mergeMap(policy =>
        this.kydSmartphoneService.enrollPolicy(policy).pipe(
          map(res => {
            if (res.code === 200) {
              return new kydSmartphoneEnrollPolicyActions.EnrollPolicySuccessAction(res);
            } else {
              return new kydSmartphoneEnrollPolicyActions.EnrollPolicyFailAction(res);
            }
          }),
          catchError(error => {
            return observableOf(new kydSmartphoneEnrollPolicyActions.EnrollPolicyFailAction(error));
          })
        )
      )
    )
  );

  constructor(
    private store: Store<fromRoot.RootState>,
    private kydSmartphoneService: KydSmartphoneService,
    private action$: Actions
  ) {}
}
