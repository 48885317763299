import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { fromAddVehicleQuoteActions } from '@amfam/policy/add-vehicle/data-access';
import { PolicySelectors } from '@amfam/policy/data-access';
import { ChangeCoverageEmailType, ICoverage } from '@amfam/policy/models';
import { CoverageFilter } from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { changeCoverageBindNotify } from '../../../../../change-coverage/feature/src/lib/+state/change-coverage-feature/change-coverage-feature.actions'; // TODO temp fix for circular dependency
import { ChangeCoverageFeatureFacade } from '../../../../../change-coverage/feature/src/lib/+state/change-coverage-feature/change-coverage-feature.facade'; // TODO temp fix for circular dependency
import { VehicleCoverageService } from '../services/vehicle-coverages.service';
import {
  addVehicleCoverage,
  addVehicleCoverageForChangeCoverage,
  bindChangeCoverageQuote,
  bindChangeCoverageQuoteError,
  bindChangeCoverageQuoteSuccess,
  bindPartnerAddVehicleChangeCoverageQuote,
  bindPartnerAddVehicleChangeCoverageQuoteError,
  bindPartnerAddVehicleChangeCoverageQuoteSuccess,
  cancelChangeCoverage,
  changeCoverageAPI,
  changeCoverageAPIError,
  emptyAction,
  FEATURES,
  LoadInitialRate,
  saveAndRate,
  saveAndRateError,
  setBaseCoverage
} from './vehicle-coverages.actions';
import { selectSelectedEntity } from './vehicle-coverages.selectors';

@Injectable()
export class VehicleCoveragesEffects {
  saveAndRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveAndRate, LoadInitialRate),
      concatLatestFrom(() => [
        this.store.select(selectSelectedEntity),
        this.store.select(BrandSelectors.selectPartnerId),
        this.store.select(userQuery.getUserStateCode)
      ]),
      mergeMap(([action, selectedEntity, partnerId, stateCode]) => {
        if (action.feature === FEATURES.CHANGE) {
          this.changeCoverageFeatureFacade.sendAnalytics({
            event: action.feature,
            pageAnalyticName: 'QuoteSpinner'
          });
        }
        this.spinnerService.start({
          blockActions: true,
          loadingMessage: this.copyService.getCopy('policy.changeCoverage.loadingtextAFI'),
          secondaryMessage: this.copyService.getCopy(
            'policy.changeCoverage.quoteCalculatingLoadingSpinnerTextAFI'
          )
        });

        return this.vehicleCoverageService.saveAndRate(selectedEntity, partnerId, stateCode).pipe(
          switchMap(output => {
            if (action.feature === FEATURES.CHANGE) {
              output.result.vehicleCoverages.forEach((vc, vcIndex) => {
                output.result.vehicleCoverages[vcIndex].coverages = vc.coverages.map(
                  (c): ICoverage => {
                    const coverage = selectedEntity.vehicleCoverages[vcIndex].coverages.find(
                      sc => sc.publicID === c.publicID
                    );
                    c.hide = coverage.hide ? coverage.hide : false;
                    return c;
                  }
                );
              });
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (action as any).initial
              ? [
                  action.feature === FEATURES.CHANGE
                    ? addVehicleCoverage({
                        payload: { ...output.result, id: uuid() },
                        feature: action.feature,
                        vehicleId: action.vehicleId,
                        rate: true
                      })
                    : addVehicleCoverageForChangeCoverage({
                        payload: { ...output.result, id: uuid() },
                        feature: action.feature,
                        vehicleId: action.vehicleId,
                        rate: true
                      }),
                  setBaseCoverage({
                    payload: { ...output.result, id: uuid() },
                    feature: action.feature,
                    vehicleId: action.vehicleId
                  }),
                  fromAddVehicleQuoteActions.addVehicleRateSuccess({
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    payload: output as any
                  })
                ]
              : [
                  action.feature === FEATURES.CHANGE
                    ? addVehicleCoverage({
                        payload: { ...output.result, id: uuid() },
                        feature: action.feature,
                        vehicleId: action.vehicleId,
                        rate: true
                      })
                    : addVehicleCoverageForChangeCoverage({
                        payload: { ...output.result, id: uuid() },
                        feature: action.feature,
                        vehicleId: action.vehicleId,
                        rate: true
                      })
                ];
          }),
          catchError(() =>
            of(saveAndRateError({ feature: action.feature, vehicleId: action.vehicleId }))
          )
        );
      })
    );
  });

  saveAndRateError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveAndRateError),
      map(action => {
        switch (action.feature) {
          case FEATURES.ADD:
            return fromRouterActions.Go({ path: ['policies/add-vehicle/agent-help'] });
          case FEATURES.CHANGE:
            return fromRouterActions.Go({
              path: [`policies/change-coverage/${action.vehicleId}/agent-help`]
            });
          case FEATURES.REPLACE:
            return fromRouterActions.Go({ path: ['policies/add-vehicle/agent-help'] });
          default:
            return fromRouterActions.Go({ path: ['policies/add-vehicle/agent-help'] });
        }
      })
    );
  });

  changeCoverageAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeCoverageAPI, cancelChangeCoverage),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectAutoPolicyRisks),
        this.store.select(BrandSelectors.selectPartnerId),
        this.store.select(userQuery.getUserStateCode)
      ]),
      switchMap(([action, risks, partnerId, stateCode]) => {
        if (action.type === '[VehicleCoverage/API] Cancel Change Coverage') return EMPTY;
        this.spinnerService.start({
          blockActions: true
        });
        return this.vehicleCoverageService.changeCoverageAPI(action.vehicle, risks, partnerId).pipe(
          switchMap(output => {
            output.result.vehicleCoverages.forEach((vc, vcIndex) => {
              output.result.vehicleCoverages[vcIndex].coverages = vc.coverages.map(
                (c): ICoverage => {
                  const coveragesConfig = this.getCoveragesForState(partnerId !== 'AFI', stateCode);
                  const coverageConfig = coveragesConfig.get(c.publicID);
                  if (coverageConfig?.forceCheckbox) c.hasTerms = false;
                  /*
                  if (
                    c.publicID === 'PARentalCov' &&
                    c.hasTerms &&
                    c.terms &&
                    c.terms.length === 0
                  ) {
                    return getMockRentalReimbursment();
                  }
                  */
                  if (
                    c.selected &&
                    (!c.hasTerms ||
                      (c.hasTerms &&
                        c.terms &&
                        c.terms[0] &&
                        c.terms[0].options &&
                        c.terms[0].options.length === 1))
                  ) {
                    c.hide = true;
                  }
                  return c;
                }
              );
            });

            return [
              addVehicleCoverage({
                payload: { ...output.result, id: uuid() },
                feature: FEATURES.CHANGE,
                vehicleId: action.vehicle.vehicleId
              }),
              setBaseCoverage({
                payload: { ...output.result, id: uuid() },
                feature: FEATURES.CHANGE,
                vehicleId: action.vehicle.vehicleId
              })
            ];
          }),
          catchError(() => {
            this.spinnerService.stop();
            return [
              fromRouterActions.Go({
                path: [`/policies/change-coverage/${action.vehicle.vehicleId}/agent-help`]
              }),
              changeCoverageAPIError({ vehicleId: action.vehicle.vehicleId })
            ];
          })
        );
      })
    );
  });

  stopSpinnerAndGo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addVehicleCoverage, addVehicleCoverageForChangeCoverage),
      map(action => {
        if (action.rate) {
          this.spinnerService.stop();
          return emptyAction();
        }
        switch (action?.feature) {
          case FEATURES.CHANGE:
            this.spinnerService.stop();
            return emptyAction();
          case FEATURES.ADD:
            this.spinnerService.stop();
            return fromRouterActions.Go({
              path: [`policies/add-vehicle/get-quote`]
            });
          default:
            return emptyAction();
        }
      })
    );
  });

  bindChangeCoverageQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(bindChangeCoverageQuote),
      map(action => action.payload),
      switchMap(payload =>
        this.vehicleCoverageService
          .bindChangeCoverageFeature(payload.policyNumber, payload.vin)
          .pipe(
            switchMap(response => [
              bindChangeCoverageQuoteSuccess({
                payload: response
              }),
              changeCoverageBindNotify({
                payload: { type: ChangeCoverageEmailType.BIND, vehicleId: payload.vehicleId }
              })
            ]),
            catchError(() => of(bindChangeCoverageQuoteError({ vehicleId: payload.vehicleId })))
          )
      )
    );
  });

  bindPartnerAddVehicleChangeCoverageQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(bindPartnerAddVehicleChangeCoverageQuote),
      map(action => action.payload),
      switchMap(payload =>
        this.vehicleCoverageService
          .bindChangeCoverageFeature(payload.policyNumber, payload.vin)
          .pipe(
            switchMap(response => [
              bindPartnerAddVehicleChangeCoverageQuoteSuccess({
                payload: response
              })
              // changeCoverageBindNotify({
              //   payload: { type: ChangeCoverageEmailType.BIND, vehicleId: payload.vehicleId }
              // })
            ]),
            catchError(() =>
              of(bindPartnerAddVehicleChangeCoverageQuoteError({ vehicleId: payload.vehicleId }))
            )
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private vehicleCoverageService: VehicleCoverageService,
    private spinnerService: LoadingSpinnerService,
    private copyService: CopyService,
    private changeCoverageFeatureFacade: ChangeCoverageFeatureFacade
  ) {}

  private getCoveragesForState(isPartner: boolean, state: string): Map<string, CoverageFilter> {
    const coverageFilter = this.copyService.getCopy(
      isPartner
        ? 'policy.changeCoverage.partnerCoverageFilter'
        : 'policy.changeCoverage.coverageFilter'
    );
    const filterMap: Map<string, CoverageFilter> = new Map();
    for (const key in coverageFilter) {
      if (Object.prototype.hasOwnProperty.call(coverageFilter, key)) {
        const filter = coverageFilter[key];
        if (filter && (!filter.states || (filter.states && filter.states.includes(state)))) {
          filterMap.set(key, filter);
        }
      }
    }
    return filterMap;
  }
}
