import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { ConfigService } from '@amfam/shared/utility/shared-services';

import { ForgerockAuthService } from '../core/auth/forgerock-auth.service';

@Injectable()
/**
 *  This guard is used to redirect the partner users to the forgerock login page if they    *  are not logged in
 */
export class LoginRedirectGuard {
  constructor(
    private config: ConfigService,
    private forgerockAuthService: ForgerockAuthService,
    private featureFlagService: FeatureFlagService
  ) {}
  canActivate(route: ActivatedRouteSnapshot) {
    if (!this.featureFlagService.isEnabled('forgerock')) {
      return true;
    }
    const expid = route.paramMap.get('expid');

    if (expid) {
      localStorage.setItem('expid', expid);
      this.forgerockAuthService.getTokens(this.config.get('forgerock.login'), false);
      return false;
    }
    return true;
  }
}
