import { Routes } from '@angular/router';

import { ResetOptionsComponent } from '../forgot-password';
import { AcceptUseridComponent } from '../forgot-password/accept-userid/accept-userid.component';
import { ResetOptionsGuard } from '../forgot-password/reset-options/reset-options.guard';
import { ResetPasswordEmailComponent } from '../forgot-password/reset-options/reset-password-email/reset-password-email.component';
import { ResetPasswordOptionsComponent } from '../forgot-password/reset-options/reset-password-options/reset-password-options.component';
import { ResetPasswordTextComponent } from '../forgot-password/reset-options/reset-password-text/reset-password-text.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { ResetPasswordGuard } from '../reset-password/reset-password.guard';
import { InvalidTokenComponent } from './invalid-token/invalid-token.component';
import { ResetPasswordCallComponent } from './reset-options/reset-password-call/reset-password-call.component';
import { ResetPasswordSecurityQuestionsComponent } from './reset-options/reset-password-security-questions/reset-password-security-questions.component';
import { VerifyTokenComponent } from './verify-token/verify-token.component';

export const ForgotPasswordRoutes: Routes = [
  {
    path: '',
    component: AcceptUseridComponent
  },
  {
    path: 'reset-options',
    component: ResetOptionsComponent,
    canActivate: [ResetOptionsGuard],
    children: [
      {
        path: '',
        component: ResetPasswordOptionsComponent
      },
      {
        path: 'text',
        component: ResetPasswordTextComponent
      },
      {
        path: 'call',
        component: ResetPasswordCallComponent
      },
      {
        path: 'email',
        component: ResetPasswordEmailComponent
      },
      {
        path: 'security-questions',
        component: ResetPasswordSecurityQuestionsComponent
      }
    ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [ResetPasswordGuard]
  },
  {
    path: 'confirm/:token',
    component: VerifyTokenComponent
  },
  {
    path: 'invalid-token',
    component: InvalidTokenComponent
  }
];

export const ForgotPasswordProviders = [ResetOptionsGuard, ResetPasswordGuard];
