<ds-modal
  [id]="config.modalId"
  [maxWidth]="config.maxWidth ? config.maxWidth : 450"
  [closeOthers]="true"
  (onCloseModalEvent)="closeEvent()"
>
  <div class="modal-container" data-cy="modalContainer">
    <h1 data-cy="modalTitle" class="text-left modal-heading">{{ config.headerText }}</h1>
    <hr />
    <p data-cy="modalBody" class="text-left">{{ config.bodyText }}</p>
    <ng-content select="[modal-body]"> </ng-content>
    @if (!!config.ctaConfig) {
      <call-to-action
        [fullWidthButtons]="config.ctaConfig?.fullWidthButtons"
        [primaryButtonName]="config.ctaConfig?.primaryButtonName"
        [secondaryButtonName]="config.ctaConfig?.secondaryButtonName"
        [tertiaryButtonName]="config.ctaConfig?.tertiaryButtonName"
        [primaryButtonDestructive]="config.ctaConfig?.primaryButtonDestructive"
        (primaryEvent)="primaryEvent.emit()"
        (secondaryEvent)="secondaryEvent.emit()"
        (tertiaryEvent)="tertiaryEvent.emit()"
      >
      </call-to-action>
    }
  </div>
</ds-modal>
