import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { AddVehicleRateRequest, AddVehicleRateResponse } from '../../models/add-vehicle-rate.model';
import { AddVehicleService } from '../../services/add-vehicle.service';
import {
  addVehicleRate,
  addVehicleRateError,
  addVehicleRateSuccess,
  loadFuturePayments,
  loadFuturePaymentsFailed,
  loadFuturePaymentsSuccess
} from './add-vehicle-quote.actions';
import { addVehicleQuoteQuery } from './add-vehicle-quote.selectors';

@Injectable()
export class AddVehicleQuoteEffects {
  getAddVehicleRate$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(addVehicleRate),
      map(action => action.payload),
      switchMap((payload: AddVehicleRateRequest) =>
        this.addVehicleService.rateAddVehicleFeature(payload).pipe(
          map((response: AddVehicleRateResponse) => addVehicleRateSuccess({ payload: response })),
          catchError(error => {
            this.spinnerService.stop();
            return [
              fromRouterActions.Go({
                path: ['policies/add-vehicle/agent-help']
              }),
              addVehicleRateError({
                payload: {
                  request: payload,
                  status: error
                }
              })
            ];
          })
        )
      )
    );
  });

  getFuturePayments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFuturePayments),
      concatLatestFrom(() => [
        this.store.select(addVehicleQuoteQuery.selectNewVehicleWorkOrderNumber),
        this.store.select(addVehicleQuoteQuery.selectNewVehiclePolicyNumber)
      ]),
      map(([, workOrderNumber, policyNumber]) =>
        this.getPolicyAndWorkOrderNumber(policyNumber, workOrderNumber)
      ),
      switchMap(payload =>
        this.addVehicleService.getFuturePayments(payload).pipe(
          map(response => this.mapFuturePayments(response, payload.policyNumber)),
          catchError(() => of(loadFuturePaymentsFailed()))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private addVehicleService: AddVehicleService,
    private spinnerService: LoadingSpinnerService,
    private store: Store
  ) {}

  private mapFuturePayments(response, policyNumber: number) {
    if (!response)
      return loadFuturePaymentsSuccess({ payload: { policyNumber, paymentSchedule: null } });
    return loadFuturePaymentsSuccess({
      payload: {
        policyNumber,
        paymentSchedule: response.result?.paymentSchedule?.paymentPreviewItems
      }
    });
  }

  private getPolicyAndWorkOrderNumber(policyNumber, workOrderNumber) {
    return {
      policyNumber: policyNumber?.split('-').join(''),
      submissionId: workOrderNumber
    };
  }
}
