import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigService, CopyService } from '@amfam/shared/utility/shared-services';

@Injectable()
export class SignUpService {
  private _availability$: BehaviorSubject<any> = new BehaviorSubject(null);
  private _accountCreatedStore$: BehaviorSubject<any> = new BehaviorSubject(null);
  private baseDigitalAcctUrl: any;

  private componentName: any = {};
  private componentData: any;
  private toasterMessage: any;
  private emailList: any = [];

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private copyService: CopyService
  ) {
    this.baseDigitalAcctUrl = this.config.get('digitalAccountApi') + '/digitalaccounts';
    this.componentName.enrollment = 'enrollment';
    this.componentData = this.copyService.getComponentData(this.componentName.enrollment);
  }

  get availability$() {
    return this._availability$.asObservable();
  }

  get accountCreatedStore$() {
    return this._accountCreatedStore$.asObservable();
  }

  public getToasterMessage() {
    return this.toasterMessage;
  }

  public setToasterMessage(message: any) {
    this.toasterMessage = message;
  }

  // TODO Move this to auth folder
  // TODO wire the actual service implementation here
  public createAccount(body: any) {
    body = JSON.stringify(body);
    return this.http.post(this.baseDigitalAcctUrl, body);
  }
}
