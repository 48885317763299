@if (featureFlagLoaded$ | async) {
  @if (isForgeRockEnabled$ | async) {
    <div class="login">
      <div class="login__form">
        <div class="login__form-header" content [contentPath]="'auth.forgerock.welcome'"></div>
        <call-to-action
          [fullWidthButtons]="true"
          [preserveTextFormat]="true"
          [primaryButtonName]="primaryButtonName"
          [secondaryButtonName]="secondaryButtonName"
          [maxHeight]="true"
          (primaryEvent)="login()"
          (secondaryEvent)="registration()"
        >
        </call-to-action>
      </div>
    </div>
  } @else {
    <div class="main">
      @if (emailRedirect) {
        <h1 class="text-center">
          <span content [contentPath]="'profile.manageEmails.validateEmailLoginHeader'"></span>
        </h1>
      }
      <div class="row pad-top-20">
        @if (responseCode === 409) {
          <div class="pad-bottom-20">
            <amfam-info-banner
              *dsPartnerAFI
              [bannerConfig]="duplicateEmailBannerConfig"
              [showIcon]="true"
              [isDuplicateEmail]="true"
            ></amfam-info-banner>
          </div>
        }
        <div class="column twelve lg-six lg-push-three md-six md-push-three">
          <ds-cookie-check
            [showCookieWarning]="!cookiesEnabled && (cookieCheckFeatureEnabled$ | async)"
          ></ds-cookie-check>
          @if (cookiesEnabled) {
            <div class="card pad-20">
              @if (emailRedirect) {
                <p class="text-center">
                  <span
                    content
                    [contentPath]="'profile.manageEmails.validateEmailLoginSubHeader'"
                  ></span>
                </p>
              }
              @if (!emailRedirect) {
                <h1 data-cy="loginBaseHeading" class="heading-sub-page">
                  <span content [contentPath]="'auth.loginAccountText'"> </span>
                </h1>
              }
              <div class="content">
                <ds-login-form
                  (responseCodeEvent)="responseReceived($event)"
                  [isEmailValidation]="false"
                  [cookieError]="!cookiesEnabled && (cookieCheckFeatureEnabled$ | async)"
                >
                </ds-login-form>
                <hr aria-hidden="true" />
                @if ((isHideMyAccountSignup$ | async) === false) {
                  @if (responseCode !== 423) {
                    <div class="column twelve caption">
                      <ng-container *dsPartnerGenericHide>
                        <span
                          class="pad-right-6"
                          content
                          [contentPath]="'shared.createAccountText'"
                        ></span>
                        <a
                          [dsRouterLink]="['/enroll']"
                          content
                          [contentPath]="'shared.signUpText'"
                          data-cy="loginBaseSignUpLink"
                        ></a>
                      </ng-container>
                    </div>
                  }
                  <ng-container *dsPartnerGenericHide>
                    @if (responseCode === 423) {
                      <div class="row pad-top-20">
                        <div class="column twelve caption">
                          <span content [contentPath]="'shared.needAccountText'"></span>
                          <a
                            [dsRouterLink]="['/enroll']"
                            content
                            [contentPath]="'shared.signUpText'"
                            data-cy="loginBaseSignUpLink"
                          ></a>
                        </div>
                      </div>
                    }
                  </ng-container>
                }
                <div class="column twelve caption pad-vertical-4">
                  <span content [contentPath]="'auth.loginTrouble'"></span>
                  <a
                    dsPartnerContent
                    [brandContent]="'selectPrettyCusCareNumber'"
                    [brandHref]="'selectCusCareNumberFullHref'"
                    data-cy="loginBaseCusCareNumberLink"
                  ></a>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }
}
