<section class="item">
  <ds-edit-business-name
    [firstName]="firstName$ | async"
    [lastName]="lastName$ | async"
    [businessName]="businessName$ | async"
    [headerCopy]="'businessNameHeaderText'"
  >
  </ds-edit-business-name>

  <ds-active-business-email-manager
    [emailAddress]="businessEmailAddress$ | async"
    [headerCopy]="'primaryMyAccountEmail'"
    [mainCaptionCopy]="'businessEmailDefaultText'"
  >
  </ds-active-business-email-manager>
</section>
