import { Action } from '@ngrx/store';
import { get as _get, has as _has } from 'lodash';

import { ApiStatus } from '../api/api-status.model';
import { AutomaticPayment } from './automatic-payment.model';
import { PaymentConfirmationModel } from './payment-confirmation.model';
import { ScheduledPayment } from './scheduled-payment.model';

export interface PaymentAccount {
  nickName: string;
  lastUpdateTimestamp: string;
  achWithdrawal?: ACHWithdrawal;
  creditCard?: CreditCard;
  schedulePayment?: any;
  autopayRules?: any;
  paymentAccountId?: string;
  modeOfAuthorization?: string;
  status?: ApiStatus;
  hasError?: boolean;
  expired?: boolean;
  expiring?: boolean;
}

// This is the shape of an achWithdrawal object in the store
export interface ACHWithdrawal {
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  accountUse: string;
  financialInstitution: string;
}

export enum ExpirationStatus {
  EXPIRED = 'EXPIRED',
  EXPIRING_SOON = 'EXPIRING_SOON',
  NOT_EXPIRED = 'NOT_EXPIRED'
}

// This is the shape of the creditCard object in the store.
export interface CreditCard {
  cardNumber: string;
  cardType: string;
}

export const initialPaymentAccount: PaymentAccount = {
  nickName: null,
  lastUpdateTimestamp: null,
  expired: false,
  expiring: false
};

// This is the shape of the credit card object for add /edit payment account api calls
export interface CreditCardRequestModel {
  cardNumber: string;
  cardType: string;
  expirationDate?: string;
}

// This is the shape of the achWithdrawal object for add / edit payment account api calls
export interface BankAccountRequestModel {
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  accountUse: string;
  financialInstitution?: string;
}

// This takes input from the components and fires the inital save action
export interface AddEditPaymentAccountDTO {
  finAcctPayload: FinAcctServiceRequestModel;
  customerPaymentPayload?: AddEditPaymentAccountRequestModel;
  currentPaymentAccountNickName?: string;
  paymentAccountId?: string;
  financialInstitution?: string;
  lastUpdateTimestamp?: string;
  modeOfAuthorization?: string;
  action: string;
  correlationId: string;
}

// This supports the actual service call request
export interface FinAcctServiceRequestModel {
  financialType: string;
  nickName: string;
  bankAccount?: BankAccountRequestModel;
  creditCard?: CreditCardRequestModel;
  expiring?: boolean;
  consumerKey?: string; // optional because this gets added by the service class right before sending
}

// The supports the actual service call response
export interface FinAcctServiceResponseModel {
  finAcctServiceResponse: {
    apiStatus: {
      code: string;
      metrics: {
        acceptedOn: string;
        returnedOn: string;
      };
      reason: string;
      transactionId: string;
    };
    consumerKey: string;
    tokenId: string;
  };
}

// This supports the actual network call
export interface AddEditPaymentAccountRequestModel {
  paymentAccount: {
    nickName: string;
    token: string;
    consumerKey: string;
    modeOfAuthorization?: any;
  };
  lastUpdateTimestamp?: string;
}

// This supports the actual network request
export interface AddEditPaymentAccountResponseModel {
  paymentAccountId: string;
  status: {
    code: string;
    metrics?: {
      acceptedOn: string;
      returnedOn: string;
    };
    reason: string;
    transactionId: string;
  };
}

export interface SchedulePaymentFormModel {
  paymentMethodCtrl: string;
  paymentDateControl: {
    date: {
      year: number;
      month: number;
      day: number;
    };
    epoc: number;
    formatted: string;
    jsdate: string;
  };
}

export interface CleanupPaymentAccountModel {
  paymentAccountId: string;
}
export interface AddEditPaymentAccountSuccessModel {
  request: AddEditPaymentAccountDTO;
  response: AddEditPaymentAccountResponseModel;
  confirmation: PaymentConfirmationModel;
}

export interface AddEditPaymentAccountFailureModel {
  request: AddEditPaymentAccountDTO;
  status: ApiStatus;
}

export interface UpdateModeOfAuthModel {
  request: UpdateModeOfAuthRequestModel;
  paymentAccountId: string;
  paymentAction: Action[];
  correlationId: any;
}

export interface UpdateModeOfAuthRequestModel {
  lastUpdateTimestamp: string;
  paymentAccount: {
    nickName: string;
    modeOfAuthorization: string;
  };
}

export interface UpdateModeOfAuthResponseModel {
  paymentAccountId: string;
  status: {
    code: string;
    metrics?: {
      acceptedOn: string;
      returnedOn: string;
    };
    reason: string;
    transactionId: string;
  };
}
export interface UpdateModeOfAuthSuccessModel {
  request: UpdateModeOfAuthRequestModel;
  response: UpdateModeOfAuthResponseModel;
}

export interface UpdateModeOfAuthFailureModel {
  request: UpdateModeOfAuthRequestModel;
  status?: ApiStatus;
  correlationId: string;
}

export interface DeletePaymentAccountRequestModel {
  paymentAccountId: string;
  nickName: string;
  creditCard?: CreditCardRequestModel;
  bankAccount?: BankAccountRequestModel;
  correlationId?: string;
}
export interface DeletePaymentAccountResponseModel {
  status: {
    code: string;
    metrics: {
      acceptedOn: string;
      returnedOn: string;
    };
    reason: string;
    transactionId: string;
  };
}

export interface DeletePaymentAccountFailureModel {
  request: DeletePaymentAccountRequestModel;
  status: ApiStatus;
}
export interface DeletePaymentAccountSuccessModel {
  request: DeletePaymentAccountRequestModel;
  response: DeletePaymentAccountResponseModel;
  confirmation: PaymentConfirmationModel;
}

export const UNAVAILABLE_PAYMENTACCOUNT_ID = 'unavailable';

export interface AchPaymentMethod {
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  accountUse: string;
  financialInstitution: string;
}

export interface CreditCardPaymentMethod {
  cardNumber: string;
  cardType: string;
}

export interface PaymentMethod {
  achWithdrawal?: AchPaymentMethod;
  creditCard?: CreditCardPaymentMethod;
  lastUpdateTimestamp: string;
  nickName: string;
  paymentAccountId: string;
  dirty: boolean;
  expired: boolean;
  expiring: boolean;
}
export interface SelectedPaymentAccount {
  accType: string;
  paymentMethod: PaymentMethod;
  paymentMethodDetails: AchPaymentMethod | CreditCardPaymentMethod;
  autopayRules?: Array<AutomaticPayment>;
  schedulePayment?: Array<ScheduledPayment>;
}
