import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { isEmpty as _isEmpty } from 'lodash';

@Component({
  selector: 'ds-table-body-cell',
  templateUrl: './table-body-cell.component.html',
  styleUrls: ['../../../containers/ds-table/ds-table.component.scss']
})
export class DsTableBodyCellComponent implements AfterViewInit, OnInit {
  @Input() row: any;
  @Input() column: any;
  public rowName = '';

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.style.width = this.column.width;
  }

  ngOnInit() {
    if (!_isEmpty(this.column) && !_isEmpty(this.row)) {
      this.rowName = this.row[this.column.prop];
    }
  }
}
