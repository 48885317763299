import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { PartyEmail, UsageCode } from '@amfam/shared/models';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { ConfigService } from '@amfam/shared/utility/shared-services';
import { ValidationService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent implements OnChanges {
  @Input() makePrimaryEmailAddDeleteBtnToggleDisplay: boolean;
  @Input() emailAddresses: PartyEmail[];
  @Input() primaryEmail: PartyEmail;
  @Input() primaryEmailContactMethodUsage: UsageCode;
  @Input() pendingEmailId: string;
  @Input() otherEditActive: boolean;
  @Output() save: EventEmitter<{
    addr: string;
    type: string;
    typeDesc: string;
    makePrimary: boolean;
  }> = new EventEmitter();
  @Output() deleteEmailEvent = new EventEmitter<PartyEmail>();
  @Output() edit = new EventEmitter<boolean>();
  @Output() makePrimaryEvent = new EventEmitter<PartyEmail>();
  @Output() sendAnotherVerificationEmailEvent = new EventEmitter<PartyEmail>();
  @Output() resendEmailEmitEvent = new EventEmitter<void>();
  @Output() changeEmailEmitEvent = new EventEmitter<string>();
  @Input() isShellAccount: boolean;
  @Input() shellEmail: string;
  @Input() isDuplicateEmail: boolean;

  addEmailAddrForm: UntypedFormGroup;
  emailTypeControl: AbstractControl;
  emailAddrControl: AbstractControl;
  typeOtherDescControl: AbstractControl;
  codeTypes: string[];
  isAddingEmail = false;
  OTHER = 'OTHER';
  makePrimaryOnSave = false;

  constructor(
    private config: ConfigService,
    private formBuilder: UntypedFormBuilder,
    public roleService: ImpersonateRolesService
  ) {
    this.codeTypes = this.config.get('profileEmailTypeList');
  }

  ngOnChanges(changes: SimpleChanges): void {
    // setup our form and validation when we get new emailAddresses
    if (changes.emailAddresses) {
      this.buildForm();
    }
  }

  addNewEmail(): void {
    this.toggleAdd();
    this.resetForm();
  }

  cancel(): void {
    this.toggleAdd();
    this.resetForm();
  }

  onDelete(email: PartyEmail): void {
    this.deleteEmailEvent.emit(email);
  }

  onEdit(val: boolean): void {
    this.otherEditActive = val;
    this.edit.emit(this.otherEditActive);
  }

  onMakePrimary(email: PartyEmail) {
    this.makePrimaryEvent.emit(email);
  }

  saveNewEmail(): void {
    const addr: string = this.emailAddrControl.value;
    const type: string = this.emailTypeControl.value;
    const typeDesc: string = type === this.OTHER ? this.typeOtherDescControl.value : '';
    const makePrimary: boolean = this.addEmailAddrForm.controls['primaryChecked'].value;
    this.save.emit({ addr, type, typeDesc, makePrimary });
    this.toggleAdd();
  }

  sendAnotherVerificationEmail(email: PartyEmail) {
    this.sendAnotherVerificationEmailEvent.emit(email);
  }
  resendEmail() {
    this.resendEmailEmitEvent.emit();
  }
  changeEmail(email) {
    this.changeEmailEmitEvent.emit(email);
  }

  private buildForm(): void {
    this.addEmailAddrForm = this.formBuilder.group(
      {
        emailType: ['HOME', Validators.required],
        emailAddr: [
          '',
          Validators.compose([
            Validators.required,
            ValidationService.emailValidator,
            ValidationService.allowedEmailCharactersValidator
          ])
        ],
        primaryChecked: [this.makePrimaryOnSave],
        typeOtherDesc: ['']
      },
      {
        validator: Validators.compose([
          ValidationService.descIsRequired('emailType', 'typeOtherDesc'),
          ValidationService.isDupEmail('emailAddr', 'emailType', this.emailAddresses)
        ])
      }
    );
    this.emailTypeControl = this.addEmailAddrForm.controls['emailType'];
    this.emailAddrControl = this.addEmailAddrForm.controls['emailAddr'];
    this.typeOtherDescControl = this.addEmailAddrForm.controls['typeOtherDesc'];
  }

  private resetForm() {
    this.addEmailAddrForm.reset({
      emailAddr: '',
      emailType: 'HOME',
      typeOtherDesc: '',
      primaryChecked: false
    });
  }

  private toggleAdd(): void {
    this.onEdit(!this.otherEditActive);
    this.isAddingEmail = !this.isAddingEmail;
  }
}
