import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ImpersonationState } from './impersonation.model';
import { fromImpersonation, IMPERSONATION_FEATURE_KEY } from './impersonation.reducer';

export const getImpersonationState =
  createFeatureSelector<ImpersonationState>(IMPERSONATION_FEATURE_KEY);

export const isImpersonating = createSelector(
  getImpersonationState,
  fromImpersonation.isImpersonating
);
export const permissions = createSelector(getImpersonationState, fromImpersonation.permissions);

export const impersonationQuery = {
  isImpersonating,
  permissions
};
