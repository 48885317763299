import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import {
  PROGRESSINDICATOR_FEATURE_KEY,
  initialState as progressIndicatorInitialState,
  progressIndicatorReducer
} from './+state/progress-indicator.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PROGRESSINDICATOR_FEATURE_KEY, progressIndicatorReducer, {
      initialState: progressIndicatorInitialState
    })
  ]
})
export class ProgressIndicatorStoreModule {}
