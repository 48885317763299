import { ActionReducerMap } from '@ngrx/store';

import * as fromAddVehicleBindState from './bind/add-vehicle-bind.reducer';
import * as fromAddVehicleNewFeaturesState from './feature/add-vehicle-feature.reducer';
import * as fromAddVehicleInfoState from './info/add-vehicle-info.reducer';
import * as fromAddVehicleQuoteState from './quote/add-vehicle-quote.reducer';

export const ADD_VEHICLE_DATA_KEY = 'add vehicle data';

export interface AddVehicleDataState {
  addVehicleBindState: fromAddVehicleBindState.AddVehicleBindState;
  addVehicleNewFeaturesState: fromAddVehicleNewFeaturesState.State;
  addVehicleInfoState: fromAddVehicleInfoState.State;
  addVehicleQuoteState: fromAddVehicleQuoteState.State;
}

export const addVehicleReducers: ActionReducerMap<AddVehicleDataState> = {
  addVehicleBindState: fromAddVehicleBindState.reducer,
  addVehicleNewFeaturesState: fromAddVehicleNewFeaturesState.reducer,
  addVehicleInfoState: fromAddVehicleInfoState.reducer,
  addVehicleQuoteState: fromAddVehicleQuoteState.reducer
};
