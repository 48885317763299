/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { SummariesLoadSuccess } from 'libs/policy/data-access/src/lib/+state/summaries/summaries.actions';
import { get as _get } from 'lodash';
import { merge, Observable, throwError as observableThrowError, of, zip } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import {
  featureFlagQuery,
  FeatureFlagService
} from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions, routerQuery } from '@amfam/shared/utility/navigation';

import { CustomerFeedbackEnum, OpportunitiesFeedback } from '../models/feedback';
import { OpportunityService } from '../services/opportunity.service';
import {
  OpportunitiesANSAction,
  OpportunitiesANSFailAction,
  OpportunitiesANSSuccessAction,
  OpportunitiesContentLoadAction,
  OpportunitiesContentLoadFailAction,
  OpportunitiesContentLoadSuccessAction,
  OpportunitiesDetailPageNavigationAction,
  OpportunitiesDetailPageNavigationFailAction,
  OpportunitiesDetailPageNavigationFromSelectionPageAction,
  OpportunitiesDisabledAction,
  OpportunitiesFeedbackAction,
  OpportunitiesFeedbackFailAction,
  OpportunitiesFeedbackOnlyFailAction,
  OpportunitiesFeedbackOnlySuccessAction,
  OpportunitiesFeedbackSuccessAction,
  OpportunitiesLoadAction,
  OpportunitiesLoadFailAction,
  OpportunitiesLoadSuccessAction,
  OpportunitiesNotificationAction,
  OpportunitiesNotificationFailAction,
  OpportunitiesNotifyAgentAction,
  OpportunitiesNotifyAgentFailAction,
  OpportunitiesNotifyAgentSuccessAction,
  OpportunitiesNotifyCustomerAction,
  OpportunitiesNotifyCustomerFailAction,
  OpportunitiesNotifyCustomerSuccessAction,
  OpportunitiesOptimizelyNotificationAction,
  OpportunityActionsUnion,
  OpportunityActionTypes
} from './opportunity.action';

@Injectable()
export class OpportunityEffects {
  /**
   * @author: Abhishek Singh
   * @returns:
   * @description: This effect triggers a analytics notification.
   */

  optimizelyAnalyticsNotification$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<OpportunitiesOptimizelyNotificationAction>(
          OpportunityActionTypes.OPPORTUNITIES_OPTIMIZELY_NOTIFICATION
        ),
        map(action => action.payload),
        map(payload => this.opportunityService.sendOptimizelyAnalytics(payload))
      ),
    { dispatch: false }
  );

  /**
   * @description: Once the policies have loaded start the process to load the policy opportunities
   * we wait for the policy actions to complete because we need policies to call the recommender service
   * which gives the policy opportunities. If there is a problem in getting the configuration/feature flag
   * do not show the opportunities by calling the disabled action on the Opportunities
   */

  loading$ = createEffect(() =>
    this.action$.pipe(
      ofType(SummariesLoadSuccess),
      map(action => action.payload),
      withLatestFrom(this.rootStore.pipe(select(featureFlagQuery.selectFeatureFlag('opportunities')))),
      switchMap(([_, opportunitiesFeatureEnabled]) => {
        const returnArray: Array<OpportunityActionsUnion> = new Array();
        if (opportunitiesFeatureEnabled.enabled) {
          returnArray.push(new OpportunitiesLoadAction());
        } else {
          returnArray.push(new OpportunitiesDisabledAction());
        }
        return returnArray;
      }),
      catchError(() => of(new OpportunitiesDisabledAction()))
    )
  );

  /**
   * @description This effect listens for the opportunity load action and calls the opportunity service
   * which in turn calls the party service to load the opportunities. This is typically called once the policies
   * have been loaded from the party service.
   */

  loadOpportunities$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesLoadAction>(OpportunityActionTypes.OPPORTUNITIES_LOAD),
      switchMap(_ =>
        this.opportunityService.loadOpportunity().pipe(
          map(response => new OpportunitiesLoadSuccessAction(response)),
          catchError(error => of(new OpportunitiesLoadFailAction(error)))
        )
      ),
      catchError(error => of(new OpportunitiesLoadFailAction(error)))
    )
  );

  /**
   * @description Once the policy opportunies have loaded we call the content service to load the content
   * for the opportunities. As of now this is from the application but would move out to sitecore soon.
   */

  loadOpportunitiesSuccess$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesLoadSuccessAction>(OpportunityActionTypes.OPPORTUNITIES_LOAD_SUCCESS),
      switchMap(() => of(new OpportunitiesContentLoadAction())),
      catchError(error => of(new OpportunitiesContentLoadFailAction(error)))
    )
  );

  /**
   * @description This effect would listen to the opportunities load success and then call the sitecore
   * to fetch the content information associated with each of the opportunity and populate in the store.
   */

  loadOpportunitiesContent$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesContentLoadAction>(OpportunityActionTypes.OPPORTUNITIES_CONTENT_LOAD),
      switchMap(() =>
        this.opportunityService.loadOpportunitiesContent().pipe(
          map(response => new OpportunitiesContentLoadSuccessAction(response)),
          catchError(error => of(new OpportunitiesContentLoadFailAction(error)))
        )
      ),
      catchError(error => of(new OpportunitiesContentLoadFailAction(error)))
    )
  );

  /**
   * @description Build and send the notification to the agent. If there is a problem with building the
   * payload or the notificaation failed we throw the failednotification action.
   */

  notifyAgent$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesNotifyAgentAction>(OpportunityActionTypes.OPPORTUNITIES_NOTIFY_AGENT),
      map(action => action.payload),
      switchMap(payload =>
        this.opportunityService
          .buildInternalEmailData(payload.agentId, payload.recommendationId)
          .pipe(
            switchMap(dataResponse =>
              this.opportunityService.generateInternalEmails(dataResponse).pipe(
                switchMap(() => of(new OpportunitiesNotifyAgentSuccessAction(payload))),
                catchError(error =>
                  of(
                    new OpportunitiesNotifyAgentFailAction({
                      ...error,
                      recommendationId: payload.recommendationId,
                      isMultiAgent: payload.isMultiAgent,
                      type: payload.type
                    })
                  )
                )
              )
            ),
            catchError(error =>
              of(
                new OpportunitiesNotifyAgentFailAction({
                  ...error,
                  recommendationId: payload.recommendationId,
                  isMultiAgent: payload.isMultiAgent,
                  type: payload.type
                })
              )
            )
          )
      ),
      catchError(error => of(new OpportunitiesNotifyAgentFailAction(error)))
    )
  );

  /**
   * @description Build and send the notification to the ANS service. If there is a problem with building the
   * payload or the notificaation failed we throw the failednotification action.
   */

  ansNotification$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesANSAction>(OpportunityActionTypes.OPPORTUNITIES_ANS_NOTIFICATION),
      map(action => action.payload),
      switchMap(payload =>
        this.opportunityService
          .buildNotificationData(payload.agentId, payload.recommendationId)
          .pipe(
            switchMap(dataResponse =>
              this.opportunityService.ansNotification(dataResponse).pipe(
                switchMap(() => of(new OpportunitiesANSSuccessAction(payload))),
                catchError(error =>
                  of(
                    new OpportunitiesANSFailAction({
                      ...error,
                      recommendationId: payload.recommendationId,
                      type: payload.type
                    })
                  )
                )
              )
            ),
            catchError(error =>
              of(
                new OpportunitiesANSFailAction({
                  ...error,
                  recommendationId: payload.recommendationId,
                  type: payload.type
                })
              )
            )
          )
      ),
      catchError(error => of(new OpportunitiesANSFailAction(error)))
    )
  );

  /**
   * @description This effects gets called when atleast one of the notification modus was successfull, i.e
   * if we were able to send the ANS notification or the Agent Email was successfully sent.
   */

  notifyCustomer$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesNotifyCustomerAction>(
        OpportunityActionTypes.OPPORTUNITIES_NOTIFY_CUSTOMER
      ),
      map(action => action.payload),
      switchMap(payload =>
        this.opportunityService.buildCustomerEmailData(payload).pipe(
          switchMap(dataResponse =>
            this.opportunityService.generateCustomerEmail(dataResponse).pipe(
              switchMap(apiResponse => {
                const resp = apiResponse as any;
                return of(
                  new OpportunitiesNotifyCustomerSuccessAction({
                    ...resp,
                    status: {
                      ...resp.status,
                      customerFeedbackCode: CustomerFeedbackEnum.CustomerRequestedQuote
                    },
                    recommendationId: payload.recommendationId,
                    isMultiAgent: payload.isMultiAgent,
                    type: payload.type
                  })
                );
              }),
              catchError(error =>
                of(new OpportunitiesNotifyCustomerFailAction({ ...error, type: payload.type }))
              )
            )
          ),
          catchError(error =>
            of(
              new OpportunitiesNotifyCustomerFailAction(
                Object.assign({}, error, {
                  recommendationId: payload.recommendationId,
                  isMultiAgent: payload.isMultiAgent,
                  type: payload.type
                })
              )
            )
          )
        )
      ),
      catchError(error => of(new OpportunitiesNotifyCustomerFailAction({ ...error })))
    )
  );

  /**
   * @author: Abhishek Singh
   * @returns: OpportunitiesFeedbackSuccessAction/OpportunitiesFeedbackFailAction
   * @description: This effect gets triggered by opportunities feedback action. This effect sends posts the feedback
   * to the recommender api. For R1 peak moments this is a irrrelevant call and the outcome doesnot effect the
   * app in anyway. We need the feedback action code to be passed back to the reducer as based on the button clicked we
   * determine weather to display a message to the customer or not.
   */

  sendFeedback$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesFeedbackAction>(OpportunityActionTypes.OPPORTUNITIES_FEEDBACK),
      switchMap(action =>
        this.opportunityService.buildFeedbackData(action.payload).pipe(
          switchMap(dataResponse =>
            this.opportunityService.sendFeedback(dataResponse).pipe(
              switchMap(apiResponse =>
                action.feedbackOnly
                  ? of(
                      new OpportunitiesFeedbackOnlySuccessAction(
                        Object.assign({}, apiResponse, {
                          status: {
                            customerFeedbackCode: dataResponse.feedback.feedbackActionCode
                          },
                          recommendationId: action.payload.recommendationId,
                          isMultiAgent: action.payload.isMultiAgent,
                          type: action.payload.type
                        })
                      )
                    )
                  : of(
                      new OpportunitiesFeedbackSuccessAction(
                        Object.assign({}, apiResponse, {
                          status: {
                            customerFeedbackCode: dataResponse.feedback.feedbackActionCode
                          },
                          recommendationId: action.payload.recommendationId,
                          isMultiAgent: action.payload.isMultiAgent,
                          type: action.payload.type
                        })
                      )
                    )
              ),

              catchError(error =>
                action.feedbackOnly
                  ? of(
                      new OpportunitiesFeedbackOnlyFailAction(
                        Object.assign({}, error, {
                          customerFeedbackCode: dataResponse.feedback.feedbackActionCode,
                          recommendationId: action.payload.recommendationId,
                          isMultiAgent: action.payload.isMultiAgent,
                          type: action.payload.type
                        })
                      )
                    )
                  : of(
                      new OpportunitiesFeedbackFailAction(
                        Object.assign({}, error, {
                          customerFeedbackCode: dataResponse.feedback.feedbackActionCode,
                          recommendationId: action.payload.recommendationId,
                          isMultiAgent: action.payload.isMultiAgent,
                          type: action.payload.type
                        })
                      )
                    )
              )
            )
          ),
          catchError(error =>
            of(
              new OpportunitiesFeedbackFailAction(
                Object.assign({}, error, {
                  customerFeedbackCode: action.payload.feedbackActionCode,
                  recommendationId: action.payload.recommendationId,
                  isMultiAgent: action.payload.isMultiAgent,
                  type: action.payload.type
                })
              )
            )
          )
        )
      )
    )
  );

  afterNotification$ = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesFeedbackSuccessAction | OpportunitiesFeedbackFailAction>(
        OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_SUCCESS,
        OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION_FAIL
      ),
      withLatestFrom(this.rootStore.select(routerQuery.getRouterCurrentState)),
      filter(([_, currentUrl]) => {
        const id = currentUrl.params['id'];
        return currentUrl.url === `/opportunities/learnmore/${id}/agent`;
      }),
      map(([action, currentUrl]) => {
        const id: string = currentUrl.params['id'];
        if (action.type === OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_SUCCESS)
          return fromRouterActions.Go({
            path: [`/opportunities/learnmore/${id}/success`]
          });
        else return fromRouterActions.Go({ path: [`/opportunities/learnmore/${id}/error`] });
      })
    )
  );

  /**
   * @author: Abhishek Singh
   * @returns: OpportunitiesAnalyticsSuccessAction
   * @description: This effect gets triggered by either the customer notification success or the opportunities notification fail.
   * In both the cases we call the sendAnalytics which then checks the store for error flag and sends either a success
   * or a failure  analytics notification.
   */

  analyticsNotification$: Observable<Action> = createEffect(
    () =>
      this.action$.pipe(
        ofType<OpportunitiesNotifyCustomerSuccessAction | OpportunitiesNotifyCustomerFailAction>(
          OpportunityActionTypes.OPPORTUNITIES_NOTIFY_CUSTOMER_SUCCESS,
          OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION_FAIL
        ),
        map(action => action.payload),
        switchMap(payload =>
          this.opportunityService.sendAnalytics(
            payload.recommendationId,
            payload.isMultiAgent,
            payload.type
          )
        )
      ),
    { dispatch: false }
  );

  /**
   * @author: Abhishek Singh
   * @returns: Array of OpportunityActionTypes
   * @description: This effect gets triggered by the opportunities notification action and then dispatches 3 different
   * actions agent notification, ANS notification, Feedback action. This effect also computes the payload which needs to be sent
   * to the feedback action as that is different than the other payloads.
   */

  // eslint-disable-next-line @typescript-eslint/member-ordering
  splitter$ = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesNotificationAction>(OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION),
      map(action => action.payload),
      mergeMap(payload => [
        new OpportunitiesNotifyAgentAction(payload),
        new OpportunitiesANSAction(payload)
      ]),
      catchError(error => of(new OpportunitiesNotificationFailAction(error)))
    )
  );

  /**
   * @author: Abhishek Singh
   * @returns: OpportunityActionTypes
   * @description: This effect gets triggered by the opportunities notification action and then listens for the
   * success action of either the agent notification or the ANS notification. Merge operator emits an event if any
   * of the input stream emits an event. In this case we grab the first event emitted by the merge stream and then
   * dispatch an action to send the customer notification.
   */

  successAggregator$ = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesNotificationAction>(OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION),
      map(action => action.payload),
      mergeMap(a =>
        merge(
          this.action$.pipe(
            ofType<OpportunitiesNotifyAgentSuccessAction>(
              OpportunityActionTypes.OPPORTUNITIES_NOTIFY_AGENT_SUCCESS
            ),
            filter(
              t =>
                t.type === OpportunityActionTypes.OPPORTUNITIES_NOTIFY_AGENT_SUCCESS &&
                t.payload.correlationId === a.correlationId
            ),
            first()
          ),
          this.action$.pipe(
            ofType<OpportunitiesANSSuccessAction>(
              OpportunityActionTypes.OPPORTUNITIES_ANS_NOTIFICATION_SUCCESS
            ),
            filter(
              t =>
                t.type === OpportunityActionTypes.OPPORTUNITIES_ANS_NOTIFICATION_SUCCESS &&
                t.payload.correlationId === a.correlationId
            ),
            first()
          )
        ).pipe(
          first(),
          catchError(error => observableThrowError(error))
        )
      ),
      catchError(error =>
        of({
          type: OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION_FAIL,
          payload: error
        })
      ),
      switchMap(pair => {
        const feedbackPayload: OpportunitiesFeedback = {
          feedbackActionCode: CustomerFeedbackEnum.CustomerRequestedQuote,
          agentId: _get(
            pair,
            'payload.agentId',
            _get(pair[0], 'payload.agentId', _get(pair[1], 'payload.agentId'))
          ),
          recommendationId: pair.payload.recommendationId,
          isMultiAgent: _get(pair, 'payload.isMultiAgent', false),
          type: pair.payload.type
        };
        return [
          {
            type: OpportunityActionTypes.OPPORTUNITIES_NOTIFY_CUSTOMER,
            payload: feedbackPayload
          },
          new OpportunitiesFeedbackAction(feedbackPayload)
        ];
      })
    )
  );

  /**
   * @author: Abhishek Singh
   * @returns: OpportunityActionTypes
   * @description: This effect gets triggered by the opportunities notification action and then listens for the
   * fail action on agent notification and ANS notification. The zip operator emits an event once both the fail action
   * emitted a value, effect then dispatches an action of the type oppotunities notification fail
   */

  errorAggregator$ = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesNotificationAction>(OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION),
      map(action => action.payload),
      switchMap(a =>
        zip(
          this.action$.pipe(
            ofType<OpportunitiesNotifyAgentFailAction>(
              OpportunityActionTypes.OPPORTUNITIES_NOTIFY_AGENT_FAIL
            ),
            filter(t => t.type === OpportunityActionTypes.OPPORTUNITIES_NOTIFY_AGENT_FAIL),
            first()
          ),
          this.action$.pipe(
            ofType<OpportunitiesANSFailAction>(
              OpportunityActionTypes.OPPORTUNITIES_ANS_NOTIFICATION_FAIL
            ),
            filter(t => t.type === OpportunityActionTypes.OPPORTUNITIES_ANS_NOTIFICATION_FAIL),
            first()
          )
        ).pipe(catchError(error => observableThrowError(error)))
      ),
      switchMap(pair =>
        of({
          type: OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION_FAIL,
          payload: _get(pair[0], 'payload') || _get(pair[1], 'payload')
        })
      ),
      catchError(error => of(new OpportunitiesNotificationFailAction(error)))
    )
  );

  /**
   * @author: Abhishek Singh
   * @returns: Nothing
   * @description: This effect gets triggered when user click on a learn more button and is intending
   * to learn more about a particular opportunity. This effect would take the recommendation id build
   * the url and navigate the user to the deail page.
   */
  navigateFromSelectionPage$ = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesDetailPageNavigationFromSelectionPageAction>(
        OpportunityActionTypes.OPPORTUNITIES_DETAIL_NAVIGATION_FROM_SELECTION_PAGE
      ),
      map(action => action.payload),
      switchMap(({ recommendationId, type }) =>
        this.opportunityService.getOpportunityTypeByRecommendationId(recommendationId).pipe(
          first(),
          map(opportunityType => {
            const path = `/opportunities/selection/${opportunityType}/${type}`;
            return fromRouterActions.Go({ path: [path] });
          }),
          catchError(() => of(new OpportunitiesDetailPageNavigationFailAction()))
        )
      ),
      catchError(() => of(new OpportunitiesDetailPageNavigationFailAction()))
    )
  );

  detailPageNavigation$ = createEffect(() =>
    this.action$.pipe(
      ofType<OpportunitiesDetailPageNavigationAction>(
        OpportunityActionTypes.OPPORTUNITIES_DETAIL_NAVIGATION
      ),
      map(action => action.payload),
      switchMap(recommendationId =>
        this.opportunityService.getOpportunityByRecommendationId(recommendationId).pipe(
          first(),
          map(opportunity => {
            const path =
              !!opportunity.content.newTemplate && opportunity.content.types
                ? `/opportunities/selection/${opportunity.content.opportunityProductType}`
                : `/opportunities/learnmore/${opportunity.content.opportunityProductType}`;
            return fromRouterActions.Go({ path: [path] });
          }),
          catchError(error => of(new OpportunitiesDetailPageNavigationFailAction()))
        )
      ),
      catchError(error => {
        return of(new OpportunitiesDetailPageNavigationFailAction());
      })
    )
  );

  constructor(
    private rootStore: Store,
    private opportunityService: OpportunityService,
    private action$: Actions,
    private featureFlagService: FeatureFlagService
  ) {}
}
