export class ANSRequest {
  notificationsRequest: {
    notificationText: string;
    notificationType: string;
    customerName: string;
    referenceNumber: string;
    formattedReferenceNumber: string;
    policyNumber: string;
    from: string;
    dueDate: string;
    createdBy: string;
    uniqueId: string;
    sourceSystem: string;
  };
  producerId: string;
}
