import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DsModalService } from '../../lib/ds-modal/components/ds-modal/ds-modal.service';
import { AlertModalConfig } from './alert-modal.model';

@Component({
  selector: 'ds-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class DsAlertModalComponent implements OnInit {
  @Input() config: AlertModalConfig;
  @Output() primaryEvent = new EventEmitter<void>();
  @Output() secondaryEvent = new EventEmitter<void>();
  @Output() tertiaryEvent = new EventEmitter<void>();

  constructor(private modalService: DsModalService) {}

  ngOnInit(): void {}

  closeEvent() {
    this.modalService.close(this.config.modalId);
  }
}
