import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClaimDashboardEffects } from './+state/claim-dashboard.effects';
import {
  CLAIMDASHBOARD_FEATURE_KEY,
  claimDashboardReducer
} from './+state/claim-dashboard.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CLAIMDASHBOARD_FEATURE_KEY, claimDashboardReducer),
    EffectsModule.forFeature([ClaimDashboardEffects])
  ]
})
export class ClaimDashboardDataAccessModule {}
