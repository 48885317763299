import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// Store
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type { SecurityQuestion } from '@amfam/shared/digital-account/data-access';
import { userQuery } from '@amfam/shared/user';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService, SecurityQuestionsService } from '@amfam/shared/utility/shared-services';
import {
  LoadingSpinnerService,
  SecurityQuestionsValidationService,
  ValidationService
} from '@amfam/ui-kit';

@Component({
  selector: 'ds-manage-security-questions',
  templateUrl: './manage-security-questions.component.html',
  styleUrls: ['./manage-security-questions.component.scss']
})
export class ManageSecurityQuestionsComponent implements OnInit, OnDestroy {
  @Input() buttonTextInput: string;
  @Input() backButtonRoute: string;
  @Input() location: string;
  // optional value passed to component if editing pre-existing questions
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('securityQuestions') userSecurityQuestions: SecurityQuestion[];
  // emit when submitting updates to security questions
  @Output() finish = new EventEmitter<{ securityQuestions: SecurityQuestion[]; status: boolean }>();
  // emit when error retrieving question list from SecurityQuestionsService
  @Output() getAllQuestionsServiceError = new EventEmitter<unknown>();

  componentInputLabels = this.copyService.get('shared', 'inputFieldLabelText');
  questionList1: string[];
  questionList2: string[];
  inputFirstQuestion: AbstractControl;
  inputSecondQuestion: AbstractControl;
  inputFirstAnswer: AbstractControl;
  inputSecondAnswer: AbstractControl;
  securityForm: UntypedFormGroup;
  inputFocusedAnswer1: boolean;
  inputFocusedAnswer2: boolean;
  buttonText: string;
  private questionList: string[];
  private username: string;
  private maskValue = '*******';
  private stop$ = new Subject<void>();

  constructor(
    private securityQuestionsService: SecurityQuestionsService,
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private spinner: LoadingSpinnerService,
    private copyService: CopyService,
    public roleService: ImpersonateRolesService
  ) {}

  // If the passed question is in the question list array
  // return a new array excluding that question
  filterQuestionList(question: string): string[] {
    return this.questionList.filter(q => q !== question);
  }

  loadInitialData() {
    this.spinner.start({ blockActions: true });

    // Fetch all the possible questions which would be shown to the user. These are fetched from
    // the CDH system, so in case of issues on questions itself check with the CDH team.
    this.securityQuestionsService
      .getAllQuestions()
      .pipe(takeUntil(this.stop$))
      .subscribe(
        res => {
          // use these for filtering dropdown list
          const q1: string = this.userSecurityQuestions
            ? this.userSecurityQuestions[0].question
            : '';
          const q2: string = this.userSecurityQuestions
            ? this.userSecurityQuestions[1].question
            : '';

          if (res.length) {
            this.questionList = res;
            // set dropdown lists excluding the set question of the other list
            this.questionList1 = this.filterQuestionList(q2);
            this.questionList2 = this.filterQuestionList(q1);
          }

          // questions are loaded, stop the spinner.
          this.spinner.stop();

          // create subscribers for newly created account info and current user scenarios
          this.loadAccountInformation();
        },
        err => {
          if (err) {
            this.spinner.stop();
            this.getAllQuestionsServiceError.emit(err);
          }
        }
      );

    this.securityQuestionsService.userQuestionsError$.pipe(takeUntil(this.stop$)).subscribe(res => {
      if (res) {
        this.spinner.stop();
        switch (res) {
          case 400004: // Answer to question 1 was matched within the question
            this.inputFirstAnswer.setErrors({ answerInQuestion: true });
            break;
          case 400005: // Answer to question 1 was rejected for miscellaneous reasons
            this.inputFirstAnswer.setErrors({ miscAnswerError: true });
            break;
          case 400006: // Answer to question 2 was matched within the question
            this.inputSecondAnswer.setErrors({ answerInQuestion: true });
            break;
          case 400007: // Answer to question 2 was rejected for miscellaneous reasons
            this.inputSecondAnswer.setErrors({ miscAnswerError: true });
            break;
          case 400102: // Password invalid
            this.securityForm.setErrors({ ldapError: true });
            break;
          default:
        }
      }
    });
  }

  loadAccountInformation() {
    // Get the username from the auth service, since this is an edit operation on the security questions
    // this can only be performed if the user is loggeg in.
    this.store
      .select(userQuery.getUserState)
      .pipe(takeUntil(this.stop$))
      .subscribe(user => {
        if (user !== null) {
          this.username = user.loginName;
          this.securityQuestionsService.getUserSecurityQuestions({ userIdentifier: this.username });
        }
      });
  }

  ngOnInit() {
    this.loadInitialData();
    let a1, a2;
    if (this.location === 'enrollment' && this.userSecurityQuestions) {
      a1 = this.userSecurityQuestions[0].answer;
      a2 = this.userSecurityQuestions[1].answer;
    } else {
      a1 = this.userSecurityQuestions ? this.maskValue : '';
      a2 = this.userSecurityQuestions ? this.maskValue : '';
    }
    const q1: string = this.userSecurityQuestions ? this.userSecurityQuestions[0].question : '';
    const q2: string = this.userSecurityQuestions ? this.userSecurityQuestions[1].question : '';

    // set custom button text if it exists
    this.buttonText = this.buttonTextInput ? this.buttonTextInput : 'Confirm Security Questions';
    // Building the form and its control elements, asssigning the in built validator on the control elements.
    this.securityForm = this.formBuilder.group(
      {
        inputFirstAnswer: [
          a1,
          Validators.compose([
            Validators.required,
            ValidationService.requiredWithTrim,
            ValidationService.invalidChar,
            Validators.minLength(3),
            Validators.maxLength(50)
          ])
        ],
        inputSecondAnswer: [
          a2,
          Validators.compose([
            Validators.required,
            ValidationService.requiredWithTrim,
            ValidationService.invalidChar,
            Validators.minLength(3),
            Validators.maxLength(50)
          ])
        ],
        inputFirstQuestion: [q1, Validators.compose([Validators.required])],
        inputSecondQuestion: [q2, Validators.compose([Validators.required])]
      },
      {
        validator: Validators.compose([
          SecurityQuestionsValidationService.securityAnswers_areEqual(
            'inputFirstAnswer',
            'inputSecondAnswer',
            this.maskValue
          ),
          SecurityQuestionsValidationService.securityAnswersUnchanged(
            'inputFirstAnswer',
            'inputSecondAnswer',
            this.maskValue
          ),
          SecurityQuestionsValidationService.questionsDoNotContainAnswerInOrder(
            'inputFirstQuestion',
            'inputSecondQuestion',
            'inputFirstAnswer',
            'inputSecondAnswer'
          )
        ])
      }
    );

    this.inputFirstAnswer = this.securityForm.controls['inputFirstAnswer'];
    this.inputSecondAnswer = this.securityForm.controls['inputSecondAnswer'];

    this.securityQuestionsService.disableDeactivate();
  }

  // clear the input if the user focuses it and it has the masked value
  onInputFocus(inputId: string): void {
    const input = this.securityForm.controls[inputId];
    if (input.value === this.maskValue) {
      input.setValue('');
    }
  }

  // Emit form values to parent component for processing
  submitAnswers() {
    // when editing questions we need to check if answer was unchanged and if so set answer to empty string
    const a1 =
      this.inputFirstAnswer.value === this.maskValue && this.userSecurityQuestions
        ? ''
        : this.inputFirstAnswer.value;
    const a2 =
      this.inputSecondAnswer.value === this.maskValue && this.userSecurityQuestions
        ? ''
        : this.inputSecondAnswer.value;
    let securityQuestions: SecurityQuestion[] = [
      {
        question: this.securityForm.controls['inputFirstQuestion'].value,
        answer: a1
      },
      {
        question: this.securityForm.controls['inputSecondQuestion'].value,
        answer: a2
      }
    ];

    // if this is an update
    if (this.userSecurityQuestions) {
      // add original question to payload
      securityQuestions[0].oldQuestion = this.userSecurityQuestions[0].question;
      securityQuestions[1].oldQuestion = this.userSecurityQuestions[1].question;
      // only send questions that have new answers
      securityQuestions = securityQuestions.filter(question => question.answer);
    }

    this.finish.emit({
      securityQuestions,
      status: true
    });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  goBack() {
    this.securityQuestionsService.enableDeactivate();
    this.store.dispatch(
      fromRouterActions.Go({
        path: [this.backButtonRoute]
      })
    );
  }

  // when selected question changes
  onSelectedIndexChange(question: number) {
    if (question === 1) {
      this.inputFocusedAnswer1 = !this.inputFocusedAnswer1;
      this.inputFirstAnswer.reset();
      this.inputFirstAnswer.setValue('');
      this.questionList2 = this.filterQuestionList(
        this.securityForm.controls['inputFirstQuestion'].value
      );
    } else if (question === 2) {
      this.inputFocusedAnswer2 = !this.inputFocusedAnswer2;
      this.inputSecondAnswer.reset();
      this.inputSecondAnswer.setValue('');
      this.questionList1 = this.filterQuestionList(
        this.securityForm.controls['inputSecondQuestion'].value
      );
    } else {
      return;
    }
  }
}
