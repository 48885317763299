import { Address } from '@amfam/policy/models';

import { Email } from './email';
import { Phone } from './phone';

export class Contact {
  name: string;
  firstName: string;
  lastName: string;
  middleName: string;
  emails: Email[];
  phones: Phone[];
  addresses: Address[];
}
