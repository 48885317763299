import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { every as _every, get as _get } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { isUserComingFromEnrollment } from '@amfam/billing/auto-pay/feature';
import { AutoPayUtilSelectors } from '@amfam/billing/auto-pay/util';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { PolicySelectors, PolicySummarySelectors } from '@amfam/policy/data-access';
import { GoPaperLessSelectors } from '@amfam/policy/go-paperless/data-access';
import { profileQuery } from '@amfam/profile/data-access';
// Store
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions, routerQuery } from '@amfam/shared/utility/navigation';

import {
  selectBillingEligibleToGoPaperless,
  selectIsEligibleToGoPaperless
} from '../+state/common.selectors';

@Injectable({
  providedIn: 'root'
})
export class PaperlessGuard {
  constructor(
    private store: Store,
    private feature: FeatureFlagService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.store.select(PolicySelectors.selectPoliciesLoading),
      this.store.select(PolicySummarySelectors.selectPolicySummaryLoading),
      this.store.select(BillAccountsSelectors.selectBillAccountsAnyLoading),
      this.store.select(GoPaperLessSelectors.selectGoPaperlessLoading),
      this.store.select(profileQuery.selectProfileLoading),
      this.store.select(BillAccountsSelectors.selectAllBillAccountsNotifications),
      this.store.select(selectBillingEligibleToGoPaperless),
      this.store.select(selectIsEligibleToGoPaperless),
      this.store.select(AutoPayUtilSelectors.selectBillAccountsEligibleForAutoPay),
      this.store.select(routerQuery.getRouterPreviousState),
      this.store.select(BrandSelectors.selectExperienceId),
      of(route.queryParams)
    ]).pipe(
      map(
        ([
          policiesLoading,
          policySummaryLoading,
          billAccountsLoading,
          goPaperLessLoading,
          profileLoading,
          billAccounts,
          isBillingEligibleForPaperLess,
          isPolicyEligibleForPaperLess,
          billAccountsEligibleForAutoPay,
          previousStateRoute,
          experienceId,
          queryParams
        ]) => ({
          loading:
            billAccountsLoading ||
            policiesLoading ||
            goPaperLessLoading ||
            policySummaryLoading ||
            profileLoading ||
            (billAccounts.length > 0
              ? _every(
                  billAccounts,
                  billAccount => _get(billAccount, 'prefsFinishedLoading', false) === false
                )
              : false),
          eligibleForPaperLess: isBillingEligibleForPaperLess || isPolicyEligibleForPaperLess,
          billAccountsEligibleForAutoPay,
          previousStateRoute,
          experienceId,
          queryParams
        })
      ),
      filter(state => !state.loading),
      map(state => {
        if (
          _get(state, 'previousStateRoute.url') === '/enroll/personal/security-questions' ||
          _get(state, 'previousStateRoute.url') ===
            '/' + state.experienceId + '/enroll/personal/security-questions' ||
          _get(state, 'previousStateRoute.url') === '/enroll/confirmation' ||
          state.queryParams.event === 'OnSigningComplete' ||
          localStorage.getItem('frFlowType') === 'enrollment'
        ) {
          this.store.dispatch(isUserComingFromEnrollment({ isUserComingFromEnrollment: true }));

          if (!state.eligibleForPaperLess) {
            if (state.billAccountsEligibleForAutoPay.length === 0) {
              this.store.dispatch(
                fromRouterActions.Go({
                  path: ['/overview']
                })
              );
            } else {
              this.store.dispatch(
                fromRouterActions.Go({
                  path: ['/billing/auto-pay/select-accounts']
                })
              );
            }

            return false;
          } else {
            return true;
          }
        } else {
          this.store.dispatch(
            fromRouterActions.Go({
              path: ['/overview']
            })
          );
          return false;
        }
      })
    );
  }
}
