import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { ProfileActions } from '@amfam/profile/data-access';
import { PaperlessDiscountPayload } from '@amfam/shared/models';
import { Applications, ApplicationService } from '@amfam/shared/utility/shared-services';

import { GenerateEmailConstants, GoPaperlessSuccessModel } from '../models/go-paperless.model';
import { GoPaperlessService } from '../services/go-paperless.service';
import {
  EnrollGoPaperless,
  EnrollGoPaperlessFailure,
  EnrollGoPaperlessSuccess,
  GenerateGoPaperlessEmail,
  GenerateGoPaperlessEmailFailure,
  GenerateGoPaperlessEmailSuccess,
  LoadGoPaperless,
  SendGoPaperlessAnalytics,
  UnEnrollGoPaperless,
  UnEnrollGoPaperlessFailure,
  UnEnrollGoPaperlessSuccess
} from './go-paperless.actions';

@Injectable()
export class GoPaperlessEffects {
  load$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ProfileActions.ProfileActionTypes.FETCH_PARTY_DATA_SUCCESS),
      map(
        (action: ReturnType<typeof ProfileActions.FetchPartyDataSuccessAction>) =>
          action.payload.typeOfEdeliveryStatusCode
      ),
      map(enrollStatus => LoadGoPaperless(enrollStatus))
    );
  });

  enroll$ = createEffect(() => {
    return this.action$.pipe(
      ofType(EnrollGoPaperless),
      map(action => action.payload),
      switchMap(payload => {
        return this.goPaperlesservice.changeEDeliveryStatus('Y').pipe(
          mergeMap(res => {
            const successModel: GoPaperlessSuccessModel = {
              status: res.status,
              isEnrolled: true,
              correlationId: payload?.correlationId
            };
            const isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
            if (isAdminApp) {
              return [
                ProfileActions.RefreshPartyDataAction(),
                EnrollGoPaperlessSuccess(successModel)
              ];
            } else {
              return [
                ProfileActions.RefreshPartyDataAction(),
                EnrollGoPaperlessSuccess(successModel),
                GenerateGoPaperlessEmail({
                  paperlessOperation: GenerateEmailConstants.GoPaperlessOptIn,
                  correlationId: payload?.correlationId
                })
              ];
            }
          }),
          catchError(err => of(EnrollGoPaperlessFailure(err)))
        );
      })
    );
  });

  unenroll$ = createEffect(() => {
    return this.action$.pipe(
      ofType(UnEnrollGoPaperless),
      map(action => action.payload),
      switchMap(payload => {
        return this.goPaperlesservice.changeEDeliveryStatus('N').pipe(
          mergeMap(res => {
            const successModel: GoPaperlessSuccessModel = {
              status: res.status,
              isEnrolled: false,
              correlationId: payload?.correlationId
            };
            const isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
            if (isAdminApp) {
              return [
                ProfileActions.RefreshPartyDataAction(),
                UnEnrollGoPaperlessSuccess(successModel)
              ];
            } else {
              return [
                ProfileActions.RefreshPartyDataAction(),
                UnEnrollGoPaperlessSuccess(successModel),
                GenerateGoPaperlessEmail({
                  paperlessOperation: GenerateEmailConstants.GoPaperlessOptOut
                })
              ];
            }
          }),
          catchError(err => of(UnEnrollGoPaperlessFailure(err)))
        );
      })
    );
  });

  generateEmail$ = createEffect(() => {
    return this.action$.pipe(
      ofType(GenerateGoPaperlessEmail),
      map(action => action.payload),
      mergeMap(payload => {
        return this.goPaperlesservice.generateCustomerEmail(payload).pipe(
          map(res => GenerateGoPaperlessEmailSuccess(res)),
          catchError(err => of(GenerateGoPaperlessEmailFailure(err)))
        );
      })
    );
  });

  /**
   * MR: Call RPA service to handle discounts for enroll/unenroll paperless success
   */

  applyPaperlessDiscount$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(EnrollGoPaperlessSuccess, UnEnrollGoPaperlessSuccess),
        map(action => action.payload),
        switchMap((payload: GoPaperlessSuccessModel) => {
          return this.goPaperlesservice
            .buildPaperlessPayload(payload.isEnrolled)
            .pipe(
              switchMap((rpaPayload: PaperlessDiscountPayload) =>
                this.goPaperlesservice.applyPaperlessDiscount(rpaPayload)
              )
            );
        })
      );
    },
    { dispatch: false }
  );

  /**
   * @author: Abhishek Singh
   * @returns:
   * @description: This effect triggers a analytics notification.
   */

  analyticsNotification$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(SendGoPaperlessAnalytics),
        map(action => action.payload),
        map(payload => this.goPaperlesservice.sendAnalytics(payload))
      );
    },
    { dispatch: false }
  );

  constructor(
    private goPaperlesservice: GoPaperlessService,
    private action$: Actions,
    private appService: ApplicationService
  ) {}
}
