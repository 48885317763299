import { Component, Input, OnInit } from '@angular/core';

import { ConfirmationModel } from '../../ds-confirmation.model';

@Component({
  selector: 'ds-confirmation-cta-area',
  templateUrl: './ds-confirmation-cta-area.component.html',
  styleUrls: ['./ds-confirmation-cta-area.component.scss']
})
export class DsConfirmationCtaAreaComponent implements OnInit {
  @Input() ctaArea: ConfirmationModel['ctaArea'];

  constructor() {}

  ngOnInit() {}

  runFunction() {
    this.ctaArea.button.callback();
  }
}
