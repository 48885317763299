import { UserLogin } from '@amfam/shared/user';
import { createAction } from '@ngrx/store';
import { BrandModel } from '../models/brand.model';
import { BrandIDModel } from '../models/brandId';

export const LOAD_BRAND_DATA = '[Brand] Load Brand Data';
export const LOAD_BRAND_DATA_SUCCESS = '[Brand] Load Brand Data Success';
export const LOAD_BRAND_DATA_FAILURE = '[Brand] Load Brand Data Failure';
export const LOAD_USER_BRAND_DATA_SUCCESS = '[Brand] Load Brand Data for User Success';
export const LOAD_USER_BRAND_DATA_FAILURE = '[Brand] Load Brand Data for User Failure';
export const VERIFY_BRAND_EXPERIENCE_SUCCESS = '[Brand] Verify Brand Experience Success';
export const VERIFY_BRAND_EXPERIENCE_FAILURE = '[Brand] Verify Brand Experience Failure';
export const UPDATE_PROPERTY_SERVICE_URL = '[Brand] Update Property Service Url';

export const LoadBrandDataAction = createAction(
  LOAD_BRAND_DATA,

  (payload: BrandIDModel) => ({
    payload
  })
);

export const LoadBrandDataSuccess = createAction(
  LOAD_BRAND_DATA_SUCCESS,
  (payload: BrandModel) => ({
    payload
  })
);

export const LoadBrandDataFailure = createAction(LOAD_BRAND_DATA_FAILURE, (payload: unknown) => ({
  payload
}));

export const LoadUserBrandDataSuccess = createAction(
  LOAD_USER_BRAND_DATA_SUCCESS,
  (payload: UserLogin) => ({
    payload
  })
);

export const LoadUserBrandDataFailure = createAction(
  LOAD_USER_BRAND_DATA_FAILURE,
  (payload: UserLogin) => ({
    payload
  })
);

export const UpdatePropertyServiceUrl = createAction(
  UPDATE_PROPERTY_SERVICE_URL,
  (payload: string) => ({
    payload
  })
);

export type BrandActions =
  | ReturnType<typeof LoadBrandDataAction>
  | ReturnType<typeof LoadBrandDataSuccess>
  | ReturnType<typeof LoadBrandDataFailure>
  | ReturnType<typeof LoadUserBrandDataSuccess>
  | ReturnType<typeof LoadUserBrandDataFailure>
  | ReturnType<typeof UpdatePropertyServiceUrl>;
