import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

import { ADD_VEHICLE_DATA_KEY, AddVehicleDataState } from '../add-vehicle-common.selector';

const selectNewVehicleInfoState = createFeatureSelector<AddVehicleDataState>(ADD_VEHICLE_DATA_KEY);

export const selectVehicleInfoEntities = createSelector(
  selectNewVehicleInfoState,
  (state: AddVehicleDataState): AddVehicleDataState['addVehicleInfoState']['entities'] =>
    _get(state, 'addVehicleInfoState.entities')
);

export const selectVehicleInfoLoading = createSelector(
  selectVehicleInfoEntities,
  (state: AddVehicleDataState['addVehicleInfoState']['entities']) => {
    const tempState = Object.values(state)[0];
    return tempState ? tempState.loading : true;
  }
);

export const selectNewVehicleTPIs = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  (userEntities, loading): AddVehicleDataState['addVehicleInfoState']['entities'][0]['tpis'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'tpis')
);

export const selectNewVehicleDrivers = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  (userEntities, loading): AddVehicleDataState['addVehicleInfoState']['entities'][0]['drivers'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'drivers')
);

export const selectHasCostcoExecutiveMembership = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  (userEntities, loading): boolean =>
    loading
      ? false
      : !!_get(Object.values(userEntities)[0], 'drivers').find(
          driver =>
            driver.affiliations &&
            !!driver.affiliations.find(
              affiliation =>
                affiliation.affiliationGroup === 'Costco' && affiliation.memberTier === 'Executive'
            )
        )
);

export const selectHasCostcoGoldStarMembership = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  (userEntities, loading): boolean =>
    loading
      ? false
      : !!_get(Object.values(userEntities)[0], 'drivers').find(
          driver =>
            driver.affiliations &&
            !!driver.affiliations.find(
              affiliation =>
                affiliation.affiliationGroup === 'Costco' && affiliation.memberTier === 'GoldStar'
            )
        )
);

export const selectNewVehicleList = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleInfoState']['entities'][0]['vehiclesInPolicy'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'vehiclesInPolicy')
);

export const selectNewVehicleCoverages = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleInfoState']['entities'][0]['vehicleCoverages'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'vehicleCoverages')
);

export const selectNewVehicleSubmissionId = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleInfoState']['entities'][0]['submissionId'] =>
    loading ? '' : _get(Object.values(userEntities)[0], 'submissionId')
);

export const selectNewVehicleDriverAssignments = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  selectNewVehicleDrivers,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleInfoState']['entities'][0]['driverAssignments'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'driverAssignments')
);

export const selectNewVehicleInitiateError = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  (userEntities, loading): AddVehicleDataState['addVehicleInfoState']['entities'][0]['error'] => {
    if (!loading) {
      return _get(Object.values(userEntities)[0], 'error');
    }
  }
);

export const selectHasTPMPackageAvailibility = createSelector(
  selectVehicleInfoEntities,
  selectVehicleInfoLoading,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleInfoState']['entities'][0]['hasTPMPackage'] => {
    if (!loading) {
      return _get(Object.values(userEntities)[0], 'hasTPMPackage');
    }
  }
);

export const selectNumberOfVehicles = createSelector(
  selectNewVehicleList,
  vehicles => vehicles?.length
);

export const selectCoveragesModelledAfter = createSelector(selectVehicleInfoEntities, e =>
  _get(Object.values(e)[0], 'coveragesModelledAfter')
);

export const selectNewVehicleVin = createSelector(selectVehicleInfoEntities, e =>
  _get(Object.values(e)[0], 'vin')
);

export const addVehicleInfoQuery = {
  selectNewVehicleInfoState,
  selectNewVehicleTPIs,
  selectNewVehicleDrivers,
  selectNewVehicleList,
  selectNewVehicleCoverages,
  selectNewVehicleSubmissionId,
  selectNewVehicleDriverAssignments,
  selectNewVehicleInitiateError,
  selectHasTPMPackageAvailibility,
  selectHasCostcoExecutiveMembership,
  selectHasCostcoGoldStarMembership,
  selectNumberOfVehicles,
  selectCoveragesModelledAfter,
  selectNewVehicleVin
};
