import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { OpportunityContent } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-opportunities-cards',
  templateUrl: './opportunities-cards.component.html',
  styleUrls: ['./opportunities-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunitiesCardsComponent {
  @Input() opportunitiesContentList: (OpportunityContent & { recommendationId: string })[];
  @Input() displayedOpportunitiesLength: number;
  @Input() isAuthorized: boolean;

  @Output() learnMoreEvent = new EventEmitter<string>();

  public opportunitiesSlicedLength: number;

  /**
   * @param: None
   * @returns: Void
   * @description: This function is called when there are more than displayedOpportunitiesLength,
   * opportunitiesSlicedLength gets updated when user clicks on Show More button, then it slices to opportunitiesContentList length,
   * if user clicks on Show less, opportunitiesSlicedLength gets updated to displayedOpportunitiesLength.
   */
  toggleContentList(
    slicedLength: number,
    contentLength: number,
    initialDisplayLength: number
  ): void {
    this.opportunitiesSlicedLength =
      slicedLength === contentLength ? initialDisplayLength : contentLength;
  }
}
