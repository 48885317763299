// Angular
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
// Store
import { Store } from '@ngrx/store';
// rxjs
import { map, tap, withLatestFrom } from 'rxjs/operators';

// Services
import { WorkflowService } from '../../../core/workflow/workflow.service';
import { RootState } from '../../store';
import * as workflowActions from './workflow.actions';

@Injectable()
export class WorkflowEffects {
  constructor(
    private store: Store<RootState>,
    private actions$: Actions,
    private workflowService: WorkflowService
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(workflowActions.WORKFLOW_LOAD),
        map((action: workflowActions.WorkflowLoadAction) => action.payload),
        tap(payload => {
          this.workflowService.workflowUnsubscribe();
          if (payload.activatedRoute) {
            this.workflowService.workflowSubscribe(payload.activatedRoute);
          }
        })
      ),
    { dispatch: false }
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering

  reset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(workflowActions.WORKFLOW_RESET),
        map((action: workflowActions.WorkflowResetAction) => action.payload),
        tap(() => {
          this.workflowService.workflowUnsubscribe();
        })
      ),
    { dispatch: false }
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering

  completeStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(workflowActions.WORKFLOW_STEP_COMPLETE),
        map((action: workflowActions.WorkflowStepCompleteAction) => action.payload),
        withLatestFrom(this.store.select(state => state.workflow)),
        tap(([payload, workflow]) => {
          if (workflow.steps[payload.step].nextStep !== workflow.activeStep) {
            this.workflowService.workflowError();
          }
        })
      ),
    { dispatch: false }
  );
}
