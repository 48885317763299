/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-array-constructor */
import { AutoPayActions } from '@amfam/billing/auto-pay/data-access';
import { PaymentMethodActions } from '@amfam/billing/payment-method/data-access';
import { PaymentConfirmationActions } from '@amfam/billing/paymentconfirmation/data-access';
import { PendingRegistrationsActions } from '@amfam/billing/registration/data-access';
import { ScheduledPaymentActions } from '@amfam/billing/schedulepayment/data-access';
import { BillAccountTransmuteService } from '@amfam/billing/shared/util';
import { PolicyActions, PolicySelectors } from '@amfam/policy/data-access';
import {
  Policy,
  PolicyTypeDisplayNameConstants,
  PolicyTypeIconConstants,
  RiskModel
} from '@amfam/policy/models';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { DynatraceService } from '@amfam/shared/analytics/src/lib/services/dynatrace.service';
import {
  BillAccount,
  BillingPaymentPaths,
  DeletePreferencesStoreModel,
  INVALID_IMPERSONATION_STATUS_CODE,
  ONLINE_BILLING,
  OldUpdatePreferencesResponse,
  PAPER,
  PaymentConfirmationModel,
  PcmAnalytics,
  PolicyType,
  PopulatePreferenceModel,
  RetrievePreferencePayload,
  UpdateAmdModel,
  UpdateRegistrationStoreModel
} from '@amfam/shared/models';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { UtilService } from '@amfam/shared/utility/shared-services';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import dateFormat from 'date-fns/format';
import {
  Dictionary,
  get as _get,
  has as _has,
  includes as _includes,
  merge as _merge,
  unionBy as _uniqBy
} from 'lodash';
import { Observable, forkJoin, from, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { RegisterBillAccModel } from '../models/billingAccount.model';
import { BillAccountsService } from '../services/bill-accounts.service';
import { BillingUtilService } from '../services/billing-util.service';
import {
  BillAccountDeletePreference,
  BillAccountLoadDetail,
  BillAccountLoadDetailSuccess,
  BillAccountLoadDocuments,
  BillAccountLoadFuturePayments,
  BillAccountLoadPreference,
  BillAccountLoadUnAssociatedRiskDetail,
  BillAccountReloadMinimumDue,
  BillAccountSilentRegistration,
  BillAccountTransformPolicyList,
  BillAccountUpdateBillingPreference,
  BillAccountUpdateDeliveryPreference,
  BillAccountUpdatePreference,
  BillAccountUpdateRegistrationSubmit,
  BillAccountsLoad,
  BillAccountsLoadSuccess,
  BillingActionTypes,
  BillingActions,
  fromBillingActions
} from './billaccount.actions';
import { billaccountsQuery } from './billaccount.selectors';

@Injectable()
export class BillingEffects {
  constructor(
    private store: Store<BillAccount>,
    private rootStore: Store<any>,
    private billingService: BillAccountsService,
    private billingUtilService: BillingUtilService,
    private analyticsFacade: AnalyticsFacade,
    private dynatraceService: DynatraceService,
    private billingTransmuteService: BillAccountTransmuteService,
    private action$: Actions,
    private featureService: FeatureFlagService,
    private utilService: UtilService
  ) {}

  futurePayments$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountLoadFuturePayments),
      map((action: BillAccountLoadFuturePayments) => action.payload),
      distinctUntilKeyChanged('billAccountNumber'),
      switchMap(payload => {
        return this.billingService.getScheduledPayments(payload.billAccountNumber).pipe(
          map((scheduledPayment: BillAccount) => {
            return new fromBillingActions.BillAccountLoadFuturePaymentsSuccess(scheduledPayment);
          }),
          catchError(error =>
            of(
              new fromBillingActions.BillAccountLoadFuturePaymentsFail({
                status: error,
                billAccountNumber: payload.billAccountNumber
              })
            )
          )
        );
      })
    )
  );

  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountsLoad),
      map((action: BillAccountsLoad) => action.payload),
      switchMap(() =>
        this.billingService.getBillAccounts().pipe(
          finalize(() => {
            // Set the list load to complete for bill accouints.
            this.store.dispatch(new fromBillingActions.BillAccountsLoadComplete());
          }),
          map(undecorated => this.billingTransmuteService.decorateAccountsArray(undecorated)),
          map((fetchedBillAccount: any) => {

            const atLeastOnePastDueAccount = fetchedBillAccount.some(acc => acc.pastDue);
            if(atLeastOnePastDueAccount) {
              this.analyticsFacade.trackEvent({
                event: 'pastdue_bill',
                eventStep: '',
              });
            }
            return new fromBillingActions.BillAccountsLoadSuccess(fetchedBillAccount);
          }),
          catchError(error => of(new fromBillingActions.BillAccountsLoadFail(error)))
        )
      )
    )
  );

  loadAutoPays$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountsLoadComplete),
      map((action: BillAccountsLoadSuccess) => action.payload),
      withLatestFrom(this.store.select(billaccountsQuery.getAllBillAccounts)),
      map(([payload, billAccounts]) => {
        return AutoPayActions.getAllAutomaticPayments({
          billAccountNumbers: billAccounts.map(ba => ba.billAccountNumber),
          correlationId: this.utilService.generateId()
        });
      })
    )
  );

  loadAll$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountsLoadSuccess),
      map((action: BillAccountsLoadSuccess) => action.payload),
      mergeMap((decoratedArray: BillAccount[]) => from(decoratedArray)),
      mergeMap((ba): Array<any> => {
        let billAccountActions: Array<any> = new Array();
        billAccountActions = [
          new fromBillingActions.BillAccountLoadDetail({
            billAccountNumber: ba.billAccountNumber,
            associated: ba.associated
          }),
          new fromBillingActions.BillAccountLoadDocuments({
            billAccountNumber: ba.billAccountNumber,
            billingSystem: ba.billingSystem
          })
        ];

        // With PCM changes we need to make Get billAccount/id/preferences call irrespective of registration.
        const payload: RetrievePreferencePayload = {
          billAccountNumber: ba.billAccountNumber,
          associated: ba.associated,
          billingMethod: ba.billingMethod
        };
        billAccountActions.push(new fromBillingActions.BillAccountLoadPreference(payload));
        // TODO the condition below may not be needed, needs to be tested after it is removed
        if (_get(ba, 'billingMethod') === ONLINE_BILLING && !ba.registeredElsewhere) {
          billAccountActions.push(
            AutoPayActions.getAutomaticPayment({
              billAccountNumber: ba.billAccountNumber,
              correlationId: 'undefined'
            })
          );
        }
        return billAccountActions;
      }),
      catchError(error => of(new fromBillingActions.BillAccountsLoadFail(error)))
    )
  );

  loadDetail$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<BillAccountLoadDetail>(BillingActionTypes.BillAccountLoadDetail),
      map(action => action.payload),
      mergeMap(
        (payload): Observable<BillingActions> =>
          this.billingService.getBillAccount(payload).pipe(
            map((res: any) => res.billingAccount),
            map((undecorated: any) =>
              this.billingTransmuteService.transmuteDetailCall(undecorated)
            ),
            map((decorated: any) => _merge(decorated, { associated: payload.associated })),
            map((res: BillAccount) => {
              let billAccountsActionsArr: Array<BillingActions> = new Array();
              billAccountsActionsArr = [new fromBillingActions.BillAccountLoadDetailSuccess(res)];
              if (!res.associated && _get(res, 'policyList.length', 0) > 0) {
                billAccountsActionsArr = [
                  ...billAccountsActionsArr,
                  new fromBillingActions.BillAccountLoadUnAssociatedRiskDetail({
                    billAccountNumber: res.billAccountNumber,
                    policyList: res.policyList
                  })
                ];
              }
              return billAccountsActionsArr;
            }),
            mergeMap(actions => actions),
            catchError(error =>
              of(
                new fromBillingActions.BillAccountLoadDetailFail({
                  billAccountNumber: payload.billAccountNumber,
                  status: error
                })
              )
            )
          )
      )
    )
  );

  loadUnassociatedBaPolicyDetail$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<BillAccountLoadUnAssociatedRiskDetail>(
        BillingActionTypes.BillAccountLoadUnAssociatedRiskDetail
      ),
      map(action => action.payload),
      mergeMap(payload =>
        this.billingService.getUnAssociatedBillAccountRiskInformation(payload.policyList).pipe(
          withLatestFrom(
            this.rootStore.select(
              billaccountsQuery.getMatchedBillAccount,
              payload.billAccountNumber
            )
          ),
          map(([policyList, billAccountArr]) => {
            return {
              newPolicyList: this.billingUtilService.uniqPolicyList(
                policyList,
                billAccountArr[0].policyList
              ),
              billaccountObj: billAccountArr[0]
            };
          }),
          map(transmutedObj => {
            let responsePayload = {
              billAccountNumber: payload.billAccountNumber,
              policyList: transmutedObj.newPolicyList
            };

            if (!transmutedObj.billaccountObj.associated) {
              let icon = PolicyTypeIconConstants.UNASSOCIATED;

              const uniquePolicyList = _uniqBy(
                transmutedObj.billaccountObj.policyList,
                'policyType'
              );
              if (
                uniquePolicyList.length === 1 &&
                (uniquePolicyList[0].policyType === PolicyType.FARM_RANCH ||
                  uniquePolicyList[0].policyType === PolicyType.FR_UMBRELLA)
              ) {
                icon = PolicyTypeIconConstants.COMMERCIAL;
              }

              responsePayload = Object.assign({}, responsePayload, {
                icon: icon,
                policyTypeDisplayName: PolicyTypeDisplayNameConstants.UNASSOCIATED
              });
            }
            return new fromBillingActions.BillAccountLoadUnAssociatedRiskDetailSuccess(
              responsePayload
            );
          }),
          catchError(error =>
            of(
              new fromBillingActions.BillAccountLoadUnAssociatedRiskDetailFail(
                Object.assign({}, { billAccountNumber: payload.billAccountNumber })
              )
            )
          )
        )
      )
    )
  );

  loadPrefs$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountLoadPreference),
      map((action: BillAccountLoadPreference) => action.payload),
      mergeMap(payload =>
        this.billingService.getPreferences(payload).pipe(
          map((res: any) => new fromBillingActions.BillAccountLoadPreferenceSuccess(res)),
          catchError(error => {
            this.analyticsFacade.trackPage(PcmAnalytics.getPreferencesPartialFailure);
            return of(
              new fromBillingActions.BillAccountLoadPreferenceFail({
                billAccountNumber: payload.billAccountNumber,
                status: error
              })
            );
          })
        )
      )
    )
  );

  updatePrefs$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountUpdatePreference),
      map((action: BillAccountUpdatePreference) => action.payload),
      mergeMap(payload =>
        this.billingService.updatePreferences(payload).pipe(
          mergeMap(response => {
            const updatePreferencesNextActions: Array<BillingActions> = new Array();

            // Translate the response object into the correct naming conventions (see bill-account.model.ts for details)
            const successObject = <OldUpdatePreferencesResponse>Object.assign(
              {},
              {
                billAccountNumber: payload.billAccountNumber,
                updateCorrelationId: payload.updateCorrelationId,
                billingPreferences: {
                  accountNickName: _get(payload, 'payload.billingPreference.accountNickname'),
                  deliveryMethod: _get(payload, 'payload.billingPreference.deliveryMethod'),
                  dueDateReminder: _get(payload, 'payload.billingPreference.dueDateReminder'),
                  preferences: _get(payload, 'payload.billingPreference.preferences')
                }
              }
            );

            if (_get(response, 'status.code') === 200) {
              updatePreferencesNextActions.push(
                new fromBillingActions.BillAccountUpdatePreferenceSuccess(successObject)
              );
            } else if (_get(response, 'status.messages[0].description')) {
              // Catches anything that would not be caught be the catch block, such as a 207
              updatePreferencesNextActions.push(
                new fromBillingActions.BillAccountUpdatePreferenceFail({
                  billAccountNumber: payload.billAccountNumber,
                  updateCorrelationId: payload.updateCorrelationId,
                  updatePreferencesError: _get(response, 'status.messages[0].description')
                })
              );
            }
            return updatePreferencesNextActions;
          }),
          catchError(error => {
            return of(
              new fromBillingActions.BillAccountUpdatePreferenceFail({
                billAccountNumber: payload.billAccountNumber,
                updateCorrelationId: payload.updateCorrelationId,
                updatePreferencesError: error
              })
            );
          })
        )
      )
    )
  );

  updateBillingPrefs$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountUpdateBillingPreference),
      map((action: BillAccountUpdateBillingPreference) => action.payload),
      mergeMap(payload =>
        this.billingService.updateBillingPreferences(payload).pipe(
          mergeMap((updatedBillingPreferences: boolean) => {
            const updateBillingPreferencesNextActions: Array<BillingActions> = [];

            if (updatedBillingPreferences) {
              // Translate the response object into the correct naming conventions (see bill-account.model.ts for details)
              const updatePreferencesResponse = Object.assign(
                {},
                {
                  billAccountNumber: payload.billAccountNumber,
                  updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                  billingPreferences: {
                    accountNickName: _get(payload, 'payload.billingPreference.accountNickname', ''),
                    dueDateReminder: _get(payload, 'payload.billingPreference.dueDateReminder', ''),
                    preferences: _get(payload, 'payload.billingPreference.preferences', [])
                  }
                }
              );
              updateBillingPreferencesNextActions.push(
                new fromBillingActions.BillAccountUpdateBillingPreferenceSuccess(
                  updatePreferencesResponse
                )
              );
            } else {
              this.analyticsFacade.trackPage(PcmAnalytics.viewDetailPutFailure);
              updateBillingPreferencesNextActions.push(
                new fromBillingActions.BillAccountUpdateBillingPreferenceFail({
                  billAccountNumber: payload.billAccountNumber,
                  updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                  error: updatedBillingPreferences
                })
              );
            }
            return updateBillingPreferencesNextActions;
          }),
          catchError(error => {
            this.analyticsFacade.trackPage(PcmAnalytics.viewDetailPutFailure);
            return of(
              new fromBillingActions.BillAccountUpdateBillingPreferenceFail({
                billAccountNumber: payload.billAccountNumber,
                updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                error: error
              })
            );
          })
        )
      )
    )
  );

  updateDeliveryPrefs$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountUpdateDeliveryPreference),
      map((action: BillAccountUpdateDeliveryPreference) => action.payload),
      mergeMap(payload =>
        this.billingService.updateDeliveryPreference(payload).pipe(
          mergeMap((updatedDeliveryPreferences: boolean) => {
            const updateDeliveryPreferenceNextActions: Array<BillingActions> = [];

            if (updatedDeliveryPreferences) {
              // Retrieve preferences payload
              const retrievePreferencepayload: RetrievePreferencePayload = {
                billAccountNumber: payload.billAccountNumber,
                associated: payload.associated,
                billingMethod: payload.billingMethod
              };
              // Update delivery preference response
              const updatePreferencesResponse = {
                billAccountNumber: payload.billAccountNumber,
                updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId
              };
              updateDeliveryPreferenceNextActions.push(
                new fromBillingActions.BillAccountLoadPreference(retrievePreferencepayload),
                new fromBillingActions.BillAccountUpdateDeliveryPreferenceSuccess(
                  updatePreferencesResponse
                )
              );
            } else {
              // AS: Track this error only in GA
              this.dynatraceService.sendDynatraceAction('pageview', 'Error_PUTFailure_Profile')
              updateDeliveryPreferenceNextActions.push(
                new fromBillingActions.BillAccountUpdateDeliveryPreferenceFail({
                  billAccountNumber: payload.billAccountNumber,
                  updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                  error: updatedDeliveryPreferences
                })
              );
            }
            return updateDeliveryPreferenceNextActions;
          }),
          catchError(error => {
            // AS: Track this error only in GA
            this.dynatraceService.sendDynatraceAction('pageview', 'Error_PUTFailure_Profile')
            return of(
              new fromBillingActions.BillAccountUpdateDeliveryPreferenceFail({
                billAccountNumber: payload.billAccountNumber,
                updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                error: error
              })
            );
          })
        )
      )
    )
  );

  deletePrefs$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountDeletePreference),
      map((action: BillAccountDeletePreference) => action.payload),
      switchMap(payload =>
        this.billingService.deletePreferences(payload.deletePreference).pipe(
          mergeMap(() => {
            // Translate the response object into the correct naming conventions (see bill-account.model.ts for details)
            const successObject = <DeletePreferencesStoreModel>Object.assign(
              {},
              {
                billAccountNumber: payload.deletePreference.billAccountNumber,
                deleteCorrelationId: payload.deletePreference.deleteCorrelationId
              }
            );

            const confirmationPayload: PaymentConfirmationModel = {
              category: 'billAccount',
              subCategory: 'remove',
              nickName: payload.deletePreference.billAccountNumber,
              policyDescription: payload.deletePreference.policyDescription
            };

            return [
              new fromRouterActions.Go({ path: [BillingPaymentPaths.CONFIRMATION_PATH] }),
              new fromBillingActions.BillAccountLoadPreference(payload.loadPreference),
              new PaymentConfirmationActions.fromPaymentConfirmationActions.PaymentConfirmationLoad(
                confirmationPayload
              ),
              new fromBillingActions.BillAccountDeletePreferenceSuccess(successObject)
            ];
          }),
          catchError(error => {
            this.analyticsFacade.trackPage(PcmAnalytics.unBAMDeleteFailure);
            return of(
              new fromBillingActions.BillAccountDeletePreferenceFail({
                billAccountNumber: payload.deletePreference.billAccountNumber,
                deleteCorrelationId: payload.deletePreference.deleteCorrelationId,
                error: error
              })
            );
          })
        )
      )
    )
  );

  loadDocuments$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountLoadDocuments),
      map((action: BillAccountLoadDocuments) => action.payload),
      mergeMap(payload =>
        this.billingService
          .getStatementHistory(payload.billAccountNumber, payload.billingSystem)
          .pipe(
            map((res: any) => res.billAccount),
            map((undecorated: any) =>
              this.billingTransmuteService.decorateDocumentsCall(undecorated)
            ),
            map((res: any) => new fromBillingActions.BillAccountLoadDocumentsSuccess(res)),
            catchError(error =>
              of(
                new fromBillingActions.BillAccountLoadDocumentsFail({
                  billAccountNumber: payload.billAccountNumber,
                  status: error
                })
              )
            )
          )
      )
    )
  );

  silentRegistration$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountSilentRegistration),
      map((action: BillAccountSilentRegistration) => action.payload),
      withLatestFrom(
        this.rootStore.select(billaccountsQuery.getBillAccountEntities),
        this.rootStore.select(PolicySelectors.getActivePolicies)
      ),
      mergeMap(([pendingRegistrations, billAccountEntities, activePolicyList]) => {
        const registrationActions: Array<BillingActions> = new Array();
        pendingRegistrations.forEach(item => {
          const apiPayload: RegisterBillAccModel =
            this.billingTransmuteService.buildRegistrationPayload(
              billAccountEntities[item.billAccountNumber],
              item.correlationId,
              activePolicyList
            );
          registrationActions.push(
            new fromBillingActions.BillAccountUpdateRegistrationSubmit(apiPayload)
          );
        });
        return registrationActions;
      })
    )
  );

  updateRegistration$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountUpdateRegistrationSubmit),
      map((action: BillAccountUpdateRegistrationSubmit) => action.payload),
      withLatestFrom(this.store.select(billaccountsQuery.getAllBillAccounts)),
      mergeMap(([payload, billAccounts]) =>
        this.billingService.updateRegistration(payload).pipe(
          mergeMap((noPartialFailure: boolean) => {
            const actionsArray = [];
            const loadPreferencePayload: RetrievePreferencePayload = {
              billAccountNumber: payload.billAccountNumber,
              associated: payload.registrationData.associated,
              billingMethod: '' // Billing method set below based on condition
            };

            if (noPartialFailure) {
              // Success case
              const confirmationPayload: any = {
                category: 'billAccount',
                subCategory: payload.isUnregister ? 'remove' : 'add',
                billAccountNumber: payload.billAccountNumber,
                nickName: payload.registrationData.accountName,
                riskDescription: payload.registrationData.riskDescription,
                policyDescription: payload.registrationData.policyDescription,
                emailAddress: payload.registrationData.emailAddress,
                associated: payload.registrationData.associated
              };

              const loadAction =
                new PaymentConfirmationActions.fromPaymentConfirmationActions.PaymentConfirmationLoad(
                  confirmationPayload
                );

              const registrationSuccessPayload: UpdateRegistrationStoreModel = {
                billAccountNumber: payload.billAccountNumber,
                correlationId: payload.correlationId,
                billingMethod: ONLINE_BILLING,
                registrationDate: dateFormat(new Date(), 'YYYY-MM-DDZ')
              };

              let extraActions = [];

              // TODO make this more explicit for each resulting call we're making for clarity
              const actionpayload: any = {
                billAccountNumber: payload.billAccountNumber,
                policyNumber: payload.registrationData.policyNumber,
                associated: payload.registrationData.associated,
                billingSystem: payload.registrationData.billingSystem // TODO: This value is never set, always caused the documents call to fail.
              };
              const preferencesPayload: PopulatePreferenceModel = {
                billAccountNumber: payload.billAccountNumber.replace(/-/g, ''),
                billingPreferences: {
                  accountNickName: payload.registrationData.accountName,
                  dueDateReminder: payload.registrationData.reminder,
                  deliveryMethod: 'ELECTRONIC'
                }
              };

              if (payload.isUnregister) {
                /*
              For an associated bill accounts, we also need to re-load the bill account details, documents and clean up the store
              For unassociated accounts, remove all of this bill account's data from the store
              For both types, refresh the payment actions, scheduled payments and autopay rules.
            */
                /*
              TODO - scheduled payments and autopay rules can probably get a
              "remove rule" action that just truncates from the store for this specific bill account.
            */
                if (payload.registrationData.associated) {
                  loadPreferencePayload.billingMethod = PAPER;
                  extraActions = [
                    new fromBillingActions.BillAccountLoadDocuments(actionpayload),
                    new fromBillingActions.BillAccountUnregisterAssociated(actionpayload),
                    new ScheduledPaymentActions.fromScheduledPaymentsActions.ScheduledPaymentsLoad()
                  ];
                } else {
                  loadPreferencePayload.billingMethod = PAPER;
                  extraActions = [
                    new fromBillingActions.BillAccountUnregisterUnAssociated({
                      bilLAccountNumber: actionpayload.billAccountNumber
                    }),
                    new ScheduledPaymentActions.fromScheduledPaymentsActions.ScheduledPaymentsLoad()
                  ];
                }
                extraActions.push(
                  AutoPayActions.getAllAutomaticPayments({
                    billAccountNumbers: billAccounts.map(ba => ba.billAccountNumber),
                    correlationId: this.utilService.generateId()
                  })
                );
                extraActions.push(
                  PaymentMethodActions.getPaymentMethods({
                    correlationId: this.utilService.generateId()
                  })
                );
              } else {
                // If this is a simple registration fire actions to load the details, preferences and documents
                loadPreferencePayload.billingMethod = ONLINE_BILLING;
                extraActions = [
                  new fromBillingActions.BillAccountPopulatePreference(preferencesPayload),
                  new fromBillingActions.BillAccountLoadDocuments(actionpayload)
                ];
              }
              // Dispatch with conditional payload(refer to comment Update billingMethod in store)
              const successAction = new fromBillingActions.BillAccountUpdateRegistrationSuccess(
                registrationSuccessPayload
              );
              // set up actions array and push to it instead
              actionsArray.push(loadAction, successAction, ...extraActions);
            } else {
              // Partial fail case
              if (payload.isUnregister) {
                this.analyticsFacade.trackPage(PcmAnalytics.unregisterPartialFailure);
              } else {
                this.analyticsFacade.trackPage(PcmAnalytics.registrationPartialFailure);
              }
              loadPreferencePayload.billingMethod = PAPER;
              const updateRegistrationPartialFailAction =
                new fromBillingActions.BillAccountUpdateRegistrationPartialFail({
                  billAccountNumber: payload.billAccountNumber,
                  correlationId: payload.correlationId
                });
              actionsArray.push(updateRegistrationPartialFailAction);
            }
            /**
             * AS: If we are trying to unregisted an unassociated account and there are no failures
             * we should not be making the preference call.
             */
            if (noPartialFailure && payload.isUnregister && !payload.registrationData.associated) {
              return actionsArray;
            }
            actionsArray.push(
              new fromBillingActions.BillAccountLoadPreference(loadPreferencePayload)
            );
            return actionsArray;
          }),
          catchError(error => {
            // If it's an unregister or unregister, fire the full failure analytics
            if (payload.isUnregister) {
              this.analyticsFacade.trackPage(PcmAnalytics.unregisterFailure);
            } else {
              this.analyticsFacade.trackPage(PcmAnalytics.registrationFailure);
            }

            const code = _get(error, 'messages[0].code');
            let errorText = '';
            if (code && code.toString() === INVALID_IMPERSONATION_STATUS_CODE) {
              errorText = INVALID_IMPERSONATION_STATUS_CODE;
            } else {
              errorText = !navigator.onLine ? 'Please check your internet connection.' : error;
            }
            return [
              new fromBillingActions.BillAccountUpdateRegistrationFail({
                billAccountNumber: payload.billAccountNumber,
                correlationId: payload.correlationId,
                error: errorText
              }),
              new PendingRegistrationsActions.fromPendingRegistrationsActions.PendingRegistrationsFail(
                payload.billAccountNumber
              )
            ];
          })
        )
      )
    )
  );

  /**
   * @author: Abhishek Singh
   * @description: This effect listens to the success of the update registration and subsequent
   * detail success then zips them together.
   * The purpose of the effect is to make sure we have bill account details loaded after a registration update
   * before we dispatch an action to transform the policylist associated with the bill account.
   */

  transFormBillAccountPostRegistration$ = createEffect(() =>
    this.action$.pipe(
      ofType<BillAccountLoadDetailSuccess>(BillingActionTypes.BillAccountLoadDetailSuccess),
      map(action => action.payload),
      withLatestFrom(
        this.rootStore.select(PolicySelectors.getPolicyRisks),
        this.rootStore.select(PolicySelectors.getPolicyEntities)
      ),
      mergeMap(
        ([billAccountObj, policyList, policyEntities]): Observable<BillingActions> =>
          of(
            new fromBillingActions.BillAccountTransformPolicyList({
              policyList: policyList,
              billAccount: billAccountObj,
              policyEntities: policyEntities
            })
          )
      )
    )
  );

  /**
   * @author: Abhishek Singh
   * @description: This effect listens to the success of the policy summary actions and then
   * maps to inner observable of billaccount loadDetailAction, so that every time the billing
   * details have been loaded we dispatch an action to transform the policy list associated with
   * that billaccount.
   * The purpose of the effect is to make sure we have policies and bill account details loaded
   * before we dispatch an action to transform the policylist associated with the bill account.
   */

  policyCompleteAction$ = createEffect(() =>
    this.action$.pipe(
      ofType<PolicyActions.PoliciesLoadComplete>(
        PolicyActions.PoliciesActionTypes.PoliciesLoadComplete
      ),
      withLatestFrom(
        this.rootStore.select(billaccountsQuery.getBillAccounts),
        this.rootStore.select(PolicySelectors.getPolicyRisks),
        this.rootStore.select(PolicySelectors.getPolicyEntities)
      ),
      mergeMap(
        ([
          policyCompleteAction,
          billingList = [],
          policyList,
          policyEntities
        ]): Observable<BillingActions> => {
          return from(billingList).pipe(
            mergeMap(billaccount =>
              of(
                new fromBillingActions.BillAccountTransformPolicyList({
                  policyList: policyList,
                  billAccount: <BillAccount>billaccount,
                  policyEntities: policyEntities
                })
              )
            )
          );
        }
      ),
      catchError(error => {
        return of(new fromBillingActions.BillAccountTransformPolicyListFail(error));
      })
    )
  );

  /**
   * @author: Abhishek Singh
   * @description: This effect listens to the transform policy action and call a function which tranforms
   * the policy list associated with a bill account to a policy list which has risk description in detail.
   */

  transformPolicyListAction$ = createEffect(() =>
    this.action$.pipe(
      ofType<BillAccountTransformPolicyList>(BillingActionTypes.BillAccountTransformPolicyList),
      map(action => action.payload),
      mergeMap(
        (response: {
          policyList: RiskModel[];
          billAccount: BillAccount;
          policyEntities: Dictionary<Policy>;
        }) =>
          this.billingTransmuteService
            .transformBillingPolicyList(response.billAccount.policyList, response.policyList)
            .pipe(
              switchMap(transformedList => {
                const transFormedIconPayload = this.billingTransmuteService.getIcon(
                  response.billAccount,
                  response.policyList,
                  response.policyEntities
                );
                return of(
                  new fromBillingActions.BillAccountTransformPolicyListSuccess({
                    billAccount: Object.assign({}, response.billAccount, {
                      policyList: transformedList,
                      icon: _get(transFormedIconPayload, 'icon', ''),
                      policyTypeDisplayName: _get(
                        transFormedIconPayload,
                        'policyTypeDisplayName',
                        ''
                      )
                    })
                  })
                );
              }),
              catchError(error =>
                of(new fromBillingActions.BillAccountTransformPolicyListFail(error))
              )
            )
      ),
      catchError(error => of(new fromBillingActions.BillAccountTransformPolicyListFail(error)))
    )
  );

  /**
   * @author: Abhishek Singh
   * @description: This effect listens to the transform policy action and checks if the incoming
   * billaccount has a policy which the user is not a named insured on. In that case we call payyorobo
   * to provide the policy description.
   */

  loadUnassociatedPolicyListAction$ = createEffect(() =>
    this.action$.pipe(
      ofType<BillAccountTransformPolicyList>(BillingActionTypes.BillAccountTransformPolicyList),
      map(action => action.payload),
      mergeMap(
        (response: {
          policyList: RiskModel[];
          billAccount: BillAccount;
          policyEntities: Dictionary<Policy>;
        }) => {
          const actionArr: Array<BillingActions> = [];
          const policyForPayorObo = [];
          _get(response, 'billAccount.policyList', []).forEach(policyFromBillObj => {
            const policyExist = response.policyList.find(policyItem =>
              _includes(policyFromBillObj.policyNumber, policyItem.policyNumber)
            );

            if (!policyExist) {
              policyForPayorObo.push(policyFromBillObj);
            }
          });

          if (policyForPayorObo.length > 0) {
            actionArr.push(
              new fromBillingActions.BillAccountLoadUnAssociatedRiskDetail({
                billAccountNumber: _get(response, 'billAccount.billAccountNumber'),
                policyList: policyForPayorObo
              })
            );
          }
          return actionArr;
        }
      )
    )
  );

  UpdateReadOnlyStatus$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        BillingActionTypes.BillAccountLoadPreferenceSuccess,
        BillingActionTypes.BillAccountDeletePreferenceFail
      ),
      map((action: BillingActions) => action.payload),
      mergeMap(payload => [new fromBillingActions.BillAccountUpdateReadOnlyStatus(payload)])
    )
  );

  update_amd$ = createEffect(() =>
    this.action$.pipe(
      ofType(BillingActionTypes.BillAccountReloadMinimumDue),
      map((action: BillAccountReloadMinimumDue) => action.payload),
      filter(payload => {
        return true;
      }),
      mergeMap((payload: string[]) => {
        // Build an array of observables of GET /billaccounts/{ID}
        const requestArray = payload.map((billAccountNumber: string) => {
          // Call GET /billaccounts/{ID}
          return this.billingService.getBillAccount({ billAccountNumber: billAccountNumber }).pipe(
            map((response: any) => {
              // We only want the minimum due value to be updated so map down to that
              return {
                billAccountNumber: String(response.billingAccount.billAccountNumber),
                minimumAmountDue: Number(response.billingAccount.minimumAmountDue)
              };
            })
          );
        });
        // Fork Join the observables - we only want to emit if all are successful
        return forkJoin(requestArray);
      }),
      mergeMap((updates: UpdateAmdModel[]) => {
        const updateActions = [];
        if (updates && updates.length) {
          updates.forEach((update: UpdateAmdModel) => {
            if (_has(update, 'billAccountNumber') && _has(update, 'minimumAmountDue')) {
              updateActions.push(new fromBillingActions.BillAccountReloadMinimumDueSuccess(update));
            }
          });
        }
        return updateActions;
      })
    )
  );
}
