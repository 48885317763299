import { Party } from '@amfam/shared/models';

import { CustomerEnrollment } from './customer-enrollment.model';

export interface EnrollmentRequest {
  enrollment: CustomerEnrollment;
  customerId: string;
  email: string;
  party: Party;
}
