import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { DigitalAccount, DigitalAccountService } from '@amfam/shared/digital-account/data-access';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';

@Injectable({
  providedIn: 'root'
})
export class EditBusinessService {
  constructor(
    private digitalAccountService: DigitalAccountService,
    private store: Store
  ) {}

  updateDigitalAccount(digitalAccount: DigitalAccount) {
    let expId = null,
      partnerId = null,
      waId = null;
    this.store
      .select(BrandSelectors.selectBrandState)
      .pipe(take(1))
      .subscribe(state => {
        expId = state.experienceId;
        partnerId = state.partnerId;
      });
    this.store
      .select(userQuery.getWaid)
      .pipe(take(1))
      .subscribe(state => {
        waId = state;
      });

    return this.digitalAccountService.updateDigitalAccount(digitalAccount, waId, partnerId, expId);
  }
}
