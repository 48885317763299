<div class="row">
  <div class="column twelve lg-eight lg-push-two md-eight md-push-two">
    <div class="column twelve md-push-two md-eight">
      <confirmation
        [config]="confirmationConfig$ | async"
        (primaryEvent)="primaryButtonClick()"
        (tertiaryEvent)="tertiaryButtonClick()"
      >
        @if (overallSubmissionStatus$ | async; as overallStatus) {
          <div content>
            <!-- Failed Case Start -->
            @if (
              overallStatus === submitStatuses.FAILURE ||
              overallStatus === submitStatuses.PARTIAL_FAILURE
            ) {
              <p class="margin-bottom-20">
                {{ failurePaperlessSubheading }}
              </p>
            }
            @if (
              overallStatus === submitStatuses.FAILURE ||
              overallStatus === submitStatuses.PARTIAL_FAILURE
            ) {
              <p class="margin-bottom-20">
                <span content [contentPath]="'shared.failed'"></span>
                @for (setup of failedReviewItems$ | async; track setup) {
                  <ds-paperless-confirmation-review-item [preference]="setup">
                  </ds-paperless-confirmation-review-item>
                }
              </p>
            }
            <!-- Failed Case End -->
            <!-- Success Case Start -->
            @if (overallStatus === submitStatuses.PARTIAL_FAILURE) {
              <p class="margin-bottom-20" content [contentPath]="'shared.successful'"></p>
            }
            @if (
              overallStatus === submitStatuses.SUCCESS ||
              overallStatus === submitStatuses.PARTIAL_FAILURE
            ) {
              <div>
                @if (overallStatus === submitStatuses.SUCCESS) {
                  <p class="subtitle" data-cy="successPaperlessSubheading">
                    {{ successPaperlessSubheading }}
                  </p>
                }
                @for (setup of successfulReviewItems$ | async; track setup) {
                  <ds-paperless-confirmation-review-item [preference]="setup">
                  </ds-paperless-confirmation-review-item>
                }
              </div>
            }
            <!-- Success Case End -->
          </div>
        }
      </confirmation>
    </div>
  </div>
</div>
