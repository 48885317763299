<div class="risk-list">
  @if (!!otherLabel) {
    <div
      [formGroup]="riskSelectionForm"
      class="row risk"
      [class.pad-0]="!showPolicyNumber"
      [ngClass]="{ active: currentRiskId && currentRiskId === 'other' }"
      (click)="toggleRisk('other')"
    >
      <div class="flex self-center pad-10">
        <div class="self-center pad-right-30">
          <input
            type="radio"
            id="other"
            formControlName="riskSelection"
            value="other"
            (change)="toggleRisk('other')"
            data-cy="riskSelection"
            aria-labelledby="risk-info-other"
          />
        </div>
        @if (otherLabel) {
          <div class="self-center">
            <div class="risk-info" id="risk-info-other">
              <div class="heading" data-cy="otherRiskLabel">{{ otherLabel }}</div>
            </div>
          </div>
        }
      </div>
    </div>
  }

  @for (risk of risks; track risk) {
    <div
      [formGroup]="riskSelectionForm"
      class="row risk"
      [class.pad-0]="!showPolicyNumber"
      [ngClass]="{ active: currentRisk?.id && currentRisk?.id === risk?.id }"
      (click)="toggleRisk(risk.id)"
      [attr.data-cy]="'riskItem'"
    >
      <div class="flex self-center pad-10">
        <div class="self-center pad-right-30">
          <input
            type="radio"
            formControlName="riskSelection"
            [attr.id]="'checkbox-risk-' + risk.id"
            [value]="risk.id"
            (change)="toggleRisk(risk.id)"
            [attr.data-cy]="'checkbox-risk-' + risk.id"
            [attr.aria-labelledby]="'risk-info-' + risk.id"
          />
        </div>
        @if (showIcon) {
          <div class="self-center pad-right-30" aria-hidden="true">
            <span
              class="policyIcon"
              [ngClass]="
                risk.vehicleTypeName === driverDefaults.VEHICLE_NOT_ON_POLICY
                  ? riskIcons.NOT_ON_POLICY
                  : risk.iconType
              "
            ></span>
          </div>
        }
        <div class="self-center">
          <div class="risk-info" id="risk-info-{{ risk.id }}">
            <div class="heading">
              {{ vehicleLabel
              }}{{
                risk.vehicleTypeName === driverDefaults.VEHICLE_NOT_ON_POLICY
                  ? driverDefaults.VEHICLE_NOT_ON_POLICY
                  : risk.description
              }}
            </div>
            @if (
              risk.vehicleTypeName !== driverDefaults.VEHICLE_NOT_ON_POLICY && showPolicyNumber
            ) {
              <div>Policy #{{ risk.policyNumber | prettyPolicyNum }}</div>
            }
          </div>
        </div>
      </div>
    </div>
  }
  @if (!!otherContent) {
    <p class="pad-top-20"><span [innerHTML]="otherContent"></span></p>
  }
</div>
