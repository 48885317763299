import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Result } from '@amfam/policy/models';

import { addVehicleSendNotification } from '../../../../../add-vehicle/feature/src/lib/+state/add-vehicle-feature.actions'; // TODO temp fix for circular dependency
import * as VehicleCoverageActions from './vehicle-coverages.actions';

export const FEATURE_KEY = 'vehicle-coverages';

export interface State extends EntityState<Result> {
  // additional entities state properties
  selectedId?: string | number; // which Modal record has been selected
  loaded: boolean; // has the Modal list been loaded
  error?: unknown;
  canRecalculate: boolean;
  baseId?: string;
}

export interface PartialState {
  readonly [FEATURE_KEY]: State;
}

export const adapter: EntityAdapter<Result> = createEntityAdapter<Result>({
  selectId: entity => entity.id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  error: null,
  canRecalculate: false
});

export const vehicleCoverageReducer = createReducer(
  initialState,
  //TODO: change generic names
  on(
    VehicleCoverageActions.addVehicleCoverage,
    VehicleCoverageActions.addBaseToSelectedVehicleCoverage,
    VehicleCoverageActions.addVehicleCoverageForChangeCoverage,
    (state, { payload }) =>
      adapter.addOne(payload, { ...state, selectedId: payload?.id, canRecalculate: false })
  ),
  on(VehicleCoverageActions.setVehicleCoverage, (state, { payload }) =>
    adapter.setOne(payload, state)
  ),
  on(VehicleCoverageActions.upsertVehicleCoverage, (state, { payload }) =>
    adapter.upsertOne(payload, state)
  ),
  on(VehicleCoverageActions.addVehicleCoverages, (state, { payload }) =>
    adapter.addMany(payload, state)
  ),
  on(VehicleCoverageActions.upsertVehicleCoverages, (state, { payload }) =>
    adapter.upsertMany(payload, state)
  ),
  on(VehicleCoverageActions.updateVehicleCoverage, (state, { update }) =>
    adapter.updateOne(update, state)
  ),
  on(VehicleCoverageActions.updateCoverage, (state, { update }) =>
    adapter.updateOne(update, { ...state, canRecalculate: true })
  ),
  on(VehicleCoverageActions.updateVehicleCoverages, (state, { updates }) =>
    adapter.updateMany(updates, state)
  ),
  on(VehicleCoverageActions.mapVehicleCoverage, (state, { entityMap }) =>
    adapter.mapOne(entityMap, state)
  ),
  on(VehicleCoverageActions.mapVehicleCoverages, (state, { entityMap }) =>
    adapter.map(entityMap, state)
  ),
  on(VehicleCoverageActions.deleteVehicleCoverage, (state, { id }) => adapter.removeOne(id, state)),
  on(VehicleCoverageActions.deleteVehicleCoverages, (state, { ids }) =>
    adapter.removeMany(ids, state)
  ),
  on(VehicleCoverageActions.deleteVehicleCoveragesByPredicate, (state, { predicate }) =>
    adapter.removeMany(predicate, state)
  ),
  on(VehicleCoverageActions.loadVehicleCoverages, (state, { payload }) =>
    adapter.setAll(payload, { ...state, selectedId: payload[0]?.id })
  ),
  on(VehicleCoverageActions.setVehicleCoverages, (state, { payload }) =>
    adapter.setMany(payload, state)
  ),
  on(VehicleCoverageActions.resetQuote, state =>
    adapter.removeOne(state.selectedId.toString(), {
      ...state,
      selectedId: state.ids
        .map(id => id.toString())
        .filter(id => id !== state.selectedId.toString())[0]
    })
  ),
  on(addVehicleSendNotification, (state, payload) => {
    const updatedVehicleInfo = Object.assign({}, state.entities[state.ids[0]], {
      typeOfAgentEmail: payload.payload.typeOfEmail
    });

    return adapter.upsertOne(updatedVehicleInfo, state);
  }),
  on(VehicleCoverageActions.setBaseCoverage, (state, { payload }) =>
    adapter.addOne(payload, { ...state, baseId: payload?.id })
  ),
  on(
    VehicleCoverageActions.bindChangeCoverageQuoteError,
    (state, payload): State => ({
      ...state,
      error: payload
    })
  ),
  on(VehicleCoverageActions.resetAll, state =>
    adapter.removeAll({ ...state, baseId: null, selectedId: null })
  ),
  on(VehicleCoverageActions.clearError, (state): State => ({ ...state, error: null }))
);

export function reducer(state: State | undefined, action: Action) {
  return vehicleCoverageReducer(state, action);
}
