import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

import { PolicySelectors } from '@amfam/policy/data-access';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';

import { GoPaperlessModel } from '../models/go-paperless.model';
import * as fromGoPaperlessState from './go-paperless.reducer';

export const GO_PAPERLESS_FEATURE_KEY = 'goPaperless';
export interface GoPaperLessState {
  goPaperLessState: GoPaperlessModel;
}

export const goPaperLessReducers: ActionReducerMap<GoPaperLessState> = {
  goPaperLessState: fromGoPaperlessState.reducer
};

export const selectGoPaperlessState =
  createFeatureSelector<GoPaperLessState>(GO_PAPERLESS_FEATURE_KEY);

export const selectGoPaperlessLoading = createSelector(
  selectGoPaperlessState,
  (state: GoPaperLessState) => _get(state, 'goPaperLessState.loading', true)
);

export const selectGoPaperlessLoaded = createSelector(
  selectGoPaperlessState,
  (state: GoPaperLessState) => !_get(state, 'goPaperLessState.loading', true)
);

export const selectGoPaperlessError = createSelector(
  selectGoPaperlessState,
  (state: GoPaperLessState) => !!_get(state, 'goPaperLessState.hasError')
);

export const selectIsEnrolledGoPaperless = createSelector(
  selectGoPaperlessState,
  (state: GoPaperLessState) => !!_get(state, 'goPaperLessState.isEnrolled')
);

export const selectCorrelationId = createSelector(
  selectGoPaperlessState,
  (state: GoPaperLessState) => _get(state, 'goPaperLessState.correlationId')
);
export const selectUserAndBrandInfo = createSelector(
  userQuery.getUserState,
  BrandSelectors.selectBrandId,
  BrandSelectors.selectPartnerId,
  selectIsEnrolledGoPaperless,
  (user, brandId, partnerId, isEnrolled) => ({
    user,
    brandId,
    partnerId,
    isEnrolled
  })
);
export const selectCustomerAndPartyDetails = createSelector(
  userQuery.getCustomerId,
  userQuery.getPartyDetails,
  (customerId, party) => ({
    customerId,
    party
  })
);
export const selectPaperlessData = createSelector(
  PolicySelectors.selectActivePolicies,
  userQuery.getCustomerId,
  BrandSelectors.selectPartnerId,
  (policies, customerId, partnerId) => ({
    policies,
    customerId,
    partnerId
  })
);
