/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { combineLatest, take } from 'rxjs';

import { addVehicleQuoteQuery } from '@amfam/policy/add-vehicle/data-access';
import { RiskSelectionContext } from '@amfam/policy/models';
import { vehicleQuery } from '@amfam/policy/vehicle/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';

import { ConfirmationModel, initialConfirmationModel } from './ds-confirmation.model';

@Component({
  selector: 'ds-confirmation',
  templateUrl: './ds-confirmation.component.html',
  styleUrls: ['./ds-confirmation.component.scss']
})
export class DsConfirmationComponent implements OnInit {
  @Input() confirmationData: ConfirmationModel;
  @Input() autopayerror: [];
  @Input() showDeletedPayment: boolean;
  @Input() paymentTerm: string;
  @Input() amfamTelNumber?: string;
  @Input() amfamPhone?: string;
  @Input() errorHeader?: string;
  @Input() policyNumber: string;
  @Input() vehicleName: string;
  @Input() hostFromCaMileage = false;
  @Output() retryClickedEvent = new EventEmitter();

  public confirmationHeading: ConfirmationModel['confirmation']['heading'];
  public errorConfirmationHeading: ConfirmationModel['confirmation']['errorHeading'];
  public confirmationSubHeading: ConfirmationModel['confirmation']['subHeading'];
  public confirmationStatus: ConfirmationModel['confirmation']['status'];
  public errorDetailsList: ConfirmationModel['details'];
  public successDetailsList: ConfirmationModel['details'];
  public hasErrorConfirmationTobeShown: boolean;
  public headerInfoText: ConfirmationModel['confirmation']['infoText'];
  public policyNumberText: string;
  public vehicleAddedText: string;
  public alertMessage: string;
  public title: string;
  public isPartner = false;
  constructor(
    protected store: Store,
    protected analyticsFacade: AnalyticsFacade
  ) {
    this.confirmationData = initialConfirmationModel;
  }

  ngOnInit() {
    this.confirmationHeading = this.confirmationData.confirmation.heading;
    this.errorConfirmationHeading = this.confirmationData.confirmation.errorHeading;
    this.confirmationSubHeading = this.confirmationData.confirmation.subHeading;
    this.confirmationStatus = this.confirmationData.confirmation.status;
    this.headerInfoText = _get(this.confirmationData, 'confirmation.infoText');
    this.isPartner = this.confirmationData.isPartner;
    this.policyNumberText = this.confirmationData.policyNumberText;
    this.vehicleAddedText = this.confirmationData.vehicleAddedText;
    this.alertMessage = this.confirmationData.alertMessageConfirmation;
    this.title = this.confirmationData.titleConfirmation;

    combineLatest(
      this.store.select(vehicleQuery.selectNewVehicle),
      this.store.select(addVehicleQuoteQuery.selectNewVehicleWorkOrderNumber),
      this.store.select(addVehicleQuoteQuery.selectVehicleRiskSelectionContextType),
      (newVehicle, workOrderNumber, riskSelectionContextType) => ({
        newVehicle: newVehicle,
        workOrderNumber: workOrderNumber,
        riskSelectionContextType: riskSelectionContextType
      })
    )
      .pipe(take(1))
      .subscribe(state => {
        if (state.workOrderNumber) {
          this.analyticsFacade.trackEvent({
            event:
              RiskSelectionContext.ADD_VEHICLE === state.riskSelectionContextType
                ? 'add_vehicle_complete'
                : 'replace_vehicle_complete',
            form_name:
              RiskSelectionContext.ADD_VEHICLE === state.riskSelectionContextType
                ? 'Add Vehicle Flow'
                : 'Replace Vehicle Flow',
            vehicle_make: _get(state, 'newVehicle.make', ''),
            vehicle_model: _get(state, 'newVehicle.model', ''),
            vehicle_year: _get(state, 'newVehicle.year', ''),
            quote_id: _get(state, 'workOrderNumber', ''),
            product_line: 'auto'
          });
        }
      });

    this.hasErrorConfirmationTobeShown = !!_get(this.confirmationData, 'details', []).find(
      detail => detail.heading === this.errorHeader && _get(detail, 'tableRows.length', 0) > 0
    );

    if (this.hasErrorConfirmationTobeShown) {
      this.errorDetailsList = _get(this.confirmationData, 'details', []).filter(
        detail => detail.heading === this.errorHeader
      );
    }
    this.successDetailsList = _get(this.confirmationData, 'details', []).filter(
      detail => detail.heading !== this.errorHeader
    );
  }
}
