import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { get as _get } from 'lodash';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { BillAccountActions } from '@amfam/billing/billaccount/data-access';

import { CommunicationPreferencesService } from '../services/communication-preferences.service';
import {
  setBillAccountDeliveryPreference,
  setBillAccountDeliveryPreferenceFailure,
  setBillAccountDeliveryPreferenceSuccess
} from './communication-preferences.actions';

@Injectable()
export class CommunicationPreferencesEffects {
  setBillAccountDeliveryPreference$ = createEffect(() =>
    // eslint-disable-next-line ngrx/prefer-effect-callback-in-block-statement
    this.actions$.pipe(
      ofType(setBillAccountDeliveryPreference),
      mergeMap(action =>
        this.communicationPreferencesService
          .setBillAccountDeliveryPreference(action.billAccountNumber, action.payload)
          .pipe(
            mergeMap(() => {
              const updatePreferences = Object.assign(
                {},
                {
                  billAccountNumber: action.billAccountNumber,
                  updatePreferenceCorrelationId: action.correlationId,
                  billingPreferences: {
                    accountNickName: _get(action.payload, 'billingPreference.accountNickname', ''),
                    dueDateReminder: _get(action.payload, 'billingPreference.dueDateReminder', ''),
                    preferences: _get(action.payload, 'billingPreference.preferences', [])
                  }
                }
              );
              return [
                setBillAccountDeliveryPreferenceSuccess({
                  billAccountNumber: action.billAccountNumber,
                  billingPreferences: action.payload.billingPreference,
                  correlationId: action.correlationId
                }),
                //This action is invoked to update the billing store as well.
                //communicationpreferences store was not used while checking the showPaperlessCard
                BillAccountActions.billAccountUpdateBillingPreferenceSuccess(updatePreferences)
              ];
            }),
            catchError(error =>
              of(
                setBillAccountDeliveryPreferenceFailure({
                  error,
                  billAccountNumber: action.billAccountNumber,
                  correlationId: action.correlationId
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private communicationPreferencesService: CommunicationPreferencesService,
    private actions$: Actions
  ) {}
}
