import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap as _flatMap, flatten as _flatten, get as _get, some as _some } from 'lodash';

import {
  AutoPolicy,
  GenericProductType,
  LifePolicy,
  Policy,
  PolicyStatus,
  PropertyPolicy,
  RiskCompare,
  RiskModel,
  SourceSystemType,
  UmbrellaPolicy
} from '@amfam/policy/models';

import { VehicleTypeConstants } from '../../models/vehicle-type-constants';
import * as fromDocumentsState from '../documents/documents.reducer';
import * as fromDocumentsNotifications from '../documents/notifications.reducer';
import * as fromSummariesNotifications from '../summaries/notifications.reducer';
import * as fromSummariesState from '../summaries/summaries.reducer';
import { selectPolicySummaries } from '../summaries/summaries.selectors';
import * as fromPoliciesNotifications from './notifications.reducer';
import * as fromPoliciesState from './policies.reducer';
import { POLICIES_FEATURE_KEY } from './policies.reducer';

export interface PoliciesState {
  policyState: fromPoliciesState.PoliciesEntityState;
  policyNotifications: fromPoliciesNotifications.NotificationState;
  documentsState: fromDocumentsState.PolicyDocumentsEntityState;
  documentsNotifications: fromDocumentsNotifications.NotificationState;
  summariesState: fromSummariesState.PolicySummariesEntityState;
  summariesNotification: fromSummariesNotifications.NotificationState;
}

export const policiesReducers: ActionReducerMap<PoliciesState> = {
  policyState: fromPoliciesState.reducer,
  policyNotifications: fromPoliciesNotifications.reducer,
  documentsState: fromDocumentsState.reducer,
  documentsNotifications: fromDocumentsNotifications.reducer,
  summariesState: fromSummariesState.reducer,
  summariesNotification: fromSummariesNotifications.reducer
};

// Lookup the 'Policies' feature state managed by NgRx
const selectPoliciesState = createFeatureSelector<PoliciesState>(POLICIES_FEATURE_KEY);

const selectLoaded = createSelector(
  selectPoliciesState,
  (state: PoliciesState) => !_get(state, 'policyNotifications.loading', true)
);

const selectError = createSelector(selectPoliciesState, (state: PoliciesState) =>
  _get(state, 'policyNotifications.error')
);

export const selectPolicyHasError = createSelector(
  selectPoliciesState,
  (state: PoliciesState) => !!_get(state, 'policyNotifications.error')
);

const selectAllPolicies = createSelector(
  selectPoliciesState,
  selectLoaded,
  (state: PoliciesState, isLoaded) =>
    // return [];
    isLoaded ? fromPoliciesState.selectAll(state.policyState) : []
);

const selectAllRawPolicies = createSelector(
  selectPoliciesState,
  selectLoaded,
  (state: PoliciesState, isLoaded) => (isLoaded ? state.policyState.rawPolicies : [])
);

const selectSelectedId = createSelector(
  selectPoliciesState,
  (state: PoliciesState) => state.policyState.selectedPolicyNumber
);

const selectSelectedPolicies = createSelector(
  selectAllPolicies,
  selectSelectedId,
  (policies = [], id) => {
    const result = policies.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : [];
  }
);

export const selectSelectedPolicy = createSelector(
  selectAllPolicies,
  selectSelectedId,
  (policies, id): Policy => policies.find(it => it['policyNumber'] === id)
  // return result ? Object.assign({}, result) : [];
);

export const selectSelectedPolicyNumber = createSelector(
  selectSelectedPolicy,
  policy => policy?.policyNumber
);

export const selectPni = createSelector(selectSelectedPolicy, selectedPolicy => {
  const roles = selectedPolicy?.policyRoles;
  const pni = roles?.find(role => role.roleType.toLowerCase() === 'primarynamedinsured');
  return pni?.firstName + ' ' + pni?.lastName;
});

export const selectVehicleDescription = vehicleId =>
  createSelector(selectActiveAutoPolicyRisks, policyRisks => {
    const vehicle = policyRisks?.find(risk => risk.vehicleId === vehicleId);
    return vehicle?.description;
  });

export const selectSelectedPolicyById = createSelector(
  selectAllPolicies,
  (policies, id: string) => policies.find(it => it['policyNumber'] === id)
  // return result ? Object.assign({}, result) : [];
);

export const selectPolicyEntities = createSelector(
  selectPoliciesState,
  (state: PoliciesState) => state.policyState.entities
);

const selectPolicyIds = createSelector(
  selectPoliciesState,
  selectLoaded,
  (state: PoliciesState, isLoaded) =>
    isLoaded ? fromPoliciesState.selectIds(state.policyState) : []
);

export const selectLatestPolicyError = createSelector(selectPoliciesState, state =>
  _get(state, 'policyNotifications.error')
);

export const selectPoliciesLoading = createSelector(
  selectPoliciesState,
  (state: PoliciesState) => <boolean>!!_get(state, 'policyNotifications.loading')
);

export const selectPoliciesLoaded = createSelector(
  selectPoliciesState,
  (state: PoliciesState) => <boolean>!_get(state, 'policyNotifications.loading')
);

export const selectPolicies = createSelector(selectAllPolicies, (policies = []) =>
  policies.filter(
    policy =>
      policy.policyStatus !== PolicyStatus.Cancelled && policy.policyStatus !== PolicyStatus.Other
  )
);

export const selectInactivePolicies = createSelector(selectAllPolicies, (policies = []) =>
  policies.filter(
    policy =>
      policy.policyStatus === PolicyStatus.Cancelled || policy.policyStatus === PolicyStatus.Other
  )
);

export const selectNumberOfPolicies = createSelector(selectPolicies, policies => {
  if (!policies || !policies.length) {
    return 0;
  } else {
    return policies.length;
  }
});
export const selectActivePolicies = createSelector(selectPolicies, policies =>
  policies.filter(policy => policy.policyStatus === PolicyStatus.Inforce)
);
export const selectAdvancePolicies = createSelector(selectPolicies, policies =>
  policies.filter(policy => policy.sourceSystem === SourceSystemType.Advance)
);

export const selectClassicPolicies = createSelector(selectPolicies, policies =>
  policies.filter(policy => policy.sourceSystem === SourceSystemType.Legacy)
);

export const selectHasAdvancePolicies = createSelector(
  selectAdvancePolicies,
  advancePolicies => advancePolicies && advancePolicies.length > 0
);

export const selectHasClassicPolicies = createSelector(
  selectPolicies,
  policies => !!policies.some(policy => policy.sourceSystem === SourceSystemType.Legacy)
);

export const selectVehiclePolicies = createSelector(selectPolicies, policies =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Auto)
    .map(policy => <AutoPolicy>policy)
);

export const selectAutoPolicies = createSelector(selectVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.AUTO)
    .map(policy => <AutoPolicy>policy)
);

export const selectCyclePolicies = createSelector(selectVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.CYCLE)
    .map(policy => <AutoPolicy>policy)
);

export const selectRvPolicies = createSelector(selectVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.RV)
    .map(policy => <AutoPolicy>policy)
);

export const selectWatercraftPolicies = createSelector(selectVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.WATERCRAFT)
    .map(policy => <AutoPolicy>policy)
);

export const selectAdvanceAutoPolicies = createSelector(selectVehiclePolicies, policies =>
  policies.filter(policy => policy.sourceSystem === SourceSystemType.Advance)
);

// added  hasAdvanceAutoPolicies to support Add/Replace Vehicle
export const selectHasAdvanceAutoPolicies = createSelector(
  selectAdvanceAutoPolicies,
  advanceAutoPolicies => !!advanceAutoPolicies && advanceAutoPolicies.length > 0
);

// added  getClassicAutoPolicies to support Add/Replace Vehicle
export const selectClassicAutoPolicies = createSelector(
  selectVehiclePolicies,
  classicAutoPolicies =>
    classicAutoPolicies.filter(policy => policy.sourceSystem === SourceSystemType.Legacy)
);

// added  hasClassicAutoPolicies to support Add/Replace Vehicle
export const selectHasClassicAutoPolicies = createSelector(
  selectClassicAutoPolicies,
  classicAutoPolicies => !!classicAutoPolicies && classicAutoPolicies.length > 0
);

export const selectPropertyPolicies = createSelector(selectPolicies, policies =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Home)
    .map(policy => <PropertyPolicy>policy)
);

export const selectUmbrellaPolicies = createSelector(selectPolicies, policies =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Umbrella)
    .map(policy => <UmbrellaPolicy>policy)
);

export const selectLifePolicies = createSelector(selectPolicies, policies =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Life)
    .map(policy => <LifePolicy>policy)
);

export const selectAutoPolicyRisks = createSelector(selectVehiclePolicies, autoPolicies =>
  _flatten(
    autoPolicies.map(policy => policy.vehicles.map(vehicle => new RiskModel(vehicle, policy)))
  )
);

// Filter any vehicles that don't have a year/make/model - this can happen for non-owned vehicle policy holders
export const selectFilteredAutoPolicyRisks = createSelector(
  selectAutoPolicyRisks,
  autoPolicyRisks =>
    autoPolicyRisks.filter(
      risk =>
        _get(risk, 'year', '') !== '' &&
        _get(risk, 'make', '') !== '' &&
        _get(risk, 'model', '') !== ''
    )
);

export const selectAdvanceAutoPolicyRisks = createSelector(
  selectAdvanceAutoPolicies,
  advanceAutoPolicies =>
    _flatten(
      advanceAutoPolicies.map(policy =>
        policy.vehicles.map(vehicle => new RiskModel(vehicle, policy))
      )
    )
);

export const selectAdvanceAutoPolicyRisksById = createSelector(
  selectAdvanceAutoPolicies,
  (advanceAutoPolicies, vehicleId) => {
    const riskArr: RiskModel[] = _flatten(
      advanceAutoPolicies.map(policy =>
        policy.vehicles.map(vehicle => new RiskModel(vehicle, policy))
      )
    );
    return riskArr.find(risk => risk.vehicleId === vehicleId);
  }
);
export const selectAutoPolicyById = vehicleId =>
  createSelector(selectAutoPolicies, autoPolicies => {
    const riskArr: RiskModel[] = _flatten(
      autoPolicies.map(policy => policy.vehicles.map(vehicle => new RiskModel(vehicle, policy)))
    );
    return riskArr.find(risk => risk.vehicleId === vehicleId);
  });

export const selectPropertyPolicyRisks = createSelector(selectPropertyPolicies, propertyPolicies =>
  propertyPolicies.map(policy => new RiskModel(policy.riskLocationAddress, policy))
);

export const selectUmbrellaPolicyRisks = createSelector(selectUmbrellaPolicies, umbrellaPolicies =>
  umbrellaPolicies.map(umbrella => new RiskModel(null, umbrella))
);

export const selectLifePolicyRisks = createSelector(selectLifePolicies, lifePolicies =>
  lifePolicies.map(life => new RiskModel(life.policyType, life))
);

export const selectPolicyRisks = createSelector(
  selectFilteredAutoPolicyRisks,
  selectPropertyPolicyRisks,
  selectUmbrellaPolicyRisks,
  selectLifePolicyRisks,
  (autoRisks, propRisks, umbRisks, lifeRisks) =>
    new Array<RiskModel>()
      .concat(autoRisks, propRisks, umbRisks, lifeRisks)
      .sort(RiskCompare.compare)
);

export const selectFilteredBillingPolicyRiskList = createSelector(
  selectPolicyRisks,
  (policyRisks, billaccount) =>
    policyRisks.filter(
      (policyRisk: RiskModel) =>
        policyRisk.policy.billingAccountNumber === billaccount.billAccountNumber
    )
);

export const selectClaimablePolicyRisks = createSelector(selectPolicyRisks, risks =>
  risks
    .filter(
      risk =>
        risk.policy.policyStatus === PolicyStatus.Inforce &&
        (risk.generalizedProductType === GenericProductType.Auto ||
          risk.generalizedProductType === GenericProductType.Home)
    )
    .sort(RiskCompare.compare)
);

export const selectPhotoUploadRisks = createSelector(selectPolicyRisks, risks =>
  risks
    .filter(
      risk =>
        (risk.policy.policyStatus === PolicyStatus.Inforce || PolicyStatus.Scheduled) &&
        (risk.generalizedProductType === GenericProductType.Auto ||
          risk.generalizedProductType === GenericProductType.Home)
    )
    .sort(RiskCompare.compare)
);

// Advance auto policies and risks are required to check kyd status, eligibility
export const selectAdvanceAutoPoliciesAndRisks = createSelector(
  selectAdvanceAutoPolicies,
  selectAdvanceAutoPolicyRisks,
  (advanceAutpolicies, advanceAutoPolicyrisks) => ({
    advanceAutpolicies: advanceAutpolicies,
    advanceAutoPolicyrisks: advanceAutoPolicyrisks
  })
);

export const selectIsEligibleForPaperless = createSelector(
  selectAllPolicies,
  (allPolicies = []) => {
    // not eligible for paperless if the customer only has a life policy
    const nonLifePolicies = allPolicies.filter(policy => !(policy instanceof LifePolicy));
    return nonLifePolicies.length > 0;
  }
);

// filter the property policies based on TPI
export const selectPropertyPoliciesWithTPI = createSelector(selectPropertyPolicies, policies =>
  policies
    .filter(policy => policy.policyRoles.some(policyRole => policyRole.roleType === 'BillTo'))
    .map(policy => policy)
);

export const selectRolesforAdvanceAutoPolicy = createSelector(selectAdvanceAutoPolicies, policies =>
  policies.map(p => ({
    policyNumber: p.policyNumber,
    roles: p.policyRoles.map(role => ({
      type: role.roleType,
      firstName: role.firstName,
      lastName: role.lastName,
      customerId: role.customerId
    }))
  }))
);

// Generate slimmed down risk models using summary data supplemented with full policy risk data if available
export const selectClaimableRiskModels = createSelector(
  selectClaimablePolicyRisks,
  claimableRisks => {
    claimableRisks.map(risk => {
      if (risk.generalizedProductType === GenericProductType.Auto) {
        return {
          id: risk.id,
          policyNumber: risk.policyNumber,
          periodStartDate: risk.periodStartDate,
          generalizedProductType: risk.generalizedProductType,
          iconType: risk.iconType,
          description: risk.description,
          vin: risk.vin,
          year: risk.year,
          make: risk.make,
          model: risk.model,
          vehicleTypeName: risk.vehicleTypeName,
          address: risk.address
        };
      } else {
        return {
          id: risk.id,
          policyNumber: risk.policyNumber,
          periodStartDate: risk.periodStartDate,
          generalizedProductType: risk.generalizedProductType,
          iconType: risk.iconType,
          description: risk.description,
          address: risk.address
        };
      }
    });
    return claimableRisks.sort(RiskCompare.compare);
  }
);

export const selectHasMultipleAdvanceAutoPolicies = createSelector(
  selectAutoPolicies,
  (policies = []) =>
    policies.filter(policy => policy.sourceSystem === SourceSystemType.Advance).length > 1
);

export const selectHasAdvanceAutoPolicy = createSelector(selectAutoPolicies, (policies = []) =>
  policies.some(policy => policy.sourceSystem === SourceSystemType.Advance)
);

export const selectVehicleRisks = createSelector(
  selectAutoPolicyRisks,
  selectPolicySummaries,
  (risks, policySummaries) => {
    const rpvRisks: Array<RiskModel> = new Array<RiskModel>();
    risks.forEach(risk => {
      policySummaries.forEach(policy => {
        if (_get(risk, 'policyNumber', '') === _get(policy, 'policyNumber', '')) {
          risk = Object.assign({}, risk, { contractState: policy.contractState });
          rpvRisks.push(risk);
        }
      });
    });
    return rpvRisks;
  }
);

export const selectActiveVehiclePolicies = createSelector(selectPolicies, policies =>
  policies
    .filter(
      policy =>
        policy.generalizedProductType === GenericProductType.Auto &&
        policy.policyStatus === PolicyStatus.Inforce
    )
    .map(policy => <AutoPolicy>policy)
);

export const selectActiveAutoPolicyRisks = createSelector(
  selectActiveVehiclePolicies,
  autoPolicies =>
    _flatten(
      autoPolicies.map(policy => policy.vehicles.map(vehicle => new RiskModel(vehicle, policy)))
    )
);

export const selectHasOnlyPolicyComprehensiveCoverage = createSelector(
  selectActiveAutoPolicyRisks,
  policyRisks => {
    let vehicleCoverages = [];
    vehicleCoverages = _flatMap(policyRisks, policyRisk => policyRisk.vehicleCoverages);
    return (
      !!vehicleCoverages.find(coverage => coverage.code === 'PAComprehensiveCov') &&
      !vehicleCoverages.find(coverage => coverage.code === 'PACollisionCov')
    );
  }
);

export const selectHasAnyPolicyLiabilityCoverage = createSelector(
  selectActiveAutoPolicyRisks,
  policyRisks => {
    let vehicleCoverages = [];
    vehicleCoverages = _flatMap(policyRisks, policyRisk => policyRisk.vehicleCoverages);
    return !!vehicleCoverages.find(coverage => coverage.code === 'PAPropDamageCov_af');
  }
);

export const selectHasAdvPolicyNotNewCarReplaceCoverageAndCarLightTruck = (
  selectedVechicleId: string
) =>
  createSelector(selectAdvanceAutoPolicyRisks, policyRisks =>
    _some(
      policyRisks,
      (policyRisk: RiskModel) =>
        policyRisk.vehicleId === selectedVechicleId &&
        policyRisk.vehicleTypeName === 'Car/Light Truck' &&
        !!policyRisk.vehicleCoverages.find(coverage => coverage.code !== 'PANewCarReplaceCov_af')
    )
  );

export const selectNoAutoPaySetupContentPath = createSelector(
  selectHasAdvancePolicies,
  hasAdvancePolicy =>
    hasAdvancePolicy ? 'billing.noAutopayRulesP2Advance' : 'billing.noAutopayRulesP2Classic'
);

export const policiesQuery = {
  selectPropertyPoliciesWithTPI,
  selectLifePolicyRisks,
  selectUmbrellaPolicyRisks,
  selectPropertyPolicyRisks,
  selectAdvanceAutoPolicyRisks,
  selectFilteredAutoPolicyRisks,
  selectAutoPolicyRisks,
  selectLifePolicies,
  selectUmbrellaPolicies,
  selectPropertyPolicies,
  selectAdvanceAutoPolicies,
  selectWatercraftPolicies,
  selectRvPolicies,
  selectCyclePolicies,
  selectAutoPolicies,
  selectVehiclePolicies,
  selectHasClassicPolicies,
  selectHasAdvancePolicies,
  selectHasOnlyPolicyComprehensiveCoverage,
  selectClassicPolicies,
  selectAdvancePolicies,
  selectActivePolicies,
  selectNumberOfPolicies,
  selectPolicies,
  selectPoliciesLoaded,
  selectPoliciesLoading,
  selectLatestPolicyError,
  selectPolicyIds,
  selectPolicyEntities,
  selectSelectedPolicy,
  selectSelectedPolicies,
  selectSelectedId,
  selectAllPolicies,
  selectAllRawPolicies,
  selectPolicyHasError,
  selectError,
  selectLoaded,
  selectPoliciesState,
  selectClaimableRiskModels,
  selectRolesforAdvanceAutoPolicy,
  selectHasAdvanceAutoPolicy,
  selectHasMultipleAdvanceAutoPolicies,
  selectVehicleRisks,
  selectInactivePolicies,
  selectHasAdvPolicyNotNewCarReplaceCoverageAndCarLightTruck,
  selectNoAutoPaySetupContentPath
};
