import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { digitalAccountFeatureKey, reducers } from './+state';
import { DigitalAccountEffects } from './+state/digital-account/digital-account.effects';
import { SecurityQuestionsEffects } from './+state/security-questions/security-questions.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(digitalAccountFeatureKey, reducers),
    EffectsModule.forFeature([DigitalAccountEffects, SecurityQuestionsEffects])
  ]
})
export class SharedDigitalAccountDataAccessModule {}
