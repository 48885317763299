<confirmation
  [config]="confirmationConfig$ | async"
  (primaryEvent)="primaryButtonClick()"
  (tertiaryEvent)="done()"
>
  <div content>
    @if ((submitStatus$ | async) === submitStatuses.SUCCESS) {
      <p class="margin-bottom-20">
        {{ successBodyText | async }}
      </p>
    }
    @if ((submitStatus$ | async) === submitStatuses.FAILURE) {
      <p class="margin-bottom-20">
        {{ failureBodyText | async }}
      </p>
    }
    @if ((submitStatus$ | async) === submitStatuses.PARTIAL_FAILURE) {
      <p class="margin-bottom-20">
        {{ partialFailureBodyTextOne }}
      </p>
    }

    @if (
      (submitStatus$ | async) === submitStatuses.FAILURE ||
      (submitStatus$ | async) === submitStatuses.PARTIAL_FAILURE
    ) {
      <p class="subheading margin-bottom-5">Failed</p>
    }
    @for (item of failedReviewItems$ | async; track item) {
      <ds-auto-pay-review-item [config]="item"></ds-auto-pay-review-item>
    }
    @if ((submitStatus$ | async) === submitStatuses.PARTIAL_FAILURE) {
      <p class="subheading margin-bottom-5">Successful</p>
    }
    @if ((submitStatus$ | async) === submitStatuses.SUCCESS) {
      <p class="subtitle">AutoPay details</p>
    }
    @for (item of successfulReviewItems$ | async; track item) {
      <ds-auto-pay-review-item
        data-cy="autoPaySuccessfulConfirmationReviewItem"
        [config]="item"
      ></ds-auto-pay-review-item>
    }
  </div>
  @if ((submitStatus$ | async) === submitStatuses.PARTIAL_FAILURE) {
    <p class="margin-top-20">
      {{ partialFailureBodyTextTwo }}
    </p>
  }
</confirmation>
