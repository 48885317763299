import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'ds-table-header-cell',
  templateUrl: './table-header-cell.component.html',
  styleUrls: ['../../../containers/ds-table/ds-table.component.scss']
})
export class DsTableHeaderCellComponent implements AfterViewInit {
  @Input() column: any;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.style.width = this.column.width;
  }
}
