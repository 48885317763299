import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ValidationService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-verify-phone',
  templateUrl: './verify-phone.component.html'
})
export class VerifyPhoneComponent implements OnInit {
  @Input() maskedPhoneNumbers: string[];
  @Input() usePhoneInput: boolean;
  @Input() buttonText: string;
  @Output() submittedPhoneEvent = new EventEmitter();

  phoneNumber: AbstractControl;
  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    if (this.usePhoneInput) {
      this.form = this.formBuilder.group({
        inputPhone: [
          '',
          Validators.compose([ValidationService.phoneNumberValidator, Validators.required])
        ]
      });
      this.phoneNumber = this.form.controls['inputPhone'];
    } else {
      this.form = this.formBuilder.group({
        selectPhone: ['', Validators.compose([Validators.required])]
      });
      this.phoneNumber = this.form.controls['selectPhone'];
      this.setPhoneNumberField();
    }
  }

  // Emits selected or input phone number to parent component
  // If phone number is input, adds masking to string
  submitNumber() {
    let maskedPhone = this.phoneNumber.value;
    if (this.usePhoneInput) {
      const phone = maskedPhone.replace(/-/g, '');
      maskedPhone = phone.slice(0, 3) + '****' + phone.slice(7, 10);
    }
    this.submittedPhoneEvent.emit(maskedPhone);
  }

  private setPhoneNumberField() {
    if (this.maskedPhoneNumbers.length === 1) {
      const singlePhoneNumber = JSON.stringify(this.maskedPhoneNumbers[0]);
      this.form.controls['selectPhone'].setValue(JSON.parse(singlePhoneNumber));
    }
  }
}
