<form [formGroup]="form" (ngSubmit)="submitEmail(form.controls['selectEmail'].value)">
  <div class="row">
    <div class="column twelve md-eight">
      <ds-form-control-container
        [labelText]="'Select Email Address'"
        [control]="form.controls['selectEmail']"
      >
        <select class="icon fill" formControlName="selectEmail">
          <option value=""></option>
          @for (email of maskedEmails; track email) {
            <option [value]="email">{{ email }}</option>
          }
        </select>
        <span class="icon-chevron-down"></span>
        <ds-form-control-message [control]="form.controls['selectEmail']"></ds-form-control-message>
      </ds-form-control-container>
    </div>
    <div class="column twelve md-four">
      <div class="md-pad-left-10 pad-top-10 pad-bottom-10">
        <button
          data-cy="emailVerifyLinkContinueButton"
          ds-button
          content
          [contentPath]="'shared.nextBtn'"
          type="submit"
          [disabled]="!form.valid"
        ></button>
      </div>
    </div>
  </div>
</form>
