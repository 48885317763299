import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CustomerFeedbackEnum, OpportunityButtonAction } from '../../models/feedback';
import { OpportunitiesCtaInterface } from './opportunities-cta.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ds-opportunities-cta',
  templateUrl: './opportunities-cta.component.html',
  styleUrls: ['./opportunities-cta.component.scss']
})
export class DsOpportunitiesCtaComponent {
  @Input() ctaData: OpportunitiesCtaInterface;
  @Input() showContactMe: boolean;
  @Input() showCTA?: boolean;
  @Output() ctaClickEvent = new EventEmitter<string>();
  opportunityButtonAction = OpportunityButtonAction;
  customerFeedbackEnum = CustomerFeedbackEnum;
  ctaClicked(buttonText: string) {
    this.ctaClickEvent.emit(buttonText);
  }
}
