import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProfileEffects } from './+state/profile.effects';
import * as fromProfile from './+state/profile.reducer';
import { AlternateAuthService } from './services/alternate-auth.service';
import { DigitalServiceProgramService } from './services/digital-service-program.service';
import { PartyService } from './services/party.service';
import { ProfileUtilService } from './services/profile-util.service';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ProfileEffects]),
    StoreModule.forFeature(fromProfile.PROFILE_FEATURE_KEY, fromProfile.reducer)
  ],
  providers: [PartyService, DigitalServiceProgramService, ProfileUtilService, AlternateAuthService]
})
export class ProfileDataAccessModule {}
