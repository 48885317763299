import { createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

import { ClaimServicesState, getClaimServicesState } from '..';
import {
  ClaimServicesStatus,
  EligibleServicesConstants,
  ServiceRequestDisclaimer
} from './eligible-services.models';
import { EligibleServicesState } from './eligible-services.reducer';

export const getEligibleServicesState = createSelector(
  getClaimServicesState,
  (state: ClaimServicesState) => state.eligibleServices
);

export const getEligibleServicesError = createSelector(
  getEligibleServicesState,
  (state: EligibleServicesState) => state.hasError
);

export const getEligibleServices = createSelector(
  getEligibleServicesState,
  (state: EligibleServicesState) => state.eligibleServices
);

export const getEligibleServicesLoaded = createSelector(
  getEligibleServicesState,
  (state: EligibleServicesState) => state.loaded
);

export const getEligibleServicesLoading = createSelector(
  getEligibleServicesState,
  (state: EligibleServicesState) => state.loading
);

export const hasUploadedPhotos = createSelector(
  getEligibleServicesState,
  (state: EligibleServicesState) => {
    return _get(state, 'numberOfCustomerPhotos', 0) > 0;
  }
);

export const isRepairEligible = createSelector(getEligibleServices, eligibleServices => {
  return eligibleServices.some(
    serviceType => serviceType.serviceCode === EligibleServicesConstants.DRP
  );
});

export const getEligibleRepairService = createSelector(getEligibleServices, eligibleServices => {
  return eligibleServices.find(
    serviceType => serviceType.serviceCode === EligibleServicesConstants.DRP
  );
});

export const getEligibleRentalService = createSelector(getEligibleServices, eligibleServices => {
  return eligibleServices.find(
    serviceType => serviceType.serviceCode === EligibleServicesConstants.RENTAL
  );
});

export const getEligibleDrpServiceDisclaimers = createSelector(
  getEligibleRepairService,
  eligibleService => {
    const empty: ServiceRequestDisclaimer[] = [];
    return _get(eligibleService, 'serviceRequestDisclaimer', empty);
  }
);

export const getEligibleRentalServiceDisclaimers = createSelector(
  getEligibleRentalService,
  eligibleService => {
    const empty: ServiceRequestDisclaimer[] = [];
    return _get(eligibleService, 'serviceRequestDisclaimer', empty);
  }
);

export const isRepairSelected = createSelector(getEligibleRepairService, eligibleService => {
  return _get(eligibleService, 'serviceOpen', false);
});

export const hasRentalCoverage = createSelector(getEligibleServices, eligibleServices => {
  return eligibleServices.some(
    serviceType =>
      serviceType.serviceCode === EligibleServicesConstants.RENTAL &&
      serviceType.coverageCode !== '(No Coverage)'
  );
});

export const getRentalCoverageAmount = createSelector(getEligibleServices, eligibleServices => {
  const coverageAmount = _get(
    eligibleServices.find(
      serviceType => serviceType.serviceCode === EligibleServicesConstants.RENTAL
    ),
    'coverageDescription',
    ''
  ).split('/')[0];

  return coverageAmount === '(No Coverage)' ? '' : coverageAmount;
});

export const isRentalEligible = createSelector(getEligibleServices, eligibleServices => {
  return eligibleServices.some(
    serviceType =>
      serviceType.serviceCode === EligibleServicesConstants.RENTAL &&
      serviceType.serviceDependenciesSatisfied === true &&
      serviceType.serviceCustomerCallInMsgCode === ''
  );
});

export const hasRentalDependency = createSelector(getEligibleServices, eligibleServices => {
  return eligibleServices.some(
    serviceType =>
      serviceType.serviceCode === EligibleServicesConstants.RENTAL &&
      serviceType.serviceDependenciesSatisfied === false &&
      serviceType.serviceCustomerCallInMsgCode === ''
  );
});

export const isRentalSelected = createSelector(getEligibleServices, eligibleServices => {
  return eligibleServices.some(
    serviceType =>
      serviceType.serviceCode === EligibleServicesConstants.RENTAL && serviceType.serviceOpen
  );
});

export const getClaimServicesStatus = createSelector(
  isRepairEligible,
  isRepairSelected,
  hasRentalCoverage,
  isRentalEligible,
  hasRentalDependency,
  isRentalSelected,
  hasUploadedPhotos,
  (
    repairEligible,
    repairSelected,
    rentalCoverage,
    rentalEligible,
    rentalDependencyUnmet,
    rentalSelected,
    photosUploaded
  ) => {
    const claimServicesStatus: ClaimServicesStatus = {
      repairEligible: repairEligible,
      repairSelected: repairSelected,
      rentalCoverage: rentalCoverage,
      rentalEligible: rentalEligible,
      rentalDependencyUnmet: rentalDependencyUnmet,
      rentalSelected: rentalSelected,
      photosUploaded: photosUploaded
    };
    return claimServicesStatus;
  }
);

export const eligibleServicesQuery = {
  getEligibleServicesState,
  getEligibleServicesError,
  getEligibleServices,
  getEligibleServicesLoaded,
  getEligibleServicesLoading,
  hasUploadedPhotos,
  isRepairEligible,
  getEligibleRepairService,
  getEligibleRentalService,
  getEligibleDrpServiceDisclaimers,
  getEligibleRentalServiceDisclaimers,
  isRepairSelected,
  hasRentalCoverage,
  getRentalCoverageAmount,
  isRentalEligible,
  hasRentalDependency,
  isRentalSelected,
  getClaimServicesStatus
};
