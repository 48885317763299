<form
  [formGroup]="passwordForm"
  (ngSubmit)="passwordForm.valid && submitPasswordForm()"
  autocomplete="off"
>
  <ds-form-control-container
    [labelTextContentPath]="'profile.acceptPassword.fieldLabel'"
    [control]="passwordForm.controls['passwordInput']"
  >
    <ds-show-hide>
      <input
        type="password"
        id="password"
        data-cy="editPasswordInput"
        show-hide-input
        class="fill pad-right-60"
        formControlName="passwordInput"
        maxlength="15"
        autocomplete="off"
      />
    </ds-show-hide>
    @if (errorText) {
      <small class="block caption error margin-top-5">{{ errorText }}</small>
    }
    <div class="pad-top-10">
      <div class="column twelve md-six">
        <ul class="criteria-list">
          @if (hasComponentUsernameInput) {
            <li
              data-cy="passwordInputContainsUserID"
              [ngClass]="passwordForm.hasError('passwordContainsUserID') ? 'hasError' : 'isValid'"
              class="pseudo-icon"
              content
              [contentPath]="'profile.acceptPassword.conditions.userId'"
            ></li>
          }
          <li
            [ngClass]="passwordInput.hasError('invalidLength') ? 'hasError' : 'isValid'"
            data-cy="passwordInputValidationLength"
            class="pseudo-icon"
            content
            [contentPath]="'profile.acceptPassword.conditions.charLimit'"
          ></li>
          <li
            [ngClass]="passwordInput.hasError('atLeastOneLetterError') ? 'hasError' : 'isValid'"
            data-cy="passwordInputValidationLetter"
            class="pseudo-icon"
            content
            [contentPath]="'profile.acceptPassword.conditions.oneLetter'"
          ></li>
        </ul>
      </div>
      <div class="column twelve md-six">
        <ul class="criteria-list">
          <li
            [ngClass]="passwordInput.hasError('atLeastOneNumberError') ? 'hasError' : 'isValid'"
            data-cy="passwordInputValidationNumber"
            class="pseudo-icon"
            content
            [contentPath]="'profile.acceptPassword.conditions.oneNumber'"
          ></li>
          <li
            [ngClass]="passwordInput.hasError('invalidPasswordChars') ? 'hasError' : 'isValid'"
            data-cy="passwordInputValidationSpecialCharacter"
            class="pseudo-icon"
            content
            [contentPath]="'profile.acceptPassword.conditions.specialChars'"
          ></li>
        </ul>
      </div>
    </div>
  </ds-form-control-container>

  <button
    ds-button
    type="submit"
    data-cy="confirmPasswordChangeButton"
    class="float-right"
    [ngClass]="{ 'margin-top-10': !hasComponentUsernameInput }"
    [disabled]="!passwordForm.valid"
  >
    {{ confirmBtnText || defaultConfirmBtnCopy }}
  </button>
</form>
