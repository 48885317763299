import { Action } from '@ngrx/store';

import { CreateRentalRequestPayload } from './rental-service.models';

export enum RentalServiceActionTypes {
  CreateRentalRequest = '[Claim Services] Create Rental Request',
  CreateRentalRequestSuccess = '[Claim Services] Create Rental Request Success',
  CreateRentalRequestFail = '[Claim Services] Create Rental Request Fail',
  ResetRentalService = '[Claim Services] Reset Rental Service'
}

export class CreateRentalRequest implements Action {
  readonly type = RentalServiceActionTypes.CreateRentalRequest;
  constructor(public payload: CreateRentalRequestPayload) {}
}

export class CreateRentalRequestSuccess implements Action {
  readonly type = RentalServiceActionTypes.CreateRentalRequestSuccess;
  constructor() {}
}

export class CreateRentalRequestFail implements Action {
  readonly type = RentalServiceActionTypes.CreateRentalRequestFail;
  constructor() {}
}

export class ResetRentalService implements Action {
  readonly type = RentalServiceActionTypes.ResetRentalService;
  constructor() {}
}

export type RentalServiceAction =
  | CreateRentalRequest
  | CreateRentalRequestSuccess
  | CreateRentalRequestFail
  | ResetRentalService;

export const fromRentalServiceActions = {
  CreateRentalRequest,
  CreateRentalRequestSuccess,
  CreateRentalRequestFail,
  ResetRentalService
};
