<li [class.open]="isOpen" #scrollTarget [class.thinner-border]="htmlTitle">
  <button
    type="button"
    [class.pseudo-icon]="showIcon && !titleIcon"
    [class.psudo-icon.new]="showIcon"
    class="category-toggle flex pad-left-0"
    [class.closed]="!isOpen"
    [class.outline]="showOutline"
    [ngClass]="{ 'button-gradient-text': htmlTitle, 'button-padding': htmlTitle, pad: !htmlTitle }"
    (click)="handleToggleClick()"
    (keyup.tab)="enableOutline()"
    (mousedown)="disableOutline()"
    (mouseup)="disableOutline()"
    (blur)="disableOutline()"
    [attr.aria-expanded]="isOpen"
    [attr.aria-controls]="uniqueContentId"
    [attr.id]="uniqueLabelId"
    [attr.aria-label]="title"
  >
    <div
      class="heading-wrapper"
      role="region"
      [attr.id]="uniqueContentId"
      [attr.aria-labelledby]="uniqueLabelId"
      [class.new-template-padding]="htmlTitle"
      [ngClass]="{ 'new-heading-wrapper': titleIcon }"
    >
      @if (titleIcon) {
        <img [src]="titleIconUrl" class="title-icon" />
      }
      @if (icon) {
        <span class="icon-{{ icon }}"></span>
      }
      <span [innerHTML]="title" class="heading-sub-page"></span>
      @if (htmlTitle) {
        <div>
          <ng-content select="[title]"></ng-content>
        </div>
      }
    </div>

    @if (showNewBadge) {
      <amfam-badge class="badge-accordion" [labelName]="'New'"></amfam-badge>
    }
    @if (titleIcon) {
      <div class="right-icon">
        <img src="/assets/images/chevron-right-thick.svg" alt="right chevron" />
      </div>
    }
  </button>
  <div
    class="content"
    [class.new-template-padding]="htmlTitle"
    [@openClose]="isOpen"
    (@openClose.done)="openCloseDone($event)"
  >
    <ng-content></ng-content>
  </div>
</li>
