@if (options?.length > 0) {
  <div class="column twelve" [formGroup]="parentFormGroup">
    @for (option of options; track option) {
      <div>
        <div
          class="question"
          [ngClass]="{
            disabled: setDisableState || disableRadioControl,
            selected: unselectAll
              ? false
              : option.id === parentFormGroup.controls[control].value?.id
          }"
          (click)="toggleDynamicOption(option)"
        >
          <div class="flex row items-center">
            <input
              [formControlName]="control"
              [value]="option"
              id="{{ option.id }}"
              class="margin-right-6 question-input"
              type="radio"
              [attr.data-cy]="option?.dataCy"
              [checked]="
                option.id === selectedAnswer ||
                option.id === parentFormGroup.controls[control].value?.id
              "
            />
            @if (option.icon) {
              <img src="/assets/images/{{ option.icon }}.svg" width="72px" height="48px" />
            }
            <label for="{{ option.id }}" data-cy="" [class]="option.icon ? 'labelMargin' : ''"
              >{{ option.text }}
            </label>
          </div>
          @if (option.helpText && option.helpText.length > 0) {
            @for (helpText of option.helpText; track helpText) {
              <div class="flex row caption" [ngClass]="{ warning: helpText.warning }">
                {{ helpText.text }}
              </div>
            }
          }
        </div>
      </div>
    }
  </div>
}
