import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { format, isAfter, isBefore } from 'date-fns';

import { MonthRange, Payment } from '@amfam/shared/models';

import { PaymentHistoryActions, PaymentHistoryActionTypes } from './payment-history.actions';

export const PAYMENT_HISTORY_FEATURE_KEY = 'paymentHistory';
export interface PaymentHistoryState extends EntityState<Payment> {
  monthRange: MonthRange;
  selectedEntityId: string;
  loaded: boolean;
  loading: boolean;
  hasError: boolean;
  error: string;
}

export const adapter: EntityAdapter<Payment> = createEntityAdapter<Payment>({
  selectId: (payment: Payment) => payment.paymentId,
  sortComparer: sortByPaymentRecievedDate
});

function sortByPaymentRecievedDate(a: Payment, b: Payment): number {
  const dateA = format(a.receivedDate, 'MM/DD/YYYY');
  const dateB = format(b.receivedDate, 'MM/DD/YYYY');
  return isBefore(dateA, dateB) ? 1 : isAfter(dateA, dateB) ? -1 : 0;
}

export const initialState: PaymentHistoryState = adapter.getInitialState({
  monthRange: null,
  selectedEntityId: null,
  loaded: false,
  loading: false,
  hasError: false,
  error: null
});

// This reduces a set of payment history
export function paymentHistoryReducer(
  state = initialState,
  action: PaymentHistoryActions
): PaymentHistoryState {
  switch (action.type) {
    case PaymentHistoryActionTypes.GetPaymentsType:
      return Object.assign({}, state, {
        monthRange: action.payload.monthRange,
        loading: true,
        loaded: false,
        hasError: false
      });

    case PaymentHistoryActionTypes.GetPaymentsSuccessType: {
      const newState = Object.assign({}, state, {
        loading: false,
        loaded: true,
        hasError: false
      });
      return adapter.setAll(action.payload, newState);
    }

    case PaymentHistoryActionTypes.GetPaymentsFailType:
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        hasError: true
      });

    default:
      return state;
  }
}

export const getSelectedEntityId = (state: PaymentHistoryState) => state.selectedEntityId;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
export const selectEntityIds = selectIds;
export const selectPaymentEntities = selectEntities;
export const selectAllPayments = selectAll;
