import { createSelector } from '@ngrx/store';

import {
  BillAccountsSelectors,
  BillAccountsSelectors as fromBillAccounts
} from '@amfam/billing/billaccount/data-access';
import { paperlessOverviewEnrollmentFeatureQuery } from '@amfam/overview/paperless-enrollment/feature';
import { PolicySelectors as fromPolicies, PolicySummarySelectors } from '@amfam/policy/data-access';
import { EdeliverySelectors as fromEdeliverySelectors } from '@amfam/policy/e-delivery/data-access';
import {
  GoPaperLessSelectors as fromGoPaperless,
  GoPaperLessSelectors
} from '@amfam/policy/go-paperless/data-access';
import { DeliveryPreferences } from '@amfam/shared/models';
import { BrandSelectors } from '@amfam/shared/utility/brand';

import { paperlessOnboardingEnrollmentFeatureQuery } from './onboarding-paperless-enrollment-feature-selectors';

export const selectBillingEligibleToGoPaperless = createSelector(
  fromBillAccounts.selectBillAccounts,
  billAccounts => {
    const isBillingPaper = billAccounts.some(billAccount =>
      billAccount.billingPreferences?.preferences?.some(
        preference =>
          preference.isModifiable &&
          preference.preferenceDeliveryCode.toLowerCase() !== DeliveryPreferences.EMAIL
      )
    );
    return isBillingPaper;
  }
);

export const selectIsEligibleToGoPaperless = createSelector(
  selectBillingEligibleToGoPaperless,
  fromGoPaperless.selectIsEnrolledGoPaperless,
  fromPolicies.selectPolicyRisks,
  fromEdeliverySelectors.getEdeliveryTermsAndConditionsHasError,
  fromPolicies.selectIsEligibleForPaperless,
  fromBillAccounts.selectIsEligibleToViewPreferences,
  (
    isBillingPaper,
    isPolicyPaperless,
    risks,
    eDeliveryTermsAndConditionsError,
    nonLifePolicies,
    noPreferencesError
  ) => {
    const isPolicyPaper = !isPolicyPaperless;

    return (
      risks.length > 0 &&
      !eDeliveryTermsAndConditionsError &&
      (isBillingPaper || isPolicyPaper) &&
      nonLifePolicies &&
      noPreferencesError
    );
  }
);

export const selectHomesiteServiceUrl = createSelector(
  PolicySummarySelectors.selectHomesitePolicies,
  BrandSelectors.selectPropertyServiceUrl,
  (policies, url) => {
    if (url.indexOf('?') > 0 && policies.length) {
      return `${url}&policyNumber=${policies[0].policyNumber}`;
    }
    return url;
  }
);

export const selectPaperlessBillPolicy = (isOverviewFlow: boolean) =>
  createSelector(
    BillAccountsSelectors.selectBillAccountsNotifications,
    GoPaperLessSelectors.selectIsEnrolledGoPaperless,
    GoPaperLessSelectors.selectCorrelationId,
    GoPaperLessSelectors.selectGoPaperlessError,
    PolicySummarySelectors.selectHomesitePolicies,
    BillAccountsSelectors.selectBillAccounts,
    paperlessOverviewEnrollmentFeatureQuery.selectCorrelationId,
    paperlessOnboardingEnrollmentFeatureQuery.selectCorrelationId,
    (
      billAccountsNotifications,
      isPolicyEnrolled,
      policyCorrelationId,
      policyHasErrors,
      homesitePolicies,
      billAccounts,
      overviewCorrelationId,
      onboardingCorrelationId
    ) => {
      const correlationId = isOverviewFlow ? overviewCorrelationId : onboardingCorrelationId;
      const filteredNotifications = billAccountsNotifications.filter(
        ban => ban.updatePreferenceCorrelationId === correlationId
      );
      return {
        billAccountsNotifications: filteredNotifications,
        isPolicyEnrolled,
        policyCorrelationId,
        policyHasErrors,
        homesitePolicies,
        billAccounts,
        correlationId
      };
    }
  );
