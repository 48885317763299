<ds-app-banner *featureEnable="'mobile_app_banner'"></ds-app-banner>
<div role="main" id="app-wrapper" [class.login-gradient]="isForgeRockEnabled$ | async">
  <app-loading-spinner></app-loading-spinner>
  <div class="main" [class.login-background]="isForgeRockEnabled$ | async">
    <div class="row">
      <div class="column twelve">
        <alerts-bar *ngIf="showBanner | async"></alerts-bar>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<footer
  [chatEnabled]="chatEnabled$ | async"
  [partnerFooterLogo]="partnerFooterLogo$ | async"
  [lastLogin]="lastLogin$ | async"
></footer>
