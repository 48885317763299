import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { fromUserActions, userQuery } from '@amfam/shared/user';

import { DigitalAccountService } from '../../services/digital-account.service';
import {
  AuthenticatePayload,
  AuthenticateSuccessResponse,
  DigitalAccount,
  DigitalAccountError
} from '../digital-account/digital-account.model';
import {
  Authenticate,
  AuthenticateFailure,
  AuthenticateSuccess,
  CheckEmailAddress,
  CheckEmailAddressFailure,
  CheckEmailAddressSuccess,
  CreateDigitalAccount,
  CreateDigitalAccountFailure,
  CreateDigitalAccountSuccess,
  DigitalAccountActions,
  DigitalAccountActionTypes,
  GetDigitalAccountByCdhId,
  GetDigitalAccountByCdhIdFailure,
  GetDigitalAccountByCdhIdSuccess,
  GetDigitalAccountByWaid,
  GetDigitalAccountByWaidFailure,
  GetDigitalAccountByWaidSuccess,
  Refresh,
  RefreshFailure,
  RefreshSuccess,
  UpdateDigitalAccount,
  UpdateDigitalAccountFailure,
  UpdateDigitalAccountSuccess
} from './digital-account.actions';

@Injectable()
export class DigitalAccountEffects {
  createDigitalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DigitalAccountActionTypes.CreateDigitalAccount),
      map((action: CreateDigitalAccount) => action.payload),
      switchMap(payload =>
        this.digitalAccountService.createDigitalAccount(payload).pipe(
          map(() => new CreateDigitalAccountSuccess()),
          catchError(() => of(new CreateDigitalAccountFailure(DigitalAccountError.SERVER_ERROR)))
        )
      )
    )
  );

  getDigitalAccountByWaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DigitalAccountActionTypes.GetDigitalAccountByWaid),
      map((action: GetDigitalAccountByWaid) => action.payload.waid),
      switchMap(waid =>
        this.digitalAccountService.getDigitalAccountByWaid(waid).pipe(
          mergeMap((digitalAccount: DigitalAccount) => {
            if (digitalAccount.shellAccount) {
              const sA = digitalAccount.shellAccount as any;
              return [
                new fromUserActions.UpdateUserEmail(sA.emailAddress),
                new GetDigitalAccountByWaidSuccess(digitalAccount)
              ];
            }
            return [new GetDigitalAccountByWaidSuccess(digitalAccount)];
          }),
          catchError(error => of(new GetDigitalAccountByWaidFailure({ error })))
        )
      )
    )
  );

  getDigitalAccountByCdhId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetDigitalAccountByCdhId),
      map(action => action.payload?.cdhId),
      withLatestFrom(this.store.select(userQuery.getCustomerId)),
      switchMap(([cdhId, id]) =>
        this.digitalAccountService.getDigitalAccountByCdhId(cdhId ? cdhId : id).pipe(
          map(
            (digitalAccount: DigitalAccount) => new GetDigitalAccountByCdhIdSuccess(digitalAccount)
          ),
          catchError(error => of(new GetDigitalAccountByCdhIdFailure({ error })))
        )
      )
    );
  });

  updateDigitalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DigitalAccountActionTypes.UpdateDigitalAccount),
      map((action: UpdateDigitalAccount) => action.payload),
      switchMap(payload =>
        this.digitalAccountService
          .updateDigitalAccount(
            payload.digitalAccount,
            payload.waid,
            payload.partnerId,
            payload.experienceId
          )
          .pipe(
            // TODO what is this returning
            map(() => new UpdateDigitalAccountSuccess()),
            catchError(error => of(new UpdateDigitalAccountFailure({ error })))
          )
      )
    )
  );

  authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DigitalAccountActionTypes.Authenticate),
      map((action: Authenticate) => action.payload),
      switchMap((payload: AuthenticatePayload) =>
        this.digitalAccountService.authenticate(payload).pipe(
          map(
            (response: AuthenticateSuccessResponse) =>
              new AuthenticateSuccess({ response, correlationId: payload.correlationId })
          ),
          catchError(() =>
            of(
              new AuthenticateFailure({
                error: DigitalAccountError.SERVER_ERROR,
                correlationId: payload.correlationId
              })
            )
          )
        )
      )
    )
  );

  getRefresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Refresh>(DigitalAccountActionTypes.Refresh),
      switchMap(() =>
        this.digitalAccountService.refresh().pipe(
          map(response => new RefreshSuccess(response)),
          catchError(error => of(new RefreshFailure({ error })))
        )
      )
    )
  );

  checkEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CheckEmailAddress>(DigitalAccountActionTypes.CheckEmailAddress),
      map(action => action.payload),
      switchMap(payload =>
        this.digitalAccountService.checkEmailAddress(payload).pipe(
          map(resp => new CheckEmailAddressSuccess(resp)),

          catchError(error => of(new CheckEmailAddressFailure({ error })))
        )
      )
    )
  );
  constructor(
    private actions$: Actions<DigitalAccountActions>,
    private digitalAccountService: DigitalAccountService,
    private store: Store
  ) {}
}
