import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { get as _get } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import { CopyService } from '@amfam/shared/utility/shared-services';

import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'ds-userid-availability',
  templateUrl: './userid-availability.component.html',
  styleUrls: ['./userid-availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UseridAvailabilityComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Output() userIdAvailabilityEvent = new EventEmitter<boolean>();

  private stop$: Subject<void> = new Subject<void>();

  private componentName: any = {};
  private componentData: any;
  userId: AbstractControl;
  userIdAvailable: boolean;
  userIdUnavailable: boolean;
  message: string;

  constructor(
    private authService: AuthService,
    private copyService: CopyService
  ) {}

  ngOnInit() {
    this.componentName.enrollment = 'enrollment';
    this.componentData = this.copyService.getComponentData(this.componentName.enrollment);
    this.userId = this.form.controls['userId'];
    if (this.userId.value) {
      this.setAvailabilityTrue();
    } else {
      this.resetAvailability();
    }
    this.checkAvailability();
  }

  checkAvailability() {
    this.userId.valueChanges.pipe(debounceTime(400), takeUntil(this.stop$)).subscribe(res => {
      // Return if form validations aren't met
      if (!this.userId.valid) {
        this.resetAvailability();
        this.userIdAvailabilityEvent.emit(false);
        return;
      }

      this.authService
        .checkUsernameAvailability(this.userId.value)
        .pipe(take(1))
        .subscribe(
          data => {
            const statusCode = _get(data, 'status.code');

            if (statusCode === 404) {
              // userId is available
              this.userIdAvailabilityEvent.emit(true);
              this.userIdAvailable = true;
              this.userIdUnavailable = false;
              this.message = this.componentData['userIdAvailable'];
            } else {
              // userId is NOT available
              this.userIdAvailabilityEvent.emit(false);
              this.userIdAvailable = false;
              this.userIdUnavailable = true;
              this.message = this.componentData['available'];
            }
          },
          err => {
            this.userIdAvailable = false;
            this.userIdUnavailable = true;
            this.message = this.componentData['signUpUserIdCheckSystemErrorText'];
          }
        );
    });
  }

  private setAvailabilityTrue() {
    this.userIdAvailabilityEvent.emit(true);
    this.userIdAvailable = true;
    this.userIdUnavailable = false;
    this.message = this.componentData['available'];
  }

  private resetAvailability() {
    this.userIdAvailable = false;
    this.userIdUnavailable = false;
    this.message = this.componentData['available'];
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
