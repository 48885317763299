<form [formGroup]="codeForm" (ngSubmit)="codeForm.valid && verifyCode()">
  <div class="row">
    <div class="column twelve md-six">
      <ds-form-control-container
        [labelText]="'Confirmation Code'"
        [control]="codeForm.controls['codeInput']"
      >
        <input
          data-cy="enrollmentCodeConfirmationInput"
          type="tel"
          class="fill"
          dsDigitMask
          id="pincode"
          formControlName="codeInput"
          maxlength="5"
        />
        <ds-form-control-message
          data-cy="enrollmentCodeConfirmationErrorText"
          [control]="codeForm.controls['codeInput']"
        ></ds-form-control-message>
      </ds-form-control-container>
    </div>
    <div class="column twelve md-four">
      <div class="md-pad-left-10 pad-top-10">
        <button
          data-cy="enrollmentCodeConfirmationContinueButton"
          ds-button
          type="submit"
          [disabled]="!codeForm.valid"
          content
          [contentPath]="'shared.nextBtn'"
        ></button>
      </div>
    </div>
  </div>
</form>
