import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ds-verify-email',
  templateUrl: './verify-email.component.html'
})
export class VerifyEmailComponent implements OnInit {
  @Input() maskedEmails: string[];
  @Output() submittedEmailEvent = new EventEmitter();

  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      selectEmail: ['', Validators.compose([Validators.required])]
    });
    this.setEmailField();
  }

  setEmailField() {
    if (this.maskedEmails.length === 1) {
      const singleEmail = JSON.stringify(this.maskedEmails[0]);
      this.form.controls['selectEmail'].setValue(JSON.parse(singleEmail));
    }
  }

  // Emits selected email address to parent component
  submitEmail(email: string) {
    this.submittedEmailEvent.emit(email);
  }
}
