<div class="simple-card" [attr.id]="id">
  <div class="simple-card__header">
    <img [src]="'/assets/images/' + headerIcon" alt="{{ heading }}" />
    <span class="title">{{ heading }}</span>
  </div>
  <div class="simple-card__body">
    <ng-content></ng-content>
  </div>
  @if (showFooter) {
    <div class="simple-card__footer">
      @for (button of buttons; track button) {
        <a
          (click)="button.clickEvent()"
          class="capitalize action__button"
          [class.icon-gradient]="button.iconClass"
          [ngClass]="button.iconClass || ''"
        >
          @if (button.iconUrl) {
            <img [src]="'/assets/images/' + button.iconUrl" alt="{{ heading }}" />
          }
          {{ button.name }}
        </a>
      }
    </div>
  }
</div>
