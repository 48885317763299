import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PolicyAnalytics } from '@amfam/policy/models';
import { AnalyticsActions, AnalyticsFacade } from '@amfam/shared/analytics';
import { PcmAnalytics } from '@amfam/shared/models';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import {
  Applications,
  ApplicationService,
  PartnerTermsData
} from '@amfam/shared/utility/shared-services';
import { DsModalService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-policy-terms-and-conditions',
  templateUrl: './policy-terms-and-conditions.component.html',
  styleUrls: ['./policy-terms-and-conditions.component.scss']
})
export class PolicyTermsAndConditionsComponent implements OnInit, OnChanges {
  public static readonly policyTermsConditionModalID = 'PolicyTermsConditionModal';

  @Input()
  public fromProfile = false;
  @Input()
  public showPolicyTerms = true;
  @Input()
  public showPaperless = true;
  @Input()
  public showBillingTerms = true;
  @Output()
  public visibleChange = new EventEmitter<boolean>();
  @Output()
  public agreeTermsAndConditionsEvent = new EventEmitter<boolean>();

  public policyTermsConditionModal: string;
  public modalWidth = 596;
  public policyTermsConditionContent: Observable<string>;
  public termsPartnerData$: Observable<PartnerTermsData> = this.store.select(
    BrandSelectors.selectPartnerTermsData
  );

  public isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);

  private _visible = false;

  constructor(
    private modalService: DsModalService,
    private store: Store,
    private analyticsFacade: AnalyticsFacade,
    private appService: ApplicationService
  ) {}

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(this._visible);
  }

  ngOnInit() {
    if (this.showPaperless) {
      this.showBillingTerms = false;
      this.showPolicyTerms = false;
    } else {
      this.policyTermsConditionModal = this.modalService.createModalId(
        PolicyTermsAndConditionsComponent.policyTermsConditionModalID
      );
    }
  }

  ngOnChanges(): void {
    if (this.visible) {
      this.openModal();
    } else {
      this.closeModal();
    }
  }

  openModal(): void {
    this.modalService.open(this.policyTermsConditionModal);
    /**
     * This is shared modal between ez button and pcm preferences.
     * Analytics are not yet finalized for pcm. So, send analytics when this modal is utilized by ez button component.
     */
    if (!this.fromProfile) {
      this.analyticsFacade.trackPage(PolicyAnalytics.pageTermsAndConditionsGoPaperless);
    }
  }

  closeModal(event?: boolean): void {
    if (event && this.isAdminApp && this.fromProfile) {
      this.store.dispatch(
        AnalyticsActions.sendAnalytic({
          options: {
            button: PcmAnalytics.closeDiscountModal
          }
        })
      );
    }

    this.modalService.close(this.policyTermsConditionModal);
    this.visible = false;
  }

  agreeTermsAndConditions(isAgreeing: boolean): void {
    if (isAgreeing && this.isAdminApp && this.fromProfile) {
      this.store.dispatch(
        AnalyticsActions.sendAnalytic({
          options: {
            button: PcmAnalytics.discountModalSave
          }
        })
      );
    }
    this.modalService.close(this.policyTermsConditionModal);
    this.visible = false;
    this.agreeTermsAndConditionsEvent.emit(isAgreeing);
  }
}
