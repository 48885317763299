import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import {
  cloneDeep as _cloneDeep,
  divide as _divide,
  forEach as _forEach,
  forOwn as _forOwn,
  isArray as _isArray,
  isEmpty as _isEmpty,
  isNaN as _isNaN,
  isNull as _isNull,
  isObject as _isObject,
  isString as _isString,
  isUndefined as _isUndefined,
  pull as _pull,
  range as _range,
  round as _round
} from 'lodash';

// date-fns
import { PrettyPolicyNum } from '@amfam/policy/pipes';

import { WindowRef } from '../window/window.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private experienceId: string;

  constructor(private win: WindowRef) {}

  // Build a year array with long:YYYY and short:YY
  //
  //  @param {Number} range [] - Number of entries to create in the object
  //  @param {Number} startYear - The year to start iterating from
  //  @param {String} yearFormat ['YYYY'] - Format to return values in. 'YYYY' and 'YY' are valid.
  //  @param {Number} step [0] - The number to step the returned values by
  //
  //  controller setup:
  //  this.dateYears = this.utilService.getYearsObject(10);
  //  output would be: dateYears[ { long: 2017, short: 17 }, { long: 2018, short: 18 }, ... ]
  //
  //  template usage:
  //   <option *ngFor='let year of dateYears | toArray' [value]='year.short'>{{year.long}}</option>

  getYearsArray(range: number, startYear?: number, yearFormat = 'YYYY', step = 0) {
    if (startYear) {
      startYear = parseInt(format(new Date(), 'YYYY'), 10);
    }
    const yearsArray = [];
    const years = _range(step, range);

    // Build array of years
    _forEach(years, year => {
      yearsArray.push(startYear + year);
    });

    return yearsArray;
  }

  // Build a year array of objects with long:YYYY and short:YY
  // Use the toArray filter to use this in an *ngFor
  //
  //  @param {Number} range [] - Number of entries to create in the object
  //  @param {Number} startYear - The year to start iterating from
  //  @param {Number} step [0] - The number to step the returned values by
  //
  //  controller setup:
  //  this.dateYears = this.utilService.getYearsObject(10);
  //  output would be: dateYears[ { long: 2017, short: 17 }, { long: 2018, short: 18 }, ... ]
  //
  //  template usage:
  //   <option *ngFor='let year of dateYears | toArray' [value]='year.short'>{{year.long}}</option>
  //
  //  controller setup with options:
  //  this.dateYears = this.utilService.getYearsObject(10, 2020, 5);
  //  output would be: dateYears[ { long: 2020, short: 20 }, { long: 2025, short: 25 }, ... ]

  getYearsObject(range: number, startYear?: number, step = 0) {
    let startYearLong = parseInt(format(new Date(), 'YYYY'), 10); // use current year
    let startYearShort = parseInt(format(new Date(), 'YY'), 10); //
    if (startYear) {
      // Unless we were supplied a start year
      startYearLong = parseInt(format(new Date().setFullYear(startYear), 'YYYY'), 10); // then, use startYear
      startYearShort = parseInt(format(new Date().setFullYear(startYear), 'YY'), 10); //
    }

    const yearsObj = [];
    const years = _range(step, range); //

    _forEach(years, year => {
      yearsObj.push({ long: startYearLong + year, short: startYearShort + year });
    });

    return yearsObj;
  }

  getBaseUrl(): string {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  getAbsoluteUrl(relativePath: string, experienceId: string): string {
    if (relativePath.startsWith('/')) {
      relativePath = relativePath.slice(1);
    }

    if (experienceId && !relativePath.startsWith(experienceId)) {
      relativePath = experienceId + '/' + relativePath;
    }

    return this.getBaseUrl() + relativePath;
  }

  scrollToElementById(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      this.win.nativeWindow.scrollTo(0, element.offsetTop);
    }
  }

  scrollToTop() {
    this.win.nativeWindow.scrollTo(0, 0);
  }

  pruneEmpty(obj) {
    return (function prune(current) {
      _forOwn(current, function (value, key) {
        if (
          _isUndefined(value) ||
          _isNull(value) ||
          _isNaN(value) ||
          (_isString(value) && _isEmpty(value)) ||
          (_isObject(value) && _isEmpty(prune(value)))
        ) {
          delete current[key];
        }
      });
      // remove any leftover undefined values from the delete
      // operation on an array
      if (_isArray(current)) {
        _pull(current, undefined);
      }
      return current;
    })(_cloneDeep(obj)); // Do not modify the original object, create a clone instead
  }

  // TODO this library should be full of static pure functions, not injected as a service
  /*
    Used to generate a correlation Id for ngrx/store Request-Reply pattern.
    Request Reply Pattern Info: https://blog.nrwl.io/ngrx-patterns-and-techniques-f46126e2b1e5
    Synthetic GUID generator: http://guid.us/GUID/JavaScript
    TODO: @angular uses uuid, we could use that as well with no increase in bundle size.
    see: https://stackoverflow.com/questions/45754907/how-to-generate-uuid-with-angular-2
  */
  /* eslint-disable no-bitwise */
  generateId() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    const guid = (
      S4() +
      S4() +
      '-' +
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    ).toLowerCase();
    return guid;
  }

  /**
   * @author: Abhishek Singh
   * @description: This function would take in a string, and return the extracted policy number from the string.
   */
  extractPolicyNumber(policyNum: string): string {
    const policyLength = policyNum.length;

    if (policyLength < 10) {
      // Return first 8 characters
      return policyNum.substr(0, 8);
    } else if (policyLength === 10) {
      // Return unmodified string
      return policyNum;
    } else if (policyLength < 12) {
      // Return first 10 characters
      return policyNum.substr(0, 10);
    } else if (policyLength >= 12) {
      if (policyNum.startsWith('41')) {
        // If length is greater than 12 and starts with '41', return first 12 digits
        return policyNum.substr(0, 12);
      } else {
        // If length is greater than 12 and doesn't start with '41', return first 10 digits
        return policyNum.substr(0, 10);
      }
    } else {
      return '';
    }
  }

  public returnQuoteDollarOrCents(
    totalCost: number,
    termMonths: number,
    returnType: 'Dollar' | 'Cent'
  ): string {
    if (returnType === 'Dollar') {
      return String(_round(_divide(Number(totalCost.toFixed(2)), termMonths), 2)).split('.')[0];
    } else {
      return String(_divide(totalCost, termMonths).toFixed(2)).split('.')[1];
    }
  }

  maskPolicyNumber(policyNumber: string) {
    const partialPolicyNumber = new PrettyPolicyNum().transform(policyNumber).substring(5);
    return `xxxxx${partialPolicyNumber}`;
  }

  isIE11AndBelow() {
    return !!this.win.nativeWindow.navigator.userAgent.match(/MSIE|Trident/);
  }

  onlyAlphanumeric(input: string): string {
    if (!input) return '';
    return input.replace(/[^a-zA-Z0-9]/g, '');
  }
}
