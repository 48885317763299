import { Injectable } from '@angular/core';
import { get as _get, isString as _isString, template as _template } from 'lodash';

import { Applications, ApplicationService } from '../application';
import { ConfigService } from '../config/config.service';
import authCopy from './data/auth-copy.json';
import billingCopy from './data/billing-copy.json';
import claimsCopy from './data/claims-copy.json';
import contactAdjusterCopy from './data/contact-adjuster-copy.json';
import defaultQuickLinksCopy from './data/default-quick-links-copy.json';
import enrollmentCopy from './data/enrollment-copy.json';
import faqCopy from './data/faq-copy.json';
import firstPartyFnolCopy from './data/first-party-fnol-copy.json';
import goPaperlessCopy from './data/go-paperless-copy.json';
import insuranceCardCopy from './data/insurance-card-copy.json';
import kydCopy from './data/kyd-copy.json';
import kydSmartphoneCopy from './data/kyd-smartphone-copy.json';
import kydSmartphonePhhCopy from './data/kyd-smartphone-phh-copy.json';
import opportunityContentCopy from './data/opportunity-content-copy.json';
import opportunityCopy from './data/opportunity-copy.json';
import overviewCopy from './data/overview-copy.json';
import policyCopy from './data/policy-copy.json';
import policyDetailCopy from './data/policy-detail-copy.json';
import profileCopy from './data/profile-copy.json';
import sharedCopy from './data/shared-copy.json';
import thirdPartyFnolCopy from './data/third-party-fnol-copy.json';

@Injectable({
  providedIn: 'root'
})
export class CopyService {
  private copy = {
    auth: authCopy,
    billing: billingCopy,
    claims: claimsCopy,
    contactAdjuster: contactAdjusterCopy,
    defaultQuickLinks: defaultQuickLinksCopy,
    enrollment: enrollmentCopy,
    faq: faqCopy,
    firstPartyFnol: firstPartyFnolCopy,
    goPaperless: goPaperlessCopy,
    insuranceCard: insuranceCardCopy,
    kyd: kydCopy,
    kydSmartphone: kydSmartphoneCopy,
    kydSmartphonePhh: kydSmartphonePhhCopy,
    overview: overviewCopy,
    opportunity: opportunityCopy,
    opportunityContent: opportunityContentCopy,
    policy: policyCopy,
    policyDetail: policyDetailCopy,
    profile: profileCopy,
    shared: sharedCopy,
    thirdPartyFnol: thirdPartyFnolCopy
  };

  constructor(
    private config: ConfigService,
    private applicationService: ApplicationService
  ) {}

  /**
   * @returns {object} All of the copy for a module
   * @param {string} module The copy module
   */
  getComponentData(module): any {
    if (this.copy[module]) {
      return this.copy[module];
    }
    if (!this.config.get('production')) {
      console.warn('copy service: no match for getComponentData', '\nmodule: ', module);
    }
  }

  /**
   * @returns {string || object} The copy found at the path or an empty string
   * @param {string} copyPath The path to the copy,
   * @param {object} options The object with data to resolve the template embedded expressions
   */
  getCopy(copyPath: string, options?: any, applicationDependentCopy?: boolean): any {
    /**
     * AS: If there is a application name being passed, check if we are currently being called
     * by that application, if yes,   then append the application name to the path for specific copy
     * for that path.
     */
    if (
      applicationDependentCopy &&
      (<string[]>Object.values(Applications)).includes(this.applicationService.getAppName())
    ) {
      copyPath = copyPath.replace(
        '.',
        `.${(<string>this.applicationService.getAppName()).toLowerCase()}.`
      );
    }
    let copyItem = _get(this.copy, copyPath);
    if (!copyItem) {
      copyItem = '';
      if (!this.config.get('production')) {
        console.warn('copy service: no match for getCopy ', 'copyPath: ', copyPath);
      }
    }
    if (_isString(copyItem)) {
      /**
       * If we pass the options parameters to the function then we are expecting the copyItem string
       * to have embedded expressions which needs to be resolved ahead of time. Use lodash's _template function and
       * pass the copy as a parameter. This would return a compiledTemplate function which would take an
       * object with key/value pairs. All the embedded expressions should be matched with the keys in the object
       * being passed in to avoid any errors.
       */
      if (options) {
        const compiledTemplate = _template(copyItem);
        return compiledTemplate(options);
      }
      return '' + copyItem;
    }
    return copyItem;
  }

  /**
   *  *** DEPRECATED, use getCopy instead ***
   *
   *  TODO: refactor references of get to use getCopy
   */
  get(module, key, subModule?): any {
    let copyPath = module + '.' + key;
    if (subModule) {
      copyPath = module + '.' + key + '.' + subModule;
    }
    return this.getCopy(copyPath);
  }
}
