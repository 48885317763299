import { get as _get } from 'lodash';

import { GoPaperlessModel, initialState } from '../models/go-paperless.model';
import { GoPaperlessActions, GoPaperlessActionTypes } from './go-paperless.actions';

export function reducer(state = initialState, action: GoPaperlessActions): GoPaperlessModel {
  switch (action.type) {
    case GoPaperlessActionTypes.LoadGoPaperlessType:
      return Object.assign({}, state, {
        isEnrolled: action.payload === 'OPTED IN' ? true : false
      });

    case GoPaperlessActionTypes.EnrollGoPaperlessType:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        correlationId: null
      });

    case GoPaperlessActionTypes.EnrollGoPaperlessSuccessType:
      return Object.assign({}, state, {
        loading: false,
        hasError: false,
        status: _get(action, 'payload.status'),
        isEnrolled: true,
        correlationId: _get(action, 'payload.correlationId')
      });

    case GoPaperlessActionTypes.EnrollGoPaperlessFailureType:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: _get(action, 'payload.status'),
        isEnrolled: false
      });

    case GoPaperlessActionTypes.UnEnrollGoPaperlessType:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        correlationId: null
      });

    case GoPaperlessActionTypes.UnEnrollGoPaperlessSuccessType:
      return Object.assign({}, state, {
        loading: false,
        hasError: false,
        status: _get(action, 'payload.status'),
        correlationId: _get(action, 'payload.correlationId'),
        isEnrolled: false
      });

    case GoPaperlessActionTypes.UnEnrollGoPaperlessFailureType:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: _get(action, 'payload.status'),
        isEnrolled: true
      });

    case GoPaperlessActionTypes.GenerateGoPaperlessEmailType:
      return Object.assign({}, state, {
        hasError: false,
        operation: action.payload,
        correlationId: _get(action, 'payload.correlationId')
      });

    default:
      return state;
  }
}

export const getLoading = (state: GoPaperlessModel) => state.loading;

export const hasError = (state: GoPaperlessModel) => state.hasError;

export const getStatus = (state: GoPaperlessModel) => state.status;

export const isEnrolled = (state: GoPaperlessModel) => state.isEnrolled;

export const selectCorrelationId = (state: GoPaperlessModel) => state.correlationId;
