import { CustomerEnrollmentRequest } from '@amfam/mae/data-access';
import {
  CheckEmailAddressPayload,
  RefreshSuccessResponse
} from '@amfam/shared/digital-account/data-access';
import { userQuery, UserState } from '@amfam/shared/user';
import { ConfigService, CopyService } from '@amfam/shared/utility/shared-services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { omit as _omit } from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ANSRequest, EnrollmentRequest } from '../model/mae-feature.model';

@Injectable({ providedIn: 'root' })
export class MaeFeatureService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private copyService: CopyService,
    private store: Store<any>
  ) {}

  /**
   * This function supports only AFI Customers, no partner support, To support partners, we need exp_id
   * Need to revisit when we know how to support partners
   * @param user
   * @param producerId
   * @param email
   * @returns
   */
  getPayload(user: UserState, producerId: string, email: string): EnrollmentRequest {
    const customerEnrollmentRequest: CustomerEnrollmentRequest = {
      autoPayOptInIndicator: false,
      eDeliveryOptInIndicator: false,
      emailAddressSelected: email,
      expId: user.experienceId !== '' ? user.experienceId : '10001',
      firstName: user.firstName,
      handlerType: user.smImpersonatorUserID,
      handlerUserId: user.smImpersonatorUserID,
      lastName: user.lastName,
      onlineBillingOptInIndicator: false,
      partnerId: user.partnerId,
      producerId: producerId
    };
    const enrollmentRequest: EnrollmentRequest = {
      customerEnrollmentRequest,
      customerId: user.customerId
    };

    return enrollmentRequest;
  }

  getCheckEmailAddressPayload(
    email: string,
    partnerId: string,
    corrId: string
  ): CheckEmailAddressPayload {
    const checkEmailAddressPayload: CheckEmailAddressPayload = {
      emailAddress: email,
      partnerId: partnerId,
      correlationId: corrId
    };
    return checkEmailAddressPayload;
  }

  getRefreshResponse(): RefreshSuccessResponse {
    let refreshSuccessResponse: RefreshSuccessResponse;
    this.store
      .select(userQuery.getUserState)
      .pipe(take(1))
      .subscribe(data => {
        refreshSuccessResponse = {
          WAID: data.waid,
          businessName: data.businessName,
          customerId: data.customerId,
          emailAddress: data.emailAddress,
          partnerId: data.partnerId,
          experienceId: data.experienceId,
          firstName: data.firstName,
          lastName: data.lastName,
          greetingName: data.greetingName,
          displayName: data.displayName,
          sessionValidUntil: '',
          lastLoggedInOn: '',
          status: null,
          token: '',
          trackId: data.trackId,
          userId: data.loginName,
          typeOfAccountCode: data.typeOfAccountCode,
          smImpersonatorUserID: data.smImpersonatorUserID,
          smImpersonator: data.smImpersonator
        };
      });
    return refreshSuccessResponse;
  }

  /**
   * @author Abhishek Singh
   * @param ANSRequest
   * @returns Json response as part of the post call on the producer API
   * @description This function takes the model and sends it to the producer API which in turn posts the ANS notification
   */
  public ansNotification(model: ANSRequest): Observable<any> {
    const uri = this.config.get('producerApi') + 'producers/' + model.producerId + '/notifications';
    return this.http.post(uri, _omit(model, ['producerId']));
  }
}
