import {
  Policy,
  Address,
  Coverage,
  NamedInsured,
  PolicyTypeIconConstants,
  SourceSystem
} from '@amfam/policy/models';

// lodash
import { flatMap as _flatMap } from 'lodash';
import { AddressPipe } from '@amfam/policy/pipes';
export class PropertyPolicy extends Policy {
  public riskLocationAddress: Address;
  public propertyCoverages: Coverage[] = new Array<Coverage>();
  public dwellingRoles: NamedInsured[] = new Array<NamedInsured>();

  static fromJson(json: any): PropertyPolicy {
    const policy = new PropertyPolicy();
    policy.assignProperties(json);

    return policy;
  }

  protected assignProperties(json: any) {
    super.assignProperties(json);
    this.iconType = PolicyTypeIconConstants.HOME;
    this.policyType = 'property';

    if (json.dwelling.riskLocationAddress) {
      this.riskLocationAddress = Address.fromJson(json.dwelling.riskLocationAddress);
      this.policyRisks.push(this.riskLocationAddress);
    }

    if (json.dwelling.dwellingRoles) {
      for (const insured of json.dwelling.dwellingRoles) {
        this.dwellingRoles.push(NamedInsured.fromJson(insured));
      }
    }

    for (const coverage of json.dwelling.dwellingCoverages) {
      const coverageEntity = Coverage.fromJson(coverage, this.sourcePath !== SourceSystem.ROME);
      if (Coverage.isValid(coverageEntity)) {
        this.propertyCoverages.push(coverageEntity);
      }
    }

    this.riskDescriptionList = [new AddressPipe().transform(this.policyAddress, 'short')];
  }
}
