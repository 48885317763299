import { ButtonProps } from '@amfam/shared/models';
import { scrollAnimation } from '@amfam/shared/utility/shared-services';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-jump-link',
  templateUrl: './jump-link.component.html',
  styleUrls: ['./jump-link.component.scss'],
  animations: [scrollAnimation]
})
export class JumpLinkComponent {
  @Input() jumpLink: ButtonProps;
  @Input() size: 'small' | 'medium' | 'large';
}
