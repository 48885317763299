import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ValidationService } from '@amfam/ui-kit';

@Component({
  selector: 'accept-password-input',
  templateUrl: './accept-password-input.component.html',
  styleUrls: ['./accept-password-input.component.scss']
})
export class AcceptPasswordInputComponent implements OnInit {
  @Input()
  componentUsernameInput: string;

  @Input()
  errorText: string;

  @Input()
  confirmBtnText: string;

  @Output() onComplete = new EventEmitter();

  passwordForm: UntypedFormGroup;
  passwordInput: AbstractControl;
  usernameInput: AbstractControl;
  hasComponentUsernameInput = false;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.loadPasswordInputForm();
    if (this.componentUsernameInput && this.componentUsernameInput !== '') {
      this.hasComponentUsernameInput = true;
    }
  }

  loadPasswordInputForm() {
    this.passwordForm = this.formBuilder.group(
      {
        passwordInput: [
          '',
          Validators.compose([
            Validators.required,
            ValidationService.atLeastOneLetter,
            ValidationService.atLeastOneNumber,
            ValidationService.userNamePasswordLengthValidator,
            ValidationService.invalidPasswordChars
          ])
        ],
        usernameInput: [this.componentUsernameInput || '', Validators.compose([])]
      },
      {
        validator: this.componentUsernameInput
          ? ValidationService.passwordContainsUserID('usernameInput', 'passwordInput')
          : null
      }
    );

    this.passwordInput = this.passwordForm.controls['passwordInput'];
    this.usernameInput = this.passwordForm.controls['usernameInput'];
  }

  submitPasswordForm() {
    this.onComplete.emit(this.passwordInput.value);
  }
}
