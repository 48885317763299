@if (emailTokenValid) {
  <div>
    <!-- Confirmation Component -->
    <div class="main row">
      <ds-confirmation [confirmationData]="confirmationData">
        <ds-confirmation-link-area>
          @if (isLoggedIn) {
            <a class="font-weight-semibold" href="javascript:void(0);" (click)="goToOverview()"
              >Go To Overview</a
            >
          }
          @if (!isLoggedIn) {
            <a class="font-weight-semibold" href="javascript:void(0);" (click)="goToLogin()"
              >Log In</a
            >
          }
          @if (!isLoggedIn) {
            <a
              class="font-weight-semibold pad-left-10"
              href="javascript:void(0);"
              (click)="goToResetPassword()"
              >Reset Password</a
            >
          }
        </ds-confirmation-link-area>
      </ds-confirmation>
    </div>
  </div>
}
@if (emailTokenValid === false) {
  <div>
    <div class="column twelve sm-ten sm-push-one md-eight md-push-two lg-six lg-push-three">
      <div class="content" tabindex="-1">
        <span class="icon-alert block"></span>
        <h1 content [contentPath]="'enrollment.verifyEmailTokenExpiredText'"></h1>
        <div class="row">
          <p class="margin-bottom-20 margin-top-40 column six text-center fill">
            <span
              content
              [contentPath]="'profile.manageEmails.validateEmailExpiredMessage1'"
            ></span>
            <strong>{{ email }}</strong>
            <span
              content
              [contentPath]="'profile.manageEmails.validateEmailExpiredMessage2'"
            ></span>
          </p>
        </div>
        <div class="row margin-bottom-40">
          <div class="column twelve text-center">
            <button
              ds-button
              class="-offset-bottom"
              type="button"
              [dsRouterLink]="['/profile']"
              content
              [contentPath]="'profile.manageEmails.validateEmailTokenExpiredButton'"
            ></button>
          </div>
        </div>
      </div>
      <div class="row pad-top-20 text-center">
        <p class="helpText" content [contentPath]="'shared.needHelpText'"></p>
      </div>
      <div class="row margin-top-20">
        <div class="column four text-center fill">
          <span class="icon icon-call"></span>
          <a
            dsPartnerContent
            [brandHref]="'selectCusCareNumberFullHref'"
            [brandContent]="'selectPrettyCusCareNumber'"
          ></a>
        </div>
      </div>
    </div>
  </div>
}
