import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// components/directives/pipes
import { ProfileFeatureModule } from '@amfam/profile/feature';

// application modules
import { SharedModule } from '../shared/shared.module';
import { EditUseridComponent } from './edit-userid/edit-userid.component';
import { ProfileComponent } from './profile.component';
import { UseridAvailabilityComponent } from './userid-availability/userid-availability.component';
import { ValidateEmailTokenComponent } from './validate-email-token';

// resolve handlers

@NgModule({
  imports: [SharedModule, ProfileFeatureModule, MatSlideToggleModule, FormsModule],
  declarations: [
    ProfileComponent,
    UseridAvailabilityComponent,
    ValidateEmailTokenComponent,
    EditUseridComponent
  ],
  exports: [
    ProfileComponent,
    UseridAvailabilityComponent,
    ValidateEmailTokenComponent,
    EditUseridComponent
  ],
  providers: []
})
export class ProfileModule {}
