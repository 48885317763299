<h1 data-cy="paymentMethodModalHeaderText">{{ heading }}</h1>
<form [formGroup]="cardForm">
  <ds-column-layout [numberOfColumns]="'two'">
    <div column-left>
      @if (isEditFlow) {
        <div class="payment-card">
          <ds-payment-method-card
            class="cell-component"
            [paymentAccount]="paymentMethodAddEditConfig.paymentMethod"
          ></ds-payment-method-card>
        </div>
      }
      <ds-credit-card-add-edit [formGroup]="cardForm"></ds-credit-card-add-edit>
    </div>
    <div column-right>
      @if (displayDiscountWarning) {
        <ds-warning-box [headingText]="autoPayWarningHeading" [messageText]="autoPayWarningBody">
        </ds-warning-box>
      }
      @if (paymentMethodError | async) {
        <ds-error-box class="margin-top-10" [messageText]="failureMessage"> </ds-error-box>
      }
    </div>
  </ds-column-layout>
</form>
<call-to-action
  [disabled]="cardForm.invalid || (authorizedToSubmit$ | async) === false"
  [primaryButtonName]="primaryButtonName"
  (primaryEvent)="savePaymentMethod()"
  [tertiaryButtonName]="tertiaryButtonName"
  (tertiaryEvent)="done()"
></call-to-action>
