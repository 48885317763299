<ds-modal
  [id]="earlyExitFlowModalId"
  [closeOthers]="true"
  [maxWidth]="modalWidth"
  (onCloseModalEvent)="close()"
>
  @if(!isPartner){
    <div>
      <h2 class="text-center neutral-600-text"><b class="block"> Are you sure?</b></h2>

      <div class="row pad-20">
        <div class="column twelve">
          <div class="flex items-center">
            <div>
              <p class="margin-0">
                {{ exitFlowConfimationText }}
              </p>
            </div>
          </div>
        </div>

        <div class="column twelve pad-top-20 flex items-center justifyButtons">
          <button
            class="margin-left-8 margin-right-8 -outline-white"
            ds-button
            aria-label="Stay"
            (click)="stay.emit(); close()"
          >
          {{ exitPrimaryButtonText }}
          </button>
          <button ds-button aria-label="I'm Sure" (click)="leavePage.emit(); close()">
            I'm Sure
          </button>
        </div>
      </div>
    </div>
  }
  @if(isPartner){
    <div>
      <h2 class="text-center neutral-600-text"><b class="block"> Want to exit?</b></h2>

      <div class="row pad-20">
        <div class="column twelve">
          <div class="flex items-center">
            <div>
              <p class="margin-0">
                {{ exitFlowConfimationTextPartner }}
              </p>
            </div>
          </div>
        </div>

        <div class="column twelve pad-top-20 flex items-center ">
          <button class="-outline-red exit-btn" ds-button aria-label="Exit" (click)="leavePage.emit(); close()">
            Exit
          </button>
        </div>
      </div>
    </div>
  }
</ds-modal>
