import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { VehicleEffects } from './+state/vehicle.effects';
import * as fromVehicle from './+state/vehicle.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('vehicle', fromVehicle.vehicleReducer),
    EffectsModule.forFeature([VehicleEffects])
  ]
})
export class PolicyVehicleDataAccessModule {}
