import { createAction, props } from '@ngrx/store';

import { PaymentScheduleItem } from '@amfam/shared/models';

import {
  AddVehicleRateErrorResponse,
  AddVehicleRateRequest,
  AddVehicleRateResponse
} from '../../models/add-vehicle-rate.model';

export const addVehicleRate = createAction(
  '[Add Vehicle] Add vehicle rate',
  props<{ payload: AddVehicleRateRequest }>()
);

export const addVehicleRateSuccess = createAction(
  '[Add Vehicle] Add vehicle rate Success',
  props<{ payload: AddVehicleRateResponse }>()
);

export const addVehicleRateError = createAction(
  '[Add Vehicle] Add vehicle rate Error',
  props<{ payload: AddVehicleRateErrorResponse }>()
);

export const addVehicleRateErrorReset = createAction('[Add Vehicle] Add vehicle rate error reset');

export const addVehicleRateReset = createAction('[Add Vehicle] Add Vehicle Rate Reset');

export const loadFuturePayments = createAction('[Add Vehicle Feature effect] Load Future Payments');

export const loadFuturePaymentsSuccess = createAction(
  '[Add Vehicle Future Payment Effect] Load Future Payments Success',
  props<{ payload: { policyNumber: number; paymentSchedule: PaymentScheduleItem[] } }>()
);

export const loadFuturePaymentsFailed = createAction(
  '[Add Vehicle Future Payment Effect] Load Future Payments Failed'
);

export const fromAddVehicleQuoteActions = {
  addVehicleRate,
  addVehicleRateSuccess,
  addVehicleRateError,
  addVehicleRateReset,
  addVehicleRateErrorReset,
  loadFuturePayments,
  loadFuturePaymentsSuccess,
  loadFuturePaymentsFailed
};
