import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { type Observable } from 'rxjs';

import { profileQuery } from '@amfam/profile/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { PartyEmail, UsageCode } from '@amfam/shared/models';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { ButtonAnalytic, CopyService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-email-list-item',
  templateUrl: './email-list-item.component.html',
  styleUrls: ['./email-list-item.component.scss']
})
export class EmailListItemComponent implements OnInit {
  static readonly makePrimaryEmailButton: ButtonAnalytic = {
    link: 'MyAccount:Profile:MakePrimaryEmail'
  };

  @Input() makePrimaryEmailAddDeleteBtnToggleDisplay: boolean;
  @Input() email: PartyEmail;
  @Input() primaryEmail: PartyEmail;
  @Input() isPrimaryEmail: boolean;
  @Input() isPendingEmail: boolean;
  @Input() primaryEmailContactMethodUsage: UsageCode;
  @Output() deleteEmailEvent: EventEmitter<PartyEmail> = new EventEmitter();
  @Output() makePrimaryEvent: EventEmitter<PartyEmail> = new EventEmitter();
  @Output() sendAnotherVerificationEmailEvent: EventEmitter<PartyEmail> = new EventEmitter();
  @Output() resendEmailEmitEvent: EventEmitter<void> = new EventEmitter();
  @Output() changeEmailEmitEvent: EventEmitter<string> = new EventEmitter();
  @Input() isShellAccount: boolean;
  @Input() shellEmail: string;
  @Input() isDuplicateEmail: boolean;

  public makePrimaryButtonText = this.copyService.getCopy('shared.makePrimaryText');
  public resendEmailButtonText = this.copyService.getCopy('shared.resendEmail');
  public changeEmailButtonText = this.copyService.getCopy('shared.changeEmail');
  public contactNumber = this.copyService.getCopy('shared.contactNumber');
  public tokenExpiry$: Observable<boolean>;

  constructor(
    public roleService: ImpersonateRolesService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade,
    private store: Store
  ) {}

  ngOnInit() {
    this.tokenExpiry$ = this.store.select(profileQuery.selectTokenExpired);
  }

  deleteEmail() {
    this.deleteEmailEvent.emit(this.email);
  }

  makePrimary() {
    //TODO Make Primary Analytics
    this.analyticsFacade.trackButtonClick(EmailListItemComponent.makePrimaryEmailButton);

    this.makePrimaryEvent.emit(this.email);
  }

  sendAnotherVerificationEmail() {
    this.sendAnotherVerificationEmailEvent.emit(this.email);
  }

  resendEmail() {
    this.resendEmailEmitEvent.emit();
  }
  changeEmail(email) {
    this.changeEmailEmitEvent.emit(email);
  }
}
