import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { format, subMonths } from 'date-fns';
import { get as _get } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { PaymentService } from '@amfam/billing/shared/util';
import { GetPaymentHistoryPayload, GetRegisteredPaymentsResponse } from '@amfam/shared/models';

import { fromPaymentHistoryActions, GetPayments } from './payment-history.actions';

@Injectable()
export class PaymentHistoryEffects {
  getPaymentHistory$ = createEffect(() => {
    return this.action$.pipe(
      ofType(GetPayments),
      map(action => action.payload),
      mergeMap((payload: GetPaymentHistoryPayload) => {
        const startDate = format(
          subMonths(new Date(), payload.monthRange.startMonth),
          'YYYY-MM-DD'
        );
        const endDate = format(subMonths(new Date(), payload.monthRange.endMonth), 'YYYY-MM-DD');
        return this.paymentService.getRegisteredPayments(startDate, endDate, false, true).pipe(
          map((response: GetRegisteredPaymentsResponse) => {
            const payments = _get(response, 'payments', []);
            return fromPaymentHistoryActions.GetPaymentsSuccess(payments);
          }),
          catchError(error => {
            if (_get(error, 'status.code') === '404') {
              return of(fromPaymentHistoryActions.GetPaymentsSuccess([]));
            }
            return of(fromPaymentHistoryActions.GetPaymentsFail());
          })
        );
      })
    );
  });

  constructor(
    private action$: Actions,
    private paymentService: PaymentService
  ) {}
}
