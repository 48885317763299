import { createAction, props } from '@ngrx/store';

import {
  AutoPayContext,
  AutoPaySettings,
  AutoPaySetup,
  AutoPaySteps
} from './auto-pay-setup.models';

export const initAutoPaySetup = createAction(
  '[AutoPaySetup] Init AutoPay Setup',
  props<{ setups: AutoPaySetup[]; context: AutoPayContext; registrationError: boolean }>()
);

export const setEntryPoint = createAction(
  '[AutoPaySetup] Set Entry Point',
  props<{ entryPoint: string }>()
);

export const setPreselectedAccounts = createAction(
  '[AutoPaySetup] Set Preselected Accounts',
  props<{ billAccountNumbers: string[] }>()
);

export const updateAutoPaySetup = createAction(
  '[AutoPaySetup] Update AutoPay Setup',
  props<{ billAccountNumber: string; autoPaySettings: AutoPaySettings }>()
);

export const editAutoPaySetup = createAction(
  '[AutoPaySetup] Edit AutoPay Setup',
  props<{ billAccountNumber: string }>()
);

export const isUserComingFromEnrollment = createAction(
  '[Enrollment Flow AutoPaySetup] User Coming From Enrollment',
  props<{ isUserComingFromEnrollment: boolean }>()
);

export const nextStep = createAction('[AutoPaySetup] Next Step');

export const previousStep = createAction('[AutoPaySetup] Previous Step');

export const setStep = createAction(
  '[AutoPaySetup] Set Step',
  props<{ step: AutoPaySteps; activeBillAccount?: string; isEditing?: boolean }>()
);

export const resetAutoPaySetup = createAction('[AutoPaySetup] Reset AutoPay Setup');
