<ng-container [formGroup]="formGroup">
  @if (inputValue) {
    <label
      #labelEl
      class="radio-toggle-button"
      [ngClass]="labelClass"
      [attr.for]="'radio-toggle-button-' + inputId"
      [attr.id]="'radio-toggle-button-label-' + inputId"
      [attr.aria-label]="labelAriaLabel"
      [attr.data-cy]="labelTestId"
    >
      <input
        type="radio"
        [attr.checked]="inputChecked"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [attr.id]="'radio-toggle-button-' + inputId"
        [value]="inputValue"
        [attr.aria-labelledby]="'radio-toggle-button-label-' + inputId"
        [formControlName]="inputFormControlName"
        [attr.data-cy]="inputTestId"
      />
      <ng-content select="[toggle]"></ng-content>
    </label>
  }
  @if (hasSubMessage) {
    <label class="radio-warning-message">
      <ng-content select="[subMessage]"></ng-content>
    </label>
  }
</ng-container>
