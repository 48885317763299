<div class="row">
  <nav class="column twelve md-twelve lg-twelve">
    <ds-quick-links-list
      [title]="quickLinksTitle"
      [links]="quickLinksList"
      [isQuickLinkDisable]="isQuickLinkDisable"
    ></ds-quick-links-list>
  </nav>
  <hr aria-hidden="true" class="column twelve none lg-block pad-bottom-20" />
  <div class="column twelve md-twelve lg-twelve"><agents-list></agents-list></div>
</div>
