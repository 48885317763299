import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AutoPayActions } from '@amfam/billing/auto-pay/data-access';
import {
  PaymentAccountsAddSuccess,
  PaymentAccountsDeleteSuccess,
  PaymentAccountsEditSuccess
} from '@amfam/billing/paymentaccount/data-access';
import { ScheduledPaymentActions } from '@amfam/billing/schedulepayment/data-access';

import * as PaymentConfirmationActions from './paymentConfirmation.actions';

@Injectable()
export class PaymentConfirmationEffects {
  // eslint-disable-next-line @typescript-eslint/member-ordering

  load$ = createEffect(() => {
    return this.action$.pipe(
      ofType(PaymentConfirmationActions.PaymentConfirmationLoad),
      map(action => action),
      map(confirmationPayload =>
        PaymentConfirmationActions.PaymentConfirmationLoadSuccess(confirmationPayload.payload)
      ),
      catchError(error => of(PaymentConfirmationActions.PaymentConfirmationLoadFail(error)))
    );
  });

  multipleAutopaySubmitSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AutoPayActions.addMultipleAutoPaySuccess),
      map(action => action),
      map(payload => PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    );
  });

  autopayEditSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AutoPayActions.editAutoPaySuccess),
      map(action => action),
      map(payload => PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    );
  });

  autopayDeleteSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AutoPayActions.deleteAutoPaySuccess),
      map(action => action),
      map(payload => PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    );
  });

  paymentSubmitSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ScheduledPaymentActions.scheduledPaymentsSubmitSuccess),
      map(action => action.payload),
      map(payload => PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    );
  });

  paymentEditSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ScheduledPaymentActions.scheduledPaymentsEditSuccess),
      map(action => action.payload),
      map(payload => PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    );
  });

  paymentDeleteSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ScheduledPaymentActions.scheduledPaymentsDeleteSuccess),
      map(action => action.payload),
      map(payload => PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    );
  });

  paymentAccountAddSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(PaymentAccountsAddSuccess),
      map(action => action.payload),
      map(payload => PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    );
  });

  paymentAccountEditSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(PaymentAccountsEditSuccess),
      map(action => action.payload),
      map(payload => PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    );
  });

  paymentAccountDeleteSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(PaymentAccountsDeleteSuccess),
      map(action => action.payload),
      map(payload => PaymentConfirmationActions.PaymentConfirmationLoad(payload.confirmation))
    );
  });

  constructor(private action$: Actions) {}
}
