<div
  [attr.data-header]="column.name"
  [ngClass]="{ 'no-label': column.isHeaderOnMobile }"
  class="ds-table-body-cell-label"
>
  @if (!column.cellTemplate) {
    <span [title]="rowName" [innerHTML]="rowName"> </span>
  }
  @if (column?.cellTemplate) {
    <ng-template
      [ngTemplateOutlet]="column.cellTemplate"
      [ngTemplateOutletContext]="{ value: rowName, row: row, column: column }"
    >
    </ng-template>
  }
</div>
