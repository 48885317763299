import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromMaeFeature from './mae-feature.reducer';

export const selectMaeFeatureState = createFeatureSelector<fromMaeFeature.MaeFeateureState>(
  fromMaeFeature.maeFeatureFeatureKey
);

export const getEnrollmentEmail = createSelector(
  selectMaeFeatureState,
  (state: fromMaeFeature.MaeFeateureState) => state.email
);
export const isEnrollmentWorkFlow = createSelector(
  selectMaeFeatureState,
  (state: fromMaeFeature.MaeFeateureState) => state.enrollmentFlow
);
export const maeFeatureQuery = {
  selectMaeFeatureState,
  getEnrollmentEmail,
  isEnrollmentWorkFlow
};
