import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';

import { userQuery } from '@amfam/shared/user';

@Component({
  selector: 'ds-profile-card-business',
  templateUrl: './profile-card-business.component.html',
  styleUrls: ['./profile-card-business.component.scss']
})
export class ProfileCardBusinessComponent implements OnInit, OnDestroy {
  @Input() isShellAccount: boolean;
  @Input() canShowStatus: boolean;
  @Input() forgerockEnabled = false;

  public emailAddress: string;
  public businessName: string;
  public userID: string;
  public name: string;
  private storeSub: Subscription;

  constructor(private store: Store) {}

  ngOnInit() {
    this.storeSub = combineLatest([
      this.store.select(userQuery.getEmailAddress),
      this.store.select(userQuery.getBusinessName),
      this.store.select(userQuery.getLoginName),
      this.store.select(userQuery.getFirstName),
      this.store.select(userQuery.getLastName)
    ]).subscribe(([emailAddress, businessName, userId, firstName, lastName]) => {
      this.emailAddress = emailAddress;
      this.businessName = businessName;
      this.userID = userId;
      this.name = firstName + lastName;
    });
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
