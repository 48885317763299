import { createAction, props } from '@ngrx/store';

import { PaymentConfirmationModel } from '@amfam/shared/models';

import {
  AddEditAutoPayPayload,
  AddEditAutoPayResponse,
  AddMultipleAutoPayPayload,
  AddMultipleAutoPayResponse,
  ApplyAutoPayDiscountPayload,
  ApplyAutoPayDiscountResponse,
  DeleteAutoPayPayload,
  DeleteAutoPayResponse,
  GetAutomaticPaymentsResponse,
  GetAutoPayPredictionPayload,
  GetAutoPayPredictionResponse
} from '../models/auto-pay.models';

// Get AutoPay Prediction
export const getAutoPayPrediction = createAction(
  '[AutoPay] Get AutoPay Prediction',
  props<{ payload: GetAutoPayPredictionPayload; context: 'add' | 'edit'; correlationId: string }>()
);

export const getAutoPayPredictionSuccess = createAction(
  '[AutoPay] Get AutoPay Prediction Success',
  props<{ response: GetAutoPayPredictionResponse; correlationId: string }>()
);

export const getAutoPayPredictionFailure = createAction(
  '[AutoPay] Get AutoPay Prediction Failure',
  props<{ error: unknown; correlationId: string }>()
);

// Add Multiple AutoPay
export const addMultipleAutoPay = createAction(
  '[AutoPay] Add Multiple AutoPay',
  props<{ payload: AddMultipleAutoPayPayload; correlationId: string }>()
);

export const addMultipleAutoPaySuccess = createAction(
  '[AutoPay] Add Multiple AutoPay Success',
  props<{
    response: AddMultipleAutoPayResponse;
    correlationId: string;
    req?: AddMultipleAutoPayPayload;
    confirmation?: PaymentConfirmationModel;
  }>()
);

export const addMultipleAutoPayPartialSuccess = createAction(
  '[AutoPay] Add Multiple AutoPay Partial Success',
  props<{ response: AddMultipleAutoPayResponse; correlationId: string }>()
);

export const addMultipleAutoPayFailure = createAction(
  '[AutoPay] Add Multiple AutoPay Failure',
  props<{ error: unknown; billAccountNumbers: string[]; correlationId: string }>()
);

// Add AutoPay
export const addAutoPay = createAction(
  '[AutoPay] Add AutoPay',
  props<{ payload: AddEditAutoPayPayload; correlationId: string }>()
);

export const addAutoPaySuccess = createAction(
  '[AutoPay] Add AutoPay Success',
  props<{ response: AddEditAutoPayResponse; correlationId: string }>()
);

export const addAutoPayFailure = createAction(
  '[AutoPay] Add AutoPay Failure',
  props<{ error: unknown; billAccountNumber: string; correlationId: string }>()
);

// Edit AutoPay
export const editAutoPay = createAction(
  '[AutoPay] Edit AutoPay Payments',
  props<{ payload: AddEditAutoPayPayload; billAccountNumber: string; correlationId: string }>()
);

export const editAutoPaySuccess = createAction(
  '[AutoPay] Edit AutoPay Success',
  props<{
    response: AddEditAutoPayResponse;
    correlationId: string;
    confirmation?: PaymentConfirmationModel;
  }>()
);

export const editAutoPayFailure = createAction(
  '[AutoPay] Edit AutoPay Failure',
  props<{ error: unknown; billAccountNumber: string; correlationId: string }>()
);

// Delete AutoPay
export const deleteAutoPay = createAction(
  '[AutoPay] Delete AutoPay',
  props<{ payload: DeleteAutoPayPayload; correlationId: string }>()
);

export const deleteAutoPaySuccess = createAction(
  '[AutoPay] Delete AutoPay Success',
  props<{
    response: DeleteAutoPayResponse;
    billAccountNumber: string;
    correlationId: string;
    confirmation?: PaymentConfirmationModel;
  }>()
);

export const deleteAutoPayFailure = createAction(
  '[AutoPay] Delete AutoPay Failure',
  props<{ error: unknown; billAccountNumber: string; correlationId: string }>()
);

export const updateAutoPay = createAction(
  '[AutoPay] Update AutoPay',
  props<{ payload: { newNickName: string; oldNickName: string } }>()
);

// Get Automatic Payment
export const getAutomaticPayment = createAction(
  '[AutoPay] Get Automatic Payments',
  props<{ billAccountNumber: string; correlationId: string }>()
);

export const getAutomaticPaymentSuccess = createAction(
  '[AutoPay] Get Automatic Payments Success',
  props<{ response: GetAutomaticPaymentsResponse; correlationId: string }>()
);

export const getAutomaticPaymentFailure = createAction(
  '[AutoPay] Get Automatic Payments Failure',
  props<{ error: unknown; billAccountNumber: string; correlationId: string }>()
);

// Get All Automatic Payment
export const getAllAutomaticPayments = createAction(
  '[AutoPay] Get All Automatic Payments',
  props<{ billAccountNumbers: string[]; correlationId: string }>()
);

export const getAllAutomaticPaymentsSuccess = createAction(
  '[AutoPay] Get All Automatic Payments Success',
  props<{ response: GetAutomaticPaymentsResponse[]; correlationId: string }>()
);

export const getAllAutomaticPaymentsFailure = createAction(
  '[AutoPay] Get All Automatic Payments Failure',
  props<{ error: unknown; billAccountNumbers: string[]; correlationId: string }>()
);

// Apply AutoPay Discount
export const applyAutoPayDiscount = createAction(
  '[AutoPay] Apply AutoPay Discount',
  props<{ payload: ApplyAutoPayDiscountPayload }>()
);

export const applyAutoPayDiscountSuccess = createAction(
  '[AutoPay] Apply AutoPay Discount Success',
  props<{ response: ApplyAutoPayDiscountResponse }>()
);

export const applyAutoPayDiscountFailure = createAction(
  '[AutoPay] Apply AutoPay Discount Failure',
  props<{ error: unknown }>()
);
