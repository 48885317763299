import { createAction, props } from '@ngrx/store';

import { FeatureFlag } from '../models/feature-flag.model';

export const loadAllFeatureFlags = createAction(
  '[Feature Flag] Load all Feature Flags',
  props<{ payload: FeatureFlag[] }>()
);

export const fromFeatureFlagActions = {
  loadAllFeatureFlags
};
