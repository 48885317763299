export class PolicyTypeIconConstants {
  static readonly UNASSOCIATED = 'icon-linked-thick';
  static readonly AUTO = 'icon-auto';
  static readonly HOME = 'icon-home';
  static readonly LIFE = 'icon-life';
  static readonly MIXED = 'icon-combine-bill-fill';
  static readonly OTHER = 'icon-online-billing';
  static readonly UMBRELLA = 'icon-umbrella';
  static readonly NOT_ON_POLICY = 'icon-auto';
  static readonly DOCUMENTS = 'icon-documents';
  static readonly CYCLE = 'icon-motorcycle';
  static readonly WATERCRAFT = 'icon-boat';
  static readonly RECREATIONALVEHICLE = 'icon-rv';
  static readonly COMMERCIAL = 'icon-afi-billing-commercial-fill-1';
}
