import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ApiStatus } from '@amfam/shared/models';

import {
  PaymentConfirmationActions,
  PaymentConfirmationActionTypes
} from './paymentConfirmation.actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PaymentConfirmationNotificationsState {}

export interface NotificationState extends EntityState<PaymentConfirmationNotificationsState> {
  loading: boolean;
  error: ApiStatus | null;
}

export const adapter: EntityAdapter<PaymentConfirmationNotificationsState> =
  createEntityAdapter<PaymentConfirmationNotificationsState>();

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: PaymentConfirmationActions
): NotificationState {
  switch (action.type) {
    case PaymentConfirmationActionTypes.PAYMENT_CONFIRMATION_LOAD: {
      return { ...state, loading: true };
    }

    case PaymentConfirmationActionTypes.PAYMENT_CONFIRMATION_LOAD_SUCCESS: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
