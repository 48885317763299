/* eslint-disable @typescript-eslint/no-explicit-any */
export class UnderlyingPolicy {
  public policyDescription: string;
  public policyNumber: string;
  public policyProvider: string;
  public expirationDate: string;
  public policyStatus: string;
  public productType: string;

  static fromJson(json: any): UnderlyingPolicy {
    const underlyingPolicy = new UnderlyingPolicy();
    underlyingPolicy.assignProperties(json);

    return underlyingPolicy;
  }

  protected assignProperties(json: any) {
    this.policyDescription = json.description;
    this.expirationDate = json.expirationDate;
    this.policyNumber = json.policyNumber.trim();
    this.policyProvider = json.policyProvider;
    this.policyStatus = json.policyStatus;
    this.productType = json.productType;
  }
}
