@if (!isAdminApp) {
  <div class="caption margin-top-20" [innerHtml]="termsAndConditionsHtml"></div>
}
@if (isAdminApp && !!paymentTermsConditionModal) {
  <ds-modal
    [id]="paymentTermsConditionModal"
    [closeOthers]="true"
    [maxWidth]="modalWidth"
    (onCloseModalEvent)="closeModal($event)"
  >
    <div data-cy="paymentTermsConditionModal">
      <div class="row pad-20" data-cy="paymentTermsConditionModalTermsContent">
        <div class="column twelve">
          <h2 data-cy="pleaseReadToCustomerHeader">Please read this to your customer:</h2>
          <div class="caption margin-top-20" [innerHtml]="termsAndConditionsHtml"></div>
        </div>
      </div>
      <div class="column twelve margin-top-20 pad-bottom-20">
        <div class="row flex items-center">
          <button
            ds-button
            (click)="agreeTermsAndConditions(true)"
            class="-outline-white margin-right-10 pad-horizontal-20"
            data-cy="paymentTermsConditionModalTermsAgreeButton"
          >
            Agree
          </button>
          <p class="caption">
            <a
              (click)="agreeTermsAndConditions(false)"
              data-cy="paymentTermsConditionModalTermsDisagreeLink"
              >Cancel</a
            >
          </p>
        </div>
      </div>
    </div>
  </ds-modal>
}
