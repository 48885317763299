<div class="full-width">
  @if (!setPreferencesSuccess) {
    <amfam-preferences-init
      [billingEligibleToGoPaperless]="billingEligibleToGoPaperless$ | async"
      [isPolicyGoPaperless]="isPolicyGoPaperless$ | async"
      [routeToConfirmation]="routeToConfirmation"
      (goPaperlessClickEvent)="goPaperlessEvent($event)"
    >
    </amfam-preferences-init>
  }
</div>
