import { format } from 'date-fns';

import { ApiStatus } from '@amfam/shared/models';

export const UNAVAILABLE_CLAIM = 'unavailable';
export interface DraftClaim {
  riskId?: string;
  partyId?: string;
  claimNumber?: string;
  policyNumber?: string;
  lossDate?: string;
  claimProgress?: string;
  submitDraftClaim?: boolean;
  insured?: {
    partyIdentifier?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    addresses?: [
      {
        addr1?: string;
        addr2?: string;
        city?: string;
        state?: string;
        zip5?: string;
      }
    ];
    phones?: [
      {
        phoneType?: string;
        areaCode?: string;
        number?: string;
      }
    ];
    emails?: [
      {
        emailType?: string;
        emailAddress?: string;
      }
    ];
  };
  reportedBy?: {
    reportedOn?: string;
    reportedBy?: {
      partyIdentifier?: string;
      firstName?: string;
      lastName?: string;
      middleName?: string;
      addresses?: [
        {
          addr1?: string;
          addr2?: string;
          city?: string;
          state?: string;
          zip5?: string;
        }
      ];
      phones?: [
        {
          phoneType?: string;
          areaCode?: string;
          number?: string;
        }
      ];
      emails?: [
        {
          emailType?: string;
          emailAddress?: string;
        }
      ];
    };
    typeOfReportedByRoleCode?: string;
  };
  lossLocationAddress?: {
    addr1?: string;
    addr2?: string;
    city?: string;
    state?: string;
    zip5?: string;
  };
  autoClaimDetail?: {
    vehicles?: [
      {
        year?: string;
        make?: string;
        model?: string;
        vin?: string;
      }
    ];
  };
  fnolDetail?: {
    typeOfFnolStatusCode?: string;
    whatHappened?: string;
    multipleMovingVehiclesIndicator: boolean;
    typeOfHowReportedCode?: string;
    typeOfLossCauseCode?: string;
    typeOfLossLocationCode?: string;
    typeOfStateJurisdictionCode?: string;
    injuryIndicator?: string;
  };
  [propName: string]: any;
}

export const initialCreateDraftClaim: DraftClaim = {
  riskId: null,
  partyId: null,
  claimNumber: null,
  policyNumber: null,
  lossDate: null,
  claimProgress: null,
  submitDraftClaim: false,
  insured: {
    partyIdentifier: null,
    firstName: null,
    lastName: null,
    middleName: null,
    addresses: [
      {
        addr1: null,
        addr2: null,
        city: null,
        state: null,
        zip5: null
      }
    ],
    phones: [
      {
        phoneType: null,
        areaCode: null,
        number: null
      }
    ],
    emails: [
      {
        emailType: null,
        emailAddress: null
      }
    ]
  },
  reportedBy: {
    reportedOn: null,
    reportedBy: {
      partyIdentifier: null,
      firstName: null,
      lastName: null,
      middleName: null,
      addresses: [
        {
          addr1: null,
          addr2: null,
          city: null,
          state: null,
          zip5: null
        }
      ],
      phones: [
        {
          phoneType: null,
          areaCode: null,
          number: null
        }
      ],
      emails: [
        {
          emailType: null,
          emailAddress: null
        }
      ]
    },
    typeOfReportedByRoleCode: null
  },
  lossLocationAddress: {
    addr1: null,
    addr2: null,
    city: null,
    state: null,
    zip5: null
  },
  autoClaimDetail: {
    vehicles: [
      {
        year: null,
        make: null,
        model: null,
        vin: null
      }
    ]
  },
  fnolDetail: {
    typeOfFnolStatusCode: '',
    whatHappened: '',
    multipleMovingVehiclesIndicator: null,
    typeOfHowReportedCode: '',
    typeOfLossCauseCode: '',
    typeOfLossLocationCode: '',
    typeOfStateJurisdictionCode: '',
    injuryIndicator: ''
  }
};

export interface CreateDraftClaimInterface {
  lossReportedDate?: string;
  dateOfLoss?: string;
  policyNumber?: string;
  lossDate?: string;
  riskId?: string;
  partyId?: string;
  vehicle?: {
    year: string;
    make: string;
    model: string;
    vin: string;
  };
}

export interface CreateDraftClaimResponse {
  claimNumber: string;
  status: ApiStatus;
}

export interface SubmitDraftClaimResponse {
  claimNumber: string;
  status: ApiStatus;
  catIndicator?: false;
  eligibleServices?: ServiceType[];
}

export interface ServiceType {
  serviceCode: string;
  serviceDescription: string;
  serviceRequestDisclaimer: ServiceRequestDisclaimer[];
}

export interface ServiceRequestDisclaimer {
  serviceDisclaimerCode: string;
  serviceDisclaimerDescription: string;
}

export class FnolConfirmation {
  draftClaim?: {
    incidentDate?: string;
  };
  submittedClaim?: {
    vin?: string;
    claimNumber?: string;
    status?: ApiStatus;
    eligibleServices?: ServiceType[];
  };
}

export interface DeleteDraftClaimData {
  // TODO: Update the model
  draftNumber?: string;
}
