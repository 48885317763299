<h3
  class="margin-bottom-20 pad-top-30"
  content
  [contentPath]="'profile.manageEmails.' + headerCopy"
></h3>

<div class="pad-left-40">
  <div class="border-dashed">
    <p class="caption"><i content [contentPath]="'profile.manageEmails.mainCaptionCopy'"></i></p>
    <div class="pad-top-10 pad-bottom-10 items-center justify-space-between sm-flex">
      @if (!isEditing) {
        <div class="sm-margin-right-10 flex-auto">
          <b>{{ emailAddress }}</b>
        </div>
      }
      @if (!isEditing) {
        <button
          ds-button
          class="-outline-white margin-top-10 sm-margin-top-0 flex-constrained"
          (click)="editing()"
          [disabled]="(roleService.isAuthorized('edit_business_email') | async) === false"
        >
          <span content [contentPath]="'shared.changeBtn'"></span>
        </button>
      }
    </div>
    @if (isEditing) {
      <form [formGroup]="changeEmailForm" class="row pad-top-10 pad-bottom-10" (ngSubmit)="save()">
        <div class="column twelve md-four">
          <ds-form-control-container
            [labelTextContentPath]="'profile.businessEmailManager.fieldLabel'"
            [control]="changeEmailForm.get('emailChange')"
          >
            <input
              id="emailChange"
              class="fill"
              formControlName="emailChange"
              maxlength="50"
              (change)="removeErrorMessage()"
            />
            <ds-form-control-message
              [control]="changeEmailForm.get('emailChange')"
            ></ds-form-control-message>
            @if (emailInUse) {
              <small
                class="block caption error margin-top-5"
                content
                [contentPath]="'profile.manageEmails.emailInUseError'"
              ></small>
            }
          </ds-form-control-container>
        </div>
        <div class="text-right">
          <button
            ds-button
            class="-white bg-transparent cancel a"
            content
            [contentPath]="'shared.cancel'"
            [disabled]=""
            (click)="cancel()"
          ></button>
          <button
            ds-button
            type="submit"
            content
            [contentPath]="'shared.confirmBtn'"
            [disabled]="
              !changeEmailForm.valid || changeEmailForm.get('emailChange').value === emailAddress
            "
          ></button>
        </div>
      </form>
    }
  </div>
</div>
