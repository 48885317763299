import { NavigationExtras } from '@angular/router';
import { Action, createAction } from '@ngrx/store';

export enum RouterActionTypes {
  GO = '[Router] Go',
  BACK = '[Router] Back',
  FORWARD = '[Router] Forward'
}

export const Go = createAction(
  RouterActionTypes.GO,
  (payload: { path: unknown[]; query?: object; extras?: NavigationExtras }) => ({ payload })
);

export const Back = createAction(RouterActionTypes.BACK);

export class Forward implements Action {
  readonly type = RouterActionTypes.FORWARD;
}

export type RouterActions = ReturnType<typeof Go> | ReturnType<typeof Back> | Forward;

export const fromRouterActions = {
  Go,
  Back,
  Forward
};
