<ds-modal
  [id]="modalId"
  [closeOthers]="true"
  [maxWidth]="959"
  (onCloseModalEvent)="close()"
  (initialized)="modalInitialized($event)"
>
  <div class="padded">
    @if (hasPrograms && !enrolled) {
      <enroll-terms [model]="model" (onSubmit)="onSubmitTerms($event)"></enroll-terms>
    }
    @if (enrolled) {
      <enroll-confirmation (onClose)="close(false)"></enroll-confirmation>
    }
  </div>
</ds-modal>
