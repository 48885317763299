import { Action } from '@ngrx/store';

import {
  ActiveStepModel,
  LoadProgressIndicatorModel,
  StepCompleteModel
} from './progress-indicator.reducer';

export enum ProgressIndicatorActionTypes {
  ProgressIndicatorLoad = '[ProgressIndicator] Load',
  ProgressIndicatorStepComplete = '[ProgressIndicator] Step Complete',
  ProgressIndicatorStepNext = '[ProgressIndicator] Step Next',
  ProgressIndicatorStepBack = '[ProgressIndicator] Back',
  ProgressIndicatorStepSetActive = '[ProgressIndicator] Set Active',
  ProgressIndicatorReset = '[ProgressIndicator] Reset',
  ProgressIndicatorDelete = '[ProgressIndicator] Delete',
  ProgressIndicatorSkip = '[ProgressIndicator] Skip'
}

export class ProgressIndicatorLoad implements Action {
  readonly type = ProgressIndicatorActionTypes.ProgressIndicatorLoad;
  constructor(public payload: LoadProgressIndicatorModel) {}
}

export class ProgressIndicatorStepComplete implements Action {
  readonly type = ProgressIndicatorActionTypes.ProgressIndicatorStepComplete;
  constructor(public payload?: StepCompleteModel) {}
}

export class ProgressIndicatorStepNext implements Action {
  readonly type = ProgressIndicatorActionTypes.ProgressIndicatorStepNext;
}

export class ProgressIndicatorStepBack implements Action {
  readonly type = ProgressIndicatorActionTypes.ProgressIndicatorStepBack;
  constructor(public payload?: any) {}
}

export class ProgressIndicatorStepSetActive implements Action {
  readonly type = ProgressIndicatorActionTypes.ProgressIndicatorStepSetActive;
  constructor(public payload?: ActiveStepModel) {}
}

export class ProgressIndicatorReset implements Action {
  readonly type = ProgressIndicatorActionTypes.ProgressIndicatorReset;
  constructor(public payload?: any) {}
}

export class ProgressIndicatorDelete implements Action {
  readonly type = ProgressIndicatorActionTypes.ProgressIndicatorDelete;
}

export class ProgressIndicatorSkip implements Action {
  readonly type = ProgressIndicatorActionTypes.ProgressIndicatorSkip;
}

export type ProgressIndicatorAction =
  | ProgressIndicatorLoad
  | ProgressIndicatorStepComplete
  | ProgressIndicatorStepNext
  | ProgressIndicatorStepBack
  | ProgressIndicatorStepSetActive
  | ProgressIndicatorReset
  | ProgressIndicatorDelete
  | ProgressIndicatorSkip;

export const fromProgressIndicatorActions = {
  ProgressIndicatorLoad,
  ProgressIndicatorStepComplete,
  ProgressIndicatorStepNext,
  ProgressIndicatorStepBack,
  ProgressIndicatorStepSetActive,
  ProgressIndicatorReset,
  ProgressIndicatorDelete,
  ProgressIndicatorSkip
};
