// Angular
import { Injectable } from '@angular/core';
import { assign as _assign, get as _get } from 'lodash';

import { ConfigService } from '../config/config.service';
import { WindowRef } from '../window/window.service';
// Models
import {
  ButtonAnalytic,
  CoverageChangeAnalyticsInfoModel,
  DataLayer,
  EventAnalytic,
  EventInfo,
  PageAnalytic,
  PageInfo,
  ReplaceVehicleAnalyticsInfoModel,
  ReportAClaimCompleteAnalyticsModel,
  UserAnalytic,
  UserInfo
} from './analytics.model';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private gaEnabled = false;
  private dtmEnabled = false;
  private profileInfo: UserAnalytic = new UserAnalytic();
  private events = new Array<EventInfo>();
  private replaceVehicleInfo: ReplaceVehicleAnalyticsInfoModel;
  private coverageChangeInfo: CoverageChangeAnalyticsInfoModel;
  private claimConfirmationInfo: ReportAClaimCompleteAnalyticsModel;

  constructor(
    private config: ConfigService,
    private win: WindowRef
  ) {}

  loadGoogleMaps(): Promise<any> {
    return new Promise<void>(resolve => {
      const url =
        '//maps.googleapis.com/maps/api/js?client=gme-americanfamilyinsurance1&channel=amfamdrp&key=' +
        this.config.get('gmKey');
      const node = document.createElement('script');
      node.src = url;
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  /**
   * loads Adobe and SiteCore analytics, validates analytics providers after page load
   *
   * @memberof AnalyticsService
   */
  loadAnalytics() {
    this.loadGoogleMaps();
    this.loadScript('launch', 'utf-8');
    this.loadScript('fxm', 'utf-8');
    this.win.nativeWindow.addEventListener('load', this.verifyAnalytics.bind(this), false);
  }

  /**
   * sets the profile info from the passed in user
   *
   * @param {UserAnalytic} userAnalytic
   * @memberof AnalyticsService
   */
  setProfileInfo(userAnalytic: UserAnalytic): void {
    this.profileInfo = Object.assign(this.profileInfo, userAnalytic);
  }

  /**
   * track a page view in Adobe and Google
   *
   * @param {PageAnalytic} pageAnalytic
   * @memberof AnalyticsService
   * @deprecated use analytics facade
   */
  trackPage(pageAnalytic: PageAnalytic): void {
    const dataLayer: DataLayer = {
      page: this.getPage(pageAnalytic),
      event: this.events,
      replaceVehicle: this.replaceVehicleInfo,
      policyCoverageChange: this.coverageChangeInfo,
      user: this.getUser()
    };
    // If a dynamic fnol claimNumber is passed, we need to add it to the dataLayer
    if (_get(pageAnalytic, 'claimNumber')) {
      dataLayer.claims = { claimNumber: _get(pageAnalytic, 'claimNumber') };
    }

    // If a payment amount is passed, we need to add it to the dataLayer
    if (_get(pageAnalytic, 'paymentAmount')) {
      dataLayer.payNow = {
        paymentAmount: _get(pageAnalytic, 'paymentAmount')
      };
    }
    // If a payment date is passed, we need to add it to the dataLayer
    if (_get(pageAnalytic, 'paymentDate')) {
      dataLayer.payNow = {
        paymentDate: _get(pageAnalytic, 'paymentDate')
      };
    }
    this.track(dataLayer);
    this.sendPageView(pageAnalytic); // Send to Google Analytics
    this.clearEvents();
  }

  /**
   * track page and error in Adobe and Google
   *
   * @param {PageAnalytic} pageAnalytic
   * @param {string} error
   * @memberof AnalyticsService
   */
  trackPageAndError(pageAnalytic: PageAnalytic, error: string) {
    const obj = <PageAnalytic>_assign(pageAnalytic);
    obj.pageName = `${pageAnalytic.pageName}:${error}`;
    this.trackPage(obj);
  }

  /**
   * Push event into queue for sending to Adobe, Google Analytics and DynaTrace. Evensts are sent with the next page view
   *
   * @param {EventAnalytic} eventAnalytic
   * @memberof AnalyticsService
   * @deprecated use analytics facade
   */
  trackEvent(eventAnalytic: EventAnalytic): void {
    const event: EventInfo = {
      eventInfo: eventAnalytic
    };
    /**
     * AS: Changing the stucture of how we are passing the replace vehicle info to analytics.
     */
    if (
      _get(
        eventAnalytic,
        'replaceVehicle.referenceNumber',
        _get(eventAnalytic, 'replaceVehicle.agentName')
      )
    ) {
      this.replaceVehicleInfo = eventAnalytic.replaceVehicle;
    }

    if (
      _get(eventAnalytic, 'changeCoverageInfo.selection', []).length > 0 ||
      _get(eventAnalytic, 'changeCoverageInfo.RRAmount')
    ) {
      this.coverageChangeInfo = eventAnalytic.changeCoverageInfo;
    }

    if (!!_get(eventAnalytic, 'claimConfirmationInfo.claimNumber')) {
      this.claimConfirmationInfo = eventAnalytic.claimConfirmationInfo;
    }

    this.events.push(event);
  }

  /**
   * Send page and events to Adobe and DynaTrace
   *
   * @param {PageAnalytic} pageAnalytic
   * @param {EventAnalytic} eventAnalytic
   * @memberof AnalyticsService
   * @deprecated use analytics facade
   */
  trackPageAndEvent(pageAnalytic: PageAnalytic, eventAnalytic: EventAnalytic): void {
    const dataLayer: DataLayer = {
      page: this.getPage(pageAnalytic),
      event: this.getEvents(eventAnalytic),
      replaceVehicle: this.getReplaceVehicleInfo(),
      policyCoverageChange: this.getChangeCoverageInfo(),
      user: this.getUser(),
      claims: this.getClaimConfirmationInfo()
    };
    this.track(dataLayer);
    this.clearEvents();
  }

  /**
   * send button clicked link to Adobe
   *
   * @param {ButtonAnalytic} buttonAnalytic
   * @memberof AnalyticsService
   * @deprecated use analytics facade
   */
  trackButtonClick(buttonAnalytic: ButtonAnalytic): void {
    const dataLayer: DataLayer = {
      linkClicked: buttonAnalytic
    };
    this.track(dataLayer);
  }

  /**
   * Send page to DynaTrace
   *
   * @param {PageAnalytic} pageAnalytic
   * @memberof AnalyticsService
   */
  sendPageView(pageAnalytic: PageAnalytic) {
    this.sendDynatraceAction('pageview', pageAnalytic.pageName);
  }

  /**
   * Send event to DynaTrace
   *
   * @param {string} eventCategory
   * @param {EventAnalytic} eventAnalytic
   * @memberof AnalyticsService
   */
  sendEvent(eventCategory: string, eventAnalytic: EventAnalytic) {
    this.sendDynatraceAction(
      eventCategory,
      eventAnalytic.eventName + '|' + eventAnalytic.eventStep
    );
  }

  /**
   * Send action to Dynatrace
   *
   * @param {string} actionName
   * @param {string} actionType
   * @memberof AnalyticsService
   */
  sendDynatraceAction(actionName: string, actionType: string) {
    const dt = this.win.nativeWindow.dT_; // agent script
    const dtrum = this.win.nativeWindow.dtrum; // one agent script
    try {
      if (typeof dt !== 'undefined' && typeof dtrum !== 'undefined') {
        if (actionName === 'exception') {
          // reportCustomError would be used to send the customer error aleerts instantly
          const customError = dtrum.reportCustomError(actionName, actionType, null, false);
        } else {
          const action = dtrum.enterAction(actionName + ': ' + actionType, 'analytics');
          dtrum.leaveAction(action);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Send the app version to Dynatrace
   *
   * @private
   * @param {string} version
   * @memberof AnalyticsService
   */
  private sendDynatraceAppVersion(version: string) {
    const dt = this.win.nativeWindow.dT_; // agent script
    const dynaTrace = this.win.nativeWindow.dynaTrace; // appmon script
    try {
      if (
        typeof dt !== 'undefined' &&
        typeof dynaTrace !== 'undefined' &&
        dynaTrace.setAppVersion
      ) {
        dynaTrace.setAppVersion(version);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * load script
   *
   * @private
   * @returns
   * @memberof AnalyticsService
   */
  private loadScript(tag, charset) {
    return new Promise<void>(resolve => {
      const url = this.config.get('links.' + tag);
      const node = document.createElement('script');
      node.src = url;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = charset;
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  /**
   * load script
   *
   * @private
   * @returns
   * @memberof AnalyticsService
   */
  private loadStyle(tag) {
    return new Promise<void>(resolve => {
      const url = this.config.get('links.' + tag);
      const node = document.createElement('link');
      (node.rel = 'stylesheet'), (node.href = url);
      node.type = 'text/css';
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  /**
   * This method will verify that anayltics tags are loaded correctly and send the avaliability to DynaTrace
   *
   * @private
   * @memberof AnalyticsService
   */
  private verifyAnalytics() {
    const version = this.config.get('version');
    this.sendDynatraceAppVersion(version);
    if (this.win.nativeWindow._satellite && this.win.nativeWindow._satellite.track) {
      this.dtmEnabled = true;
    } else {
      this.dtmEnabled = false;
    }
    if (this.win.nativeWindow.ga && this.win.nativeWindow.ga.loaded) {
      this.gaEnabled = true;
    } else {
      this.gaEnabled = false;
    }
    this.sendDynatraceAction('dtm', String(this.dtmEnabled));
    this.sendDynatraceAction('ga', String(this.gaEnabled));
  }

  private getPage(pageAnalytic: PageAnalytic): PageInfo {
    return {
      pageInfo: {
        pageName: pageAnalytic.pageName,
        experience: pageAnalytic.experience
      },
      category: {
        primaryCategory: pageAnalytic.primaryCategory,
        subCategory1: pageAnalytic.subCategory1,
        subCategory2: pageAnalytic.subCategory2,
        subCategory3: pageAnalytic.subCategory3
      },
      attributes: { language: ' ' },
      paymnthistory: { timeframe: pageAnalytic.timeframe }
    };
  }

  private getEvents(eventAnalytic: EventAnalytic): EventInfo[] {
    this.trackEvent(eventAnalytic);
    return this.events;
  }

  private getReplaceVehicleInfo(): ReplaceVehicleAnalyticsInfoModel {
    return this.replaceVehicleInfo;
  }

  private getChangeCoverageInfo(): CoverageChangeAnalyticsInfoModel {
    return this.coverageChangeInfo;
  }

  private getClaimConfirmationInfo() {
    return this.claimConfirmationInfo;
  }

  private clearEvents() {
    this.events = [];
  }

  private getUser(): UserInfo {
    return {
      profileInfo: {
        userType: this.profileInfo.userType,
        trackID: this.profileInfo.trackId,
        experienceID: this.profileInfo.experienceId,
        partnerID: this.profileInfo.partnerId
      }
    };
  }

  private track(dataLayer: DataLayer) {
    this.win.nativeWindow.digitalData = {};
    this.win.nativeWindow.digitalData = dataLayer;
    if (this.win.nativeWindow._satellite && this.win.nativeWindow._satellite.track) {
      const primaryCategory = _get(
        this.win.nativeWindow.digitalData,
        'page.category.primaryCategory',
        null
      );

      this.win.nativeWindow._satellite.track('myaccount');
    }
  }
}
