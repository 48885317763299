import { Action, createAction } from '@ngrx/store';

import {
  AuthenticatePayload,
  AuthenticateSuccessResponse,
  CheckEmailAddressPayload,
  CheckEmailAddressResponse,
  CreateDigitalAccountPayload,
  DigitalAccount,
  DigitalAccountError,
  GetDigitalAccountByCdhIdPayload,
  GetDigitalAccountByWaidPayload,
  RefreshSuccessResponse,
  UpdateDigitalAccountPayload
} from './digital-account.model';

export enum DigitalAccountActionTypes {
  CreateDigitalAccount = '[DigitalAccount] Create Digital Account',
  CreateDigitalAccountSuccess = '[DigitalAccount] Create Digital Account Success',
  CreateDigitalAccountFailure = '[DigitalAccount] Create Digital Account Failure',

  GetDigitalAccountByCdhId = '[DigitalAccount] Get Digital Account By CdhId',

  GetDigitalAccountByCdhIdSuccess = '[DigitalAccount] Get Digital Account By CdhId Success',
  GetDigitalAccountByCdhIdFailure = '[DigitalAccount] Get Digital Account By CdhId Failure',

  GetDigitalAccountByWaid = '[DigitalAccount] Get Digital Account By Waid',
  GetDigitalAccountByWaidSuccess = '[DigitalAccount] Get Digital Account By Waid Success',
  GetDigitalAccountByWaidFailure = '[DigitalAccount] Get Digital Account By Waid Failure',

  UpdateDigitalAccount = '[DigitalAccount] Update Digital Account',
  UpdateDigitalAccountSuccess = '[DigitalAccount] Update Digital Account Success',
  UpdateDigitalAccountFailure = '[DigitalAccount] Update Digital Account Failure',

  Authenticate = '[DigitalAccount] Authenticate',
  AuthenticateSuccess = '[DigitalAccount] Authenticate Success',
  AuthenticateFailure = '[DigitalAccount] Authenticate Failure',

  Refresh = '[DigitalAccount] Get Refresh',
  RefreshSuccess = '[DigitalAccount] Get Refresh Success',
  RefreshFailure = '[DigitalAccount] Get Refresh Failure',

  CheckEmailAddress = '[DigitalAccount] Check Email Address',
  CheckEmailAddressSuccess = '[DigitalAccount] Check Email Address Success',
  CheckEmailAddressFailure = '[DigitalAccount] Check Email Address Failure'
}

export class CreateDigitalAccount implements Action {
  readonly type = DigitalAccountActionTypes.CreateDigitalAccount;
  constructor(public payload: CreateDigitalAccountPayload) {}
}

export class CreateDigitalAccountSuccess implements Action {
  readonly type = DigitalAccountActionTypes.CreateDigitalAccountSuccess;
}

export class CreateDigitalAccountFailure implements Action {
  readonly type = DigitalAccountActionTypes.CreateDigitalAccountFailure;
  constructor(public payload: DigitalAccountError) {}
}

export const GetDigitalAccountByCdhId = createAction(
  DigitalAccountActionTypes.GetDigitalAccountByCdhId,
  (payload?: GetDigitalAccountByCdhIdPayload) => ({ payload })
);

export class GetDigitalAccountByCdhIdSuccess implements Action {
  readonly type = DigitalAccountActionTypes.GetDigitalAccountByCdhIdSuccess;
  constructor(public payload: DigitalAccount) {}
}

export class GetDigitalAccountByCdhIdFailure implements Action {
  readonly type = DigitalAccountActionTypes.GetDigitalAccountByCdhIdFailure;
  constructor(public payload: { error: any }) {}
}

export class GetDigitalAccountByWaid implements Action {
  readonly type = DigitalAccountActionTypes.GetDigitalAccountByWaid;
  constructor(public payload: GetDigitalAccountByWaidPayload) {}
}

export class GetDigitalAccountByWaidSuccess implements Action {
  readonly type = DigitalAccountActionTypes.GetDigitalAccountByWaidSuccess;
  constructor(public payload: DigitalAccount) {}
}

export class GetDigitalAccountByWaidFailure implements Action {
  readonly type = DigitalAccountActionTypes.GetDigitalAccountByWaidFailure;
  constructor(public payload: { error: any }) {}
}

export class UpdateDigitalAccount implements Action {
  readonly type = DigitalAccountActionTypes.UpdateDigitalAccount;
  constructor(public payload: UpdateDigitalAccountPayload) {}
}

export class UpdateDigitalAccountSuccess implements Action {
  readonly type = DigitalAccountActionTypes.UpdateDigitalAccountSuccess;
  constructor() {}
}

export class UpdateDigitalAccountFailure implements Action {
  readonly type = DigitalAccountActionTypes.UpdateDigitalAccountFailure;
  constructor(public payload: { error: any }) {}
}

export class Authenticate implements Action {
  readonly type = DigitalAccountActionTypes.Authenticate;
  constructor(public payload: AuthenticatePayload) {}
}

export class AuthenticateSuccess implements Action {
  readonly type = DigitalAccountActionTypes.AuthenticateSuccess;
  constructor(public payload: { response: AuthenticateSuccessResponse; correlationId: string }) {}
}

export class AuthenticateFailure implements Action {
  readonly type = DigitalAccountActionTypes.AuthenticateFailure;
  constructor(public payload: { error: DigitalAccountError; correlationId: string }) {}
}

export class Refresh implements Action {
  readonly type = DigitalAccountActionTypes.Refresh;
}

export class RefreshSuccess implements Action {
  readonly type = DigitalAccountActionTypes.RefreshSuccess;
  constructor(public payload: RefreshSuccessResponse) {}
}

export class RefreshFailure implements Action {
  readonly type = DigitalAccountActionTypes.RefreshFailure;
  constructor(public payload: { error: any }) {}
}
export class CheckEmailAddress implements Action {
  readonly type = DigitalAccountActionTypes.CheckEmailAddress;
  constructor(public payload: CheckEmailAddressPayload) {}
}

export class CheckEmailAddressSuccess implements Action {
  readonly type = DigitalAccountActionTypes.CheckEmailAddressSuccess;
  constructor(public payload: CheckEmailAddressResponse) {}
}

export class CheckEmailAddressFailure implements Action {
  readonly type = DigitalAccountActionTypes.CheckEmailAddressFailure;
  constructor(public payload: { error: any }) {}
}

export type DigitalAccountActions =
  | CreateDigitalAccountFailure
  | CreateDigitalAccountSuccess
  | CreateDigitalAccount
  | ReturnType<typeof GetDigitalAccountByCdhId>
  | GetDigitalAccountByCdhIdSuccess
  | GetDigitalAccountByCdhIdFailure
  | GetDigitalAccountByWaid
  | GetDigitalAccountByWaidSuccess
  | GetDigitalAccountByWaidFailure
  | UpdateDigitalAccount
  | UpdateDigitalAccountSuccess
  | UpdateDigitalAccountFailure
  | Authenticate
  | AuthenticateSuccess
  | AuthenticateFailure
  | Refresh
  | RefreshSuccess
  | RefreshFailure
  | CheckEmailAddress
  | CheckEmailAddressSuccess
  | CheckEmailAddressFailure;

export const fromDigitalAccountActions = {
  CreateDigitalAccountFailure,
  CreateDigitalAccountSuccess,
  CreateDigitalAccount,
  GetDigitalAccountByCdhId,
  GetDigitalAccountByCdhIdSuccess,
  GetDigitalAccountByCdhIdFailure,
  GetDigitalAccountByWaid,
  GetDigitalAccountByWaidSuccess,
  GetDigitalAccountByWaidFailure,
  UpdateDigitalAccount,
  UpdateDigitalAccountSuccess,
  UpdateDigitalAccountFailure,
  Authenticate,
  AuthenticateSuccess,
  AuthenticateFailure,
  Refresh,
  RefreshSuccess,
  RefreshFailure,
  CheckEmailAddressSuccess,
  CheckEmailAddress,
  CheckEmailAddressFailure
};
