import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { AlertModalConfig, DsModalService } from '@amfam/ui-kit';

import * as AutoPaySetupActions from '../../+state/auto-pay-setup.actions';
import { AutoPayContext, AutoPaySteps } from '../../+state/auto-pay-setup.models';
import * as AutoPaySetupSelectors from '../../+state/auto-pay-setup.selectors';

@Component({
  selector: 'ds-auto-pay-wrapper',
  templateUrl: './auto-pay-wrapper.component.html',
  styleUrls: ['./auto-pay-wrapper.component.scss']
})
export class AutoPayWrapperComponent implements OnInit, OnDestroy {
  stepNumber$: Observable<number>;

  cancelModalConfig: AlertModalConfig = {
    headerText: this.copyService.getCopy('billing.autoPayRefactor.cancelModalHeading'),
    bodyText: this.copyService.getCopy('billing.autoPayRefactor.setUpCancelModalBody'),
    modalId: 'autoPayCancelModal',
    ctaConfig: {
      primaryButtonName: "I'm sure",
      primaryButtonDestructive: true,
      tertiaryButtonName: 'Cancel',
      fullWidthButtons: true
    }
  };

  constructor(
    private store: Store,
    private modalService: DsModalService,
    private copyService: CopyService
  ) {}

  ngOnInit(): void {
    this.stepNumber$ = combineLatest([
      this.store.select(AutoPaySetupSelectors.selectActiveStep),
      this.store.select(AutoPaySetupSelectors.selectContext)
    ]).pipe(
      map(([step, context]) => {
        if (context === AutoPayContext.EDIT) {
          this.cancelModalConfig.bodyText = this.copyService.getCopy(
            'billing.autoPayRefactor.editCancelModalBody'
          );
        }
        return step === AutoPaySteps.SELECT_ACCOUNTS
          ? 1
          : context === AutoPayContext.ADD_MULTIPLE && step === AutoPaySteps.SETUP
            ? 2
            : context === AutoPayContext.ADD_MULTIPLE && step === AutoPaySteps.REVIEW
              ? 3
              : null;
      })
    );
  }

  stay() {
    this.modalService.close(this.cancelModalConfig.modalId);
  }

  leave() {
    this.store
      .select(AutoPaySetupSelectors.selectEntryPoint)
      .pipe(take(1))
      .subscribe(entryPoint => {
        this.store.dispatch(
          fromRouterActions.Go({
            path: [entryPoint]
          })
        );
      });
  }

  ngOnDestroy() {
    this.store.dispatch(AutoPaySetupActions.resetAutoPaySetup());
  }
}
