import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PaymentHistoryEffects } from './+state/payment-history.effects';
import {
  PAYMENT_HISTORY_FEATURE_KEY,
  paymentHistoryReducer
} from './+state/payment-history.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PAYMENT_HISTORY_FEATURE_KEY, paymentHistoryReducer),
    EffectsModule.forFeature([PaymentHistoryEffects])
  ]
})
export class BillingPaymentHistoryDataAccessModule {}
