<div class="banner-border neutral-600-border pad-20">
  @if (isAdminApp) {
    <button
      ds-button
      class="icon-exit -white float-right"
      (click)="cancelClick.emit()"
      aria-label="Close"
    ></button>
  }
  <div class="row flex items-center">
    @if (bannerConfig?.iconPath) {
      <img src="{{ bannerConfig.iconPath }}" class="relief-icon margin-right-20" />
    }
    <div>
      @if (bannerConfig?.headingTextPath) {
        <div [ngClass]="[isDuplicateEmail ? '' : 'font-weight-bold']">
          @if (showIcon) {
            <span class="icon-alert duplicateEmail margin-right-30"></span>
          }
          <span
            [ngClass]="{ 'header-content': phaseTwoEnabled }"
            [ngClass]="[
              isDuplicateEmail ? 'header-duplicateEmailContent' : '',
              phaseTwoEnabled ? 'header-content' : ''
            ]"
            content
            [contentPath]="bannerConfig.headingTextPath"
          ></span>
        </div>
      }
      @if (bannerConfig?.subText) {
        <div>
          {{ bannerConfig.subText }}
        </div>
      }
    </div>
    <span class="grow"></span>
    @if (bannerConfig?.buttonText) {
      <button
        ds-button
        class="button -outline-white margin-left-10"
        [ngClass]="{
          buttonv2: phaseTwoEnabled
        }"
        [attr.aria-label]="bannerConfig.buttonText"
        (click)="this.buttonClick.emit()"
      >
        {{ bannerConfig.buttonText }}
      </button>
    }
    @if (phaseTwoEnabled) {
      <button
        ds-button
        class="icon-exit -white"
        (click)="cancelClick.emit()"
        aria-label="Close"
      ></button>
    }
  </div>
</div>
