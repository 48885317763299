import { state, style, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivationEnd, NavigationEnd, RouteConfigLoadEnd, Router } from '@angular/router';
import { get as _get, has as _has } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { HeaderNavRect, NavLinkTreeObj } from '../../../models';
import { HeaderWrapperService } from '../../../services/header-wrapper.service';

@Component({
  selector: 'ds-header-navigation-item',
  templateUrl: './ds-header-navigation-item.component.html',
  styleUrls: ['../shared/ds-header-navigation-links.scss'],
  animations: [
    trigger('showSubmenu', [
      state(
        'show',
        style({
          opacity: 1,
          visibility: 'visible',
          height: '*'
        })
      ),
      state(
        'hide',
        style({
          opacity: 0,
          visibility: 'hidden',
          height: '0px'
        })
      )
    ])
  ]
})
export class DsHeaderNavigationItemComponent implements AfterViewInit, OnInit, OnDestroy {
  // TODO: Fix inputs upstream to use async as soon as possible and remove Observables here
  @Input() loaded$: Observable<boolean> = this.headerWrapperService.loaded$;
  @Input() link: NavLinkTreeObj;
  @Input() showSub = 'hide';
  @Input() headerNavRect: HeaderNavRect;
  @Output() subOpenEvent = new EventEmitter<this>();
  @Output() linkClickEvent = new EventEmitter();
  @Output() sublinkClickEvent = new EventEmitter();
  @ViewChild('linkContainer') linkContainer: ElementRef;
  @ViewChild('linkElem') linkElem: ElementRef;
  @ViewChild('linkElemItem') linkElemItem: ElementRef;
  @ViewChild('sublinkElem') subLinkElem: ElementRef;
  @ViewChildren('subLinkElemList') subLinkElemListItems: QueryList<ElementRef>;
  elementCenterPosition: number;
  linkActive: ElementRef;
  sublinkActive: ElementRef;
  routerStateUrl: string;
  routerStateUrlWithoutParams: string;
  submenuExpanded = 'false';
  submenuHasPopup = false;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private headerWrapperService: HeaderWrapperService,
    private renderer: Renderer2
  ) {}

  @HostListener('window:resize')
  resize() {
    // Re-run sub menu centering if window resize occurs
    this.getElementCenter(this.linkElem, this.subLinkElem);
  }

  // ESC has to close menu for WCAG 2.0
  @HostListener('keyup.esc')
  onEsc() {
    this.showSub = 'hide';
    this.submenuExpanded = 'false';
  }

  ngOnInit() {
    this.headerWrapperService.routerStateUrl$
      .pipe(takeUntil(this.stop$))
      .subscribe(routerStateUrl => {
        this.routerStateUrl = routerStateUrl;
      });
  }

  ngAfterViewInit() {
    this.submenuHasPopup = _has(this.link, 'subLinkArray.length');

    // If this item doesn't have children, remove the aria-expanded attribute
    // Otherwise the screen reader reads collapsed for an item with no submenu
    if (!this.submenuHasPopup && this.linkElemItem) {
      this.linkElemItem.nativeElement.removeAttribute('aria-expanded');
      this.linkElemItem.nativeElement.removeAttribute('aria-haspopup');
    }

    this.headerWrapperService.linkActive$
      .pipe(
        takeUntil(this.stop$),
        filter(linkActive => _has(linkActive, 'nativeElement'))
      )
      .subscribe((linkActive: ElementRef) => {
        if (this.linkActive !== linkActive) {
          this.linkActive = linkActive;
        }
      });
    window.setTimeout(() => {
      // Set sublink active if it matches the active sublink in the service
      this.headerWrapperService.sublinkActive$
        .pipe(takeUntil(this.stop$))
        .subscribe((sublinkActive: ElementRef) => {
          if (this.subLinkElemListItems) {
            this.subLinkElemListItems.forEach(subLinkElemListItem => {
              if (subLinkElemListItem.nativeElement.classList.contains('active')) {
                subLinkElemListItem.nativeElement.classList.remove('active');
              } else if (sublinkActive === subLinkElemListItem.nativeElement) {
                subLinkElemListItem.nativeElement.classList.add('active');
              }
            });
          }
        });

      // Set link active if it matches the router.url
      this.router.events.pipe(takeUntil(this.stop$)).subscribe(event => {
        this.determineLinkStatus(this.linkElem, this.subLinkElem, event);
      });

      // Set link active again as things load
      this.loaded$.pipe(takeUntil(this.stop$)).subscribe(() => {
        this.determineLinkStatus(this.linkElem, this.subLinkElem, this.headerNavRect);
      });
    }, 1);
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  linkClick(link, event) {
    this.linkClickEvent.emit({ link, event });
    this.showSub = 'show';
    if (this.submenuHasPopup) {
      this.submenuExpanded = 'true';
    }

    this.setLinkActive(this.linkElem);
  }

  sublinkClick(sublink, event) {
    const sublinkElemItem = _get(event, 'currentTarget');
    this.sublinkClickEvent.emit({ sublink, event });
    this.setLinkActive(this.linkElem, 'active');
    const sublinkElem = sublinkElemItem.parentElement;
    this.headerWrapperService.setSublinkActive(sublinkElem);
  }

  subOpen(focusedElem?) {
    // TODO: [DRE-1307] jjc - rework submenu to treat tabs and hover independently
    // Technically, we should treat tabs differently than hover
    // See: https://www.w3.org/WAI/tutorials/menus/flyout/#keyboard-users
    if (this.showSub !== 'show') {
      // Hide other nav items / sub menus
      this.subOpenEvent.emit(this);
      // Show this sub menu
      this.showSub = 'show';
    }

    if (focusedElem !== undefined) {
      if (this.linkContainer.nativeElement.contains(focusedElem)) {
        if (this.submenuHasPopup && this.submenuExpanded === 'false') {
          this.submenuExpanded = 'true';
        }
      }
    } else {
      if (this.submenuHasPopup && this.submenuExpanded === 'false') {
        this.submenuExpanded = 'true';
      }
    }
    this.setLinkFocus(this.linkElem);
  }

  subClose(focusedElem?) {
    if (this.showSub !== 'hide') {
      // Hide this sub menu
      this.showSub = 'hide';
    }

    if (focusedElem !== undefined) {
      if (this.linkContainer.nativeElement.contains(focusedElem)) {
        if (this.submenuHasPopup && this.submenuExpanded === 'false') {
          this.submenuExpanded = 'true';
        }
      }
    } else {
      if (this.submenuExpanded === 'true') {
        this.submenuExpanded = 'false';
      }
    }
    this.setLinkBlur(this.linkElem);
  }

  // Tell Angular to track these items by ID so that they are not recreated in the DOM
  trackByFn(index) {
    return index;
  }

  private determineLinkStatus(linkElem, subLinkElem, navEvent?) {
    if (linkElem) {
      if (subLinkElem) {
        this.getElementCenter(linkElem, subLinkElem);
      }
      // If the current linkElem is in the store as the linkActive Elem
      // Or the current routerStateUrl is the same as the linkRoute prop for this link
      // Then set this link as active
      if (linkElem === this.linkActive || this.routerStateUrl === _get(this.link, 'linkRoute')) {
        this.setLinkActive(linkElem, 'active');
        // Else if the current linkElem is not in the service as the linkActive Elem
        // Move on...
      } else if (linkElem !== this.linkActive) {
        // If we have a navEvent other than NavigationEnd or RouteConfigLoadEnd
        // Bail, no need to try matching on them
        if (navEvent) {
          const navEventAggregate =
            navEvent instanceof NavigationEnd ||
            navEvent instanceof RouteConfigLoadEnd ||
            navEvent instanceof ActivationEnd;
          if (!navEventAggregate) {
            return false;
          } else {
            if (navEventAggregate) {
              this.centerSubNavOnNavEnd();
            }
            // If the router.url is the same as the linkRoute prop of this link
            // Or the routerStateUrl is the same as the linkRoute prop of this link
            // Or if there's a fuzzy match between the routerStateUrl is the same as the linkRoute prop of this link
            // Then set link as active
            if (
              _get(this.link, 'linkRoute') === _get(this.router, 'url') ||
              this.routerStateUrl === _get(this.link, 'linkRoute') ||
              this.fuzzyUrlMatch(this.routerStateUrl, _get(this.link, 'linkRoute'))
            ) {
              this.setLinkActive(linkElem, 'active');
            } else {
              if (linkElem.nativeElement.classList.contains('active')) {
                this.renderer.removeClass(linkElem.nativeElement, 'active');
              }
              if (linkElem.nativeElement.hasAttribute('aria-current')) {
                linkElem.nativeElement.removeAttribute('aria-current');
              }
            }
          }
        }
      }
    }
  }

  private getElementCenter(linkElem, subLinkElem) {
    this.headerWrapperService.headerNavRect$
      .pipe(takeUntil(this.stop$))
      .subscribe(headerNavRect => {
        this.elementCenterPosition =
          this.linkElem.nativeElement.getBoundingClientRect().left +
          this.linkElem.nativeElement.getBoundingClientRect().width / 2;
        this.headerWrapperService.centerSubNav(linkElem, subLinkElem, headerNavRect);
      });
  }

  private centerSubNavOnNavEnd() {
    // Centers the sub menu under the nav item on navigation end.
    if (this.linkElem && this.subLinkElem) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.getElementCenter(this.linkElem, this.subLinkElem);
        }
      });
    }
  }

  private setLinkActive(linkElem: ElementRef, className = '') {
    if (className.length) {
      this.renderer.addClass(linkElem.nativeElement, className);
    }
    linkElem.nativeElement.setAttribute('aria-current', 'page');
    this.headerWrapperService.setLinkActive(linkElem);
  }

  private setLinkFocus(linkElem: ElementRef) {
    this.renderer.addClass(linkElem.nativeElement, 'focus');
    this.headerWrapperService.setLinkFocus(linkElem);
  }

  private setLinkBlur(linkElem: ElementRef) {
    this.renderer.removeClass(linkElem.nativeElement, 'focus');
    this.headerWrapperService.setLinkFocus(null);
  }

  private fuzzyUrlMatch(routerUrl: string, linkRoute: string): boolean {
    if (typeof routerUrl === 'string' && typeof linkRoute === 'string') {
      // Match starting with / to the next / or a ?. Example here: https://regexr.com/5k23d
      const regex = /(?:\/)(.*?)(?=[?/])/g;
      const resultMatchGroup = routerUrl.match(regex);
      if (resultMatchGroup) {
        // Destructure to get first array property
        const [desiredRes] = resultMatchGroup;
        return desiredRes === linkRoute;
      }
    }
  }
}
