import { Action } from '@ngrx/store';

import {
  GetAllSecurityQuestionsSuccessPayload,
  GetUserSecurityQuestionsPayload,
  SetSecurityQuestionsAnswersPayload
} from './security-questions.models';

export enum SecurityQuestionsActionTypes {
  GetAllSecurityQuestions = '[SecurityQuestions] Get All Security Questions',
  GetAllSecurityQuestionsSuccess = '[SecurityQuestions] Get All Security Questions Success',
  GetAllSecurityQuestionsFailure = '[SecurityQuestions] Get All Security Questions Failure',
  GetUserSecurityQuestions = '[SecurityQuestions] Get User Security Questions',
  GetUserSecurityQuestionsSuccess = '[SecurityQuestions] Get User Security Questions Success',
  GetUserSecurityQuestionsFailure = '[SecurityQuestions] Get User Security Questions Failure',
  SetSecurityQuestionsAnswers = '[SecurityQuestions] Set Security Questions Answers',
  SetSecurityQuestionsAnswersSuccess = '[SecurityQuestions] Set Security Questions Answers Success',
  SetSecurityQuestionsAnswersFailure = '[SecurityQuestions] Set Security Questions Answers Failure'
}

export class GetAllSecurityQuestions implements Action {
  readonly type = SecurityQuestionsActionTypes.GetAllSecurityQuestions;
  constructor() {}
}

export class GetAllSecurityQuestionsSuccess implements Action {
  readonly type = SecurityQuestionsActionTypes.GetAllSecurityQuestionsSuccess;
  constructor(public payload: GetAllSecurityQuestionsSuccessPayload) {}
}

export class GetAllSecurityQuestionsFailure implements Action {
  readonly type = SecurityQuestionsActionTypes.GetAllSecurityQuestionsFailure;
  constructor() {}
}

export class GetUserSecurityQuestions implements Action {
  readonly type = SecurityQuestionsActionTypes.GetUserSecurityQuestions;
  constructor(public payload: GetUserSecurityQuestionsPayload) {}
}

export class GetUserSecurityQuestionsSuccess implements Action {
  readonly type = SecurityQuestionsActionTypes.GetUserSecurityQuestionsSuccess;
  constructor(public payload: { response: any }) {}
}

export class GetUserSecurityQuestionsFailure implements Action {
  readonly type = SecurityQuestionsActionTypes.GetUserSecurityQuestionsFailure;
  constructor() {}
}

export class SetSecurityQuestionsAnswers implements Action {
  readonly type = SecurityQuestionsActionTypes.SetSecurityQuestionsAnswers;
  constructor(public payload: SetSecurityQuestionsAnswersPayload) {}
}

export class SetSecurityQuestionsAnswersSuccess implements Action {
  readonly type = SecurityQuestionsActionTypes.SetSecurityQuestionsAnswersSuccess;
  constructor(public payload: { response: any }) {}
}

export class SetSecurityQuestionsAnswersFailure implements Action {
  readonly type = SecurityQuestionsActionTypes.SetSecurityQuestionsAnswersFailure;
  constructor() {}
}

export type SecurityQuestionsActions =
  | GetAllSecurityQuestions
  | GetAllSecurityQuestionsSuccess
  | GetAllSecurityQuestionsFailure
  | GetUserSecurityQuestions
  | GetUserSecurityQuestionsSuccess
  | GetUserSecurityQuestionsFailure
  | SetSecurityQuestionsAnswers
  | SetSecurityQuestionsAnswersSuccess
  | SetSecurityQuestionsAnswersFailure;

export const fromSecurityQuestionsActions = {
  GetAllSecurityQuestions,
  GetAllSecurityQuestionsSuccess,
  GetAllSecurityQuestionsFailure,
  GetUserSecurityQuestions,
  GetUserSecurityQuestionsSuccess,
  GetUserSecurityQuestionsFailure,
  SetSecurityQuestionsAnswers,
  SetSecurityQuestionsAnswersSuccess,
  SetSecurityQuestionsAnswersFailure
};
