import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
// Store
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ConfigService } from '@amfam/shared/utility/shared-services';

import * as fromRoot from '../../core/store';

@Injectable()
export class FeatureFlagGuard {
  constructor(
    private featureFlagService: FeatureFlagService,
    private store: Store<fromRoot.RootState>,
    private config: ConfigService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const data = route.data;
    if (data['feature']) {
      const feature = data['feature'];
      if (this.featureFlagService.isEnabled(feature)) {
        return observableOf(true);
      }
    }
    return this.redirect(route);
  }

  canLoad(route: Route) {
    const data = route.data;
    if (data['feature']) {
      const feature = data['feature'];
      if (this.featureFlagService.isEnabled(feature)) {
        return observableOf(true);
      }
    }
    return this.redirect(route);
  }

  private redirect(route: Route | ActivatedRouteSnapshot) {
    const data = route.data;
    if (data['redirectUrlKey']) {
      const redirectUrl = this.config.get(data['redirectUrlKey']);
      location.replace(redirectUrl);
    } else if (data['redirectRoute']) {
      this.store.dispatch(
        fromRouterActions.Go({
          path: [data['redirectRoute']]
        })
      );
    } else {
      this.store.dispatch(
        fromRouterActions.Go({
          path: ['/login']
        })
      );
    }
    return observableOf(false);
  }
}
