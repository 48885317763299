import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { take } from 'rxjs/operators';

import { BillingPaymentPaths } from '@amfam/shared/models';
import { BrandModel, BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { QuickLink } from '../models/quick-link.model';

@Injectable({ providedIn: 'root' })
export class QuickLinksService {
  private isPartner: boolean;
  private hideMakePaymentIndicator = false;
  isContactBillingToggleEnabled: boolean;

  constructor(private store: Store<BrandModel>, private featureFlagService: FeatureFlagService) {
    this.store
      .select(BrandSelectors.getIsPartner)
      .pipe(take(1))
      .subscribe(res => (this.isPartner = res));
  }

  // Builds the quick links containing communication preferences
  // Required for the policy and billing detail pages for billing PCM work
  public buildQuickLinks(hideMakePaymentLink?: boolean) {
    if(hideMakePaymentLink === true) {
      this.hideMakePaymentIndicator = true;
    }
    this.isContactBillingToggleEnabled = this.featureFlagService.isEnabled('contact_billing');
    const quickLinks = [
      new QuickLink('Make a Payment', BillingPaymentPaths.ADD_ONE_TIME_PAYMENT),
      new QuickLink('Communication Preferences', '/profile', null, {
        openAccordion: 'communication'
      }),
      new QuickLink('Request a Change', '/policies/changerequest'),
      new QuickLink('Contact a Billing Specialist', '/billing/contact'),
      new QuickLink('FAQs', '/faq')
    ]
      .filter(this.excludePartnerQuickLinks, this)
      .filter(this.excludeMakePaymentLink, this)
      .filter(
        link =>
          // fitler Contact a Billing Specialist link if contact billing is toggled off
          !(
            _get(link, 'text') === 'Contact a Billing Specialist' &&
            !this.isContactBillingToggleEnabled
          )
      );

    return quickLinks;
  }

  excludePartnerQuickLinks(link) {
    // Do not show Request a Change or FAQ link if non-AFI partner
    return !(_get(link, 'text') === 'Request a Change' && this.isPartner);
  }

  excludeMakePaymentLink(link) {
    // Do not show Make a Payment quick link if selected account is registered elsewhere
    return !(_get(link, 'text') === 'Make a Payment' && this.hideMakePaymentIndicator);
  }
}
