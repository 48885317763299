import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { BRAND_FEATURE_KEY, brandReducer } from './+state/brand.reducer';
import { initialBrandModel as brandInitialState } from './models/brand.model';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(BRAND_FEATURE_KEY, brandReducer, {
      initialState: brandInitialState
    })
  ]
})
export class BrandModule {}
