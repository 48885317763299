<div
  #linkContainer
  class="nav-container"
  (mouseover)="subOpen()"
  (mouseout)="subClose()"
  role="menuitem"
  [attr.aria-label]="link.linkText"
>
  <div
    #linkElem
    class="nav-item-link"
    data-cy="navigationMenuItem"
    [style.maxWidth.px]="link?.maxWidth"
    [class.has-children]="!!link?.subLinkArray?.length"
    [class.overflow-ellipsis]="link?.overflowEllipsis"
    [ngClass]="link?.listClasses"
  >
    @if (!link?.linkExternalUrl && !link.mobileOnly && link.showOnNavigationMenu) {
      <a
        #linkElemItem
        [dsRouterLink]="link?.linkRoute"
        [queryParams]="link?.queryParams"
        [ngClass]="link?.linkClasses + '-text'"
        [attr.aria-label]="link.linkText"
        [attr.aria-expanded]="submenuExpanded"
        [attr.aria-haspopup]="submenuHasPopup ? 'menu' : 'false'"
        [attr.data-cy]="link?.linkTestId"
        (focus)="subOpen($event.currentTarget)"
        (blur)="subClose($event.currentTarget)"
        (touchstart)="subOpen($event.currentTarget)"
        (click)="linkClick(link, $event)"
      >
        <div class="link-content">
          @if (!!link?.linkIcon) {
            <span role="presentation" aria-hidden="true" [ngClass]="link?.linkIcon"></span>
          }
          <span [class.overflow-ellipsis]="link?.overflowEllipsis">{{ link.linkText }}</span>
        </div>
      </a>
    }
    @if (!!link?.linkExternalUrl && !link.mobileOnly && link.showOnNavigationMenu) {
      <a
        [ngClass]="link?.linkClasses + '-text'"
        [href]="link?.linkExternalUrl"
        [attr.aria-label]="link.linkText"
        [attr.target]="link?.linkTarget"
        [attr.data-cy]="link?.linkTestId"
        (click)="linkClick(link, $event)"
        (focus)="subOpen($event.currentTarget)"
        (blur)="subClose($event.currentTarget)"
      >
        <div class="link-content">
          @if (!!link?.linkIcon) {
            <span role="presentation" aria-hidden="true" [ngClass]="link?.linkIcon"></span>
          }
          <span [class.overflow-ellipsis]="link?.overflowEllipsis">{{ link.linkText }}</span>
        </div>
      </a>
    }
    @if (!!link?.subLinkArray?.length) {
      <ul
        [@showSubmenu]="showSub"
        #sublinkElem
        class="nav-item-sublink"
        role="menu"
        [attr.data-cy]="link?.submenuTestId"
      >
        @for (sublink of link?.subLinkArray; track trackByFn($index, sublink)) {
          <li
            #subLinkElemList
            role="menuitem"
            authCheck
            [ngClass]="sublink?.listClasses"
            [style.maxWidth.px]="sublink?.maxWidth"
            [permissionName]="sublink?.permissionName"
            [checkAction]="sublink?.checkAction"
          >
            @if (!sublink?.linkExternalUrl && !link.mobileOnly) {
              <a
                [dsRouterLink]="sublink?.linkRoute"
                [ngClass]="sublink?.linkClasses"
                [queryParams]="sublink?.queryParams"
                [attr.aria-label]="sublink?.linkText"
                [attr.data-cy]="sublink?.linkTestId"
                (click)="sublinkClick(sublink, $event)"
                (focus)="subOpen($event.currentTarget)"
                (blur)="subClose($event.currentTarget)"
              >
                @if (!!sublink?.linkIcon) {
                  <span
                    role="presentation"
                    aria-hidden="true"
                    alt="Link Icon"
                    [ngClass]="sublink?.linkIcon"
                  ></span>
                }
                <span [class.overflow-ellipsis]="sublink?.overflowEllipsis">{{
                  sublink.linkText
                }}</span>
              </a>
            }
            @if (!!sublink?.linkExternalUrl && !link.mobileOnly) {
              <a
                [ngClass]="sublink?.linkClasses"
                [href]="sublink?.linkExternalUrl"
                [attr.aria-label]="sublink?.linkText"
                [attr.target]="sublink?.linkTarget"
                [attr.data-cy]="sublink?.linkTestId"
                (click)="sublinkClick(sublink, $event)"
                (focus)="subOpen($event.currentTarget)"
                (blur)="subClose($event.currentTarget)"
              >
                @if (!!sublink?.linkIcon) {
                  <span
                    role="presentation"
                    aria-hidden="true"
                    alt="Link Icon"
                    [ngClass]="sublink?.linkIcon"
                  ></span>
                }
                <span [class.overflow-ellipsis]="sublink?.overflowEllipsis">{{
                  sublink.linkText
                }}</span>
              </a>
            }
          </li>
        }
      </ul>
    }
  </div>
</div>
