import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { BillingRegistrationSelectors } from '@amfam/billing/registration/data-access';
import { Alert } from '@amfam/shared/models';

import * as fromRoot from '../../core/store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'alerts-bar',
  templateUrl: './alerts-bar.component.html'
})
export class AlertsBarComponent implements OnInit {
  @Input() alertSelector = 'getConsolidatedAlerts';
  @Input() fromOverview: boolean;

  consolidatedAlerts: Observable<Alert[]>;
  hasRegistrationError$: Observable<boolean>;
  buttonClickedFromErrorModal: Observable<string> = of('');

  constructor(private store: Store) {}

  ngOnInit() {
    this.consolidatedAlerts = this.store.select(fromRoot[this.alertSelector]);
    // Selector to know bill account registration status.
    this.hasRegistrationError$ = this.store.pipe(
      select(BillingRegistrationSelectors.selectPendingRegistrationError)
    );
  }

  /**
   * @description : Once there is an action from the try again modal/ sub component , we pass that
   * info to another component to initiate an action.
   */
  buttonClickedFromModal(eventName: string) {
    this.buttonClickedFromErrorModal = of(eventName);
  }
}
