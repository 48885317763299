import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  BrowserFavicons, BROWSER_FAVICONS_CONFIG, FaviconService
} from './services/favicon.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: FaviconService,
      useClass: BrowserFavicons
    },
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          amfam: {
            type: 'image/x-icon',
            href: './favicons/amfam-favicon.ico',
            isDefault: true
          },
          onstar: {
            type: 'image/x-icon',
            href: './favicons/onstar-favicon.ico',
            isDefault: true
          },
          general: {
            type: 'image/x-icon',
            href: './favicons/general-favicon.ico',
            isDefault: true
          },
          connect: {
            type: 'image/x-icon',
            href: './favicons/connect-favicon.ico',
            isDefault: true
          },
          midvale: {
            type: 'image/x-icon',
            href: './favicons/midvale-favicon.jpg',
            isDefault: true
          }
        },
        cacheBusting: true
      }
    }
  ]
})
export class SharedFaviconModule {}
