import { Action, createReducer, on } from '@ngrx/store';

import {
  completeOnboardingPaperlessEnrollment,
  initiateOnboardingPaperlessEnrollment
} from './onboarding-paperless-enrollment-feature-actions';

export const onboardingPaperlessEnrollmentFeatureKey = 'onboardingPaperlessEnrollmentFeature';

export interface OnboardingPaperlessEnrollmentFeatureState {
  onboardingFlow: boolean;
  correlationId: string;
}

export const initialState: OnboardingPaperlessEnrollmentFeatureState = {
  onboardingFlow: false,
  correlationId: ''
};

export function reducer(
  state: OnboardingPaperlessEnrollmentFeatureState | undefined,
  action: Action
): OnboardingPaperlessEnrollmentFeatureState {
  return mapReducer(state, action);
}

const mapReducer = createReducer(
  initialState,

  on(
    initiateOnboardingPaperlessEnrollment,
    (state, { correlationId }): OnboardingPaperlessEnrollmentFeatureState => ({
      ...state,
      onboardingFlow: true,
      correlationId: correlationId
    })
  ),

  on(
    completeOnboardingPaperlessEnrollment,
    (state): OnboardingPaperlessEnrollmentFeatureState => ({
      ...state,
      onboardingFlow: false,
      correlationId: ''
    })
  )
);
