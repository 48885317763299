import { createFeatureSelector, createSelector } from '@ngrx/store';
import { find as _find } from 'lodash';

import { PolicySelectors as policyQuery, PolicySelectors } from '@amfam/policy/data-access';
import { agentQuery } from '@amfam/shared/utility/agent';

import * as fromVehicleCoverage from './vehicle-coverages.reducer';

export const selectState = createFeatureSelector<// fromVehicleCoverage.PartialState,
fromVehicleCoverage.State>(fromVehicleCoverage.FEATURE_KEY);

const {
  selectIds: selectVehicleCoverageIds,
  selectEntities: selectVehicleCoverageEntities,
  selectAll: selectVehicleCoverageAll,
  selectTotal: selectVehicleCoverageTotal
} = fromVehicleCoverage.adapter.getSelectors();

export const selectIds = createSelector(selectState, (state: fromVehicleCoverage.State) =>
  selectVehicleCoverageIds(state)
);
export const selectEntities = createSelector(selectState, (state: fromVehicleCoverage.State) =>
  selectVehicleCoverageEntities(state)
);
export const selectAll = createSelector(selectState, (state: fromVehicleCoverage.State) =>
  selectVehicleCoverageAll(state)
);
export const selectTotal = createSelector(selectState, (state: fromVehicleCoverage.State) =>
  selectVehicleCoverageTotal(state)
);
export const selectError = createSelector(
  selectState,
  (state: fromVehicleCoverage.State) => state.error
);
export const selectLoaded = createSelector(
  selectState,
  (state: fromVehicleCoverage.State) => state.loaded
);
export const selectCanRecalculate = createSelector(
  selectState,
  (state: fromVehicleCoverage.State) => state.canRecalculate
);
export const selectSelectedId = createSelector(
  selectState,
  (state: fromVehicleCoverage.State) => state.selectedId
);

export const selectSelectedEntity = createSelector(
  selectEntities,
  selectSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
export const selectRate = createSelector(selectSelectedEntity, entity => entity?.rate);

export const selectVehicleCoverages = createSelector(
  selectSelectedEntity,
  entity => entity?.vehicleCoverages || []
);
export const selectPolicyCoverages = createSelector(
  selectSelectedEntity,
  entity => entity?.policyCoverages || []
);

export const selectVehicleId = createSelector(
  selectSelectedEntity,
  entity => entity?.vehicleCoverages[0]?.fixedId
);

export const selectVehicleName = createSelector(
  selectSelectedEntity,
  entity => entity?.vehicleCoverages[0]?.vehicleName || 'no-vehicle-name'
);

export const selectCoverages = createSelector(
  selectSelectedEntity,
  entity =>
    entity?.vehicleCoverages[0]?.coverages.sort((a, b) =>
      a.publicID.toUpperCase() < b.publicID.toUpperCase() ? -1 : 1
    ) || []
);

export const selectAfiCoverages = createSelector(
  selectSelectedEntity,
  entity =>
    // we cant change terms if there are no terms, so I'm removing them (justin)
    entity?.vehicleCoverages[0]?.coverages
      ?.filter(
        c => !(c.hasTerms && c.terms.length === 0) || c.description === 'Emergency Roadside Service'
      )
      .sort((a, b) => (a.terms.length > b.terms.length ? -1 : 1)) || []
);

export const selectAppliedDiscounts = createSelector(
  selectSelectedEntity,
  entity => entity?.vehicleCoverages[0]?.appliedDiscounts
);

export const selectDriverAssignments = createSelector(
  selectSelectedEntity,
  entity => entity.driverAssignments || []
);

export const selectVehicles = createSelector(
  selectSelectedEntity,
  entity => entity.vehiclesInPolicy
);
export const selectFilteredVehicles = createSelector(
  selectDriverAssignments,
  selectVehicles,
  (driverAssignments, vehicles) =>
    vehicles
      .filter(vehicle => !driverAssignments.map(d => d.vin).includes(vehicle.vin))
      .sort((a, b) => (a.description.toUpperCase() < b.description.toUpperCase() ? -1 : 1))
);
export const selectDrivers = createSelector(selectSelectedEntity, entity => entity.drivers);
export const selectDriversFixedIdDisplayName = createSelector(
  selectDrivers,
  drivers =>
    drivers.map(driver => ({ fixedId: driver.fixedId, displayName: driver.displayName })) || []
);
export const selectPrimaryDrivers = createSelector(selectDrivers, drivers =>
  drivers.filter(driver => driver.primary)
);
export const selectFilteredDrivers = createSelector(
  selectDriverAssignments,
  selectDriversFixedIdDisplayName,
  (driverAssignments, drivers) =>
    drivers
      .filter(driver => !driverAssignments.map(d => d.driverId).includes(driver.fixedId))
      .sort((a, b) => (a.displayName < b.displayName ? -1 : 1)) || []
);

export const selectWorkOrderNumberAndPolicyNumber = createSelector(selectSelectedEntity, entity =>
  entity
    ? {
        policyNumber: entity.policyNumber,
        workOrderNumber: entity.submissionId,
        vin: entity.vin
      }
    : null
);

/**
 * Active Agent selectors
 */
export const selectAllActiveAgents = createSelector(
  agentQuery.getRealAgents,
  policyQuery.selectPolicies,
  (agents, policies) =>
    agents.filter(
      agent => agent && _find(policies, p => p.assignedProducer.producerIdNum === agent.id)
    )
);

// MR: List of agents that specifically produced the Advance Auto Policies
export const selectAdvanceAutoPolicyProducingAgents = createSelector(
  selectAllActiveAgents,
  policyQuery.selectAdvanceAutoPolicies,
  (agents, policies) =>
    agents.filter(agent =>
      policies.some(policy => policy.assignedProducer.producerIdNum === agent.id)
    )
);

export const selectVehicleFees = createSelector(
  selectVehicleCoverages,
  vehicleCoverages => vehicleCoverages[0].fees
);

export const selectPNI = createSelector(
  selectWorkOrderNumberAndPolicyNumber,
  PolicySelectors.selectAutoPolicies,
  (policyIdentifier, policies) => {
    if (policyIdentifier && policies) {
      const selectedPolicy = policies.find(
        p => p.policyNumber === policyIdentifier?.policyNumber.replace(/-/gi, '')
      );
      const roles = selectedPolicy.policyRoles;
      const pni = roles.find(role => role.roleType.toLowerCase() === 'primarynamedinsured');
      return pni.firstName + ' ' + pni.lastName;
    }
    return null;
  }
);

export const selectBaseId = createSelector(selectState, state => state.baseId);

export const selectIdsExceptBaseId = createSelector(selectIds, selectBaseId, (ids, baseId) =>
  (ids as string[]).filter(id => id !== baseId)
);

export const selectBaseEntity = createSelector(
  selectEntities,
  selectBaseId,
  (entities, baseId) => baseId && entities[baseId]
);
