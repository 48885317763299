import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PolicySelectors } from '@amfam/policy/data-access';

import { CLAIMFAQ_FEATURE_KEY, ClaimFaqState } from './claim-faq.reducer';

// Lookup the 'ClaimFaq' feature state managed by NgRx
const getClaimFaqState = createFeatureSelector<ClaimFaqState>(CLAIMFAQ_FEATURE_KEY);

export const getClaimsFaqLoading = createSelector(
  getClaimFaqState,
  (state: ClaimFaqState) => state.loading
);

export const getClaimsFaqContent = createSelector(
  getClaimFaqState,
  (state: ClaimFaqState) => state.richContent
);

export const claimFaqQuery = {
  getClaimFaqState,
  getClaimsFaqLoading,
  getClaimsFaqContent
};

export const selectFaqAndRisks = createSelector(
  getClaimsFaqContent,
  PolicySelectors.selectClaimableRiskModels,
  (faq, risks) => ({ faq, risks })
);
