import { PolicyBase, PolicyStatus, Risk } from '@amfam/policy/models';

import { KydSmartphoneRole } from './kyd-smartphone-role.model';
import { KydSmartphoneSummary } from './kyd-smartphone-summary.model';

export class KydSmartphonePolicy extends PolicyBase {
  public policyNumber: string;
  public policyStatus: PolicyStatus;
  public effectiveDate: Date;
  public endEffectiveDate: Date;
  public policyState: string;
  public enrollStatusFlag: {
    enrolled: boolean;
    waitingPeriod: boolean;
    optedOut: boolean;
  };
  public enrollStatus: string;
  public programCategory: string;
  public eligibleForKYD: string;
  public policyRoles: KydSmartphoneRole[];
  public policyRisks: Risk[];
  public enrollmentSummary: KydSmartphoneSummary;
  public _edit: boolean;
  public _allOperatorsEnrolled: boolean;

  constructor() {
    super();
    this.enrollStatus = 'N';
    this.eligibleForKYD = 'Y';
    this.policyRoles = [];
    this.enrollmentSummary = null;
    this.enrollStatusFlag = {
      enrolled: false,
      waitingPeriod: false,
      optedOut: true
    };
    this._edit = false;
    this._allOperatorsEnrolled = false;
  }

  public assignProperties(json: any) {
    super.assignProperties(json);
    this.policyStatus = json.policyStatus;
    this.effectiveDate = json.periodStartDate;
    this.policyState = json.policyAddress.state;
    this.endEffectiveDate = json.periodEndDate;
    this.policyRisks = json.policyRisks;
    this.sourcePath = json.sourcePath;
    this.sourceSystem = json.sourceSystem;
  }
}

export class KydCustomerDataModel {
  id: string;
  customerId?: string;
  fullName: string;
  phoneNumber: string;
  status: string;
  editPhone?: string;
  editStatus?: string;
  isEditStatus?: boolean;
  isEditPhone?: boolean;
  isPhoneValid?: boolean;
}
