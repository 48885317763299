/* eslint-disable arrow-body-style */
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ProfileAnalytics } from '@amfam/shared/models';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DeleteService } from '../../delete';
import * as deleteActions from '../delete-myaccount/delete.actions';

@Injectable()
export class DeleteEffects {
  authenticate$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteActions.AUTHENTICATE_USER),
      map((action: deleteActions.AuthenticateUserAction) => action.payload),
      switchMap(payload => {
        return this.deleteService.authenticate(payload).pipe(
          map((response: any) => {
            return new deleteActions.AuthenticateUserActionSuccess(response);
          }),
          catchError(error => {
            if (error.status.code === 423) {
              this.analyticsFacade.trackEvent(ProfileAnalytics.deleteMyAccountAccountLocked);
            }
            if (error.status === 401) {
              this.analyticsFacade.trackEvent(ProfileAnalytics.deleteMyAccountInvalidPassword);
            }
            return of(new deleteActions.AuthenticateUserActionFail(error));
          })
        );
      })
    );
  });

  unenrollPrograms$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteActions.UNENROLL_PROGRAMS),
      map((action: deleteActions.UnenrollProgramsAction) => action),
      switchMap(payload => {
        return this.deleteService.unenrollPrograms().pipe(
          map((response: any) => {
            if (!this.isUnenrollmentSuccessful(response)) {
              const error = { status: { code: response.status.messages[0].status } };
              this.analyticsFacade.trackEvent(ProfileAnalytics.deleteMyAccountFailure);
              return new deleteActions.UnenrollUserActionFail(error);
            }
            return new deleteActions.UnenrollUserActionSuccess(response);
          }),
          catchError(error => {
            this.analyticsFacade.trackEvent(ProfileAnalytics.deleteMyAccountFailure);
            return of(new deleteActions.UnenrollUserActionFail(error));
          })
        );
      })
    );
  });

  deleteMyaccount$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteActions.DELETE_MYACCOUNT_ACTION),
      map((action: deleteActions.DeleteMyAccountAction) => action),
      switchMap(payload => {
        return this.deleteService.deleteMyaccount().pipe(
          map((response: any) => {
            return new deleteActions.DeleteMyAccountActionSuccess(response);
          }),
          catchError(error => {
            this.analyticsFacade.trackEvent(ProfileAnalytics.deleteMyAccountFailure);
            return of(new deleteActions.DeleteMyAccountActionFail(error));
          })
        );
      })
    );
  });

  sendTemplatedEmails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteActions.SEND_TEMPLATED_EMAIL_ACTION),
      map((action: deleteActions.SendTemplatedEmailsAction) => action.payload),
      switchMap(payload => {
        return this.deleteService.sendTemplatedEmails(payload).pipe(
          map((response: any) => {
            return new deleteActions.SendTemplatedEmailsActionSuccess(response);
          }),
          catchError(error => {
            this.analyticsFacade.trackEvent(ProfileAnalytics.deleteMyAccountFailure);
            return of(new deleteActions.SendTemplatedEmailsActionFail(error));
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private analyticsFacade: AnalyticsFacade,
    private deleteService: DeleteService
  ) {}

  isUnenrollmentSuccessful = response => {
    // when messages is empty unenrollment was successful
    if (response.status.messages.length === 0) {
      return true;
    } else {
      // check if all the messages have response as 20000 then unenrollment was successful
      return response.status.messages.every(message => message.code === 20000);
    }
  };
}
