import { createAction } from '@ngrx/store';

import {
  FetchPciTokenModel,
  FinancialAccountResponseModel
} from '../models/financial-institution.models';

export enum FinancialInstitutionActionTypes {
  FINANCIAL_INSTITUTION = '[FinancialInstitution] Get FinancialInstitution',
  FINANCIAL_INSTITUTION_SUCCESS = '[FinancialInstitution] Get FinancialInstitution Success',
  FINANCIAL_INSTITUTION_FAIL = '[FinancialInstitution] Get FinancialInstitution Fail',
  FETCH_AUTH_TOKEN = '[FinancialInstitution] Fetch Auth Token',
  RESET_FINANCIAL_INSTITUTION = '[FinancialInstitution] Get FinancialInstitution Reset'
}

export const GetFinancialInstitution = createAction(
  FinancialInstitutionActionTypes.FINANCIAL_INSTITUTION,
  (payload: string) => ({ payload })
);

export const GetFinancialInstitutionSuccess = createAction(
  FinancialInstitutionActionTypes.FINANCIAL_INSTITUTION_SUCCESS,
  (payload: FinancialAccountResponseModel) => ({ payload })
);

export const GetFinancialInstitutionFail = createAction(
  FinancialInstitutionActionTypes.FINANCIAL_INSTITUTION_FAIL,
  (error: unknown) => ({ error })
);

export const ResetFinancialInstitution = createAction(
  FinancialInstitutionActionTypes.RESET_FINANCIAL_INSTITUTION
);

export const FetchPciTokenAction = createAction(
  FinancialInstitutionActionTypes.FETCH_AUTH_TOKEN,
  (payload: FetchPciTokenModel) => ({ payload })
);

export type FinancialInstitutionAction =
  | ReturnType<typeof GetFinancialInstitution>
  | ReturnType<typeof GetFinancialInstitutionSuccess>
  | ReturnType<typeof GetFinancialInstitutionFail>
  | ReturnType<typeof ResetFinancialInstitution>
  | ReturnType<typeof FetchPciTokenAction>;

export const fromFinancialInstitutionActions = {
  GetFinancialInstitution,
  GetFinancialInstitutionSuccess,
  GetFinancialInstitutionFail,
  ResetFinancialInstitution,
  FetchPciTokenAction
};
