export class InternalEmail {
  public messageFrom: Addressee;
  public messageToList: Addressee[];
  public messageReplyToList: Addressee[];
  public messageCcList: Addressee[];
  public messageBccList: Addressee[];
  public messageSubject: string;
  public messageContent: string;
  public messageContentType: string;
  public messageKeywords: string[];
  public messageComments: string[];
  public messageHeaders: Header[];

  constructor() {
    this.messageFrom = new Addressee(null, null);
    this.messageToList = new Array<Addressee>();
    this.messageReplyToList = new Array<Addressee>();
    this.messageBccList = new Array<Addressee>();
    this.messageCcList = new Array<Addressee>();
    this.messageContentType = 'text/html';
    this.messageKeywords = new Array<string>();
    this.messageComments = new Array<string>();
    this.messageHeaders = new Array<Header>();
  }
}

export class Addressee {
  constructor(
    public address: string,
    public name: string
  ) {}
}

export class Header {
  constructor(
    public key: string,
    public value: string
  ) {}
}

export class MimeDetail {
  constructor() {}
}
