import { Claim } from '@amfam/claim/data-access';
import { BrandSelectors, BrandModel as BrandState } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { ConfigService, CopyService } from '@amfam/shared/utility/shared-services';
import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Adjuster } from '../models/adjuster.model';
import { AdjusterListService } from '../services/adjuster-list.service';

@Component({
  selector: 'adjuster-list',
  templateUrl: './adjuster-list.component.html',
  styleUrls: ['./adjuster-list.component.scss']
})
export class AdjusterListComponent implements OnChanges {
  @Input() public claim: Claim;
  public adjuster: Adjuster;
  amfamTel: string;
  claimsStatusEnabled: boolean;
  amfamPhone: string = this.copyService.get('shared', 'amfamPhoneNumber');

  constructor(
    private adjusterList: AdjusterListService,
    private copyService: CopyService,
    private feature: FeatureFlagService,
    private store: Store<BrandState>,
    private config: ConfigService
  ) {
    this.amfamTel = this.config.get('numberLink');
    // Check Claim Status feature flag.
    this.store
      .select(BrandSelectors.selectIsPartner)
      .pipe(take(1))
      .subscribe(isPartner => {
        this.claimsStatusEnabled = !isPartner || this.feature.isEnabled('partner_dynamic_fnol');
      });
  }

  ngOnChanges() {
    this.adjuster = this.adjusterList.getAdjuster(this.claim);
  }
}
