import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { take } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ResetOptionModel } from '@amfam/shared/models';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { LoadingSpinnerService, ToasterService } from '@amfam/ui-kit';

import * as fromRoot from '../../../../core/store';
import { ForgotService } from '../../../shared/forgot.service';
import { ForgotPasswordAnalytics } from '../../shared/forgot-password-analytic-constants';

@Component({
  selector: 'ds-reset-password-email',
  templateUrl: './reset-password-email.component.html',
  styleUrls: ['../../forgot-password.component.scss']
})
export class ResetPasswordEmailComponent implements OnInit, OnDestroy {
  maskedEmail: string;
  userIdentifier: string;
  gotError = false;
  initialLoading = true;

  constructor(
    private store: Store<fromRoot.RootState>,
    private spinner: LoadingSpinnerService,
    private toaster: ToasterService,
    private analyticsFacade: AnalyticsFacade,
    private forgotService: ForgotService
  ) {}

  ngOnInit() {
    this.store
      .select(fromRoot.getResetPasswordUserIdentifier)
      .pipe(take(1))
      .subscribe(userIdentifier => {
        this.userIdentifier = userIdentifier;
        this.sendEmail();
      });

    this.analyticsFacade.trackPage(ForgotPasswordAnalytics.pageForgotPasswordEmail);
  }

  sendEmail() {
    this.spinner.start();

    const requestObj: ResetOptionModel = {
      userIdentifier: this.userIdentifier,
      typeOfVerificationMethodCode: 'EMAIL'
    };

    this.forgotService
      .sendVerificationRequest(requestObj)
      .pipe(take(1))
      .subscribe(
        res => {
          this.spinner.stop();
          const apiCode = _get(res, 'status.code');
          if (apiCode === 200) {
            this.toaster.pop('success', 'We will be sending you an email shortly');
            this.initialLoading = false;
            this.maskedEmail = res.maskedEmailAddress;
            this.gotError = false;
          } else {
            this.initialLoading = false;
            this.gotError = true;
            this.toaster.pop('error', 'There was en error sending the email. Please try again.');
          }
        },
        err => {
          this.spinner.stop();
          this.initialLoading = false;
          this.gotError = true;
          this.toaster.pop('error', 'There was en error sending the email. Please try again.');
        }
      );
  }

  goBackOneStep() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/forgot-password/reset-options']
      })
    );
  }

  ngOnDestroy() {
    this.spinner.stop();
  }
}
