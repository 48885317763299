import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { Result } from '@amfam/policy/models';
import { loadVehicleCoverages } from '@amfam/policy/vehicle-coverages/data-access';
import { BrandSelectors } from '@amfam/shared/utility/brand';

import { AddVehicleInitiateResponse } from '../../models/add-vehicle-initiate.model';
import { AddVehicleService } from '../../services/add-vehicle.service';
import {
  addVehicleInitiate,
  addVehicleInitiateError,
  addVehicleInitiateSuccess,
  addVehicleTPMAvailibilityCheckError,
  addVehicleTPMAvailibilityCheckSuccess
} from './add-vehicle-info.actions';

@Injectable()
export class AddVehicleInfoEffects {
  getAddVehicle$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(addVehicleInitiate),
      map(action => action.payload),
      concatLatestFrom(() => this.store.select(BrandSelectors.selectIsPartner)),
      // TODO: justin
      switchMap(([payload, isPartner]) =>
        this.addVehicleService.initiateAddVehicleFeature(payload.request, isPartner).pipe(
          map((response: AddVehicleInitiateResponse) => {
            const path = [];
            // If vehicle has TPI< route to loan info page before going to driver info
            if (payload.hasTPI && _get(response, 'result.tpis', []).length) {
              path.push('policies/add-vehicle/loan-info');
            } else {
              path.push('policies/add-vehicle/driver-info');
            }
            return addVehicleInitiateSuccess({ payload: response, nextRoutePath: path });
          }),
          catchError(error =>
            of(addVehicleInitiateError({ payload: { status: error, request: payload.request } }))
          )
        )
      )
    );
  });

  getTPMPackageAvailability$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(addVehicleInitiateSuccess),
      map(action => {
        const result = action.payload.result as unknown as Result;
        // eslint-disable-next-line ngrx/no-dispatch-in-effects
        this.store.dispatch(
          loadVehicleCoverages({ payload: [{ ...result, driverAssignments: [], id: uuid() }] })
        );
        return { ...action.payload };
      }),
      switchMap((payload: AddVehicleInitiateResponse) =>
        zip(
          this.addVehicleService.getTPMAvailibility(
            payload.result.policyNumber.replace(/-/g, ''),
            payload.result.submissionId
          ),
          this.addVehicleService.getEnrollments(payload.result.policyNumber.replace(/-/g, ''))
        ).pipe(
          map(([hasTPMPackage, enrollment]) =>
            addVehicleTPMAvailibilityCheckSuccess({
              payload: {
                hasTPMPackage:
                  hasTPMPackage &&
                  _get(enrollment, 'enrollmentSummary.enrollmentStatus', '') === 'Enrolled',
                policyNumber: payload.result.policyNumber.replace(/-/g, '')
              }
            })
          ),
          catchError(() =>
            of(
              addVehicleTPMAvailibilityCheckError({
                payload: {
                  hasTPMPackage: false,
                  policyNumber: payload.result.policyNumber.replace(/-/g, '')
                }
              })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private addVehicleService: AddVehicleService,
    private store: Store
  ) {}
}
