import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { FutureState } from './future-state';
import { FutureStateService } from './future-state.service';

@Directive({
  selector: '[toggleFutureState]'
})
export class ToggleFutureStateDirective implements OnInit, OnDestroy {
  private el: HTMLElement;
  private futureState: FutureState = null;

  private sub: any;

  constructor(
    private elRef: ElementRef,
    private futureStateSvc: FutureStateService
  ) {
    this.el = elRef.nativeElement;
    this.sub = this.futureStateSvc.futureState$.subscribe(
      futureState => {
        this.futureState = futureState;
        this.update();
      },
      error => console.log(error)
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private update() {
    if (this.futureState.showFutureState) {
      this.el.style.display = 'block';
    } else {
      this.el.style.display = 'none';
    }
  }
}
