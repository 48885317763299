import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';

import { Option } from '../ds-radio-group/option.model';

@Component({
  selector: 'ds-checkbox-group',
  templateUrl: './ds-checkbox-group.component.html',
  styleUrls: ['./ds-checkbox-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DsCheckboxGroupComponent),
      multi: true
    }
  ]
})
export class DsCheckboxGroupComponent implements ControlValueAccessor {
  @Input() options: Option[];
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() displayClass: string;
  @Output() optionSelected = new EventEmitter();
  @Output() option = new EventEmitter();

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  set value(val) {
    this.onChange(val);
    this.onTouched(val);
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  toggleDynamicOption(option: UntypedFormControl, optionId: string, selectedOption = {}) {
    if (!option.disabled) {
      option.setValue(!option.value);
      this.optionSelected.emit(optionId);
      this.option.emit(Object.assign({}, selectedOption, { isSelected: option.value }));
    }
  }
}
