import { AbstractControl } from '@angular/forms';
import { EntityMap, EntityMapOne, Predicate, Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { Result, Vehicle } from '@amfam/policy/models';
import { ApiStatus } from '@amfam/shared/models';

export enum FEATURES {
  ADD = 'ADD',
  REPLACE = 'REPLACE',
  CHANGE = 'CHANGE'
}

export const loadVehicleCoverages = createAction(
  '[VehicleCoverage/API] Load VehicleCoverages',
  props<{ payload: Result[] }>()
);
export const setVehicleCoverages = createAction(
  '[VehicleCoverage/API] Set VehicleCoverages',
  props<{ payload: Result[] }>()
);
export const addVehicleCoverage = createAction(
  '[VehicleCoverage/API] Add VehicleCoverage',
  props<{ payload: Result; feature: FEATURES; vehicleId?: string; rate?: boolean }>()
);
export const addVehicleCoverageForChangeCoverage = createAction(
  '[VehicleCoverage/API] Add VehicleCoverage For Change Coverage',
  props<{ payload: Result; feature: FEATURES; vehicleId?: string; rate?: boolean }>()
);
export const addBaseToSelectedVehicleCoverage = createAction(
  '[VehicleCoverage/API] Add Base To Selected VehicleCoverage',
  props<{ payload: Result; feature: FEATURES; vehicleId?: string; rate?: boolean }>()
);
export const setVehicleCoverage = createAction(
  '[VehicleCoverage/API] Set VehicleCoverage',
  props<{ payload: Result }>()
);
export const upsertVehicleCoverage = createAction(
  '[VehicleCoverage/API] Upsert VehicleCoverage',
  props<{ payload: Result }>()
);
export const addVehicleCoverages = createAction(
  '[VehicleCoverage/API] Add VehicleCoverages',
  props<{ payload: Result[] }>()
);
export const upsertVehicleCoverages = createAction(
  '[VehicleCoverage/API] Upsert VehicleCoverages',
  props<{ payload: Result[] }>()
);
export const updateVehicleCoverage = createAction(
  '[VehicleCoverage/API] Update VehicleCoverage',
  props<{ update: Update<Result>; control?: AbstractControl; controlValue?: string }>()
);
export const updateCoverage = createAction(
  '[VehicleCoverage/API] Update Coverage',
  props<{ update: Update<Result> }>()
);
export const updateDriverAssignmentControl = createAction(
  '[VehicleCoverage/API] Update driver assignment control',
  props<{ control?: AbstractControl; controlValue?: string }>()
);
export const updateVehicleCoverages = createAction(
  '[VehicleCoverage/API] Update VehicleCoveragess',
  props<{ updates: Update<Result>[] }>()
);
export const mapVehicleCoverage = createAction(
  '[VehicleCoverage/API] Map VehicleCoverages',
  props<{ entityMap: EntityMapOne<Result> }>()
);
export const mapVehicleCoverages = createAction(
  '[VehicleCoverage/API] Map VehicleCoverages',
  props<{ entityMap: EntityMap<Result> }>()
);
export const loadVehicleCoveragesError = createAction(
  '[VehicleCoverage/API] Load VehicleCoveragesError'
);

export const deleteVehicleCoverage = createAction(
  '[VehicleCoverage/API] Delete VehicleCoverage',
  props<{ id: string }>()
);
export const deleteVehicleCoverages = createAction(
  '[VehicleCoverage/API] Delete VehicleCoverages',
  props<{ ids: string[] }>()
);
export const deleteVehicleCoveragesByPredicate = createAction(
  '[VehicleCoverage/API] Delete VehicleCoverages By Predicate',
  props<{ predicate: Predicate<Result> }>()
);
export const clearVehicleCoverages = createAction('[VehicleCoverage/API] Clear VehicleCoverages');
export const saveAndRate = createAction(
  '[VehicleCoverage/API] Save and Rate',
  props<{ feature: FEATURES; vehicleId: string | undefined }>()
);

export const LoadInitialRate = createAction(
  '[VehicleCoverage/API] Load Initial Rate',
  props<{ feature: FEATURES; vehicleId: string | undefined; initial: boolean }>()
);

export const saveAndRateError = createAction(
  '[VehicleCoverage/API] Save and Rate Error',
  props<{ feature: FEATURES; vehicleId: string }>()
);

// Change coverage in this context represents a call to the /change-coverage api
export const changeCoverageAPI = createAction(
  '[VehicleCoverage/API] Change Coverage',
  props<{ vehicle: Vehicle }>()
);

export const cancelChangeCoverage = createAction('[VehicleCoverage/API] Cancel Change Coverage');

// Change coverage in this context represents a call to the /change-coverage api
export const changeCoverageAPISuccess = createAction(
  '[VehicleCoverage/API] Change Coverage Success',
  props<{ vehicle: Vehicle }>()
);

// Change coverage in this context represents a call to the /change-coverage api
export const changeCoverageAPIError = createAction(
  '[VehicleCoverage/API] Change Coverage Error',
  props<{ vehicleId: number | string }>()
);

export const resetQuote = createAction('[VehicleCoverage/API] Reset Quote');
export const emptyAction = createAction('[VehicleCoverage/API] EMPTY');

export const bindChangeCoverageQuote = createAction(
  '[VehicleCoverage/API] Change Coverage Quote',
  props<{
    payload: {
      workOrderNumber: string;
      policyNumber: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      vehicleId: string | any;
      vin: string;
    };
  }>()
);

export const bindChangeCoverageQuoteSuccess = createAction(
  '[VehicleCoverage/API] Change Coverage Quote Success',
  props<{ payload: ApiStatus }>()
);

export const bindChangeCoverageQuoteError = createAction(
  '[VehicleCoverage/API] Change Coverage Quote Error',
  props<{ vehicleId: number | string }>()
);

export const bindPartnerAddVehicleChangeCoverageQuote = createAction(
  '[VehicleCoverage/API] Partner Add Vehicle Change Coverage Quote',
  props<{
    payload: {
      workOrderNumber: string;
      policyNumber: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      vehicleId: string | any;
      vin: string;
    };
  }>()
);

export const bindPartnerAddVehicleChangeCoverageQuoteSuccess = createAction(
  '[VehicleCoverage/API] Partner Add Vehicle Change Coverage Quote Success',
  props<{ payload: ApiStatus }>()
);

export const bindPartnerAddVehicleChangeCoverageQuoteError = createAction(
  '[VehicleCoverage/API] Partner Add Vehicle Change Coverage Quote Error',
  props<{ vehicleId: number | string }>()
);

export const initializeChangeCoverage = createAction(
  '[VehicleCoverage/API] Add Initialize',
  props<{ payload: Result; feature: FEATURES; vehicleId?: string; rate?: boolean }>()
);

export const setBaseCoverage = createAction(
  '[VehicleCoverage/API] Set Base VehicleCoverage',
  props<{ payload: Result; feature: FEATURES; vehicleId?: string; rate?: boolean }>()
);

export const resetAll = createAction('[VehicleCoverage/API] Reset All');

export const clearError = createAction('[VehicleCoverage/API] Clear Error');
