<ds-modal
  [id]="registrationModal"
  [closeOthers]="true"
  [maxWidth]="modalWidth"
  (onCloseModalEvent)="close('registrationModal')"
  (initialized)="modalInitialized($event)"
>
  <div class="row pad-20">
    <div class="registration-modal-heading column twelve">
      <span class="error-600-text icon-alert margin-right-10"></span>
      <h3>Oops, something went wrong</h3>
    </div>
    <div class="registration-modal-content column twelve pad-bottom-20">
      <p class="pad-bottom-20">
        We were unable to complete paperless document enrollment for some of your accounts. Please
        call us or try again.
      </p>
      <!-- Success List -->
      @for (registered of userRegisteredBillAccounts | async; track registered) {
        <span>
          <div class="list-item">
            <span class="success-400-text icon-complete-circle margin-right-10"></span>Bills for
            account ending in
            {{ registered.billAccountNumber | returnLast: 4 }}
          </div>
        </span>
      }
      <!-- Failed list -->
      @for (unregistered of unregisteredBillAccounts | async; track unregistered) {
        <span>
          @if (unregistered.billAccountNumber) {
            <div class="list-item">
              <span class="error-600-text icon-alert margin-right-10"></span> Bills for account
              ending in
              {{ unregistered.billAccountNumber | returnLast: 4 }}
            </div>
          }
        </span>
      }
      <!-- Policy Documents -->
      <div class="list-item">
        @if (!!(isEnrolled | async)) {
          <span class="success-400-text icon-complete-circle margin-right-10"></span>
        }
        @if (!(isEnrolled | async)) {
          <span class="error-600-text icon-alert margin-right-10"></span>
        }
        <div>
          Policy documents<br /><span class="caption">Insurance cards and coverage documents</span>
        </div>
      </div>
    </div>
    <div class="registration-modal-cta column twelve">
      <button
        ds-button
        (click)="registrationTryAgain()"
        [disabled]="disableTryAgain"
        class="-outline fill"
      >
        Try Again
      </button>
      <div class="text-center pad-top-10">
        Call us at:
        <a href="javascript:void(0)" (click)="openContactUs()">
          <span *dsPartnerAFI>{{ amfamPhone }}</span>
          <span *dsPartnerConnect dsPartnerContent [brandContent]="'selectPrettyCusCareNumber'"></span>
        </a>
      </div>
    </div>
  </div>
</ds-modal>

<ds-modal
  [id]="registrationTryAgainModal"
  [closeOthers]="true"
  [maxWidth]="modalWidth"
  (onCloseModalEvent)="close('registrationTryAgainModal')"
>
  <div class="row pad-20">
    <div class="registration-modal-heading column twelve">
      <span class="error-600-text icon-alert margin-right-10"></span>
      <h3>Please call us</h3>
    </div>
    <div class="registration-modal-content column twelve pad-bottom-20">
      <p class="pad-bottom-20">We were unable to complete paperless document enrollment.</p>
      <!-- Success List -->
      @for (registered of userRegisteredBillAccounts | async; track registered) {
        <span>
          <div class="list-item">
            <span class="success-400-text icon-complete-circle margin-right-10"></span>Bills for
            account ending in
            {{ registered.billAccountNumber | returnLast: 4 }}
          </div>
        </span>
      }
      <!-- Failed list -->
      @for (unregistered of unregisteredBillAccounts | async; track unregistered) {
        <span>
          @if (unregistered.billAccountNumber) {
            <div class="list-item">
              <span class="error-600-text icon-alert margin-right-10"></span> Bills for account
              ending in
              {{ unregistered.billAccountNumber | returnLast: 4 }}
            </div>
          }
        </span>
      }
      <!-- Policy Documents -->
      <div class="list-item">
        @if (!!(isEnrolled | async)) {
          <span class="success-400-text icon-complete-circle"></span>
        }
        @if (!(isEnrolled | async)) {
          <span class="error-600-text icon-alert margin-right-10"></span>
        }
        <div>
          Policy documents<br /><span class="caption">Insurance cards and coverage documents</span>
        </div>
      </div>
    </div>
    <div class="registration-modal-cta column twelve">
      <div class="text-center pad-top-10">
        Call us at:
        <a href="javascript:void(0)" (click)="openContactUs()">
          <span *dsPartnerAFI>{{ amfamPhone }}</span>
          <span *dsPartnerConnect dsPartnerContent [brandContent]="'selectPrettyCusCareNumber'"></span>
        </a>
      </div>
    </div>
  </div>
</ds-modal>
