import { Dictionary } from '@ngrx/entity';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get, some as _some } from 'lodash';

import { PaymentAccount, UNAVAILABLE_PAYMENTACCOUNT_ID } from '@amfam/shared/models';

import * as fromPaymentAccountNotifications from './notifications.reducer';
import * as fromPaymentAccountState from './paymentaccount.reducer';
import { PAYMENT_ACCOUNT_FEATURE_KEY } from './paymentaccount.reducer';

export interface PaymentAccountState {
  paymentAccountState: fromPaymentAccountState.PaymentAccountEntityState;
  paymentAccountNotifications: fromPaymentAccountNotifications.NotificationState;
}

export const paymentAccountReducers: ActionReducerMap<PaymentAccountState> = {
  paymentAccountState: fromPaymentAccountState.reducer,
  paymentAccountNotifications: fromPaymentAccountNotifications.reducer
};

export const selectPaymentAccountsState = createFeatureSelector<PaymentAccountState>(
  PAYMENT_ACCOUNT_FEATURE_KEY
);

export const selectPaymentAccountsLoading = createSelector(
  selectPaymentAccountsState,
  (state: PaymentAccountState) => _get(state, 'paymentAccountNotifications.loading', true)
);

export const selectPaymentAccountsLoaded = createSelector(
  selectPaymentAccountsState,
  (state: PaymentAccountState) => !_get(state, 'paymentAccountNotifications.loading', true)
);

export const selectPaymentAccountEntities = createSelector(
  selectPaymentAccountsState,
  selectPaymentAccountsLoaded,
  (state: PaymentAccountState, isLoaded) =>
    isLoaded
      ? <Dictionary<PaymentAccount>>(
          fromPaymentAccountState.selectEntities(state.paymentAccountState)
        )
      : []
);

export const selectPaymentAccountIds = createSelector(
  selectPaymentAccountsState,
  selectPaymentAccountsLoaded,
  (state: PaymentAccountState, isLoaded) =>
    isLoaded ? <string[]>fromPaymentAccountState.selectIds(state.paymentAccountState) : []
);

export const selectPaymentAccountsError = createSelector(
  selectPaymentAccountsState,
  (state: PaymentAccountState) => !!_get(state, 'paymentAccountNotifications.error')
);

export const selectAllPaymentAccounts = createSelector(
  selectPaymentAccountEntities,
  selectPaymentAccountIds,
  (entities, ids) => <PaymentAccount[]>ids.map(id => entities[id])
);

export const selectPaymentAccountsWithoutUnavailable = createSelector(
  selectPaymentAccountEntities,
  selectPaymentAccountIds,
  (entities, ids) => ids.filter(id => id !== UNAVAILABLE_PAYMENTACCOUNT_ID).map(id => entities[id])
);

export const selectMatchedPaymentAccount = createSelector(
  selectPaymentAccountEntities,
  (paymentAccounts, paymentId: string) => paymentAccounts[paymentId]
);

export const selectPaymentAccountsExpiredFirst = createSelector(
  selectPaymentAccountsWithoutUnavailable,
  (paymentAccounts = []) =>
    paymentAccounts.slice().sort((a: PaymentAccount, b: PaymentAccount) => {
      // Assign a weight of 0 for expired payment accounts, 1 for expiring payment accounts, 2 for not expired/expiring payment accounts
      const aValue = a.expired ? 0 : a.expiring ? 1 : 2;
      const bValue = b.expired ? 0 : b.expiring ? 1 : 2;

      if (aValue > bValue) {
        return 1;
      } else if (aValue < bValue) {
        return -1;
      }
      return 0;
    })
);

export const selectPaymentAccountsExpiredLast = createSelector(
  selectPaymentAccountsExpiredFirst,
  (paymentAccounts = []) => paymentAccounts.slice().reverse()
);

export const selectPaymentAccountsNotificationsEntities = createSelector(
  selectPaymentAccountsState,
  selectPaymentAccountsLoaded,
  (state: PaymentAccountState, isLoaded) =>
    isLoaded
      ? fromPaymentAccountNotifications.selectEntities(state.paymentAccountNotifications)
      : []
);

export const selectPaymentAccountsNotificationsIds = createSelector(
  selectPaymentAccountsState,
  selectPaymentAccountsLoaded,
  (state: PaymentAccountState, isLoaded) =>
    isLoaded
      ? <string[]>fromPaymentAccountNotifications.selectIds(state.paymentAccountNotifications)
      : []
);

export const selectPaymentAccountsNotifications = createSelector(
  selectPaymentAccountsNotificationsEntities,
  selectPaymentAccountsNotificationsIds,
  (entities, ids) =>
    <fromPaymentAccountNotifications.PaymentAccountNotificationState[]>ids.map(id => entities[id])
);

export const selectPaymentAccountsNotificationsWithoutUnavailable = createSelector(
  selectPaymentAccountsNotificationsEntities,
  selectPaymentAccountsNotificationsIds,
  (entities, ids) =>
    <fromPaymentAccountNotifications.PaymentAccountNotificationState[]>(
      ids.filter(id => id !== UNAVAILABLE_PAYMENTACCOUNT_ID).map(id => entities[id])
    )
);

export const selectPaymentAccountsAnyLoading = createSelector(
  selectPaymentAccountsNotifications,
  selectPaymentAccountsLoading,
  (paymentAccounts, paymentAccountsLoading) =>
    paymentAccountsLoading || _some(paymentAccounts, { loading: true })
);

export const selectPaymentAccounts = (correlationId: string) =>
  createSelector(selectPaymentAccountsNotificationsWithoutUnavailable, paymentAccounts =>
    paymentAccounts.filter(pa => pa.correlationId === correlationId)
  );

export const paymentAccountQuery = {
  selectPaymentAccountsLoading,
  selectPaymentAccountsLoaded,
  selectPaymentAccountEntities,
  selectPaymentAccountIds,
  selectPaymentAccountsError,
  selectAllPaymentAccounts,
  selectPaymentAccountsWithoutUnavailable,
  selectMatchedPaymentAccount,
  selectPaymentAccountsExpiredFirst,
  selectPaymentAccountsExpiredLast,
  selectPaymentAccountsNotificationsEntities,
  selectPaymentAccountsNotificationsIds,
  selectPaymentAccountsNotifications,
  selectPaymentAccountsNotificationsWithoutUnavailable,
  selectPaymentAccountsAnyLoading,
  selectPaymentAccounts
};
