import { createAction, props } from '@ngrx/store';

import {
  AddVehicleCoverage,
  AddVehicleRateResponse,
  typeOfAgentEmail,
  vehicleOwnershipDate
} from '@amfam/policy/add-vehicle/data-access';
import { CoverageOptionModelWithControl } from '@amfam/policy/add-vehicle/ui';
import { Address } from '@amfam/policy/models';
import { Agent } from '@amfam/shared/utility/agent';

import {
  DriverInfoModel,
  PrimaryUsageInfo,
  TPIModel,
  VehicleFeaturesInfoModel,
  VehicleInfo,
  VehicleUnderwritingPartnerQuestionsModels,
  VehicleUnderwritingQuestionsModels
} from '../models';
import { AddVehicleAnalyticsModel } from '../models/add-vehicle-analytics.model';

export const setVehicleYearVinOdo = createAction(
  '[Add Vehicle] Set year vin odo',
  props<VehicleInfo['primaryVehicleInfo']>()
);

export const setNewVehicleDiscount = createAction(
  '[Add Vehicle] Set new vehicle discount',
  props<{ isNewVehicleDiscountActive: boolean }>()
);

export const setVehicleUsageInfo = createAction(
  '[Add Vehicle] Set vehicle usage info',
  props<{ vehicleUsage: PrimaryUsageInfo }>()
);

export const setVehicleownershipInfo = createAction(
  '[Add Vehicle] Set vehicle ownershipDate info',
  props<{ vehicleUsage: vehicleOwnershipDate }>()
);

export const setGarageInfo = createAction(
  '[Add Vehicle] Set vehicle garage info',
  props<{ garageAddress: Address }>()
);

export const fetchVehicleFeature = createAction('[Add Vehicle] Fetch new vehicle feature');

export const addVehicleInitiate = createAction(
  '[Add Vehicle] Initiate add new vehicle',
  props<{ hasTPI: boolean }>()
);

export const addVehicleRate = createAction('[Add Vehicle] Rate add new vehicle');

export const addVehicleResetRate = createAction('[Add Vehicle] Reset add new vehicle');

export const addVehicleNavigateToFuturePaymentSchedule = createAction(
  '[Add Vehicle] Add new vehicle navigate to future payment schedule after rate success',
  props<{ navigateToFuturePaymentSchedule: boolean }>()
);

export const setVehicleFeaturesInfo = createAction(
  '[Add Vehicle] Set new vehicle features',
  props<{ featureInfo: VehicleFeaturesInfoModel }>()
);

export const setVehicleUnderwritingQuestionsInfo = createAction(
  '[Add Vehicle] Set new vehicle underwriting questions info',
  props<{
    underwritingQuestions:
      | VehicleUnderwritingQuestionsModels
      | VehicleUnderwritingPartnerQuestionsModels;
  }>()
);

export const setVehicleTPIsInfo = createAction(
  '[Add Vehicle] Set new vehicle TPIs info',
  props<{ tpisInfo: TPIModel }>()
);

export const setVehicleLeaseStartDate = createAction(
  '[Add Vehicle] Set new vehicle Lease Start Date',
  props<{ leaseStartDate: any }>()
);

export const setVehiclePrimaryDriversInfo = createAction(
  '[Add Vehicle] Set new vehicle primary drivers info',
  props<{ driversInfo: DriverInfoModel[] }>()
);

export const setVehicleSecondaryDriversInfo = createAction(
  '[Add Vehicle] Set new vehicle secondary drivers info',
  props<{ driversInfo: DriverInfoModel[] }>()
);

export const setVehicleCoveragesInfo = createAction(
  '[Add Vehicle] Set new vehicle coverages info',
  props<{ vehicleCoverages: AddVehicleCoverage[] }>()
);

export const addVehicleSendNotification = createAction(
  '[Add Vehicle] add new vehicle notification',
  props<{
    payload: {
      agent: Agent;
      typeOfEmail: typeOfAgentEmail;
      workOrderNumber?: string;
      classicOnlyAutoPolicyOrCompOnlyAdv: boolean;
    };
  }>()
);

export const addVehicleSendNotificationAfterBind = createAction(
  '[Add Vehicle] add new vehicle notification after bind',
  props<{ payload: { agent: Agent; typeOfEmail: typeOfAgentEmail; workOrderNumber?: string } }>()
);

export const addVehicleSendNotificationAfterBindError = createAction(
  '[Add Vehicle] add new vehicle notification after bind',
  props<{ error: any }>()
);

export const addVehicleBindQuote = createAction(
  '[Add Vehicle] add new vehicle bind quote',
  props<{
    payload: {
      agent: Agent;
      typeOfEmail: typeOfAgentEmail;
      workOrderNumber?: string;
      vin?: string;
    };
  }>()
);

export const addVehicleSendPartnerNotification = createAction(
  '[Add Vehicle] add new vehicle partner notification',
  props<{ payload: { typeOfEmail: typeOfAgentEmail; workOrderNumber?: string } }>()
);

export const addVehicleBindPartnerQuote = createAction(
  '[Add Vehicle] add new vehicle bind partner quote',
  props<{ payload: { typeOfEmail: typeOfAgentEmail; workOrderNumber?: string } }>()
);

export const addVehicleSendAnalytics = createAction(
  '[Add Vehicle] send new vehicle analytics',
  props<{ payload: AddVehicleAnalyticsModel }>()
);

export const addVehicleHardStopSendAnalytics = createAction(
  '[Add Vehicle] send new vehicle with hard stop analytics',
  props<{ payload: AddVehicleAnalyticsModel }>()
);

export const addVehicleExpectationSettingNotification = createAction(
  '[Add Vehicle] Expectation Setting Notification',
  props<{
    payload: {
      agent: Agent;
      policyNumber: string;
    };
  }>()
);

export const addVehicleExitFlow = createAction('[Add Vehicle] exit flow');

export const addVehicleReset = createAction('[Add Vehicle] reset state');

export const addVehicleBindReset = createAction('[Add Vehicle] bind error reset state');

export const buildPartnerCoverages = createAction(
  '[Add Vehicle] build partner coverages',
  props<{
    payload: AddVehicleRateResponse;
    hasTPMPackageAvailibility: boolean;
    hasCostcoGoldStarMembership: boolean;
    copy: any;
    stateCode: string;
  }>()
);

export const updateCoverage = createAction(
  '[Add Vehicle] update coverage',
  props<{
    coverage: CoverageOptionModelWithControl;
    value: string;
    optOutCoverages?: string[];
  }>()
);

export const changePartnerCoverages = createAction(
  '[Add Vehicle] change partner coverages',
  props<{ payload: AddVehicleAnalyticsModel }>()
);
export const loadFuturePaymemts = createAction(
  '[Add Vehicle Quote Info Page] Load Future Payments'
);
export const addVehicleOtherExitFlow = createAction(
  '[Add Vehicle] Make/Model/Series any one of them are other or msrp is null then exit'
);
