import { BillAccount } from '@amfam/shared/models';

export class BillAccountCanRegister {
  billAccountNumber: string;
  policyNumber: string;
  billingAccount: BillAccount;
  loading: boolean;
  loaded: boolean;
  canRegister: boolean;
  status: unknown;
}

export const initialBillAccountCanRegister: BillAccountCanRegister = {
  billAccountNumber: null,
  policyNumber: null,
  billingAccount: null,
  loading: false,
  loaded: false,
  canRegister: null,
  status: null
};
