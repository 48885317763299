import { ApiStatus } from '../api/api-status.model';

export interface BillAccount {
  billAccountNumber: string;
  minimumAmountDue: string;
  paymentDueDate: string;
  billingMethod: string;
  policyList?: PolicyList;
  associated: boolean;
  // TODO: Make billingPreferences mandatory once PCM is live
  billingPreferences?: BillingPreferences;
  registeredElsewhere?: boolean;
  enabledAFT: boolean;
  isExternalBam: boolean;
  readOnly: boolean;
  pastDue: boolean;
  isPartialPaymentPastDue: boolean;
  icon: string;
  policyTypeDisplayName?: string;
  futurePayments?: FuturePaymentList;
  accountBalance: string;
  accountStatus: string;
  accountType: string;
  billingDocumentsList: Array<any>;
  billingNameAndAddress: BillingNameAndAddress;
  billingStatementLanguage: string;
  billingStatements: BillingStatements[];
  billingStatement: BillingStatement;
  billingSystem: string;
  eligibleBillingMethods: Array<EligibleBillingMethods>;
  fullPayBalance: number;
  hasMultipleScheduledPayments: boolean;
  overviewPaymentCopy: string;
  registrationDate: string;
  risks: Array<string>;
  scheduled: Scheduled;
  paymentSchedule?: any;
  isAdvance?: boolean;
  // TODO: billAccount.reducer has a weird logic to handle the BillAccountUpdateReadOnlyStatus
  // That need to be refactored to a seperate function/selector, when that happens remove this loading/error
  // inidicator.
  loadPrefsError?: boolean;
  minimumAmountDueBreakdown?: MinimumAmountDueBreakdown; // New fields for classic past due
  cancelLetterSent?: boolean;
  correlationId?: string;
  updatingPreferenceAction?: boolean;
}

export type FuturePaymentList = Array<ScheduledPaymentModel>;
export interface ScheduledPaymentModel {
  amount;
  dueDate: string;
  invoiceDate: string;
  invoiceNumber: string;
}
export const initialBillAccount: BillAccount = {
  billAccountNumber: null,
  minimumAmountDue: null,
  paymentDueDate: null,
  associated: null,
  billingMethod: null,
  registeredElsewhere: false,
  enabledAFT: false,
  isExternalBam: false,
  readOnly: true,
  pastDue: null,
  isPartialPaymentPastDue: null,
  icon: null,
  policyTypeDisplayName: null,
  futurePayments: [],
  accountBalance: null,
  accountStatus: null,
  accountType: null,
  billingDocumentsList: null,
  billingNameAndAddress: null,
  billingStatementLanguage: null,
  billingStatements: null,
  billingStatement: null,
  billingSystem: null,
  eligibleBillingMethods: null,
  fullPayBalance: null,
  hasMultipleScheduledPayments: null,
  overviewPaymentCopy: null,
  registrationDate: null,
  risks: null,
  scheduled: null,
  paymentSchedule: null
};
export type PolicyList = Array<PolicyListItem>;
export interface PolicyListItem {
  policyNumber: string;
  policyTypeDisplayName?: string;
  riskDescriptionList?: string[];
  productType?: string;
  riskIconList: string[];
  risks?: Array<{ id: string; description: string }>;
  policyType?: PolicyType;
}

export enum PolicyType {
  FARM_RANCH = 'FARM_RANCH',
  FR_UMBRELLA = 'FR_UMBRELLA'
}

export interface BillingPreferences {
  accountNickName: string;
  deliveryMethod?: string;
  dueDateReminder: string;
  // TODO: Make preferences mandatory and take deliveryMethod out once PCM is live
  preferences?: Preferences[];
}

// Need final api version to harden this
export interface Preferences {
  businessUnitCode: string;
  emailAddress?: string;
  isModifiable: boolean;
  lastUpdateTimestamp: string;
  phoneNumber?: string;
  preferenceCode: string;
  preferenceDeliveryCode: string;
  preferenceId?: string;
  preferenceTypeCode: string;
  reasonNotModifiable?: string;
}

// Model for PCM
export interface DeletePreferencesRequest {
  deleteCorrelationId: string;
  billAccountNumber: string;
  policyDescription: string[];
}

export interface DeletePreferencesActionPayload {
  billAccountNumber: string;
  deletePreference: DeletePreferencesRequest;
  loadPreference: RetrievePreferencePayload;
}

export interface UpdatePreferencesRequest {
  billAccountNumber: string;
  updatePreferenceCorrelationId: string;
  associated: boolean;
  billingMethod: string;
  payload: {
    billingPreference: {
      accountNickname?: string;
      dueDateReminder?: string;
      preferences: UpdatePreferences[];
    };
  };
}

export interface UpdatePreferences {
  businessUnitCode: string;
  emailAddress?: string;
  lastUpdateTimestamp?: string;
  phoneNumber?: string;
  payloadEntityId: string;
  preferenceCode: string;
  preferenceDeliveryCode: string;
  preferenceId?: string;
  preferenceTypeCode: string;
}

// Old model
/*
  This request model has a couple of annoyances.
  The API requires billingPreference (singular) and a misspelled accountNickname element.
  The rest of the store uses billingPreferences (plural) and accountNickName (notice the capitalization)
*/
export interface OldUpdatePreferencesRequest {
  billAccountNumber: string;
  updateCorrelationId: string;
  payload: {
    billingPreference: {
      accountNickname: string;
      deliveryMethod: string;
      dueDateReminder: string;
    };
  };
}

export interface OldUpdatePreferencesResponse {
  billAccountNumber: string;
  updateCorrelationId: string;
  billingPreferences?: BillingPreferences;
  error?: any;
  updatePreferencesError?: any;
}

export interface UpdatePreferencesStoreModel {
  billAccountNumber: string;
  updatePreferenceCorrelationId: string;
  billingPreferences?: BillingPreferences;
  error?: any;
}

export interface UpdatePreferencesResponse {
  status: ApiStatus;
}

export interface DeletePreferencesStoreModel {
  billAccountNumber: string;
  deleteCorrelationId: string;
  error?: any;
}

export interface DeletePreferencesResponse {
  status: ApiStatus;
}

export interface PopulatePreferenceModel {
  billAccountNumber: string;
  billingPreferences: {
    accountNickName: string;
    dueDateReminder: string;
    deliveryMethod: string;
  };
}

// TODO map out specific payload - seperate needs for api call and those for confirmation.
export interface UpdateRegistrationRequest {
  billAccountNumber: string;
  correlationId: string;
  isUnregister: boolean;
  registrationData: {
    billAccountNumber?: string;
    policyNumber: string;
    policyDescription?: Array<string>;
    accountName: string;
    reminder: string;
    associated: boolean;
    riskDescription?: Array<string>;
    emailAddress?: string;
    billingSystem?: string;
  };
}

export interface UpdateRegistrationPayload {
  actionIndicator: string;
  billAccount: {
    billingMethod: string;
    billingPreference: BillingPreference;
  };
}

export interface BillingPreference {
  billingPreference: {
    dueDateReminder: string;
    accountNickname: string;
  };
}

export interface UpdateRegistrationStoreModel {
  billAccountNumber: string;
  correlationId: string;
  registrationDate?: string;
  billingMethod?: string;
  error?: any;
  updateRegistrationError?: any;
}

export interface UpdateRegistrationResponse {
  status: ApiStatus;
}

export interface SilentRegistrationPayload {
  billAccountNumber: string;
  correlationId: string;
}

export interface RetrievePreferencePayload {
  billAccountNumber: string;
  associated: boolean;
  billingMethod: string;
}

export interface RetrievePreferenceResponse {
  status: ApiStatus;
  billAccount: {
    billAccountNumber: string;
    billingSystem: string;
    billingPreference: {
      accountNickname: string;
      dueDateReminder: number;
      deliveryMethod?: string;
      preferences: Preferences[];
    };
  };
}

export interface DecoratedPreference {
  billAccountNumber: string;
  billingPreferences: {
    accountNickName: string;
    dueDateReminder: number;
    preferences: Preferences[];
  };
}

export interface NewBillingPreference {
  billAccountNumber: string;
  preference: string;
}

export interface UpdatedPreferenceBillAccount {
  billAccountNickName: string;
  updatedDeliveryPreferences: boolean;
}

export interface BillAccountData {
  billingPreferences: BillingPreferences;
  associated: boolean;
  billingMethod: string;
}

export interface UnassociatedRiskDetailSuccess {
  status: ApiStatus;
  policies: UnassociatedRiskDetailPolicyType[];
  notFoundPolicies: string[];
}

export interface UnassociatedRiskDetailFail {
  billAccountNumber: string;
}
interface UnassociatedRiskDetailPolicyType {
  policyNumber: string;
  periodStartDate: string;
  periodEndDate: string;
  productType: string;
  sourceSystem: string;
  assignedProducer: string;
  riskDescriptions: string[];
}

export interface UnassociatedRiskDetailModel {
  billAccountNumber: string;
  policyList: PolicyList;
  icon?: string;
  policyTypeDisplayName?: string;
}

export interface UpdateAmdModel {
  billAccountNumber: string;
  minimumAmountDue: number;
}

export interface BillingNameAndAddress {
  address: {
    addressLine1: string;
    city: string;
  };
  name: string;
}
export interface BillingStatements {
  billingStatementType: string;
}

export interface BillingStatement {
  statementType: string;
  currentAmtDueDate: string;
  pastDueAmtDueDate?: string;
  statementDate: string;
  totalAmountBilled: number;
  policiesBilled: {
    policyNumber: string;
    currentMinimumDue: {
      booked: number;
      earned: number;
      special: number;
      unbooked: number;
    };
  }[];
}
export interface EligibleBillingMethods {
  billingMethod: string;
  eligibleStatus: string;
  ineligibleReasons: {
    name: string;
  }[];
}

export interface Scheduled {
  autoPayIndicator: boolean | string;
  name: string;
  // get all account numbers using this payment method
  billAccounts: Array<{
    billAccountNumber: string;
    billAcctPaymentAmount: string;
    nickName: string;
    accountBalance: string;
    minimumAmountDue: string;
  }>;
  date: string;
  paymentId: string;
  totalPaymentAmount: string;
}

export interface TryAgainRegistrationModel {
  billAccountNumber: string;
  route: string;
}

export interface MinimumAmountDueBreakdown {
  currentAmountDue: number;
  previousAmountDue: number;
}

export interface AmountBreakdown {
  dollars: string;
  cents: string;
  total: number;
  title: string;
}

export interface BillingDashBoardBillAccount {
  billAccountNumber: string;
  amount: string;
  icon: string;
  status: StatusObj;
}

export interface StatusObj {
  statusText: string;
  statusSubText?: string;
}
