@if (newTemplate) {
<div class="agent-card" *dsPartnerAFI>
  <div class="agent-card__header">
    <div class="thumbnail">
      <div class="image">
        @if (agent.photoUrl && !photoError) {
        <img [src]="agent.photoUrl" (error)="onPhotoError()" />
        } @else{
        <div class="initials" data-cy="initials">{{ initials }}</div>
        }
      </div>
    </div>
    <div class="name">
      <div class="full-name" data-cy="fullAgentName">{{ agent.firstName }} {{ agent.lastName }}</div>
      <div class="agency-name" data-cy="agencyName">
        {{ agent.fullName }}
        <!-- @if (agent.isAmericanStarCertified) {
        <img src="assets/images/jd-power.png" alt="American Star Certified Agency" />American Star
        Certified Agency } -->
      </div>
    </div>
  </div>
  <div class="agent-card__content">
    @if (mainPhoneNumber || defaultContactNumber) {
    <div>
      <span tabindex="0" aria-label="Phone Number" class="pseudo-icon icon-call icon"></span>
      <a class="text" [href]="'tel:+' + mainPhoneNumber || defaultContactNumber" data-cy="mainPhoneNumber">{{
        mainPhoneNumber
          ? (mainPhoneNumber | phoneNumberSingleStringFormatter)
          : defaultContactNumber
      }}</a>
    </div>
    } @if (mapUrl) {
    <div>
      <span class="pseudo-icon icon-find icon"></span>
      <a
        class="text"
        [href]="mapUrl"
        target="_blank"
        [attr.aria-label]="'Open Google Maps link to ' + mainOfficeAddress + ' in a new window.'"
        data-cy="mainOfficeAddress"
        >Location</a
      >
    </div>
    } @if (agent.websiteUrl) {
    <div>
      <span class="pseudo-icon icon-agent icon"></span>
      <a class="text" [href]="agent.websiteUrl" target="_blank" data-cy="agentWebsite">Website</a>
    </div>
    } @if (emailUrl) {
    <div>
      <span class="pseudo-icon icon-email icon"></span>
      <a class="text" [href]="emailUrl" target="_blank" data-cy="emailURL">Email</a>
    </div>
    }
  </div>
</div>
} @else {
<div *dsPartnerAFI afi class="agents-list-item">
  <div class="agent-headings">
    <h5 data-cy="agentFullName">{{ agent.fullName }}</h5>
    <h6 *ngIf="agent.isAmericanStarCertified">
      <img src="assets/images/jd-power.png" alt="American Star Certified Agency" />American Star
      Certified Agency
    </h6>
  </div>
  <ul>
    <li
      class="agent-photo"
      [style.background-image]="agent.photoUrl ? 'url(' + agent.photoUrl + ')' : ''"
    >
      <span *ngIf="!agent.photoUrl" class="initials">{{ initials }}</span>
    </li>
    <li *ngIf="mainPhoneNumber || defaultContactNumber">
      <span tabindex="0" aria-label="Phone Number" class="pseudo-icon icon-call"></span>
      <a [href]="'tel:+' + mainPhoneNumber || defaultContactNumber" data-cy="agentPhoneNumber">{{
        mainPhoneNumber
          ? (mainPhoneNumber | phoneNumberSingleStringFormatter)
          : defaultContactNumber
      }}</a>
    </li>
    <li *ngIf="mapUrl">
      <span class="pseudo-icon icon-find"></span>
      <a
        [href]="mapUrl"
        target="_blank"
        [attr.aria-label]="'Open Google Maps link to ' + mainOfficeAddress + ' in a new window.'"
        >Location</a
      >
    </li>
    <li *ngIf="agent.websiteUrl">
      <span class="pseudo-icon icon-agent"></span>
      <a [href]="agent.websiteUrl" target="_blank">Website</a>
    </li>
    <li *ngIf="emailUrl">
      <span class="pseudo-icon icon-email"></span> <a [href]="emailUrl" target="_blank">Email</a>
    </li>
  </ul>
</div>
}
<div *dsPartnerConnect partner class="agents-list-item partner-width">
  <div class="partner-headings"></div>
  <ul class="partner-height">
    <li>
      <span class="contact-us-placeholder"> <i class="icon-contact-us"></i></span>
    </li>
    <li>
      <span class="pseudo-icon icon-call"></span>
      <a
        dsPartnerContent
        [brandContent]="'getPrettyCusCareNumber'"
        [brandHref]="'getCusCareNumberFullHref'"
      ></a>
    </li>
    <li>
      <span class="pseudo-icon icon-email"></span>
      <a dsPartnerContent [brandHref]="'getCusCareEmailHref'">Email</a>
    </li>
  </ul>
</div>
