<div>
  <h3 data-cy="pleaseReadToCustomerCommPrefHeader">To save paperless communication preferences, please read this to your customer.</h3>
  <br />
  <div data-cy="commPrefInfo">
    You have the option to receive billing statements, policy documents and communications about
    your policies electronically. To choose this you have to have a personal computer and internet
    connectivity and an active My Account. You can change your mind and go back to receiving paper
    copies at any time. By having me update this preference, you are consenting to receive insurance
    forms, invoices and communications from us electronically. We will send you an email that
    contains additional information on this option along with a means to opt out if you change your
    mind.
  </div>
  <br />
  <span data-cy="commPrefAgree">
    By clicking “Agree” you will be saving your customer’s communication preferences and confirming
    that they’ve accepted these terms.
  </span>
</div>
