<ds-header-wrapper></ds-header-wrapper>
<div toastContainer></div>
<div
  [style.height]="(mobileMenuOpen$ | async) && (mobileMenuActive$ | async) ? '100%' : '0'"
  class="overlay"
></div>
<div id="body-wrapper" class="flex row" [class.noDock]="noDock$ | async">
  <div class="column twelve">
    <router-outlet></router-outlet>
  </div>
</div>

<ds-modal [id]="noDocumentModalId" [closeOthers]="true" maxWidth="300">
  <h3 class="no-documents-modal-header font-size-1-75 font-weight-semi-bold">Sorry</h3>
  <div class="no-documents-modal flex-1">
    <div class="pad-vertical-20">{{noDocumentMessage}}</div>
    <button ds-button data-cy="noDocumentModal" (click)="closeNoDocumentModal()">OK</button>
  </div>
</ds-modal>
