<div class="container">
  <div class="delete-confirm-message pad-horizantal-16 flex flex-direction-column items-center">
    <span class="icon-complete-circle"></span>
    <h4 data-cy="accountDeletedHeader" content contentPath="enrollment.accountDeletedHeader"></h4>
    <div
      class="pad-bottom-10"
      data-cy="deleteConfirmationAdmin"
      content
      [contentPath]="
        isAdminApp ? 'enrollment.deleteConfirmationAdmin' : 'enrollment.deleteConfirmation'
      "
    ></div>
    <div
      class="pad-bottom-10"
      data-cy="deleteEmailConfirmationAdmin"
      content
      contentPath="enrollment.deleteEmailConfirmationAdmin"
    ></div>
    @if (!isAdminApp) {
      <div class="row">
        <div class="column twelve">
          <button
            ds-button
            class="delete-cancel"
            type="button"
            [attr.aria-label]="'Back to signup'"
            data-cy="backToLogin"
            (click)="backToLogin()"
            content
            contentPath="enrollment.deleteMyAccountClose"
          ></button>
        </div>
      </div>
    }
  </div>
</div>
