import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import {
  Applications,
  ApplicationService,
  CopyService
} from '@amfam/shared/utility/shared-services';
import { Option } from '@amfam/ui-kit';

@Component({
  selector: 'ds-auto-pay-setup-payment-date',
  templateUrl: './auto-pay-setup-payment-date.component.html',
  styleUrls: ['./auto-pay-setup-payment-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoPaySetupPaymentDateComponent implements OnInit {
  @Input() paymentDateOptions: Option[];
  @Input() formGroup: UntypedFormGroup;
  @Input() paymentDueDate: string;
  subHeading: string;

  constructor(
    private copyService: CopyService,
    private appService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.subHeading = this.appService.isApp(Applications.MYACCOUNT_ADMIN)
      ? this.copyService.getCopy('billing.autoPayRefactor.whenDoTheyWantToPay')
      : this.copyService.getCopy('billing.autoPayRefactor.whenDoYouWantToPay');
  }
}
