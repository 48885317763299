import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

// Store
import { DigitalAccount, NameDetail } from '@amfam/shared/digital-account/data-access';
import { fromUserActions, UserUpdate } from '@amfam/shared/user';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { LoadingSpinnerService, ValidationService } from '@amfam/ui-kit';

import { EditBusinessService } from '../../services/edit-business.service';

@Component({
  selector: 'ds-edit-business-name',
  templateUrl: './edit-business-name.component.html',
  styleUrls: ['./edit-business-name.component.scss']
})
export class EditBusinessNameComponent implements OnInit {
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() businessName: string;
  @Input() headerCopy: string;
  @Output() edit: EventEmitter<boolean> = new EventEmitter();

  isEditing = false;
  nameFormat: string;
  changeNamesForm: UntypedFormGroup;

  constructor(
    private editBusiness: EditBusinessService,
    private formBuilder: UntypedFormBuilder,
    private spinner: LoadingSpinnerService,
    private store: Store,
    public roleService: ImpersonateRolesService
  ) {}

  ngOnInit() {
    this.nameFormat = `${this.firstName} ${this.lastName}`;

    this.changeNamesForm = this.formBuilder.group({
      firstName: [
        this.firstName,
        [Validators.required, Validators.maxLength(30), ValidationService.firstNameValidator]
      ],
      lastName: [
        this.lastName,
        [
          Validators.required,
          Validators.maxLength(30),
          ValidationService.lastNameEntryValidator,
          ValidationService.lastNameAdditionalValidator,
          ValidationService.specialCharValidator,
          ValidationService.nolastNameValidatior
        ]
      ]
    });
  }

  editing(): void {
    this.toggleEdit();
  }

  toggleEdit(): void {
    this.isEditing = !this.isEditing;
    this.edit.emit(this.isEditing);
  }

  save(): void {
    if (this.changeNamesForm.dirty) {
      this.spinner.start();
      const newFirstName: string = this.changeNamesForm.get('firstName').value;
      const newLastName: string = this.changeNamesForm.get('lastName').value;
      let nameDetail: NameDetail = {
        firstName: newFirstName,
        lastName: newLastName
      };
      if (newFirstName === this.firstName) {
        nameDetail = {
          lastName: newLastName
        };
      } else if (newLastName === this.lastName) {
        nameDetail = {
          firstName: newFirstName
        };
      }
      const displayName = newFirstName + ' ' + newLastName;
      const digitalAccount: DigitalAccount = {
        nameDetail: nameDetail
      };
      this.editBusiness.updateDigitalAccount(digitalAccount).subscribe(
        () => {
          const userUpdate: UserUpdate = {
            firstName: newFirstName,
            lastName: newLastName,
            displayName: displayName
          };
          this.store.dispatch(fromUserActions.UpdateUser(userUpdate));
          this.nameFormat = `${newFirstName} ${newLastName}`;
          this.toggleEdit();
          this.spinner.stop();
        },
        err => {
          this.spinner.stop();
          this.handleError(err);
        }
      );
    }
  }

  cancel(): void {
    this.resetForm();
    this.toggleEdit();
  }

  private resetForm(): void {
    const firstName = this.changeNamesForm.controls['firstName'];
    const lastName = this.changeNamesForm.controls['lastName'];
    // reset the form control with the initial value and stop editing
    firstName.reset(this.firstName);
    lastName.reset(this.lastName);
  }

  private handleError(err): void {
    const apiMessage = err.status.messages ? err.status.messages[0] : null;
    if (apiMessage && apiMessage.code) {
      const apiCode = apiMessage.code;
      switch (apiCode) {
        case 400001: // Request Missing Info
          this.changeNamesForm.controls['firstName'].setErrors({
            'The first name entered is invalid': true
          });
          this.changeNamesForm.controls['lastName'].setErrors({
            'The last name entered is invalid': true
          });
          break;
        case 400002: // Validation Error
          this.changeNamesForm.controls['firstName'].setErrors({
            'The first name entered is invalid': true
          });
          this.changeNamesForm.controls['lastName'].setErrors({
            'The last name entered is invalid': true
          });
          break;
        case 400003: // Inconsitent Info
          this.changeNamesForm.controls['firstName'].setErrors({
            'The first name entered is invalid': true
          });
          this.changeNamesForm.controls['lastName'].setErrors({
            'The last name entered is invalid': true
          });
          break;
      }
    }
  }
}
