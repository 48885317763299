import { Injectable } from '@angular/core';
import { get as _get, has as _has } from 'lodash';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { Applications, ApplicationService } from '@amfam/shared/utility/shared-services';

import {
  CreditCardExpirationStatusType,
  CreditCardRegex,
  CreditCardType,
  PaymentMethod,
  PaymentMethodAccountType,
  PaymentMethodAnalyticsModel,
  PaymentMethodModeOfAuthorizationType
} from '../models/payment-method-util.models';

@Injectable({ providedIn: 'root' })
export class PaymentMethodUtilService {
  constructor(
    private appService: ApplicationService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  getPaymentMethodAccountType(paymentMethod: PaymentMethod): PaymentMethodAccountType {
    return _has(paymentMethod, 'creditCard')
      ? PaymentMethodAccountType.CREDIT_DEBIT
      : _get(paymentMethod, 'achWithdrawal.accountType', '') === PaymentMethodAccountType.CHECKING
        ? PaymentMethodAccountType.CHECKING
        : _get(paymentMethod, 'achWithdrawal.accountType', '') === PaymentMethodAccountType.SAVINGS
          ? PaymentMethodAccountType.SAVINGS
          : null;
  }

  getPaymentMethodModeOfAuthorization(
    accountType: PaymentMethodAccountType
  ): PaymentMethodModeOfAuthorizationType {
    if (accountType === PaymentMethodAccountType.CREDIT_DEBIT) {
      return PaymentMethodModeOfAuthorizationType.EMPTY;
    } else {
      return this.appService.isApp(Applications.MYACCOUNT_ADMIN)
        ? PaymentMethodModeOfAuthorizationType.ORAL
        : PaymentMethodModeOfAuthorizationType.ESIGNATURE;
    }
  }

  getCreditCardType(creditCardNumber: string): CreditCardType {
    const cardNumber: string = creditCardNumber.replace(/[ -]/g, '');
    return cardNumber.match(CreditCardRegex.AMERICAN_EXPRESS)
      ? CreditCardType.AMERICAN_EXPRESS
      : cardNumber.match(CreditCardRegex.DISCOVER)
        ? CreditCardType.DISCOVER
        : cardNumber.match(CreditCardRegex.VISA)
          ? CreditCardType.VISA
          : cardNumber.match(CreditCardRegex.MASTERCARD)
            ? CreditCardType.MASTERCARD
            : null;
  }

  isPaymentMethodExpired(paymentMethod: PaymentMethod): boolean {
    return (
      _get(paymentMethod, 'creditCard.expirationStatus', null) ===
      CreditCardExpirationStatusType.EXPIRED
    );
  }

  isPaymentMethodExpiringSoon(paymentMethod: PaymentMethod): boolean {
    return (
      _get(paymentMethod, 'creditCard.expirationStatus', null) ===
      CreditCardExpirationStatusType.EXPIRING_SOON
    );
  }

  sendPaymentMethodAnalytics(analyticPayload: PaymentMethodAnalyticsModel): void {
    const pageObj = {
      pageName: `MyAccount:${analyticPayload.pageAnalyticName}`,
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Billing Payments',
      subCategory2: 'StandAloneMethod',
      subCategory3: ''
    };
    if (analyticPayload.buttonAnalyticName) {
      return this.analyticsFacade.trackButtonClick({
        link: `MyAccount:StandAlone:${analyticPayload.buttonAnalyticName}`
      });
    }

    return this.analyticsFacade.trackPage(pageObj);
  }
}
