import { createReducer, on } from '@ngrx/store';
import { get as _get } from 'lodash';

import { FinancialInstitution } from '../../models/financial-account.model';
import * as FinancialAccountActions from './financial-account.actions';

export interface State {
  loading: boolean;
  pciToken: string;
  pciTokenError: boolean;
  financialInstitution: FinancialInstitution;
  financialInstitutionError: boolean;
  correlationId: string;
  paymentMethodError: boolean;
}

export const initialState: State = {
  loading: false,
  pciToken: '',
  pciTokenError: false,
  financialInstitution: null,
  financialInstitutionError: false,
  correlationId: '',
  paymentMethodError: false
};

export const reducer = createReducer(
  initialState,

  // GET PCI TOKEN
  on(
    FinancialAccountActions.getPCIAuthorizationToken,
    (state): State => ({
      ...state,
      loading: true,
      pciTokenError: false,
      pciToken: ''
    })
  ),
  on(FinancialAccountActions.getPCIAuthorizationTokenSuccess, (state, action) => ({
    ...state,
    loading: false,
    pciToken: _get(action, 'response.pciToken'),
    correlationId: action.correlationId
  })),
  on(
    FinancialAccountActions.getPCIAuthorizationTokenFailure,
    (state, action): State => ({
      ...state,
      loading: false,
      pciTokenError: true,
      pciToken: '',
      correlationId: action.correlationId
    })
  ),

  // GET FINANCIAL INSTITUTION
  on(
    FinancialAccountActions.getFinancialInstitution,
    (state): State => ({
      ...state,
      loading: true,
      financialInstitutionError: false,
      financialInstitution: null
    })
  ),
  on(
    FinancialAccountActions.getFinancialInstitutionSuccess,
    (state, action): State => ({
      ...state,
      loading: false,
      financialInstitution: action.response.finAcctServiceResponse.financialInstitute
    })
  ),
  on(
    FinancialAccountActions.getFinancialInstitutionFailure,
    (state): State => ({
      ...state,
      loading: false,
      financialInstitutionError: true,
      financialInstitution: null
    })
  ),

  // SAVE PAYMENT METHOD
  on(
    FinancialAccountActions.savePaymentMethod,
    (state): State => ({
      ...state,
      loading: true,
      paymentMethodError: false
    })
  ),
  // we don't need to update the store on a success action here because
  // we need to make a subsequent call to add/edit
  on(
    FinancialAccountActions.savePaymentMethodFailure,
    (state, action): State => ({
      ...state,
      loading: false,
      paymentMethodError: true,
      correlationId: action.correlationId
    })
  ),

  // RESET ERROR STATE
  on(
    FinancialAccountActions.resetErrorState,
    (state): State => ({
      ...state,
      pciTokenError: false,
      financialInstitutionError: false
    })
  )
);
