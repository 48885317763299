import { createAction, props } from '@ngrx/store';

import { PaymentMethodAddEditOperationType } from '@amfam/billing/payment-method/util';

import {
  GetFinancialInstitutionPayload,
  GetFinancialInstitutionResponse,
  GetPCIAuthorizationTokenResponse,
  SavePaymentMethodPayload,
  SavePaymentMethodResponse
} from '../../models/financial-account.model';
import { AddEditPaymentMethodPayload } from '../../models/payment-method.model';

// Save Payment Method
export const savePaymentMethod = createAction(
  '[FinancialAccount] Save Payment Methods',
  props<{
    correlationId: string;
    savePayload: SavePaymentMethodPayload;
    addEditPayload: AddEditPaymentMethodPayload;
    paymentAccountId?: string;
    operation: PaymentMethodAddEditOperationType;
  }>()
);

export const savePaymentMethodSuccess = createAction(
  '[FinancialAccount] Save Payment Methods Success',
  props<{ correlationId: string; response: SavePaymentMethodResponse }>()
);

export const savePaymentMethodFailure = createAction(
  '[FinancialAccount] Save Payment Methods Failure',
  props<{ error: unknown; correlationId: string }>()
);

// Get Financial Institutions
export const getFinancialInstitution = createAction(
  '[FinancialAccount] Get Financial Institution',
  props<{ payload: GetFinancialInstitutionPayload; correlationId: string }>()
);

export const getFinancialInstitutionSuccess = createAction(
  '[FinancialAccount] Get Financial Institution Success',
  props<{ response: GetFinancialInstitutionResponse; correlationId: string }>()
);

export const getFinancialInstitutionFailure = createAction(
  '[FinancialAccount] Get Financial Institution Failure',
  props<{ error: unknown; correlationId: string }>()
);

// Get PCI Token
export const getPCIAuthorizationToken = createAction(
  '[FinancialAccount] Get PCI Authorization Token',
  props<{ correlationId: string }>()
);

export const getPCIAuthorizationTokenSuccess = createAction(
  '[FinancialAccount] Get PCI Authorization Token Success',
  props<{ response: GetPCIAuthorizationTokenResponse; correlationId: string }>()
);

export const getPCIAuthorizationTokenFailure = createAction(
  '[FinancialAccount] Get PCI Authorization Token Failure',
  props<{ error: unknown; correlationId: string }>()
);

// Reset Error State
export const resetErrorState = createAction('[FinancialAccount] Reset Error State');
