import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ConfirmationPage, CopyService } from '@amfam/shared/utility/shared-services';

import { OpportunitiesAgentService } from '../opportunities-agent/opportunities-agent.service';

@Component({
  selector: 'amfam-opportunities-confirmation',
  templateUrl: './opportunities-confirmation.component.html',
  styleUrls: ['./opportunities-confirmation.component.scss']
})
export class OpportunitiesConfirmationComponent implements OnInit {
  context: string;
  data: ConfirmationPage;
  constructor(
    private copyService: CopyService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private opportunitiesAgentService: OpportunitiesAgentService
  ) {}

  ngOnInit(): void {
    this.opportunitiesAgentService.setDockingbarHeading();
    this.context = this.activatedRoute.snapshot.data['context'];
    this.data = this.getData();
  }

  private getData() {
    return this.copyService.getCopy(`opportunityContent.${this.context}`);
  }

  backToOverview() {
    this.store.dispatch(fromRouterActions.Go({ path: ['/overview'] }));
  }
}
