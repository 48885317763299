<div class="box">
  <span class="flex">
    @if (showIcon) {
      <div class="pad-right-10">
        @if (creditCardIcon) {
          <ds-credit-card-icon [ccType]="icon" [ccIconHeight]="24"></ds-credit-card-icon>
        }
        @if (!creditCardIcon) {
          <span class="icon-bank bank-icon white" role="img"></span>
        }
      </div>
    }
    <div class="flex card-right">
      <div>
        @if (!showIcon) {
          <span>Payment method:</span>
        }
        @if (title) {
          <span class="overflow-hidden overflow-ellipsis payment-method-name" title="{{ title }}">
            {{ title }}
          </span>
        }
      </div>
      <div class="whitespace-nowrap account-type">
        <span>{{ accountType }}</span>
        @if (paymentAccount?.creditCard) {
          <span>{{ accountNumber | returnLast: 4 | redact: paymentAccount }}</span>
        }
        @if (paymentAccount?.achWithdrawal) {
          <span>{{ accountNumber | returnLast: 3 | redact: paymentAccount }}</span>
        }
      </div>
    </div>
  </span>
</div>
