import { get as _get, groupBy as _groupBy, keyBy as _keyBy } from 'lodash';

import { Entities, initialEntities } from '../../entity/entity.model';
import * as kydActions from './kyd.actions';
import { PolicyEnrollment } from './models';

export function reducer(
  state = initialEntities<PolicyEnrollment>(),
  action: kydActions.Actions
): Entities<PolicyEnrollment> {
  let entities: {};

  switch (action.type) {
    case kydActions.LOAD:
      return Object.assign({}, state, {
        loading: true
      });

    case kydActions.LOAD_COMPLETE:
      return Object.assign({}, state, {
        loading: false
      });

    case kydActions.LOAD_SUCCESS:
      const enrolledPolicies = action.payload
        .filter(
          enrollment =>
            enrollment && enrollment.enrolledPolicies && enrollment.enrolledPolicies.length > 0
        )
        .reduce(
          (policies, enrollment) => policies.concat(enrollment.enrolledPolicies),
          new Array<PolicyEnrollment>()
        );
      const groupedPolicies = _groupBy(enrolledPolicies, policy => policy.policyNumber);
      const combinedPolicies = Object.keys(groupedPolicies).map(key =>
        groupedPolicies[key].reduce((previous, current) => {
          const enrolledVehicles = previous.enrolledVehicles.concat(current.enrolledVehicles);
          return Object.assign(<PolicyEnrollment>{}, previous, {
            enrolledVehicles: enrolledVehicles
          });
        })
      );

      entities = _keyBy(combinedPolicies, policy => policy.policyNumber);

      entities = Object.assign({}, state.entities || {}, entities);

      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities,
        loaded: true,
        loading: false
      });

    default:
      return state;
  }
}

export const getEntities = (state: Entities<PolicyEnrollment>) => state.entities;

export const getIds = (state: Entities<PolicyEnrollment>) => state.ids;

export const getError = (state: Entities<PolicyEnrollment>) => state.error;

export const getLoaded = (state: Entities<PolicyEnrollment>) => state.loaded;

export const getLoading = (state: Entities<PolicyEnrollment>) => state.loading;
