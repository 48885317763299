import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromMae from './mae.reducer';

export const selectMaeState = createFeatureSelector<fromMae.MaeState>(fromMae.maeFeatureKey);

export const selectResendEmailSuccess = createSelector(
  selectMaeState,
  (state: fromMae.MaeState) => state.resendEmailSuccess
);

export const selectChangeEmailSuccess = createSelector(
  selectMaeState,
  (state: fromMae.MaeState) => state.changeEmailSuccess
);

export const selectEnrollmentHasError = createSelector(selectMaeState, fromMae.hasError);
export const selectEnrollmentLoaded = createSelector(selectMaeState, fromMae.loaded);
export const selectEnrollmentLoading = createSelector(selectMaeState, fromMae.loading);

export const maeQuery = {
  selectMaeState,
  selectResendEmailSuccess,
  selectChangeEmailSuccess,
  selectEnrollmentHasError,
  selectEnrollmentLoaded,
  selectEnrollmentLoading
};
