import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { PageAnalytic } from '@amfam/shared/utility/shared-services';

import * as fromRoot from '../../../core/store';
import { EnrollConfirmationModel } from '../../../core/store/mae';

@Component({
  selector: 'enroll-confirmation',
  templateUrl: './enroll-confirmation.component.html',
  styleUrls: ['./enroll-confirmation.component.scss']
})
export class EnrollConfirmationComponent implements OnInit, OnDestroy {
  @Output() onClose = new EventEmitter<boolean>();
  model: EnrollConfirmationModel;
  propertyServiceUrl: string;
  private isConnectPartner = false;
  private stop$ = new Subject<void>();

  constructor(
    private analyticsFacade: AnalyticsFacade,
    private store: Store
  ) {}

  close() {
    this.onClose.emit();
  }

  ngOnInit() {
    const userObs = this.store.select(userQuery.getUserState);
    const enrollmentObs = this.store.select(fromRoot.getEnrollments);
    const propertyServiceUrl = this.store.select(BrandSelectors.selectPropertyServiceUrl);
    const isConnectPartner = this.store.select(BrandSelectors.selectIsPartner);
    combineLatest(userObs, enrollmentObs, propertyServiceUrl, isConnectPartner)
      .pipe(
        takeUntil(this.stop$),
        map(results => {
          const user = results[0];
          const enrollment = results[1];
          this.propertyServiceUrl = results[2];
          this.isConnectPartner = results[3];
          return new EnrollConfirmationModel(enrollment, user);
        })
      )
      .subscribe(confirmation => {
        if (this.isConnectPartner) {
          for (let enrollment of confirmation.enrollments) {
            if (enrollment.title === 'Online Billing') {
              enrollment = Object.assign({}, enrollment, { title: 'Electronic Payments' });
            }
            if (enrollment.title === 'Go Paperless') {
              enrollment = Object.assign({}, enrollment, { title: 'Paperless Policy Documents' });
            }
          }
        }
        this.model = confirmation;
        // **** Start of Analytics data for this component
        const pageName = this.model.anyErrors
          ? 'MyAccount:AgentProgramEnrollment:Error'
          : 'MyAccount:AgentProgramEnrollment:Complete';

        const pageAnalytic: PageAnalytic = {
          pageName: pageName,
          experience: '',
          primaryCategory: 'My Account',
          subCategory1: 'Agent Enrollment',
          subCategory2: 'Programs',
          subCategory3: ''
        };
        // **** End of Analytics data for this component

        this.analyticsFacade.trackPage(pageAnalytic);
      });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
