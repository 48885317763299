<div class="row">
  <div class="column twelve lg-eight lg-push-two md-eight md-push-two">
    <div class="container">
      <div class="pad-left-20 pad-right-20 pad-top-30">
        <h4 data-cy="deleteMyAccountHeaderOneA">This form allows you to delete your MyAccount and all the info saved in it. <span data-cy="deleteMyAccountHeaderOneB">This does not cancel your insurance policies.</span></h4>
        <ul>
          <li data-cy="receiveAllCommunicationsBills">To receive all communications and bills in the mail.</li>
          <li data-cy="unEnrollPaperlessAutoPay">To be unenrolled from Paperless and Autopay, losing any associated discounts at the next renewal period.</li>
          <li data-cy="premiumIncreaseLosingDiscounts">To any premium increase as a result of losing the Paperless and Autopay discounts.</li>
          <li data-cy="unenrolledFromElectronicPayMethods">To be unenrolled from all electronic payment methods and pay via phone or mail.</li>
        </ul>
        <h4 data-cy="confirmPassWord">To continue, please confirm your password, then tap 'Delete' below</h4>
      </div>

      <form [formGroup]="deleteForm" (ngSubmit)="deleteAccount()" class="pad-bottom-20">
        <div class="column twelve pad-left-20 pad-right-20">
          <ds-form-control-container
            [labelText]="'Password'"
            [control]="password"
          >
            <input
              id="accountName"
              class="fill"
              formControlName="password"
              type="password"
              data-cy="accountPassWord"
            />
            <ds-form-control-message [control]="password"></ds-form-control-message>
            @if(errorMsg){
              <div class="row caption error-600-text">
                <p>{{errorMsg}}</p>
              </div>
            }
            
          </ds-form-control-container>
        </div>

        <div class="row">
          <div class="column twelve md-six cancel-btn">
            <button
              ds-button
              class="-outline btn"
              type="button"
              [attr.aria-label]="'Cancel Delete Account'"
              data-cy="cancelDeleteAccount"
              (click)="cancel()"
            >
              Cancel
            </button>
          </div>  

          <div class="column twelve md-six delete-btn">
            <button
              ds-button
              class="-error btn"
              type="submit"
              [attr.aria-label]="'Delete Account'"
              data-cy="deleteMyAccountBtn"
            >
              Delete My Account
            </button>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>

<ds-modal [id]="deleteMyAccountModalId" [closeOthers]="true" maxWidth="300">
  <div>
    <h3>Error</h3>
    <div data-cy="errorWhileDeletingMyAccount">
      Error occurred when deleting your account. Please try again later.
    </div>
    <button
      ds-button
      (click)="closeDeleteMyAccountModal()"
      data-cy="deleteMyAccountModal"
    >
      OK
    </button>
  </div>
</ds-modal>
