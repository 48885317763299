import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import {
  Magic3SearchRequest,
  SendPinRequest,
  UpdateEmailData,
  UpdateEmailRequest,
  VerifyPinRequest
} from '@amfam/profile/data-access';
import { BrandModel, BrandSelectors } from '@amfam/shared/utility/brand';
import { ConfigService } from '@amfam/shared/utility/shared-services';

@Injectable()
export class AlternateAuthService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private config: ConfigService
  ) {}

  magic3Search(requestObj: Magic3SearchRequest) {
    const url = this.config.get('alternateAuthApi') + 'authenticate';
    const params = this.getParams(requestObj);

    return this.http.get(url, { params: params });
  }

  sendPin(requestObj: SendPinRequest) {
    const url = this.config.get('alternateAuthApi') + 'sendpin';

    return this.http.post(url, requestObj);
  }

  verifyPin(requestObj: VerifyPinRequest) {
    const url = this.config.get('alternateAuthApi') + 'verifypin';

    return this.http.post(url, requestObj);
  }

  updateEmail(requestData: UpdateEmailData) {
    const url = this.config.get('alternateAuthApi') + 'digitalaccount/profile/' + requestData.waid;

    return this.getBrand().pipe(
      switchMap(brand => {
        const requestObj: UpdateEmailRequest = {
          partnerId: brand.partnerId,
          experienceId: brand.experienceId,
          digitalAccount: requestData.digitalAccount
        };

        return this.http.put(url, requestObj);
      })
    );
  }

  private getBrand(): Observable<BrandModel> {
    return this.store.select(BrandSelectors.selectBrandState).pipe(take(1));
  }

  private getParams(requestObj: Magic3SearchRequest): HttpParams {
    return new HttpParams()
      .set('partnerId', requestObj.partnerId)
      .set('lastName', requestObj.lastName)
      .set('zip5', requestObj.zipcode)
      .set('dateOfBirth', format(new Date(requestObj.dob), 'MMDDYYYY'))
      .set('authId', this.config.get('processId'));
  }
}
