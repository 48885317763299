<div>
  <h1 data-cy="forgotPasswordHelpText">
    <span content [contentPath]="'auth.forgotPasswordHelpText'"></span>
  </h1>
  <p content [contentPath]="'auth.forgotPasswordResetTextAcceptId'"></p>
  <div class="row"><hr aria-hidden="true" /></div>
  <form
    [formGroup]="acceptUseridForm"
    (ngSubmit)="acceptUseridForm.valid && checkIfUserIdExists()"
    autocomplete="off"
  >
    <div class="row">
      <div class="content">
        <div class="column twelve md-eight">
          <ds-form-control-container [labelText]="'Email or User ID'" [control]="username">
            <input
              type="text"
              id="username"
              class="fill"
              [focus]="true"
              formControlName="username"
              autocomplete="off"
              data-cy="forgotPasswordUserId"
            />
            <ds-form-control-message [control]="username"></ds-form-control-message>
            @if (userIdError) {
              <small
                data-cy="resetPasswordUserIdError"
                class="block caption error margin-top-5"
                content
                [contentPath]="'auth.forgotPasswordUserIdErrorText'"
              ></small>
            }
            @if (userIdSystemError) {
              <small
                class="block caption error margin-top-5"
                content
                [contentPath]="'auth.genericErrorTryAgain'"
              >
              </small>
            }
            @if (isDuplicateEmailError) {
              <small class="duplicateEmailError">
                <span content contentPath="auth.duplicateEmailForgotPasswordError"></span>
                <span content contentPath="shared.contactNumber"></span>
                <span>.</span>
              </small>
            }
          </ds-form-control-container>
        </div>
        <div class="column twelve md-four">
          <div class="md-pad-left-10 pad-top-10 pad-bottom-10">
            <button
              ds-button
              id="continueBtn"
              class="-outline float-left"
              data-cy="continueToSecurityQuestionButton"
              type="submit"
              [disabled]="!acceptUseridForm.valid || !responseReceived"
              content
              [contentPath]="'shared.nextBtn'"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row"><hr aria-hidden="true" /></div>
</div>
