import {
  ButtonAnalyticParams,
  EventAnalyticParams,
  PageAnalyticParams
} from '../models/analytics.model';

export class ReplaceVehicleAnalytics {
  public static readonly pageQuoteOrPurchase: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuoteOrPurchase',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly eventQuoteOrPurchase: EventAnalyticParams = {
    eventName: 'Replace Vehicle',
    eventStep: 'Quick Quote or Purchase'
  };

  public static readonly pageQuickQuoteStart: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuickQuote',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: 'Quick Quote',
    subCategory3: ''
  };

  public static readonly eventQuickQuoteStart: EventAnalyticParams = {
    eventName: 'Quick Quote',
    eventStep: 'start'
  };

  public static readonly pageQuickQuoteComplete: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:Complete',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: 'Quick Quote',
    subCategory3: ''
  };

  public static readonly eventQuickQuoteComplete: EventAnalyticParams = {
    eventName: 'Quick Quote',
    eventStep: 'complete'
  };

  public static readonly pageQuickQuoteAgentHelp: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:AgentHelp',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: 'Quick Quote',
    subCategory3: ''
  };

  public static readonly eventQuickQuoteAgentHelp: EventAnalyticParams = {
    eventName: 'Quick Quote',
    eventStep: 'Agent Help'
  };

  public static readonly pageQuickQuoteSendToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:Complete:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: 'Quick Quote',
    subCategory3: ''
  };

  public static readonly eventQuickQuoteSendToAgent: EventAnalyticParams = {
    eventName: 'Quick Quote',
    eventStep: 'Quote Submitted'
  };

  public static readonly pageQuickQuoteAgentNotified: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:AgentNotified',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: 'Quick Quote',
    subCategory3: ''
  };

  public static readonly eventQuickQuoteAgentNotified: EventAnalyticParams = {
    eventName: 'Quick Quote',
    eventStep: 'Agent Notified'
  };

  public static readonly buttonQuickQuoteNext: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:Next'
  };

  public static readonly buttonQuickQuoteCalculating: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:Calculating'
  };

  public static readonly buttonQuickQuoteCompleteSendToMyAgent: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:Complete:SendToMyAgent'
  };

  public static readonly buttonQuickQuoteCompleteGetAnotherQuote: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:Complete:GetAnotherQuote'
  };

  public static readonly buttonQuickQuoteAgentHelpSendToMyAgent: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:AgentHelp:SendToMyAgent'
  };

  public static readonly buttonQuickQuoteAgentNotifiedGetAnotherQuote: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:QuickQuote:AgentNotified:GetAnotherQuote'
  };

  public static readonly pageReplaceVehicleVinYear: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:VIN:Start',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly eventReplaceVehicleVinYear: EventAnalyticParams = {
    eventName: 'Replace Vehicle',
    eventStep: 'start'
  };

  public static readonly pageReplaceVehicleUnderWritingQuestions: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:UWDetails',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehiclePremium: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:NewPremium',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly eventReplaceVehiclePremium: EventAnalyticParams = {
    eventName: 'Replace Vehicle',
    eventStep: 'complete'
  };

  public static readonly pagePolicyReplaceVehicleNewPremiumSendToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:NewPremium:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyReplaceVehicleNewPremiumCancel: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:NewPremium:Cancel',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pagePolicyReplaceVehicleNewPremiumCancelSendToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:NewPremium:Cancel:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly buttonReplaceVehicleSendToAgent: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:SendToMyAgent'
  };

  public static readonly pageReplaceVehicleSendToAgentSuccess: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:SendToAgent:Success',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly eventReplaceVehicleSendToAgentSuccess: EventAnalyticParams = {
    eventName: 'Replace Vehicle',
    eventStep: 'Bind'
  };

  public static readonly pageReplaceVehicleBindSuccess: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:Confirmation',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly eventReplaceVehicleBindSuccess: EventAnalyticParams = {
    eventName: 'Replace Vehicle',
    eventStep: 'bind complete'
  };

  public static readonly pageReplaceVehicleHardStop: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:UWHardstop',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly buttonReplaceVehicleHardStopSendToAgent: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:UWHardstopSendToAgent'
  };

  public static readonly buttonReplaceVehicleQuoteOrPurchaseFindVIN: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:QuoteOrPurchase:FindVIN'
  };

  public static readonly pageReplaceVehicleQuoteOrPurchaseSentToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuoteOrPurchase:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly buttonReplaceVehicleVINStartFindVIN: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:VIN:Start:FindVIN'
  };

  public static readonly pageReplaceVehicleFindVIN: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:FindVIN',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleQuoteSpinner: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuoteSpinner',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleQuoteSpinnerNeedAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuoteSpinner:NeedAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleQuoteSpinnerNeedAgentSendToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuoteSpinner:NeedAgent:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleQuoteSpinnerNeedAgentCancel: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:QuoteSpinner:NeedAgent:Cancel',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleQuoteSpinnerNeedAgentCancelSendToAgent: PageAnalyticParams =
    {
      pageName: 'MyAccount:Policies:ReplaceVehicle:QuoteSpinner:NeedAgent:Cancel:SentToAgent',
      experience: '',
      primaryCategory: 'MyAccount',
      subCategory1: 'Replace Vehicle',
      subCategory2: '',
      subCategory3: ''
    };

  public static readonly pageReplaceVehicleUWDetailsCancel: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:UWDetails:Cancel',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleTPIDetails: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:TPIDetails',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleTPIDetailsCancel: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:TPIDetails:Cancel',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleTPIDetailsCancelSendToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:TPIDetails:Cancel:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleUWDetailsCancelSendToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:UWDetails:Cancel:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleFuturePayments: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:FuturePayments',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleFuturePaymentsCancel: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:FuturePayments:Cancel',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly pageReplaceVehicleFuturePaymentsSendToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:FuturePayments:SendToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly pageReplaceVehicleFuturePaymentsCancelSendToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:FuturePayments:Cancel:SendToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageReplaceVehicleFuturePaymentsNeedAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:FuturePayments:NeedAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly pageReplaceVehicleFuturePaymentNeedAgentSentToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:FuturePayments:NeedAgent:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly pageReplaceVehicleFuturePaymentsNeedAgentCancel: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:FuturePayments:NeedAgent:Cancel',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly pageReplaceVehicleFuturePaymentsNeedAgentCancelSendToAgent: PageAnalyticParams =
    {
      pageName: 'MyAccount:Policies:ReplaceVehicle:FuturePayments:NeedAgent:Cancel:SentToAgent',
      experience: '',
      primaryCategory: 'MyAccount',
      subCategory1: 'Replace Vehicle',
      subCategory2: '',
      subCategory3: ''
    };
  public static readonly pageReplaceVehicleOnlineBindError: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:Error',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly pageReplaceVehicleOnlineBindErrorSentToAgent: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:Error:SentToAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly buttonReplaceVehicleOtherSendToAgent: ButtonAnalyticParams = {
    link: 'MyAccount:Policies:ReplaceVehicle:Other:SendToAgent'
  };

  public static readonly pageReplaceVehicleOtherSendToAgentSuccess: PageAnalyticParams = {
    pageName: 'MyAccount:Policies:ReplaceVehicle:Other:SendToAgent:Success',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Replace Vehicle',
    subCategory2: '',
    subCategory3: ''
  };
}
