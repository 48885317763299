import { ButtonAnalyticParams, PageAnalyticParams } from '../models/analytics.model';

/* To be deleted, while cleaning the old flow, we will delete this */
export class PaperlessAnalytics {
  public static readonly pageOnboardingPaperless: PageAnalyticParams = {
    pageName: 'MyAccount:Onboarding:GoPaperless',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Onboarding',
    subCategory2: 'Paperless',
    subCategory3: ''
  };
  public static readonly pageGoPaperlessEnrollBothError: PageAnalyticParams = {
    pageName: 'MyAccount:Enroll:Paperless:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Policies',
    subCategory2: 'Go Paperless',
    subCategory3: ''
  };

  public static readonly pageGoPaperlessEnrollBothComplete: PageAnalyticParams = {
    pageName: 'MyAccount:Enroll:Paperless:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Policies',
    subCategory2: 'Go Paperless',
    subCategory3: ''
  };
  public static readonly buttonGoPaperlessEnrollBothCompleteSkipAutoPaySetup: ButtonAnalyticParams =
    {
      link: 'MyAccount:GoPaperless:Enroll_Both:Complete:SkipAutoPaySetup'
    };

  public static readonly buttonGoPaperlessEnrollBililngOnly: ButtonAnalyticParams = {
    link: 'MyAccount:Overview:GoPaperless:Enroll_BillingOnly'
  };
  public static readonly buttonGoPaperlessEnrollPolicyOnly: ButtonAnalyticParams = {
    link: 'MyAccount:Overview:GoPaperless:Enroll_PolicyOnly'
  };
  public static readonly buttonGoPaperlessEnrollBoth: ButtonAnalyticParams = {
    link: 'MyAccount:Overview:GoPaperless:Enroll_Both'
  };
}
/* To be deleted, while cleaning the old flow, we will delete this */
export class PaperlessAnalyticsAdmin {
  public static readonly pageOnboardingPaperless: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:Onboarding:Paperless',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Onboarding',
    subCategory3: 'Paperless'
  };
  public static readonly pageGoPaperlessEnrollBothError: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:GoPaperless:Enroll_Both:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Policies',
    subCategory3: 'Go Paperless'
  };

  public static readonly pageGoPaperlessEnrollBothComplete: PageAnalyticParams = {
    pageName: 'MyAccount:Admin:GoPaperless:Enroll_Both:Complete',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Policies',
    subCategory3: 'Go Paperless'
  };
  public static readonly buttonGoPaperlessEnrollBothCompleteSkipAutoPaySetup: ButtonAnalyticParams =
    {
      link: 'MyAccount:Admin:GoPaperless:Enroll_Both:Complete:SkipAutoPaySetup'
    };
}

export class GoPaperlessAnalytics {
  //
  // Overview :: Success - Page
  //
  public static readonly pageOverviewGoPaperlessSuccess: PageAnalyticParams = {
    pageName: 'MyAccount:GoPaperless:Success',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };
  // Overview :: Success - Done - Button
  public static readonly buttonOverviewGoPaperlessSuccessDone: ButtonAnalyticParams = {
    link: 'MyAccount:GoPaperless:Success:Done'
  };
  // Overview :: Success - Profile - Button
  public static readonly linkOverviewGoPapelressSuccessProfile: ButtonAnalyticParams = {
    link: 'MyAccount:GoPaperless:Success:Profile'
  };

  //
  // Overview :: PartialSuccess - Page
  //
  public static readonly pageOverviewGoPaperlessPartialSuccess: PageAnalyticParams = {
    pageName: 'MyAccount:GoPaperless:PartialSuccess',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };
  // Overview :: PartialSuccess :: TryAgain - Button
  public static readonly buttonOverviewGoPaperlessPartialSuccessTryAgain: ButtonAnalyticParams = {
    link: 'MyAccount:GoPaperless:PartialSuccess:TryAgain'
  };
  // Overview :: PartialSuccess :: Cancel - Button
  public static readonly buttonOverviewGoPaperlessPartialSuccessCancel: ButtonAnalyticParams = {
    link: 'MyAccount:GoPaperless:PartialSuccess:Cancel'
  };

  //
  // Overview :: Failure - Page
  //
  public static readonly pageOverviewGoPaperlessFailure: PageAnalyticParams = {
    pageName: 'MyAccount:GoPaperless:Failure',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };
  // Overview :: Failure :: TryAgain - Button
  public static readonly buttonOverviewGoPaperlessFailureTryAgain: ButtonAnalyticParams = {
    link: 'MyAccount:GoPaperless:Failure:TryAgain'
  };
  // Overview :: Failure :: Cancel - Button
  public static readonly buttonOverviewGoPaperlessFailureCancel: ButtonAnalyticParams = {
    link: 'MyAccount:GoPaperless:Failure:Cancel'
  };

  //
  // Onboarding :: Success - Page
  //
  public static readonly pageOnboardingGoPaperlessSuccess: PageAnalyticParams = {
    pageName: 'MyAccount:Onboarding:Paperless:Success',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageOnboardingGoPaperlessAlreadyOnAutoPaySuccess: PageAnalyticParams = {
    pageName: 'MyAccount:Onboarding:Paperless:AlreadyOnAutoPay:Success',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };

  // Onboarding :: Success - SetupAutoPay - Button
  public static readonly buttonOnboardingGoPaperlessSuccessSetUpAutoPay: ButtonAnalyticParams = {
    link: 'MyAccount:Onboarding:Paperless:Success:SetUpAutoPay'
  };
  // Onboarding :: Success - SkipAutoPaySetup - Button
  public static readonly buttonOnboardingGoPaperlessSuccessSkipAutoPaySetup: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:Success:SkipAutoPaySetup'
    };
  // Onboarding :: Success - Done - Button
  public static readonly buttonOnboardingGoPaperlessAlreadyOnAutoPaySuccessDone: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:AlreadyOnAutoPay:Success:Done'
    };

  //
  // Onboarding :: PartialSuccess - Page
  //
  public static readonly pageOnboardingGoPaperlessPartialSuccess: PageAnalyticParams = {
    pageName: 'MyAccount:Onboarding:Paperless:PartialSuccess',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageOnboardingGoPaperlessAlreadyOnAutoPayPartialSuccess: PageAnalyticParams =
    {
      pageName: 'MyAccount:Onboarding:Paperless:AlreadyOnAutoPay:PartialSuccess',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: '',
      subCategory2: '',
      subCategory3: ''
    };

  // Onboarding :: PartialSuccess :: TryAgain - Button
  public static readonly buttonOnboardingGoPaperlessPartialSuccessTryAgain: ButtonAnalyticParams = {
    link: 'MyAccount:Onboarding:Paperless:PartialSuccess:TryAgain'
  };
  // Onboarding :: AlreadyOnAutoPay:: PartialSuccess :: TryAgain - Button
  public static readonly buttonOnboardingGoPaperlessAlreadyOnAutoPayPartialSuccessTryAgain: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:AlreadyOnAutoPay:PartialSuccess:TryAgain'
    };

  // Onboarding :: PartialSuccess :: ContinueAutoPaySetup - Button
  public static readonly buttonOnboardingGoPaperlessPartialSuccessContinueAutoPaySetup: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:PartialSuccess:ContinueAutoPaySetup'
    };
  // Onboarding :: PartialSuccess :: SkipAutoPaySetup - Button
  public static readonly buttonOnboardingGoPaperlessPartialSuccessSkipAutoPaySetup: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:PartialSuccess:SkipAutoPaySetup'
    };

  // Onboarding :: AlreadyOnAutoPay :: PartialSuccess :: Cancel - Button
  public static readonly buttonOnboardingGoPaperlessAlreadyOnAutoPayPartialSuccessCancel: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:AlreadyOnAutoPay:PartialSuccess:Cancel'
    };

  //
  // Onboarding :: Failure - Page
  //
  public static readonly pageOnboardingGoPaperlessFailure: PageAnalyticParams = {
    pageName: 'MyAccount:Onboarding:Paperless:Failure',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageOnboardingGoPaperlessAlreadyOnAutoPayFailure: PageAnalyticParams = {
    pageName: 'MyAccount:Onboarding:Paperless:AlreadyOnAutoPay:Failure',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };
  // Onboarding :: Failure :: TryAgain - Button
  public static readonly buttonOnboardingGoPaperlessFailureTryAgain: ButtonAnalyticParams = {
    link: 'MyAccount:Onboarding:Paperless:Failure:TryAgain'
  };
  // Onboarding :: AlreadyOnAutoPay:: Failure :: TryAgain - Button
  public static readonly buttonOnboardingGoPaperlessAlreadyOnAutoPayFailureTryAgain: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:AlreadyOnAutoPay:Failure:TryAgain'
    };
  // Onboarding :: Failure :: ContinueAutoPaySetup - Button
  public static readonly buttonOnboardingGoPaperlessFailureContinueAutoPaySetup: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:Failure:ContinueAutoPaySetup'
    };
  // Onboarding :: Failure :: SkipAutoPaySetup - Button
  public static readonly buttonOnboardingGoPaperlessFailureSkipAutoPaySetup: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:Failure:SkipAutoPaySetup'
    };
  // Onboarding :: AlreadyOnAutoPay :: Failure :: Cancel - Button
  public static readonly buttonOnboardingGoPaperlessAlreadyOnAutoPayFailureCancel: ButtonAnalyticParams =
    {
      link: 'MyAccount:Onboarding:Paperless:AlreadyOnAutoPayFailure:Cancel'
    };
}
