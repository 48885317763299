import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import {
  Applications,
  ApplicationService,
  CopyService
} from '@amfam/shared/utility/shared-services';
import { Option } from '@amfam/ui-kit';

@Component({
  selector: 'ds-setup-payment-method',
  templateUrl: './setup-payment-method.component.html',
  styleUrls: ['./setup-payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetupPaymentMethodComponent implements OnInit {
  @Input() paymentMethodOptions: Option[];
  @Input() formGroup: UntypedFormGroup;
  @Input() displayDiscountWarning: boolean;
  @Input() displayPaymentExpiredError: boolean;
  @Input() displayAutoPayDiscountMessage: boolean;
  @Input() authorizedToAddPaymentMethod: boolean;
  @Input() authorizedToEditPaymentMethod: boolean;
  @Input() paymentMethodError: boolean;
  @Input() paymentError: string;

  @Output() editPaymentMethodEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() addCreditDebitEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() addCheckingAccountEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() addSavingsAccountEvent: EventEmitter<void> = new EventEmitter<void>();

  autoPayWarningHeading: string;
  autoPayWarningBody: string;
  paymentExpiredErrorMessage: string;
  choosePaymentMethod: string;

  constructor(
    private copyService: CopyService,
    private appService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.autoPayWarningHeading = this.copyService.getCopy(
      'billing.autoPayRefactor.aboutAutoPayDiscountHeader'
    );
    this.autoPayWarningBody = this.appService.isApp(Applications.MYACCOUNT_ADMIN)
      ? this.copyService.getCopy('billing.autoPayRefactor.adminToolLoseAutoPayDiscountMessage')
      : this.copyService.getCopy('billing.autoPayRefactor.loseAutoPayDiscountMessage');

    this.paymentExpiredErrorMessage = this.copyService.getCopy(
      'billing.autoPayRefactor.paymentExpiredMessage'
    );

    this.choosePaymentMethod = this.copyService.getCopy(
      'billing.oneTimePayRefactor.choosePaymentMethod'
    );
  }

  editPaymentMethod(): void {
    if (this.authorizedToEditPaymentMethod) {
      this.editPaymentMethodEvent.emit();
    }
  }

  addCreditDebit(): void {
    if (this.authorizedToEditPaymentMethod) {
      this.addCreditDebitEvent.emit();
    }
  }

  addCheckingAccount(): void {
    if (this.authorizedToEditPaymentMethod) {
      this.addCheckingAccountEvent.emit();
    }
  }

  addSavingsAccount(): void {
    if (this.authorizedToEditPaymentMethod) {
      this.addSavingsAccountEvent.emit();
    }
  }
}
