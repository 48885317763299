import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { AccountTypeEnum, userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService, PageAnalytic } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService, ToasterService } from '@amfam/ui-kit';

import * as fromRoot from '../../../../core/store/';
import * as enrollUserActions from '../../../../core/store/enrollment/enroll-user.actions';
import * as enrollStepsActions from '../../../../core/store/enrollment/steps/enroll-steps.actions';
import * as workflowActions from '../../../../core/store/workflow';
import { stepNames } from '../../../../core/store/workflow/workflow.model';
import { VerifyTokenModel } from '../../../shared/verify-token.model';
import { VerifyUserPhoneModel } from '../../../shared/verify-user-phone.model';
import { VerificationService } from '../verification.service';

@Component({
  selector: 'ds-enroll-verify-text',
  templateUrl: './enroll-verify-text.component.html',
  styleUrls: ['../../../enrollment.component.scss']
})
export class EnrollVerifyTextComponent implements OnInit, OnDestroy {
  phone: string;
  gotError: boolean;
  showCodeEntry = false;
  phoneList: any = [];
  partyId: string;
  codeNotFound = false;
  partnerId: string;
  experienceId: string;
  verificationCodes = this.copyService.getCopy('enrollment.verifyOptionsCode');
  private pageAnalyticVerify: PageAnalytic;
  private pageAnalyticConfirm: PageAnalytic;
  private conversionPageAnalyticVerify: PageAnalytic;
  private conversionPageAnalyticConfirm: PageAnalytic;
  private typeOfAccountCode: string;
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<fromRoot.RootState>,
    private verifyBy: VerificationService,
    private toasterSvc: ToasterService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade,
    private copyService: CopyService
  ) {}

  ngOnInit() {
    this.buildVerifyAnalytics();
    this.buildConfirmAnalytics();

    combineLatest(
      this.store.select(fromRoot.getEnrollUserState),
      this.store.select(userQuery.getTypeOfAccountCode),
      this.store.select(BrandSelectors.selectExperienceId),
      (enrollUserState, typeOfAccountCode, experienceId) => ({
        enrollUserState: enrollUserState,
        typeOfAccountCode: typeOfAccountCode,
        experienceId: experienceId
      })
    )
      .pipe(take(1))
      .subscribe(state => {
        this.phoneList = state.enrollUserState.maskedPhones;
        this.partyId = state.enrollUserState.partyId;
        this.typeOfAccountCode = state.typeOfAccountCode;
        this.partnerId = state.enrollUserState.partnerId;
        this.experienceId = state.experienceId;

        // Analytics for conversion
        if (this.typeOfAccountCode && this.typeOfAccountCode !== AccountTypeEnum.Customer) {
          this.analyticsFacade.trackPage(this.conversionPageAnalyticVerify);
        } else {
          this.analyticsFacade.trackPage(this.pageAnalyticVerify);
        }
      });
  }

  goBackOneStep() {
    this.store.dispatch(new workflowActions.WorkflowStepBackAction());
  }

  sendCode(phone: string) {
    this.spinner.start();
    this.phone = phone;
    const textError = 'We had trouble sending you a text';
    const requestObj: VerifyUserPhoneModel = new VerifyUserPhoneModel(
      this.partyId,
      this.verificationCodes['MESSAGE'],
      phone,
      this.partnerId,
      this.experienceId
    );

    this.verifyBy
      .text(requestObj)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        (res: any) => {
          // TODO - pull this from a message service
          this.spinner.stop();
          const textMsg = 'We will be texting you shortly with your code';
          if (res) {
            this.toasterSvc.pop('success', textMsg);
            this.showCodeEntry = true;
            this.trackConfirmAnalytics();
          } else {
            this.toasterSvc.pop('error', textError);
            this.showCodeEntry = false;
          }
          this.gotError = false;
        },
        err => {
          this.spinner.stop();
          if (!err) {
            return;
          }
          this.gotError = true;
          this.toasterSvc.pop('error', textError);
          this.showCodeEntry = false;
        }
      );
  }

  // Track analytics for pin confirmation
  private trackConfirmAnalytics() {
    if (this.typeOfAccountCode && this.typeOfAccountCode !== AccountTypeEnum.Customer) {
      this.analyticsFacade.trackPage(this.conversionPageAnalyticConfirm);
    } else {
      this.analyticsFacade.trackPage(this.pageAnalyticConfirm);
    }
  }

  verifyCode(pin: string) {
    this.spinner.start();
    const requestObj: VerifyTokenModel = new VerifyTokenModel(
      pin,
      this.verificationCodes['MESSAGE'],
      this.partyId
    );

    this.verifyBy
      .pin(requestObj)
      .pipe(takeUntil(this.stop$))
      .subscribe(
        (res: any) => {
          this.spinner.stop();
          if (!res) {
            return;
          }
          const statusCode = res.status && res.status.code ? res.status.code : 500;
          if (statusCode === 200) {
            this.verifyBy.resetVerifyAttempts();
            this.toasterSvc.pop('success', 'Your code has been verified');
            this.store.dispatch(
              new enrollUserActions.SubmitVerificationAction({
                verificationType: 'phone',
                partyId: this.partyId
              })
            );
            this.store.dispatch(
              new enrollStepsActions.VerifyStepAction({
                verificationStep: true
              })
            );

            // complete the step
            this.store.dispatch(
              new workflowActions.WorkflowStepCompleteAction({ step: stepNames.verification })
            );
          } else {
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/enroll/contact-us']
              })
            );
          }
        },
        err => {
          this.spinner.stop();
          if (!err) {
            return;
          }
          const errCode = err.status && err.status.code ? err.status.code : 500;
          if (errCode === 404) {
            this.verifyBy.incrementAttempts();
            this.codeNotFound = true;
          } else {
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/enroll/contact-us']
              })
            );
          }
        }
      );
  }

  private buildVerifyAnalytics() {
    this.pageAnalyticVerify = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:Text',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'Confirm Identity:Text'
    };
    this.conversionPageAnalyticVerify = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:Text',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Add Personal Line',
      subCategory2: '',
      subCategory3: ''
    };
  }

  private buildConfirmAnalytics() {
    this.pageAnalyticConfirm = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:VerificationCode',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'Confirm Identity:VerificationCode'
    };
    this.conversionPageAnalyticConfirm = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity:VerificationCode',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Add Personal Line',
      subCategory2: '',
      subCategory3: ''
    };
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
