import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  of as observableOf,
  throwError as observableThrowError
} from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { GetSecurityQuestionsModel } from '@amfam/shared/models';

import { ConfigService } from '../config';

@Injectable({
  providedIn: 'root'
})
export class SecurityQuestionsService {
  private _editQuestionsStore$: BehaviorSubject<any> = new BehaviorSubject(null);
  private _userQuestionsStore$: BehaviorSubject<any> = new BehaviorSubject([]);
  private _userQuestionsErrorStore$: BehaviorSubject<any> = new BehaviorSubject(null);
  private deactivateEnabled = false;

  private digitalAccountApiName = 'digitalAccountApi';

  constructor(
    public config: ConfigService,
    public http: HttpClient
  ) {}

  get editQuestionsStore$() {
    return this._editQuestionsStore$.asObservable();
  }

  get userQuestions$() {
    return this._userQuestionsStore$.asObservable();
  }

  get userQuestionsError$() {
    return this._userQuestionsErrorStore$.asObservable();
  }

  setUserQuestionsError$(errorCode) {
    this._userQuestionsErrorStore$.next(errorCode);
  }

  public enableDeactivate() {
    this.deactivateEnabled = true;
  }

  public disableDeactivate() {
    this.deactivateEnabled = false;
  }

  public canDeactivate() {
    return this.deactivateEnabled;
  }

  public getAllQuestions(): Observable<any> {
    const questionsEndPoint = this.config.get(this.digitalAccountApiName);
    const questionsUrl = questionsEndPoint + '/referencedata?filter=SECURITY%20QUESTIONS';
    return this.http.get(questionsUrl).pipe(
      mergeMap((data: any) => {
        if (data && data.status.code === 200) {
          const questionsList: Array<string> = data.questions;
          return observableOf(questionsList);
        }
        return this.handleError(data);
      }),
      catchError(this.handleError)
    );
  }

  public postAnswers(securityAnswersData: any): Observable<any> {
    return this.http
      .post(
        this.config.get(this.digitalAccountApiName) + '/digitalaccounts/forgotpassword',
        JSON.stringify(securityAnswersData)
      )
      .pipe(
        mergeMap((data: any) => {
          return observableOf(data);
        }),
        catchError(this.handleError)
      );
  }

  /**
   * MR: Gets the security questions associated with a user
   * @param userData - Contains userIdentifier and partnerId to be used for finding security questions
   */
  public getUserSecurityQuestions(userData: GetSecurityQuestionsModel): Observable<any> {
    const endpoint = this.formatUrl(userData);

    return this.http.get(endpoint).pipe(
      mergeMap((data: any) => {
        if (data && data.status && data.status.code === 200) {
          const questions: Array<string> = data.securityQuestions.map(item => item.question);
          return observableOf(questions);
        }
      }),
      catchError(this.handleError)
    );
  }

  /**
   * MR: Formats endpoint based on whether the userIdentifier is a userId or email address
   * @param userData - Contains userIdentifier and partnerId to be used for finding security questions
   */
  public formatUrl(userData: GetSecurityQuestionsModel): string {
    const url = this.config.get(this.digitalAccountApiName) + '/securityquestions?';
    if (userData.userIdentifier.includes('@')) {
      return (
        url +
        'email=' +
        encodeURIComponent(userData.userIdentifier) +
        '&partnerId=' +
        userData.partnerId
      );
    } else {
      return url + 'userId=' + userData.userIdentifier;
    }
  }

  private handleError(error) {
    return observableThrowError(error);
  }
}
