@if (!(fromOverview && alert.level === 5)) {
  <div authCheck [permissionName]="'view_sso_banner'" [checkAction]="'remove'" data-cy="alertItem">
    @if (content && !alert.data?.registeredElseWhere) {
      <div [ngClass]="alertClasses" class="alert items-center flex">
        <span [ngClass]="alertIcon"></span>
        @if (showPastDueAlert) {
          <div class="alert-message" data-cy="alertMessage">
            <bills-overdue-alert
              (routeToBillAccount)="routeToBillAccount($event)"
              [billAccounts]="alert.data.billAccounts"
            ></bills-overdue-alert>
          </div>
        }
        @if (!showPastDueAlert) {
          <div class="alert-message" data-cy="alertMessage" [innerHtml]="content.message"></div>
          @if (content.action && alert.level === alertLevels.WARNING) {
            <button class="alert-action-button" (click)="takeAction()">
              {{ content.action }}
            </button>
          }
        }
        <div class="alert-action">
          @if (content.action && alert.level !== alertLevels.WARNING) {
            <button
              ds-button
              authCheck
              [permissionName]="'select_sso_banner'"
              [checkAction]="'disable'"
              [applyAuthCheck]="alert.type === alertTypes.POLICIES_MANAGED_EXTERNALLY"
              [ngClass]="buttonClasses"
              (click)="takeAction()"
              data-cy="alertActionButton"
            >
              {{ content.action }}
            </button>
          }
        </div>
      </div>
    }
  </div>
}
