import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BillAccountCanRegister } from '../models/bill-account-can-register.models';

export const BILL_ACCOUNT_CAN_REGISTER_FEATURE_KEY = 'billAccountCanRegister';

export const selectBillAccountCanRegisterState = createFeatureSelector<BillAccountCanRegister>(
  BILL_ACCOUNT_CAN_REGISTER_FEATURE_KEY
);

export const selectLoading = createSelector(
  selectBillAccountCanRegisterState,
  (state: BillAccountCanRegister) => state.loading
);

export const selectLoaded = createSelector(
  selectBillAccountCanRegisterState,
  (state: BillAccountCanRegister) => state.loaded
);

export const billAccountCanRegisterQuery = {
  selectBillAccountCanRegisterState,
  selectLoading,
  selectLoaded
};
