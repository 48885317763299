import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { find as _find } from 'lodash';
import { from as observableFrom, of as observableOf, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  startWith,
  switchMap,
  toArray,
  withLatestFrom
} from 'rxjs/operators';

import { PoliciesLoadSuccess, PolicySelectors } from '@amfam/policy/data-access';

import * as fromRoot from '../../';
import { KydSmartphoneService } from '../../../../policy/know-your-drive-partial-household/services/kyd-smartphone.service';
import { KydService } from '../../../../policy/know-your-drive/shared/kyd.service';
import * as kydEligibilityActions from './kyd-eligibility.actions';

@Injectable()
export class KydEligibilityEffects {
  loading$ = createEffect(() => {
    return this.action$.pipe(
      ofType(PoliciesLoadSuccess),
      map(action => action.payload),
      withLatestFrom(this.store.select(PolicySelectors.selectAdvanceAutoPolicyRisks)),
      map(([policyArr, riskModelArr]) => new kydEligibilityActions.LoadAction(riskModelArr))
    );
  });

  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydEligibilityActions.LOAD),
      map((action: kydEligibilityActions.LoadAction) => action.payload),
      mergeMap(risks =>
        observableFrom(risks).pipe(
          mergeMap(risk => {
            return this.kydService.verifyEligibility(
              risk.policyNumber,
              risk.vin,
              risk.address.state
            );
          }),
          toArray()
        )
      ),
      // if all eligibility fails it wont come into map, so LoadComplete action won't get fired. so, calling startWith here.
      startWith(null),
      switchMap(riskEligibilities => [
        new kydEligibilityActions.LoadSuccessAction(riskEligibilities),
        new kydEligibilityActions.LoadCompleteAction()
      ]),
      catchError(error => observableOf(new kydEligibilityActions.LoadFailAction(error)))
    )
  );

  refresh$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydEligibilityActions.REFRESH_ELIGIBILITY),
      map((action: kydEligibilityActions.RefreshEligibilityAction) => action.payload),
      withLatestFrom(this.store.select(PolicySelectors.selectAdvanceAutoPolicyRisks)),
      map(([policyNumbers, risks]) => {
        return risks.filter(risk =>
          policyNumbers.some(policyNumber => risk.policyNumber === policyNumber)
        );
      }),
      map(risks => new kydEligibilityActions.LoadAction(risks))
    )
  );

  fetchSmartphonePolicies$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydEligibilityActions.LOAD_SMARTPHONE_KYD_POLICIES),
      map((action: kydEligibilityActions.LoadSmartPhoneKydPoliciesAction) => action.payload),
      switchMap(({ smartPhonePolicyList, smartPhoneEnrollmentSummaryList }) =>
        this.kydSmartphoneService.prepareSmartphonePolicies(smartPhonePolicyList).pipe(
          mergeMap(policies =>
            smartPhoneEnrollmentSummaryList
              .map(enrollment => {
                const policy = _find(
                  policies,
                  _policy => enrollment.policyNumber === _policy.policyNumber
                );
                return { enrollment, policy };
              })
              .filter(enrollObj => enrollObj.policy)
          ),
          mergeMap(enrollObj =>
            this.kydSmartphoneService.setEnrollmentSummary(enrollObj.policy, enrollObj.enrollment)
          ),
          toArray(),
          map(
            response =>
              new kydEligibilityActions.LoadSmartPhoneKydPoliciesActionSuccessAction(response)
          ),
          catchError(err =>
            of(new kydEligibilityActions.LoadSmartPhoneKydPoliciesActionFailAction(err))
          )
        )
      )
    )
  );

  constructor(
    private store: Store<fromRoot.RootState>,
    private kydService: KydService,
    private action$: Actions,
    private kydSmartphoneService: KydSmartphoneService
  ) {}
}
