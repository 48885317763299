import { ApiStatus, PartyEmail } from '@amfam/shared/models';

export interface PendingEmailVerification {
  pending: boolean;
  oldEmail: PartyEmail;
  newEmail: PartyEmail;
  tokenExpiry?: boolean;
}

export interface UpdateContactMethodPayload {
  data: PartyEmail;
  methodType: string;
}

export interface EmailProfile {
  confirmed: boolean;
  createDate: number;
  createProgramName: string;
  createUserId: string;
  displayName: string;
  emailAddress: string;
  id: number;
  profileLastConfirmed: number;
  profileLastUpdated: number;
  updateDate: number;
  updatedProgramName: string;
  updatedUserId: string;
  waid: string;
}

export interface EmailValidationResponse {
  emailProfile: EmailProfile;
  status: ApiStatus;
}

export interface EmailPayloadToGetValidationStatus {
  newEntry: PartyEmail;
  oldEntry: PartyEmail | undefined;
  type: string;
}

export type MembershipType = 'BUSINESS_MEMBERSHIP' | 'GOLD STAR' | 'EXECUTIVE';
