import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { get as _get } from 'lodash';

import * as AutoPaySetupActions from './auto-pay-setup.actions';
import { AutoPayContext, AutoPaySetup, AutoPaySteps } from './auto-pay-setup.models';

export const autoPaySetupFeatureKey = 'autoPaySetup';

export interface State extends EntityState<AutoPaySetup> {
  context: AutoPayContext;
  activeStep: AutoPaySteps;
  entryPoint: string;
  preselectedAccounts: string[];
  registrationError: boolean;
  isUserComingFromEnrollment: boolean;
}

export const adapter: EntityAdapter<AutoPaySetup> = createEntityAdapter<AutoPaySetup>({
  selectId: (autoPaySetup: AutoPaySetup) => autoPaySetup.billAccount.billAccountNumber,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  context: null,
  activeStep: AutoPaySteps.SELECT_ACCOUNTS,
  entryPoint: 'overview',
  preselectedAccounts: [],
  registrationError: false,
  isUserComingFromEnrollment: false
});

export const reducer = createReducer(
  initialState,

  on(
    AutoPaySetupActions.setPreselectedAccounts,
    (state, action): State => ({
      ...state,
      preselectedAccounts: action.billAccountNumbers
    })
  ),

  on(
    AutoPaySetupActions.setEntryPoint,
    (state, action): State => ({
      ...state,
      entryPoint: action.entryPoint
    })
  ),

  on(
    AutoPaySetupActions.isUserComingFromEnrollment,
    (state, action): State => ({
      ...state,
      isUserComingFromEnrollment: action.isUserComingFromEnrollment
    })
  ),

  on(AutoPaySetupActions.initAutoPaySetup, (state, action) =>
    adapter.setAll(action.setups, {
      ...state,
      context: action.context,
      registrationError: action.registrationError
    })
  ),

  on(AutoPaySetupActions.updateAutoPaySetup, (state, action) =>
    adapter.updateOne(
      {
        id: action.billAccountNumber,
        changes: Object.assign({}, state.entities[action.billAccountNumber], {
          autoPaySettings: action.autoPaySettings
        })
      },
      state
    )
  ),

  on(AutoPaySetupActions.setStep, (state, action) => {
    if (_get(action, 'activeBillAccount', null)) {
      // set active setup to active: true, set all others to false
      const setupUpdates: Update<AutoPaySetup>[] = (state.ids as string[]).map(id => ({
        id,
        changes: Object.assign({}, state.entities[id], {
          active: id === action.activeBillAccount,
          editing: id === action.activeBillAccount && action.isEditing
        })
      }));
      return adapter.updateMany(setupUpdates, {
        ...state,
        activeStep: action.step
      });
    } else {
      return {
        ...state,
        activeStep: action.step
      };
    }
  }),

  on(AutoPaySetupActions.resetAutoPaySetup, (): State => initialState)
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAutoPaySetupsIds = selectIds;
export const selectAutoPaySetupEntities = selectEntities;
export const selectAllAutoPaySetups = selectAll;
export const selectAutoPaySetupsTotal = selectTotal;
