<div class="row">
  <div class="column relative twelve phoneListInfo" data-cy="phoneListInfo">
    <div class="pad-top-10 pad-bottom-10 items-center justify-space-between sm-flex">
      <div class="flex-auto">
        <strong data-cy="phoneNumber">{{ phone | phoneDescription }}</strong>
        @if (phone.primaryIndicator) {
          <span data-cy="phoneNumberPrimary">
            <span class="pad-left-20 success-400-text icon icon-complete-circle icon-size"></span>
            <span class="pad-left-5 subTextSize" content [contentPath]="'shared.primary'"></span>
          </span>
        }
        <p data-cy="phoneType" class="subTextSize">
          {{ phone.contactMethodUsages[0].typeOfUsageCode | titlecase }}
          @if (phone.contactMethodUsages[0].descriptionForOtherType) {
            <span> - </span>
          }
          {{ phone.contactMethodUsages[0].descriptionForOtherType }}
        </p>
      </div>
      <a
        [hidden]="(roleService.isAuthorized('editphonenumber_view') | async) === false"
        (click)="deletePhone()"
        data-cy="deletePhoneButton"
        class="caption error-600-text font-weight-bold"
        content
        [contentPath]="'shared.deleteBtn'"
      ></a>
      @if (!phone.primaryIndicator) {
        <button
          ds-button
          data-cy="makePrimaryButton"
          class="-outline-white flex-constrained margin-top-10 sm-margin-top-0 margin-left-20"
          [disabled]="(roleService.isAuthorized('make_primary_phone') | async) === false"
          type="button"
          (click)="makePrimary()"
          content
          [contentPath]="'shared.makePrimaryText'"
        ></button>
      }
    </div>
  </div>
</div>
