import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';

import { ProfileActions, profileQuery } from '@amfam/profile/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { PageAnalytic } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import * as fromRoot from '../../core/store';

@Component({
  selector: 'ds-add-security-questions',
  templateUrl: './add-security-questions.component.html',
  styleUrls: ['./add-security-questions.component.scss']
})
export class AddSecurityQuestionsComponent implements OnInit, OnDestroy {
  securityQuestionsSuccess: boolean;
  securityQuestionsFailed: boolean;
  securityQuestions: any;
  private stop$ = new Subject<void>();

  constructor(
    private store: Store<fromRoot.RootState>,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    this.trackPage('SecurityQuestions');

    this.store
      .select(profileQuery.selectProfileSecurityInfoStatus)
      .pipe(
        skipWhile(status => status.loading),
        takeUntil(this.stop$)
      )
      .subscribe(status => {
        this.spinner.stop();
        if (!status.loading && status.editSecurityInfoError === false) {
          this.securityQuestionsSuccess = true;
          // Update security questions in Store
          this.store.dispatch(
            ProfileActions.UpdateSecurityQuestionsAction({
              securityQuestions: this.securityQuestions
            })
          );
          this.trackPage('SecurityQuestionsSaved');
        } else if (!status.loading && status.editSecurityInfoError) {
          this.securityQuestionsFailed = true;
          this.trackPage('ErrorSaving');
        } else {
          return;
        }
      });
  }

  onComplete(response: any) {
    this.spinner.start();
    this.securityQuestions = response.securityQuestions;
    const data = {
      securityQuestions: response.securityQuestions
    };
    this.store.dispatch(
      ProfileActions.ChangeSecurityInfoAction({
        data,
        methodType: 'securityQuestions',
        hideToaster: true
      })
    );
  }

  trackPage(step: string) {
    const pageAnalytic: PageAnalytic = {
      pageName: `MyAccount:Enroll:ConvertUser:${step}`,
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Convert User',
      subCategory2: '',
      subCategory3: ''
    };
    this.analyticsFacade.trackPage(pageAnalytic);
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
