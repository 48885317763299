import { Action } from '@ngrx/store';

import { Agent } from '../models/agent.model';

export enum AgentActionTypes {
  LoadAgents = '[Agent] Load',
  LoadAgentsSuccess = '[Agent] Load Success',
  LoadAgentsFail = '[Agent] Load Fail'
}

export class LoadAgentAction implements Action {
  readonly type = AgentActionTypes.LoadAgents;
  constructor(public payload?: Agent) {}
}

export class LoadAgentsSuccessAction implements Action {
  readonly type = AgentActionTypes.LoadAgentsSuccess;
  constructor(public payload: Agent) {}
}

export class LoadAgentsFailAction implements Action {
  readonly type = AgentActionTypes.LoadAgentsFail;
  constructor(public payload?: any) {}
}

export type AgentActions = LoadAgentAction | LoadAgentsSuccessAction | LoadAgentsFailAction;

export const fromAgentActions = {
  LoadAgentAction,
  LoadAgentsSuccessAction,
  LoadAgentsFailAction
};
