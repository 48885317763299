import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FnolEffects } from './+state/fnol.effects';
import * as fromFnol from './+state/fnol.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromFnol.FNOL_FEATURE_KEY, fromFnol.reducer),
    EffectsModule.forFeature([FnolEffects])
  ]
})
export class ClaimDynamicFnolDataAccessFnolModule {}
