import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, toArray, withLatestFrom } from 'rxjs/operators';

import { PolicySelectors } from '@amfam/policy/data-access';

import * as fromRoot from '../../';
import { KydService } from '../../../../policy/know-your-drive/shared/kyd.service';
import * as kydActions from './kyd.actions';

@Injectable()
export class KydEffects {
  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydActions.LOAD),
      map((action: kydActions.LoadAction) => action.payload),
      mergeMap(autoPolicies => {
        return observableFrom(autoPolicies).pipe(
          mergeMap(autoPolicy => this.kydService.getPolicyEnrollments(autoPolicy.policyNumber)),
          toArray()
        );
      }),
      map(enrollmentResponses => {
        this.store.dispatch(new kydActions.LoadSuccessAction(enrollmentResponses));
        return new kydActions.LoadCompleteAction();
      }),
      catchError(error => observableOf(new kydActions.LoadFailAction(error)))
    )
  );

  refresh$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydActions.REFRESH),
      map((action: kydActions.RefreshAction) => action.payload),
      withLatestFrom(this.store.select(PolicySelectors.selectAdvanceAutoPolicies)),
      mergeMap(([policyNumbers, autoPolicies]) =>
        observableOf(
          autoPolicies.filter(policy =>
            policyNumbers.some(policyNumber => policy.policyNumber === policyNumber)
          )
        )
      ),
      map(policies => new kydActions.LoadAction(policies))
    )
  );

  constructor(
    private store: Store<fromRoot.RootState>,
    private kydService: KydService,
    private action$: Actions
  ) {}
}
