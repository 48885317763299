@if (notifications?.messagesArray?.length) {
  <div class="mobile-notification-container">
    <div #mobileNotificationElem class="mobile-notification-menu">
      <button
        #notificationMenuLink
        role="menuitem"
        tabindex="0"
        class="notification-icon"
        [attr.aria-label]="notificationMessage"
        (click)="toggleSub()"
      >
        <span
          class="mobile-notification-icon"
          [ngClass]="!showNotificationMenuSub ? 'icon-bell' : 'icon-exit'"
        ></span>
        @if (!showNotificationMenuSub) {
          <div data-cy="headerNotificationNumber" class="mobile-notification-number">
            {{ notifications?.messagesArray?.length }}
          </div>
        }
      </button>
    </div>
    <div
      role="menu"
      id="navigationMobileNotificationMenuItems"
      data-cy="navigationMobileNotificationMenuItems"
    >
      <ul
        [@toggleSubmenu]="showNotificationMenuSub"
        class="mobile-notification-message"
        role="navigation"
        id="navigationMobileNotificationMenu"
        data-cy="navigationMobileNotificationMenu"
      >
        <li class="mobile-notification-subnav-title">
          <a [href]="">
            <span>Notifications</span>
          </a>
        </li>
        @for (message of notifications?.messagesArray; track message) {
          <li #notificationItem role="menuitem" class="mobile-message-item">
            <a
              class="item-link"
              role="menuitem"
              [style.width.px]="notifications?.width"
              [attr.aria-label]="message.linkText"
              [attr.data-cy]="message?.linkTestId"
              (click)="onLinkClick(message)"
            >
              <div class="mobile-link-content">
                <span class="link-content-icon icon-documents"></span>
                <span class="link-text">{{ message.linkText }}</span>
              </div>
            </a>
            <a
              class="dismiss-link"
              data-cy="notificationDismissItems"
              (blur)="onLinkBlur($event.currentTarget)"
              (click)="onDismissClick(message)"
            >
              <span class="dismiss-icon icon-exit"
                ><span class="sr-only">Dismiss Message</span></span
              >
            </a>
          </li>
        }
      </ul>
    </div>
  </div>
}
