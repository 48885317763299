import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ResetPasswordSubmitModel } from '@amfam/shared/models';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { AuthService } from '../../core/auth/auth.service';
import * as fromRoot from '../../core/store';
import * as resetPassword from '../../core/store/reset-password/reset-password.actions';
import { ForgotPasswordAnalytics } from '../forgot-password/shared/forgot-password-analytic-constants';
import { ForgotService } from '../shared/forgot.service';

@Component({
  selector: 'ds-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  securityQuestions: any;
  correlationId: string;
  pinCode: string;
  resetPasswordErrorText: string;
  resetPasswordErrorTextGeneric: string;
  resetPasswordErrorTextInvalid: string;
  customerCareNumber: string;
  token: string;
  userIdentifier: string;

  constructor(
    private authService: AuthService,
    private forgotService: ForgotService,
    private copyService: CopyService,
    private store: Store,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    combineLatest(
      this.store.select(fromRoot.getResetPasswordState),
      this.store.select(BrandSelectors.selectPrettyCusCareNumber)
    )
      .pipe(
        map(([passwordState, number]) => ({ passwordState: passwordState, number: number })),
        take(1)
      )
      .subscribe(state => {
        this.customerCareNumber = state.number;
        this.userIdentifier = _get(state.passwordState, 'userIdentifier', undefined);
        this.pinCode = _get(state.passwordState, 'pinCode', undefined);
        this.correlationId = _get(state.passwordState, 'correlationId', undefined);
        this.securityQuestions = _get(state.passwordState, 'answeredSecurityQuestions', undefined);
      });

    this.resetPasswordErrorTextGeneric = this.copyService.getCopy(
      'auth.resetPasswordGenericError',
      {
        customerCareNumber: this.customerCareNumber
      }
    );
    this.resetPasswordErrorTextInvalid = this.copyService.getCopy(
      'auth.resetPasswordInvalidPassword'
    );

    this.analyticsFacade.trackPage(ForgotPasswordAnalytics.pageResetPassword);
  }

  onComplete(password: string) {
    this.spinner.start();
    this.resetPasswordErrorText = null;

    const resetPasswordSubmitObject: ResetPasswordSubmitModel = {
      userIdentifier: this.userIdentifier,
      newPassword: password,
      pinCode: this.pinCode,
      correlationId: this.correlationId,
      securityQuestions: this.securityQuestions
    };

    this.store.dispatch(new resetPassword.ResetPasswordAction(resetPasswordSubmitObject));

    this.store
      .select(fromRoot.getResetPasswordState)
      .pipe(
        filter(state => !state.loading),
        take(1)
      )
      .subscribe(state => {
        this.spinner.stop();
        if (state.hasError) {
          this.handleApiResponseCode(state.status);
        } else {
          this.authService.loginRedirect();
        }
      });
  }

  private handleApiResponseCode(err: any) {
    this.spinner.stop();
    const apiCode = _get(err, 'messages[0].code');
    switch (apiCode) {
      case 400000: // invalid or missing parameters. malformed request
        break;
      case 400001: // request missing information
        break;
      case 400002: // Invalid token
        break;
      case 400003: // inconsistent information provided
        break;
      case 400008: // Token expired
        return;
      case 400102:
        this.resetPasswordErrorText = this.resetPasswordErrorTextInvalid;
        return;
      case 404000: // Verification token not found
        break;
      case 503000: // errors from backend services encountered
        break;
      case 500000: // Unhandled exceptions
        break;
    }
    this.resetPasswordErrorText = this.resetPasswordErrorTextGeneric;
  }

  ngOnDestroy() {
    this.spinner.stop();
  }
}
