import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AddVehicleFeaturesResponseModel } from '@amfam/policy/add-vehicle/data-access';
import {
  Result,
  RiskModel,
  TransactionRequest,
  TransactionResponse,
  Vehicle
} from '@amfam/policy/models';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ApiStatus } from '@amfam/shared/models';
import {
  CommunicationService,
  ConfigService,
  CopyService
} from '@amfam/shared/utility/shared-services';

export interface AdaptedVehicleFeatures {
  features: VehicleFeaturesModel[];
}
export interface VehicleFeaturesModel {
  typeOfFeatureCode: string;
  description: string;
  typeOfAvailabilityCode: string;
}
@Injectable({ providedIn: 'root' })
export class VehicleCoverageService {
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private communicationService: CommunicationService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  getNewVehicleFeatures(model: {
    year: string;
    vin: string;
    typeOfVehicleCode: 'automobile';
  }): Observable<AddVehicleFeaturesResponseModel> {
    let params = new HttpParams();
    params = params.append('typeOfVehicleCode', model.typeOfVehicleCode);
    params = params.append('year', model.year);
    return this.http.get<AddVehicleFeaturesResponseModel>(
      this.config.get('vehicleApi') + 'vehicles/' + model.vin,
      {
        params: params
      }
    );
  }

  saveAndRate(
    entity: Result,
    partnerId: string,
    stateCode: string
  ): Observable<TransactionResponse> {
    let headers = new HttpHeaders();
    if (this.config.get('applicationTitle') === 'MyAccount - INT - 01') {
      headers = headers.set('afi-leg', '01');
    }

    const data: TransactionRequest = {
      policyNumber: entity.policyNumber.replace(/-/g, ''),
      callProductModelApi: true,
      policyType: 'FAMILY_CAR',
      partnerCode: partnerId,
      contractState: stateCode,
      drivers: [...entity.driverAssignments],
      vehicle: {
        vin: entity.vin,
        year: entity.year,
        coverages: [
          ...entity.vehicleCoverages.map(vc => ({
            ...vc,
            coverages: vc.coverages.map(c =>
              c.terms && c.terms.length > 0
                ? { ...c, updated: true, terms: c.terms.map(t => ({ ...t, updated: true })) }
                : { ...c, updated: true }
            )
          }))
        ]
      }
    };
    const url = `${this.config.get('plpcContextAPI')}save-and-rate`;
    return this.http.post<TransactionResponse>(url, data as unknown as TransactionRequest, {
      headers
    });

    //return this.mockSaveAndRate();
  }

  changeCoverageAPI(vehicle: Vehicle, risks: RiskModel[], partnerId: string) {
    const risk = risks.filter(v => v.vehicleId === vehicle.vehicleId)[0];
    const url = this.config.get('plpcContextAPI') + 'change-coverage';
    let headers = new HttpHeaders();
    if (this.config.get('applicationTitle') === 'MyAccount - INT - 01') {
      headers = headers.set('afi-leg', '01');
    }

    const data: TransactionRequest = {
      drivers: [],
      vehicle: {
        vin: vehicle.vinSerialNumber,
        year: vehicle.year,
        garageAddress: {
          ...vehicle.garageLocationAddress,
          postalCode: vehicle.garageLocationAddress.zipCode,
          country: 'USA'
        },
        coverages: [],
        tpis: []
      },
      policyNumber: risk.policyNumber,
      callProductModelApi: true,
      policyType: 'FAMILY_CAR',
      partnerCode: partnerId
    };

    return this.http.post<TransactionResponse>(url, data, {
      headers
    });

    //return this.mockCoverages();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public bindChangeCoverageFeature(policyNumber: string, vin: string): Observable<any> {
    let headers = new HttpHeaders();
    if (this.config.get('applicationTitle') === 'MyAccount - INT - 01') {
      headers = headers.set('afi-leg', '01');
    }
    return this.http.post(
      `${this.config.get('plpcContextAPI')}bind/${policyNumber}/${vin}`,
      {},
      { headers: headers }
    );
  }
}

export interface ResponseModel {
  status: ApiStatus;
  matchedVehicles: {
    typeOfVehicleMatchCode: string;
    atvs: [];
    automobiles: [
      {
        patternPercentage: string;
        countOfCylinders: string;
        displacementVolume: string;
        minimumHorsePower: string;
        maximumHorsePower: string;
        transmissionDescription: string;
        size: string;
        minimumGrossVehicleWeightRating: string;
        maximumGrossVehicleWeigthRating: string;
        length: string;
        height: string;
        manufacturersSuggestedRetailPrice: string;
        features: VehicleFeaturesModel[];
        vehicle: {
          id: string;
          source: string;
          year: string;
          make: string;
          model: string;
          vinPattern: string;
          series: string;
          engineDescription: string;
          weight: string;
          createdOn: string;
          manufacturersSuggestedRetailPrice: string;
          wheelBase: string;
        };
      }
    ];
    dirtBikes: [];
    motorcycles: [];
    scooters: [];
    trucks: [];
  };
}

export interface VehicleFeaturesModel {
  typeOfFeatureCode: string;
  description: string;
  typeOfAvailabilityCode: string;
}

export interface AdaptedVehicleFeatures {
  features: VehicleFeaturesModel[];
}

export interface GetNewVehicleFeaturesRequestModel {
  vin: string;
  year: string;
  typeOfVehicleCode: 'automobile';
}

export interface GetNewVehicleFeaturesResponseModel {
  request: GetNewVehicleFeaturesRequestModel;
  response: AdaptedVehicleFeatures;
}

export interface NewVehicleFeaturesState {
  vin: string;
  features?: VehicleFeaturesModel[];
  error?: ApiStatus | null;
  success?: ApiStatus | null;
  loading: boolean;
  status?: ApiStatus;
}

export function getMockRentalReimbursment() {
  return {
    isValid: true,
    coverageCategoryDisplayName: 'Miscellaneous Coverages Group',
    hasTerms: true,
    coverageCategoryCode: 'PAMiscGrp',
    updated: false,
    isRejected: false,
    publicID: 'PARentalCov',
    selected: false,
    required: false,
    terms: [
      {
        updated: false,
        isAscendingBetter: true,
        chosenTermValue: '',
        coveragePublicID: 'PARentalCov',
        chosenTerm: '',
        publicID: 'PARental',
        required: true,
        options: [
          {
            optionValue: 1,
            maxValue: 5,
            code: 'z89h8d8kfe9d5cmdm2je6qlbud8',
            name: '25/750'
          },
          {
            optionValue: 3,
            maxValue: 5,
            code: 'zplis8g256b061gcm0ibnhict2b',
            name: '35/1,050'
          },
          {
            optionValue: 4,
            maxValue: 5,
            code: 'zm2hcp9bc3ncjatt3kv04t61768',
            name: '45/1,350'
          },
          {
            optionValue: 5,
            maxValue: 5,
            code: 'z1tied5cjct2la7o7fd6jf7r22b',
            name: '65/1,950'
          }
        ],
        patternCode: 'PARental',
        type: 'PackagePARentalType',
        name: 'Daily Limit/Maximum'
      }
    ],
    description: 'Rental Reimbursement',
    name: 'Rental Reimbursement',
    amount: {
      currency: 'usd',
      amount: 26.1
    }
  };
}
