import { Injectable } from '@angular/core';
import { get as _get } from 'lodash';

import { ApiStatus, DecoratedPreference, RetrievePreferenceResponse } from '@amfam/shared/models';

import { Adapter } from './adapter';

@Injectable({
  providedIn: 'root'
})
export class RetrievePreferenceAdapter implements Adapter<DecoratedPreference | ApiStatus> {
  constructor() {}

  /**
   * Return DecoratedPreference object if pcm is enabled.
   * TODO: Remove else condition, return type ANY once pcm is live
   */

  adapt(item: RetrievePreferenceResponse): DecoratedPreference | ApiStatus {
    return {
      billAccountNumber: _get(item, 'billAccount.billAccountNumber', '').replace(/-/g, ''),
      billingPreferences: {
        accountNickName: _get(item, 'billAccount.billingPreference.accountNickname'),
        dueDateReminder: _get(item, 'billAccount.billingPreference.dueDateReminder'),
        preferences: _get(item, 'billAccount.billingPreference.preferences')
      }
    };
  }
}
