import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { get as _get } from 'lodash';

import { Policy } from '@amfam/policy/models';

import { PoliciesAction, PoliciesActionTypes } from './policies.actions';

export const POLICIES_FEATURE_KEY = 'policies';

/**
 * Interface for the 'Policies' data used in
 *  - PoliciesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

export interface PoliciesPartialState {
  readonly [POLICIES_FEATURE_KEY]: PoliciesEntityState;
}

export interface PoliciesEntityState extends EntityState<Policy> {
  selectedPolicyNumber: string;
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  error: unknown;
  rawPolicies: Policy[];
}

export const adapter: EntityAdapter<Policy> = createEntityAdapter<Policy>({
  selectId: (policyObjModel: Policy) => policyObjModel.policyNumber,
  sortComparer: false
});

export const initialState: PoliciesEntityState = adapter.getInitialState({
  selectedPolicyNumber: '',
  loading: false,
  loaded: false,
  hasError: false,
  error: null,
  rawPolicies: []
});

export function reducer(
  state: PoliciesEntityState = initialState,
  action: PoliciesAction
): PoliciesEntityState {
  switch (action.type) {
    case PoliciesActionTypes.PoliciesLoadSuccessType: {
      return adapter.addMany(_get(action, 'payload', []), initialState);
    }

    case PoliciesActionTypes.PoliciesRawLoadSuccessType: {
      return { ...state, rawPolicies: _get(action, 'payload', []) };
    }

    case PoliciesActionTypes.PoliciesSetSelectedSuccessType: {
      return { ...state, selectedPolicyNumber: _get(action, 'payload.policyNumber') };
    }

    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
