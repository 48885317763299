import { createFeatureSelector, createSelector } from '@ngrx/store';

import { claimQuery } from '@amfam/claim/data-access';

import {
  CLAIMDASHBOARD_FEATURE_KEY,
  ClaimDashboardState,
  selectAllDashboardClaims
} from './claim-dashboard.reducer';

// Lookup the 'ClaimDashboard' feature state managed by NgRx
const getClaimDashboardState = createFeatureSelector<ClaimDashboardState>(
  CLAIMDASHBOARD_FEATURE_KEY
);
const getLoading = createSelector(
  getClaimDashboardState,
  claimQuery.getClaimsLoading,
  (state: ClaimDashboardState, claimsLoading) => state.loading || claimsLoading
);
const getLoaded = createSelector(
  getClaimDashboardState,
  (state: ClaimDashboardState) => state.loaded
);
const getError = createSelector(
  getClaimDashboardState,
  (state: ClaimDashboardState) => state.error
);
const getAllDashboardClaims = createSelector(getClaimDashboardState, selectAllDashboardClaims);
const getDisplayedDashboardClaims = createSelector(getAllDashboardClaims, claims =>
  claims.slice(0, 6)
);

export const claimDashboardQuery = {
  getClaimDashboardState,
  getLoading,
  getLoaded,
  getError,
  getAllDashboardClaims,
  getDisplayedDashboardClaims
};
