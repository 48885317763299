<div class="opportunities-cta">
  <div class="row flex">
    @if (ctaData?.ctaIconPath) {
      <div>
        <img alt="Insurance Policy Image" [src]="ctaData?.ctaIconPath" />
      </div>
    }

    <div class="row margin-left-30">
      <div class="column twelve">
        <div class="opportunities-cta-title margin-bottom-0">{{ ctaData?.title }}</div>
        @for (item of ctaData?.body; track item) {
          <div class="margin-bottom-10" [innerHTML]="item"></div>
        }
      </div>
      @if (showCTA) {
        <div class="row margin-top-20">
          @for (button of ctaData?.buttons; track button) {
            <div class="column twelve md-six md-pad-right-10 md-pad-top-20">
              <button
                ds-button
                class="{{ button.buttonType }}"
                [disabled]="
                  button.buttonText.toLowerCase() === opportunityButtonAction.contactMe
                    ? showContactMe
                    : !ctaData?.enabled
                "
                (click)="ctaClicked(button.buttonText)"
                data-cy="detailctaBtn"
              >
                {{ button.buttonText }}
              </button>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
