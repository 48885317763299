import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions, NavigationService } from '@amfam/shared/utility/navigation';
import { ConfigService } from '@amfam/shared/utility/shared-services';

import * as BrandSelectors from '../+state/brand.selectors';
import { BrandModel } from '../models/brand.model';
import { BrandService } from '../services/brand.service';

@Injectable()
export class BrandGuard {
  public dataLoading: Observable<boolean>;
  constructor(
    private store: Store,
    private brandService: BrandService,
    private config: ConfigService,
    private navigationService: NavigationService,
    private feature: FeatureFlagService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let experienceId = route.root.firstChild.firstChild.paramMap.get('expid');
    const hasCode = route.queryParamMap.has('code');
    if (!experienceId) {
      experienceId =
        localStorage.getItem('expid') && hasCode
          ? localStorage.getItem('expid')
          : this.config.get('afiExperienceId');
    }
    const currentExperienceId = this.navigationService.getExperienceId();
    if (currentExperienceId !== '' && currentExperienceId === experienceId) {
      return true;
    }
    return this.getBrand().pipe(
      switchMap(brand => {
        if (brand.loaded) {
          return of(true);
        }
        return this.brandService.loadBrandForUrl(experienceId).pipe(
          take(1),
          switchMap(() => of(true)),
          catchError(() => {
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/not-found']
              })
            );
            return of(false);
          })
        );
      })
    );
  }

  private getBrand(): Observable<BrandModel> {
    return this.store.select(BrandSelectors.selectBrandState).pipe(take(1));
  }
}
