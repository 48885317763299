@if (showPill && isRomePillEnabled) {
  <div class="tile-pill">
    <span
      class="tile-pill-text"
      [ngClass]="{
        'tile-pill-color-success': variant === 'success',
        'tile-pill-color-alert': variant === 'alert'
      }"
    >
      <ng-content select="[tile-pill]"></ng-content>
    </span>
  </div>
}

<div class="tile" [ngClass]="tileClasses">
  @if (showBanner) {
    <div class="tile-banner-wrapper">
      <ng-content select="[tile-banner]"></ng-content>
    </div>
  }
  <div class="tile-heading-wrapper"><ng-content select="[tile-heading]"></ng-content></div>
  <div class="tile-content-wrapper fill">
    <ng-content select="[tile-content]"></ng-content>
  </div>
  <span class="grow"></span>
  <div [ngClass]="{ 'tile-cta-wrapper': !noCTAWrapper }">
    <ng-content select="[tile-cta]"></ng-content>
  </div>
</div>
