import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

import { PaymentConfirmationModel } from '@amfam/shared/models';

import * as fromPaymentConfirmationNotifications from './notifications.reducer';
import * as fromPaymentConfirmationState from './paymentConfirmation.reducer';

export const PAYMENT_CONFIRMATION_FEATURE_KEY = 'payment confirmation';

export interface PaymentConfirmationState {
  paymentConfirmationState: fromPaymentConfirmationState.PaymentConfirmtionEntityState;
  paymentConfirmationNotifications: fromPaymentConfirmationNotifications.NotificationState;
}

export const paymentConfirmationReducers: ActionReducerMap<PaymentConfirmationState> = {
  paymentConfirmationState: fromPaymentConfirmationState.reducer,
  paymentConfirmationNotifications: fromPaymentConfirmationNotifications.reducer
};

export const selectPaymentConfirmationState = createFeatureSelector<PaymentConfirmationState>(
  PAYMENT_CONFIRMATION_FEATURE_KEY
);
export const selectPaymentConfirmationLoading = createSelector(
  selectPaymentConfirmationState,
  (state: PaymentConfirmationState) => _get(state, 'paymentConfirmationNotifications.loading', true)
);

export const selectPaymentConfirmationLoaded = createSelector(
  selectPaymentConfirmationState,
  (state: PaymentConfirmationState) =>
    !_get(state, 'paymentConfirmationNotifications.loading', true)
);

export const selectPaymentConfirmationDataState = createSelector(
  selectPaymentConfirmationState,
  selectPaymentConfirmationLoaded,
  (state, isLoaded) => {
    const paymentConfirmationStateArr = isLoaded
      ? fromPaymentConfirmationState.selectAll(state.paymentConfirmationState)
      : [];
    return <PaymentConfirmationModel>(
      (_get(paymentConfirmationStateArr, 'length', 0) > 0 ? paymentConfirmationStateArr[0] : [])
    );
  }
);
