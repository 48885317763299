import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { addDays } from 'date-fns';
import format from 'date-fns/format';
import { omit as _omit, isEmpty, isFinite } from 'lodash';
import { Observable } from 'rxjs';

import { ANSRequest } from '@amfam/policy/add-vehicle/data-access';
import {
  ChangeCoverageAFIEmailTemplate,
  ChangeCoverageEmailTemplate,
  ChangeCoverageEmailType,
  ICoverage,
  MessageDetail,
  PartnerChangeCoverageAgentEmail,
  Policy,
  PolicyCoverage,
  VehicleCoverageAnalyticPayLoad
} from '@amfam/policy/models';
import { PrettyPolicyNum } from '@amfam/policy/pipes';
import { FEATURES } from '@amfam/policy/vehicle-coverages/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ArrayToList, CoverageCurrencyUSDPipe } from '@amfam/shared/ui/pipes';
import { Agent, agentQuery } from '@amfam/shared/utility/agent';
import {
  Addressee,
  CommunicationService,
  ConfigService,
  CopyService,
  InternalEmail,
  TemplatedEmail,
  UtilService
} from '@amfam/shared/utility/shared-services';

@Injectable({ providedIn: 'root' })
export class ChangeCoverageFeatureService {
  public partnersId: string;
  private selectedPolicy: Policy;
  private selectedProducer: Agent;
  premiumDetails: any = {
    quoteExpirationDate: new Date(),
    current: {},
    new: {}
  };

  prepareCustomerEmail(
    emailType: ChangeCoverageEmailType,
    partnerId: string,
    brandId: string,
    accountId: string,
    customerId: string,
    emailAddress: string,
    policyNumber: string,
    vehicle: string,
    changes: string,
    customerFirstName: string,
    companyName?: string,
    context?: string,
    currentCoverages?: string
  ): TemplatedEmail {
    let templateCode = '';
    if (partnerId === 'AFI') {
      templateCode = ChangeCoverageAFIEmailTemplate[emailType];
    } else {
      templateCode = ChangeCoverageEmailTemplate[emailType];
    }
    this.partnersId = partnerId;
    const messageDetails: MessageDetail[] = [];
    messageDetails.push(new MessageDetail('PolicyNumber', policyNumber));
    //messageDetails.push(new MessageDetail('ProdID', prodId || ''));
    messageDetails.push(new MessageDetail('Vehicle', vehicle));
    messageDetails.push(new MessageDetail('CoverageChanges', changes));

    messageDetails.push(new MessageDetail('EmailAddress', emailAddress || ''));
    messageDetails.push(new MessageDetail('SubscriberKey', emailAddress || ''));
    messageDetails.push(new MessageDetail('FirstName', customerFirstName || ''));
    messageDetails.push(new MessageDetail('DateAdded', format(new Date(), 'MM/DD/YYYY')));
    messageDetails.push(new MessageDetail('Context', context || ''));
    messageDetails.push(new MessageDetail('CompanyName', companyName || ''));

    //messageDetails.push(new MessageDetail('ProdID', prodId || '')); //agent Id

    messageDetails.push(new MessageDetail('CurrentCoverage', currentCoverages));
    return new TemplatedEmail(
      templateCode,
      partnerId,
      brandId,
      'EN',
      customerId,
      emailAddress,
      messageDetails,
      accountId
    );
  }

  public sendChangeCovergeLicensedAdvicerEmail(
    emailContent: PartnerChangeCoverageAgentEmail,
    vehicleId: string,
    isPartner: boolean,
    producer: Agent
  ) {
    let agentName = '';
    let producerId = '';
    const email: InternalEmail = new InternalEmail();
    const internalAgentEmail = producer?.agencyEmails[0]
      ? this.config.get('policyGracePeriodNotificatonInternalAgencyMailbox')
      : this.config.get('policyGracePeriodNotificatonInternal');
    const emailAddress = isPartner
      ? this.config.get('partnerLicensedAdvisorEmail')
      : internalAgentEmail;
    email.messageFrom = new Addressee(
      this.copyService.getCopy('policy.changeCoverage.policychangeEmail'),
      this.copyService.getCopy('policy.changeCoverage.policyChangeRequest')
    );
    email.messageToList.push(
      new Addressee(
        emailAddress,
        isPartner ? 'Advisor' : `${producer?.firstName} ${producer?.lastName}`
      )
    );

    this.store.select(agentQuery.getAgents).subscribe(agent => {
      if (agent && agent.length > 0) {
        agentName = agent[0]?.firstName;
        producerId = agent[0]?.agentId;
      }
    });

    if (this.partnersId === 'AFI') {
      email.messageSubject = this.copyService.getCopy(
        emailContent.emailType === ChangeCoverageEmailType.BIND
          ? 'policy.changeCoverage.afiAgentEmail.partner.happyPath.emailSubject'
          : 'policy.changeCoverage.afiAgentEmail.partner.unHappyPath.emailSubject'
      );
      email.messageContent = this.copyService.getCopy(
        emailContent.emailType === ChangeCoverageEmailType.BIND
          ? 'policy.changeCoverage.afiAgentEmail.partner.happyPath.emailBody'
          : 'policy.changeCoverage.afiAgentEmail.partner.unHappyPath.emailBody',
        {
          agentName: producer?.firstName || agentName,
          customerName: emailContent.customerName,
          policyNumber: emailContent.policyNumber,
          vehicleDescription: emailContent.vehicleDescription,
          coverageChanges: emailContent.coverageChanges,
          workOrderNumber: emailContent.workOrderNumber
        }
      );
      this.sendNotificationtoAgent(emailContent, vehicleId, producer?.id || producerId);
    } else {
      email.messageSubject = this.copyService.getCopy(
        emailContent.emailType === ChangeCoverageEmailType.BIND
          ? 'policy.changeCoverage.agentEmail.partner.happyPath.emailSubject'
          : 'policy.changeCoverage.agentEmail.partner.unHappyPath.emailSubject'
      );
      email.messageContent = this.copyService.getCopy(
        emailContent.emailType === ChangeCoverageEmailType.BIND
          ? 'policy.changeCoverage.agentEmail.partner.happyPath.emailBody'
          : 'policy.changeCoverage.agentEmail.partner.unHappyPath.emailBody',
        {
          customerName: emailContent.customerName,
          policyNumber: emailContent.policyNumber,
          vehicleDescription: emailContent.vehicleDescription,
          coverageChanges: emailContent.coverageChanges,
          workOrderNumber: emailContent.workOrderNumber
        }
      );
    }
    return this.communicationService.sendInternalEmail(email);
  }

  public sendNotificationtoAgent(
    emailContent: PartnerChangeCoverageAgentEmail,
    vehicleId: string,
    producerId: string
  ) {
    let emailCoverage = emailContent.coverageChanges;
    let notificationMsg = '';
    if (emailContent.emailType === ChangeCoverageEmailType.SEND_TO_AGENT) {
      notificationMsg = this.copyService.getCopy('policy.ansEmailForCoverageChange', {
        customerName: emailContent.customerName,
        policyNumber: emailContent.policyNumber,
        vehicleInfo: emailContent.vehicleDescription,
        coverageChanges: emailCoverage.replace(/(<([^>]+)>)/gi, '\n'),
        orderNumber: emailContent.workOrderNumber
      });
    } else {
      notificationMsg = this.copyService.getCopy('policy.confirmAnsEmailForCoverageChange', {
        customerName: emailContent.customerName,
        policyNumber: emailContent.policyNumber,
        vehicleInfo: emailContent.vehicleDescription,
        coverageChanges: emailCoverage.replace(/(<([^>]+)>)/gi, '\n'),
        orderNumber: emailContent.workOrderNumber
      });
    }

    const ansPayload: ANSRequest = {
      notificationsRequest: {
        customerName: emailContent.customerName,
        notificationText: notificationMsg,
        notificationType: 'ANSMYBNCOV',
        createdBy: 'DMSI',
        policyNumber: new ArrayToList().transform([emailContent.policyNumber]),
        referenceNumber: emailContent.policyNumber,
        formattedReferenceNumber: new PrettyPolicyNum().transform(
          emailContent.policyNumber?.replace(/-/g, '')
        ),
        from: 'DMSI',
        dueDate: addDays(new Date(), 2).toISOString(),
        sourceSystem: 'MYACCOUNT',
        uniqueId: this.utilService.generateId()
      },
      producerId: producerId
    };

    this.ansNotification(ansPayload).subscribe();
  }

  public sendChangeCovergeCustomerEmail(model: TemplatedEmail) {
    return this.communicationService.sendTemplatedEmail(model);
  }

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private communicationService: CommunicationService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade,
    private store: Store,
    private utilService: UtilService
  ) {}
  isCheckBox(c: ICoverage): boolean {
    return !c.hasTerms || (c.hasTerms && (c.terms.length < 1 || c.terms[0].options.length === 1));
  }

  isCoverageUpdated(initCoverages: ICoverage[], coverage: ICoverage) {
    let hasChanged: boolean;
    const initialCoverageInfo = (initCoverages as Array<ICoverage>).find(
      ic => ic.publicID === coverage.publicID
    );
    if (!this.isCheckBox(coverage)) {
      hasChanged = initialCoverageInfo.terms.some(t =>
        coverage.terms.some(
          tt => tt.publicID === t.publicID && tt.chosenTermValue !== t.chosenTermValue
        )
      );
    } else {
      hasChanged = initialCoverageInfo.selected !== coverage.selected;
    }
    return hasChanged;
  }

  coverageReducer(
    coverages: ICoverage[] | PolicyCoverage[],
    changesOnly = true,
    initCoverages?: ICoverage[] | PolicyCoverage[]
  ) {
    if (!coverages || (changesOnly && isEmpty(initCoverages))) return null;

    const changedCoverages: {
      option: string;
      value: string;
      isCheckbox: boolean;
      coverageHelpTextList: string[];
    }[] = [];
    coverages.forEach(c => {
      const isCheckbox = this.isCheckBox(c);
      let selectedValue = '';
      if (isCheckbox) {
        selectedValue =
          c.selected || (c.terms.length && c.terms[0].chosenTermValue)
            ? 'Included'
            : 'Not Included';
      } else {
        selectedValue = new CoverageCurrencyUSDPipe().transform(
          c.terms[0].chosenTermValue?.toString(),
          c.publicID
        );
        selectedValue = this.addDollarIfNumber(selectedValue);
      }
      const hasChanged = changesOnly ? this.isCoverageUpdated(initCoverages, c) : true;

      if (hasChanged && selectedValue !== 'Not Included' && selectedValue !== '') {
        changedCoverages.push({
          option: c.name,
          value: selectedValue,
          isCheckbox: isCheckbox,
          coverageHelpTextList: ['']
        });
      }
    });

    return changedCoverages;
  }

  private addDollarIfNumber(input: string): string {
    const cleanedInput = input.replace(/,/g, '');
    if (isFinite(Number(cleanedInput)) && !input.startsWith('$')) {
      return `$${input}`;
    }
    return input;
  }

  getCovergesForEmail(coverages: { option: string; value: string }[]) {
    if (!coverages) return '';
    return coverages.map(c => `${c.option}: ${c.value}`).join('<br>');
  }

  getCoveragesForAns(coverages: { option: string; value: string }[]) {
    if (!coverages) return '';
    return coverages.map(c => `${c.option}: ${c.value}`).join('</n>');
  }

  public sendVehicleCoveragesAnalytics(analyticPayload: VehicleCoverageAnalyticPayLoad): void {
    if (analyticPayload.event === FEATURES.CHANGE) {
      const pageObj = {
        pageName: `MyAccount:Policies:ChangeCoverage:${analyticPayload.pageAnalyticName}`,
        experience: '',
        primaryCategory: 'My Account',
        subCategory1: 'Policies',
        subCategory2: '',
        subCategory3: ''
      };
      if (analyticPayload.buttonAnalyticName) {
        return this.analyticsFacade.trackButtonClick({
          link: `MyAccount:Policies:ChangeCoverage:${analyticPayload.buttonAnalyticName}`
        });
      }
      return this.analyticsFacade.trackPage(pageObj);
    }
  }

  public sendEditCoveragesAnalytics(analyticPayload: VehicleCoverageAnalyticPayLoad): void {
    if (analyticPayload.event === FEATURES.CHANGE) {
      const { pageAnalyticName } = analyticPayload;
      const analyticName = pageAnalyticName ? `:${pageAnalyticName}` : '';
      const pageObj = {
        pageName: `MyAccount:AutoPolicy:EditCoverage${analyticName}`,
        experience: '',
        primaryCategory: 'MyAccount',
        subCategory1: 'AutoPolicy',
        subCategory2: 'EditCoverage',
        subCategory3: ''
      };
      if (analyticPayload.buttonAnalyticName) {
        return this.analyticsFacade.trackButtonClick({
          link: `MyAccount:AutoPolicy:EditCoverage:${analyticPayload.buttonAnalyticName}`
        });
      }
      return this.analyticsFacade.trackPage(pageObj);
    }
  }

  /**
   * @author Balakumar Arasiah
   * @param ANSRequest
   * @returns Json response as part of the post call on the producer API
   * @description This function takes the model and sends it to the producer API which in turn posts the ANS notification
   */
  public ansNotification(model: ANSRequest): Observable<any> {
    const uri = this.config.get('producerApi') + 'producers/' + model.producerId + '/notifications';
    return this.http.post(uri, _omit(model, ['producerId']));
  }
}
