import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { DsClicktipComponent } from '../ds-clicktip/ds-clicktip.component';

@Component({
  selector: 'ds-clicktip-icon',
  templateUrl: './ds-clicktip-icon.component.html',
  styleUrls: ['./ds-clicktip-icon.component.scss']
})
export class DsClicktipIconComponent implements OnInit {
  // Add Readme and define input props

  @Input() clicktipRef: DsClicktipComponent;
  @Input() ariaDescribedBy: string;
  @Input() alignRight = false;
  iconClass = 'icon-info';
  tooltipStatus = 'Open tooltip';
  private el: HTMLElement;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngOnInit() {
    if (this.alignRight) this.el.classList.add('align-right');
  }

  toggleContent() {
    this.clicktipRef.toggleClicktip();
    this.iconClass = this.clicktipRef.contentVisible() ? 'icon-times-thick open' : 'icon-info';
    this.tooltipStatus = this.clicktipRef.contentVisible() ? 'Close tooltip' : 'Open tooltip';
  }
}
