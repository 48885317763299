import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ClaimServicesService } from '../../services/claim-services.service';
import {
  CreateRentalRequest,
  CreateRentalRequestFail,
  CreateRentalRequestSuccess,
  RentalServiceActionTypes
} from './rental-service.actions';
import { CreateRentalRequestPayload } from './rental-service.models';

@Injectable()
export class RentalServiceEffects {
  createRentalRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateRentalRequest>(RentalServiceActionTypes.CreateRentalRequest),
      map(action => action.payload),
      switchMap((payload: CreateRentalRequestPayload) => {
        return this.claimServicesService
          .createRentalRequest(
            payload.claimNumber,
            payload.vin,
            payload.selectedShop.id,
            payload.customerId
          )
          .pipe(
            map(() => new CreateRentalRequestSuccess()),
            catchError(() => of(new CreateRentalRequestFail()))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private claimServicesService: ClaimServicesService
  ) {}
}
