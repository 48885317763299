/* eslint-disable ngrx/prefer-action-creator-in-dispatch */
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { userQuery } from '@amfam/shared/user';
import {
  ButtonAnalytic,
  CookiesService,
  CopyService,
  HttpStatusCode,
  OpportunityContent,
  OpportunityQualifierOptions,
  PageAnalytic,
  WindowRef
} from '@amfam/shared/utility/shared-services';
import { DockingBarService, LoadingSpinnerService } from '@amfam/ui-kit';

import { getNotifiedOpportunities } from '../../+state';
import { OpportunitiesFeedbackAction } from '../../+state/opportunity.action';
import { DSP_DOMAIN, StampsApiResponse } from '../../models';
import { CustomerFeedbackEnum } from '../../models/feedback';
import { OpportunityAnalytics } from '../../models/opportunity-analytic-contants';
import { CoreService } from '../../services/core.service';
import { OpportunityService } from '../../services/opportunity.service';

@Component({
  selector: 'amfam-opportunities-qualifiers',
  templateUrl: './opportunities-qualifiers.component.html',
  styleUrls: ['./opportunities-qualifiers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunitiesQualifiersComponent implements OnInit, OnDestroy {
  qualifersFormGroup: FormGroup;
  opportunityContent$: Observable<OpportunityContent>;
  qualifierOptions: OpportunityQualifierOptions[];
  productType: string;
  recommendationId$: Observable<string>;
  openedWindow: Window;
  isOpportunityQuoted$: Observable<boolean>;
  private stop$ = new Subject<void>();
  dspUrl: string;
  type: string;

  constructor(
    private copyService: CopyService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router,
    private opportunityService: OpportunityService,
    private store: Store,
    private windowRef: WindowRef,
    private analyticsFacade: AnalyticsFacade,
    private spinner: LoadingSpinnerService,
    private dockingBarService: DockingBarService,
    private coreService: CoreService,
    private cookieService: CookiesService
  ) {}

  get qualifiersFormArray() {
    return this.qualifersFormGroup.controls['qualifiers'] as FormArray;
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.dockingBarService.registerHeading('My Opportunity');
    this.productType = this.route.snapshot.params['id'];
    this.type = this.route.snapshot.queryParamMap.get('type');
    this.recommendationId$ = this.opportunityService.getRecommendationIdByOpportunityType(
      this.productType
    );
    this.sendPageAnalytics();
    this.buildForm();
    this.isOpportunityQuoted$ = combineLatest([
      this.recommendationId$,
      this.store.select(getNotifiedOpportunities)
    ]).pipe(
      takeUntil(this.stop$),
      map(([recommendationId, quotedOpportunities]) =>
        quotedOpportunities.some(opp => opp.recommendationId === recommendationId)
      )
    );
  }

  buildForm() {
    if (this.productType === 'auto') {
      this.qualifersFormGroup = this.formBuilder.group({
        qualifiers: new FormArray([])
      });
      this.qualifierOptions = this.copyService
        .getCopy('opportunity')
        .find(
          opportunityFromCopy => opportunityFromCopy.opportunityProductType === this.productType
        ).qualifiers;
      this.addControls();
    } else if (this.productType === 'prop') {
      this.qualifersFormGroup = this.formBuilder.group({
        maritalStatus: ['MARRIED'],
        residenceType: ['SingleFamily'],
        runBusiness: [false],
        ownItems: [false],
        occupantsCount: ['']
      });
    }
  }

  sendPageAnalytics() {
    this.recommendationId$
      .pipe(
        filter(recommendationId => !!recommendationId),
        first(),
        switchMap(recommendationId =>
          this.opportunityService.buildPageAnalyticData(
            OpportunityAnalytics.pageOpportunitiesQualifiers,
            recommendationId
          )
        ),
        tap(analytics => this.analyticsFacade.trackPage(analytics))
      )
      .subscribe();
  }

  sendButtonAnalytics(analytic: ButtonAnalytic) {
    return this.recommendationId$.pipe(
      filter(recommendationId => !!recommendationId),
      first(),
      switchMap(recommendationId =>
        this.opportunityService.buildButtonAnalyticData(analytic, recommendationId)
      ),
      delay(0),
      tap(analytics => this.analyticsFacade.trackButtonClick(analytics))
    );
  }

  addControls() {
    this.qualifierOptions.forEach(() => this.qualifiersFormArray.push(new FormControl(false)));
  }

  continue() {
    let qualifiers: Array<boolean> = [];
    let rentersQualifiers = this.qualifersFormGroup.getRawValue();
    if (this.productType === 'auto') {
      this.sendButtonAnalytics(OpportunityAnalytics.clickOpportunityContinueQuote).subscribe();
      qualifiers = this.qualifersFormGroup.getRawValue().qualifiers;
    }

    if (this.productType === 'auto' && qualifiers.length > 0 && qualifiers.some(q => q)) {
      const qualifiers: Array<boolean> = this.qualifersFormGroup.getRawValue().qualifiers;
      qualifiers.forEach((q, i) => {
        setTimeout(() => {
          if (!!q) {
            const qualifier = this.qualifierOptions[i];
            const pageAnalytic: PageAnalytic = {
              pageName: qualifier.analytics.pagename,
              experience: '',
              primaryCategory: 'My Account',
              subCategory1: '',
              subCategory2: '',
              subCategory3: ''
            };
            this.analyticsFacade.trackPage(pageAnalytic);
          }
        });
      });
      this.router.navigate(['opportunities', 'learnmore', this.productType, 'agent']);
    } else if (
      this.productType === 'prop' &&
      this.type === 'renters' &&
      !!(rentersQualifiers.runBusiness || rentersQualifiers.ownItems)
    ) {
      this.router.navigate(['opportunities', 'learnmore', this.productType, 'agent']);
    } else {
      this.spinner.start({ blockActions: true });
      this.openedWindow = this.windowRef.nativeWindow.open('', '_blank');
      this.openedWindow.document.write('Loading. Please Wait !');
      this.store
        .select(userQuery.getZipCode)
        .pipe(
          withLatestFrom(this.store.select(userQuery.getUserStateCode), this.recommendationId$),
          switchMap(([zipCode, stateCode, recommendationId]) => {
            let content = (<OpportunityContent[]>this.copyService.getCopy('opportunity')).find(
              opportunityFrmCopy => opportunityFrmCopy.opportunityProductType === this.productType
            );
            if (this.type)
              content = this.opportunityService.mapSelectionTypeObjects(content, this.type);
            const quote = content.quote;
            return this.opportunityService
              .stamps(zipCode, quote.serviceLine, quote.lineCategory)
              .pipe(
                tap((response: StampsApiResponse) => {
                  if (response.status.code === HttpStatusCode.OK) {
                    const url = response.url;
                    if (url.startsWith(DSP_DOMAIN)) {
                      this.sendNavigateToDSPAnalytics();
                      this.setRentersCookie(rentersQualifiers);

                      this.dspUrl = `${url}?state=${stateCode}&zipCode=${zipCode}&servLine=${quote.serviceLine}&servLineCat=${quote.lineCategory}&tid=CrossSellAutoDSPWeb&CrossSellValue=true`;
                      this.coreService.recommendationId = recommendationId;
                      this.coreService.dspUrl = this.dspUrl;
                      this.openedWindow.location = this.dspUrl;
                    } else {
                      this.openedWindow.close();
                      this.router.navigate([
                        'opportunities',
                        'learnmore',
                        this.productType,
                        'agent'
                      ]);
                    }
                  } else {
                    this.openedWindow.close();
                    this.router.navigate(['opportunities', 'learnmore', this.productType, 'agent']);
                  }
                })
              );
          }),
          first(),
          catchError(error => {
            console.log(error);
            this.openedWindow.close();
            this.router.navigate(['opportunities', 'learnmore', this.productType, 'agent']);
            return of(null);
          }),
          finalize(() => this.spinner.stop())
        )
        .subscribe();
    }
  }

  setRentersCookie(rentersQualifiers) {
    if (this.cookieService.hasItem('MYACCOUNT_DETAILS'))
      this.cookieService.removeItem('MYACCOUNT_DETAILS');
    this.cookieService.setItem(
      'MYACCOUNT_DETAILS',
      JSON.stringify(rentersQualifiers),
      null,
      '/',
      '.amfam.com'
    );
  }

  sendNavigateToDSPAnalytics() {
    const analyticData: ButtonAnalytic = OpportunityAnalytics.clickOpportunitySendToDSP;
    this.analyticsFacade.trackButtonClick(analyticData);
  }

  sendFeedback(recommendationId: string): void {
    this.store.dispatch(
      new OpportunitiesFeedbackAction(
        {
          feedbackActionCode: CustomerFeedbackEnum.CustomerRequestedQuote,
          producerId: null,
          recommendationId: recommendationId,
          isMultiAgent: false
        },
        true
      )
    );
  }
  cancel() {
    this.sendButtonAnalytics(OpportunityAnalytics.clickOpportunityCancelQuote)
      .pipe(tap(() => this.location.back()))
      .subscribe();
  }

  onEnterKeyPressed(formControlName: string) {
    const value = this.qualifiersFormArray.controls[formControlName].value;
    const formControl: AbstractControl = this.qualifiersFormArray.controls[formControlName];
    if (formControl) formControl.setValue(!value);
  }

  ngOnDestroy(): void {
    this.spinner.stop();
    this.stop$.next();
    this.stop$.complete();
  }
}
