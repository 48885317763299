import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { LogoModel } from '@amfam/shared/models';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';

import { chatEnabled } from '../store';
import { GlobalOutageAnalyticConstants } from './global-outage-analytic.constants';

@Component({
  selector: 'ds-outage',
  templateUrl: './outage.component.html',
  styleUrls: ['./outage.component.scss']
})
export class OutageComponent implements OnInit {
  chatEnabled$: Observable<boolean>;
  partnerFooterLogo$: Observable<LogoModel>;

  constructor(
    private store: Store,
    private featureFlag: FeatureFlagService,
    private router: Router,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    if (!this.featureFlag.isEnabled('global_outage')) this.router.navigateByUrl('/');
    this.analyticsFacade.trackPage(GlobalOutageAnalyticConstants.pageGlobalOutage);
    this.chatEnabled$ = this.store.select(chatEnabled);
    this.partnerFooterLogo$ = this.store.select(BrandSelectors.selectFooterLogo);
  }
}
