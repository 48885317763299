import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ProfileAnalytics } from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { DsModalService, LoadingSpinnerService } from '@amfam/ui-kit';

import * as fromRoot from '../../../../../../../apps/myaccount/src/app/core/store';
import * as deleteActions from '../../../../../../../apps/myaccount/src/app/core/store/delete-myaccount/delete.actions';

@Component({
  selector: 'ds-delete-my-account',
  templateUrl: './delete-my-account.component.html',
  styleUrls: ['./delete-my-account.component.scss']
})
export class DeleteMyAccountComponent implements OnInit, OnDestroy {
  public deleteMyAccountModalId: string;
  public deleteMyAccountConfirmModalId: string;
  public firstName: string;
  public emailAddress: string;
  public deleteForm: UntypedFormGroup;
  public password: AbstractControl;
  public errorMsg: string;

  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private modalService: DsModalService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
    this.spinner.stop();
  }

  ngOnInit() {
    this.store
      .select(userQuery.getUserState)
      .pipe(takeUntil(this.stop$))
      .subscribe(userState => {
        this.firstName = userState.firstName;
        this.emailAddress = userState.emailAddress;
      });

    this.deleteMyAccountConfirmModalId = 'deleteMyAccountConfirmModalId';
    this.deleteMyAccountModalId = 'deleteMyAccountModalId';
    this.buildForm();
    this.handleAuthenticateStatus();
    this.handleUnenrollmentStatus();
    this.handleDeleteMyAccountStatus();
    this.handleSendTemplatedEmailStatus();

    this.analyticsFacade.trackPage(ProfileAnalytics.deleteMyAccountPageAnalytics);
  }

  buildForm() {
    this.deleteForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required])]
    });

    this.password = this.deleteForm.controls['password'];
  }

  handleAuthenticateStatus() {
    this.store
      .select(fromRoot.getAuthenticateStatus)
      .pipe(takeUntil(this.stop$))
      .subscribe(status => {
        if (status) {
          if (status === 200) {
            this.errorMsg = '';
            // Make the unenroll call to
            // https://intapiext.amfam.com/myaccountdelete/v1/unenrollprograms/WAID-CTDNC
            this.store.dispatch(deleteActions.UnenrollProgramsAction());
          } else if (status === 423) {
            this.spinner.stop();
            this.errorMsg = 'Account locked, Give us a call at 1-800-692-6326';
          } else if (status === 401) {
            this.spinner.stop();
            this.errorMsg =
              'Incorrect Password. Your account will be locked after three unsuccessful attempts';
          } else {
            this.spinner.stop();
            this.modalService.open(this.deleteMyAccountModalId);
          }
        }
      });
  }

  handleUnenrollmentStatus() {
    this.store
      .select(fromRoot.getUnenrollmentStatus)
      .pipe(takeUntil(this.stop$))
      .subscribe(status => {
        if (status) {
          if (status === 200) {
            // Make the delete call
            // https://intapiext.amfam.com/myaccountdelete/v1/delete/WAID-CTDNC

            this.store.dispatch(deleteActions.DeleteMyAccountAction());
          } else {
            this.spinner.stop();
            this.modalService.open(this.deleteMyAccountModalId);
          }
        }
      });
  }

  handleDeleteMyAccountStatus() {
    this.store
      .select(fromRoot.getDeleteMyAccountStatus)
      .pipe(takeUntil(this.stop$))
      .subscribe(status => {
        if (status) {
          if (status === 200) {
            // Make the templated email call
            // https://intapiext.amfam.com/communication/v1/templatedemails
            this.store.dispatch(
              deleteActions.SendTemplatedEmailsAction({
                firstName: this.firstName,
                email: this.emailAddress
              })
            );
          } else {
            this.spinner.stop();
            this.modalService.open(this.deleteMyAccountModalId);
          }
        }
      });
  }

  handleSendTemplatedEmailStatus() {
    this.store
      .select(fromRoot.getSendTemplatedEmailStatus)
      .pipe(takeUntil(this.stop$))
      .subscribe(status => {
        this.spinner.stop();
        if (status) {
          if (status === 200) {
            // Delete complete route to new page
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['delete-myaccount-confirmation']
              })
            );
          } else {
            this.modalService.open(this.deleteMyAccountModalId);
          }
        }
      });
  }

  cancel() {
    // Navigate to Profile Page
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/profile']
      })
    );
  }

  deleteAccount() {
    this.closeDeleteMyAccountConfirmModal();
    this.analyticsFacade.trackEvent(ProfileAnalytics.deleteMyAccount);
    this.spinner.start({
      blockActions: true
    });
    this.store.dispatch(deleteActions.ResetAction());
    // Make the authenticate call to
    // https://intapiext.amfam.com/digitalaccount/v1/digitalaccounts/authenticate

    /*  this.store.dispatch(
      new deleteActions.AuthenticateUserAction({
        username: this.userName,
        password: this.password.value
      })
    );*/
    this.store.dispatch(deleteActions.UnenrollProgramsAction());
  }

  userConfirmationDeleteAccount() {
    this.modalService.open(this.deleteMyAccountConfirmModalId);
  }

  closeDeleteMyAccountModal() {
    this.modalService.close(this.deleteMyAccountModalId);
  }

  closeDeleteMyAccountConfirmModal() {
    this.modalService.close(this.deleteMyAccountConfirmModalId);
  }
}
