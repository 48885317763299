<div
  class="row button-container"
  [ngClass]="{
    'full-width-buttons': fullWidthButtons,
    'action-panel-container': actionPanel,
    'error-action-btn': dsErrorAction,
    'preserve-text-format': preserveTextFormat
  }"
>
  <!--Back Button-->
  @if (displayBackButton) {
    <div class="flex items-center link-button">
      <span class="back-icon icon-chevron-left"></span>
      <a class="caption no-decoration" (click)="backEvent.emit()" data-cy="backButton">{{
        backButtonName
      }}</a>
    </div>
  }

  @if (!equalWidthButtons) {
    <span class="grow"></span>
  }
  <!--Tertiary Button-->
  @if (!!tertiaryButtonName) {
    <a
      class="pad-horizontal-10 link-button no-decoration caption"
      [ngClass]="{
        'error-600-text': tertiaryButtonDestructive,
        'flex-1': equalWidthButtons,
        'text-center': equalWidthButtons,
        'pad-vertical-16': maxHeight
      }"
      (click)="tertiaryEvent.emit()"
      data-cy="tertiaryButton"
      >{{ tertiaryButtonName }}</a
    >
  }
  <!--Secondary Button-->
  @if (!!secondaryButtonName) {
    <button
      ds-button
      class="secondary-button margin-left-0"
      [ngClass]="{
        '-error': secondaryButtonDestructive,
        '-outline-white': !secondaryButtonDestructive,
        'flex-1': equalWidthButtons,
        'pad-vertical-16': maxHeight
      }"
      (click)="secondaryEvent.emit()"
      [disabled]="disabled || disableSecondaryButton"
      data-cy="secondaryButton"
      [class.capitalize]="capitalize"
    >
      {{ secondaryButtonName }}
    </button>
  }
  <!--Primary Button-->
  @if (!!primaryButtonName) {
    <button
      ds-button
      class="margin-right-0 margin-left-0 primary-button"
      [ngClass]="{
        '-error': primaryButtonDestructive,
        'flex-1': equalWidthButtons,
        'pad-vertical-16': maxHeight
      }"
      (click)="primaryEvent.emit()"
      [disabled]="disabled || disablePrimaryButton"
      [class.capitalize]="capitalize"
      data-cy="primaryButton"
    >
      {{ primaryButtonName }}
      @if (primaryButtonIcon) {
        <i [ngClass]="primaryButtonIcon"></i>
      }
    </button>
  }
</div>
