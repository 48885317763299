<ds-modal
  [id]="'duplicatePaymentConfirmationModal'"
  [closeOthers]="true"
  [maxWidth]="750"
  [warningBackground]="true"
>
  <div class="flex-container alert">
    <div class="column left">
      <div class="column-content">
        <span class="icon-amfam-alert-triangle-fill-1"></span>
      </div>
    </div>
    <div class="column right">
      <div class="column-content">
        <div class="heading">{{ heading }}</div>
        <div>{{ alreadyPaid }}</div>
        <div>{{ question }}</div>
      </div>
    </div>
  </div>

  <button ds-button class="-outline-primary btn" (click)="handleYes.emit()">{{ yes }}</button>
  <button ds-button class="-outline btn" (click)="handleNo.emit()">{{ no }}</button>
</ds-modal>
