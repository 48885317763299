/* This is to standardize how source System is dealt with */
export enum SourceSystemType {
  Advance, // PolicyCenter
  Legacy // Classic i.e. AutoPlus
}

export enum SourceSystem {
  ROME = 'Rome',
  POLICYCENTER = 'PolicyCenter',
  AUTOPLUS = 'AutoPlus'
}
