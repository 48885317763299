import { Action } from '@ngrx/store';

import { AutoPolicy, RiskModel } from '@amfam/policy/models';

import { KydSmartphonePolicy, KydSmartphoneSummary } from '../kyd-smartphone';
import { KydEligibilityResponse } from './models/kyd-eligibility-response.model';

export const LOAD = '[KydEligibility] Load';
export const LOAD_SUCCESS = '[KydEligibility] Load Success';
export const LOAD_COMPLETE = '[KydEligibility] Load Complete';
export const LOAD_FAIL = '[KydEligibility] Load Fail';

export const REFRESH_ELIGIBILITY = '[KydEligibility] Refresh Eligibility';

export const LOAD_SMARTPHONE_KYD = '[KydEligibility] Load Kyd';
export const LOAD_SMARTPHONE_KYD_SUCCESS = '[KydEligibility] Load Kyd Success';
export const LOAD_SMARTPHONE_KYD_FAIL = '[KydEligibility] Load Kyd Fail';

export const LOAD_SMARTPHONE_KYD_POLICIES = '[KydEligibility] Load Kyd Policies';
export const LOAD_SMARTPHONE_KYD_POLICIES_SUCCESS = '[KydEligibility] Load Kyd Success Policies';
export const LOAD_SMARTPHONE_KYD_POLICIES_FAIL = '[KydEligibility] Load Kyd Fail Policies';

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: RiskModel[]) {}
}

export class LoadCompleteAction implements Action {
  readonly type = LOAD_COMPLETE;

  constructor(public payload?: any) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: KydEligibilityResponse[]) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;

  constructor(public payload?: any) {}
}

export class RefreshEligibilityAction implements Action {
  readonly type = REFRESH_ELIGIBILITY;

  constructor(public payload?: string[]) {}
}

export class LoadSmartPhoneKydPoliciesAction implements Action {
  readonly type = LOAD_SMARTPHONE_KYD_POLICIES;

  constructor(
    public payload: {
      smartPhonePolicyList: AutoPolicy[];
      smartPhoneEnrollmentSummaryList: KydSmartphoneSummary[];
    }
  ) {}
}

export class LoadSmartPhoneKydPoliciesActionSuccessAction implements Action {
  readonly type = LOAD_SMARTPHONE_KYD_POLICIES_SUCCESS;

  constructor(public payload: KydSmartphonePolicy[]) {}
}

export class LoadSmartPhoneKydPoliciesActionFailAction implements Action {
  readonly type = LOAD_SMARTPHONE_KYD_POLICIES_FAIL;

  constructor(public payload?: any) {}
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | LoadCompleteAction
  | RefreshEligibilityAction
  | LoadSmartPhoneKydPoliciesAction
  | LoadSmartPhoneKydPoliciesActionSuccessAction
  | LoadSmartPhoneKydPoliciesActionFailAction;
