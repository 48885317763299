<div class="borderless-tiles">
  @for (
    opportunityContent of opportunitiesContentList
      | slice
        : 0
        : (opportunitiesSlicedLength > displayedOpportunitiesLength
            ? opportunitiesSlicedLength
            : displayedOpportunitiesLength);
    track opportunityContent
  ) {
    <div class="opportunity-tile">
      @for (opportunityInfo of opportunityContent?.slides; track opportunityInfo) {
        @if (opportunityInfo) {
          <ds-opportunity-card
            [opportunityCardInformation]="opportunityInfo"
            [recommendationId]="opportunityContent.recommendationId"
            [successMessage]="opportunityContent.successNotification"
            [nothanksSuccessMessage]="opportunityContent.nothanksSuccessMessage"
            [isAuthorized]="isAuthorized"
            [failureMessage]="opportunityContent.failureNotification"
            (learnMoreEvent)="learnMoreEvent.emit(opportunityContent.recommendationId)"
          >
          </ds-opportunity-card>
        }
      }
    </div>
  }
</div>

@if (
  opportunitiesContentList?.length &&
  opportunitiesContentList?.length > displayedOpportunitiesLength
) {
  <div class="column twelve pad-10 text-center pad-top-40">
    <p class="primary-800-text text-center">
      <a
        id="toggleLink"
        class="no-underline"
        href="javascript:void(0);"
        (click)="
          toggleContentList(
            opportunitiesSlicedLength,
            opportunitiesContentList?.length,
            displayedOpportunitiesLength
          )
        "
      >
        {{
          (opportunitiesSlicedLength > displayedOpportunitiesLength
            ? opportunitiesSlicedLength
            : displayedOpportunitiesLength) < opportunitiesContentList?.length
            ? 'Show More'
            : 'Show less'
        }}
        <span
          [ngClass]="
            (opportunitiesSlicedLength > displayedOpportunitiesLength
              ? opportunitiesSlicedLength
              : displayedOpportunitiesLength) < opportunitiesContentList?.length
              ? 'icon-chevron-down'
              : 'icon-chevron-up'
          "
        ></span>
      </a>
    </p>
  </div>
}
