import { createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap } from 'lodash';

import { AutoPaySelectors } from '@amfam/billing/auto-pay/data-access';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { PaymentMethodSelectors } from '@amfam/billing/payment-method/data-access';
import { ScheduledPaymentSelectors } from '@amfam/billing/schedulepayment/data-access';
import { PaymentStatus } from '@amfam/shared/models';
import { ArrayToList } from '@amfam/shared/ui/pipes';

import {
  AutomaticPayments,
  PaymentMethodSetup,
  ScheduledPayments
} from './payment-method-feature.models';
import * as fromPaymentMethodFeatureState from './payment-method-feature.reducer';

export const selectPaymentMethodFeatureState =
  createFeatureSelector<fromPaymentMethodFeatureState.State>(
    fromPaymentMethodFeatureState.paymentMethodFeatureKey
  );

export const selectPaymentMethodOperation = createSelector(
  selectPaymentMethodFeatureState,
  (state: fromPaymentMethodFeatureState.State) =>
    <'add' | 'edit' | 'delete-success' | 'delete-error'>state.operation
);

export const selectScheduledPaymentsByPaymentMethod = createSelector(
  PaymentMethodSelectors.selectPaymentMethodEntities,
  PaymentMethodSelectors.selectNewPaymentAccountId,
  BillAccountsSelectors.selectUserRegisteredBillAccounts,
  ScheduledPaymentSelectors.selectScheduledPayments,
  AutoPaySelectors.getAutomaticPayments,
  (
    paymentMethodEntities,
    paymentAccountId,
    userRegisteredBillAccountEntities,
    scheduledPaymentEntities,
    automaticPaymentEntities
  ) => {
    // get payment method to be deleted
    const paymentMethod = paymentMethodEntities[paymentAccountId];
    const scheduledPayments: ScheduledPayments[] = [];
    const automaticPayments: AutomaticPayments[] = [];
    // filter scheduled payments and automatic payments with same payment method
    scheduledPaymentEntities = scheduledPaymentEntities.filter(
      sp =>
        sp?.paymentStatus === PaymentStatus.SCHEDULED &&
        sp?.paymentAccount?.nickName === paymentMethod.nickName
    );
    automaticPaymentEntities = automaticPaymentEntities.filter(
      ap => ap?.paymentAccount.nickName === paymentMethod.nickName
    );

    // get the affected scheduled payments
    for (const item in scheduledPaymentEntities) {
      if (scheduledPaymentEntities[item]) {
        for (const n in scheduledPaymentEntities[item].billAccounts) {
          if (scheduledPaymentEntities[item].billAccounts) {
            const matchingBillAccount = userRegisteredBillAccountEntities.find(
              ba =>
                ba.billAccountNumber ===
                scheduledPaymentEntities[item].billAccounts[n].billAccountNumber
            );
            if (matchingBillAccount) {
              const objData: ScheduledPayments = {
                billAccountNumber: matchingBillAccount.billAccountNumber,
                billAcctPaymentAmount:
                  scheduledPaymentEntities[item].billAccounts[n].billAcctPaymentAmount,
                icon: matchingBillAccount.icon,
                riskDescription: new ArrayToList().transform(
                  flatMap(matchingBillAccount.policyList, policy => policy.riskDescriptionList)
                ),
                date: scheduledPaymentEntities[item].receivedDate
              };
              scheduledPayments.push(objData);
            }
          }
        }
      }
    }
    scheduledPayments.sort(function (a, b) {
      return Number(new Date(b.date)) - Number(new Date(a.date));
    });

    // get the affected automatic payments
    for (const item in automaticPaymentEntities) {
      if (automaticPaymentEntities[item]) {
        const matchingBillAccount = userRegisteredBillAccountEntities.find(
          ba => ba.billAccountNumber === automaticPaymentEntities[item].billAccountNumber
        );
        if (matchingBillAccount) {
          const objData: AutomaticPayments = {
            billAccountNumber: matchingBillAccount.billAccountNumber,
            daysBeforeDueDate: automaticPaymentEntities[item].daysBeforeDueDate,
            icon: matchingBillAccount.icon,
            nextPaymentDate: automaticPaymentEntities[item].nextPaymentDate,
            paymentAmount: automaticPaymentEntities[item].paymentAmount,
            predictedDollarAmount: automaticPaymentEntities[item].predictedDollarAmount,
            riskDescription: new ArrayToList().transform(
              flatMap(matchingBillAccount.policyList, policy => policy.riskDescriptionList)
            )
          };
          automaticPayments.push(objData);
        }
      }
    }
    const paymentMethodInfo: PaymentMethodSetup = {
      paymentMethod: paymentMethod,
      scheduledPayments: scheduledPayments,
      automaticPayments: automaticPayments
    };
    return paymentMethodInfo;
  }
);

export const selectDeletePaymentMethodInfo = createSelector(
  selectPaymentMethodFeatureState,
  (state: fromPaymentMethodFeatureState.State) => state.paymentMethodInfo
);
