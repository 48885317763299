import { Policy, PolicyTypeIconConstants } from '@amfam/policy/models';

import { UnderlyingPolicy } from './underlying-policy';

export class UmbrellaPolicy extends Policy {
  public policyDescription: string;
  public underlyingPolicies: UnderlyingPolicy[] = new Array<UnderlyingPolicy>();

  static fromJson(json: unknown): UmbrellaPolicy {
    const policy = new UmbrellaPolicy();
    policy.assignProperties(json);

    return policy;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected assignProperties(json: any) {
    super.assignProperties(json);
    this.iconType = PolicyTypeIconConstants.UMBRELLA;
    this.policyType = 'umbrella';

    this.policyDescription = json.policyDescription;
    this.policyRisks = [this.policyDescription];
    this.riskDescriptionList = [this.policyDescription];

    for (const policy of json.underlyingPolicies) {
      this.underlyingPolicies.push(UnderlyingPolicy.fromJson(policy));
    }
  }
}
