import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { GoPaperLessSelectors } from '@amfam/policy/go-paperless/data-access';
import { userQuery } from '@amfam/shared/user';

import * as fromProfileState from './profile.reducer';
import { PROFILE_FEATURE_KEY, ProfileState } from './profile.reducer';

export const selectProfileState = createFeatureSelector<ProfileState>(PROFILE_FEATURE_KEY);

export const selectProfileLoading = createSelector(selectProfileState, fromProfileState.getLoading);
export const selectAlternateAuthCustomerId = createSelector(
  selectProfileState,
  fromProfileState.getCustomerId
);

export const selectEmailVerificationPending = createSelector(
  selectProfileState,
  fromProfileState.getEmailVerificationPending
);
export const selectUpdatedMyAccountEmailAddress = createSelector(
  selectProfileState,
  fromProfileState.getUpdatedMyAccountEmailAddress
);

export const selectToastMessage = createSelector(
  selectProfileState,
  fromProfileState.getToastMessage
);
export const selectProfileSecurityInfoStatus = createSelector(
  selectProfileState,
  fromProfileState.selectSecurityInfoStatus
);
export const selectSecurityQuestions = createSelector(
  selectProfileState,
  fromProfileState.getSecurityQuestions
);
export const selectMembership = createSelector(selectProfileState, fromProfileState.getMembership);

export const selectHasSecurityQuestions = createSelector(
  selectSecurityQuestions,
  securityQuestions => {
    if (securityQuestions && securityQuestions.length && securityQuestions.length > 0) {
      return (
        _get(securityQuestions[0], 'question', '') !== '' &&
        _get(securityQuestions[1], 'question', '') !== ''
      );
    }
    return false;
  }
);
export const selectTokenExpired = createSelector(
  selectProfileState,
  (state: ProfileState) => fromProfileState.getTokenExpred(state) ?? false
);

export const selectPaperlessAndBillAccount = createSelector(
  GoPaperLessSelectors.selectGoPaperlessState,
  BillAccountsSelectors.selectBillAccountsState,
  (paperlessState, billAccounts) => ({
    paperlessState,
    billAccounts
  })
);

export const selectBillAccountAndPaperless = createSelector(
  BillAccountsSelectors.selectBillAccountsNotifications,
  BillAccountsSelectors.selectBillAccountsAnyLoading,
  GoPaperLessSelectors.selectGoPaperlessLoading,
  GoPaperLessSelectors.selectGoPaperlessError,
  (billAccountNotifications, billAccountsAnyLoading, goPaperlessLoading, goPaperlessError) => ({
    billAccountNotifications,
    loading: billAccountsAnyLoading || goPaperlessLoading,
    goPaperlessError: goPaperlessError
  })
);

export const selectEmail = createSelector(
  selectEmailVerificationPending,
  selectUpdatedMyAccountEmailAddress,
  userQuery.getMyAccountEmail,
  userQuery.getEmails,
  selectProfileLoading,
  (emailVerificationPending, updatedMyAccountEmailAddress, myAccountEmail, emails, loading) => ({
    emailVerificationPending,
    updatedMyAccountEmailAddress,
    myAccountEmail,
    emails,
    loading
  })
);

export const selectCostcoMembershipNumber = createSelector(selectMembership, membershipDetails => {
  const memberNumber = membershipDetails.membershipNumber ?? '123456789012'; //The hard code value can be removed once API returns the membership details.
  const charsToMask = memberNumber.length - 4;
  return '*'.repeat(charsToMask) + memberNumber.substring(charsToMask);
});

export const selectCostcoMembershipType = createSelector(selectMembership, membershipDetails => {
  switch (membershipDetails.membershipType) {
    case 'BUSINESS_MEMBERSHIP':
      return 'Business';
    case 'EXECUTIVE':
      return 'Executive';
    case 'GOLD STAR':
      return 'Gold Star';
    default:
      return 'Gold Star';
  }
});

export const profileQuery = {
  selectBillAccountAndPaperless,
  selectEmail,
  selectPaperlessAndBillAccount,
  selectProfileState,
  selectProfileLoading,
  selectAlternateAuthCustomerId,
  selectEmailVerificationPending,
  selectUpdatedMyAccountEmailAddress,
  selectToastMessage,
  selectProfileSecurityInfoStatus,
  selectSecurityQuestions,
  selectHasSecurityQuestions,
  selectTokenExpired,
  selectMembership,
  selectCostcoMembershipNumber,
  selectCostcoMembershipType
};
