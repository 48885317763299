<form [formGroup]="billingPreferencesForm" class="fill">
  <div formArrayName="billingPreferences">
    @for (preference of preferenceData; track preference; let i = $index) {
      <div class="preferences-row">
        <div class="row">
          <div class="column twelve flex flex-direction-column md-flex-direction-row">
            <div class="row flex grow">
              <div class="flex wrap">
                <div class="fill" data-cy="billsForBillAccountName">
                  <span content [contentPath]="'profile.communicationPreferencesBillsFor'"></span>
                  {{ preference.billAccountName }}
                </div>
                <div class="fill description-text" data-cy="preferenceRiskNames">
                  {{ preference.riskNames?.join(', ') }}
                </div>
              </div>
            </div>
            <div class="row billing preference-options">
              <!--
              Below implementation to disable radio button is workaround implementation from https://github.com/angular/angular/issues/11763#issuecomment-268622631
              -->
              <div class="column preference-options-item">
                <fieldset
                  [disabled]="
                    preference.canSetPreferences === false ||
                    (roleService.isAuthorized('edit_communication_prefrences') | async) === false ||
                    adminPartnerDisabled
                  "
                >
                  <input
                    type="radio"
                    id="paper-{{ i }}"
                    value="paper"
                    formControlName="{{ i }}"
                    data-cy="billingPreferenceCtrlPaper"
                  />
                </fieldset>
                <label
                  class="block md-none"
                  for="paper-{{ i }}"
                  data-cy="paperHeader"
                  content
                  [contentPath]="'profile.communicationPreferencesPaperLabel'"
                ></label>
              </div>
              <div class="column preference-options-item">
                <fieldset
                  [disabled]="
                    preference.canSetPreferences === false ||
                    (roleService.isAuthorized('edit_communication_prefrences') | async) === false ||
                    adminPartnerDisabled
                  "
                >
                  <input
                    type="radio"
                    id="email-{{ i }}"
                    value="email"
                    formControlName="{{ i }}"
                    data-cy="billingPreferenceCtrlEmail"
                  />
                </fieldset>
                <label
                  class="block md-none"
                  for="email-{{ i }}"
                  data-cy="userEmail"
                  content
                  [contentPath]="'profile.communicationPreferencesEmailLabel'"
                ></label>
              </div>
              <div class="column preference-options-item">
                <fieldset
                  [disabled]="
                    preference.canSetPreferences === false ||
                    (roleService.isAuthorized('edit_communication_prefrences') | async) === false ||
                    adminPartnerDisabled
                  "
                >
                  <input
                    type="radio"
                    id="both-{{ i }}"
                    value="both"
                    formControlName="{{ i }}"
                    data-cy="billingPreferenceCtrlBoth"
                  />
                </fieldset>
                <label
                  class="block md-none"
                  for="both-{{ i }}"
                  data-cy="bothPaperAndPaperless"
                  content
                  [contentPath]="'profile.communicationPreferencesBothLabel'"
                ></label>
              </div>
            </div>
          </div>
        </div>
        @if (canWarn[i] && billingPreferences.controls[i].value !== 'email') {
          <div class="row">
            <div class="column twelve">
              <ds-preferences-warning
                [billAccountType]="preference.billAccountType"
                [deliveryPreference]="billingPreferences.controls[i].value"
              >
              </ds-preferences-warning>
            </div>
          </div>
        }
      </div>
    }
  </div>
</form>
