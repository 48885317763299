import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  PAYMENT_HISTORY_FEATURE_KEY,
  PaymentHistoryState,
  selectAllPayments
} from './payment-history.reducer';

const selectPaymentHistoryState = createFeatureSelector<PaymentHistoryState>(
  PAYMENT_HISTORY_FEATURE_KEY
);

const selectMonthRange = createSelector(selectPaymentHistoryState, state => state.monthRange);

const selectPayments = createSelector(selectPaymentHistoryState, selectAllPayments);

const selectLoading = createSelector(selectPaymentHistoryState, state => state.loading);

const selectError = createSelector(selectPaymentHistoryState, state => state.hasError);

export const paymentHistoryQuery = {
  selectPaymentHistoryState,
  selectMonthRange,
  selectPayments,
  selectLoading,
  selectError
};
