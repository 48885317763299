<div class="ds-tooltip">
  <div class="ds-tooltip__header">
    @if (heading) {
      <div class="title">{{ heading }}</div>
    }
    @if (showCloseButton) {
      <button
        title="Close"
        class="close"
        data-cy="iconExit"
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          $event.stopImmediatePropagation();
          close.emit($event)
        "
      >
        <i class="icon-exit"></i>
      </button>
    }
  </div>
  <div class="ds-tooltip__body" [innerHTML]="text" data-cy="toolTipBody"></div>
</div>
