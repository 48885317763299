import { keyBy as _keyBy, values as _values } from 'lodash';

import { Entities, initialEntities } from '../entity/entity.model';
import * as maeProgram from './mae-program.actions';
import { Program } from './models';

export function reducer(
  state = initialEntities<Program>(),
  action: maeProgram.Actions
): Entities<Program> {
  let entities = {};

  switch (action.type) {
    case maeProgram.LOAD:
      entities = {};
      return Object.assign({}, state, {
        loading: true
      });
    case maeProgram.LOAD_SUCCESS:
      entities = _keyBy(action.payload, (program: Program) => program.title);
      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities,
        loaded: true,
        loading: false
      });
    case maeProgram.LOAD_FAIL:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload
      });
    case maeProgram.INIT_PROGRAMS:
      return Object.assign({}, state, {
        ids: [],
        entities: []
      });
    default:
      return state;
  }
}

export const getEntities = (state: Entities<Program>) => state.entities;

export const getIds = (state: Entities<Program>) => state.ids;

export const getError = (state: Entities<Program>) => state.error;

export const getLoaded = (state: Entities<Program>) => state.loaded;

export const getLoading = (state: Entities<Program>) => state.loading;
