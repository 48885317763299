import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PolicySummary } from '@amfam/policy/models';

import { LoadDocuments } from './documents.actions';
import { documentsQuery } from './documents.selectors';

@Injectable()
export class DocumentsFacade {
  loaded$ = this.store.select(documentsQuery.selectLoaded);
  allDocuments$ = this.store.select(documentsQuery.selectAllDocuments);

  constructor(private store: Store) {}

  loadAll(policySummaryList: PolicySummary[]) {
    this.store.dispatch(LoadDocuments(policySummaryList));
  }
}
