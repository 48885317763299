import { keyBy as _keyBy, values as _values } from 'lodash';

import { Entities, initialEntities } from '../../entity/entity.model';
import * as kydEnrollActions from './kyd-enroll.actions';
import { KydConfirmation } from './models';

export function reducer(
  state = initialEntities<KydConfirmation>(),
  action: kydEnrollActions.Actions
): Entities<KydConfirmation> {
  let entities: {};

  switch (action.type) {
    case kydEnrollActions.UNENROLL:
    case kydEnrollActions.ENROLL:
      return Object.assign({}, state, {
        loaded: false,
        loading: true
      });

    case kydEnrollActions.UNENROLL_COMPLETE:
    case kydEnrollActions.ENROLL_COMPLETE:
      return Object.assign({}, state, {
        loaded: true,
        loading: false
      });

    case kydEnrollActions.ENROLL_SUCCESS:
      entities = _keyBy(action.payload, confirmation => confirmation.risk.vin);
      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: entities,
        loading: false
      });

    case kydEnrollActions.UNENROLL_FAIL:
    case kydEnrollActions.ENROLL_FAIL:
      return Object.assign({}, state, {
        ids: [],
        entities: [],
        loaded: true,
        loading: false,
        error: action.payload
      });

    default:
      return state;
  }
}

export const getEntities = (state: Entities<KydConfirmation>) => state.entities;

export const getIds = (state: Entities<KydConfirmation>) => state.ids;

export const getError = (state: Entities<KydConfirmation>) => state.error;

export const getLoaded = (state: Entities<KydConfirmation>) => state.loaded;

export const getLoading = (state: Entities<KydConfirmation>) => state.loading;
