import { ApiStatus } from '@amfam/shared/models';

import { VehicleFeaturesModel } from './vehicle-features.model';

export interface VehicleSelectCriteria {
  make?: string;
  series?: string;
  model?: string;
  odometer?: string;
  vin?: string;
  year?: string;
  msrp?: string;
  estimatedValue?: string;
  vehicleType?: string;
  underWritingQuestions?: UnderwritingQuestion[];
  copyTPIIndicator?: TPIIndicator;
  TPIName?: string;
}

export interface LookupVinYearPayload {
  year: string;
  vin: string;
}

export interface GetMakesPayload {
  year: string;
}

export interface GetModelsPayload {
  year: string;
  make: string;
}

export interface GetStylesPayload {
  year: string;
  make: string;
  model: string;
}

export interface GetVehicleMakesApiResponse {
  status: ApiStatus;
  makes?: string[];
}

export interface GetVehicleModelsApiResponse {
  status: ApiStatus;
  models?: string[];
}

export interface GetVehicleSeriesApiResponse {
  status: ApiStatus;
  series?: string[];
}

export interface GetVehicleVinYearApiResponse {
  newVehicle: NewVehicle;
  features: VehicleFeaturesModel[];
  criteria?: VehicleSelectCriteria;
}

export interface UnderwritingQuestion {
  question: string;
  answer: string;
}

export enum TPIIndicator {
  YES = 'yes',
  NO = 'no'
}

export enum VechiclePolicyChangeEntryPoint {
  OVERVIEW = 'overview',
  POLICY = 'policies'
}

export interface NewVehicle {
  year?: string;
  vin?: string;
  make?: string;
  model?: string;
  series?: string;
  msrp?: string;
  estimatedValue?: string;
  odometer?: string;
}
