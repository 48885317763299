import * as session from './session.actions';
import { Session, initialSession } from './session.model';

export function reducer(state = initialSession, action: session.Actions): Session {
  switch (action.type) {
    case session.SESSION_LOAD_SUCCESS:
      return Object.assign({}, state, {
        device: action.payload
      });

    case session.REFRESH_USER:
    case session.LOGIN_USER:
      return Object.assign({}, state, {
        status: null,
        loggedIn: false,
        hasError: false,
        isLoading: true,
        sessionValidUntil: null
      });

    case session.LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        status: action.payload.status,
        loggedIn: true,
        hasError: false,
        isLoading: false,
        sessionValidUntil: action.payload.sessionValidUntil
      });

    case session.LOGIN_USER_FAIL:
      return Object.assign({}, state, {
        status: action.payload.status,
        loggedIn: false,
        hasError: true,
        isLoading: false,
        sessionValidUntil: null
      });

    case session.REFRESH_USER_FAIL:
      return Object.assign({}, state, {
        status: action.payload.status,
        loggedIn: false,
        hasError: true,
        isLoading: false,
        sessionValidUntil: null
      });

    case session.SET_TIME_OFFSET:
      return Object.assign({}, state, {
        differenceInMilliseconds: action.payload.differenceInMilliseconds
      });

    default:
      return state;
  }
}

export const status = (state: Session) => state.status;

export const hasError = (state: Session) => state.hasError;

export const isLoading = (state: Session) => state.isLoading;

export const loggedIn = (state: Session) => !!state.loggedIn;

export const loggedOut = (state: Session) => !state.loggedIn;

export const getDevice = (state: Session) => state.device;

export const getSessionValidUntil = (state: Session) => state.sessionValidUntil;

export const getTimeDifferenceInMilliseconds = (state: Session) => state.differenceInMilliseconds;
