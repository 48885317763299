import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MaeService } from '../../../enrollment/mae/mae.service';
import * as maeStatusActions from './mae-status.actions';

@Injectable()
export class MaeStatusEffects {
  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(maeStatusActions.LOAD),
      map((action: maeStatusActions.LoadAction) => action.payload),
      switchMap(request => this.maeService.getMaeStatus(request)),
      map(status => new maeStatusActions.LoadSuccessAction(status)),
      catchError(error => observableOf(new maeStatusActions.LoadFailAction(error)))
    )
  );

  constructor(
    private maeService: MaeService,
    private action$: Actions
  ) {}
}
