<div>
  @if (!hidePolicyNumberBanner) {
    <div class="pad-bottom-20">
      <ds-amfam-dsn-alert
        *dsPartnerConnect
        [icon]="false"
        [type]="'info'"
        [dismiss]="true"
        [message]="bannerForPartner"
        [title]="BannerForPartner"
        [action]="true"
        (dismissEvent)="clickedOnCancel()"
      ></ds-amfam-dsn-alert>
    </div>
  }
  <h3 content [contentPath]="'enrollment.verifyPolicyConfirm'"></h3>
  <p>
    @if (multipleMatch) {
      <span content [contentPath]="'enrollment.verifyPolicyNumConfirmText'"></span>
    }
    <span content [contentPath]="'enrollment.verifyPolicyNumContinueText'"></span>
  </p>
  <ds-verify-policy-num (policyNumSubmittedEvent)="preparePolicyNum($event)">
  </ds-verify-policy-num>
  <div *dsPartnerAFI>
    @if (verifyResponse && !verifyResponse.success && policyNumNotFoundMessage) {
      <small
        data-cy="enrollmentVerifyPolicyNumMismatchText"
        class="block caption error margin-top-5"
        content
        [contentPath]="'enrollment.verifyPolicyNumMismatchText'"
      ></small>
    }
  </div>
  <div *dsPartnerConnect>
    @if (verifyResponse && !verifyResponse.success && policyNumNotFoundMessage) {
      <small
        data-cy="enrollmentVerifyPolicyNumMismatchText"
        class="block caption error margin-top-5"
        content
        [contentPath]="'enrollment.verifyPolicyNumNotFoundParnterText'"
      ></small>
    }
  </div>

  <a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block margin-top-20 caption"
    ><span class="icon-chevron-left"></span> Back</a
  >
</div>
