import { createSelector } from '@ngrx/store';

import { ClaimServicesState, getClaimServicesState } from '..';
import { RepairServiceState } from './repair-service.reducer';

export const getRepairServiceState = createSelector(
  getClaimServicesState,
  (state: ClaimServicesState) => state.repairService
);

export const getRepairServiceLoading = createSelector(
  getRepairServiceState,
  (state: RepairServiceState) => state.loading
);

export const getRepairServiceError = createSelector(
  getRepairServiceState,
  (state: RepairServiceState) => state.error
);

export const getRepairServiceSelectedShop = createSelector(
  getRepairServiceState,
  (state: RepairServiceState) => state.selectedShop
);

export const repairServiceQuery = {
  getRepairServiceState,
  getRepairServiceLoading,
  getRepairServiceError,
  getRepairServiceSelectedShop
};
