import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { PaymentMethodSetup } from '../..';
import * as PaymentMethodFeatureActions from './payment-method-feature.actions';

export const paymentMethodFeatureKey = 'paymentMethodSetup';

export interface State extends EntityState<PaymentMethodSetup> {
  operation: 'add' | 'edit' | 'delete-success' | 'delete-error';
  paymentMethodInfo: PaymentMethodSetup;
}

export const adapter: EntityAdapter<PaymentMethodSetup> = createEntityAdapter<PaymentMethodSetup>();

export const initialState: State = adapter.getInitialState({
  operation: null,
  paymentMethodInfo: null
});

export const reducer = createReducer(
  initialState,

  on(
    PaymentMethodFeatureActions.storeDeletePaymentMethodInfo,
    (state, action): State => ({
      ...state,
      paymentMethodInfo: action.paymentMethodInfo
    })
  ),

  on(
    PaymentMethodFeatureActions.setPaymentMethodOperation,
    (state, action): State => ({
      ...state,
      operation: action.operation
    })
  ),

  on(PaymentMethodFeatureActions.resetPaymentMethodState, (): State => initialState)
);
