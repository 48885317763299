import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { mergeMap, Observable } from 'rxjs';

import { MessageDetail } from '@amfam/policy/models';
import { BrandSelectors } from '@amfam/shared/utility/brand';

import { ConfigService } from '../config';
import { ANSNotification } from './models/ansnotification.model';
import { InternalEmail } from './models/internalemail.model';
import { TemplatedEmail } from './models/templatedemail.model';

@Injectable({ providedIn: 'root' })
export class CommunicationService {
  producerApi = this.config.get('producerApi');
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private store: Store
  ) {}

  public sendEmailNotificationForProfile(
    key,
    partnerId: string,
    customerId: string,
    emailAddress: string,
    firstname?: string
  ): Observable<any> {
    const keys = {
      emails: 'UpdateEmail',
      phones: 'UpdatePhoneNumber'
    };
    const email = new TemplatedEmail(
      keys[key],
      partnerId,
      'MyAccount',
      'EN',
      customerId,
      emailAddress,
      firstname ? [new MessageDetail('firstname', firstname)] : null
    );
    return this.sendTemplatedEmail(email);
  }

  public sendTemplatedEmail(email: TemplatedEmail): Observable<any> {
    return this.store.select(BrandSelectors.selectAccountId).pipe(
      mergeMap(accountId => {
        const headers = this.getHeaders();
        const url: string = this.config.get('communicationApi') + 'templatedemails';
        return this.http.post(url, { ...email, accountId }, { headers: headers });
      })
    );
  }

  public sendInternalEmail(email: InternalEmail): Observable<any> {
    const headers = this.getHeaders();
    const url: string = this.config.get('communicationApi') + 'emails';
    return this.http.post(url, email, { headers: headers });
  }

  public ansNotification(ansNotification: ANSNotification): Observable<any> {
    const { producerId, ...ansRequest } = ansNotification;
    const uri = `${this.config.get('producerApi')}producers/${producerId}/notifications`;
    return this.http.post(uri, ansRequest);
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({ authId: 'cdhtp1', userId: 'cdhtp1' });
  }
}
