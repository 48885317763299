import { ConfigurableFocusTrap, ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { distinctUntilChanged, filter, map, Subject, takeUntil, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { TooltipComponent } from './tooltip.component';
import { TooltipService } from './tooltip.service';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input() tooltip: string | TemplateRef<any>;
  @Input() triggerOn: string;
  @Input() heading: string;
  @Input() showCloseButton = false;
  @Input() actionButtonsRef: string;
  @Input() closeOnClickingOutside = true;
  @Input() closeOnEscape = true;
  @Output() buttonClick = new EventEmitter<any>();

  id: string;

  private stop$ = new Subject<void>();
  private isMobile = this.platform.ANDROID || this.platform.IOS;
  private overlayRef: OverlayRef;
  private tooltipRef: ComponentRef<TooltipComponent | any> | EmbeddedViewRef<any>;
  private focusTrap: ConfigurableFocusTrap;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private platform: Platform,
    private viewContainerRef: ViewContainerRef,
    private tooltipService: TooltipService,
    private renderer: Renderer2,
    private configurableFocusTrapFactory: ConfigurableFocusTrapFactory
  ) {}

  ngOnInit(): void {
    this.id = uuidv4();
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: Positions.CENTER,
          originY: Positions.TOP,
          overlayX: Positions.START,
          overlayY: Positions.BOTTOM,
          offsetY: -20,
          offsetX: -20
        },
        {
          originX: Positions.CENTER,
          originY: Positions.TOP,
          overlayX: Positions.END,
          overlayY: Positions.BOTTOM,
          offsetY: -20,
          offsetX: 20
        },
        {
          originX: Positions.CENTER,
          originY: Positions.BOTTOM,
          overlayX: Positions.START,
          overlayY: Positions.TOP,
          offsetY: 20,
          offsetX: -20
        },
        {
          originX: Positions.CENTER,
          originY: Positions.BOTTOM,
          overlayX: Positions.END,
          overlayY: Positions.TOP,
          offsetY: 20,
          offsetX: 15
        }
      ]);

    positionStrategy.positionChanges
      .pipe(
        map(
          x =>
            `${x.connectionPair.originY}-${x.connectionPair.originX}__${x.connectionPair.overlayY}-${x.connectionPair.overlayX}`
        ),
        distinctUntilChanged(),
        tap(position => this.setArrow(position)),
        takeUntil(this.stop$)
      )
      .subscribe();

    this.overlayRef = this.overlay.create({
      positionStrategy,
      panelClass: 'tooltip__container',
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef
      .outsidePointerEvents()
      .pipe(
        takeUntil(this.stop$),
        filter((event: MouseEvent) => event.target !== this.elementRef.nativeElement),
        tap(() => {
          if (this.closeOnClickingOutside) this.hideTooltip();
        })
      )
      .subscribe();

    this.overlayRef
      .keydownEvents()
      .pipe(
        takeUntil(this.stop$),
        tap(({ key }) => {
          if (key === Keys.ESCAPE && this.closeOnEscape) this.hideTooltip();
        })
      )
      .subscribe();

    this.tooltipService.addTooltip(this.id, this.overlayRef);
  }

  @HostListener('click', ['$event'])
  toggle(e: MouseEvent | KeyboardEvent | TouchEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    if (this.triggerOn === EventsEnum.CLICK) {
      if (!this.overlayRef.hasAttached()) {
        this.showTooltip();
      } else this.hideTooltip();
    }
  }

  @HostListener('mouseenter', ['$event'])
  @HostListener('focus', ['$event'])
  show(e) {
    if (this.triggerOn === EventsEnum.CLICK) return;
    if (this.isMobile && e.type === EventsEnum.MOUSE_ENTER) return;

    if (!this.overlayRef.hasAttached()) {
      this.showTooltip();
    }
  }

  showTooltip() {
    this.tooltipService.closeAllExcept(this.id);
    if (typeof this.tooltip === 'string') {
      this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
      this.tooltipRef.setInput('text', this.tooltip);
      if (this.heading) this.tooltipRef.instance.heading = this.heading;
      this.tooltipRef.instance.showCloseButton = this.showCloseButton;
      this.tooltipRef.instance.close
        .pipe(
          takeUntil(this.stop$),
          tap(event => {
            this.buttonClick.emit({ buttonRef: 'close', event } as TooltipButtonEventArgs);
            if (this.overlayRef.hasAttached()) this.hideTooltip();
            this.elementRef.nativeElement.focus();
          })
        )
        .subscribe();
      this.focusTrap = this.configurableFocusTrapFactory.create(this.overlayRef.hostElement);
    } else if (this.tooltip instanceof TemplateRef) {
      this.tooltipRef = this.overlayRef.attach(
        new TemplatePortal(this.tooltip as TemplateRef<any>, this.viewContainerRef)
      );
      this.focusTrap = this.configurableFocusTrapFactory.create(this.overlayRef.hostElement);
      this.setupButtonsInTemplate();
    }
  }

  hideTooltip() {
    if (this.focusTrap) this.focusTrap.destroy();
    if (this.overlayRef.hasAttached()) this.overlayRef.detach();
  }

  setupButtonsInTemplate() {
    if (this.actionButtonsRef) {
      const buttonRefs = this.actionButtonsRef.split(',');
      buttonRefs.forEach(buttonRef => {
        const button = this.overlayRef.hostElement.querySelector(`[${buttonRef}]`);
        if (button) {
          this.renderer.listen(button, EventsEnum.CLICK, event => {
            this.buttonClick.emit({ buttonRef, event } as TooltipButtonEventArgs);
            if (buttonRef.trim() === ButtonsRefs.CLOSE) {
              if (this.overlayRef.hasAttached()) this.hideTooltip();
              this.elementRef.nativeElement.focus();
            }
          });
          this.renderer.setStyle(button, 'z-index', 10001);
          this.renderer.setStyle(button, 'cursor', 'pointer');
        }
      });
    }
  }

  @HostListener('mouseleave', ['$event'])
  @HostListener('blur', ['$event'])
  hide(e) {
    if (this.triggerOn === EventsEnum.CLICK) return;
    if (this.isMobile && e.type === 'mouseleave') return;

    if (this.overlayRef.hasAttached()) this.overlayRef.detach();
    this.focusTrap.destroy();
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
    if (this.overlayRef) this.overlayRef.dispose();
    if (this.tooltipRef) this.tooltipRef.destroy();
    if (this.focusTrap) this.focusTrap.destroy();
  }

  setArrow(direction: string) {
    classess.forEach(cssClass => this.overlayRef.removePanelClass(cssClass));
    this.overlayRef.addPanelClass(direction);
  }
}

const classess = [
  'top-center__bottom-start',
  'top-center__bottom-end',
  'bottom-center__top-start',
  'bottom-center__top-end'
];

export const enum Positions {
  START = 'start',
  END = 'end',
  TOP = 'top',
  BOTTOM = 'bottom',
  CENTER = 'center'
}

export const enum ButtonsRefs {
  CLOSE = 'close'
}

export interface TooltipButtonEventArgs {
  buttonRef: string;
  event: any;
}

export const enum EventsEnum {
  CLICK = 'click',
  FOCUS = 'focus',
  BLUR = 'blur',
  MOUSE_ENTER = 'mouseenter',
  MOUSE_LEAVE = 'mouseleave'
}

export const enum Keys {
  ESCAPE = 'Escape'
}

export const enum TriggerOn {
  CLICK = 'click',
  HOVER = 'hover'
}
