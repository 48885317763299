import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import { isEmpty as _isEmpty } from 'lodash';
import { Subject, takeUntil, tap } from 'rxjs';

import { CopyService } from '@amfam/shared/utility/shared-services';

import { ErrorMessageService } from '../../services/error-message.service';

@Component({
  selector: 'ds-form-control-container',
  templateUrl: './ds-form-control-container.component.html',
  styleUrls: ['./ds-form-control-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DsFormControlContainerComponent
  implements OnInit, OnDestroy, AfterViewInit, OnDestroy
{
  private stop$ = new Subject<void>();
  // Input FormControl
  @Input() control: AbstractControl;

  // Input Label Text
  @Input() labelText?: string;

  // Input Label Text content path
  @Input() labelTextContentPath?: string;

  // Input Wrapper Class
  @Input() inputWrapperClass = 'input';

  // Input Label Class
  @Input() labelClass = '';

  // Input show all error messages
  @Input() allMessages = false;

  // Input to validate pristine submission
  @Input() validateOnSubmit = false;

  @Input() width: number;

  // Whether the label should be floating
  isFloating = false;
  private controlElement: any;

  inputIdAttr = '';

  @ViewChild('inputWrapper', { static: true }) private inputWrapper: ElementRef;

  minWidth: number;

  public get labelControl(): HTMLElement {
    return this.inputWrapper.nativeElement.querySelector('label');
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private hostElement: ElementRef,
    private mediaMatcher: MediaMatcher,
    public copyService: CopyService
  ) {}
  ngAfterViewInit(): void {
    this.setWidth();
  }

  setWidth() {
    const mediaQueryList = this.mediaMatcher.matchMedia('(max-width: 480px)');
    if (mediaQueryList.matches) {
      (this.controlElement as HTMLElement).style.removeProperty('min-width');
      (this.controlElement as HTMLElement).style.width = '100%';
    } else {
      this.minWidth = this.labelControl?.offsetWidth;
      if (this.minWidth > 0) this.controlElement.style.minWidth = `${this.minWidth + 50}px`;
      if (this.width > 0) this.controlElement.style.minWidth = `${this.width + 50}px`;
    }
  }

  ngOnInit() {
    this.controlElement = this.inputWrapper.nativeElement.querySelector('input');
    if (!this.controlElement) {
      this.controlElement = this.inputWrapper.nativeElement.querySelector('select');
    }
    if (!this.controlElement) {
      this.controlElement = this.inputWrapper.nativeElement.querySelector('textarea');
    }

    // Get input element id and set it for use in the [attr.for] of the label
    if (this.controlElement.getAttribute('id')) {
      this.inputIdAttr = this.controlElement.getAttribute('id');
    }

    //  Float if the control value is a number and is greater than or equal to 0
    //  Float if the control value has a length and that length is greater than 0
    if (this.control) {
      this.isFloating =
        this.control.value &&
        (typeof this.control.value === 'number' ||
          (this.control.value.length && this.control.value.length > 0) ||
          !_isEmpty(this.control.value));

      this.control.valueChanges.pipe(takeUntil(this.stop$)).subscribe(data => {
        if (data) {
          this.isFloating = !data.length || data.length > 0;
        } else {
          /**
           * This condition is called if the control had value and a
           * reset is called on control with empty value.
           */
          this.isFloating = false;
        }
      });

      this.breakpointObserver
        .observe(['(max-width: 480px)', '(min-width: 481px)'])
        .pipe(
          takeUntil(this.stop$),
          tap(matched => {
            if (matched) {
              if (matched.breakpoints['(max-width: 480px)']) {
                (this.controlElement as HTMLElement).style.removeProperty('min-width');
                (this.controlElement as HTMLElement).style.width = '100%';
              } else {
                (this.controlElement as HTMLElement).style.removeProperty('width');
                this.setWidth();
              }
            }
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  get errorMessage() {
    if ((this.control && !this.control.pristine && this.control.touched) || this.validateOnSubmit) {
      for (const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName)) {
          return ErrorMessageService.getValidatorErrorMessage(
            propertyName,
            this.control.errors[propertyName]
          );
        }
      }
    }
    return null;
  }

  get allErrorMessages() {
    const messages = [];
    if (!this.control.pristine) {
      for (const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
          messages.push(
            ErrorMessageService.getValidatorErrorMessage(
              propertyName,
              this.control.errors[propertyName]
            )
          );
        }
      }
    }
    return messages;
  }
}
