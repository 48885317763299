import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

import { ConfigService, WindowRef } from '@amfam/shared/utility/shared-services';

import { AnalyticOptions } from '../models/analytics.model';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  constructor(
    private config: ConfigService,
    private win: WindowRef
  ) {}

  loadDataDog() {
    datadogRum.init({
      applicationId: this.config.get('datadogApplicationId'),
      clientToken: this.config.get('datadogClientToken'),
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'myaccount',
      env: this.config.get('env'),
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
  }

  sendActionWithAnalytic(options: AnalyticOptions) {
    if (options.event) {
      datadogRum.addAction(options.event.method, { step: options.event.event });
    }
    if (options.page) {
      datadogRum.addAction('pageview', { name: options.page.pageName });
    }
  }
}
