import { Component } from '@angular/core';

// parent component
@Component({
  selector: 'ds-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss']
})
export class OverviewCardComponent {}

// the below are all child components specific to `OverviewCardComponent`

// heading
@Component({
  selector: 'ds-overview-card-heading',
  template: `<ng-content></ng-content>`
})
export class OverviewCardHeadingComponent {}

// subheading
@Component({
  selector: 'ds-overview-card-subheading',
  template: `<ng-content></ng-content>`
})
export class OverviewCardSubheadingComponent {}

// content
@Component({
  selector: 'ds-overview-card-content',
  template: `<ng-content></ng-content>`
})
export class OverviewCardContentComponent {}

// cta
@Component({
  selector: 'ds-overview-card-cta',
  template: `<ng-content></ng-content>`
})
export class OverviewCardCtaComponent {}
