import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, take } from 'rxjs/operators';

import { PaymentMethodFeatureActions } from '@amfam/billing/payment-method/feature';
import {
  PaymentMethod,
  PaymentMethodAddEditOperationType,
  PaymentMethodAnalyticsModel
} from '@amfam/billing/payment-method/util';
import {
  PaymentAccountActions,
  PaymentAccountSelectors
} from '@amfam/billing/paymentaccount/data-access';
import { BillingPaymentPaths, DeletePaymentAccountRequestModel } from '@amfam/shared/models';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import {
  Applications,
  ApplicationService,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { PaymentMethodActions } from '../..';
import { SavePaymentMethodPayload } from '../models/financial-account.model';
import { AddEditPaymentMethodPayload } from '../models/payment-method.model';
import * as FinancialAccountActions from './financial-account/financial-account.actions';
import * as FinancialAccountSelectors from './financial-account/financial-account.selectors';
import * as PaymentMethodSelectors from './payment-method/payment-method.selectors';

@Injectable({ providedIn: 'root' })
export class PaymentMethodFacade {
  paymentMethods$ = this.store.select(PaymentMethodSelectors.selectPaymentMethods);
  financialInstitution$ = this.store.select(FinancialAccountSelectors.selectFinancialInstitution);
  hasError$ = this.store.select(PaymentMethodSelectors.selectHasAnyError);

  constructor(
    private store: Store,
    private utilService: UtilService,
    private appService: ApplicationService,
    private spinner: LoadingSpinnerService
  ) {}

  getFinancialInstitution(routingNumber: string) {
    if (!this.appService.isApp(Applications.MYACCOUNT_ADMIN)) {
      const correlationId: string = this.utilService.generateId();
      this.store.dispatch(FinancialAccountActions.getPCIAuthorizationToken({ correlationId }));
      this.store
        .select(FinancialAccountSelectors.selectFinancialAccountState)
        .pipe(
          filter(state => state.correlationId === correlationId),
          filter(state => !state.pciTokenError),
          take(1)
        )
        .subscribe(() => {
          this.store.dispatch(
            FinancialAccountActions.getFinancialInstitution({
              payload: { routingNumber },
              correlationId: this.utilService.generateId()
            })
          );
        });
    } else {
      this.store.dispatch(
        FinancialAccountActions.getFinancialInstitution({
          payload: { routingNumber },
          correlationId: this.utilService.generateId()
        })
      );
    }
  }

  savePaymentMethod(
    operation: PaymentMethodAddEditOperationType,
    savePayload: SavePaymentMethodPayload,
    addEditPayload: AddEditPaymentMethodPayload,
    paymentAccountId: string,
    correlationId: string
  ) {
    if (!this.appService.isApp(Applications.MYACCOUNT_ADMIN)) {
      const pciCorrelationId: string = this.utilService.generateId();
      this.store.dispatch(
        FinancialAccountActions.getPCIAuthorizationToken({ correlationId: pciCorrelationId })
      );
      this.store
        .select(FinancialAccountSelectors.selectFinancialAccountState)
        .pipe(
          filter(state => state.correlationId === pciCorrelationId),
          filter(state => !state.pciTokenError),
          take(1)
        )
        .subscribe(() => {
          this.store.dispatch(
            FinancialAccountActions.savePaymentMethod({
              paymentAccountId,
              savePayload,
              addEditPayload,
              operation,
              correlationId
            })
          );
        });
    } else {
      this.store.dispatch(
        FinancialAccountActions.savePaymentMethod({
          paymentAccountId,
          savePayload,
          addEditPayload,
          operation,
          correlationId
        })
      );
    }
  }

  deletePaymentMethod(paymentMethod: PaymentMethod) {
    this.spinner.start({ blockActions: true });

    // generate coorelation id
    const correlationId = this.utilService.generateId();
    let payload: DeletePaymentAccountRequestModel = {
      nickName: paymentMethod.nickName,
      paymentAccountId: paymentMethod?.paymentAccountId,
      correlationId: correlationId
    };

    if (paymentMethod.creditCard) {
      payload = Object.assign({}, payload, {
        creditCard: paymentMethod.creditCard
      });
    } else if (paymentMethod.achWithdrawal) {
      payload = Object.assign({}, payload, {
        bankAccount: paymentMethod.achWithdrawal
      });
    }

    // This will be removed in the next MR
    // eslint-disable-next-line ngrx/prefer-action-creator-in-dispatch
    this.store.dispatch(PaymentAccountActions.PaymentAccountsDelete(payload));
    this.store
      .select(PaymentAccountSelectors.selectPaymentAccounts(correlationId))
      .pipe(
        filter(paymentAccounts => paymentAccounts && paymentAccounts.length === 1),
        first()
      )
      .subscribe(paymentAccounts => {
        // set operation to fetch correct information on confirmation page
        if (!paymentAccounts[0].error) {
          this.store.dispatch(
            PaymentMethodFeatureActions.setPaymentMethodOperation({ operation: 'delete-success' })
          );
        } else {
          this.store.dispatch(
            PaymentMethodFeatureActions.setPaymentMethodOperation({ operation: 'delete-error' })
          );
        }
        this.spinner.stop();
        // route to confirmation
        this.store.dispatch(
          fromRouterActions.Go({
            path: [BillingPaymentPaths.CONFIRMATION_PAYMENT_METHOD]
          })
        );
      });
  }

  sendPaymentMethodAnalytics(analyticsData: PaymentMethodAnalyticsModel) {
    this.store.dispatch(
      PaymentMethodActions.sendPaymentMethodAnalytics({ payload: analyticsData })
    );
  }
}
