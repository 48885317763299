<h1>Is everything correct?</h1>
<p class="caption margin-top-20 margin-bottom-20">{{ reviewSettingsText }}</p>
<p class="subtitle">AutoPay settings</p>
@for (item of autoPayReviewItems$ | async; track item) {
  <ds-auto-pay-review-item
    data-cy="autoPayReviewItem"
    [config]="item"
    (editEvent)="edit(item.id)"
  ></ds-auto-pay-review-item>
}
<ds-payment-terms-and-conditions
  [(visible)]="showTermsAndConditionsModal"
  [isPartner]="isPartner$ | async"
  [paymentType]="termsAndConditionsType$ | async"
  [partnerContent]="termsAndConditionsPartnerContent$ | async"
  (agreeTermsAndConditionsEvent)="agreeTermsAndConditionsEvent($event)"
  data-cy="autopayTermsAndConditions"
>
</ds-payment-terms-and-conditions>

@if (adminEnrollmentFlow === false) {
  <div>
    <call-to-action
      [primaryButtonName]="'Save & Finish'"
      [displayBackButton]="true"
      [tertiaryButtonName]="tertiaryButtonName"
      [disablePrimaryButton]="(authorizedToSubmit$ | async) === false"
      (primaryEvent)="saveAndFinish()"
      (tertiaryEvent)="cancel()"
      (backEvent)="back()"
    >
    </call-to-action>
  </div>
}

@if (adminEnrollmentFlow) {
  <div>
    <call-to-action
      [primaryButtonName]="'Save & Finish'"
      [displayBackButton]="true"
      [disablePrimaryButton]="(authorizedToSubmit$ | async) === false"
      (primaryEvent)="saveAndFinish()"
      (backEvent)="back()"
      [tertiaryButtonName]="'Skip AutoPay Setup'"
      (tertiaryEvent)="skipAutopay()"
    >
    </call-to-action>
  </div>
}
