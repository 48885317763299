import { createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap as _flatMap, get as _get } from 'lodash';

import { ADD_VEHICLE_DATA_KEY, AddVehicleDataState } from '../add-vehicle-common.selector';

const selectNewVehicleFeaturesState =
  createFeatureSelector<AddVehicleDataState>(ADD_VEHICLE_DATA_KEY);

export const selectNewVehicleVin = createSelector(
  selectNewVehicleFeaturesState,
  (state: AddVehicleDataState) => state.addVehicleNewFeaturesState.entities[0]?.vin
);

export const selectVehicleInfoEntities = createSelector(
  selectNewVehicleFeaturesState,
  (state: AddVehicleDataState) => _get(state, 'addVehicleNewFeaturesState.entities')
);

export const selectVehicleFeaturesLoading = createSelector(
  selectVehicleInfoEntities,
  (state: AddVehicleDataState['addVehicleNewFeaturesState']['entities']) => {
    const tempState = Object.values(state)[0];
    return tempState ? tempState.loading : true;
  }
);

export const selectNewVehicleFeatures = createSelector(
  selectVehicleInfoEntities,
  selectVehicleFeaturesLoading,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleNewFeaturesState']['entities'][0]['features'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'features')
);

export const selectNewVehiclesFeaturesDescriptionList = createSelector(
  selectNewVehicleFeatures,
  featuresList => _flatMap(featuresList, feature => feature.description)
);

export const selectNewVehiclesFeaturesCodesList = createSelector(
  selectNewVehicleFeatures,
  featuresList => _flatMap(featuresList, feature => feature.typeOfFeatureCode)
);

export const selectNewVehicleFeaturesError = createSelector(
  selectVehicleInfoEntities,
  selectVehicleFeaturesLoading,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleNewFeaturesState']['entities'][0]['error'] => {
    if (!loading) {
      return _get(Object.values(userEntities)[0], 'error');
    }
  }
);

export const addVehicleFeatureQuery = {
  selectNewVehicleFeaturesState,
  selectNewVehicleVin,
  selectNewVehicleFeatures,
  selectNewVehiclesFeaturesDescriptionList,
  selectNewVehiclesFeaturesCodesList,
  selectNewVehicleFeaturesError
};
