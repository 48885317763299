import { Action } from '@ngrx/store';

import { AssociatedParty, AssociatedPartyInput } from './models';

export const LOAD = '[KydAssociatedParty] Load';
export const LOAD_SUCCESS = '[KydAssociatedParty] Load Success';
export const LOAD_COMPLETE = '[KydAssociatedParty] Load Complete';
export const LOAD_FAIL = '[KydAssociatedParty] Load Fail';
export const REFRESH = '[KydAssociatedParty] Refresh';

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload?: AssociatedPartyInput[]) {}
}

export class LoadCompleteAction implements Action {
  readonly type = LOAD_COMPLETE;

  constructor() {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: AssociatedParty[]) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;

  constructor(public payload?: any) {}
}

export class RefreshAction implements Action {
  readonly type = REFRESH;

  constructor(public payload?: AssociatedPartyInput[]) {}
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | LoadCompleteAction
  | RefreshAction;
