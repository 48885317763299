<ds-modal [id]="'paymentMethodModal'" [maxWidth]="800" [closeOthers]="true">
  <div class="modal-container">
    @if (
      paymentMethodAddEditConfig?.accountType === paymentMethodTypes.CHECKING ||
      paymentMethodAddEditConfig?.accountType === paymentMethodTypes.SAVINGS
    ) {
      <ds-bank-account-add-edit-wrapper
        [paymentMethodAddEditConfig]="paymentMethodAddEditConfig"
        [showTryAgainButton]="showTryAgainButton"
        (paymentMethodAddEditCompleteEvent)="paymentMethodAddEditCompleteEvent.emit($event)"
      >
      </ds-bank-account-add-edit-wrapper>
    }

    @if (paymentMethodAddEditConfig?.accountType === paymentMethodTypes.CREDIT_DEBIT) {
      <ds-credit-debit-add-edit-wrapper
        [paymentMethodAddEditConfig]="paymentMethodAddEditConfig"
        [displayDiscountWarning]="displayDiscountWarning"
        [showTryAgainButton]="showTryAgainButton"
        (paymentMethodAddEditCompleteEvent)="paymentMethodAddEditCompleteEvent.emit($event)"
      >
      </ds-credit-debit-add-edit-wrapper>
    }
  </div>
</ds-modal>
