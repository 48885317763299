import { Injectable } from '@angular/core';

import { Adapter } from '../models/adapter';
import { AddVehicleInitiateResponse } from '../models/add-vehicle-initiate.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleInitiateAdapterService implements Adapter<AddVehicleInitiateResponse> {
  adapt(item: AddVehicleInitiateResponse): AddVehicleInitiateResponse {
    const coverages = item.result.vehicleCoverages.filter(
      coverage => coverage.coverageCode !== 'PAAddlCustomVehicleCov_af'
    );

    const driversAdaptedAssignment = item.result.driverAssignments.map(driverAssignment => ({
      name: driverAssignment.driverName,
      publicId: String(driverAssignment.driverId),
      primary: driverAssignment.primary,
      vin: driverAssignment.vin,
      vehicleId: String(driverAssignment.vehicleId),
      driverId: String(driverAssignment.driverId)
    }));

    const adaptedObj = Object.assign({}, item, {
      result: {
        ...item.result,
        vehicleCoverages: coverages,
        driverAssignments: driversAdaptedAssignment
      }
    });
    return adaptedObj;
  }
}
