import { Policy } from '@amfam/policy/models';

import { KydEnrollmentRequest } from './kyd-enrollment-request.model';

export interface KydEnrollmentResponse {
  code: number;
  reason: string;
  enrollmentRequest: KydEnrollmentRequest;
  policy: Policy;
}
