@if (!showCodeEntry) {
  <div>
    <div class="row">
      <div class="column twelve text-left">
        <h3 content [contentPath]="'enrollment.verifyTextConfirm'"></h3>
        <p><span content [contentPath]="'enrollment.verifyTextContinueText'"></span></p>
      </div>
    </div>
    <ds-verify-phone
      [maskedPhoneNumbers]="phoneList"
      [usePhoneInput]="false"
      [buttonText]="'continue'"
      (submittedPhoneEvent)="sendCode($event)"
    >
    </ds-verify-phone>
    @if (gotError && !showCodeEntry) {
      <small
        class="block caption error margin-top-5"
        content
        [contentPath]="'enrollment.verifyEmailNotFound'"
      ></small>
    }
  </div>
}

@if (showCodeEntry) {
  <div>
    <h3 content [contentPath]="'enrollment.verifyTextConfirm'"></h3>
    <p>
      <span content [contentPath]="'enrollment.verifyTextSentPart1'"></span>{{ phone }}
      <span content [contentPath]="'enrollment.verifyTextSentPart2'"></span>
    </p>
    <p content [contentPath]="'enrollment.verifyPhoneCodeConfirm'"></p>
    <ds-verify-code-entry
      [confirmMethod]="'TEXT MESSAGE'"
      (submittedCodeEvent)="verifyCode($event)"
    >
    </ds-verify-code-entry>
    @if (codeNotFound) {
      <small
        data-cy="confirmationCodeNotFoundText"
        class="error-600-text margin-bottom-5"
        content
        [contentPath]="'enrollment.verifyPhoneInvalidText'"
      >
      </small>
    }
    <p class="caption">
      <button
        ds-button
        class="link"
        (click)="sendCode(phone)"
        content
        [contentPath]="'enrollment.verifyTextResend'"
      ></button>
    </p>
    @if (gotError) {
      <small
        class="block caption error margin-top-5"
        content
        [contentPath]="'enrollment.verifyEmailNotFound'"
      ></small>
    }
  </div>
}

<a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block margin-top-20 caption"
  ><span class="icon-chevron-left"></span> Back</a
>
