import { createSelector } from '@ngrx/store';

import { ClaimServicesState, getClaimServicesState } from '..';
import { RentalServiceState } from './rental-service.reducer';

export const getRentalServiceState = createSelector(
  getClaimServicesState,
  (state: ClaimServicesState) => state.rentalService
);

export const getRentalServiceLoading = createSelector(
  getRentalServiceState,
  (state: RentalServiceState) => state.loading
);

export const getRentalServiceError = createSelector(
  getRentalServiceState,
  (state: RentalServiceState) => state.error
);

export const getRentalServiceSelectedShop = createSelector(
  getRentalServiceState,
  (state: RentalServiceState) => state.selectedShop
);

export const rentalServiceQuery = {
  getRentalServiceState,
  getRentalServiceLoading,
  getRentalServiceError,
  getRentalServiceSelectedShop
};
