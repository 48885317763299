import { SearchResult } from '@amfam/claim/drp-search/feature';

import { RentalServiceState } from '../+state/rental-service/rental-service.reducer';

export class MockRentalServiceData {
  public static getMockRentalServiceState = function () {
    const rentalServiceState: RentalServiceState = {
      loading: false,
      error: false,
      selectedShop: selectedShop
    };
    return rentalServiceState;
  };
}

const selectedShop: SearchResult = {
  id: '3001325',
  name: 'Bergstrom Body Shop - Madison',
  address: {
    addressLine1: '1200 Applegate Rd',
    addressLine2: '',
    city: 'Madison',
    state: 'WI',
    zipCode: '53713'
  },
  contactMethodDetail: {
    primaryPhoneNumber: '(608) 276-5276',
    fax: '(608) 271-2266',
    emailAddresses: ['rjones@bergstromauto.com'],
    websiteAddresses: ['www.bergstrombodyshop.com/locations.html']
  },
  aseCertified: 'YES',
  hoursOfOperation: ['7:30 am - 5:30 pm'],
  tier: 'Tier1',
  distanceInfo: {
    distance: '10.3594686255609',
    units: 'MILES'
  },
  coordinates: {
    latitude: '43.031932',
    longitude: '-89.400778'
  }
};
