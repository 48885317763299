<div class="outage__container">
  <div class="info">
    <div class="info__heading" content contentPath="shared.totalOutage.heading"></div>
    <div class="info__content" content contentPath="shared.totalOutage.content"></div>
  </div>
  <div class="image">
    <img src="/assets/images/total_outage.png" alt="Total Outage Image" />
  </div>
</div>
<ds-footer [showContactInfo]="false" [partnerFooterLogo]="partnerFooterLogo$ | async"></ds-footer>
