import { PartyPhone } from '@amfam/shared/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';
import { USER_FEATURE_KEY, UserState } from './user.reducer';
// Lookup the 'User' feature state managed by NgRx
const getUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

const getBusinessName = createSelector(getUserState, (state: UserState) => state.businessName);
const getUserLoading = createSelector(getUserState, (state: UserState) => state.loading);
const getUserLoaded = createSelector(getUserState, (state: UserState) => state.loaded);

const getCustomerId = createSelector(getUserState, (state: UserState) => state.customerId);
const getEmailAddress = createSelector(getUserState, (state: UserState) => state.emailAddress);
const getExperienceId = createSelector(getUserState, (state: UserState) => state.experienceId);
const getFirstName = createSelector(getUserState, (state: UserState): string =>
  _get(state, 'party.firstName', _get(state, 'firstName', ''))
);
const getLastName = createSelector(getUserState, (state: UserState) =>
  _get(state, 'party.lastName', _get(state, 'lastName', ''))
);

export const getGreetingName = createSelector(getUserState, (state: UserState) => state.greetingName);

const getFirstAndLastName = createSelector(getFirstName, getLastName, (firstName, lastName) => {
  if (firstName || lastName) {
    return `${firstName} ${lastName}`;
  }
});
const getDisplayName = createSelector(getUserState, (state: UserState) =>
  _get(state, 'party.displayName', _get(state, 'displayName', ''))
);
const getLastLoggedInOn = createSelector(getUserState, (state: UserState) => state.lastLoggedInOn);
const getLoginName = createSelector(getUserState, (state: UserState) => state.loginName);
const getPartnerId = createSelector(getUserState, (state: UserState) => state.partnerId);
const getSmImpersonator = createSelector(getUserState, (state: UserState) => state.smImpersonator);
const getSmImpersonatorUserId = createSelector(
  getUserState,
  (state: UserState) => state.smImpersonatorUserID
);
const getTrackId = createSelector(getUserState, (state: UserState) => state.trackId);
const getTypeOfAccountCode = createSelector(
  getUserState,
  (state: UserState) => state.typeOfAccountCode
);
const getWaid = createSelector(getUserState, (state: UserState) => state.waid);
const isCustomer = createSelector(getUserState, (state: UserState) => state.isCustomer);
const getPartyDetails = createSelector(getUserState, (state: UserState) => state.party);
export const getUserStateCode = createSelector(getPartyDetails, (party): string =>
  _get(party, 'addresses[0].state', '')
);
export const getUserCity = createSelector(getPartyDetails, (party): string =>
  _get(party, 'addresses[0].city', '')
);
export const getPartyVersion = createSelector(getPartyDetails, party =>
  _get(party, 'partyVersion', '')
);
export const getCustomerIdAndLoginName = createSelector(
  getCustomerId,
  getLoginName,
  (customerId, loginName) => ({
    customerId,
    loginName
  })
);
const getEmails = createSelector(getUserState, (state: UserState) => state.party.emails);
const getPhones = createSelector(getUserState, (state: UserState) => state.party.phones);
const getAddress = createSelector(getUserState, (state: UserState) =>
  !state.party.addresses || !state.party.addresses.length
    ? undefined
    : state.party.addresses.find(address =>
        address.contactMethodUsages.some(usage => usage.typeOfResidencePurposeCode === 'PRIMARY')
      )
);

export const getPhoneWithAreaCode = createSelector(getPhones, phones =>
  phones.map(elem => elem.tenDigitPhoneNumber)
);

const getMyAccountEmail = createSelector(getUserState, (state: UserState) =>
  !state.party.emails || !state.party.emails.length
    ? undefined
    : state.party.emails.find(email =>
        email.contactMethodUsages.some(usage => usage.typeOfUsageCode === 'MYACCOUNT')
      )
);
const getPrimaryEmail = createSelector(getUserState, (state: UserState) =>
  !state.party.emails || !state.party.emails.length
    ? undefined
    : // if there is no primary phone return a placeholder email address
      state.party.emails.find(email => email.primaryIndicator) || undefined
);
const getPrimaryPhone = createSelector(getUserState, (state: UserState) =>
  !state.party.phones || !state.party.phones.length
    ? undefined
    : state.party.phones.find(email => email.primaryIndicator) || undefined
);

const getPreEnrollementPhoneNumberObj = createSelector(getUserState, (state: UserState) =>
  !state.party.phones || !state.party.phones.length
    ? undefined
    : state.party.phones.find(phone => phone.primaryIndicator) ||
      state.party.phones.find(phone => phone.contactMethodUsages[0].typeOfUsageCode === 'HOME') ||
      state.party.phones.find(phone => phone.contactMethodUsages[0].typeOfUsageCode === 'WORK')
);

const getPreEnrollementPhoneNumber = createSelector(
  getPreEnrollementPhoneNumberObj,
  (phone: PartyPhone) => {
    if (_get(phone, 'areaCode', '') !== '' && _get(phone, 'phoneNumber', '') !== '') {
      return _get(phone, 'areaCode') + _get(phone, 'phoneNumber');
    } else {
      return 'None';
    }
  }
);

const getCustomerInfo = createSelector(
  getCustomerId,
  getFirstName,
  getLastName,
  getPrimaryEmail,
  getMyAccountEmail,
  getGreetingName,
  (customerId, firstname, lastname, primaryEmail, accountEmail, greetingName) => ({
    customerId,
    firstname,
    lastname,
    primaryEmail: _get(primaryEmail, 'emailAddress'),
    accountEmail: _get(accountEmail, 'emailAddress'),
    greetingName
  })
);

const getZipCode = createSelector(getAddress, address => address.zip5);

const selectCUSTKEY = createSelector(getUserState, (state: UserState) => state.token);

export const userQuery = {
  getUserState,
  getUserCity,
  getBusinessName,
  getCustomerId,
  getEmailAddress,
  getExperienceId,
  getFirstName,
  getGreetingName,
  getLastName,
  getFirstAndLastName,
  getDisplayName,
  getLastLoggedInOn,
  getLoginName,
  getPartnerId,
  getSmImpersonator,
  getSmImpersonatorUserId,
  getTrackId,
  getTypeOfAccountCode,
  getWaid,
  isCustomer,
  getPartyDetails,
  getUserStateCode,
  getPartyVersion,
  getCustomerIdAndLoginName,
  getEmails,
  getPhones,
  getPhoneWithAreaCode,
  getAddress,
  getMyAccountEmail,
  getPrimaryEmail,
  getPrimaryPhone,
  getPreEnrollementPhoneNumber,
  getUserLoading,
  getUserLoaded,
  getCustomerInfo,
  getZipCode,
  selectCUSTKEY
};
