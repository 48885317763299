<div class="container pad-20">
  <div class="header">
    <img src="/assets/images/benefits.png" />
    <h2>Let's make sure we can help you online</h2>
  </div>
  <p class="pad-top-5">
    If any of the following are true, please check the box and an agent will help you get a quote.
  </p>
  <div class="form-container pad-top-20">
    <form [formGroup]="qualifersFormGroup">
      @if(productType === 'auto'){
      <div
        formArrayName="qualifiers"
        role="checkbox"
        class="checkbox-container"
        *ngFor="let qualifier of qualifiersFormArray.controls; let i = index"
      >
        <input
          [formControlName]="i"
          type="checkbox"
          id="{{ i }}"
          name="{{ i }}"
          (keydown.enter)="onEnterKeyPressed(i)"
        />
        <label for="{{ i }}">{{ qualifierOptions[i].text }}</label>
        <button
          class="info__button"
          data-cy="infoButton"
          triggerOn="click"
          [heading]="qualifierOptions[i].tooltipContent.heading"
          [tooltip]="qualifierOptions[i].tooltipContent.body"
          [showCloseButton]="true"
          [closeOnClickingOutside]="false"
          [closeOnEscape]="true"
        >
          <i class="icon-info"></i>
        </button>
      </div>
      } @if(productType === 'prop' && type==='renters'){
      <div id="renters">
        <div>
          <label for="maritalStatus">Marital Status:</label>
          <select formControlName="maritalStatus" id="maritalStatus">
            <option value="MARRIED">Married</option>
            <option value="SEPARATED">Married (Separated)</option>
            <option value="DIVORCED">Not Married (Divorced)</option>
            <option value="NEVER_MARRIED">Not Married (Never Married)</option>
            <option value="WIDOWED">Not Married (Widowed)</option>
          </select>
        </div>

        <div>
          <label for="residenceType">Residence Type</label>
          <select formControlName="residenceType" id="residenceType">
            <option value="SingleFamily">Single Family Dwelling</option>
            <option value="Duplex">Duplex</option>
            <option value="Apt">Apartment Complex</option>
            <option value="CondoCoop">Condominium/Co-op</option>
            <option value="TownRow">
              Single Family Unit within a Townhouse/Rowhouse
            </option>
            <option value="ManufacturedHome">Manufactured Home</option>
          </select>
        </div>
        <div>
          <label
            ><input type="checkbox" formControlName="runBusiness" /> Do you currently, or plan to,
            run a business or daycare out of your residence?</label
          >
        </div>
        <div>
          <label
            ><input type="checkbox" formControlName="ownItems" /> Do you currently own any
            individual items of high value?</label
          >
        </div>
        <div>
          <label for="textbox"
            >Including yourself, how many total occupants live at your residence?</label
          >
          <input type="number" id="textbox" formControlName="occupantsCount" />
        </div>
      </div>
      }
    </form>
  </div>
  <div class="cta-buttons">
    <call-to-action
      primaryButtonName="Continue"
      secondaryButtonName="Cancel"
      [fullWidthButtons]="false"
      [capitalize]="true"
      (primaryEvent)="continue()"
      (secondaryEvent)="cancel()"
      [equalWidthButtons]="true"
      [disablePrimaryButton]="isOpportunityQuoted$ | async"
    >
    </call-to-action>
  </div>
</div>
