import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyfilter'
})
export class CurrencyFilterPipe implements PipeTransform {
  transform(currency: number | string, type: string): string {
    if (!currency) return '';
    const amount = String(currency)?.split('.');
    return type === 'dollar' ? amount[0] || '0' : amount[1] || '00';
  }
}
