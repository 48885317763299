import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AddVehicleInfoState } from '../../models/add-vehicle-initiate.model';
import * as AddVehicleInfoActions from './add-vehicle-info.actions';

export type State = EntityState<AddVehicleInfoState>;

export const adapter: EntityAdapter<AddVehicleInfoState> = createEntityAdapter<AddVehicleInfoState>(
  {
    selectId: state => state.policyNumber
  }
);

export const initialState: State = adapter.getInitialState({ error: null, success: null });

const userReducer = createReducer(
  initialState,

  on(AddVehicleInfoActions.addVehicleInitiate, (state, action) =>
    adapter.upsertOne({ ...action.payload.request, status: null, loading: true }, state)
  ),

  on(AddVehicleInfoActions.addVehicleInitiateSuccess, (state, initiateResponse) => {
    // Convert publicId to string, which is required for Rate call
    initiateResponse.payload.result.tpis.forEach(tpi => (tpi.publicId = tpi.publicId.toString()));
    const responseResult = Object.assign({}, initiateResponse.payload.result, {
      policyNumber: initiateResponse.payload.result.policyNumber.replace(/-/g, '')
    });
    return adapter.upsertOne(
      {
        ...responseResult,
        status: initiateResponse.payload.status,
        error: null,
        loading: false
      },
      state
    );
  }),
  on(AddVehicleInfoActions.addVehicleInitiateError, (state, initiateFailureResponse) =>
    adapter.upsertOne(
      {
        ...initiateFailureResponse.payload.request,
        status: initiateFailureResponse.payload.status,
        error: initiateFailureResponse.payload.status,
        loading: false
      },
      state
    )
  ),
  on(AddVehicleInfoActions.addVehicleResetError, state =>
    state.ids && state.ids.length > 0
      ? adapter.updateOne(
          {
            id: state.ids[0].toString(),
            changes: {
              status: null,
              error: null
            }
          },
          state
        )
      : initialState
  ),
  on(
    AddVehicleInfoActions.addVehicleTPMAvailibilityCheckSuccess,
    (state, addVehicleTPMAvailibilityCheckSuccessPayload) =>
      adapter.upsertOne(
        {
          policyNumber: addVehicleTPMAvailibilityCheckSuccessPayload.payload.policyNumber,
          status:
            state.entities[addVehicleTPMAvailibilityCheckSuccessPayload.payload.policyNumber]
              .status,
          loading: false,
          hasTPMPackage: addVehicleTPMAvailibilityCheckSuccessPayload.payload.hasTPMPackage
        },
        state
      )
  ),
  on(
    AddVehicleInfoActions.addVehicleTPMAvailibilityCheckError,
    (state, addVehicleTPMAvailibilityCheckErrorPayload) =>
      adapter.upsertOne(
        {
          policyNumber: addVehicleTPMAvailibilityCheckErrorPayload.payload.policyNumber,
          status:
            state.entities[addVehicleTPMAvailibilityCheckErrorPayload.payload.policyNumber].status,
          loading: false,
          hasTPMPackage: addVehicleTPMAvailibilityCheckErrorPayload.payload.hasTPMPackage
        },
        state
      )
  )
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectNewVehiclePolicyNumbers = selectIds;

export const selectNewVehicleInfoEntities = selectEntities;

export const selectAllNewVehicleInfoList = selectAll;

export const selectNewVehicleInfoListCount = selectTotal;
