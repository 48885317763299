import { format, isAfter, isValid } from 'date-fns';
import { get as _get, has as _has, reduce as _reduce, some as _some } from 'lodash';

import {
  BillingEntityState,
  BillingNotificationsState
} from '@amfam/billing/billaccount/data-access';
import {
  AutoPolicy,
  GenericProductType,
  Policy,
  PropertyPolicy,
  Vehicle
} from '@amfam/policy/models';
import {
  Alert,
  AlertLevels,
  AlertTypes,
  AutomaticPayment,
  BillAccount,
  BillAccountModel,
  DeliveryPreferences,
  ONLINE_BILLING,
  Party,
  PaymentAccount,
  ScheduledPayment
} from '@amfam/shared/models';
import { Partners } from '@amfam/shared/utility/brand';
import { KydSmartphoneEnrolledOperatorModel } from '../programs/kyd-smartphone/models/kyd-smartphone-enrolled-operator.model';
import { KydSmartphoneSummary } from '../programs/kyd-smartphone/models/kyd-smartphone-summary.model';
import { KydVehicleEligibility, PolicyEnrollment } from '../programs/kyd/models';

export const initialAlert: Alert = {
  level: AlertLevels.UNKNOWN,
  type: AlertTypes.UNKNOWN,
  consolidated: false
};

const findBillingAccountForPolicy = (policy: Policy, billAccounts: BillAccount[]): BillAccount =>
  billAccounts.find(billAccount => {
    if (!billAccount.policyList) {
      return false;
    }

    return !!billAccount.policyList.find(
      billAccountPolicy => policy.policyNumber === billAccountPolicy.policyNumber
    );
  });

const policiesWithPaperlessDiscount = (policies: Policy[]): Policy[] => {
  let autoPolicies: Policy[] = [];
  let propPolicies: Policy[] = [];

  autoPolicies = policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Auto)
    .filter(
      (policy: AutoPolicy) =>
        !!policy.vehicles.find(
          vehicle =>
            !!vehicle.vehicleDiscounts.find(discount => discount.code === 'PAGreenAutoDisc_af')
        )
    );

  propPolicies = policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Home)
    .filter(
      (policy: PropertyPolicy) =>
        // find policies with the autopay discount
        !!policy.policyDiscounts.find(discount => discount.code === 'HOGreenLifestyleDisc_af')
    );
  const combPolicies = propPolicies.concat(autoPolicies);

  return combPolicies;
};

const policiesWithKydDiscount = (policies: Policy[]): Policy[] =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Auto)
    .filter((policy: AutoPolicy) =>
      policy.vehicles.some(vehicle =>
        vehicle.vehicleDiscounts.some(discount => discount.code === 'PAUBIIntroductoryDisc_af')
      )
    );

export const homesitePoliciesAlert = (showBanner: boolean): Alert[] => {
  const systemAlerts = [];
  if (showBanner) {
    systemAlerts.push(
      Object.assign({}, initialAlert, {
        level: AlertLevels.HOMESITE,
        type: AlertTypes.POLICIES_MANAGED_EXTERNALLY
      })
    );
  }
  return systemAlerts;
};

const policiesWithKydSmartphoneDiscount = (policies: Policy[]): Policy[] =>
  policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Auto)
    .filter((policy: AutoPolicy) =>
      policy.vehicles.some(vehicle =>
        vehicle.vehicleDiscounts.some(
          discount => discount.code === 'PAUBISmartphoneIntroductoryDisc_af'
        )
      )
    );

/*
  Derive each bill account is having a scheduled payment or not and proceed further to display/ hide bill over due alert.
*/
const billAccountWithScheduledPayment = (
  billAccount: BillAccount,
  scheduledPayments: ScheduledPayment[]
): boolean =>
  !!scheduledPayments.find(payment =>
    _some(payment.billAccounts, {
      billAccountNumber: billAccount.billAccountNumber
    })
  );

/*
  Derive each bill account is having a automatic payment or not and proceed further to display/ hide bill over due alert.
*/
const billAccountWithAutoPay = (
  billAccount: BillAccount,
  automaticPayments: AutomaticPayment[]
): boolean =>
  !!automaticPayments.find(payment => billAccount.billAccountNumber === payment?.billAccountNumber);
// Requirement BR-03
export const getBillOverdueAlerts = (
  state: BillAccount[],
  payments: ScheduledPayment[],
  automaticPayments: AutomaticPayment[],
  billOverdueAlertsLoading
): Alert[] => {
  /*
    Find the bill account/s with past due status
    1. Return to /billing for multiple bill accounts.
    2. Return to /billing/id for single account.
    3. Return to /billing/register for single account and not registered.
  */
  if (billOverdueAlertsLoading) {
    return [];
  }
  let filteredArr = [];
  if (state.length > 0) {
    filteredArr = _reduce(
      state,
      (res, billAccount) => {
        const minimumAmountDue = Number(billAccount.minimumAmountDue);
        const isRegisteredElsewhere =
          String(_get(billAccount, 'registeredElsewhere', '')) === 'true';
        if (
          minimumAmountDue > 0 &&
          billAccount.pastDue === true &&
          !billAccountWithScheduledPayment(billAccount, payments) &&
          !isRegisteredElsewhere &&
          !billAccountWithAutoPay(billAccount, automaticPayments)
        ) {
          res.push(billAccount);
        }
        return res;
      },
      []
    );
  }

  return filteredArr.map(billAccount => {
    if (filteredArr) {
      if (filteredArr.length > 1) {
        return Object.assign({}, initialAlert, {
          level: AlertLevels.HIGH,
          type: AlertTypes.MULTIPLE_BILLS_OVERDUE,
          data: {
            billAccounts: filteredArr
          }
        });
      } else if (filteredArr.length === 1) {
        return Object.assign({}, initialAlert, {
          level: AlertLevels.HIGH,
          type: AlertTypes.BILL_OVERDUE,
          data: {
            billAccounts: filteredArr
          }
        });
      }
    }
  });
};

/*
  For each bill account, determine if the most recent item is a failed payment of any paymentMethod.

  1. Get find the most recent failed payment that this bill account was a part of, if any. Early out if no failed payment found
  2. Iterate SUCCESSFUL payments of all types along with SCHEDULED and AUTHORIZED Online Billing payments.
      Locate the most recent payment associated to that bill account, if any. For SUCCESSFUL payments, used the recievedDate
      field as the date value, for AUTHORIZED or SCHEDULED payments look at the lastUpdateTimestamp. pending payments
      with a date starting 0001 are treated as newest.
  3. Iterate the automatic payments to find the most recent autopay rule associated t that bill account, if any
  4. Determine if there are any payments or autopay rules that are newer than the failed payment - if not, show the alert
*/
export const getUnsuccessfulPaymentAlerts = (
  billAccounts: BillAccount[],
  pendingAndSuccessfulPayments: ScheduledPayment[],
  autopayRules: AutomaticPayment[],
  failedPayments: ScheduledPayment[],
  loading: boolean
) => {
  // early out if loading
  if (loading) {
    return [];
  }

  const alertableBillAccounts = [];
  const alertablePayments = [];

  let onlyOneAFTUnsuccessful = false;
  // Determine for each bill account if there is an unsuccessful payment and if there are any payment actions made after that point
  billAccounts.forEach(ba => {
    // Skip bill accounts that are registered elsewhere
    if (ba.registeredElsewhere) {
      return;
    }

    // Skip bill accounts that do not have an account balance, or if the account balance is negative.
    if (Number(ba.accountBalance) <= 0) {
      return;
    }

    // Determine if there is a failed payment for this bill account
    const matchingFailedPayments = [];
    failedPayments.forEach(payment => {
      _get(payment, 'billAccounts', [] as BillAccountModel[]).forEach(billAccountForPayment => {
        if (_get(billAccountForPayment, 'billAccountNumber', '') === ba.billAccountNumber) {
          matchingFailedPayments.push(payment);
        }
      });
    });

    // Early out of evaluating this bill account if there are no failed payments
    if (matchingFailedPayments.length === 0) {
      return;
    }

    // Order failed payments by most recent first
    matchingFailedPayments
      .sort((a, b) => String(a['receivedDate']).localeCompare(String(b['receivedDate'])))
      .reverse();

    // Grab the most recent failed payment ordered by receivedDate (or empty string if not found)
    const mostRecentFailedPaymentDate = String(_get(matchingFailedPayments[0], 'receivedDate', ''));

    // Determine if there are any pending payments for this bill account
    const matchingPayments = [];
    pendingAndSuccessfulPayments.forEach(payment => {
      const billAccountsForPayment: BillAccountModel[] = _get(
        payment,
        'billAccounts',
        [] as BillAccountModel[]
      );
      billAccountsForPayment.forEach(billAccountForPayment => {
        if (_get(billAccountForPayment, 'billAccountNumber', '') === ba.billAccountNumber) {
          matchingPayments.push(payment);
        }
      });
    });
    const defaultDate = '9999-01-01T00:00:00.000-0600';
    const initialDate = '0001-01-01';
    const ISODateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

    // Order pending payments by most recent first
    matchingPayments
      .sort((a, b) => {
        // if the payment is SUCCESSFUL, we need to sort on the receivedDate field. For pending payments we sort on lastUpdateTimestamp
        // If a lastUpdateTimestamp starts with 0001-01-01 treat it as the most recent payment, so cast it to the year 9999
        const dateA =
          a.paymentStatus === 'SUCCESSFUL'
            ? _get(a, 'receivedDate', '')
            : _get(a, 'lastUpdateTimestamp', '').startsWith(initialDate)
              ? defaultDate
              : a.lastUpdateTimestamp;

        const dateB =
          b.paymentStatus === 'SUCCESSFUL'
            ? _get(b, 'receivedDate', '')
            : _get(b, 'lastUpdateTimestamp', '').startsWith(initialDate)
              ? defaultDate
              : b.lastUpdateTimestamp;
        return String(dateA).localeCompare(String(dateB));
      })
      .reverse();

    // Grab the most recent matching payment by lastUpdateTimestamp. Payments starting with 0001 are new and
    // need to be treated as the newest so we cast them to the year 9999
    const mostRecentScheduledDate = _get(matchingPayments[0], 'lastUpdateTimestamp', '').startsWith(
      initialDate
    )
      ? defaultDate
      : _get(matchingPayments[0], 'lastUpdateTimestamp', '');

    // Determine if there are any autopay rules for this bill account.
    // This currently will always be 1 autopay rule per bill account but that may change
    const matchingAutopay = autopayRules.filter(
      autopay => _get(autopay, 'billAccountNumber') === ba.billAccountNumber
    );

    // Order autopay rules by most recent
    matchingAutopay
      .sort((a, b) => {
        // If the lastUpdateTimestamp starts with 0001-01-01 the treat it as the most recent payment
        const dateA = _get(a, 'lastUpdateTimestamp', '').startsWith(initialDate)
          ? defaultDate
          : a.lastUpdateTimestamp;
        const dateB = _get(b, 'lastUpdateTimestamp', '').startsWith(initialDate)
          ? defaultDate
          : b.lastUpdateTimestamp;
        return String(dateA).localeCompare(String(dateB));
      })
      .reverse();

    // Grab the most recent autopay rule by lastUpdateTimestamp. Autopay rules with a lastUpdateTimestamp starting with 0001 are new and
    // need to be treated as the newest item so we cast them to the year 9999
    const mostRecentAutopayDate = _get(matchingAutopay[0], 'lastUpdateTimestamp', '').startsWith(
      initialDate
    )
      ? defaultDate
      : _get(matchingAutopay[0], 'lastUpdateTimestamp', '');

    // Determine whether to show an alert for this bill account:
    // if the failed payment is valid and there is a more recent scheduled payment or automatic payment, suppress

    // Date() function in safari throws an error if the timezone offset field doesn't have a ":"
    // Ensure the dates are in the ISO format before passing to the Date() function.
    const failedPaymentDate = format(mostRecentFailedPaymentDate, ISODateFormat);
    const scheduledDate = format(mostRecentScheduledDate, ISODateFormat);
    const autopayDate = format(mostRecentAutopayDate, ISODateFormat);
    if (isValid(new Date(failedPaymentDate))) {
      if (
        (isValid(new Date(scheduledDate)) &&
          isAfter(mostRecentScheduledDate, mostRecentFailedPaymentDate)) ||
        (isValid(new Date(autopayDate)) &&
          isAfter(mostRecentAutopayDate, mostRecentFailedPaymentDate))
      ) {
        // Suppress the alert because of the scheduled payment or autopay rule
      } else {
        alertableBillAccounts.push(ba);
        // Make sure the payment isn't already being alerted on. This can happen for bundled payments
        const matchingAlertablePayment = alertablePayments.find(
          payment => payment.paymentId === _get(matchingFailedPayments[0], 'paymentId')
        );
        if (!matchingAlertablePayment) {
          if (ba.billingMethod === 'AFT') {
            onlyOneAFTUnsuccessful = true;
          }
          alertablePayments.push(matchingFailedPayments[0]);
        }
      }
    }
  });

  // Sending any alertable payments and all bill account data associated to them
  if (alertablePayments.length > 0) {
    let alertType;
    let data;

    if (alertablePayments.length === 1) {
      alertType = onlyOneAFTUnsuccessful
        ? AlertTypes.UNSUCCESSFUL_AFT_PAYMENT
        : AlertTypes.UNSUCCESSFUL_PAYMENT;
      let nameReference;
      if (alertableBillAccounts.length === 1) {
        nameReference = _get(
          alertableBillAccounts[0],
          'billingPreferences.accountNickName',
          _get(alertableBillAccounts[0], 'billAccountNumber', 'one of your billing accounts')
        );
      } else {
        nameReference = 'multiple billing accounts';
      }
      data = {
        billAccounts: alertableBillAccounts,
        payments: alertablePayments,
        paymentAmount: '$' + alertablePayments[0].totalPaymentAmount,
        nameReference: nameReference,
        paymentDate: format(alertablePayments[0].receivedDate, 'MM/DD/YYYY')
      };
    } else {
      alertType = AlertTypes.MULTIPLE_UNSUCCESSFUL_PAYMENTS;
      data = {
        billAccounts: alertableBillAccounts,
        payments: alertablePayments
      };
    }

    return [
      Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: alertType,
        data: data
      })
    ];
  } else {
    return [];
  }
};

// Requirement BR-06
export const getAutoPayAlerts = (
  billAccounts: BillAccount[],
  policies: Policy[],
  automaticPayments: AutomaticPayment[],
  paymentAccounts: PaymentAccount[],
  autoPayAlertsLoading
): Alert[] => {
  let autoPolicies: Policy[] = [];
  let propPolicies: Policy[] = [];
  let achPaymentTypes = false;
  let automaticPaymentRulesSetups = false;
  let numEditPayment = 0;
  if (autoPayAlertsLoading) {
    return [];
  }
  autoPolicies = policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Auto)
    .filter(
      (policy: AutoPolicy) =>
        // find policies with the autopay discount
        !!policy.vehicles.find(
          vehicle =>
            !!vehicle.vehicleDiscounts.find(discount => discount.code === 'PAAutoPayVehDisc_af')
        )
    );

  propPolicies = policies
    .filter(policy => policy.generalizedProductType === GenericProductType.Home)
    .filter(
      (policy: PropertyPolicy) =>
        // find policies with the autopay discount
        !!policy.policyDiscounts.find(
          discount =>
            !!policy.dwellingRoles.find(
              dwellingRole =>
                discount.code === 'HOAFTDisc_af' &&
                _get(dwellingRole, 'thirdPartyInterestType.length', 0) !== 0
            )
        )
    );

  const combPolicies = autoPolicies.concat(propPolicies);

  const autopayBillAccounts = combPolicies
    .map(policy =>
      billAccounts.find(
        account =>
          // find the bill account associated with the policy
          !!account.policyList.find(
            billAccountPolicy => policy.policyNumber === billAccountPolicy.policyNumber
          )
      )
    )
    .filter(billAccount => {
      if (!billAccount || billAccount.registeredElsewhere) {
        // there's a _rare_ case where a policy will not have a bill account
        // we don't have enough information in this scenario to accurately
        // detect an autopay alert, so just filter this away.
        return false;
      }
      const automaticPaymentRulesSetup = billAccountWithAutoPay(billAccount, automaticPayments);
      let achPaymentType;
      if (automaticPaymentRulesSetup) {
        achPaymentType = !!paymentAccounts.find(
          paymentAccount =>
            !!automaticPayments.find(
              autoPayment =>
                _has(paymentAccount, 'achWithdrawal') &&
                paymentAccount.nickName === autoPayment.paymentAccount.nickName &&
                autoPayment.billAccountNumber === billAccount.billAccountNumber
            )
        );
      }

      const accountIsAFT = billAccount.billingMethod === 'AFT';
      if (!((automaticPaymentRulesSetup && achPaymentType) || accountIsAFT)) {
        achPaymentTypes = achPaymentType;
        automaticPaymentRulesSetups = automaticPaymentRulesSetup;
        numEditPayment++;
      }
      return !((automaticPaymentRulesSetup && achPaymentType) || accountIsAFT);
    });

  if (autopayBillAccounts.length > 1) {
    if (
      automaticPaymentRulesSetups &&
      !achPaymentTypes &&
      numEditPayment === autopayBillAccounts.length
    ) {
      return [
        Object.assign({}, initialAlert, {
          level: AlertLevels.HIGH,
          type: AlertTypes.MULTIPLE_AUTOPAY_EDIT
        })
      ];
    } else {
      return [
        Object.assign({}, initialAlert, {
          level: AlertLevels.HIGH,
          type: AlertTypes.MULTIPLE_BILLS_AUTOPAY_DISCOUNT
        })
      ];
    }
  }

  return autopayBillAccounts.map(billAccount => {
    if (billAccount.billingMethod !== ONLINE_BILLING) {
      return Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: AlertTypes.PREREQUISITE_NEEDED_AUTOPAY_DISCOUNT,
        data: {
          associated: billAccount.associated,
          billAccountNumber: billAccount.billAccountNumber,
          policyNumber: billAccount.policyList[0].policyNumber
        }
      });
    }
    // Cusotmer set up autopay with CC/DC navigate to edit auto pay flow.
    if (automaticPaymentRulesSetups && !achPaymentTypes) {
      return Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: AlertTypes.AUTOPAY_EDIT,
        data: {
          accountNumber: billAccount.billAccountNumber
        }
      });
    }

    return Object.assign({}, initialAlert, {
      level: AlertLevels.HIGH,
      type: AlertTypes.AUTOPAY_DISCOUNT,
      data: {
        accountNumber: billAccount.billAccountNumber,
        registeredElseWhere: billAccount.registeredElsewhere
      }
    });
  });
};

// Requirement BR-04
export const getPaperlessPolicyAlerts = (policies: Policy[], party: Party): Alert[] => {
  const paperlessPolicies = policiesWithPaperlessDiscount(policies).filter(policy => {
    if (party.typeOfEdeliveryStatusCode) {
      return party.typeOfEdeliveryStatusCode !== 'OPTED IN';
    }
    return false;
  });

  if (paperlessPolicies) {
    return paperlessPolicies.map(policy =>
      Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: AlertTypes.PAPERLESS_POLICY_DISCOUNT
      })
    );
  }
};
export const getCaMileagePolicyAlert = (
  isFlagEnabled: boolean,
  policies: Policy[],
  partnerId: string
): Alert[] => {
  const caPolicy = policies.find(policy => policy.policyAddress.state === 'CA');
  const policyNumber = caPolicy?.policyNumber;
  const systemAlerts = [];
  const partnersAvailableForCaMileage = [Partners.COSTCO_ECP];
  const isAvailableForPartner = partnersAvailableForCaMileage.includes(partnerId as Partners);

  if (isFlagEnabled && !!caPolicy && isAvailableForPartner) {
    systemAlerts.push({
      ...initialAlert,
      level: AlertLevels.WARNING,
      type: AlertTypes.CA_MILEAGE,
      data: {
        policyNumber
      }
    });
  }
  return systemAlerts;
};

// Get system banner information from Sitecore.
export const getSystemStableAlert = (showBanner: boolean): Alert[] => {
  const systemAlerts = [];
  if (showBanner) {
    systemAlerts.push(
      Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: AlertTypes.SYSTEM_OUTAGE
      })
    );
  }
  return systemAlerts;
};

// Requirement BR-05
export const getPaperlessBillingAlerts = (
  billAccounts: BillAccount[],
  policies: Policy[]
): Alert[] => {
  const paperlessBilling = policiesWithPaperlessDiscount(policies)
    .map(policy => findBillingAccountForPolicy(policy, billAccounts))
    .filter(billAccount => {
      if (!billAccount || billAccount.registeredElsewhere) {
        return false;
      }
      // Check if email is not selected preference to determine paperless billing alert with PCM project.
      if (_get(billAccount, 'billingPreferences.preferences')) {
        return billAccount.billingPreferences.preferences.find(
          preference =>
            preference.preferenceDeliveryCode.toLowerCase() !== DeliveryPreferences.EMAIL
        );
      } else {
        return billAccount.billingMethod !== ONLINE_BILLING;
      }
    });

  if (paperlessBilling) {
    return paperlessBilling.map(billingAccount => {
      if (paperlessBilling.length > 1) {
        return Object.assign({}, initialAlert, {
          level: AlertLevels.HIGH,
          type: AlertTypes.MULTIPLE_PAPERLESS_BILLING_DISCOUNT
        });
      } else if (paperlessBilling.length === 1) {
        return Object.assign({}, initialAlert, {
          level: AlertLevels.HIGH,
          type: AlertTypes.PAPERLESS_BILLING_DISCOUNT,
          data: {
            associated: billingAccount.associated,
            billAccountNumber: billingAccount.billAccountNumber,
            policyNumber: billingAccount.policyList[0].policyNumber
          }
        });
      }
    });
  }
};

/*
 * show kyd high alert if no vehicles are enrolled and kyd discount is applied on policy
 */

export const getKydAlerts = (
  policies: Policy[],
  kydEnrollments: PolicyEnrollment[],
  finishedLoading: boolean,
  kydVehicleEligibility: KydVehicleEligibility[]
): Alert[] => {
  if (!finishedLoading) {
    return [];
  }

  return policiesWithKydDiscount(policies)
    .filter(policy => {
      // get kyd policy enrollments
      const policyKydEnrollments = kydEnrollments.filter(
        kydEnrollment => kydEnrollment.policyNumber === policy.policyNumber
      );

      // get enrolled vehicles from policy enrollments
      const enrolledVehicles = policyKydEnrollments
        .map(kydEnrollment => kydEnrollment.enrolledVehicles)
        // create flat enrolled vehicle array from policy enrollments
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .reduce((accumulator, _enrolledVehicles) => accumulator.concat(_enrolledVehicles), [])
        // filter enrolled vehicles
        .filter(
          enrolledVehicle =>
            enrolledVehicle.enrollmentSummary &&
            (enrolledVehicle.enrollmentSummary.enrollmentStatus === 'Enrolled' ||
              enrolledVehicle.enrollmentSummary.enrollmentStatus === 'Waiting Period')
        );

      // check if vehicle is enrolled by matching the discount applied policy
      const isEnrolledKyd = enrolledVehicles.some(enrolledVehicle =>
        policy.policyRisks.some(
          (vehicle: Vehicle) => vehicle.vinSerialNumber === enrolledVehicle.vin
        )
      );

      const isEligibleForKyd = enrolledVehicles.some(enrolledVehicle =>
        kydVehicleEligibility.some(
          vehicleEligibility => vehicleEligibility.vin === enrolledVehicle.vin
        )
      );

      return !isEnrolledKyd && isEligibleForKyd;
    })
    .map(policy =>
      Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: AlertTypes.KYD_DISCOUNT
      })
    );
};

export const getKydSmartphoneAlerts = (
  kydSmartphonePolicies: AutoPolicy[],
  kydSmartphoneEnrollments: KydSmartphoneSummary[],
  finishedLoading: boolean
): Alert[] => {
  if (!finishedLoading) {
    return [];
  }

  return policiesWithKydSmartphoneDiscount(kydSmartphonePolicies)
    .map(policy => {
      const isEnrolledKydSmartphone = kydSmartphoneEnrollments.some(kydSmartphoneEnrollment => {
        const status: string = _get(kydSmartphoneEnrollment, 'enrollmentStatus', '');
        return (
          policy.policyNumber === kydSmartphoneEnrollment.policyNumber &&
          (status === 'Enrolled' || status === 'Waiting Period' || status === 'Opted-Out')
        );
      });
      const allOperatorsHaveEnrolledPhone = kydSmartphoneEnrollments.some(
        kydSmartphoneEnrollment => {
          const status: string = _get(kydSmartphoneEnrollment, 'enrollmentStatus', '');
          const operators: KydSmartphoneEnrolledOperatorModel[] = _get(
            kydSmartphoneEnrollment,
            'enrolledOperators',
            [] as KydSmartphoneEnrolledOperatorModel[]
          );
          return (
            policy.policyNumber === kydSmartphoneEnrollment.policyNumber &&
            (status === 'Enrolled' || status === 'Waiting Period' || status === 'Opted-Out') &&
            operators.every(operator => !!_get(operator, 'contactDetail.phone'))
          );
        }
      );

      return {
        isNotEnrolled: !isEnrolledKydSmartphone,
        missingPhone: !allOperatorsHaveEnrolledPhone
      };
    })
    .filter(data => data.isNotEnrolled || data.missingPhone)
    .map(data => {
      const alertType = data.isNotEnrolled
        ? AlertTypes.KYD_SMARTPHONE_DISCOUNT
        : AlertTypes.KYD_SMARTPHONE_OPERATOR_DISCOUNT;
      return Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: alertType
      });
    });
};

export const getConsolidatedAlert = (alerts: any[]): Alert[] => {
  // JG: this function _always_ returns an array
  // because when we do [...alert1, ...alert2] in
  // thegetConsolidatedAlerts selector, the syntax
  // automatically excludes empty arrays

  if (alerts.length < 2) {
    // case 0: return the empty array we were given
    // case 1: return the single alert array
    // in either case, we don't need/want to consolidate.
    return alerts;
  }

  return [
    Object.assign({}, initialAlert, {
      type: alerts[0].type,
      level: alerts[0].level,
      consolidated: true,
      data: alerts[0].data
    })
  ];
};

export const getPaperLessEnrollmentErrorAlerts = (
  billAccounts: BillingNotificationsState[],
  goPaperLessPolicyError: boolean
): Alert[] => {
  if (
    _some(billAccounts, {
      updateDeliveryPreferencesError: true
    }) ||
    goPaperLessPolicyError
  ) {
    return [
      Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: AlertTypes.GOPAPERLESS_FAILURE
      })
    ];
  }
  return [];
};

export const getEmailDeepLinkFailureAlerts = (billingEntity: BillingEntityState): Alert[] => {
  if (billingEntity?.deepLinkBillAccountFound === false) {
    return [
      Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: AlertTypes.EMAILL_DEEP_LINK_FAILURE
      })
    ];
  } else if (billingEntity?.billAccountHasAutopay === false) {
    return [
      Object.assign({}, initialAlert, {
        level: AlertLevels.HIGH,
        type: AlertTypes.AUTOPAY_DOES_NOT_EXIST_ON_BILLACCOUNT
      })
    ];
  }
  return [];
};
