import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { get as _get } from 'lodash';

import { EnrollmentStepNames } from '../../shared/enrollment-step-names.interface';

export class PersonalServiceStepNames implements EnrollmentStepNames {
  readonly [index: string]: symbol;
  readonly identification: symbol = Symbol('identification');
  readonly verificationOptions: symbol = Symbol('verification-options');
  readonly signup: symbol = Symbol('signup');
  readonly chooseSecurityQuestions: symbol = Symbol('security-questions-business');
  readonly personalInfo: symbol = Symbol('personal-info');
}

@Injectable()
export class PersonalService {
  readonly stepNames: PersonalServiceStepNames = new PersonalServiceStepNames();

  showPersonalInfo = false;

  hasProgram(routeData, programName: string): boolean {
    return _get(routeData, 'programs', []).some(program => {
      return _get(program, 'program') === programName;
    });
  }

  constructor() {}
}
