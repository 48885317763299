import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { get as _get } from 'lodash';
import { Observable } from 'rxjs';

import { DynamicViewModel, Option } from '@amfam/shared/models';

import { minimumCheckedCheckboxCheckedValidator } from '../../form-controls/components/ds-checkbox-group/ds-checkbox-group.validator';

@Component({
  selector: 'multiple-select-question',
  templateUrl: './multiple-select-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleSelectQuestionComponent implements OnInit {
  @Input() dynamicView: DynamicViewModel;
  @Input() selectedAnswer: string;
  @Input() displayBackButton: boolean = false;
  @Output() submitQuestion = new EventEmitter<string>();
  @Output() cancelEvent = new EventEmitter();
  @Output() goBackEvent = new EventEmitter();

  multipleSelectionGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.multipleSelectionGroup = this.formBuilder.group(
      {
        option: this.setupMultiFormControl(this.dynamicView)
      },
      { validators: minimumCheckedCheckboxCheckedValidator() }
    );
  }

  // JP: Helper function to setup array of FormControls for multiple select
  setupMultiFormControl(view: DynamicViewModel): UntypedFormArray {
    const selectedAnswersArr = _get(view, 'selectedAnswer', '').split(',');
    const formControls = view.options.map((option: Option) => {
      return new UntypedFormControl(selectedAnswersArr.includes(<string>option.id) || false);
    });
    return new UntypedFormArray(formControls);
  }

  onMultipleSelectSubmit() {
    this.submitQuestion.emit(this.getSelectedOptions(this.multipleSelectionGroup));
    this.multipleSelectionGroup.reset();
  }

  // JP: Go through all Form Controls and match to corresponding answered questions
  // to make concatenated string of answers
  getSelectedOptions(multipleSelectionGroup: UntypedFormGroup): string {
    let selectedIds = '';
    multipleSelectionGroup.controls.option['controls'].forEach((option, i) => {
      if (option.value) {
        selectedIds += _get(this.dynamicView, 'options', [])[i].id + ',';
      }
    });

    // Trim trailing comma
    selectedIds = selectedIds.substring(0, selectedIds.length - 1);

    return selectedIds;
  }

  cancel() {
    this.cancelEvent.emit();
  }

  goBack() {
    this.goBackEvent.emit();
    this.multipleSelectionGroup.reset();
  }
}
