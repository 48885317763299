import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';

import { NotificationObj } from '../../../models';
import { HeaderWrapperService } from '../../../services/header-wrapper.service';

@Component({
  selector: 'ds-header-mobile-notification',
  templateUrl: './ds-header-mobile-notification.component.html',
  // TODO: @jjc - Consolidate these
  styleUrls: [
    './ds-header-mobile-notification.component.scss',
    '../shared/ds-header-mobile-navigation.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggleSubmenu', [
      state('true', style({ transform: 'translateX(0)', visibility: 'visible' })),
      state('false', style({ transform: 'translateX(100%)', visibility: 'hidden' })),
      transition('* => *', animate('0.25s'))
    ])
  ]
})
export class DsHeaderMobileNotificationComponent implements OnInit {
  // TODO: Fix inputs upstream to use async as soon as possible and remove Observables here
  @Input() notifications$: Observable<NotificationObj>;
  @Input() experienceId: string;
  @ViewChildren('notificationItem') notificationItems: QueryList<ElementRef>;
  @ViewChild('notificationMenuLink') notificationMenuLink: ElementRef;

  notificationMessage = 'You have no notifications';
  showNotificationMenuSub = false;
  showMenuSub = false;
  notifications: NotificationObj;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private headerWrapperService: HeaderWrapperService,
    private ref: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService,
    private router: Router
  ) {}

  ngOnInit() {
    this.notifications$.pipe(takeUntil(this.stop$)).subscribe(notifications => {
      this.notifications = this.headerWrapperService.buildNotificationMessageArray(
        notifications,
        this.experienceId
      );
      this.ref.detectChanges();
    });

    this.headerWrapperService.mobileNotificationMenuOpen$
      .pipe(takeUntil(this.stop$))
      .subscribe(showNotificationMenuSub => {
        this.showNotificationMenuSub = showNotificationMenuSub;
        this.ref.detectChanges();
      });

    this.headerWrapperService.mobileMenuOpen$.pipe(takeUntil(this.stop$)).subscribe(showMenuSub => {
      this.showMenuSub = showMenuSub;
      this.ref.detectChanges();
    });
  }

  toggleSub() {
    this.headerWrapperService.setMobileNotificationMenuOpen(!this.showNotificationMenuSub);
    if (this.showMenuSub) {
      this.headerWrapperService.setMobileMenuOpen(false);
    }
  }

  onLinkBlur(target) {
    if (target?.parentNode === this.notificationItems?.last?.nativeElement) {
      this.notificationMenuLink.nativeElement.focus();
    }
  }

  onLinkClick(message: NotificationObj) {
    if (this.featureFlagService.isEnabled('policy_new_template')) {
      this.router.navigate([message.linkRoute], {
        queryParams: { scrollTo: 'documentsCard' }
      });
    } else {
      this.router.navigate([message.linkRoute], {
        queryParams: { openAccordion: 'documents', tid: 'newdocnotice' }
      });
    }
    this.headerWrapperService.setMobileNotificationMenuOpen(false);
  }

  onDismissClick(notification) {
    this.headerWrapperService.setMobileNotificationMenuOpen(false);
    this.headerWrapperService.setNotificationToDismiss(notification);
  }
}
