import { createAction, props } from '@ngrx/store';
import { Dictionary } from 'lodash';

import { Policy, RiskModel } from '@amfam/policy/models';
import {
  ApiStatus,
  BillAccount,
  DecoratedPreference,
  DeletePreferencesActionPayload,
  DeletePreferencesStoreModel,
  OldUpdatePreferencesRequest,
  OldUpdatePreferencesResponse,
  PopulatePreferenceModel,
  RetrievePreferencePayload,
  SilentRegistrationPayload,
  UnassociatedRiskDetailFail,
  UnassociatedRiskDetailModel,
  UpdateAmdModel,
  UpdatePreferencesRequest,
  UpdatePreferencesStoreModel,
  UpdateRegistrationRequest,
  UpdateRegistrationStoreModel
} from '@amfam/shared/models';
import { UserLogin } from '@amfam/shared/user';

export enum BillingActionTypes {
  BillAccountsLoadType = '[Billing] BillAccounts Load',
  BillAccountsLoadSuccessType = '[Billing] BillAccounts Load Success',
  BillAccountsLoadFailType = '[Billing] BillAccounts Load Failure',
  BillAccountsLoadCompleteType = '[Billing] BillAccounts Load Complete',

  BillAccountLoadDetailType = '[Billing] BillAccount Load Detail',
  BillAccountLoadDetailSuccessType = '[Billing] BillAccount Load Detail Success',
  BillAccountLoadDetailFailType = '[Billing] BillAccount Load Detail Fail',

  BillAccountLoadPreferenceType = '[Billing] BillAccount Load Preference',
  BillAccountLoadPreferenceSuccessType = '[Billing] BillAccount Load Preference Success',
  BillAccountLoadPreferenceFailType = '[Billing] BillAccount Load Preference Fail',

  BillAccountUpdatePreferenceType = '[Billing] BillAccount Update Preference',
  BillAccountUpdatePreferenceSuccessType = '[Billing] BillAccount Update Preference Success',
  BillAccountUpdatePreferenceFailType = '[Billing] BillAccount Update Preference Fail',
  BillAccountUpdatePreferenceInitType = '[Billing] BillAccount Update Preference Init',

  BillAccountUpdateBillingPreferenceType = '[Billing] BillAccount Update Billing Preference',
  BillAccountUpdateBillingPreferenceSuccessType = '[Billing] BillAccount Update Billing Preference Success',
  BillAccountUpdateBillingPreferenceFailType = '[Billing] BillAccount Update Billing Preference Fail',
  BillAccountUpdateBillingPreferenceInitType = '[Billing] BillAccount Update Billing Preference Init',

  BillAccountUpdateDeliveryPreferenceType = '[Billing] BillAccount Update Delivery Preference',
  BillAccountUpdateDeliveryPreferenceSuccessType = '[Billing] BillAccount Update Delivery Preference Success',
  BillAccountUpdateDeliveryPreferenceFailType = '[Billing] BillAccount Update Delivery Preference Fail',

  BillAccountDeletePreferenceType = '[Billing] BillAccount Delete Preference',
  BillAccountDeletePreferenceRetryType = '[Billing] BillAccount Delete Preference Retry',
  BillAccountDeletePreferenceResetRetryType = '[Billing] BillAccount Delete Preference Reset Retry',
  BillAccountDeletePreferenceSuccessType = '[Billing] BillAccount Delete Preference Success',
  BillAccountDeletePreferenceFailType = '[Billing] BillAccount Delete Preference Fail',

  BillAccountPopulatePreferenceType = '[Billing] BillAccount Populate Preference',

  BillAccountLoadDocumentsType = '[Billing] BillAccount Load Documents',
  BillAccountLoadDocumentsSuccessType = '[Billing] BillAccount Load Documents Success',
  BillAccountLoadDocumentsFailType = '[Billing] BillAccount Load Documents Fail',

  BillAccountUpdateRegistrationSubmitType = '[Billing] BillAccount Update Registration Submit',
  BillAccountUpdateRegistrationSuccessType = '[Billing] BillAccount Update Registration Success',
  BillAccountUpdateRegistrationPartialFailType = '[Billing] BillAccount Update Registration Partial Fail',
  BillAccountUpdateRegistrationFailType = '[Billing] BillAccount Update Registration Fail',
  BillAccountUpdateRegistrationInitType = '[Billing] BillAccount Update Registration Init',
  UnregisterBillAccountType = '[Billing] Unregister Bill Account',

  BillAccountUnregisterAssociatedType = '[Billing] BillAccount Unregister Associated',
  BillAccountUnregisterUnAssociatedType = '[Billing] BillAccount Unregister UnAssociated',
  BillAccountSilentRegistrationType = '[Billing] BillAccount Silent Registration',
  BillAccountRegistrationWithRoutingType = '[BillAccount] Register And Route',

  BillAccountTransformPolicyListType = '[Billing] BillAccount Transform PolicyList',
  BillAccountTransformPolicyListSuccessType = '[Billing] BillAccount Transform PolicyList Success',
  BillAccountTransformPolicyListFailType = '[Billing] BillAccount Transform PolicyList Fail',

  BillAccountUpdateReadOnlyStatusType = '[Billing] BillAccount Update Read Only Status',

  BillAccountLoadUnAssociatedRiskDetailType = '[Billing] BillAccount Load Unassociated Detail',
  BillAccountLoadUnAssociatedRiskDetailSuccessType = '[Billing] BillAccount Load Unassociated Detail Success',
  BillAccountLoadUnAssociatedRiskDetailFailType = '[Billing] BillAccount Load Unassociated Detail Fail',

  BillAccountReloadMinimumDueType = '[Billing] BillAccount Reload Minimum Due(s)',
  BillAccountReloadMinimumDueSuccessType = '[Billing] BillAccount Reload Minimum Due(s) Success',

  BillAccountLoadFuturePaymentsType = '[Billing] BillAccount Load Future Payments',
  BillAccountLoadFuturePaymentsSuccessType = '[Billing] BillAccount Load Future Payments Success',
  BillAccountLoadFuturePaymentsFailType = '[Billing] BillAccount Load Future Payments Fail',

  BillAccountSetCancelPathType = '[Billing] BillAccount Set Cancel Path',

  BillAccountFoundType = '[Billing Deeplink] BillAccount not found',
  DoesBillAccountHaveAutopayType = '[Billing Deeplink] BillAccount does not have Autopay',
  DismissBannerType = '[Billing Deeplink] Bill account does not have autopay dismiss banner'
}

export const billAccountsLoad = createAction(
  BillingActionTypes.BillAccountsLoadType,
  (payload?: UserLogin) => ({ payload })
);
export const billAccountsLoadSuccess = createAction(
  BillingActionTypes.BillAccountsLoadSuccessType,
  (payload?: BillAccount[]) => ({ payload })
);
export const billAccountLoadFuturePayments = createAction(
  BillingActionTypes.BillAccountLoadFuturePaymentsType,
  (payload: BillAccount) => ({ payload })
);

export const billAccountsLoadFail = createAction(
  BillingActionTypes.BillAccountsLoadFailType,
  props<{ error: undefined }>()
);
export const billAccountsLoadComplete = createAction(
  BillingActionTypes.BillAccountsLoadCompleteType,
  (payload?: Partial<BillAccount>) => ({ payload })
);
export const billAccountLoadDetail = createAction(
  BillingActionTypes.BillAccountLoadDetailType,
  (payload: Partial<BillAccount>) => ({ payload })
);
export const billAccountLoadDetailSuccess = createAction(
  BillingActionTypes.BillAccountLoadDetailSuccessType,
  (payload: BillAccount) => ({ payload })
);
export const billAccountLoadDetailFail = createAction(
  BillingActionTypes.BillAccountLoadDetailFailType,
  (payload: { status: ApiStatus; billAccountNumber: string }) => ({ payload })
);
export const billAccountLoadPreference = createAction(
  BillingActionTypes.BillAccountLoadPreferenceType,
  (payload: RetrievePreferencePayload) => ({ payload })
);
export const billAccountUpdatePreferenceSuccess = createAction(
  BillingActionTypes.BillAccountUpdatePreferenceSuccessType,
  (payload: OldUpdatePreferencesResponse) => ({ payload })
);
export const billAccountUpdatePreferenceFail = createAction(
  BillingActionTypes.BillAccountUpdatePreferenceFailType,
  (payload: OldUpdatePreferencesResponse) => ({ payload })
);
export const billAccountUpdatePreferenceInit = createAction(
  BillingActionTypes.BillAccountUpdatePreferenceInitType,
  (payload: string) => ({ payload })
);
export const billAccountUpdateBillingPreference = createAction(
  BillingActionTypes.BillAccountUpdateBillingPreferenceType,
  (payload: UpdatePreferencesRequest) => ({ payload })
);
export const billAccountUpdateBillingPreferenceSuccess = createAction(
  BillingActionTypes.BillAccountUpdateBillingPreferenceSuccessType,
  (payload: UpdatePreferencesStoreModel) => ({ payload })
);
export const billAccountUpdateDeliveryPreference = createAction(
  BillingActionTypes.BillAccountUpdateDeliveryPreferenceType,
  (payload: UpdatePreferencesRequest) => ({ payload })
);
export const billAccountUpdateDeliveryPreferenceSuccess = createAction(
  BillingActionTypes.BillAccountUpdateDeliveryPreferenceSuccessType,
  (payload: UpdatePreferencesStoreModel) => ({ payload })
);
export const billAccountUpdateDeliveryPreferenceFail = createAction(
  BillingActionTypes.BillAccountUpdateDeliveryPreferenceFailType,
  (payload: UpdatePreferencesStoreModel) => ({ payload })
);
export const billAccountDeletePreference = createAction(
  BillingActionTypes.BillAccountDeletePreferenceType,
  (payload: DeletePreferencesActionPayload) => ({ payload })
);
export const billAccountDeletePreferenceRetry = createAction(
  BillingActionTypes.BillAccountDeletePreferenceRetryType,
  (payload: { billAccountNumber: string }) => ({ payload })
);
export const billAccountDeletePreferenceResetRetry = createAction(
  BillingActionTypes.BillAccountDeletePreferenceResetRetryType,
  (payload: object) => ({ payload })
);
export const billAccountDeletePreferenceSuccess = createAction(
  BillingActionTypes.BillAccountDeletePreferenceSuccessType,
  (payload: DeletePreferencesStoreModel) => ({ payload })
);
export const billAccountDeletePreferenceFail = createAction(
  BillingActionTypes.BillAccountDeletePreferenceFailType,
  (payload: DeletePreferencesStoreModel) => ({ payload })
);
export const billAccountPopulatePreference = createAction(
  BillingActionTypes.BillAccountPopulatePreferenceType,
  (payload: PopulatePreferenceModel) => ({ payload })
);
export const billAccountLoadDocuments = createAction(
  BillingActionTypes.BillAccountLoadDocumentsType,
  (payload: { status?: ApiStatus; billAccountNumber: string; billingSystem: string }) => ({
    payload
  })
);
export const billAccountLoadDocumentsSuccess = createAction(
  BillingActionTypes.BillAccountLoadDocumentsSuccessType,
  (payload: unknown) => ({ payload })
);
export const billAccountLoadDocumentsFail = createAction(
  BillingActionTypes.BillAccountLoadDocumentsFailType,
  (payload: unknown) => ({ payload })
);
export const billAccountUpdateRegistrationSubmit = createAction(
  BillingActionTypes.BillAccountUpdateRegistrationSubmitType,
  (payload: UpdateRegistrationRequest) => ({ payload })
);
export const billAccountUpdateRegistrationSuccess = createAction(
  BillingActionTypes.BillAccountUpdateRegistrationSuccessType,
  (payload: UpdateRegistrationStoreModel) => ({ payload })
);
export const billAccountUpdateRegistrationPartialFail = createAction(
  BillingActionTypes.BillAccountUpdateRegistrationPartialFailType,
  (payload: UpdateRegistrationStoreModel) => ({ payload })
);
export const billAccountUpdateRegistrationFail = createAction(
  BillingActionTypes.BillAccountUpdateRegistrationFailType,
  (payload: UpdateRegistrationStoreModel) => ({ payload })
);
export const billAccountUpdateRegistrationInit = createAction(
  BillingActionTypes.BillAccountUpdateRegistrationInitType,
  (payload: string) => ({ payload })
);
export const billAccountUnregisterAssociated = createAction(
  BillingActionTypes.BillAccountUnregisterAssociatedType,
  (payload: { billAccountNumber: string }) => ({ payload })
);
export const billAccountUnregisterUnAssociated = createAction(
  BillingActionTypes.BillAccountUnregisterUnAssociatedType,
  (payload?: { bilLAccountNumber: string }) => ({ payload })
);
export const billAccountSilentRegistration = createAction(
  BillingActionTypes.BillAccountSilentRegistrationType,
  (payload: SilentRegistrationPayload[]) => ({ payload })
);
export const billAccountTransformPolicyList = createAction(
  BillingActionTypes.BillAccountTransformPolicyListType,
  (payload: {
    policyList: RiskModel[];
    billAccount: BillAccount;
    policyEntities: Dictionary<Policy>;
  }) => ({ payload })
);
export const billAccountTransformPolicyListSuccess = createAction(
  BillingActionTypes.BillAccountTransformPolicyListSuccessType,
  (payload: { billAccount: BillAccount }) => ({ payload })
);

export const billAccountTransformPolicyListFail = createAction(
  BillingActionTypes.BillAccountTransformPolicyListFailType,
  (payload: unknown) => ({ payload })
);
export const billAccountUpdateReadOnlyStatus = createAction(
  BillingActionTypes.BillAccountUpdateReadOnlyStatusType,
  (payload: DecoratedPreference) => ({ payload })
);
export const billAccountLoadUnAssociatedRiskDetail = createAction(
  BillingActionTypes.BillAccountLoadUnAssociatedRiskDetailType,
  (payload: UnassociatedRiskDetailModel) => ({ payload })
);
export const billAccountLoadUnAssociatedRiskDetailSuccess = createAction(
  BillingActionTypes.BillAccountLoadUnAssociatedRiskDetailSuccessType,
  (payload: UnassociatedRiskDetailModel) => ({ payload })
);
export const billAccountLoadUnAssociatedRiskDetailFail = createAction(
  BillingActionTypes.BillAccountLoadUnAssociatedRiskDetailFailType,
  (payload: UnassociatedRiskDetailFail) => ({ payload })
);
export const billAccountReloadMinimumDue = createAction(
  BillingActionTypes.BillAccountReloadMinimumDueType,
  (payload: string[]) => ({ payload })
);
export const billAccountReloadMinimumDueSuccess = createAction(
  BillingActionTypes.BillAccountReloadMinimumDueSuccessType,
  (payload: UpdateAmdModel) => ({ payload })
);

export const billAccountLoadFuturePaymentsSuccess = createAction(
  BillingActionTypes.BillAccountLoadFuturePaymentsSuccessType,
  (payload: BillAccount) => ({ payload })
);
export const billAccountLoadFuturePaymentsFail = createAction(
  BillingActionTypes.BillAccountLoadFuturePaymentsFailType,
  (payload?: { status: ApiStatus; billAccountNumber: string }) => ({ payload })
);
export const billAccountSetCancelPath = createAction(
  BillingActionTypes.BillAccountSetCancelPathType,
  (payload: string) => ({ payload })
);
export const billAccountFound = createAction(
  BillingActionTypes.BillAccountFoundType,
  (payload?: boolean) => ({ payload })
);
export const doesBillAccountHaveAutopay = createAction(
  BillingActionTypes.DoesBillAccountHaveAutopayType,
  (payload?: boolean) => ({ payload })
);
export const dismissBanner = createAction(
  BillingActionTypes.DismissBannerType,
  (payload?: boolean) => ({ payload })
);

export const billAccountLoadPreferenceSuccess = createAction(
  BillingActionTypes.BillAccountLoadPreferenceSuccessType,
  (payload: DecoratedPreference) => ({ payload })
);

export const billAccountLoadPreferenceFail = createAction(
  BillingActionTypes.BillAccountLoadPreferenceFailType,
  (payload?: { status: ApiStatus; billAccountNumber: string }) => ({ payload })
);

export const billAccountUpdatePreference = createAction(
  BillingActionTypes.BillAccountUpdatePreferenceType,
  (payload: OldUpdatePreferencesRequest) => ({ payload })
);

export const billAccountUpdateBillingPreferenceFail = createAction(
  BillingActionTypes.BillAccountUpdateBillingPreferenceFailType,
  (payload: UpdatePreferencesStoreModel) => ({ payload })
);

export const billAccountUpdateBillingPreferenceInit = createAction(
  BillingActionTypes.BillAccountUpdateBillingPreferenceInitType,
  (payload: string) => ({ payload })
);

export type BillingActions =
  | ReturnType<typeof billAccountsLoadSuccess>
  | ReturnType<typeof billAccountsLoad>
  | ReturnType<typeof billAccountsLoadComplete>
  | ReturnType<typeof billAccountsLoadFail>
  | ReturnType<typeof billAccountLoadDetail>
  | ReturnType<typeof billAccountLoadDetailSuccess>
  | ReturnType<typeof billAccountLoadDetailFail>
  | ReturnType<typeof billAccountLoadPreference>
  | ReturnType<typeof billAccountUpdatePreferenceSuccess>
  | ReturnType<typeof billAccountUpdatePreferenceFail>
  | ReturnType<typeof billAccountUpdateBillingPreference>
  | ReturnType<typeof billAccountUpdateBillingPreferenceSuccess>
  | ReturnType<typeof billAccountUpdateDeliveryPreference>
  | ReturnType<typeof billAccountUpdateDeliveryPreferenceSuccess>
  | ReturnType<typeof billAccountUpdateDeliveryPreferenceFail>
  | ReturnType<typeof billAccountDeletePreference>
  | ReturnType<typeof billAccountDeletePreferenceRetry>
  | ReturnType<typeof billAccountDeletePreferenceResetRetry>
  | ReturnType<typeof billAccountDeletePreferenceSuccess>
  | ReturnType<typeof billAccountDeletePreferenceFail>
  | ReturnType<typeof billAccountPopulatePreference>
  | ReturnType<typeof billAccountLoadDocuments>
  | ReturnType<typeof billAccountLoadDocumentsSuccess>
  | ReturnType<typeof billAccountLoadDocumentsFail>
  | ReturnType<typeof billAccountUpdateRegistrationSubmit>
  | ReturnType<typeof billAccountUpdateRegistrationSuccess>
  | ReturnType<typeof billAccountUpdateRegistrationPartialFail>
  | ReturnType<typeof billAccountUpdateRegistrationFail>
  | ReturnType<typeof billAccountUpdateRegistrationInit>
  | ReturnType<typeof billAccountUnregisterAssociated>
  | ReturnType<typeof billAccountUnregisterUnAssociated>
  | ReturnType<typeof billAccountSilentRegistration>
  | ReturnType<typeof billAccountTransformPolicyList>
  | ReturnType<typeof billAccountTransformPolicyListSuccess>
  | ReturnType<typeof billAccountTransformPolicyListFail>
  | ReturnType<typeof billAccountUpdateReadOnlyStatus>
  | ReturnType<typeof billAccountLoadUnAssociatedRiskDetail>
  | ReturnType<typeof billAccountLoadUnAssociatedRiskDetailSuccess>
  | ReturnType<typeof billAccountLoadUnAssociatedRiskDetailFail>
  | ReturnType<typeof billAccountReloadMinimumDue>
  | ReturnType<typeof billAccountReloadMinimumDueSuccess>
  | ReturnType<typeof billAccountLoadFuturePayments>
  | ReturnType<typeof billAccountLoadFuturePaymentsSuccess>
  | ReturnType<typeof billAccountLoadFuturePaymentsFail>
  | ReturnType<typeof billAccountSetCancelPath>
  | ReturnType<typeof billAccountFound>
  | ReturnType<typeof doesBillAccountHaveAutopay>
  | ReturnType<typeof dismissBanner>
  | ReturnType<typeof billAccountLoadPreferenceSuccess>
  | ReturnType<typeof billAccountLoadPreferenceFail>
  | ReturnType<typeof billAccountUpdatePreference>
  | ReturnType<typeof billAccountUpdateBillingPreferenceFail>
  | ReturnType<typeof billAccountUpdateBillingPreferenceInit>
  | ReturnType<typeof billAccountUpdatePreferenceInit>;

export const fromBillingActions = {
  billAccountLoadFuturePayments,
  billAccountLoadFuturePaymentsSuccess,
  billAccountLoadFuturePaymentsFail,
  billAccountsLoad,
  billAccountsLoadSuccess,
  billAccountsLoadFail,
  billAccountsLoadComplete,
  billAccountLoadDetail,
  billAccountLoadDetailSuccess,
  billAccountLoadDetailFail,
  billAccountLoadUnAssociatedRiskDetail,
  billAccountLoadUnAssociatedRiskDetailSuccess,
  billAccountLoadUnAssociatedRiskDetailFail,
  billAccountLoadPreference,
  billAccountLoadPreferenceSuccess,
  billAccountLoadPreferenceFail,
  billAccountLoadDocuments,
  billAccountLoadDocumentsSuccess,
  billAccountLoadDocumentsFail,
  billAccountUnregisterAssociated,
  billAccountUnregisterUnAssociated,
  billAccountUpdatePreference,
  billAccountUpdatePreferenceSuccess,
  billAccountUpdatePreferenceFail,
  billAccountUpdatePreferenceInit,
  billAccountUpdateBillingPreference,
  billAccountUpdateBillingPreferenceSuccess,
  billAccountUpdateBillingPreferenceFail,
  billAccountUpdateBillingPreferenceInit,
  billAccountUpdateDeliveryPreference,
  billAccountUpdateDeliveryPreferenceSuccess,
  billAccountUpdateDeliveryPreferenceFail,
  billAccountPopulatePreference,
  billAccountUpdateRegistrationInit,
  billAccountUpdateRegistrationSubmit,
  billAccountUpdateRegistrationSuccess,
  billAccountUpdateRegistrationPartialFail,
  billAccountUpdateRegistrationFail,
  billAccountTransformPolicyList,
  billAccountTransformPolicyListSuccess,
  billAccountTransformPolicyListFail,
  billAccountDeletePreference,
  billAccountDeletePreferenceResetRetry,
  billAccountDeletePreferenceRetry,
  billAccountDeletePreferenceSuccess,
  billAccountDeletePreferenceFail,
  billAccountSilentRegistration,
  billAccountUpdateReadOnlyStatus,
  billAccountReloadMinimumDue,
  billAccountReloadMinimumDueSuccess,
  billAccountSetCancelPath,
  billAccountFound,
  doesBillAccountHaveAutopay,
  dismissBanner
};
