import { createAction, props } from '@ngrx/store';

import { ApiStatus } from '@amfam/shared/models';
import { TemplatedEmail } from '@amfam/shared/utility/shared-services';

import { ANSRequest } from '../../models/add-vehicle-bind.model';
import {
  AddVehicleNotificationRequestAgentEmail,
  AddVehicleNotificationRequestPartnerEmail
} from '../../models/add-vehicle.model';

// Agent Email Notifications
export const sendAgentEmailNotification = createAction(
  '[Add Vehicle] Send Agent Email Notification',
  props<{ payload: AddVehicleNotificationRequestAgentEmail }>()
);

export const sendAgentEmailNotificationSuccess = createAction(
  '[Add Vehicle] Send Agent Email Notification Success',
  props<{ payload: ApiStatus }>()
);
export const sendAgentEmailNotificationError = createAction(
  '[Add Vehicle] Send Agent Email Notification Error',
  props<{ payload: ApiStatus }>()
);

// Partner Email Notifications
export const sendPartnerEmailNotification = createAction(
  '[Add Vehicle] Send Partner Email Notification',
  props<{ payload: AddVehicleNotificationRequestPartnerEmail }>()
);

export const sendPartnerEmailNotificationSuccess = createAction(
  '[Add Vehicle] Send Partner Email Notification Success',
  props<{ payload: ApiStatus }>()
);
export const sendPartnerEmailNotificationError = createAction(
  '[Add Vehicle] Send Partner Email Notification Error',
  props<{ payload: ApiStatus }>()
);

// Customer Email Notifications
export const sendCustomerEmailNotification = createAction(
  '[Add Vehicle] Send Customer Email Notification',
  props<{ payload: TemplatedEmail }>()
);

export const sendCustomerEmailNotificationSuccess = createAction(
  '[Add Vehicle] Send Customer Email Notification Success',
  props<{ payload: ApiStatus }>()
);

export const sendCustomerEmailNotificationError = createAction(
  '[Add Vehicle] Send Customer Email Notification Error',
  props<{ payload: ApiStatus }>()
);

// Partner Customer Email Notifications
export const sendPartnerCustomerEmailNotification = createAction(
  '[Add Vehicle] Send Partner Customer Email Notification',
  props<{ payload: TemplatedEmail }>()
);

export const sendPartnerCustomerEmailNotificationSuccess = createAction(
  '[Add Vehicle] Send Partner Customer Email Notification Success',
  props<{ payload: ApiStatus }>()
);

export const sendPartnerCustomerEmailNotificationError = createAction(
  '[Add Vehicle] Send Partner Customer Email Notification Error',
  props<{ payload: ApiStatus }>()
);

// Agent ANS notifications
export const sendAgentANSNotification = createAction(
  '[Add Vehicle] Send Agent ANS Notification',
  props<{ payload: ANSRequest }>()
);

export const sendAgentANSNotificationSuccess = createAction(
  '[Add Vehicle] Send Agent ANS Notification Success',
  props<{ payload: ApiStatus }>()
);

export const sendAgentANSNotificationError = createAction(
  '[Add Vehicle] Send Agent ANS Notification Error',
  props<{ payload: ApiStatus }>()
);

// Agent ANS notifications
export const bindAddVehicleQuote = createAction(
  '[Add Vehicle] Bind Add Vehicle Quote',
  props<{ payload: { workOrderNumber: string; policyNumber: string; vin?: string } }>()
);

export const bindAddVehicleQuoteSuccess = createAction(
  '[Add Vehicle] Bind Add Vehicle Quote Success',
  props<{ payload: ApiStatus }>()
);

export const bindAddVehicleQuoteError = createAction(
  '[Add Vehicle] Bind Add Vehicle Quote Error',
  props<{ payload: ApiStatus }>()
);

export const bindAddVehicleQuoteReset = createAction('[Add Vehicle] Bind Add Vehicle Error Reset');

export const fromAddVehicleBindActions = {
  sendAgentEmailNotification,
  sendAgentEmailNotificationSuccess,
  sendAgentEmailNotificationError,
  sendPartnerEmailNotification,
  sendPartnerEmailNotificationSuccess,
  sendPartnerEmailNotificationError,
  sendCustomerEmailNotification,
  sendCustomerEmailNotificationSuccess,
  sendCustomerEmailNotificationError,
  sendPartnerCustomerEmailNotification,
  sendPartnerCustomerEmailNotificationSuccess,
  sendPartnerCustomerEmailNotificationError,
  sendAgentANSNotification,
  sendAgentANSNotificationSuccess,
  sendAgentANSNotificationError,
  bindAddVehicleQuote,
  bindAddVehicleQuoteSuccess,
  bindAddVehicleQuoteError,
  bindAddVehicleQuoteReset
};
