import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FRUser } from '@forgerock/javascript-sdk';
import { Store } from '@ngrx/store';
import { flatMap as _flatmap, get as _get, some as _some } from 'lodash';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, take, takeUntil } from 'rxjs/operators';

import { BrandSelectors, Partners } from '@amfam/shared/utility/brand';

import {
  BillAccountsSelectors,
  BillingNotificationsState
} from '@amfam/billing/billaccount/data-access';
import { PolicySelectors, PolicySummarySelectors } from '@amfam/policy/data-access';
import { GoPaperLessSelectors } from '@amfam/policy/go-paperless/data-access';
import { SourceSystemType } from '@amfam/policy/models';
import { ProfileActions, profileQuery } from '@amfam/profile/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import {
  BillAccount,
  DeliveryPreferences,
  PreferenceItem,
  ProfileAnalytics,
  ProfileOverviewModel
} from '@amfam/shared/models';
import { QuickLink } from '@amfam/shared/ui/quick-links';
import { AccountTypeEnum, userQuery } from '@amfam/shared/user';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import {
  ConfigService,
  CookiesService,
  CopyService,
  PageAnalytic,
  ScrollService
} from '@amfam/shared/utility/shared-services';
import {
  Action,
  DockingBarService,
  ErrorLevels,
  ErrorObj,
  LoadingSpinnerService,
  PageTitleService,
  ToasterService
} from '@amfam/ui-kit';

import { AuthService } from '../core/auth/auth.service';
import { ForgerockAuthService } from '../core/auth/forgerock-auth.service';
import * as fromRoot from '../core/store';
import { PrettyBillingAcctNum } from '../shared/ui/format-pipes/format.pipe';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  contactInformationTitle: string;
  communicationPreferencesTitle: string;
  costcoMembershipTitle: string;
  deleteMyAccountTitle: string;
  profileCard$: Observable<ProfileOverviewModel>;
  requestChangeRoute = '/policies/changerequest/';
  securityInformationTitle: string;
  securityQuestions$: Observable<any[]>;
  quickLinksOverride = new Array<QuickLink>();
  isCustomer: boolean;
  openSecurity = false;
  openContact = false;
  openCommunication = false;
  openDeleteMyAccount = false;
  openCostcoMembership = false;
  isCostcoPolicy: boolean;
  isCostcoMembershipEnabled$ = this.store.select(fromRoot.selectMembershipEnabled);
  preferenceData: PreferenceItem[];
  homeUrl: string;
  public errorList: Array<ErrorObj> = [];
  eSignatureStatus: string;
  public hasSecurityQuestions: boolean;
  public isEligibleToViewPreferences: Observable<boolean>;
  public billAccounts$: Observable<BillAccount[]>;
  public billAccountsNotifications$: Observable<BillingNotificationsState[]>;
  isConnectPartner = false;
  isDuplicateEmail = false;
  duplicateEmailErrorText: string;
  contactNumber: string;
  isContactBillingToggleEnabled = this.featureFlagService.isEnabled('contact_billing');
  isPartyLoaded$: Observable<boolean>;
  deleteMyAccountToggleEnabled = this.featureFlagService.isEnabled('delete_myaccount');
  forgerockEnabled = this.featureFlagService.isEnabled('forgerock');
  forgerockMfaEnabled = this.featureFlagService.isEnabled('forgerock_mfa');
  isSmallScreen = false;
  isMediumScreen = false;
  openPhoneNumber = false;
  isOn = false; // Initial state is off

  private pageAnalytic: PageAnalytic;
  private amfamDotCom: string;
  // Shared subject for completing observables
  private stop$: Subject<void> = new Subject<void>();
  private accountType: string;

  constructor(
    private authService: AuthService,
    private copyService: CopyService,
    private dockingService: DockingBarService,
    private pageTitle: PageTitleService,
    private route: ActivatedRoute,
    private config: ConfigService,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade,
    private store: Store,
    private cookiesService: CookiesService,
    private featureFlagService: FeatureFlagService,
    private scrollService: ScrollService,
    private breakpointObserver: BreakpointObserver,
    private toasterService: ToasterService,
    private forgerockAuthService: ForgerockAuthService,
    private featureService: FeatureFlagService
  ) {
    // assign the Observable value from the store
    this.amfamDotCom = this.config.get('links.amfamDotComUrl');
    this.profileCard$ = this.store.select(fromRoot.getProfileCardData);
    this.isPartyLoaded$ = this.store.select(userQuery.getPartyDetails).pipe(
      filter(party => !!party && !!party.partnerIdentifier),
      map(() => true)
    );
    this.securityQuestions$ = this.store.select(profileQuery.selectSecurityQuestions);
    this.billAccounts$ = this.store.select(BillAccountsSelectors.selectBillAccounts);
    this.billAccountsNotifications$ = this.store.select(
      BillAccountsSelectors.selectAllBillAccountsNotifications
    );
    this.store
      .select(profileQuery.selectProfileState)
      .pipe(takeUntil(this.stop$))
      .subscribe(profile => {
        this.isOn = profile.mfaActive;
      });
  }

  @HostListener('window:resize')
  resize() {
    this.screenSize();
  }

  ngOnInit() {
    this.screenSize();
    // Display error message when retrieving billing preferences has partial error.
    this.isEligibleToViewPreferences = this.store.select(
      BillAccountsSelectors.selectIsEligibleToViewPreferences
    );
    this.errorList = this.showErrorMessage([
      this.copyService.getCopy('billing.defaultBillingErrorMessage')
    ]);

    this.route.queryParams.pipe(takeUntil(this.stop$)).subscribe(queryParams => {
      // Query param from docusign
      if (queryParams['event']) {
        this.openCommunication = true;
        this.eSignatureStatus = queryParams['event'];
      }
      // if query has profile open communication preferences accordion
      if (queryParams.openAccordion) {
        this.openSecurity = false;
        this.openContact = false;
        this.openCommunication = false;
        switch (queryParams.openAccordion) {
          case 'security':
            this.openSecurity = true;
            break;
          case 'contact':
            this.openContact = true;
            break;
          case 'communication':
            this.openCommunication = true;
            break;
        }
      }
    });

    // check if this user has any security questions
    this.securityQuestions$.pipe(takeUntil(this.stop$)).subscribe(securityQuestions => {
      if (
        _get(securityQuestions, 'length') > 0 &&
        (_get(securityQuestions[0], 'question') === '' ||
          _get(securityQuestions[1], 'question') === '')
      ) {
        this.hasSecurityQuestions = false;
      } else {
        this.hasSecurityQuestions = true;
      }
    });

    combineLatest(
      this.store.select(PolicySelectors.selectPoliciesLoading),
      this.store.select(PolicySummarySelectors.selectPolicySummaryLoading),
      this.store.select(BillAccountsSelectors.selectBillAccountsAnyLoading),
      this.store.select(GoPaperLessSelectors.selectGoPaperlessLoading),
      this.store.select(profileQuery.selectProfileLoading),
      this.billAccounts$,
      this.billAccountsNotifications$,
      (
        detailLoading,
        summaryLoading,
        billAccountsLoading,
        goPaperlessLoading,
        profileLoading,
        billAccounts,
        billAccountsNotifications
      ) => ({
        detailLoading: detailLoading,
        summaryLoading: summaryLoading,
        billAccountsLoading: billAccountsLoading,
        goPaperlessLoading: goPaperlessLoading,
        profileLoading: profileLoading,
        billAccounts: billAccounts,
        billAccountsNotifications
      })
    )
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        if (
          state.detailLoading ||
          state.summaryLoading ||
          state.billAccountsLoading ||
          state.profileLoading ||
          state.goPaperlessLoading ||
          _some(state.billAccountsNotifications, 'updatingDeliveryPreferences') ||
          !state.billAccountsNotifications.every(
            ban => ban.prefsFinishedLoading || ban.loadPrefsError
          )
        ) {
          this.spinner.start({
            blockActions: true
          });
        } else {
          this.buildPreferenceData(state.billAccounts);
          this.spinner.stop();
          if (localStorage.getItem('frFlowType') === 'change-password') {
            this.analyticsFacade.trackEvent({
              event: 'forgerock_password_success',
              account_type: this.accountType,
              method: ''
            });
            localStorage.removeItem('frFlowType');
            const message = this.copyService.getCopy('profile.passwordChangeText');
            this.toasterService.pop('success', message);
          }
        }
      });

    // confirms user account type before assigning request a change functionality
    combineLatest(
      this.store
        .select(userQuery.getUserState)
        .pipe(
          distinctUntilKeyChanged(
            'typeOfAccountCode',
            (prev: string, next: string) => prev.toLowerCase() === next.toLowerCase()
          )
        ),
      this.store.select(BrandSelectors.selectIsPartner),
      this.store.select(BrandSelectors.selectLandingPageUrl),
      this.store.select(BrandSelectors.selectCusCareNumber),
      (userState, isPartner, landingPageUrl, contactNumber) => ({
        userState: userState,
        isPartner: isPartner,
        landingPageUrl: landingPageUrl,
        contactNumber: contactNumber
      })
    )
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        // check if user is a non-AFI partner
        if (state.isPartner) {
          this.isConnectPartner = true;
          this.isDuplicateEmail = false;
        } else if (this.cookiesService.hasItem('isDuplicateEmail')) {
          this.isDuplicateEmail = true;
          this.analyticsFacade.trackPage(ProfileAnalytics.profileMultipleEmailErrorAnalytic);
        }

        this.contactNumber = this.copyService.getCopy('shared.contactNumber');
        if (state.isPartner && state.landingPageUrl) {
          this.homeUrl = encodeURI(state.landingPageUrl);
        } else {
          this.homeUrl = encodeURI(this.config.get('links.amfamDotComUrl'));
        }
        // confirms user account type before assigning request a change functionality
        this.dockingService.clearActions();
        this.isCustomer = state.userState.typeOfAccountCode === 'CUSTOMER';
        if (this.isCustomer && !this.isConnectPartner) {
          this.dockingService.registerAction(
            new Action('Request a Change', () => {
              this.store.dispatch(
                fromRouterActions.Go({
                  path: [this.requestChangeRoute]
                })
              );
            })
          );
        }
        this.pageAnalytic = {
          pageName: 'MyAccount:Profile:Dashboard',
          experience: '',
          primaryCategory: 'My Account',
          subCategory1: 'Profile',
          subCategory2: '',
          subCategory3: ''
        };
        this.analyticsFacade.trackPage(this.pageAnalytic);
      });

    this.store
      .select(BrandSelectors.selectPartnerId)
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        if ([String(Partners.COSTCO_ECP)].includes(state)) this.isCostcoPolicy = true;
      });

    this.dockingService.registerHeading('Manage Your Account');
    this.pageTitle.set('Profile');

    this.securityInformationTitle = this.copyService.getCopy('profile.securityInformationTitle');
    this.contactInformationTitle = this.copyService.getCopy('profile.contactInformationTitle');
    this.communicationPreferencesTitle = this.copyService.getCopy(
      'profile.communicationPreferencesTitle'
    );
    this.costcoMembershipTitle = this.copyService.getCopy('profile.costcoMembershipTitle');
    this.deleteMyAccountTitle = this.copyService.getCopy('profile.deleteMyAccountTitle');
    this.quickLinksOverride = [
      new QuickLink('Make a Payment', '/billing/one-time-pay/choose-accounts'),
      new QuickLink('Request a Change', '/policies/changerequest'),
      new QuickLink('Contact a Billing Specialist', '/billing/contact'),
      new QuickLink(
        'FAQs',
        `${this.amfamDotCom}resources/articles/understanding-insurance/understanding-my-account`,
        'amfam'
      ),
      new QuickLink('Log out', null, null, null, 'quickLinkLogoutLink').withOnClick(
        (event: MouseEvent) => {
          if (this.featureService.isEnabled('forgerock')) {
            FRUser.logout();
            this.forgerockAuthService
              .logout()
              .pipe(takeUntil(this.stop$))
              .subscribe(() => {
                window.location.href = encodeURI(this.homeUrl);
              });
          } else {
            this.authService
              .logout()
              .pipe(takeUntil(this.stop$))
              .subscribe(() => {
                window.location.href = encodeURI(this.homeUrl);
              });
          }
          event.preventDefault();
        }
      )
    ]
      .filter(
        link =>
          // filter Request a Change link if non-AFI partner
          !(
            (_get(link, 'text') === 'Request a Change' || _get(link, 'text') === 'FAQs') &&
            this.isConnectPartner
          )
      )
      .filter(
        link =>
          // fitler Contact a Billing Specialist link if contact billing is toggled off
          !(
            _get(link, 'text') === 'Contact a Billing Specialist' &&
            !this.isContactBillingToggleEnabled
          )
      );

    this.store.dispatch(ProfileActions.GetValidationStatusForPendingEmailAction());

    this.store
      .select(userQuery.getUserState)
      .pipe(take(1))
      .subscribe(user => {
        this.accountType =
          user.typeOfAccountCode === AccountTypeEnum.CFR ? 'commercial' : 'personal';
      });
  }

  deleteMyAccount() {
    // Navigate to Delete My Account Page
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/profile/delete-my-account']
      })
    );
  }

  screenSize() {
    this.isMediumScreen = this.breakpointObserver.isMatched('(max-width: 960px)');
    this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
  }

  htmlClickEventHandler(e: any, id: string) {
    if (!id) return false;
    switch (id) {
      case 'agent':
        this.moveToAgentCard();
        break;

      default:
        return false;
    }
  }

  requestPolicyUpdate() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: [this.requestChangeRoute]
      })
    );
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  moveToAgentCard() {
    this.scrollService.scrollToElement(
      'agents',
      this.isMediumScreen ? (this.isSmallScreen ? 85 : 160) : 210
    );
  }

  moveToPhoneNumber() {
    this.openPhoneNumber = true;
    if (this.openContact) setTimeout(() => this.contactOpened(), 100);
    else this.openContact = true;
  }

  contactOpened() {
    if (this.openPhoneNumber) {
      setTimeout(() => {
        this.scrollService.scrollToElement(
          'managePhoneNumbers',
          this.isMediumScreen ? (this.isSmallScreen ? 85 : 160) : 215
        );
      });
    }
  }

  toggleContact(open: boolean) {
    this.openContact = open;
    if (!open) this.openPhoneNumber = false;
  }

  async changePassword() {
    this.spinner.start();
    const acrValues = this.config.get('forgerock.changePassword');
    try {
      localStorage.setItem('frFlowType', 'change-password');
      await this.forgerockAuthService.getTokens(acrValues, true);
    } catch (error) {
      console.log(error);
      this.analyticsFacade.trackEvent({
        event: 'forgerock_password_failure',
        account_type: this.accountType,
        method: ''
      });
    }
  }

  updateMfa() {
    this.store.dispatch(ProfileActions.UpdateDaAction({ mfaActive: this.isOn }));
    this.analyticsFacade.trackEvent({
      event: 'multifactor_authentication',
      account_type: this.accountType,
      profile_change_type: this.isOn ? 'MFA Yes' : 'MFA No'
    });
  }

  private buildPreferenceData(billAccounts: BillAccount[]) {
    this.preferenceData = billAccounts.map(billAccount => {
      let deliveryPreference: string;
      // Need to list cases where we would not have preferences and compare to requirements to see if this is the correct way to default
      let canSetPreferences = !billAccount.enabledAFT;
      let email = '';
      let emailId = '';
      let paperId = '';
      // Determine delivery preference, preference ids, and if we can set
      if (_get(billAccount, 'billingPreferences.preferences')) {
        const preferences = billAccount.billingPreferences.preferences;
        canSetPreferences = preferences[0].isModifiable;
        // Check exisisting preferences
        const getsPaper = preferences.some(
          pref =>
            pref.preferenceCode.toLowerCase() === 'documents' &&
            pref.preferenceDeliveryCode.toLowerCase() === DeliveryPreferences.PAPER
        );
        const getsEmail = preferences.some(
          pref =>
            pref.preferenceCode.toLowerCase() === 'documents' &&
            pref.preferenceDeliveryCode.toLowerCase() === DeliveryPreferences.EMAIL
        );
        if (getsPaper && getsEmail) {
          deliveryPreference = DeliveryPreferences.BOTH;
        } else if (getsEmail) {
          deliveryPreference = DeliveryPreferences.EMAIL;
        } else if (getsPaper) {
          deliveryPreference = DeliveryPreferences.PAPER;
        }

        if (getsEmail) {
          const emailPreference = preferences.find(pref => pref.preferenceDeliveryCode === 'EMAIL');
          email = emailPreference.emailAddress;
          emailId = emailPreference.preferenceId;
        }
        if (getsPaper) {
          paperId = preferences.find(pref => pref.preferenceDeliveryCode === 'PAPER').preferenceId;
        }
      }

      // Logic to find risks associated with the current bill account and create a list of the names
      const riskNames = _flatmap(billAccount.policyList, policy => policy.riskDescriptionList);

      const billAccountName = _get(
        billAccount,
        'billingPreferences.accountNickName',
        new PrettyBillingAcctNum().transform(billAccount.billAccountNumber)
      );

      const item: PreferenceItem = {
        billAccountName: billAccountName,
        billAccountNumber: billAccount.billAccountNumber,
        billAccountType: billAccount.isAdvance ? SourceSystemType.Advance : SourceSystemType.Legacy,
        canSetPreferences: canSetPreferences,
        deliveryPreference: deliveryPreference,
        email: email,
        preferenceId: {
          email: emailId,
          paper: paperId
        },
        riskNames: riskNames
      };

      return item;
    });
  }
  private showErrorMessage(
    errorMessageList: Array<string>,
    errorLevel?: ErrorLevels
  ): Array<ErrorObj> {
    let errorList: Array<ErrorObj> = [];
    errorMessageList.forEach((msg: string) => {
      if (!errorList.find(error => error.errorMessage === msg)) {
        errorList = [
          ...errorList,
          { errorMessage: msg, errorLevel: errorLevel || ErrorLevels.HIGH }
        ];
      }
    });
    return errorList;
  }
}
