import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';

import { MembershipType, ProfileActions, profileQuery } from '@amfam/profile/data-access';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { Option, ValidationService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-edit-membership',
  templateUrl: './edit-membership.component.html',
  styleUrl: './edit-membership.component.scss'
})
export class EditMembershipComponent implements OnInit, OnDestroy {
  showForm = false;
  membershipForm = this.buildForm();
  membershipTypeVal$: Observable<string> = of();
  membershipNumberVal$: Observable<string> = of();
  membershipOptions: Option[];
  requestInProgress = false;
  hasError = false;
  customerCareNumber: unknown;
  public alertMessage = '';
  public alertErrorMessage = '';
  showPending = false;
  primaryButtonName: string;
  tertiaryButtonName: string;
  submitted = false;

  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private copyService: CopyService,
    private store: Store
  ) {
    this.membershipOptions = [
      {
        text: 'Executive',
        id: 'EXECUTIVE',
        icon: 'executiveMember'
      },
      {
        text: 'Gold Star',
        id: 'GOLD STAR',
        icon: 'goldStarMember'
      },
      {
        text: 'Business',
        id: 'BUSINESS_MEMBERSHIP',
        icon: 'businessMember'
      }
    ];

    this.store
      .select(BrandSelectors.selectCusCareNumber)
      .pipe(take(1))
      .subscribe(number => (this.customerCareNumber = number));
  }

  ngOnInit() {
    this.buildForm();

    this.membershipNumberVal$ = this.store.select(profileQuery.selectCostcoMembershipNumber);
    this.membershipTypeVal$ = this.store.select(profileQuery.selectCostcoMembershipType);

    this.alertErrorMessage =
      this.copyService.getCopy('profile.membership.editMembershipErrorMessage') +
      `<a href="tel:${this.customerCareNumber}" class='errorPhone'">${this.customerCareNumber}</a>` +
      '.';

    this.alertMessage =
      this.copyService.getCopy('profile.membership.editMembershipMessage') +
      `<a href="tel:${this.customerCareNumber}">${this.customerCareNumber}</a>` +
      '.';

    this.primaryButtonName = this.copyService.getCopy('shared.saveBtn');
    this.tertiaryButtonName = this.copyService.getCopy('shared.cancel');
  }

  editMembership() {
    this.showForm = true;
    this.showPending = false;
  }

  editMembershipCancel() {
    this.showForm = false;
    this.hasError = false;

    this.membershipForm.controls.membershipNumber.setErrors({
      invalidMembershipNumber: false
    });

    this.membershipForm.controls.membershipNumber.clearValidators();
    this.membershipForm.controls.membershipNumber.markAsUntouched();
    this.membershipForm.reset();
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  submit() {
    this.submitted = true;
    if (this.membershipForm.valid) {
      // start the spinner
      this.requestInProgress = true;
      this.store.dispatch(ProfileActions.MakeRequestAction());

      // dispatch the request to update membership
      this.store.dispatch(
        ProfileActions.UpdateMembership({
          membershipType: (this.membershipForm.value.membershipType as unknown as Option)
            .id as MembershipType,
          membershipNumber: this.membershipForm.value.membershipNumber
        })
      );

      this.store
        .select(profileQuery.selectMembership)
        .pipe(takeUntil(this.stop$))
        .subscribe(state => {
          this.showPending = state.membershipEditSuccess;
          this.hasError = state.membershipEditFailDueToInvalidNumber;
        });

      if (!this.hasError) {
        this.showForm = false;
        this.membershipForm.reset();
        this.membershipForm.controls.membershipNumber.setErrors({
          invalidMembershipNumber: false
        });
      } else {
        this.membershipForm.controls.membershipNumber.setErrors({
          invalidMembershipNumber: true
        });
      }
    }
  }

  changeHandler() {
    this.hasError = false;
  }

  private buildForm() {
    return this.formBuilder.group(
      {
        membershipType: ['', [Validators.required]],
        membershipNumber: [
          '',
          [
            ValidationService.validateWithKey(
              Validators.required,
              'profile.membershipUpdate.required'
            ),
            ValidationService.costcoMembershipValidator,
            ValidationService.costcoMembershipLengthValidator
          ]
        ]
      },
      {
        updateOn: 'blur'
      }
    );
  }
}
