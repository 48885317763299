import { SearchResult } from '@amfam/claim/drp-search/feature';

import { RepairServiceAction, RepairServiceActionTypes } from './repair-service.actions';

export const REPAIRSERVICE_FEATURE_KEY = 'repairService';

export interface RepairServiceState {
  loading: boolean;
  error: boolean;
  selectedShop: SearchResult;
}

export const initialState: RepairServiceState = {
  loading: false,
  error: false,
  selectedShop: null
};

export function repairServiceReducer(
  state: RepairServiceState = initialState,
  action: RepairServiceAction
): RepairServiceState {
  switch (action.type) {
    case RepairServiceActionTypes.CreateRepairRequest:
      return Object.assign({}, state, {
        loading: true,
        selectedShop: action.payload.selectedShop
      });

    case RepairServiceActionTypes.CreateRepairRequestSuccess:
      return Object.assign({}, state, {
        loading: false,
        error: false
      });

    case RepairServiceActionTypes.CreateRepairRequestFail:
      return Object.assign({}, state, {
        loading: false,
        error: true
      });

    case RepairServiceActionTypes.ResetRepairService:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
}
