<!-- AMFAM Design System UI Component : Card -->
<section
  [ngClass]="cardBg"
  aria-label="info dialog"
  aria-labelledby="cardMessage"
  class="ds-card"
  role="card"
>
  <!-- Image -->
  @if (img) {
    <img
      src="{{ imgSrc }}"
      width="100%"
      height="180"
      class="ds-card__img top"
      alt="{{ title }}"
      role="image"
    />
  }
  <!-- Card Header -->
  <div class="ds-card__header">
    @if (icon) {
      <div class="icon">
        <i class="{{ iconName }}"></i>
      </div>
    }
    <div class="text" id="cardMessage" data-cy="cardTitle">{{ title }}</div>
  </div>
  <!-- Card Body -->
  <div class="ds-card__body" data-cy="cardMessage">
    {{ message }}
  </div>
  <!-- Card Action -->
  <div class="ds-card__action">
    <call-to-action
      [primaryButtonName]="primaryButtonName"
      [secondaryButtonName]="secondaryButtonName"
      [fullWidthButtons]="false"
      (primaryEvent)="primaryButtonEvent.emit()"
      (secondaryEvent)="secondaryButtonEvent.emit()"
      [equalWidthButtons]="true"
    >
    </call-to-action>
  </div>
</section>
<!-- / AMFAM Design System UI Component : Card -->
