import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { AnalyticsActions, AnalyticsSelectors } from '@amfam/shared/analytics';
import { digitalAccountQuery } from '@amfam/shared/digital-account/data-access';
import { userQuery } from '@amfam/shared/user';
import { CopyService, UtilService } from '@amfam/shared/utility/shared-services';
import { ErrorObj, LoadingSpinnerService } from '@amfam/ui-kit';

import { initiateEnrollment, validateEmail } from '../+state/mae-feature.actions';
import { MaeAnalytics } from '../mae-constants';

@Component({
  selector: 'ds-initiation',
  templateUrl: './initiation.component.html',
  styleUrls: ['./initiation.component.scss']
})
export class InitiationComponent implements OnInit, OnDestroy {
  emails: any[] = [];
  emailSelectionForm: UntypedFormGroup;
  emailInput: AbstractControl;
  errorList: Array<ErrorObj> = [];
  emailSelectionHeader: string;
  emailSelectionConditionHeader: string;
  public isEmailError = false;
  duplicateEmail: boolean;
  hasError: boolean;
  loading: Observable<boolean>;
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private copyService: CopyService,
    private spinnerService: LoadingSpinnerService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.spinnerService.start();

    this.loading = this.store
      .select(userQuery.getFirstName)
      .pipe(map((firstName: string) => firstName === ''));

    this.store
      .select(userQuery.getEmails)
      .pipe(takeUntil(this.stop$))
      .subscribe(emails => {
        this.spinnerService.stop();

        this.isEmailError = emails.length === 0 ? true : false;
        const defaultEmail = emails.length === 1 ? emails[0].emailAddress : '';
        this.emails = emails.length > 0 ? emails : [];
        this.setupForm(defaultEmail);
      });
    this.emailInput = this.emailSelectionForm.get('emailInput');
    this.emailSelectionHeader = this.copyService.getCopy('enrollment.emailSelectionHeader');
    this.emailSelectionConditionHeader = this.copyService.getCopy(
      'enrollment.emailSelectionConditionHeader'
    );
    this.store
      .select(AnalyticsSelectors.getAdobeAnalyticsLoaded)
      .pipe(
        filter(loaded => loaded),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(
          AnalyticsActions.sendAnalytic({
            options: {
              page: MaeAnalytics.pageAgentProgramEnrollmentStart
            }
          })
        );
      });
  }

  createAccount() {
    this.spinnerService.start();

    const correlationId = this.utilService.generateId();
    const email = this.emailInput.value;
    this.store.dispatch(validateEmail({ email, correlationId }));

    this.store
      .select(digitalAccountQuery.getDigitalAccountState)
      .pipe(
        filter(state => state.correlationId === correlationId),
        take(1)
      )
      .subscribe(state => {
        this.duplicateEmail = _get(state, 'emailAssingedToOthers', false);
        this.spinnerService.stop();
        if (!this.duplicateEmail) {
          this.store.dispatch(initiateEnrollment({ email: this.emailInput.value }));
        }
      });
  }

  setupForm(defaultEmail: string) {
    this.emailSelectionForm = this.formBuilder.group({
      emailInput: [defaultEmail, Validators.compose([Validators.required])]
    });
    this.emailInput = this.emailSelectionForm.controls['emailInput'];
  }
  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
