<div class="align">
  <div class="pill" [ngClass]="(billingPill$ | async)?.pillColor" data-cy="billingPill">
    <p class="pill-text">{{ (billingPill$ | async)?.statusText }}</p>
  </div>
  @if (amount) {
    <p class="tile-amount" [ngClass]="(billingPill$ | async)?.amountColor">
      {{ amount }}
    </p>
  }
</div>
