import { Action } from '@ngrx/store';

import { EdeliveryTermsAndConditionsResponse } from '../models/e-delivery-terms-and-conditions.model';

export enum EDeliveryTermsAndConditionsActionTypes {
  LoadEDeliveryTermsAndConditions = '[EDeliveryTermsAndConditions] Load EDeliveryTermsAndConditionss',
  LoadEDeliveryTermsAndConditionssSuccess = '[EDeliveryTermsAndConditions] Load EDeliveryTermsAndConditionss Success',
  LoadEDeliveryTermsAndConditionssFailure = '[EDeliveryTermsAndConditions] Load EDeliveryTermsAndConditionss Failure'
}

export class LoadEDeliveryTermsAndConditions implements Action {
  readonly type = EDeliveryTermsAndConditionsActionTypes.LoadEDeliveryTermsAndConditions;
  constructor(public payload: { brandId: string }) {}
}

export class LoadEDeliveryTermsAndConditionssSuccess implements Action {
  readonly type = EDeliveryTermsAndConditionsActionTypes.LoadEDeliveryTermsAndConditionssSuccess;
  constructor(
    public payload: { richContent: EdeliveryTermsAndConditionsResponse; brandId: string }
  ) {}
}

export class LoadEDeliveryTermsAndConditionssFailure implements Action {
  readonly type = EDeliveryTermsAndConditionsActionTypes.LoadEDeliveryTermsAndConditionssFailure;
  constructor(public payload: { error: any; brandId: string }) {}
}

export type EDeliveryTermsAndConditionsActions =
  | LoadEDeliveryTermsAndConditions
  | LoadEDeliveryTermsAndConditionssSuccess
  | LoadEDeliveryTermsAndConditionssFailure;

export const fromPoliciesActions = {
  LoadEDeliveryTermsAndConditions,
  LoadEDeliveryTermsAndConditionssSuccess,
  LoadEDeliveryTermsAndConditionssFailure
};
