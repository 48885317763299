import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from 'apps/myaccount/src/app/core/store';
import { get as _get, keyBy as _keyBy } from 'lodash';

import { ReplaceVehicle } from '@amfam/policy/models';

import { AddVehicleCoverage } from '../../models/add-vehicle-rate.model';
import { ADD_VEHICLE_DATA_KEY, AddVehicleDataState } from '../add-vehicle-common.selector';

const selectNewVehicleQuoteState = createFeatureSelector<AddVehicleDataState>(ADD_VEHICLE_DATA_KEY);

export const selectVehicleQuoteEntities = createSelector(
  selectNewVehicleQuoteState,
  (state: AddVehicleDataState): AddVehicleDataState['addVehicleQuoteState']['entities'] =>
    _get(state, 'addVehicleQuoteState.entities')
);

export const selectVehicleQuoteLoading = createSelector(
  selectVehicleQuoteEntities,
  (state: AddVehicleDataState['addVehicleQuoteState']['entities']) => {
    const tempState = state ? Object.values(state)[0] : null;
    return tempState ? tempState.loading : true;
  }
);

export const selectNewVehicleTPIs = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (userEntities, loading): AddVehicleDataState['addVehicleQuoteState']['entities'][0]['tpis'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'tpis')
);

export const selectNewVehicleDrivers = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (userEntities, loading): AddVehicleDataState['addVehicleQuoteState']['entities'][0]['drivers'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'drivers')
);

export const selectNewVehicleCoverages = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleQuoteState']['entities'][0]['vehicleCoverages'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'vehicleCoverages[0].coverages')
);

export const selectNewVehicleAppliedDiscounts = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleQuoteState']['entities'][0]['vehicleCoverages'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'vehicleCoverages[0].appliedDiscounts')
);

export const selectNewVehiclesInPolicy = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (
    userEntities,
    loading
  ): AddVehicleDataState['addVehicleQuoteState']['entities'][0]['vehiclesInPolicy'] =>
    loading ? [] : _get(Object.values(userEntities)[0], 'vehiclesInPolicy')
);

export const selectNewVehicleRateError = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (userEntities, loading): AddVehicleDataState['addVehicleQuoteState']['entities'][0]['error'] => {
    if (!loading) {
      return _get(Object.values(userEntities)[0], 'error');
    }
  }
);

export const selectNewVehicleRate = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (userEntities, loading): AddVehicleDataState['addVehicleQuoteState']['entities'][0]['rate'] => {
    if (!loading) {
      return _get(Object.values(userEntities)[0], 'rate');
    }
  }
);

export const selectNewVehicleWorkOrderNumber = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (userEntities, loading): string => {
    if (!loading) {
      return _get(Object.values(userEntities)[0], 'submissionId');
    }
  }
);

export const selectNewVehiclePolicyNumber = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (userEntities, loading): string => {
    if (!loading) {
      return Object.values(userEntities)[0]?.policyNumber;
    }
  }
);

export const selectNewVehicleVin = createSelector(
  selectVehicleQuoteEntities,
  selectVehicleQuoteLoading,
  (userEntities, loading): string => {
    if (!loading) {
      return Object.values(userEntities)[0]?.vehicle?.vin;
    }
  }
);

export const selectHasTPMPackageAndERSRTAARequired = createSelector(
  selectNewVehicleCoverages,
  selectVehicleQuoteLoading,
  (
    vehicleCoverages: AddVehicleCoverage[],
    loading,
    matchingCriteria: { coveragesCodeList: string[]; TPMCoverageCode: string }
  ): boolean => {
    if (!loading) {
      const coverageCodeKeyedObj = _keyBy(
        vehicleCoverages,
        vehicleCoverage => vehicleCoverage.publicID
      );
      if (
        coverageCodeKeyedObj[matchingCriteria.TPMCoverageCode] &&
        coverageCodeKeyedObj[matchingCriteria.TPMCoverageCode].selected
      ) {
        let hasRequiredFlagForAllCodes = true;
        for (const vehicleCoverageCode of matchingCriteria.coveragesCodeList) {
          if (!_get(coverageCodeKeyedObj[vehicleCoverageCode], 'required')) {
            hasRequiredFlagForAllCodes = false;
          }
        }
        return hasRequiredFlagForAllCodes;
      }

      return false;
    }
  }
);

export const selectFuturePayments = createSelector(
  selectVehicleQuoteEntities,
  (entities): AddVehicleDataState['addVehicleQuoteState']['entities'][0]['paymentSchedule'] =>
    entities && Object.values(entities)[0]?.paymentSchedule
);

export const getReplaceVehicleState = (state: RootState) => state.replaceVehicle;

export const getVehicleRiskSelectionContext = (state: ReplaceVehicle) => state.riskSelectionContext;

export const selectVehicleRiskSelectionContextType = createSelector(
  getReplaceVehicleState,
  getVehicleRiskSelectionContext
);

export const addVehicleQuoteQuery = {
  selectVehicleQuoteLoading,
  selectNewVehicleQuoteState,
  selectNewVehicleTPIs,
  selectNewVehicleDrivers,
  selectNewVehicleCoverages,
  selectNewVehicleAppliedDiscounts,
  selectNewVehiclesInPolicy,
  selectNewVehicleRateError,
  selectNewVehicleRate,
  selectNewVehicleWorkOrderNumber,
  selectNewVehiclePolicyNumber,
  selectHasTPMPackageAndERSRTAARequired,
  selectFuturePayments,
  selectNewVehicleVin,
  selectVehicleRiskSelectionContextType
};
