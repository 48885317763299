import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ds-table',
  templateUrl: './ds-table.component.html',
  styleUrls: ['./ds-table.component.scss']
})
export class DsTableComponent implements OnInit {
  @Input() public rows: Array<any> = [];
  @Input() public columns: [{}];
  @Input() public headerHeight: any;
  @Input() public rowDetail: any;
  @Input() public tableClasses: any;

  constructor() {}

  ngOnInit() {}
}
