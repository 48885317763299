@if (party && party.match >= 1) {
  <div id="verify-options">
    <p class="margin-bottom-20">
      <span content [contentPath]="'enrollment.verificationUserFoundText'"></span>
    </p>
    <div class="row verification-list">
      <div class="column twelve">
        <ul data-cy="verificationMethodList" class="margin-bottom-10">
          @if (showSSN()) {
            <li (click)="completeStep('ssn')">
              <a data-cy="enterSSNEnrollVerifyLink" href="javascript:void(0)"
                >{{ verifyOptions.ssn
                }}<span class="margin-top-5 float-right icon-chevron-right"></span
              ></a>
            </li>
          }
          @if (showEmail() && showSSN()) {
            <hr aria-hidden="true" class="dash" />
          }
          @if (showEmail()) {
            <li (click)="completeStep('email')">
              <a data-cy="emailMeVerifyLink" href="javascript:void(0)"
                >{{ forgerockEnabled ? verifyOptions.emailCode : verifyOptions.emailLink
                }}<span class="margin-top-5 float-right icon-chevron-right"></span
              ></a>
            </li>
          }
          @if (showText() && (showEmail() || showSSN())) {
            <hr aria-hidden="true" class="dash" />
          }
          @if (showText()) {
            <li (click)="completeStep('text')">
              <a data-cy="textMeCodeEnrollVerifyLink" href="javascript:void(0)"
                >{{ verifyOptions.textCode
                }}<span class="margin-top-5 float-right icon-chevron-right"></span
              ></a>
            </li>
          }
          @if (showPhone() && (showText() || showEmail() || showSSN())) {
            <hr aria-hidden="true" class="dash" />
          }
          @if (showPhone()) {
            <li (click)="completeStep('phone')">
              <a data-cy="callMeWithCodeEnrollVerifyLink" href="javascript:void(0)"
                >{{ verifyOptions.callCode
                }}<span class="margin-top-5 float-right icon-chevron-right"></span
              ></a>
            </li>
          }
          @if (showPolicy() && (showPhone() || showText() || showEmail() || showSSN())) {
            <hr aria-hidden="true" class="dash" />
          }
          @if (showPolicy()) {
            <li (click)="completeStep('policy-number')">
              <a data-cy="enterPolicyNumberEnrollVerifyLink" href="javascript:void(0)"
                >{{ verifyOptions.policyNumber
                }}<span class="margin-top-5 float-right icon-chevron-right"></span
              ></a>
            </li>
          }
        </ul>
      </div>
    </div>
    <hr aria-hidden="true" />
    <div class="row">
      <div id="back" class="caption margin-top-10">
        <a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block"
          ><span class="icon-chevron-left"></span> Back</a
        >
      </div>
    </div>
  </div>
}

@if (party && party.match < 1) {
  <div>
    <p
      content
      data-cy="enrollmentVerificationApologyText"
      [contentPath]="'enrollment.verificationApologyText'"
    ></p>
    <button
      ds-button
      type="button"
      (click)="goBackOneStep()"
      content
      [contentPath]="'shared.retry'"
    ></button>
  </div>
}
