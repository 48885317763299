<div class="table-body-cell-label-content expandable-cell">
  <span class="overflow-hidden overflow-ellipsis payment-method-wrapper">
    <div class="flex items-center">
      <ds-credit-card-icon
        class="pad-right-10"
        [ccType]="icon"
        [showDefault]="'icon'"
        [ccIconHeight]="24"
      ></ds-credit-card-icon>
      @if (title) {
        <span
          data-cy="scheduledPaymentName"
          class="overflow-hidden overflow-ellipsis payment-method-name"
          title="{{ title }}"
        >
          {{ title }}</span
        >
      }
    </div>
    @if (paymentAccount?.creditCard?.cardType) {
      <p class="cell-secondary whitespace-nowrap">
        {{ accountNumber | returnLast: 4 | redact: paymentAccount }}
      </p>
    }
    @if (paymentAccount?.achWithdrawal) {
      <p class="cell-secondary whitespace-nowrap">
        {{ accountNumber | returnLast: 3 | redact: paymentAccount }}
      </p>
    }
    @if (subLine1) {
      <p class="cell-secondary whitespace-nowrap" title="{{ subLine1 }}">
        {{ subLine1 }}
      </p>
    }
  </span>
</div>
