import { compareAsc, parse } from 'date-fns';
import { get as _get } from 'lodash';

import { Party } from '@amfam/shared/models';

import { UserAction, UserActionTypes } from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface UserState {
  businessName?: string;
  customerId?: string;
  displayName: string; // update with party.displayName()
  editedEmailAddress?: string;
  emailAddress?: string; // update with party.primaryEmail()
  experienceId?: string;
  firstName: string; // update with party.firstName
  greetingName: string;
  isCustomer?: boolean;
  lastName: string; // update with party.lastName
  lastLoggedInOn: string;
  loginName: string; // TODO: should be refactored to userId
  partnerId?: string;
  party?: Party;
  smImpersonator?: string;
  smImpersonatorUserID?: string;
  trackId: string;
  typeOfAccountCode: string;
  token: string;
  waid?: string;
  forgerockId?: string;
  loading: boolean;
  loaded: boolean;
}

export const initialState: UserState = {
  businessName: '',
  customerId: '',
  displayName: '',
  editedEmailAddress: '',
  emailAddress: '',
  experienceId: '',
  firstName: '',
  greetingName: '',
  isCustomer: false,
  lastLoggedInOn: '',
  lastName: '',
  loginName: '',
  partnerId: '',
  token: '',
  party: new Party(),
  smImpersonator: '',
  smImpersonatorUserID: null,
  trackId: '',
  typeOfAccountCode: '',
  waid: '',
  forgerockId: '',
  loading: false,
  loaded: false
};

const EPOCH_DATE: Date = parse('1970-01-01T00:00:00.000Z');
function checkLastLogin(lastLoggedInOn: string): string {
  return compareAsc(parse(lastLoggedInOn), EPOCH_DATE) === 0 ? '' : lastLoggedInOn;
}

export function reducer(state: UserState = initialState, action: UserAction): UserState {
  switch (action.type) {
    case UserActionTypes.LoginSuccess:
      const payloadIsCustomer = (action.payload.customerId && action.payload.customerId) !== '';
      const emailAddress =
        state.editedEmailAddress && state.editedEmailAddress !== ''
          ? state.editedEmailAddress
          : action.payload.emailAddress;

      const currentStateUserObject: UserState = Object.assign({}, state);

      const actionPayloadUserObject: UserState = {
        businessName: action.payload.businessName,
        customerId: action.payload.customerId,
        displayName: action.payload.displayName,
        experienceId: action.payload.experienceId,
        firstName: action.payload.firstName,
        greetingName: action.payload.greetingName || state.greetingName,
        isCustomer: payloadIsCustomer,
        lastLoggedInOn: checkLastLogin(action.payload.lastLoggedInOn),
        lastName: action.payload.lastName,
        loginName: action.payload.userId,
        partnerId: action.payload.partnerId,
        smImpersonator: action.payload.smImpersonator,
        smImpersonatorUserID: action.payload.smImpersonatorUserID,
        trackId: action.payload.trackId,
        token: action.payload.token,
        typeOfAccountCode: action.payload.typeOfAccountCode,
        waid: action.payload.WAID,
        forgerockId: action.payload.forgeRockId,
        loading: false,
        loaded: true
      };

      let userDiff: any = Object.keys(actionPayloadUserObject).reduce((diff, key) => {
        if (currentStateUserObject[key] === actionPayloadUserObject[key]) {
          return diff;
        }
        return {
          ...diff,
          [key]: actionPayloadUserObject[key]
        };
      }, {});

      // if it is not a business user omit unneeded values as we are already setting them in UpdateParty action.
      // but for instance, if it is a business user, we need to keep the values as is as there is no party call to fetch that info.
      // if (payloadIsCustomer) {
      //   userDiff = _omit(userDiff, ['firstName', 'lastName', 'displayName', 'emailAddress']);
      // } else {
      //   userDiff.emailAddress = emailAddress;
      // }

      userDiff.emailAddress = emailAddress;
      return Object.assign({}, state, userDiff);

    case UserActionTypes.UpdatePartyType:
      if (_get(action, 'payload.partyIdentifier')) {
        return Object.assign({}, state, {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          greetingName: action.payload.greetingName,
          displayName: action.payload.displayName,
          emailAddress: action.payload.primaryEmail.emailAddress,
          partnerId: action.payload.partnerIdentifier,
          party: action.payload
        });
      }
      return state;

    case UserActionTypes.UpdateUserEmail:
      return { ...state, emailAddress: action.payload };

    case UserActionTypes.UpdateUserType:
      if (action.payload.emailAddress && action.payload.emailAddress !== '') {
        return Object.assign({}, state, action.payload, {
          editedEmailAddress: action.payload.emailAddress
        });
      }
      return Object.assign({}, state, action.payload);

    /* TODO do we really need this?
    case session.GET_VALIDATION_STATUS_FOR_EMAIL_FAIL:
      return Object.assign({}, state, {
        error: action.payload
      });
*/
    case UserActionTypes.EditUserId:
      return Object.assign({}, state, {
        loginName: action.payload
      });

    case UserActionTypes.UserInfoSuccess: {
      const userState: UserState = {
        firstName: action.payload.firstName,
        greetingName: action.payload.greetingName,
        lastName: action.payload.lastName,
        customerId: action.payload.customerId,
        emailAddress: action.payload.emailAddress,
        typeOfAccountCode: action.payload.typeOfAccountCode,
        partnerId: action.payload.partnerId,
        waid: action.payload.WAID,
        displayName: action.payload.displayName,
        trackId: action.payload.trackId,
        lastLoggedInOn: action.payload.lastLoggedInOn,
        loginName: action.payload.userId,
        businessName: action.payload.businessName,
        experienceId: action.payload.experienceId,
        smImpersonator: action.payload.smImpersonator,
        smImpersonatorUserID: action.payload.smImpersonatorUserID,
        token: action.payload.token,
        loading: false,
        loaded: true
      };

      return Object.assign({}, state, userState);
    }
    case UserActionTypes.EditSmImpersonatrUserId: {
      return Object.assign({}, state, { smImpersonatorUserID: action.payload });
    }
    case UserActionTypes.EditCustomerId: {
      return Object.assign({}, state, { customerId: action.payload });
    }
    case UserActionTypes.EditWAID: {
      return Object.assign({}, state, { waid: action.payload });
    }
    case UserActionTypes.EditExperienceId: {
      return Object.assign({}, state, { experienceId: action.payload });
    }
    default:
      return state;
  }
}
