export interface Policy101ClicktipModel {
  codes: string[];
  title: string;
  text: string;
}

export interface FindVin {
  linkText: string;
  headerText: string;
  definition: string;
  documentsSubHeader: string;
  vinOnDocument: string;
  yourVehicleSubHeaderText: string;
  vinOnVehicle: string;
  content: VinContent[];
}

interface VinContent {
  header: string;
  icon: string;
  body: string;
}
