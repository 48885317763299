<div class="column twelve" [formGroup]="parentFormGroup" *ngIf="options?.length > 0">
  <div *ngFor="let option of options">
    <div
      class="question"
      [ngClass]="{
        disabled: setDisableState || disableRadioControl,
        selected: unselectAll ? false : option.id === parentFormGroup.controls[control].value?.id
      }"
      (click)="toggleDynamicOption(option)"
    >
      <div class="flex row items-center">
        <input
          [formControlName]="control"
          [value]="option"
          id="{{ option.id }}"
          class="margin-right-6 question-input"
          type="radio"
          [attr.data-cy]="option?.dataCy"
          [checked]="
            option.id === selectedAnswer ||
            option.id === parentFormGroup.controls[control].value?.id
          "
        />
        <label for="{{ option.id }}" data-cy="">{{ option.text }} </label>
      </div>

      <ng-container *ngIf="option.helpText && option.helpText.length > 0">
        <div
          *ngFor="let helpText of option.helpText"
          class="flex row caption"
          [ngClass]="{ warning: helpText.warning }"
        >
          {{ helpText.text }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
