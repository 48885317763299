import { createAction } from '@ngrx/store';

import { DigitalAccount, SecurityQuestion } from '@amfam/shared/digital-account/data-access';
import { Party, PartyEmail, PartyPhone } from '@amfam/shared/models';
import { UserLogin } from '@amfam/shared/user';

import { UpdateEmailData } from '../models/email-validation.model';
import {
  EmailPayloadToGetValidationStatus,
  MembershipType,
  type PendingEmailVerification
} from '../models/profile.model';

export type Entry = PartyEmail | PartyPhone;
export type MethodType = 'emails' | 'phones';

export interface EmailContactEntryActionPayload {
  data: PartyEmail;
  methodType: 'emails';
}

export interface PhoneContactEntryActionPayload {
  data: PartyPhone;
  methodType: 'phones';
}

export type ContactEntryActionPayload =
  | EmailContactEntryActionPayload
  | PhoneContactEntryActionPayload;

export interface DeleteContactEntryActionPayload {
  data: string;
  methodType: MethodType;
}

export interface ChangeContactEntryActionPayload {
  data: {
    oldEntry?: Entry;
    newEntry: Entry;
  };
  methodType: MethodType;
}

export interface ValidateEmailActionPayload {
  newEmail: PartyEmail;
  oldEmail: PartyEmail;
}

export interface UpdateMembershipPayload {
  membershipType: MembershipType;
  membershipNumber: string;
}

// The generic term 'Contact Entry' is used here in place of 'Email Address' or 'Phone Number'
// so actions and functions can be reused due to the fact that both
// email and phone can be altered in much the same way

export enum ProfileActionTypes {
  PROFILE_LOAD = '[Profile] Load Profile',
  ADD_CONTACT_ENTRY = '[Profile] Add New Contact Entry',
  ADD_CONTACT_ENTRY_SUCCESS = '[Profile] Add Contact Entry Success',
  DELETE_CONTACT_ENTRY = '[Profile] Delete Contact Entry',
  DELETE_CONTACT_ENTRY_SUCCESS = '[Profile] Delete Contact Entry Success',
  CHANGE_CONTACT_ENTRY = '[Profile] Change Contact Entry',
  CHANGE_CONTACT_ENTRY_SUCCESS = '[Profile] Change Contact Entry Success',
  CONTACT_ENTRY_FAIL = '[Profile] Add Contact Entry Fail',
  CHANGE_SECURITY_INFO = '[Profile] Change Security Information',
  CHANGE_SECURITY_INFO_SUCCESS = '[Profile] Change Security Information Success',
  CHANGE_SECURITY_INFO_FAIL = '[Profile] Change Security Information Fail',
  FETCH_DATA = '[Profile] Fetch Party Data',
  FETCH_PARTY_DATA_SUCCESS = '[Profile] Fetch Party Data Success',
  FETCH_PARTY_DATA_FAIL = '[Profile] Fetch Party Data Fail',
  FETCH_DA_DATA_SUCCESS = '[Profile] Fetch Digital Account Data Success',
  FETCH_DA_DATA_FAIL = '[Profile] Fetch Digital Account Data Fail',
  UPDATE_DA = '[Profile] Update Digital Account',
  UPDATE_DA_SUCCESS = '[Profile] Update Digital Account Success',
  UPDATE_DA_FAIL = '[Profile] Update Digital Account Fail',
  UPDATE_EMAIL = '[Profile] Update MyAccount Email',
  UPDATE_EMAIL_SUCCESS = '[Profile] Update MyAccount Email Success',
  UPDATE_EMAIL_FAIL = '[Profile] Update MyAccount Email Fail',
  UPDATE_SECURITY_QUESTIONS = '[Profile] Update Security Questions',
  MAKE_REQUEST = '[Profile] Make request from Profile',
  UPDATE_CONTACT_METHOD_VALUES = '[Profile] Update Contact Method Values',
  NOTIFICATION_SUCCESS = '[Profile] Send Email Notificiation Success',
  NOTIFICATION_FAIL = '[Profile] Send Email Notification Fail',
  VALIDATE_EMAIL = '[Profile] Send Email for Validation',
  VALIDATE_EMAIL_SUCCESS = '[Profile] Send Email for Validation Success',
  VALIDATE_EMAIL_FAIL = '[Profile] Send Email for Validation Fail',
  MAGIC3_SEARCH_SUCCESS = '[Profile] Email Validation Magic 3 Search Success',
  REFRESH_PARTY_DATA = '[Profile] Refresh Party Data',
  GET_VALIDATION_STATUS_FOR_EMAIL = '[Profile] Get Validation Status For Email',
  GET_VALIDATION_STATUS_FOR_EMAIL_FAIL = '[Profile] Get Validation Status For Email Fail',
  GET_VALIDATION_STATUS_FOR_PENDING_EMAIL = '[Profile] Get Validation Status For Pending Email',
  GET_VALIDATION_STATUS_FOR_PENDING_EMAIL_SUCCESS = '[Profile] Get Validation Status For Pending Email Success',
  CLEAR_TOAST = '[Profile] Clear Toast',
  UPDATE_MEMBERSHIP = '[Profile] Update Membership',
  UPDATE_MEMBERSHIP_SUCCESS = '[Profile] Update Membership Success',
  UPDATE_MEMBERSHIP_FAIL = '[Profile] Update Membership Fail'
}

export const ProfileLoad = createAction(ProfileActionTypes.PROFILE_LOAD, (payload: UserLogin) => ({
  payload
}));

// The action for adding a new email or phone contact
export const AddContactEntryAction = createAction(
  ProfileActionTypes.ADD_CONTACT_ENTRY,
  (payload: ContactEntryActionPayload) => ({ payload })
);

export const ChangeContactEntryAction = createAction(
  ProfileActionTypes.CHANGE_CONTACT_ENTRY,
  (payload: ChangeContactEntryActionPayload) => ({ payload })
);

export const ChangeContactEntrySuccessAction = createAction(
  ProfileActionTypes.CHANGE_CONTACT_ENTRY_SUCCESS,
  (payload: { methodType: MethodType; data: Entry[] }) => ({ payload })
);

export const DeleteContactEntryAction = createAction(
  ProfileActionTypes.DELETE_CONTACT_ENTRY,
  (payload: DeleteContactEntryActionPayload) => ({ payload })
);

export const AddContactEntrySuccessAction = createAction(
  ProfileActionTypes.ADD_CONTACT_ENTRY_SUCCESS,
  (payload: {
    setPrimaryEmail: boolean;
    methodType: MethodType;
    emails?: Party['emails'];
    phones?: Party['phones'];
  }) => ({
    payload
  })
);

export const ContactEntryFailAction = createAction(
  ProfileActionTypes.CONTACT_ENTRY_FAIL,
  payload => ({ payload })
);

export const ChangeSecurityInfoAction = createAction(
  ProfileActionTypes.CHANGE_SECURITY_INFO,
  payload => ({ payload })
);

export const ChangeSecurityInfoSuccessAction = createAction(
  ProfileActionTypes.CHANGE_SECURITY_INFO_SUCCESS,
  payload => ({ payload })
);

export const ChangeSecurityInfoFailAction = createAction(
  ProfileActionTypes.CHANGE_SECURITY_INFO_FAIL,
  payload => ({ payload })
);

export const DeleteContactEntrySuccessAction = createAction(
  ProfileActionTypes.DELETE_CONTACT_ENTRY_SUCCESS,
  (payload: DeleteContactEntryActionPayload) => ({ payload })
);

export const FetchDataAction = createAction(
  ProfileActionTypes.FETCH_DATA,
  (payload: UserLogin) => ({ payload })
);

export const FetchPartyDataSuccessAction = createAction(
  ProfileActionTypes.FETCH_PARTY_DATA_SUCCESS,
  (payload: Party) => ({ payload })
);

export const FetchPartyDataFailAction = createAction(
  ProfileActionTypes.FETCH_PARTY_DATA_FAIL,
  payload => ({ payload })
);

export const FetchDaDataSuccessAction = createAction(
  ProfileActionTypes.FETCH_DA_DATA_SUCCESS,
  (payload: DigitalAccount) => ({ payload })
);

export const FetchDaDataFailAction = createAction(
  ProfileActionTypes.FETCH_DA_DATA_FAIL,
  payload => ({ payload })
);

export const UpdateDaAction = createAction(
  ProfileActionTypes.UPDATE_DA,
  (payload: DigitalAccount) => ({ payload })
);

export const UpdateDaSuccessAction = createAction(
  ProfileActionTypes.UPDATE_DA_SUCCESS,
  (payload: DigitalAccount) => ({ payload })
);

export const UpdateDaFailAction = createAction(ProfileActionTypes.UPDATE_DA_FAIL, payload => ({
  payload
}));

export const UpdateMembership = createAction(
  ProfileActionTypes.UPDATE_MEMBERSHIP,
  (payload: UpdateMembershipPayload) => ({ payload })
);

export const UpdateMembershipSuccessAction = createAction(
  ProfileActionTypes.UPDATE_MEMBERSHIP_SUCCESS,
  (payload: UpdateMembershipPayload) => ({ payload })
);

export const UpdateMembershipFailAction = createAction(
  ProfileActionTypes.UPDATE_MEMBERSHIP_FAIL,
  payload => ({
    payload
  })
);

export const UpdateEmailAction = createAction(
  ProfileActionTypes.UPDATE_EMAIL,
  (payload: UpdateEmailData) => ({ payload })
);

export const UpdateEmailSuccessAction = createAction(
  ProfileActionTypes.UPDATE_EMAIL_SUCCESS,
  (payload: UpdateEmailData) => ({ payload })
);

export const UpdateEmailFailAction = createAction(
  ProfileActionTypes.UPDATE_EMAIL_FAIL,
  payload => ({ payload })
);

// This used to start the loading spinner
export const MakeRequestAction = createAction(ProfileActionTypes.MAKE_REQUEST);

// This is a generic action that will merge the payload into the store
export const UpdateSecurityQuestionsAction = createAction(
  ProfileActionTypes.UPDATE_SECURITY_QUESTIONS,
  (payload: { securityQuestions: SecurityQuestion[] }) => ({ payload })
);

export const UpdateContactMethodValuesAction = createAction(
  ProfileActionTypes.UPDATE_CONTACT_METHOD_VALUES,
  (payload: ContactEntryActionPayload) => ({ payload })
);

export const EmailNotificationSuccessAction = createAction(ProfileActionTypes.NOTIFICATION_SUCCESS);

export const EmailNotificationFailAction = createAction(
  ProfileActionTypes.NOTIFICATION_FAIL,
  payload => ({ payload })
);

export const ValidateEmailAction = createAction(
  ProfileActionTypes.VALIDATE_EMAIL,
  (payload: ValidateEmailActionPayload) => ({ payload })
);

export const ValidateEmailSuccessAction = createAction(ProfileActionTypes.VALIDATE_EMAIL_SUCCESS);

export const ValidateEmailFailAction = createAction(
  ProfileActionTypes.VALIDATE_EMAIL_FAIL,
  (payload?) => ({ payload })
);

export const Magic3SearchSuccessAction = createAction(
  ProfileActionTypes.MAGIC3_SEARCH_SUCCESS,
  (payload: string) => ({ payload })
);
export const RefreshPartyDataAction = createAction(ProfileActionTypes.REFRESH_PARTY_DATA);

export const GetValidationStatusForEmailAction = createAction(
  ProfileActionTypes.GET_VALIDATION_STATUS_FOR_EMAIL,
  (payload: EmailPayloadToGetValidationStatus) => ({ payload })
);

export const GetValidationStatusForEmailFailAction = createAction(
  ProfileActionTypes.GET_VALIDATION_STATUS_FOR_EMAIL_FAIL,
  payload => ({ payload })
);

export const GetValidationStatusForPendingEmailAction = createAction(
  ProfileActionTypes.GET_VALIDATION_STATUS_FOR_PENDING_EMAIL
);

export const GetValidationStatusForPendingEmailSuccessAction = createAction(
  ProfileActionTypes.GET_VALIDATION_STATUS_FOR_PENDING_EMAIL_SUCCESS,
  (payload: Pick<PendingEmailVerification, 'newEmail' | 'tokenExpiry'>) => ({ payload })
);

export const ClearToast = createAction(ProfileActionTypes.CLEAR_TOAST);

export type Actions =
  | ReturnType<typeof ProfileLoad>
  | ReturnType<typeof FetchDataAction>
  | ReturnType<typeof FetchPartyDataSuccessAction>
  | ReturnType<typeof FetchPartyDataFailAction>
  | ReturnType<typeof FetchDaDataSuccessAction>
  | ReturnType<typeof FetchDaDataFailAction>
  | ReturnType<typeof AddContactEntryAction>
  | ReturnType<typeof AddContactEntrySuccessAction>
  | ReturnType<typeof ChangeContactEntryAction>
  | ReturnType<typeof ChangeSecurityInfoAction>
  | ReturnType<typeof ChangeSecurityInfoSuccessAction>
  | ReturnType<typeof ChangeSecurityInfoFailAction>
  | ReturnType<typeof DeleteContactEntryAction>
  | ReturnType<typeof DeleteContactEntrySuccessAction>
  | ReturnType<typeof ContactEntryFailAction>
  | ReturnType<typeof ChangeContactEntrySuccessAction>
  | ReturnType<typeof UpdateDaAction>
  | ReturnType<typeof UpdateDaSuccessAction>
  | ReturnType<typeof UpdateDaFailAction>
  | ReturnType<typeof UpdateEmailAction>
  | ReturnType<typeof UpdateEmailSuccessAction>
  | ReturnType<typeof UpdateEmailFailAction>
  | ReturnType<typeof UpdateSecurityQuestionsAction>
  | ReturnType<typeof MakeRequestAction>
  | ReturnType<typeof UpdateContactMethodValuesAction>
  | ReturnType<typeof EmailNotificationSuccessAction>
  | ReturnType<typeof EmailNotificationFailAction>
  | ReturnType<typeof ValidateEmailSuccessAction>
  | ReturnType<typeof ValidateEmailFailAction>
  | ReturnType<typeof ValidateEmailAction>
  | ReturnType<typeof Magic3SearchSuccessAction>
  | ReturnType<typeof RefreshPartyDataAction>
  | ReturnType<typeof GetValidationStatusForEmailAction>
  | ReturnType<typeof GetValidationStatusForEmailFailAction>
  | ReturnType<typeof GetValidationStatusForPendingEmailAction>
  | ReturnType<typeof GetValidationStatusForPendingEmailSuccessAction>
  | ReturnType<typeof ClearToast>
  | ReturnType<typeof UpdateMembership>
  | ReturnType<typeof UpdateMembershipSuccessAction>
  | ReturnType<typeof UpdateMembershipFailAction>;
