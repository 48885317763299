import { Component, Input, OnInit } from '@angular/core';

import { FindVin } from '@amfam/shared/utility/shared-services';
import { DsModalService } from '@amfam/ui-kit';

@Component({
  selector: 'amfam-find-vin-modal',
  templateUrl: './find-vin-modal.component.html',
  styleUrls: ['./find-vin-modal.component.scss']
})
export class FindVinModalComponent implements OnInit {
  @Input() findVinFlowModalId: string;
  @Input() vinContent: FindVin;

  public modalWidth = '450';

  constructor(private modalService: DsModalService) {}

  @Input() openModal = (isOpen: boolean) => () => isOpen ?? this.open();

  ngOnInit(): void {}

  open(): void {
    this.modalService.open(this.findVinFlowModalId);
  }

  close(): void {
    this.modalService.close(this.findVinFlowModalId);
  }
}
