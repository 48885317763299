import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DsRouterLinkModule } from '@amfam/shared/ui/ds-router-link';
import { PipesModule } from '@amfam/shared/ui/pipes';
import { ImpersonationModule } from '@amfam/shared/utility/impersonation';

import { SharedSitemapComponent } from './components/shared-sitemap/shared-sitemap.component';

@NgModule({
  imports: [CommonModule, DsRouterLinkModule, PipesModule, ImpersonationModule],
  declarations: [SharedSitemapComponent],
  exports: [SharedSitemapComponent]
})
export class SharedSitemapModule {}
