// TODO: Move this to User Store lib, once User Store is moved out
import { ApiStatus } from '@amfam/shared/models';

export interface UserLogin {
  businessName?: string;
  customerId?: string;
  displayName: string;
  emailAddress?: string;
  experienceId: string;
  firstName: string;
  greetingName: string;
  lastLoggedInOn: string;
  lastName: string;
  partnerId: string;
  sessionValidUntil: string;
  smImpersonator?: string;
  smImpersonatorUserID?: string;
  status: ApiStatus;
  token: string;
  trackId: string;
  typeOfAccountCode: string;
  userId: string;
  WAID?: string;
  forgeRockId?: string;
}

export interface UserUpdate {
  businessName?: string;
  displayName?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  customerId?: string;
}

export class AccountTypeEnum {
  public static Customer = 'CUSTOMER';
  public static CFR = 'COMMERCIAL BUSINESS REPRESENTATIVE';
  public static nonCustomer = 'NON CUSTOMER';
  // TODO we need to change CFR as that is not a relevant name
}
