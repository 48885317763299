@if (!showAll) {
  <div class="invalid-feedback" aria-live="assertive">
    @if (errorMessages) {
      <span class="error"> {{ errorMessages[0] }} </span>
    }
  </div>
}
@if (showAll && groupMessages) {
  <div aria-live="assertive">
    <ul class="error-list">
      @for (message of errorMessages; track message) {
        <li>{{ message }}</li>
      }
    </ul>
  </div>
}
@if (showAll && groupMessages) {
  <div class="pad-top-10 row" aria-live="assertive">
    @for (messages of groupedAllErrorMessages; track messages) {
      <div class="column twelve md-six">
        <ul class="error-list row">
          @for (message of messages; track message) {
            <li>{{ message }}</li>
          }
        </ul>
      </div>
    }
  </div>
}
