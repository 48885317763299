import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BillAccountsService } from '@amfam/billing/billaccount/data-access';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import * as billAccountCanRegister from './bill-account-can-register.actions';

@Injectable()
export class BillAccountCanRegisterEffects {
  canRegister$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountCanRegister.CAN_REGISTER),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((action: any) => action.payload),
      switchMap(payload => {
        this.spinner.start({ blockActions: true });
        return this.billingService
          .canRegister(payload.billAccountNumber, payload.policyNumber)
          .pipe(
            map((canRegisterReponse: unknown) => {
              this.spinner.stop();
              return billAccountCanRegister.CanRegisterSuccessAction(canRegisterReponse);
            }),
            catchError(error => {
              this.spinner.stop();
              return of(billAccountCanRegister.CanRegisterFailAction(error));
            })
          );
      })
    );
  });

  constructor(
    private action$: Actions,
    private billingService: BillAccountsService,
    private spinner: LoadingSpinnerService
  ) {}
}
