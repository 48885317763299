<div class="row">
  <div class="column relative twelve emailListInfo" data-cy="contactEmailInfoWrapper">
    <div
      class="pad-top-10 pad-bottom-10 items-center justify-space-between sm-flex"
      data-cy="contactEmailInfo"
    >
      @if (isShellAccount) {
        <div class="sm-margin-right-10 flex-auto">
          <b data-cy="contactEmail">{{ email.emailAddress }}</b>
          @if (email.emailAddress === shellEmail) {
            <span>
              <span class="pad-left-20 success-400-text icon icon-complete-circle"></span>
              <span
                class="pad-left-5 subTextSize"
                content
                [contentPath]="'shared.enrolledEmail'"
              ></span>
            </span>
          }
          @if (email.emailAddress === shellEmail && isShellAccount) {
            <div class="flex">
              <span class="error-600-text icon-alert icon-error"></span>
              <p
                class="margin-left error-600-text"
                content
                [contentPath]="'shared.resendEmailBanner'"
              ></p>
            </div>
          }
        </div>
      }
      @if (!isShellAccount) {
        <div class="sm-margin-right-10 flex-auto">
          <b data-cy="contactEmail">{{ email.emailAddress }}</b>
          @if (isPrimaryEmail) {
            <span>
              <span class="pad-left-20 success-400-text icon icon-complete-circle icon-size"></span>
              <span
                class="pad-left-5 subTextSize"
                content
                [contentPath]="'shared.primary'"
                data-cy="primaryEmailValidation"
              ></span>
            </span>
          }
          @if (isPendingEmail && (tokenExpiry$ | async) === false) {
            <span
              class="pad-left-20 subTextSize"
              data-cy="pendingVerification"
              content
              [contentPath]="'profile.manageEmails.pendingVerificationText'"
            ></span>
          }
          @if (!isPrimaryEmail) {
            <p class="subTextSize" data-cy="contactEmailType">
              {{ email.contactMethodUsages[0].typeOfUsageCode | titlecase }}
              @if (email.contactMethodUsages[0].descriptionForOtherType) {
                <span> - </span>
              }
              {{ email.contactMethodUsages[0].descriptionForOtherType }}
            </p>
          }
          @if (isPrimaryEmail) {
            <p class="subTextSize">
              {{ primaryEmailContactMethodUsage.typeOfUsageCode | titlecase }}
              @if (primaryEmailContactMethodUsage.descriptionForOtherType) {
                <span> - </span>
              }
              {{ primaryEmailContactMethodUsage.descriptionForOtherType }}
            </p>
          }
          @if (isPrimaryEmail && isDuplicateEmail) {
            <div>
              <span class="icon-alert duplicateEmailIcon"></span>
              <span
                class="duplicateEmailContent column twelve"
                content
                [contentPath]="'overview.duplicateEmailProfileContent'"
                [options]="{ contactNumber: contactNumber }"
              ></span>
            </div>
          }
          @if (
            isPendingEmail &&
            primaryEmail &&
            primaryEmail.emailAddress &&
            (tokenExpiry$ | async) === false
          ) {
            <div class="pad-top-20">
              <span
                content
                [contentPath]="'profile.manageEmails.primaryEmailVerificationText1'"
              ></span>
              <span
                ><b>{{ primaryEmail.emailAddress }}</b></span
              >
              <span
                content
                [contentPath]="'profile.manageEmails.primaryEmailVerificationText2'"
              ></span>
            </div>
          }
          @if (
            isPendingEmail && primaryEmail && primaryEmail.emailAddress && (tokenExpiry$ | async)
          ) {
            <div class="pad-top-20">
              <span
                content
                [contentPath]="'profile.manageEmails.primaryEmailVerificationExpired'"
              ></span>
            </div>
          }
          @if (isPendingEmail) {
            <div class="pad-top-20">
              @if (roleService.isAuthorized('send_verification_link') | async) {
                <a
                  (click)="sendAnotherVerificationEmail()"
                  content
                  [contentPath]="'profile.manageEmails.sendAnotherVerificationEmailText'"
                ></a>
              }
              @if ((roleService.isAuthorized('send_verification_link') | async) === false) {
                <span
                  class="color-blue"
                  content
                  [contentPath]="'profile.manageEmails.sendAnotherVerificationEmailText'"
                >
                </span>
              }
            </div>
          }
        </div>
      }
      <a
        [hidden]="
          makePrimaryEmailAddDeleteBtnToggleDisplay ||
          isPrimaryEmail ||
          isPendingEmail ||
          (roleService.isAuthorized('deleteemailaddr_view') | async) === false
        "
        (click)="deleteEmail()"
        data-cy="deleteEmailLink"
        class="caption error-600-text font-weight-bold"
        content
        [contentPath]="'shared.deleteBtn'"
      >
      </a>
      @if (!(isPrimaryEmail || isPendingEmail) && !isShellAccount) {
        <button
          ds-button
          [disabled]="(roleService.isAuthorized('make_primary_email') | async) === false"
          class="-outline-white flex-constrained margin-top-10 sm-margin-top-0 margin-left-20"
          (click)="makePrimary()"
          data-cy="makePrimaryCheckbox"
          [attr.aria-label]="makePrimaryButtonText"
        >
          {{ makePrimaryButtonText }}
        </button>
      }
      @if (isShellAccount && email.emailAddress === shellEmail) {
        <button
          ds-button
          class="-outline-white flex-constrained margin-top-10 sm-margin-top-0 margin-left-20"
          (click)="resendEmail()"
          data-cy="makePrimaryCheckbox"
          [attr.aria-label]="resendEmailButtonText"
        >
          {{ resendEmailButtonText }}
        </button>
      }
      @if (isShellAccount && email.emailAddress !== shellEmail) {
        <button
          ds-button
          class="-outline-white flex-constrained margin-top-10 sm-margin-top-0 margin-left-20"
          (click)="changeEmail(email.emailAddress)"
          data-cy="makePrimaryCheckbox"
          [attr.aria-label]="changeEmailButtonText"
        >
          {{ changeEmailButtonText }}
        </button>
      }
    </div>
  </div>
</div>
