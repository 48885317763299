@if (agents?.length) {
  <ds-overview-section class="overview-section--programs" [disableDefaultClass]="true">
    <ds-overview-section-content>
      @if (opportunitiesContent?.length === 0) {
        <div class="column twelve margin-bottom-10">
          <p content [contentPath]="'shared.noOpportunities'"></p>
        </div>
      }
      @if (opportunitiesContent?.length > 0) {
        <div class="opportunities-container opportunities-width">
          <div
            class="text-center pad-top-80 pad-bottom-16 font-size-1-50 font-weight-semibold"
            content
            [contentPath]="'shared.moreCoverage'"
          ></div>
          <div
            class="opportunities-next-icon items-center text-center pad-bottom-80 margin-bottom-20"
          >
            <img src="/assets/images/doodle-arrow.svg" alt="next arrow" />
          </div>
        </div>
        <ds-opportunities-cards
          class="flex wrap opportunities-card-container"
          [opportunitiesContentList]="opportunitiesContent"
          [displayedOpportunitiesLength]="displayedOpportunitiesLengthSlice"
          [isAuthorized]="isAuthorized$ | async"
          (learnMoreEvent)="learnMore($event)"
        ></ds-opportunities-cards>
      }
    </ds-overview-section-content>
  </ds-overview-section>
}
