import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { DsModalService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-billing-registration-failure-modal',
  templateUrl: './billing-registration-failure-modal.component.html',
  styleUrls: ['./billing-registration-failure-modal.component.scss']
})
export class BillingRegistrationFailureModalComponent implements OnInit, OnChanges, OnDestroy {
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() tryAgainClicked = new EventEmitter();
  @Output() tryAgainCloseClicked = new EventEmitter();

  @Input() impersonationError: boolean;

  public modalId: string;
  private _visible = false;

  constructor(private modalService: DsModalService) {}

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(this._visible);
  }

  ngOnInit(): void {
    // Create unique modal id.
    this.modalId = this.modalService.createModalId('billingRegistrationFailureModalFromOverview');
  }

  ngOnChanges() {
    if (this.visible) {
      this.open();
    } else {
      this.close();
    }
  }

  tryAgain() {
    /**
     * AS: Refactored this to emit the event to parent instead of dispatching an event to store
     */
    this.tryAgainClicked.emit();

    // this.store.dispatch(
    //   new tryAgainRegistrationActions.TryAgainRegistrationEvent({ eventLocation: this.location })
    // );
  }

  open() {
    this.modalService.openCloseOthers(this.modalId);
  }

  close() {
    /**
     * AS: Refactored this to emit the event to parent instead of dispatching an event to store
     */

    this.tryAgainCloseClicked.emit();
    this.modalService.close(this.modalId);
    this.visible = false;
  }

  ngOnDestroy() {
    this.modalService.close(this.modalId);
  }
}
