import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  private _title$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private titleService: Title) {
    this.set(this.get());
  }

  get title$() {
    return this._title$.asObservable();
  }

  set(title: string) {
    this.titleService.setTitle(title);
    this._title$.next(title);
  }

  get() {
    return this.titleService.getTitle();
  }
}
