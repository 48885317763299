import { ElementRef, Injectable } from '@angular/core';
import { get as _get } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DsModalService {
  private modalsMap: Map<string, ModalState> = new Map();
  private originalFocusElem: Element;

  // generate unique modal id
  createModalId(seed?: string): string {
    let id = seed || 'modal';
    let i = 1;
    while (this.modalsMap.has(id)) {
      id = seed + i++;
    }
    return id;
  }

  add(modal: any) {
    this.modalsMap.set(modal.id, {
      isOpen: false,
      elementRef: modal.modal
    });
  }

  remove(id: string) {
    this.modalsMap.delete(id);
  }

  openCloseOthers(id: string) {
    this.open(id, true);
  }

  open(id: string, closeOthers?: boolean) {
    this.originalFocusElem = document.activeElement;
    const modalState: ModalState = this.modalsMap.get(id);
    const contentChild = this.getContentChild(modalState);
    if (closeOthers === true) {
      this.closeAll();
    }
    if (modalState) {
      modalState.elementRef.nativeElement.classList.add('open');
      modalState.isOpen = true;

      if (!!contentChild) {
        // Remove hidden class from #content child in ds-modal-component
        contentChild.classList.remove('none');

        // Add focus to #content child for accessibility
        // First make the div element something that can be tabbed to (it's not by default)
        contentChild.setAttribute('tabindex', '-1');
        // Focus the #content child div
        contentChild.focus();
      }
    }
    this.evaluateBodyClass();
  }

  close(id: string) {
    const modalState: ModalState = this.modalsMap.get(id);
    const contentChild = this.getContentChild(modalState);
    if (modalState) {
      modalState.elementRef.nativeElement.classList.remove('open');
      modalState.isOpen = false;

      // Add hidden class from #content child in ds-modal-component
      if (!!contentChild) {
        contentChild.classList.add('none');
      }
    }
    /*
      Reset the focus to the element that fired the modal

      The instanceof is a trick to add .focus to the element.
      this.originalFocusElemEl is from document.activeElement and is of type Element, not HTMLElement
      Element doesn't have .focus(), but HTMLElement does. Source: https://github.com/Microsoft/TypeScript/issues/5901#issuecomment-431649653
    */
    if (this.originalFocusElem instanceof HTMLElement) {
      this.originalFocusElem.focus(); // reset focus
      this.originalFocusElem = null; // clear variable
    }

    // clear tabindex attribute on #content child set in open otherwise this ends up creating a focus trap toward the end of the document
    if (!!contentChild) {
      contentChild.removeAttribute('tabindex');
    }

    this.evaluateBodyClass();
  }

  isOpen(id: string) {
    const modalState: ModalState = this.modalsMap.get(id);
    if (modalState) {
      return modalState.isOpen;
    }
    return false;
  }

  closeAll() {
    for (const key of Array.from(this.modalsMap.keys())) {
      this.close(key);
    }
    this.evaluateBodyClass();
  }

  scrollTop(id: string) {
    const modalState: ModalState = this.modalsMap.get(id);
    if (modalState && modalState.isOpen) {
      modalState.elementRef.nativeElement.scrollTop = 0;
    }
  }

  hasOpenModals(): boolean {
    for (const modalState of Array.from(this.modalsMap.values())) {
      if (modalState.isOpen === true) {
        return true;
      }
    }

    return false;
  }

  private evaluateBodyClass() {
    const hasOpenModals = this.hasOpenModals();
    if (hasOpenModals) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  // Got tired of seeing this everywhere.
  private getContentChild(modalState) {
    if (_get(modalState, 'elementRef.nativeElement.children[0]') !== undefined) {
      return modalState.elementRef.nativeElement.children[0];
    }
  }
}

export class ModalState {
  isOpen: boolean;
  elementRef: ElementRef;
}
