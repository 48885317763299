import { createAction, props } from '@ngrx/store';

import {
  BillAccountDeliveryPreference,
  SetBillAccountDeliveryPreferencePayload
} from '../models/communication-preferences.models';

// Set Bill Account Delivery Preference
export const setBillAccountDeliveryPreference = createAction(
  '[CommunicationPreferences] Set Bill Account Delivery Preference',
  props<{
    billAccountNumber: string;
    payload: SetBillAccountDeliveryPreferencePayload;
    correlationId: string;
  }>()
);

export const setBillAccountDeliveryPreferenceSuccess = createAction(
  '[CommunicationPreferences] Set Bill Account Delivery Preference Success',
  props<{
    billAccountNumber: string;
    billingPreferences: BillAccountDeliveryPreference;
    correlationId: string;
  }>()
);

export const setBillAccountDeliveryPreferenceFailure = createAction(
  '[CommunicationPreferences] Set Bill Account Delivery Preference Failure',
  props<{ billAccountNumber: string; error: unknown; correlationId: string }>()
);
