import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { take } from 'rxjs/operators';

import {
  DigitalServiceProgramService,
  ProfileActions,
  ValidateTokenRequest
} from '@amfam/profile/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import type { DigitalAccount } from '@amfam/shared/digital-account/data-access';
import { fromUserActions, userQuery } from '@amfam/shared/user';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { ConfirmationModel, LoadingSpinnerService } from '@amfam/ui-kit';

import { AuthService } from '../../core';
import { ForgerockAuthService } from '../../core/auth/forgerock-auth.service';
import { LoginAnalytics } from '../../login/shared/login-constants';

@Component({
  selector: 'ds-validate-email-token',
  templateUrl: './validate-email-token.component.html',
  styleUrls: ['./validate-email-token.component.scss']
})
export class ValidateEmailTokenComponent implements OnInit {
  confirmationData: ConfirmationModel;
  isLoggedIn = false;
  email: string;
  emailTokenValid: boolean;

  private token: string;
  private customerId: string;

  constructor(
    private route: ActivatedRoute,
    private spinner: LoadingSpinnerService,
    private authService: AuthService,
    private forgerockAuthService: ForgerockAuthService,
    private analyticsFacade: AnalyticsFacade,
    private copyService: CopyService,
    private dspService: DigitalServiceProgramService,
    private store: Store,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    if (this.featureFlagService.isEnabled('forgerock')) {
      this.isLoggedIn = this.forgerockAuthService.isLoggedIn();
    } else {
      this.isLoggedIn = this.authService.isLoggedIn();
    }

    this.route.params.pipe(take(1)).subscribe(this.handleRouteParams.bind(this));
    this.store
      .select(userQuery.getCustomerId)
      .pipe(take(1))
      .subscribe(customerId => {
        this.customerId = customerId ? customerId : undefined;
      });
  }
  goToOverview() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/overview']
      })
    );
  }

  goToLogin() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/login']
      })
    );
  }

  goToResetPassword() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/forgot-password']
      })
    );
  }

  buildConfirmationData() {
    const nextStepText = this.copyService.getCopy('auth.emailValidationConfirmationNextStep', {
      email: this.email
    });
    this.confirmationData = {
      confirmation: {
        heading: 'Your email address has been verified.'
      },
      nextSteps: {
        heading: 'Next Steps',
        text: nextStepText
      }
    };
  }
  private handleRouteParams(params) {
    this.token = params['token'];
    this.verifyToken(this.token);
  }

  private verifyToken(token: string) {
    this.spinner.start();
    const requestObj: ValidateTokenRequest = {
      correlationId: token
    };
    this.dspService
      .confirm(requestObj)
      .pipe(take(1))
      .subscribe(
        res => {
          const statusCode = _get(res, 'status.code');
          if (statusCode === 200) {
            this.emailTokenValid = true;
            this.email = _get(res, 'emailProfile.emailAddress');
            // Build confirmation data after we get the email address from response
            this.buildConfirmationData();

            const updateData: DigitalAccount = {
              customerId: this.customerId,
              emailAddress: this.email
            };

            if (this.isLoggedIn) {
              // If authenticated, call digitalaccounts PUT with the updated
              // email address after confirming the correlation id
              this.store.dispatch(ProfileActions.UpdateDaAction(updateData));
            } else {
              // If using altAuth, dispatch action to call AltAuthN PUT with updated email address
              const waid: string = _get(res, 'emailProfile.waid');
              this.store.dispatch(
                ProfileActions.UpdateEmailAction({ digitalAccount: updateData, waid: waid })
              );
              if (this.featureFlagService.isEnabled('forgerock')) {
                this.forgerockAuthService.setAltAuth(false);
              } else {
                this.authService.setAltAuth(false);
              }
            }
            this.store.dispatch(fromUserActions.UpdateUser(updateData));
            this.analyticsFacade.trackPage(LoginAnalytics.emailVerifiedAnalytic);
          } else {
            this.emailTokenValid = false;
          }
          this.spinner.stop();
        },
        err => {
          this.spinner.stop();
          this.emailTokenValid = false;
        }
      );
  }
}
