import { Action, createAction } from '@ngrx/store';

export const RESET = '[Delete Myaccount] Reset';
export const AUTHENTICATE_USER = '[Delete Myaccount] Authenticate User';
export const AUTHENTICATE_USER_SUCCESS = '[Delete Myaccount] Authenticate User Success';
export const AUTHENTICATE_USER_FAIL = '[Delete Myaccount] Authenticate User Fail';
export const UNENROLL_PROGRAMS = '[Delete Myaccount] Unenroll Programs';
export const UNENROLL_USER_FAIL = '[Delete Myaccount] Unenroll Programs Fail';
export const UNENROLL_USER_SUCCESS = '[Delete Myaccount] Unenroll Programs Success';
export const DELETE_MYACCOUNT_ACTION = '[Delete Myaccount] Delete';
export const DELETE_MYACCOUNT_ACTION_SUCCESS = '[Delete Myaccount] Delete Success';
export const DELETE_MYACCOUNT_ACTION_FAIL = '[Delete Myaccount] Delete Fail';
export const SEND_TEMPLATED_EMAIL_ACTION = '[Delete Myaccount] Send Templated Emails';
export const SEND_TEMPLATED_EMAIL_ACTION_SUCCESS =
  '[Delete Myaccount] Send Templated Emails Success';
export const SEND_TEMPLATED_EMAIL_ACTION_FAIL = '[Delete Myaccount] Send Templated Emails Fail';

export interface Credentials {
  username: string;
  password: string;
}

export interface userDetails {
  firstName: string;
  email: string;
}

export const ResetAction = createAction(RESET);

export class AuthenticateUserAction implements Action {
  readonly type = AUTHENTICATE_USER;

  constructor(public payload: Credentials) {}
}

export class AuthenticateUserActionSuccess implements Action {
  readonly type = AUTHENTICATE_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class AuthenticateUserActionFail implements Action {
  readonly type = AUTHENTICATE_USER_FAIL;

  constructor(public payload: any) {}
}

export const UnenrollProgramsAction = createAction(UNENROLL_PROGRAMS);

export class UnenrollUserActionFail implements Action {
  readonly type = UNENROLL_USER_FAIL;

  constructor(public payload: any) {}
}

export class UnenrollUserActionSuccess implements Action {
  readonly type = UNENROLL_USER_SUCCESS;

  constructor(public payload: any) {}
}

export const DeleteMyAccountAction = createAction(DELETE_MYACCOUNT_ACTION);

export class DeleteMyAccountActionSuccess implements Action {
  readonly type = DELETE_MYACCOUNT_ACTION_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteMyAccountActionFail implements Action {
  readonly type = DELETE_MYACCOUNT_ACTION_FAIL;

  constructor(public payload: any) {}
}

export const SendTemplatedEmailsAction = createAction(
  SEND_TEMPLATED_EMAIL_ACTION,
  (payload: userDetails) => ({
    payload
  })
);

export class SendTemplatedEmailsActionSuccess implements Action {
  readonly type = SEND_TEMPLATED_EMAIL_ACTION_SUCCESS;

  constructor(public payload: any) {}
}

export class SendTemplatedEmailsActionFail implements Action {
  readonly type = SEND_TEMPLATED_EMAIL_ACTION_FAIL;

  constructor(public payload: any) {}
}

export type Actions =
  | ReturnType<typeof ResetAction>
  | AuthenticateUserActionSuccess
  | AuthenticateUserActionFail
  | ReturnType<typeof UnenrollProgramsAction>
  | UnenrollUserActionFail
  | UnenrollUserActionSuccess
  | ReturnType<typeof DeleteMyAccountAction>
  | DeleteMyAccountActionSuccess
  | DeleteMyAccountActionFail
  | ReturnType<typeof SendTemplatedEmailsAction>
  | SendTemplatedEmailsActionSuccess
  | SendTemplatedEmailsActionFail;
