import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PaperlessConfirmationReviewItemComponent } from './paperless-confirmation-review-item/paperless-confirmation-review-item.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PaperlessConfirmationReviewItemComponent],
  exports: [PaperlessConfirmationReviewItemComponent]
})
export class ProfileCommunicationPreferencesUiModule {}
