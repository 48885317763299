<div class="row">
  @if (!showCodeEntry && !phoneLocked) {
    <div>
      <div class="column twelve">
        <h1 class="pad-bottom-20" content [contentPath]="'auth.emailValidationPhoneHeader'"></h1>
        <div class="button-width">
          <div class="block button-group pad-bottom-20">
            <button
              ds-button
              class="-stacked"
              tabindex="-1"
              (click)="selectText()"
              [class.active]="textSelected"
              content
              [contentPath]="'auth.emailValidationPhoneTextButton'"
            ></button>
            <button
              ds-button
              class="-stacked"
              tabindex="-1"
              (click)="selectCall()"
              [class.active]="callSelected"
              content
              [contentPath]="'auth.emailValidationPhoneCallButton'"
            ></button>
          </div>
        </div>
      </div>
      <p class="pad-bottom-10" content [contentPath]="'auth.emailValidationPhoneText'"></p>
      <ds-verify-phone
        [usePhoneInput]="true"
        [buttonText]="'next'"
        (submittedPhoneEvent)="sendPin($event)"
      >
      </ds-verify-phone>
      @if (pinSendError) {
        <small
          class="block caption error margin-top-5"
          content
          [contentPath]="'auth.emailValidationPhoneError'"
        ></small>
      }
      @if (genericError) {
        <small
          class="block caption error margin-top-5"
          content
          [contentPath]="'auth.emailValidationPhoneGenericError'"
        ></small>
      }
    </div>
  }
  @if (showCodeEntry && !phoneLocked) {
    <div>
      <div class="column twelve">
        <h1 class="pad-bottom-10">
          @if (callSelected) {
            <span content [contentPath]="'auth.emailValidationConfirmHeaderCall'"></span>
          }
          @if (textSelected) {
            <span content [contentPath]="'auth.emailValidationConfirmHeaderText'"></span>
          }
        </h1>
        <p class="pad-bottom-20">
          @if (textSelected) {
            <span content [contentPath]="'auth.emailValidationConfirmTextPart1'"></span>
          }
          @if (callSelected) {
            <span content [contentPath]="'auth.emailValidationConfirmCallPart1'"></span>
          }
          <strong>{{ maskedPhone }}</strong>
          @if (textSelected) {
            <span content [contentPath]="'auth.emailValidationConfirmTextPart2'"></span>
          }
          @if (callSelected) {
            <span content [contentPath]="'auth.emailValidationConfirmCallPart2'"></span>
          }
        </p>
        <ds-verify-code-entry (submittedCodeEvent)="verifyPin($event)"></ds-verify-code-entry>
        <p class="caption">
          <button
            ds-button
            class="link"
            (click)="sendPin(maskedPhone)"
            content
            [contentPath]="'auth.emailValidationConfirmResendCode'"
          ></button>
        </p>
        @if (pinVerifyError) {
          <small
            class="block caption error margin-top-5"
            content
            [contentPath]="'auth.emailValidationConfirmError'"
          ></small>
        }
        @if (genericError) {
          <small
            class="block caption error margin-top-5"
            content
            [contentPath]="'auth.emailValidationPhoneGenericError'"
          ></small>
        }
      </div>
    </div>
  }

  @if (phoneLocked) {
    <div>
      <div class="column twelve">
        <h1 content [contentPath]="'auth.emailValidationInvalidAttemptsHeader'"></h1>
        <p
          class="pad-bottom-30"
          content
          [contentPath]="'auth.emailValidationInvalidAttemptsText'"
        ></p>
        <button ds-button dsRouterLink="/email-validation" class="pad-left-10 pad-right-10">
          Start Over
        </button>
      </div>
    </div>
  }

  <a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block margin-top-20 caption"
    ><em class="icon-chevron-left"></em> Back</a
  >
</div>
