import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { every as _every, get as _get, includes as _includes, reduce as _reduce } from 'lodash';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  AutoPayPayloadModel,
  BillAccount,
  BillAccountData,
  BillingPreferences,
  DeliveryPreferences,
  NewBillingPreference,
  PolicyList,
  Preferences,
  SelectedDueDateForAutopay,
  SelectedPaymentAmountForAutopay,
  SelectedPaymentMethodForAutopay,
  UpdatePreferences,
  UpdatePreferencesRequest
} from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { ApplicationService } from '@amfam/shared/utility/shared-services';
import { ErrorLevels, ErrorObj } from '@amfam/ui-kit';

@Injectable()
export class BillingUtilService {
  constructor(
    private store: Store,
    private applicationService: ApplicationService
  ) {}

  public buildPreferencePayload(
    newBillingPrerence: NewBillingPreference,
    billAccountData: BillAccountData,
    correlationId: string
  ): UpdatePreferencesRequest {
    const billingPreferences: BillingPreferences = billAccountData.billingPreferences;
    const newPreference = newBillingPrerence.preference;
    const billAccountNumber = newBillingPrerence.billAccountNumber;
    const preferencesObj: UpdatePreferencesRequest = {
      billAccountNumber: billAccountNumber,
      updatePreferenceCorrelationId: correlationId,
      associated: billAccountData.associated,
      billingMethod: billAccountData.billingMethod,
      payload: {
        billingPreference: {
          // spread operator to append accountNickName and dueDateReminder when available.
          ...(_get(billingPreferences, 'accountNickName')
            ? { accountNickname: _get(billingPreferences, 'accountNickName') }
            : {}),
          ...(_get(billingPreferences, 'dueDateReminder')
            ? { dueDateReminder: _get(billingPreferences, 'dueDateReminder') }
            : {}),
          preferences: this.preferenceBuilder(newPreference, billingPreferences.preferences)
        }
      }
    };
    return preferencesObj;
  }
  public showErrorMessage(
    errorMessageList: Array<string> = [],
    errorLevel?: ErrorLevels
  ): Array<ErrorObj> {
    let errorList: Array<ErrorObj> = [];
    errorMessageList.forEach((msg: string) => {
      if (!errorList.find(error => error.errorMessage === msg)) {
        errorList = [
          ...errorList,
          { errorMessage: msg, errorLevel: errorLevel || ErrorLevels.HIGH }
        ];
      }
    });
    return errorList;
  }
  public extractErrorMessage(event, errorMessages, defaultErrorMessage): string[] {
    let errorMsg: string;
    const errorMsgList = [];
    if (_get(event, 'status.messages')) {
      _get(event, 'status.messages').forEach(errorMsgObj => {
        if (errorMsgObj.code) {
          errorMsg = errorMessages[0][errorMsgObj.code];
          if (errorMsg) {
            errorMsgList.push(errorMsg);
            return;
          } else {
            errorMsgList.push(defaultErrorMessage);
            return;
          }
        }
      });
    }
    return errorMsgList;
  }

  /**
   *
   * @param selectedAmountDict : Dictionary object which stores autopay amount details
   * @param selectedPaymentMethod : Object which stores payment method details.
   * @param selectedDueDate : Dictionary object which stores the due date details
   * @description Builds the payload for multiple autopay
   */
  public buildNewPayload(
    selectedAmountDict?: SelectedPaymentAmountForAutopay,
    selectedPaymentMethod?: SelectedPaymentMethodForAutopay,
    selectedDueDate?: SelectedDueDateForAutopay
  ): AutoPayPayloadModel {
    return {
      payload: {
        autopayRule: {
          paymentAccountNickName: _get(selectedPaymentMethod, 'selectedPaymentMethod', ''),
          daysBeforeDueDate: _get(selectedDueDate, 'selectedDueDate', ''),
          paymentAmount: _get(selectedAmountDict, 'selectedAmount', 'MINDUE')
        }
      },
      billAccountNumber: _get(selectedAmountDict, 'billAccountNumber', ''),
      billAccountNickName: _get(selectedAmountDict, 'billAccountNickName', ''),
      billAccountDueDate: _get(selectedAmountDict, 'billAccountDueDate', ''),
      hasScheduledPayment: false,
      correlationId: ''
    };
  }

  /**
   *
   * @param selectedBillaccountsWithPayload : Exisiting payload for autoapay
   * @param selectedAmountDict : Dictionary object which stores autopay amount details
   * @description Builds the payload for multiple autopay
   */
  public editExistingPayload(
    selectedBillaccountsWithPayload: Dictionary<AutoPayPayloadModel>,
    selectedAmountDict: SelectedPaymentAmountForAutopay
  ): AutoPayPayloadModel {
    return {
      payload: {
        autopayRule: {
          paymentAccountNickName: _get(
            selectedBillaccountsWithPayload[selectedAmountDict.billAccountNumber],
            'payload.autopayRule.paymentAccountNickName',
            ''
          ),
          daysBeforeDueDate: _get(
            selectedBillaccountsWithPayload[selectedAmountDict.billAccountNumber],
            'payload.autopayRule.daysBeforeDueDate',
            ''
          ),
          paymentAmount: _get(selectedAmountDict, 'selectedAmount')
        }
      },
      billAccountNumber: _get(selectedAmountDict, 'billAccountNumber', ''),
      billAccountNickName: _get(selectedAmountDict, 'billAccountNickName', ''),
      billAccountDueDate: _get(selectedAmountDict, 'billAccountDueDate', ''),
      hasScheduledPayment: false,
      correlationId: ''
    };
  }

  /**
   *
   * @param selectedAmount : String telling if its 'FULLPAY' Or 'MINDUE'
   * @param selectedPaymentMethod : String Payment MethodName
   * @param selectedDueDate : String Due Date
   * @description Builds the payload for multiple autopay edit
   */
  public buildNewPayloadForEditAutopay(
    selectedAmount?: 'MINDUE' | 'FULLPAY',
    selectedPaymentMethod?: string,
    selectedDueDate?: string,
    billAccountObj?: BillAccount,
    lastUpdatedTimestamp?: string
  ): AutoPayPayloadModel {
    return {
      payload: {
        autopayRule: {
          paymentAccountNickName: selectedPaymentMethod,
          daysBeforeDueDate: selectedDueDate,
          paymentAmount: selectedAmount
        },
        lastUpdateTimestamp: lastUpdatedTimestamp
      },
      billAccountNumber: _get(billAccountObj, 'billAccountNumber', ''),
      billAccountNickName: _get(billAccountObj, 'billingPreferences.accountNickName', ''),
      billAccountDueDate: _get(billAccountObj, 'paymentDueDate', ''),
      hasScheduledPayment: false,
      correlationId: ''
    };
  }

  /**
   * @author: Abhishek Singh
   * @description: This function validates the model AutoPayPayloadModel for each of the bill accounts being
   * passed in the billAccountStream
   */

  checkBillaccountDictionaryStatus(
    billAccountsStream$: Observable<BillAccount[]>,
    billAccountsDictionary: Dictionary<AutoPayPayloadModel>
  ): Observable<boolean> {
    return billAccountsStream$.pipe(
      map(billAccounts =>
        _every(
          billAccounts,
          billAcc =>
            !!Object.keys(billAccountsDictionary).find(
              billingNumber => billingNumber === billAcc.billAccountNumber
            ) &&
            !!billAccountsDictionary[billAcc.billAccountNumber].payload.autopayRule
              .paymentAccountNickName &&
            !!billAccountsDictionary[billAcc.billAccountNumber].payload.autopayRule
              .daysBeforeDueDate
        )
      )
    );
  }

  /**
   * @author: Abhishek Singh
   * @description: Takes 2 arrays of type PolicyList as input, and returns a single array of unique
   * data from the 2. lodash's _merge/_uniq could not be applied here as it supports merging of arrays and
   * not collections.
   */
  public uniqPolicyList(incomingPolicyList: PolicyList, policyList: PolicyList): PolicyList {
    return _reduce(
      policyList,
      function (result, value) {
        const policyItem = incomingPolicyList.find(policyObj =>
          _includes(policyObj.policyNumber, value.policyNumber)
        );
        if (policyItem) {
          result.push(Object.assign({}, policyItem, value));
        } else result.push(value);
        return result;
      },
      []
    );
  }

  /**
   * @author: Abhishek Singh
   * @description: Gets the list of billaccounts and creates an array of billing preference
   * to be used for setting the preference of the bill accounts.
   */
  public setBillAccountsToPaperless(billAccounts: BillAccount[]): NewBillingPreference[] {
    const newBillingPreferences: NewBillingPreference[] = [];
    billAccounts.forEach(billAccount => {
      _get(billAccount, 'billingPreferences.preferences', []).forEach(preference => {
        if (
          preference.isModifiable &&
          preference.preferenceDeliveryCode.toLowerCase() !== DeliveryPreferences.EMAIL
        ) {
          newBillingPreferences.push({
            billAccountNumber: billAccount.billAccountNumber,
            preference: DeliveryPreferences.EMAIL
          });
        }
      });
    });
    return newBillingPreferences;
  }
  /**
   * @param newPreference is modified preference
   * @param preferences is existing preferences
   * @param newPreferenceIsBoth tells selected preference is both(email and paper)
   * Payload required for API is built with above parameters.
   */
  private preferenceBuilder(
    newPreference: string,
    preferences: Preferences[],
    newPreferenceIsBoth?
  ): UpdatePreferences[] {
    if (newPreference === DeliveryPreferences.BOTH) {
      return [
        this.preferenceBuilder(DeliveryPreferences.PAPER, preferences, true)[0],
        this.preferenceBuilder(DeliveryPreferences.EMAIL, preferences, true)[0]
      ];
    } else {
      const pref = newPreference.toUpperCase();
      let originalPreference: Preferences;
      // Only to or from 'both' will have an existing preference, so we only look for it in those cases
      if (newPreferenceIsBoth || preferences.length > 1) {
        originalPreference = this.getExistingPreference(newPreference, preferences);
      }
      const preferenceInfo: UpdatePreferences = {
        payloadEntityId: 'DOCUMENTS.' + pref,
        preferenceCode: 'DOCUMENTS',
        preferenceDeliveryCode: pref,
        businessUnitCode: 'BILLING',
        preferenceTypeCode: 'DOCUMENT-NOTIF',
        lastUpdateTimestamp: _get(originalPreference, 'lastUpdateTimestamp', ''),
        phoneNumber: _get(originalPreference, 'phoneNumber', ''),
        preferenceId: _get(originalPreference, 'preferenceId', '')
      };
      if (newPreference === DeliveryPreferences.EMAIL) {
        const emailAddress = _get(originalPreference, 'emailAddress', this.getEmailAddress());
        preferenceInfo.emailAddress = emailAddress;
      }
      return [preferenceInfo];
    }
  }

  private getExistingPreference(newPreference: string, preferences: Preferences[]): Preferences {
    return preferences.find(pref => pref.preferenceDeliveryCode === newPreference.toUpperCase());
  }

  private getEmailAddress(): string {
    let emailAddress: string;
    this.store
      .select(userQuery.getUserState)
      .pipe(take(1))
      .subscribe(currentUser => {
        emailAddress = currentUser.emailAddress;
      });
    return emailAddress;
  }
}
