import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BillingPaymentMethodDataAccessModule } from '@amfam/billing/payment-method/data-access';

import { PaymentAccountEffects } from './+state/paymentaccount.effects';
import * as fromPaymentAccount from './+state/paymentaccount.reducer';
import { paymentAccountReducers } from './+state/paymentaccount.selectors';

@NgModule({
  imports: [
    CommonModule,
    BillingPaymentMethodDataAccessModule,
    EffectsModule.forFeature([PaymentAccountEffects]),
    StoreModule.forFeature(fromPaymentAccount.PAYMENT_ACCOUNT_FEATURE_KEY, paymentAccountReducers)
  ]
})
export class BillingPaymentaccountDataAccessModule {}
