import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProfileActions, profileQuery } from '@amfam/profile/data-access';
import { userQuery } from '@amfam/shared/user';
import { CopyService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit, OnDestroy {
  username: string;
  showForm = false;
  requestInProgress = false;
  confirmBtnText = this.copyService.getCopy('shared.confirmBtn');
  private customerId: string;
  private stop$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store,
    private copyService: CopyService
  ) {}

  ngOnInit() {
    this.store
      .select(userQuery.getCustomerIdAndLoginName)
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        this.customerId = state.customerId ?? '';
        this.username = state.loginName ?? '';
      });

    this.store
      .select(profileQuery.selectProfileSecurityInfoStatus)
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        if (state.editSecurityInfoMethodType !== 'password') {
          return;
        }
        if (!state.loading && !state.editSecurityInfoError) {
          this.showForm = false;
        }
      });
  }

  openEditPasswordForm() {
    this.showForm = !this.showForm;
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  onComplete(password: string) {
    // start the spinner
    this.requestInProgress = true;
    this.store.dispatch(ProfileActions.MakeRequestAction());

    // dispatch the request to change password
    const data = {
      password: password,
      customerId: this.customerId
    };
    this.store.dispatch(
      ProfileActions.ChangeSecurityInfoAction({
        data,
        methodType: 'password'
      })
    );
  }
}
