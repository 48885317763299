import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { Alert, AlertEnums } from '../amfam-dsn-alert/amfam-dsn-alert.models';

@Component({
  selector: 'ds-amfam-dsn-alert',
  templateUrl: './amfam-dsn-alert.component.html',
  styleUrls: ['./amfam-dsn-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmfamDsnAlertComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() icon: boolean;
  @Input() dismiss: boolean;
  @Input() action: boolean;
  @Input() title: string;
  @Input() message: string;
  @Input() primaryButtonName: string;
  @Input() secondaryButtonName: string;
  @Input() disablePrimaryButton = false;
  @Input() disableSecondaryButton = false;
  @Input() disabled = false;
  @Input() applyMargin: boolean;

  @Output() primaryButtonEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondaryButtonEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() dismissEvent: EventEmitter<void> = new EventEmitter<void>();

  alert: Alert;

  constructor() {}

  ngOnInit(): void {
    this.alertBar(this.type);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isChangeValid(changes)) this.alertBar(this.type);
  }

  alertBar(type: string) {
    switch (type) {
      // INFO
      case AlertEnums.INFO:
        this.alert = {
          type: type,
          alertBg: 'ds-alert--info',
          alertIcon: 'icon-info'
        };
        break;
      // WARNING
      case AlertEnums.WARNING:
        this.alert = {
          type: type,
          alertBg: 'ds-alert--warning',
          alertIcon: 'icon-amfam-alert-triangle-fill-1'
        };
        break;
      // SUCCESS
      case AlertEnums.SUCCESS:
        this.alert = {
          type: type,
          alertBg: 'ds-alert--success',
          alertIcon: 'icon-complete-circle'
        };
        break;
      // ERROR
      case AlertEnums.ERROR:
        this.alert = {
          type: type,
          alertBg: 'ds-alert--error',
          alertIcon: 'icon-alert'
        };
        break;
    }
  }

  private isChangeValid(changes: SimpleChanges): boolean {
    return (
      changes &&
      changes.type &&
      changes.type.currentValue &&
      changes.type.currentValue !== changes.type.previousValue
    );
  }
}
