import { state, style, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { get as _get } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';

import { HeaderNavRect, NotificationObj } from '../../../models';
import { HeaderWrapperService } from '../../../services/header-wrapper.service';

@Component({
  selector: 'ds-header-notification',
  templateUrl: './ds-header-notification.component.html',
  styleUrls: ['../shared/ds-header-navigation-links.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggleSubmenu', [
      state(
        'show',
        style({
          opacity: 1,
          visibility: 'visible',
          height: '*'
        })
      ),
      state(
        'hide',
        style({
          opacity: 0,
          visibility: 'hidden',
          height: '0px'
        })
      )
    ])
  ]
})
export class DsHeaderNotificationComponent implements OnInit, OnDestroy {
  // TODO: Fix inputs upstream to use async as soon as possible and remove Observables here
  @Input() notifications$: Observable<NotificationObj>;
  @Input() showNotificationMenuSub = 'hide';
  @Input() headerNavRect: HeaderNavRect;
  @Input() experienceId: string;
  @Output() subOpenEvent = new EventEmitter<this>();
  @Output() notificationDismissClickEvent = new EventEmitter();
  @ViewChild('notificationElem')
  notificationElem: ElementRef;

  @ViewChild('subNotificationElem')
  subNotificationElem: ElementRef;

  notificationMessage = 'You have no notifications';
  submenuExpanded = 'false';
  submenuHasPopup = false;
  notifications: NotificationObj;
  messageCount = 0;
  queryParams: Record<string, string> = {};

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private headerWrapperService: HeaderWrapperService,
    private renderer: Renderer2,
    private ref: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService
  ) {
    this.queryParams = this.featureFlagService.isEnabled('policy_new_template')
      ? { scrollTo: 'documentsCard' }
      : { openAccordion: 'documents', tid: 'newdocnotice' };
  }

  @HostListener('window:resize')
  resize() {
    // Re-run sub menu centering if window resize occurs
    this.centerSubNav();
  }

  // ESC has to close menu for WCAG 2.0
  @HostListener('keyup.esc')
  onTab() {
    this.showNotificationMenuSub = 'hide';
    this.submenuExpanded = 'false';
  }

  ngOnInit() {
    this.notifications$.pipe(takeUntil(this.stop$)).subscribe(notifications => {
      this.notifications = this.headerWrapperService.buildNotificationMessageArray(
        notifications,
        this.experienceId
      );
      this.ref.detectChanges();
      this.messageCount = _get(this.notifications, 'messagesArray.length', 0);
      if (this.messageCount === 1) {
        this.notificationMessage = 'You have a new notification';
      } else if (this.messageCount >= 2) {
        this.notificationMessage = 'You have ' + this.messageCount + ' new notifications';
      }
      if (this.messageCount >= 1) {
        this.submenuHasPopup = true;
        this.headerWrapperService.setLoaded(true);
      }
      this.centerSubNav();
      this.centerSubNavOnNavEnd();
    });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  onDismissClick(message) {
    this.headerWrapperService.setNotificationToDismiss(message);
    // Recenter roofline mover as the notification menu may have been removed.
    if (this.messageCount <= 1) {
      this.headerWrapperService.resetRooflineMover();
    }
  }

  subOpen() {
    if (this.showNotificationMenuSub !== 'show') {
      // Hide other nav items / sub menus
      this.subOpenEvent.emit(this);
      // Show this sub menu
      this.showNotificationMenuSub = 'show';
      if (this.submenuHasPopup && this.submenuExpanded === 'false') {
        this.submenuExpanded = 'true';
      }
    }
    this.setLinkFocus(this.notificationElem);
  }
  subClose() {
    // Hide this sub menu
    if (this.showNotificationMenuSub !== 'hide') {
      this.showNotificationMenuSub = 'hide';
      if (this.submenuExpanded === 'true') {
        this.submenuExpanded = 'false';
      }
    }
    this.setLinkBlur(this.notificationElem);
  }

  private centerSubNavOnNavEnd() {
    // This centers the sub menu under the nav item on navigation end.
    // Needed to get accurate height, maybe switch to use route change complete
    if (this.notificationElem && this.subNotificationElem) {
      // filter only for NavigationEnd and RouteConfigLoadEnd (app load) events and set routerUrl var
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.centerSubNav();
        }
      });
    }
  }

  private setLinkFocus(linkElem: ElementRef) {
    this.renderer.addClass(linkElem.nativeElement, 'focus');
    this.headerWrapperService.setLinkFocus(linkElem);
  }

  private setLinkBlur(linkElem: ElementRef) {
    this.renderer.removeClass(linkElem.nativeElement, 'focus');
    this.headerWrapperService.setLinkFocus(null);
  }

  private centerSubNav() {
    this.headerWrapperService.centerSubNav(
      this.notificationElem,
      this.subNotificationElem,
      this.headerNavRect
    );
  }
}
