import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { PolicyActions, PolicySelectors } from '@amfam/policy/data-access';
import { GoPaperlessService } from '@amfam/policy/go-paperless/data-access';
import {
  Coverage,
  CoverageChangeQuoteResponseModel,
  PolicyDetails,
  QuoteModel
} from '@amfam/policy/models';
import { VehicleSelectCriteria } from '@amfam/policy/vehicle/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { BillingPaymentPaths, ButtonProps, PolicyChangeRequestType } from '@amfam/shared/models';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { CopyService, UtilService } from '@amfam/shared/utility/shared-services';

import * as EsignatureActions from '../+state/e-signature.actions';

@Injectable({
  providedIn: 'root'
})
export class PolicyUtilService {
  constructor(
    private store: Store<any>,
    private utilService: UtilService,
    private goPaperlessService: GoPaperlessService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade,
    private router: Router
  ) {}

  /**
   *
   * @param urlPathToReturn
   * @description function takes a path as the input and call the docusign with the passed url. docusign
   * after completion of the signature would return to that url.
   */
  public dispatchGoPaperlessForGA(urlPathToReturn: string) {
    this.store.pipe(select(BrandSelectors.selectExperienceId), take(1)).subscribe(experienceId => {
      const callbackUrl = this.utilService.getAbsoluteUrl(urlPathToReturn, experienceId);
      const signatureRequest = this.goPaperlessService.createSignatureRequest(callbackUrl);
      this.store.dispatch(EsignatureActions.SignESignature(signatureRequest));
    });
  }

  /**
   * @param newVehicleDetails of the type VehicleSelectCriteria
   * @description Returns the underwriting questions answer as either true or false.
   */
  public getUnderWritingQuestionsDetails(
    newVehicleDetails: VehicleSelectCriteria,
    copyPath: string,
    getKey: boolean = true
  ): 'Yes' | 'No' {
    const underwritingObj = _get(newVehicleDetails, 'underWritingQuestions', []).find(
      underWritingObj =>
        underWritingObj.question ===
        (getKey ? this.copyService.getCopy(copyPath)['key'] : this.copyService.getCopy(copyPath))
    );
    return _get(underwritingObj, 'answer', '') === 'true' ? 'Yes' : 'No';
  }

  /**
   * @author Abhishek Singh
   * @param coverage : Given coverage of the vehicle
   * @description: Find out if the vehicle coverage is non comprehensive and non liability.
   */
  public isNonComprehensiveNonLiabilityCoverageVehicle(coverage: Coverage): boolean {
    const isAdditionalCoverage =
      _get(coverage, 'limitTerms[0].value', '') === '' &&
      _get(coverage, 'deductibleTerms[0].value', '') === '' &&
      _get(coverage, 'otherTerms[0].valueCode') !== 'Rejected' &&
      !_get(coverage, 'rejectedCoverage');
    return (
      !isAdditionalCoverage &&
      coverage.code !== 'PAComprehensiveCov' &&
      coverage.coverageType !== 'Liability Group'
    );
  }

  public reformQuoteResponse(item: CoverageChangeQuoteResponseModel): QuoteModel {
    return {
      description: 'Quote',
      fullCost: Number(_get(item, 'premium.totalPremium', 0)).toFixed(2),
      monthlyCost: Number(_get(item, 'premium.monthlyPremium', 0)).toFixed(2),
      policyTermMonths: _get(item, 'premium.termMonths') + ' months',
      workOrderNumber: _get(item, 'premium.workOrderNumber')
    };
  }

  public dispatchPolicySelectedAction(params$: Observable<Params>) {
    combineLatest(
      params$,
      this.store.select(PolicySelectors.selectPolicies),
      this.store.select(PolicySelectors.selectPoliciesLoading),
      (params, policies, loading) => {
        return { params, policies, loading };
      }
    )
      .pipe(
        filter(state => !state.loading && !!state.params['id'] && !!_get(state, 'policies.length')),
        take(1)
      )
      .subscribe(state => {
        this.store.dispatch(PolicyActions.PoliciesSetSelected(state.params['id']));
      });
  }

  getPeopleCoveredAction(): ButtonProps[] {
    return [
      {
        name: 'Request a Policy Update',
        iconClass: 'icon-refresh-reset',
        clickEvent: () => {
          this.analyticsFacade.trackButtonClick(PolicyDetails.buttonRequestPolicyChange);
          this.router.navigate(['/policies/changerequest'], {
            state: { changeRequestType: PolicyChangeRequestType.POLICY_COVERAGE_CHANGE }
          });
        }
      }
    ];
  }

  getPremiumActionButtons(billAccountNumber: string): Observable<ButtonProps[]> {
    return combineLatest([
      this.store.select(BillAccountsSelectors.selectAllBillAccountsNotifications),
      this.store.select(BillAccountsSelectors.selectBillAccountsAnyLoading)
    ]).pipe(
      filter(([_, loading]) => !loading),
      map(([billAccounts, _]) => {
        if (billAccounts) {
          const match = billAccounts.find(billAccount => {
            return billAccount.billAccountNumber === billAccountNumber;
          });
          if (match && match.detailsLoaded) {
            return [
              {
                name: 'Manage Billing Account',
                iconClass: 'icon-online-billing',
                clickEvent: () => {
                  this.analyticsFacade.trackButtonClick(PolicyDetails.buttonManageBillingAccount);
                  this.router.navigate([
                    BillingPaymentPaths.BILLING_DETAIL_PAGE,
                    billAccountNumber
                  ]);
                }
              }
            ];
          } else return [];
        }
      })
    );
  }
}
