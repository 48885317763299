import { Action } from '@ngrx/store';

import { Policy } from '@amfam/policy/models';

import { EnrollmentsResponse } from './models';

export const LOAD = '[Kyd] Load';
export const LOAD_SUCCESS = '[Kyd] Load Success';
export const LOAD_COMPLETE = '[Kyd] Load Complete';
export const LOAD_FAIL = '[Kyd] Load Fail';
export const REFRESH = '[Kyd] Refresh';

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: Policy[]) {}
}

export class LoadCompleteAction implements Action {
  readonly type = LOAD_COMPLETE;

  constructor(public payload?: any) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: EnrollmentsResponse[]) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;

  constructor(public payload?: any) {}
}

export class RefreshAction implements Action {
  readonly type = REFRESH;

  constructor(public payload: string[]) {}
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | LoadCompleteAction
  | RefreshAction;
