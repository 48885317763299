import { Policy } from '@amfam/policy/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ESignatureActions, ESignatureActionTypes } from './e-signature.actions';

export const ESIGNATURE_FEATURE_KEY = 'e-signature';

export interface EsignaturePartialState {
  readonly [ESIGNATURE_FEATURE_KEY]: EsignatureEntityState;
}

export interface EsignatureEntityState extends EntityState<Policy> {
  loading: boolean;
}

export const adapter: EntityAdapter<Policy> = createEntityAdapter<Policy>({
  selectId: (esignatureObjModel: Policy) => esignatureObjModel.policyNumber,
  sortComparer: false
});

export const initialState: EsignatureEntityState = adapter.getInitialState({
  loading: false
});

export function reducer(
  state: EsignatureEntityState = initialState,
  action: ESignatureActions
): EsignatureEntityState {
  switch (action.type) {
    case ESignatureActionTypes.GetESignatureType: {
      return Object.assign({}, state, {
        loading: true
      });
    }

    case ESignatureActionTypes.GetESignatureCompleteType: {
      return Object.assign({}, state, {
        loading: false
      });
    }

    case ESignatureActionTypes.GetESignatureSuccessType: {
      return adapter.addMany(action.payload, { ...state, loading: false });
    }

    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
