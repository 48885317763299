import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { get as _get } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { CCValidator } from '../form-controls/services/validators/creditCard/CCValidator';

@Component({
  selector: 'ds-credit-card-icon',
  templateUrl: './ds-credit-card-icon.component.html',
  styleUrls: ['./ds-credit-card-icon.component.scss']
})
export class DsCardIconComponent implements OnInit, OnChanges {
  @Input()
  ccNumberCtrl: AbstractControl;

  @Input()
  showDefault = 'icon';

  @Input()
  ccType = 'icon';

  @Input()
  ccIconHeight = 32;

  @Input()
  defaultOnInvalid = true;

  @Input()
  ariaLabel: string;

  @Input()
  roleAttr = 'presentation';

  private creditCardTypeSub: Subscription;

  ccClasses: Array<string> = [];
  ccIconWidth: number;
  backgroundOffset = 0;

  constructor() {}

  ngOnInit() {
    this.ccIconWidth = Math.round(this.ccIconHeight * 1.5) / 1;
    if (this.ccNumberCtrl) {
      this.creditCardSub();
      this.getBackgroundPos();
    } else if (this.ccType) {
      this.createCssClass(this.ccType);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_get(changes, 'ccNumberCtrl', null)) {
      if (this.ccNumberCtrl) {
        this.creditCardSub();
        this.getBackgroundPos();
      } else if (this.ccType) {
        this.createCssClass(this.ccType);
      }
    }
  }

  getBackgroundPos() {
    if (this.showDefault !== '') {
      this.ccClasses.push('block');
      this.backgroundOffset = 0;
    }
  }

  calcNewPos(location: number) {
    if (location > 0) {
      // card locations = 0:default, 1:visa, 2:amex, 3:mastercard, 4:discover, 5:bank, 6: check, 7: cash
      // card image has a space of .125 of the card icon width between them
      this.backgroundOffset = -Math.round((this.ccIconWidth + this.ccIconWidth * 0.125) * location);
    } else {
      this.backgroundOffset = 0;
    }
  }

  createCssClass(cardType: string) {
    let ccTypeString = '-';
    cardType = cardType.toLowerCase();
    cardType = cardType.replace(/[^a-zA-Z]/g, ''); // Replace any non-letters or spaces with nothing
    switch (cardType) {
      case 'vi':
      case 'visa':
        ccTypeString += 'visa';
        this.calcNewPos(1);
        break;
      case 'mc':
      case 'mastercard':
        ccTypeString += 'masterCard';
        this.calcNewPos(3);
        break;
      case 'ax':
      case 'americanexpress':
        ccTypeString += 'amEx';
        this.calcNewPos(2);
        break;
      case 'ds':
      case 'discover':
        ccTypeString += 'discover';
        this.calcNewPos(4);
        break;
      case 'bank':
      case 'checkingsavings':
      case 'checkingsavingsaccount':
      case 'automatedfundswithdrawal':
        ccTypeString += 'bank';
        this.calcNewPos(5);
        break;
      case 'check':
      case 'moneyorder':
      case 'billpayerservice':
      case 'thirdpartyinterest':
        ccTypeString += 'bank';
        this.calcNewPos(6);
        break;
      case 'cash':
        ccTypeString += 'cash';
        this.calcNewPos(7);
        break;
      default:
        ccTypeString += 'invalid';
        this.backgroundOffset = 0;
        break;
    }
    return ccTypeString;
  }

  showIcon(ccNumberCtrl: AbstractControl) {
    if (this.defaultOnInvalid && !ccNumberCtrl.valid) {
      return false;
    } else {
      return true;
    }
  }

  creditCardSub() {
    this.creditCardTypeSub = this.ccNumberCtrl.valueChanges
      .pipe(debounceTime(600))
      .subscribe(res => {
        let ccTypeString = '';
        this.calcNewPos(0);
        this.getBackgroundPos();
        if (this.ccNumberCtrl.value.length > 1 && this.showIcon(this.ccNumberCtrl)) {
          ccTypeString = this.createCssClass(CCValidator.getCardType(this.ccNumberCtrl.value));
        }
        this.ccClasses.push('cc-type' + ccTypeString);
      });
  }
}
