import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FinancialInstitutionModel } from '../models/financial-institution.models';

const selectFinancialInstitutionState =
  createFeatureSelector<FinancialInstitutionModel>('financialInstitution');

export const selectFinancialInstitutionLoading = createSelector(
  selectFinancialInstitutionState,
  (state: FinancialInstitutionModel) => state.loading
);

export const selectLoaded = createSelector(
  selectFinancialInstitutionState,
  (state: FinancialInstitutionModel) => state.loaded
);

export const financialInstitutionQuery = {
  selectFinancialInstitutionState,
  selectFinancialInstitutionLoading,
  selectLoaded
};
