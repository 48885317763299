import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ADD_VEHICLE_DATA_KEY, addVehicleReducers } from './+state/add-vehicle-common.selector';
import { AddVehicleBindEffects } from './+state/bind/add-vehicle-bind.effects';
import { AddNewVehicleFeaturesEffects } from './+state/feature/add-vehicle-feature.effects';
import { AddVehicleInfoEffects } from './+state/info/add-vehicle-info.effects';
import { AddVehicleQuoteEffects } from './+state/quote/add-vehicle-quote.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ADD_VEHICLE_DATA_KEY, addVehicleReducers),
    EffectsModule.forFeature([
      AddVehicleBindEffects,
      AddNewVehicleFeaturesEffects,
      AddVehicleInfoEffects,
      AddVehicleQuoteEffects
    ])
  ]
})
export class PolicyAddVehicleDataAccessModule {}
