import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'amfam-enrollment-header',
  templateUrl: './enrollment-header.component.html',
  styleUrls: ['./enrollment-header.component.scss']
})
export class EnrollmentHeaderComponent implements OnInit {
  @Input() step: string;
  @Input() firstLine: string;
  @Input() secondLine: string;

  constructor() {}

  ngOnInit(): void {}
}
