import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { UiKitModule } from '@amfam/ui-kit';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

@NgModule({
  imports: [CommonModule, UiKitModule],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent]
})
export class SharedUiBreadcrumbModule {}

export interface Breadcrumb {
  label: BehaviorSubject<unknown>;
  params: Params;
  url: string;
}
