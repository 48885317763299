@if (show) {
  <div class="modal overlay-black load-timeout lg-six">
    <div class="content pad" style="background-color: rgb(237, 237, 237)" tabindex="-1">
      <span class="icon-alert block"></span>
      <p class="text-center">Are you still there?</p>
      <p class="text-center">For your protection, your secure connection to My Account will</p>
      <p class="text-center">
        end in 2 minutes due to inactivity. Would you like to stay signed in?
      </p>
      <div class="text-center">
        <a (click)="continueSession()" id="continueSession" href="javascript:void(0)"
          >I'm still here</a
        >
      </div>
      <div class="text-center">
        <a (click)="exitSession()" id="exitSession" href="javascript:void(0)">I'm done</a>
      </div>
    </div>
  </div>
}
