<div class="quote">
  <div class="quote-header pad-top-10">{{ quote.header }}</div>
  <div class="cards">
    @if (quote?.premiumPerMonth) {
      <ds-premium-card
        [amount]="quote?.premiumPerMonth?.amount"
        [term]="quote?.premiumPerMonth?.term"
      ></ds-premium-card>
    }
    @if (quote?.premiumOther) {
      <div class="separator"></div>
    }
    @if (quote?.premiumOther?.amount) {
      <ds-premium-card
        [amount]="quote?.premiumOther?.amount"
        [term]="quote?.premiumOther?.term"
      ></ds-premium-card>
    }
  </div>
  @if (quote?.subText) {
    <div class="pad-16 color-white sub-text">
      {{ quote?.subText }}
    </div>
  }
</div>
