import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FinancialInstitutionEffects } from './+state/financial-institution.effects';
import * as fromFinancialInstitution from './+state/financial-institution.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'financialInstitution',
      fromFinancialInstitution.financialInstitutionReducer
    ),
    EffectsModule.forFeature([FinancialInstitutionEffects])
  ]
})
export class BillingFinancialInstitutionDataAccessModule {}
