import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PolicySummary } from '@amfam/policy/models';

import { LoadPolicies } from './policies.actions';
import { policiesQuery } from './policies.selectors';

@Injectable()
export class PoliciesFacade {
  loaded$ = this.store.select(policiesQuery.selectLoaded);
  allPolicies$ = this.store.select(policiesQuery.selectAllPolicies);
  selectedPolicies$ = this.store.select(policiesQuery.selectSelectedPolicies);

  constructor(private store: Store) {}

  loadAll(policySummaryList: PolicySummary[]) {
    this.store.dispatch(LoadPolicies(policySummaryList));
  }
}
