import { EventAnalyticParams } from '@amfam/shared/analytics';
import { ButtonAnalytic, PageAnalytic } from '@amfam/shared/utility/shared-services';

export class OverviewAnalytics {
  // click analytic for Go Paperless
  public static readonly clickGoPaperless: ButtonAnalytic = {
    link: 'overviewGoPaperlessBoth'
  };

  // click analytic for Autopay
  public static readonly clickAutoPay: ButtonAnalytic = {
    link: 'overviewAutoPay'
  };

  // click analytic for Lifepolicy
  public static readonly clickLifePolicy: ButtonAnalytic = {
    link: 'lifePolicyView'
  };

  // click analytic for failed payment with past due
  public static readonly clickFailedPaymentPastDue: ButtonAnalytic = {
    link: 'overviewFailedPaymentPastDue'
  };

  // click analytic for failed payment
  public static readonly clickFailedPayment: ButtonAnalytic = {
    link: 'overviewFailedPayment'
  };

  // click analytic for View Claim
  public static readonly clickViewClaim: ButtonAnalytic = {
    link: 'MyAccountOverviewViewClaim'
  };

  // click analytic for multiple autopay setup
  public static readonly clickMultiAutopaySetup: ButtonAnalytic = {
    link: 'OverviewSetupAutomaticPayments'
  };

  // page analytic for go paperless error modal
  public static readonly pageGoPaperlessError: PageAnalytic = {
    pageName: 'MyAccount:GoPaperlessEnrollment:Error',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Policies',
    subCategory2: 'Go Paperless',
    subCategory3: ''
  };

  public static readonly overviewMultipleEmailErrorAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Overview:MultipleEmailError',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Overview',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly overviewMultipleEmailErrorLearnMoreHere: ButtonAnalytic = {
    link: 'MyAccount:Overview:MultipleEmailError:LearnMoreHere'
  };

  public static readonly overviewPastDueAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Overview:PastDue',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Overview',
    subCategory2: '',
    subCategory3: 'Past Due'
  };

  public static readonly overviewAdminPastDueAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Admin:Overview:PastDue',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Overview',
    subCategory2: '',
    subCategory3: 'Past Due'
  };

  public static readonly VinYearAnalytic: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:HardStop:VinYear',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Hardstops',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly CompOnlyAnalytic: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:HardStop:CompOnly',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Hardstops',
    subCategory2: '',
    subCategory3: ''
  };
  public static readonly SpinnerCompleteAnalytics: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:AssignDriver:LoadingSpinner:Complete',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'AddVehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly SpinnerRecalculateCompleteAnalytics: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:CustomizeCoverage:LoadingSpinner:Complete',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'AddVehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly AddVehicleErrorAnalytics: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:Error:NeedAgent',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'AddVehicle',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly TooManyDriversAnalytic: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:HardStop:TooManyDrivers',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Hardstops',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly PassengerVanAnalytic: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:HardStop:PassengerVan',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Hardstops',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly RateHarsdStopAnalytic: PageAnalytic = {
    pageName: 'MyAccount:AddVehicle:HardStop:Rate',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Hardstops',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly overviewPastDueBanner: ButtonAnalytic = {
    link: 'MyAccount:Overview:PastDue:BannerMakePayment'
  };

  public static readonly overviewPastDueCard: ButtonAnalytic = {
    link: '  MyAccount:Overview:PastDue:MyBillsMakePayment'
  };

  public static readonly overviewAdminPastDueCard: ButtonAnalytic = {
    link: '  MyAccount:Admin:Overview:PastDue:MyBillsMakePayment'
  };

  public static readonly documentsGetPOIButton: ButtonAnalytic = {
    link: 'MyAccount:Policies:Documents:GetPOI'
  };

  public static readonly documentsGetPOIError: PageAnalytic = {
    pageName: 'MyAccount:Policies:Documents:GetPOI:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Policies',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly overviewGetPOIButton: ButtonAnalytic = {
    link: 'MyAccount:Overview:GetPOI'
  };

  public static readonly overviewGetOperatorPOIButton: ButtonAnalytic = {
    link: 'MyAccount:Overview:Operator:GetPOI'
  };

  public static readonly overviewGetPOIError: PageAnalytic = {
    pageName: 'MyAccount:Overview:GetPOI:Error',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Policies',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly romeModalOpen: PageAnalytic = {
    pageName: 'MyAccount:Overview:Rome:LearnMorePage',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: '',
    subCategory2: '',
    subCategory3: ''
  };

  // click analytic for Banner KYD: Learn More Button
  public static readonly clickBannerKYDLearnMore: ButtonAnalytic = {
    link: 'MyAccount:LearnMoreBanner'
  };

  // click analytic for Rome: Learn More Button
  public static readonly clickRomeLearnMore: ButtonAnalytic = {
    link: 'MyAccount:Overview:Rome:LearnMore'
  };

  // click analytic for Card MMW: Learn More Button
  public static readonly clickCardMMWLearnMore: ButtonAnalytic = {
    link: 'MyAccount:MilesMyWay:LearnMore'
  };
  // click analytic for Card DMW: Learn More Button
  public static readonly clickCardDMWLearnMore: ButtonAnalytic = {
    link: 'MyAccount:DriveMyWay:LearnMore'
  };

  public static readonly pastDueBillEventAnalytic: EventAnalyticParams = {
    eventName: 'pastdue_bill',
    eventStep: ''
  };
}
