import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, from as observableFrom } from 'rxjs';
import { filter, map, mergeMap, take, toArray } from 'rxjs/operators';

// Store
import { PolicySelectors } from '@amfam/policy/data-access';
import { BufferedFile, Policy, SignatureRequest } from '@amfam/policy/models';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { ConfigService, ContentService } from '@amfam/shared/utility/shared-services';

@Injectable({
  providedIn: 'root'
})
export class ESignatureService {
  constructor(
    private configService: ConfigService,
    private contentService: ContentService,
    private http: HttpClient,
    private store: Store<any>
  ) {}

  requiresSignature(): Observable<Policy[]> {
    const policySource = PolicySelectors.selectPolicies;
    return this.store.select(policySource).pipe(
      filter(policies => {
        for (const policy of policies) {
          if (policy && policy.policyAddress) {
            const isGeorgiaPolicy = /^GA$|^Georgia$/i.test(policy.policyAddress.state);
            if (isGeorgiaPolicy) {
              return true;
            }
          }
        }
        return false;
      })
    );
  }

  requestSignature(request: SignatureRequest): Observable<string> {
    return observableFrom(request.files).pipe(
      mergeMap(file => {
        const localFileOptions = {
          headers: new HttpHeaders({ Accept: file.contentType }),
          responseType: 'arraybuffer'
        };

        return this.contentService
          .getMedia(file.absolutePath, localFileOptions)
          .pipe(map(response => new BufferedFile(file, response)));
      }),
      toArray(),
      mergeMap(bufferedFiles => this.createDocusignEnvelope(request.callbackUrl, bufferedFiles))
    );
  }

  private createDocusignEnvelope(
    callbackUrl: string,
    files: Array<BufferedFile>
  ): Observable<string> {
    // AS: TODO, this need to be refactored to be async.
    let brandData, userData;
    this.store
      .select(BrandSelectors.selectBrandState)
      .pipe(take(1))
      .subscribe(brand => (brandData = brand));
    this.store
      .select(userQuery.getUserState)
      .pipe(take(1))
      .subscribe(user => (userData = user));

    const context = brandData.isPartner ? 'myaccount-whitelabel' : 'myaccount';
    const eSignatureApiUrl = this.configService.get('eSignatureApi');
    const url = eSignatureApiUrl + 'envelopes';
    const multipartBoundary = `----=${Date.now().toString()}`;
    let headers = this.getHeaders();
    headers = headers
      .set('content-type', `multipart/form-data; boundary="${multipartBoundary}"`)
      .set('MIME-Version', '1.0');

    const data = {
      envelope: {
        contextName: context,
        callbackURL: callbackUrl,
        recipients: [
          {
            name: userData.displayName,
            emailAddress: userData.emailAddress,
            recipientType: 'Signer',
            signingMethod: 'Embedded',
            routingOrder: 1,
            roles: ['PrimaryInsured1']
          }
        ],
        documentDetails: files.map((file, index) => ({
          name: file.friendlyName,
          originalDocumentFileName: file.fileName,
          sequence: index + 1,
          producerId: '1234567890',
          customerId: '12345678901234'
        })),
        sender: [
          {
            senderId: 'myacct',
            senderIdType: 'system',
            senderRole: 'Sender'
          }
        ]
      }
    };
    const jsonData = [
      `--${multipartBoundary}`,
      'Content-Type: application/json; name=myaccount.json',
      'Content-Disposition: form-data; name="myaccount.json"; filename="myaccount.json"',
      '',
      JSON.stringify(data)
    ].join('\r\n');
    const filesWithHeaders = files.map(file => {
      const fileHeaders = [
        '',
        `--${multipartBoundary}`,
        `Content-Type: ${file.contentType}; name=${file.fileName}`,
        `Content-Disposition: form-data; name="${file.fileName}"; filename="${file.fileName}"`,
        '',
        ''
      ].join('\r\n');

      const fileDataView = new Uint8Array(file.buffer);
      return {
        binaryView: fileDataView,
        headers: fileHeaders,
        length: fileHeaders.length + fileDataView.byteLength
      };
    });
    const endMultipartBoundary = ['', `--${multipartBoundary}--`].join('\r\n');

    const filesLength = filesWithHeaders.reduce(
      (length, fileWithHeaders) => length + fileWithHeaders.length,
      0
    );
    const binaryDataSize = jsonData.length + filesLength + endMultipartBoundary.length;
    const binaryData = new Uint8Array(binaryDataSize);
    let i = 0;
    for (; i < jsonData.length; i++) {
      // eslint-disable-next-line no-bitwise
      binaryData[i] = jsonData.charCodeAt(i) & 0xff;
    }
    for (const fileWithHeaders of filesWithHeaders) {
      for (let j = 0; j < fileWithHeaders.headers.length; i++, j++) {
        // eslint-disable-next-line no-bitwise
        binaryData[i] = fileWithHeaders.headers.charCodeAt(j) & 0xff;
      }
      for (let j = 0; j < fileWithHeaders.binaryView.byteLength; i++, j++) {
        binaryData[i] = fileWithHeaders.binaryView[j];
      }
    }
    for (let j = 0; j < endMultipartBoundary.length; i++, j++) {
      // eslint-disable-next-line no-bitwise
      binaryData[i] = endMultipartBoundary.charCodeAt(j) & 0xff;
    }
    const body = binaryData.buffer;

    return this.http
      .post(url, body, { headers: headers })
      .pipe(mergeMap(json => this.getDocusignUrl(json)));
  }

  private getDocusignUrl(envelopeJson: any): Observable<string> {
    const envelope = envelopeJson.envelopes;
    const envelopeId = envelope.envelopeId;
    const recipientId = envelope.recipients.recipientId;
    const eSignatureApiUrl = this.configService.get('eSignatureApi');
    const signingUrl = `${eSignatureApiUrl}envelopes/${envelopeId}/recipients/${recipientId}/retrieveSigningUrl`;
    const signingBody = {
      authenticationAssertion: {
        assertionID: 123,
        authenticationDateTime: '2012-04-23T18:25:43.511Z',
        authenticationMethod: 'SingleSignOn_CASiteminder',
        securityDomain: 'Corporate'
      }
    };
    return this.http
      .post(signingUrl, signingBody, { headers: this.getHeaders() })
      .pipe(map(json => this.getSigningUrl(json)));
  }

  private getSigningUrl(json: any): string {
    const recipient = json.recipients;
    const redirectUrl = decodeURIComponent(recipient.signingUrl);

    return redirectUrl;
  }

  private getHeaders() {
    const headers = new HttpHeaders({
      authId: this.configService.get('processId'),
      userId: this.configService.get('processId')
    });
    return headers;
  }
}
