export class PartyPhone {
  phoneId: string;
  areaCode: string;
  phoneNumber: string;
  primaryIndicator = false;
  extension?: string;
  contactMethodUsages = new Array<{
    typeOfUsageCode: PartyPhoneUsageCode;
    descriptionForOtherType?: string;
  }>();

  get tenDigitPhoneNumber(): string {
    if (this.areaCode && this.phoneNumber) {
      return `${this.areaCode}${this.phoneNumber}`;
    }
    return '';
  }

  set tenDigitPhoneNumber(tenDigitPhoneNumber: string) {
    const formattedTenDigitPhoneNumber = this.formatConcatenatedPhoneNumber(tenDigitPhoneNumber);
    const { areaCode, phoneNumber } = formattedTenDigitPhoneNumber;
    this.areaCode = areaCode;
    this.phoneNumber = phoneNumber;
  }

  static fromForm(formGroup: any): PartyPhone {
    const phoneFromForm = new PartyPhone();

    if (formGroup) {
      const tenDigitPhoneNumber = phoneFromForm.formatConcatenatedPhoneNumber(
        formGroup.controls['phoneNumber'].value
      );
      const { areaCode, phoneNumber } = tenDigitPhoneNumber;

      phoneFromForm.areaCode = areaCode;
      phoneFromForm.phoneNumber = phoneNumber;
      phoneFromForm.primaryIndicator = formGroup.controls['primaryChecked'].value;
    }

    if (formGroup.controls['extension'].value) {
      phoneFromForm.extension = formGroup.controls['extension'].value;
    }

    if (formGroup.controls['typeOfUsageCode'].value) {
      const typeOfUsageCodeString = formGroup.controls['typeOfUsageCode'].value;
      if (formGroup.controls['typeOfUsageCode'].value === 'OTHER') {
        const descriptionForOtherTypeString = formGroup.controls['descriptionForOtherType'].value;
        phoneFromForm.contactMethodUsages.push({
          typeOfUsageCode: typeOfUsageCodeString,
          descriptionForOtherType: descriptionForOtherTypeString
        });
      } else {
        phoneFromForm.contactMethodUsages.push({ typeOfUsageCode: typeOfUsageCodeString });
      }
    }
    return phoneFromForm;
  }

  static fromJson(json: any): PartyPhone {
    const phone = new PartyPhone();
    if (json) {
      phone.areaCode = json.areaCode;
      phone.phoneNumber = json.phoneNumber;
      phone.primaryIndicator = json.primaryIndicator;

      if (json.phoneId) {
        phone.phoneId = json.phoneId;
      }

      if (json.extension) {
        phone.extension = json.extension;
      }

      if (json.contactMethodUsages) {
        phone.contactMethodUsages = json.contactMethodUsages;
      }
    }

    return phone;
  }

  private formatConcatenatedPhoneNumber(phoneNumber: string) {
    const regEx = /([0-9]{3})([0-9]{7})/;
    let formattedPhoneNumber = phoneNumber;
    formattedPhoneNumber = formattedPhoneNumber.replace(/-/g, '');
    const regExMatch = formattedPhoneNumber.match(regEx);
    const fullPhoneNumber = { areaCode: '', phoneNumber: '' };
    if (regExMatch) {
      fullPhoneNumber.areaCode = regExMatch[1];
      fullPhoneNumber.phoneNumber = regExMatch[2];
    }
    return fullPhoneNumber;
  }
}

export class PartyPhoneUsageCodes {
  usageCodes: PartyPhoneUsageCode[] = [
    'MOBILE',
    'HOME',
    'WORK',
    'FAX',
    'PAGER'
  ];
}

export type PartyPhoneUsageCode =
  | 'MOBILE'
  | 'HOME'
  | 'WORK'
  | 'FAX'
  | 'PAGER'
