<div class="ds-table-header-cell-content">
  @if (!column.headerTemplate) {
    <span class="ds-table-header-cell-wrapper">
      <span class="ds-table-header-cell-label" [innerHTML]="column.name"> </span>
    </span>
  }
  @if (column.headerTemplate) {
    <ng-template
      [ngTemplateOutlet]="column.headerTemplate"
      [ngTemplateOutletContext]="{
        column: column
      }"
    >
    </ng-template>
  }
</div>
