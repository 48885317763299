<h4>{{ title }}</h4>
<ul data-cy="quickLinksList">
  @for (link of links; track link) {
    <li>
      @if (link.href && !link.target && !link.queryParams) {
        <a
          [ngClass]="{ 'disable-link': isQuickLinkDisable }"
          [dsRouterLink]="link.href"
          (click)="link.onClick($event)"
          [attr.data-cy]="link?.dataCy"
          data-cy="makePaymentLink"
          >{{ link.text }}</a
        >
      }
      @if (link.href && !link.target && link.queryParams) {
        <a
          [ngClass]="{ 'disable-link': isQuickLinkDisable }"
          [dsRouterLink]="link.href"
          [queryParams]="link.queryParams"
          (click)="link.onClick($event)"
          [attr.data-cy]="link?.dataCy"
          data-cy="communicationPreferencesLink"
          >{{ link.text }}</a
        >
      }
      @if (link.href && link.target) {
        <a
          [ngClass]="{ 'disable-link': isQuickLinkDisable }"
          [href]="link.href"
          [attr.target]="link.target"
          (click)="link.onClick($event)"
          [attr.data-cy]="link?.dataCy"
          data-cy="requestChangeLink"
          >{{ link.text }}</a
        >
      }
      @if (!link.href) {
        <a
          [ngClass]="{ 'disable-link': isQuickLinkDisable }"
          href="#"
          [attr.data-cy]="link?.dataCy"
          (click)="link.onClick($event)"
          data-cy="faqs"
          >{{ link.text }}</a
        >
      }
    </li>
  }
</ul>
