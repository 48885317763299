@if (!suppressBreadcrumbs) {
  @if(newBillingDesign) {
    <nav class="breadcrumbs" [class]="{'breadcrumb-background' : newBillingDesign, 'breadcrumb-background-blue': showBlueBreadCrumbBackground}" aria-label="Breadcrumb">
      <ol>
        @for (breadcrumb of breadcrumbs; track breadcrumb; let last = $last) {
          <li [ngClass]="{ active: last }">
            <!-- disable link of last item -->
            @if (!last) {
              <a (click)="navigateTo(breadcrumb.url)">{{ breadcrumb.label | async }}</a>
            }
            @if (last) {
              <span aria-current="page">{{ breadcrumb.label | async }}</span>
            }
          </li>
        }
      </ol>
    </nav>
  }
}
