import { SearchResult } from '@amfam/claim/drp-search/feature';

import { RentalServiceAction, RentalServiceActionTypes } from './rental-service.actions';

export const RENTALSERVICE_FEATURE_KEY = 'rentalService';

export interface RentalServiceState {
  loading: boolean;
  error: boolean;
  selectedShop: SearchResult;
}

export const initialState: RentalServiceState = {
  loading: false,
  error: false,
  selectedShop: null
};

export function rentalServiceReducer(
  state: RentalServiceState = initialState,
  action: RentalServiceAction
): RentalServiceState {
  switch (action.type) {
    case RentalServiceActionTypes.CreateRentalRequest:
      return Object.assign({}, state, {
        loading: true,
        selectedShop: action.payload.selectedShop
      });

    case RentalServiceActionTypes.CreateRentalRequestSuccess:
      return Object.assign({}, state, {
        loading: false,
        error: false
      });

    case RentalServiceActionTypes.CreateRentalRequestFail:
      return Object.assign({}, state, {
        loading: false,
        error: true
      });

    case RentalServiceActionTypes.ResetRentalService:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
}
