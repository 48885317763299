import { createAction } from '@ngrx/store';

import { PendingPayment } from '../models/pending-payment.models';

export const ADD_PENDING_PAYMENTS = '[PendingPayments] Add Many';
export const ADD_PENDING_PAYMENT = '[PendingPayment] Add One';
export const CLEAR_PENDING_PAYMENTS = '[PendingPayments] Clear All';
export const REMOVE_PENDING_PAYMENT = '[PendingPayment] Remove One';

export const AddPendingPaymentsAction = createAction(
  ADD_PENDING_PAYMENTS,
  (payload: PendingPayment[]) => ({ payload })
);

export const AddPendingPaymentAction = createAction(
  ADD_PENDING_PAYMENT,
  (payload: PendingPayment) => ({ payload })
);

export const ClearPendingPaymentsAction = createAction(CLEAR_PENDING_PAYMENTS);

export const RemovePendingPaymentAction = createAction(
  REMOVE_PENDING_PAYMENT,
  (payload: PendingPayment) => ({ payload })
);

export type PendingPaymentAction =
  | ReturnType<typeof AddPendingPaymentsAction>
  | ReturnType<typeof AddPendingPaymentAction>
  | ReturnType<typeof ClearPendingPaymentsAction>
  | ReturnType<typeof RemovePendingPaymentAction>;

export const pendingPaymentActions = {
  AddPendingPaymentsAction,
  AddPendingPaymentAction,
  ClearPendingPaymentsAction,
  RemovePendingPaymentAction
};
