import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ReviewItemConfig } from './review-item.model';

@Component({
  selector: 'ds-auto-pay-review-item',
  templateUrl: './auto-pay-review-item.component.html',
  styleUrls: ['./auto-pay-review-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoPayReviewItemComponent {
  @Input() config: ReviewItemConfig;
  @Output() editEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  edit() {
    if (this.config.showEditButton) {
      this.editEvent.emit();
    }
  }
}
