<div class="container">
  <div class="row">
    <div class="column twelve lg-eight lg-push-two md-eight md-push-two">
      <span class="icon-complete-circle"></span>
      <h4 data-cy="accountDeletedHeader">Your account has been deleted</h4>
      <div data-cy="emailConfirmation">We'll send you an email confirming this. You will no longer have access to My Account unless you sign up again.</div>
      <div class="row">
        <div class="column twelve">
          <button
            ds-button
            class="btn margin-top-30"
            type="button"
            [attr.aria-label]="'Back to login'"
            data-cy="backToLogin"
            (click)="backToLogin()"
          >
            Back to login
          </button>
        </div> 
      </div>
    </div>
  </div>
</div>
