import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { take } from 'rxjs/operators';

import { GoPaperLessActions } from '@amfam/policy/go-paperless/data-access';
import {
  CommunicationPreferencesActions,
  DeliveryPreferenceCodeTypes,
  DeliveryPreferenceTypes,
  SetBillAccountDeliveryPreferencePayload
} from '@amfam/profile/communication-preferences/data-access';
import { BillAccount, Preferences } from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { UtilService } from '@amfam/shared/utility/shared-services';

@Injectable({
  providedIn: 'root'
})
export class CommunicationPreferencesUtilService {
  constructor(
    private store: Store,
    private utilService: UtilService
  ) {}

  setBillAccountDeliveryPreference(billAccount: BillAccount) {
    const existingPreferences: Preferences[] = _get(
      billAccount.billingPreferences,
      'preferences',
      []
    );

    if (existingPreferences[0].isModifiable === true) {
      const correlationId: string = this.utilService.generateId();
      const payload: SetBillAccountDeliveryPreferencePayload = {
        billingPreference: {
          billAccountNumber: billAccount.billAccountNumber,
          accountNickname: billAccount.billingPreferences.accountNickName,
          dueDateReminder: billAccount.billingPreferences.dueDateReminder,
          preferences: [
            {
              payloadEntityId: DeliveryPreferenceCodeTypes.EMAIL,
              preferenceCode: 'DOCUMENTS',
              preferenceDeliveryCode: DeliveryPreferenceTypes.EMAIL,
              businessUnitCode: 'BILLING',
              preferenceTypeCode: 'DOCUMENT-NOTIF',
              lastUpdateTimestamp: _get(existingPreferences, 'lastUpdateTimestamp', ''),
              phoneNumber: _get(existingPreferences, 'phoneNumber', ''),
              preferenceId: _get(existingPreferences, 'preferenceId', ''),
              emailAddress: _get(existingPreferences, 'emailAddress', this.getEmailAddress()),
              isModifiable: _get(existingPreferences, 'isModifiable', true),
              reasonNotModifiable: _get(existingPreferences, 'reasonNotModifiable', '')
            }
          ]
        }
      };
      this.store.dispatch(
        CommunicationPreferencesActions.setBillAccountDeliveryPreference({
          billAccountNumber: billAccount.billAccountNumber,
          payload,
          correlationId
        })
      );
    }
  }

  setPolicyDocumentPreference() {
    const correlationId: string = this.utilService.generateId();
    this.store.dispatch(GoPaperLessActions.EnrollGoPaperless({ correlationId: correlationId }));
  }

  private getEmailAddress(): string {
    let emailAddress: string;
    this.store
      .select(userQuery.getUserState)
      .pipe(take(1))
      .subscribe(currentUser => {
        emailAddress = currentUser.emailAddress;
      });
    return emailAddress;
  }
}
