import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { get as _get } from 'lodash';
import { Observable, from as observableFrom, throwError as observableThrowError, of } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';

// Store
import { PolicySelectors } from '@amfam/policy/data-access';
import { File, SignatureRequest, Status } from '@amfam/policy/models';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { DiscountPolicyModel, PaperlessDiscountPayload } from '@amfam/shared/models';
import { userQuery, UserState } from '@amfam/shared/user';
import {
  Applications,
  ApplicationService,
  CommunicationService,
  ConfigService,
  MessageDetail,
  TemplatedEmail
} from '@amfam/shared/utility/shared-services';

import * as GoPaperLessSelectors from '../+state/go-paperless.selectors';
import {
  EDeliveryOptingsModel,
  GenerateEmailConstants,
  GoPaperLessAnalyticsModel,
  SourceSystemType
} from '../models/go-paperless.model';

@Injectable({
  providedIn: 'root'
})
export class GoPaperlessService {
  userAndBrandInfo$ = this.store.select(GoPaperLessSelectors.selectUserAndBrandInfo);
  private brandId: string;
  private isEnrolled: boolean;
  private partnerId: string;
  private user: UserState;
  private userID: string;

  constructor(
    private http: HttpClient,
    private store: Store,
    private configService: ConfigService,
    private communicationService: CommunicationService,
    private analyticsFacade: AnalyticsFacade,
    private appService: ApplicationService
  ) {
    this.userAndBrandInfo$.subscribe(state => {
      this.user = state.user;
      this.brandId = state.brandId;
      this.partnerId = state.partnerId;
      this.isEnrolled = state.isEnrolled;
    });
  }

  createSignatureRequest(callbackUrl: string): SignatureRequest {
    const eSignatureConsentFile = new File(
      `/assets/documents/${this.brandId}-esignature-consent.pdf`,
      'application/pdf',
      'ELECTRONIC SIGNATURE CONSENT AND DISCLOSURE'
    );
    const goPaperlessTermsFile = new File(
      `/assets/documents/${this.brandId}-ga-paperless-policy-terms-and-conditions.pdf`,
      'application/pdf',
      'Go Paperless Policy Service Terms & Conditions'
    );
    const files = [eSignatureConsentFile, goPaperlessTermsFile];
    return new SignatureRequest(files, callbackUrl);
  }

  public generateCustomerEmail(payload): Observable<string> {
    return this.store.select(PolicySelectors.selectPolicies).pipe(
      mergeMap(policies => observableFrom(policies)),
      filter(
        policy =>
          policy &&
          policy.assignedProducer.producerIdNum &&
          policy.assignedProducer.producerIdNum !== ''
      ),
      take(1),
      map(policy => {
        if (policy && policy.assignedProducer.producerIdNum) {
          const messageDetails: MessageDetail[] = new Array<MessageDetail>();
          messageDetails.push(new MessageDetail('FirstName', this.user.firstName));
          messageDetails.push(new MessageDetail('EmailAddress', this.user.emailAddress));
          messageDetails.push(new MessageDetail('AgentID', policy.assignedProducer.producerIdNum));

          if (
            payload.paperlessOperation === GenerateEmailConstants.MAACommPrefChangeOptIn ||
            payload.paperlessOperation === GenerateEmailConstants.MAACommPrefChangeOptOut ||
            payload.paperlessOperation === GenerateEmailConstants.MAAStatementPrefChangeOptBoth
          ) {
            messageDetails.push(
              new MessageDetail('AffectedItemsList', payload.affectedItemsList.join(''))
            );
            messageDetails.push(new MessageDetail('SubscriberKey', this.user.emailAddress));
          }

          return messageDetails;
        }

        return new Array<MessageDetail>();
      }),
      mergeMap(details => {
        const email = new TemplatedEmail(
          payload.paperlessOperation,
          this.partnerId,
          'MyAccount',
          'EN',
          this.user.customerId,
          this.user.emailAddress,
          details
        );

        return this.communicationService.sendTemplatedEmail(email);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public changeEDeliveryStatus(edeliveryIndicator: 'Y' | 'N'): Observable<any> {
    return this.store.select(GoPaperLessSelectors.selectCustomerAndPartyDetails).pipe(
      filter(state => state.party.partyVersion !== undefined),
      take(1),
      mergeMap(state => {
        if (state.customerId && state.party) {
          const baseUrl = this.configService.get('partyApi');
          const url = baseUrl + 'parties/' + state.customerId + '/edeliveryoptings';
          let primaryEmail = state.party.emails.filter(
            email => email.emailAddress.toLowerCase() === this.user.emailAddress.toLowerCase()
          );
          const isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
          const sourceSystemName = isAdminApp ? SourceSystemType.ADMIN : SourceSystemType.MYACCOUNT;
          if (isAdminApp) {
            // eslint-disable-next-line ngrx/no-store-subscription
            this.store.select(userQuery.getSmImpersonatorUserId).subscribe(userID => {
              this.userID = userID;
            });

            primaryEmail = primaryEmail.map(email => {
              const updatedEmail = { ...email, primaryIndicator: 'NOT_MANAGED' };
              return updatedEmail;
            });
          } else {
            this.userID = this.configService.get('processId');
          }
          const data: EDeliveryOptingsModel = {
            authId: this.userID,
            party: {
              edeliveryIndicator: edeliveryIndicator,
              partyVersion: String(state.party.partyVersion),
              updatedOn: state.party.updatedOn,
              typeOfPartyCode: state.party.typeOfPartyCode,

              sourceSystemInformation: {
                sourceSystemName: sourceSystemName,
                sourceSystemKey: state.customerId
              },
              contactMethodDetail: {
                emails: [...primaryEmail]
              }
            }
          };

          return this.http.post(url, data /*{ headers: this.getHeaders() }*/).pipe(
            map((json: { status: Status; reason: unknown }) => {
              if (!json.status || json.status.code !== 200) {
                throw json.reason || 'Unknown error';
              }
              return json;
            })
          );
        }
      })
    );
  }

  /**
   * MR: Builds the payload for RPA GoPaperless discount POST
   */
  buildPaperlessPayload(paperlessIndicator: boolean): Observable<PaperlessDiscountPayload> {
    const policiesArray: DiscountPolicyModel[] = [];

    return this.store.select(GoPaperLessSelectors.selectPaperlessData).pipe(
      filter(state => !!state.policies.length),
      take(1),
      mergeMap(state => {
        // Build array of all active policies and associated producer ids
        state.policies.forEach(policy => {
          const policyModel: DiscountPolicyModel = {
            policyNumber: policy.policyNumber,
            producerId: _get(policy, 'assignedProducer.producerIdNum', '')
          };
          policiesArray.push(policyModel);
        });

        const payload: PaperlessDiscountPayload = {
          FormInstanceTypePaperless: {
            policies: policiesArray,
            paperlessIndicator: paperlessIndicator,
            effectiveDate: format(new Date(), 'YYYY-MM-DD'),
            customerId: state.customerId,
            partnerId: state.partnerId,
            templateCode: 'PAPERLESS_DISCOUNT_CHANGE'
          }
        };
        return of(payload);
      }),
      catchError(error => observableThrowError(error))
    );
  }

  /**
   * MR: Sends data to RPA to apply GoPaperless discounts
   */
  applyPaperlessDiscount(payload: PaperlessDiscountPayload) {
    const isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
    let headers = null;
    if (!isAdminApp) {
      headers = new HttpHeaders({ 'AFI-UserId': 'MyAF_Web' });
    }

    const endpoint = this.configService.get('discountApi');

    return this.http.post(endpoint + 'applypaperlessdiscount', payload, { headers: headers });
  }
  //commented as it is working without these headers
  // private getHeaders(): HttpHeaders {
  //   return new HttpHeaders({
  //      authId: this.configService.get('processId'),
  //      userId: this.configService.get('processId')

  //   });
  // }

  /**
   * @author Abhishek Singh
   * @param
   * @returns
   * @description Send the analytics for the go paperless page actions.
   */
  public sendAnalytics(analyticPayload: GoPaperLessAnalyticsModel): void {
    if (analyticPayload.pageAnalytic) {
      return this.analyticsFacade.trackPage(analyticPayload.pageAnalytic);
    }
    return this.analyticsFacade.trackButtonClick(analyticPayload.buttonAnalytic);
  }
}
