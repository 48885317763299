import { ApiStatus } from '@amfam/shared/models';
import { SecurityQuestion } from '../security-questions/security-questions.models';

export interface DigitalAccount {
  waid?: string;
  customerId?: string;
  userId?: string;
  password?: string;
  typeOfAccountCode?: string;
  emailAddress?: string;
  partnerId?: string;
  securityQuestions?: SecurityQuestion[];
  maskedEmailAddress?: string;
  maskedPhoneNumbers?: string[];
  nameDetail?: NameDetail;
  userIdLastUpdatedOn?: string;
  securityQuestionsLastUpdatedOn?: string;
  shellAccount?: boolean;
  enrollmentRequest?: boolean;
  verificationInProgress?: boolean;
}

export interface NameDetail {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  businessName?: string;
  greetingName?: string;
}

export interface UpdateDigitalAccountPayload {
  partnerId: string;
  experienceId: string;
  waid: string;
  digitalAccount: DigitalAccount;
}

export interface CreateDigitalAccountPayload {
  partnerId: string;
  experienceId: string;
  maskedEmailAddress: string;
  digitalAccount: {
    customerId: string;
    typeOfAccountCode: string;
    userId: string;
    password: string;
    emailAddress: string;
    securityQuestions: SecurityQuestion[];
  };
}

export interface GetDigitalAccountByCdhIdPayload {
  cdhId: string;
}

export interface GetDigitalAccountByWaidPayload {
  waid: string;
}

export interface GetDigitalAccountByWaidResponse {
  status: ApiStatus;
  digitalAccount: {
    waid: string;
    customerId: string;
    userId: string;
    typeOfAccountCode: string;
    emailAddress: string;
    partnerId: string;
    password?: string;
    securityQuestions: SecurityQuestion[];
    nameDetail: {
      firstName: string;
      middleName: string;
      lastName: string;
      businessName?: string;
    };
    userIdLastUpdatedOn: string;
    securityQuestionsLastUpdatedOn?: string;
  };
  shellAccount?: boolean;
}

export interface GetDigitalAccountByCdhIdResponse {
  status: ApiStatus;
  shellAccountIndicator: boolean;
  enrollmentRequestIndicator: boolean;
  verificationInProgressIndicator: boolean;
  maskedEmailAddress: string;
  maskedPhoneNumbers: string[];
  waid?: string;
  emailAddress?: string;
  cdhId?: string;
}

export interface AuthenticatePayload {
  userId: string;
  password: string;
  rememberMe: boolean;
  partnerId: string;
  correlationId: string;
}

interface Message {
  code: number;
  level: string;
  description: string;
  details: Detail[];
}

interface Detail {
  field: string;
  description: string;
}

export interface AuthenticateSuccessResponse {
  status: ApiStatus;
  lastLoggedInOn: string;
  sessionValidUntil: string;
  trackId: string;
  displayName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userId: string;
  customerId: string;
  WAID: string;
  businessName: string;
  typeOfAccountCode: string;
  token: string;
  partnerId: string;
  experienceId: string;
}

export interface RefreshSuccessResponse {
  businessName?: string;
  customerId?: string;
  displayName: string;
  emailAddress?: string;
  experienceId: string;
  firstName: string;
  greetingName: string;
  lastLoggedInOn: string;
  lastName: string;
  partnerId: string;
  sessionValidUntil: string;
  smImpersonator?: string;
  smImpersonatorUserID?: string;
  status: ApiStatus;
  token: string;
  trackId: string;
  typeOfAccountCode: string;
  userId: string;
  WAID?: string;
}

export interface CheckEmailAddressResponse {
  status: ApiStatus;
  emailDetail: EmailDetail;
  correlationId: string;
}

export interface EmailDetail {
  emailAddress: string;
  assignedToAccountIndicator: boolean;
}
export interface CheckEmailAddressPayload {
  emailAddress: string;
  partnerId: string;
  correlationId: string;
}
export enum DigitalAccountError {
  SERVER_ERROR = 'This service is temporarily unavailable. Please try again later.'
}
