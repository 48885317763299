import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { AnalyticsActions } from '@amfam/shared/analytics';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { launchOverview } from '../+state/mae-feature.actions';
import { MaeAnalytics } from '../mae-constants';

@Component({
  selector: 'ds-skipautopay',
  templateUrl: './skipautopay.component.html',
  styleUrls: ['./skipautopay.component.scss']
})
export class SkipautopayComponent implements OnInit {
  @Output() createAccountEvent: EventEmitter<any> = new EventEmitter();

  skipAutoPayFirstLineText;
  skipAutoPaySecondLineText;

  constructor(
    private copyService: CopyService,
    private store: Store,
    private spinnerService: LoadingSpinnerService
  ) {}

  ngOnInit(): void {
    this.skipAutoPayFirstLineText = this.copyService.getCopy('enrollment.skipAutoPayFirstLineText');
    this.skipAutoPaySecondLineText = this.copyService.getCopy(
      'enrollment.skipAutoPaySecondLineText'
    );

    this.store.dispatch(
      AnalyticsActions.sendAnalytic({
        options: {
          page: MaeAnalytics.pageAgentProgramEnrollmentComplete
        }
      })
    );
    this.spinnerService.stop();
  }

  continue() {
    this.store.dispatch(launchOverview());
  }
}
