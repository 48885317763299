import { get as _get } from 'lodash';

import * as impersonation from './impersonation.actions';
import { ImpersonationState, initialImpersonationState } from './impersonation.model';

export const IMPERSONATION_FEATURE_KEY = 'impersonation';

export function reducer(
  state = initialImpersonationState,
  action: impersonation.ImpersonationActions
): ImpersonationState {
  switch (action.type) {
    case impersonation.SET_ROLES_SUCCESS:
      return Object.assign({}, state, {
        permissions: action.payload.permissions,
        isImpersonating: action.payload.isImpersonating,
        hasError: false,
        isLoading: false
      });

    case impersonation.SET_ROLES_FAIL:
      return Object.assign({}, state, {
        hasError: true,
        isLoading: false
      });

    default:
      return state;
  }
}

export const isImpersonating = (state: ImpersonationState) => _get(state, 'isImpersonating', false);

export const permissions = (state: ImpersonationState) => _get(state, 'permissions', []);

export const fromImpersonation = {
  isImpersonating,
  permissions
};
