import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';

@Directive({
  selector: '[featureDisable]'
})
export class FeatureDisableDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private feature: FeatureFlagService
  ) {}

  @Input()
  set featureDisable(flag: string) {
    if (this.feature.isEnabled(flag)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
