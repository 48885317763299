import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { userQuery, UserState } from '@amfam/shared/user';

import { ClaimServicesService } from '../../services/claim-services.service';
import {
  EligibleServicesActionTypes,
  GetEligibleServices,
  GetEligibleServicesFail,
  GetEligibleServicesSuccess
} from './eligible-services.actions';
import { EligibleServicesResponse, GetEligibleServicesPayload } from './eligible-services.models';

@Injectable()
export class EligibleServicesEffects {
  getEligibleServices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEligibleServices>(EligibleServicesActionTypes.GetEligibleServices),
      map(action => action.payload),
      withLatestFrom(this.userStore.select(userQuery.getCustomerId)),
      switchMap(([payload, customerId]) => {
        return this.claimServicesService
          .getEligibleServices(payload.claimNumber, payload.vin, customerId)
          .pipe(
            map((response: EligibleServicesResponse) => new GetEligibleServicesSuccess(response)),
            catchError(error => of(new GetEligibleServicesFail(error)))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private claimServicesService: ClaimServicesService,
    private userStore: Store<UserState>
  ) {}
}
