import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BillingAutoPayDataAccessModule } from '@amfam/billing/auto-pay/data-access';
import { BillingAutoPayUiModule } from '@amfam/billing/auto-pay/ui';
import { BillingPaymentMethodDataAccessModule } from '@amfam/billing/payment-method/data-access';
import { BillingPaymentMethodFeatureModule } from '@amfam/billing/payment-method/feature';
import { BillingSharedUiModule } from '@amfam/billing/shared/ui';
import { MaeUiModule } from '@amfam/mae/ui';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';

import { AutoPaySetupStateModule } from './+state/auto-pay-setup-state.module';
import { AutoPayConfirmationWrapperComponent } from './components/auto-pay-confirmation-wrapper/auto-pay-confirmation-wrapper.component';
import { AutoPayReviewWrapperComponent } from './components/auto-pay-review-wrapper/auto-pay-review-wrapper.component';
import { AutoPaySetupWrapperComponent } from './components/auto-pay-setup-wrapper/auto-pay-setup-wrapper.component';
import { BillAccountSelectionWrapperComponent } from './components/bill-account-selection-wrapper/bill-account-selection-wrapper.component';
import { AutoPayWrapperComponent } from './containers/auto-pay-wrapper/auto-pay-wrapper.component';
import { AutoPayRoutingModule } from './routing/auto-pay-routing.module';

@NgModule({
  imports: [
    CommonModule,
    AutoPayRoutingModule,
    UiKitModule,
    SharedServicesModule,
    FormsModule,
    ReactiveFormsModule,
    BillingAutoPayUiModule,
    BillingAutoPayDataAccessModule,
    BillingPaymentMethodDataAccessModule,
    BillingPaymentMethodFeatureModule,
    AutoPaySetupStateModule,
    BillingSharedUiModule,
    MaeUiModule
  ],
  declarations: [
    AutoPayWrapperComponent,
    BillAccountSelectionWrapperComponent,
    AutoPayReviewWrapperComponent,
    AutoPayConfirmationWrapperComponent,
    AutoPaySetupWrapperComponent
  ]
})
export class BillingAutoPayFeatureModule {}
