import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';

import * as fromRoot from '../../../../core/store/';
import * as enrollUserActions from '../../../../core/store/enrollment';
import * as enrollStepsActions from '../../../../core/store/enrollment/steps';
import { ExitWarningService } from '../../../exit-warning/exit-warning.service';

@Injectable()
export class ResetRoutesService {
  constructor(
    private store: Store<fromRoot.RootState>,
    private warningService: ExitWarningService
  ) {}

  resetEnrollmentSteps() {
    this.store.dispatch(new enrollStepsActions.ResetEnrollStepsAction());
    this.store.dispatch(new enrollUserActions.ResetEnrollmentAction());
    this.warningService.setAdviceIgnored(false);
  }
}
