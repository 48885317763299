import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { every as _every, get as _get } from 'lodash';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { AutoPayUtilService } from '@amfam/billing/auto-pay/feature';
import { AutoPayUtilSelectors } from '@amfam/billing/auto-pay/util';
import {
  BillAccountActions,
  BillAccountsSelectors,
  BillingUtilService
} from '@amfam/billing/billaccount/data-access';
import { EsignatureActions, EsignatureSelectors } from '@amfam/policy/e-signature/data-access';
import {
  GoPaperLessActions,
  GoPaperLessSelectors,
  GoPaperlessService
} from '@amfam/policy/go-paperless/data-access';
import { CommunicationPreferencesSelectors } from '@amfam/profile/communication-preferences/data-access';
import {
  AnalyticsFacade,
  EventAnalyticParams,
  PaperlessAnalytics,
  PaperlessAnalyticsAdmin
} from '@amfam/shared/analytics';
import { BillAccount, BillAccountData, NewBillingPreference } from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import {
  Applications,
  ApplicationService,
  CopyService,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { DockingBarService, DsModalService, LoadingSpinnerService } from '@amfam/ui-kit';

import { selectBillingEligibleToGoPaperless } from '../../+state/common.selectors';
import { initiateOnboardingPaperlessEnrollment } from '../../+state/onboarding-paperless-enrollment-feature-actions';
import { CommunicationPreferencesUtilService } from '../../util/communication-preferences-util.service';

@Component({
  selector: 'ds-go-paperless-wrapper',
  templateUrl: './go-paperless-wrapper.component.html',
  styleUrls: ['./go-paperless-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoPaperlessWrapperComponent implements OnInit, OnDestroy {
  public policyEnrolledGoPaperless: boolean;
  isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
  eSignatureStatus: string;
  billAccounts$: Observable<BillAccount[]>;
  billAccounts: BillAccount[];
  showTermsAndConditionsModal = false;
  email$: Observable<string>;
  /**
   *  new falg: paperless_confirmation_new
   */
  paperLessConfirmationNew: boolean;
  private eSignatureErrorQueryParams: string[];
  private stop$: Subject<void> = new Subject<void>();
  private isESignatureRequired: boolean;
  private newBillingPreferences: NewBillingPreference[] = [];
  private correlationId: string;
  private billingEnrolledGoPaperless: boolean;

  constructor(
    private dockingBar: DockingBarService,
    private appService: ApplicationService,
    private autoPayUtil: AutoPayUtilService,
    private modalService: DsModalService,
    private spinner: LoadingSpinnerService,
    private store: Store,
    private utilService: UtilService,
    private goPaperlessService: GoPaperlessService,
    private copyService: CopyService,
    private route: ActivatedRoute,
    private communicationPreferencesUtilService: CommunicationPreferencesUtilService,
    private analyticsFacade: AnalyticsFacade,
    private feature: FeatureFlagService,
    private billingUtilService: BillingUtilService
  ) {}

  ngOnInit(): void {
    this.correlationId = this.utilService.generateId();

    if (!this.isAdminApp) {
      this.dockingBar.registerHeading('Paperless Signup');
    }

    this.loadESignature();
    this.onSigningComplete();
    this.email$ = this.store.select(userQuery.getEmailAddress);

    if (this.isAdminApp) {
      this.analyticsFacade.trackPage(PaperlessAnalyticsAdmin.pageOnboardingPaperless);
    } else {
      this.analyticsFacade.trackPage(PaperlessAnalytics.pageOnboardingPaperless);
    }

    this.paperLessConfirmationNew = this.feature.isEnabled('paperless_confirmation_new');
  }

  loadESignature() {
    combineLatest([
      this.store.select(GoPaperLessSelectors.selectIsEnrolledGoPaperless),
      this.store.select(EsignatureSelectors.getESignatureRequired),
      this.store.select(selectBillingEligibleToGoPaperless)
    ])
      .pipe(takeUntil(this.stop$))
      .subscribe(([eDelivery, eSignatureRequired, isBillngEligibleToGoPaperless]) => {
        this.policyEnrolledGoPaperless = eDelivery;
        this.isESignatureRequired = this.isAdminApp ? false : eSignatureRequired;
        this.billingEnrolledGoPaperless = !isBillngEligibleToGoPaperless;
      });
  }

  onSigningComplete() {
    this.route.queryParams.pipe(takeUntil(this.stop$)).subscribe(queryParams => {
      // Query param from docusign
      if (queryParams['event']) {
        this.eSignatureStatus = queryParams['event'];
      }
    });
    if (this.eSignatureStatus === 'OnSigningComplete') {
      this.spinner.start({
        blockActions: true
      });
      if (this.paperLessConfirmationNew) {
        this.store.dispatch(
          GoPaperLessActions.EnrollGoPaperless({ correlationId: this.correlationId })
        );
      } else {
        this.communicationPreferencesUtilService.setPolicyDocumentPreference();
      }

      this.setBillingPreferences(this.correlationId);
      this.routeToConfirmation();
    }
  }

  submit() {
    if (this.isAdminApp) {
      this.showTermsAndConditionsModal = true;
    } else {
      this.setPreferences(this.correlationId);
    }
  }

  skipPaperless() {
    this.store.dispatch(
      initiateOnboardingPaperlessEnrollment({ correlationId: this.correlationId })
    );
    this.store
      .select(AutoPayUtilSelectors.selectBillAccountsEligibleForAutoPay)
      .pipe(take(1))
      .subscribe(billAccounts => {
        // take the user to autopay setup if they have eligible bill accounts
        if (billAccounts.length === 0) {
          this.store.dispatch(
            fromRouterActions.Go({
              path: ['/overview']
            })
          );
        } else {
          this.autoPayUtil.routeToMultipleAutoPaySelection('overview');
        }
      });
  }

  agreeTermsAndConditions(agree: boolean): void {
    if (agree) {
      this.setPreferences(this.correlationId);
    }
  }

  openTermsAndConditionModal() {
    this.modalService.open('termAndConditionsModal');
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
  private setPreferences(correlationId: string) {
    // GA event for paperless enrollment start
    const paperlessStartEvent: EventAnalyticParams = {
      event: 'paperless_enroll_start',
      method: 'create account'
    };
    this.analyticsFacade.trackEvent(paperlessStartEvent);

    //TODO - Define a feature store to handle the flow
    this.store.dispatch(initiateOnboardingPaperlessEnrollment({ correlationId: correlationId }));
    // Route to docusign for GA customers if e-signature is required
    if (this.isESignatureRequired && !this.policyEnrolledGoPaperless) {
      this.store
        .select(BrandSelectors.selectExperienceId)
        .pipe(take(1))
        .subscribe(experienceId => {
          const callbackUrl = this.utilService.getAbsoluteUrl('paperless/sign-up', experienceId);
          const signatureRequest = this.goPaperlessService.createSignatureRequest(callbackUrl);
          this.store.dispatch(EsignatureActions.SignESignature(signatureRequest));
        });
      this.spinner.start({
        blockActions: true
      });
    } else if (
      !this.isESignatureRequired &&
      (!this.policyEnrolledGoPaperless || !this.billingEnrolledGoPaperless)
    ) {
      this.spinner.start({
        blockActions: true
      });
      if (!this.policyEnrolledGoPaperless) {
        if (this.paperLessConfirmationNew) {
          this.store.dispatch(
            GoPaperLessActions.EnrollGoPaperless({ correlationId: correlationId })
          );
        } else {
          this.communicationPreferencesUtilService.setPolicyDocumentPreference();
        }
      }
      if (!this.billingEnrolledGoPaperless) {
        this.setBillingPreferences(correlationId);
      }
      this.routeToConfirmation();
    }
  }

  private setBillingPreferences(correlationId: string) {
    if (!this.paperLessConfirmationNew) {
      combineLatest([
        this.store.select(BillAccountsSelectors.selectAllBillingPreferencesLoaded),
        this.store.select(BillAccountsSelectors.selectBillAccounts)
      ])
        .pipe(
          filter(
            ([loaded, billAccounts]) =>
              !!loaded &&
              (billAccounts.length > 0
                ? _every(
                    billAccounts,
                    billAccount => _get(billAccount, 'billingPreferences.preferences') !== undefined
                  )
                : false)
          ),
          take(1)
        )
        .subscribe(([, billAccounts]) => {
          billAccounts.forEach((billAccount: BillAccount) => {
            this.communicationPreferencesUtilService.setBillAccountDeliveryPreference(billAccount);
          });
        });
    } else {
      combineLatest([
        this.store.select(BillAccountsSelectors.selectAllBillingPreferencesLoaded),
        this.store.select(BillAccountsSelectors.selectBillAccounts)
      ])
        .pipe(
          filter(
            ([loaded, billAccounts]) =>
              !!loaded &&
              (billAccounts.length > 0
                ? _every(
                    billAccounts,
                    billAccount => _get(billAccount, 'billingPreferences.preferences') !== undefined
                  )
                : false)
          ),
          take(1)
        )
        .subscribe(([, billAccounts]) => {
          this.billAccounts = billAccounts;
        });

      this.newBillingPreferences = this.billingUtilService.setBillAccountsToPaperless(
        this.billAccounts
      );

      if (this.newBillingPreferences && this.newBillingPreferences.length > 0) {
        this.newBillingPreferences.forEach(newPreference => {
          this.store
            .select(
              BillAccountsSelectors.selectBillAccountDataToUpdatePreferences(
                newPreference.billAccountNumber
              )
            )
            .pipe(take(1))
            .subscribe((billAccountData: BillAccountData) => {
              const preferencesObj = this.billingUtilService.buildPreferencePayload(
                newPreference,
                billAccountData,
                correlationId
              );
              this.store.dispatch(
                BillAccountActions.billAccountUpdateDeliveryPreference(preferencesObj)
              );
            });
        });
      }
    }
  }

  private routeToConfirmation() {
    combineLatest([
      this.store.select(GoPaperLessSelectors.selectGoPaperlessLoaded),
      this.store.select(CommunicationPreferencesSelectors.selectAnyLoading),
      this.store.select(BillAccountsSelectors.selectBillAccounts)
    ])
      .pipe(
        filter(
          ([policyLoaded, billingLoading, billAccounts]) =>
            !!policyLoaded &&
            !billingLoading &&
            (billAccounts.length > 0
              ? _every(
                  billAccounts,
                  billAccount => _get(billAccount, 'billingPreferences.preferences') !== undefined
                )
              : false)
        ),
        take(1)
      )
      .subscribe(([policyLoaded, billingLoading]) => {
        if (policyLoaded && !billingLoading) {
          this.spinner.stop();
          let path = '';
          if (this.isAdminApp) {
            path = this.paperLessConfirmationNew
              ? '/enroll/paperless/gopaperless/confirmation'
              : '/enroll/paperless/confirmation';
          } else {
            path = this.paperLessConfirmationNew
              ? '/paperless/gopaperless/confirmation'
              : 'paperless/confirmation';
          }
          this.store.dispatch(fromRouterActions.Go({ path: [path] }));
        }
      });
  }
}
