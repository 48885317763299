import {
  ButtonAnalyticParams,
  EventAnalyticParams,
  PageAnalyticParams
} from '../models/analytics.model';

export class ThirdPartyFnolAnalytics {
  public static readonly pageInsuredInfoQuestion: PageAnalyticParams = {
    pageName: 'Claims:ReportClaim:ThirdParty:InsuredInfoQuestion',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageAdvanceVehicleInfo: PageAnalyticParams = {
    pageName: 'Claims:ReportClaim:ThirdParty:AdvanceVehicleInfo',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly pageNoMatchingPolicy: PageAnalyticParams = {
    pageName: 'Claims:ReportClaim:ThirdParty:AdvanceVehicleInfo',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly buttonNoMatchingPolicyCallUs: ButtonAnalyticParams = {
    link: 'Claims:ReportClaim:ThirdParty:NoMatchingPolicy:CallUs'
  };

  public static readonly buttonNoMatchingPolicyContinueFilingClaim: ButtonAnalyticParams = {
    link: 'Claims:ReportClaim:ThirdParty:NoMatchingPolicy:ContinueFilingClaim'
  };

  public static readonly pageDuplicateClaim: PageAnalyticParams = {
    pageName: 'Claims:ReportClaim:ThirdParty:Auto:DuplicateClaim',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly buttonDuplicateClaimCallUs: ButtonAnalyticParams = {
    link: 'Claims:ReportClaim:ThirdParty:Auto:DuplicateClaim:CallUs'
  };

  public static readonly pageAutoClaimIncidentInfo: PageAnalyticParams = {
    pageName: 'Claims:ReportClaim:ThirdParty:Auto:ClaimStart',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly eventReportClaimStart: EventAnalyticParams = {
    eventName: 'Third Party Report A Claim',
    eventStep: 'start'
  };

  public static readonly pageRiskLocationInfo: PageAnalyticParams = {
    pageName: 'Claims:ReportClaim:Guest:Auto:Where',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };

  public static readonly pageAutoClaimSubmitted: PageAnalyticParams = {
    pageName: 'Claims:ReportClaim:ThirdParty:Auto:ClaimSubmitted',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report a Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };
  public static readonly eventThirdPartyAutoClaimSubmitted: EventAnalyticParams = {
    eventName: 'Third Party Report A Claim',
    eventStep: 'complete'
  };

  public static readonly pageAutoClaimSubmittedTimeout: PageAnalyticParams = {
    pageName: 'Claims:ReportClaim:ThirdParty:Auto:ClaimSubmittedTimeout',
    experience: '',
    primaryCategory: 'Claims',
    subCategory1: 'Report A Claim',
    subCategory2: 'Auto',
    subCategory3: ''
  };
}
