<layout-sidebar [sidebarVisible]="isCustomer">
  <div class="main">
    <section id="profile-information" class="margin-bottom-60">
      <div class="row">
        @if (isCustomer) {
          <ds-profile-card
            [profileCard]="profileCard$ | async"
            [isCustomer]="isCustomer"
            [isPartyLoaded]="isPartyLoaded$ | async"
            [forgerockEnabled]="forgerockEnabled"
            (moveToPhoneNumberClicked)="moveToPhoneNumber()"
          ></ds-profile-card>
        }
        @if (!isCustomer) {
          <ds-profile-card-business
            [forgerockEnabled]="forgerockEnabled"
          ></ds-profile-card-business>
        }
      </div>
    </section>
    <section id="profile-details">
      <div class="row">
        <!-- <div class="column twelve padding-bottom-20">
        <h2 data-cy="profileDetails" content [contentPath]="'profile.profileHeader'"></h2>
        <p *ngIf="isCustomer">
          <span content [contentPath]="'profile.profileText1'"></span>
          <span *dsPartnerAFI>
            <span content [contentPath]="'profile.profileText2'"></span>
            <a
              [dsRouterLink]="requestChangeRoute"
              content
              [contentPath]="'profile.requestChangeLinkText'"
              >
            </a>
            <span content [contentPath]="'profile.profileText3'"></span>
          </span>
        </p>
        <p *ngIf="!isCustomer"><span content [contentPath]="'profile.profileText1'"></span></p>
      </div> -->
      </div>
      <div class="row">
        <div class="column twelve">
          <ds-accordion [allowMultipleOpenCategories]="true" [allowToggleAccordion]="true">
            <ds-accordion-category [title]="securityInformationTitle" [startOpen]="openSecurity">
              <div class="accordion-content-wrapper">
                @if (forgerockEnabled) {
                  <div class="flex justify-center items-center">
                    <div class="security flex flex-direction-column font-weight-bold">
                      @if (forgerockMfaEnabled === true) {
                        <div class="multi-factor pad-vertical-16 flex justify-space-between">
                          <p>Multi-factor authentication</p>
                          <!-- <a class="pad-horizontal-16 flex items-center"
                        >Edit <i class="icon icon-chevron-right-thick"></i
                      ></a> -->
                          <p class="pad-horizontal-16 flex items-center">
                            <mat-slide-toggle
                              [(ngModel)]="isOn"
                              (click)="updateMfa()"
                            ></mat-slide-toggle>
                          </p>
                        </div>
                      }
                      <div class="password pad-vertical-16 flex justify-space-between">
                        Password
                        <a
                          class="pad-horizontal-16 flex items-center"
                          id="passwordchange"
                          (click)="changePassword()"
                          >Edit <i class="icon icon-chevron-right-thick"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                } @else {
                  <p
                    class="accordion-text pad-bottom-20"
                    content
                    [contentPath]="
                      hasSecurityQuestions
                        ? 'profile.securityInformationText'
                        : 'profile.noSecurityQuestionsSecurityInformationText'
                    "
                  ></p>
                  <ds-edit-userid class="block"></ds-edit-userid>
                  @if (hasSecurityQuestions) {
                    <ds-edit-security-questions
                      class="block"
                      [securityQuestions]="securityQuestions$ | async"
                    ></ds-edit-security-questions>
                  }
                  <ds-edit-password class="block pad-bottom-20"></ds-edit-password>
                }
              </div>
            </ds-accordion-category>
            <ds-accordion-category
              data-cy="contactInformation"
              [title]="contactInformationTitle"
              [startOpen]="openContact"
              data-cy="contactInformationTitle"
              (opening)="toggleContact(true)"
              (closing)="toggleContact(false)"
              (opened)="contactOpened()"
            >
              <div class="accordion-content-wrapper">
                @if (isCustomer) {
                  <p
                    class="accordion-text"
                    content
                    [contentPath]="'profile.contactInformationText'"
                  ></p>
                }
                @if (isCustomer) {
                  <ds-manage-email-address
                    [isDuplicateEmail]="isDuplicateEmail"
                  ></ds-manage-email-address>
                }
                @if (!isCustomer) {
                  <p
                    class="accordion-text"
                    content
                    [contentPath]="'profile.contactBusinessInformationText'"
                  ></p>
                }
                @if (!isCustomer) {
                  <ds-manage-business-email-address></ds-manage-business-email-address>
                }
                @if (isCustomer) {
                  <hr aria-hidden="true" />
                }
                @if (isCustomer) {
                  <ds-manage-phone-numbers id="managePhoneNumbers"></ds-manage-phone-numbers>
                }
              </div>
            </ds-accordion-category>
            <ng-container>
              @if (preferenceData?.length) {
                <ds-accordion-category
                  [title]="communicationPreferencesTitle"
                  [startOpen]="openCommunication"
                  data-cy="communicationPreferencesTitle"
                >
                  @if (!(isEligibleToViewPreferences | async)) {
                    <ds-error-message [errorList]="errorList"></ds-error-message>
                  }
                  @if (isEligibleToViewPreferences | async) {
                    <div class="accordion-content-wrapper">
                      <ds-communication-preferences
                        [preferenceData]="preferenceData"
                        [eSignatureStatus]="eSignatureStatus"
                      ></ds-communication-preferences>
                    </div>
                  }
                </ds-accordion-category>
              }
            </ng-container>
            @if ((isCostcoMembershipEnabled$ | async) && isCostcoPolicy) {
              <ds-accordion-category
                [title]="costcoMembershipTitle"
                [startOpen]="openCostcoMembership"
                data-cy="costcoMembershipTitle"
              >
                <div class="accordion-content-wrapper">
                  <ds-edit-membership />
                </div>
              </ds-accordion-category>
            }
            @if (deleteMyAccountToggleEnabled) {
              <ds-accordion-category
                [title]="deleteMyAccountTitle"
                [startOpen]="openDeleteMyAccount"
                data-cy="deleteMyAccountTitle"
              >
                <div class="accordion-content-wrapper">
                  <ds-delete-my-account />
                </div>
              </ds-accordion-category>
            }
          </ds-accordion>
        </div>
      </div>
    </section>
  </div>
  <aside class="main-aside">
    <div class="profile margin-bottom-30 flex flex-direction-column">
      @if (isCustomer && !isConnectPartner) {
        <div
          class="profile__header margin-bottom-12"
          safeEventHandler
          eventType="click"
          [action]="htmlClickEventHandler.bind(this)"
          data-cy="profileDetails"
          content
          contentPath="profile.profileHeader"
        ></div>
        <button class="arrow">
          <img src="/assets/images/doodle-arrow.svg" alt="Request Policy Update" />
        </button>
        <button
          (click)="requestPolicyUpdate()"
          class="policy-update flex-1 flex pad-vertical-12 pad-horizontal-16 justify-center"
        >
          Request Policy Update
        </button>
      } @else {
        @if (!isCustomer) {
          <p><span content [contentPath]="'profile.profileText1'"></span></p>
        }
      }
    </div>
    <links-agents-sidebar [quickLinksList]="quickLinksOverride"></links-agents-sidebar>
  </aside>
</layout-sidebar>
