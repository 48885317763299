import { createAction, props } from '@ngrx/store';

import { PaymentMethod, PaymentMethodAnalyticsModel } from '@amfam/billing/payment-method/util';

import type {
  AddEditPaymentMethodPayload,
  AddEditPaymentMethodResponse,
  UpdateModeOfAuthorizationPayload,
  UpdateModeOfAuthorizationResponse
} from '../../models/payment-method.model';

// Get Payment Method
export const getPaymentMethods = createAction(
  '[PaymentMethod] Get Payment Methods',
  props<{ correlationId: string }>()
);

export const getPaymentMethodsSuccess = createAction(
  '[PaymentMethod] Get Payment Methods Success',
  props<{ paymentMethods: PaymentMethod[]; correlationId: string }>()
);

export const getPaymentMethodsFailure = createAction(
  '[PaymentMethod] Get Payment Methods Failure',
  props<{ error: unknown; correlationId: string }>()
);

// Add Payment Method
export const addPaymentMethod = createAction(
  '[PaymentMethod] Add Payment Method',
  props<{
    correlationId: string;
    payload: AddEditPaymentMethodPayload;
  }>()
);

export const addPaymentMethodSuccess = createAction(
  '[PaymentMethod] Add Payment Method Success',
  props<{ correlationId: string; response: AddEditPaymentMethodResponse }>()
);

export const addPaymentMethodFailure = createAction(
  '[PaymentMethod] Add Payment Method Failure',
  props<{ error: unknown; correlationId: string }>()
);

// Edit Payment Method
export const editPaymentMethod = createAction(
  '[PaymentMethod] Edit Payment Method',
  props<{
    correlationId: string;
    payload: AddEditPaymentMethodPayload;
    paymentAccountId: string;
  }>()
);

export const editPaymentMethodSuccess = createAction(
  '[PaymentMethod] Edit Payment Method Success',
  props<{
    correlationId: string;
    oldPaymentAccountId: string;
    response: AddEditPaymentMethodResponse;
    newNickName: string;
    oldNickName: string;
  }>()
);

export const editPaymentMethodFailure = createAction(
  '[PaymentMethod] Edit Payment Method Failure',
  props<{ error: unknown; correlationId: string }>()
);

// Update Mode of Authorization
export const updateModeOfAuthorization = createAction(
  '[PaymentMethod] Update Mode Of Authorization',
  props<{
    correlationId: string;
    payload: UpdateModeOfAuthorizationPayload[];
  }>()
);

export const updateModeOfAuthorizationSuccess = createAction(
  '[PaymentMethod] Update Mode Of Authorization Success',
  props<{
    correlationId: string;
    response: UpdateModeOfAuthorizationResponse[];
  }>()
);

export const updateModeOfAuthorizationFailure = createAction(
  '[PaymentMethod] Update Mode Of Authorization Failure',
  props<{ error: unknown; correlationId: string }>()
);

// Delete Payment Method
export const storePaymentAccountId = createAction(
  '[PaymentMethod] Store Payment Account Id',
  props<{
    paymentAccountId: string;
  }>()
);

export const deletePaymentMethod = createAction(
  '[PaymentMethod] Delete Payment Method',
  props<{
    correlationId: string;
    paymentAccountId: string;
  }>()
);

export const deletePaymentMethodSuccess = createAction(
  '[PaymentMethod] Delete Payment Method Success',
  props<{
    correlationId: string;
    paymentAccountId: string;
    response: AddEditPaymentMethodResponse;
  }>()
);

export const deletePaymentMethodFailure = createAction(
  '[PaymentMethod] Delete Payment Method Failure',
  props<{ error: unknown; paymentAccountId: string; correlationId: string }>()
);

export const sendPaymentMethodAnalytics = createAction(
  '[PaymentMethod] Send Payment Method Analytics',
  props<{
    payload: PaymentMethodAnalyticsModel;
  }>()
);

// Reset Error State
export const resetErrorState = createAction('[PaymentMethod] Reset Error State');
export const resetpaymentAccountId = createAction('[PaymentMethod] Reset Payment Account Id');
