import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { LogoModel } from '@amfam/shared/models';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { routerQuery } from '@amfam/shared/utility/navigation';

@Component({
  selector: 'ds-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  @Input() chatEnabled? = false;
  @Input() feedbackEnabled = true;
  @Input() lastLogin? = '';
  @Input() partnerFooterLogo?: LogoModel;
  @Input() showContactInfo = true;
  newBillingDesign: boolean;

  constructor(
    private store: Store,
    private feature: FeatureFlagService
  ) {}

  ngOnInit() {
    this.newBillingDesign = this.feature.isEnabled('new-billing-design');
    this.store
      .select(routerQuery.getRouterCurrentStateUrl)
      .pipe()
      .subscribe(url => {
        if (url.includes('one-time-pay')) {
          document.getElementById('lastLogin')?.style.setProperty('display', 'none');
          document.getElementById('footer')?.style.setProperty('padding-top', '0px');
        } else {
          document.getElementById('lastLogin')?.style.setProperty('display', 'block');
          document.getElementById('footer')?.style.setProperty('padding-top', '60px');
        }
      });
  }
}
