@if (risk$ | async; as risk) {
  <div class="card-content" data-cy="insurance-card">
    <div
      class="main-header"
      data-cy="insuranceCardHeader"
      content
      [contentPath]="'insuranceCard.heading'"
    ></div>
    <!-- <div class="buttons">
    <div class="button">
      <a
        [routerLink]="'/policies/auto/' + risk.policyNumber"
        [queryParams]="{ vehicleId: risk.vehicleId }"
        data-cy="view-policy-link"
        ><img src="/assets/images/poi-auto.svg" /><span>View Policy</span>
      </a>
    </div>
    <div class="button">
      <a (click)="openStatement(risk.poiLink)" data-cy="download-pdf-link"
        ><img src="/assets/images/download.svg" /><span>Download as PDF</span>
      </a>
    </div>
  </div>
  <div>
    <ds-jump-links [jumpLinks]="links"></ds-jump-links>
  </div> -->
    <div class="margin-bottom-40">
      <!-- <h3 data-cy="yourPolicyHeader">Your Policy</h3> -->
      <amfam-policy-card
        [coverages]="coverages$ | async"
        [risk]="risk"
        [insureds]="insureds$ | async"
        data-cy="policyCard"
        [links]="links"
      ></amfam-policy-card>
    </div>
    <div class="margin-bottom-40">
      <div
        class="sub-header margin-bottom-20"
        data-cy="yourVehicleHeader"
        content
        [contentPath]="'insuranceCard.vehicleHeader'"
      ></div>
      <amfam-vehicle-card
        [risk]="risk"
        data-cy="vehicleCard"
        (vehicleCardClicked)="onVehicleCardClicked($event)"
      ></amfam-vehicle-card>
    </div>
    @if ((agent$ | async) && !isPartner) {
      <div
        class="agent-section margin-bottom-40"
        [class.stretch-column]="isHandsetPortrait$ | async"
      >
        <div
          class="sub-header margin-bottom-20"
          data-cy="yourAgentHeader"
          content
          [contentPath]="'insuranceCard.agentInfo'"
        ></div>
        <agent-item
          class="agent-item"
          [agent]="agent$ | async"
          [defaultContactNumber]="defaultContactNumber"
          data-cy="agentItem"
          [newTemplate]="true"
        ></agent-item>
      </div>
    }
    @if (!isPartner) {
      <div class="disclaimer">
        <div class="agent-id">
          <p data-cy="producerId">
            <span content [contentPath]="'insuranceCard.producerId'"></span>: {{ risk.producerId }}
          </p>
          @if (risk.address.state === 'AZ') {
            <p data-cy="azDotNumber">
              <span content [contentPath]="'insuranceCard.adot'"></span>: {{ azDOTNumber$ | async }}
            </p>
          }
        </div>
        <b>{{ POIName$ | async }}</b>
        <p [innerHTML]="stateDisclaimer$ | async" data-cy="stateDisclaimer"></p>
        <p [innerHTML]="footer" data-cy="footer"></p>
        <div class="company-and-naic">
          <p data-cy="amfamCompanyDescription">{{ risk.amfamCompanyDescription }}</p>
          <p data-cy="naicCode">
            <span content [contentPath]="'insuranceCard.naicNumber'"></span>:
            {{ naicCode$ | async }}
          </p>
        </div>
        <i data-cy="disclaimer">{{ disclaimer }}</i>
      </div>
    }
    @if (!isPartner) {
      <div class="doodle-container">
        <img src="/assets/images/car_waving.svg" />
      </div>
    }
  </div>
}
@if (isHandsetPortrait$ | async) {
  <div class="action sticky">
    <a href="tel:1-866-987-0206" class="flex-column icon-button" data-cy="roadside-assistance-link">
      <img src="/assets/images/TowTruck2x.png" height="50" width="50" alt="File a Claim" />
      <span data-cy="roadsideAssistance"
        ><span content [contentPath]="'insuranceCard.roadsideAssistance'"></span
      ></span>
    </a>
    <a
      class="flex-column icon-button"
      [routerLink]="['/claims/report-claim-fnol']"
      data-cy="fileClaimLink"
    >
      <img src="/assets/images/Document2x.png" height="50" width="50" alt="File a Claim" />
      <span content [contentPath]="'insuranceCard.fileClaim'"></span>
    </a>
  </div>
}

<ds-modal [id]="hasEffectiveDatesChangedModalId" [closeOthers]="true" [maxWidth]="800">
  <div>
    <h3
      class="modal-header"
      content
      [contentPath]="'insuranceCard.effectiveDatesModal.heading'"
    ></h3>
    <div class="modal-text">
      <div content [contentPath]="'insuranceCard.effectiveDatesModal.content1'"></div>
      <div content [contentPath]="'insuranceCard.effectiveDatesModal.content2'"></div>
    </div>
    <button
      ds-button
      (click)="openStatementForEffectiveDateChange()"
      class="gotIt-btn"
      content
      [contentPath]="'insuranceCard.effectiveDatesModal.cta'"
    ></button>
  </div>
</ds-modal>
