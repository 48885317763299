import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get as _get, merge as _merge } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configUrl = `config.json?t=${new Date().getTime()}`;
  private data: any;
  private http: HttpClient;
  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  load<T>(setConfigDefaults: Function): Promise<T> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return lastValueFrom(
      this.http.get<T>(this.configUrl, { headers: headers }).pipe(
        tap(data => {
          this.data = _merge({}, setConfigDefaults(data), data);
        })
      )
    );
  }

  get(key: string): any {
    return _get(this.data, key);
  }

  getConfigJson(): string {
    return JSON.stringify(this.data);
  }
}
