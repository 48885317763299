<div [ngClass]="{ 'note-info': nextSteps.isNote }">
  <div class="confirmation-nextSteps">
    @if (nextSteps?.heading) {
      <h2 class="confirmation-nextSteps-title margin-bottom-0" [innerHTML]="nextSteps.heading"></h2>
    }
    @if (nextSteps?.text) {
      <p [innerHTML]="nextSteps.text"></p>
    }
  </div>
</div>
