import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';

import { userQuery } from '@amfam/shared/user';
import {
  Applications,
  ApplicationService,
  ConfigService
} from '@amfam/shared/utility/shared-services';

import {
  GetFinancialInstitutionResponse,
  GetPCIAuthorizationTokenResponse,
  SavePaymentMethodPayload,
  SavePaymentMethodResponse
} from '../models/financial-account.model';

@Injectable({ providedIn: 'root' })
export class FinancialAccountService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private appService: ApplicationService,
    private store: Store
  ) {}

  savePaymentMethod(
    payload: SavePaymentMethodPayload,
    pciToken?: string /*FinAcctServiceRequestModel*/
  ): Observable<SavePaymentMethodResponse> {
    const endpoint: string =
      (this.appService.isApp(Applications.MYACCOUNT_ADMIN)
        ? this.config.get('financialAccountServiceInternal')
        : this.config.get('financialAccountService')) + 'financialaccounts';

    let headers: HttpHeaders = new HttpHeaders();

    if (!this.appService.isApp(Applications.MYACCOUNT_ADMIN)) {
      headers = headers.set('Authorization', pciToken);
    }
    return this.http.post<SavePaymentMethodResponse>(endpoint, payload, { headers });
  }

  getFinancialInstitution(
    routingNumber: string,
    pciToken?: string
  ): Observable<GetFinancialInstitutionResponse> {
    const endpoint: string =
      (this.appService.isApp(Applications.MYACCOUNT_ADMIN)
        ? this.config.get('financialAccountServiceInternal')
        : this.config.get('financialAccountService')) +
      'financialinstitutions/' +
      btoa(routingNumber);

    const params: HttpParams = new HttpParams().set(
      'consumerKey',
      this.config.get('finAcctConsumerKey')
    );

    let headers: HttpHeaders = new HttpHeaders();

    if (!this.appService.isApp(Applications.MYACCOUNT_ADMIN)) {
      headers = headers.set('Authorization', pciToken);
    }

    return this.http.get<GetFinancialInstitutionResponse>(endpoint, {
      headers,
      params
    });
  }

  getPCIAuthorizationToken(): Observable<GetPCIAuthorizationTokenResponse> {
    const uri: string = this.config.get('pciAuthorizationService') + 'create';
    let CustkeyToken = '';
    this.store
      .select(userQuery.selectCUSTKEY)
      .pipe(take(1))
      .subscribe(token => (CustkeyToken = token));
    const payload = {
      destinationName: 'FinancialAccountService',
      consumerKey: this.config.get('finAcctConsumerKey')
    };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + CustkeyToken
      })
    };
    return this.http.post<GetPCIAuthorizationTokenResponse>(uri, payload, httpOptions);
  }
}
