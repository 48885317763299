<div class="progress-tracker-container" [ngClass]="{ 'is-mini': mini }">
  @if (!!mini && !!activeStep?.title) {
    <span class="heading-section active">{{ activeStep.title }}</span>
  }
  @if (!mini) {
    <div role="presentation" class="flex items-center progress-text heading-section">
      @for (step of steps; track step; let first = $first; let last = $last) {
        <div
          aria-hidden="true"
          class="flex"
          [ngClass]="{
            'justify-flex-start first-label': first,
            'justify-space-around mid-label': !first && !last,
            'justify-flex-end last-label': last,
            'active-text': step.active
          }"
        >
          {{ step.title }}
        </div>
      }
    </div>
  }
  <ul class="progress-tracker progress-tracker" [attr.aria-label]="progressTitle">
    @for (step of steps; track step; let i = $index; let last = $last) {
      <li
        class="progress-step"
        [ngClass]="{
          'is-skipped': step.skipped,
          'is-complete': step.complete && !step.skipped,
          'is-active active': step.active && !step.complete,
          'complete-bar':
            (step.complete || step.skipped) &&
            steps[i + 1] &&
            (steps[i + 1].complete || steps[i + 1].active || steps[i + 1].skipped)
        }"
        [attr.aria-current]="step.active && !step.complete"
      >
        <span class="sr-only" [innerText]="activeStepStatus(step)"></span>
        @if (step.complete || step.active || step.skipped) {
          <span class="progress-marker">
            @if (!!step.complete) {
              <i class="icon-complete"></i>
            }
          </span>
        }
        @if (!mini) {
          <svg class="svg-circle">
            <circle
              cx="20"
              cy="20"
              fill="#CFCFCF"
              r="16"
              stroke="#CFCFCF"
              stroke-width="8"
            ></circle>
          </svg>
        }
        @if (!!mini) {
          <svg class="svg-circle">
            <circle cx="7" cy="14" fill="#CFCFCF" r="7"></circle>
          </svg>
        }
        @if (!last && !mini) {
          <svg class="svg-line">
            <line x1="0" y1="20" x2="100%" y2="20" stroke="#CFCFCF" stroke-width="16" />
          </svg>
        }
        @if (!last && !!mini) {
          <svg class="svg-line">
            <line x1="1" y1="14" x2="100%" y2="14" stroke="#CFCFCF" stroke-width="2" />
          </svg>
        }
      </li>
    }
  </ul>
</div>
