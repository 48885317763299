import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { AccountTypeEnum, userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { CopyService, PageAnalytic } from '@amfam/shared/utility/shared-services';

import * as fromRoot from '../../../../core/store/';
import { PartyModel } from '../../../../core/store/enrollment/personal/party';
import * as workflowActions from '../../../../core/store/workflow';
import { VerificationService } from '../../verification/verification.service';

@Component({
  selector: 'ds-enroll-verify-options',
  templateUrl: './enroll-verify-options.component.html',
  styleUrls: ['../../../enrollment.component.scss', './enroll-verify-options.component.scss']
})
export class EnrollVerifyOptionsComponent implements OnInit, OnDestroy {
  private pageAnalytic: PageAnalytic;
  private conversionPageAnalytic: PageAnalytic;
  public party: PartyModel;
  private isPartner: boolean;
  private combinedSub: Subscription;
  forgerockEnabled = this.featureFlag.isEnabled('forgerock');
  verifyOptions = this.copy.getCopy('enrollment.verifyOptions');

  constructor(
    private store: Store<fromRoot.RootState>,
    private verificationService: VerificationService,
    private analyticsFacade: AnalyticsFacade,
    private featureFlag: FeatureFlagService,
    private copy: CopyService
  ) {}

  ngOnInit() {
    // **** Start of Analytics data for this component
    this.pageAnalytic = {
      pageName: 'MyAccount:Enroll:IDNotFound',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Enroll',
      subCategory2: 'Personal',
      subCategory3: 'ID Not Found'
    };
    this.conversionPageAnalytic = {
      pageName: 'MyAccount:Enroll:ConfirmIdentity',
      experience: '',
      primaryCategory: 'My Account',
      subCategory1: 'Add Personal Line',
      subCategory2: '',
      subCategory3: ''
    };
    // **** End of Analytics data for this component
    this.combinedSub = combineLatest(
      this.store.select(userQuery.getTypeOfAccountCode),
      this.store.select(fromRoot.getEnrollUserState),
      this.store.select(fromRoot.identificationStep),
      this.store.select(BrandSelectors.selectPartnerId),
      this.store.select(BrandSelectors.selectIsPartner),
      (typeOfAccountCode, enrollUserState, identificationStep, partnerId, isPartner) => {
        return {
          typeOfAccountCode: typeOfAccountCode,
          enrollUserState: enrollUserState,
          step: identificationStep,
          partnerId: partnerId,
          isPartner: isPartner
        };
      }
    )
      .pipe(take(1))
      .subscribe(state => {
        // Analytics for conversion
        if (state.typeOfAccountCode && state.typeOfAccountCode !== AccountTypeEnum.Customer) {
          this.analyticsFacade.trackPage(this.conversionPageAnalytic);
        }
        this.party = {
          partyId: state.enrollUserState.partyId,
          partnerId: state.enrollUserState.partnerId,
          typeOfPartyCode: state.enrollUserState.typeOfPartyCode,
          firstName: state.enrollUserState.firstName,
          middleName: _get(state, 'enrollUserState.middleInitial', ''),
          lastName: state.enrollUserState.lastName,
          socialSecurityNumberOnRecordIndicator:
            state.enrollUserState.socialSecurityNumberOnRecordIndicator,
          maskedPhones: state.enrollUserState.maskedPhones,
          maskedEmails: state.enrollUserState.maskedEmails,
          match: state.enrollUserState.match
        };
        if (state.isPartner) {
          this.isPartner = true;
        }
        if (state.step) {
          if (this.party && this.party.match < 1) {
            this.analyticsFacade.trackPage(this.pageAnalytic);
          }
        }
      });
  }

  completeStep(route) {
    // complete the step
    this.verificationService.selectOption(route);
  }

  goBackOneStep() {
    this.store.dispatch(new workflowActions.WorkflowStepBackAction());
  }

  showSSN() {
    return !this.forgerockEnabled && this.party.socialSecurityNumberOnRecordIndicator;
  }

  showEmail() {
    return this.party.maskedEmails && this.party.maskedEmails.length >= 1;
  }

  showPhone() {
    return (
      !this.forgerockEnabled &&
      this.party.maskedPhones &&
      this.party.maskedPhones.length >= 1 &&
      !this.isPartner
    );
  }

  showText() {
    return this.party.maskedPhones && this.party.maskedPhones.length >= 1;
  }

  showPolicy() {
    return !this.forgerockEnabled && this.party.match >= 1;
  }

  ngOnDestroy() {
    if (this.combinedSub) {
      this.combinedSub.unsubscribe();
    }
  }
}
