/* eslint-disable max-len */

import { Injectable } from '@angular/core';
import {
  forEach as _forEach,
  get as _get,
  isString as _isString,
  range as _range,
  template as _template
} from 'lodash';
import {
  AllCopy,
  AuthCopy,
  BillingCopy,
  ClaimsCopy,
  ContactAdjusterCopy,
  DefaultQuickLinksCopy,
  EnrollmentCopy,
  FaqCopy,
  GoPaperlessCopy,
  KydCopy,
  KydSmartphoneCopy,
  KydSmartphonePhhCopy,
  OverviewCopy,
  PolicyCopy,
  PolicyDetailCopy,
  ProfileCopy,
  SharedCopy,
  FirstPartyFnolCopy,
  ThirdPartyFnolCopy,
  OpportunityContentCopy
} from './models';
import { OpportunityContent } from './models/opportunity-copy';
import { ConfigService } from '../config/config.service';
import { ApplicationService, Applications } from '../application';

@Injectable({
  providedIn: 'root'
})
export class CopyService {
  private authCopy: AuthCopy = require('./data/auth-copy.json');
  private billingCopy: BillingCopy = require('./data/billing-copy.json');
  private claimsCopy: ClaimsCopy = require('./data/claims-copy.json');
  private contactAdjusterCopy: ContactAdjusterCopy = require('./data/contact-adjuster-copy.json');
  private defaultQuickLinksCopy: DefaultQuickLinksCopy = require('./data/default-quick-links-copy.json');
  private enrollmentCopy: EnrollmentCopy = require('./data/enrollment-copy.json');
  private faqCopy: FaqCopy = require('./data/faq-copy.json');
  private goPaperlessCopy: GoPaperlessCopy = require('./data/go-paperless-copy.json');
  private kydCopy: KydCopy = require('./data/kyd-copy.json');
  private kydSmartphoneCopy: KydSmartphoneCopy = require('./data/kyd-smartphone-copy.json');
  private kydSmartphonePhhCopy: KydSmartphonePhhCopy = require('./data/kyd-smartphone-phh-copy.json');
  private overviewCopy: OverviewCopy = require('./data/overview-copy.json');
  private opportunityCopy: OpportunityContent[] = require('./data/opportunity-copy.json');
  private opportunityContentCopy: OpportunityContentCopy = require('./data/opportunity-content-copy.json');
  private policyCopy: PolicyCopy = require('./data/policy-copy.json');
  private policyDetailCopy: PolicyDetailCopy = require('./data/policy-detail-copy.json');
  private profileCopy: ProfileCopy = require('./data/profile-copy.json');
  private sharedCopy: SharedCopy = require('./data/shared-copy.json');
  private firstPartyFnolCopy: FirstPartyFnolCopy = require('./data/first-party-fnol-copy.json');
  private thirdPartyFnolCopy: ThirdPartyFnolCopy = require('./data/third-party-fnol-copy.json');

  private copy: AllCopy = {
    auth: this.authCopy,
    billing: this.billingCopy,
    claims: this.claimsCopy,
    contactAdjuster: this.contactAdjusterCopy,
    defaultQuickLinks: this.defaultQuickLinksCopy,
    enrollment: this.enrollmentCopy,
    faq: this.faqCopy,
    goPaperless: this.goPaperlessCopy,
    kyd: this.kydCopy,
    kydSmartphone: this.kydSmartphoneCopy,
    kydSmartphonePhh: this.kydSmartphonePhhCopy,
    overview: this.overviewCopy,
    opportunity: this.opportunityCopy,
    policy: this.policyCopy,
    policyDetail: this.policyDetailCopy,
    profile: this.profileCopy,
    shared: this.sharedCopy,
    firstPartyFnol: this.firstPartyFnolCopy,
    thirdPartyFnol: this.thirdPartyFnolCopy,
    opportunityContent: this.opportunityContentCopy
  };

  private programType;

  constructor(private config: ConfigService, private applicationService: ApplicationService) {}

  setProgramType(programType: string) {
    this.programType = programType;
    this.copy['enrollment'] = this.enrollmentCopy;
  }

  /**
   * @returns {object} All of the copy for a module
   * @param {string} module The copy module
   */
  getComponentData(module): any {
    if (this.copy[module]) {
      return this.copy[module];
    }
    if (!this.config.get('production')) {
      console.warn('copy service: no match for getComponentData', '\nmodule: ', module);
    }
  }

  /**
   * @returns {string || object} The copy found at the path or an empty string
   * @param {string} copyPath The path to the copy,
   * @param {object} options The object with data to resolve the template embedded expressions
   */
  getCopy(copyPath: string, options?: any, applicationDependentCopy?: boolean): any {
    /**
     * AS: If there is a application name being passed, check if we are currently being called
     * by that application, if yes,   then append the application name to the path for specific copy
     * for that path.
     */
    if (
      applicationDependentCopy &&
      (<string[]>Object.values(Applications)).includes(this.applicationService.getAppName())
    ) {
      copyPath = copyPath.replace(
        '.',
        `.${(<string>this.applicationService.getAppName()).toLowerCase()}.`
      );
    }
    let copyItem = _get(this.copy, copyPath);
    if (!copyItem) {
      copyItem = '';
      if (!this.config.get('production')) {
        console.warn('copy service: no match for getCopy ', 'copyPath: ', copyPath);
      }
    }
    if (_isString(copyItem)) {
      /**
       * If we pass the options parameters to the function then we are expecting the copyItem string
       * to have embedded expressions which needs to be resolved ahead of time. Use lodash's _template function and
       * pass the copy as a parameter. This would return a compiledTemplate function which would take an
       * object with key/value pairs. All the embedded expressions should be matched with the keys in the object
       * being passed in to avoid any errors.
       */
      if (options) {
        const compiledTemplate = _template(copyItem);
        return compiledTemplate(options);
      }
      return '' + copyItem;
    }
    return copyItem;
  }

  /**
   *  *** DEPRECATED, use getCopy instead ***
   *
   *  TODO: refactor references of get to use getCopy
   */
  get(module, key, subModule?): any {
    let copyPath = module + '.' + key;
    if (subModule) {
      copyPath = module + '.' + key + '.' + subModule;
    }
    return this.getCopy(copyPath);
  }
}
