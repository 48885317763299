import { AfterContentChecked, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective implements AfterContentChecked {
  @Input() focus: boolean;
  private element: HTMLElement;
  private hasFocused = false;

  constructor(elementReference: ElementRef) {
    this.element = elementReference.nativeElement;
  }

  ngAfterContentChecked() {
    this.giveFocus();
  }

  giveFocus() {
    if (this.focus && !this.hasFocused) {
      setTimeout(() => this.element.focus());
      this.hasFocused = true;
    }
  }
}
