import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';

import {
  ChangeCoverageEmailType,
  ICoverage,
  VehicleCoverageAnalyticPayLoad
} from '@amfam/policy/models';
import { VehicleCoveragesFacade } from '@amfam/policy/vehicle-coverages/data-access';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { CopyService } from '@amfam/shared/utility/shared-services';

import { ChangeCoverageFeatureService } from '../../services/change-coverage-feature.service';
import {
  changeCoverageNotifyAgent,
  editCoveragesSendAnalytics,
  vehicleCoveragesSendAnalytics
} from './change-coverage-feature.actions';

@Injectable({ providedIn: 'root' })
export class ChangeCoverageFeatureFacade {
  public initialCoverages$ = this.vehicleCoverageFacade.baseEntity$.pipe(
    map(entity => entity?.vehicleCoverages[0]?.coverages || [])
  );
  public isPartner$ = this.store.select(BrandSelectors.selectIsPartner);
  constructor(
    private store: Store<any>,
    private featureService: FeatureFlagService,
    private changeCoverageFeatureService: ChangeCoverageFeatureService,
    private vehicleCoverageFacade: VehicleCoveragesFacade,
    private copyService: CopyService
  ) {}

  vehicleId: string;
  sendAnalytics(payload: VehicleCoverageAnalyticPayLoad) {
    this.isPartner$.pipe(first()).subscribe(isPartner => {
      if (isPartner) {
        this.store.dispatch(vehicleCoveragesSendAnalytics({ payload }));
      } else {
        this.store.dispatch(editCoveragesSendAnalytics({ payload }));
      }
    });
  }

  sendToAgent(vehicleId: string) {
    this.store.dispatch(
      changeCoverageNotifyAgent({
        payload: { type: ChangeCoverageEmailType.SEND_TO_AGENT, vehicleId: vehicleId }
      })
    );
  }

  isCheckBox(c: ICoverage) {
    return this.changeCoverageFeatureService.isCheckBox(c);
  }

  isCoverageUpdated(initialCoverages: ICoverage[], newCoverage: ICoverage) {
    return this.changeCoverageFeatureService.isCoverageUpdated(initialCoverages, newCoverage);
  }
}
