<form [formGroup]="securityForm" (ngSubmit)="submitAnswers()">
  <ds-form-control-container
    [labelText]="componentInputLabels.securityQuestion1"
    [control]="securityForm.controls['inputFirstQuestion']"
  >
    <select
      (change)="onSelectedIndexChange(1)"
      id="firstQuestion"
      class="icon fill"
      data-cy="securityQuestionDropdown1"
      formControlName="inputFirstQuestion"
    >
      <option value=""></option>
      @for (question of questionList1; track question) {
        <option data-cy="dropdownOptions1" [value]="question">{{ question }}?</option>
      }
      <optgroup></optgroup>
    </select>
    <span class="icon-chevron-down"></span>
    <ds-form-control-message
      [control]="securityForm.controls['inputFirstQuestion']"
    ></ds-form-control-message>
  </ds-form-control-container>
  <ds-form-control-container
    [labelText]="componentInputLabels.securityAnswer1"
    [control]="securityForm.controls['inputFirstAnswer']"
  >
    <input
      type="text"
      id="inputFirstAnswer"
      (focus)="onInputFocus($event.target.id)"
      data-cy="securityQuestionInput1"
      class="fill"
      formControlName="inputFirstAnswer"
      maxlength="50"
    />
    <ds-form-control-message
      data-cy="editSecurityQuestionInputOneErrorText"
      [control]="securityForm.controls['inputFirstAnswer']"
    ></ds-form-control-message>
    @if (securityForm.hasError('answersMatch')) {
      <ds-form-control-message
        data-cy="editSecurityQuestionAnswersMatchText"
        [control]="securityForm"
      ></ds-form-control-message>
    }
  </ds-form-control-container>

  <ds-form-control-container
    [labelText]="componentInputLabels.securityQuestion2"
    [control]="securityForm.controls['inputSecondQuestion']"
  >
    <select
      id="inputSecondQuestion"
      (change)="onSelectedIndexChange(2)"
      class="icon fill"
      data-cy="securityQuestionDropdown2"
      formControlName="inputSecondQuestion"
    >
      <option value="" disabled></option>
      @for (question of questionList2; track question) {
        <option data-cy="dropdownOptions2" [value]="question">{{ question }}?</option>
      }
      <optgroup></optgroup>
    </select>
    <span class="icon-chevron-down"></span>
    <ds-form-control-message
      [control]="securityForm.controls['inputSecondQuestion']"
    ></ds-form-control-message>
  </ds-form-control-container>
  <ds-form-control-container
    [labelText]="componentInputLabels.securityAnswer2"
    [control]="securityForm.controls['inputSecondAnswer']"
  >
    <input
      type="text"
      id="inputSecondAnswer"
      (focus)="onInputFocus($event.target.id)"
      class="fill"
      data-cy="securityQuestionInput2"
      formControlName="inputSecondAnswer"
      maxlength="50"
    />
    <ds-form-control-message
      [control]="securityForm.controls['inputSecondAnswer']"
    ></ds-form-control-message>
    @if (securityForm.hasError('answersMatch')) {
      <ds-form-control-message [control]="securityForm"></ds-form-control-message>
    }
    @if (securityForm.hasError('ldapError')) {
      <ds-form-control-message [control]="securityForm"></ds-form-control-message>
    }
  </ds-form-control-container>

  @if (backButtonRoute) {
    <a href="javascript:void(0)" (click)="goBack()" class="inline-block margin-top-20 caption">
      <i class="icon-chevron-left"></i> Back
    </a>
  }
  <button
    ds-button
    class="float-right -offset-top"
    type="submit"
    data-cy="submitSecurityQuestionsButton"
    id="securityQuestionsBtn"
    [disabled]="
      !securityForm.valid ||
      (roleService.isAuthorized('securityquestionsanswers_view') | async) === false
    "
  >
    {{ buttonText }}
  </button>
</form>
