// TODO: @jjc - move this to a shared space, update docs and replace with shared model in sitemap
export interface NavLinkTreeObj {
  linkText: string;
  linkRoute?: string;
  queryParams?: string | object;
  linkExternalUrl?: string;
  textOnly?: boolean; // Treat as text. Set to true and use without linkRoute and linkExternal to treat the link as only text.
  linkTarget?: string;
  linkIcon?: string;
  linkFormat?: LinkFormat;
  ariaLabel?: string;
  groupName?: string;
  dividerAfter?: boolean;
  permissionName?: string;
  checkAction?: string;
  clickFunction?: () => void;
  listClasses?: string; // Classes specific to the list attributes
  linkClasses?: string; // Classes specific to the link attributes
  linkTestId?: string; // Test id of the link item
  submenuTestId?: string; // Test id for the submenu
  mobileLinkTestId?: string; // Test id of the link item in the mobile menu
  mobileSubmenuTestId?: string; // Test id of the submenu in the mobile menu
  mobileAltSubMenuTitle?: string; // Use to override the sub-menu title. e.g., Hi, Andy >> My Account
  mobileOnly?: boolean; // Use to only display this link item on the desktop/mobile menu
  mobileMenuItemOrder?: number; // Use to override the mobile menu item order
  linkTreeSliceObj?: NavLinkTreeSliceObj;
  maxWidth?: number; // Added to restrict width of link container
  overflowEllipsis?: boolean; // Added to add elipsis to a width constrained link's text. See .overflowElipsis class.
  subLinkArray?: NavLinkTreeObj[];
  showOnNavigationMenu?: boolean;
}

// Using a type so it can extend LinksObj, just adding linkCount
export type NavLinkTreeSliceObj = NavLinkTreeObj & {
  linkCount: number;
};

export const LinkSliceDefault = 4;

export enum LinkFormat {
  CLAIM = 'CLAIM',
  POLICY = 'POLICY',
  BILLING = 'BILLING'
}
