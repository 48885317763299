<!-- <div class="policy-card" data-cy="policy-card">
  <div class="grid-container">
    <div class="column">
      <div class="name">
        <p><strong data-cy="insuredNameTitle">Insured Name</strong></p>
        <p class="break-all" *ngFor="let name of insureds" data-cy="insured-name">{{ name }}</p>
      </div>
      <div class="policy-number">
        <div class="risk-policy">
          <p><strong data-cy="policyNumberTitle">Policy Number</strong></p>
          <p class="break-all" data-cy="policy-number">{{ risk.policyNumber }}</p>
        </div>
        <div class="copy-button">
          <a [ariaDisabled]="copied" (click)="copyPolicyNumberToClipboard()"
            ><img src="/assets/images/clipboard.svg" /><span *ngIf="!copied" data-cy="copy-button"
              >Copy</span
            ><span *ngIf="copied" data-cy="copied-message">Copied!</span>
          </a>
        </div>
      </div>
      <div class="effective-date">
        <p><strong data-cy="effectiveDateTitle">Effective Date</strong></p>
        <p class="break-all" data-cy="effective-date">
          {{ risk.periodStartDate | date: 'MM/d/yyyy' }}
        </p>
      </div>
    </div>
    <div class="column">
      <div class="address">
        <p><strong data-cy="addressTitle">Address</strong></p>
        <p class="break-all">
          {{ risk.policy.policyAddress.addressLine1 }}<br />{{
            risk.policy.policyAddress?.addressLine2
          }}{{ risk.policy.policyAddress.city }}, {{ risk.policy.policyAddress.state }}
          {{ risk.policy.policyAddress.zipCode }}
        </p>
      </div>
      <div class="coverages">
        <p><strong data-cy="coveragesTitle">Coverages</strong></p>
        <p class="break-all" data-cy="coverages">{{ coverages }}</p>
      </div>
      <div class="expiration-date">
        <p><strong data-cy="expirationDateTitle">Expiration Date</strong></p>
        <p class="break-all" data-cy="expiration-date">
          {{ risk.periodEndDate | date: 'MM/d/yyyy' }}
        </p>
      </div>
    </div>
  </div>
</div> -->
<div class="policy-card">
  <div class="policy-card__content" data-cy="policyCard">
    <div class="left">
      <article class="policy-card__content__item">
        <div class="header" data-cy="nameInsuredHeader">Named Insured</div>
        <div id="insured" class="content">
          <ul>
            @for (name of insureds; track $index) {
            <li data-cy="namedInsured">{{ name }}</li>
            }
          </ul>
        </div>
      </article>

      <div class="policy-card__content__jump-links">
        <ds-jump-links [jumpLinks]="links" size="medium"></ds-jump-links>
      </div>

      <article class="policy-card__content__item">
        <div class="header" data-cy="policyNumberHeader">Policy Number</div>
        <div class="content" data-cy="policyNumber">
          {{ risk.policyNumber | prettyPolicyNum }}
        </div>
      </article>
    </div>
    <div class="right">
      <article class="policy-card__content__item">
        <div class="header" data-cy="effectiveDatesHeader">Effective Dates</div>
        <div class="content" data-cy="effectiveDates">
          {{ risk.periodStartDate | date: 'MM/d/yyyy' }} -
          {{ risk.periodEndDate | date: 'MM/d/yyyy' }}
        </div>
      </article>
      <article class="policy-card__content__item">
        <div class="header" data-cy="addressHeader">Address</div>
        <div class="content" data-cy="address">
          {{ risk.policy.policyAddress.addressLine1 }}<br />{{
            risk.policy.policyAddress?.addressLine2
          }}{{ risk.policy.policyAddress.city }}, {{ risk.policy.policyAddress.state }}
          {{ risk.policy.policyAddress.zipCode }}
        </div>
      </article>
    </div>
  </div>
  <div class="policy-card__action">
    <ds-jump-links [jumpLinks]="links" size="medium"></ds-jump-links>
  </div>
</div>
