import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AddressPipe,
  CoverageArray,
  FilterPolicy,
  FormatRentalDeductibles,
  PrettyHomesiteRiskDescription,
  PrettyPolicyNum,
  PrettyPolicyType,
  PrettyRiskDescription, RemoveNoCoverage, RemoveRejected, RemoveRejectedIfSelected, RiskPipe,
  TermPipe,
  VehiclePipe
} from './pipes/policy.pipes';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AddressPipe,
    CoverageArray,
    PrettyHomesiteRiskDescription,
    PrettyPolicyNum,
    PrettyPolicyType,
    PrettyRiskDescription,
    FormatRentalDeductibles,
    RemoveRejected,
    RemoveNoCoverage,
    RiskPipe,
    TermPipe,
    VehiclePipe,
    FilterPolicy,
    RemoveRejectedIfSelected
  ],
  exports: [
    AddressPipe,
    CoverageArray,
    PrettyHomesiteRiskDescription,
    PrettyPolicyNum,
    PrettyPolicyType,
    PrettyRiskDescription,
    RemoveRejected,
    RemoveNoCoverage,
    FormatRentalDeductibles,
    RiskPipe,
    TermPipe,
    VehiclePipe,
    FilterPolicy,
    RemoveRejectedIfSelected
  ]
})
export class PolicyPipesModule { }
