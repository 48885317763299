// Angular Imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { differenceInCalendarDays } from 'date-fns';
import { empty, Observable, of } from 'rxjs';
import { filter, map, mergeMap, skipWhile, take } from 'rxjs/operators';

// lodash
import { get as _get } from 'lodash';

// Date-fns Imports

// My Account Imports
import {
  AutoPolicy,
  GenericProductType,
  PolicyDocument,
  PolicySummary,
  SourceSystemType
} from '@amfam/policy/models';
import { userQuery, UserState } from '@amfam/shared/user';
import { ConfigService } from '@amfam/shared/utility/shared-services/src/lib/config';
import { documentsQuery } from '../+state/documents/documents.selectors';

@Injectable({
  providedIn: 'root'
})
export class PolicyDocumentService {
  private supportedPolicyTypes = [
    GenericProductType.Auto,
    GenericProductType.Home,
    GenericProductType.Umbrella,
    GenericProductType.Life
  ];

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private policyDocumentStore: Store<PolicyDocument>,
    private userStore: Store<UserState>
  ) {}

  public getDocumentList(
    policyId: string,
    policySourceSystem: number,
    policyType: GenericProductType,
    sourceSystemName: string
  ): Observable<PolicyDocument[]> {
    const baseUrl = this.getBaseUrl(policyType);
    if (baseUrl) {
      const url = `${baseUrl}${policyId}/documents`;
      const options = { headers: this.getHeaders() };
      let lastLogin = '';
      this.userStore.select(userQuery.getUserState).subscribe(user => {
        lastLogin = user.lastLoggedInOn;
      });

      return this.http.get(url, options).pipe(
        filter((data: any) => data.status && data.status.code === 200),
        map(data => <Array<any>>_get(data, 'documentList', data.rows)),

        map(documents =>
          documents.map((document: PolicyDocument) =>
            PolicyDocument.fromJson(document, policyId, policySourceSystem, sourceSystemName)
          )
        ),
        map(documents =>
          documents.map((document: PolicyDocument) => {
            if (differenceInCalendarDays(new Date(), document.documentDate) <= 7) {
              document.isNew = true;
            } else {
              document.isNew = false;
            }
            return document;
          })
        )
      );
    } else {
      return empty();
    }
  }

  public getPOILinks<T extends AutoPolicy | PolicySummary>(policy: T): Observable<T> {
    const matchData: any[] =
      policy.sourceSystem === SourceSystemType.Advance
        ? [
            {
              'Content Description': 'PROOF OF INSURANCE CARD'
            },
            {
              'Category Type': 'NEW BUSINESS',
              'Content Description': 'POLICY PACKAGE'
            },
            {
              'Category Type': 'RENEWAL',
              'Content Description': 'POLICY PACKAGE'
            }
          ]
        : [
            {
              'Content Description': 'PROOF OF INSURANCE CARD'
            },
            {
              'Content Category': 'DECLARATION',
              'Category Type': 'COVERAGE SUMMARY LETTER',
              'Content Description': 'AUTO'
            },
            {
              'Content Category': 'POLICY INFORMATION',
              'Category Type': 'COVERAGE SUMMARY LETTER',
              'Content Description': 'AUTO'
            },
            {
              'Content Category': 'DECLARATION',
              'Category Type': 'RENEWAL',
              'Content Description': 'AUTO'
            },
            {
              'Content Category': 'DECLARATION',
              'Category Type': 'NEW BUSINESS',
              'Content Description': 'AUTO'
            },
            {
              'Content Category': 'DECLARATION',
              'Category Type': 'CHANGE',
              'Content Description': 'AUTO'
            },
            {
              'Content Category': 'DECLARATION',
              'Category Type': 'REINSTATEMENT',
              'Content Description': 'AUTO'
            },
            {
              'Content Category': 'DECLARATION',
              'Category Type': 'REWRITE',
              'Content Description': 'AUTO'
            }
          ];

    return this.policyDocumentStore.select(documentsQuery.getPolicyDocumentsWithState).pipe(
      skipWhile(state => state.policyDocumentsLoading),
      map(policyDocumentsState => {
        return (policyDocumentsState as any).policyDocuments.filter(
          policyDocument => policyDocument.policyNumber === policy.policyNumber
        );
      }),
      map(documents =>
        documents
          .sort(PolicyDocument.comparePOI)
          .filter(document =>
            matchData.some(metadata =>
              Object.keys(metadata).every(
                metadataField => document.metadata[metadataField] === metadata[metadataField]
              )
            )
          )
          .filter(document => {
            return document.linksToSelf.length && !!document.linksToSelf[0].href;
          })
          .map(document => document.linksToSelf[0].href)
      ),
      take(1),
      mergeMap(poiLinks => {
        if (poiLinks && poiLinks.length) {
          policy.poiLink = poiLinks[0];
        }
        return of(policy);
      })
    );
  }

  private getBaseUrl(policyType: GenericProductType) {
    const baseUrl = this.config.get('productApi');

    switch (policyType) {
      case GenericProductType.Auto:
        return baseUrl + 'autopolicies/';
      case GenericProductType.Home:
        return baseUrl + 'propertypolicies/';
      case GenericProductType.Umbrella:
        return baseUrl + 'plumbrellapolicies/';
      case GenericProductType.Life:
        return baseUrl + 'lifepolicies/';
    }

    throw new Error(`Unrecognized policy type: ${policyType}`);
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({ authId: 'cdhtp1', userId: 'cdhtp1' });
  }

  public isPolicyTypeSupported(policyType: GenericProductType): boolean {
    return this.supportedPolicyTypes.includes(policyType);
  }
}
