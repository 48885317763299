import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PartnerTermsData } from '../terms.model';

@Component({
  selector: 'ds-admin-terms',
  templateUrl: './admin-terms.component.html',
  styleUrls: ['./admin-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminTermsComponent {
  @Input() partnerData: PartnerTermsData;

  constructor() {}
}
