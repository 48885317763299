import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { get as _get } from 'lodash';

import { ApiStatus, initialApiStatus } from '@amfam/shared/models';

import {
  EDeliveryTermsAndConditionsActions,
  EDeliveryTermsAndConditionsActionTypes
} from './e-delivery-terms-and-conditions.actions';

export interface EdeliveryNotificationsState {
  brandId: string;
  error: ApiStatus | null;
  success: ApiStatus | null;
  loading: boolean;
}

export interface NotificationState extends EntityState<EdeliveryNotificationsState> {
  loading: boolean;
  error: ApiStatus | null;
}

export const adapter: EntityAdapter<EdeliveryNotificationsState> =
  createEntityAdapter<EdeliveryNotificationsState>({
    selectId: (eDeliveryNotificationsState: EdeliveryNotificationsState) =>
      eDeliveryNotificationsState.brandId,
    sortComparer: false
  });

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: EDeliveryTermsAndConditionsActions
): NotificationState {
  switch (action.type) {
    case EDeliveryTermsAndConditionsActionTypes.LoadEDeliveryTermsAndConditions: {
      return { ...state, loading: true };
    }

    case EDeliveryTermsAndConditionsActionTypes.LoadEDeliveryTermsAndConditionssFailure: {
      return adapter.upsertOne(
        {
          brandId: _get(action, 'payload.brandId'),
          success: null,
          error: _get(action, 'payload.status', initialApiStatus),
          loading: false
        },

        { ...state, loading: false }
      );
    }

    case EDeliveryTermsAndConditionsActionTypes.LoadEDeliveryTermsAndConditionssSuccess: {
      return adapter.upsertOne(
        {
          brandId: _get(action, 'payload.brandId'),
          success: _get(action, 'payload.status', initialApiStatus),
          error: null,
          loading: false
        },
        { ...state, loading: false }
      );
    }

    default: {
      return state;
    }
  }
}
