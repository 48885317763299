import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { has as _has } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ds-form-character-counter',
  templateUrl: './ds-form-character-counter.component.html',
  styleUrls: ['./ds-form-character-counter.component.scss']
})
export class DsFormCharacterCounterComponent implements OnInit, OnDestroy {
  private el: any;
  private stop$: Subject<void> = new Subject<void>();
  public usedLength = 0;
  public showCounter: Boolean = false;
  public errorClass = false;

  @Input()
  control: UntypedFormControl;

  @Input()
  maxLength = 0;

  @Input()
  suffix = 'characters';

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    this.el = this.elRef; // This element

    if (_has(this.el, 'nativeElement') && this.el.nativeElement.parentElement) {
      const parentEl = this.el.nativeElement.parentElement; // Get back up to the ds-form-controller to query for form element children

      // Look for form elements
      let controlElement = parentEl.querySelector('input');
      if (!controlElement) {
        controlElement = parentEl.querySelector('textarea');
      }

      // Use maxlength attribute from the form element
      if (this.maxLength === 0 && controlElement && controlElement.getAttribute('maxlength')) {
        this.maxLength = controlElement.getAttribute('maxlength');
      } else if (this.maxLength === 0) {
        console.error(
          'form-character-counter requires a form element with a maxlength attribute or a [maxLength] input value'
        );
      }
    }

    if (this.control && this.maxLength > 0) {
      this.showCounter = true; // Show counter if there's a maxlength attribute and we have a controller
      this.control.valueChanges
        .pipe(takeUntil(this.stop$), distinctUntilChanged())
        .subscribe(formValues => {
          if (formValues && formValues.length) {
            this.usedLength = formValues.length; // If there's value, return the length

            if (this.usedLength > this.maxLength) {
              this.errorClass = true;
            } else {
              this.errorClass = false;
            }
          } else {
            this.usedLength = 0; // If not, reset the var to 0. Needed to reset on clearing the field / no length.
            this.errorClass = false;
          }
        });

      if (this.control && this.control.value) {
        // Update usedLength when text-area is prefilled.
        this.usedLength = this.control.value.length;
      }
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
