import { LogoModel } from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import {
  featureFlagQuery,
  FeatureFlagService
} from '@amfam/shared/utility/feature-flag/data-access';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromRoot from '../../core/store';

@Component({
  selector: 'ds-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  public showBanner: Observable<boolean>;
  chatEnabled$: Observable<boolean>;
  isPartner$: Observable<boolean>;
  partnerFooterLogo$: Observable<LogoModel>;
  lastLogin$: Observable<string>;
  isForgeRockEnabled$ = this.store.select(featureFlagQuery.selectFeatureFlag).pipe(
    filter(loaded => !!loaded),
    map(() => this.featureFlagService.isEnabled('forgerock'))
  );

  constructor(
    private store: Store,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.showBanner = this.store.select(fromRoot.getShowBanner);
    this.chatEnabled$ = this.store.select(fromRoot.chatEnabled);
    this.partnerFooterLogo$ = this.store.select(BrandSelectors.selectFooterLogo);
    this.lastLogin$ = this.store.select(userQuery.getLastLoggedInOn);
  }
}
