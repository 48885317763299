import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FeatureFlagsState } from './feature-flag.reducer';

const selectFeatureFlagState = createFeatureSelector<FeatureFlagsState>('featureFlag');

export const selectFeatureFlagEntitiesState = createSelector(
  selectFeatureFlagState,
  state => state?.entities
);

export const selectHasFeatureFlag = createSelector(
  selectFeatureFlagState,
  state => !!state && state.ids?.length > 0
);

export const selectFeatureFlag = feature =>
  createSelector(selectFeatureFlagEntitiesState, entities => entities && entities[feature]);

export const featureFlagQuery = {
  selectHasFeatureFlag,
  selectFeatureFlagState,
  selectFeatureFlagEntitiesState,
  selectFeatureFlag
};
