import { ErrorHandler, NgModule } from '@angular/core';

// application modules
import { SharedModule } from '../shared/shared.module';
// providers
import { ApplicationErrorHandler } from './error-handler/error-handler';
// components/directives/pipes
import { ErrorPageComponent } from './error-page/error-page.component';

@NgModule({
  imports: [SharedModule],
  declarations: [ErrorPageComponent],
  exports: [ErrorPageComponent]
})
export class ErrorModule {}

export const ErrorProviders = [
  {
    provide: ErrorHandler,
    useClass: ApplicationErrorHandler
  }
];
