import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DsRouterLinkModule } from '@amfam/shared/ui/ds-router-link';
import { ImpersonationModule } from '@amfam/shared/utility/impersonation';

import { DsHeaderMobileNavigationItemComponent } from './components/ds-header-mobile-navigation/ds-header-mobile-navigation-item/ds-header-mobile-navigation-item.component';
import { DsHeaderMobileNavigationComponent } from './components/ds-header-mobile-navigation/ds-header-mobile-navigation.component';
import { DsHeaderMobileNotificationComponent } from './components/ds-header-mobile-navigation/ds-header-mobile-notification/ds-header-mobile-notification.component';
import { DsHeaderNavigationItemComponent } from './components/ds-header-navigation/ds-header-navigation-item/ds-header-navigation-item.component';
import { DsHeaderNavigationComponent } from './components/ds-header-navigation/ds-header-navigation.component';
import { DsHeaderNotificationComponent } from './components/ds-header-navigation/ds-header-notification/ds-header-notification.component';
import { DsHeaderComponent } from './components/ds-header/ds-header.component';

@NgModule({
  imports: [CommonModule, DsRouterLinkModule, ImpersonationModule],
  declarations: [
    DsHeaderComponent,
    DsHeaderNavigationComponent,
    DsHeaderNavigationItemComponent,
    DsHeaderNotificationComponent,
    DsHeaderMobileNavigationComponent,
    DsHeaderMobileNavigationItemComponent,
    DsHeaderMobileNotificationComponent
  ],
  exports: [DsHeaderComponent]
})
export class SharedHeaderModule {}
