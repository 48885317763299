<div>
  <p class="pad-top-20" data-cy="paymentMethodText">Add a new payment method</p>
  <div class="row">
    <a (click)="addCheckingAccount()" [ngClass]="{ disabled: !authorizedToAddPaymentMethod }" data-cy="checkingAccountLink"
      >Checking Account</a
    >
  </div>
  <p *ngIf="displayAutoPayDiscountMessage" class="caption margin-bottom-10">AutoPay Discount</p>
  <div class="row">
    <a (click)="addSavingsAccount()" [ngClass]="{ disabled: !authorizedToAddPaymentMethod }" data-cy="savingsAccountLink"
      >Savings Account</a
    >
  </div>
  <p *ngIf="displayAutoPayDiscountMessage" class="caption margin-bottom-10">AutoPay Discount</p>
  <div class="row">
    <a (click)="addCreditDebit()" [ngClass]="{ disabled: !authorizedToAddPaymentMethod }" data-cy="debitCreditCardLink"
      >Debit/Credit Card</a
    >
  </div>
</div>
