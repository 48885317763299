<section id="edit-password-component" class="item">
  <div class="row">
    <div class="column twelve">
      @if (!showForm) {
        <div
          class="pad-left-40 pad-top-10 float-left"
          content
          [contentPath]="'profile.editPasswordTitle'"
        ></div>
      }
      @if (!showForm) {
        <button
          ds-button
          id="openEditPasswordForm"
          data-cy="openEditPasswordFormButton"
          class="-outline-white float-right margin-left-10"
          (click)="openEditPasswordForm()"
          content
          [contentPath]="'shared.changeBtn'"
          authCheck
          [permissionName]="'editpassword_view'"
          [checkAction]="'disable'"
        ></button>
      }
    </div>
  </div>
  @if (showForm) {
    <div class="row pad-left-40">
      <div class="column relative twelve">
        <h3 content [contentPath]="'profile.editPasswordTitle'"></h3>
        <p class="margin-bottom-20" content [contentPath]="'profile.editPasswordText'"></p>
        @if (username) {
          <ds-accept-password-input
            [confirmBtnText]="confirmBtnText"
            [componentUsernameInput]="username"
            (finish)="onComplete($event)"
          ></ds-accept-password-input>
        }
        <button
          ds-button
          class="-white bg-transparent cancel a float-right margin-right-5"
          (click)="openEditPasswordForm()"
          content
          [contentPath]="'shared.cancel'"
        ></button>
      </div>
    </div>
  }
</section>
