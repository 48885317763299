import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { get as _get } from 'lodash';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { LogoModel } from '@amfam/shared/models';
import { BrandLinkDataModel } from '@amfam/shared/utility/brand';

import { NavLinkTreeObj, NotificationObj } from '../../models';
import { HeaderWrapperService } from '../../services/header-wrapper.service';

@Component({
  selector: 'ds-header',
  templateUrl: './ds-header.component.html',
  styleUrls: ['./ds-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DsHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  // TODO: Fix inputs upstream to use async as soon as possible and remove Observables here
  @Input() headerLogo: LogoModel;
  @Input() brandInfo$: Observable<BrandLinkDataModel>;
  @Input() homeUrl: string;
  @Input() chatEnabled$: Observable<boolean>;
  @Input() loggedIn$: Observable<boolean>;
  @Input() isPartner$: Observable<boolean>;
  @Input() loaded$: Observable<boolean> = this.headerWrapperService.loaded$;
  @Input() skipContentFocus = false;
  @Input() notifications$: Observable<NotificationObj[] | false>;
  @Input() linkArray$: Observable<NavLinkTreeObj[]>;

  @Output() linkClickEvent = new EventEmitter();
  @Output() sublinkClickEvent = new EventEmitter();
  @Output() notificationDismissClickEvent = new EventEmitter();

  headerNavRightPostion: number;
  headerNavLeftPostion: number;
  headerNavWidth: number;
  experienceId: string;
  // TODO: jjc - get this '960' from the design token breakpoints
  mobileMenuBreakpoint = 960;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private elemRef: ElementRef,
    private headerWrapperService: HeaderWrapperService,
    private renderer: Renderer2,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // If mobile menu is open, lock scroll on the body element
    this.headerWrapperService.mobileMenuOpen$
      .pipe(takeUntil(this.stop$))
      .subscribe(mobileMenuOpen =>
        mobileMenuOpen
          ? this.renderer.setStyle(document.body, 'overflow', 'hidden')
          : this.renderer.removeStyle(document.body, 'overflow')
      );

    this.brandInfo$.subscribe(brandInfo => {
      if (_get(brandInfo, 'isPartner')) {
        this.experienceId = _get(brandInfo, 'brandState.experienceId', '');
      }
      this.ref.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.isMobileMenuActive();
    const resizeEvent = fromEvent(window, 'resize').pipe(debounceTime(200));
    resizeEvent.pipe(takeUntil(this.stop$)).subscribe(this.handleResize.bind(this));
    this.headerWrapperService.setHeaderNavRect(this.elemRef.nativeElement.getBoundingClientRect());
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  handleResize() {
    this.isMobileMenuActive();
    this.headerWrapperService.setHeaderNavRect(this.elemRef.nativeElement.getBoundingClientRect());
  }

  isMobileMenuActive() {
    this.headerWrapperService.setMobileMenuActive(
      document.documentElement.clientWidth < this.mobileMenuBreakpoint
    );
  }

  linkClicked(event) {
    if (event) {
      this.linkClickEvent.emit(event);
    }
  }

  sublinkClicked(event) {
    if (event) {
      this.sublinkClickEvent.emit(event);
    }
  }
}
