import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  GetMakesPayload,
  GetModelsPayload,
  GetStylesPayload,
  GetVehicleModelsApiResponse,
  GetVehicleSeriesApiResponse,
  LookupVinYearPayload
} from '../models/vehicle.models';
import { VehicleService } from '../services/vehicle.service';
import {
  fromVehicleActions,
  GetMakes,
  GetModels,
  GetStyles,
  LookupVinYear
} from './vehicle.actions';

@Injectable()
export class VehicleEffects {
  getByVinAndYear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LookupVinYear),
      map(action => action.payload),
      switchMap((payload: LookupVinYearPayload) =>
        this.vehicleService.getVehicleByVinAndYear(payload).pipe(
          map(response => {
            return fromVehicleActions.LookupVinYearSuccess(response);
          }),
          catchError(error => of(fromVehicleActions.LookupVinYearFail(error)))
        )
      )
    );
  });

  getMakes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetMakes),
      map(action => action.payload),
      switchMap((payload: GetMakesPayload) =>
        this.vehicleService.getVehicleMakes(payload).pipe(
          map(response => {
            return fromVehicleActions.GetMakesSuccess(response);
          }),
          catchError(error => of(fromVehicleActions.GetMakesFail(error)))
        )
      )
    );
  });

  getModels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetModels),
      map(action => action.payload),
      switchMap((payload: GetModelsPayload) =>
        this.vehicleService.getVehicleModels(payload).pipe(
          map((response: GetVehicleModelsApiResponse) => {
            return fromVehicleActions.GetModelsSuccess(response);
          }),
          catchError(error => of(fromVehicleActions.GetModelsFail(error)))
        )
      )
    );
  });

  getStyles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetStyles),
      map(action => action.payload),
      switchMap((payload: GetStylesPayload) =>
        this.vehicleService.getVehicleStyles(payload).pipe(
          map((response: GetVehicleSeriesApiResponse) => {
            return fromVehicleActions.GetStylesSuccess(response);
          }),
          catchError(error => of(fromVehicleActions.GetStylesFail(error)))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private vehicleService: VehicleService
  ) {}
}
