import { createSelector } from '@ngrx/store';

import { DigitalAccountFeatureState, getDigitalAccountFeatureState } from '..';
import { SecurityQuestionsState } from './security-questions.reducer';

export const getSecurityQuestionsState = createSelector(
  getDigitalAccountFeatureState,
  (state: DigitalAccountFeatureState) => state.securityQuestions
);

export const getLoading = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.loading
);

export const getLoaded = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.loaded
);

export const hasError = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.hasError
);

export const getAllSecurityQuestions = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.allSecurityQuestions
);

export const getUserSecurityQuestions = createSelector(
  getSecurityQuestionsState,
  (state: SecurityQuestionsState) => state.userSecurityQuestions
);

export const securityQuestionsQuery = {
  getSecurityQuestionsState,
  getLoading,
  getLoaded,
  hasError,
  getAllSecurityQuestions,
  getUserSecurityQuestions
};
