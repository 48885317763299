<ds-modal
    [id]="id"
    [closeOthers]="true"
    [maxWidth]="750"
    >
    <div class="romeBannerModal">
      <div class="heading">
        {{heading}}
      </div>

      @for(questionAndAnswer of questionsAndAnswers; track questionAndAnswer){
        <div class="question">
            {{questionAndAnswer.question}}
          </div>
          <div class="margin-bottom-10">
            <div>
                {{questionAndAnswer.answer}}
            </div>
          </div>
      }

      <div class="flex-container alert">
        <div class="column left">
          <div class="column-content">
            <span class="icon-innovative"></span>
          </div>
        </div>
        <div class="column right">
          <div class="column-content">
            @for(alert of alerts; track alert){
                <div>
                    {{alert}}
                </div>
            }
          </div>
        </div>
      </div>
    </div>
</ds-modal>