import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { LogoModel } from '@amfam/shared/models';
import { BrandLinkDataModel } from '@amfam/shared/utility/brand';
import { ConfigService, CopyService } from '@amfam/shared/utility/shared-services';

import { HeaderNavRect, NavLinkTreeObj, NotificationObj } from '../../models';
import { HeaderWrapperService } from '../../services/header-wrapper.service';
import { DsHeaderNavigationItemComponent } from './ds-header-navigation-item/ds-header-navigation-item.component';

@Component({
  selector: 'ds-header-navigation',
  templateUrl: './ds-header-navigation.component.html',
  styleUrls: ['./ds-header-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DsHeaderNavigationComponent implements OnInit, OnDestroy {
  // TODO: Fix inputs upstream to use async as soon as possible and remove Observables here
  @Input() loaded$: Observable<boolean>;
  @Input() headerLogo: LogoModel;
  @Input() brandInfo$: Observable<BrandLinkDataModel>;
  @Input() loggedIn$: Observable<boolean>;
  @Input() isPartner$: Observable<boolean>;
  @Input() chatEnabled$: Observable<boolean>;
  @Input() homeUrl: '';
  @Input() notifications$: Observable<NotificationObj[] | false>;
  @Input() linkArray$: Observable<NavLinkTreeObj[]>;

  @Output() linkClickEvent = new EventEmitter();
  @Output() sublinkClickEvent = new EventEmitter();

  @ViewChildren('navItem') navItems: QueryList<DsHeaderNavigationItemComponent>;

  headerNavRect$: Observable<HeaderNavRect>;
  amfamChatUrl: string = this.config.get('links.amfamChatUrl');
  amfamHeaderFooterPhoneNumber = this.copy.getCopy('shared.amfamHeaderFooterPhoneNumber');
  contactUsURL = this.config.get('links.amfamDotComUrl') + 'contact?UTM_Source=MyAccount';
  // Shared subject for completing observables
  protected stop$ = new Subject<void>();

  constructor(
    private headerWrapperService: HeaderWrapperService,
    private config: ConfigService,
    private copy: CopyService
  ) {}

  ngOnInit() {
    this.headerNavRect$ = this.headerWrapperService.headerNavRect$;
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  hideAllSubMenus(navItem: DsHeaderNavigationItemComponent) {
    if (this.navItems) {
      this.navItems.forEach(navItemInstance => {
        if (navItemInstance !== navItem) {
          if (navItemInstance.showSub !== 'hide') {
            navItemInstance.showSub = 'hide';
          }
          if (navItemInstance.submenuExpanded !== 'false') {
            navItemInstance.submenuExpanded = 'false';
          }
        }
      });
    }
  }

  linkClicked(event) {
    if (event) {
      this.linkClickEvent.emit(event);
    }
  }

  // Tell Angular to track these items by ID so that they are not recreated in the DOM
  trackByFn(index) {
    return index;
  }

  sublinkClicked(event) {
    if (event) {
      this.sublinkClickEvent.emit(event);
    }
  }
}
