<ul class="progress-bar">
  @for (step of steps; track step; let i = $index) {
    <li [ngClass]="{ 'complete icon': step.complete, active: step.active }">
      <span>
        <span class="circle">{{ i + 1 }}</span>
        @if (!step.active) {
          <span class="none md-inline-block pad-left-10">{{ step.title }}</span>
        }
        @if (step.active) {
          <span class="md-inline-block pad-left-10">{{ step.title }}</span>
        }
      </span>
    </li>
  }
</ul>
