import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from as observableFrom, of as observableOf, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, toArray } from 'rxjs/operators';

import { PolicyDocument, PolicySummary } from '@amfam/policy/models';
import { UtilService } from '@amfam/shared/utility/shared-services';

import { PolicyDocumentService } from '../../services/policy-documents.service';
import { PolicyService } from '../../services/policy.service';
import { SummariesAction, SummariesActionTypes } from '../summaries/summaries.actions';
import {
  fromDocumentsActions,
  LoadDocuments,
  LoadDocumentsError,
  LoadDocumentsSuccess
} from './documents.actions';

@Injectable()
export class DocumentsEffects {
  // TODO: AS - This pattern with datapersistence is not working and i do not have time currently to fix it
  // @Effect() loadPolicyDocuments$ = this.dataPersistence.fetch(DocumentsActionTypes.LoadDocuments, {
  //   run: (action: LoadDocuments, state: PolicyDocumentsPartialState) => {
  //     return from(action.payload).pipe(
  //       filter(policySummary =>
  //         this.policyDocumentService.isPolicyTypeSupported(policySummary.generalizedProductType)
  //       ),
  //       mergeMap(policySummary => {
  //         return this.policyDocumentService.getDocumentList(
  //           policySummary.policyNumber,
  //           policySummary.sourceSystem,
  //           policySummary.generalizedProductType
  //         );
  //       }),
  //       mergeMap(policyDocuments => policyDocuments),
  //       toArray(),
  //       map(
  //         (policyDocumentList: PolicyDocument[]) =>
  //           new fromDocumentsActions.LoadDocumentsSuccess(policyDocumentList)
  //       )
  //     );
  //   },

  //   onError: (action: LoadDocuments, error) => {
  //     return new fromDocumentsActions.LoadDocumentsError(error);
  //   }
  // });

  // constructor(
  //   private actions$: Actions,
  //   private dataPersistence: DataPersistence<PolicyDocumentsPartialState>,
  //   private policyDocumentService: PolicyDocumentService
  // ) {}

  policySummariesLoaded$ = createEffect(() => {
    return this.action$.pipe(
      ofType(SummariesActionTypes.SummariesLoadSuccessType),
      map((action: SummariesAction) => action.payload),
      map(payload => fromDocumentsActions.LoadDocuments(payload))
    );
  });

  loadPolicyDocuments$ = createEffect(() => {
    return this.action$.pipe(
      ofType(LoadDocuments),
      map(action => action.payload),
      switchMap((payload: PolicySummary[]) =>
        observableFrom(
          payload.filter(policySummary =>
            this.policyservice.isPolicyTypeSupported(policySummary.generalizedProductType)
          )
        ).pipe(
          mergeMap(policySummary => {
            return this.policyDocumentService
              .getDocumentList(
                policySummary.policyNumber,
                policySummary.sourceSystem,
                policySummary.generalizedProductType,
                policySummary.sourcePath
              )
              .pipe(catchError(() => of([])));
          }),
          mergeMap(p => p),
          toArray(),
          map(policyDocuments =>
            (policyDocuments as PolicyDocument[]).map(doc => ({
              ...doc,
              policyNumber: this.utilService.onlyAlphanumeric(doc.policyNumber)
            }))
          ),
          catchError(() => of([]))
        )
      ),
      map(policyDocuments => fromDocumentsActions.LoadDocumentsSuccess(policyDocuments)),
      catchError(error => observableOf(fromDocumentsActions.LoadDocumentsError(error)))
    );
  });

  policyDocumentsLoaded$ = createEffect(() => {
    return this.action$.pipe(
      ofType(LoadDocumentsSuccess, LoadDocumentsError),
      map(action => action.payload),
      map(() => fromDocumentsActions.LoadDocumentsComplete())
    );
  });

  constructor(
    private policyDocumentService: PolicyDocumentService,
    private policyservice: PolicyService,
    private action$: Actions,
    private utilService: UtilService
  ) {}
}
