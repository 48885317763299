import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { flatMap as _flatMap, get as _get } from 'lodash';

import { PolicyStatus } from '@amfam/policy/models';

import { POLICIES_FEATURE_KEY } from '../policies/policies.reducer';
import { PoliciesState } from '../policies/policies.selectors';
import * as fromSummariesNotifications from './notifications.reducer';
import * as fromSummariesState from './summaries.reducer';

export interface SummariesState {
  state: fromSummariesState.PolicySummariesEntityState;
  notifications: fromSummariesNotifications.NotificationState;
}

export const summaryReducers: ActionReducerMap<SummariesState> = {
  state: fromSummariesState.reducer,
  notifications: fromSummariesNotifications.reducer
};

// Lookup the 'Summaries' feature state managed by NgRx
export const selectPolicySummariesState =
  createFeatureSelector<PoliciesState>(POLICIES_FEATURE_KEY);

export const selectLoaded = createSelector(
  selectPolicySummariesState,
  (state: PoliciesState) =>
    !_get(state, 'summariesNotification.loading') &&
    _get(state, 'summariesNotification.loading') !== null
);

export const selectPolicySummariesHasError = createSelector(
  selectPolicySummariesState,
  (state: PoliciesState) => !!_get(state, 'summariesNotification.error')
);

export const selectAllPolicySummaries = createSelector(
  selectPolicySummariesState,
  selectLoaded,
  (summariesState: PoliciesState, isLoaded) =>
    isLoaded ? fromSummariesState.selectAll(summariesState.summariesState) : []
);

export const selectPolicySummaryLoading = createSelector(
  selectPolicySummariesState,
  (state: PoliciesState) => !!_get(state, 'summariesNotification.loading')
);

export const selectPolicySummaryEntities = createSelector(
  selectPolicySummariesState,
  (state: PoliciesState) => _get(state, 'summariesState.entities')
);

export const selectPolicySummaryIds = createSelector(
  selectPolicySummariesState,
  (state: PoliciesState) => _get(state, 'summariesState.ids')
);

export const selectPolicySummaries = createSelector(selectAllPolicySummaries, policies =>
  policies.filter(
    policy =>
      policy.policyStatus !== PolicyStatus.Cancelled && policy.policyStatus !== PolicyStatus.Other
  )
);

export const selectPolicyList = createSelector(selectPolicySummaries, policies =>
  _flatMap(policies, policy => policy.policyNumber)
);

export const selectNonOperatorPolicyList = createSelector(selectPolicySummaries, policies =>
  policies.filter(policy => !policy.operator).map(policy => policy.policyNumber)
);

export const selectOperatorPolicies = createSelector(selectPolicySummaries, policies =>
  policies.filter(policy => policy.operator)
);

export const selectHomesitePolicies = createSelector(selectAllPolicySummaries, policies =>
  policies.filter(policy => policy.sourcePath === 'HOMESITE')
);

export const selectHasHomesitePolicy = createSelector(
  selectHomesitePolicies,
  policies => policies && policies.length && policies.length > 0
);

export const selectProducerId = createSelector(selectPolicySummaries, (summaries = []) =>
  summaries && summaries.length > 0 ? summaries[0].servicingProducerId : '10007'
);

export const selectNonOperatorPolicySummaries = createSelector(selectPolicySummaries, policies =>
  policies.filter(policy => !policy.operator)
);

export const summariesQuery = {
  selectLoaded,
  selectHasHomesitePolicy,
  selectHomesitePolicies,
  selectPolicySummaries,
  selectPolicySummaryIds,
  selectPolicySummaryEntities,
  selectPolicySummaryLoading,
  selectAllPolicySummaries,
  selectPolicySummariesHasError,
  selectPolicySummariesState,
  selectProducerId,
  selectPolicyList,
  selectNonOperatorPolicyList,
  selectNonOperatorPolicySummaries,
  selectOperatorPolicies
};
