import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BillingDashBoardBillAccount } from '@amfam/shared/models';

@Component({
  selector: 'ds-billing-tile',
  templateUrl: './billing-tile.component.html',
  styleUrls: ['./billing-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingTileComponent {
  @Input() account: BillingDashBoardBillAccount;

  constructor() {}
}
