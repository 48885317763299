import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PaymentTermsAndConditionsPartnerContent } from '@amfam/billing/shared/ui';
import {
  LetterToDigitPhoneNumberPipe,
  PrettyLetterAndDigitPhoneNumberPipe
} from '@amfam/shared/ui/pipes';

import { BrandModel as BrandState } from '../models/brand.model';
import * as fromBrand from './brand.reducer';
import { BRAND_FEATURE_KEY } from './brand.reducer';

/**
 * Brand Selectors
 */
export const selectBrandState = createFeatureSelector<BrandState>(BRAND_FEATURE_KEY);
export const selectPaymentTermsAndConditionsPartnerContent = createSelector(
  selectBrandState,
  ({ data }: BrandState): PaymentTermsAndConditionsPartnerContent => ({
    companyName: data.company,
    phoneNumber: data.cusCareNumber,
    landingPage: data.landingPageUrl,
    billingEmail: data.billingContactEmailFaq
  })
);

export const selectBrandId = createSelector(selectBrandState, state => state.brandId);
export const selectExperienceId = createSelector(selectBrandState, fromBrand.experienceId);
export const selectPartnerId = createSelector(selectBrandState, fromBrand.partnerId);
export const selectAccountId = createSelector(selectBrandState, fromBrand.accountId);
export const selectAssetPath = createSelector(selectBrandState, fromBrand.assetPath);
export const selectClaimPhoneNum = createSelector(selectBrandState, fromBrand.claimPhoneNum);
export const selectPrettyClaimPhoneNum = createSelector(selectClaimPhoneNum, number => {
  const formattedNumber = new PrettyLetterAndDigitPhoneNumberPipe().transform(number);
  return formattedNumber;
});
export const selectClaimPhoneNumFullHref = createSelector(selectClaimPhoneNum, number => {
  // If we have a number add prefix, else return empty string
  const formattedNumber = new LetterToDigitPhoneNumberPipe().transform(number);
  return formattedNumber ? 'tel:+' + formattedNumber.replace(/\D/g, '') : '';
});

export const selectClaimPhoneNumAuto = createSelector(
  selectBrandState,
  fromBrand.claimPhoneNumAuto
);
export const selectClaimPhoneNumProperty = createSelector(
  selectBrandState,
  fromBrand.claimPhoneNumProperty
);
export const selectErsClaimPhoneNum = createSelector(selectBrandState, fromBrand.claimErsPhoneNum);
export const selectGlassClaimPhoneNum = createSelector(
  selectBrandState,
  fromBrand.claimGlassPhoneNum
);

export const selectCompany = createSelector(selectBrandState, fromBrand.company);
export const selectCusCareNumber = createSelector(selectBrandState, fromBrand.cusCareNumber);
export const selectPrettyCusCareNumber = createSelector(selectCusCareNumber, number => {
  const formattedNumber = new PrettyLetterAndDigitPhoneNumberPipe().transform(number);
  return formattedNumber;
});
export const selectCusCareNumberFullHref = createSelector(selectCusCareNumber, number => {
  // If we have a number add prefix, else return empty string
  const formattedNumber = new LetterToDigitPhoneNumberPipe().transform(number);
  return formattedNumber ? 'tel:+' + formattedNumber.replace(/\D/g, '') : '';
});
export const selectCusCareEmail = createSelector(selectBrandState, fromBrand.cusCareEmail);
export const selectCusCareEmailHref = createSelector(selectCusCareEmail, email =>
  // If we have a number add prefix, else return empty string
  email ? 'mailto:' + email : ''
);
export const selectCusCare247 = createSelector(selectBrandState, fromBrand.cusCare247);
export const selectCusCareHoursWeekday = createSelector(
  selectBrandState,
  fromBrand.cusCareHoursWeekday
);
export const selectCusCareHoursFriday = createSelector(
  selectBrandState,
  fromBrand.cusCareHoursFriday
);
export const selectCusCareHoursWeekend = createSelector(
  selectBrandState,
  fromBrand.cusCareHoursWeekend
);
export const selectCustomerCareTimezone = createSelector(
  selectBrandState,
  fromBrand.customerCareTimezone
);
export const selectCustomerCareWeekdayEnd = createSelector(
  selectBrandState,
  fromBrand.customerCareWeekdayEnd
);
export const selectCustomerCareWeekdayStart = createSelector(
  selectBrandState,
  fromBrand.customerCareWeekdayStart
);
export const selectExperienceClass = createSelector(selectBrandState, fromBrand.experienceClass);
export const selectPartnerNameInUrl = createSelector(selectBrandState, fromBrand.partnerNameInUrl);
export const selectBillingContactEmailFaq = createSelector(
  selectBrandState,
  fromBrand.billingContactEmailFaq
);
export const selectMyaccountSubdomain = createSelector(
  selectBrandState,
  fromBrand.myaccountSubdomain
);
export const selectLandingPageDomain = createSelector(
  selectBrandState,
  fromBrand.landingPageDomain
);
export const selectLandingPagePath = createSelector(selectBrandState, fromBrand.landingPagePath);
export const selectLandingPageUrl = createSelector(selectBrandState, fromBrand.landingPageUrl);
export const selectLandingPage = createSelector(selectBrandState, fromBrand.landingPage);
export const selectPropertyServiceUrl = createSelector(
  selectBrandState,
  fromBrand.propertyServiceUrl
);
export const selectPropertyServiceSSO = createSelector(
  selectBrandState,
  fromBrand.propertyServiceSSO
);
export const selectIsPartner = createSelector(selectBrandState, fromBrand.isPartner);
export const selectIsGenericPartner = createSelector(selectBrandState, fromBrand.isGenericPartner);
export const selectHeaderLogo = createSelector(selectBrandState, fromBrand.brandHeaderLogo);
export const selectFooterLogo = createSelector(selectBrandState, fromBrand.brandFooterLogo);
export const selectBrandLoading = createSelector(selectBrandState, fromBrand.loading);
export const selectBrandHasError = createSelector(selectBrandState, fromBrand.hasError);
export const selectUserBrandLoaded = createSelector(selectBrandState, fromBrand.userBrandLoaded);
export const selectBrandLoaded = createSelector(
  selectBrandState,
  selectExperienceId,
  (brand, userExperienceId) => {
    if (userExperienceId === '') {
      return brand.loaded;
    }
    return brand.userBrandLoaded;
  }
);
export const selectPartnerTermsData = createSelector(
  selectLandingPage,
  selectLandingPageUrl,
  selectPrettyCusCareNumber,
  selectIsPartner,
  (landingPage, landingPageUrl, cusCareNumber, isPartner) => ({
    landingPage,
    landingPageUrl,
    cusCareNumber,
    isPartner
  })
);
