import { isAfter, isBefore, isSameDay } from 'date-fns';
import { map, Observable } from 'rxjs';

import { PolicySummary } from '@amfam/policy/models';

export function removeDuplicatePolicies() {
  return (policies$: Observable<PolicySummary[]>) =>
    policies$.pipe(
      map(policies => {
        const uniquePolicies = new Map();
        policies.forEach(policy => {
          const policyNumber = policy.policyNumber;
          const dupePolicy = getDuplicatePolicy(policyNumber, policies);
          if (!uniquePolicies.has(policyNumber)) {
            if (dupePolicy) {
              const validPolicy = getValidPolicy(policy, dupePolicy);
              if (validPolicy) uniquePolicies.set(policyNumber, validPolicy);
            } else {
              uniquePolicies.set(policyNumber, policy);
            }
          }
        });
        return Array.from(uniquePolicies.values());
      })
    );
}

function getValidPolicy(policy: PolicySummary, dupePolicy: PolicySummary) {
  return [policy, dupePolicy].find(p => {
    const dupePolicyInForceOn = new Date(p.effectiveDate);
    const dupePolicyExpiresOn = new Date(p.endEffectiveDate);
    const currentDate = new Date();

    if (
      (isBefore(dupePolicyInForceOn, currentDate) || isSameDay(dupePolicyInForceOn, currentDate)) &&
      isAfter(dupePolicyExpiresOn, currentDate) &&
      p.status !== 'Other'
    ) {
      return p;
    }
  });
}

function getDuplicatePolicy(policyNumber: string, policies: PolicySummary[]) {
  const policy = policies.find(p => p.policyNumber === policyNumber);
  const duplicatePolicies = policies.filter(p => p.policyNumber === policyNumber);
  if (duplicatePolicies.length > 1)
    return duplicatePolicies.find(
      dp =>
        dp.sourcePath !== policy.sourcePath ||
        !isSameDay(dp.effectiveDate, policy.effectiveDate) ||
        !isSameDay(dp.endEffectiveDate, policy.endEffectiveDate)
    );
}
