import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Action } from '../models/action.model';

@Injectable({
  providedIn: 'root'
})
export class DockingBarService {
  private _dockHeading$: BehaviorSubject<string> = new BehaviorSubject(' ');
  get dockHeading$() {
    return this._dockHeading$.asObservable();
  }

  private _dockSecondaryHeading$: BehaviorSubject<string> = new BehaviorSubject(' ');
  get dockSecondaryHeading$() {
    return this._dockSecondaryHeading$.asObservable();
  }

  private _dockActions$: BehaviorSubject<Action[]> = new BehaviorSubject([]);
  get dockActions$() {
    return this._dockActions$.asObservable();
  }

  private _dockEmpty$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  get dockEmpty$() {
    return this._dockEmpty$.asObservable();
  }

  constructor() {}

  public register(heading: string, action?: Action, secondaryHeading?: string) {
    this.registerHeading(heading);

    if (action) {
      this.registerAction(action);
    }

    if (secondaryHeading) {
      this.registerSecondaryHeading(secondaryHeading);
    }

    this._dockEmpty$.next(false);
  }

  public registerHeading(heading: string) {
    this._dockHeading$.next(heading);

    this._dockEmpty$.next(false);
  }

  public registerSecondaryHeading(secondaryHeading: string) {
    this._dockSecondaryHeading$.next(secondaryHeading);

    this._dockEmpty$.next(false);
  }

  public registerAction(action: Action) {
    const actions = this._dockActions$.getValue().concat(action);
    this._dockActions$.next(actions);

    this._dockEmpty$.next(false);
  }

  public hide() {
    this._dockHeading$.next(null);
    this._dockEmpty$.next(true);
  }

  public clear() {
    this._dockActions$.next([]);
    this._dockHeading$.next(null);
    this._dockSecondaryHeading$.next(null);
    this._dockEmpty$.next(true);
  }

  public clearActions() {
    this._dockActions$.next([]);
  }
}
