import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ClaimsFaqResponse } from '../models/claim-faq.model';
import { ClaimFaqService } from '../services/claim-faq.service';
import { ClaimFaqActionTypes, fromClaimFaqActions } from './claim-faq.actions';

@Injectable()
export class ClaimFaqEffects {
  claimsFaqLoad$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClaimFaqActionTypes.LoadClaimFaq),
      switchMap(() =>
        this.claimsFaqService.getClaimsFaq().pipe(
          map((res: ClaimsFaqResponse) => new fromClaimFaqActions.LoadClaimFaqSuccess(res)),
          catchError(error => of(new fromClaimFaqActions.LoadClaimFaqFail(error)))
        )
      )
    )
  );

  constructor(
    private action$: Actions,
    private claimsFaqService: ClaimFaqService
  ) {}
}
