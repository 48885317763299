import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ds-localized-spinner',
  templateUrl: './ds-localized-spinner.component.html',
  styleUrls: ['./ds-localized-spinner.component.scss']
})
export class DsLocalizedSpinnerComponent implements OnInit {
  @Input() bordered = true;
  @Input() padded = true;
  @Input() dataLoading = false;
  @Input() loadingMessage = '';

  constructor() {}

  ngOnInit() {}
}
