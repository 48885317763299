import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PolicyPipesModule } from '@amfam/policy/pipes';

import { RiskListComponent } from './components/risk-list.component';

@NgModule({
  imports: [CommonModule, PolicyPipesModule, FormsModule, ReactiveFormsModule],
  declarations: [RiskListComponent],
  exports: [RiskListComponent]
})
export class RiskListModule {}
