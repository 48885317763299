import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, forkJoin as observableForkJoin, of as observableOf, throwError } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';

// Store
import { GoPaperLessActions } from '@amfam/policy/go-paperless/data-access';
import { userQuery, UserState } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { ConfigService, UtilService } from '@amfam/shared/utility/shared-services';

import { ContentPaths, ContentService, PartialContentPaths } from '../../../core';
import * as fromRoot from '../../../core/store';
import {
  CustomerEnrollment,
  CustomerEnrollmentStatus,
  Enrollment,
  EnrollmentRequest,
  Program
} from '../../../core/store/mae/models';

@Injectable()
export class EnrollNotificationService {
  private maeBaseUrl: string;
  private currentUser: UserState;
  private brandId: string;

  constructor(
    private configService: ConfigService,
    private contentService: ContentService,
    private http: HttpClient,
    private store: Store<fromRoot.RootState>,
    private utilService: UtilService
  ) {
    this.maeBaseUrl = this.configService.get('maeApi');

    this.store.select(BrandSelectors.selectBrandId).subscribe(brandId => (this.brandId = brandId));

    this.store.select(userQuery.getUserState).subscribe(user => (this.currentUser = user));
  }

  enroll(request: EnrollmentRequest): Observable<CustomerEnrollmentStatus> {
    return this.enrollOrDecline(request, true);
  }

  decline(request: EnrollmentRequest): Observable<CustomerEnrollmentStatus> {
    return this.enrollOrDecline(request, false);
  }

  toEnrollmentStatus(json: any): CustomerEnrollmentStatus {
    if (!json.CustomerEnrollment || !json.CustomerEnrollment.length) {
      throw new Error(json);
    }

    const enrollment = json.CustomerEnrollment[0];
    const status = new CustomerEnrollmentStatus();
    status.autoPayOptInStatus.requested = enrollment.autoPayOptInIndicator;
    status.eDeliveryOptInStatus.requested = enrollment.eDeliveryOptInIndicator;
    status.onineBillingOptInStatus.requested = enrollment.onineBillingOptInIndicator;
    status.ubiDeviceOptInStatus.requested = enrollment.ubiDeviceOptInIndicator;
    status.ubiOptInStatus.requested = enrollment.ubiOptInIndicator;
    status.managedPreferenceChangeStatus.requested = enrollment.managedPreferenceChangeIndicator;

    if (enrollment.status && enrollment.status.messages) {
      const messages = Array.isArray(enrollment.status.messages)
        ? enrollment.status.messages
        : [enrollment.status.messages];

      for (const message of messages) {
        switch (message.level) {
          case 'A':
            status.autoPayOptInStatus.success = message.code === 200;
            status.autoPayOptInStatus.message = message.description;
            break;
          case 'E':
            status.eDeliveryOptInStatus.success = message.code === 200;
            status.eDeliveryOptInStatus.message = message.description;
            break;
          case 'O':
            status.onineBillingOptInStatus.success = message.code === 200;
            status.onineBillingOptInStatus.message = message.description;
            break;
          case 'U':
            status.ubiOptInStatus.success = message.code === 200;
            status.ubiOptInStatus.message = message.description;
            break;
          case 'S':
            status.ubiDeviceOptInStatus.success = message.code === 200;
            status.ubiDeviceOptInStatus.message = message.description;
            break;
          case 'P':
            status.managedPreferenceChangeStatus.success = message.code === 200;
            status.managedPreferenceChangeStatus.message = message.description;
            break;
        }
      }
    }

    return status;
  }

  getMaeEnrollments(data: CustomerEnrollmentStatus): Array<Enrollment> {
    const enrollments = new Array<Enrollment>();
    const autoPay = data.autoPayOptInStatus;
    const eDelivery = data.eDeliveryOptInStatus;
    const onlineBilling = data.onineBillingOptInStatus;
    const ubi = data.ubiOptInStatus;
    const ubiDevice = data.ubiDeviceOptInStatus;
    const billingPreferences = data.managedPreferenceChangeStatus;
    if (autoPay.requested) {
      const automaticPaymentEnrollment = new Enrollment(
        'Automatic Payments',
        autoPay.success,
        autoPay.message
      );
      enrollments.push(automaticPaymentEnrollment);
    }
    if (eDelivery.requested) {
      const eDeliveryEnrollment = new Enrollment(
        'Go Paperless',
        eDelivery.success,
        eDelivery.message
      );
      enrollments.push(eDeliveryEnrollment);
      // Updates Store to indicate that GoPaperless is enrolled successfully
      if (eDelivery.success) {
        this.store.dispatch(GoPaperLessActions.EnrollGoPaperlessSuccess({ isEnrolled: true }));
      }
    }
    if (ubi.requested) {
      const ubiEnrollment = new Enrollment('KnowYourDrive', ubi.success, ubi.message);
      enrollments.push(ubiEnrollment);
    }
    if (ubiDevice.requested) {
      const ubiDeviceEnrollment = new Enrollment(
        'KnowYourDrive Smartphone Program',
        ubiDevice.success,
        ubiDevice.message
      );
      enrollments.push(ubiDeviceEnrollment);
    }
    if (billingPreferences.requested) {
      const billingPreferencesEnrollment = new Enrollment(
        'Billing Preference',
        billingPreferences.requested,
        billingPreferences.message
      );
      enrollments.push(billingPreferencesEnrollment);
    }
    return enrollments;
  }

  public getMaePrograms(customerId: string): Observable<Program[]> {
    const url = this.maeBaseUrl + `customerenrollments/${customerId}`;
    return this.http.get(url).pipe(
      mergeMap((json: any) => {
        const programs = new Array<Program>();
        const subs = new Array<Observable<Program>>();
        if (json.CustomerEnrollment && json.CustomerEnrollment.length) {
          const enrollment: CustomerEnrollment = json.CustomerEnrollment[0];
          if (enrollment.autoPayOptInIndicator) {
            const automaticPaymentProgram = new Program('Automatic Payments', enrollment);
            subs.push(observableOf(automaticPaymentProgram));
          }
          if (enrollment.eDeliveryOptInIndicator) {
            const partialPath: PartialContentPaths = '-edelivery-paperlesspolicytermsandcondition';
            const fullPath = this.brandId + partialPath;
            subs.push(
              this.contentService.getContent(fullPath).pipe(
                withLatestFrom(this.store.select(BrandSelectors.selectExperienceId)),
                map(([content, brandExperienceId]) => {
                  const termsUrl = this.utilService.getAbsoluteUrl(
                    'terms/go-paperless',
                    brandExperienceId
                  );
                  const goPaperlessProgram = new Program(
                    'Go Paperless',
                    enrollment,
                    content.richContent,
                    termsUrl
                  );
                  return goPaperlessProgram;
                })
              )
            );
          }

          if (enrollment.ubiOptInIndicator) {
            const contentPath: ContentPaths = 'TermsandConditions';
            subs.push(
              this.contentService.getContent(contentPath, 'knowyourdrive').pipe(
                withLatestFrom(this.store.select(BrandSelectors.selectExperienceId)),
                map(([content, brandExperienceId]) => {
                  const termsUrl = this.utilService.getAbsoluteUrl(
                    'terms/know-your-drive',
                    brandExperienceId
                  );
                  const kydProgram = new Program(
                    'KnowYourDrive',
                    enrollment,
                    content.richContent,
                    termsUrl
                  );
                  return kydProgram;
                })
              )
            );
          }
          if (enrollment.ubiDeviceOptInIndicator) {
            const contentPath: ContentPaths = 'smartphone-termsandconditions';
            subs.push(
              this.contentService.getContent(contentPath, 'knowyourdrive').pipe(
                withLatestFrom(this.store.select(BrandSelectors.selectExperienceId)),
                map(([content, brandExperienceId]) => {
                  const termsUrl = this.utilService.getAbsoluteUrl(
                    'terms/know-your-drive-smartphone',
                    brandExperienceId
                  );
                  const kydProgram = new Program(
                    'KnowYourDrive Smartphone Program',
                    enrollment,
                    content.richContent,
                    termsUrl
                  );
                  return kydProgram;
                })
              )
            );
          }
          // Billing preferences indicator
          if (enrollment.managedPreferenceChangeIndicator) {
            const contentPath: ContentPaths = 'OnlineTermsandConditions';
            subs.push(
              this.contentService.getContent(contentPath).pipe(
                withLatestFrom(this.store.select(BrandSelectors.selectExperienceId)),
                map(([content, brandExperienceId]) => {
                  const termsUrl = this.utilService.getAbsoluteUrl(
                    'terms/billing',
                    brandExperienceId
                  );
                  const billingPreferenceProgram = new Program(
                    'Billing Preference',
                    enrollment,
                    content.richContent,
                    termsUrl
                  );
                  return billingPreferenceProgram;
                })
              )
            );
          }
        }
        return subs.length > 0 ? observableForkJoin(subs) : throwError({});
      })
    );
  }

  private enrollOrDecline(
    request: EnrollmentRequest,
    agreeToTerms: boolean
  ): Observable<CustomerEnrollmentStatus> {
    const url = this.maeBaseUrl + `customerenrollments/${request.customerId}`;
    const data = {
      CustomerEnrollmentRequest: {
        userApprovalIndicator: agreeToTerms,
        autoPayOptInIndicator: request.enrollment.autoPayOptInIndicator,
        eDeliveryOptInIndicator: request.enrollment.eDeliveryOptInIndicator,
        onineBillingOptInIndicator: request.enrollment.onineBillingOptInIndicator,
        ubiOptInIndicator: request.enrollment.ubiOptInIndicator,
        managedPreferenceChangeIndicator: request.enrollment.managedPreferenceChangeIndicator,
        ubiDeviceOptInIndicator: request.enrollment.ubiDeviceOptInIndicator,
        emailAddressSelected: request.email,
        partyVersion: request.party.partyVersion,
        typeOfPartyCode: request.party.typeOfPartyCode,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        partnerId: this.currentUser.partnerId,
        expId: this.currentUser.experienceId
      }
    };

    return this.http.post(url, data).pipe(map(this.toEnrollmentStatus));
  }
}
