import { Routes } from '@angular/router';
import { get as _get } from 'lodash';
import { of } from 'rxjs';

import { CapitalizePipe } from '@amfam/shared/ui/pipes';

import { OpportunitiesAgentComponent } from './containers/opportunities-agent/opportunities-agent.component';
import { OpportunitiesConfirmationComponent } from './containers/opportunities-confirmation/opportunities-confirmation.component';
import { OpportunitiesDetailComponent } from './containers/opportunities-detail-page/opportunities-detail-page.component';
import { OpportunitiesDetailSelectionPageComponent } from './containers/opportunities-detail-selection-page/opportunities-detail-selection-page.component';
import { OpportunitiesQualifiersComponent } from './containers/opportunities-qualifiers/opportunities-qualifiers.component';

const getOpportunityBreadcrumb = (params: any) => {
  const myAccountText = 'My Opportunity: ';
  switch ((<string>_get(params, 'id', '')).toLocaleLowerCase()) {
    case 'ers':
      return of(`${myAccountText} Help Whenever You Need it`);
    case 'life':
      return of(`${myAccountText} Protect Those You Love`);
    case 'umbrella':
      return of(`${myAccountText} Get The Best Coverage`);
    case 'renters':
      return of(`${myAccountText} BACK ON THE ROAD IN NO TIME`);
    case 'ebci':
      return of(`${myAccountText} Equipment Breakdown Coverage`);
    case 'slc':
      return of(`${myAccountText} Service Line Coverage`);
    case 'accident':
      return of(`${myAccountText} Accident Forgiveness`);
    case 'autodimded':
      return of(`${myAccountText} Auto Diminishing Deductible`);
    case 'propdimded':
      return of(`${myAccountText} Property Diminishing Deductible`);
    case 'idtheft':
      return of(`${myAccountText} Identity Theft`);
    case 'siding':
      return of(`${myAccountText} Matching Siding Protection`);
    case 'sump':
      return of(`${myAccountText} Sump Pump and Water`);
    case 'leaseloan':
      return of(`${myAccountText} Lease & Loan Insurance`);
    case 'hiddenwater':
      return of(`${myAccountText} Hidden Water Damage Insurance`);
    case 'motorcycle':
      return of(`${myAccountText} Motorcycle`);
    case 'boat':
      return of(`${myAccountText} Boat`);
    case 'rvcamper':
      return of(`${myAccountText} RV/Camper`);
    case 'snowmobile':
      return of(`${myAccountText} Snowmobile`);
    case 'atv':
      return of(`${myAccountText} ATV/UTV`);
    case 'auto':
      return of(`${myAccountText} Auto`);
    case 'prop':
      return of(`${myAccountText} Property Insurance`);
    default:
      return of(`${myAccountText}`);
  }
};

const getOpportunityTypeBreadcrumb = params => {
  switch (params['type']) {
    case 'manufacturedhome':
      return of('Manufactured Home');
    default:
      return of(new CapitalizePipe().transform(params['type']));
  }
};

const quoteRoutes: Routes = [
  {
    path: 'eligibility',
    component: OpportunitiesQualifiersComponent,
    data: { breadcrumb: 'eligibility' }
  },
  {
    path: 'agent',
    component: OpportunitiesAgentComponent,
    data: { breadcrumb: 'agent' }
  },
  {
    path: 'error',
    component: OpportunitiesConfirmationComponent,
    data: { breadcrumb: 'error', context: 'agent.error' }
  },
  {
    path: 'success',
    component: OpportunitiesConfirmationComponent,
    data: { breadcrumb: 'success', context: 'agent.success' }
  }
];

export const OpportunityRoutes: Routes = [
  {
    path: 'opportunities/learnmore/:id',
    data: { breadcrumb: getOpportunityBreadcrumb },
    children: [
      {
        path: '',
        pathMatch: 'full',
        data: { breadcrumb: false },
        component: OpportunitiesDetailComponent
      },
      ...quoteRoutes
    ]
  },
  {
    path: 'opportunities/selection/:id',
    data: { breadcrumb: getOpportunityBreadcrumb },
    children: [
      {
        path: '',
        pathMatch: 'full',
        data: { breadcrumb: false },
        component: OpportunitiesDetailSelectionPageComponent
      },
      {
        path: 'eligibility',
        component: OpportunitiesQualifiersComponent,
        data: { breadcrumb: 'eligibility' }
      },
      {
        path: ':type',
        component: OpportunitiesDetailComponent,
        data: { breadcrumb: getOpportunityTypeBreadcrumb }
      }
    ]
  }
];
