import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get, some as _some } from 'lodash';

import {
  BillAccount,
  BillAccountData,
  DeliveryPreferences,
  ONLINE_BILLING,
  PAPER
} from '@amfam/shared/models';

import * as fromBillingState from './billaccount.reducer';
import { BILLING_FEATURE_KEY } from './billaccount.reducer';
import * as fromBillingNotifications from './notifications.reducer';

export interface BillingState {
  billingState: fromBillingState.BillingEntityState;
  billingNotifications: fromBillingNotifications.NotificationState;
}

export const billingReducers: ActionReducerMap<BillingState> = {
  billingState: fromBillingState.reducer,
  billingNotifications: fromBillingNotifications.reducer
};

// Lookup the 'Billing' feature state managed by NgRx
export const selectBillAccountsState = createFeatureSelector<BillingState>(BILLING_FEATURE_KEY);

/**
 * BillAccounts State Selectors
 */

export const selectBillAccountsLoaded = createSelector(
  selectBillAccountsState,
  (state: BillingState) => !_get(state, 'billingNotifications.loading', true)
);

export const selectAllBillAccounts = createSelector(
  selectBillAccountsState,
  selectBillAccountsLoaded,
  (state: BillingState, isLoaded) =>
    isLoaded ? fromBillingState.selectAll(state.billingState) : []
);

export const selectBillAccountEntities = createSelector(
  selectBillAccountsState,
  (state: BillingState) => state.billingState.entities
);

export const selectBillAccountIds = createSelector(
  selectBillAccountsState,
  selectBillAccountsLoaded,
  (state: BillingState, isLoaded) =>
    isLoaded ? <string[]>fromBillingState.selectIds(state.billingState) : []
);

const selectSelectedBillAccountId = createSelector(
  selectBillAccountsState,
  (state: BillingState) => state.billingState.selectedBillAccountNumber
);

const selectSelectedBillAccount = createSelector(
  selectAllBillAccounts,
  selectSelectedBillAccountId,
  (billAccounts = [], id) => {
    const result = billAccounts.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : [];
  }
);

export const selectBillAccounts = createSelector(
  selectBillAccountEntities,
  selectBillAccountIds,
  (entities, ids) => ids.map(id => entities[id])
);

export const selectBillAccount = (billAccountNumber: string) =>
  createSelector(selectBillAccountEntities, entities => entities[billAccountNumber]);

export const selectUserRegisteredBillAccounts = createSelector(selectBillAccounts, accounts =>
  accounts.filter(
    billAcc =>
      _get(billAcc, 'billingMethod') === ONLINE_BILLING &&
      !_get(billAcc, 'registeredElsewhere', false) &&
      !_get(billAcc, 'enabledAFT', false)
  )
);

export const selectAllRegisteredBillAccounts = createSelector(selectBillAccounts, accounts =>
  accounts.filter(billAccs => _get(billAccs, 'billingMethod') === ONLINE_BILLING)
);

export const selectUnregisteredBillAccounts = createSelector(selectBillAccounts, billAccounts =>
  billAccounts.filter(billAcc => _get(billAcc, 'billingMethod') === PAPER)
);

/**
 * Returns billingPreferences object for the bill account.
 */
export const selectBillAccountDataToUpdatePreferences = billAccountNumber =>
  createSelector(selectBillAccounts, billAccounts => {
    const matchingBillAccount = billAccounts.find(
      billAccount => billAccount.billAccountNumber === billAccountNumber
    );
    const billAccountData: BillAccountData = {
      billingPreferences: _get(matchingBillAccount, 'billingPreferences'),
      associated: _get(matchingBillAccount, 'associated'),
      billingMethod: _get(matchingBillAccount, 'billingMethod')
    };
    return billAccountData;
  });

export const selectMatchedBillAccount = createSelector(
  selectBillAccountEntities,
  (billAccounts: { [id: string]: BillAccount }, billAccountNumber: string | number) => [
    billAccounts[billAccountNumber]
  ]
);

/**
 * Returns true if all billaccounts are paperless
 */
export const selectBillAccountsArePaperless = createSelector(selectBillAccounts, billAccounts => {
  const allPaperless = billAccounts.every(billAccount => {
    if (_get(billAccount, 'billingPreferences.preferences')) {
      const accountIsPaperless = !billAccount.billingPreferences.preferences.some(
        pref =>
          pref.preferenceCode.toLowerCase() === 'documents' &&
          pref.preferenceDeliveryCode.toLowerCase() === DeliveryPreferences.PAPER
      );
      return accountIsPaperless;
    }
  });
  return allPaperless;
});

/**
 * BillAccounts Notification Selectors
 */

export const selectBillAccountsLoading = createSelector(
  selectBillAccountsState,
  (state: BillingState) => _get(state, 'billingNotifications.loading', true)
);

export const selectBillAccountsError = createSelector(
  selectBillAccountsState,
  (state: BillingState) => _get(state, 'billingNotifications.error')
);

export const selectBillAccountsHasError = createSelector(
  selectBillAccountsState,
  (state: BillingState) => !!_get(state, 'billingNotifications.error')
);

export const selectAllBillAccountsNotifications = createSelector(
  selectBillAccountsState,
  selectBillAccountsLoaded,
  (state: BillingState, isLoaded) =>
    isLoaded ? fromBillingNotifications.selectAll(state.billingNotifications) : []
);

export const selectBillAccountNotificationsEntities = createSelector(
  selectBillAccountsState,
  (state: BillingState) => state.billingNotifications.entities
);

/**
 * added this selector to get bill account statement history document status if it's loaded or not
 *
 * @param billAccountNumber
 * @returns boolean
 */
export const selectBillAccountDocumentLoaded = (billAccountNumber: string) =>
  createSelector(selectBillAccountNotificationsEntities, entities => {
    if (billAccountNumber) {
      return entities[billAccountNumber]?.documentsLoaded;
    } else {
      return Object.values(entities).every(entity => entity.documentsLoaded);
    }
  });

const selectBillAccountNotificationsIds = createSelector(
  selectBillAccountsState,
  selectBillAccountsLoaded,
  (state: BillingState, isLoaded) =>
    isLoaded ? <string[]>fromBillingNotifications.selectIds(state.billingNotifications) : []
);

export const selectBillAccountsNotifications = createSelector(
  selectBillAccountNotificationsEntities,
  selectBillAccountNotificationsIds,
  (entities, ids) => ids.map(id => entities[id])
);

export const selectMatchedBillAccountNotification = createSelector(
  selectBillAccountNotificationsEntities,
  (
    billAccounts: { [id: string]: fromBillingNotifications.BillingNotificationsState },
    billAccountNumber: string | number
  ) => billAccounts[billAccountNumber]
);

// AS: Get the list of bill accounts which failed during the registration process in the
// current session.
export const selectBillAccountsWithRegistrationFailure = createSelector(
  selectBillAccountsNotifications,
  billAccounts =>
    billAccounts.filter(billAcc => !!_get(billAcc, 'updateRegistrationError') === true)
);

// AS: Get the list of billaccounts which failed during the updation of the preference in the
// current session.
export const selectBillAccountsWithUpdatePreferenceFailure = createSelector(
  selectBillAccountsNotifications,
  billAccounts => billAccounts.filter(billAcc => !!_get(billAcc, 'updatePreferencesError') === true)
);

export const selectBillAccountsAnyLoading = createSelector(
  selectBillAccountsNotifications,
  selectBillAccountsLoading,
  (billAccounts, billAccountsLoading) =>
    billAccountsLoading || _some(billAccounts, { loading: true })
);

export const selectBillAccountsAnyPrefsLoading = createSelector(
  selectBillAccountsNotifications,
  selectBillAccountsLoading,
  (billAccounts, billAccountsLoading) =>
    billAccountsLoading ||
    _some(billAccounts, { prefsFinishedLoading: false }) ||
    _some(billAccounts, { loading: true })
);

export const selectBillAccountsUpdatingPreferences = createSelector(
  selectBillAccountsNotifications,
  billAccounts => _some(billAccounts, { updatingPreferences: true })
);

export const selectBillAccountsUpdatingRegistration = createSelector(
  selectBillAccountsNotifications,
  billAccounts => _some(billAccounts, { updatingRegistration: true })
);

export const selectBillAccountsUpdatingPolicyList = createSelector(
  selectBillAccountsNotifications,
  billAccounts => _some(billAccounts, { riskDetailsLoaded: false })
);

// To hide biling preferences section if there is partial/retrieve preferences error.
export const selectIsEligibleToViewPreferences = createSelector(
  selectBillAccountsNotifications,
  billAccounts => !_some(billAccounts, { loadPrefsError: true })
);

export const selectBillAccountCancelPath = createSelector(
  selectBillAccountsState,
  (state: BillingState) => _get(state, 'billingNotifications.cancelNavigationPath')
);

export const selectAllBillingPreferencesLoaded = createSelector(
  selectBillAccountsNotifications,
  billAccounts => !_some(billAccounts, { prefsFinishedLoading: false })
);

// bill account which are not readonly.
// DM: need here so we can use notifications entities
export const selectModifiableBillAccounts = createSelector(
  selectBillAccounts,
  selectBillAccountNotificationsEntities,
  (billAccounts, notifications) =>
    billAccounts.filter(
      billAccount =>
        !billAccount.readOnly &&
        _get(notifications[billAccount.billAccountNumber], 'prefsFinishedLoading', true)
    )
);

export const selectHasPastDue = createSelector(selectBillAccounts, billAccounts =>
  _some(billAccounts, { pastDue: true })
);

export const billaccountsQuery = {
  selectBillAccountCancelPath,
  selectBillAccountsArePaperless,
  selectMatchedBillAccount,
  selectIsEligibleToViewPreferences,
  selectBillAccountsUpdatingPolicyList,
  selectBillAccountsUpdatingRegistration,
  selectBillAccountsUpdatingPreferences,
  selectBillAccountsAnyLoading,
  selectBillAccountsAnyPrefsLoading,
  selectBillAccountDataToUpdatePreferences,
  selectBillAccountsWithUpdatePreferenceFailure,
  selectBillAccountsWithRegistrationFailure,
  selectUnregisteredBillAccounts,
  selectAllRegisteredBillAccounts,
  selectUserRegisteredBillAccounts,
  selectModifiableBillAccounts,
  selectBillAccounts,
  selectSelectedBillAccount,
  selectBillAccountIds,
  selectBillAccountEntities,
  selectAllBillAccounts,
  selectAllBillAccountsNotifications,
  selectBillAccountsHasError,
  selectBillAccountsError,
  selectBillAccountsLoading,
  selectBillAccountsLoaded,
  selectBillAccountsState,
  selectAllBillingPreferencesLoaded,
  selectHasPastDue
};
