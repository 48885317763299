import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import compareAsc from 'date-fns/compare_asc';
import dateFormat from 'date-fns/format';
import { get as _get, flatMap } from 'lodash';

import {
  EditScheduledPaymentSuccessModel,
  PaymentStatus,
  ScheduledPayment,
  SubmitScheduledPaymentSuccessModel
} from '@amfam/shared/models';

import { ScheduledPaymentsActions, ScheduledPaymentsActionTypes } from './schedulepayment.actions';

export const SCHEDULE_PAYMENT_FEATURE_KEY = 'schedule payment';
export interface SchedulePaymentEntityState extends EntityState<ScheduledPayment> {
  paymentId: string;
}

export const adapter: EntityAdapter<ScheduledPayment> = createEntityAdapter<ScheduledPayment>({
  selectId: (schedulePaymentObjModel: ScheduledPayment) => schedulePaymentObjModel.paymentId,
  sortComparer: false
});

export const initialState: SchedulePaymentEntityState = adapter.getInitialState({
  paymentId: ''
});

export function reducer(
  state: SchedulePaymentEntityState = initialState,
  action: ScheduledPaymentsActions
): SchedulePaymentEntityState {
  switch (action.type) {
    case ScheduledPaymentsActionTypes.ScheduledPaymentsLoadSuccessType: {
      return adapter.upsertMany(action.payload, state);
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitSuccessType: {
      const registeredPayment = buildScheduledPaymentEntity(action.payload);
      return adapter.upsertOne(registeredPayment, state);
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsEditSuccessType: {
      const editedRegisteredPayment = buildScheduledPaymentEntity(action.payload);
      return adapter.upsertOne(editedRegisteredPayment, state);
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsTruncateType: {
      return adapter.removeOne(action.payload, state);
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsUpdateType: {
      return adapter.upsertMany(
        flatMap(state.entities)
          .filter(entity => entity?.paymentAccount?.nickName === action.payload.oldNickName)
          .map(entity =>
            Object.assign({}, entity, {
              paymentAccount: {
                nickName: action.payload.newNickName
              }
            })
          ),
        state
      );
    }

    case ScheduledPaymentsActionTypes.ScheduledPaymentsRemoveType: {
      let paymentsArr: Array<string> = [];
      Object.keys(state.entities).forEach(key => {
        if (_get(state, 'entities[key].paymentAccount.nickName')) {
          // Remove the scheduled payments which matched the delted payment account, unless the status on the
          // scheduled payment is 'AUTHORIZED' , in which case leave that payment alone as it has already been processed.
          if (
            state.entities[key].paymentAccount.nickName ===
              _get(action, 'payload.request.nickName') &&
            state.entities[key].paymentStatus !== PaymentStatus.AUTHORIZED
          ) {
            paymentsArr = [...paymentsArr, state.entities[key].paymentId];
          }
        }
      });
      return adapter.removeMany(paymentsArr, state);
    }

    default:
      return state;
  }

  function buildScheduledPaymentEntity(
    obj: SubmitScheduledPaymentSuccessModel | EditScheduledPaymentSuccessModel
  ): ScheduledPayment {
    const newScheduledPayment = Object.assign({});

    // Last updated timestamp will not be accurate in case of edit scheduled payment
    newScheduledPayment.lastUpdateTimestamp = '0001-01-01T00:00:00.000-0600';

    // PaymentId is in the response for add payment, in the request object for edit
    newScheduledPayment.paymentId = _get(obj, 'response.paymentId')
      ? _get(obj, 'response.paymentId')
      : _get(obj, 'request.paymentId', '');

    // This is to indicate if the payment was created via an autopay rule, which is never the case here
    newScheduledPayment.autoPayIndicator = 'false';

    newScheduledPayment.paymentMethod =
      _get(obj, 'request.payment.paymentMethod') === 'OLB'
        ? 'Online Billing'
        : _get(obj, 'request.payment.paymentMethod', 'Online Billing');

    newScheduledPayment.totalPaymentAmount = _get(obj, 'request.payment.totalPaymentAmount');

    // Remove the billAccountNickName element from each bill account array object
    newScheduledPayment.billAccounts = [];
    const bas = _get(obj, 'request.payment.billAccounts', []);
    bas.forEach(ba => {
      newScheduledPayment.billAccounts.push({
        billAcctPaymentAmount: ba.billAcctPaymentAmount,
        billAccountNumber: ba.billAccountNumber
      });
    });

    newScheduledPayment.paymentAccount = _get(obj, 'request.payment.paymentAccount');
    newScheduledPayment.receivedDate = _get(obj, 'request.payment.receivedDate');

    // Default to credit card if we don't know what the payment type is since its the most restrictive
    newScheduledPayment.paymentType =
      _get(obj, 'request.paymentMethodType') === 'Bank' ? 'Checking/Savings' : 'Debit/CreditCard';

    if (
      compareAsc(newScheduledPayment.receivedDate, dateFormat(new Date(), 'YYYY-MM-DD')) ||
      newScheduledPayment.paymentType === 'Checking/Savings'
    ) {
      newScheduledPayment.paymentStatus = PaymentStatus.SCHEDULED;
    } else {
      newScheduledPayment.paymentStatus = PaymentStatus.AUTHORIZED;
    }

    return newScheduledPayment;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
