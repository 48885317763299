import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { AddressLookupModel, AddressLookupService } from '../../core/address-lookup';
import { AddressLookupResponse } from '../../core/address-lookup/address-model';

@Component({
  selector: 'app-suggest-address',
  templateUrl: './suggest-address.component.html',
  styleUrls: ['./suggest-address.component.scss']
})
export class SuggestAddressComponent implements OnChanges, OnInit {
  @Input()
  address: AddressLookupModel;

  @Output()
  addressLookup: EventEmitter<AddressLookupResponse> = new EventEmitter<AddressLookupResponse>(
    false
  );

  public addressList: Array<AddressLookupModel>;
  public selectedAddress: AddressLookupModel;
  public addressValid: boolean;

  constructor(private addressLookupService: AddressLookupService) {}

  ngOnInit() {
    this.addressValid = true;
    this.selectedAddress = null;
  }

  isValid(address?: AddressLookupModel) {
    address = address || this.address;
    return (
      this.addressValid &&
      address &&
      address.addressLine1 &&
      address.city &&
      address.stateCode &&
      address.zip5Code
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const address: AddressLookupModel = changes['address'].currentValue;
    this.addressList = [];
    this.addressValid = true;
    if (this.isValid(address)) {
      this.addressLookupService.lookup(address).subscribe(addressList => {
        this.addressList = addressList;
        this.selectedAddress = this.addressList[0] || null;
        this.addressValid = !!this.selectedAddress;
        this.addressLookup.emit({ address: this.selectedAddress, valid: this.addressValid });
      });
    }
  }

  onSelect(address: AddressLookupModel) {
    this.selectedAddress = address;
    this.addressLookup.emit({ address: address, valid: true });
  }

  getDisplayAddress(address: AddressLookupModel): string {
    const zipCode = [address.zip5Code, address.zip4Code].filter(z => z).join('-');
    return [
      address.addressLine1,
      address.addressLine2,
      '<br>' + address.city,
      address.stateCode + ' ' + zipCode
    ]
      .filter(a => a)
      .join(', ');
  }
}
