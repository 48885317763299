import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

import { ADD_VEHICLE_DATA_KEY, AddVehicleDataState } from '../add-vehicle-common.selector';

const selectNewVehicleBindState = createFeatureSelector<AddVehicleDataState>(ADD_VEHICLE_DATA_KEY);

export const selectVehicleBindLoading = createSelector(selectNewVehicleBindState, state => {
  const tempState = _get(state, 'addVehicleBindState');
  return tempState ? tempState.loading : true;
});

export const selectVehicleANSNotificationError = createSelector(
  selectNewVehicleBindState,
  state => {
    const tempState = _get(state, 'addVehicleBindState');
    return tempState ? tempState.ansError : false;
  }
);

export const selectVehicleBindError = createSelector(selectNewVehicleBindState, state => {
  const tempState = _get(state, 'addVehicleBindState');
  return tempState ? tempState.bindError : false;
});

export const addVehicleBindQuery = {
  selectVehicleBindLoading,
  selectVehicleANSNotificationError,
  selectVehicleBindError
};
