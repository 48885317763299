@if (!isForgerockEnabled) {
  <div class="main">
    <div class="row">
      <div class="column twelve sm-ten sm-push-one md-eight md-push-two lg-six lg-push-three">
        <div class="content">
          <h1>
            <span
              data-cy="needHelpSigningInHeader"
              content
              [contentPath]="'auth.forgotPasswordHelpText'"
            ></span>
          </h1>
          <p>
            <span content [contentPath]="'auth.forgotUseridResetText1'"></span>
            <!-- <span dsPartnerContent [brandContent]="'selectCompany'"></span> -->
            <span *dsPartnerAFI>American Family Insurance</span>
            <span *dsPartnerConnect>Midvale Insurance</span>
            <span content [contentPath]="'auth.forgotUseridResetText2'"></span>
          </p>
          <div class="row"><hr aria-hidden="true" /></div>
          <form
            [formGroup]="acceptEmailForm"
            (ngSubmit)="acceptEmailForm.valid && retrieveUserIdSetup()"
          >
            <ds-form-control-container [labelText]="'Email Address'" [control]="email">
              <input
                [focus]="emailInputFocus"
                data-cy="forgotUserIdEmailInput"
                type="text"
                id="email"
                class="fill"
                formControlName="email"
              />
              <ds-form-control-message [control]="email"></ds-form-control-message>
              @if (emailNotFoundError) {
                <small class="block caption error margin-top-5">
                  <span
                    data-cy="forgotUserIdMismatchText"
                    content
                    [contentPath]="'auth.forgotUseridMismatchText'"
                  ></span>
                  <span dsPartnerContent [brandContent]="'selectPrettyCusCareNumber'"></span>
                  <span content [contentPath]="'auth.forgotUseridMismatchText2'"></span>
                </small>
              }
              @if (unexpectedError) {
                <small
                  class="block caption error margin-top-5"
                  content
                  [contentPath]="'auth.genericErrorTryAgain'"
                ></small>
              }
              @if (duplicateEmailError && partnerId === 'AFI') {
                <p class="duplicateEmailError">
                  <span>{{ dupEmailError }}</span>
                </p>
              }
            </ds-form-control-container>
            <div class="row">
              <div class="column twelve">
                <a
                  data-cy="returnToLogInLink"
                  href="javascript:void(0);"
                  [dsRouterLink]="['/login']"
                  class="inline-block margin-top-20 caption"
                  ><i class="icon-chevron-left"></i>Return to log in</a
                >
                <button
                  ds-button
                  type="submit"
                  id="continueBtn"
                  data-cy="sendUserIdButton"
                  class="-outline float-right"
                  [disabled]="!acceptEmailForm.valid || !responseReceived"
                  content
                  [contentPath]="'auth.forgotUseridGetButtonText'"
                ></button>
              </div>
            </div>
          </form>
          <div class="row"><hr aria-hidden="true" /></div>
          @if (emailFound) {
            <div class="row">
              <small content [contentPath]="'auth.forgotUseridCheckSpamText'"></small>
            </div>
          }
          <div class="row">
            <div class="column twelve pad-top-5">
              <small content [contentPath]="'auth.loginTrouble'"></small>
              <a
                *dsPartnerAFI
                href="javascript:void(0)"
                (click)="openContactUs()"
                class="caption"
                content
                [contentPath]="'auth.loginTroubleNumber'"
              ></a>
              <a
                *dsPartnerConnect
                class="caption"
                dsPartnerContent
                [brandContent]="'selectPrettyCusCareNumber'"
                [brandHref]="'selectCusCareNumberFullHref'"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="login">
    <div class="login__form">
      <div
        class="login__form-header"
        content
        [contentPath]="'auth.forgerock.forgotUserIdHeaderText'"
      ></div>
      <p class="pad-top-10" content [contentPath]="'auth.forgerock.forgotUserIdBodyText'"></p>
      <br />
      <button
        ds-button
        class="-stacked"
        tabindex="-1"
        (click)="signIn()"
        [class.active]="true"
        content
        [contentPath]="'auth.forgerock.signIn'"
      ></button>
    </div>
  </div>
}
