import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AutoPaySelectors } from '@amfam/billing/auto-pay/data-access';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import {
  PaymentMethodActions,
  PaymentMethodSelectors
} from '@amfam/billing/payment-method/data-access';
import { PaymentAccountSelectors } from '@amfam/billing/paymentaccount/data-access';
import { ScheduledPaymentSelectors } from '@amfam/billing/schedulepayment/data-access';

import * as PaymentMethodFeatureActions from '../lib/+state/payment-method-feature.actions';
import { PaymentMethodDeleteContainerComponent } from './payment-method-delete-container/payment-method-delete-container.component';

@Injectable()
export class PaymentMethodFeatureGuard {
  dataLoading$: Observable<boolean> = of(false);

  constructor(private store: Store) {}

  canActivate(): Observable<boolean> | boolean {
    return combineLatest([
      this.store.select(BillAccountsSelectors.selectBillAccountsAnyLoading),
      this.store.select(AutoPaySelectors.getAnyLoading),
      this.store.select(ScheduledPaymentSelectors.selectScheduledPaymentsAnyLoading),
      this.store.select(PaymentAccountSelectors.selectPaymentAccountsAnyLoading),
      this.store.select(PaymentMethodSelectors.selectLoading)
    ]).pipe(
      map(
        ([
          billAccountsLoading,
          autopayLoading,
          scheduledPayLoading,
          paymentAccountsLoading,
          paymentMethodsLoading
        ]) =>
          billAccountsLoading ||
          autopayLoading ||
          scheduledPayLoading ||
          paymentAccountsLoading ||
          paymentMethodsLoading
      ),
      filter(loading => !loading),
      map(() => true)
    );
  }

  canDeactivate(
    component: PaymentMethodDeleteContainerComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    if (nextState.url.indexOf('payment-method') === -1) {
      if (state.url.includes('payment-method/delete')) {
        this.store.dispatch(PaymentMethodActions.resetpaymentAccountId());
      }
      this.store.dispatch(PaymentMethodFeatureActions.resetPaymentMethodState());
    }
    return true;
  }
}
