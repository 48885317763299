import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { PageAnalytic } from '@amfam/shared/utility/shared-services';

import * as fromRoot from '../../core/store';
import { ExitWarningService } from './exit-warning.service';

@Component({
  selector: 'enroll-exit-warning',
  templateUrl: './exit-warning.component.html',
  styleUrls: ['./exit-warning.component.scss']
})
export class ExitWarningComponent implements OnInit, OnDestroy {
  @Input()
  urlToNavigate: string;

  private sub: Subscription;
  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Enroll:CloseWindowWarning',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Enroll',
    subCategory2: 'Close Window Warning',
    subCategory3: ''
  };
  // **** End of Analytics data for this component
  public showWarning = false;

  constructor(
    private exitWarningSvc: ExitWarningService,
    private store: Store<fromRoot.RootState>,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    this.sub = this.exitWarningSvc.showWarning$.subscribe(flag => {
      this.showWarning = flag;
      if (flag) {
        this.analyticsFacade.trackPage(this.pageAnalytic);
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  cancel() {
    this.exitWarningSvc.hideWarning();
  }

  confirm() {
    this.exitWarningSvc.ignoreAdvice();
    this.store.dispatch(
      fromRouterActions.Go({
        path: [this.urlToNavigate]
      })
    );
  }
}
