import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClaimGlassOnlyEffects } from './+state/claim-glass-only.effects';
import {
  CLAIMGLASSONLY_FEATURE_KEY,
  claimGlassOnlyReducer
} from './+state/claim-glass-only.reducer';
import { initialClaimGlassOnly as claimGlassOnlyInitialState } from './models/claim-glass-only.model';
import { ClaimGlassOnlyService } from './services/claim-glass-only.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CLAIMGLASSONLY_FEATURE_KEY, claimGlassOnlyReducer, {
      initialState: claimGlassOnlyInitialState
    }),
    EffectsModule.forFeature([ClaimGlassOnlyEffects])
  ]
})
export class ClaimGlassOnlyDataAccessModule {}
