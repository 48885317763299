import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';

import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { CopyService, PartnerTermsData } from '@amfam/shared/utility/shared-services';
import { AlertModalConfig, DsModalService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-paperless-modal-wrapper',
  templateUrl: './paperless-modal-wrapper.component.html',
  styleUrls: ['./paperless-modal-wrapper.component.scss']
})
export class PaperlessModalWrapperComponent implements OnInit {
  @Output() agreeTermsAndConditionsEvent = new EventEmitter<void>();

  public termsPartnerData$: Observable<PartnerTermsData> = this.store.select(
    BrandSelectors.selectPartnerTermsData
  );
  modalConfig: AlertModalConfig;
  constructor(
    private copyService: CopyService,
    private store: Store,
    private modalService: DsModalService,
    private feature: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.getModelConfig();
  }
  getModelConfig(): void {
    const tertiaryButtonName = this.copyService.getCopy('shared.cancel');
    const primaryButtonName = this.feature.isEnabled('go_paperless_new_enrollment_page')
      ? this.copyService.getCopy('shared.iAgree')
      : undefined;

    this.modalConfig = {
      headerText: this.copyService.getCopy('policy.TAndC'),
      bodyText: '',
      modalId: 'termAndConditionsModal',
      maxWidth: 700,
      ctaConfig: { primaryButtonName, tertiaryButtonName }
    };
  }

  agree(): void {
    this.agreeTermsAndConditionsEvent.emit();
    this.modalService.close('termAndConditionsModal');
  }

  close(): void {
    this.modalService.close('termAndConditionsModal');
  }
}
