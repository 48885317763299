import { Action } from '@ngrx/store';

import { Program } from './models';

export const LOAD = '[MaeProgram] Load';
export const LOAD_SUCCESS = '[MaeProgram] Load Success';
export const ENROLL = '[MaeProgram] Enroll';
export const UNENROLL = '[MaeProgram] Unenroll';
export const LOAD_FAIL = '[MaeProgram] Load Fail';
export const INIT_PROGRAMS = '[MaeProgram] Init';
export class LoadAction implements Action {
  readonly type = LOAD;
  constructor(public payload?: any) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;
  constructor(public payload: Program[]) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
  constructor(public payload?: any) {}
}

/**
 * Init store after mae enrollments are completed.
 */
export class InitPrograms implements Action {
  readonly type = INIT_PROGRAMS;
  constructor() {}
}

export type Actions = LoadAction | LoadSuccessAction | LoadFailAction | InitPrograms;
