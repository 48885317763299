import { Location } from '@angular/common';
import { ErrorHandler, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ConfigService } from '@amfam/shared/utility/shared-services';

import * as fromRoot from '../../core/store';
import * as errorActions from '../../core/store/error/error.actions';

@Injectable()
export class ApplicationErrorHandler extends ErrorHandler {
  private isLoaded: boolean;

  constructor(
    private route: Location,
    private store: Store<fromRoot.RootState>,
    private config: ConfigService
  ) {
    super();
  }

  handleError(error: any) {
    try {
      if (!this.config.get('suppressErrorPage')) {
        if (error instanceof Error) {
          this.store.dispatch(
            new errorActions.LoadSuccessAction({ message: error.message, stack: error.stack })
          );
        }
        if (this.route.path().indexOf('error') < 0) {
          this.store.dispatch(
            fromRouterActions.Go({
              path: ['/error']
            })
          );
        }
      }
    } catch (e) {
      console.log('error handler caused error', e);
    }
    super.handleError(error);
    return true;
  }
}
