import { createAction, props } from '@ngrx/store';

export enum ClaimEnterpriseActionTypes {
  CreateClaim = '[ClaimEnterprise] Create  Claim',
  CreateClaimSuccess = '[ClaimEnterprise] Create Claim Success',
  CreateClaimFail = '[ClaimEnterprise] Create Claim Fail'
}

export const CreateEnterpriseClaim = createAction(
  ClaimEnterpriseActionTypes.CreateClaim,
  props<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
    isProperty?: boolean;
  }>()
);

export const CreateEnterpriseClaimSuccess = createAction(
  ClaimEnterpriseActionTypes.CreateClaimSuccess,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload?: any) => ({ payload })
);
export const CreateEnterpriseClaimFail = createAction(
  ClaimEnterpriseActionTypes.CreateClaimFail,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload?: any) => ({ payload })
);

export type ClaimEnterpriseAction =
  | ReturnType<typeof CreateEnterpriseClaim>
  | ReturnType<typeof CreateEnterpriseClaimSuccess>
  | ReturnType<typeof CreateEnterpriseClaimFail>;

export const fromClaimEnterpriseActions = {
  CreateEnterpriseClaim,
  CreateEnterpriseClaimSuccess,
  CreateEnterpriseClaimFail
};
