import { Component, Input, OnInit } from '@angular/core';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';

@Component({
  selector: 'if-feature-enabled',
  templateUrl: './feature-flag-wrapper.component.html'
})
export class FeatureFlagWrapperComponent implements OnInit {
  @Input()
  private flag: string;

  @Input()
  private hideIfEnabled = false;

  featureEnabled: boolean;

  constructor(private feature: FeatureFlagService) {}

  ngOnInit() {
    if (!this.hideIfEnabled) {
      this.featureEnabled = this.feature.isEnabled(this.flag);
    } else {
      this.featureEnabled = !this.feature.isEnabled(this.flag);
    }
  }
}
