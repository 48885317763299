import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { fromRouterActions } from '@amfam/shared/utility/navigation';

import * as fromRoot from '../store/';
import * as workflowActions from '../store/workflow';
import { stepNames } from '../store/workflow/workflow.model';

@Injectable()
export class WorkflowGuardService {
  constructor(private store: Store<fromRoot.RootState>) {}

  /**
   * This method checks if a step can be activated
   */
  canActivateStep(activeStepName: string, enrollmentType: string): Observable<boolean> {
    return this.store.select(fromRoot.getWorkflowState).pipe(
      map(workflow => {
        // is workflow loaded?
        if (!workflow.loaded) {
          // entered directly to this step, go to the beginning
          this.store.dispatch(
            fromRouterActions.Go({
              path: ['/enroll/' + enrollmentType]
            })
          );
          return false;
        }
        // is this the active step?
        if (workflow.steps[stepNames.verification].name === workflow.activeStep) {
          return true;
        }
        // is this step complete?
        if (workflow.steps[stepNames.verification].complete) {
          // workflow does not know we are this step, (could be from back button) set as active step
          // TODO: should the workflow steps specify which steps can be accessed by back button?
          this.store.dispatch(
            new workflowActions.WorkflowStepSetActiveAction({ activeStep: activeStepName })
          );
        }
        return false;
      }),
      catchError(err => {
        return of(false);
      })
    );
  }
}
