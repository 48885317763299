import { createAction } from '@ngrx/store';

import { EDeliveryStatus } from '@amfam/shared/models';

import { GoPaperLessAnalyticsModel, GoPaperlessSuccessModel } from '../models/go-paperless.model';

export enum GoPaperlessActionTypes {
  LoadGoPaperlessType = '[GoPaperless] Load GoPaperless',

  EnrollGoPaperlessType = '[GoPaperless] Enroll GoPaperless',
  EnrollGoPaperlessSuccessType = '[GoPaperless] Enroll GoPaperless Success',
  EnrollGoPaperlessFailureType = '[GoPaperless] Enroll GoPaperless Failure',

  UnEnrollGoPaperlessType = '[GoPaperless] Unenroll GoPaperless',
  UnEnrollGoPaperlessSuccessType = '[GoPaperless] Unenroll GoPaperless Success',
  UnEnrollGoPaperlessFailureType = '[GoPaperless] Unenroll GoPaperless Failure',

  GenerateGoPaperlessEmailType = '[GoPaperless] Email GoPaperless',
  GenerateGoPaperlessEmailSuccessType = '[GoPaperless] Email GoPaperless Success',
  GenerateGoPaperlessEmailFailureType = '[GoPaperless] Email GoPaperless Failure',

  SendGoPaperlessAnalyticsType = '[GoPaperless] Analytics GoPaperless'
}

export const LoadGoPaperless = createAction(
  GoPaperlessActionTypes.LoadGoPaperlessType,
  (payload?: EDeliveryStatus) => ({ payload })
);

export const EnrollGoPaperless = createAction(
  GoPaperlessActionTypes.EnrollGoPaperlessType,
  (payload?: { correlationId: string }) => ({ payload })
);

export const EnrollGoPaperlessSuccess = createAction(
  GoPaperlessActionTypes.EnrollGoPaperlessSuccessType,
  (payload?: GoPaperlessSuccessModel) => ({ payload })
);

export const EnrollGoPaperlessFailure = createAction(
  GoPaperlessActionTypes.EnrollGoPaperlessFailureType,
  (payload?: unknown) => ({ payload })
);

export const UnEnrollGoPaperless = createAction(
  GoPaperlessActionTypes.UnEnrollGoPaperlessType,
  (payload?: { correlationId: string }) => ({ payload })
);

export const UnEnrollGoPaperlessSuccess = createAction(
  GoPaperlessActionTypes.UnEnrollGoPaperlessSuccessType,
  (payload?: GoPaperlessSuccessModel) => ({ payload })
);

export const UnEnrollGoPaperlessFailure = createAction(
  GoPaperlessActionTypes.UnEnrollGoPaperlessFailureType,
  (payload?: unknown) => ({ payload })
);

export const GenerateGoPaperlessEmail = createAction(
  GoPaperlessActionTypes.GenerateGoPaperlessEmailType,
  (payload?: {
    paperlessOperation: unknown;
    affectedItemsList?: unknown;
    correlationId?: string;
  }) => ({ payload })
);

export const GenerateGoPaperlessEmailSuccess = createAction(
  GoPaperlessActionTypes.GenerateGoPaperlessEmailSuccessType,
  (payload?: unknown) => ({ payload })
);

export const GenerateGoPaperlessEmailFailure = createAction(
  GoPaperlessActionTypes.GenerateGoPaperlessEmailFailureType,
  (payload?: unknown) => ({ payload })
);

export const SendGoPaperlessAnalytics = createAction(
  GoPaperlessActionTypes.SendGoPaperlessAnalyticsType,
  (payload: GoPaperLessAnalyticsModel) => ({ payload })
);

export type GoPaperlessActions =
  | ReturnType<typeof LoadGoPaperless>
  | ReturnType<typeof EnrollGoPaperless>
  | ReturnType<typeof EnrollGoPaperlessSuccess>
  | ReturnType<typeof EnrollGoPaperlessFailure>
  | ReturnType<typeof UnEnrollGoPaperless>
  | ReturnType<typeof UnEnrollGoPaperlessSuccess>
  | ReturnType<typeof UnEnrollGoPaperlessFailure>
  | ReturnType<typeof GenerateGoPaperlessEmail>
  | ReturnType<typeof GenerateGoPaperlessEmailSuccess>
  | ReturnType<typeof GenerateGoPaperlessEmailFailure>
  | ReturnType<typeof SendGoPaperlessAnalytics>;

export const fromGoPaperlessActions = {
  LoadGoPaperless,
  EnrollGoPaperless,
  EnrollGoPaperlessSuccess,
  EnrollGoPaperlessFailure,
  UnEnrollGoPaperless,
  UnEnrollGoPaperlessSuccess,
  UnEnrollGoPaperlessFailure,
  GenerateGoPaperlessEmail,
  GenerateGoPaperlessEmailSuccess,
  GenerateGoPaperlessEmailFailure,
  SendGoPaperlessAnalytics
};
