import { createAction, props } from '@ngrx/store';

export const forgerockLogin = createAction(
  '[Auth] Forgerock Login',
  props<{ accessToken: string; idToken: string }>()
);

export const forgerockLoginSuccess = createAction('[Auth] Forgerock Login Success');
export const forgerockLoginFailure = createAction('[Auth] Forgerock Login Failure');
export const forgerockLogout = createAction('[Auth] Forgerock Logout');
export const forgerockLogoutSuccess = createAction('[Auth] Forgerock Logout Success');
export const forgerockLogoutFailure = createAction('[Auth] Forgerock Logout Failure');
export const forgerockSessionCheck = createAction('[Auth] Forgerock Session Check');
export const forgerockSessionCheckSuccess = createAction('[Auth] Forgerock Session Check Success');
export const forgerockRefresh = createAction('[Auth] Forgerock Refresh');
export const forgerockRefreshSuccess = createAction('[Auth] Forgerock Refresh Success');
export const forgerockRefreshFailure = createAction('[Auth] Forgerock Refresh Failure');
