import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ACTIONTYPE, AnalyticsActions, AnalyticsFacade } from '@amfam/shared/analytics';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ConfigService, CopyService, PageAnalytic } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService, ToasterService, ValidationService } from '@amfam/ui-kit';

import { ForgerockAuthService } from '../../core/auth';
import { AuthService } from '../../core/auth/auth.service';
import * as fromRoot from '../../core/store';
import * as enrollUserActions from '../../core/store/enrollment';
import { VerificationService } from '../../enrollment/personal/verification';
import { ForgotUserIdModel } from './forgot-userid.model';

@Component({
  selector: 'forgot-userid',
  templateUrl: './forgot-userid.component.html',
  styleUrls: ['./forgot-userid.component.scss']
})
export class ForgotUseridComponent implements OnInit, OnDestroy {
  public acceptEmailForm: UntypedFormGroup;
  public email: AbstractControl;
  public emailNotFoundError = false;
  public emailFound = false;
  public unexpectedError = false;
  public responseReceived = true;
  public emailInputFocus = true;
  public readonly ALLOWED_EMAIL_CHECKS: string;
  public partnerId = 'AFI';
  public duplicateEmailError = false;
  public dupEmailError: string;
  public isForgerockEnabled: boolean;
  private userIdRetrievalAttemptsSub: Subscription;
  private numberOfAttempts: number;
  private amfamChatUrl: string;
  private amfamDotCom: string;
  private emailForResend: string;
  private contactNumberLink: string;
  // **** Start of Analytics data for this component
  private pageAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotUserID',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot User ID',
    subCategory3: ''
  };
  private trackAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotUserID:EmailSent',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: 'Forgot User ID',
    subCategory3: ''
  };
  private forgotUserIDMultipleEmailErrorAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Login:ForgotUserID:MultipleEmailError',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Login',
    subCategory2: '',
    subCategory3: ''
  };
  // **** End of Analytics data for this component

  private stop$ = new Subject<void>();
  private contactNumber: string;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private spinner: LoadingSpinnerService,
    private config: ConfigService,
    private toasterService: ToasterService,
    private verificationService: VerificationService,
    private analyticsFacade: AnalyticsFacade,
    private store: Store<fromRoot.RootState>,
    private copyService: CopyService,
    private featureFlagService: FeatureFlagService,
    private forgerockService: ForgerockAuthService
  ) {
    this.ALLOWED_EMAIL_CHECKS = this.config.get('userIdRetrievalAllowedAttempts');
    this.amfamChatUrl = this.config.get('links.amfamChatUrl');
    this.amfamDotCom = this.config.get('links.amfamDotComUrl');
    this.contactNumberLink = this.config.get('numberLink');
    this.authService.refreshSessionStorageAttempts();
  }

  ngOnInit() {
    this.getPartnerId();
    this.getContactNumber();
    this.userIdRetrievalAttemptsSub = this.authService.userIdRetrievalAttempts$.subscribe(res => {
      this.numberOfAttempts = parseInt(res, 10);
    });

    this.acceptEmailForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          ValidationService.emailValidator,
          ValidationService.allowedEmailCharactersValidator
        ])
      ]
    });
    this.email = this.acceptEmailForm.controls['email'];
    this.analyticsFacade.trackPage(this.pageAnalytic);
    this.isForgerockEnabled = this.featureFlagService.isEnabled('forgerock');
  }

  ngOnDestroy() {
    if (this.userIdRetrievalAttemptsSub) {
      this.userIdRetrievalAttemptsSub.unsubscribe();
    }
    this.spinner.stop();
  }

  retrieveUserIdSetup() {
    this.store.dispatch(
      AnalyticsActions.sendDynatraceAction({
        payload: {
          actionName: this.email.value,
          actionType: ACTIONTYPE.FORGOTUSERID
        }
      })
    );
    this.retrieveUserId(this.setForgotUserIdRequestObject(this.email.value));
  }

  resetErrorAndSuccessMessages() {
    this.responseReceived = false;
    this.emailNotFoundError = false;
    this.unexpectedError = false;
    this.emailFound = false;
  }

  retrieveUserId(requestObj: any) {
    this.resetErrorAndSuccessMessages();
    this.spinner.start();
    this.authService.retrieveUserId(requestObj).subscribe(
      res => {
        // 200 indicates enrolled account found, so send email
        if (res && res.status.code === 200) {
          this.handleNoShellAccount(res);
        }
        this.spinner.stop();
        this.responseReceived = true;
      },
      err => {
        if (err && err.status.code === 409) {
          if (this.partnerId === 'AFI') {
            this.dupEmailError = this.copyService.getCopy('auth.duplicateEmailForgotUserIdError', {
              contactNumber: this.copyService.getCopy('shared.contactNumber')
            });
          }
          this.analyticsFacade.trackPage(this.forgotUserIDMultipleEmailErrorAnalytic);
          this.duplicateEmailError = true;
        } else if (err && err.status.code === 404) {
          // Check if a shell account exists and route to enrollment if found
          this.checkForShellAccount();
        } else {
          this.unexpectedError = true;
        }
        this.spinner.stop();
        this.responseReceived = true;
      }
    );
  }

  checkForShellAccount() {
    const email = this.email.value;
    return this.verificationService.checkIfMyAccountExistsEmail(email).subscribe(
      res => {
        const statusCode = res && res.status && res.status.code ? res.status.code : 500;
        if (statusCode === 200) {
          if (res.shellAccountIndicator) {
            // If shell account found, route user to personal enrollment
            this.store.dispatch(new enrollUserActions.ForgotUserIdShellAccountAction({}));
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/enroll/personal']
              })
            );
            return;
          } else {
            this.handleNoAccountFound();
          }
        } else if (statusCode === 404) {
          this.handleNoAccountFound();
        }
        return;
      },
      err => {
        if (err && err.status.code === 404) {
          this.handleNoAccountFound();
        } else {
          this.unexpectedError = true;
        }
        return;
      }
    );
  }

  handleNoAccountFound() {
    this.emailNotFoundError = true;
    this.emailFound = false;
    this.authService.incrementEmailEntryAttempts();
  }

  handleNoShellAccount(res: any) {
    this.emailNotFoundError = false;
    this.emailForResend = res.emailForResend;
    this.toasterService.pop(
      'success',
      'Thanks! Your User ID has been sent to ' + this.emailForResend + '.'
    );
    this.emailFound = true;
    this.trackEmailSent();
  }

  setForgotUserIdRequestObject(email: string): ForgotUserIdModel {
    const forgotUserIdRequestObject: ForgotUserIdModel = {
      partnerId: this.partnerId,
      emailAddress: email
    };
    return forgotUserIdRequestObject;
  }

  resendEmail() {
    this.retrieveUserId(this.setForgotUserIdRequestObject(this.emailForResend));
  }

  openLiveChat() {
    window.open(this.amfamChatUrl);
  }

  openContactUs() {
    window.open(this.contactNumberLink);
  }

  async signIn() {
    this.spinner.start();
    const acrValues = this.config.get('forgerock.login');
    await this.forgerockService.getTokens(acrValues, true);
    this.spinner.stop();
  }

  private getPartnerId() {
    this.store
      .select(BrandSelectors.selectPartnerId)
      .pipe(take(1))
      .subscribe(partnerId => (this.partnerId = partnerId));
  }
  private getContactNumber() {
    this.store
      .select(BrandSelectors.selectCusCareNumber)
      .pipe(take(1))
      .subscribe((contactNumber: string) => {
        this.contactNumber = contactNumber;
      });
  }

  private trackEmailSent() {
    this.analyticsFacade.trackPage(this.trackAnalytic);
  }
}
