import { createAction } from '@ngrx/store';

import {
  DeletePaymentAccountSuccessModel,
  DeleteScheduledPaymentFailureModel,
  DeleteScheduledPaymentSuccessModel,
  EditScheduledPaymentSuccessModel,
  ScheduledPayment,
  SchedulePaymentPayloadModel,
  SubmitScheduledPaymentFailureModel,
  SubmitScheduledPaymentPayloadModel
} from '@amfam/shared/models';

export enum ScheduledPaymentsActionTypes {
  ScheduledPaymentsLoadType = '[ScheduledPayments] Load',
  ScheduledPaymentsLoadSuccessType = '[ScheduledPayments] Load Success',
  ScheduledPaymentsLoadFailType = '[ScheduledPayments] Load Fail',

  ScheduledPaymentsSubmitType = '[ScheduledPayments] Submit',
  ScheduledPaymentsSubmitSuccessType = '[ScheduledPayments] Submit Success',
  ScheduledPaymentsSubmitFailType = '[ScheduledPayments] Submit Fail',

  ScheduledPaymentsEditType = '[ScheduledPayments] Edit',
  ScheduledPaymentsEditSuccessType = '[ScheduledPayments] Edit Success',
  ScheduledPaymentsEditFailType = '[ScheduledPayments] Edit Fail',

  ScheduledPaymentsDeleteType = '[ScheduledPayments] Delete',
  ScheduledPaymentsDeleteSuccessType = '[ScheduledPayments] Delete Success',
  ScheduledPaymentsDeleteFailType = '[ScheduledPayments] Delete Fail',

  ScheduledPaymentsRemoveType = '[ScheduledPayments] Remove',
  ScheduledPaymentsUpdateType = '[ScheduledPayments] Update',
  ScheduledPaymentsCleanupType = '[ScheduledPayments] Cleanup',
  ScheduledPaymentsTruncateType = '[ScheduledPayments] Truncate'
}

export const scheduledPaymentsLoad = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsLoadType,
  (payload?: unknown) => ({ payload })
);

export const scheduledPaymentsLoadSuccess = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsLoadSuccessType,
  (payload: ScheduledPayment[]) => ({ payload })
);

export const scheduledPaymentsLoadFail = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsLoadFailType,
  (payload?: unknown) => ({ payload })
);

export const scheduledPaymentsSubmit = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitType,
  (payload: SubmitScheduledPaymentPayloadModel) => ({ payload })
);

export const scheduledPaymentsSubmitSuccess = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitSuccessType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (payload: any) => ({ payload })
);

export const scheduledPaymentsSubmitFail = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitFailType,
  (payload: SubmitScheduledPaymentFailureModel) => ({ payload })
);

export const scheduledPaymentsEdit = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsEditType,
  (payload: SchedulePaymentPayloadModel) => ({ payload })
);

export const scheduledPaymentsEditSuccess = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsEditSuccessType,
  (payload: EditScheduledPaymentSuccessModel) => ({ payload })
);

export const scheduledPaymentsEditFail = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsEditFailType,
  (payload: DeleteScheduledPaymentFailureModel) => ({ payload })
);

export const scheduledPaymentsDelete = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteType,
  (payload: SchedulePaymentPayloadModel) => ({ payload })
);

export const scheduledPaymentsDeleteSuccess = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteSuccessType,
  (payload: DeleteScheduledPaymentSuccessModel) => ({ payload })
);

export const scheduledPaymentsDeleteFail = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteFailType,
  (payload: DeleteScheduledPaymentFailureModel) => ({ payload })
);

export const scheduledPaymentsRemove = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsRemoveType,
  (payload: DeletePaymentAccountSuccessModel) => ({ payload })
);

export const scheduledPaymentsUpdate = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsUpdateType,
  (payload: { newNickName: string; oldNickName: string }) => ({ payload })
);

export const scheduledPaymentsCleanup = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsCleanupType,
  (payload: string) => ({ payload }) // paymentId
);

export const scheduledPaymentsTruncate = createAction(
  ScheduledPaymentsActionTypes.ScheduledPaymentsTruncateType,
  (payload: string) => ({ payload }) // paymentId
);

export type ScheduledPaymentsActions =
  | ReturnType<typeof scheduledPaymentsLoad>
  | ReturnType<typeof scheduledPaymentsLoadSuccess>
  | ReturnType<typeof scheduledPaymentsLoadFail>
  | ReturnType<typeof scheduledPaymentsCleanup>
  | ReturnType<typeof scheduledPaymentsSubmit>
  | ReturnType<typeof scheduledPaymentsSubmitSuccess>
  | ReturnType<typeof scheduledPaymentsSubmitFail>
  | ReturnType<typeof scheduledPaymentsEdit>
  | ReturnType<typeof scheduledPaymentsEditSuccess>
  | ReturnType<typeof scheduledPaymentsEditFail>
  | ReturnType<typeof scheduledPaymentsDelete>
  | ReturnType<typeof scheduledPaymentsDeleteSuccess>
  | ReturnType<typeof scheduledPaymentsDeleteFail>
  | ReturnType<typeof scheduledPaymentsUpdate>
  | ReturnType<typeof scheduledPaymentsRemove>
  | ReturnType<typeof scheduledPaymentsTruncate>;

export const fromScheduledPaymentsActions = {
  scheduledPaymentsLoad,
  scheduledPaymentsLoadSuccess,
  scheduledPaymentsLoadFail,
  scheduledPaymentsCleanup,
  scheduledPaymentsSubmit,
  scheduledPaymentsSubmitSuccess,
  scheduledPaymentsSubmitFail,
  scheduledPaymentsEdit,
  scheduledPaymentsEditSuccess,
  scheduledPaymentsEditFail,
  scheduledPaymentsDelete,
  scheduledPaymentsDeleteSuccess,
  scheduledPaymentsDeleteFail,
  scheduledPaymentsUpdate,
  scheduledPaymentsRemove,
  scheduledPaymentsTruncate
};
