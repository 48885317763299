import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { LoadSummaries } from './summaries.actions';
import { summariesQuery } from './summaries.selectors';

@Injectable()
export class SummariesFacade {
  loaded$ = this.store.select(summariesQuery.selectLoaded);
  allSummaries$ = this.store.select(summariesQuery.selectAllPolicySummaries);
  selectedSummaries$ = this.store.select(summariesQuery.selectPolicySummaries);

  constructor(private store: Store) {}

  loadAll(user: unknown) {
    this.store.dispatch(LoadSummaries(user));
  }
}
