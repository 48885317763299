import { ButtonAnalytic, PageAnalytic } from '@amfam/shared/utility/shared-services';

export const insuranceCardAnalytic: PageAnalytic = {
  pageName: 'MyAccount:Policies:Documents:InsuranceCard',
  experience: '',
  primaryCategory: 'My Account',
  subCategory1: '',
  subCategory2: '',
  subCategory3: ''
};

export const downloadPoiButton: ButtonAnalytic = {
  link: 'MyAccount:Policies:Documents:GetPOI:Download'
};

export const printPoiButton: ButtonAnalytic = {
  link: 'MyAccount:Policies:Documents:GetPOI:Print'
};

export const sharePoiButton: ButtonAnalytic = {
  link: 'MyAccount:Policies:Documents:GetPOI:Share'
};

export const viewCoveragePoiButton: ButtonAnalytic = {
  link: 'MyAccount:Policies:Documents:GetPOI:ViewCoverage'
};
