import { Action, createAction, props } from '@ngrx/store';

import {
  ANSRequestModel,
  ChangeCoverageEmailType,
  CoverageControlModel,
  ICoverage,
  PartnerChangeCoverageAgentEmail,
  Result,
  VehicleCoverageAnalyticPayLoad
} from '@amfam/policy/models';
import { Agent } from '@amfam/shared/utility/agent';
import { CopyService, TemplatedEmail } from '@amfam/shared/utility/shared-services';

export const setVehicleId = createAction(
  '[Change Coverage Feature] Set Vehicle Id',
  props<{ vehicleId: string }>()
);

export const buildPartnerCoverages = createAction(
  '[Change Coverage Feature] build partner coverages',
  props<{
    coverages: ICoverage[];
    stateCode: string;
    copy: { coverageMap: CopyService };
  }>()
);
export class CoverageChangeRequestANSNotificationAction implements Action {
  readonly type = '[Change Coverage Feature] Send Analytic Information';
  constructor(public payload: ANSRequestModel) {}
}

export const updateCoverage = createAction(
  '[Change Coverage Feature] update partner coverages',
  props<{ coverage: CoverageControlModel; value: string }>()
);

export const reset = createAction('[Change Coverage Feature] Reset partner coverages');

export const vehicleCoverageBindQuote = createAction(
  '[VehicleCoverage/API] Change Coverage bind quote',
  props<{ payload: { agent: Agent; workOrderNumber?: string; vehicleId: string } }>()
);

export const sendChangeCoverageCustomerEmail = createAction(
  '[Change Coverage Bind Effect] Send Customer Email',
  props<{ payload: TemplatedEmail }>()
);
export const sendChangeCoverageCustomerEmailSuccess = createAction(
  '[Change Coverage Bind Customer Email Effect] Send Customer Email Success'
);
export const sendChangeCoverageCustomerEmailFailed = createAction(
  '[Change Coverage Bind Customer Email Effect] Send Customer Email Failed'
);

export const sendChangeCoverageAgentEmail = createAction(
  '[Change Coverage Bind Effect] Send Agent Email',
  props<{ payload: PartnerChangeCoverageAgentEmail; vehicleId: string }>()
);
export const sendChangeCoverageAgentEmailSuccess = createAction(
  '[Change Coverage Bind Agent Email Effect] Send Agent Email Success',
  props<{ vehicleId: string }>()
);
export const sendChangeCoverageAgentEmailFailed = createAction(
  '[Change Coverage Bind Agent Email Effect] Send Agent Email Failed'
);

export const setInitialVehicleCoverage = createAction(
  '[Change Coverage] Set Initial VehicleCoverage',
  props<{ payload: Result; vehicleId?: string }>()
);

export const changeCoverageBindNotify = createAction(
  '[Change Coverage Bind Effect] Change Coverage Notify',
  props<{ payload: { type: ChangeCoverageEmailType; vehicleId: string } }>()
);
export const changeCoverageNotifyAgent = createAction(
  '[Change Coverage Send To Agent] Change Coverage Notify Agent',
  props<{ payload: { type: ChangeCoverageEmailType; vehicleId: string } }>()
);

export const sendAgentEmail = createAction(
  '[Change Coverage Send To Agent]agent email on error',
  props<{ payload: { type: ChangeCoverageEmailType; vehicleId: string } }>()
);

export const vehicleCoveragesSendAnalytics = createAction(
  '[Add/Change Coverage] send analytics',
  props<{ payload: VehicleCoverageAnalyticPayLoad }>()
);

export const editCoveragesSendAnalytics = createAction(
  '[Add/Edit Coverage] send analytics',
  props<{ payload: VehicleCoverageAnalyticPayLoad }>()
);
