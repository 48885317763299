import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { has as _has } from 'lodash';

@Component({
  selector: 'ds-radio-toggle-button',
  templateUrl: './ds-radio-toggle-button.component.html',
  styleUrls: ['./ds-radio-toggle-button.component.scss']
})
export class DsRadioToggleButtonComponent implements OnInit, AfterViewInit {
  @ViewChild('labelEl')
  labelEl: ElementRef;

  @Input() labelText: string;
  @Input() labelTestId: string;
  @Input() labelClass: string;
  @Input() labelAriaLabel: string;
  @Input() inputId: string;
  @Input() inputChecked = false;
  @Input() inputValue = false;
  @Input() hasSubMessage = false;
  @Input() inputFormControlName: string;
  @Input() inputTestId: string;
  @Input() formGroup: UntypedFormGroup;

  ngOnInit(): void {
    this.labelAriaLabel = this.labelAriaLabel || this.labelText;
  }

  ngAfterViewInit() {
    if (_has(this.labelEl, 'nativeElement')) {
      this.labelEl.nativeElement.classList.add('toggle-focus-off');
    }
  }

  onFocus() {
    if (_has(this.labelEl, 'nativeElement')) {
      this.labelEl.nativeElement.classList.add('toggle-focus-on');
      this.labelEl.nativeElement.classList.remove('toggle-focus-off');
    }
  }

  onBlur() {
    if (_has(this.labelEl, 'nativeElement')) {
      this.labelEl.nativeElement.classList.remove('toggle-focus-on');
      this.labelEl.nativeElement.classList.add('toggle-focus-off');
    }
  }
}
