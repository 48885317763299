import { createAction } from '@ngrx/store';

import { PendingRegistration } from '../models/pending-registration.model';

export enum PendingRegistrationsActionTypes {
  PENDING_REGISTRATIONS_ADD_MANY = '[PendingRegistrations] Add Many',
  PENDING_REGISTRATIONS_ADD_ONE = '[PendingRegistrations] Add One',
  PENDING_REGISTRATIONS_REMOVE_ALL = '[PendingRegistrations] Remove All',
  PENDING_REGISTRATIONS_REMOVE_ONE = '[PendingRegistrations] Remove One',
  PENDING_REGISTRATIONS_FAIL = '[PendingRegistrations] Fail'
}

export const PendingRegistrationsAddMany = createAction(
  PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_ADD_MANY,
  (payload: PendingRegistration[]) => ({
    payload
  })
);

export const PendingRegistrationsAddOne = createAction(
  PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_ADD_ONE,
  (payload: PendingRegistration) => ({
    payload
  })
);

export const PendingRegistrationsRemoveAll = createAction(
  PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_REMOVE_ALL,
  (payload?: unknown) => ({
    payload
  })
);

export const PendingRegistrationsRemoveOne = createAction(
  PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_REMOVE_ONE,
  (payload: PendingRegistration) => ({
    payload
  })
);

export const PendingRegistrationsFail = createAction(
  PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_FAIL,
  (payload: string) => ({
    payload
  })
);

export type PendingRegistrationsActions =
  | ReturnType<typeof PendingRegistrationsAddMany>
  | ReturnType<typeof PendingRegistrationsAddOne>
  | ReturnType<typeof PendingRegistrationsRemoveAll>
  | ReturnType<typeof PendingRegistrationsRemoveOne>
  | ReturnType<typeof PendingRegistrationsFail>;

export const fromPendingRegistrationsActions = {
  PendingRegistrationsAddMany,
  PendingRegistrationsAddOne,
  PendingRegistrationsRemoveAll,
  PendingRegistrationsRemoveOne,
  PendingRegistrationsFail
};
