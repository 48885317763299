import { get as _get } from 'lodash';

import { PartyEmail } from './party-email';
import { PartyPhone } from './party-phone';

export class Party {
  constructor() {}

  customerIdentifier: string;
  addresses: PartyAddress[];
  firstName: string;
  lastName: string;
  greetingName: string;
  partnerIdentifier: string;
  partyIdentifier: string;
  partyVersion: string;
  socialSecurityNumberExists: boolean;
  typeOfEdeliveryStatusCode: EDeliveryStatus;
  typeOfPartyCode: string;
  updatedOn: Date;

  emails = new Array<PartyEmail>();
  phones = new Array<PartyPhone>();

  get displayName() {
    // AS: If no first name just return the last name.
    return this.firstName ? (this.firstName + ' ' + this.lastName).trim() : this.lastName;
  }

  get primaryEmail(): PartyEmail {
    if (!this.emails || !this.emails.length) {
      return new PartyEmail('', 'HOME');
    }

    return (
      this.emails.find(email =>
        email.contactMethodUsages.some(usage => usage.typeOfUsageCode === 'MYACCOUNT')
      ) ||
      this.emails.find(email => email.primaryIndicator) ||
      this.emails[0]
    );
  }

  get primaryPhone(): PartyPhone {
    if (!this.phones || !this.phones.length) {
      return new PartyPhone();
    }

    return this.phones.find(phone => phone.primaryIndicator) || this.phones[0];
  }

  get homePhone(): PartyPhone {
    if (!this.phones || !this.phones.length) {
      return new PartyPhone();
    }
    return this.phones.find(phone => phone.contactMethodUsages[0].typeOfUsageCode === 'HOME');
  }

  static fromJson(json: any): Party {
    const party = new Party();
    // Party V3 Leg 01
    if (json) {
      party.firstName = json.firstName; // Is this right
      party.lastName = json.lastName; // Is this right
      party.greetingName = json.partyDetail?.greetingName;
      party.partnerIdentifier = json.partnerId;
      party.partyIdentifier = json.partyId;
      party.partyVersion = json.partyVersion;
      party.socialSecurityNumberExists =
        ['true', true].indexOf(
          _get(json, 'person.personConfidentialDetail.socialSecurityNumberOnRecordIndicator', false)
        ) > -1;
      party.typeOfEdeliveryStatusCode = Party.getEDeliveryStatus(json);
      party.typeOfPartyCode = json.typeOfPartyCode;
      party.updatedOn = new Date(json.updatedOn);

      if (json.contactMethodDetail) {
        if (json.contactMethodDetail.addresses) {
          party.addresses = json.contactMethodDetail.addresses;
        }
        if (json.contactMethodDetail.emails) {
          party.emails = json.contactMethodDetail.emails;
        }
        if (json.contactMethodDetail.phones) {
          party.phones = json.contactMethodDetail.phones.map(phone => PartyPhone.fromJson(phone));
        }
      }
    }
    return party;
  }

  private static getEDeliveryStatus(json: any): EDeliveryStatus {
    let status = 'NOT SPECIFIED';
    if (json.partyDetail && json.partyDetail.typeOfEdeliveryStatusCode) {
      status = json.partyDetail.typeOfEdeliveryStatusCode;
    } else if (json.typeOfEdeliveryStatusCode) {
      status = json.typeOfEdeliveryStatusCode;
    }

    return <EDeliveryStatus>status.replace('_', ' ');
  }
}

export interface PartyAddress {
  addressId: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip5: string;
  countryIdentifier: string;
  internationalAddressIndicator: string;
  locationDetail: LocationDetail;
  mailingIndicator: boolean;
  typeOfMailingPurposeCode: string;
  residenceIndicator: boolean;
  typeOfResidencePurposeCode: string;
  contactMethodUsages: ContactMethodUsage[];
}

export interface LocationDetail {
  latitude: string;
  longitude: string;
}

export interface ContactMethodUsage {
  mailingIndicator: string;
  typeOfResidencePurposeCode: string;
  residenceIndicator: string;
  businessIndicator: string;
}

type EDeliveryStatus = 'OPTED IN' | 'OPTED OUT' | 'NOT SPECIFIED';
