@if ((opportunityContent$ | async) && !showNewTemplate) {
  <layout-sidebar>
    <div class="main">
      <div class="faq">
        <div class="row">
          <div class="column twelve">
            <h2 class="heading-main">
              {{ (opportunityContent$ | async)?.detailComponentHeader }}
            </h2>
            <p [innerHTML]="(opportunityContent$ | async)?.detailComponentIntro"></p>
            @if ((opportunityContent$ | async)?.detailComponentIntro2) {
              <div>
                <p [innerHTML]="(opportunityContent$ | async)?.detailComponentIntro2"></p>
              </div>
            }
            @if ((opportunityContent$ | async)?.opportunityProductCode === 'property') {
              <div class="column twelve">
                <div class="column twelve">
                  <h2 class="heading-sub prop-sub">
                    {{ (opportunityContent$ | async).propertyHeaderQuestion }}
                  </h2>
                </div>
                @for (icon of propertyIcons; track icon; let i = $index) {
                  <div class="icon-div column two" [ngClass]="{ active: icon.active }">
                    <div (click)="switchPropertyTile(i)">
                      <i [ngClass]="icon.icon"></i>
                      <p class="icon-name">{{ icon.name }}</p>
                    </div>
                  </div>
                }
              </div>
            }
            @if ((opportunityContent$ | async)?.opportunityProductCode !== 'property') {
              <ds-accordion [allowMultipleOpenCategories]="true" [allowToggleAccordion]="true">
                @for (item of (opportunityContent$ | async)?.detailComponentFAQ; track item) {
                  <ds-accordion-category [title]="item.title">
                    <p [innerHTML]="item.body"></p>
                  </ds-accordion-category>
                }
              </ds-accordion>
            }
            @if ((opportunityContent$ | async)?.opportunityProductCode === 'property') {
              <ds-accordion
                [allowMultipleOpenCategories]="true"
                [allowToggleAccordion]="true"
                [index]="propItemIndex"
              >
                @for (
                  item of ((opportunityContent$ | async)?.detailComponentFAQ)[propItemIndex];
                  track item
                ) {
                  <ds-accordion-category [title]="item.title">
                    <p [innerHTML]="item.body"></p>
                  </ds-accordion-category>
                }
              </ds-accordion>
            }
            <ng-container *ngTemplateOutlet="cta"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <aside class="main-aside">
      @if ((authorized$ | async) && !!(recommendationId$ | async)) {
        <links-agents-sidebar
          [isQuickLinkDisable]="
            isContactMeDisabled(notificationState$ | async, recommendationId$ | async, 'links')
          "
          [quickLinksList]="quickLinksList"
        >
        </links-agents-sidebar>
      }
    </aside>
  </layout-sidebar>
} @else {
  <div class="row margin-top-10 new-card">
    <div class="column twelve">
      <p [innerHTML]="(opportunityContent$ | async)?.heroCard"></p>
    </div>
    <div class="column twelve md-pad-horizontal-40">
      <div class="column twelve pad-10">
        <h3 class="pad-bottom-10 font-weight-bold">
          {{ (opportunityContent$ | async)?.detailComponentHeader }}
        </h3>
        <h5 class="pad-bottom-10" [innerHTML]="(opportunityContent$ | async)?.detailComponentIntro">
          {{ (opportunityContent$ | async)?.detailComponentIntro }}
        </h5>
      </div>
      @for (item of (opportunityContent$ | async)?.detailComponentFAQ; track item) {
        <div class="column twelve md-four pad-10">
          <ds-flip-card>
            <h3 class="center font-weight-bold" front>{{ item.title }}</h3>
            <div back>
              <h3 class="center font-weight-bold">{{ item.title }}</h3>
              <p class="overflow-auto" [innerHTML]="item.body"></p>
            </div>
          </ds-flip-card>
        </div>
      }
      <div class="column twelve md-eight pad-10 justify-center items-center cta-section">
        <ng-container *ngTemplateOutlet="cta"></ng-container>
      </div>
    </div>
  </div>
  <div class="row md-pad-horizontal-40 caption">
    <p class="column pad-20" [innerHTML]="(opportunityContent$ | async)?.disclaimer"></p>
  </div>
}

<ng-template #cta>
  <div class="margin-top-20 opportunities-cta-background">
    <ds-opportunities-cta
      [showCTA]="!((notificationSuccess$ | async) || !(recommendationId$ | async))"
      [ctaData]="opportunitiesCTAData | async"
      [showContactMe]="
        notificationSuccess$ && notificationSuccess$?.customerFeedbackCode === 'CUSTOMER_DISMISSED'
      "
      (ctaClickEvent)="contactAgent($event)"
    ></ds-opportunities-cta>

    @if (!((notificationSuccess$ | async) || !(recommendationId$ | async))) {
      <call-to-action
        class="pad-bottom-10"
        [primaryButtonName]="primaryButton"
        [secondaryButtonName]="secondaryButton"
        [tertiaryButtonName]="tertiaryButton"
        [capitalize]="true"
        [equalWidthButtons]="true"
        (primaryEvent)="contactAgent(primaryButton)"
        (secondaryEvent)="contactAgent(secondaryButton)"
        (tertiaryEvent)="contactAgent(tertiaryButton)"
      ></call-to-action>
    }

    @if (!(pending$ | async)) {
      <div class="margin-top-20">
        @if (notificationStatus$ | async; as feedback) {
          @if (feedback?.error) {
            <div>
              <span class="error-600-text icon-alert margin-right-10"></span>
              @if (feedback.customerFeedbackCode === 'CUSTOMER_DISMISSED') {
                {{
                  (opportunityContent$ | async)?.generalFailureMessage ||
                    (opportunityContent$ | async)?.failureNotification
                }}
              } @else {
                {{ (opportunityContent$ | async)?.failureNotification }}
              }
              <ng-template #error>
                {{ (opportunityContent$ | async)?.failureNotification }}
              </ng-template>
            </div>
          }
        }
        @if (
          (notificationSuccess$ | async) &&
          (notificationSuccess$ | async)?.customerFeedbackCode !== 'CUSTOMER_DISMISSED'
        ) {
          <div>
            <span
              data-cy="successNotification"
              class="success-400-text icon-complete-circle margin-right-10"
            ></span
            >{{ (opportunityContent$ | async)?.successNotification }}
          </div>
        }
        @if (
          (notificationSuccess$ | async) &&
          (notificationSuccess$ | async)?.customerFeedbackCode === 'CUSTOMER_DISMISSED'
        ) {
          <div>
            <span class="success-400-text icon-complete-circle margin-right-10"></span
            >{{ (opportunityContent$ | async)?.nothanksSuccessMessage }}
          </div>
        }
      </div>
    }
  </div>
</ng-template>

<ds-modal
  [id]="agentSelectionModal"
  [closeOthers]="true"
  [maxWidth]="modalWidth"
  (onCloseModalEvent)="closeModal()"
>
  <p>{{ (opportunityContent$ | async)?.agentSelectHeader }}</p>
  <div>
    @if (!(pending$ | async)) {
      <form [formGroup]="agentNotificationForm">
        <ds-form-control-container
          [labelText]="'Select which agent'"
          [control]="agentNotificationForm.get('selectAgent')"
        >
          <select id="selectAgent" class="icon fill" formControlName="selectAgent">
            <option value=""></option>
            @for (agent of agents$ | async; track agent) {
              <option [value]="agent.agentId">
                {{ agent.fullName }}
              </option>
            }
          </select>
          <span class="icon-chevron-down"></span>
          <ds-form-control-message
            [control]="agentNotificationForm.get('selectAgent')"
          ></ds-form-control-message>
        </ds-form-control-container>
      </form>
    }
  </div>
  <div class="pad-top-10">
    <button
      ds-button
      content
      (click)="confirmAgentSelection()"
      class="fill"
      type="submit"
      [disabled]="!agentNotificationForm.valid || !(authorized$ | async)"
      [contentPath]="'shared.notifyAgent'"
    ></button>
  </div>
</ds-modal>
