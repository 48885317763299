import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import dateFormat from 'date-fns/format';
import {
  get as _get,
  has as _has,
  includes as _includes,
  merge as _merge,
  unionBy as _uniqBy,
  Dictionary
} from 'lodash';
import { forkJoin, from, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  finalize,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';

import { AutoPayActions } from '@amfam/billing/auto-pay/data-access';
import { PaymentMethodActions } from '@amfam/billing/payment-method/data-access';
import { PaymentConfirmationActions } from '@amfam/billing/paymentconfirmation/data-access';
import { PendingRegistrationsActions } from '@amfam/billing/registration/data-access';
import { ScheduledPaymentActions } from '@amfam/billing/schedulepayment/data-access';
import { BillAccountTransmuteService } from '@amfam/billing/shared/util';
import { PoliciesLoadComplete, PolicySelectors } from '@amfam/policy/data-access';
import {
  Policy,
  PolicyTypeDisplayNameConstants,
  PolicyTypeIconConstants,
  RiskModel
} from '@amfam/policy/models';
import { AnalyticsFacade, DynatraceService } from '@amfam/shared/analytics';
import {
  BillAccount,
  BillingPaymentPaths,
  DecoratedPreference,
  DeletePreferencesStoreModel,
  INVALID_IMPERSONATION_STATUS_CODE,
  OldUpdatePreferencesResponse,
  ONLINE_BILLING,
  PAPER,
  PaymentConfirmationModel,
  PcmAnalytics,
  PolicyType,
  PopulatePreferenceModel,
  RetrievePreferencePayload,
  UpdateAmdModel,
  UpdateRegistrationStoreModel
} from '@amfam/shared/models';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { UtilService } from '@amfam/shared/utility/shared-services';

import { RegisterBillAccModel } from '../models/billingAccount.model';
import { BillAccountsService } from '../services/bill-accounts.service';
import { BillingUtilService } from '../services/billing-util.service';
import {
  billAccountDeletePreference,
  billAccountDeletePreferenceFail,
  billAccountLoadDetail,
  billAccountLoadDetailSuccess,
  billAccountLoadDocuments,
  billAccountLoadFuturePayments,
  billAccountLoadPreference,
  billAccountLoadPreferenceSuccess,
  billAccountLoadUnAssociatedRiskDetail,
  billAccountReloadMinimumDue,
  billAccountSilentRegistration,
  billAccountsLoad,
  billAccountsLoadComplete,
  billAccountsLoadSuccess,
  billAccountTransformPolicyList,
  billAccountUpdateBillingPreference,
  billAccountUpdateDeliveryPreference,
  billAccountUpdatePreference,
  billAccountUpdateRegistrationSubmit,
  fromBillingActions
} from './billaccount.actions';
import { billaccountsQuery } from './billaccount.selectors';

@Injectable()
export class BillingEffects {
  futurePayments$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountLoadFuturePayments),
      map(action => action.payload),
      distinctUntilKeyChanged('billAccountNumber'),
      switchMap(payload => {
        return this.billingService.getScheduledPayments(payload.billAccountNumber).pipe(
          map((scheduledPayment: BillAccount) => {
            return fromBillingActions.billAccountLoadFuturePaymentsSuccess(scheduledPayment);
          }),
          catchError(error =>
            of(
              fromBillingActions.billAccountLoadFuturePaymentsFail({
                status: error,
                billAccountNumber: payload.billAccountNumber
              })
            )
          )
        );
      })
    );
  });

  load$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountsLoad),
      map(action => action.payload),
      switchMap(() =>
        this.billingService.getBillAccounts().pipe(
          finalize(() => {
            // Set the list load to complete for bill accouints.
            // eslint-disable-next-line ngrx/no-dispatch-in-effects
            this.store.dispatch(fromBillingActions.billAccountsLoadComplete());
          }),
          map(undecorated => this.billingTransmuteService.decorateAccountsArray(undecorated)),
          map(fetchedBillAccount => {
            const atLeastOnePastDueAccount = fetchedBillAccount.some(acc => acc.pastDue);
            if (atLeastOnePastDueAccount) {
              this.analyticsFacade.trackEvent({
                event: 'pastdue_bill',
                eventStep: ''
              });
            }
            return fromBillingActions.billAccountsLoadSuccess(fetchedBillAccount);
          }),
          catchError(error => of(fromBillingActions.billAccountsLoadFail(error)))
        )
      )
    );
  });

  loadAutoPays$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountsLoadComplete),
      map(action => action.payload),
      concatLatestFrom(() => this.store.select(billaccountsQuery.selectAllBillAccounts)),
      map(([, billAccounts]) => {
        return AutoPayActions.getAllAutomaticPayments({
          billAccountNumbers: billAccounts.map(ba => ba.billAccountNumber),
          correlationId: this.utilService.generateId()
        });
      })
    );
  });

  loadAll$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountsLoadSuccess),
      map(action => action.payload),
      mergeMap((decoratedArray: BillAccount[]) => from(decoratedArray)),
      mergeMap(ba => {
        let billAccountActions = [];
        billAccountActions = [
          fromBillingActions.billAccountLoadDetail({
            billAccountNumber: ba.billAccountNumber,
            associated: ba.associated
          }),
          fromBillingActions.billAccountLoadDocuments({
            billAccountNumber: ba.billAccountNumber,
            billingSystem: ba.billingSystem
          })
        ];

        // With PCM changes we need to make Get billAccount/id/preferences call irrespective of registration.
        const payload: RetrievePreferencePayload = {
          billAccountNumber: ba.billAccountNumber,
          associated: ba.associated,
          billingMethod: ba.billingMethod
        };
        billAccountActions.push(fromBillingActions.billAccountLoadPreference(payload));
        // TODO the condition below may not be needed, needs to be tested after it is removed
        if (_get(ba, 'billingMethod') === ONLINE_BILLING && !ba.registeredElsewhere) {
          billAccountActions.push(
            AutoPayActions.getAutomaticPayment({
              billAccountNumber: ba.billAccountNumber,
              correlationId: 'undefined'
            })
          );
        }
        return billAccountActions;
      }),
      catchError(error => of(fromBillingActions.billAccountsLoadFail(error)))
    );
  });

  loadDetail$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountLoadDetail),
      map(action => action.payload),
      mergeMap(payload =>
        this.billingService.getBillAccount(payload).pipe(
          map((res: { billingAccount: BillAccount }) => res.billingAccount),
          map(undecorated => this.billingTransmuteService.transmuteDetailCall(undecorated)),
          map(decorated => _merge(decorated, { associated: payload.associated })),
          map((res: BillAccount) => {
            let billAccountsActionsArr = [];
            billAccountsActionsArr = [fromBillingActions.billAccountLoadDetailSuccess(res)];
            if (!res.associated && _get(res, 'policyList.length', 0) > 0) {
              billAccountsActionsArr = [
                ...billAccountsActionsArr,
                fromBillingActions.billAccountLoadUnAssociatedRiskDetail({
                  billAccountNumber: res.billAccountNumber,
                  policyList: res.policyList
                })
              ];
            }
            return billAccountsActionsArr;
          }),
          mergeMap(actions => actions),
          catchError(error =>
            of(
              fromBillingActions.billAccountLoadDetailFail({
                billAccountNumber: payload.billAccountNumber,
                status: error
              })
            )
          )
        )
      )
    );
  });

  loadUnassociatedBaPolicyDetail$: Observable<Action> = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountLoadUnAssociatedRiskDetail),
      map(action => action.payload),
      mergeMap(payload =>
        this.billingService.getUnAssociatedBillAccountRiskInformation(payload.policyList).pipe(
          withLatestFrom(
            this.store.select(billaccountsQuery.selectMatchedBillAccount, payload.billAccountNumber)
          ),
          map(([policyList, billAccountArr]) => {
            return {
              newPolicyList: this.billingUtilService.uniqPolicyList(
                policyList,
                billAccountArr[0].policyList
              ),
              billaccountObj: billAccountArr[0]
            };
          }),
          map(transmutedObj => {
            let responsePayload = {
              billAccountNumber: payload.billAccountNumber,
              policyList: transmutedObj.newPolicyList
            };

            if (!transmutedObj.billaccountObj.associated) {
              let icon = PolicyTypeIconConstants.UNASSOCIATED;

              const uniquePolicyList = _uniqBy(
                transmutedObj.billaccountObj.policyList,
                'policyType'
              );
              if (
                uniquePolicyList.length === 1 &&
                (uniquePolicyList[0].policyType === PolicyType.FARM_RANCH ||
                  uniquePolicyList[0].policyType === PolicyType.FR_UMBRELLA)
              ) {
                icon = PolicyTypeIconConstants.COMMERCIAL;
              }

              responsePayload = Object.assign({}, responsePayload, {
                icon: icon,
                policyTypeDisplayName: PolicyTypeDisplayNameConstants.UNASSOCIATED
              });
            }
            return fromBillingActions.billAccountLoadUnAssociatedRiskDetailSuccess(responsePayload);
          }),
          catchError(() =>
            of(
              fromBillingActions.billAccountLoadUnAssociatedRiskDetailFail(
                Object.assign({}, { billAccountNumber: payload.billAccountNumber })
              )
            )
          )
        )
      )
    );
  });

  loadPrefs$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountLoadPreference),
      map(action => action.payload),
      mergeMap(payload =>
        this.billingService.getPreferences(payload).pipe(
          map((res: DecoratedPreference) =>
            fromBillingActions.billAccountLoadPreferenceSuccess(res)
          ),
          catchError(error => {
            this.analyticsFacade.trackPage(PcmAnalytics.getPreferencesPartialFailure);
            return of(
              fromBillingActions.billAccountLoadPreferenceFail({
                billAccountNumber: payload.billAccountNumber,
                status: error
              })
            );
          })
        )
      )
    );
  });

  updatePrefs$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountUpdatePreference),
      map(action => action.payload),
      mergeMap(payload =>
        this.billingService.updatePreferences(payload).pipe(
          mergeMap(response => {
            const updatePreferencesNextActions = [];

            // Translate the response object into the correct naming conventions (see bill-account.model.ts for details)
            const successObject = <OldUpdatePreferencesResponse>Object.assign(
              {},
              {
                billAccountNumber: payload.billAccountNumber,
                updateCorrelationId: payload.updateCorrelationId,
                billingPreferences: {
                  accountNickName: _get(payload, 'payload.billingPreference.accountNickname'),
                  deliveryMethod: _get(payload, 'payload.billingPreference.deliveryMethod'),
                  dueDateReminder: _get(payload, 'payload.billingPreference.dueDateReminder'),
                  preferences: _get(payload, 'payload.billingPreference.preferences')
                }
              }
            );

            if (_get(response, 'status.code') === 200) {
              updatePreferencesNextActions.push(
                fromBillingActions.billAccountUpdatePreferenceSuccess(successObject)
              );
            } else if (_get(response, 'status.messages[0].description')) {
              // Catches anything that would not be caught be the catch block, such as a 207
              updatePreferencesNextActions.push(
                fromBillingActions.billAccountUpdatePreferenceFail({
                  billAccountNumber: payload.billAccountNumber,
                  updateCorrelationId: payload.updateCorrelationId,
                  updatePreferencesError: _get(response, 'status.messages[0].description')
                })
              );
            }
            return updatePreferencesNextActions;
          }),
          catchError(error => {
            return of(
              fromBillingActions.billAccountUpdatePreferenceFail({
                billAccountNumber: payload.billAccountNumber,
                updateCorrelationId: payload.updateCorrelationId,
                updatePreferencesError: error
              })
            );
          })
        )
      )
    );
  });

  updateBillingPrefs$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountUpdateBillingPreference),
      map(action => action.payload),
      mergeMap(payload =>
        this.billingService.updateBillingPreferences(payload).pipe(
          mergeMap((updatedBillingPreferences: boolean) => {
            const updateBillingPreferencesNextActions = [];

            if (updatedBillingPreferences) {
              // Translate the response object into the correct naming conventions (see bill-account.model.ts for details)
              const updatePreferencesResponse = Object.assign(
                {},
                {
                  billAccountNumber: payload.billAccountNumber,
                  updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                  billingPreferences: {
                    accountNickName: _get(payload, 'payload.billingPreference.accountNickname', ''),
                    dueDateReminder: _get(payload, 'payload.billingPreference.dueDateReminder', ''),
                    preferences: _get(payload, 'payload.billingPreference.preferences', [])
                  }
                }
              );
              updateBillingPreferencesNextActions.push(
                fromBillingActions.billAccountUpdateBillingPreferenceSuccess(
                  updatePreferencesResponse
                )
              );
            } else {
              this.analyticsFacade.trackPage(PcmAnalytics.viewDetailPutFailure);
              updateBillingPreferencesNextActions.push(
                fromBillingActions.billAccountUpdateBillingPreferenceFail({
                  billAccountNumber: payload.billAccountNumber,
                  updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                  error: updatedBillingPreferences
                })
              );
            }
            return updateBillingPreferencesNextActions;
          }),
          catchError(error => {
            this.analyticsFacade.trackPage(PcmAnalytics.viewDetailPutFailure);
            return of(
              fromBillingActions.billAccountUpdateBillingPreferenceFail({
                billAccountNumber: payload.billAccountNumber,
                updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                error: error
              })
            );
          })
        )
      )
    );
  });

  updateDeliveryPrefs$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountUpdateDeliveryPreference),
      map(action => action.payload),
      mergeMap(payload =>
        this.billingService.updateDeliveryPreference(payload).pipe(
          mergeMap((updatedDeliveryPreferences: boolean) => {
            const updateDeliveryPreferenceNextActions = [];

            if (updatedDeliveryPreferences) {
              // Retrieve preferences payload
              const retrievePreferencepayload: RetrievePreferencePayload = {
                billAccountNumber: payload.billAccountNumber,
                associated: payload.associated,
                billingMethod: payload.billingMethod
              };
              // Update delivery preference response
              const updatePreferencesResponse = {
                billAccountNumber: payload.billAccountNumber,
                updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId
              };
              updateDeliveryPreferenceNextActions.push(
                fromBillingActions.billAccountLoadPreference(retrievePreferencepayload),
                fromBillingActions.billAccountUpdateDeliveryPreferenceSuccess(
                  updatePreferencesResponse
                )
              );
            } else {
              // AS: Track this error only in GA
              this.dynatraceService.sendDynatraceAction('pageview', 'Error_PUTFailure_Profile');
              updateDeliveryPreferenceNextActions.push(
                fromBillingActions.billAccountUpdateDeliveryPreferenceFail({
                  billAccountNumber: payload.billAccountNumber,
                  updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                  error: updatedDeliveryPreferences
                })
              );
            }
            return updateDeliveryPreferenceNextActions;
          }),
          catchError(error => {
            // AS: Track this error only in GA
            this.dynatraceService.sendDynatraceAction('pageview', 'Error_PUTFailure_Profile');
            return of(
              fromBillingActions.billAccountUpdateDeliveryPreferenceFail({
                billAccountNumber: payload.billAccountNumber,
                updatePreferenceCorrelationId: payload.updatePreferenceCorrelationId,
                error: error
              })
            );
          })
        )
      )
    );
  });

  deletePrefs$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountDeletePreference),
      map(action => action.payload),
      switchMap(payload =>
        this.billingService.deletePreferences(payload.deletePreference).pipe(
          mergeMap(() => {
            // Translate the response object into the correct naming conventions (see bill-account.model.ts for details)
            const successObject = <DeletePreferencesStoreModel>Object.assign(
              {},
              {
                billAccountNumber: payload.deletePreference.billAccountNumber,
                deleteCorrelationId: payload.deletePreference.deleteCorrelationId
              }
            );

            const confirmationPayload: PaymentConfirmationModel = {
              category: 'billAccount',
              subCategory: 'remove',
              nickName: payload.deletePreference.billAccountNumber,
              policyDescription: payload.deletePreference.policyDescription
            };

            return [
              fromRouterActions.Go({ path: [BillingPaymentPaths.CONFIRMATION_PATH] }),
              fromBillingActions.billAccountLoadPreference(payload.loadPreference),
              PaymentConfirmationActions.fromPaymentConfirmationActions.PaymentConfirmationLoad(
                confirmationPayload
              ),
              fromBillingActions.billAccountDeletePreferenceSuccess(successObject)
            ];
          }),
          catchError(error => {
            this.analyticsFacade.trackPage(PcmAnalytics.unBAMDeleteFailure);
            return of(
              fromBillingActions.billAccountDeletePreferenceFail({
                billAccountNumber: payload.deletePreference.billAccountNumber,
                deleteCorrelationId: payload.deletePreference.deleteCorrelationId,
                error: error
              })
            );
          })
        )
      )
    );
  });

  loadDocuments$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountLoadDocuments),
      map(action => action.payload),
      mergeMap(payload =>
        this.billingService
          .getStatementHistory(payload.billAccountNumber, payload.billingSystem)
          .pipe(
            map((res: { billAccount: BillAccount }) => res.billAccount),
            map(undecorated => this.billingTransmuteService.decorateDocumentsCall(undecorated)),
            map(res => fromBillingActions.billAccountLoadDocumentsSuccess(res)),
            catchError(error =>
              of(
                fromBillingActions.billAccountLoadDocumentsFail({
                  billAccountNumber: payload.billAccountNumber,
                  status: error
                })
              )
            )
          )
      )
    );
  });

  silentRegistration$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountSilentRegistration),
      map(action => action.payload),
      concatLatestFrom(() => [
        this.store.select(billaccountsQuery.selectBillAccountEntities),
        this.store.select(PolicySelectors.selectActivePolicies)
      ]),
      mergeMap(([pendingRegistrations, billAccountEntities, activePolicyList]) => {
        const registrationActions = [];
        pendingRegistrations.forEach(item => {
          const apiPayload: RegisterBillAccModel =
            this.billingTransmuteService.buildRegistrationPayload(
              billAccountEntities[item.billAccountNumber],
              item.correlationId,
              activePolicyList
            );
          registrationActions.push(
            fromBillingActions.billAccountUpdateRegistrationSubmit(apiPayload)
          );
        });
        return registrationActions;
      })
    );
  });

  updateRegistration$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountUpdateRegistrationSubmit),
      map(action => action.payload),
      concatLatestFrom(() => this.store.select(billaccountsQuery.selectAllBillAccounts)),
      mergeMap(([payload, billAccounts]) =>
        this.billingService.updateRegistration(payload).pipe(
          mergeMap((noPartialFailure: boolean) => {
            const actionsArray = [];
            const loadPreferencePayload: RetrievePreferencePayload = {
              billAccountNumber: payload.billAccountNumber,
              associated: payload.registrationData.associated,
              billingMethod: '' // Billing method set below based on condition
            };

            if (noPartialFailure) {
              // Success case
              const confirmationPayload = {
                category: 'billAccount',
                subCategory: payload.isUnregister ? 'remove' : 'add',
                billAccountNumber: payload.billAccountNumber,
                nickName: payload.registrationData.accountName,
                riskDescription: payload.registrationData.riskDescription,
                policyDescription: payload.registrationData.policyDescription,
                emailAddress: payload.registrationData.emailAddress,
                associated: payload.registrationData.associated
              };

              const loadAction =
                PaymentConfirmationActions.fromPaymentConfirmationActions.PaymentConfirmationLoad(
                  confirmationPayload
                );

              const registrationSuccessPayload: UpdateRegistrationStoreModel = {
                billAccountNumber: payload.billAccountNumber,
                correlationId: payload.correlationId,
                billingMethod: ONLINE_BILLING,
                registrationDate: dateFormat(new Date(), 'YYYY-MM-DDZ')
              };

              let extraActions = [];

              // TODO make this more explicit for each resulting call we're making for clarity
              const actionpayload = {
                billAccountNumber: payload.billAccountNumber,
                policyNumber: payload.registrationData.policyNumber,
                associated: payload.registrationData.associated,
                billingSystem: payload.registrationData.billingSystem // TODO: This value is never set, always caused the documents call to fail.
              };
              const preferencesPayload: PopulatePreferenceModel = {
                billAccountNumber: payload.billAccountNumber.replace(/-/g, ''),
                billingPreferences: {
                  accountNickName: payload.registrationData.accountName,
                  dueDateReminder: payload.registrationData.reminder,
                  deliveryMethod: 'ELECTRONIC'
                }
              };

              if (payload.isUnregister) {
                /*
              For an associated bill accounts, we also need to re-load the bill account details, documents and clean up the store
              For unassociated accounts, remove all of this bill account's data from the store
              For both types, refresh the payment actions, scheduled payments and autopay rules.
            */
                /*
              TODO - scheduled payments and autopay rules can probably get a
              "remove rule" action that just truncates from the store for this specific bill account.
            */
                if (payload.registrationData.associated) {
                  loadPreferencePayload.billingMethod = PAPER;
                  extraActions = [
                    fromBillingActions.billAccountLoadDocuments(actionpayload),
                    fromBillingActions.billAccountUnregisterAssociated(actionpayload),
                    ScheduledPaymentActions.fromScheduledPaymentsActions.scheduledPaymentsLoad()
                  ];
                } else {
                  loadPreferencePayload.billingMethod = PAPER;
                  extraActions = [
                    fromBillingActions.billAccountUnregisterUnAssociated({
                      bilLAccountNumber: actionpayload.billAccountNumber
                    }),
                    ScheduledPaymentActions.fromScheduledPaymentsActions.scheduledPaymentsLoad()
                  ];
                }
                extraActions.push(
                  AutoPayActions.getAllAutomaticPayments({
                    billAccountNumbers: billAccounts.map(ba => ba.billAccountNumber),
                    correlationId: this.utilService.generateId()
                  })
                );
                extraActions.push(
                  PaymentMethodActions.getPaymentMethods({
                    correlationId: this.utilService.generateId()
                  })
                );
              } else {
                // If this is a simple registration fire actions to load the details, preferences and documents
                loadPreferencePayload.billingMethod = ONLINE_BILLING;
                extraActions = [
                  fromBillingActions.billAccountPopulatePreference(preferencesPayload),
                  fromBillingActions.billAccountLoadDocuments(actionpayload)
                ];
              }
              // Dispatch with conditional payload(refer to comment Update billingMethod in store)
              const successAction = fromBillingActions.billAccountUpdateRegistrationSuccess(
                registrationSuccessPayload
              );
              // set up actions array and push to it instead
              actionsArray.push(loadAction, successAction, ...extraActions);
            } else {
              // Partial fail case
              if (payload.isUnregister) {
                this.analyticsFacade.trackPage(PcmAnalytics.unregisterPartialFailure);
              } else {
                this.analyticsFacade.trackPage(PcmAnalytics.registrationPartialFailure);
              }
              loadPreferencePayload.billingMethod = PAPER;
              const updateRegistrationPartialFailAction =
                fromBillingActions.billAccountUpdateRegistrationPartialFail({
                  billAccountNumber: payload.billAccountNumber,
                  correlationId: payload.correlationId
                });
              actionsArray.push(updateRegistrationPartialFailAction);
            }
            /**
             * AS: If we are trying to unregisted an unassociated account and there are no failures
             * we should not be making the preference call.
             */
            if (noPartialFailure && payload.isUnregister && !payload.registrationData.associated) {
              return actionsArray;
            }
            actionsArray.push(fromBillingActions.billAccountLoadPreference(loadPreferencePayload));
            return actionsArray;
          }),
          catchError(error => {
            // If it's an unregister or unregister, fire the full failure analytics
            if (payload.isUnregister) {
              this.analyticsFacade.trackPage(PcmAnalytics.unregisterFailure);
            } else {
              this.analyticsFacade.trackPage(PcmAnalytics.registrationFailure);
            }

            const code = _get(error, 'messages[0].code');
            let errorText = '';
            if (code && code.toString() === INVALID_IMPERSONATION_STATUS_CODE) {
              errorText = INVALID_IMPERSONATION_STATUS_CODE;
            } else {
              errorText = !navigator.onLine ? 'Please check your internet connection.' : error;
            }
            return [
              fromBillingActions.billAccountUpdateRegistrationFail({
                billAccountNumber: payload.billAccountNumber,
                correlationId: payload.correlationId,
                error: errorText
              }),
              PendingRegistrationsActions.fromPendingRegistrationsActions.PendingRegistrationsFail(
                payload.billAccountNumber
              )
            ];
          })
        )
      )
    );
  });

  /**
   * @author: Abhishek Singh
   * @description: This effect listens to the success of the update registration and subsequent
   * detail success then zips them together.
   * The purpose of the effect is to make sure we have bill account details loaded after a registration update
   * before we dispatch an action to transform the policylist associated with the bill account.
   */

  transFormBillAccountPostRegistration$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountLoadDetailSuccess),
      map(action => action.payload),
      concatLatestFrom(() => [
        this.store.select(PolicySelectors.selectPolicyRisks),
        this.store.select(PolicySelectors.selectPolicyEntities)
      ]),
      mergeMap(([billAccountObj, policyList, policyEntities]) =>
        of(
          fromBillingActions.billAccountTransformPolicyList({
            policyList: policyList,
            billAccount: billAccountObj,
            policyEntities: policyEntities
          })
        )
      )
    );
  });

  /**
   * @author: Abhishek Singh
   * @description: This effect listens to the success of the policy summary actions and then
   * maps to inner observable of billaccount loadDetailAction, so that every time the billing
   * details have been loaded we dispatch an action to transform the policy list associated with
   * that billaccount.
   * The purpose of the effect is to make sure we have policies and bill account details loaded
   * before we dispatch an action to transform the policylist associated with the bill account.
   */

  policyCompleteAction$ = createEffect(() => {
    return this.action$.pipe(
      ofType(PoliciesLoadComplete),
      concatLatestFrom(() => [
        this.store.select(billaccountsQuery.selectBillAccounts),
        this.store.select(PolicySelectors.selectPolicyRisks),
        this.store.select(PolicySelectors.selectPolicyEntities)
      ]),
      mergeMap(([, billingList = [], policyList, policyEntities]) => {
        return from(billingList).pipe(
          mergeMap(billaccount =>
            of(
              fromBillingActions.billAccountTransformPolicyList({
                policyList: policyList,
                billAccount: <BillAccount>billaccount,
                policyEntities: policyEntities
              })
            )
          )
        );
      }),
      catchError(error => {
        return of(fromBillingActions.billAccountTransformPolicyListFail(error));
      })
    );
  });

  /**
   * @author: Abhishek Singh
   * @description: This effect listens to the transform policy action and call a function which tranforms
   * the policy list associated with a bill account to a policy list which has risk description in detail.
   */

  transformPolicyListAction$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountTransformPolicyList),
      map(action => action.payload),
      mergeMap(
        (response: {
          policyList: RiskModel[];
          billAccount: BillAccount;
          policyEntities: Dictionary<Policy>;
        }) =>
          this.billingTransmuteService
            .transformBillingPolicyList(response.billAccount.policyList, response.policyList)
            .pipe(
              switchMap(transformedList => {
                const transFormedIconPayload = this.billingTransmuteService.getIcon(
                  response.billAccount,
                  response.policyList,
                  response.policyEntities
                );
                return of(
                  fromBillingActions.billAccountTransformPolicyListSuccess({
                    billAccount: Object.assign({}, response.billAccount, {
                      policyList: transformedList,
                      icon: _get(transFormedIconPayload, 'icon', ''),
                      policyTypeDisplayName: _get(
                        transFormedIconPayload,
                        'policyTypeDisplayName',
                        ''
                      )
                    })
                  })
                );
              }),
              catchError(error => of(fromBillingActions.billAccountTransformPolicyListFail(error)))
            )
      ),
      catchError(error => of(fromBillingActions.billAccountTransformPolicyListFail(error)))
    );
  });

  /**
   * @author: Abhishek Singh
   * @description: This effect listens to the transform policy action and checks if the incoming
   * billaccount has a policy which the user is not a named insured on. In that case we call payyorobo
   * to provide the policy description.
   */

  loadUnassociatedPolicyListAction$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountTransformPolicyList),
      map(action => action.payload),
      mergeMap(
        (response: {
          policyList: RiskModel[];
          billAccount: BillAccount;
          policyEntities: Dictionary<Policy>;
        }) => {
          const actionArr = [];
          const policyForPayorObo = [];
          _get(response, 'billAccount.policyList', []).forEach(policyFromBillObj => {
            const policyExist = response.policyList.find(policyItem =>
              _includes(policyFromBillObj.policyNumber, policyItem.policyNumber)
            );

            if (!policyExist) {
              policyForPayorObo.push(policyFromBillObj);
            }
          });

          if (policyForPayorObo.length > 0) {
            actionArr.push(
              fromBillingActions.billAccountLoadUnAssociatedRiskDetail({
                billAccountNumber: _get(response, 'billAccount.billAccountNumber'),
                policyList: policyForPayorObo
              })
            );
          }
          return actionArr;
        }
      )
    );
  });

  UpdateReadOnlyStatus$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountLoadPreferenceSuccess, billAccountDeletePreferenceFail),
      map(action => action.payload),
      mergeMap((payload: DecoratedPreference) => [
        fromBillingActions.billAccountUpdateReadOnlyStatus(payload)
      ])
    );
  });

  updateAmd$ = createEffect(() => {
    return this.action$.pipe(
      ofType(billAccountReloadMinimumDue),
      map(action => action.payload),
      mergeMap((payload: string[]) => {
        // Build an array of observables of GET /billaccounts/{ID}
        const requestArray = payload.map((billAccountNumber: string) => {
          // Call GET /billaccounts/{ID}
          return this.billingService.getBillAccount({ billAccountNumber: billAccountNumber }).pipe(
            map((response: { billingAccount: BillAccount }) => {
              // We only want the minimum due value to be updated so map down to that
              return {
                billAccountNumber: String(response.billingAccount.billAccountNumber),
                minimumAmountDue: Number(response.billingAccount.minimumAmountDue)
              };
            })
          );
        });
        // Fork Join the observables - we only want to emit if all are successful
        return forkJoin(requestArray);
      }),
      mergeMap((updates: UpdateAmdModel[]) => {
        const updateActions = [];
        if (updates && updates.length) {
          updates.forEach((update: UpdateAmdModel) => {
            if (_has(update, 'billAccountNumber') && _has(update, 'minimumAmountDue')) {
              updateActions.push(fromBillingActions.billAccountReloadMinimumDueSuccess(update));
            }
          });
        }
        return updateActions;
      })
    );
  });
  constructor(
    private store: Store,
    private billingService: BillAccountsService,
    private billingUtilService: BillingUtilService,
    private analyticsFacade: AnalyticsFacade,
    private dynatraceService: DynatraceService,
    private billingTransmuteService: BillAccountTransmuteService,
    private action$: Actions,
    private utilService: UtilService
  ) {}
}
