<form [formGroup]="codeForm" (ngSubmit)="codeForm.valid && verifyCode()">
  <div class="row">
    <div class="column twelve md-six">
      <ds-form-control-container
        [labelText]="'Confirmation Code'"
        [control]="codeForm.controls['codeInput']"
      >
        <input
          data-cy="resetPasswordCodeEntryInput"
          type="tel"
          class="fill"
          dsDigitMask
          id="pincode"
          formControlName="codeInput"
          maxlength="5"
        />
        <ds-form-control-message
          [control]="codeForm.controls['codeInput']"
        ></ds-form-control-message>
        <small class="error-600-text margin-bottom-5">
          @if (codeNotFound) {
            <span
              data-cy="invalidTokenErrorText"
              content
              [contentPath]="'enrollment.verifyPhoneInvalidText'"
            ></span>
          }
          @if (codeExpired) {
            <span content [contentPath]="'auth.resetOptionsCodeExpired'"></span>
          }
        </small>
      </ds-form-control-container>
    </div>
    <div class="column twelve md-four">
      <div class="md-pad-left-10 pad-top-10">
        <button
          data-cy="resetPasswordCodeEntrySubmitButton"
          ds-button
          type="submit"
          [disabled]="!codeForm.valid"
          content
          [contentPath]="'shared.nextBtn'"
        ></button>
      </div>
    </div>
  </div>
</form>
