import { createSelector } from '@ngrx/store';

import { selectState, State } from '..';
import * as fromFinancialAccount from './financial-account.reducer';

export const selectFinancialAccountState = createSelector(
  selectState,
  (state: State) => state.financialAccountState
);

export const selectPCIAuthorizationToken = createSelector(
  selectFinancialAccountState,
  (state: fromFinancialAccount.State) => state.pciToken
);

export const selectFinancialInstitution = createSelector(
  selectFinancialAccountState,
  (state: fromFinancialAccount.State) => state.financialInstitution
);

export const selectLoading = createSelector(
  selectFinancialAccountState,
  (state: fromFinancialAccount.State) => state.loading
);

export const selectHasFinancialInstitutionError = createSelector(
  selectFinancialAccountState,
  (state: fromFinancialAccount.State) => state.financialInstitutionError || state.paymentMethodError
);

export const selectHasPCITokenError = createSelector(
  selectFinancialAccountState,
  (state: fromFinancialAccount.State) => state.pciTokenError
);

export const selectCorrelationId = createSelector(
  selectFinancialAccountState,
  (state: fromFinancialAccount.State) => state.correlationId
);
