<div class="flex footer-container pad-top-30 md-pad-right-30 md-pad-left-30 neutral-600-bg">
  <div
    class="column twelve pad-bottom-20 md-four text-center flex items-center justify-center"
    id="left"
  >
    <div>
      <a
        dsPartnerContent
        [brandHref]="'selectLandingPageUrl'"
        target="amfam"
        [attr.title]="footerLogo?.altText ? footerLogo.altText : 'logo'"
      >
        <div
          class="logo-container column text-center fixed-200"
          [style.backgroundImage]="'url(' + footerLogo?.logoURL + ')'"
          [style.height.px]="footerLogo?.height"
        ></div>
      </a>
    </div>
  </div>
  @if (showContactInfo) {
    <div class="vertical-rule none md-block"></div>
  }
  @if (showContactInfo) {
    <div class="contact-info column twelve md-four" id="center">
      <div class="row flex wrap justify-center items-center">
        <div>
          <span class="icon-call"></span>
          <a
            dsPartnerContent
            [brandContent]="'selectPrettyCusCareNumber'"
            [brandHref]="'selectCusCareNumberFullHref'"
            class="inline-block"
          ></a>
          <p
            dsPartnerContent
            [brandContent]="'selectCusCareHoursWeekday'"
            class="neutral-200-text"
          ></p>
          <p dsPartnerContent [brandContent]="'selectCusCareHoursFriday'" class="neutral-200-text"></p>
          <p
            dsPartnerContent
            [brandContent]="'selectCusCareHoursWeekend'"
            class="neutral-200-text"
          ></p>
        </div>
      </div>
    </div>
  }
  @if (showContactInfo) {
    <div class="vertical-rule none lg-block"></div>
  }
  <hr
    aria-hidden="true"
    class="column twelve none md-margin-bottom lg-margin-bottom-20 md-block lg-none"
  />
  @if (showContactInfo) {
    <div class="column twelve lg-four" id="right">
      <div class="row flex wrap pad-top-30 md-pad-top-0 justify-center">
        <a dsPartnerContent [brandHref]="'selectCusCareEmailHref'" class="inline-block">
          <span class="icon-email pad-right-5"></span>
          <span dsPartnerContent [brandContent]="'selectCusCareEmail'"></span>
        </a>
      </div>
    </div>
  }
</div>

<div
  class="row pad-top-10 pad-bottom-30 md-pad-top-5 md-pad-bottom-0 md-pad-right-30 md-pad-left-30 neutral-600-bg"
>
  <hr aria-hidden="true" class="md-margin-top-10 lg-margin-top-20" />
  <ul class="column twelve pad-top-10 md-pad-top-0 md-flex wrap justify-space-between text-center">
    <li class="pad-bottom md-pad-right">
      <a [dsRouterLink]="['/company-information']">Company Information </a>
    </li>
    <li class="pad-bottom md-pad-right">
      <a [dsRouterLink]="['/privacy-security-policy']">Privacy &amp; Security Policy</a>
    </li>
    <li class="pad-bottom md-pad-right"><a [dsRouterLink]="['/legal-notice']">Legal Notice</a></li>
    @if (showSiteMap) {
      <li class="pad-bottom md-pad-right">
        <a [dsRouterLink]="['/sitemap']">Sitemap </a>
      </li>
    }
    <li class="md-none pad-bottom-0"><hr aria-hidden="true" /></li>
    <li class="md-text-left">
      <small>
        &copy;{{ currentYear }} American Family Mutual Insurance Company, S.I. All rights
        reserved.</small
      >
    </li>
  </ul>
</div>
