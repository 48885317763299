import { Action, createReducer, on } from '@ngrx/store';

import { ApiStatus } from '@amfam/shared/models';

import * as AddVehicleBindActions from './add-vehicle-bind.actions';

export interface AddVehicleBindState {
  ansError: ApiStatus | null;
  bindError?: ApiStatus | null;
  success: ApiStatus | null;
  loading: boolean;
  cancelNavigationPath: string;
}

export const initialState: AddVehicleBindState = {
  ansError: null,
  bindError: null,
  success: null,
  loading: false,
  cancelNavigationPath: '/policies'
};

const userReducer = createReducer(
  initialState,

  on(
    AddVehicleBindActions.sendAgentEmailNotification,
    (state): AddVehicleBindState => ({
      ...state,
      loading: true
    })
  ),

  on(
    AddVehicleBindActions.sendPartnerEmailNotification,
    (state): AddVehicleBindState => ({
      ...state,
      loading: true
    })
  ),

  on(
    AddVehicleBindActions.sendAgentANSNotificationError,
    (state, payload): AddVehicleBindState => ({
      ...state,
      loading: false,
      success: null,
      ansError: payload.payload
    })
  ),

  on(
    AddVehicleBindActions.bindAddVehicleQuoteError,
    (state, payload): AddVehicleBindState => ({
      ...state,
      loading: false,
      success: null,
      bindError: payload.payload
    })
  ),

  // Todo add licensed agent error - Abhi will help :)

  on(AddVehicleBindActions.bindAddVehicleQuoteReset, (): AddVehicleBindState => initialState)
);

export function reducer(state: AddVehicleBindState | undefined, action: Action) {
  return userReducer(state, action);
}
