import { Injectable } from '@angular/core';

import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { ConfigService } from '@amfam/shared/utility/shared-services';

@Injectable({ providedIn: 'root' })
export class OptimizelyGuard {
  constructor(
    private featureFlag: FeatureFlagService,
    private configService: ConfigService
  ) {}

  async canActivate(): Promise<boolean> {
    const key = this.configService.get('optimizelyKey');
    if (!key) return true;
    await this.featureFlag.initiateOptimizely(this.configService.get('optimizelyKey'));
    return this.featureFlag.optimizelyClient.onReady().then(() => true);
  }
}
