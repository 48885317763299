import { Routes } from '@angular/router';

import { DeleteMyAccountComponent } from '@amfam/profile/feature';

import { ProfileComponent } from './profile.component';

export const ProfileRoutes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      breadcrumb: 'Profile'
    }
  },
  {
    path: 'profile/delete-my-account',
    component: DeleteMyAccountComponent,
    data: {
      breadcrumb: 'Delete My Account'
    }
  }
];
