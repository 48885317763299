import {
  AddEditAutoPayPayload,
  AddMultipleAutoPayPayload,
  ApplyAutoPayDiscountPayload,
  AutomaticPayment,
  AutoPayActions,
  AutoPayAmounts,
  AutoPaySelectors
} from '@amfam/billing/auto-pay/data-access';
import { ReviewItemConfig } from '@amfam/billing/auto-pay/ui';
import { AutoPayUtilSelectors } from '@amfam/billing/auto-pay/util';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import {
  PaymentMethodActions,
  PaymentMethodSelectors,
  UpdateModeOfAuthorizationPayload
} from '@amfam/billing/payment-method/data-access';
import {
  PaymentMethod,
  PaymentMethodAccountType,
  PaymentMethodUtilService
} from '@amfam/billing/payment-method/util';
import { BillAccountTransmuteService } from '@amfam/billing/shared/util';
import { PolicySelectors } from '@amfam/policy/data-access';
import { Policy } from '@amfam/policy/models';
import { BillAccount, ONLINE_BILLING } from '@amfam/shared/models';
import { ArrayToList, PrettyBillingAcctNum, PrettyPaymentMethod } from '@amfam/shared/ui/pipes';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { UtilService } from '@amfam/shared/utility/shared-services';
import { DsModalService, LoadingSpinnerService, Option } from '@amfam/ui-kit';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { flatMap as _flatmap, get as _get, has as _has } from 'lodash';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as AutoPaySetupActions from '../+state/auto-pay-setup.actions';
import {
  AutoPayContext,
  AutoPaySettings,
  AutoPaySetup,
  AutoPaySteps
} from '../+state/auto-pay-setup.models';

@Injectable({ providedIn: 'root' })
export class AutoPayUtilService {
  constructor(
    private store: Store,
    private spinner: LoadingSpinnerService,
    private utilService: UtilService,
    private billAccountTransmuteService: BillAccountTransmuteService,
    private modalService: DsModalService,
    private paymentMethodUtil: PaymentMethodUtilService
  ) {}

  /**
   * Routes the user to the bill account selection screen if they have multiple eligible accounts
   *  - but skips and routes to the setup screen if they only have one
   * @param entryPoint The route the feature should return to after exiting
   * @param preselectedBillAccounts Any bill accounts that should be pre-selected on the bill account selection screen
   */
  routeToMultipleAutoPaySelection(
    entryPoint: string,
    preselectedBillAccounts: string[] = []
  ): void {
    this.store.dispatch(AutoPaySetupActions.setEntryPoint({ entryPoint }));
    this.store
      .select(AutoPayUtilSelectors.getBillAccountsEligibleForAutoPay)
      .pipe(take(1))
      .subscribe(billAccounts => {
        // take the user to autopay setup if they only have one eligible account
        if (billAccounts.length === 1) {
          this.addAutoPay(billAccounts[0].billAccountNumber, entryPoint);
        } else {
          if (preselectedBillAccounts.length > 0) {
            this.store.dispatch(
              AutoPaySetupActions.setPreselectedAccounts({
                billAccountNumbers: preselectedBillAccounts
              })
            );
          }
          this.store.dispatch(
            new fromRouterActions.Go({
              path: ['/billing/auto-pay/select-accounts']
            })
          );
        }
      });
  }

  /**
   * Automatically sets up a bill account for an autopay on the due date with the monthly minimum
   * @param billAccountNickName The bill account to set up autopay on
   * @param defaultPaymentMethodNickName The payment method to use
   */
  addAutoPayQuick(billAccountNickName: string, defaultPaymentMethodNickName: string): void {
    combineLatest([
      this.store.select(BillAccountsSelectors.getBillAccounts),
      this.store.select(PaymentMethodSelectors.getPaymentMethods)
    ])
      .pipe(take(1))
      .subscribe(([billAccounts, paymentMethods]) => {
        const selectedBillAccount: BillAccount = billAccounts.find(
          billAccount => billAccount.billingPreferences.accountNickName === billAccountNickName
        );
        const setup: AutoPaySetup[] = this.getDefaultSetups(
          AutoPayContext.ADD,
          [selectedBillAccount],
          null,
          paymentMethods,
          defaultPaymentMethodNickName
        );
        this.initAutoPaySetup(
          AutoPayContext.ADD,
          [selectedBillAccount],
          null,
          paymentMethods,
          defaultPaymentMethodNickName,
          false,
          false
        );
        this.saveAutoPay(AutoPayContext.ADD, setup);
      });
  }

  /**
   * Sets up the autopay flow for adding an autopay and routes the user
   * to the setup screen for a single bill account
   * @param billAccountNumber The bill account to add autopay to
   * @param entryPoint The route the feature should return to after exiting
   */
  addAutoPay(billAccountNumber: string, entryPoint: string): void {
    this.store.dispatch(AutoPaySetupActions.setEntryPoint({ entryPoint }));
    combineLatest([
      this.store.select(BillAccountsSelectors.getBillAccount(billAccountNumber)),
      this.store.select(PaymentMethodSelectors.getPaymentMethods)
    ])
      .pipe(take(1))
      .subscribe(([billAccount, paymentMethods]) => {
        if (billAccount.billingMethod !== ONLINE_BILLING) {
          this.registerBillAccountsBeforeAutoPaySetup([billAccount], paymentMethods);
        } else {
          this.initAutoPaySetup(
            AutoPayContext.ADD,
            [billAccount],
            null,
            paymentMethods,
            null,
            false,
            true
          );
        }
      });
  }

  /**
   * Sets up the autopay flow for editing an autopay and routes the user
   * to the setup screen for a single bill account
   * @param billAccountNumber The bill account to add autopay to
   * @param entryPoint The route the feature should return to after exiting
   */
  editAutoPay(billAccountNumber: string, entryPoint: string): void {
    this.store.dispatch(AutoPaySetupActions.setEntryPoint({ entryPoint }));
    combineLatest([
      this.store.select(BillAccountsSelectors.getBillAccount(billAccountNumber)),
      this.store.select(AutoPaySelectors.getAutomaticPayment(billAccountNumber)),
      this.store.select(PaymentMethodSelectors.getPaymentMethods)
    ])
      .pipe(take(1))
      .subscribe(([billAccount, automaticPayment, paymentMethods]) => {
        this.initAutoPaySetup(
          AutoPayContext.EDIT,
          [billAccount],
          automaticPayment,
          paymentMethods,
          null,
          false,
          true
        );
      });
  }

  /**
   * Sets up the autopay flow for adding multiple autopays and routes the user
   * to the first setup screen
   * @param billAccountNumbers The bill accounts to add autopay to
   * @param entryPoint The route the feature should return to after exiting
   */
  addMultipleAutoPay(billAccountNumbers: string[], entryPoint?: string): void {
    if (!!entryPoint) {
      this.store.dispatch(AutoPaySetupActions.setEntryPoint({ entryPoint }));
    }
    combineLatest([
      this.store.select(BillAccountsSelectors.getBillAccounts).pipe(
        take(1),
        map(billAccounts =>
          billAccounts.filter(billAccount =>
            billAccountNumbers.includes(billAccount.billAccountNumber)
          )
        )
      ),
      this.store.select(PaymentMethodSelectors.getPaymentMethods)
    ])
      .pipe(take(1))
      .subscribe(([billAccounts, paymentMethods]) => {
        const hasUnregisteredBillAccounts: boolean =
          billAccounts.filter(billAccount => billAccount.billingMethod !== ONLINE_BILLING).length >
          0;
        if (hasUnregisteredBillAccounts) {
          this.registerBillAccountsBeforeAutoPaySetup(billAccounts, paymentMethods);
        } else {
          this.initAutoPaySetup(
            AutoPayContext.ADD_MULTIPLE,
            billAccounts,
            null,
            paymentMethods,
            null,
            false,
            true
          );
        }
      });
  }

  /**
   * Registers unregistered bill accounts before initializing autopay setup
   * @param billAccounts All bill accounts that we want to set up for autopay - regardless of registration status
   * @param paymentMethods All payment methods
   */
  private registerBillAccountsBeforeAutoPaySetup(
    billAccounts: BillAccount[],
    paymentMethods: PaymentMethod[]
  ): void {
    this.spinner.start({
      blockActions: true
    });

    // get bill accounts that haven't been registered
    const unregisteredBillAccountNumbers: string[] = billAccounts
      .filter(billAccount => billAccount.billingMethod !== ONLINE_BILLING)
      .map(billAccount => billAccount.billAccountNumber);

    // get bill accounts that have already been registered
    const previouslyRegisteredBillAccounts: BillAccount[] = billAccounts.filter(
      billAccount => billAccount.billingMethod === ONLINE_BILLING
    );

    // register unregistered bill accounts
    this.billAccountTransmuteService
      .initiateSilentRegistration(unregisteredBillAccountNumbers)
      .pipe(take(1))
      .subscribe((successfullyRegisteredBillAccounts: BillAccount[]) => {
        this.spinner.stop();
        const registeredAccounts: BillAccount[] = previouslyRegisteredBillAccounts.concat(
          successfullyRegisteredBillAccounts
        );
        const registrationError: boolean =
          successfullyRegisteredBillAccounts.length < unregisteredBillAccountNumbers.length;

        // proceed to autopay setup if we have any accounts to set up
        if (registeredAccounts.length > 0) {
          this.initAutoPaySetup(
            AutoPayContext.ADD_MULTIPLE,
            registeredAccounts,
            null,
            paymentMethods,
            null,
            registrationError,
            true
          );
        } else {
          // otherwise route to error page
          this.modalService.open('silentRegistrationErrorModal');
        }
      });
  }

  /**
   * initializes up autopay-setup store and routes to first setup page
   * @param context The context - add, edit, or add multiple
   * @param billAccounts Bill Accounts we want to set up with autopay
   * @param defaultAutomaticPayment The autopay to default settings to if we are editing
   * @param paymentMethods All of the customers payment methods - needed for setting defaults
   * @param defaultPaymentMethodNickName Needed for quick add
   * @param registrationError If there was an error registering bill accounts - used to add banner to top
   * @param routeToSetup True if we want to route to the flow, false for quick add
   */
  private initAutoPaySetup(
    context: AutoPayContext,
    billAccounts: BillAccount[],
    defaultAutomaticPayment: AutomaticPayment,
    paymentMethods: PaymentMethod[],
    defaultPaymentMethodNickName: string,
    registrationError: boolean,
    routeToSetup: boolean
  ): void {
    const defaultSetups: AutoPaySetup[] = this.getDefaultSetups(
      context,
      billAccounts,
      defaultAutomaticPayment,
      paymentMethods,
      defaultPaymentMethodNickName
    );
    this.store.dispatch(
      AutoPaySetupActions.initAutoPaySetup({
        setups: defaultSetups,
        context,
        registrationError
      })
    );
    if (routeToSetup) {
      this.store.dispatch(AutoPaySetupActions.nextStep());
    }
  }

  /**
   * Generates default setups
   * @param context The context - add, edit, or add multiple
   * @param billAccounts Bill Accounts we want to set up with autopay
   * @param defaultAutomaticPayment The autopay to default settings to if we are editing
   * @param paymentMethods All of the customers payment methods - needed for setting defaults
   * @param defaultPaymentMethodNickName Needed for quick add
   * @returns Array of default setups
   */
  private getDefaultSetups(
    context: AutoPayContext,
    billAccounts: BillAccount[],
    defaultAutomaticPayment: AutomaticPayment,
    paymentMethods: PaymentMethod[],
    defaultPaymentMethodNickName: string
  ): AutoPaySetup[] {
    const defaultSettings: AutoPaySettings = this.getDefaultSettings(
      context,
      defaultAutomaticPayment,
      paymentMethods,
      defaultPaymentMethodNickName
    );
    const defaultSetups: AutoPaySetup[] = billAccounts.map((billAccount, index) => ({
      billAccount: {
        billAccountNumber: billAccount.billAccountNumber,
        riskDescription: new ArrayToList().transform(
          _flatmap(billAccount.policyList, policy => policy.riskDescriptionList)
        ),
        policyTypeIcon: billAccount.icon
      },
      active: index === 0,
      editing: false,
      autoPaySettings: defaultSettings
    }));

    return defaultSetups;
  }

  /**
   * Settings are a field in setups - this generates the autopay settings for a single setup
   * @param context The context - add, edit, or add multiple
   * @param defaultAutomaticPayment The autopay to default settings to if we are editing
   * @param paymentMethods All of the customers payment methods - needed for setting defaults
   * @param defaultPaymentMethodNickName Needed for quick add
   * @returns autopay settings for a single setup
   */
  private getDefaultSettings(
    context: AutoPayContext,
    defaultAutomaticPayment: AutomaticPayment,
    paymentMethods: PaymentMethod[],
    defaultPaymentMethodNickName: string
  ): AutoPaySettings {
    // get options
    const paymentMethodOptions: Option[] = this.getPaymentMethodOptions(paymentMethods, false);
    const paymentFrequencyOptions: Option[] = this.getPaymentFrequencyOptions(false, false);
    const paymentDateOptions: Option[] = this.getPaymentDateOptions();

    let defaultPaymentMethodOption: Option = defaultPaymentMethodNickName
      ? paymentMethodOptions.find(option => option.text === defaultPaymentMethodNickName)
      : paymentMethods.length
      ? paymentMethodOptions[0]
      : null;
    let defaultPaymentFrequencyOption: Option = paymentFrequencyOptions[0];
    let defaultPaymentDateOption: Option = paymentDateOptions[0];
    if (context === AutoPayContext.EDIT) {
      defaultPaymentMethodOption = paymentMethodOptions.find(
        option => option.text === _get(defaultAutomaticPayment, 'paymentAccount.nickName', '')
      );
      defaultPaymentFrequencyOption = paymentFrequencyOptions.find(
        option =>
          option.id ===
          _get(defaultAutomaticPayment, 'paymentAmount', AutoPayAmounts.MINDUE).toUpperCase()
      );
      defaultPaymentDateOption = paymentDateOptions.find(
        option => option.id === _get(defaultAutomaticPayment, 'daysBeforeDueDate', '0')
      );
    }

    return this.getAutoPaySettings(
      defaultPaymentMethodOption,
      defaultPaymentFrequencyOption,
      defaultPaymentDateOption
    );
  }

  /**
   * Saves an auto pay setup edit - do not call directly, use saveAutoPay()
   * @param setup the setup to be saved as an edit
   */
  private saveAutoPayEdit(setup: AutoPaySetup): void {
    this.spinner.start({
      blockActions: true
    });
    const correlationId: string = this.utilService.generateId();
    const billAccountNumber: string = setup.billAccount.billAccountNumber;
    const payload: AddEditAutoPayPayload = {
      autopayRule: {
        paymentAccountNickName: setup.autoPaySettings.paymentAccountNickName.value,
        daysBeforeDueDate: setup.autoPaySettings.daysBeforeDueDate.value,
        paymentAmount: AutoPayAmounts[setup.autoPaySettings.paymentAmount.value]
      },
      transactionId: this.utilService.generateId()
    };
    this.store.dispatch(
      AutoPayActions.editAutoPay({
        payload,
        billAccountNumber,
        correlationId
      })
    );
    this.store
      .select(AutoPaySelectors.getCorrelationId(billAccountNumber))
      .pipe(
        filter(id => id === correlationId),
        take(1)
      )
      .subscribe(() => {
        this.spinner.stop();
        this.applyAutoPayDiscount([setup]);
        this.routeToConfirmation();
      });
  }

  /**
   * Saves auto pay adds - do not call directly, use saveAutoPay()
   * @param setups the setups to be saved as an edit
   */
  private saveAutoPayAdd(setups: AutoPaySetup[]): void {
    this.spinner.start({
      blockActions: true
    });
    const correlationId: string = this.utilService.generateId();
    const payload: AddMultipleAutoPayPayload = {
      accounts: setups.map(setup => ({
        billingNumber: setup.billAccount.billAccountNumber,
        paymentAccountNickName: setup.autoPaySettings.paymentAccountNickName.value,
        daysBeforeDueDate: setup.autoPaySettings.daysBeforeDueDate.value,
        paymentAmount: setup.autoPaySettings.paymentAmount.value
      }))
    };
    this.store.dispatch(AutoPayActions.addMultipleAutoPay({ payload, correlationId }));
    this.store
      .select(AutoPaySelectors.getCorrelationIds)
      .pipe(
        filter(getCorrelationIds => getCorrelationIds.includes(correlationId)),
        take(1)
      )
      .subscribe(() => {
        this.spinner.stop();
        this.applyAutoPayDiscount(setups);
        this.routeToConfirmation();
      });
  }

  /**
   * Saves auto pay setups, and updates mode of auth used on payment methods
   * before if needed
   * @param context The context of the save - add, edit, or add multiple
   * @param setups The setups to be saved
   */
  saveAutoPay(context: AutoPayContext, setups: AutoPaySetup[]): void {
    this.store
      .select(PaymentMethodSelectors.getPaymentMethods)
      .pipe(take(1))
      .subscribe(paymentMethods => {
        const paymentMethodsToUpdateModeOfAuthorization: PaymentMethod[] =
          this.getPaymentMethodsToBeUpdated(paymentMethods, setups);
        if (paymentMethodsToUpdateModeOfAuthorization.length > 0) {
          this.updateModeOfAuthorizationBeforeSaving(
            context,
            setups,
            paymentMethodsToUpdateModeOfAuthorization
          );
        } else {
          if (context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE) {
            this.saveAutoPayAdd(setups);
          } else if (context === AutoPayContext.EDIT) {
            this.saveAutoPayEdit(setups[0]);
          }
        }
      });
  }
  /**
   * Applies auto pay discount by calling rpa service - we do not care about response
   * @param setups the setups that were just saved
   */
  private applyAutoPayDiscount(setups: AutoPaySetup[]): void {
    combineLatest([
      this.store.select(PolicySelectors.getActivePolicies),
      this.store.select(userQuery.getCustomerId),
      this.store.select(BrandSelectors.getPartnerId),
      this.store.select(PaymentMethodSelectors.getPaymentMethods),
      this.store.select(AutoPaySelectors.getAutoPayNotifications)
    ])
      .pipe(take(1))
      .subscribe(([policies, customerId, partnerId, paymentMethods, notifications]) => {
        setups.forEach(setup => {
          const billAccountNumber: string = setup.billAccount.billAccountNumber;
          const hasError: boolean = notifications.find(
            notification => notification.id === billAccountNumber
          ).hasError;

          if (!hasError) {
            const paymentMethod: PaymentMethod = paymentMethods.find(
              paymentMethod =>
                paymentMethod.nickName === setup.autoPaySettings.paymentAccountNickName.value
            );
            const paymentMethodType: string = _get(
              paymentMethod,
              'achWithdrawal.accountType',
              'card'
            );
            const billAccountPolicies = policies
              .filter(policy => policy.billingAccountNumber === billAccountNumber)
              .map((policy: Policy) => ({
                policyNumber: policy.policyNumber,
                producerId: _get(policy, 'assignedProducer.producerIdNum', '')
              }));
            const payload: ApplyAutoPayDiscountPayload = {
              FormInstanceTypeAutoPay: {
                policies: billAccountPolicies,
                billAccount: billAccountNumber,
                paymentType: paymentMethodType,
                autoPayIndicator: true,
                effectiveDate: format(new Date(), 'YYYY-MM-DD'),
                customerId,
                partnerId,
                templateCode: 'AUTO_PAY_CHANGE'
              }
            };
            this.store.dispatch(
              AutoPayActions.applyAutoPayDiscount({
                payload
              })
            );
          }
        });
      });
  }

  /**
   * Returns a list of payment methods that need to have their mode of auth updated
   * before saving
   * @param paymentMethods all payment methods
   * @param setups autopay setups that need to be saved
   * @returns payment methods with incorrect mode of auth
   */
  private getPaymentMethodsToBeUpdated(
    paymentMethods: PaymentMethod[],
    setups: AutoPaySetup[]
  ): PaymentMethod[] {
    return paymentMethods.filter(paymentMethod => {
      const accountType: PaymentMethodAccountType =
        this.paymentMethodUtil.getPaymentMethodAccountType(paymentMethod);
      const isCheckingOrSavings: boolean =
        accountType === PaymentMethodAccountType.CHECKING ||
        accountType === PaymentMethodAccountType.SAVINGS;
      const isIncorrectModeOfAuth: boolean =
        paymentMethod.modeOfAuthorization !==
        this.paymentMethodUtil.getPaymentMethodModeOfAuthorization(accountType);
      const isUsedInAutoPay: boolean = setups
        .map(setup => setup.autoPaySettings.paymentAccountNickName.value)
        .includes(paymentMethod.nickName);
      return isCheckingOrSavings && isIncorrectModeOfAuth && isUsedInAutoPay;
    });
  }

  /**
   * Updates mode of auth on payment methods, then saves autopays
   * @param context The context of the save - add, edit, or add multiple
   * @param setups autopay setups that need to be saved
   * @param paymentMethods payment methods that need to have their mode up auth updated
   */
  private updateModeOfAuthorizationBeforeSaving(
    context: AutoPayContext,
    setups: AutoPaySetup[],
    paymentMethods: PaymentMethod[]
  ): void {
    const correlationId: string = this.utilService.generateId();
    var filteredPaymentMethods = paymentMethods.filter(function (x) {
      return x !== undefined;
    });
    const payload: UpdateModeOfAuthorizationPayload[] = filteredPaymentMethods.map(
      paymentMethod => {
        const accountType: PaymentMethodAccountType =
          this.paymentMethodUtil.getPaymentMethodAccountType(paymentMethod);
        return {
          paymentAccountId: paymentMethod?.paymentAccountId,
          requestBody: {
            paymentAccount: {
              nickName: paymentMethod.nickName,
              modeOfAuthorization:
                this.paymentMethodUtil.getPaymentMethodModeOfAuthorization(accountType)
            },
            lastUpdateTimestamp: paymentMethod.lastUpdateTimestamp
          }
        };
      }
    );

    // start request
    this.spinner.start({
      blockActions: true
    });
    this.store.dispatch(PaymentMethodActions.updateModeOfAuthorization({ payload, correlationId }));

    // on request completion
    this.store
      .select(PaymentMethodSelectors.getPaymentMethodState)
      .pipe(
        filter(state => state.correlationId === correlationId),
        take(1)
      )
      .subscribe(state => {
        if (state.hasError) {
          this.spinner.stop();
          this.routeToConfirmation();
        } else {
          if (context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE) {
            this.saveAutoPayAdd(setups);
          } else if (context === AutoPayContext.EDIT) {
            this.saveAutoPayEdit(setups[0]);
          }
        }
      });
  }

  /**
   * Routes to confirmation screen and sets step
   */
  private routeToConfirmation(): void {
    this.store.dispatch(AutoPaySetupActions.setStep({ step: AutoPaySteps.CONFIRMATION }));
    this.store.dispatch(
      new fromRouterActions.Go({ path: ['billing', 'auto-pay', 'confirmation'] })
    );
  }

  /**
   * Utility function - returns review item config for an autopay setup
   * @param setup The autopay setup
   * @param showEditButton If the review item should show the edit button
   * @returns review item config
   */
  getReviewItem(setup: AutoPaySetup, showEditButton: boolean): ReviewItemConfig {
    return {
      id: setup.billAccount.billAccountNumber,
      icon: setup.billAccount.policyTypeIcon,
      rightHeader: setup.autoPaySettings.paymentAmount.text,
      rightSubtext: [
        setup.autoPaySettings.daysBeforeDueDate.text,
        `Payment Method: ${setup.autoPaySettings.paymentAccountNickName.value}`,
        setup.autoPaySettings.paymentAccountNickName.text
      ],
      leftHeader: setup.billAccount.riskDescription,
      leftSubtext: [
        `Acct ${new PrettyBillingAcctNum().transform(setup.billAccount.billAccountNumber)}`
      ],
      showEditButton
    };
  }

  /**
   * gets an array of Options objects for payment methods and generates proper sub helptexts
   * @param paymentMethods All payment Methods
   * @param hasClassicPolicy If the user has a classic policy
   * @returns Payment Method Options array
   */
  getPaymentMethodOptions(paymentMethods: PaymentMethod[], hasClassicPolicy: boolean): Option[] {
    var filteredPaymentMethods = paymentMethods.filter(function (x) {
      return x !== undefined;
    });
    return filteredPaymentMethods.map((paymentMethod: PaymentMethod) => {
      const option: Option = {
        text: paymentMethod.nickName,
        helpText: [{ text: new PrettyPaymentMethod().transform(paymentMethod) }],
        id: paymentMethod?.paymentAccountId
      };
      if (_has(paymentMethod, 'achWithdrawal') && !hasClassicPolicy) {
        option.helpText.push({ text: 'AutoPay Discount' });
      }
      if (this.paymentMethodUtil.isPaymentMethodExpired(paymentMethod)) {
        option.helpText.push({ text: 'Expired', warning: true });
      }
      if (this.paymentMethodUtil.isPaymentMethodExpiringSoon(paymentMethod)) {
        option.helpText.push({ text: 'Expiring soon', warning: true });
      }
      return option;
    });
  }

  /**
   * gets an array of payment frequency options and generates proper sub helptexts
   * @param hasClassicPolicy If the user has a classic policy
   * @param displayFullPayDiscountMessage If the option should show a full pay discount message
   * @returns payment frequency options array
   */
  getPaymentFrequencyOptions(
    hasClassicPolicy: boolean,
    displayFullPayDiscountMessage: boolean
  ): Option[] {
    const paymentFrequencyOptions = [
      {
        text: 'Monthly',
        id: AutoPayAmounts.MINDUE
      },
      {
        text: 'All at once',
        helpText: [],
        id: AutoPayAmounts.FULLPAY
      }
    ];

    if (!hasClassicPolicy && displayFullPayDiscountMessage) {
      paymentFrequencyOptions[1].helpText.push({ text: 'Full Pay Discount' });
    }
    return paymentFrequencyOptions;
  }

  /**
   *  gets an array of payment date options and generates proper sub helptexts
   * @returns payment date options array
   */
  getPaymentDateOptions(): Option[] {
    const options: Option[] = [];
    for (let i = 0; i <= 20; i++) {
      const text =
        i === 0
          ? 'On the due Date'
          : i === 1
          ? i.toString() + ' day before due date'
          : i.toString() + ' days before due date';
      options.push({
        text,
        id: i.toString()
      });
    }
    return options;
  }

  /**
   * Utility function - converts Options for payment method, payment date, and payment frequency
   * into an AutoPaySettings object, which is used in an AutoPaySetup
   * @param paymentMethod payment method Option
   * @param paymentAmount payment frequency Option
   * @param daysBeforeDueDate payment date Option
   * @returns Options converted into AutoPaySettings
   */
  getAutoPaySettings(
    paymentMethod: Option,
    paymentAmount: Option,
    daysBeforeDueDate: Option
  ): AutoPaySettings {
    return {
      paymentMethodId: _get(paymentMethod, 'id', ''),
      paymentAccountNickName: {
        value: _get(paymentMethod, 'text', ''),
        text: _get(paymentMethod, 'helpText[0].text', '')
      },
      paymentAmount: {
        value: <string>paymentAmount.id,
        text: paymentAmount.text
      },
      daysBeforeDueDate: {
        value: <string>daysBeforeDueDate.id,
        text: daysBeforeDueDate.text
      }
    };
  }
}
