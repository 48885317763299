import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[isRendered]'
})
export class IsRenderedDirective implements AfterViewInit {
  @Output() iAmReady = new EventEmitter<boolean>();

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (this.el.nativeElement) {
      setTimeout(() => {
        this.iAmReady.emit(true);
      });
    }
  }
}
