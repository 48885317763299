import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProfileActions, profileQuery } from '@amfam/profile/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ProfileAnalytics } from '@amfam/shared/models';
import { ValidationService } from '@amfam/ui-kit';

import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'ds-edit-userid',
  templateUrl: './edit-userid.component.html'
})
export class EditUseridComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  showForm = false;
  form: UntypedFormGroup;
  userId: AbstractControl;
  userIdAvailable = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private analyticsFacade: AnalyticsFacade,
    private authService: AuthService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.buildForm();

    // Subscribe to status of userid update
    this.store
      .select(profileQuery.selectProfileSecurityInfoStatus)
      .pipe(takeUntil(this.stop$))
      .subscribe(status => {
        // return if type is not userId
        if (status.editSecurityInfoMethodType !== 'userId') {
          return;
        }
        if (!status.loading && !status.editSecurityInfoError) {
          // User ID was successfully changed: Reset form and send analytics
          this.authService.setRememberMe(false);
          this.analyticsFacade.trackButtonClick(ProfileAnalytics.editUserIdSuccess);
          this.resetForm();
        }
      });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      userId: [
        '',
        [
          Validators.required,
          ValidationService.alphanumericCheck,
          ValidationService.userNamePasswordLengthValidator
        ]
      ]
    });
    this.userId = this.form.controls['userId'];
  }

  private resetForm() {
    this.showForm = false;
    this.userId.setValue('');
    this.userId.markAsUntouched();
  }

  submit() {
    // Start spinner
    this.store.dispatch(ProfileActions.MakeRequestAction());
    this.analyticsFacade.trackButtonClick(ProfileAnalytics.editUserIdSave);

    // Dispatch the request to change user id
    const data = {
      userId: this.userId.value
    };
    this.store.dispatch(
      ProfileActions.ChangeSecurityInfoAction({
        data,
        methodType: 'userId'
      })
    );
  }

  toggleEditUserIdForm() {
    if (!this.showForm) {
      // If form isn't already open, send analytics
      this.analyticsFacade.trackButtonClick(ProfileAnalytics.editUserIdOpen);
    }
    this.showForm = !this.showForm;
  }

  getUserIdAvailability(isAvailable: boolean) {
    this.userIdAvailable = isAvailable;
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
