import { createAction, props } from '@ngrx/store';

import {
  GetNewVehicleFeaturesRequestModel,
  GetNewVehicleFeaturesResponseModel
} from '../../models/vehicle-features.model';

// Agent Email Notifications
export const getNewVehicleFeatures = createAction(
  '[Add Vehicle] Get New Vehicle Features',
  props<{ payload: GetNewVehicleFeaturesRequestModel }>()
);

export const getNewVehicleFeaturesSuccess = createAction(
  '[Add Vehicle] Get New Vehicle Features Success',
  props<{ payload: GetNewVehicleFeaturesResponseModel }>()
);
export const getNewVehicleFeaturesError = createAction(
  '[Add Vehicle] Get New Vehicle Features Error',
  props<{ payload: GetNewVehicleFeaturesResponseModel }>()
);

export const fromAddVehicleFeaturesActions = {
  getNewVehicleFeatures,
  getNewVehicleFeaturesSuccess,
  getNewVehicleFeaturesError
};
