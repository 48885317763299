<div class="main row">
  <!-- New Code Start -->
  <div class="row">
    <section class="column twelve lg-push-two lg-eight">
      <!-- Icon & Headline Start -->
      <div class="confirmation-title">
        <span class="icon {{ classStyle }}"></span>
        <div>
          <h2
            data-cy="confirmationScreenHeader"
            class="heading-main confirmation-title-text"
            [innerHTML]="pageHeadingText"
          ></h2>
        </div>
      </div>
      <!-- Icon & Headline End -->

      <!-- Content Start -->
      <div class="margin-top-30">
        <p>
          {{ pageInfoText }}
        </p>

        <!-- Bill & Policy Details Start -->
        <div class="row pad-top-10">
          <!-- Bill Lists Start -->
          <section>
            <!-- updatedPreferenceBillAccounts Start -->
            @for (acc of updatedPreferenceBillAccounts; track acc) {
              <div class="column twelve list confirmation-status">
                <span class="margin-right-10 icon success-400-text icon-complete-circle"></span>
                <span
                  ><span content [contentPath]="'profile.communicationPreferencesBillsFor'"></span>
                  {{
                    acc.billingPreferences?.accountNickName
                      ? acc.billingPreferences?.accountNickName
                      : acc.billAccountNumber
                  }}</span
                >
              </div>
            }
            <!-- updatedPreferenceBillAccounts End -->

            <!-- failedPreferenceBillAccounts Start -->
            @for (acc of failedPreferenceBillAccounts; track acc) {
              <div class="column twelve list confirmation-status">
                <span class="margin-right-10 {{ billingLineItemClassStyle }}"></span>
                <span
                  ><span content [contentPath]="'profile.communicationPreferencesBillsFor'"></span>
                  {{
                    acc.billingPreferences?.accountNickName
                      ? acc.billingPreferences?.accountNickName
                      : acc.billAccountNumber
                  }}</span
                >
              </div>
            }
            <!-- failedPreferenceBillAccounts End -->
          </section>
          <!-- Bill Lists End -->

          <!-- Policy Lists Start -->
          <section>
            @if (isPolicyEnrolledNow) {
              <div class="column twelve list confirmation-status">
                <span class="margin-right-10 {{ policyLineItemClassStyle }}"></span>
                <div>
                  <div
                    content
                    [contentPath]="'profile.communicationPreferencesPolicyDocuments'"
                  ></div>
                  <span
                    class="caption"
                    content
                    [contentPath]="'profile.communicationPreferencesInsuranceCardsDocs'"
                  ></span>
                </div>
              </div>
            }
            @if (isPolicyHasErrors) {
              <div class="column twelve list confirmation-status">
                <span class="margin-right-10 {{ policyLineItemClassStyle }}"></span>
                <div>
                  <div
                    content
                    [contentPath]="'profile.communicationPreferencesPolicyDocuments'"
                  ></div>
                  <span
                    class="caption"
                    content
                    [contentPath]="'profile.communicationPreferencesInsuranceCardsDocs'"
                  ></span>
                </div>
              </div>
            }
          </section>
          <!-- Policy Lists End -->
        </div>
        <!-- Bill & Policy Details End -->

        <!-- List Description Text -->
        <p class="pad-vertical-20" [innerHTML]="pageActionText"></p>

        <hr />
      </div>
      <!-- Content End -->

      <!-- CTA Action Start -->
      <div class="flex row justify-flex-end">
        <call-to-action
          [primaryButtonName]="primaryButtonLabel"
          [secondaryButtonName]="secondaryButtonLabel"
          [tertiaryButtonName]="tertiaryButtonLabel"
          (primaryEvent)="primaryButtonEvent()"
          (secondaryEvent)="secondaryButtonEvent()"
          (tertiaryEvent)="tertiaryButtonEvent()"
        >
        </call-to-action>
      </div>
      <!-- CTA Action End -->
    </section>
  </div>
  <!-- New Code End -->
</div>
