import { PipesModule } from '@amfam/shared/ui/pipes';
import { BrandDirectivesModule } from '@amfam/shared/utility/brand';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddPaymentMethodQuicklinksComponent } from './add-payment-method-quicklinks/add-payment-method-quicklinks.component';
import { BillAccountPolicyRiskIconComponent } from './bill-account-policy-risk-icon/bill-account-policy-risk-icon.component';
import { BillingCardComponent } from './billing-card/billing-card.component';
import { BillingRegistrationFailureModalComponent } from './billing-registration-failure-modal/billing-registration-failure-modal.component';
import { BillingStatusPillComponent } from './billing-status-pill/billing-status-pill.component';
import { BillingTileComponent } from './billing-tile/billing-tile.component';
import { DuplicatePaymentConfirmationModalComponent } from './duplicate-payment-confirmation-modal/duplicate-payment-confirmation-modal.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { PaymentTermsAndConditionsComponent } from './payment-terms-and-conditions/payment-terms-and-conditions.component';
import { RomeBillPaymentFAQModalComponent } from './rome-bill-payment-faq-modal/rome-bill-payment-faq-modal.component';
import { SetupPaymentMethodComponent } from './setup-payment-method/setup-payment-method.component';
import { SilentRegistrationErrorModalComponent } from './silent-registration-error-modal/silent-registration-error-modal.component';
@NgModule({
  imports: [CommonModule, PipesModule, UiKitModule, SharedServicesModule, BrandDirectivesModule],
  declarations: [
    BillingRegistrationFailureModalComponent,
    SilentRegistrationErrorModalComponent,
    PaymentTermsAndConditionsComponent,
    SetupPaymentMethodComponent,
    AddPaymentMethodQuicklinksComponent,
    BillingStatusPillComponent,
    BillingTileComponent,
    BillingCardComponent,
    BillAccountPolicyRiskIconComponent,
    PaymentMethodComponent,
    RomeBillPaymentFAQModalComponent,
    DuplicatePaymentConfirmationModalComponent
  ],
  exports: [
    BillingRegistrationFailureModalComponent,
    SilentRegistrationErrorModalComponent,
    PaymentTermsAndConditionsComponent,
    SetupPaymentMethodComponent,
    AddPaymentMethodQuicklinksComponent,
    BillingStatusPillComponent,
    BillingTileComponent,
    BillingCardComponent,
    BillAccountPolicyRiskIconComponent,
    PaymentMethodComponent,
    RomeBillPaymentFAQModalComponent,
    DuplicatePaymentConfirmationModalComponent
  ]
})
export class BillingSharedUiModule {}
