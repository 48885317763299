import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PolicySummary, UmbrellaPolicy } from '@amfam/policy/models';
import { ConfigService } from '@amfam/shared/utility/shared-services';

import { TypedWorker } from './typed-worker';

@Injectable({
  providedIn: 'root'
})
export class PlumbrellaService implements TypedWorker {
  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {}

  public getEndpoint() {
    return this.config.get('productApi') + 'plumbrellapolicies/';
  }

  public getPolicy(url: string, policySummary: PolicySummary): Observable<UmbrellaPolicy> {
    return this.http.get(url).pipe(map((data: unknown) => this.loadModel(data, policySummary)));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public loadModel(json: any, policySummary: PolicySummary): UmbrellaPolicy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let policy: any = json;
    if (json.policy) {
      policy = json.policy;
    }
    return UmbrellaPolicy.fromJson({ ...policy, sourceSystem: policySummary.sourcePath });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getContactName(contactReferenceKey: unknown, policy: any) {
    let name = '';

    for (let i = 0; i < policy.Contacts.length; i++) {
      if (policy.Contacts[i].ContactReferenceKey === contactReferenceKey) {
        name = policy.Contacts[i].name;
        break;
      }
    }

    return name;
  }
}
