<footer [ngClass]="{ 'grey-background': newBillingDesign }">
  @if (lastLogin) {
    <ds-last-login [lastLogin]="lastLogin" id="lastLogin"></ds-last-login>
  }
  <ds-footer-afi
    *dsPartnerAFI
    [chatEnabled]="chatEnabled"
    [feedbackEnabled]="feedbackEnabled"
    [showContactInfo]="showContactInfo"
  ></ds-footer-afi>
  <ds-footer-partner
    *dsPartnerConnect
    [showContactInfo]="showContactInfo"
    [footerLogo]="partnerFooterLogo"
  ></ds-footer-partner>
</footer>
