import { EventAnalyticParams } from '@amfam/shared/analytics/src/lib/models/analytics.model';
import { ButtonAnalytic, PageAnalytic } from '@amfam/shared/utility/shared-services';

export class DeliveryPreferences {
  static readonly PAPER = 'paper';
  static readonly EMAIL = 'email';
  static readonly BOTH = 'both';
}

export class ProfileAnalytics {
  // Click analytic for opening Edit User Id
  public static readonly editUserIdOpen: ButtonAnalytic = {
    link: 'ProfileUserIDEdit'
  };

  // Click analytic for submitting User ID change
  public static readonly editUserIdSave: ButtonAnalytic = {
    link: 'ProfileUserIDSave'
  };

  // Analytic for Edit User Id success
  public static readonly editUserIdSuccess: ButtonAnalytic = {
    link: 'ProfileUserIDSuccess'
  };

  // Analytic for Delete MyAccount Button
  public static readonly deleteMyAccount: EventAnalyticParams = {
    event: 'delete_account'
  };

  // Analytic for Delete MyAccount confirmation
  public static readonly deleteMyAccountConfirmationEvent: EventAnalyticParams = {
    event: 'delete_account_complete'
  };

  // Analytic for Delete MyAccount account locked
  public static readonly deleteMyAccountAccountLocked: EventAnalyticParams = {
    event: 'delete_account_account_locked'
  };

  // Analytic for Delete MyAccount account Invalid password
  public static readonly deleteMyAccountInvalidPassword: EventAnalyticParams = {
    event: 'delete_account_invalid_password'
  };

  // Analytic for Delete MyAccount account failure
  public static readonly deleteMyAccountFailure: EventAnalyticParams = {
    event: 'delete_account_failure'
  };

  public static readonly updateContactInfo: PageAnalytic = {
    pageName: 'MyAccount:Profile:ConfirmYourContactInformation',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Profile',
    subCategory2: 'Contact Information',
    subCategory3: ''
  };
  public static readonly profileMultipleEmailErrorAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:MultipleEmailError',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: '',
    subCategory3: ''
  };

  public static readonly deleteMyAccountPageAnalytics: PageAnalytic = {
    pageName: 'MyAccount:Profile:DeleteAccount',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'Delete MyAccount',
    subCategory3: ''
  };

  public static readonly deleteMyAccountConfirmationPageAnalytics: PageAnalytic = {
    pageName: 'MyAccount:Profile:DeleteAccount:ConfirmDelete',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'Delete MyAccount Confirmation',
    subCategory3: ''
  };
}

export class PcmAnalytics {
  // Click analytic for Go Paperless
  public static readonly clickGoPaperless: ButtonAnalytic = {
    link: 'PaymentConfirmationGoPaperless'
  };

  // Try again analytic for Go Paperless
  public static readonly clickGoPaperlessTryAgain: ButtonAnalytic = {
    link: 'PaymentConfirmationTryAgain'
  };

  // Try again analytic for UnBam
  public static readonly stopManagingBillAccount: ButtonAnalytic = {
    link: 'StopManagingBillingAccount'
  };

  // Click analytic for Save button
  public static readonly communicationPreferencesSave: ButtonAnalytic = {
    link: 'CommunicationPreferencesSave'
  };

  // Analytic for Discount modal
  public static readonly discountModalSave: ButtonAnalytic = {
    link: 'MyAccount:Admin:Profile:PaperToEmail:Yes'
  };

  // Analytic for lose Discount modal
  public static readonly loseDiscountModalSave: ButtonAnalytic = {
    link: 'MyAccount:Admin:Profile:EmailToPaper:Yes'
  };

  //Analytic for close warning modal
  public static readonly closeWarningModal: ButtonAnalytic = {
    link: 'MyAccount:Admin:Profile:EmailToPaper:No'
  };

  //Analytic for close discount modal
  public static readonly closeDiscountModal: ButtonAnalytic = {
    link: 'MyAccount:Admin:Profile:PaperToEmail:No'
  };

  //Analytic for terms and conditions modal
  public static readonly paperToEmailModalAdminAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Admin:Profile:PaperToEmail',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Profile',
    subCategory3: ''
  };

  //Analytic for can not change settings modal
  public static readonly modalCannotChangeSettingsAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:CommunicationPreferences:ChangeSettings',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'CommunicationPreferences:ChangeSettings',
    subCategory3: ''
  };

  // Analytic for email to paper admin
  public static readonly emailToPaperAdminAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Admin:Profile:EmailToPaper',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Profile',
    subCategory3: ''
  };

  // Analytic for paper to email admin
  public static readonly paperToEmailAdminAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Admin:Profile:PaperToEmail',
    experience: '',
    primaryCategory: 'My Account',
    subCategory1: 'Admin',
    subCategory2: 'Profile',
    subCategory3: ''
  };

  // Analytic for switch all accounts to paperless button click on promote modal
  public static readonly modalPromoteDiscountAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:CommunicationPreferences:SwitchAllAccounts',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'CommunicationPreferences:SwitchAllAccounts',
    subCategory3: ''
  };

  // Ananlytic for billing and policies TC
  public static readonly combinedTCAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:CommunicationPreferences:CombinedTC',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'CommunicationPreferences:TC',
    subCategory3: ''
  };

  // Ananlytic for billing TC
  public static readonly billingTCAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:CommunicationPreferences:BillingTC',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'CommunicationPreferences:TC',
    subCategory3: ''
  };

  // Ananlytic for policies TC
  public static readonly policiesTCAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:CommunicationPreferences:PoliciesTC',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'CommunicationPreferences:TC',
    subCategory3: ''
  };

  // Analytic for switch to paper button click on warning modal
  public static readonly modalWarningAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:CommunicationPreferences:SwitchToPaper',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'CommunicationPreferences:SwitchToPaper',
    subCategory3: ''
  };

  // Analytic for failed save preferences.
  public static readonly saveErrorAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:CommunicationPreferences:SaveError',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'CommunicationPreferences:SaveError',
    subCategory3: ''
  };

  // Analytic for successfully saved preferences.
  public static readonly saveSuccessAnalytic: PageAnalytic = {
    pageName: 'MyAccount:Profile:CommunicationPreferences:GoPaperless:Complete',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Profile',
    subCategory2: 'CommunicationPreferences:Complete',
    subCategory3: ''
  };

  // Analytics for UNBAM partial error
  public static readonly unBAMPartialError: PageAnalytic = {
    pageName: 'MyAccount:StopManagingAccount:207Partial',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'StopManagingAccount',
    subCategory2: '207Partial',
    subCategory3: ''
  };

  // Analytic for bill account registration partial error (OLB created but preference didn’t get created)
  public static readonly registrationPartialFailure: PageAnalytic = {
    pageName: 'Log_RegisterPartialFailure',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Billing',
    subCategory2: '',
    subCategory3: ''
  };

  // Analytic for bill account registration full error
  public static readonly registrationFailure: PageAnalytic = {
    pageName: 'Error_RegisterFailure',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Billing',
    subCategory2: '',
    subCategory3: ''
  };

  // Analytic for UNBAM partial error (OLB dropped, preference not removed)
  public static readonly unregisterPartialFailure: PageAnalytic = {
    pageName: 'Error_UnregisterPartialFailure',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Billing',
    subCategory2: '',
    subCategory3: ''
  };

  // Analytic for UNBAM full error
  public static readonly unregisterFailure: PageAnalytic = {
    pageName: 'Error_UnregisterFailure',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Billing',
    subCategory2: '',
    subCategory3: ''
  };

  // Analytic for retrieving preferences failure
  public static readonly getPreferencesPartialFailure: PageAnalytic = {
    pageName: 'Warning_GETPartialFailure_UXFlowsDisabled',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Billing',
    subCategory2: '',
    subCategory3: ''
  };

  // Analytic for View Detail PUT failure
  public static readonly viewDetailPutFailure: PageAnalytic = {
    pageName: 'Error_PUTFailure_ViewDetail',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Billing',
    subCategory2: '',
    subCategory3: ''
  };

  // Analytic for Profile Communication Preferences PUT failure
  public static readonly profileCommunicationPreferencesPutFailure: PageAnalytic = {
    pageName: 'Error_PUTFailure_Profile',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Billing',
    subCategory2: '',
    subCategory3: ''
  };

  // Analytic for UNBAM unregistered bill account failure`
  public static readonly unBAMDeleteFailure: PageAnalytic = {
    pageName: 'Error_DELETEFailure',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Billing',
    subCategory2: '',
    subCategory3: ''
  };

  // Catch-all analytic for when other preference scenarios don't catch error
  public static readonly generalBillingPreferencesFailure: PageAnalytic = {
    pageName: 'Error_BillingPreferences',
    experience: '',
    primaryCategory: 'MyAccount',
    subCategory1: 'Billing',
    subCategory2: '',
    subCategory3: ''
  };
}
