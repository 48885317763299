import { File } from './file';

export class SignatureRequest {
  constructor(
    public readonly files = new Array<File>(),
    public readonly callbackUrl: string
  ) {}

  merge(other: SignatureRequest): SignatureRequest {
    if (!other) {
      return this;
    }

    const files = this.files.concat(other.files);
    return new SignatureRequest(files, this.callbackUrl);
  }
}
