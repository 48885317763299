@if (opportunityContent$ | async; as content) {
  <div>
    <div class="row margin-top-10 new-card">
      <div [innerHTML]="content?.heroCard"></div>
      <div class="pad-top-20">
        <div class="pad-horizontal-20">
          <h2 class="pad-bottom-10">
            <b>{{ content?.detailComponentHeader }}</b>
          </h2>
          <h4 class="pad-bottom-10">
            <b>{{ content?.selectionHeader }}</b>
          </h4>
        </div>
        <!-- TODO: cards go here -->
        <p class="pad-horizontal-20">{{ content?.selectionTypeHeading ?? '' }}</p>
        <div class="cards">
          @for (selectionType of opportunitySelectionTypes$ | async; track selectionType) {
            <ds-select-card
              [name]="selectionType.name"
              [imageUrl]="selectionType.thumbnail"
              [imageAltText]="selectionType.name + 'Image'"
              (cardSelected)="onCardSelected(selectionType.code)"
            ></ds-select-card>
          }
        </div>
      </div>
    </div>
  </div>
}
