import { Action } from '@ngrx/store';

import { AutoPolicy } from '@amfam/policy/models';

import { KydSmartphonePolicy } from './models';
import { KydSmartphoneSummary } from './models/kyd-smartphone-summary.model';

export const LOAD = '[kydSmartphoneSummary] Load';
export const LOAD_SUCCESS = '[kydSmartphoneSummary] Load Success';
export const LOAD_COMPLETE = '[kydSmartphoneSummary] Load Complete';
export const LOAD_FAIL = '[kydSmartphoneSummary] Load Fail';
export const REFRESH = '[kydSmartphoneSummary] Refresh';

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload?: AutoPolicy[]) {}
}

export class LoadCompleteAction implements Action {
  readonly type = LOAD_COMPLETE;

  constructor(public payload?: any) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: KydSmartphoneSummary[]) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;

  constructor(public payload?: any) {}
}

export class RefreshAction implements Action {
  readonly type = REFRESH;

  constructor(public payload?: string[]) {}
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | LoadCompleteAction
  | RefreshAction;
