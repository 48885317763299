/* eslint-disable @typescript-eslint/naming-convention */
import { AutoPaySelectors } from '@amfam/billing/auto-pay/data-access';
import { ReviewItemConfig } from '@amfam/billing/auto-pay/ui';
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { PaymentMethodSelectors } from '@amfam/billing/payment-method/data-access';
import { MaeFeatureActions, MaeFeatureSelectors } from '@amfam/mae/feature';
import { completeOverviewPaperlessEnrollment } from '@amfam/overview/paperless-enrollment/feature/src/lib/+state/overview-paperless-enrollment-feature-actions';
import { paperlessOnboardingEnrollmentFeatureQuery } from '@amfam/profile/communication-preferences/feature';
import { completeOnboardingPaperlessEnrollment } from '@amfam/profile/communication-preferences/feature/src/lib/+state/onboarding-paperless-enrollment-feature-actions';
import {
  AnalyticsFacade,
  AutomaticPaymentsAnalytics,
  AutomaticPaymentsAnalyticsAdmin
} from '@amfam/shared/analytics';
import { EventAnalyticParams } from '@amfam/shared/analytics/src/lib/models/analytics.model';
import { fromDigitalAccountActions } from '@amfam/shared/digital-account/data-access';
import { userQuery } from '@amfam/shared/user';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import {
  ApplicationService,
  Applications,
  CopyService
} from '@amfam/shared/utility/shared-services';
import { ConfirmationConfig, ConfirmationStatus, DockingBarService } from '@amfam/ui-kit';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  AutoPayContext,
  AutoPaySetup,
  AutoPaySubmitStatus
} from '../../+state/auto-pay-setup.models';
import * as AutoPaySetupSelectors from '../../+state/auto-pay-setup.selectors';
import { AutoPayUtilService } from '../../util/auto-pay-util.service';
@Component({
  selector: 'ds-auto-pay-confirmation-wrapper',
  templateUrl: './auto-pay-confirmation-wrapper.component.html',
  styleUrls: ['./auto-pay-confirmation-wrapper.component.scss']
})
export class AutoPayConfirmationWrapperComponent implements OnInit, OnDestroy {
  confirmationConfig$: Observable<ConfirmationConfig>;
  userEmail$: Observable<string>;

  successfulSetups$: Observable<AutoPaySetup[]>;
  failedSetups$: Observable<AutoPaySetup[]>;

  successfulReviewItems$: Observable<ReviewItemConfig[]>;
  failedReviewItems$: Observable<ReviewItemConfig[]>;
  submitStatus$: Observable<AutoPaySubmitStatus>;
  submitStatuses: typeof AutoPaySubmitStatus = AutoPaySubmitStatus;

  failureBodyText: Observable<string>;
  successBodyText: Observable<string>;
  partialFailureBodyTextOne: string;
  partialFailureBodyTextTwo: string;
  isAutopayEdit$: Observable<boolean>;
  isAdminApp = this.appService.isApp(Applications.MYACCOUNT_ADMIN);
  adminEnrollmentFlow: boolean;
  // myaccountEnrollmentFlow: boolean;
  isOnboardingFlow = false;
  isOveriviewFlow = false;

  constructor(
    private dockingBar: DockingBarService,
    private store: Store,
    private autoPayUtil: AutoPayUtilService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade,
    private appService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.failedSetups$ = this.getFailedSetups();
    this.successfulSetups$ = this.getSuccessfulSetups();
    this.submitStatus$ = this.getSubmitStatus();
    this.failedReviewItems$ = this.getFailedReviewItems();
    this.successfulReviewItems$ = this.getSuccessfulReviewItems();
    this.confirmationConfig$ = this.getConfirmationConfig();
    if (this.isAdminApp) {
      this.store
        .select(MaeFeatureSelectors.isEnrollmentWorkFlow)
        .pipe(take(1))
        .subscribe(enrollmentFlow => {
          this.adminEnrollmentFlow = enrollmentFlow;
          if (!this.adminEnrollmentFlow) {
            this.dockingBar.registerHeading('Set Up AutoPay');
          }
        });
    } else {
      this.dockingBar.registerHeading('Set Up AutoPay');
    }

    this.store
      .select(paperlessOnboardingEnrollmentFeatureQuery.selectIsOnboardingFlow)
      .pipe(take(1))
      .subscribe(enrllmentFlow => {
        this.isOnboardingFlow = enrllmentFlow;
      });

    this.getContent();
    this.dispatchAnalytics();
  }

  ngOnDestroy() {
    // Todo: check bill length and put below condition
    // Set the state of onboardingFlow and overviewFlow as false, if it was true
    if (this.isOnboardingFlow) {
      this.store.dispatch(completeOnboardingPaperlessEnrollment());
    } else if (this.isOveriviewFlow) {
      this.store.dispatch(completeOverviewPaperlessEnrollment());
    }
  }

  primaryButtonClick(): void {
    this.submitStatus$.pipe(take(1)).subscribe((submitStatus: AutoPaySubmitStatus) => {
      if (submitStatus === AutoPaySubmitStatus.SUCCESS) {
        this.done();
      } else {
        this.tryAgain();
      }
    });
  }

  tryAgain(): void {
    combineLatest([
      this.submitStatus$,
      this.failedSetups$,
      this.store.select(AutoPaySetupSelectors.getContext)
    ])
      .pipe(take(1))
      .subscribe(([submitStatus, failedSetups, context]) => {
        this.autoPayUtil.saveAutoPay(context, failedSetups);

        if (this.isAdminApp) {
          if (submitStatus === AutoPaySubmitStatus.FAILURE) {
            this.analyticsFacade.trackButtonClick(
              AutomaticPaymentsAnalyticsAdmin.buttonErrorTryAgain
            );
          } else if (submitStatus === AutoPaySubmitStatus.PARTIAL_FAILURE) {
            this.analyticsFacade.trackButtonClick(
              AutomaticPaymentsAnalyticsAdmin.buttonPartialErrorTryAgain
            );
          }
        } else {
          if (submitStatus === AutoPaySubmitStatus.FAILURE) {
            this.analyticsFacade.trackButtonClick(AutomaticPaymentsAnalytics.buttonErrorTryAgain);
          } else if (submitStatus === AutoPaySubmitStatus.PARTIAL_FAILURE) {
            this.analyticsFacade.trackButtonClick(
              AutomaticPaymentsAnalytics.buttonPartialErrorTryAgain
            );
          }
        }
      });
  }

  done(): void {
    if (this.adminEnrollmentFlow === true) {
      // dispatch an action to create digital account at end of enrollment flow
      this.store.dispatch(new fromDigitalAccountActions.GetDigitalAccountByCdhId());
      this.store.dispatch(MaeFeatureActions.launchOverview());
    } else if (this.isOnboardingFlow === true) {
      this.store.dispatch(
        // eslint-disable-next-line ngrx/prefer-action-creator-in-dispatch
        new fromRouterActions.Go({
          path: ['/overview']
        })
      );
    } else {
      this.store.dispatch(
        new fromRouterActions.Go({
          path: ['billing']
        })
      );
    }
  }
  private getFailedSetups(): Observable<AutoPaySetup[]> {
    return combineLatest([
      this.store.select(AutoPaySetupSelectors.getAllAutoPaySetups),
      this.store.select(AutoPaySelectors.getAutoPayNotifications),
      this.store.select(PaymentMethodSelectors.getLoading),
      this.store.select(PaymentMethodSelectors.hasError)
    ]).pipe(
      filter(
        ([setups, notifications, paymentMethodLoading, paymentMethodError]) =>
          !notifications.map(notification => notification.loading).includes(true) ||
          paymentMethodLoading
      ),
      map(([setups, notifications, paymentMethodLoading, paymentMethodError]) => {
        if (paymentMethodError) {
          // fail all setups if we have a payment method error (could not update all modes of auth)
          return setups;
        } else {
          return setups.filter(
            setup =>
              notifications.find(
                notification => notification.id === setup.billAccount.billAccountNumber
              ).hasError
          );
        }
      })
    );
  }

  private getSuccessfulSetups(): Observable<AutoPaySetup[]> {
    return combineLatest([
      this.store.select(AutoPaySetupSelectors.getAllAutoPaySetups),
      this.failedSetups$
    ]).pipe(
      map(([allSetups, failedSetups]) =>
        // get difference between all setups and failed ones
        allSetups.filter(
          setup =>
            !failedSetups
              .map(failedSetup => failedSetup.billAccount.billAccountNumber)
              .includes(setup.billAccount.billAccountNumber)
        )
      )
    );
  }

  private getFailedReviewItems(): Observable<ReviewItemConfig[]> {
    return this.failedSetups$.pipe(
      map(setups => setups.map(setup => this.autoPayUtil.getReviewItem(setup, false)))
    );
  }

  private getSuccessfulReviewItems(): Observable<ReviewItemConfig[]> {
    return this.successfulSetups$.pipe(
      map(setups => setups.map(setup => this.autoPayUtil.getReviewItem(setup, false)))
    );
  }

  private getSubmitStatus(): Observable<AutoPaySubmitStatus> {
    return combineLatest([this.failedSetups$, this.successfulSetups$]).pipe(
      map(([failedSetups, successfulSetup]) =>
        successfulSetup.length === 0
          ? AutoPaySubmitStatus.FAILURE
          : failedSetups.length === 0
          ? AutoPaySubmitStatus.SUCCESS
          : AutoPaySubmitStatus.PARTIAL_FAILURE
      )
    );
  }

  private getContent(): void {
    this.failureBodyText = this.store
      .select(AutoPaySetupSelectors.getContext)
      .pipe(
        map(context =>
          context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE
            ? this.copyService.getCopy('billing.autoPayRefactor.confirmationFailureBody')
            : this.copyService.getCopy(
                'billing.autoPayRefactor.deleteEditFailureConfirmationBodyText'
              )
        )
      );

    this.successBodyText = combineLatest([
      this.store.select(userQuery.getEmailAddress),
      this.store.select(AutoPaySetupSelectors.getContext)
    ]).pipe(
      map(([emailAddress, context]) => {
        const editBodyText = this.appService.isApp(Applications.MYACCOUNT_ADMIN)
          ? this.copyService.getCopy('billing.autoPayRefactor.adminToolEditConfirmationSuccessBody')
          : this.copyService.getCopy('billing.autoPayRefactor.editConfirmationSuccessBody');
        return context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE
          ? this.copyService.getCopy('billing.autoPayRefactor.confirmationSuccessBody', {
              emailAddress
            })
          : editBodyText;
      })
    );

    this.partialFailureBodyTextOne = this.copyService.getCopy(
      'billing.autoPayRefactor.confirmationPartialFailureBodyOne'
    );
    this.partialFailureBodyTextTwo = this.copyService.getCopy(
      'billing.autoPayRefactor.confirmationPartialFailureBodyTwo'
    );
  }

  private dispatchAnalytics(): void {
    combineLatest([
      this.submitStatus$,
      this.store.select(AutoPaySetupSelectors.getContext),
      this.successfulSetups$,
      this.store.select(BillAccountsSelectors.getAllBillAccounts),
      this.store.select(PaymentMethodSelectors.getPaymentMethodEntities)
    ])
      .pipe(take(1))
      .subscribe(([submitStatus, context, successfulSetups, allBillAccounts, paymentMethods]) => {
        switch (submitStatus) {
          case AutoPaySubmitStatus.SUCCESS:
            if (this.isAdminApp) {
              if (context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE) {
                this.analyticsFacade.trackAutoPayEnrollmentCountAndPageAndEvent(
                  AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsComplete,
                  AutomaticPaymentsAnalyticsAdmin.eventMultipleAutomaticPaymentsComplete,
                  {
                    accountsenrolled: successfulSetups.length.toString()
                  }
                );
              } else if (context === AutoPayContext.EDIT) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalyticsAdmin.MyAccountBillingPaymentsMultipleAutomaticPaymentsEditComplete
                );
              }
            } else {
              if (context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE) {
                this.analyticsFacade.trackAutoPayEnrollmentCountAndPageAndEvent(
                  AutomaticPaymentsAnalytics.pageMultipleAutomaticPaymentsComplete,
                  AutomaticPaymentsAnalytics.eventMultipleAutomaticPaymentsComplete,
                  {
                    accountsenrolled: successfulSetups.length.toString()
                  }
                );

                // GA analytics for autopay success
                successfulSetups.forEach(setup => {
                  // Using the billAccountNumber find the product type and pastDue
                  const billAccountDetails = allBillAccounts.find(
                    billAccount =>
                      billAccount.billAccountNumber === setup.billAccount.billAccountNumber
                  );
                  const productType = billAccountDetails.policyTypeDisplayName;
                  const pastDue = billAccountDetails.pastDue;

                  // Find the payment_term
                  const paymentTerm = setup.autoPaySettings.paymentAmount.value;
                  // Find the payment type
                  const paymentMethod = paymentMethods[setup.autoPaySettings.paymentMethodId];

                  let paymentType;
                  if (Object.keys(paymentMethod).includes('creditCard')) {
                    paymentType = 'debit-credit';
                  } else if (Object.keys(paymentMethod).includes('achWithdrawal')) {
                    paymentType = paymentMethod.achWithdrawal.accountType;
                  }

                  const autoPaySuccessEvent: EventAnalyticParams = {
                    event: 'autopay_signup_success',
                    product_line: productType,
                    payment_term: paymentTerm,
                    payment_type: paymentType,
                    past_due: pastDue
                  };

                  this.analyticsFacade.trackEvent(autoPaySuccessEvent);
                });
              } else if (context === AutoPayContext.EDIT) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalytics.MyAccountBillingPaymentsMultipleAutomaticPaymentsEditComplete
                );
              }
            }
            break;

          case AutoPaySubmitStatus.FAILURE:
            if (this.isAdminApp) {
              if (context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsError
                );
              } else if (context === AutoPayContext.EDIT) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalyticsAdmin.MyAccountBillingPaymentsMultipleAutomaticPaymentsEditError
                );
              }
            } else {
              if (context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalytics.pageMultipleAutomaticPaymentsError
                );
              } else if (context === AutoPayContext.EDIT) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalytics.MyAccountBillingPaymentsMultipleAutomaticPaymentsEditError
                );
              }
            }
            break;

          case AutoPaySubmitStatus.PARTIAL_FAILURE:
            if (this.isAdminApp) {
              if (context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalyticsAdmin.pageMultipleAutomaticPaymentsPartialError
                );
              } else if (context === AutoPayContext.EDIT) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalyticsAdmin.MyAccountBillingPaymentsMultipleAutomaticPaymentsEditError
                );
              }
            } else {
              if (context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalytics.pageMultipleAutomaticPaymentsPartialError
                );
              } else if (context === AutoPayContext.EDIT) {
                this.analyticsFacade.trackPage(
                  AutomaticPaymentsAnalytics.MyAccountBillingPaymentsMultipleAutomaticPaymentsEditError
                );
              }
            }
            break;

          default:
        }
      });
  }
  private getConfirmationConfig(): Observable<ConfirmationConfig> {
    const headingText = this.appService.isApp(Applications.MYACCOUNT_ADMIN)
      ? this.copyService.getCopy('billing.autoPayRefactor.adminToolEditConfirmationSuccessHeader')
      : this.copyService.getCopy('billing.autoPayRefactor.editConfirmationSuccessHeader');

    return combineLatest([
      this.submitStatus$,
      this.store.select(AutoPaySetupSelectors.getContext)
    ]).pipe(
      map(([submitStatus, context]) =>
        submitStatus === AutoPaySubmitStatus.SUCCESS
          ? {
              heading:
                context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE
                  ? this.copyService.getCopy('billing.autoPayRefactor.confirmationSuccessHeader')
                  : headingText,

              status: ConfirmationStatus.SUCCESS,
              ctaConfig: {
                primaryButtonName:
                  context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE
                    ? this.adminEnrollmentFlow === true
                      ? 'Go to Overview'
                      : this.isOnboardingFlow
                      ? 'Done'
                      : 'Go to Billing & Payments'
                    : 'Done'
              }
            }
          : {
              heading: this.copyService.getCopy(
                'billing.autoPayRefactor.confirmationFailureHeader'
              ),
              status: ConfirmationStatus.ERROR,
              ctaConfig: {
                primaryButtonName: 'Try Again',
                tertiaryButtonName:
                  context === AutoPayContext.ADD || context === AutoPayContext.ADD_MULTIPLE
                    ? this.adminEnrollmentFlow === true
                      ? 'Go to Overview'
                      : 'Go to Billing & Payments'
                    : 'Done'
              }
            }
      )
    );
  }
}
