<div data-cy="pastDueAlert" style="flex-grow: 1">
  @for (billAccount of billAccounts; track billAccount; let i = $index; let isLast = $last) {
    <a
      data-cy="multiplePastDueAccountNumber"
      (click)="routeToBillAccount.emit(billAccount.billAccountNumber)"
      >{{
        billAccount?.billingPreferences?.accountNickName
          ? billAccount?.billingPreferences?.accountNickName
          : (billAccount?.billAccountNumber | prettyBillingAcctNum)
      }}</a
    >
    @if (!isLast && billAccounts.length > 2 && i !== billAccounts.length - 2) {
      ,
    }
    @if (i === billAccounts.length - 2) {
      and
    }
  }
  @if (billAccounts.length === 1) {
    is past due. Please make a payment to avoid losing your coverage.
  } @else {
    are past due. Please make a payment to avoid losing your coverage.
  }
</div>
