import { Action } from '@ngrx/store';

import { ApiStatus } from '@amfam/shared/models';
import { OpportunityContent } from '@amfam/shared/utility/shared-services';

import { OpportunitiesFailureResponse, Opportunity, Recommendation } from '../models';
import { AgentRequestActionPayload } from '../models/agentNotification';
import { ANSFalure, ANSRequestActionPayload } from '../models/ansNotification';
import {
  CustomerNotificationFailure,
  CustomerNotificationSuccess
} from '../models/customerNotification';
import {
  OpportunitiesFeedback,
  OpportunitiesFeedbackFailure,
  OpportunitiesFeedbackSuccess
} from '../models/feedback';

export enum OpportunityActionTypes {
  // Actions to fetch the opportunities from service
  OPPORTUNITIES_LOAD = '[Opportunities] Load',
  OPPORTUNITIES_LOAD_SUCCESS = '[Opportunities] Load Success',
  OPPORTUNITIES_LOAD_FAIL = '[Opportunities] Load Fail',

  // Actions to fetch the content of the opportunities from sitecore
  OPPORTUNITIES_CONTENT_LOAD = '[Opportunities] Content Load',
  OPPORTUNITIES_CONTENT_LOAD_SUCCESS = '[Opportunities] Content Load Success',
  OPPORTUNITIES_CONTENT_LOAD_FAIL = '[Opportunities] Content Load Fail',

  // Actions to send the feedback of the user action to the service
  OPPORTUNITIES_FEEDBACK = '[Opportunities] Feedback',
  OPPORTUNITIES_FEEDBACK_SUCCESS = '[Opportunities] Feedback Success',
  OPPORTUNITIES_FEEDBACK_ONLY_SUCCESS = '[Opportunities] Feedback Only Success',
  OPPORTUNITIES_FEEDBACK_FAIL = '[Opportunities] Feedback Fail',
  OPPORTUNITIES_FEEDBACK_ONLY_FAIL = '[Opportunities] Feedback Only Fail',

  OPPORTUNITIES_NOTIFICATION = '[Opportunities] Notification',
  OPPORTUNITIES_NOTIFICATION_SUCCESS = '[Opportunities] Notification Success',
  OPPORTUNITIES_NOTIFICATION_FAIL = '[Opportunities] Notification Failure',

  OPPORTUNITIES_NOTIFY_AGENT = '[Opportunities] Notify Agent',
  OPPORTUNITIES_NOTIFY_AGENT_SUCCESS = '[Opportunities] Notify Agent Success',
  OPPORTUNITIES_NOTIFY_AGENT_FAIL = '[Opportunities] Notify Agent Failure',

  OPPORTUNITIES_NOTIFY_CUSTOMER = '[Opportunities] Notify Customer',
  OPPORTUNITIES_NOTIFY_CUSTOMER_SUCCESS = '[Opportunities] Notify Customer Success',
  OPPORTUNITIES_NOTIFY_CUSTOMER_FAIL = '[Opportunities] Notify Customer Failure',

  OPPORTUNITIES_ANS_NOTIFICATION = '[Opportunities] ANS Notification',
  OPPORTUNITIES_ANS_NOTIFICATION_SUCCESS = '[Opportunities] ANS Notification Success',
  OPPORTUNITIES_ANS_NOTIFICATION_FAIL = '[Opportunities] ANS Notification Failure',

  OPPORTUNITIES_TOASTER_NOTIFICATION = '[Opportunities] Toaster Notification',
  OPPORTUNITIES_TOASTER_NOTIFICATION_SUCCESS = '[Opportunities] Toaster Notification Success',
  OPPORTUNITIES_TOASTER_NOTIFICATION_FAIL = '[Opportunities] Toaster Notification Failure',

  OPPORTUNITIES_DETAIL_NAVIGATION = '[Opportunities] Detail Navigation',
  OPPORTUNITIES_DETAIL_NAVIGATION_FAIL = '[Opportunities] Detail Navigation Fail',

  OPPORTUNITIES_OPTIMIZELY_NOTIFICATION = '[Opportunities] Optimizely Notification',

  // If opportunity is disabled we prevent the loading of opportunities by triggering the
  // opportunities_disabled action.
  OPPORTUNITIES_DISABLED = '[Opportunities] Disabled',

  OPPORTUNITIES_DETAIL_NAVIGATION_FROM_SELECTION_PAGE = '[Opportunities] Detail Navigation From Selection Page'
}

export class OpportunitiesLoadAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_LOAD;
  constructor() {}
}

export class OpportunitiesLoadSuccessAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_LOAD_SUCCESS;
  constructor(public payload: Recommendation) {}
}

export class OpportunitiesLoadFailAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_LOAD_FAIL;
  constructor(public payload: OpportunitiesFailureResponse) {}
}

export class OpportunitiesContentLoadAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_CONTENT_LOAD;
  constructor() {}
}

export class OpportunitiesContentLoadSuccessAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_CONTENT_LOAD_SUCCESS;
  constructor(public payload: OpportunityContent[]) {}
}

export class OpportunitiesContentLoadFailAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_CONTENT_LOAD_FAIL;
  constructor(public payload: ApiStatus) {}
}

export class OpportunitiesFeedbackAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_FEEDBACK;
  constructor(
    public payload: OpportunitiesFeedback,
    public feedbackOnly = false
  ) {}
}

export class OpportunitiesFeedbackSuccessAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_SUCCESS;
  constructor(public payload: OpportunitiesFeedbackSuccess) {}
}

export class OpportunitiesFeedbackOnlySuccessAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_ONLY_SUCCESS;
  constructor(public payload: OpportunitiesFeedbackSuccess) {}
}

export class OpportunitiesFeedbackFailAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_FAIL;
  constructor(public payload: OpportunitiesFeedbackFailure) {}
}

export class OpportunitiesFeedbackOnlyFailAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_FEEDBACK_ONLY_FAIL;
  constructor(public payload: OpportunitiesFeedbackFailure) {}
}

export class OpportunitiesNotificationAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION;
  constructor(public payload: AgentRequestActionPayload) {}
}

export class OpportunitiesNotificationFailAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_NOTIFICATION_FAIL;
  constructor(public payload: any) {}
}

export class OpportunitiesNotifyAgentAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_NOTIFY_AGENT;
  constructor(public payload: AgentRequestActionPayload) {}
}

export class OpportunitiesNotifyAgentSuccessAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_NOTIFY_AGENT_SUCCESS;
  constructor(public payload: ANSRequestActionPayload) {}
}

export class OpportunitiesNotifyAgentFailAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_NOTIFY_AGENT_FAIL;
  constructor(public payload: ANSRequestActionPayload) {}
}

export class OpportunitiesNotifyCustomerAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_NOTIFY_CUSTOMER;
  constructor(public payload: OpportunitiesFeedback) {}
}

export class OpportunitiesNotifyCustomerSuccessAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_NOTIFY_CUSTOMER_SUCCESS;
  constructor(public payload: CustomerNotificationSuccess) {}
}

export class OpportunitiesNotifyCustomerFailAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_NOTIFY_CUSTOMER_FAIL;
  constructor(public payload: CustomerNotificationFailure) {}
}

export class OpportunitiesANSAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_ANS_NOTIFICATION;
  constructor(public payload: ANSRequestActionPayload) {}
}

export class OpportunitiesANSSuccessAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_ANS_NOTIFICATION_SUCCESS;
  constructor(public payload: ANSRequestActionPayload) {}
}

export class OpportunitiesANSFailAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_ANS_NOTIFICATION_FAIL;
  constructor(public payload: ANSFalure) {}
}

export class OpportunitiesDisabledAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_DISABLED;
  constructor() {}
}

export class OpportunitiesDetailPageNavigationAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_DETAIL_NAVIGATION;
  constructor(public payload: string) {}
}

export class OpportunitiesDetailPageNavigationFromSelectionPageAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_DETAIL_NAVIGATION_FROM_SELECTION_PAGE;
  constructor(public payload: { recommendationId: string; type: string }) {}
}

export class OpportunitiesDetailPageNavigationFailAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_DETAIL_NAVIGATION_FAIL;
  constructor() {}
}

export class OpportunitiesOptimizelyNotificationAction implements Action {
  readonly type = OpportunityActionTypes.OPPORTUNITIES_OPTIMIZELY_NOTIFICATION;
  constructor(public payload: { eventName: string; testingKey: string; variationName: string }) {}
}

export type OpportunityActionsUnion =
  | OpportunitiesLoadAction
  | OpportunitiesLoadSuccessAction
  | OpportunitiesLoadFailAction
  | OpportunitiesContentLoadAction
  | OpportunitiesContentLoadSuccessAction
  | OpportunitiesContentLoadFailAction
  | OpportunitiesFeedbackAction
  | OpportunitiesFeedbackSuccessAction
  | OpportunitiesFeedbackOnlySuccessAction
  | OpportunitiesFeedbackFailAction
  | OpportunitiesFeedbackOnlyFailAction
  | OpportunitiesNotificationAction
  | OpportunitiesNotificationFailAction
  | OpportunitiesNotifyAgentAction
  | OpportunitiesNotifyAgentSuccessAction
  | OpportunitiesNotifyAgentFailAction
  | OpportunitiesNotifyCustomerAction
  | OpportunitiesNotifyCustomerSuccessAction
  | OpportunitiesNotifyCustomerFailAction
  | OpportunitiesANSAction
  | OpportunitiesANSSuccessAction
  | OpportunitiesANSFailAction
  | OpportunitiesDisabledAction
  | OpportunitiesDetailPageNavigationAction
  | OpportunitiesOptimizelyNotificationAction;
