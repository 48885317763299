@if (showCounter) {
  <div class="row character-counter">
    <div class="column twelve" [ngClass]="{ 'error-600-text': errorClass }">
      <span>{{ usedLength }} / {{ maxLength }}</span>
      @if (suffix) {
        <span> {{ suffix }}</span>
      }
    </div>
  </div>
}
