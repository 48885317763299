<ul class="criteria-list">
  @for (item of criteriaItems; track item) {
    <li
      [ngClass]="{
        isValid: item.valid,
        hasError: item.error
      }"
      class="pseudo-icon flex items-center"
    >
      <span class="margin-top-5 margin-left-5">{{ item.text }}</span>
    </li>
  }
</ul>
