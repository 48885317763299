@if (notifications?.messagesArray?.length) {
  <div class="notification-container" (mouseover)="subOpen()" (mouseout)="subClose()">
    <div
      #notificationElem
      class="notification-link"
      [class.has-children]="notifications?.messagesArray?.length"
    >
      <a
        tabindex="0"
        role="menuitem"
        [href]=""
        [attr.aria-label]="notificationMessage"
        [attr.aria-expanded]="submenuExpanded"
        [attr.aria-haspopup]="submenuHasPopup ? 'menu' : 'false'"
        (focus)="subOpen()"
        (blur)="subClose()"
      >
        <span class="notification-icon icon-bell"></span>
        <div data-cy="headerNotificationNumber" class="notification-number">
          {{ notifications?.messagesArray?.length }}
        </div>
      </a>
    </div>
    <ul
      [@toggleSubmenu]="showNotificationMenuSub"
      class="notification-message"
      #subNotificationElem
      role="menu"
    >
      @for (message of notifications?.messagesArray; track message) {
        <li role="menuitem" class="message-item">
          <div class="flex items-center">
            <a
              class="item-link"
              role="menuitem"
              [style.width.px]="notifications?.width"
              [attr.aria-label]="message.linkText"
              [attr.data-cy]="message?.linkTestId"
              [dsRouterLink]="message.linkRoute"
              [queryParams]="queryParams"
              (focus)="subOpen()"
              (blur)="subClose()"
            >
              <span class="link-icon icon-documents"></span>
              <span class="link-text">{{ message.linkText }}</span>
            </a>
            <button
              class="dismiss-button"
              data-cy="notificationDismissItems"
              (focus)="subOpen()"
              (blur)="subClose()"
              (click)="onDismissClick(message)"
            >
              <span class="dismiss-icon icon-exit"
                ><span class="sr-only">Dismiss Message</span></span
              >
            </button>
          </div>
        </li>
      }
    </ul>
  </div>
}
