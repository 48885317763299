@if (!!config) {
  <div class="margin-bottom-20">
    <div class="row flex items-center heading">
      <span class="pseudo-icon heading-icon" [ngClass]="config.status"></span>
      <h1 data-cy="confirmationScreenHeader" class="heading-text">
        {{ config.heading }}
      </h1>
    </div>
    @if (!!config.subheading) {
      <h3 class="subheading-text">{{ config.subheading }}</h3>
    }
    @if (config.bodyText) {
      <p class="margin-top-20 margin-bottom-20">{{ config.bodyText }}</p>
    }
    @if (config.subheading2) {
      <p class="subheading margin-bottom-20">{{ config.subheading2 }}</p>
    }
  </div>
  <ng-content selector="content"></ng-content>
  @if (!!config.ctaConfig) {
    <call-to-action
      [primaryButtonName]="config.ctaConfig.primaryButtonName"
      [secondaryButtonName]="config.ctaConfig.secondaryButtonName"
      [disablePrimaryButton]="config.ctaConfig.disablePrimaryButton"
      [tertiaryButtonName]="config.ctaConfig.tertiaryButtonName"
      [displayBackButton]="config.ctaConfig.displayBackButton"
      (primaryEvent)="primaryEvent.emit()"
      (secondaryEvent)="secondaryEvent.emit()"
      (tertiaryEvent)="tertiaryEvent.emit()"
      (backEvent)="backEvent.emit()"
    >
    </call-to-action>
  }
}
