export interface LogoModel {
  logoURL: string;
  height: string;
  width?: string;
  altText?: string;
}

export class Logos {
  public static readonly AFI_HEADER: LogoModel = {
    logoURL: 'assets/images/american-family-insurance-logo.png',
    height: '44',
    width: '150',
    altText: 'American Family Insurance'
  };

  public static readonly AFI_FOOTER: LogoModel = {
    logoURL: '/assets/images/american-family-insurance-logo-tagline.png',
    height: '61',
    width: '150',
    altText: 'American Family Insurance'
  };

  public static readonly MIDVALE_HEADER: LogoModel = {
    logoURL: '/assets/images/midvale-logo.png',
    height: '56',
    width: '115',
    altText: 'Midvale'
  };

  public static readonly MIDVALE_FOOTER = {
    logoURL: '/assets/images/footer-logo-midvale.png',
    height: '56',
    width: '115',
    altText: 'Midvale'
  };

  public static readonly GENERAL_HEADER: LogoModel = {
    logoURL: '/assets/images/general-logo.png',
    height: '44',
    width: '150',
    altText: 'General'
  };

  public static readonly GENERAL_FOOTER = {
    logoURL: '/assets/images/general-logo-footer.png',
    height: '61',
    width: '150',
    altText: 'General'
  };

  public static readonly CONNECT_HEADER: LogoModel = {
    logoURL: '/assets/images/connect-logo-header.png',
    height: '56',
    width: '115',
    altText: 'Connect'
  };

  public static readonly CONNECT_FOOTER = {
    logoURL: '/assets/images/connect-logo-footer.png',
    height: '56',
    width: '115',
    altText: 'Connect'
  };
}
