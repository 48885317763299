import * as enrollUserActions from './enroll-user.actions';
import { EnrollUserModel, initialState } from './enroll-user.model';

export function reducer(state = initialState, action: enrollUserActions.Actions): EnrollUserModel {
  switch (action.type) {
    case enrollUserActions.PARTY_DETAILS:
      return Object.assign({}, state, {
        partyId: action.payload.partyId,
        partnerId: action.payload.partnerId,
        typeOfPartyCode: action.payload.typeOfPartyCode,
        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        greetingName: action.payload.greetingName,
        lastName: action.payload.lastName,
        socialSecurityNumberOnRecordIndicator: action.payload.socialSecurityNumberOnRecordIndicator,
        maskedPhones: action.payload.maskedPhones,
        maskedEmails: action.payload.maskedEmails,
        match: action.payload.match
      });

    case enrollUserActions.SUBMIT_IDENTIFICATION:
      return Object.assign({}, state, {
        lastName: action.payload.lastName,
        dob: action.payload.dob,
        zipcode: action.payload.zipcode,
        enrollmentType: action.payload.enrollmentType
      });

    case enrollUserActions.VERIFY_DONE:
      return Object.assign({}, state, {
        verificationType: action.payload.verificationType,
        partyId: action.payload.partyId,
        policyNumber: action.payload.policyNumber,
        emailAddressInput: action.payload.emailAddressInput
      });

    case enrollUserActions.SUBMIT_SIGNUP:
      return Object.assign({}, state, {
        emailAddressInput: action.payload.emailAddressInput,
        emailAddressSelect: action.payload.emailAddressSelect,
        userName: action.payload.userName,
        password: action.payload.password,
        enrollmentType: action.payload.enrollmentType
      });

    case enrollUserActions.SUBMIT_SECURITYQUESTIONS:
      return Object.assign({}, state, {
        questionOne: action.payload.questionOne,
        answerOne: action.payload.answerOne,
        questionTwo: action.payload.questionTwo,
        answerTwo: action.payload.answerTwo
      });

    case enrollUserActions.SUBMIT_BUSINESS:
      return Object.assign({}, state, {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        businessName: action.payload.businessName
      });

    case enrollUserActions.FORGOT_USERID_SHELL_ACCOUNT:
      return Object.assign({}, state, {
        shellAccountFoundForgotUserId: true
      });

    case enrollUserActions.SHELL_ACCOUNT_FOUND:
      return Object.assign({}, state, {
        shellAccountFound: true,
        emailAddressSelect: action.payload.emailAddressSelect
      });

    case enrollUserActions.RESET_USER:
      return initialState;

    default:
      return state;
  }
}

export const partyId = (state: EnrollUserModel) => state.partyId;

export const partnerId = (state: EnrollUserModel) => state.partnerId;

export const typeOfPartyCode = (state: EnrollUserModel) => state.typeOfPartyCode;

export const firstName = (state: EnrollUserModel) => state.firstName;

export const greetingName = (state: EnrollUserModel) => state.greetingName;

export const middleName = (state: EnrollUserModel) => state.middleName;

export const lastName = (state: EnrollUserModel) => state.lastName;

export const businessName = (state: EnrollUserModel) => state.businessName;

export const socialSecurityNumberOnRecordIndicator = (state: EnrollUserModel) =>
  state.socialSecurityNumberOnRecordIndicator;

export const maskedPhones = (state: EnrollUserModel) => state.maskedPhones;

export const maskedEmails = (state: EnrollUserModel) => state.maskedEmails;

export const match = (state: EnrollUserModel) => state.maskedEmails;

export const dob = (state: EnrollUserModel) => state.dob;

export const zipcode = (state: EnrollUserModel) => state.zipcode;

export const verificationType = (state: EnrollUserModel) => state.verificationType;

export const policyNumber = (state: EnrollUserModel) => state.policyNumber;

export const emailAddressInput = (state: EnrollUserModel) => state.emailAddressInput;

export const emailAddressSelect = (state: EnrollUserModel) => state.emailAddressSelect;

export const userName = (state: EnrollUserModel) => state.userName;

export const password = (state: EnrollUserModel) => state.password;

export const enrollmentType = (state: EnrollUserModel) => state.enrollmentType;

export const questionOne = (state: EnrollUserModel) => state.questionOne;

export const answerOne = (state: EnrollUserModel) => state.answerOne;

export const questionTwo = (state: EnrollUserModel) => state.questionTwo;

export const answerTwo = (state: EnrollUserModel) => state.answerTwo;

export const shellAccountFoundForgotUserId = (state: EnrollUserModel) =>
  state.shellAccountFoundForgotUserId;

export const shellAccountFound = (state: EnrollUserModel) => state.shellAccountFound;
