import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { get as _get } from 'lodash';

import { ApiStatus } from '@amfam/shared/models';

import {
  PendingRegistrationsActions,
  PendingRegistrationsActionTypes
} from './registration.actions';

export interface PendingRegistrationNotificationsState {
  billAccountNumber: string;
  error?: ApiStatus | null;
  success?: ApiStatus | null;
  loading: boolean;
}

export type NotificationState = EntityState<PendingRegistrationNotificationsState>;

export const adapter: EntityAdapter<PendingRegistrationNotificationsState> =
  createEntityAdapter<PendingRegistrationNotificationsState>({
    selectId: (pendingRegistrationNotificationsState: PendingRegistrationNotificationsState) =>
      pendingRegistrationNotificationsState.billAccountNumber,
    sortComparer: false
  });

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: PendingRegistrationsActions
): NotificationState {
  switch (action.type) {
    case PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_FAIL: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload'),
          loading: false
        },
        state
      );
    }

    case PendingRegistrationsActionTypes.PENDING_REGISTRATIONS_REMOVE_ALL: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
