import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { ClaimActionTypes, fromClaimActions, LoadClaimsSuccess } from '@amfam/claim/data-access';
import {
  ClaimGlassOnly,
  ClaimGlassOnlyAction,
  fromClaimGlassOnlyActions
} from '@amfam/claim/glass-only/data-access';
import { fromEligibleServicesActions } from '@amfam/claim/post-fnol/services-data-access';
import { GenericProductType } from '@amfam/policy/models';
import { ClaimsNavigationPaths } from '@amfam/shared/models';
import { userQuery, UserState } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { fromRouterActions, RouterActions } from '@amfam/shared/utility/navigation';
import { Applications, ApplicationService } from '@amfam/shared/utility/shared-services';

import { CreateDraftClaimModel } from '../models/create-draft-claim.model';
import { InitiateDynamicQuestionAdapterModel } from '../models/initiate-dynamic-question-adapter.model';
import { FnolQuestionTypes } from '../models/question-types';
import { FnolService, FnolTransmuteService } from '../services';
import { DynamicFnolAnalyticsService } from '../services/dynamic-fnol-analytics.service';
import { FnolAdapter } from '../services/fnol-adapter';
import { GlassDamagePayload } from '../util/glass-damage-payload';
import * as fnolActions from './fnol.actions';
import { FnolActions, FnolActionTypes } from './fnol.actions';
import { FnolAnalyticsModel, FnolModel } from './fnol.model';
import { FnolState } from './fnol.reducer';
import { fnolQuery } from './fnol.selectors';

@Injectable()
export class FnolEffects {
  loadDraftClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimActionTypes.LoadClaimsSuccess),
      map((action: LoadClaimsSuccess) => action.payload.fnols),
      withLatestFrom(this.userStore.select(userQuery.getPartyDetails)),
      mergeMap(([fnols, partyInfo]) => {
        const loadDraftClaimActions = new Array();
        let newFnols: FnolModel[] = [];
        newFnols = fnols
          .filter(
            fnol =>
              _get(fnol, 'isNewDigitalClaim', false) &&
              _get(fnol, 'reportedBy.firstName') === partyInfo.firstName &&
              _get(fnol, 'reportedBy.lastName') === partyInfo.lastName
          )
          .map(fnol => {
            return Object.assign({}, fnol, {
              lossDate: _get(fnol, 'lossDate').replace('T', ' ') // transmute loss date so that it will be consistnet
            });
          });

        newFnols = this.fnolTransmuteService.filterFnols(newFnols);

        loadDraftClaimActions.push(new fnolActions.LoadDraftClaims(newFnols));
        return loadDraftClaimActions;
      })
    )
  );

  resumeDraftClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.RESUME_DRAFT_CLAIM),
      map((action: fnolActions.ResumeDraftClaim) => action.payload),
      mergeMap(payload => {
        return this.fnolService
          .resumeDraftClaim(payload.claimNumber, _get(payload, 'risk.generalizedProductType', ''))
          .pipe(
            mergeMap((response: InitiateDynamicQuestionAdapterModel) => {
              return [
                fromRouterActions.Go({
                  path: [response.path]
                }),
                new fnolActions.ResumeDraftClaimSuccess(response)
              ];
            }),
            catchError(err => {
              const errorMessage = this.fnolAdapter.buildErrorMessage(err);
              return [
                new fnolActions.SendAnalytics({
                  claimNumber: _get(payload, 'claimNumber'),
                  lineOfBusiness: _get(payload, 'risk.generalizedProductType'),
                  isError: true
                }),
                new fnolActions.ResumeDraftClaimFail(errorMessage)
              ];
            })
          );
      })
    )
  );

  // If draft claim fails to load, we want to do a reload of claims to fetch already submitted draft

  resumeDraftClaimFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.RESUME_DRAFT_CLAIM_FAIL),
      map((action: fnolActions.ResumeDraftClaimFail) => action.payload),
      withLatestFrom(this.userStore.select(userQuery.getCustomerId)),
      switchMap(([action, customerId]) => {
        return of(new fromClaimActions.LoadClaims({ customerId: customerId }));
      })
    )
  );

  initiateDraftClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.INITIATE_DRAFT_CLAIM),
      map((action: fnolActions.InitiateDraftClaim) => action.payload),
      withLatestFrom(this.fnolStore.select(BrandSelectors.selectIsPartner)),
      switchMap(([payload, isPartner]) => {
        const createDraftClaimPayload = this.fnolTransmuteService.createDraftClaimPayload(
          payload.draftInfo,
          { lossLocationFormData: payload.locationInfo, isPartner: isPartner }
        );
        return of(new fnolActions.CreateDraftClaim(createDraftClaimPayload));
      })
    )
  );

  createDraftClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.CREATE_DRAFT_CLAIM),
      map((action: fnolActions.CreateDraftClaim) => action.payload),
      mergeMap((payload: CreateDraftClaimModel) =>
        this.fnolService.createDraftClaim(payload).pipe(
          mergeMap((response: { claimNumber: string }) => {
            let nextStepActions: Array<FnolActions> = new Array();

            // Return fail action when there is no claim number
            if (!_get(response, 'claimNumber')) {
              nextStepActions.push(new fnolActions.CreateDraftClaimFail());
            } else {
              const draftClaimNumber = response.claimNumber;

              // Create payload for converse API(Initiate dynamic questions).
              const initiateDynamicQuestionPayload =
                this.fnolTransmuteService.createInitiateDynamicQuestionPayload(
                  draftClaimNumber,
                  payload
                );

              nextStepActions = [
                new fnolActions.CreateDraftClaimSuccess(response.claimNumber),
                new fnolActions.InitiateDynamicQuestionsFlow(initiateDynamicQuestionPayload)
              ];
            }

            return nextStepActions;
          }),
          catchError(() => of(new fnolActions.CreateDraftClaimFail()))
        )
      )
    )
  );

  InitiateDynamicQuestionsFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.INITIATE_DYNAMIC_QUESTIONS_FLOW),
      map((action: fnolActions.InitiateDynamicQuestionsFlow) => action.payload),
      withLatestFrom(this.fnolStore.select(fnolQuery.getFnolRiskLineOfBusiness)),
      mergeMap(([payload, lineOfBusiness]) =>
        this.fnolService.initiateDynamicQuestionFlow(payload, lineOfBusiness).pipe(
          mergeMap((response: InitiateDynamicQuestionAdapterModel) => {
            return [
              new fnolActions.InitiateDynamicQuestionsFlowSuccess(response),
              new fnolActions.SendAnalytics({
                claimNumber: _get(payload, 'claimNumber'),
                lineOfBusiness: lineOfBusiness,
                isError: false,
                apiResponse: response
              }),
              fromRouterActions.Go({
                path: [response.path]
              })
            ];
          }),
          catchError(err => {
            const errorMessage = this.fnolAdapter.buildErrorMessage(err);
            return [
              new fnolActions.SendAnalytics({
                claimNumber: _get(payload, 'claimNumber'),
                lineOfBusiness: lineOfBusiness,
                isError: true
              }),
              new fnolActions.InitiateDynamicQuestionsFlowFail(errorMessage)
            ];
          })
        )
      )
    )
  );

  submitQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.SUBMIT_QUESTION),
      map((action: fnolActions.SubmitQuestion) => action.payload),
      withLatestFrom(
        this.fnolStore.select(fnolQuery.submitQuestionStoreData),
        this.fnolStore.select(fnolQuery.getFnolRiskLineOfBusiness)
      ),
      mergeMap(([selectedOption, payload, lineOfBusiness]) => {
        window.scrollTo(0, 0); // scroll to top of the page
        const submitQuestionPayload = this.fnolTransmuteService.createSubmitQuestionPayload(
          selectedOption,
          payload
        );
        return this.fnolService
          .submitQuestion(submitQuestionPayload, payload.draftClaimNumber, lineOfBusiness)
          .pipe(
            mergeMap((response: InitiateDynamicQuestionAdapterModel) => {
              const submitQuestionActions: Array<FnolActions | RouterActions> = [
                new fnolActions.SubmitQuestionSuccess(response),
                new fnolActions.SendAnalytics({
                  claimNumber: _get(payload, 'draftClaimNumber'),
                  lineOfBusiness: lineOfBusiness,
                  isError: false,
                  apiResponse: response
                })
              ];

              // If the user is making a glass or ers claim, we want to delete the draft
              // The other systems respectively will handle the claim
              if (
                response.questionType === FnolQuestionTypes.GLASS_END ||
                response.questionType === FnolQuestionTypes.ERS_END
              ) {
                submitQuestionActions.push(
                  new fnolActions.DeleteDraftClaim(_get(payload, 'draftClaimNumber'))
                );
              } else {
                submitQuestionActions.push(
                  fromRouterActions.Go({
                    path: [response.path]
                  })
                );
              }

              return submitQuestionActions;
            }),
            catchError(err => {
              const errorMessage = this.fnolAdapter.buildErrorMessage(err);

              return [
                new fnolActions.SendAnalytics({
                  claimNumber: _get(payload, 'draftClaimNumber'),
                  lineOfBusiness: lineOfBusiness,
                  isError: true
                }),
                new fnolActions.SubmitQuestionFail(errorMessage)
              ];
            })
          );
      })
    )
  );

  submitVehicleDamageQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.SUBMIT_VEHICLE_DAMAGE_QUESTION),
      map((action: fnolActions.SubmitVehicleDamageQuestion) => action.payload),
      withLatestFrom(this.fnolStore.select(fnolQuery.submitQuestionStoreData)),
      mergeMap(([payload, questionInfo]) => {
        window.scrollTo(0, 0); // scroll to top of the page
        const submitQuestionPayload = this.fnolTransmuteService.createVehicleDamageQuestionPayload(
          payload,
          questionInfo
        );
        return this.fnolService
          .submitQuestion(
            submitQuestionPayload,
            questionInfo.draftClaimNumber,
            GenericProductType.Auto
          )
          .pipe(
            mergeMap((response: InitiateDynamicQuestionAdapterModel) => {
              return [
                new fnolActions.SubmitQuestionSuccess(response),
                new fnolActions.SendAnalytics({
                  claimNumber: _get(questionInfo, 'draftClaimNumber'),
                  lineOfBusiness: GenericProductType.Auto,
                  isError: false,
                  apiResponse: response
                }),
                fromRouterActions.Go({
                  path: [response.path]
                })
              ];
            }),
            catchError(err => {
              const errorMessage = this.fnolAdapter.buildErrorMessage(err);

              return [
                new fnolActions.SendAnalytics({
                  claimNumber: _get(questionInfo, 'draftClaimNumber'),
                  lineOfBusiness: GenericProductType.Auto,
                  isError: true
                }),
                new fnolActions.SubmitQuestionFail(errorMessage)
              ];
            })
          );
      })
    )
  );

  submitThirdPartyContactFormQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.SUBMIT_THIRD_PARTY_CONTACT_FORM_QUESTION),
      map((action: fnolActions.SubmitThirdPartyContactFormQuestion) => action.payload),
      withLatestFrom(this.fnolStore.select(fnolQuery.submitQuestionStoreData)),
      mergeMap(([payload, questionInfo]) => {
        const submitQuestionPayload =
          this.fnolTransmuteService.createThirdPartyContactFormQuestionPayload(
            payload,
            questionInfo
          );
        return this.fnolService
          .submitQuestion(
            submitQuestionPayload,
            questionInfo.draftClaimNumber,
            GenericProductType.Auto
          )
          .pipe(
            mergeMap((response: InitiateDynamicQuestionAdapterModel) => {
              return [
                new fnolActions.SubmitQuestionSuccess(response),
                new fnolActions.SendAnalytics({
                  claimNumber: _get(questionInfo, 'draftClaimNumber'),
                  lineOfBusiness: GenericProductType.Auto,
                  isError: false,
                  apiResponse: response
                }),
                fromRouterActions.Go({
                  path: [response.path]
                })
              ];
            }),
            catchError(err => {
              const errorMessage = this.fnolAdapter.buildErrorMessage(err);

              return [
                new fnolActions.SendAnalytics({
                  claimNumber: _get(questionInfo, 'draftClaimNumber'),
                  lineOfBusiness: GenericProductType.Auto,
                  isError: true
                }),
                new fnolActions.SubmitQuestionFail(errorMessage)
              ];
            })
          );
      })
    )
  );

  submitClaimantContactFormQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.SUBMIT_CLAIMANT_CONTACT_FORM_QUESTION),
      map((action: fnolActions.SubmitClaimantContactFormQuestion) => action.payload),
      withLatestFrom(
        this.fnolStore.select(fnolQuery.submitQuestionStoreData),
        this.fnolStore.select(fnolQuery.getCurrentFnol)
      ),
      mergeMap(([payload, questionInfo, currentFnol]) => {
        // we add a new draft claim payload here because we need to change the reportedBy claim contact field
        const draftClaimPayload = this.fnolTransmuteService.createDraftClaimPayload(currentFnol, {
          reportedBy: _get(payload, 'claimContact.contact.contactId', 1),
          addReportedBy: true,
          addVehicleDetails: true
        });
        const submitQuestionPayload =
          this.fnolTransmuteService.createClaimantContactFormQuestionPayload(
            payload,
            questionInfo,
            draftClaimPayload
          );
        return this.fnolService
          .submitQuestion(
            submitQuestionPayload,
            questionInfo.draftClaimNumber,
            GenericProductType.Auto
          )
          .pipe(
            mergeMap((response: InitiateDynamicQuestionAdapterModel) => {
              return [
                new fnolActions.SubmitQuestionSuccess(response),
                new fnolActions.SendAnalytics({
                  claimNumber: _get(questionInfo, 'draftClaimNumber'),
                  lineOfBusiness: GenericProductType.Auto,
                  isError: false,
                  apiResponse: response
                }),
                fromRouterActions.Go({
                  path: [response.path]
                })
              ];
            }),
            catchError(err => {
              const errorMessage = this.fnolAdapter.buildErrorMessage(err);

              return [
                new fnolActions.SendAnalytics({
                  claimNumber: _get(questionInfo, 'draftClaimNumber'),
                  lineOfBusiness: GenericProductType.Auto,
                  isError: true
                }),
                new fnolActions.SubmitQuestionFail(errorMessage)
              ];
            })
          );
      })
    )
  );

  submitInsuredContactQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.SUBMIT_INSURED_CONTACT_QUESTION),
      map((action: fnolActions.SubmitInsuredContactQuestion) => action.payload),
      withLatestFrom(
        this.fnolStore.select(fnolQuery.submitQuestionStoreData),
        this.fnolStore.select(fnolQuery.getFnolRiskLineOfBusiness)
      ),
      mergeMap(([payload, questionInfo, lineOfBusiness]) => {
        window.scrollTo(0, 0); // scroll to top of the page
        const submitQuestionPayload = this.fnolTransmuteService.createInsuredQuestionPayload(
          payload,
          questionInfo
        );
        return this.fnolService
          .submitQuestion(submitQuestionPayload, questionInfo.draftClaimNumber, lineOfBusiness)
          .pipe(
            mergeMap((response: InitiateDynamicQuestionAdapterModel) => {
              return [
                new fnolActions.SubmitQuestionSuccess(response),
                new fnolActions.SendAnalytics({
                  claimNumber: _get(questionInfo, 'draftClaimNumber'),
                  lineOfBusiness: lineOfBusiness,
                  isError: false,
                  apiResponse: response
                }),
                fromRouterActions.Go({
                  path: [response.path]
                })
              ];
            }),
            catchError(err => {
              const errorMessage = this.fnolAdapter.buildErrorMessage(err);

              return [
                new fnolActions.SendAnalytics({
                  claimNumber: _get(questionInfo, 'draftClaimNumber'),
                  lineOfBusiness: lineOfBusiness,
                  isError: true
                }),
                new fnolActions.SubmitQuestionFail(errorMessage)
              ];
            })
          );
      })
    )
  );

  goBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.GO_BACK),
      map((action: fnolActions.GoBack) => action),
      withLatestFrom(
        this.fnolStore.select(fnolQuery.submitQuestionStoreData),
        this.fnolStore.select(fnolQuery.getFnolRiskLineOfBusiness)
      ),
      mergeMap(([action, fnolData, lineOfBusiness]) => {
        window.scrollTo(0, 0); // scroll to top of the page
        return this.fnolService
          .goBack(fnolData.draftClaimNumber, fnolData.questionId, lineOfBusiness)
          .pipe(
            mergeMap((response: InitiateDynamicQuestionAdapterModel) => {
              return [
                new fnolActions.GoBackSuccess(response),
                new fnolActions.SendAnalytics({
                  claimNumber: _get(fnolData, 'draftClaimNumber'),
                  lineOfBusiness: lineOfBusiness,
                  isError: false,
                  apiResponse: response
                }),
                fromRouterActions.Go({
                  path: [response.path]
                })
              ];
            }),
            catchError(err => {
              const errorMessage = this.fnolAdapter.buildErrorMessage(err);

              return [
                new fnolActions.SendAnalytics({
                  claimNumber: _get(fnolData, 'draftClaimNumber'),
                  lineOfBusiness: lineOfBusiness,
                  isError: true
                }),
                new fnolActions.GoBackFail(errorMessage)
              ];
            })
          );
      })
    )
  );

  editQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.EDIT_QUESTION),
      map((action: fnolActions.EditQuestion) => action.payload),
      withLatestFrom(
        this.fnolStore.select(fnolQuery.submitQuestionStoreData),
        this.fnolStore.select(fnolQuery.getFnolRiskLineOfBusiness)
      ),
      mergeMap(([payload, fnolData, lineOfBusiness]) => {
        window.scrollTo(0, 0); // scroll to top of the page
        return this.fnolService
          .editQuestion(fnolData.draftClaimNumber, payload, lineOfBusiness)
          .pipe(
            mergeMap((response: InitiateDynamicQuestionAdapterModel) => {
              return [
                new fnolActions.EditQuestionSuccess(response),
                new fnolActions.SendAnalytics({
                  claimNumber: _get(fnolData, 'draftClaimNumber'),
                  lineOfBusiness: lineOfBusiness,
                  isError: false,
                  apiResponse: response
                }),
                fromRouterActions.Go({
                  path: [response.path]
                })
              ];
            }),
            catchError(err => {
              const errorMessage = this.fnolAdapter.buildErrorMessage(err);

              return [
                new fnolActions.SendAnalytics({
                  claimNumber: _get(fnolData, 'draftClaimNumber'),
                  lineOfBusiness: lineOfBusiness,
                  isError: true
                }),
                new fnolActions.EditQuestionFail(errorMessage)
              ];
            })
          );
      })
    )
  );

  submitDraftClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.SUBMIT_DRAFT_CLAIM),
      withLatestFrom(
        this.fnolStore.select(fnolQuery.getCurrentFnol),
        this.userStore.select(userQuery.getCustomerId)
      ),
      switchMap(([action, currentFnol, customerId]) => {
        return this.fnolService
          .submitDraftClaim(
            _get(currentFnol, 'claimNumber'),
            _get(currentFnol, 'risk.generalizedProductType', '')
          )
          .pipe(
            mergeMap(response => {
              const claimNumber = response.toString().replace(/-/g, '');
              return [
                new fnolActions.SubmitDraftClaimSuccess(claimNumber),
                new fromClaimActions.SetSelectedClaim(claimNumber),
                new fromClaimActions.RequireClaimsRefresh()
              ];
            }),
            catchError(err => {
              // If error is timeout (status code 400009), we will treat as success
              if (
                _get(err, 'status.errorCode', '').includes('400009') ||
                _get(err, 'error.status.errorCode', '').includes('400009')
              ) {
                return of(new fnolActions.SubmitDraftClaimSuccess(''));
              } else {
                const errorMessage = this.fnolAdapter.buildErrorMessage(err);

                return [
                  new fnolActions.SendAnalytics({
                    claimNumber: _get(currentFnol, 'claimNumber'),
                    lineOfBusiness: _get(currentFnol, 'risk.generalizedProductType'),
                    isError: true
                  }),
                  new fnolActions.SubmitDraftClaimFail(errorMessage)
                ];
              }
            })
          );
      })
    )
  );

  getClaimDataAfterSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.SUBMIT_DRAFT_CLAIM_SUCCESS),
      map((action: fnolActions.SubmitDraftClaimSuccess) => action.payload),
      withLatestFrom(this.fnolStore.select(fnolQuery.getCurrentFnol)),
      switchMap(([claimNumber, draftClaim]) => {
        if (draftClaim.risk.generalizedProductType === GenericProductType.Auto) {
          let year, make, model, vin;
          if (_get(draftClaim, 'autoClaimDetail')) {
            year = _get(draftClaim, 'autoClaimDetail.vehicles[0].year', '');
            make = _get(draftClaim, 'autoClaimDetail.vehicles[0].make', '');
            model = _get(draftClaim, 'autoClaimDetail.vehicles[0].model', '');
            vin = _get(draftClaim, 'autoClaimDetail.vehicles[0].vin', '');
          } else {
            year = _get(draftClaim, 'vehicleDetails[0].vehTypeYear', '');
            make = _get(draftClaim, 'vehicleDetails[0].vehTypeMake', '');
            model = _get(draftClaim, 'vehicleDetails[0].vehTypeModel', '');
            vin = _get(draftClaim, 'vehicleDetails[0].vehTypeVin', '');
          }
          // "creates" the claim in our store so we can access this data elsewhere
          let returnActions: any[] = [
            new fromClaimActions.UpdateClaimField({
              claimNumber: claimNumber,
              claimFields: {
                claimNumber: claimNumber,
                autoClaimDetails: [
                  {
                    year,
                    make,
                    model,
                    vin
                  }
                ]
              }
            })
          ];
          if (
            !this.appService.isApp(Applications.FIRST_PARTY_FNOL) &&
            !this.appService.isApp(Applications.THIRD_PARTY_FNOL)
          ) {
            returnActions = returnActions.concat([
              new fromClaimActions.LoadClaimDetail({ claimNumber }),
              new fromClaimActions.GetExposureId({ vin: vin, claimNumber: claimNumber }),
              new fromEligibleServicesActions.GetEligibleServices({
                claimNumber: claimNumber,
                vin: vin
              })
            ]);
          }

          return returnActions;
        }
        return EMPTY;
      })
    )
  );

  deleteDraftClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnolActionTypes.DELETE_DRAFT_CLAIM),
      map((action: fnolActions.DeleteDraftClaim) => action.payload),
      withLatestFrom(this.fnolStore.select(fnolQuery.getCurrentFnol)),
      mergeMap(([draftClaimNumber, fnol]) =>
        this.fnolService.deleteDraftClaim(draftClaimNumber).pipe(
          mergeMap(() => {
            const deleteDraftActions: Array<FnolActions | RouterActions | ClaimGlassOnlyAction> = [
              new fnolActions.DeleteDraftClaimSuccess(draftClaimNumber)
            ];

            // If we're deleting a draft that's on the glass or ers stage, we want to
            // end the user on the relevant page
            if (fnol.questionType === FnolQuestionTypes.GLASS_END) {
              if (_get(fnol, 'vehicleDetails[0].vehTypeVin', '')) {
                let glassClaimPayload: ClaimGlassOnly = GlassDamagePayload.create(fnol);
                if (this.appService.isApp(Applications.FIRST_PARTY_FNOL)) {
                  glassClaimPayload = Object.assign({}, glassClaimPayload, {
                    defaultPartnerId: 'AFI'
                  });
                }

                deleteDraftActions.push(
                  new fromClaimGlassOnlyActions.LoadGlassOnlyClaims(glassClaimPayload)
                );
              } else {
                deleteDraftActions.push(
                  fromRouterActions.Go({
                    path: [ClaimsNavigationPaths.CLAIM_CREATE_CONTACT_GLASS]
                  })
                );
              }
            } else if (fnol.questionType === FnolQuestionTypes.ERS_END) {
              deleteDraftActions.push(
                fromRouterActions.Go({
                  path: [ClaimsNavigationPaths.CLAIM_CREATE_CONTACT_ERS]
                })
              );
            }
            /**
             * Delete draft claim from draft claim store.
             * This implementaion going to look more cleaner once we move to converse for Auto as well
             */
            return deleteDraftActions;
          }),
          catchError(() => of(new fnolActions.DeleteDraftClaimFail(draftClaimNumber)))
        )
      )
    )
  );

  sendClaimAnalytics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FnolActionTypes.SEND_ANALYTICS),
        map((action: fnolActions.SendAnalytics) => action.payload),
        map((payload: FnolAnalyticsModel) => {
          // Send Error Analytics
          this.dynamicFnolAnalyticsService.sendDynamicClaimsAnalytic(payload);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private fnolTransmuteService: FnolTransmuteService,
    private fnolService: FnolService,
    private fnolAdapter: FnolAdapter,
    private fnolStore: Store<FnolState>,
    private userStore: Store<UserState>,
    private dynamicFnolAnalyticsService: DynamicFnolAnalyticsService,
    private appService: ApplicationService
  ) {}
}
