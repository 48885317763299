import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CLAIM_ENTERPRISE_FEATURE_KEY, ClaimEnterpriseState } from './claim-enterprise.reducer';

// Lookup the 'ClaimEnterprise' feature state managed by NgRx
const selectClaimEnterpriseState = createFeatureSelector<ClaimEnterpriseState>(
  CLAIM_ENTERPRISE_FEATURE_KEY
);

export const selectClaimsEnterpriseLoading = createSelector(
  selectClaimEnterpriseState,
  (state: ClaimEnterpriseState) => state.loading
);

export const selectClaimsEnterpriseContent = createSelector(
  selectClaimEnterpriseState,
  (state: ClaimEnterpriseState) => state.url
);

export const claimEnterpriseQuery = {
  selectClaimEnterpriseState,
  selectClaimsEnterpriseLoading,
  selectClaimsEnterpriseContent
};
