import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { DigitalAccount, DigitalAccountService } from '@amfam/shared/digital-account/data-access';
import { Party } from '@amfam/shared/models';
import { fromUserActions, userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CommunicationService, CookiesService } from '@amfam/shared/utility/shared-services';

import { UpdateEmailData } from '../models/email-validation.model';
import {
  EmailPayloadToGetValidationStatus,
  EmailValidationResponse
} from '../models/profile.model';
import { AlternateAuthService } from '../services/alternate-auth.service';
import { DigitalServiceProgramService } from '../services/digital-service-program.service';
import { PartyService } from '../services/party.service';
import { ProfileUtilService } from '../services/profile-util.service';
import * as profile from './profile.actions';

@Injectable()
export class ProfileEffects {
  /**
   * Dispatches a FETCH_DATA action when the account is loaded
   *
   * @memberof ProfileEffects
   */

  loadAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.PROFILE_LOAD),
      map(action => action.payload),
      map(payload => profile.FetchDataAction(payload))
    );
  });

  /**
   * gets Party data from the store
   * if the authenticatin payload contains a customerId
   *
   * @memberof ProfileEffects
   */

  loadParty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.FETCH_DATA),
      map(action => action.payload),
      filter(payload => {
        // Make sure we have a customerId before calling getPartyDetails()
        return payload && payload.customerId && payload.customerId !== '';
      }),
      switchMap(() =>
        this.store.select(userQuery.getPartyDetails).pipe(
          map(res => profile.FetchPartyDataSuccessAction(res)),
          catchError(err => of(profile.FetchPartyDataFailAction(err)))
        )
      )
    );
  });

  /**
   * Add an email or phone to a Party
   *
   * @memberof ProfileEffects
   */

  addContactEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.ADD_CONTACT_ENTRY),
      map(action => action.payload),
      switchMap(
        // POST to party with new email
        (data): Observable<Action> =>
          this.partyService.addContactEntry(data).pipe(
            map(res => {
              res.methodType = _get(data, 'methodType');
              return res.status.code === 200
                ? profile.UpdateContactMethodValuesAction(data)
                : profile.ContactEntryFailAction(res);
            }),
            catchError(err => {
              err.methodType = _get(data, 'methodType');
              return of(profile.ContactEntryFailAction(err));
            })
          )
      )
    );
  });

  /**
   * This happens after you ADD a new email of phone.
   * We need to GET all emails or phones again so we
   * have the ID of the entry we just added.
   *
   * @memberof ProfileEffects
   */

  getUpdatedValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.UPDATE_CONTACT_METHOD_VALUES),
      map(action => action.payload),
      switchMap(payload =>
        this.partyService.getPartyDetails().pipe(
          mergeMap((res: Party) => {
            if (payload.methodType === 'emails' && _get(payload.data, 'setPrimaryEmail')) {
              const validationPayload: EmailPayloadToGetValidationStatus =
                this.profileUtilService.getOldAndNewPrimaryEmail(payload.data, res);
              return [
                profile.AddContactEntrySuccessAction({
                  setPrimaryEmail: true,
                  methodType: payload.methodType,
                  [payload.methodType]: res[payload.methodType]
                }),
                profile.GetValidationStatusForEmailAction(validationPayload)
              ];
            }
            return [
              profile.AddContactEntrySuccessAction({
                setPrimaryEmail: false,
                methodType: payload.methodType,
                [payload.methodType]: res[payload.methodType]
              })
            ];
          }),
          catchError(err => {
            err.methodType = _get(payload, 'methodType');
            return of(profile.ContactEntryFailAction(err));
          })
        )
      )
    );
  });

  validation$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.GET_VALIDATION_STATUS_FOR_EMAIL),
      map(action => action.payload),
      switchMap((payload: EmailPayloadToGetValidationStatus) => {
        return this.dspService.getValidationStatus(payload.newEntry.emailAddress).pipe(
          mergeMap((response: EmailValidationResponse) => {
            if (_get(response, 'emailProfile.confirmed')) {
              const emailAddress = payload.newEntry.emailAddress;
              return [
                profile.MakeRequestAction(), // start the spinner
                profile.UpdateDaAction({ emailAddress })
              ];
            } else {
              return [
                profile.MakeRequestAction(), // start the spinner
                profile.ValidateEmailAction({
                  // email has not been validated
                  newEmail: payload.newEntry,
                  oldEmail: payload.oldEntry
                })
              ];
            }
          }),
          catchError(error => of(profile.GetValidationStatusForEmailFailAction(error)))
        );
      })
    );
  });

  /**
   * Delete an email or phone from a party
   *
   * @memberof ProfileEffects
   */

  deleteContactEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.DELETE_CONTACT_ENTRY),
      map(action => action.payload),
      switchMap(
        (data): Observable<Action> =>
          this.partyService.deleteContactEntry(data).pipe(
            map(res => {
              res.methodType = _get(data, 'methodType');
              return res.status.code === 200
                ? profile.DeleteContactEntrySuccessAction(data)
                : profile.ContactEntryFailAction(res);
            }),
            catchError(err => {
              err.methodType = _get(data, 'methodType');
              return of(profile.ContactEntryFailAction(err));
            })
          )
      )
    );
  });

  /**
   * Update an email or phone in a party
   *
   * @memberof ProfileEffects
   */

  changeContactEntry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.CHANGE_CONTACT_ENTRY),
      map(action => action.payload),
      switchMap((data): Observable<Action> => {
        // oldEntry will exist if the user is changing a preferred contact setting that has a pre-existing value
        // in this situation the old and new values have to be PUT separately
        // first update should be the old entry if there is one
        const entry = data.data.oldEntry ? data.data.oldEntry : data.data.newEntry;
        // if there is an old entry, second update will be the new entry
        const entry2 = data.data.oldEntry ? data.data.newEntry : undefined;
        return this.partyService.editContactEntry(entry, data.methodType, entry2).pipe(
          map(res => {
            if (res.status.code === 200) {
              if (entry2) {
                // kick off edit for second entry
                return profile.ChangeContactEntryAction({
                  data: { newEntry: entry2 },
                  methodType: data.methodType
                });
              } else {
                return profile.ChangeContactEntrySuccessAction({
                  methodType: data.methodType,
                  data: res.otherEntry ? [entry, res.otherEntry] : [entry]
                });
              }
            }
            res.methodType = data.methodType;
            return profile.ContactEntryFailAction(res);
          }),
          catchError(err => {
            err.methodType = data.methodType;
            return of(profile.ContactEntryFailAction(err));
          })
        );
      })
    );
  });

  /**
   * Refresh party details from the party API after all CRUD operations on a party
   *
   * @memberof ProfileEffects
   */

  refreshParty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        profile.ProfileActionTypes.REFRESH_PARTY_DATA,
        profile.ProfileActionTypes.CHANGE_CONTACT_ENTRY_SUCCESS,
        profile.ProfileActionTypes.DELETE_CONTACT_ENTRY_SUCCESS
      ),
      mergeMap(() =>
        this.partyService.getPartyDetails().pipe(
          map(res => profile.FetchPartyDataSuccessAction(res)),
          catchError(err => of(profile.FetchPartyDataFailAction(err)))
        )
      )
    );
  });

  loadDigitalAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.FETCH_DATA),
      concatLatestFrom(() => this.store.select(userQuery.getWaid)),
      switchMap(([, waid]) =>
        this.daService.getDigitalAccountByWaid(waid).pipe(
          concatLatestFrom(() => this.store.select(userQuery.getSmImpersonatorUserId)),
          switchMap(([res, impersonatorUserId]) => {
            const actions: Action[] = [];
            if (
              !_get(res, 'securityQuestions[0].question') &&
              this.featureFlagService.isEnabled('type_zero_security_questions') &&
              !impersonatorUserId &&
              !this.featureFlagService.isEnabled('forgerock')
            ) {
              actions.push(
                fromRouterActions.Go({
                  path: ['/add-security-questions']
                })
              );
            }
            actions.push(profile.FetchDaDataSuccessAction(res));
            return actions;
          }),
          catchError(err => of(profile.FetchDaDataFailAction(err)))
        )
      )
    );
  });

  updateDigitalAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.UPDATE_DA),
      map(action => action.payload),
      concatLatestFrom(
        () =>
          [
            this.store.select(userQuery.getWaid),
            this.store.select(BrandSelectors.selectPartnerId),
            this.store.select(BrandSelectors.selectExperienceId)
          ] satisfies [Observable<unknown>, Observable<unknown>, Observable<unknown>]
      ),

      switchMap(([data, waid, partnerId, expId]) =>
        this.daService.updateDigitalAccount(data, waid, partnerId, expId).pipe(
          mergeMap(() => {
            // we now need to dispatch two actions
            // 1. to update the UI by calling getPartyDetails -> called by RefreshPartyDataAction
            // 2. to update the state with UpdateDaSuccessAction
            return [profile.RefreshPartyDataAction(), profile.UpdateDaSuccessAction(data)];
          }),
          catchError(err => of(profile.UpdateDaFailAction(err)))
        )
      )
    );
  });

  /**
   * This effect is used to update MyAccount email when using AltAuth
   *
   * @memberof ProfileEffects
   */

  updateMyAccountEmail = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.UPDATE_EMAIL),
      map(action => action.payload),
      switchMap((data: UpdateEmailData) =>
        this.alternateAuthService.updateEmail(data).pipe(
          map(() => profile.UpdateEmailSuccessAction(data)),
          catchError(err => of(profile.UpdateEmailFailAction(err)))
        )
      )
    );
  });

  /**
   * this effect is used for editing security questions or password
   *
   * @memberof ProfileEffects
   */

  changeSecurityInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.CHANGE_SECURITY_INFO),
      map(action => action.payload),
      concatLatestFrom(
        () =>
          [
            this.store.select(userQuery.getWaid),
            this.store.select(BrandSelectors.selectPartnerId),
            this.store.select(BrandSelectors.selectExperienceId)
          ] satisfies [Observable<unknown>, Observable<unknown>, Observable<unknown>]
      ),
      switchMap(([data, waid, partnerId, expId]) => {
        const methodType: string = _get(data, 'methodType', '');
        const methodTypeValue = data['data'][methodType];
        let digitalAccount: DigitalAccount;
        if (methodType === 'securityQuestions') {
          digitalAccount = {
            customerId: data.data.customerId,
            securityQuestions: methodTypeValue
          };
        } else if (methodType === 'password') {
          digitalAccount = {
            customerId: data.data.customerId,
            password: methodTypeValue
          };
        } else if (methodType === 'userId') {
          digitalAccount = {
            userId: methodTypeValue
          };
        } else {
          // TODO: ChangeSecurityInfoFailAction does not do anything
          return of(
            profile.ChangeSecurityInfoFailAction({
              error: 'invalid payload'
            })
          );
        }
        return this.daService.updateDigitalAccount(digitalAccount, waid, partnerId, expId).pipe(
          mergeMap(res => {
            res.methodType = methodType;
            res.hideToaster = data.hideToaster;

            if (methodType === 'userId') {
              return [
                profile.ChangeSecurityInfoSuccessAction(data),
                new fromUserActions.EditUserId(methodTypeValue)
              ];
            }
            return res.status.code === 200
              ? [profile.ChangeSecurityInfoSuccessAction(data)]
              : [profile.ChangeSecurityInfoFailAction(res)];
          }),
          catchError(err => {
            err.methodType = methodType;
            err.hideToaster = data.hideToaster;
            return of(profile.ChangeSecurityInfoFailAction(err));
          })
        );
      })
    );
  });
  /**
   * Email the user when something changes
   *
   * @memberof ProfileEffects
   */

  sendNotificationEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.CHANGE_CONTACT_ENTRY_SUCCESS),
      map(action => action.payload),
      concatLatestFrom(
        () =>
          [
            this.store.select(userQuery.getUserState),
            this.store.select(BrandSelectors.selectPartnerId)
          ] satisfies [Observable<unknown>, Observable<unknown>]
      ),
      switchMap(([data, userState, partnerId]) =>
        this.communicationService
          .sendEmailNotificationForProfile(
            data.methodType,
            partnerId,
            userState.customerId,
            userState.emailAddress,
            userState.firstName
          )
          .pipe(
            map(() => profile.EmailNotificationSuccessAction()),
            catchError(err => of(profile.EmailNotificationFailAction(err)))
          )
      )
    );
  });

  /**
   * Send an email to the new email address
   * with a link to confirm the new address
   *
   * @memberof ProfileEffects
   */

  validateEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.VALIDATE_EMAIL),
      map(action => action.payload),
      switchMap(data =>
        this.dspService.validate(data.newEmail.emailAddress).pipe(
          map(res => {
            if (res.status && res.status.code === 200) {
              this.cookiesService.removeItem('isDuplicateEmail');
            }
            return res.status && res.status.code === 200
              ? profile.ValidateEmailSuccessAction()
              : profile.ValidateEmailFailAction();
          }),
          catchError(err => of(profile.ValidateEmailFailAction(err)))
        )
      )
    );
  });

  /**
   *  New email status for Make Primary
   */
  emailStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.GET_VALIDATION_STATUS_FOR_PENDING_EMAIL),
      switchMap(() => {
        return this.dspService.getValidationStatus('').pipe(
          mergeMap((response: EmailValidationResponse) => {
            if (_get(response, 'emailProfile.confirmed')) {
              return [profile.RefreshPartyDataAction()];
            } else {
              return [
                profile.MakeRequestAction(),
                profile.GetValidationStatusForPendingEmailSuccessAction({
                  // email has not been validated
                  newEmail: _get(response, 'emailProfile.emailAddress'),
                  tokenExpiry: _get(response, 'emailProfile.tokenExpired')
                })
              ];
            }
          }),
          catchError(error => of(profile.GetValidationStatusForEmailFailAction(error)))
        );
      })
    );
  });

  updateMembership$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profile.ProfileActionTypes.UPDATE_MEMBERSHIP),
      map(action => action.payload),
      switchMap(
        // POST updated membership details
        (data): Observable<Action> =>
          this.profileUtilService.editMembership(data).pipe(
            map(res => {
              return res.status.code === 200
                ? profile.UpdateMembershipSuccessAction(data)
                : profile.UpdateMembershipFailAction(res);
            }),
            catchError(err => {
              return of(profile.UpdateMembershipFailAction(err));
            })
          )
      )
    );
  });

  constructor(
    private actions$: Actions<profile.Actions>,
    private communicationService: CommunicationService,
    private daService: DigitalAccountService,
    private featureFlagService: FeatureFlagService,
    private dspService: DigitalServiceProgramService,
    private alternateAuthService: AlternateAuthService,
    private partyService: PartyService,
    private store: Store,
    private profileUtilService: ProfileUtilService,
    private cookiesService: CookiesService
  ) {}
}
