import { Injectable } from '@angular/core';
import { forEach as _forEach, get as _get } from 'lodash';

import { PolicyList, UnassociatedRiskDetailSuccess } from '@amfam/shared/models';

import { Adapter } from './adapter';

@Injectable({
  providedIn: 'root'
})
export class UnassociatedRiskAdapterService implements Adapter<PolicyList> {
  constructor() {}

  adapt(response: UnassociatedRiskDetailSuccess, policyList: PolicyList = []): PolicyList {
    if (_get(response, 'policies.length')) {
      const policyListItems: PolicyList = [];
      _forEach(response.policies, policy => {
        const policyObj = policyList.find(
          policyFrmBilling => policyFrmBilling.policyNumber === policy.policyNumber
        );
        if (policyObj) {
          policyListItems.push(
            Object.assign({}, policyObj, {
              riskDescriptionList: policy.riskDescriptions,
              policyNumber: policyObj.policyNumber,
              productType: policy.productType
            })
          );
        }
      });
      return _get(policyListItems, 'length', 0) === 0 ? policyList : policyListItems;
    }
    return policyList;
  }
}
