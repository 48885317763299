import { Directive, HostListener } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Directive({
  selector: '[dsSsnMask]'
})
export class SsnMaskDirective {
  private model: FormControlName;
  private digitPattern = /\d+/;

  constructor(model: FormControlName) {
    this.model = model;
  }

  @HostListener('keyup', ['$event'])
  onInputChange(event: KeyboardEvent) {
    const currentValue = this.model.value;
    let actualValue = '';

    // Allow navigation and deleting of characters
    if (event.keyCode === 8 || event.keyCode === 37 || event.keyCode === 38) {
      return;
    }

    // Exit early if there's no value
    if (!currentValue || currentValue.length <= 0) {
      return;
    }

    actualValue = this.getActualValue(currentValue);

    // This is the actual unmasked value
    this.model.viewToModelUpdate(actualValue);
    // This is the displayed (masked) value
    this.model.valueAccessor.writeValue(this.maskValue(actualValue));
  }

  // Add dashes to actualValue
  maskValue(value: string): string {
    let output = value;
    const len = value.length;

    if (len >= 3) {
      output = `${value.slice(0, 3)}-${value.slice(3)}`;
    }

    if (len >= 5) {
      output = `${output.slice(0, 6)}-${output.slice(6)}`;
    }

    return output;
  }

  // Returns the actual (unmasked) value
  getActualValue(currentValue: string): string {
    // remove any dashes before matching
    const match: string[] = currentValue.replace(/-/g, '').match(this.digitPattern) || [];

    // since match returns an array, we reduce array of matches to a string

    return match.reduce((a, b) => a + b, '');
  }
}
