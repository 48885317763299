// eslint-disable-next-line
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { get as _get } from 'lodash';

import { ApiStatus, initialApiStatus } from '@amfam/shared/models';

import { BillingActions, BillingActionTypes } from './billaccount.actions';

export interface BillingNotificationsState {
  billAccountNumber: string;
  error?: ApiStatus | null;
  success?: ApiStatus | null;
  loading: boolean;
  prefsLoaded?: boolean;
  detailsLoaded?: boolean;
  prefsFinishedLoading?: boolean;
  loadPrefsError?: boolean;
  documentsLoaded?: boolean;
  updatingPreferences?: boolean;
  updatedPreferences?: boolean;
  deletingPreferences?: boolean;
  deletedPreferences?: boolean;
  deleteCorrelationId?: string;
  deletePrefsRetry?: boolean;
  deletePreferencesError?: ApiStatus | null;
  updatingRegistration?: boolean;
  updatedRegistration?: boolean;
  updateRegistrationPartialFailure?: boolean;
  riskDetailsLoaded?: boolean;
  updatedBillingPreferences?: boolean;
  updateBillingPreferencesError?: boolean;
  updatePreferenceCorrelationId?: string;
  updateCorrelationId?: string;
  updatingDeliveryPreferences?: boolean;
  updatedDeliveryPreferences?: boolean;
  updateDeliveryPreferencesError?: boolean;
  correlationId?: string;
  updateRegistrationError?: string;
  updatePreferencesError?: string;
}

export interface NotificationState extends EntityState<BillingNotificationsState> {
  loading: boolean;
  error: ApiStatus | null;
  cancelNavigationPath?: string;
}

export const adapter: EntityAdapter<BillingNotificationsState> =
  createEntityAdapter<BillingNotificationsState>({
    selectId: (billingNotificationsState: BillingNotificationsState) =>
      billingNotificationsState.billAccountNumber,
    sortComparer: false
  });

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  prefsLoaded: false,
  detailsLoaded: false,
  prefsFinishedLoading: false,
  loadPrefsError: false,
  documentsLoaded: false,
  updatingPreferences: false,
  updatedPreferences: false,
  deletingPreferences: false,
  deletedPreferences: false,
  deletePrefsRetry: false,
  updatingRegistration: false,
  updatedRegistration: false,
  updateRegistrationPartialFailure: false,
  riskDetailsLoaded: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: BillingActions
): NotificationState {
  switch (action.type) {
    case BillingActionTypes.BillAccountsLoadType:
    case BillingActionTypes.BillAccountLoadFuturePaymentsType: {
      return { ...state, loading: true };
    }

    case BillingActionTypes.BillAccountsLoadFailType:
    case BillingActionTypes.BillAccountsLoadSuccessType:
    case BillingActionTypes.BillAccountsLoadCompleteType:
    case BillingActionTypes.BillAccountLoadFuturePaymentsSuccessType: {
      return { ...state, loading: false };
    }

    case BillingActionTypes.BillAccountLoadDetailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          error: state.entities[_get(action, 'payload.billAccountNumber')]
            ? state.entities[_get(action, 'payload.billAccountNumber')].error
            : null,
          success: state.entities[_get(action, 'payload.billAccountNumber')]
            ? state.entities[_get(action, 'payload.billAccountNumber')].success
            : null,
          prefsLoaded: state.entities[_get(action, 'payload.billAccountNumber')]
            ? state.entities[_get(action, 'payload.billAccountNumber')].prefsLoaded
            : false,
          detailsLoaded: state.entities[_get(action, 'payload.billAccountNumber')]
            ? state.entities[_get(action, 'payload.billAccountNumber')].detailsLoaded
            : false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadDetailSuccessType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          success: _get(action, 'payload.status', initialApiStatus),
          error: null,
          loading: false,
          prefsLoaded: state.entities[_get(action, 'payload.billAccountNumber')]
            ? state.entities[_get(action, 'payload.billAccountNumber')].prefsLoaded
            : false,
          detailsLoaded: true
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadDetailFailType:
    case BillingActionTypes.BillAccountLoadFuturePaymentsFailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          success: null,
          error: _get(action, 'payload.status', initialApiStatus),
          loading: false,
          prefsLoaded: state.entities[_get(action, 'payload.billAccountNumber')]
            ? state.entities[_get(action, 'payload.billAccountNumber')].prefsLoaded
            : false,
          detailsLoaded: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUnregisterAssociatedType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          prefsLoaded: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadPreferenceType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          prefsFinishedLoading: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadPreferenceSuccessType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadPreferenceFailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          loadPrefsError: true
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadDocumentsType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          documentsLoaded: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadDocumentsFailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          documentsLoaded: true
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadDocumentsSuccessType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          documentsLoaded: true
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdatePreferenceType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          updatingPreferences: true,
          updatedPreferences: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdatePreferenceSuccessType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          updatingPreferences: false,
          updatedPreferences: true
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdatePreferenceFailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          updatingPreferences: false,
          updatedPreferences: false,
          updateCorrelationId: action.payload.updateCorrelationId,
          updatePreferencesError: action.payload.error
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateBillingPreferenceInitType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload'),
          loading: false,
          updatedBillingPreferences: null,
          updateBillingPreferencesError: null,
          updatePreferenceCorrelationId: null
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateBillingPreferenceType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          updatedBillingPreferences: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateBillingPreferenceSuccessType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          updatedBillingPreferences: true,
          updatePreferenceCorrelationId: action.payload.updatePreferenceCorrelationId
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateBillingPreferenceFailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          updateBillingPreferencesError: true,
          updatePreferenceCorrelationId: action.payload.updatePreferenceCorrelationId
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateDeliveryPreferenceType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          updatingDeliveryPreferences: true,
          updatedDeliveryPreferences: false,
          updateDeliveryPreferencesError: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateDeliveryPreferenceSuccessType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          updatingDeliveryPreferences: false,
          updatedDeliveryPreferences: true,
          updatePreferenceCorrelationId: action.payload.updatePreferenceCorrelationId
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateDeliveryPreferenceFailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          updatingDeliveryPreferences: false,
          updateDeliveryPreferencesError: true,
          updatePreferenceCorrelationId: action.payload.updatePreferenceCorrelationId
        },
        state
      );
    }

    case BillingActionTypes.BillAccountDeletePreferenceType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          deletingPreferences: true,
          deletedPreferences: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountDeletePreferenceRetryType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          deletingPreferences: true,
          deletePrefsRetry: true
        },
        state
      );
    }

    case BillingActionTypes.BillAccountDeletePreferenceResetRetryType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          deletingPreferences: true,
          deletePrefsRetry: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountDeletePreferenceSuccessType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          deletingPreferences: false,
          deletedPreferences: true,
          deleteCorrelationId: action.payload.deleteCorrelationId
        },
        state
      );
    }

    case BillingActionTypes.BillAccountDeletePreferenceFailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          deletingPreferences: false,
          deletedPreferences: false,
          deleteCorrelationId: action.payload.deleteCorrelationId,
          deletePreferencesError: action.payload.error
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateRegistrationSubmitType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          updatingRegistration: true,
          updatedRegistration: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateRegistrationSuccessType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          updatingRegistration: false,
          updatedRegistration: true,
          correlationId: action.payload.correlationId
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateRegistrationPartialFailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          updatingRegistration: false,
          updatedRegistration: false,
          correlationId: action.payload.correlationId,
          updateRegistrationPartialFailure: true
        },
        state
      );
    }

    case BillingActionTypes.BillAccountUpdateRegistrationFailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          updatingRegistration: false,
          updatedRegistration: false,
          correlationId: action.payload.correlationId,
          updateRegistrationError: action.payload.error
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadUnAssociatedRiskDetailType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: true,
          riskDetailsLoaded: false
        },
        state
      );
    }

    case BillingActionTypes.BillAccountLoadUnAssociatedRiskDetailFailType:
    case BillingActionTypes.BillAccountLoadUnAssociatedRiskDetailSuccessType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          riskDetailsLoaded: true
        },
        state
      );
    }

    case BillingActionTypes.BillAccountSetCancelPathType: {
      return {
        ...state,
        cancelNavigationPath: _get(action, 'payload', '')
      };
    }

    case BillingActionTypes.BillAccountUpdatePreferenceInitType: {
      const billAccountPrefsInit = Object.assign({}, state.entities[action.payload]);
      delete billAccountPrefsInit.updatingPreferences;
      delete billAccountPrefsInit.updatedPreferences;
      delete billAccountPrefsInit.updatePreferencesError;
      delete billAccountPrefsInit.updateCorrelationId;

      return adapter.upsertOne(billAccountPrefsInit, state);
    }

    case BillingActionTypes.BillAccountUpdateReadOnlyStatusType: {
      return adapter.upsertOne(
        {
          billAccountNumber: _get(action, 'payload.billAccountNumber'),
          loading: false,
          prefsFinishedLoading: true
        },
        state
      );
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
