import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'ds-errorbox',
  templateUrl: './ds-errorbox.component.html',
  styleUrls: ['./ds-errorbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DsErrorboxComponent implements OnInit {
  @Input() headingText: string;
  @Input() messageText: string;
  @Input() linkText: string;
  @Output() linkEvent = new EventEmitter<any>();
  @Input() postLinkText: string;

  constructor() {}

  ngOnInit(): void {}
}
