import { Routes } from '@angular/router';

import { ForgerockAuthGuard } from '../core';
import { AuthGuard } from '../core/auth/auth.guard';
import { ContactUsComponent } from '../core/contact-us/contact-us.component';
import { MaintenanceGuard } from '../core/maintenance/maintenance.guard';
import { ErrorRoutes } from '../error/error-routing.module';
import { ValidateEmailTokenComponent } from '../profile/validate-email-token';
import {
  CompanyInformationComponent,
  LegalNoticeComponent,
  PrivacySecurityPolicyComponent
} from '../shared/footer-links';
import { AddSecurityQuestionsComponent } from './add-security-questions/add-security-questions.component';
import { EmailValidationLoginComponent } from './email-validation/email-validation-login/email-validation-login.component';
import { EmailValidationVerifyPhoneComponent } from './email-validation/email-validation-verify-phone/email-validation-verify-phone.component';
import { EmailValidationComponent } from './email-validation/email-validation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {
  ForgotPasswordProviders,
  ForgotPasswordRoutes
} from './forgot-password/forgot-password.routing';
import { ForgotPasswordGuard } from './forgot-password/shared/forgot-password.guards';
import { ForgotUseridComponent } from './forgot-userid/forgot-userid.component';
import { LoginBaseComponent } from './login-base/login-base.component';
import { LoginRedirectGuard } from './login.redirect.guard';
import { LoginComponent } from './login/login.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';

export const LoginRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [MaintenanceGuard],
    children: [
      ...ErrorRoutes,
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'login', component: LoginBaseComponent, canActivate: [LoginRedirectGuard] },
      {
        path: 'login/invalid',
        component: LoginBaseComponent,
        data: { invalid: true }
      },
      {
        path: 'add-security-questions',
        component: AddSecurityQuestionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [ForgotPasswordGuard],
        children: [...ForgotPasswordRoutes]
      },
      { path: 'forgot-userid', component: ForgotUseridComponent },
      { path: 'session-expired', component: SessionExpiredComponent },
      { path: 'company-information', component: CompanyInformationComponent },
      { path: 'privacy-security-policy', component: PrivacySecurityPolicyComponent },
      { path: 'legal-notice', component: LegalNoticeComponent },
      {
        path: 'validate-email/:token',
        component: ValidateEmailTokenComponent,
        canActivate: [AuthGuard, ForgerockAuthGuard]
      },
      {
        path: 'email-validation',
        component: EmailValidationComponent,
        children: [
          {
            path: '',
            component: EmailValidationLoginComponent
          },
          {
            path: 'verify-phone',
            component: EmailValidationVerifyPhoneComponent
          }
        ]
      }
    ]
  }
];

export const authProviders = [
  AuthGuard,
  ForgerockAuthGuard,
  ForgotPasswordGuard,
  ...ForgotPasswordProviders,
  LoginRedirectGuard
];
