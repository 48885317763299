import { ApiStatus } from '@amfam/shared/models';

export interface Vehicle {
  id: string;
  source: string;
  year: string;
  make: string;
  model: string;
  vinPattern: string;
  series: string;
  engineDescription: string;
  weight: string;
  createdOn: string;
  manufacturersSuggestedRetailPrice: string;
  wheelBase: string;
}

export interface AddVehicleFeaturesResponseModel {
  status: ApiStatus;
  matchedVehicles: {
    typeOfVehicleMatchCode: string;
    atvs: [];
    automobiles: [
      {
        patternPercentage: string;
        countOfCylinders: string;
        displacementVolume: string;
        minimumHorsePower: string;
        maximumHorsePower: string;
        transmissionDescription: string;
        size: string;
        minimumGrossVehicleWeightRating: string;
        maximumGrossVehicleWeigthRating: string;
        length: string;
        height: string;
        manufacturersSuggestedRetailPrice: string;
        features: VehicleFeaturesModel[];
        vehicle: Vehicle;
      }
    ];
    dirtBikes: [];
    motorcycles: [];
    scooters: [];
    trucks: [];
  };
}

export interface VehicleFeaturesModel {
  typeOfFeatureCode: string;
  description: string;
  typeOfAvailabilityCode: string;
}

export interface AdaptedVehicleFeatures {
  features: VehicleFeaturesModel[];
}

export interface GetNewVehicleFeaturesRequestModel {
  vin: string;
  year: string;
  typeOfVehicleCode: 'automobile';
}

export interface GetNewVehicleFeaturesResponseModel {
  request: GetNewVehicleFeaturesRequestModel;
  response: AdaptedVehicleFeatures;
}

export interface NewVehicleFeaturesState {
  vin: string;
  features?: VehicleFeaturesModel[];
  error?: ApiStatus | null;
  success?: ApiStatus | null;
  loading: boolean;
  status?: ApiStatus;
}
