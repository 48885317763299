// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Models
import { from } from 'rxjs';

import { ConfigService, WindowRef } from '@amfam/shared/utility/shared-services';

import { ACTIONTYPE, AnalyticOptions } from '../models/analytics.model';

@Injectable({
  providedIn: 'root'
})
export class DynatraceService {
  constructor(
    private config: ConfigService,
    private win: WindowRef,
    private http: HttpClient
  ) {}

  loadDynatraceScript() {
    return from(
      new Promise<void>((resolve, reject) => {
        const script = document.createElement('script') as any;
        script.type = 'text/javascript';
        script.crossorigin = 'anonymous';
        script.src = this.config.get('links.dynatrace');

        // cross browser handling of onLoaded event
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              if (this.win.nativeWindow.dT_ && this.win.nativeWindow.dT_.initAngularNg) {
                resolve();
              } else {
                reject();
              }
            }
          };
        } else {
          // Others
          script.onload = () => {
            if (this.win.nativeWindow.dT_ && this.win.nativeWindow.dT_.initAngularNg) {
              resolve();
            } else {
              reject();
            }
          };
          script.onerror = (error: any) => {
            reject();
          };
          // finally append the script tag in the DOM
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }).then(
        () => {
          this.win.nativeWindow.dT_.initAngularNg(this.http, HttpHeaders);
        },
        reason => {
          console.log(reason);
          throw new Error(reason);
        }
      )
    );
  }
  sendActionWithAnalytic(options: AnalyticOptions) {
    if (options.event) {
      this.sendDynatraceAction(
        options.event.eventCategory,
        options.event.eventName + '|' + options.event.eventStep
      );
    }
    if (options.page) {
      this.sendDynatraceAction('pageview', options.page.pageName);
    }
  }

  /**
   * Send action to Dynatrace
   *
   * @param {string} actionName
   * @param {string} actionType
   * @memberof AnalyticsService
   */
  sendDynatraceAction(actionName: string, actionType: string) {
    const dt = this.win.nativeWindow.dT_; // agent script
    const dtrum = this.win.nativeWindow.dtrum; // one agent script
    try {
      if (typeof dt !== 'undefined' && typeof dtrum !== 'undefined') {
        if (actionName === 'exception') {
          // reportCustomError would be used to send the customer error aleerts instantly
          const customError = dtrum.reportCustomError(actionName, actionType, null, false);
        } else {
          if (Object.values(ACTIONTYPE).includes(actionType as ACTIONTYPE)) {
            this.win.nativeWindow.email = actionName;
          }
          const action = dtrum.enterAction(actionName + ': ' + actionType);
          dtrum.leaveAction(action);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Send the app version to Dynatrace
   *
   * @private
   * @param {string} version
   * @memberof AnalyticsService
   */
  sendDynatraceAppVersion() {
    const version = this.config.get('version');
    const dt = this.win.nativeWindow.dT_; // agent script
    const dynaTrace = this.win.nativeWindow.dynaTrace; // appmon script
    const dtrum = this.win.nativeWindow.dtrum; // one agent script

    try {
      if (
        typeof dt !== 'undefined' &&
        typeof dynaTrace !== 'undefined' &&
        dynaTrace.setAppVersion
      ) {
        dynaTrace.setAppVersion(version);
      } else if (typeof dt !== 'undefined' && typeof dtrum !== 'undefined' && dtrum.setAppVersion) {
        dtrum.setAppVersion(version);
      }
    } catch (error) {
      console.log(error);
    }
  }
}
