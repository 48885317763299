import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forEach as _forEach, get as _get, has as _has } from 'lodash';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AnalyticsActions } from '@amfam/shared/analytics';

import * as fromRoot from '../store/';
import * as sessionActions from '../store/session/session.actions';

export interface ApiStatus {
  method: string;
  url: string;
  code: number;
  reason: string;
  messages: string[];
}

@Injectable()
export class GlobalResponseInterceptor implements HttpInterceptor {
  constructor(private store: Store<fromRoot.RootState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(request, error)));
  }

  /**
   * @param error
   * @param apiStatus
   *
   * clone the HttpErrorResponse with modified error
   */
  private cloneErrorResponse(error: HttpErrorResponse, apiError: ApiStatus) {
    return new HttpErrorResponse({
      error: apiError,
      headers: error.headers,
      status: error.status,
      statusText: error.statusText,
      url: error.url || undefined
    });
  }

  /**
   * @param error
   * @param apiStatus
   *
   * throw the modified HttpErrorResponse
   */
  private throwError(error: any, apiStatus?: ApiStatus) {
    if (error.status && error.status === 401) {
      this.store.dispatch(new sessionActions.RefreshUserAction());
    }
    if (apiStatus) {
      return observableThrowError(this.cloneErrorResponse(error, apiStatus));
    }
    return observableThrowError(error);
  }

  /**
   *
   * @param apiStatus
   *
   * sends error to analytics
   */
  private logError(apiStatus: ApiStatus) {
    this.store.dispatch(
      AnalyticsActions.sendDynatraceAction({
        payload: {
          actionName: 'exception',
          actionType:
            apiStatus.code + '|' + apiStatus.method + '::' + apiStatus.url + '|' + apiStatus.reason
        }
      })
    );
  }

  /**
   * Throw consistent status objects from http calls.
   * Always throw a status object with a code and a reason
   */
  private handleError(request: HttpRequest<any>, error: any) {
    const apiStatus: ApiStatus = {
      method: request.method ? request.method : 'unknown',
      url: error.url ? error.url : 'unknown',
      code: error.status === 0 || error.status ? Number(error.status) : 520,
      reason: '',
      messages: []
    };
    if (apiStatus.code === 401) {
      return this.throwError(error, apiStatus);
    }
    if (error instanceof HttpErrorResponse) {
      try {
        const body = error.error;
        // KP: this block is specifically for financial account service where the errponse pattern is a bit different.
        if (_has(body, 'finAcctServiceResponse.apiStatus.code')) {
          apiStatus.reason = _get(body, 'finAcctServiceResponse.apiStatus.reason', 'Unknown Error');
          apiStatus.code = Number(body.finAcctServiceResponse.apiStatus.code);
          apiStatus.messages = _get(body, 'finAcctServiceResponse.apiStatus.messages', []);
          this.logError(apiStatus);
          return this.throwError(body, apiStatus);
        }
        // most API error responses will return a code
        if (_has(body, 'status.code')) {
          apiStatus.reason = body.status.reason || body.status.message || 'Unknown Error';
          apiStatus.code = isNaN(Number(body.status.code)) ? 520 : Number(body.status.code);
          if (apiStatus.code !== 404) {
            apiStatus.messages = _get(body, 'status.messages', _get(body, 'status.errors', []));
            // log all messages
            if (apiStatus.messages.length > 0) {
              _forEach(apiStatus.messages, message => {
                const messageCode = message['code'];
                const messageType = message['type '] || message['level'] || '';
                const messageDescription = message['description'] || message['message'] || '';
                const logReason = `${messageType} ${messageDescription}`;
                this.logError({
                  method: apiStatus.method,
                  code: messageCode,
                  reason: logReason,
                  url: apiStatus.url,
                  messages: []
                });
              });
            } else {
              this.logError(apiStatus);
            }
          }
          // trow the error without modification
          return this.throwError(body);
        }
        apiStatus.reason = `${error.statusText || ''} ${JSON.stringify(body)}`;
      } catch (error) {
        apiStatus.reason = error.toString();
      }
    } else {
      apiStatus.reason = error && error.message ? error.message : error.toString();
    }
    this.logError(apiStatus);
    return this.throwError(error, apiStatus);
  }
}
