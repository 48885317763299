<div *dsPartnerAFI id="agents">
  @if ((areAllAgentsLoaded | async) && (agents | async).length > 0) {
    <div class="agents-list">
      @if ((agents | async).length === 1 && !isConnectPartner) {
        <h4 data-cy="myAgentHeader">My Agent</h4>
      }
      @if ((agents | async).length > 1 && !isConnectPartner) {
        <h4 data-cy="myAgentsHeader">My Agents</h4>
      }
      <ul class="agent-list-item-wrapper">
        @for (agent of agents | async; track agent) {
          <li>
            <agent-item [agent]="agent" [newTemplate]="true"></agent-item>
          </li>
        }
      </ul>
    </div>
  }
</div>
<div *dsPartnerConnect class="agents-list">
  <h4>Contact Us</h4>
  <ul class="agent-list-item-wrapper">
    <li><agent-item></agent-item></li>
  </ul>
</div>
