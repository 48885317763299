import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { AutoPayUtilService } from '@amfam/billing/auto-pay/feature';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { LoadingSpinnerService, Option } from '@amfam/ui-kit';

@Component({
  selector: 'ds-autopay',
  templateUrl: './autopay.component.html',
  styleUrls: ['./autopay.component.scss']
})
export class AutopayComponent implements OnInit {
  autoPaySignupFormGroup: UntypedFormGroup;
  autoPaySignupOptions: Option[] = [
    { text: 'Yes', id: '0' },
    { text: 'No', id: '1' }
  ];
  billAccounts: string[];
  constructor(
    private autoPayUtilService: AutoPayUtilService,
    private formBuilder: UntypedFormBuilder,
    private spinnerService: LoadingSpinnerService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.spinnerService.stop();
    this.autoPaySignupFormGroup = this.formBuilder.group({
      autoPayOption: [this.autoPaySignupOptions[0], Validators.compose([Validators.required])]
    });
  }

  submit() {
    if (this.autoPaySignupFormGroup.value.autoPayOption.text === 'No') {
      this.store.dispatch(
        fromRouterActions.Go({
          path: ['/enroll/skip-autopay']
        })
      );
    } else {
      // this.store.dispatch(new BillAccountActions.BillAccountsLoad());
      this.autoPayUtilService.routeToMultipleAutoPaySelection('overview');
    }
  }
  back() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/enroll/paperless/confirmation']
      })
    );
  }
}
