import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { CriteriaItem } from '@amfam/ui-kit';

@Component({
  selector: 'ds-credit-card-add-edit',
  templateUrl: './credit-card-add-edit.component.html',
  styleUrls: ['./credit-card-add-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditCardAddEditComponent implements OnChanges {
  @Input() formGroup: UntypedFormGroup;
  criteria: Observable<CriteriaItem[]>;

  constructor() {}

  ngOnChanges(): void {
    this.criteria = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      map(() => {
        const nickNameControl: AbstractControl = this.formGroup.get('nickName');
        return [
          {
            text: 'Maximum 4 numbers',
            valid: !nickNameControl.hasError('noMoreThanFourNumbers') && nickNameControl.value,
            error: nickNameControl.hasError('noMoreThanFourNumbers')
          },
          {
            text: 'No special characters',
            valid: !nickNameControl.hasError('invalidNickName') && nickNameControl.value,
            error: nickNameControl.hasError('invalidNickName')
          },
          {
            text: 'Unique account name',
            valid: !this.formGroup.hasError('duplicateNickName') && nickNameControl.value,
            error: this.formGroup.hasError('duplicateNickName')
          }
        ];
      })
    );
  }
}
