import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, Observable, of as observableOf, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, toArray } from 'rxjs/operators';

import * as fromRoot from '../../';
import { KydService } from '../../../../policy/know-your-drive/shared/kyd.service';
import * as kydEligibilityActions from './kyd-eligibility.actions';
import * as kydEnrollActions from './kyd-enroll.actions';
import * as kydActions from './kyd.actions';
import { KydEnrollmentRequest, KydEnrollmentResponse, KydUnenrollRequest } from './models';

@Injectable()
export class KydEnrollEffects {
  enroll$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydEnrollActions.ENROLL),
      map((action: kydEnrollActions.EnrollAction) => action.payload),
      switchMap(payload => this.kydService.saveEnrollments(payload)),
      map(enrollmentResponses => new kydEnrollActions.EnrollSuccessAction(enrollmentResponses)),
      catchError(error => observableOf(new kydEnrollActions.EnrollFailAction(error)))
    )
  );

  enrollSuccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydEnrollActions.ENROLL_SUCCESS),
      map((action: kydEnrollActions.EnrollSuccessAction) => action.payload),
      map(payload => payload.map(kydConfirmation => kydConfirmation.risk.policy)),
      map(policies => policies.map(policy => policy.policyNumber)),
      map(policyNumbers => {
        this.store.dispatch(new kydActions.RefreshAction(policyNumbers));
        this.store.dispatch(new kydEligibilityActions.RefreshEligibilityAction(policyNumbers));
        return new kydEnrollActions.EnrollCompleteAction();
      }),
      catchError(error => observableOf(new kydEnrollActions.EnrollFailAction(error)))
    )
  );

  unenroll$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydEnrollActions.UNENROLL),
      map((action: kydEnrollActions.UnenrollAction) => action.payload),
      mergeMap((payload: KydUnenrollRequest[]) =>
        from(payload).pipe(
          mergeMap(
            request => this.kydService.deleteEnrollment(request.enrollmentId),
            (request, response) => request.policyNumber
          ),
          toArray()
        )
      ),
      map(policyNumbers => new kydEnrollActions.UnenrollSuccessAction(policyNumbers)),
      catchError(error => of(new kydEnrollActions.UnenrollFailAction(error)))
    )
  );

  unenrollSuccess$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydEnrollActions.UNENROLL_SUCCESS),
      map((action: kydEnrollActions.UnenrollSuccessAction) => action.payload),
      map(policyNumbers => {
        this.store.dispatch(new kydActions.RefreshAction(policyNumbers));
        this.store.dispatch(new kydEligibilityActions.RefreshEligibilityAction(policyNumbers));
        return new kydEnrollActions.UnenrollCompleteAction();
      }),
      catchError(error => observableOf(new kydEnrollActions.UnenrollFailAction(error)))
    )
  );

  constructor(
    private store: Store<fromRoot.RootState>,
    private kydService: KydService,
    private action$: Actions
  ) {}
}
