import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { ProfileActions, ProfileUtilService } from '@amfam/profile/data-access';
import { PartyPhone, PartyPhoneUsageCode } from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { CopyService } from '@amfam/shared/utility/shared-services';
import { DsModalService, ToasterService } from '@amfam/ui-kit';

@Component({
  selector: 'ds-manage-phone-numbers',
  templateUrl: './manage-phone-numbers.component.html',
  styleUrls: ['./manage-phone-numbers.component.scss']
})
export class ManagePhoneNumbersComponent implements OnInit {
  phones: PartyPhone[];
  flattenedPhones: PartyPhone[];
  primaryPhone: PartyPhone;
  modalId = 'deletePhnNum';
  phoneToDelete: PartyPhone;
  firstPhoneNumberInList: PartyPhone[] = [];
  allPrimaryCheck: boolean;
  private stop$ = new Subject<void>();

  constructor(
    private store: Store,
    private modalService: DsModalService,
    private profileUtilService: ProfileUtilService,
    private toasterService: ToasterService,
    private copyService: CopyService
  ) {}

  ngOnInit() {
    this.store
      .select(userQuery.getPhones)
      .pipe(
        filter(phones => !!phones),
        takeUntil(this.stop$)
      )
      .subscribe(phones => {
        this.phones = phones;
        this.flattenedPhones = this.profileUtilService.flattenPhones(phones);
        /*
         Every time when phones gets updated, if first phone number is in array then
         put it at top of list else list is shown as it is.
         */
        if (this.firstPhoneNumberInList.length) {
          this.putFirstPhoneNumberOnTop(this.firstPhoneNumberInList);
        }
        /* For the first time when there is no first phone number and the list contains phones
        then first set primary as first phone number else set the one which is already on top
        */
        if (this.flattenedPhones.length && !this.firstPhoneNumberInList.length) {
          this.firstPhoneNumberInList = this.flattenedPhones.filter(phn => phn.primaryIndicator);
          if (!this.firstPhoneNumberInList.length) {
            this.firstPhoneNumberInList = this.flattenedPhones.filter(
              phn => phn.phoneId === this.flattenedPhones[0].phoneId
            );
          }
          this.putFirstPhoneNumberOnTop(this.firstPhoneNumberInList);
        }
        this.allPrimaryCheck = true;
        for (const phone of this.flattenedPhones) {
          if (!phone.primaryIndicator) {
            this.allPrimaryCheck = false;
            break;
          }
        }
      });
  }

  // create a new phone entry
  onCreate(data: PartyPhone) {
    let matchFound = false;
    if (!_get(data, 'extension')) {
      data = Object.assign({}, data, {
        extension: ''
      });
    }
    matchFound = this.flattenedPhones.some(phone => {
      if (phone.areaCode === data.areaCode && phone.phoneNumber === data.phoneNumber) {
        if (
          data.contactMethodUsages[0].typeOfUsageCode.toLowerCase() === 'work' &&
          phone.contactMethodUsages[0].typeOfUsageCode.toLowerCase() === 'work'
        ) {
          if (_get(phone, 'extension') && _get(data, 'extension') === _get(phone, 'extension')) {
            return true;
          } else if (!_get(phone, 'extension') && !_get(data, 'extension')) {
            return true;
          }
        } else if (data.contactMethodUsages[0].typeOfUsageCode.toLowerCase() === 'other') {
          if (
            _get(data.contactMethodUsages[0], 'descriptionForOtherType') ===
            _get(phone.contactMethodUsages[0], 'descriptionForOtherType')
          ) {
            return true;
          }
        } else if (
          data.contactMethodUsages[0].typeOfUsageCode.toLowerCase() ===
          phone.contactMethodUsages[0].typeOfUsageCode.toLowerCase()
        ) {
          return true;
        }
      }
    });
    if (matchFound) {
      this.toasterService.pop(
        'error',
        this.copyService.get('profile.managePhoneNumbers', 'toasterServicePhoneAlreadyInUse')
      );
    } else {
      // start the spinner
      this.store.dispatch(ProfileActions.MakeRequestAction());
      // send the request to add the new email
      this.store.dispatch(
        ProfileActions.AddContactEntryAction({
          data,
          methodType: 'phones'
        })
      );
    }
  }

  putFirstPhoneNumberOnTop(firstPhoneNumberList: PartyPhone[]) {
    const topPhoneList = this.flattenedPhones.filter(
      phone => _get(phone, 'phoneId') === _get(firstPhoneNumberList[0], 'phoneId')
    );
    this.flattenedPhones = this.flattenedPhones.filter(
      phone => _get(phone, 'phoneId') !== _get(firstPhoneNumberList[0], 'phoneId')
    );
    if (topPhoneList.length) {
      for (const phone of topPhoneList) {
        this.flattenedPhones.unshift(phone);
      }
    }
  }

  // change the values of some of the properties of a phone entry
  onMakePrimary(phoneNumber: PartyPhone) {
    const phone = this.phones.find(ph => phoneNumber.phoneId === ph.phoneId);
    this.store
      .select(userQuery.getPrimaryPhone)
      .pipe(take(1))
      .subscribe(primaryPhone => {
        this.primaryPhone = primaryPhone;
        let newEntry: PartyPhone = Object.assign({}, phone, {
          primaryIndicator: true
        });
        newEntry = PartyPhone.fromJson(newEntry);
        // if there is no old entry this will be `undefined`
        let oldEntry: PartyPhone = this.phones.find(phn => {
          const oldPrimaryId: string = _get(this.primaryPhone, 'phoneId')
            ? this.primaryPhone.phoneId
            : '';
          return phn.phoneId === oldPrimaryId;
        });
        oldEntry = oldEntry ? Object.assign({}, oldEntry, { primaryIndicator: false }) : oldEntry;
        oldEntry = oldEntry ? PartyPhone.fromJson(oldEntry) : oldEntry;
        const data: { newEntry: PartyPhone; oldEntry: PartyPhone } = { newEntry, oldEntry };
        // start the spinner
        this.store.dispatch(ProfileActions.MakeRequestAction());
        // send the request to change the phone
        this.store.dispatch(
          ProfileActions.ChangeContactEntryAction({
            data,
            methodType: 'phones'
          })
        );
      });
  }

  // delete a phone entry
  onDelete(phoneToDelete: PartyPhone) {
    // The parameter phoneToDelete is flattened phone which has single usage code.
    // Find the phone from which it is flattened.
    const phone = this.phones.find(ph => phoneToDelete.phoneId === ph.phoneId);
    if (phone.contactMethodUsages.length === 1) {
      // start the spinner
      this.store.dispatch(ProfileActions.MakeRequestAction());
      // send the request to delete the phone
      this.store.dispatch(
        ProfileActions.DeleteContactEntryAction({
          data: phone.phoneId,
          methodType: 'phones'
        })
      );
    } else {
      const oldEntry = phone;
      let contactMethodUsages = new Array<{
        typeOfUsageCode: PartyPhoneUsageCode;
        descriptionForOtherType?: string;
      }>();
      if (phoneToDelete.contactMethodUsages[0].typeOfUsageCode.toLowerCase() === 'other') {
        contactMethodUsages = phone.contactMethodUsages.filter(
          usage =>
            usage.typeOfUsageCode.toLowerCase() !== 'other' ||
            (usage.typeOfUsageCode.toLowerCase() === 'other' &&
              usage.descriptionForOtherType !==
                phoneToDelete.contactMethodUsages[0].descriptionForOtherType)
        );
      } else {
        contactMethodUsages = phone.contactMethodUsages.filter(
          usage => usage.typeOfUsageCode !== phoneToDelete.contactMethodUsages[0].typeOfUsageCode
        );
      }
      const newEntry = Object.assign({}, phone, {
        contactMethodUsages: contactMethodUsages
      });
      const data: { newEntry: PartyPhone; oldEntry: PartyPhone } = { newEntry, oldEntry };
      // start the spinner
      this.store.dispatch(ProfileActions.MakeRequestAction());
      // send the request to change the phone
      this.store.dispatch(
        ProfileActions.ChangeContactEntryAction({
          data,
          methodType: 'phones'
        })
      );
    }
    this.closeModal();
  }

  onDeleteConfirm(phone: PartyPhone) {
    this.phoneToDelete = phone;
    this.modalService.open(this.modalId);
  }

  closeModal() {
    this.modalService.close(this.modalId);
  }
}
