import * as fromOpportunity from '../../+state';
import * as OpportunityActions from '../../+state/opportunity.action';

import {
  ButtonAnalytic,
  CopyService,
  OpportunityContent,
  OpportunityContentWithRecommendationId
} from '@amfam/shared/utility/shared-services';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics/src';
import { Agent } from '@amfam/shared/utility/agent';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { OpportunityAnalytics } from '../../models/opportunity-analytic-contants';

@Component({
  selector: 'ds-opportunities-overview',
  templateUrl: './opportunities-overview.component.html',
  styleUrls: ['./opportunities-overview.component.scss']
})
export class OpportunitiesOverviewComponent implements OnInit, OnDestroy {
  // This component takes the input of agents, which are active.
  @Input() agents: Agent[];

  @Input() opportunitiesContent: (OpportunityContent & OpportunityContentWithRecommendationId)[] =
    [];

  public title: string;

  displayedOpportunitiesLengthSlice = 6;
  isAuthorized$: Observable<boolean>;
  opportunityContent$: Observable<OpportunityContent>;

  // Private variables
  private stop$: Subject<void> = new Subject<void>();
  constructor(
    private copyService: CopyService,
    private store: Store,
    private analyticsFacade: AnalyticsFacade,
    public roleService: ImpersonateRolesService
  ) {}

  ngOnInit() {
    this.isAuthorized$ = this.roleService.isAuthorized('myopportunities_cta');
    this.title =
      this.opportunitiesContent.length > 1
        ? this.copyService.getCopy('shared.opportunitiesTitle')
        : this.copyService.getCopy('shared.opportunityTitle');
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  /**
   * @param: None
   * @returns: Void
   * @description: When the user clicks on the learn more button on the opportunity card, this function would be
   * invoked , which should dispatch an action to take them to the detail page for the opportunity.
   */
  learnMore(recommendationId: string): void {
    // Send back the analytic data
    this.buildButtonAnalyticData(OpportunityAnalytics.clickOpportunityLearnMore, recommendationId)
      .pipe(take(1))
      .subscribe(analytticsRes => this.analyticsFacade.trackButtonClick(analytticsRes));
    this.store.dispatch(
      new OpportunityActions.OpportunitiesDetailPageNavigationAction(recommendationId)
    );
  }

  /**
   *
   * @param analyticData Incoming analytic model containing a link string based on the specific criterion
   * @param productType Product type of the selected opportunity
   * @param subProductType Sub Producttype of the selected opportunity/
   * @returns ButtonAnalytic : returns a ButtonAnalytic model containing a link string with the concatenated product type
   * and sub product type.
   */
  private buildButtonAnalyticData(
    analyticData: ButtonAnalytic,
    recommendationId: string
  ): Observable<ButtonAnalytic> {
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
      take(1),
      mergeMap(
        (opportunity): Observable<ButtonAnalytic> =>
          of(
            Object.assign({}, analyticData, {
              link:
                'MyAccount:' + opportunity.content.analyticsInfo.pageInfo + ':' + analyticData.link
            })
          )
      )
    );
  }
}
