import { Action, createReducer, on } from '@ngrx/store';

import { UserAnalyticParams } from '../models/analytics.model';
import * as AnalyticsActions from './analytics.actions';

export const analyticsFeatureKey = 'analytics';

export interface State {
  loading: boolean;
  user: UserAnalyticParams;
  adobeAnalyticsLoaded: boolean;
  googleAnalyticsLoaded: boolean;
  adobeAnalyticsError: boolean;
  googleAnalyticsError: boolean;
  gtm: any;
}

export const initialState: State = {
  loading: false,
  user: null,
  adobeAnalyticsLoaded: false,
  googleAnalyticsLoaded: false,
  adobeAnalyticsError: false,
  googleAnalyticsError: false,
  gtm: {}
};

const analyticsReducer = createReducer(
  initialState,

  on(AnalyticsActions.loadAdobeAnalytics, state => ({
    ...state,
    loading: true
  })),

  on(AnalyticsActions.loadAdobeAnalyticsSuccess, state => ({
    ...state,
    loading: false,
    adobeAnalyticsLoaded: true,
    adobeAnalyticsError: false
  })),

  on(AnalyticsActions.loadAdobeAnalyticsFailure, state => ({
    ...state,
    loading: false,
    adobeAnalyticsError: true
  })),

  on(AnalyticsActions.loadGoogleAnalytics, state => ({
    ...state,
    loading: true
  })),

  on(AnalyticsActions.loadGoogleAnalyticsSuccess, state => ({
    ...state,
    loading: false,
    googleAnalyticsLoaded: true,
    googleAnalyticsError: false
  })),

  on(AnalyticsActions.storeGtmDetails, (state, action) => ({
    ...state,
    gtm: {
      ...state.gtm,
      ...action.payload
    }
  })),

  on(AnalyticsActions.loadGoogleAnalyticsFailure, state => ({
    ...state,
    loading: false,
    googleAnalyticsError: true
  })),

  on(AnalyticsActions.setUserInfo, (state, action) => ({
    ...state,
    user: action.user
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return analyticsReducer(state, action);
}
