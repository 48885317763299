<card cardClasses="profile-card gray margin-16">
  <div card-header class="flex pad-10">
    <div class="flex items-center justify-space-between flex-1">
      <div>
        <h3>{{ name }}</h3>
        @if (canShowStatus) {
          <div>
            <span content [contentPath]="'profile.profileCard.status'"></span>:
            <span class="status">
              {{ !isShellAccount ? 'Active' : 'Shell Account Pending' }}
            </span>
          </div>
        }
      </div>
      @if (!forgerockEnabled) {
        <div class="flex items-center justify-center text-right">
          <h4 data-cy="myAccountUserIDHeader">
            <span content [contentPath]="'profile.profileCard.userId'"></span>:
            <span> {{ isShellAccount ? '' : userID }} </span>
          </h4>
        </div>
      }
    </div>
  </div>
  <div card-content>
    <div class="flex flex-direction-column">
      <div>
        <h4
          data-cy="myAccountEmailHeader"
          content
          [contentPath]="'profile.profileCard.myAccountEmail'"
        ></h4>
        <p class="primary-email" data-cy="emailAddress">{{ emailAddress }}</p>
      </div>
      <div>
        <div>
          <h4 content [contentPath]="'profile.profileCard.businessName'"></h4>
          <p class="business-name" data-cy="businessName">{{ businessName }}</p>
        </div>
      </div>
    </div>
  </div>
</card>
