import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { get as _get, template } from 'lodash';
import { filter, map, mergeMap, Observable, of, take, tap, withLatestFrom } from 'rxjs';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { CapitalizePipe } from '@amfam/shared/ui/pipes';
import {
  ButtonAnalytic,
  CopyService,
  OpportunityContent,
  SelectionType
} from '@amfam/shared/utility/shared-services';
import { DockingBarService } from '@amfam/ui-kit';

import * as fromOpportunity from '../../+state';
import * as OpportunityActions from '../../+state/opportunity.action';
import { OpportunityAnalytics } from '../../models/opportunity-analytic-contants';
import { OpportunityService } from '../../services/opportunity.service';

@Component({
  selector: 'ds-opportunities-detail-selection-page',
  templateUrl: './opportunities-detail-selection-page.component.html',
  styleUrls: ['./opportunities-detail-selection-page.component.scss']
})
export class OpportunitiesDetailSelectionPageComponent implements OnInit {
  opportunityContent$: Observable<OpportunityContent>;
  opportunitySelectionTypes$: Observable<SelectionType[]>;
  recommendationId: string;
  private opportunityType$: Observable<string>;
  constructor(
    private dockingService: DockingBarService,
    private store: Store,
    private route: ActivatedRoute,
    private opportunityService: OpportunityService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade
  ) {
    this.dockingService.registerHeading('My Opportunity');
  }

  ngOnInit(): void {
    this.opportunityType$ = this.route.params.pipe(
      take(1),
      map(param => <string>param['id'])
    );
    this.opportunityContent$ = this.opportunityType$.pipe(
      mergeMap(opportunityType =>
        this.opportunityService.getRecommendationIdByOpportunityType(opportunityType)
      ),
      tap(recommendationId => (this.recommendationId = recommendationId)),
      mergeMap(recommendationId =>
        this.store.pipe(
          select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
          withLatestFrom(this.opportunityType$),
          map(([opportunity, opportunityType]) => {
            if (!opportunity) {
              return (<OpportunityContent[]>this.copyService.getCopy('opportunity')).find(
                opportunityFrmCopy => opportunityFrmCopy.opportunityProductType === opportunityType
              );
            }
            return opportunity;
          })
        )
      ),
      filter(opportunity => !!opportunity),
      map(opportunity => _get(opportunity, 'content', opportunity)),
      map(content => this.opportunityService.mapContent([content])[0])
    );

    this.opportunitySelectionTypes$ = this.opportunityContent$.pipe(
      map(content => (content.types ? content.types : null))
    );
  }

  onCardSelected(code: string) {
    // Send back the analytic data
    this.buildButtonAnalyticData(
      OpportunityAnalytics.clickOpportunityLearnMoreWithType,
      this.recommendationId,
      code
    )
      .pipe(take(1))
      .subscribe(analytticsRes => {
        this.analyticsFacade.trackButtonClick(analytticsRes);
      });

    this.store.dispatch(
      new OpportunityActions.OpportunitiesDetailPageNavigationFromSelectionPageAction({
        recommendationId: this.recommendationId,
        type: code
      })
    );
  }

  private buildButtonAnalyticData(
    analyticData: ButtonAnalytic,
    recommendationId: string,
    code: string
  ): Observable<ButtonAnalytic> {
    return this.store.pipe(
      select(fromOpportunity.getOpportunityByRecommendationId, recommendationId),
      take(1),
      mergeMap(
        (opportunity): Observable<ButtonAnalytic> =>
          of(
            Object.assign({}, analyticData, {
              link:
                'MyAccount:' +
                opportunity.content.analyticsInfo.pageInfo +
                ':' +
                template(analyticData.link)({ type: new CapitalizePipe().transform(code) })
            })
          )
      )
    );
  }
}
