import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';

import { Option } from './option.model';

@Component({
  selector: 'ds-radio-group',
  templateUrl: './ds-radio-group.component.html',
  styleUrls: ['./ds-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DsRadioGroupComponent),
      multi: true
    }
  ]
})
export class DsRadioGroupComponent implements ControlValueAccessor, OnChanges {
  @Input() options: Option[];
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() control: string;
  @Input() setDisableState = false;
  @Input() disableRadioControl = false;
  @Input() selectedAnswer: string;
  @Input() unselectAll: boolean;
  @Output() optionSelected = new EventEmitter();

  set value(val) {
    this.onChange(val);
    this.onTouched(val);
  }
  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnChanges() {
    if (this.setDisableState) {
      this.parentFormGroup.disable();
    }
    if (this.disableRadioControl) {
      this.parentFormGroup.controls[this.control].disable();
    } else {
      this.parentFormGroup.controls[this.control].enable();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  toggleDynamicOption(option: Option) {
    this.parentFormGroup.controls[this.control].setValue(option);
    this.optionSelected.emit(option);
  }
}
