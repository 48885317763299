<!-- Alert Bar Start -->
@if (alert) {
  <section
    [ngClass]="alert.alertBg"
    aria-label="info dialog"
    aria-labelledby="alertMessage"
    [class]="this.applyMargin ? 'ds-alert' : 'ds-alert noMargin'"
    role="alert"
  >
    <!-- Alert Icon Start -->
    @if (icon) {
      <div class="ds-alert__icon" aria-hidden="true">
        <span [ngClass]="alert.alertIcon"></span>
      </div>
    }
    <!-- Alert Icon End -->
    <!-- Alert Text Start -->
    <div class="ds-alert__text" id="alertMessage">
      @if (title) {
        <strong class="title">{{ title }}</strong>
      }
      @if (message) {
        <p [innerHTML]="message" class="message"></p>
      }
    </div>
    <!-- Alert Text End -->
    <!-- Alert Dismiss Start -->
    @if (dismiss) {
      <div class="ds-alert__dismiss">
        <a (click)="dismissEvent.emit()" title="Close"></a>
      </div>
    }
    <!-- Alert Close End -->
    <!-- Alert Action Start -->
    @if (action) {
      <div class="ds-alert__action">
        <!--Primary Button-->
        @if (!!primaryButtonName) {
          <button
            ds-button
            (click)="primaryButtonEvent.emit()"
            [disabled]="disabled"
            data-cy="alertPrimaryButton"
          >
            {{ primaryButtonName }}
          </button>
        }
        <!--Secondary Button-->
        @if (!!secondaryButtonName) {
          <button
            ds-button
            [ngClass]="'-outline-white'"
            (click)="secondaryEvent.emit()"
            [disabled]="disabled"
            data-cy="secondaryButton"
          >
            {{ secondaryButtonName }}
          </button>
        }
      </div>
    }
    <!-- Alert Action End -->
  </section>
}
<!-- Alert Bar End -->
