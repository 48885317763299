import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventDispatcherService {
  noDocumentModal$ = new Subject<string>();
  logout$ = new Subject<void>();

  openNoDocumentModal(action) {
    this.noDocumentModal$.next(action);
  }

  logout() {
    this.logout$.next();
  }
}
