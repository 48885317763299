import {
  RetrievePreferenceAdapter,
  UnassociatedRiskAdapterService
} from '@amfam/billing/shared/util';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import {
  ApiStatus,
  AutopayAnalyticModel,
  BillAccountRegistration,
  BillingPreference,
  DecoratedPreference,
  DeletePreferencesRequest,
  DeletePreferencesResponse,
  EntityReferences,
  ONLINE_BILLING,
  OldUpdatePreferencesRequest,
  PartialStatus,
  PolicyList,
  RetrievePreferencePayload,
  RetrievePreferenceResponse,
  UnassociatedRiskDetailSuccess,
  UpdatePreferencesRequest,
  UpdatePreferencesResponse,
  UpdateRegistrationPayload,
  UpdateRegistrationResponse
} from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import {
  EventAnalytic,
  PageAnalytic
} from '@amfam/shared/utility/shared-services/src/lib/analytics/analytics.model';
import { ConfigService } from '@amfam/shared/utility/shared-services/src/lib/config';
import { CopyService } from '@amfam/shared/utility/shared-services/src/lib/copy/copy.service';
import { UtilService } from '@amfam/shared/utility/shared-services/src/lib/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Store
import { Store } from '@ngrx/store';
// date-fns
import { addDays, format, subMonths } from 'date-fns';
import { get as _get, isEqual as _isEqual, some as _some } from 'lodash';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable()
export class BillAccountsService {
  constructor(
    private store: Store<any>,
    private http: HttpClient,
    private config: ConfigService,
    private retrievepreferenceAdapter: RetrievePreferenceAdapter,
    private unassociatedBaRiskAdapter: UnassociatedRiskAdapterService,
    private copyService: CopyService,
    private analyticsFacade: AnalyticsFacade,
    private utilService: UtilService
  ) {}

  private getBillingApiPath(): string {
    return this.config.get('billingApi');
  }

  private payorOboPath(): string {
    return this.config.get('payoroboApi');
  }

  private getCustomerDetails(): { cdhid: string; waid: string; isCustomer: boolean } {
    let cdhid: string, waid: string, isCustomer: boolean;
    this.store.select(userQuery.getUserState).subscribe(user => {
      if (user !== null && user.waid) {
        cdhid = user.customerId;
        waid = user.waid;
        isCustomer = user.isCustomer;
      }
    });
    return { cdhid, waid, isCustomer };
  }

  public getBillAccounts() {
    const byCdh = this.http
      .get(
        this.getBillingApiPath() +
          'billaccounts?cdhid=' +
          this.getCustomerDetails().cdhid +
          '&associationRole=NAMED_INSURED'
      )
      .pipe(
        catchError(error => {
          // If we come accross a 404, return an empty array. Service returns a 404 if there are no bill accounts.
          // Any other error continue to throw the error to be handled in global catch.
          if (error && String(error.status.code) === '404') {
            return of([]);
          } else {
            throw error;
          }
        })
      );

    const byWaid = this.http
      .get(this.getBillingApiPath() + 'billaccounts?waid=' + this.getCustomerDetails().waid)
      .pipe(retry(1));

    // TODO: Check with gateway if this is a valid change.
    if (this.getCustomerDetails().isCustomer || !!this.getCustomerDetails().cdhid) {
      return forkJoin([byCdh, byWaid]);
    }
    {
      return forkJoin([of(true), byWaid]);
    }
  }

  public getBillAccount(payload: any) {
    const optionalParam: string = payload.policyNumber
      ? '&policyNumber=' + payload.policyNumber
      : '';
    return this.http
      .get(
        this.getBillingApiPath() +
          'billaccounts/' +
          payload.billAccountNumber +
          '?includePolicyInfo=true' +
          optionalParam
      )
      .pipe(retry(1));
  }
  public getScheduledPayments(billAccountNumber: string) {
    return this.http
      .get(this.getBillingApiPath() + 'billaccounts/' + billAccountNumber + '/paymentschedule')
      .pipe(retry(1));
  }

  public getStatementHistory(billAccountNumber: string, billingSystem: string) {
    const startDate = format(subMonths(new Date(), 25), 'MM/DD/YYYY');
    const endDate = format(addDays(new Date(), 7), 'MM/DD/YYYY');
    const options = {
      params: new HttpParams()
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('documentType', 'Billing Statement')
        .set('billingSystem', billingSystem)
    };
    return this.http.get(
      `${this.getBillingApiPath()}billaccounts/${billAccountNumber}/documents`,
      options
    );
  }

  public canRegister(accountNumber: string, policyNumber: string) {
    const options = {
      params: new HttpParams()
        .set('includePolicyInfo', 'true')
        .set('policyNumber', this.utilService.extractPolicyNumber(policyNumber))
    };

    return this.http.get(`${this.getBillingApiPath()}billaccounts/${accountNumber}`, options).pipe(
      map((responseJson: any) => {
        try {
          /*
            billAccount can register if billingMethod is OLB and eligibleStatus is true.
          */
          responseJson = Object.assign({}, responseJson, {
            billAccountNumber: accountNumber,
            policyNumber: policyNumber,
            canRegister: _some(responseJson.billingAccount.eligibleBillingMethods, {
              billingMethod: BillAccountRegistration.ONLINE_BILLING,
              eligibleStatus: 'true'
            })
          });
        } catch (error) {
          responseJson = Object.assign({}, responseJson, { canRegister: false });
        }
        return responseJson;
      })
    );
  }

  public updateRegistration(payload: any): Observable<boolean> {
    const registrationData = payload.registrationData;
    const isUnregister = payload.isUnregister;
    let apiPayload: UpdateRegistrationPayload | any;

    /**
     * billingPreference object is required in apiPayload when Registering bill account ONLY.
     */
    let billingPreference: BillingPreference;
    if (!isUnregister) {
      billingPreference = {
        billingPreference: {
          dueDateReminder: registrationData.reminder,
          accountNickname: registrationData.accountName
        }
      };
    }

    apiPayload = {
      actionIndicator: isUnregister ? 'UNREGISTER' : 'REGISTER',
      policyNumber: registrationData.policyNumber,
      billAccount: {
        billingMethod: isUnregister ? 'UNSPECIFIED' : 'ONLINE BILLING',
        // Spread operator to add object to payload conditionally.
        ...(!isUnregister ? billingPreference : {})
      }
    };

    return this.http
      .put<UpdateRegistrationResponse>(
        this.getBillingApiPath() + 'billaccounts/' + payload.billAccountNumber,
        apiPayload
      )
      .pipe(
        map(response => {
          return this.decorateUpdateRegistrationCall(response, isUnregister);
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getPreferences(
    payload: RetrievePreferencePayload
  ): Observable<DecoratedPreference | ApiStatus> {
    return this.http
      .get<RetrievePreferenceResponse>(
        this.getBillingApiPath() + 'billaccounts/' + payload.billAccountNumber + '/preferences'
      )
      .pipe(
        map(preference => {
          return this.decorateRetrivePreferencesCall(preference, payload);
        }),
        catchError(err => {
          return throwError(err);
        })
      )
      .pipe(retry(1));
    // TODO: Fix this retry , this is making a extra call when there is a partial success.
  }

  public updatePreferences(
    preferencesData: OldUpdatePreferencesRequest | UpdatePreferencesRequest
  ) {
    return this.http.put(
      this.getBillingApiPath() +
        'billaccounts/' +
        preferencesData.billAccountNumber +
        '/preferences',
      preferencesData.payload
    );
  }

  // Updates billing preferences such as Account nick name, Duedate reminder .
  public updateBillingPreferences(
    updatePreferenceRequest: UpdatePreferencesRequest
  ): Observable<boolean | ApiStatus> {
    return this.http
      .put<UpdatePreferencesResponse>(
        this.getBillingApiPath() +
          'billaccounts/' +
          updatePreferenceRequest.billAccountNumber +
          '/preferences',
        updatePreferenceRequest.payload
      )
      .pipe(
        map(updateBillingPreferencesResponse => {
          return this.decorateUpdateBillingPreferencesCall(updateBillingPreferencesResponse.status);
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  // Updates delivery preferences such as paper, email or  both.
  public updateDeliveryPreference(
    updatePreferenceRequest: UpdatePreferencesRequest
  ): Observable<boolean | ApiStatus> {
    return this.http
      .put<UpdatePreferencesResponse>(
        this.getBillingApiPath() +
          'billaccounts/' +
          updatePreferenceRequest.billAccountNumber +
          '/preferences',
        updatePreferenceRequest.payload
      )
      .pipe(
        map(updateDeliveryPreferenceResponse => {
          return this.decorateUpdateDeliveryPreferenceCall(updateDeliveryPreferenceResponse.status);
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public deletePreferences(payload: DeletePreferencesRequest): Observable<boolean | {}> {
    return this.http
      .delete<DeletePreferencesResponse>(
        this.getBillingApiPath() + 'billaccounts/' + payload.billAccountNumber + '/preferences'
      )
      .pipe(
        map(response => {
          return this.decorateDeletePreferencesCall(response);
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  private decorateUpdateRegistrationCall(response, isUnregister: boolean): boolean {
    const code = Number(_get(response, 'status.code'));
    if (code === 200) {
      // Return value is noPartialFailure
      return true;
    } else if (code === 207) {
      // The partial fail case is only for unregister
      if (!isUnregister) {
        return true;
      }
      // Get entity references and use to detect fail scenario
      const partialStatuses: PartialStatus[] = _get(response, 'status.partialStatuses');
      const payloadEntityReferences: string[] = partialStatuses.map(status => {
        return status.payloadEntityReference;
      });
      const preferenceFailed =
        payloadEntityReferences.includes(EntityReferences.PREFERENCES) ||
        payloadEntityReferences.includes(EntityReferences.PAPER) ||
        payloadEntityReferences.includes(EntityReferences.EMAIL);
      if (preferenceFailed) {
        // Unregister succeeded but preferences failed, we have a partial failure
        return false;
      }
      // Consider 207s successful if they do not meet the fail criteria
      return true;
    }
    // All other scenarios considered failure
    throw this.markAsError(response.status, 'Update registration error');
  }

  public getUnAssociatedBillAccountRiskInformation(policyList: PolicyList): Observable<PolicyList> {
    let policies: string;
    policyList.forEach(policy => {
      policies = policies ? policies + ',' + policy.policyNumber : policy.policyNumber;
    });
    return this.http
      .get<UnassociatedRiskDetailSuccess>(this.payorOboPath() + 'policies?policyNumber=' + policies)
      .pipe(
        map((response: UnassociatedRiskDetailSuccess) =>
          this.unassociatedBaRiskAdapter.adapt(response, policyList)
        ),
        catchError(error => throwError(error))
      );
  }

  private decorateRetrivePreferencesCall(
    preference: RetrievePreferenceResponse,
    billAccount: RetrievePreferencePayload
  ): DecoratedPreference | ApiStatus {
    // Get status code.
    const code = Number(_get(preference, 'status.code'));
    if (code === 200) {
      return this.retrievepreferenceAdapter.adapt(preference);
    } else if (code === 207) {
      // billAccount should be associated.
      if (billAccount.associated) {
        const requiredPayloadEntityReferences: string[] = this.copyService.get(
          'billing',
          'requiredPayloadEntityReferences'
        );

        const partialStatuses: PartialStatus[] = _get(preference, 'status.partialStatuses');

        // Extract payload entity references from API to match with requiredPayloadEntityReferences.
        const payloadEntityReferences: string[] = partialStatuses.map(status => {
          return status.payloadEntityReference;
        });

        // Returns boolean if payloadEntityReferences from API matches with requiredPayloadEntityReferences(see copy file).
        const isPayloadEntityReferenceMatch = _isEqual(
          requiredPayloadEntityReferences.sort(),
          payloadEntityReferences.sort()
        );

        // billing method is online billing
        if (billAccount.billingMethod === ONLINE_BILLING) {
          // internal status code should be 403
          const internalCodeMatch: boolean = !!partialStatuses.find(
            partialStatus => partialStatus.status.code === 403 || partialStatus.status.code === 404
          );

          if (isPayloadEntityReferenceMatch && internalCodeMatch) {
            return this.retrievepreferenceAdapter.adapt(preference);
          }
        } else {
          // billing method is not online billing
          if (isPayloadEntityReferenceMatch) {
            return this.retrievepreferenceAdapter.adapt(preference);
          }
        }
      }
    }

    throw this.markAsError(preference.status, 'Retrieve preferences partial error');
  }

  private decorateUpdateBillingPreferencesCall(status: ApiStatus): boolean {
    const code = Number(_get(status, 'code'));

    if (code === 200) {
      return true;
    } else if (code === 207) {
      const requiredPayloadEntityReferences: string[] = this.copyService.get(
        'billing',
        'requiredPayloadEntityReferences'
      );

      const partialStatuses: PartialStatus[] = _get(status, 'partialStatuses');

      // Extract payload entity references from API to match with requiredPayloadEntityReferences.
      const payloadEntityReferences: string[] = partialStatuses.map(partialStatus => {
        return partialStatus.payloadEntityReference;
      });

      // Returns boolean if payloadEntityReferences from API matches with requiredPayloadEntityReferences(see copy file).
      const isPayloadEntityReferenceMatch = _isEqual(
        requiredPayloadEntityReferences.sort(),
        payloadEntityReferences.sort()
      );
      if (isPayloadEntityReferenceMatch) {
        throw this.markAsError(status, 'Update billing preferences partial error');
      } else {
        return true;
      }
    }
    throw this.markAsError(status, 'Update billing preferences partial error');
  }

  private decorateUpdateDeliveryPreferenceCall(status: ApiStatus): boolean {
    const code = Number(_get(status, 'code'));
    if (code === 200) {
      return true;
    } else if (code === 207) {
      const requiredPayloadEntityReferences: string[] = this.copyService.get(
        'billing',
        'requiredPayloadEntityReferences'
      );

      const partialStatuses: PartialStatus[] = _get(status, 'partialStatuses');

      // Extract payload entity references from API to match with requiredPayloadEntityReferences.
      const payloadEntityReferences: string[] = partialStatuses.map(partialStatus => {
        return partialStatus.payloadEntityReference;
      });

      // Returns boolean if payloadEntityReferences from API matches with requiredPayloadEntityReferences(see copy file).
      const isPayloadEntityReferenceMatch = _isEqual(
        requiredPayloadEntityReferences.sort(),
        payloadEntityReferences.sort()
      );
      if (isPayloadEntityReferenceMatch) {
        return true;
      }
    }
    throw this.markAsError(status, 'Update delivery preferences partial error');
  }

  private decorateDeletePreferencesCall(response): boolean {
    // TODO: this function doesn't need to return anything, Abhi has ideas for the re-write
    const code = Number(_get(response, 'status.code'));
    if (code === 200) {
      // Return value is whether or not call was successful
      return true;
    } else if (code === 207) {
      // Get entity references and use to detect fail scenario
      const partialStatuses: PartialStatus[] = _get(response, 'status.partialStatuses');
      const payloadEntityReferences: string[] = partialStatuses.map(status => {
        return status.payloadEntityReference;
      });
      const isFail =
        payloadEntityReferences.includes(EntityReferences.PAPER) ||
        payloadEntityReferences.includes(EntityReferences.EMAIL);
      if (isFail) {
        throw this.markAsError(response.status, 'Delete preferences partial error');
      }
      // Consider 207s successful if they do not meet the fail criteria
      return true;
    }
    // All other scenarios considered failure
    throw this.markAsError(response.status, 'Delete preferences error');
  }

  private markAsError(statusObject: ApiStatus, reason: string): ApiStatus {
    return Object.assign({}, statusObject, {
      code: 500,
      reason: reason
    });
  }

  /**
   * @author Abhishek Singh
   * @param analyticPayload
   * @returns void
   * @description : Sends the analytic information related to multiple autopay setup.
   */
  public sendAnalytic(analyticPayload: AutopayAnalyticModel): void {
    let pageAnalytic: PageAnalytic;
    if (analyticPayload.pageAnalytic) {
      pageAnalytic = Object.assign({}, analyticPayload.pageAnalytic, {
        experience: '',
        primaryCategory: 'My Account',
        subCategory1: 'Billing Payments',
        subCategory2: 'Multiple Automatic Payments',
        subCategory3: ''
      });
    }
    if (analyticPayload.eventAnalytic) {
      const eventAnalytic: EventAnalytic = Object.assign({}, analyticPayload.eventAnalytic, {
        eventName: 'Multiple Automatic Payments'
      });
      return this.analyticsFacade.trackPageAndEvent(pageAnalytic, eventAnalytic);
    }
    return this.analyticsFacade.trackPage(pageAnalytic);
  }
}
