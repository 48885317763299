import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { get as _get } from 'lodash';

import * as fromPendingRegistrationNotifications from './pending-registration-notifications.reducer';
import * as fromPendingRegistrationState from './pending-registration.reducer';

export interface RegistrationState {
  pendingRegistrationState: fromPendingRegistrationState.PendingRegistrationEntityState;
  pendingRegistrationNotifications: fromPendingRegistrationNotifications.NotificationState;
}

export const billingRegistrationReducers: ActionReducerMap<RegistrationState> = {
  pendingRegistrationState: fromPendingRegistrationState.reducer,
  pendingRegistrationNotifications: fromPendingRegistrationNotifications.reducer
};

// TODO: Change this when the bill account registration is moved here.
const selectRegistrationState = createFeatureSelector<RegistrationState>('billing registrations');

const selectPendingRegistrationsLoaded = createSelector(
  selectRegistrationState,
  (state: RegistrationState) => !_get(state, 'pendingRegistrationNotifications.loading', true)
);

/**
 * Pending Registration Reducers
 */

export const selectPendingRegistrationIds = createSelector(
  selectRegistrationState,
  selectPendingRegistrationsLoaded,
  (state, isLoaded) =>
    isLoaded ? <string[]>fromPendingRegistrationState.selectIds(state.pendingRegistrationState) : []
);

export const selectPendingRegistrationEntities = createSelector(
  selectRegistrationState,
  selectPendingRegistrationsLoaded,
  (state, isLoaded) =>
    isLoaded ? fromPendingRegistrationState.selectEntities(state.pendingRegistrationState) : []
);

export const selectPendingRegistrationError = createSelector(
  selectRegistrationState,
  (state: RegistrationState) => !!_get(state, 'pendingRegistrationNotifications.error')
);

export const selectPendingRegistrations = createSelector(
  selectPendingRegistrationEntities,
  selectPendingRegistrationIds,
  (entities, ids) => ids.map(id => entities[id])
);

export const registrationsQuery = {
  selectPendingRegistrationIds,
  selectPendingRegistrationEntities,
  selectPendingRegistrationError,
  selectPendingRegistrations,
  selectRegistrationState
};
