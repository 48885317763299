import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get as _get } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@amfam/shared/utility/shared-services';

import { DashboardClaim } from '../+state/claim-dashboard.models';
import { mockResponse } from '../testing/claim-dashboard.mock';

@Injectable({
  providedIn: 'root'
})
export class ClaimDashboardService {
  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) {}

  getDashboardClaims(cdhId: string): Observable<DashboardClaim[]> {
    const endpoint = this.config.get('claimApi') + 'claimdash/' + cdhId;
    return this.http.get(endpoint).pipe(
      map(response => {
        return _get(response, 'dashboardClaims');
      })
    );
  }
}
