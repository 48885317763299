import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { addDays, format, subDays } from 'date-fns';
import { get as _get } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { BillAccountActions } from '@amfam/billing/billaccount/data-access';
import {
  PaymentMethodActions,
  PaymentMethodSelectors
} from '@amfam/billing/payment-method/data-access';
import {
  PaymentAccountActions,
  PaymentAccountSelectors
} from '@amfam/billing/paymentaccount/data-access';
import { PaymentService } from '@amfam/billing/shared/util';
import {
  ApiStatus,
  BillingPaymentPaths,
  GetRegisteredPaymentsResponse,
  INVALID_IMPERSONATION_STATUS_CODE,
  ScheduledPayment,
  SchedulePaymentPayloadModel,
  SubmitScheduledPaymentPayloadModel,
  UNAVAILABLE_PAYMENT_ID
} from '@amfam/shared/models';
import { fromRouterActions } from '@amfam/shared/utility/navigation';

import * as ScheduledPaymentActions from './schedulepayment.actions';

@Injectable()
export class ScheduledPaymentEffects {
  load$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsLoadType),
      switchMap(() => {
        const startDate = format(subDays(new Date(), 31), 'YYYY-MM-DD');
        const endDate = format(addDays(new Date(), 31), 'YYYY-MM-DD');
        return this.paymentService.getRegisteredPayments(startDate, endDate, false, true).pipe(
          map((response: GetRegisteredPaymentsResponse) => _get(response, 'payments', [])),
          map((payments: ScheduledPayment[]) =>
            // Normalize the billAccount number to be a string for future comparisons and consistancy across API calls

            payments.map(payment => {
              payment.billAccounts = payment.billAccounts.map(billAccount => {
                billAccount.billAccountNumber = String(billAccount.billAccountNumber);
                return billAccount;
              });
              return payment;
            })
          ),
          map((payments: ScheduledPayment[]) =>
            ScheduledPaymentActions.scheduledPaymentsLoadSuccess(payments)
          ),
          catchError(error => of(ScheduledPaymentActions.scheduledPaymentsLoadFail(error)))
        );
      })
    );
  });

  add$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ScheduledPaymentActions.scheduledPaymentsSubmit),
      map(action => action.payload),
      switchMap((payload: SubmitScheduledPaymentPayloadModel) =>
        this.paymentService.addScheduledPayment(payload).pipe(
          mergeMap(res => {
            const returnArray: Array<ScheduledPaymentActions.ScheduledPaymentsActions> = [];
            /**
             * Status code of '202304' will be returned if the user is impersonating and is
             * trying to schedule a payment without sufficient permission to do so.
             * In this case treat the response code of '202304' as a failure code and stop them from
             * moving forward.
             */
            if (_get(res, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
              returnArray.push(
                ScheduledPaymentActions.scheduledPaymentsSubmitFail(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      status: res.status
                    }
                  )
                )
              );
            } else {
              const conf = this.paymentService.buildAddScheduledPaymentConfirmationData(
                payload,
                res
              );
              // TODO - instead of only passing the requestJSON from the payload, pass the whole thing.
              returnArray.push(
                ScheduledPaymentActions.scheduledPaymentsSubmitSuccess(
                  Object.assign(
                    {},
                    {
                      request: Object.assign({}, payload.requestJson, {
                        paymentMethodType: payload.paymentMethodType,
                        correlationId: payload.correlationId
                      }),
                      response: res,
                      confirmation: conf
                    }
                  )
                ),
                ScheduledPaymentActions.scheduledPaymentsTruncate(UNAVAILABLE_PAYMENT_ID)
              );
            }
            return returnArray;
          }),
          catchError(error =>
            of(
              ScheduledPaymentActions.scheduledPaymentsSubmitFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: error
                  }
                )
              )
            )
          )
        )
      )
    );
  });

  // When adding a scheduled payment fails we need to cleanup the state (error state is alread in the store)

  addFailCleanup$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitFailType),
      map(() => ScheduledPaymentActions.scheduledPaymentsTruncate(UNAVAILABLE_PAYMENT_ID))
    );
  });

  edit$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ScheduledPaymentActions.scheduledPaymentsEdit),
      map(action => action.payload),
      switchMap((payload: SchedulePaymentPayloadModel) =>
        this.paymentService.editScheduledPayment(payload).pipe(
          mergeMap(response => {
            const returnArray: Array<ScheduledPaymentActions.ScheduledPaymentsActions> = [];
            /**
             * Status code of '202304' will be returned if the user is impersonating and is
             * trying to edit a payment without sufficient permission to do so.
             * In this case treat the response code of '202304' as a failure code and stop them from
             * moving forward.
             */
            if (_get(response, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
              returnArray.push(
                ScheduledPaymentActions.scheduledPaymentsEditFail(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      status: response.status
                    }
                  )
                )
              );
            } else {
              const conf = this.paymentService.buildEditScheduledPaymentConfirmationData(
                payload,
                response
              );
              // TODO - this router action should be handled by the component https://amfament.atlassian.net/browse/DRE-34426
              // eslint-disable-next-line ngrx/no-dispatch-in-effects
              this.store.dispatch(
                fromRouterActions.Go({
                  path: [BillingPaymentPaths.CONFIRMATION_PATH]
                })
              );
              returnArray.push(
                ScheduledPaymentActions.scheduledPaymentsEditSuccess(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      response: response,
                      confirmation: conf
                    }
                  )
                ),
                ScheduledPaymentActions.scheduledPaymentsCleanup(payload.paymentId),
                ScheduledPaymentActions.scheduledPaymentsLoad()
              );
            }
            return returnArray;
          }),
          catchError(error =>
            of(
              ScheduledPaymentActions.scheduledPaymentsEditFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: <ApiStatus>_get(error, 'status')
                  }
                )
              )
            )
          )
        )
      )
    );
  });

  // When editing / deleting a scheduled payment fails we need to cleanup the state (error state is alread in the store)

  editDeleteFailCleanup$ = createEffect(() => {
    return this.action$.pipe(
      ofType(
        ScheduledPaymentActions.scheduledPaymentsEditFail,
        ScheduledPaymentActions.scheduledPaymentsDeleteFail
      ),
      map(action =>
        ScheduledPaymentActions.scheduledPaymentsCleanup(action.payload.request.paymentId)
      )
    );
  });

  delete$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ScheduledPaymentActions.scheduledPaymentsDelete),
      map(action => action.payload),
      switchMap((payload: SchedulePaymentPayloadModel) =>
        this.paymentService.deleteScheduledPayment(payload).pipe(
          mergeMap(response => {
            const returnArray: Array<ScheduledPaymentActions.ScheduledPaymentsActions> = [];
            /**
             * Status code of '202304' will be returned if the user is impersonating and is
             * trying to edit a payment without sufficient permission to do so.
             * In this case treat the response code of '202304' as a failure code and stop them from
             * moving forward.
             */
            if (_get(response, 'status.messages[0].code') === INVALID_IMPERSONATION_STATUS_CODE) {
              returnArray.push(
                ScheduledPaymentActions.scheduledPaymentsDeleteFail(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      status: response.status
                    }
                  )
                )
              );
            } else {
              const conf = this.paymentService.buildDeleteScheduledPaymentConfirmationData(
                payload,
                response
              );
              // TODO - this should be handled by the component https://amfament.atlassian.net/browse/DRE-34426
              // eslint-disable-next-line ngrx/no-dispatch-in-effects
              this.store.dispatch(
                fromRouterActions.Go({
                  path: [BillingPaymentPaths.CONFIRMATION_PATH]
                })
              );
              returnArray.push(
                ScheduledPaymentActions.scheduledPaymentsDeleteSuccess(
                  Object.assign(
                    {},
                    {
                      request: payload,
                      response: response,
                      confirmation: conf
                    }
                  )
                ),
                ScheduledPaymentActions.scheduledPaymentsTruncate(payload.paymentId)
              );
            }
            return returnArray;
          }),
          catchError(error =>
            of(
              ScheduledPaymentActions.scheduledPaymentsDeleteFail(
                Object.assign(
                  {},
                  {
                    request: payload,
                    status: error
                  }
                )
              )
            )
          )
        )
      )
    );
  });

  paymentEditReloadPaymentAccount$ = createEffect(() => {
    return this.action$.pipe(
      ofType(
        ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsSubmitSuccessType,
        ScheduledPaymentActions.ScheduledPaymentsActionTypes.ScheduledPaymentsDeleteSuccessType
      ),
      concatLatestFrom(() => [
        this.store.select(PaymentAccountSelectors.selectPaymentAccountEntities),
        this.store.select(PaymentMethodSelectors.selectPaymentMethodEntities)
      ]),
      map(([action, , paymentMethodEntities]) => {
        const paymentMethods = paymentMethodEntities;
        const nickName = String(_get(action, 'payload.request.payment.paymentAccount.nickName'));
        if (nickName && paymentMethods && paymentMethods[nickName]) {
          const paymentAccountId = paymentMethods[nickName].paymentAccountId;
          return PaymentAccountActions.PaymentAccountsLoadDetail(paymentAccountId);
        } else {
          // Loading individual payment account didn't work, reload all of them
          return PaymentAccountActions.PaymentAccountsLoad();
        }
      })
    );
  });

  // TODO - We should only reload the payment accounts affected (if they switched payment accounts)

  paymentEditSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ScheduledPaymentActions.scheduledPaymentsEditSuccess),
      map(action => action.payload),
      map(() => PaymentAccountActions.PaymentAccountsLoad())
    );
  });

  // When payment accounts are edited we need to update the reference in any scheduled payments that use it
  // When payment accounts are removed we need to remove any payments that are associated with it
  // eslint-disable-next-line @typescript-eslint/naming-convention
  paymentAccount_edit_delete_success$ = createEffect(() => {
    return this.action$.pipe(
      ofType(
        PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsEditSuccessType,
        PaymentAccountActions.PaymentAccountActionTypes.PaymentAccountsDeleteSuccessType
      ),
      map(() => ScheduledPaymentActions.fromScheduledPaymentsActions.scheduledPaymentsLoad())
    );
  });

  // When payments are added, edited or deleted we need to query to reload the minimum due

  update_amd$ = createEffect(() => {
    return this.action$.pipe(
      ofType(
        ScheduledPaymentActions.scheduledPaymentsSubmitSuccess,
        ScheduledPaymentActions.scheduledPaymentsDeleteSuccess,
        ScheduledPaymentActions.scheduledPaymentsEditSuccess
      ),
      map(action => {
        const billAccounts = _get(action, 'payload.request.payment.billAccounts', []).map(
          ba => ba.billAccountNumber
        );
        return BillAccountActions.billAccountReloadMinimumDue(billAccounts);
      })
    );
  });

  // When payment method is edited we need to make sure any payment references are updated

  editPaymentMethodSuccess$ = createEffect(() => {
    return this.action$.pipe(
      ofType(PaymentMethodActions.editPaymentMethodSuccess),
      map(action => {
        const payload = {
          newNickName: action.newNickName,
          oldNickName: action.oldNickName
        };
        return ScheduledPaymentActions.scheduledPaymentsUpdate(payload);
      })
    );
  });

  constructor(
    private store: Store,
    private action$: Actions,
    private paymentService: PaymentService
  ) {}
}
