import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { LoadingSpinnerService } from './loading-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerResolverService {
  constructor(private loadingSpinnerService: LoadingSpinnerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!state.url.includes('/overview')) {
      if (this.loadingSpinnerService.active) {
        this.loadingSpinnerService.stop();
      }
    }
    document.getElementById('global-spinner').innerHTML = '';
    return of(true);
  }
}
