import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { take } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { VerifyPinRequest } from '@amfam/shared/models';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { LoadingSpinnerService, ToasterService, ValidationService } from '@amfam/ui-kit';

import * as fromRoot from '../../../../core/store';
import * as resetPasswordActions from '../../../../core/store/reset-password/reset-password.actions';
import { ForgotService } from '../../../shared/forgot.service';
import { ForgotPasswordAnalytics } from '../../shared/forgot-password-analytic-constants';

@Component({
  selector: 'ds-reset-password-code-entry',
  templateUrl: './reset-password-code-entry.component.html'
})
export class ResetPasswordCodeEntryComponent implements OnInit {
  @Input() pinConfirmModel: VerifyPinRequest;

  private codeInput: AbstractControl;
  codeForm: UntypedFormGroup;
  codeNotFound = false;
  codeExpired = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private forgotService: ForgotService,
    private toaster: ToasterService,
    private analyticsFacade: AnalyticsFacade,
    private store: Store<fromRoot.RootState>,
    private spinner: LoadingSpinnerService
  ) {}

  ngOnInit() {
    this.loadCodeForm();
    this.analyticsFacade.trackPage(ForgotPasswordAnalytics.pageForgotPasswordConfirmPin);
  }

  private loadCodeForm() {
    this.codeForm = this.formBuilder.group({
      codeInput: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          ValidationService.numbersOnly
        ]
      ]
    });
    this.codeInput = this.codeForm.controls['codeInput'];
  }

  verifyCode() {
    this.spinner.start();
    this.codeNotFound = false;
    this.codeExpired = false;
    const pinCode = this.codeInput.value;
    this.pinConfirmModel.pinCode = pinCode;

    this.forgotService
      .verifyToken(this.pinConfirmModel)
      .pipe(take(1))
      .subscribe(
        res => {
          this.spinner.stop();
          this.toaster.pop('success', 'Success!');
          this.store.dispatch(new resetPasswordActions.UserVerifiedPhoneAction(pinCode));
          this.store.dispatch(
            fromRouterActions.Go({
              path: ['/forgot-password/reset-password']
            })
          );
        },
        err => {
          this.spinner.stop();
          this.handleVerifyError(err);
        }
      );
  }

  private handleVerifyError(err: any) {
    const apiCode = _get(err, 'status.messages[0].code');
    switch (apiCode) {
      case 400008:
        this.codeExpired = true;
        break;
      case 400010:
        this.store.dispatch(new resetPasswordActions.VerifyMethodLockedAction());
        this.store.dispatch(
          fromRouterActions.Go({
            path: ['/forgot-password/reset-options']
          })
        );
        break;
      default:
        this.codeNotFound = true;
        break;
    }
  }
}
