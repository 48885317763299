import { createFeatureSelector, createSelector } from '@ngrx/store';

import { VehicleState } from './vehicle.reducer';
import {
  flatMap as _flatMap
} from 'lodash';

const selectVehicleState = createFeatureSelector<VehicleState>('vehicle');

export const selectStatus = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.status
);

export const selectHasError = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.hasError
);

export const selectLoading = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.loading
);

export const selectCriteria = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.criteria
);

export const selectYears = createSelector(selectVehicleState, (state: VehicleState) => state.years);

export const selectMakes = createSelector(selectVehicleState, (state: VehicleState) => state.makes);

export const selectModels = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.models
);

export const selectStyles = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.styles
);

export const selectVehiclePolicyChangeEntryPoint = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.vehiclePolicyChangeEntryPoint
);

export const selectNewVehicle = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.newVehicle
);

export const selectVehicleFeatures = createSelector(
  selectVehicleState,
  (state: VehicleState) => state.features
);

export const selectVehicleFeaturesCodesList = createSelector(
  selectVehicleState,
  (state: VehicleState) => _flatMap(state.features, feature => feature.typeOfFeatureCode)
);

export const selectNewRiskDescription = createSelector(selectNewVehicle, newVehicle =>
  newVehicle
    ? `${newVehicle.year} ${newVehicle.make} ${newVehicle.model} ${
        newVehicle.series ? newVehicle.series : ''
      }`
    : ''
);

export const selectNewRiskDescriptionWithoutSeries = createSelector(selectNewVehicle, newVehicle =>
  newVehicle ? `${newVehicle.year} ${newVehicle.make} ${newVehicle.model}` : ''
);

export const vehicleQuery = {
  selectStatus,
  selectHasError,
  selectLoading,
  selectCriteria,
  selectYears,
  selectMakes,
  selectModels,
  selectStyles,
  selectNewVehicle,
  selectNewRiskDescription,
  selectVehiclePolicyChangeEntryPoint,
  selectNewRiskDescriptionWithoutSeries,
  selectVehicleFeatures,
  selectVehicleFeaturesCodesList
};
