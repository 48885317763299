<div class="row flex column-row">
  <div
    [ngClass]="{ 'md-four': numberOfColumns === 'three', 'md-six': numberOfColumns === 'two' }"
    class="column twelve column-left"
  >
    <ng-content select="[column-left]"></ng-content>
  </div>
  @if (numberOfColumns === 'three') {
    <div class="column twelve md-four column-center">
      <ng-content select="[column-center]"></ng-content>
    </div>
  }
  <div
    [ngClass]="{ 'md-four': numberOfColumns === 'three', 'md-six': numberOfColumns === 'two' }"
    class="column twelve column-right"
  >
    <ng-content select="[column-right]"></ng-content>
  </div>
</div>
