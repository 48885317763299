// Angular
import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { ConfigService, WindowRef } from '@amfam/shared/utility/shared-services';

// Models
import {
  AnalyticOptions,
  ButtonAnalyticParams,
  ClaimsAnalyticsParams,
  ClaimsDataLayer,
  CoverageChangeAnalyticParams,
  CoverageChangeDataLayer,
  DataLayer,
  EventAnalyticParams,
  EventDataLayer,
  LinkDataLayer,
  PageAnalyticParams,
  PageDataLayer,
  ReplaceVehicleAnalyticParams,
  ReplaceVehicleDataLayer,
  UserAnalyticParams,
  UserDataLayer
} from '../models/analytics.model';
import { DynatraceService } from './dynatrace.service';

@Injectable({
  providedIn: 'root'
})
export class AdobeAnalyticsService {
  constructor(
    private config: ConfigService,
    private win: WindowRef,
    private dynatraceService: DynatraceService
  ) {}

  loadAdobeAnalyticsScript() {
    return from(
      new Promise<void>((resolve, reject) => {
        const script = document.createElement('script') as any;
        script.type = 'text/javascript';
        script.src = this.config.get('links.launch');
        // cross browser handling of onLoaded event
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              // verify _satellite exists
              if (this.win.nativeWindow._satellite && this.win.nativeWindow._satellite.track) {
                resolve();
              } else {
                reject();
              }
            }
          };
        } else {
          // Others
          script.onload = () => {
            // verify _satellite exists
            if (this.win.nativeWindow._satellite && this.win.nativeWindow._satellite.track) {
              resolve();
            } else {
              reject();
            }
          };
        }
        script.onerror = (error: any) => {
          reject();
        };
        // finally append the script tag in the DOM
        document.getElementsByTagName('head')[0].appendChild(script);
      }).then(
        () => {
          this.dynatraceService.sendDynatraceAction('dtm', 'true');
        },
        reason => {
          this.dynatraceService.sendDynatraceAction('dtm', 'false');
          throw new Error(reason);
        }
      )
    );
  }

  private createDataLayer(options: AnalyticOptions): DataLayer {
    const dataLayer: DataLayer = {};
    if (options.page) {
      dataLayer.page = this.createPageDataLayer(options.page);
    }
    if (options?.page?.paymentAmount || options?.page?.paymentDate) {
      dataLayer.payNow = {
        paymentAmount: options?.page?.paymentAmount,
        paymentDate: options?.page?.paymentDate
      };
    }
    if (options.event) {
      dataLayer.event = [this.createEventDataLayer(options.event)];
    }
    if (options.button) {
      dataLayer.linkClicked = this.createLinkDataLayer(options.button);
    }
    if (options.user) {
      dataLayer.user = this.createUserDataLayer(options.user);
    }
    if (options.replaceVehicle) {
      dataLayer.replaceVehicle = this.createReplaceVehicleDataLayer(options.replaceVehicle);
    }
    if (options.coverageChange) {
      dataLayer.policyCoverageChange = this.createCoveragechangeDataLayer(options.coverageChange);
    }
    if (options.claims) {
      dataLayer.claims = this.createClaimsDataLayer(options.claims);
    }
    if (options.accountEnrollment) {
      dataLayer.accountEnrollment = options.accountEnrollment;
    }
    return dataLayer;
  }

  private createPageDataLayer(params: PageAnalyticParams): PageDataLayer {
    let pageDataLayer: PageDataLayer = {
      pageInfo: {
        pageName: params.error ? `${params.pageName}:${params.error}` : params.pageName,
        experience: params.experience
      },
      category: {
        primaryCategory: params.primaryCategory,
        subCategory1: params.subCategory1,
        subCategory2: params.subCategory2,
        subCategory3: params.subCategory3
      },
      attributes: { language: ' ' },
      payNow: {
        paymentAmount: params.paymentAmount,
        paymentDate: params.paymentDate
      }
    };

    if (params.timeframe) {
      pageDataLayer = Object.assign(pageDataLayer, {
        paymnthistory: {
          timeframe: params.timeframe
        }
      });
    }

    return pageDataLayer;
  }

  private createEventDataLayer(params: EventAnalyticParams): EventDataLayer {
    let eventDataLayer: EventDataLayer = {
      eventInfo: {
        eventName: params.eventName,
        eventStep: params.eventStep
      }
    };
    if (params.eventAmount) {
      eventDataLayer = Object.assign(eventDataLayer, {
        eventInfo: {
          eventAmount: params.eventAmount
        }
      });
    }
    if (params.eventTerm) {
      eventDataLayer = Object.assign(eventDataLayer, {
        eventInfo: {
          eventTerm: params.eventTerm
        }
      });
    }
    return eventDataLayer;
  }

  private createLinkDataLayer(params: ButtonAnalyticParams): LinkDataLayer {
    return params;
  }

  private createUserDataLayer(params: UserAnalyticParams): UserDataLayer {
    return {
      profileInfo: {
        userType: params.userType,
        trackID: params.trackID,
        experienceID: params.experienceID,
        partnerID: params.partnerID
      }
    };
  }

  private createReplaceVehicleDataLayer(
    params: ReplaceVehicleAnalyticParams
  ): ReplaceVehicleDataLayer {
    return params;
  }

  private createCoveragechangeDataLayer(
    params: CoverageChangeAnalyticParams
  ): CoverageChangeDataLayer {
    return params;
  }

  private createClaimsDataLayer(params: ClaimsAnalyticsParams): ClaimsDataLayer {
    return params;
  }

  sendAnalytic(options: AnalyticOptions) {
    const dataLayer = this.createDataLayer(options);
    this.win.nativeWindow.digitalData = dataLayer;
    if (this.win.nativeWindow._satellite && this.win.nativeWindow._satellite.track) {
      this.win.nativeWindow._satellite.track('myaccount');
    }
  }
}
