import { Action, createReducer, on } from '@ngrx/store';

import * as maeActions from './mae.actions';

export const maeFeatureKey = 'mae';

export interface MaeState {
  resendEmailSuccess: boolean;
  changeEmailSuccess: boolean;
  hasError: boolean;
  loading: boolean;
  loaded: boolean;
}

export const initialState: MaeState = {
  resendEmailSuccess: false,
  changeEmailSuccess: false,
  hasError: false,
  loading: false,
  loaded: false
};

const maeReducer = createReducer(
  initialState,

  on(
    maeActions.changeEmail,
    (state): MaeState => ({
      ...state,
      changeEmailSuccess: false,
      resendEmailSuccess: false
    })
  ),

  on(
    maeActions.changeEmailSuccess,
    (state): MaeState => ({
      ...state,
      changeEmailSuccess: true,
      resendEmailSuccess: false
    })
  ),

  on(
    maeActions.changeEmailFail,
    (state): MaeState => ({
      ...state,
      changeEmailSuccess: false,
      resendEmailSuccess: false
    })
  ),

  on(
    maeActions.resendEmail,
    (state): MaeState => ({
      ...state,
      resendEmailSuccess: false,
      changeEmailSuccess: false
    })
  ),

  on(
    maeActions.resendEmailSuccess,
    (state): MaeState => ({
      ...state,
      resendEmailSuccess: true,
      changeEmailSuccess: false
    })
  ),

  on(
    maeActions.resendEmailFail,
    (state): MaeState => ({
      ...state,
      resendEmailSuccess: false,
      changeEmailSuccess: false
    })
  ),

  on(
    maeActions.enrollment,
    (state): MaeState => ({
      ...state,
      hasError: false,
      loading: true,
      loaded: false
    })
  ),
  on(
    maeActions.enrollmentSuccess,
    (state): MaeState => ({
      ...state,
      hasError: false,
      loaded: true,
      loading: false
    })
  ),

  on(
    maeActions.enrollmentFailure,
    (state): MaeState => ({
      ...state,
      hasError: true,
      loaded: true,
      loading: false
    })
  )
);

export function reducer(state: MaeState | undefined, action: Action): unknown {
  return maeReducer(state, action);
}

export const hasError = (state: MaeState) => state.loaded && state.hasError;
export const loading = (state: MaeState) => !state.loaded && state.loading;
export const loaded = (state: MaeState) => !state.loading && state.loaded;
