/* eslint-disable ngrx/prefix-selectors-with-select */
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { PolicySelectors } from '@amfam/policy/data-access';
import {
  AutoPolicy,
  GenericProductType,
  PolicyStatus,
  VehicleTypeConstants
} from '@amfam/policy/models';
import { EntityNotification } from '@amfam/shared/models';

import * as fromAutoPayNotifications from './auto-pay-notifications.reducer';
import * as fromAutoPayPrediction from './auto-pay-prediction.reducer';
import * as fromAutoPay from './auto-pay.reducer';

export const autoPayFeatureKey = 'autoPay';

// Reducer Map
export interface State {
  autoPayState: fromAutoPay.State;
  autoPayPredictionState: fromAutoPayPrediction.State;
  autoPayNotificationsState: fromAutoPayNotifications.State;
}

export const reducers: ActionReducerMap<State> = {
  autoPayState: fromAutoPay.reducer,
  autoPayPredictionState: fromAutoPayPrediction.reducer,
  autoPayNotificationsState: fromAutoPayNotifications.reducer
};

// State Selectors
export const getState = createFeatureSelector<State>(autoPayFeatureKey);

export const getAutoPayState = createSelector(getState, (state: State) => state.autoPayState);

export const getAutoPayPredictionState = createSelector(
  getState,
  (state: State) => state.autoPayPredictionState
);

export const getAutoPayNotificationsState = createSelector(
  getState,
  (state: State) => state.autoPayNotificationsState
);

// AutoPay Selectors
export const getAutomaticPayments = createSelector(
  getAutoPayState,
  fromAutoPay.selectAllAutomaticPayments
);

export const getAutomaticPayment = (billAccountNumber: string) =>
  createSelector(getAutoPayState, (state: fromAutoPay.State) => state.entities[billAccountNumber]);

// AutoPay Notifications Selectors
export const getAutoPayNotifications = createSelector(
  getAutoPayNotificationsState,
  fromAutoPayNotifications.selectAllAutomaticPaymentNotifications
);

export const getAutoPayNotificationEntities = createSelector(
  getAutoPayNotificationsState,
  (state: fromAutoPayNotifications.State) => state.entities
);

export const getAutoPayNotification = (billAccountNumber: string) =>
  createSelector(
    getAutoPayNotificationsState,
    (state: fromAutoPayNotifications.State) => state.entities[billAccountNumber]
  );

export const getAnyLoading = createSelector(
  getAutoPayNotifications,
  (notifications: EntityNotification[]) =>
    notifications.map(notification => notification.loading).includes(true)
);

export const hasAnyError = createSelector(
  getAutoPayNotifications,
  (notifications: EntityNotification[]) =>
    notifications.map(notification => notification.hasError).includes(true)
);

export const getCorrelationIds = createSelector(
  getAutoPayNotifications,
  (notifications: EntityNotification[]) =>
    notifications.map(notification => notification.correlationId)
);

export const getLoading = (billAccountNumber: string) =>
  createSelector(
    getAutoPayNotification(billAccountNumber),
    (notification: EntityNotification) => notification.loading
  );

export const hasError = (billAccountNumber: string) =>
  createSelector(
    getAutoPayNotification(billAccountNumber),
    (notification: EntityNotification) => notification.hasError
  );

export const selectCorrelationId = (billAccountNumber: string) =>
  createSelector(
    getAutoPayNotification(billAccountNumber),
    (notification: EntityNotification) => notification.correlationId
  );

// AutoPay Prediction Selectors
export const getPredictionLoading = createSelector(
  getAutoPayPredictionState,
  (state: fromAutoPayPrediction.State) => state.loading
);

export const getAutoPayPrediction = createSelector(
  getAutoPayPredictionState,
  (state: fromAutoPayPrediction.State) => state.prediction
);

export const selectActiveVehiclePolicies = createSelector(
  PolicySelectors.selectPolicies,
  policies =>
    policies
      .filter(
        policy =>
          policy.generalizedProductType === GenericProductType.Auto &&
          policy.policyStatus === PolicyStatus.Inforce
      )
      .map(policy => <AutoPolicy>policy)
);

export const selectActiveAutoPolicies = createSelector(selectActiveVehiclePolicies, policies =>
  policies
    .filter(policy => policy.productDescription.toLowerCase() === VehicleTypeConstants.AUTO)
    .map(policy => <AutoPolicy>policy)
);

export const selectAutoPayNotificationHasError = (billAccountNumber: string) =>
  createSelector(
    getAutoPayNotification(billAccountNumber),
    notification => !notification.loading && notification.hasError
  );
