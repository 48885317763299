/* eslint-disable ngrx/prefer-action-creator-in-dispatch */
/* eslint-disable @typescript-eslint/member-ordering */
import { FaviconService } from '@amfam/shared/favicon';
import { LogoModel, Logos } from '@amfam/shared/models';
import {
  featureFlagQuery,
  FeatureFlagService
} from '@amfam/shared/utility/feature-flag/data-access';
import { NavigationService } from '@amfam/shared/utility/navigation';
import { ConfigService } from '@amfam/shared/utility/shared-services/src/lib/config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { find as _find, get as _get } from 'lodash';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as brandActions from '../+state/brand.actions';
import * as BrandSelectors from '../+state/brand.selectors';
import {
  BrandDataModel,
  BrandModel,
  BrandServiceDataMap,
  BrandModel as BrandState,
  initialBrandDataModel,
  initialBrandModel,
  initialLogoModel
} from '../models/brand.model';
import { Partners } from './brand.enum';

@Injectable({
  providedIn: 'root'
})

//TODO: Rebrand all variables/directives/etc with Connect name to Midvale
export class BrandService {
  private isMidvalePartner: boolean;

  constructor(
    private store: Store<BrandState>,
    private config: ConfigService,
    private http: HttpClient,
    private navigationService: NavigationService,
    private feature: FeatureFlagService,
    private faviconService: FaviconService
  ) {}

  loadBrandForUrl(experienceId: string): Observable<BrandModel> {
    return this.loadBrand(experienceId).pipe(
      take(1),
      map(brand => {
        this.store.dispatch(new brandActions.LoadBrandDataSuccess(brand));
        return brand;
      }),
      catchError(error => {
        if (error && error.status && error.status.code && error.status.code === 404) {
          this.store.dispatch(new brandActions.LoadBrandDataFailure(error));
          return this.handleError(error);
        }
      })
    );
  }

  loadBrandForUser(experienceId: string): Observable<boolean> {
    if (!experienceId) {
      // experienceId = this.constantService.get('afiExperienceId');
      // TODO: User does not have an experienceId in aaa, fornow, trust url
      return of(true);
    }
    return this.store.select(BrandSelectors.getBrandState).pipe(
      take(1),
      mergeMap(currentBrand => {
        if (currentBrand.userBrandLoaded) {
          return of(true);
        }
        if (currentBrand.loaded && currentBrand.experienceId === experienceId) {
          if (!currentBrand.isPartner) {
            this.showFeedbackTab();
          }
          return of(true);
        }
        return this.loadBrand(experienceId).pipe(
          take(1),
          mergeMap(brand => {
            if (brand && brand.experienceId) {
              this.store.dispatch(new brandActions.LoadBrandDataSuccess(brand));
              return of(true);
            }
            const errorMessage = { error: 'error loading brand' };
            this.store.dispatch(new brandActions.LoadBrandDataFailure(errorMessage));
            return this.handleError(errorMessage);
          }),
          catchError(error => {
            this.store.dispatch(new brandActions.LoadBrandDataFailure(error));
            return this.handleError(error);
          })
        );
      })
    );
  }

  private loadBrand(expid: string): Observable<BrandModel> {
    const url: string = this.config.get('brandApi') + '/brands?experienceId=' + expid;
    return this.store.select(featureFlagQuery.hasFeatureFlag).pipe(
      filter(f => f),
      first(),
      switchMap(f =>
        this.http.get(url).pipe(
          map((body: any) => {
            const summary = body.summaries[0];
            const brandId = summary.brand.brandId;
            const experience = _find(summary.experiences, exp => exp.experienceId === expid);
            const data = summary.brand.productLines.find(pl => pl.productNm === 'AUTO');

            const genAssets = data.genAssets.filter(function (asset) {
              return asset.genAssetDesc === 'MyAccount_DMSI';
            });

            const genAssetAttrs = genAssets[0]?.genAssetAttrs;
            const brandData: BrandDataModel = Object.assign({}, initialBrandDataModel);
            for (const member in BrandServiceDataMap) {
              if (BrandServiceDataMap[member]) {
                const genAssetAttr = genAssetAttrs?.find(
                  asset => asset.genAssetAttrKeyId === BrandServiceDataMap[member]
                );
                if (genAssetAttr) {
                  brandData[member] = genAssetAttr.assetAttrValueTxt;
                }
              }
            }

            const brand: BrandModel = Object.assign({}, initialBrandModel, experience);
            brand.isPartner = brand.partnerId === 'AFI' ? false : true; // Check if the partner ID returned is AFI or not.
            brand.isGenericPartner = false; // This is not the generic partner.
            this.isMidvalePartner = brand.isPartner;
            brand.brandId = brandId;

            let defaultHeaderLogo;
            let defaultFooterLogo;
            if (this.isMidvalePartner) {
              defaultHeaderLogo = Logos.MIDVALE_HEADER;
              defaultFooterLogo = Logos.MIDVALE_FOOTER;
              if (brand.partnerId === Partners.ONSTAR) {
                const osiToMidvale = this.feature.isEnabled('osi_to_midvale');
                this.faviconService.activate(osiToMidvale ? 'midvale' : 'onstar');
              } else if (brand.partnerId === Partners.GENERAL) {
                defaultHeaderLogo = Logos.GENERAL_HEADER;
                defaultFooterLogo = Logos.GENERAL_FOOTER;
                this.faviconService.activate('general');
              } else if (
                [
                  String(Partners.CAH),
                  String(Partners.COSTCO_ECP),
                  String(Partners.AMERIPRISE_DIRECT),
                  String(Partners.AMFAM_EXCLUSIVE)
                ].includes(brand.partnerId)
              ) {
                defaultHeaderLogo = Logos.CONNECT_HEADER;
                defaultFooterLogo = Logos.CONNECT_FOOTER;
                this.faviconService.activate('connect');
              }
            } else {
              defaultHeaderLogo = Logos.AFI_HEADER;
              defaultFooterLogo = Logos.AFI_FOOTER;
              this.faviconService.activate('amfam');
            }

            // Get header and footer logo data
            const headerLogoData = this.buildLogo(
              data.digitalAssets.find(
                asset => asset.digitalAssetResourceDec === 'MyAccount_Headerimage_Logo'
              )
            );
            const footerLogoData = this.buildLogo(
              data.digitalAssets.find(
                asset => asset.digitalAssetResourceDec === 'MyAccount_Footerimage_Logo'
              )
            );

            // Build logo models
            if (headerLogoData && footerLogoData) {
              brandData['brandHeaderLogo'] = headerLogoData;
              brandData['brandFooterLogo'] = footerLogoData;
            } else {
              brandData['brandHeaderLogo'] = defaultHeaderLogo;
              brandData['brandFooterLogo'] = defaultFooterLogo;
            }

            brand.data = brand.data = Object.assign({}, brandData);
            brand.data.propertyServiceUrl = this.config.get('links.propertyServiceUrl');
            const tier = this.config.get('partnerLandingPageTier');
            brand.data.landingPageUrl = this.buildLandingPageUrl(brand, expid, tier); // Build the env specific url for partner landing page
            // If the partner doesn't have a specific landingpage domain, use myaccount domain
            if (brand.data.landingPageDomain && brand.data.landingPagePath) {
              brand.data.landingPage = `www.${brand.data.landingPageDomain}/${brand.data.landingPagePath}`;
            } else {
              brand.data.landingPage = `www.myaccount${this.config.get(
                'appDomainTier'
              )}.amfam.com/${expid}`;
            }

            if (
              this.feature.isEnabled('partner_sso') ||
              (brand.data.propertyServiceSSO && brand.data.propertyServiceSSO === 'true')
            ) {
              const ssoUrl = this.config.get('links.propertyServiceSsoUrl');
              brand.data.propertyServiceUrl = `${ssoUrl}&experienceId=${expid}&returnUrl=${encodeURIComponent(
                brand.data.landingPageUrl
              )}`;
            }
            this.setExperience(brand, expid);
            return brand;
          }),
          catchError(this.handleError)
        )
      ),
      catchError(this.handleError)
    );
  }

  /**
   * This takes the attributes for a logo and fills them
   * into the LogoModel.
   * @param logoAttrs
   */
  private buildLogo(logoAttrs: any): LogoModel {
    if (logoAttrs) {
      const logoDimensions = logoAttrs.digitalAssetAttrs;
      if (!logoDimensions) {
        return initialLogoModel;
      }
      return {
        logoURL: logoAttrs.relativeLocTxt,
        height: _get(
          logoDimensions.find(asset => asset.assetAttrKeyId === 'height'),
          'assetAttrValueTxt',
          ''
        ),
        width: _get(
          logoDimensions.find(asset => asset.assetAttrKeyId === 'width'),
          'assetAttrValueTxt',
          ''
        ),
        altText: logoAttrs.altText ? logoAttrs.altText : ''
      };
    }
    return null;
  }

  private setExperience(brand: BrandModel, experienceId: string) {
    if (!brand.isPartner) {
      this.navigationService.clearExperienceId();
    } else {
      this.navigationService.setExperienceId(experienceId);
    }
  }

  /**
   * This builds the specific tiered url.  If
   * it is prod the tier is empty.
   * @param brand
   * @param expid
   * @param tier
   */
  private buildLandingPageUrl(brand: BrandModel, expid: string, tier: string): string {
    if (!brand.data.landingPageDomain) {
      return `https://myaccount${this.config.get('appDomainTier')}.amfam.com/${expid}/login`;
    } else {
      return `https://${tier}.${brand.data.landingPageDomain}/${brand.data.landingPagePath}/?expid=${expid}`;
    }
  }

  /*
    We only show the feedback tab for AFI users
  */
  private showFeedbackTab() {
    const styleTag = document.createElement('style');
    const styleText = document.createTextNode(
      '#oo_tab { display: initial !important; } @media print {#oo_tab {display: none !important;}}'
    );
    styleTag.appendChild(styleText);
    document.getElementsByTagName('head')[0].appendChild(styleTag);
  }

  private handleError(error: any) {
    return observableThrowError(error);
  }
}
