import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Agent } from '../models/agent.model';
import { AgentActions, AgentActionTypes } from './agent.actions';

export const AGENT_FEATURE_KEY = 'agent';

export interface AgentState extends EntityState<Agent> {
  loaded: boolean;
  loading: boolean;
  hasError: boolean;
}

export const adapter: EntityAdapter<Agent> = createEntityAdapter<Agent>({
  selectId: (agentPayload: Agent) => agentPayload.id,
  sortComparer: false
});

export const initialState: AgentState = adapter.getInitialState({
  loaded: false,
  loading: false,
  hasError: false
});

export function agentReducer(state = initialState, action: AgentActions): AgentState {
  switch (action.type) {
    case AgentActionTypes.LoadAgents:
      return { ...state, loading: true, loaded: false, hasError: false };

    case AgentActionTypes.LoadAgentsSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        hasError: false
      });

    case AgentActionTypes.LoadAgentsFail:
      return { ...state, loading: false, loaded: true, hasError: true };

    default:
      return state;
  }
}

// get the selectors
const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

// select the array of agent ids
export const selectAgentIds = selectIds;

// select the dictionary of agent entities
export const selectAgentEntities = selectEntities;

// select the array of agents
export const selectAllAgents = selectAll;
