import { Directive, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NavigationService } from '@amfam/shared/utility/navigation';

@Directive({
  selector: '[dsRouterLinkActive]'
})
export class RouterLinkActiveDirective implements OnDestroy, OnChanges {
  @HostBinding('class.active') activeClass: boolean;
  @Input() dsRouterLinkActive: unknown[] | string;
  @Input() fuzzyMatchRoute?: boolean;

  private subscription: Subscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private pathToMatch: any;

  constructor(
    private router: Router,
    private navigationService: NavigationService
  ) {
    this.subscription = router.events.subscribe(routeInstance => {
      if (routeInstance instanceof NavigationEnd) {
        this.updateActiveClass();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
  ngOnChanges(_changes: {}): void {
    this.updateActiveClass();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private updateActiveClass(): void {
    this.pathToMatch = this.navigationService.getCommands(this.dsRouterLinkActive);

    if (this.fuzzyMatchRoute) {
      this.activeClass = this.fuzzyMatchUrl();
    } else {
      this.activeClass = this.pathToMatch === this.router.url ? true : false;
    }
  }

  private fuzzyMatchUrl(): boolean {
    const fuzzyUrl = this.router.url.substring(0, this.pathToMatch.length);
    return fuzzyUrl === this.pathToMatch ? true : false;
  }
}
