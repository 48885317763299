import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { PoliciesLoadSuccess } from '@amfam/policy/data-access';

import { ESignatureService } from '../services/e-signature.service';
import {
  ESignatureActions,
  GetESignature,
  GetESignatureComplete,
  GetESignatureFail,
  GetESignatureSuccess,
  SignESignature,
  SignESignatureComplete,
  SignESignatureSuccess
} from './e-signature.actions';

@Injectable()
export class ESignatureEffects {
  loading$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PoliciesLoadSuccess),
      map(action => action.payload),
      switchMap(payload => observableOf(GetESignature(payload)))
    );
  });

  getESignature$: Observable<ESignatureActions> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetESignature),
      map(action => action.payload),
      switchMap(() => this.eSignatureService.requiresSignature()),
      mergeMap(response => {
        return observableFrom([GetESignatureComplete(), GetESignatureSuccess(response)]);
      }),
      catchError(error => observableOf(GetESignatureFail(error)))
    );
  });

  sign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignESignature),
      map(action => action.payload),
      switchMap(payload => this.eSignatureService.requestSignature(payload)),
      map((response: string) => SignESignatureSuccess(response))
    );
  });

  signed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignESignatureSuccess),
      map(action => action.payload),
      first(),
      tap(payload => (location.href = payload)),
      map(payload => SignESignatureComplete(payload))
    );
  });

  constructor(
    private eSignatureService: ESignatureService,
    private actions$: Actions
  ) {}
}
