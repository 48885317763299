import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProfileActions, profileQuery } from '@amfam/profile/data-access';
import { SecurityQuestion } from '@amfam/shared/digital-account/data-access';
import { userQuery } from '@amfam/shared/user';
import { SecurityQuestionsService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-edit-security-questions',
  templateUrl: './edit-security-questions.component.html',
  styleUrls: ['./edit-security-questions.component.scss']
})
export class EditSecurityQuestionsComponent implements OnInit, OnDestroy {
  @Input() securityQuestions: SecurityQuestion[];

  showForm = false;
  errorLoadingQuestions = false;
  private customerId: string;
  private stop$ = new Subject<void>();
  private updatedSecurityQuestions: SecurityQuestion[];

  constructor(
    private securityQuestionsService: SecurityQuestionsService,
    private store: Store
  ) {}

  ngOnInit() {
    this.loadAccountInformation();
  }

  loadAccountInformation() {
    // Get the customerId from the auth service, since this is an edit operation on the security questions
    // this can only be performed if the user is logged in.
    this.store
      .select(userQuery.getUserState)
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        this.customerId = state.customerId;
      });

    this.store
      .select(profileQuery.selectProfileSecurityInfoStatus)
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        if (state.editSecurityInfoMethodType !== 'securityQuestions') {
          return;
        }
        const editSecurityInfoLoading = state.loading;
        const editSecurityInfoError = state.editSecurityInfoError;
        if (!editSecurityInfoLoading && !editSecurityInfoError) {
          this.handleSecurityQuestionSuccess(this.updatedSecurityQuestions);
        } else if (!editSecurityInfoLoading && editSecurityInfoError) {
          this.handleSecurityQuestionFailure(state.editSecurityInfoResponse);
        }
      });
  }

  // post updates to security questions
  onComplete({ status, securityQuestions }) {
    if (status) {
      // start the spinner
      this.store.dispatch(ProfileActions.MakeRequestAction());

      // dispatch the request to change password
      const data = {
        securityQuestions: securityQuestions,
        customerId: this.customerId
      };
      this.updatedSecurityQuestions = securityQuestions;
      this.store.dispatch(
        ProfileActions.ChangeSecurityInfoAction({
          data,
          methodType: 'securityQuestions'
        })
      );
    }
  }

  handleSecurityQuestionSuccess(securityQuestions: SecurityQuestion[]): void {
    if (!securityQuestions) {
      return;
    }
    // pass updates to the store
    this.store.dispatch(
      ProfileActions.UpdateSecurityQuestionsAction({
        securityQuestions: this.getNewQuestionsArray(securityQuestions)
      })
    );
    this.showForm = false;
  }

  handleSecurityQuestionFailure(error) {
    const apiMessage = _get(error, 'messages[0]', null);
    if (apiMessage && apiMessage.code) {
      this.securityQuestionsService.setUserQuestionsError$(apiMessage.code);
    }
  }

  // triggers if theres an error loading the list of security questions
  onGetAllQuestionsServiceError() {
    // toggle flag to show error messaging
    this.errorLoadingQuestions = true;
  }

  openSecurityQuestionsForm() {
    // reset error value so if opened again request for questions will be attempted again
    this.errorLoadingQuestions = false;
    this.securityQuestionsService.setUserQuestionsError$(null);
    this.showForm = !this.showForm;
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  private getNewQuestionsArray(securityQuestions: SecurityQuestion[]): SecurityQuestion[] {
    // clear answers and oldQuestion prop on question objects
    return [...securityQuestions].map(({ question }) => ({
      question,
      answer: ''
    }));
  }
}
