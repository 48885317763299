import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '@amfam/shared/utility/shared-services';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class GlobalHeadersInterceptor implements HttpInterceptor {
  constructor(private config: ConfigService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modified = request.clone({
      headers: this.addGlobalHeaders(request.headers, request.method),
      withCredentials: true
    });
    return next.handle(modified);
  }

  private addGlobalHeaders(headers: HttpHeaders, method: string): HttpHeaders {
    if (!headers.has('accept')) {
      headers = headers.set('accept', 'application/json');
    }
    if (!headers.has('afi-appname')) {
      headers = headers.set('afi-appname', 'MyAccountWebApplication');
    }
    if (!headers.has('content-type')) {
      headers = headers.set('content-type', 'application/json');
    }
    if (!headers.has('afi-api-key')) {
      headers = headers.set('afi-api-key', this.config.get('afiApiKey'));
    }
    if (method === 'GET') {
      if (!headers.has('cache-control')) {
        headers = headers.set('cache-control', 'no-cache');
      }
      if (!headers.has('pragma')) {
        headers = headers.set('pragma', 'no-cache');
      }
    }
    if (!headers.has('afe-trace-id')) {
      headers = headers.set('afe-trace-id', crypto.randomUUID());
    }
    if (!headers.has('afe-source-id')) {
      headers = headers.set('afe-source-id', 'MyAccountWebApplication');
    }
    return headers;
  }
}
