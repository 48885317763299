import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { get as _get } from 'lodash';

import { CopyService } from '@amfam/shared/utility/shared-services';

import { Adapter } from '../models/adapter';
import {
  AdaptedVehicleFeatures,
  AddVehicleFeaturesResponseModel,
  VehicleFeaturesModel
} from '../models/vehicle-features.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleFeaturesAdapterService implements Adapter<AdaptedVehicleFeatures> {
  constructor(private copyService: CopyService) {}
  adapt(item: AddVehicleFeaturesResponseModel): AdaptedVehicleFeatures {
    const eligibleFeatureCodesWithDescription: Dictionary<string> = this.copyService.getCopy(
      'policy.newVehicleFeaturesAndEquipmentsCodesWithDescription'
    );
    let featuresList: VehicleFeaturesModel[] = _get(
      item,
      'matchedVehicles.automobiles[0].features',
      []
    );
    const frontAirbags = this.copyService.getCopy('policy.newVehicleFeatureCodeFrontAirbags');
    const sideAirbags = this.copyService.getCopy('policy.newVehicleFeatureCodeSideAirbags');
    const fronAndSideAirbags = this.copyService.getCopy(
      'policy.newVehicleFeatureCodeFrontAndSideAirbags'
    );
    let frontAirbagsFound: boolean;
    let sideAirbagsFound: boolean;
    const standardText = 'standard';
    for (const feature of Object.values(featuresList)) {
      if (
        feature.typeOfFeatureCode === frontAirbags &&
        feature.typeOfAvailabilityCode.toLowerCase() === standardText
      ) {
        frontAirbagsFound = true;
      }
      if (
        feature.typeOfFeatureCode === sideAirbags &&
        feature.typeOfAvailabilityCode.toLowerCase() === standardText
      ) {
        sideAirbagsFound = true;
      }
    }

    if (frontAirbagsFound && sideAirbagsFound) {
      featuresList.unshift({
        description: eligibleFeatureCodesWithDescription[fronAndSideAirbags],
        typeOfFeatureCode: fronAndSideAirbags,
        typeOfAvailabilityCode: standardText
      });

      featuresList = featuresList.filter(
        feature =>
          feature.typeOfFeatureCode !== frontAirbags && feature.typeOfFeatureCode !== sideAirbags
      );
    }

    return {
      features: featuresList
        .filter(
          feature =>
            feature.typeOfAvailabilityCode.toLowerCase() === standardText &&
            !!Object.keys(eligibleFeatureCodesWithDescription).find(
              elligibleFeatureCode => elligibleFeatureCode === feature.typeOfFeatureCode
            )
        )
        .map(feature =>
          Object.assign({}, feature, {
            description: eligibleFeatureCodesWithDescription[feature.typeOfFeatureCode],
            typeOfFeatureCode: feature.typeOfFeatureCode.toLowerCase()
          })
        )
    };
  }
}
