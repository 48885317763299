import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get, includes as _includes } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilKeyChanged, take, takeUntil } from 'rxjs/operators';

import { LogoModel } from '@amfam/shared/models';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { Applications, ApplicationService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-footer-partner',
  templateUrl: './footer-partner.component.html',
  styleUrls: ['./footer-partner.component.scss']
})
export class FooterPartnerComponent implements OnInit, OnDestroy {
  @Input() footerLogo: LogoModel;
  @Input() showContactInfo = true;
  currentYear = new Date().getFullYear();
  showSiteMap = false;
  experienceId: string;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store,
    private appService: ApplicationService
  ) {}

  ngOnInit() {
    this.store
      .select(BrandSelectors.selectExperienceId)
      .pipe(take(1))
      .subscribe(experienceId => {
        this.experienceId = experienceId;
      });

    const excludedAppNames = [
      Applications.MYACCOUNT_ADMIN,
      Applications.FIRST_PARTY_FNOL,
      Applications.THIRD_PARTY_FNOL
    ];

    // Check if the current app is in the excluded apps list
    // Then sub to the userState.loginName to determine login state
    if (!_includes(excludedAppNames, this.appService.getAppName())) {
      this.store
        .select(userQuery.getUserState)
        .pipe(distinctUntilKeyChanged('loginName'), takeUntil(this.stop$))
        .subscribe(state => {
          this.showSiteMap = !!_get(state, 'loginName');
        });
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
