/* eslint-disable arrow-body-style */
import { BillAccountActions } from '@amfam/billing/billaccount/data-access';
import { fromPaymentHistoryActions } from '@amfam/billing/payment-history/data-access';
import { PaymentMethodActions } from '@amfam/billing/payment-method/data-access';
import { PaymentAccountActions } from '@amfam/billing/paymentaccount/data-access';
import { ScheduledPaymentActions } from '@amfam/billing/schedulepayment/data-access';
import { fromClaimDashboardActions } from '@amfam/claim/dashboard/data-access';
import { fromClaimActions } from '@amfam/claim/data-access';
import { PolicySummaryActions } from '@amfam/policy/data-access';
import { ProfileActions } from '@amfam/profile/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { DynatraceService } from '@amfam/shared/analytics/src/lib/services/dynatrace.service';
import { fromUserActions, userQuery } from '@amfam/shared/user';
import { BrandActions, BrandService } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromImpersonationActions, impersonationQuery } from '@amfam/shared/utility/impersonation';
import {
  EventAnalytic,
  UtilService
} from '@amfam/shared/utility/shared-services';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import differenceInMilliseconds from 'date-fns/difference_in_milliseconds';
import parse from 'date-fns/parse';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Observable, defer, of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import * as fromRoot from '../../';
import { AuthService } from '../../auth';
import { UpdateContactService } from '../../update-contact/update-contact.service';
import * as appBootstrap from '../app-bootstrap/app-bootstrap.actions';
import * as session from '../session/session.actions';
import { GatewayTime } from '../session/session.model';

@Injectable()
export class SessionEffects {
  start$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.SESSION_LOAD),
      switchMap(() => {
        const deviceInfo: DeviceInfo = this.deviceService.getDeviceInfo();
        return [
          new session.SessionLoadSuccessAction({
            browser: deviceInfo.browser,
            browserVersion: deviceInfo.browser_version,
            device: deviceInfo.device,
            os: deviceInfo.os,
            osVersion: deviceInfo.os_version,
            userAgent: deviceInfo.userAgent,
            isMobile: this.deviceService.isMobile(),
            isTablet: this.deviceService.isTablet(),
            isDesktop: this.deviceService.isDesktop()
          }),
          new appBootstrap.StartApplicationAction()
        ];
      })
    );
  });

  login$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.LOGIN_USER),
      map((action: session.LoginUserAction) => action.payload),
      switchMap(payload =>
        this.authService.loginWithPayload(payload).pipe(
          map((loginResponse: any) => {
            return new session.LoginUserSuccessAction(loginResponse);
          }),
          catchError(error => {
            return of(new session.LoginUserFailAction(error));
          })
        )
      )
    );
  });

  refresh$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.REFRESH_USER),
      map((action: session.RefreshUserAction) => action.payload),
      concatLatestFrom(() => this.store.select(fromRoot.getSessionIsLoading)),
      switchMap(([, loading]) => {
        let obs: Observable<Action> = of(new session.RefreshUserCancelledAction(false));
        if (!loading) {
          obs = this.authService.refresh().pipe(
            map((loginResponse: any) => new session.RefreshUserSuccessAction(loginResponse)),
            catchError(error => of(new session.RefreshUserFailAction(error)))
          );
        }
        return obs;
      })
    );
  });

  afterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BrandActions.LOAD_USER_BRAND_DATA_SUCCESS, BrandActions.LOAD_USER_BRAND_DATA_FAILURE),
      map(
        (action: BrandActions.LoadUserBrandDataSuccess | BrandActions.LoadUserBrandDataFailure) =>
          action.payload
      ),
      distinctUntilKeyChanged(
        'userId',
        (prev: string, next: string) => prev.toLowerCase() === next.toLowerCase()
      ),
      map(payload => new session.LoadAccountAction(payload))
    );
  });

  getClaimsAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.LOAD_ACCOUNT),
      map((action: session.LoadAccountAction) => action.payload),
      switchMap(payload => {
        const returnActions = [];
        returnActions.push(new fromClaimActions.LoadClaims(payload));
        returnActions.push(
          new fromClaimDashboardActions.LoadClaimDashboard({ cdhId: payload.customerId })
        );
        return returnActions;
      })
    );
  });

  serveMandate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProfileActions.ProfileActionTypes.FETCH_PARTY_DATA_SUCCESS),
        withLatestFrom(
          this.store.select(userQuery.getTrackId),
          this.store.select(userQuery.getLastLoggedInOn),
          this.store.select(impersonationQuery.isImpersonating)
        ),
        map(([payload, trackId, lastLoggedIn, isImpersonating]) => {
          if (!isImpersonating && lastLoggedIn !== '') {
            this.updateContactService.serveMandate(trackId, lastLoggedIn);
          }
        })
      );
    },
    { dispatch: false }
  );

  time$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.SESSION_LOAD),
      map((action: session.SessionLoadAction) => action),
      switchMap(() =>
        this.authService.getGatewayTime().pipe(
          map((time: GatewayTime) => {
            if (time && time.utcFormat) {
              const clientTime = new Date();
              const serverTime = parse(new Date(time.utcFormat));
              const offset = differenceInMilliseconds(clientTime, serverTime);
              return new session.SetTimeOffsetAction({
                differenceInMilliseconds: offset
              });
            }
          })
        )
      )
    );
  });

  setRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.LOAD_ACCOUNT),
      map((action: session.LoadAccountAction) => action.payload),
      map(payload => new fromImpersonationActions.SetRolesAction(payload))
    );
  });

  init$: Observable<Action> = createEffect(() => {
    return defer(() => of(new session.SessionLoadAction()));
  });

  setuser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.LOGIN_USER_SUCCESS),
      map((action: session.LoginUserSuccessAction) => action.payload),
      map(payload => new fromUserActions.LoginSuccess(payload))
    );
  });

  loadForUser$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.LOGIN_USER_SUCCESS),
      map((action: session.LoginUserSuccessAction) => action.payload),
      distinctUntilKeyChanged(
        'userId',
        (prev: string, next: string) => prev.toLowerCase() === next.toLowerCase()
      ),
      switchMap(payload => {

        // set analytic profile info
        this.analyticsFacade.setProfileInfo({
          trackId: payload.trackId,
          experienceId: payload.experienceId,
          partnerId: payload.partnerId
        });

        // AS: Add the trackId to the sessionStorage to be used by OpinionLab
        sessionStorage.setItem('trackID', payload?.trackId);

        const eventAnalytic: EventAnalytic = {
          eventName: 'login success',
          eventStep: ''
        };
        this.analyticsFacade.trackEvent(eventAnalytic);
        this.dynatraceService.sendDynatraceAction('experience', 'trackId' + '|' + payload.trackId);
        this.dynatraceService.sendDynatraceAction('experience', 'expid' + '|' + payload.experienceId);

        return this.brandService.loadBrandForUser(payload.experienceId).pipe(
          map(() => new BrandActions.LoadUserBrandDataSuccess(payload)),
          catchError(() => of(new BrandActions.LoadUserBrandDataFailure(payload)))
        );
      })
    );
  });

  constructor(
    private store: Store<fromRoot.RootState>,
    private actions$: Actions,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    private updateContactService: UpdateContactService,
    private brandService: BrandService,
    private feature: FeatureFlagService,
    private utilService: UtilService,
    private analyticsFacade: AnalyticsFacade,
    private dynatraceService: DynatraceService,
  ) {}

  /* eslint-disable @typescript-eslint/member-ordering */

  loadAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.LOAD_ACCOUNT),
      map((action: session.LoadAccountAction) => action.payload),
      filter(
        payload =>
          // Make sure we have a customerId before calling getPolicySummaries
          payload && payload.customerId && payload.customerId !== ''
      ),
      map(payload => new PolicySummaryActions.LoadSummaries(payload))
    );
  });

  loadPaymentMethods$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.LOAD_ACCOUNT),
      map((action: session.LoadAccountAction) => action.payload),
      map(payload =>
        PaymentMethodActions.getPaymentMethods({ correlationId: this.utilService.generateId() })
      )
    );
  });

  /* eslint-disable @typescript-eslint/member-ordering */

  loadBilling$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.LOAD_ACCOUNT),
      map((action: session.LoadAccountAction) => action.payload),
      switchMap(payload => [
        new BillAccountActions.BillAccountsLoad(payload),
        new PaymentAccountActions.PaymentAccountsLoad(payload),
        new ScheduledPaymentActions.ScheduledPaymentsLoad(payload),
        new fromPaymentHistoryActions.GetPayments({ monthRange: { startMonth: 3, endMonth: 0 } })
      ])
    );
  });

  loadAccountForProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(session.LOAD_ACCOUNT),
      map((action: session.LoadAccountAction) => action.payload),
      map(payload => new ProfileActions.ProfileLoad(payload))
    );
  });
}
