import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { BrandSelectors } from '@amfam/shared/utility/brand';

import { Content, ContentService, PartialContentPaths } from '../../core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ds-payment-terms-and-conditions',
  templateUrl: './payment-terms.component.html'
})
export class PaymentTermsComponent implements OnInit {
  @Input() paymentMethodType: string;
  @Input() borderColor = 'primary-800';
  cardAuthText: Observable<Content>;
  achAuthText: Observable<Content>;
  autopayCardAuthText: Observable<Content>;
  autopayACHAuthText: Observable<Content>;
  loading = true;
  constructor(
    private store: Store,
    public contentService: ContentService
  ) {}

  ngOnInit() {
    this.store
      .select(BrandSelectors.selectBrandId)
      .pipe(
        filter(id => !!id),
        take(1)
      )
      .subscribe(id => {
        switch (this.paymentMethodType) {
          case 'Card': {
            const singleDccPath: PartialContentPaths = '-payments-singlepaydcc';
            const singleDccFullPath = id + singleDccPath;
            this.cardAuthText = this.contentService.getContent(singleDccFullPath).pipe(
              tap(content => {
                this.loading = !_get(content, 'richContent', false);
              })
            );
            break;
          }
          case 'Bank': {
            const singleAchPath: PartialContentPaths = '-payments-singlepayach';
            const singleAchFullPath = id + singleAchPath;
            this.achAuthText = this.contentService.getContent(singleAchFullPath).pipe(
              tap(content => {
                this.loading = !_get(content, 'richContent', false);
              })
            );
            break;
          }
          case 'autopayCard': {
            const autoDccPath: PartialContentPaths = '-payments-autopaydcc';
            const autoDccFullPath = id + autoDccPath;
            this.autopayCardAuthText = this.contentService.getContent(autoDccFullPath).pipe(
              tap(content => {
                this.loading = !_get(content, 'richContent', false);
              })
            );
            break;
          }
          case 'autopayBank': {
            const autoAchPath: PartialContentPaths = '-payments-autopayach';
            const autoAchFullPath = id + autoAchPath;
            this.autopayACHAuthText = this.contentService.getContent(autoAchFullPath).pipe(
              tap(content => {
                this.loading = !_get(content, 'richContent', false);
              })
            );
            break;
          }
          case 'Unknown': {
            this.loading = false;
            break;
          }
          default:
            break;
        }
      });
  }
}
