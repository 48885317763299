/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { CopyService } from '@amfam/shared/utility/shared-services';

import { ForgerockAuthService } from '../../auth/forgerock-auth.service';
import { forgerockLogin } from './forgerock-session.actions';
import { LoginUserFailAction, LoginUserSuccessAction } from './session.actions';

@Injectable()
export class ForgerockSessionEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgerockLogin),
      map(action => action),
      switchMap(action =>
        this.forgerockAuthService.forgerockLogin(action.accessToken, action.idToken).pipe(
          map((loginResponse: any) => {
            const key = this.copyService.getCopy('auth.pendingRedirectKey');
            const pendingRedirect = sessionStorage.getItem(key);
            if (pendingRedirect) {
              sessionStorage.removeItem(key);
              return fromRouterActions.Go({ path: [pendingRedirect] });
            } else {
              switch (localStorage.getItem('frFlowType')) {
                case 'enrollment':
                  return fromRouterActions.Go({ path: ['/paperless/sign-up'] });

                case 'change-password':
                  return fromRouterActions.Go({
                    path: ['/profile'],
                    query: { openAccordion: 'security' }
                  });
                default:
                  return new LoginUserSuccessAction(loginResponse);
              }
            }
          }),
          catchError(error => {
            const key = this.copyService.getCopy('auth.pendingRedirectKey');
            sessionStorage.removeItem(key);
            return of(new LoginUserFailAction(error));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private forgerockAuthService: ForgerockAuthService,
    private copyService: CopyService
  ) {}
}
