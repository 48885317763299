import { get as _get, values as _values } from 'lodash';

import { Entities, initialEntities } from '../../entity/entity.model';
import * as kydSmartphoneEnrollPolicyActions from './kyd-smartphone-enroll-policy.actions';
import { KydSmartphoneEnrollPolicyState } from './models';

export function reducer(
  state = initialEntities<KydSmartphoneEnrollPolicyState>(),
  action: kydSmartphoneEnrollPolicyActions.Actions
): Entities<KydSmartphoneEnrollPolicyState> {
  const entities: any = state.entities;
  let key: string;

  switch (action.type) {
    case kydSmartphoneEnrollPolicyActions.ENROLL_POLICY_INIT:
      action.payload = action.payload || [];
      action.payload.forEach(policy => {
        entities[policy['policyNumber']] = {
          inProgress: true,
          policy: policy,
          loaded: false
        };
      });

      return Object.assign({}, state, {
        ids: Object.keys(entities),
        entities: Object.assign({}, state.entities, entities)
      });

    case kydSmartphoneEnrollPolicyActions.ENROLL_POLICY:
      key = action.payload['policyNumber'];
      entities[key] = Object.assign({}, entities[key], {
        inProgress: true,
        loaded: false
      });

      return Object.assign({}, state);

    case kydSmartphoneEnrollPolicyActions.ENROLL_POLICY_SUCCESS:
      key = _get(action, 'payload._policy.policyNumber', '');

      entities[key] = Object.assign({}, entities[key], {
        enrolled: true,
        inProgress: false,
        loaded: true
      });

      return Object.assign({}, state);

    case kydSmartphoneEnrollPolicyActions.ENROLL_POLICY_FAIL:
      key = _get(action, 'payload._policy.policyNumber', '');

      entities[key] = Object.assign({}, entities[key], {
        enrolled: false,
        inProgress: false,
        error: true,
        loaded: true
      });

      return Object.assign({}, state);

    case kydSmartphoneEnrollPolicyActions.ENROLL_POLICY_RESET:
      return initialEntities<KydSmartphoneEnrollPolicyState>();

    default:
      return state;
  }
}

export const getStatus = (state: Entities<KydSmartphoneEnrollPolicyState>) => state.loaded;
export const getIds = (state: Entities<KydSmartphoneEnrollPolicyState>) => state.ids;
export const getEntities = (state: Entities<KydSmartphoneEnrollPolicyState>) => {
  return _values(state.entities);
};
