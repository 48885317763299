export interface Environment {
  production: boolean;
  devMode: boolean;
  suppressErrorPage: boolean;
  billingCareCentreEmail: string;
  billingPersonalLinesEmail: string;
  billingCommercialLinesEmail: string;
  billingInternalFromEmail: string;
  policyChangeRequestInternal: string;
  policyGracePeriodNotificatonInternal: string;
  policyGracePeriodNotificatonInternalAgencyMailbox: string;
  claimContantAdjusterInternal: string;
  claimReportClaimInternal: string;
  partnerBillingEmail: string;
  partnerClaimEmail: string;
  partnerAdjusterEmail: string;
  partnerLicensedAdvisorEmail: string;
  gaProperty: string;
  currentDate: Date;
}

//**Partner information plus base shared properties */
export interface EnvironmentShared extends EnvironmentBase {
  partnerName: string;
  partnerId: string;
}

//** My accounts original shared properties */
export interface EnvironmentBase {
  production: boolean;
  devMode: boolean;
  suppressErrorPage: boolean;
  billingCareCentreEmail: string;
  billingPersonalLinesEmail: string;
  billingCommercialLinesEmail: string;
  billingInternalFromEmail: string;
  policyChangeRequestInternal: string;
  policyGracePeriodNotificatonInternal: string;
  policyGracePeriodNotificatonInternalAgencyMailbox: string;
  claimContantAdjusterInternal: string;
  claimReportClaimInternal: string;
  partnerBillingEmail: string;
  partnerClaimEmail: string;
  partnerAdjusterEmail: string;
  partnerLicensedAdvisorEmail: string;
  gaProperty: string;
  currentDate: Date;
}
