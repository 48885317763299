<!-- here -->
<div [formGroup]="formGroup">
  <!-- Routing Number -->
  <label
    for="routingNumber"
    class="subheading"
    content
    [contentPath]="'billing.routingNumber'"
  ></label>
  <div class="margin-bottom-30">
    <ds-form-control-container [control]="formGroup.get('routingNumber')">
      <input
        type="text"
        id="routingNumber"
        class="fill"
        autocomplete="off"
        formControlName="routingNumber"
        data-cy="paymentMethodRoutingNumberInput"
      />
    </ds-form-control-container>
    <ds-form-control-message [control]="formGroup.get('routingNumber')"></ds-form-control-message>
    <!--Financial Institution Name-->
    @if (!!financialInstitutionName && formGroup.get('routingNumber').valid) {
      <p class="caption">
        {{ financialInstitutionName }}
      </p>
    }
  </div>

  <!--Account Number-->
  <label
    for="routingNumber"
    class="subheading"
    content
    [contentPath]="'billing.accountNumber'"
  ></label>
  <div class="margin-bottom-20">
    <ds-form-control-container [control]="formGroup.get('accountNumber')">
      <input
        type="text"
        id="accountNumber"
        autocomplete="off"
        class="fill"
        formControlName="accountNumber"
        data-cy="paymentMethodAccountNumberInput"
      />
    </ds-form-control-container>
    <ds-form-control-message [control]="formGroup.get('accountNumber')"></ds-form-control-message>
  </div>

  <!--Business Account-->
  <div class="row margin-bottom-20">
    <input
      type="checkbox"
      class="checkbox"
      id="businessAccount"
      formControlName="businessAccount"
      (change)="businessAccountCheck($event.target.checked)"
    />
    <label
      for="businessAccount"
      class="checkbox-label margin-left-0"
      content
      [contentPath]="'billing.thisIsABusinessAccount'"
    ></label>
  </div>

  <!-- Payment Method Nickname -->
  <label
    for="routingNumber"
    class="subheading"
    content
    [contentPath]="'billing.paymentMethodNickname'"
  ></label>
  <div class="margin-bottom-30">
    <ds-form-control-container [control]="formGroup.get('nickName')">
      <input
        type="text"
        id="nickName"
        class="fill"
        formControlName="nickName"
        data-cy="paymentMethodNameInput"
      />
    </ds-form-control-container>
    <ds-form-control-message [control]="formGroup.get('nickName')"></ds-form-control-message>
  </div>

  <!--Criteria List-->
  <div class="margin-bottom-30">
    <ds-criteria-list [criteriaItems]="criteria | async"> </ds-criteria-list>
  </div>
</div>
