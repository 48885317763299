@if (svg) {
  <svg
    xmlns="http://www.w3.org/2000/svg"
    [attr.viewBox]="svg.viewBox"
    preserveAspectRatio="xMidYMid meet"
    [attr.height]="svg?.height"
    [attr.width]="svg?.width"
  >
    <title>{{ percent }}% {{ title }}</title>
    <circle
      [attr.cx]="svg?.circle?.cx"
      [attr.cy]="svg?.circle?.cy"
      [attr.r]="svg?.circle?.r"
      [attr.class]="'circle'"
      [attr.stroke-width]="svg?.circle?.strokeWidth"
    />
    <path
      [attr.d]="svg?.path.d"
      [attr.class]="'path'"
      [attr.stroke-width]="svg?.path?.strokeWidth"
    />
  </svg>
}
