import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Agent, initialAgent } from '@amfam/shared/utility/agent';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'agent-item',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnChanges {
  @Input() agent: Agent;
  @Input() defaultContactNumber: string;
  @Input() newTemplate = false;
  emailUrl: string;
  initials: string;
  mainPhoneNumber: string;
  mapUrl: string;
  mainOfficeAddress: string;
  private mainOffice: any;
  photoError: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.agent) {
      this.agent = initialAgent;
    }
    this.emailUrl = this.getEmailUrl();
    this.initials = this.getInitials();
    this.mainOffice = this.getMainOffice();
    this.mainPhoneNumber = this.getMainPhoneNumber();
    this.mapUrl = this.getMapUrl();
    this.mainOfficeAddress = this.getMainAddress();
  }

  private getEmailUrl(): string {
    return this.agent?.emailFormUrl || '';
  }

  private getInitials(): string {
    if (this.agent.firstName && this.agent.lastName) {
      return `${this.agent.firstName.slice(0, 1).toUpperCase()}${this.agent.lastName
        .slice(0, 1)
        .toUpperCase()}`;
    }

    return '';
  }

  private getMainOffice(): any {
    if (this.agent.offices && this.agent.offices.length) {
      return this.agent.offices.find(office => office.typeOfOfficeCode === 'WORK_ADDRESS');
    }

    return null;
  }

  private getMainPhoneNumber(): string {
    const mainOffice = this.mainOffice;

    if (mainOffice && mainOffice.phones && mainOffice.phones.length) {
      const mainPhone = mainOffice.phones.find(phone => {
        return phone.typeOfPhoneCode === 'OFFICE' && phone.typeOfUsageCode === 'VOICE';
      });

      if (mainPhone) {
        return `1${mainPhone.areaCode}${mainPhone.phoneNumber}`;
      }
    }

    return '';
  }

  private hasMainAddress(): boolean {
    const mainOffice = this.mainOffice;
    if (mainOffice && mainOffice.address) {
      return true;
    }

    return;
  }

  private getMainAddress(): string {
    const mainOffice = this.mainOffice;
    if (this.hasMainAddress()) {
      if (mainOffice && mainOffice.address) {
        const addressLines = mainOffice.address.line2
          ? `${mainOffice.address.line1} ${mainOffice.address.line2}`
          : `${mainOffice.address.line1}`;
        const address = `${addressLines} ${mainOffice.address.city} ${mainOffice.address.state} ${mainOffice.address.zip5}`;
        return address;
      }
    }

    return '';
  }

  private getMapUrl(): string {
    const address = this.getMainAddress();
    if (this.hasMainAddress()) {
      return `https://www.google.com/maps/place/${encodeURIComponent(address)}`;
    }
    return '';
  }

  onPhotoError() {
    this.photoError = true;
  }
}
