import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AnalyticsEffects } from './+state/analytics.effects';
import * as fromAnalytics from './+state/analytics.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAnalytics.analyticsFeatureKey, fromAnalytics.reducer),
    EffectsModule.forFeature([AnalyticsEffects])
  ]
})
export class SharedAnalyticsModule {}
