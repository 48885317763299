import { Injectable } from '@angular/core';

import { EnrollmentStepNames } from '../../shared/enrollment-step-names.interface';

export class BusinessServiceStepNames implements EnrollmentStepNames {
  readonly [index: string]: symbol;
  readonly enrollBusiness: symbol = Symbol('enroll-business');
  readonly signUpBusiness: symbol = Symbol('sign-up-business');
  readonly securityQuestionsBusiness: symbol = Symbol('security-questions');
}

@Injectable()
export class BusinessService {
  readonly stepNames: BusinessServiceStepNames = new BusinessServiceStepNames();

  constructor() {}
}
