import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AutoPaySetupEffects } from './auto-pay-setup.effects';
import * as fromAutoPaySetup from './auto-pay-setup.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(fromAutoPaySetup.autoPaySetupFeatureKey, fromAutoPaySetup.reducer),
    EffectsModule.forFeature([AutoPaySetupEffects])
  ]
})
export class AutoPaySetupStateModule {}
