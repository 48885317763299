import { isFuture } from 'date-fns';

import { SortTypes } from '@amfam/shared/utility/shared-services';

import { Policy } from './policy';
import { PolicyBase } from './policy-base';
import { PolicyStatus } from './policy-status';
import { RiskSummary } from './risk-summary';

export class PolicySummary extends PolicyBase {
  public policyNumber: string;
  public productType: string;
  public effectiveDate: string;
  public endEffectiveDate: string;
  public billAccountNumber: string;
  public status: string;
  public policyStatus: PolicyStatus;
  public originatingProducerId: string;
  public servicingProducerId: string;
  public policyTypeCode: string;
  public contractState: string;
  public sortType: string;
  public operator: boolean;
  public poiLink: string;
  public risk: RiskSummary[] = new Array<RiskSummary>();

  static fromJson(json: any): PolicySummary {
    const policySummary = new PolicySummary();
    policySummary.assignProperties(json);

    return policySummary;
  }

  protected assignProperties(json: any) {
    /*
    Moved from POLICY to PRODUCTS.
    */
    if (json.sourceSystemProductIdentifier) {
      // dealing with PRODUCTS
      json.policyNumber = json.sourceSystemProductIdentifier;
      json.sourceSystem = json.sourceSystemName;
      json.productType = json.productCode;
      super.assignProperties(json);
      this.productDescription = json.productTypeDescription;
      this.effectiveDate = json.policyInForceOn;
      this.endEffectiveDate = json.policyExpiresOn;
      this.billAccountNumber = json.billAccountNumber_BOBUSEONLY; // TODO: deprecated
      this.status = this.getPolicyStatus(json.policyInForceIndicator, this.effectiveDate);
      this.policyStatus = Policy.getPolicyStatusType(this.status);
      this.servicingProducerId = json.servicingAgentProducerId;
      this.policyTypeCode = json.policyTypeCode;
      this.contractState = json.contractState;
      this.sortType = !!json.operator
        ? SortTypes['OPERATOR']
        : SortTypes[this.productDescription?.trim().replace(/\s/g, '').toUpperCase()];
      this.operator = !!json.operator;
    } else {
      // dealing with POLICY
      super.assignProperties(json);
      this.effectiveDate = json.effectiveDate;
      this.endEffectiveDate = json.endEffectiveDate;
      this.billAccountNumber = json.billAccountNumber;
      this.status = json.status;
      this.policyStatus = Policy.getPolicyStatusType(json.status);
      this.originatingProducerId = json.originatingProducerId;
      this.servicingProducerId = json.servicingProducerId;
      this.operator = !!json.operator;
      this.sortType = !!json.operator ? SortTypes['OPERATOR'] : this.sortType;
    }
    if (json.risk) {
      for (const risk of json.risk) {
        this.risk.push(RiskSummary.fromJson(risk));
      }
    }
  }

  // TODO: use effectiveDate to check for Scheduled status
  private getPolicyStatus(policyInForceIndicator, effectiveDate): string {
    if (policyInForceIndicator === true || policyInForceIndicator === 'true') {
      return 'Inforce';
    }
    if (isFuture(effectiveDate)) {
      return 'Scheduled';
    }
    return 'Other';
  }
}
