import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { BrandActions, BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';

import { ContentService } from '../../../core';
import * as fromRoot from '../../../core/store';
import * as systemStatus from '../app-bootstrap/app-bootstrap.actions';

@Injectable()
export class AppBootstrapEffects {
  constructor(
    private actions$: Actions,
    private contentService: ContentService,
    private featureFlagService: FeatureFlagService,
    private store: Store<fromRoot.RootState>
  ) {}

  /**
   * Once Brand Data is available, get the Outage Banner content while sending PartnerId as a parameter. The Outage Banner
   * content is dynamic based on partnerId sent.
   */

  // eslint-disable-next-line @typescript-eslint/member-ordering
  start$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandActions.LOAD_BRAND_DATA_SUCCESS),
      withLatestFrom(this.store.select(BrandSelectors.selectPartnerId)),
      mergeMap(([action, partnerId]) => {
        const contentPath = 'OutageBanner?partnerId=' + partnerId;
        return this.contentService.getContent(contentPath).pipe(
          map((contentResponse: any) => {
            if (contentResponse.richContent !== '') {
              return new systemStatus.LoadSystemBannerSuccessAction(contentResponse);
            } else {
              return new systemStatus.LoadSystemBannerFailAction(contentResponse);
            }
          })
        );
      })
    )
  );
}
