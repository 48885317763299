import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ProfileOverviewModel } from '@amfam/shared/models';
import { Applications, ApplicationService } from '@amfam/shared/utility/shared-services';

@Component({
  selector: 'ds-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileCardComponent {
  @Input() profileCard: ProfileOverviewModel;
  @Input() isCustomer: boolean;
  @Input() isShellAccount: boolean;
  @Input() canShowStatus: boolean;
  @Input() shellEmail: string;
  @Input() isPartyLoaded = false;
  @Input() forgerockEnabled = false;

  @Output() moveToPhoneNumberClicked = new EventEmitter<void>();
  isMyAccountAdmin = this.appService.isApp(Applications.MYACCOUNT_ADMIN);

  constructor(private appService: ApplicationService) {}

  /*
    Todo: Once the API is ready, we will swap greetingName with goesByName in the interface, model, effect and here in the function.
  */
  // get greetingName or firstName
  get greetOrFirstName(): string {
    return this.profileCard.greetingName || this.profileCard.firstName;
  }
  getApplicationName(): boolean {
    return this.isMyAccountAdmin;
  }
}
