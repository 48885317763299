import { Directive, HostListener } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Directive({
  selector: '[dsExpirationDateMask]'
})
export class ExpirationDateMaskDirective {
  private model: FormControlName;
  private seperator = '/';

  constructor(model: FormControlName) {
    this.model = model;
  }

  @HostListener('keyup', ['$event'])
  onInputChange(event: KeyboardEvent) {
    if (!this.model.value || this.model.value.length <= 0) {
      return;
    }

    let actualValue = this.model.value;
    let maskedValue = '';

    // if the user inputs '/' it will not interfere
    // if he/she doesn't, the directive will put the '/' for them
    const backspaceKey = event.keyCode || event.charCode;

    if (backspaceKey === 8) {
      return;
    }

    /*
    if (keyEntered === 47 || keyEntered === 191) {
      return;
    }
*/
    if (!actualValue.match('^[0-9/]+$')) {
      actualValue = actualValue.replace(/[^\d/]/g, '');

      maskedValue = actualValue;
      // This is the actual binding (unmasked) value
      this.model.viewToModelUpdate(actualValue);

      // This is the displaying (masked) value
      this.model.valueAccessor.writeValue(maskedValue);
    } else {
      if (
        actualValue.charAt(actualValue.length - 1) === '/' &&
        actualValue.length !== 3 &&
        actualValue.length !== 6
      ) {
        actualValue = actualValue.substr(0, actualValue.length - 1);
        maskedValue = actualValue;
        // This is the actual binding (unmasked) value
        this.model.viewToModelUpdate(actualValue);

        // This is the displaying (masked) value
        this.model.valueAccessor.writeValue(maskedValue);
      }
    }

    // Check the length. If it's < 2 do nothing
    if (actualValue.length < 2) {
      return;
    } else if (actualValue.length === 2) {
      // Length is 2 - append the seperator
      if ((actualValue.match(/\//g) || []).length === 1) {
        // KP: check if the separator is being put already for months less than 10
        // If so, prepend a '0' before the string and update the view and model
        maskedValue = '0' + actualValue;
        this.model.viewToModelUpdate(actualValue);
        this.model.valueAccessor.writeValue(maskedValue);
        return;
      }

      maskedValue = actualValue + this.seperator;
    } else {
      // Length is > 2 && < 5 - do nothing
      return;
    }
    // This is the actual binding (unmasked) value
    this.model.viewToModelUpdate(actualValue);

    // This is the displaying (masked) value
    this.model.valueAccessor.writeValue(maskedValue);
  }

  insert(str: string, index: number, value: string) {
    return str.substr(0, index) + value + str.substr(index);
  }
}
