import { ApiStatus } from '@amfam/shared/models';

import { SummariesAction, SummariesActionTypes } from './summaries.actions';

export interface NotificationState {
  error: ApiStatus | null;
  success: ApiStatus | null;
  loading: boolean;
}

export const initialState: NotificationState = {
  loading: null,
  error: null,
  success: null
};

export function reducer(
  state: NotificationState = initialState,
  action: SummariesAction
): NotificationState {
  switch (action.type) {
    case SummariesActionTypes.LoadSummariesType: {
      return { ...state, loading: true };
    }

    case SummariesActionTypes.SummariesLoadStatusNotFoundType:
    case SummariesActionTypes.SummariesLoadErrorType: {
      return { ...state, loading: false, error: action.payload };
    }

    case SummariesActionTypes.SummariesLoadSuccessType:
    case SummariesActionTypes.SummariesLoadedType: {
      return { ...state, loading: false, error: null, success: action.payload };
    }

    default: {
      return state;
    }
  }
}
