import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { get as _get, has as _has } from 'lodash';

import {
  AddEditPaymentAccountSuccessModel,
  ExpirationStatus,
  initialPaymentAccount,
  PaymentAccount,
  UNAVAILABLE_PAYMENTACCOUNT_ID
} from '@amfam/shared/models';

import { PaymentAccountActions, PaymentAccountActionTypes } from './paymentaccount.actions';

export const PAYMENT_ACCOUNT_FEATURE_KEY = 'payment account';

export interface PaymentAccountPartialState {
  readonly [PAYMENT_ACCOUNT_FEATURE_KEY]: PaymentAccountEntityState;
}

export interface PaymentAccountEntityState extends EntityState<PaymentAccount> {
  selectedPaymentAccountNickName: string;
}

export const adapter: EntityAdapter<PaymentAccount> = createEntityAdapter<PaymentAccount>({
  selectId: (paymentAccountObjModel: PaymentAccount) => paymentAccountObjModel.nickName,
  sortComparer: false
});

export const initialState: PaymentAccountEntityState = adapter.getInitialState({
  selectedPaymentAccountNickName: ''
});

export function reducer(
  state: PaymentAccountEntityState = initialState,
  action: PaymentAccountActions
): PaymentAccountEntityState {
  switch (action.type) {
    case PaymentAccountActionTypes.PaymentAccountsLoadType: {
      return initialState;
    }

    case PaymentAccountActionTypes.PaymentAccountsLoadSuccessType: {
      const expirationStatus = _get(action, 'payload.creditCard.expirationStatus');
      const expired = expirationStatus === ExpirationStatus.EXPIRED;
      const expiring = expirationStatus === ExpirationStatus.EXPIRING_SOON;
      //const payload =_omit(action.payload, ['creditCard.expirationStatus']);
      return adapter.upsertOne({ ...action.payload, expired: expired, expiring: expiring }, state);
    }

    case PaymentAccountActionTypes.PaymentAccountsLoadDetailSuccessType: {
      return adapter.upsertOne(
        {
          ...action.payload,
          expired:
            _get(action, 'payload.creditDetails.expirationStatus') === ExpirationStatus.EXPIRED,
          expiring:
            _get(action, 'payload.creditDetails.expirationStatus') ===
            ExpirationStatus.EXPIRING_SOON,
          autopayRules: _get(action, 'payload.autopayRules'),
          schedulePayment: _get(action, 'payload.scheduledPayments')
        },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsSaveType: {
      let savePaymentId = '';
      if (_get(action, 'payload.action') === 'edit') {
        savePaymentId = _get(action, 'payload.currentPaymentAccountNickName');
      } else {
        savePaymentId = UNAVAILABLE_PAYMENTACCOUNT_ID;
      }
      return adapter.upsertOne(
        { ...state.entities[savePaymentId], nickName: savePaymentId },
        state
      );
    }

    case PaymentAccountActionTypes.PaymentAccountsAddSuccessType: {
      const paymentAccountEntity = buildAddEditPaymentAccountEntity(action.payload);
      return adapter.upsertOne(paymentAccountEntity, state);
    }

    case PaymentAccountActionTypes.PaymentAccountsEditSuccessType: {
      const previousNickName = String(
        _get(action, 'payload.request.currentPaymentAccountNickName')
      );
      const newNickName = String(_get(action, 'payload.request.finAcctPayload.nickName'));
      let editedPaymentAccount = buildAddEditPaymentAccountEntity(action.payload);
      // Add the new entity object under the new nickname
      editedPaymentAccount = Object.assign(
        {},
        state.entities[previousNickName],
        editedPaymentAccount
      );

      adapter.removeOne(previousNickName, state);
      return adapter.upsertOne({ ...editedPaymentAccount, nickName: newNickName }, state);
    }

    case PaymentAccountActionTypes.PaymentAccountsTruncateType:
      return adapter.removeOne(action.payload, state);

    default:
      return state;
  }

  function buildAddEditPaymentAccountEntity(
    payload: AddEditPaymentAccountSuccessModel
  ): PaymentAccount {
    const newPaymentAccount = Object.assign({}, initialPaymentAccount);
    newPaymentAccount.lastUpdateTimestamp = '0001-01-01T00:00:00.000-0600';
    newPaymentAccount.nickName = _get(payload, 'request.finAcctPayload.nickName');
    newPaymentAccount.paymentAccountId = _get(payload, 'response.paymentAccountId');
    newPaymentAccount.modeOfAuthorization = _get(payload, 'request.modeOfAuthorization');
    // Checking expired here shouldn't be needed, but checking just in case - you can't add an expired payment method
    newPaymentAccount.expired = _get(payload, 'request.finAcctPayload.expired', false);
    newPaymentAccount.expiring = _get(payload, 'request.finAcctPayload.expiring', false);

    // Determine whether to add the bank or credit card info
    if (_has(payload, 'request.finAcctPayload.bankAccount')) {
      // Routing numbers need to be masked (xxxxxx123)
      const routingMasked =
        'xxxxxx' +
        String(_get(payload, 'request.finAcctPayload.bankAccount.routingNumber', '')).slice(-3);
      // Account numbers need to be masked (first n get masked with an x, last 3 are shown)
      const accountUnmasked = String(
        _get(payload, 'request.finAcctPayload.bankAccount.accountNumber', '')
      );
      // create a string of x's that is three less than the over all length, then add the last 3 chars of the account
      const accountMasked = 'x'.repeat(accountUnmasked.length - 3) + accountUnmasked.slice(-3);
      newPaymentAccount.achWithdrawal = {
        routingNumber: routingMasked,
        accountNumber: accountMasked,
        accountType: _get(payload, 'request.finAcctPayload.bankAccount.accountType', ''),
        accountUse: _get(payload, 'request.finAcctPayload.bankAccount.accountUse', ''),
        financialInstitution: _get(payload, 'request.financialInstitution', '')
      };
    } else if (_has(payload, 'request.finAcctPayload.creditCard')) {
      const cardTypeAbbrev = String(
        _get(payload, 'request.finAcctPayload.creditCard.cardType', '')
      );
      const cardMask = cardTypeAbbrev === 'AX' ? 'xxxxx-xxxxxxx-x' : 'xxxx-xxxx-xxxx-';
      const lastFour = String(
        _get(payload, 'request.finAcctPayload.creditCard.cardNumber', '')
      ).slice(-4);
      let cardType = '';
      switch (cardTypeAbbrev) {
        case 'AX':
          cardType = 'American Express';
          break;
        case 'DS':
          cardType = 'Discover';
          break;
        case 'VI':
          cardType = 'Visa';
          break;
        case 'MC':
          cardType = 'Mastercard';
          break;
      }
      newPaymentAccount.creditCard = {
        cardNumber: cardMask + lastFour,
        cardType: cardType
      };
    }
    return newPaymentAccount;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
