import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get, some as _some } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

// Store
import {
  BillAccountActions,
  BillAccountsSelectors,
  BillingUtilService
} from '@amfam/billing/billaccount/data-access';
import { PolicyUtilService } from '@amfam/policy/e-signature/data-access';
import { GoPaperLessActions } from '@amfam/policy/go-paperless/data-access';
import {
  BillAccount,
  BillAccountData,
  DeliveryPreferences,
  NewBillingPreference,
  UpdatedPreferenceBillAccount
} from '@amfam/shared/models';
import { UserState } from '@amfam/shared/user';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { UtilService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { initiateOverviewPaperlessEnrollment } from '../+state/overview-paperless-enrollment-feature-actions';
import {
  selectAnyLoading,
  selectCombinedBillAccountState,
  selectPaperlessSetupData
} from '../+state/overview-paperless-enrollment-feature-selectors';

@Component({
  selector: 'amfam-set-billing-and-policy-preferences',
  templateUrl: './set-billing-and-policy-preferences.component.html'
})
export class SetBillingAndPolicyPreferencesComponent implements OnInit, OnDestroy {
  @Input() routeToConfirmation = false;
  @Input() billingEligibleToGoPaperless$: Observable<boolean>;
  @Input() isPolicyGoPaperless$: Observable<boolean>;

  setPreferencesSuccess = false;
  setPreferencesFailure = false;
  showTryAgainModal = false;
  /**
   *  new falg: paperless_confirmation_new
   */
  paperLessConfirmationNew: boolean;
  user: UserState;
  updatedPreferenceBillAccounts: UpdatedPreferenceBillAccount[] = [];
  dataLoading: Observable<boolean>;
  private newBillingPreferences: NewBillingPreference[] = [];
  private stop$ = new Subject<void>();
  private correlationId: string;
  private isESignatureRequired: boolean;
  private policyPreference: string;
  private billAccounts: BillAccount[] = [];
  private readonly goPaperLessSignaturePath = 'policies/gopaperless/signature';

  constructor(
    private spinner: LoadingSpinnerService,
    private store: Store,
    private utilService: UtilService,
    private billingUtilService: BillingUtilService,
    private policyUtilService: PolicyUtilService,
    private feature: FeatureFlagService
  ) {}

  ngOnInit() {
    this.dataLoading = this.store.select(selectAnyLoading);
    this.paperLessConfirmationNew = this.feature.isEnabled('paperless_confirmation_new');
    // Combined selector of bill accounts and esignature status.
    this.store
      .select(selectPaperlessSetupData)
      .pipe(takeUntil(this.stop$))
      .subscribe(state => {
        this.user = state.user;
        this.billAccounts = state.billAccounts;
        this.isESignatureRequired = state.isEsignatureRequired;
        this.policyPreference = state.isEnrolledGoPaperless
          ? DeliveryPreferences.EMAIL
          : DeliveryPreferences.PAPER;
      });

    // To determine next state on success/failure.
    this.store
      .select(selectCombinedBillAccountState)
      .pipe(
        filter(state => !state.loading && !!this.correlationId),
        map(state => {
          const filtered = state.billAccountNotifications.filter(
            ba => ba.updatePreferenceCorrelationId === this.correlationId
          );
          return {
            ...state,
            billAccountNotifications: filtered
          };
        }),
        filter(
          state =>
            state.billAccountNotifications.length === _get(this.newBillingPreferences, 'length', 0)
        ),
        take(1)
      )
      .subscribe(state => {
        this.spinner.stop();
        const billAccounts: BillAccount[] = state.billAccounts.filter(ba =>
          state.billAccountNotifications.find(ban => ban.billAccountNumber === ba.billAccountNumber)
        );
        const policyPreferenceError: boolean = state.goPaperlessError;
        const billingPreferencesError: boolean = _some(state.billAccountNotifications, {
          updateDeliveryPreferencesError: true
        });

        // List of updatedDeliveryPreferences bill accounts(contains successfully updated or/and failed to update).
        this.updatedPreferenceBillAccounts = billAccounts.map(billAccount => ({
          billAccountNickName: _get(billAccount, 'registrationDate')
            ? billAccount.billingPreferences.accountNickName
            : billAccount.billAccountNumber,
          updatedDeliveryPreferences: _get(
            state.billAccountNotifications.find(
              ban => ban.billAccountNumber === billAccount.billAccountNumber
            ),
            'updatedDeliveryPreferences'
          )
        }));

        // Error.
        if (policyPreferenceError || billingPreferencesError) {
          // To detect if this component is executed from My Programs section and show error modal.
          if (this.routeToConfirmation) {
            if (this.paperLessConfirmationNew) {
              this.store.dispatch(
                fromRouterActions.Go({ path: ['/paperless/gopaperless/confirmation'] })
              );
            } else {
              this.showTryAgainModal = true;
            }
          } else {
            this.setPreferencesFailure = true;
          }
          // } else {
          // Success
          // Route to docusign if e-signature is required and current preference is paper
          // if (this.isESignatureRequired && this.policyPreference === DeliveryPreferences.PAPER) {
          //   this.policyUtilService.dispatchGoPaperlessForGA(this.goPaperLessSignaturePath);
          //   this.spinner.start({
          //     blockActions: true
          //   });
          //   // To detect if this component is executed from My Programs section and route to confirmation.
          //
        } else if (this.routeToConfirmation) {
          const confiramtionPath = this.paperLessConfirmationNew
            ? '/paperless/gopaperless/confirmation'
            : '/policies/gopaperless/confirmation';
          this.store.dispatch(
            // eslint-disable-next-line ngrx/prefer-action-creator-in-dispatch
            fromRouterActions.Go({
              path: [confiramtionPath]
            })
          );
        } else {
          this.setPreferencesSuccess = true;
        }
      });
  }

  goPaperLess(): void {
    //TODO - Define a feature store to handle the flow

    this.spinner.start({
      blockActions: true
    });

    this.correlationId = this.utilService.generateId();
    this.store.dispatch(initiateOverviewPaperlessEnrollment({ correlationId: this.correlationId }));
    this.newBillingPreferences = this.billingUtilService.setBillAccountsToPaperless(
      this.billAccounts
    );

    // Set policy preferences for non georgia customers.
    if (!this.isESignatureRequired) {
      if (this.policyPreference === DeliveryPreferences.PAPER) {
        this.store.dispatch(
          GoPaperLessActions.EnrollGoPaperless({ correlationId: this.correlationId })
        );
      }
    }

    // Set bill account preferences.
    if (this.newBillingPreferences && this.newBillingPreferences.length > 0) {
      this.newBillingPreferences.forEach(newPreference => {
        this.store
          .select(
            BillAccountsSelectors.selectBillAccountDataToUpdatePreferences(
              newPreference.billAccountNumber
            )
          )
          .pipe(take(1))
          .subscribe((billAccountData: BillAccountData) => {
            const preferencesObj = this.billingUtilService.buildPreferencePayload(
              newPreference,
              billAccountData,
              this.correlationId
            );
            this.store.dispatch(
              BillAccountActions.billAccountUpdateDeliveryPreference(preferencesObj)
            );
          });
      });
    } else if (this.isESignatureRequired && this.policyPreference === DeliveryPreferences.PAPER) {
      // When no bill accounts are available to set paperless, proceed with docusign.
      this.policyUtilService.dispatchGoPaperlessForGA(this.goPaperLessSignaturePath);
    }
  }

  goPaperlessEvent(goPaperless: boolean): void {
    if (goPaperless) {
      this.goPaperLess();
    }
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }
}
