import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PrettyClaimNum } from './pipes/format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [PrettyClaimNum],
  exports: [PrettyClaimNum]
})
export class ClaimSharedUtilityModule {}
