import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { forkJoin, noop } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PolicyStatus } from '@amfam/policy/models';
import { userQuery } from '@amfam/shared/user';
import { BrandSelectors } from '@amfam/shared/utility/brand';

import { PolicyDocumentService } from '../../services/policy-documents.service';
import { PolicyService } from '../../services/policy.service';
import { DocumentsActionTypes } from '../documents/documents.actions';
import { fromSummariesActions, LoadSummaries, SummariesAction } from './summaries.actions';
import { selectOperatorPolicies } from './summaries.selectors';

@Injectable()
export class SummariesEffects {
  // TODO: AS - This pattern with datapersistence is not working and i do not have time currently to fix it
  // @Effect() loadSummaries$ = this.dataPersistence.fetch(SummariesActionTypes.LoadSummaries, {
  //   run: (action: LoadSummaries, state: PolicySummariesPartialState) => {
  //     return from(action.payload).pipe(
  //       switchMap(user => this.policyService.getPolicySummaries(_get(user, 'customerId'))),
  //       map(policies => {
  //         const policySummaries = policies.filter(
  //           policy =>
  //             this.policyService.isPolicyTypeSupported(policy.generalizedProductType) &&
  //             (policy.policyStatus === PolicyStatus.Inforce ||
  //               policy.policyStatus === PolicyStatus.Scheduled)
  //         );
  //         return new fromSummariesActions.SummariesLoadSuccess(policySummaries);
  //       })
  //     );
  //   },

  //   onError: (action: SummariesActionTypes.SummariesLoadError, error) => {
  //     // action perform for 404 status code when there are no policies.
  //     if (_get(error, 'status.code') === 404) {
  //       return new fromSummariesActions.SummariesStatusNotFoundAction(error);
  //     } else {
  //       return new fromSummariesActions.SummariesLoadError(error);
  //     }
  //   }
  // });

  loadSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadSummaries),
      map(action => action.payload),
      concatLatestFrom(() => [
        this.store.select(userQuery.getCustomerId),
        this.store.select(BrandSelectors.selectIsPartner)
      ]),
      switchMap(([user, customerId, isPartner]) =>
        this.policyService.getPolicySummaries(_get(user, 'customerId', customerId), isPartner).pipe(
          map(policies => {
            const policySummaries = policies.filter(
              policy =>
                this.policyService.isPolicyTypeSupported(policy.generalizedProductType) &&
                (policy.policyStatus === PolicyStatus.Inforce ||
                  policy.policyStatus === PolicyStatus.Scheduled)
            );
            return fromSummariesActions.SummariesLoadSuccess(policySummaries);
          }),
          catchError(error => {
            const returnErrArray: SummariesAction[] = [];
            // action perform for 404 status code when there are no policies.
            if (_get(error, 'status.code') === 404) {
              returnErrArray.push(fromSummariesActions.SummariesStatusNotFoundAction(error));
            } else {
              returnErrArray.push(fromSummariesActions.SummariesLoadError(error));
            }

            return returnErrArray;
          })
        )
      )
    );
  });

  updateOpertorPoiLinks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentsActionTypes.LoadDocumentsCompleteType),
      concatLatestFrom(() => this.store.select(selectOperatorPolicies)),
      switchMap(([, policies]) =>
        forkJoin(policies.map(policy => this.policyDocumentService.getPOILinks(policy)))
      ),
      map(policies =>
        policies?.length > 0 ? fromSummariesActions.UpdatePoiLink(policies) : new noop()
      )
    );
  });

  constructor(
    private actions$: Actions,
    private policyService: PolicyService,
    private store: Store,
    private policyDocumentService: PolicyDocumentService
  ) {}
}
