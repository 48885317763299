<ds-table-body-row-wrapper [rowDetail]="rowDetail">
  @for (row of rows; track row) {
    <ds-table-body-row
      [columns]="columns"
      [tableClasses]="tableClasses"
      [row]="row"
      [rowDetail]="rowDetail"
    >
    </ds-table-body-row>
  }
</ds-table-body-row-wrapper>
