import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { CriteriaItem } from './criteria-item';

@Component({
  selector: 'ds-criteria-list',
  templateUrl: './criteria-list.component.html',
  styleUrls: ['./criteria-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriteriaListComponent implements OnInit {
  @Input() criteriaItems: CriteriaItem[];

  constructor() {}

  ngOnInit(): void {}
}
