import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { policiesQuery } from '@amfam/policy/data-access';
import { userQuery } from '@amfam/shared/user';
import { Applications, ApplicationService } from '@amfam/shared/utility/shared-services';

import { AdobeAnalyticsService } from '../services/adobe-analytics.service';
import { DataDogService } from '../services/data-dog.service';
import { DynatraceService } from '../services/dynatrace.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import * as AnalyticsActions from './analytics.actions';
import {
  getAdobeAnalyticsLoaded,
  getGoogleAnalyticsLoaded,
  getGtm,
  getUser
} from './analytics.selectors';

@Injectable()
export class AnalyticsEffects {
  loadAdobeAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.loadAdobeAnalytics),
      switchMap(() =>
        this.adobeAnalyticsService.loadAdobeAnalyticsScript().pipe(
          map(() => AnalyticsActions.loadAdobeAnalyticsSuccess()),
          catchError(() => of(AnalyticsActions.loadAdobeAnalyticsFailure()))
        )
      )
    )
  );

  loadGoogleAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.loadGoogleAnalytics),
      switchMap(() =>
        this.googleAnalyticsService.loadGoogleTagManagerScript().pipe(
          map(() => AnalyticsActions.loadGoogleAnalyticsSuccess()),
          catchError(() => of(AnalyticsActions.loadGoogleAnalyticsFailure()))
        )
      )
    )
  );

  loadDynatrace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.loadDynatrace),
      switchMap(() =>
        this.dynatraceService.loadDynatraceScript().pipe(
          map(() => AnalyticsActions.loadDynatraceSuccess()),
          catchError(() => of(AnalyticsActions.loadDynatraceFailure()))
        )
      )
    )
  );

  loadDataDog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.loadDataDog),
        tap(() => this.datadogService.loadDataDog())
      ),
    { dispatch: false }
  );

  sendAnalytic$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.sendAnalytic),
        map(action => action.options),
        withLatestFrom(
          this.store.select(getAdobeAnalyticsLoaded),
          this.store.select(getGoogleAnalyticsLoaded),
          this.store.select(getUser),
          this.store.select(getGtm),
          this.store.select(userQuery.getTrackId),
          this.store.select(userQuery.getAddress),
          this.store.select(policiesQuery.selectAllRawPolicies)
        ),
        tap(
          ([
            options,
            adobeEnabled,
            googleEnabled,
            user,
            gtm,
            trackId,
            primaryAddress,
            policies
          ]) => {
            // always send adobe analytic
            if (adobeEnabled && !options?.isGA) {
              // attach user
              if (user) {
                options = { ...options, user: user };
              }
              this.adobeAnalyticsService.sendAnalytic(options);
            }

            // send google analytic
            if (googleEnabled) {
              // send google analytic for page
              if (options?.page) {
                const atleastOnePolicyActive = policies.some(policy => policy.status === 'InForce');
                let type0002;
                // Determine type0002 only when user is loggedin else it will be undefined
                if (trackId) {
                  type0002 = atleastOnePolicyActive ? 'amf001' : 'amf000';
                }

                const gtmDataLayer = {
                  event: 'dataLayer-initialized',
                  content_language: 'English',
                  content_group: this.applicationService.isApp(Applications.MYACCOUNT_ADMIN)
                    ? 'myaccount-admin'
                    : 'myaccount',
                  user_id: trackId,
                  page_name: options.page.pageName,
                  previous_page_name: gtm?.page_name,
                  logged_in: trackId ? '1' : '0',
                  type0001: 'amf0003',
                  type0002: type0002,
                  zip_code: primaryAddress?.zip5,
                  city: primaryAddress?.city,
                  state: primaryAddress?.state,
                  dam_number: undefined,
                  user: options.user
                };
                this.store.dispatch(AnalyticsActions.storeGtmDetails({ payload: gtmDataLayer }));
                this.googleAnalyticsService.sendAnalytic(gtmDataLayer);
              } else {
                // send google analytic for event
                this.googleAnalyticsService.sendAnalytic(options.event);
              }
            }
            // send dynatrace action if event or page
            if (!!options?.event || !!options?.page) {
              this.dynatraceService.sendActionWithAnalytic(options);
            }

            if (!!options?.event || !!options?.page) {
              this.datadogService.sendActionWithAnalytic(options);
            }
          }
        )
      ),
    { dispatch: false }
  );

  sendDynatraceAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.sendDynatraceAction),
        map(action => action.payload),
        tap(payload =>
          this.dynatraceService.sendDynatraceAction(payload.actionName, payload.actionType)
        )
      ),
    { dispatch: false }
  );

  sendDynatraceAppVersion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        tap(() => this.dynatraceService.sendDynatraceAppVersion())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private dynatraceService: DynatraceService,
    private applicationService: ApplicationService,
    private datadogService: DataDogService
  ) {}
}
