<ds-modal
  *ngIf="!!policyTermsConditionModal"
  [id]="policyTermsConditionModal"
  [closeOthers]="true"
  [maxWidth]="modalWidth"
  (onCloseModalEvent)="closeModal($event)"
>
  <div data-cy="policyTermsConditionModal">
    <div *ngIf="showPaperless" class="row" data-cy="policyTermsConditionModalPaperlessContent">
      <div class="column twelve items-center">
        <h1
          class="pad-bottom-20"
          content
          [contentPath]="'goPaperless.paperlessBenefitTitle'"
          data-cy="policyTermsConditionModalPaperlessContentTitle"
        ></h1>

        <p
          *dsPartnerAFI
          class="pad-bottom-20"
          content
          [contentPath]="'goPaperless.paperlessBenefitTextAFI'"
          data-cy="paperlessBenefitTextAFI"
        ></p>

        <p
          *dsPartnerConnect
          class="pad-bottom-20"
          content
          [contentPath]="'goPaperless.paperlessBenefitTextPARTNER'"
          data-cy="paperlessBenefitTextPARTNER"
        ></p>

        <h2
          class="font-weight-semibold"
          content
          [contentPath]="'goPaperless.paperlessBenefitTitle2'"
          data-cy="paperlessBenefitTitle2"
        ></h2>
        <p
          *dsPartnerAFI
          class="pad-bottom-40"
          content
          [contentPath]="'goPaperless.paperlessBenefitsAFI'"
          data-cy="paperlessBenefitsAFI"
        ></p>
        <p
          *dsPartnerConnect
          class="pad-bottom-40"
          content
          [contentPath]="'goPaperless.paperlessBenefitsPARTNER'"
          data-cy="paperlessBenefitsPARTNER"
        ></p>
      </div>
    </div>
    <div *ngIf="!showPaperless" class="row pad-20" data-cy="policyTermsConditionModalTermsContent">
      <div class="column twelve">
        <ds-policy-terms
          *ngIf="!isAdminApp && showPolicyTerms"
          [partnerData]="termsPartnerData$ | async"
          data-cy="policyTermsConditionModalPolicyTermsContent"
        ></ds-policy-terms>

        <ds-billing-terms
          *ngIf="!isAdminApp && showBillingTerms"
          [partnerData]="termsPartnerData$ | async"
          data-cy="billingTermsConditionModalPolicyTermsContent"
        ></ds-billing-terms>

        <ds-admin-terms
          *ngIf="isAdminApp && (showPolicyTerms || showBillingTerms)"
          [partnerData]="termsPartnerData$ | async"
          data-cy="policyTermsConditionModalPolicyTermsContent"
        >
        </ds-admin-terms>
      </div>
    </div>
    <div *ngIf="fromProfile" class="column twelve margin-top-20 pad-bottom-20">
      <div class="row flex items-center">
        <button
          ds-button
          (click)="agreeTermsAndConditions(true)"
          class="-outline-white margin-right-10 pad-horizontal-20"
          data-cy="policyTermsConditionModalTermsAgreeButton"
        >
          Agree
        </button>
        <p class="caption">
          <a
            (click)="agreeTermsAndConditions(false)"
            data-cy="policyTermsConditionModalTermsDisagreeLink"
            >Cancel</a
          >
        </p>
      </div>
    </div>
    <div *ngIf="!fromProfile" class="column twelve pad-bottom-20">
      <div class="row flex justify-close-text">
        <p>
          <a (click)="closeModal()" data-cy="policyTermsConditionModalCloseLink">Close</a>
        </p>
      </div>
    </div>
  </div>
</ds-modal>
