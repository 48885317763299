import { Component, EventEmitter, Input, Output } from '@angular/core';

import { RiskModel } from '@amfam/policy/models';

@Component({
  selector: 'amfam-vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.scss']
})
export class VehicleCardComponent {
  @Input() risk: RiskModel;
  @Output() vehicleCardClicked = new EventEmitter<RiskModel>();
}
