import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ApiStatus } from '@amfam/shared/models';

import { DocumentsAction, DocumentsActionTypes } from './documents.actions';

export interface PolicyDocumentsNotificationsState {
  policyNumber: string;
  error: ApiStatus | null;
  success: ApiStatus | null;
  loading: boolean;
}

export interface NotificationState extends EntityState<PolicyDocumentsNotificationsState> {
  loading: boolean;
  error: ApiStatus | null;
}

export const adapter: EntityAdapter<PolicyDocumentsNotificationsState> =
  createEntityAdapter<PolicyDocumentsNotificationsState>({
    selectId: (policiesNotificationsState: PolicyDocumentsNotificationsState) =>
      policiesNotificationsState.policyNumber,
    sortComparer: false
  });

export const initialState: NotificationState = adapter.getInitialState({
  loading: false,
  error: null
});

export function reducer(
  state: NotificationState = initialState,
  action: DocumentsAction
): NotificationState {
  switch (action.type) {
    case DocumentsActionTypes.LoadDocumentsType: {
      return { ...state, loading: true };
    }

    case DocumentsActionTypes.LoadDocumentsErrorType: {
      return { ...state, loading: false };
    }

    case DocumentsActionTypes.LoadDocumentsCompleteType:
    case DocumentsActionTypes.LoadDocumentsSuccessType: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
}
