/* eslint-disable @typescript-eslint/naming-convention */
import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { Claim, claimQuery } from '@amfam/claim/data-access';
import { PrettyClaimNum } from '@amfam/claim/shared/utility';
import { documentsQuery, PolicyDocumentsActions, PolicySelectors } from '@amfam/policy/data-access';
import { RiskModel } from '@amfam/policy/models';
import { BillAccount, BillingPaymentPaths, LogoModel } from '@amfam/shared/models';
import {
  HeaderWrapperService,
  LinkFormat,
  LinkSliceDefault,
  NavLinkTreeObj,
  NotificationObj
} from '@amfam/shared/ui/ds-header';
import { PrettyBillingAcctNum } from '@amfam/shared/ui/pipes';
import { AccountTypeEnum, userQuery } from '@amfam/shared/user';
import { BrandLinkDataModel, BrandSelectors } from '@amfam/shared/utility/brand';
import { routerQuery } from '@amfam/shared/utility/navigation';
import { ConfigService, UtilService } from '@amfam/shared/utility/shared-services';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Store
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';
import { select, Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, take, takeUntil } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import {
  featureFlagQuery,
  FeatureFlagService
} from '@amfam/shared/utility/feature-flag/data-access';
import { AuthService } from '../../core';
import * as fromRoot from '../../core/store';

@Component({
  selector: 'ds-header-wrapper',
  templateUrl: './header-wrapper.component.html',
  styleUrls: ['./header-wrapper.component.scss']
})
export class HeaderWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('fixedwrapper')
  fixedwrapper: ElementRef;
  private sub: Subscription;

  // State
  loggedIn: boolean;
  loggedIn$: Observable<boolean>;
  displayReportClaim = false;

  risks: RiskModel[] = new Array<RiskModel>();
  billAccounts: BillAccount[] = new Array<BillAccount>();

  // Header asset info
  homeUrl: string;
  amfamDotCom: string;
  headerGreeting$: Observable<string>;
  skipContentFocus: boolean;
  skipContentHeight = 48; // Deafault height for Skip to Content link
  headerHeight = 80; // Default height for header
  headerOffset = 0; // Default offset for header

  // Observables
  dataLoaded$: Observable<boolean>;
  isPartner$: Observable<boolean>;
  headerData$: Observable<any>;
  headerLogo: LogoModel;
  notifications$: Observable<NotificationObj[] | false>;
  linkArray$: Observable<NavLinkTreeObj[]>;

  brandInfo$: Observable<BrandLinkDataModel>;

  experienceId$: Observable<string>;
  experienceIdLinkPrefix$: Observable<string>;
  chatEnabled$: Observable<boolean>;
  mobileMenuActive$: Observable<boolean>;
  featureFlagsLoaded$: Observable<boolean>;
  isGlobalOutage = false;

  // Shared subject for completing observables
  protected stop$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<fromRoot.RootState>,
    private headerWrapperService: HeaderWrapperService,
    private utilService: UtilService,
    private router: Router,
    private config: ConfigService,
    private authService: AuthService,
    private featureService: FeatureFlagService,
    public roleService: ImpersonateRolesService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    this.featureFlagsLoaded$ = this.store
      .select(featureFlagQuery.hasFeatureFlag)
      .pipe(filter(loaded => loaded));
    this.amfamDotCom = this.config.get('links.amfamDotComUrl');
    this.chatEnabled$ = this.store.select(fromRoot.chatEnabled);
    this.mobileMenuActive$ = this.headerWrapperService.mobileMenuActive$;
    this.notifications$ = this.store.pipe(select(documentsQuery.getPolicyDocumentNotifications));
    this.isPartner$ = this.store.select(BrandSelectors.getIsPartner);

    this.headerWrapperService.notificationToDismiss$
      .pipe(takeUntil(this.stop$))
      .subscribe(notification => {
        this.notificationDismissClicked(notification);
      });

    this.store
      .select(routerQuery.getRouterState)
      .pipe(takeUntil(this.stop$))
      .subscribe(routerState => {
        this.headerWrapperService.setRouterStateUrl(_get(routerState, 'state.url', ''));
      });

    this.dataLoaded$ = combineLatest([
      this.store.select(PolicySelectors.getPoliciesLoaded),
      this.store.select(claimQuery.getClaimsLoaded),
      this.store.select(BillAccountsSelectors.getBillAccountsLoaded)
    ]).pipe(
      map(
        ([policiesLoaded, claimsLoaded, billAccountsLoaded]) =>
          policiesLoaded && claimsLoaded && billAccountsLoaded
      )
    );

    this.dataLoaded$.pipe(takeUntil(this.stop$)).subscribe(dataLoaded => {
      this.headerWrapperService.setLoaded(dataLoaded);
    });

    this.brandInfo$ = combineLatest([
      this.store.select(BrandSelectors.getBrandState),
      this.store.select(BrandSelectors.getCusCareNumberFullHref),
      this.store.select(BrandSelectors.getCusCareEmailHref)
    ]).pipe(
      map(([brandState, cusCareNumberFullHref, cusCareEmailHref]) => ({
        brandState,
        cusCareNumberFullHref,
        cusCareEmailHref
      }))
    );

    this.experienceIdLinkPrefix$ = this.store
      .select(BrandSelectors.getBrandState)
      .pipe(
        map(brandState =>
          _get(brandState, 'isPartner') ? '/' + _get(brandState, 'experienceId') : ''
        )
      );

    this.brandInfo$
      .pipe(distinctUntilKeyChanged('brandState')) // Because loaded doesn't work
      .subscribe(brandInfo => {
        const landingPageUrl = _get(brandInfo, 'brandState.data.landingPageUrl');
        this.headerLogo = _get(brandInfo, 'brandState.data.brandHeaderLogo');
        if (_get(brandInfo, 'brandState.isPartner')) {
          if (landingPageUrl) {
            let prefix;
            this.experienceIdLinkPrefix$.pipe(take(1)).subscribe(data => (prefix = data));
            this.homeUrl = prefix + '/overview';
          }
        } else {
          this.homeUrl = 'overview';
        }
      });

    // Use loginName to determine if the user is logged in.
    // This isn't ideal, but it seems to be the most reliable.
    this.loggedIn$ = this.store.select(userQuery.getUserState).pipe(
      distinctUntilKeyChanged('loginName'),
      map(userState => {
        this.loggedIn = !!_get(userState, 'loginName');
        return !!_get(userState, 'loginName');
      })
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (
          event.url === '/overview' ||
          event.url === '/paperless/signup' ||
          event.url === '/paperless/sign-up'
        ) {
          this.loggedIn$ = this.store.select(userQuery.getUserState).pipe(
            map(userState => {
              this.loggedIn = !!_get(userState, 'loginName');
              return !!_get(userState, 'loginName');
            })
          );
          this.linkArray$ = this.buildAllLinkArrays().pipe(takeUntil(this.stop$));
        }
      }
    });

    this.headerGreeting$ = this.store.select(userQuery.getUserState).pipe(
      distinctUntilKeyChanged('firstName'),
      map(userState => {
        const firstName = _get(userState, 'firstName');
        return firstName ? 'Hi, ' + firstName : 'My Account';
      })
    );

    // Get all of the store data needed to construct the links
    this.headerData$ = combineLatest([
      this.store.select(claimQuery.getRecentClaims),
      this.store.select(PolicySelectors.getPolicyRisks),
      this.store.select(BillAccountsSelectors.getBillAccounts),
      this.dataLoaded$,
      this.store.select(userQuery.getUserState)
    ]).pipe(
      map(([claims, risks, billAccounts, loaded, user]) => ({
        claims,
        risks,
        billAccounts,
        loaded,
        user
      }))
    );

    // Build linkArrays and send to the servic
    this.linkArray$ = this.buildAllLinkArrays().pipe(takeUntil(this.stop$));
  }

  ngAfterViewInit() {
    // Subscribe to route changes and recalculate
    if (this.fixedwrapper) {
      // Filter only for NavigationEnd
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.updateHeaderHeight();
        }
      });

      // Listen for window resize events
      window.addEventListener('resize', () => {
        this.updateHeaderHeight();
      });
    }
  }

  // Helper method to update the header height
  private updateHeaderHeight() {
    window.setTimeout(() => {
      this.headerHeight = this.fixedwrapper.nativeElement.getBoundingClientRect().height;
    }, 0);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateHeaderHeight);
    this.stop$.next();
    this.stop$.complete();
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // When the skip content link has focus
  focusHandler() {
    // Send update to service
    this.headerWrapperService.setSkipContentFocus(true);

    this.skipContentFocus = true;
    this.headerHeight += this.skipContentHeight;
    this.headerOffset = this.skipContentHeight;
    this.utilService.scrollToTop();
  }

  // When the skip content link has lost focus
  blurHandler() {
    // Send update to service
    this.headerWrapperService.setSkipContentFocus(false);

    this.skipContentFocus = false;
    this.headerHeight -= this.skipContentHeight;
    this.headerOffset = 0;
  }

  // Set focus to main content
  focusMainContent() {
    this.headerWrapperService.setFocusMainContent(true);
    // scroll to top otherwise the window is off by the height of the skip content block
    this.utilService.scrollToTop();
  }

  linkClicked({ link }) {
    // Event is also available if needed. Access it by deconstructing the incoming object like: {link, event}
    if (_get(link, 'clickFunction')) {
      link.clickFunction();
    }
    this.utilService.scrollToTop();
  }

  sublinkClicked({ sublink }) {
    // Event is also available if needed. Access it by deconstructing the incoming object like: {link, event}
    if (_get(sublink, 'clickFunction')) {
      sublink.clickFunction();
    }
    this.utilService.scrollToTop();
  }

  notificationDismissClicked(notification) {
    if (_get(notification, 'ids.length')) {
      notification.ids.forEach(documentId => {
        this.store.dispatch(new PolicyDocumentsActions.DismissNotificationAction(documentId));
      });
    }
  }

  private buildRiskLinkArray(): Observable<NavLinkTreeObj> {
    let riskLinkArray: NavLinkTreeObj;
    return combineLatest([this.experienceIdLinkPrefix$, this.headerData$]).pipe(
      map(([experienceIdLinkPrefix, state]) => {
        {
          riskLinkArray = {
            linkText: 'Policies',
            linkRoute: experienceIdLinkPrefix + '/policies',
            linkFormat: LinkFormat.POLICY,
            linkTestId: 'navItemPolicyOverviewLink',
            submenuTestId: 'navItemPolicySubmenu',
            mobileLinkTestId: 'mobileNavItemPolicyOverviewLink',
            mobileSubmenuTestId: 'mobileNavItemPolicySubmenu',
            mobileMenuItemOrder: 3,
            showOnNavigationMenu: true,
            linkClasses: 'nav-item-policyoverview-link',
            subLinkArray: [
              {
                linkText: 'Policies Overview',
                linkRoute: experienceIdLinkPrefix + '/policies',
                linkIcon: 'icon-view-all',
                linkTestId: 'subnavItemPolicyOverviewLink',
                mobileLinkTestId: 'mobileSubnavItemPolicyOverviewLink'
              }
            ],
            linkTreeSliceObj: {
              linkCount: LinkSliceDefault,
              linkText: 'View All',
              linkRoute: experienceIdLinkPrefix + '/policies',
              linkIcon: 'icon-view-all',
              linkTestId: 'subnavItemPolicyViewAllLink',
              mobileLinkTestId: 'mobileSubnavItemPolicyViewAllLink'
            }
          };
        }
        if (_get(riskLinkArray, 'subLinkArray')) {
          return this.buildNavLinkTreeObjArray({ itemArray: riskLinkArray, items: state.risks });
        }
      })
    );
  }

  private buildBillAccountLinkArray(): Observable<NavLinkTreeObj> {
    let billAccountLinkArray: NavLinkTreeObj;
    return combineLatest([this.experienceIdLinkPrefix$, this.headerData$]).pipe(
      map(([experienceIdLinkPrefix, state]) => {
        {
          billAccountLinkArray = {
            linkText: 'Billing & Payments',
            linkRoute: experienceIdLinkPrefix + '/billing',
            linkFormat: LinkFormat.BILLING,
            linkTestId: 'navItemBillingOverviewLink',
            submenuTestId: 'navItemBillingSubmenu',
            mobileLinkTestId: 'mobileNavItemBillingOverviewLink',
            mobileSubmenuTestId: 'mobileNavItemBillingSubmenu',
            mobileMenuItemOrder: 2,
            showOnNavigationMenu: true,
            linkClasses: 'nav-item-billingoverview-link',
            subLinkArray: [
              {
                linkText: 'Billing Overview',
                linkRoute: experienceIdLinkPrefix + '/billing',
                linkIcon: 'icon-view-all',
                linkTestId: 'subnavItemBillingOverviewLink',
                mobileLinkTestId: 'mobileSubnavItemBillingOverviewLink'
              }
            ],
            linkTreeSliceObj: {
              linkCount: LinkSliceDefault,
              linkText: 'View All',
              linkRoute: experienceIdLinkPrefix + '/billing',
              linkIcon: 'icon-view-all',
              linkTestId: 'subnavItemBillingViewAllLink',
              mobileLinkTestId: 'mobileSubnavItemBillingViewAllLink'
            }
          };
        }
        if (_get(billAccountLinkArray, 'subLinkArray')) {
          return this.buildNavLinkTreeObjArray({
            itemArray: billAccountLinkArray,
            items: state.billAccounts
          });
        }
      })
    );
  }

  private buildClaimLinkArray(): Observable<NavLinkTreeObj> {
    // let authorizeMenuItem;
    // this.sub = this.roleService.isAuthorized('claims_overview').subscribe(data => {
    //   if (data) {
    //     authorizeMenuItem = data;
    //   }
    // });

    let claimLinkArray: NavLinkTreeObj;
    let claimLinkPostArray: NavLinkTreeObj[];
    return combineLatest([
      this.experienceIdLinkPrefix$,
      this.headerData$,
      this.roleService.isAuthorized('claims_overview')
    ]).pipe(
      map(([experienceIdLinkPrefix, state, authorizeMenuItem]) => {
        {
          claimLinkArray = {
            linkText: 'Claims',
            linkRoute: authorizeMenuItem ? experienceIdLinkPrefix + '/claims' : '',
            linkFormat: LinkFormat.CLAIM,
            permissionName: 'claimsummary_veiw',
            checkAction: 'remove',
            linkTestId: 'navItemClaimOverviewLink',
            submenuTestId: 'navItemClaimSubmenu',
            mobileLinkTestId: 'mobileNavItemClaimOverviewLink',
            mobileSubmenuTestId: 'mobileNavItemClaimSubmenu',
            mobileMenuItemOrder: 4,
            showOnNavigationMenu: true,
            linkClasses: 'navitem-claimoverview-link',
            subLinkArray: authorizeMenuItem
              ? [
                  {
                    linkText: 'Claims Overview',
                    linkRoute: experienceIdLinkPrefix + '/claims',
                    permissionName: 'claimsummary_veiw',
                    checkAction: 'remove',
                    linkTestId: 'subnavItemClaimOverviewLink',
                    mobileLinkTestId: 'mobileSubnavItemClaimOverviewLink',
                    linkIcon: 'icon-claims'
                  }
                ]
              : [],
            linkTreeSliceObj: {
              linkCount: LinkSliceDefault,
              linkText: 'View All',
              linkRoute: experienceIdLinkPrefix + '/claims',
              linkIcon: 'icon-view-all',
              linkTestId: 'navItemClaimViewAllLink',
              mobileLinkTestId: 'mobileNavItemClaimViewAllLink'
            }
          };
        }

        claimLinkPostArray = [];
        let authorizeFileaClaimMenuItem;
        this.sub = this.roleService.isAuthorized('file_a_claim').subscribe(data => {
          if (data) {
            authorizeFileaClaimMenuItem = data;
          }
        });

        // Should we display the report a claim link at all?
        if (
          _get(state, 'user.typeOfAccountCode') === AccountTypeEnum.Customer &&
          authorizeFileaClaimMenuItem
        ) {
          // If so, assume fnol is not enabled
          const reportClaimArray: NavLinkTreeObj = {
            linkText: 'File a Claim',
            linkRoute: experienceIdLinkPrefix + '/claims/report-claim-fnol',
            linkTestId: 'subnavItemClaimReportLink',
            mobileLinkTestId: 'mobileSubnavItemClaimReportLink',
            permissionName: 'fnolform_view',
            checkAction: 'disable',
            linkIcon: 'icon-file-claim'
          };

          // Push the report a claim array into the claims link array
          claimLinkPostArray.push(reportClaimArray);
        }
        const claimFaqLink = {
          linkText: 'Claims FAQ',
          linkRoute: experienceIdLinkPrefix + '/faq/claims',
          linkTestId: 'subnavItemClaimFaqLink',
          mobileLinkTestId: 'mobileSubnavItemClaimFaqLink',
          linkIcon: 'icon-faqs'
        };

        claimLinkPostArray.push(claimFaqLink);

        if (_get(claimLinkArray, 'subLinkArray')) {
          const claims = !this.featureService.isEnabled('claims_enterprise_app')
            ? state.claims
            : [];

          return this.buildNavLinkTreeObjArray({
            itemArray: claimLinkArray,
            items: claims,
            postArray: claimLinkPostArray,
            experienceIdLinkPrefix
          });
        }
      })
    );
  }

  // Determine the type of link and format it accordingly
  private determineLinkFormatType(link): NavLinkTreeObj {
    switch (_get(link, 'linkFormat')) {
      case LinkFormat.POLICY:
        return this.formatPolicyRiskLink(
          _get(link, 'item'),
          _get(link, 'experienceIdLinkPrefix', '')
        );
      case LinkFormat.BILLING:
        return this.formatBillAccountLink(
          _get(link, 'item'),
          _get(link, 'experienceIdLinkPrefix', '')
        );
      case LinkFormat.CLAIM:
        return this.formatClaimLink(_get(link, 'item'), _get(link, 'experienceIdLinkPrefix', ''));
    }
  }

  private formatPolicyRiskLink(risk: RiskModel, experienceIdLinkPrefix = ''): NavLinkTreeObj {
    const riskTestId = risk.vehicleId ? risk.vehicleId : risk.policyNumber;
    return {
      linkText: risk.description,
      linkRoute: experienceIdLinkPrefix + '/policies/' + risk.policyType + '/' + risk.policyNumber,
      queryParams: risk.vehicleId ? { vehicleId: risk.vehicleId } : '',
      linkTestId: 'subnavItemPolicy-' + riskTestId + '-Link',
      mobileLinkTestId: 'mobileSubnavItemPolicy-' + riskTestId + '-Link',
      linkIcon: risk.iconType
    };
  }

  private formatBillAccountLink(
    billAccount: BillAccount,
    experienceIdLinkPrefix: string
  ): NavLinkTreeObj {
    return {
      linkText:
        _get(billAccount, 'billingPreferences.accountNickName') ||
        new PrettyBillingAcctNum().transform(billAccount.billAccountNumber),
      linkRoute:
        experienceIdLinkPrefix +
        BillingPaymentPaths.BILLING_DETAIL_PAGE +
        '/' +
        billAccount.billAccountNumber,
      linkTestId: 'subnavItemBilling-' + billAccount.billAccountNumber + '-Link',
      mobileLinkTestId: 'mobileSubnavItemBilling-' + billAccount.billAccountNumber + '-Link',
      linkIcon: 'icon-payments'
    };
  }

  private formatClaimLink(claim: Claim, experienceIdLinkPrefix: string): NavLinkTreeObj {
    // Only return if claim does not have an error
    if (!claim.hasError) {
      return {
        linkText: 'Claim ' + new PrettyClaimNum().transform(claim.claimNumber),
        linkRoute: experienceIdLinkPrefix + '/claims/' + claim.claimNumber,
        linkTestId: 'subnavItemClaim-' + claim.claimNumber + '-Link',
        mobileLinkTestId: 'mobileSubnavItemClaim-' + claim.claimNumber + '-Link',
        permissionName: 'clmdetails_view',
        checkAction: 'remove',
        linkIcon: 'icon-view-claim'
      };
    }
  }

  private formatNavLinkTreeSliceObj(link: NavLinkTreeObj): NavLinkTreeObj {
    return {
      linkText: link.linkText,
      linkRoute: link.linkRoute,
      linkIcon: link.linkIcon,
      linkTestId: link.linkTestId,
      mobileLinkTestId: link.mobileLinkTestId,
      dividerAfter: link.dividerAfter
    };
  }

  private buildNavLinkTreeObjArray(link): NavLinkTreeObj {
    // Check if we're limiting the links displayed
    const linkTreeSliceObjCount = _get(link.itemArray, 'linkTreeSliceObj.linkCount');
    // If we have limited links, only push those into the array
    if (link.items && _get(link.items, 'length') > 0) {
      if (linkTreeSliceObjCount) {
        link.items.forEach((item, index) => {
          if (linkTreeSliceObjCount && index + 1 <= linkTreeSliceObjCount) {
            link.itemArray.subLinkArray.push(
              this.determineLinkFormatType({
                item,
                linkFormat: link.itemArray.linkFormat,
                experienceIdLinkPrefix: link.experienceIdLinkPrefix
              })
            );
          }
        });

        // If total links are greater than the link limit prop, add the linkTreeSliceObj props
        if (link.items.length > linkTreeSliceObjCount) {
          link.itemArray.subLinkArray.push(
            this.formatNavLinkTreeSliceObj(link.itemArray.linkTreeSliceObj)
          );
        }
        // Else, push every link into the array
      } else {
        link.items.forEach(item => {
          link.itemArray.subLinkArray.push(
            this.determineLinkFormatType({
              item,
              linkFormat: link.itemArray.linkFormat,
              experienceIdLinkPrefix: link.experienceIdLinkPrefix
            })
          );
        });
      }
    }

    // Some link objects have links after the formatted links, lets add those
    if (link.postArray) {
      link.postArray.forEach(postItem => {
        link.itemArray.subLinkArray.push(postItem);
      });
    }
    return link.itemArray;
  }

  private buildContactLinkArray(): Observable<NavLinkTreeObj> {
    let itemArray: NavLinkTreeObj;
    return this.brandInfo$.pipe(
      distinctUntilKeyChanged('brandState'), // Because loaded doesn't work
      map(brandInfo => {
        {
          if (_get(brandInfo, 'brandState.isPartner')) {
            return (itemArray = {
              linkText: 'Contact Us',
              linkTarget: 'partner',
              linkTestId: 'navItemContactUsLink',
              mobileLinkTestId: 'mobileNavItemContactUsLink',
              mobileMenuItemOrder: 6,
              showOnNavigationMenu: true,
              linkClasses: 'nav-item-contactus-link',
              subLinkArray: [
                {
                  linkText: _get(brandInfo, 'brandState.data.cusCareNumber'),
                  linkExternalUrl: _get(brandInfo, 'cusCareNumberFullHref'),
                  linkTarget: 'partner',
                  linkIcon: 'icon-call',
                  listClasses: 'no-border',
                  linkTestId: 'navItemCustomerCarePhoneLink',
                  mobileLinkTestId: 'mobileNavItemCustomerCarePhoneLink'
                },
                {
                  linkText: _get(brandInfo, 'brandState.data.cusCareHoursWeekday'),
                  textOnly: true,
                  listClasses: 'customer-care-hours no-border',
                  linkTestId: 'navItemCustomerCareHoursWeekdayLink',
                  mobileLinkTestId: 'mobileNavItemCustomerCareHoursWeekdayLink'
                },
                {
                  linkText: _get(brandInfo, 'brandState.data.cusCareHoursFriday'),
                  textOnly: true,
                  listClasses: 'customer-care-hours no-border',
                  linkTestId: 'navItemCustomerCareHoursFridayLink',
                  mobileLinkTestId: 'mobileNavItemCustomerCareHoursFridayLInk'
                },
                {
                  linkText: _get(brandInfo, 'brandState.data.cusCareHoursWeekend'),
                  textOnly: true,
                  listClasses: 'customer-care-hours no-border',
                  linkTestId: 'navItemCustomerCareHoursWeekendLink',
                  mobileLinkTestId: 'mobileNavItemCustomerCareHoursWeekendLink'
                },
                {
                  linkText: _get(brandInfo, 'brandState.data.cusCareEmail'),
                  linkExternalUrl: _get(brandInfo, 'cusCareEmailHref'),
                  linkTarget: 'partner',
                  linkIcon: 'icon-email',
                  linkTestId: 'navItemCustomerCareEmailLink',
                  mobileLinkTestId: 'mobileNavItemCustomerCareEmailLink'
                }
              ]
            });
          } else {
            return (itemArray = {
              linkText: 'Contact Us',
              linkExternalUrl: this.amfamDotCom + 'contact?UTM_Source=MyAccount',
              linkTarget: 'amfam',
              linkTestId: 'navItemContactUsLink',
              mobileLinkTestId: 'mobileNavItemContactUsLink',
              mobileMenuItemOrder: 6,
              showOnNavigationMenu: false,
              linkClasses: 'navitem-contactus-link'
            });
          }
        }
      })
    );
  }

  private buildAllLinkArrays(): Observable<NavLinkTreeObj[]> {
    let allLinkArrays: NavLinkTreeObj[];
    return combineLatest([
      this.brandInfo$,
      this.experienceIdLinkPrefix$,
      this.loggedIn$,
      this.buildBillAccountLinkArray(),
      this.buildRiskLinkArray(),
      this.buildClaimLinkArray(),
      this.buildContactLinkArray(),
      this.featureFlagsLoaded$
    ]).pipe(
      map(
        ([
          brandInfo,
          experienceIdLinkPrefix,
          loggedIn,
          buildBillAccountLinkArray,
          buildRiskLinkArray,
          buildClaimLinkArray,
          contactLinkArray,
          featureFlagsLoaded
        ]: [
          // If you use combineLatest with more than 5 items, you have to manually type them
          // See: https://github.com/ReactiveX/rxjs/issues/3601#issuecomment-384711601
          BrandLinkDataModel,
          string,
          boolean,
          NavLinkTreeObj,
          NavLinkTreeObj,
          NavLinkTreeObj,
          NavLinkTreeObj,
          boolean
        ]) => {
          this.isGlobalOutage = this.featureService.isEnabled('global_outage');
          if (!loggedIn) {
            return (allLinkArrays = this.isGlobalOutage ? [] : [contactLinkArray]);
          } else {
            return (allLinkArrays = [
              {
                linkText: 'Overview',
                linkRoute: experienceIdLinkPrefix + '/overview',
                linkTestId: 'navItemOverviewLink',
                mobileLinkTestId: 'mobileNavItemOverviewLink',
                mobileMenuItemOrder: 1,
                showOnNavigationMenu: true,
                linkClasses: 'nav-item-overview-link'
              },
              buildBillAccountLinkArray,
              buildRiskLinkArray,
              buildClaimLinkArray,
              this.isGlobalOutage ? ({} as NavLinkTreeObj) : contactLinkArray,
              {
                linkText: 'Profile',
                linkRoute: experienceIdLinkPrefix + '/profile',
                linkTestId: 'navItemProfileLink',
                mobileLinkTestId: 'mobileNavItemProfileLink',
                mobileMenuItemOrder: 5,
                showOnNavigationMenu: true,
                linkClasses: 'nav-item-profile-link'
              },
              {
                linkText: 'Log out',
                clickFunction: () => this.logout(experienceIdLinkPrefix),
                linkTestId: 'navItemLogoutLink',
                mobileLinkTestId: 'mobileNavItemLogoutLink',
                mobileMenuItemOrder: 7,
                showOnNavigationMenu: true,
                linkClasses: 'nav-item-logout-link'
              }
            ]);
          }
        }
      )
    );
  }

  private logout = experienceIdLinkPrefix => {
    this.authService
      .logout()
      .pipe(takeUntil(this.stop$))
      .subscribe(() => {
        window.location.href = window.location.origin + experienceIdLinkPrefix;
      });
  };
}
