@if (!!step) {
  <p class="margin-bottom-20">{{ step }}</p>
}

@if (!!firstLine) {
  <h1 class="margin-bottom-20">{{ firstLine }}</h1>
}

@if (!!secondLine) {
  <p class="margin-bottom-20">{{ secondLine }}</p>
}
