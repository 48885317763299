import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  APP_BOOTSTRAP_LISTENER,
  APP_INITIALIZER,
  Inject,
  InjectionToken,
  NgModule,
  Type
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule, EffectSources } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
// ngrx/store
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// third-party modules
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';

import { BillingBillAccountCanRegisterDataAccessModule } from '@amfam/billing/bill-account-can-register/data-access';
import { BillingBillAccountDataAccessModule } from '@amfam/billing/billaccount/data-access';
import { BillingFinancialInstitutionDataAccessModule } from '@amfam/billing/financial-institution/data-access';
import { BillingPaymentHistoryDataAccessModule } from '@amfam/billing/payment-history/data-access';
import { BillingPaymentMethodDataAccessModule } from '@amfam/billing/payment-method/data-access';
import { BillingPaymentaccountDataAccessModule } from '@amfam/billing/paymentaccount/data-access';
import { BillingPaymentconfirmationDataAccessModule } from '@amfam/billing/paymentconfirmation/data-access';
import { BillingPendingPaymentDataAccessModule } from '@amfam/billing/pending-payment/data-access';
import { BillingRegistrationDataAccessModule } from '@amfam/billing/registration/data-access';
import { BillingSchedulepaymentDataAccessModule } from '@amfam/billing/schedulepayment/data-access';
import {
  BillAccountTransmuteService,
  FinancialAccountService,
  PaymentAccountTransmuteService,
  PaymentService
} from '@amfam/billing/shared/util';
import { ClaimDashboardDataAccessModule } from '@amfam/claim/dashboard/data-access';
import { ClaimDataAccessModule } from '@amfam/claim/data-access';
import { ClaimEnterpriseDataAccessModule } from '@amfam/claim/enterprise/data-access';
import { ClaimFaqDataAccessModule } from '@amfam/claim/faq/data-access';
import { ClaimFnolDataAccessModule } from '@amfam/claim/fnol/data-access';
import { ClaimGlassOnlyDataAccessModule } from '@amfam/claim/glass-only/data-access';
import { ClaimServicesDataAccessModule } from '@amfam/claim/post-fnol/services-data-access';
import { PolicyDataAccessPolicyModule } from '@amfam/policy/data-access';
import { PolicyEDeliveryDataAccessModule } from '@amfam/policy/e-delivery/data-access';
import { PolicyESignatureDataAccessModule } from '@amfam/policy/e-signature/data-access';
import { PolicyGoPaperlessDataAccessModule } from '@amfam/policy/go-paperless/data-access';
import { PolicyVehicleDataAccessModule } from '@amfam/policy/vehicle/data-access';
import { ProfileDataAccessModule } from '@amfam/profile/data-access';
import { ProfileFeatureModule } from '@amfam/profile/feature';
import { SharedAnalyticsModule } from '@amfam/shared/analytics';
import { SharedFaviconModule } from '@amfam/shared/favicon';
// policy services
import { SharedHeaderModule } from '@amfam/shared/ui/ds-header';
import { UserModule } from '@amfam/shared/user';
import { AgentModule } from '@amfam/shared/utility/agent';
import { BrandModule, BrandService } from '@amfam/shared/utility/brand';
import {
  FeatureFlagService,
  SharedUtilityFeatureFlagDataAccessModule
} from '@amfam/shared/utility/feature-flag/data-access';
import {
  ImpersonateRolesService,
  ImpersonationRoles,
  ImpersonationStoreModule
} from '@amfam/shared/utility/impersonation';
import {
  CustomRouterStateSerializer,
  NavigationModule,
  NavigationService
} from '@amfam/shared/utility/navigation';
import {
  ConfigService,
  CopyService,
  UtilService,
  WINDOW_PROVIDERS
} from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService, PageTitleService, ValidationService } from '@amfam/ui-kit';

import { environment } from '../../environments/environment';
import { AppRoutingModule } from '../app-routing.module';
// components/directives/pipes
import { AppComponent } from '../app.component';
// application config
import { loadConfig } from '../app.config';
// billing services
import { BillAccountContactService } from '../billing/bill-account-contact/bill-account-contact.service';
import { BusinessConversionService } from '../business/conversion/business-conversion.service';
import { AppBootstrapEffects } from '../core/store/app-bootstrap/app-bootstrap.effects';
// effects
import { DeleteEffects } from '../core/store/delete-myaccount/delete.effects';
import { KydSmartphoneEnrollPolicyEffects } from '../core/store/programs/kyd-smartphone/kyd-smartphone-enroll-policy.effects';
import { KydSmartphoneEnrollmentSummaryEffects } from '../core/store/programs/kyd-smartphone/kyd-smartphone-enrollment-summary.effects';
import { KydEligibilityEffects } from '../core/store/programs/kyd/kyd-eligibility.effects';
import { KydEnrollEffects } from '../core/store/programs/kyd/kyd-enroll.effects';
import { KydEffects } from '../core/store/programs/kyd/kyd.effects';
import { ResetPasswordEffects } from '../core/store/reset-password/reset-password.effects';
import { SessionEffects } from '../core/store/session/session.effects';
import { WorkflowEffects } from '../core/store/workflow/workflow.effects';
import { BusinessService } from '../enrollment/business/shared/business.service';
// enrollment services
import { ExitWarningService } from '../enrollment/exit-warning/exit-warning.service';
import { MaeService } from '../enrollment/mae/mae.service';
import { IdentificationService } from '../enrollment/personal/identification/identification.service';
import { ResetRoutesService } from '../enrollment/personal/shared/personal-enrollment-guards/reset-routes.service';
import { PersonalService } from '../enrollment/personal/shared/personal.service';
import { VerificationService } from '../enrollment/personal/verification/verification.service';
import { SignUpService } from '../enrollment/shared/sign-up.service';
// application modules
import { ErrorModule } from '../error/error.module';
import { LoginModule } from '../login/login.module';
import { ProfileModule } from '../profile';
import { PreloadSelectedModules } from '../selective-preload-strategy';
// shared services
import { EnrollNotificationService } from '../shared/enroll-notification/shared/enroll-notification.service';
import { FutureStateService } from '../shared/feature-toggle/future-state.service';
import { SharedModule } from '../shared/shared.module';
import { AddressLookupService } from './address-lookup';
// core services
import { ContentService } from './content';
import { DeleteService } from './delete/delete.service';
import { FeatureDetectionService } from './feature-detection/feature-detection.service';
import { httpInterceptorProviders } from './http-interceptors';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { OutageComponent } from './outage/outage.component';
import { metaReducers, reducers } from './store';
import { MaeEnrollmentEffects } from './store/mae/mae-enrollment.effects';
import { MaeProgramEffects } from './store/mae/mae-program.effects';
import { MaeStatusEffects } from './store/mae/mae-status.effects';
import { ReplaceVehicleEffects } from './store/replace-vehicle/replace-vehicle.effects';
import { ReplaceVehicleService } from './store/replace-vehicle/replace-vehicle.service';
import { ForgerockSessionEffects } from './store/session/forgerock-session.effects';
import { WorkflowGuardService } from './workflow/workflow-guard.service';
import { WorkflowService } from './workflow/workflow.service';

export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

export function bootstrapEffects(effects: Type<any>[], sources: EffectSources) {
  return () => {
    effects.forEach(effect => sources.addEffects(effect));
  };
}

export function createInstances(...instances: any[]) {
  return instances;
}

export function provideBootstrapEffects(effects: Type<any>[]) {
  return [
    effects,
    { provide: BOOTSTRAP_EFFECTS, deps: effects, useFactory: createInstances },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources]
    }
  ];
}

export const appEffects = [
  AppBootstrapEffects,
  DeleteEffects,
  KydEffects,
  KydEligibilityEffects,
  KydEnrollEffects,
  KydSmartphoneEnrollPolicyEffects,
  KydSmartphoneEnrollmentSummaryEffects,
  MaeEnrollmentEffects,
  MaeProgramEffects,
  MaeStatusEffects,
  ReplaceVehicleEffects,
  ResetPasswordEffects,
  SessionEffects,
  ForgerockSessionEffects,
  WorkflowEffects
  /*
   * Associated party (other party) information is used by KydSmartphone. Since, we cannot compare enrolled
   * operator information with associated party information due to different encrypted cdhid's
   * - We don't need this right now. May be in future we use this after gateway fix the encryption mechanism for cdhid's
   */
  // AssociatedPartyEffects
];

@NgModule({
  declarations: [AppComponent, MaintenanceComponent, OutageComponent],
  exports: [],
  bootstrap: [AppComponent],
  imports: [
    AgentModule,
    SharedAnalyticsModule,
    BrandModule,
    UserModule,
    ProfileModule,
    ProfileFeatureModule,
    ImpersonationStoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    SharedFaviconModule,
    BillingBillAccountDataAccessModule,
    BillingPaymentaccountDataAccessModule,
    BillingSchedulepaymentDataAccessModule,
    BillingRegistrationDataAccessModule,
    BillingPaymentconfirmationDataAccessModule,
    BillingFinancialInstitutionDataAccessModule,
    ClaimDataAccessModule,
    ClaimServicesDataAccessModule,
    ClaimFaqDataAccessModule,
    ClaimFnolDataAccessModule,
    ClaimEnterpriseDataAccessModule,
    ClaimGlassOnlyDataAccessModule,
    ClaimDashboardDataAccessModule,
    SharedUtilityFeatureFlagDataAccessModule,
    PolicyDataAccessPolicyModule,
    PolicyEDeliveryDataAccessModule,
    BillingPaymentHistoryDataAccessModule,
    BillingPendingPaymentDataAccessModule,
    BillingBillAccountCanRegisterDataAccessModule,
    ProfileDataAccessModule,
    PolicyESignatureDataAccessModule,
    PolicyGoPaperlessDataAccessModule,
    PolicyVehicleDataAccessModule,
    BillingPaymentMethodDataAccessModule,
    SharedModule,
    LoginModule,
    ErrorModule,
    NavigationModule,
    ToastrModule.forRoot({ positionClass: 'inline' }),
    ToastContainerModule,
    /**
     * runtrimeChecks enable store freeze functionality in local
     */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: environment.production
        ? { strictStateImmutability: true, strictActionImmutability: true }
        : { strictStateImmutability: false, strictActionImmutability: false }
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      stateKey: 'router' // name of reducer key
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 100, connectInZone: true })
      : [],
    EffectsModule.forRoot([]),
    SharedHeaderModule
  ],
  providers: [
    PreloadSelectedModules,
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [ConfigService],
      multi: true
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    provideBootstrapEffects(appEffects),
    AddressLookupService,
    BillAccountContactService,
    BrandService,
    BusinessConversionService,
    BusinessService,
    ContentService,
    CopyService,
    DeleteService,
    EnrollNotificationService,
    ExitWarningService,
    FeatureDetectionService,
    FeatureFlagService,
    FinancialAccountService,
    FutureStateService,
    IdentificationService,
    ImpersonateRolesService,
    ImpersonationRoles,
    LoadingSpinnerService,
    MaeService,
    NavigationService,
    PageTitleService,
    PaymentAccountTransmuteService,
    PaymentService,
    BillAccountTransmuteService,
    PersonalService,
    ReplaceVehicleService,
    ResetRoutesService,
    SignUpService,
    UtilService,
    ValidationService,
    VerificationService,
    WorkflowGuardService,
    WorkflowService,
    DeviceDetectorService,
    ...WINDOW_PROVIDERS,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {}
