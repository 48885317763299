import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { userQuery } from '@amfam/shared/user';
import { ToasterService } from '@amfam/ui-kit';

import { CustomerEnrollentResponse } from '../models/mae.models';
import { MaeService } from './../services/mae.service';
import {
  changeEmail,
  changeEmailFail,
  changeEmailSuccess,
  enrollment,
  enrollmentFailure,
  enrollmentSuccess,
  resendEmail,
  resendEmailFail,
  resendEmailSuccess
} from './mae.actions';

@Injectable()
export class MaeEffects {
  resendEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resendEmail),
      map(action => action),
      concatLatestFrom(() => this.store.select(userQuery.getUserState)),
      mergeMap(([, user]) =>
        this.maeService.resendEmail(user.customerId, user.smImpersonatorUserID)
      ),
      map(() => {
        this.toasterService.pop('success', 'Email sent successfully');
        return resendEmailSuccess();
      }),
      catchError(() => observableOf(resendEmailFail()))
    );
  });

  changeEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeEmail),
      map(action => action.email),
      concatLatestFrom(() => this.store.select(userQuery.getUserState)),
      mergeMap(([email, user]) =>
        this.maeService.changeEnrolledEmail(user.customerId, email, user.smImpersonatorUserID).pipe(
          mergeMap(res => {
            if (res.errors && res.errors.length > 0) {
              if (
                res.errors[0].code === 400003 &&
                res.errors[0].message === 'Email address is already in use.'
              ) {
                this.toasterService.pop(
                  'error',
                  'This email address is already in use. Please select a different email address.'
                );
              } else {
                this.toasterService.pop(
                  'error',
                  "We couldn't update your customer's email address. Please try again later"
                );
              }
              return [changeEmailFail()];
            } else {
              this.toasterService.pop('success', 'Email successfully updated');
              return [changeEmailSuccess()];
            }
          })
        )
      ),
      catchError(() => {
        this.toasterService.pop(
          'error',
          'We couldn’t update your customer’s email address. Please try again later.'
        );
        return observableOf(changeEmailFail());
      })
    );
  });

  enrollment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(enrollment),
      map(action => action.payload),
      mergeMap(enrollmentRequest =>
        this.maeService
          .agentenrollment(
            enrollmentRequest.customerEnrollmentRequest,
            enrollmentRequest.customerId
          )
          .pipe(
            map((response: CustomerEnrollentResponse) =>
              enrollmentSuccess({
                payload: response.CustomerEnrollment
              })
            ),
            catchError(error => observableOf(enrollmentFailure(error)))
          )
      )
    );
  });

  constructor(
    private maeService: MaeService,
    private actions$: Actions,
    private store: Store,
    private toasterService: ToasterService
  ) {}
}
