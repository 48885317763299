import { getYear } from 'date-fns';
import { get as _get } from 'lodash';

import { VehicleFeaturesModel } from '../models/vehicle-features.model';
import {
  NewVehicle,
  VechiclePolicyChangeEntryPoint,
  VehicleSelectCriteria
} from '../models/vehicle.models';
import { VehicleAction, VehicleActionTypes } from './vehicle.actions';

export interface VehicleState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status?: any;
  hasError?: boolean;
  loading: boolean;
  criteria: VehicleSelectCriteria;
  newVehicle: NewVehicle;
  years: Array<number>;
  makes: Array<string>;
  models: Array<string>;
  styles: Array<string>;
  vehiclePolicyChangeEntryPoint: VechiclePolicyChangeEntryPoint;
  features: VehicleFeaturesModel[];
}

export const initialState: VehicleState = {
  status: null,
  hasError: false,
  loading: false,
  criteria: {
    make: null,
    series: null,
    model: null,
    odometer: null,
    vin: null,
    year: null,
    vehicleType: null
  },
  newVehicle: null,
  years: getValidVehicleYears(),
  makes: [],
  models: [],
  styles: [],
  vehiclePolicyChangeEntryPoint: null,
  features: []
};

function getValidVehicleYears(): number[] {
  const start = 1982;
  const end = getYear(new Date()) + 2;
  const validYears = [];
  for (let i = start; i <= end; i++) {
    validYears.push(i);
  }
  return validYears.reverse();
}

export function vehicleReducer(
  state: VehicleState = initialState,
  action: VehicleAction
): VehicleState {
  switch (action.type) {
    case VehicleActionTypes.ResetType:
      return Object.assign({}, initialState);

    case VehicleActionTypes.LookupVinYearType:
      return Object.assign({}, state, {
        loading: true,
        status: null,
        hasError: false,
        criteria: {
          year: action.payload.year,
          vin: action.payload.vin
        },
        makes: [],
        models: [],
        styles: []
      });

    // TODO - add response data when shape is know
    case VehicleActionTypes.LookupVinYearSuccessType:
      return Object.assign({}, state, {
        loading: false,
        hasError: false,
        newVehicle: action.payload.newVehicle,
        features: action.payload.features,
        criteria: action.payload.criteria || state.criteria
      });

    case VehicleActionTypes.LookupVinYearFailType:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: _get(action, 'payload.status', action.payload)
      });

    case VehicleActionTypes.GetMakesType:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        criteria: {
          ...state.criteria,
          year: action.payload.year
        },
        makes: [],
        models: [],
        styles: []
      });

    case VehicleActionTypes.GetMakesSuccessType:
      return Object.assign({}, state, {
        loading: false,
        makes: action.payload.makes
      });

    case VehicleActionTypes.GetMakesFailType:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });

    case VehicleActionTypes.GetModelsType:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        criteria: {
          ...state.criteria,
          make: action.payload.make
        },
        models: [],
        styles: []
      });

    case VehicleActionTypes.GetModelsSuccessType:
      return Object.assign({}, state, {
        loading: false,
        models: action.payload.models
      });

    case VehicleActionTypes.GetModelsFailType:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });

    // This is going to be called Series or Styles - need spec.
    case VehicleActionTypes.GetStylesType:
      return Object.assign({}, state, {
        loading: true,
        hasError: false,
        criteria: {
          ...state.criteria,
          model: action.payload.model
        },
        styles: []
      });

    case VehicleActionTypes.GetStylesSuccessType:
      return Object.assign({}, state, {
        loading: false,
        styles: action.payload.series
      });

    case VehicleActionTypes.GetStylesFailType:
      return Object.assign({}, state, {
        loading: false,
        hasError: true,
        status: action.payload.status
      });

    case VehicleActionTypes.SetCriteriaType:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          year: action.payload.year || state.criteria.year,
          vin: action.payload.vin || state.criteria.vin,
          odometer: action.payload.odometer || state.criteria.odometer,
          make: action.payload.make || state.criteria.make,
          model: action.payload.model || state.criteria.model,
          series: action.payload.series || state.criteria.series,
          underWritingQuestions:
            action.payload.underWritingQuestions || state.criteria.underWritingQuestions,
          copyTPIIndicator: action.payload.copyTPIIndicator || state.criteria.copyTPIIndicator,
          TPIName: action.payload.TPIName || state.criteria.TPIName
        },
        newVehicle: {
          ...state.newVehicle,
          year: action.payload.year || state.newVehicle?.year || state.criteria?.year,
          make: action.payload.make || state.newVehicle?.make || state.criteria?.make,
          model: action.payload.model || state.newVehicle?.model || state.criteria?.model,
          series: action.payload.series || state.newVehicle?.series || state.criteria?.series,
          vin: action.payload.vin || state.newVehicle?.vin || state.criteria?.vin,
          odometer:
            action.payload.odometer || state.newVehicle?.odometer || state.criteria?.odometer
        }
      };

    case VehicleActionTypes.SetTpiType:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          TPIName: action.payload?.tpiName,
          copyTPIIndicator: action.payload?.copyTpiIndicator
        }
      };

    case VehicleActionTypes.SetVehiclePolicyChangeEntryPointType:
      return Object.assign({}, state, {
        loading: false,
        vehiclePolicyChangeEntryPoint: action.payload.entryPoint
      });

    default:
      return state;
  }
}
