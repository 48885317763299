import { Action } from '@ngrx/store';

import { CreateRepairRequestPayload } from './repair-service.models';

export enum RepairServiceActionTypes {
  CreateRepairRequest = '[Claim Services] Create Repair Request',
  CreateRepairRequestSuccess = '[Claim Services] Create Repair Request Success',
  CreateRepairRequestFail = '[Claim Services] Create Repair Request Fail',
  ResetRepairService = '[Claim Services] Reset Repair Service'
}

export class CreateRepairRequest implements Action {
  readonly type = RepairServiceActionTypes.CreateRepairRequest;
  constructor(public payload: CreateRepairRequestPayload) {}
}

export class CreateRepairRequestSuccess implements Action {
  readonly type = RepairServiceActionTypes.CreateRepairRequestSuccess;
  constructor() {}
}

export class CreateRepairRequestFail implements Action {
  readonly type = RepairServiceActionTypes.CreateRepairRequestFail;
  constructor() {}
}

export class ResetRepairService implements Action {
  readonly type = RepairServiceActionTypes.ResetRepairService;
  constructor() {}
}

export type RepairServiceAction =
  | CreateRepairRequest
  | CreateRepairRequestSuccess
  | CreateRepairRequestFail
  | ResetRepairService;

export const fromRepairServiceActions = {
  CreateRepairRequest,
  CreateRepairRequestSuccess,
  CreateRepairRequestFail,
  ResetRepairService
};
