import { ButtonProps } from '@amfam/shared/models';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-jump-links',
  templateUrl: './jump-links.component.html',
  styleUrls: ['./jump-links.component.scss']
})
export class JumpLinksComponent {
  @Input() jumpLinks: ButtonProps[];
  @Input() size: 'small' | 'large' | 'medium' = 'large';
}
