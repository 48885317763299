import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ds-step-counter',
  templateUrl: './ds-step-counter.component.html',
  styleUrls: ['./ds-step-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DsStepCounterComponent implements OnInit {
  @Input() currentStep: number;
  @Input() totalSteps: number;

  constructor() {}

  ngOnInit(): void {}
}
