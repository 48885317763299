import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortColFormat'
})
export class SortColFormatPipe implements PipeTransform {
  private decimalPipe: DecimalPipe = new DecimalPipe('en-US');

  transform(input: any, ...args: any[]): string {
    let format = '';
    let parsedFloat = 0;

    switch (args[0].toLowerCase()) {
      case 'currency':
      case 'decimal':
      case 'number':
        parsedFloat = !isNaN(parseFloat(input)) ? parseFloat(input) : 0;
        format = args.length > 1 ? args[1] : null; // decimal : 1.0-2 for example
        return this.decimalPipe.transform(parsedFloat, format);
      case 'percentage':
        parsedFloat = !isNaN(parseFloat(input)) ? parseFloat(input) : 0;
        format = args.length > 1 ? args[1] : null;
        return this.decimalPipe.transform(parsedFloat, format) + '%';
      case 'billingdocument':
        const documentDisplay = 'Bill Detail';
        const items = { documentId: '', documentType: '', documentDisplay, documentExt: '' };
        items.documentId = input[0].documentId;
        items.documentType = input[0].documentType;

        switch (input[0].documentType) {
          case 'Regular Notice':
            items.documentDisplay = 'Bill Detail';
            items.documentExt = 'pdf';
            break;
          default:
            items.documentDisplay = 'Bill Detail';
            items.documentExt = 'pdf';
            break;
        }

        const keyArr: any[] = Object.keys(items);
        const dataArr = [];

        keyArr.forEach((key: any) => {
          dataArr[key] = items[key];
        });

        return items[args[1]]; // TODO make this better
      default:
        return input;
    }
  }
}
