import { AnalyticsFacade } from '@amfam/shared/analytics';
import { ProfileAnalytics } from '@amfam/shared/models';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../core';

@Component({
  selector: 'delete-my-account-confirmation',
  templateUrl: './delete-my-account-confirmation.component.html',
  styleUrls: ['./delete-my-account-confirmation.component.scss']
})
export class DeleteMyAccountConfirmationComponent implements OnInit, OnDestroy {
  protected stop$: Subject<void> = new Subject<void>();

  constructor(private authService: AuthService, private analyticsFacade: AnalyticsFacade) {}

  ngOnInit() {
    this.analyticsFacade.trackPage(ProfileAnalytics.deleteMyAccountConfirmationPageAnalytics);
    this.analyticsFacade.trackEvent(ProfileAnalytics.deleteMyAccountConfirmationEvent);
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  backToLogin() {
    this.authService
      .logout()
      .pipe(takeUntil(this.stop$))
      .subscribe(() => {
        window.location.href = window.location.origin;
      });
  }
}
