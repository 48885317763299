import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  BillingStatusEnums,
  BillingStatusPill
} from '../billing-status-pill/billing-status-pill.models';

@Component({
  selector: 'ds-billing-status-pill',
  templateUrl: './billing-status-pill.component.html',
  styleUrls: ['./billing-status-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingStatusPillComponent implements OnInit {
  @Input() statusText: string;
  @Input() amount?: string;
  billingPill$: Observable<BillingStatusPill>;
  constructor() {}

  ngOnInit(): void {
    this.mapPillBackgroungColorWithStatusText(this.statusText);
  }

  mapPillBackgroungColorWithStatusText(statusText: string) {
    switch (statusText) {
      case BillingStatusEnums.SCHEDULED:
      case BillingStatusEnums.AUTO_PAY:
        this.billingPill$ = of({
          statusText: statusText,
          pillColor: 'pill-background-success',
          amountColor: 'pill-amount-color-success'
        });
        break;
      case BillingStatusEnums.PAID:
      case BillingStatusEnums.AFT:
      case BillingStatusEnums.ESCROW:
        this.billingPill$ = of({
          statusText: statusText,
          pillColor: 'pill-background-neutral',
          amountColor: 'pill-amount-color-neutral'
        });
        break;
      case BillingStatusEnums.DUE_SOON:
      case BillingStatusEnums.PROCESSING:
        this.billingPill$ = of({
          statusText: statusText,
          pillColor: 'pill-background-primary',
          amountColor: 'pill-amount-color-primary'
        });
        break;
      case BillingStatusEnums.PAST_DUE:
      case BillingStatusEnums.DECLINED:
      case BillingStatusEnums.RETURNED:
      case BillingStatusEnums.CANCELED:
      case BillingStatusEnums.FAILED:
        this.billingPill$ = of({
          statusText: statusText,
          pillColor: 'pill-background-error',
          amountColor: 'pill-amount-color-error'
        });
        break;
    }
  }
}
