import { createAction, props } from '@ngrx/store';

import {
  AddVehicleInitiateErrorResponse,
  AddVehicleInitiateRequest,
  AddVehicleInitiateResponse
} from '../../models/add-vehicle-initiate.model';

export const addVehicleInitiate = createAction(
  '[Add Vehicle] Add vehicle initiate',
  props<{ payload: { request: AddVehicleInitiateRequest; hasTPI: boolean } }>()
);

export const addVehicleInitiateSuccess = createAction(
  '[Add Vehicle] Add vehicle initiate Success',
  props<{ payload: AddVehicleInitiateResponse; nextRoutePath: Array<string> }>()
);

export const addVehicleInitiateError = createAction(
  '[Add Vehicle] Add vehicle initiate Error',
  props<{ payload: AddVehicleInitiateErrorResponse }>()
);

export const addVehicleResetError = createAction('[Add Vehicle] Add vehicle reset Error');

export const addVehicleTPMAvailibilityCheckSuccess = createAction(
  '[Add Vehicle] Add vehicle TPM availibility Success',
  props<{ payload: { hasTPMPackage: boolean; policyNumber: string } }>()
);

export const addVehicleTPMAvailibilityCheckError = createAction(
  '[Add Vehicle] Add vehicle TPM availibility Error',
  props<{ payload: { hasTPMPackage: boolean; policyNumber: string } }>()
);

export const fromAddVehicleInfoActions = {
  addVehicleInitiate,
  addVehicleInitiateSuccess,
  addVehicleInitiateError,
  addVehicleResetError,
  addVehicleTPMAvailibilityCheckSuccess,
  addVehicleTPMAvailibilityCheckError
};
