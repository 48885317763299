import { Address } from './address';
import { Coverage } from './coverage';
import { Discount } from './discount';
import { NamedInsured } from './named-insured';
import { VehicleType } from './vehicle-type';
import { Fee } from './fee';
import { VehicleTypeDisplayNameConstants } from './vehicle-type-display-name-constants';

export class Vehicle {
  public vehicleId: string;
  public year: string;
  public make: string;
  public model: string;
  public vin: string;
  public garageLocationAddress: Address;
  public premium: string;
  public vinSerialNumber: string;
  public vehicleType: VehicleType;
  public vehicleTypeDisplayName: string;
  public vehicleTypeCode: string;
  public vehicleTypeName: string;
  public vehicleCoverages: Coverage[] = new Array<Coverage>();
  public vehicleDiscounts: Discount[] = new Array<Discount>();
  public vehicleFees: Fee[] = new Array<Fee>();
  public vehicleRoles: NamedInsured[] = new Array<NamedInsured>();
  public vehicleUseDescription: string;
  public iconType: string;
  public description?: string;

  static fromJson(json: any, sourceSystem:string): Vehicle {
    const vehicle = new Vehicle();
    vehicle.assignProperties(json, sourceSystem);

    return vehicle;
  }

  public static getVehicleTypeDisplayName(type): string {
    switch (type) {
      case VehicleType.ATV:
        return VehicleTypeDisplayNameConstants.ATV;
      case VehicleType.UTV:
        return VehicleTypeDisplayNameConstants.UTV;
      case VehicleType.GolfCart:
        return VehicleTypeDisplayNameConstants.GOLFCART;
      case VehicleType.MobilityDevice:
        return VehicleTypeDisplayNameConstants.MOBILITYDEVICE;
      case VehicleType.Boat:
        return VehicleTypeDisplayNameConstants.WATERCRAFT;
      case VehicleType.Motorcycle:
        return VehicleTypeDisplayNameConstants.MOTORCYCLE;
      case VehicleType.Moped:
        return VehicleTypeDisplayNameConstants.MOPED;
      case VehicleType.DirtBike:
        return VehicleTypeDisplayNameConstants.DIRTBIKE;
      case VehicleType.Scooter:
        return VehicleTypeDisplayNameConstants.SCOOTER;
      case VehicleType.Motorhome:
        return VehicleTypeDisplayNameConstants.MOTORHOME;
      case VehicleType.Camper:
        return VehicleTypeDisplayNameConstants.CAMPER;
      case VehicleType.Trailer:
        return VehicleTypeDisplayNameConstants.TRAILER;
      case VehicleType.Snowmobile:
        return VehicleTypeDisplayNameConstants.SNOWMOBILE;
      case VehicleType.FarmMachinery:
        return VehicleTypeDisplayNameConstants.FARMMACHINERY;
      default:
        return VehicleTypeDisplayNameConstants.AUTO;
    }
  }

  static getVehicleType(vehicleTypeCode: string): VehicleType {
    switch (vehicleTypeCode) {
      case 'auto':
      case 'antiques_af':
      case 'medHeavyDutyTruck_af':
      case 'miniTruck_onroad_af':
        return VehicleType.Auto;
      case 'atv_af':
        return VehicleType.ATV;
      case 'inboard_af':
      case 'inboard_outdrive_af':
      case 'jet_drive_af':
      case 'outboard_af':
      case 'sailboat_af':
        return VehicleType.Boat;
      case 'camper_af':
        return VehicleType.Camper;
      case 'dirt_bike_af':
        return VehicleType.DirtBike;
      case 'farmMachinery_af':
        return VehicleType.FarmMachinery;
      case 'golfCart_onroad_af':
      case 'golfCarts_af':
      case 'golfcarts_af':
        return VehicleType.GolfCart;
      case 'mobility_af':
        return VehicleType.MobilityDevice;
      case 'moped_af':
        return VehicleType.Moped;
      case 'motorcycle_af':
        return VehicleType.Motorcycle;
      case 'motorhome_af':
        return VehicleType.Motorhome;
      case 'scooter_af':
        return VehicleType.Scooter;
      case 'snowmobile_af':
        return VehicleType.Snowmobile;
      case 'trailer_af':
        return VehicleType.Trailer;
      case 'utv_af':
        return VehicleType.UTV;
      default:
        return VehicleType.Unknown;
    }
  }

  protected assignProperties(json: any, sourceSystem:string) {
    this.vehicleId = json.vehicleId;
    this.year = json.year;
    this.make = json.make;
    this.model = json.model;
    this.garageLocationAddress = Address.fromJson(json.garageLocationAddress);
    this.premium = json.premium;
    this.vinSerialNumber = json.vinSerialNumber || json.vin;
    this.vehicleType = Vehicle.getVehicleType(json.vehicleTypeCode);
    this.vehicleTypeDisplayName = Vehicle.getVehicleTypeDisplayName(this.vehicleType);
    this.vehicleTypeCode = json.vehicleTypeCode;
    this.vehicleTypeName = json.vehicleTypeName;
    this.vehicleUseDescription = json.vehicleUseDescription;
    this.description = `${json.year} ${json.make} ${json.model}`;

    this.iconType = json.iconType;
    if (json.vehicleCoverages) {
      for (const vehicleCoverage of json.vehicleCoverages) {
        const coverage = Coverage.fromJson(vehicleCoverage, sourceSystem !== 'Rome');
        if (Coverage.isValid(coverage)) {
          this.vehicleCoverages.push(coverage);
        }
      }
    }
    if (json.vehicleDiscounts) {
      for (const vehicleDiscount of json.vehicleDiscounts) {
        this.vehicleDiscounts.push(Discount.fromJson(vehicleDiscount));
      }
    }
    if (json.vehicleFees) {
      for (const vehicleFee of json.vehicleFees) {
        this.vehicleFees.push(Discount.fromJson(vehicleFee));
      }
    }
    if (json.vehicleRoles) {
      for (const vehicleRole of json.vehicleRoles) {
        this.vehicleRoles.push(NamedInsured.fromJson(vehicleRole));
      }
    }
  }
}
