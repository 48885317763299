@if (loggedIn$ | async) {
  <div id="navigationMenuContact" class="top-navigation-contact" data-cy="navigationMenuContact">
    @if ((isPartner$ | async) === false) {
      <span>
        <span class="icon-contact-us" data-cy="navigationMenuContactUsIcon"></span>
        <a
          class="pad-right-24"
          [href]="contactUsURL"
          target="amfam"
          data-cy="navigationMenuContactUsLink"
          ><em>Contact Us</em></a
        >
      </span>
    }
    @if ((chatEnabled$ | async) && (isPartner$ | async) === false) {
      <span>
        <span class="icon-faqs"></span>
        <a
          class="pad-right-24"
          [href]="amfamChatUrl"
          target="amfam"
          data-cy="navigationMenuContactChatLink"
          ><em>Chat Online</em></a
        >
      </span>
    }
    @if (
      (brandInfo$ | async)?.cusCareNumberFullHref &&
      (brandInfo$ | async)?.brandState?.data?.cusCareNumber
    ) {
      <span data-cy="navigationMenuContactCusCareInfo">
        <span class="icon-call" data-cy="iconCall"></span>
        @if (isPartner$ | async) {
          <a
            [href]="(brandInfo$ | async)?.cusCareNumberFullHref"
            [attr.aria-label]="
              'Customer Service: ' + (brandInfo$ | async)?.brandState?.data?.cusCareNumber
            "
            class="inline-block"
            data-cy="navigationMenuContactCusCareNumberLink"
            >{{ (brandInfo$ | async)?.brandState?.data?.cusCareNumber }}</a
          >
        }
        @if ((isPartner$ | async) === false) {
          <a
            [href]="'tel:+' + amfamHeaderFooterPhoneNumber"
            [attr.aria-label]="'Customer Service: ' + amfamHeaderFooterPhoneNumber"
            class="inline-block"
            data-cy="navigationMenuContactCusCareNumberLink"
            ><em>{{ amfamHeaderFooterPhoneNumber }}</em></a
          >
        }
      </span>
    }
  </div>
}
<nav role="navigation" data-cy="navigation">
  <div id="navigationMenu" data-cy="navigationMenu">
    <div class="flex">
      <a
        target="_self"
        tabindex="-1"
        [href]="homeUrl"
        [attr.title]="headerLogo?.altText ? headerLogo?.altText : 'logo'"
      >
        <div
          class="logo-container column text-center fixed-200"
          data-cy="logo"
          tabindex="0"
          [style.backgroundImage]="'url(' + headerLogo?.logoURL + ')'"
          [style.height.px]="headerLogo?.height"
        ></div>
      </a>
    </div>
    <div role="menubar" id="navigationMenuItems" class="flex" data-cy="navigationMenuItems">
      @for (link of linkArray$ | async; track trackByFn($index)) {
        @if (link.showOnNavigationMenu) {
          <ds-header-navigation-item
            #navItem
            class="link-item"
            [ngClass]="link.linkClasses"
            [link]="link"
            [loaded$]="loaded$"
            [headerNavRect]="headerNavRect$ | async"
            (linkClickEvent)="linkClicked($event)"
            (sublinkClickEvent)="sublinkClicked($event)"
            (subOpenEvent)="hideAllSubMenus($event)"
          ></ds-header-navigation-item>
        }
      }
      @if ((loggedIn$ | async) && (notifications$ | async)) {
        <ds-header-notification
          [headerNavRect]="headerNavRect$ | async"
          [notifications$]="notifications$"
          [experienceId]="(brandInfo$ | async)?.brandState?.experienceId"
        >
        </ds-header-notification>
      }
    </div>
  </div>
</nav>
