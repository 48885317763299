/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ACTIONTYPE, AnalyticsActions, AnalyticsFacade } from '@amfam/shared/analytics';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { CookiesService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService, ValidationService } from '@amfam/ui-kit';

import { AuthErrors } from '../../core/auth/auth.enum';
import { AuthService } from '../../core/auth/auth.service';
import * as fromRoot from '../../core/store/';
import * as sessionActions from '../../core/store/session/session.actions';

@Component({
  selector: 'ds-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Output() responseCodeEvent = new EventEmitter();
  @Input() isEmailValidation: boolean;
  @Input() cookieError: boolean;

  disableUsername = false;
  username: AbstractControl;
  password: AbstractControl;
  rememberMe: AbstractControl;
  loginForm: UntypedFormGroup;
  responseCode = 0;
  responseSubcode = 0;
  loginAttempts = 0;
  responseReceived = true;
  showBanner: boolean;
  loginType: string;
  customerCareNumber: Observable<string>;
  authErrors = AuthErrors;

  private stop$: Subject<void> = new Subject<void>();
  private loginAttemptsUser = '';

  constructor(
    private store: Store,
    private authService: AuthService,
    private cookiesService: CookiesService,
    private featureFlagService: FeatureFlagService,
    private fb: UntypedFormBuilder,
    private spinner: LoadingSpinnerService,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
    this.spinner.stop();
  }

  ngOnInit() {
    this.customerCareNumber = this.store.select(BrandSelectors.selectCusCareNumber);

    this.buildForm();
    this.handleSessionStatus();
  }

  loginUser() {
    const loginEvent = {
      event: 'login_attempt',
      method: '',
      account_type: '', // we wont know this for the attempt
      login_attempt_count: '1'
    };
    this.analyticsFacade.trackEvent(loginEvent);

    // Reset response code upon new login attempt
    this.responseCode = 0;
    localStorage.removeItem('vehicleName');
    const usernameControl = this.loginForm.controls.username as UntypedFormControl;
    if (this.disableUsername) {
      this.loginType = 'remember me';
    } else if (!ValidationService.emailValidator(usernameControl)) {
      this.loginType = 'email';
    } else {
      this.loginType = 'userid';
    }

    this.store.dispatch(
      new sessionActions.LoginUserAction({
        username: this.username.value.trim(),
        password: this.password.value
      })
    );
  }

  rememberMeToggle() {
    const cookieExists = this.cookiesService.hasItem('RMUID');
    // Reset userID form value if rememberMe === false AND cookie exists
    if (!this.rememberMe.value && cookieExists) {
      this.username.setValue('');
    }
    // Disable username field ONLY if the cookie already exists and rememberMe === true
    this.disableUsername = cookieExists && this.rememberMe.value;
    if (this.disableUsername) this.username.disable();
    else this.username.enable();

    // Updates auth service with new rememberMe value and deletes cookie if false
    this.authService.setRememberMe(this.rememberMe.value);
  }

  private buildForm() {
    // Set initial value for Remember Me
    let rememberMeDefault = false;
    let username = '';

    this.loginForm = this.fb.group({
      username: [username, Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      rememberMe: [rememberMeDefault]
    });

    this.username = this.loginForm.controls['username'];
    this.password = this.loginForm.controls['password'];
    this.rememberMe = this.loginForm.controls['rememberMe'];

    // If RMUID cookie exists, then Remember Me = true
    if (this.featureFlagService.isEnabled('save_user_id')) {
      if (this.authService.rememberMe) {
        rememberMeDefault = true;
        this.rememberMe.setValue(rememberMeDefault);
        const maskedUserId = this.authService.maskedUserId;
        // If maskedUserId exists, use that for username. Otherwise use only asterisks
        username = maskedUserId ? maskedUserId : '*******';
        this.username.setValue(username);
        this.disableUsername = true;
        this.username.disable();
      }
    }
  }

  private handleSessionStatus() {
    const loginFailureAnalyticsEvent = {
      event: 'login_failure',
      method: '',
      account_type: '', // Not available in case if failure
      failure_reason: null, // Do not include PII
      login_failure_count: '1'
    };

    this.store
      .select(fromRoot.getSessionStatus)
      .pipe(takeUntil(this.stop$))
      .subscribe(status => {
        this.responseReceived = true;
        // is session established
        if (!status) {
          return;
        }
        this.responseCode = _get(status, 'code', 401);
        if (status.code !== 200) this.responseSubcode = _get(status, 'messages[0].code', 401000);

        if (this.responseCode === 409) {
          this.responseCodeEvent.emit(this.responseCode);
        }

        // If response is 423 but subcode is not there, default to 4230001
        if (this.responseCode === 423 && this.responseSubcode === 0) {
          this.responseSubcode = 4230001;
          // Emit response code back to parent
          this.responseCodeEvent.emit(this.responseCode);
        }

        // success
        if (this.responseCode === 200) {
          this.clearAttempts();
          this.authService.loginRedirect();
          return;
        }
        // fail
        this.spinner.stop();

        // TODO: Handle 423 for email validation
        if (this.responseSubcode === AuthErrors.ACCOUNT_LOCKED) {
          this.clearAttempts();
          if (!this.disableUsername) {
            this.authService.setCurrentlyLockedUserId(this.username.value);
            this.store.dispatch(
              AnalyticsActions.sendDynatraceAction({
                payload: {
                  actionName: this.username.value,
                  actionType: ACTIONTYPE.ACCOUNT_LOCKED
                }
              })
            );
          }
        }
        if (this.responseSubcode === AuthErrors.INVALID_CREDENTIALS) {
          this.incrementAttempts(this.username.value);
          this.store.dispatch(
            AnalyticsActions.sendDynatraceAction({
              payload: {
                actionName: this.username.value,
                actionType: ACTIONTYPE.INVALID_CREDENTIALS
              }
            })
          );
        }

        // successfull login will not reach this part of code
        loginFailureAnalyticsEvent.failure_reason = this.responseCode;
        this.analyticsFacade.trackEvent(loginFailureAnalyticsEvent);
      });
  }

  private incrementAttempts(username) {
    if (this.loginAttemptsUser !== username) {
      this.clearAttempts();
    }
    this.loginAttemptsUser = username;
    if (this.loginAttempts < 6) {
      this.loginAttempts++;
    }
  }

  private clearAttempts() {
    this.loginAttemptsUser = '';
    this.loginAttempts = 0;
  }
}
