<div class="row">
  <div data-cy="phoneNumberList" class="column twelve solidBorderBottom pad-bottom-30">
    <ds-phone-list
      [phones]="flattenedPhones"
      [allPrimaryCheck]="allPrimaryCheck"
      (makePrimaryEvent)="onMakePrimary($event)"
      (createEvent)="onCreate($event)"
      (deleteEvent)="onDeleteConfirm($event)"
    >
    </ds-phone-list>
  </div>
</div>

<ds-modal [id]="modalId" [closeOthers]="true" [maxWidth]="800">
  <div class="row">
    <div class="column twelve">
      <h2
        class="pad-bottom-20 pad-left-50 pad-right-50 text-center font-weight-semibold"
        content
        [contentPath]="'profile.managePhoneNumbers.deletePhoneNumbersText'"
      ></h2>
      <p class="pad-bottom-20 font-weight-semibold text-center">
        {{ phoneToDelete | phoneDescription }}
        <span content [contentPath]="'shared.willBeDeleted'"></span>
      </p>
      <div class="flex items-center justify-center">
        <button
          ds-button
          (click)="onDelete(phoneToDelete)"
          data-cy="deletePhoneConfirmButton"
          class="-error"
          type="button"
          content
          [contentPath]="'shared.deleteBtn'"
        ></button>
        <a
          (click)="closeModal()"
          class="pad-left-16 caption primary-800-text font-weight-bold"
          content
          [contentPath]="'shared.cancel'"
        ></a>
      </div>
    </div>
  </div>
</ds-modal>
