import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { fromRouterActions } from '@amfam/shared/utility/navigation';

@Component({
  selector: 'ds-underlying-policy-button',
  templateUrl: './underlying-policy-button.component.html',
  styleUrls: ['./underlying-policy-button.component.scss']
})
export class UnderlyingPolicyButtonComponent {
  @Input() productType: string;
  @Input() productName: string;
  @Input() iconType: string;
  @Input() policyNumber: string;
  @Input() vehicleId: string;

  constructor(private store: Store<any>) {}

  navigateToPolicy(): void {
    let linkProductType = this.productType;
    if (
      this.productType.toLowerCase() === 'homeowners' ||
      this.productType.toLowerCase() === 'personalproperty'
    ) {
      linkProductType = 'property';
    } else if (this.productType.toLowerCase() === 'personalauto') {
      linkProductType = 'auto';
    }

    const path = `/policies/${linkProductType}/${this.policyNumber}`;
    // Navigate to the policy page
    if (this.vehicleId) {
      this.store.dispatch(
        fromRouterActions.Go({
          path: [path],
          query: { vehicleId: `${this.vehicleId}` }
        })
      );
    } else {
      this.store.dispatch(
        fromRouterActions.Go({
          path: [path]
        })
      );
    }
  }
}
