export interface OverviewCopy {
  reliefCredit: ReliefCreditCopy;
  aft: string;
  amount_due: string;
  automaticAMD: string;
  managedByOther: string;
  managedByThirdParty: string;
  paid: string;
  paid_full: string;
  scheduled: string;
  kydCardEnrolled: string;
  kydCardNotEnrolled: string;
  kydSmartphoneCardNotEnrolled: string;
  kydSmartphoneCardEnrolled: string;
  mmwCardEnrolledTitle: string;
  mmwCardEnrolledMessage: string;
  alerts: AlertsCopy;
  multipleAutopayHeaderA: string;
  multipleAutopayHeaderB: string;
  multipleAutomaticPaymentInfo: string;
  multipleAutomaticPaymentInfoSub: string;
  multipleLateChoose: string;
  multipleLateGet: string;
  multipleLateFees: string;
  multiplePeaceOfMind: string;
  multipleCancelAnytime: string;
  mutipleAutopaySetupBtn: string;
  noDocumentFound: string;
  duplicateEmailError: string;
  duplicateEmailText: string;
  duplicateEmailProfileContent: string;
  alertTitle: string;
  alertMessage: string;
  partnerBanner: string;
}

export interface ReliefCreditCopy {
  bannerHeadingText: string;
  bannerHeadingTextPhase2: string;
  bannerHeadingTextPhase3: string;
  bannerHeadingTextPhase4: string;
  bannerHeadingTextPhase5: string;
  bannerSubText: string;
  bannerButtonText: string;
  totalSaved1: string;
  totalSaved2: string;
  totalSaved1ROP5: string;
  totalSaved2ROP5: string;
  pageTitle: string;
  pageDescription: string;
  pageTitleAllDiscountsPhase2: string;
  pageTitleAllDiscountsPhase5: string;
  pageDescriptionAllDiscountsPhase2: string;
  pageDescriptionAllDiscountsPhase3: string;
  pageDescription2AllDiscountsPhase2: string;
  pageTitleNotAllDiscountsPhase2: string;
  pageDescriptionNotAllDiscountsPhase2: string;
  pageDescriptionNotAllDiscountsPhase3: string;
  pageDescriptionNotAllDiscountsPhase5: string;
  pageDescriptionNotAllDiscounts2Phase5: string;
  pageTitleNoAuto: string;
  pageDescriptionNoAuto: string;
  pageTitleNoAutoPhase2: string;
  pageDescriptionNoAutoPhase2: string;
  pageDescriptionNoAutoPhase3: string;
  pageDescriptionNoAutoPhase4: string;
  savingsTitle: string;
  expectedTitle: string;
  expectedDescription: string;
  noCreditNoAuto: string;
  noCreditNoAutoDescription: string;
  noCreditNoAutoBullet1: string;
  noCreditNoAutoBullet2: string;
  noCreditNoAutoBullet3: string;
  questionsTitle: string;
  questionsTitleNoAuto: string;
  questionsDescriptionNoAuto: string;
  disclaimer: string;
  disclaimerPhase3: string;
  disclaimerPhase4: string;
  disclaimer2: string;
  disclaimer2Phase5: string;
  increasedDiscountsAvailableTitle: string;
  increasedDiscountsAvailableDescription: string;
  increasedDiscountsAvailableLabel: string;
  increasedDiscountsUnavailableTitle: string;
  increasedDiscountsUnavailableDescription: string;
  increasedDiscountsUnavailableLabel: string;
  bottomTextPayment: string;
}

export interface AlertsCopy {
  MULTIPLE_BILLS_OVERDUE: MULTIPLEBILLSOVERDUE;
  BILL_OVERDUE: MULTIPLEBILLSOVERDUE;
  UNSUCCESSFUL_PAYMENT: MULTIPLEBILLSOVERDUE;
  UNSUCCESSFUL_AFT_PAYMENT: MULTIPLEBILLSOVERDUE;
  PAPERLESS_BILLING_DISCOUNT: MULTIPLEBILLSOVERDUE;
  PAPERLESS_POLICY_DISCOUNT: MULTIPLEBILLSOVERDUE;
  PREREQUISITE_NEEDED_AUTOPAY_DISCOUNT: MULTIPLEBILLSOVERDUE;
  AUTOPAY_DISCOUNT: MULTIPLEBILLSOVERDUE;
  AUTOPAY_EDIT: MULTIPLEBILLSOVERDUE;
  KYD_DISCOUNT: MULTIPLEBILLSOVERDUE;
  KYD_SMARTPHONE_DISCOUNT: MULTIPLEBILLSOVERDUE;
  KYD_SMARTPHONE_OPERATOR_DISCOUNT: MULTIPLEBILLSOVERDUE;
  POLICIES_MANAGED_EXTERNALLY: MULTIPLEBILLSOVERDUE;
}

export interface MULTIPLEBILLSOVERDUE {
  message: string;
  action: string;
}
