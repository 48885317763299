@if (!showCodeEntry) {
  <div>
    <div class="row">
      <div class="column twelve margin-bottom-20 text-left">
        <h1 content [contentPath]="'auth.resetOptionsCallHeader'"></h1>
        <p><span content [contentPath]="'enrollment.verifyCallContinueText'"></span></p>
      </div>
    </div>
    <ds-verify-phone
      [maskedPhoneNumbers]="phoneList"
      [usePhoneInput]="false"
      [buttonText]="'continue'"
      (submittedPhoneEvent)="sendCode($event)"
    >
    </ds-verify-phone>
    @if (gotError && !showCodeEntry) {
      <small class="block caption error margin-top-5">
        @if (!phoneLocked) {
          <span content [contentPath]="'enrollment.verifyEmailNotFound'"></span>
        }
        @if (phoneLocked) {
          <span content [contentPath]="'auth.resetOptionsPhoneLocked'"></span>
        }
      </small>
    }
  </div>
}
@if (showCodeEntry) {
  <div>
    <h1 content [contentPath]="'auth.resetOptionsCodeHeader'"></h1>
    <p>
      <span content [contentPath]="'enrollment.verifyCallSentPart1'"></span>
      <strong>{{ phone }}</strong>
      <span content [contentPath]="'enrollment.verifyCallSentPart2'"></span>
    </p>
    <ds-reset-password-code-entry [pinConfirmModel]="pinConfirmData">
    </ds-reset-password-code-entry>
    <p class="caption">
      <button
        ds-button
        class="link"
        (click)="sendCode(phone)"
        content
        [contentPath]="'enrollment.verifyCallAgain'"
      ></button>
    </p>
    @if (gotError) {
      <small
        class="block caption error margin-top-5"
        content
        [contentPath]="'enrollment.verifyEmailNotFound'"
      ></small>
    }
  </div>
}

<a href="javascript:void(0)" (click)="goBackOneStep()" class="inline-block margin-top-20 caption"
  ><em class="icon-chevron-left"></em> Back</a
>
