<!-- <table class="vehicle-table" data-cy="vehicle-table">
  <thead>
    <tr>
      <th data-cy="yearTitle">Year</th>
      <th data-cy="makeTitle">Make</th>
      <th data-cy="modelTitle">Model</th>
      <th data-cy="vinTitle">VIN</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-cy="vehichleYear">{{ risk.year }}</td>
      <td data-cy="vehichleMake">{{ risk.make }}</td>
      <td data-cy="vehichleModel">{{ risk.model }}</td>
      <td class="break-all" data-cy="vehicle-vin">{{ risk.vin }}</td>
    </tr>
  </tbody>
</table> -->

<a class="vehicle-card__wrapper" (click)="vehicleCardClicked.emit(risk)">
  <div class="vehicle-card">
    <div class="vehicle-card__icon">
      <i class="icon-auto"></i>
    </div>
    <div class="vehicle-card__content" data-cy="riskYearMakeModel">
      {{ risk.year }} {{ risk.make }} {{ risk.model }}
    </div>
    <div class="vehicle-card__action">
      <i class="icon-chevron-right-thick with-gradient"></i>
    </div>
  </div>
</a>
