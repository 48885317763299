import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'amfam-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent {
  @Input() index: number;
  @Input() id: string;
  @Input() url: string;
  @Input() name: string;
  @Input() office: string;
  @Input() address: string;
  @Input() altText: string;
  @Input() recommended: boolean;
  @Input() showRadio = false;
  @Input() defautImage = '/assets/images/blank-profile-picture.webp';
  @Input() selected = false;
  @Output() profileSelected = new EventEmitter<string>();

  imageLoadError() {
    this.url = this.defautImage;
  }
}
