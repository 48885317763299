import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';

import { fromRouterActions } from '@amfam/shared/utility/navigation';

import * as AutoPaySetupActions from './auto-pay-setup.actions';
import { AutoPaySteps } from './auto-pay-setup.models';
import * as AutoPaySetupSelectors from './auto-pay-setup.selectors';

@Injectable()
export class AutoPaySetupEffects {
  // Go to next step after updating autopay preferences and setting completed to true
  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AutoPaySetupActions.updateAutoPaySetup),
      map(() => AutoPaySetupActions.nextStep())
    );
  });

  edit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AutoPaySetupActions.editAutoPaySetup),
      switchMap(action => [
        AutoPaySetupActions.setStep({
          step: AutoPaySteps.SETUP,
          activeBillAccount: action.billAccountNumber,
          isEditing: true
        }),
        fromRouterActions.Go({
          path: ['billing', 'auto-pay', 'setup'],
          query: { billAccount: action.billAccountNumber }
        })
      ])
    );
  });

  routeToPreviousStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AutoPaySetupActions.previousStep),
      concatLatestFrom(() => this.store.select(AutoPaySetupSelectors.selectPreviousSetup)),
      switchMap(([, previousSetup]) => {
        if (previousSetup) {
          return [
            AutoPaySetupActions.setStep({
              step: AutoPaySteps.SETUP,
              activeBillAccount: previousSetup.billAccount.billAccountNumber,
              isEditing: false
            }),
            fromRouterActions.Go({
              path: ['billing', 'auto-pay', 'setup'],
              query: { billAccount: previousSetup.billAccount.billAccountNumber }
            })
          ];
        } else {
          return [
            AutoPaySetupActions.setStep({ step: AutoPaySteps.SELECT_ACCOUNTS }),
            fromRouterActions.Go({
              path: ['billing', 'auto-pay', 'select-accounts']
            })
          ];
        }
      })
    );
  });

  routeToNextStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AutoPaySetupActions.nextStep),
      concatLatestFrom(() => [
        this.store.select(AutoPaySetupSelectors.selectNextSetup),
        this.store.select(AutoPaySetupSelectors.selectActiveSetup)
      ]),
      switchMap(([, nextSetup, activeSetup]) => {
        if (nextSetup && !activeSetup.editing) {
          return [
            AutoPaySetupActions.setStep({
              step: AutoPaySteps.SETUP,
              activeBillAccount: nextSetup.billAccount.billAccountNumber,
              isEditing: false
            }),
            fromRouterActions.Go({
              path: ['billing', 'auto-pay', 'setup'],
              query: { billAccount: nextSetup.billAccount.billAccountNumber }
            })
          ];
        } else {
          return [
            AutoPaySetupActions.setStep({ step: AutoPaySteps.REVIEW }),
            fromRouterActions.Go({
              path: ['billing', 'auto-pay', 'review']
            })
          ];
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store
  ) {}
}
