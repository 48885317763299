import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { take } from 'rxjs/operators';

import { AnalyticsFacade } from '@amfam/shared/analytics';
import { SecurityQuestionsAnswerModel, VerifySecurityQuestionsModel } from '@amfam/shared/models';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import * as fromRoot from '../../../../core/store';
import * as resetPasswordActions from '../../../../core/store/reset-password/reset-password.actions';
import { ForgotService } from '../../../shared/forgot.service';
import { ForgotPasswordAnalytics } from '../../shared/forgot-password-analytic-constants';

@Component({
  selector: 'ds-reset-password-security-questions',
  templateUrl: './reset-password-security-questions.component.html',
  styleUrls: ['../../forgot-password.component.scss']
})
export class ResetPasswordSecurityQuestionsComponent implements OnInit, OnDestroy {
  firstQuestion: string;
  secondQuestion: string;
  userIdentifier: string;
  inputFirstAnswer: AbstractControl;
  inputSecondAnswer: AbstractControl;
  securityForm: UntypedFormGroup;
  securityVerificationError = false;
  securityQuestionsGenericError = false;
  submitAttempts = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private forgotService: ForgotService,
    private spinner: LoadingSpinnerService,
    private store: Store<fromRoot.RootState>,
    private analyticsFacade: AnalyticsFacade
  ) {}

  ngOnInit() {
    this.store
      .select(fromRoot.getResetPasswordState)
      .pipe(take(1))
      .subscribe(state => {
        this.firstQuestion = state.securityQuestions[0];
        this.secondQuestion = state.securityQuestions[1];
        this.userIdentifier = state.userIdentifier;
      });

    this.buildForm();
    this.analyticsFacade.trackPage(ForgotPasswordAnalytics.pageForgotPasswordSecurityQuestions);
  }

  private buildForm() {
    this.securityForm = this.formBuilder.group({
      inputFirstAnswer: '',
      inputSecondAnswer: ''
    });
    this.inputFirstAnswer = this.securityForm.controls['inputFirstAnswer'];
    this.inputSecondAnswer = this.securityForm.controls['inputSecondAnswer'];
  }

  submitAnswers() {
    this.securityVerificationError = false;
    this.securityQuestionsGenericError = false;
    this.spinner.start();
    const requestModel: VerifySecurityQuestionsModel = this.buildSubmitRequest();
    this.forgotService
      .verifySecurityQuestions(requestModel)
      .pipe(take(1))
      .subscribe(
        res => {
          this.spinner.stop();
          const statusCode = _get(res, 'status.code');
          if (statusCode === 200) {
            this.submitAttempts = 0;
            this.store.dispatch(
              new resetPasswordActions.UserVerifiedSecurityQuestionsAction(
                requestModel.securityQuestions
              )
            );
            this.store.dispatch(
              fromRouterActions.Go({
                path: ['/forgot-password/reset-password']
              })
            );
          } else {
            this.securityQuestionsGenericError = true;
          }
        },
        err => {
          this.spinner.stop();
          const apiCode = _get(err, 'status.messages[0].code');
          if (apiCode === 400002) {
            // Validation error (Incorrect answers)
            this.submitAttempts++;
            this.securityVerificationError = true;
          } else {
            this.securityQuestionsGenericError = true;
          }
        }
      );
  }

  private buildSubmitRequest() {
    const securityQuestionsFirst: SecurityQuestionsAnswerModel = new SecurityQuestionsAnswerModel();
    securityQuestionsFirst.setQuestion = this.firstQuestion;
    securityQuestionsFirst.setAnswer = this.inputFirstAnswer.value;

    const securityQuestionsSecond: SecurityQuestionsAnswerModel =
      new SecurityQuestionsAnswerModel();
    securityQuestionsSecond.setQuestion = this.secondQuestion;
    securityQuestionsSecond.setAnswer = this.inputSecondAnswer.value;
    const post: VerifySecurityQuestionsModel = {
      userIdentifier: this.userIdentifier,
      securityQuestions: [securityQuestionsFirst, securityQuestionsSecond]
    };
    return post;
  }

  goBackOneStep() {
    this.store.dispatch(
      fromRouterActions.Go({
        path: ['/forgot-password/reset-options']
      })
    );
  }

  ngOnDestroy() {
    this.spinner.stop();
  }
}
