import { CoreService } from '@amfam/opportunities';
import { AnalyticsActions, AnalyticsFacade } from '@amfam/shared/analytics';
import { GTMDataLayerInit } from '@amfam/shared/analytics/src/lib/models/analytics.model';
import { HeaderWrapperService } from '@amfam/shared/ui/ds-header';
import {
  ConfigService,
  EventDispatcherService,
  ScriptLoaderService,
  WindowRef
} from '@amfam/shared/utility/shared-services';
import { DockingBarService, DsModalService, LoadingSpinnerService } from '@amfam/ui-kit';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { FeatureDetectionService } from './core/feature-detection/feature-detection.service';
import * as fromRoot from './core/store';
import { SessionActivityService } from './login/session-activity';

@Component({
  selector: 'ds-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public noDock$: Observable<boolean>;
  private stop$ = new Subject<void>();
  dataLayerInit: GTMDataLayerInit;

  title = 'My Account';

  mobileMenuOpen$: Observable<boolean>;
  mobileMenuActive$: Observable<boolean>;

  @ViewChild('overlay')
  overlay: ElementRef;

  @HostListener('click')
  logSessionActivity() {
    this.sessionSvc.registerUserActivity();
  }
  noDocumentModalId = 'noDocumentModal';
  noDocumentMessage: string;

  constructor(
    private spinner: LoadingSpinnerService,
    private sessionSvc: SessionActivityService,
    private dockingService: DockingBarService,
    private featureDetectionService: FeatureDetectionService,
    private router: Router,
    private win: WindowRef,
    private store: Store<fromRoot.RootState>,
    private headerWrapperService: HeaderWrapperService,
    private deviceService: DeviceDetectorService,
    private coreService: CoreService,
    private scriptloader: ScriptLoaderService,
    private configService: ConfigService,
    private analyticsFacade: AnalyticsFacade,
    private modal: DsModalService,
    private eventDispatcherService: EventDispatcherService
  ) {}

  ngOnInit() {
    // Attempt to load the script only if the script URL is available in the config.json
    try {
      const scriptURL = this.configService.get('links.salesForceBeconURL');
      if (scriptURL) {
        this.scriptloader.loadScript(scriptURL);
      } else {
        throw new Error('Script URL not found check app.config.ts');
      }
    } catch (error) {
      console.error('Error loading: scriptURL', error);
    }

    // Check the app status
    this.featureDetectionService.detect();
    this.analyticsFacade.loadGoogleMaps();
    this.store.dispatch(AnalyticsActions.loadDynatrace());
    this.store.dispatch(AnalyticsActions.loadAdobeAnalytics()); // This is how we load analytics with the ngrx implementation.
    this.store.dispatch(AnalyticsActions.loadGoogleAnalytics()); // This is how we load analytics with the ngrx implementation.

    this.mobileMenuOpen$ = this.headerWrapperService.mobileMenuOpen$;
    this.mobileMenuActive$ = this.headerWrapperService.mobileMenuActive$;

    // TODO find a better place for this subscription / refactor analytic user type
    this.store
      .select(fromRoot.getAnalyticUserType)
      .pipe(takeUntil(this.stop$))
      .subscribe(userType => {
        this.analyticsFacade.setProfileInfo({ userType });
      });

    // TODO fix pipe(delay(0))
    this.noDock$ = this.dockingService.dockEmpty$.pipe(delay(0));

    // Scroll to the top of the page on route change
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.win.nativeWindow.scrollTo(0, 0);

      /*
      On navigation end, change focused element to body if needed.
      Without this the focused item will be the last item focused before the route change.
      This causes a 'focus trap' and keeps the 'Skip to content' link at the top of the page from being the first tabbed to items.
      That means that the screen reader starts at a weird place in the screen, sometimes on an element that no longer exists.
      */

      // a11y: this checks if the currently focused dom element is the body
      if (document.activeElement !== document.body) {
        // if it's not then it sets the tabindex so it can be focused
        document.body.tabIndex = -1;
        // then sets the focus
        document.body.focus();
        // then removes the tabIndex attribute again
        document.body.removeAttribute('tabIndex');
      }
    });

    this.coreService.listenForMessages();
    this.eventDispatcherService.noDocumentModal$
      .pipe(
        takeUntil(this.stop$),
        tap(action => {
          this.noDocumentMessage = this.getNoDocumentMessage(action);
          this.modal.open(this.noDocumentModalId);
          console.log(action);
        })
      )
      .subscribe();
  }

  private isSafariOnMobile(): boolean {
    return (
      ['iphone', 'ipad', 'macintosh'].includes(this.deviceService.device.toLowerCase()) &&
      ['tablet', 'mobile'].includes(this.deviceService.deviceType.toLowerCase()) &&
      this.deviceService.browser.toLowerCase() === 'safari'
    );
  }

  closeNoDocumentModal() {
    this.modal.close(this.noDocumentModalId);
  }

  getNoDocumentMessage(action = 'download') {
    return `We're unable to ${action} your policy document at this time. Please try again later`;
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
    this.spinner.start();
  }
}
