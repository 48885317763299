import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap, toArray, withLatestFrom } from 'rxjs/operators';

import { Policy } from '@amfam/policy/models';

import * as fromRoot from '../../';
import { KydSmartphoneService } from '../../../../policy/know-your-drive-partial-household/services/kyd-smartphone.service';
import * as kydEligibilityActions from '../kyd/kyd-eligibility.actions';
import * as kydSmartphoneEnrollSummaryActions from './kyd-smartphone-enrollment-summary.actions';

@Injectable()
export class KydSmartphoneEnrollmentSummaryEffects {
  loading$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydEligibilityActions.LOAD_SUCCESS),
      map((action: kydEligibilityActions.LoadSuccessAction) => action.payload),
      map(payload => new kydSmartphoneEnrollSummaryActions.LoadAction())
    )
  );

  load$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydSmartphoneEnrollSummaryActions.LOAD),
      map((action: kydSmartphoneEnrollSummaryActions.LoadAction) => action.payload),
      withLatestFrom(this.store.select(fromRoot.getEligibleKydSmartphonePolicies)),
      mergeMap(([payload, smartphonePolicies]) => {
        return observableFrom(smartphonePolicies).pipe(
          mergeMap(smartphonePolicy =>
            this.kydSmartphoneService.getEnrollmentSummary(smartphonePolicy.policyNumber)
          ),
          toArray(),
          switchMap(enrollmentResponses => [
            new kydSmartphoneEnrollSummaryActions.LoadSuccessAction(enrollmentResponses),
            new kydSmartphoneEnrollSummaryActions.LoadCompleteAction(),
            new kydEligibilityActions.LoadSmartPhoneKydPoliciesAction({
              smartPhonePolicyList: smartphonePolicies,
              smartPhoneEnrollmentSummaryList: enrollmentResponses
            })
          ])
        );
      }),
      catchError(error => observableOf(new kydSmartphoneEnrollSummaryActions.LoadFailAction(error)))
    )
  );

  refresh$ = createEffect(() =>
    this.action$.pipe(
      ofType(kydSmartphoneEnrollSummaryActions.REFRESH),
      map((action: kydSmartphoneEnrollSummaryActions.RefreshAction) => action.payload),
      withLatestFrom(this.store.select(fromRoot.getEligibleKydSmartphonePolicies)),
      mergeMap(([policyNumbers, smartphonePolicies]) => {
        const matchedPolicies = smartphonePolicies.filter(policy =>
          policyNumbers.some(policyNumber => policy.policyNumber === policyNumber)
        );
        return observableOf(new kydSmartphoneEnrollSummaryActions.LoadAction(matchedPolicies));
      })
    )
  );

  constructor(
    private store: Store<fromRoot.RootState>,
    private kydSmartphoneService: KydSmartphoneService,
    private action$: Actions
  ) {}
}
