import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get } from 'lodash';
import { take } from 'rxjs/operators';

import {
  AlternateAuthService,
  Magic3SearchRequest,
  ProfileActions
} from '@amfam/profile/data-access';
import { AnalyticsFacade } from '@amfam/shared/analytics';
import { BrandSelectors } from '@amfam/shared/utility/brand';
import { FeatureFlagService } from '@amfam/shared/utility/feature-flag/data-access';
import { fromRouterActions } from '@amfam/shared/utility/navigation';
import { ConfigService, CopyService } from '@amfam/shared/utility/shared-services';
import { LoadingSpinnerService } from '@amfam/ui-kit';

import { ForgerockAuthService } from '../../../core/auth/forgerock-auth.service';
import { Magic3Model } from '../../../shared/magic3-search/magic3.model';
import { LoginAnalytics } from '../../shared/login-constants';


@Component({
  selector: 'ds-email-validation-login',
  templateUrl: './email-validation-login.component.html',
  styleUrls: ['./email-validation-login.component.scss']
})
export class EmailValidationLoginComponent implements OnInit {
  showLogin = true;
  showSearch = false;
  accountLocked = false;
  magic3Error = false;
  loginResponseCode: number;
  partnerId: string;
  searchErrorMessage: string;
  searchErrorMessageInput: string;
  forgerockEnabled: boolean;
  contactNumber: string;

  constructor(
    private alternateAuthService: AlternateAuthService,
    private analyticsFacade: AnalyticsFacade,
    private spinner: LoadingSpinnerService,
    private copyService: CopyService,
    private store: Store,
    private forgerockService: ForgerockAuthService,
    private config: ConfigService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.searchErrorMessage = this.copyService.getCopy('auth.emailValidationSearchError');
    this.store
      .select(BrandSelectors.selectPartnerId)
      .pipe(take(1))
      .subscribe(partnerId => {
        this.partnerId = partnerId;
      });
    this.forgerockEnabled = this.featureFlagService.isEnabled('forgerock');
    this.contactNumber = this.copyService.getCopy('shared.amfamPhoneNumber');
  }

  loginSelected() {
    this.showLogin = true;
    this.showSearch = false;
  }

  searchSelected() {
    this.showLogin = false;
    this.showSearch = true;
  }

  onMagic3Submit(magic3: Magic3Model) {
    this.spinner.start();
    this.analyticsFacade.trackPage(LoginAnalytics.emailVerificationMagic3);
    this.searchErrorMessageInput = '';
    const requestObj: Magic3SearchRequest = Object.assign({}, magic3, {
      partnerId: this.partnerId
    });

    this.alternateAuthService
      .magic3Search(requestObj)
      .pipe(take(1))
      .subscribe(
        res => {
          this.spinner.stop();
          const customerId: string = _get(res, 'verifiedUsers.partyId');
          this.store.dispatch(ProfileActions.Magic3SearchSuccessAction(customerId));
          this.store.dispatch(
            fromRouterActions.Go({
              path: ['/email-validation/verify-phone']
            })
          );
        },
        err => {
          this.spinner.stop();
          this.searchErrorMessageInput = this.searchErrorMessage;
        }
      );
  }

  loginResponseReceived(responseCode: number) {
    this.analyticsFacade.trackPage(LoginAnalytics.emailVerificationLogin);
    this.loginResponseCode = responseCode;
    if (responseCode === 423 || responseCode === 403201) {
      this.accountLocked = true;
      this.showLogin = false;
      this.showSearch = true;
    }
  }
  async signIn() {
    this.spinner.start();
    const acrValues = this.config.get('forgerock.login');
    await this.forgerockService.getTokens(acrValues, true);
    this.spinner.stop();
  }
}
