import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';

import { Entities } from '../models/entity/entity.model';
import { PendingPayment } from '../models/pending-payment.models';
import * as fromPendingPayment from './pending-payment.reducer';

export const PENDING_PAYMENT_FEATURE_KEY = 'pendingPayment';

const selectPendingPaymentState = createFeatureSelector<Entities<PendingPayment>>(
  PENDING_PAYMENT_FEATURE_KEY
);

export const selectPendingPaymentIds = createSelector(
  selectPendingPaymentState,
  fromPendingPayment.getIds
);
export const selectPendingPaymentEntities = createSelector(
  selectPendingPaymentState,
  fromPendingPayment.getEntities
);
export const selectPendingPayments = createSelector(
  selectPendingPaymentEntities,
  selectPendingPaymentIds,
  (entities, ids) => ids.map(id => entities[id])
);
export const selectPendingPaymentsLoading = createSelector(
  selectPendingPaymentState,
  fromPendingPayment.getLoading
);

export const selectBillAccountsEligibleForOneTimePay = createSelector(
  BillAccountsSelectors.selectModifiableBillAccounts,
  billAccounts => billAccounts.filter(billAccount => Number(billAccount.accountBalance) > 0)
);

export const selectBillAccountsWithPastDue = createSelector(
  selectBillAccountsEligibleForOneTimePay,
  billAccounts => billAccounts.filter(billAccount => billAccount.pastDue)
);

export const pendingPaymentQuery = {
  selectPendingPaymentState,
  selectPendingPaymentIds,
  selectPendingPaymentEntities,
  selectPendingPaymentsLoading,
  selectPendingPayments,
  selectBillAccountsEligibleForOneTimePay,
  selectBillAccountsWithPastDue
};
