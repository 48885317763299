import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BillingBillAccountDataAccessModule } from '@amfam/billing/billaccount/data-access';
import { ProfileCommunicationPreferencesDataAccessModule } from '@amfam/profile/communication-preferences/data-access';
import { ProfileCommunicationPreferencesUiModule } from '@amfam/profile/communication-preferences/ui';
import { ProfileFeatureModule } from '@amfam/profile/feature';
import { SharedServicesModule } from '@amfam/shared/utility/shared-services';
import { UiKitModule } from '@amfam/ui-kit';

import { OnboardingPaperlessEnrollmentFeatureEffects } from './+state/onboarding-paperless-enrollment-feature-effects';
import * as onboardingPaperlessEnrollmentFeature from './+state/onboarding-paperless-enrollment-feature-reducer';
import { GoPaperlessConfirmationComponent } from './containers/go-paperless-confirmation/go-paperless-confirmation.component';
import { GoPaperlessWrapperComponent } from './containers/go-paperless-wrapper/go-paperless-wrapper.component';
import { PaperlessConfirmationWrapperComponent } from './containers/paperless-confirmation-wrapper/paperless-confirmation-wrapper.component';
import { PaperlessModalWrapperComponent } from './containers/paperless-modal-wrapper/paperless-modal-wrapper.component';
import { PaperlessRoutingModule } from './routing/paperless-routing.module';

@NgModule({
  imports: [
    BillingBillAccountDataAccessModule,
    CommonModule,
    FormsModule,
    PaperlessRoutingModule,
    ProfileCommunicationPreferencesDataAccessModule,
    ProfileCommunicationPreferencesUiModule,
    ProfileFeatureModule,
    ReactiveFormsModule,
    SharedServicesModule,
    UiKitModule,
    EffectsModule.forFeature([OnboardingPaperlessEnrollmentFeatureEffects]),
    StoreModule.forFeature(
      onboardingPaperlessEnrollmentFeature.onboardingPaperlessEnrollmentFeatureKey,
      onboardingPaperlessEnrollmentFeature.reducer
    )
  ],
  declarations: [
    PaperlessModalWrapperComponent,
    GoPaperlessWrapperComponent,
    GoPaperlessConfirmationComponent,
    PaperlessConfirmationWrapperComponent
  ]
})
export class ProfileCommunicationPreferencesFeatureModule {}
