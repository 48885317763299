import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BillAccountsSelectors } from '@amfam/billing/billaccount/data-access';
import { EsignatureSelectors } from '@amfam/policy/e-signature/data-access';
import { GoPaperLessSelectors } from '@amfam/policy/go-paperless/data-access';
import { userQuery } from '@amfam/shared/user';

import * as fromPaperlessEnrollmentFeature from './overview-paperless-enrollment-feature-reducer';

export const selectPaperlessOverviewEnrollmentFeatureState =
  createFeatureSelector<fromPaperlessEnrollmentFeature.PaperlessOverviewEnrollmentFeatureState>(
    fromPaperlessEnrollmentFeature.paperlessEnrollmentFeatureKey
  );
export const selectIsOverviewFlow = createSelector(
  selectPaperlessOverviewEnrollmentFeatureState,
  (state: fromPaperlessEnrollmentFeature.PaperlessOverviewEnrollmentFeatureState) =>
    state?.overviewFlow
);

export const selectCorrelationId = createSelector(
  selectPaperlessOverviewEnrollmentFeatureState,
  (state: fromPaperlessEnrollmentFeature.PaperlessOverviewEnrollmentFeatureState) =>
    state?.correlationId
);
export const paperlessOverviewEnrollmentFeatureQuery = {
  selectPaperlessOverviewEnrollmentFeatureState,
  selectIsOverviewFlow,
  selectCorrelationId
};
export const selectAnyLoading = createSelector(
  BillAccountsSelectors.selectBillAccountsAnyLoading,
  GoPaperLessSelectors.selectGoPaperlessLoading,
  (billAccountsAnyLoading, goPaperlessLoading) => billAccountsAnyLoading || goPaperlessLoading
);
export const selectPaperlessSetupData = createSelector(
  BillAccountsSelectors.selectBillAccounts,
  EsignatureSelectors.getESignatureRequired,
  GoPaperLessSelectors.selectIsEnrolledGoPaperless,
  userQuery.getUserState,
  (billAccounts, isESignatureRequired, isEnrolledGoPaperless, user) => ({
    billAccounts,
    isEsignatureRequired: isESignatureRequired,
    isEnrolledGoPaperless,
    user
  })
);
export const selectCombinedBillAccountState = createSelector(
  BillAccountsSelectors.selectBillAccounts,
  BillAccountsSelectors.selectBillAccountsNotifications,
  BillAccountsSelectors.selectBillAccountsAnyLoading,
  GoPaperLessSelectors.selectGoPaperlessLoading,
  GoPaperLessSelectors.selectGoPaperlessError,
  (
    billAccounts,
    billAccountNotifications,
    billAccountsAnyLoading,
    goPaperlessLoading,
    goPaperlessError
  ) => ({
    billAccounts: billAccounts,
    billAccountNotifications,
    loading: billAccountsAnyLoading || goPaperlessLoading,
    goPaperlessError: goPaperlessError
  })
);
