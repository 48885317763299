import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NavigationEnd, NavigationStart, RouteConfigLoadEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { HeaderWrapperService } from '@amfam/shared/ui/ds-header';
import { ImpersonateRolesService } from '@amfam/shared/utility/impersonation';

import { PageTitleService } from '../../../page-title/page-title.service';
import { Action } from '../../models/action.model';
import { DockingBarService } from '../../services/docking-bar.service';

@Component({
  selector: 'docking-bar',
  templateUrl: './docking-bar.component.html',
  styleUrls: ['./docking-bar.component.scss']
})
export class DockingBarComponent implements OnInit, OnDestroy {
  actions: Array<Action>;
  heading = 'My Account';
  secondaryHeading: string;
  empty = true;
  routerUrl: string;

  @Input() isShellAccount: boolean;

  private stop$ = new Subject<void>();
  @ViewChild('mainHeading', { static: false }) mainHeading: ElementRef;
  @ViewChild('dockingBar', { static: false }) dockingBar: ElementRef;

  constructor(
    private dockService: DockingBarService,
    private pageTitle: PageTitleService,
    private router: Router,
    private headerWrapperService: HeaderWrapperService,
    private roleService: ImpersonateRolesService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    // Subscribe to main content focus event
    this.headerWrapperService.focusMainContent$.pipe(takeUntil(this.stop$)).subscribe(() => {
      if (this.mainHeading) {
        this.mainHeading.nativeElement.tabIndex = 0;
        this.mainHeading.nativeElement.focus();
      }
    });

    // filter only for NavigationEnd and RouteConfigLoadEnd (app load) events and set routerUrl var
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd || event instanceof RouteConfigLoadEnd) {
        this.routerUrl = this.router.url;
      }

      // filter only for NavigationStart events
      // we want this code to run _before_ the ngOnInit of the next page
      if (!(event instanceof NavigationStart)) {
        return;
      }

      // don't do anything if we're navigating to the same page
      if (event.url === this.router.url) {
        return;
      }

      this.dockService.clear();
    });

    this.dockService.dockHeading$.subscribe(this.updateHeading.bind(this));
    this.dockService.dockSecondaryHeading$.subscribe(this.updateSecondaryHeading.bind(this));
    this.dockService.dockActions$
      .pipe(takeUntil(this.stop$), withLatestFrom(this.roleService.isAuthorized('file_a_claim')))
      .subscribe(([actions, authorizeFileAClaim]) => {
        this.actions = actions.sort((a, b) => (a.title > b.title ? -1 : 1));
        if (!authorizeFileAClaim) {
          this.actions = this.actions.filter(ele => ele.title !== 'File a Claim');
        }
      });

    this.pageTitle.title$.subscribe(this.updatePageTitle.bind(this));
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  updateHeading(heading) {
    this.heading = heading;

    if (this.isShellAccount === undefined && this.dockingBar) {
      this.renderer.removeClass(this.dockingBar.nativeElement, 'primary-800-bg');
      this.renderer.addClass(this.dockingBar.nativeElement, 'docking-bar-amfam');
    }

    if (!heading) {
      this.empty = true;
    } else {
      this.empty = false;
    }
  }

  updateSecondaryHeading(secondaryHeading) {
    this.secondaryHeading = secondaryHeading;
  }

  updatePageTitle(heading) {
    // if the page has registered a dock heading, it takes priority
    if (!this.heading) {
      this.dockService.registerHeading(heading);
    }
  }
}
