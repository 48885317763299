<div class="row">
  <div class="column twelve lg-eight lg-push-two md-eight md-push-two">
    <div class="column twelve md-push-two md-eight">
      <amfam-enrollment-header
        [firstLine]="'Would you like to setup Autopay?'"
      ></amfam-enrollment-header>
      <form [formGroup]="autoPaySignupFormGroup">
        <ds-radio-group
          data-cy="autoPayOptionsRadioButtons"
          class="radio-group-container"
          [control]="'autoPayOption'"
          [parentFormGroup]="autoPaySignupFormGroup"
          [options]="autoPaySignupOptions"
        >
        </ds-radio-group>
      </form>
      <call-to-action
        [disabled]="autoPaySignupFormGroup.invalid"
        [primaryButtonName]="'Continue'"
        (primaryEvent)="submit()"
        [displayBackButton]="true"
        (backEvent)="back()"
      ></call-to-action>
    </div>
  </div>
</div>
